import React, { useEffect } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { func, object, bool, shape, number } from 'prop-types';
import { ToolBarBtnDivider, Select, DatePicker } from '@poly/admin-book';
import {
  getAdminUsersByEmploymentStatusQuery,
  UserSelect,
  ClientSelect,
} from '@poly/admin-ui';
import {
  UserEmployeeInfoStatus,
  AccountingStatus,
  ASC_SORT_ORDER,
} from '@poly/constants';
import { alwaysNewDate, ensureIsDate } from '@poly/utils';
import { endOfDay } from 'date-fns';
import { keywordSortQuery } from '@poly/client-utils';

import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import { FlexCenterAlign } from '../../components/FlexContainer.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { FiltersContainer } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { WorkInProgressExportPDFBtn } from './WorkInProgressExportPDFBtn.js';
import { WorkInProgressExportXLSBtn } from './WorkInProgressExportXLSBtn.js';
import { projectAccountingStatusToString } from '../../sidebars/ProjectSidebar/projectSidebarUtils.js';
import { wipRecordsPropTypes } from './WorkInProgressTable.js';

const workInProgressFiltersConfig = (date) => [
  { name: 'clientId' },
  { name: 'projectManagerId' },
  { name: 'projectAccountingStatus' },
  { name: 'date', defaultValue: date },
];

export function AACManagerSelect(props) {
  return (
    <UserSelect
      {...props}
      placeholder="Start typing name"
      query={getAdminUsersByEmploymentStatusQuery(
        UserEmployeeInfoStatus.ACTIVE,
      )}
      sort={keywordSortQuery(['profile', 'fullName'])(ASC_SORT_ORDER)}
      withoutSkip
      isClearable
    />
  );
}

// getAccountingStatusOptions :: [String] -> Pair String String
const getAccountingStatusOptions = R.map(
  R.applySpec({
    value: R.identity,
    label: R.compose(
      projectAccountingStatusToString,
      R.objOf('accountingStatus'),
    ),
  }),
);

const accountingStatusOptions = getAccountingStatusOptions([
  AccountingStatus.PENDING,
  AccountingStatus.READY_TO_INVOICE,
]);

export const ButtonsContainerS = styled(FlexCenterAlign)`
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const formatFilters = R.evolve({
  date: R.compose(endOfDay, ensureIsDate),
});

export function AccountingStatusSelect(props) {
  const selectProps = {
    name: 'accountingStatus',
    placeholder: 'All',
    options: accountingStatusOptions,
    width: '100%',
    isClearable: true,
    ...props,
  };

  return <Select {...selectProps} />;
}

export function WorkInProgressHeader({
  setQueryFilters,
  queryFilters,
  loading,
  sorting,
  wipRecords,
}) {
  const { searchFilters, handlers, onReset } = useSearchFilters(
    workInProgressFiltersConfig(alwaysNewDate()),
  );

  const onSearch = () => {
    setQueryFilters(formatFilters(searchFilters));
  };

  const onResetSearch = () => {
    onReset();
  };

  useEffect(onSearch, []);

  return (
    <SearchPageHeaderContainer title="Work In Progress Report">
      <FiltersContainer>
        <FlexSpaceBetween>
          <SearchHeaderColumn
            title="Client"
            titleWidth="120px"
            filterWidth="260px"
          >
            <ClientSelect
              value={searchFilters.clientId}
              onChange={handlers.clientId}
              width="100%"
              includeAllOption={false}
              withoutSkip
              isClearable
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn
            title="Date"
            titleWidth="120px"
            filterWidth="260px"
          >
            <DatePicker
              value={searchFilters.date}
              onChange={handlers.date}
              width="100%"
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn filterWidth="200px">
            <ButtonsContainerS>
              <WorkInProgressExportPDFBtn
                queryFilters={queryFilters}
                sorting={sorting}
                wipRecords={wipRecords}
              />
              <ToolBarBtnDivider />
              <WorkInProgressExportXLSBtn
                queryFilters={queryFilters}
                sorting={sorting}
                wipRecords={wipRecords}
              />
            </ButtonsContainerS>
          </SearchHeaderColumn>
        </FlexSpaceBetween>
        <FlexSpaceBetween>
          <SearchHeaderColumn
            title="Project Manager"
            titleWidth="120px"
            filterWidth="260px"
          >
            <AACManagerSelect
              value={searchFilters.projectManagerId}
              onChange={handlers.projectManagerId}
              width="100%"
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn
            title="Acct Status"
            titleWidth="120px"
            filterWidth="260px"
          >
            <AccountingStatusSelect
              value={searchFilters.projectAccountingStatus}
              onChange={handlers.projectAccountingStatus}
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn filterWidth="200px">
            <SearchHeaderButton
              size="small"
              styleType="basicPrimary"
              onClick={onResetSearch}
            >
              Reset
            </SearchHeaderButton>
            <SearchHeaderButton
              size="small"
              onClick={onSearch}
              loader={loading}
            >
              Search
            </SearchHeaderButton>
          </SearchHeaderColumn>
        </FlexSpaceBetween>
      </FiltersContainer>
    </SearchPageHeaderContainer>
  );
}

WorkInProgressHeader.propTypes = {
  wipRecords: wipRecordsPropTypes,
  setQueryFilters: func.isRequired,
  sorting: shape({
    columnKey: number.isRequired,
    dir: number.isRequired,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  queryFilters: object,
  loading: bool,
};
