import React from 'react';
import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import {
  arrayOf,
  bool,
  func,
  instanceOf,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import styled, { css } from 'styled-components';
import { NOTHING_UI_STRING } from '@poly/constants';
import { formatTotal, formatDate, convertCentsToDollars } from '@poly/utils';
import { SidebarRow, useMapConfigToTableProps } from '@poly/admin-ui';
import { Table } from '@poly/admin-book/src/Table/Table.js';
import { Button } from '@poly/admin-book/src/Button/index.js';
import { getThemeColor } from '@poly/admin-book/src/utils.js';
import { moneyColumnStyles } from '@poly/admin-book/src/Table/columnStyles.js';
import { useOutSidebarContext } from '@poly/client-utils/src/slideOutSidebar.js';
import { formatDateOrNothingUI } from '@poly/client-utils/src/dates.js';
import { LinkButton } from '@poly/admin-book/src/LinkButton/index.js';
import { DatePicker } from '@poly/admin-book/src/DatePicker/index.js';

import { HeaderBlock } from './HeaderBlock.js';
import { journalPropTypes } from './transactionDetailsPropTypes.js';
import {
  transactionsDetailsSidebarId,
  transformJournalsToTransactionInfo,
} from './helpers.js';
import { PrintTransactionDetailsPDF } from './PrintButton.js';
import { FlexColumn, FlexContainer } from '../../components/FlexContainer.js';
import { useEditJournalPostingDate } from './useEditJournalPostingDate.js';
import { BlockWithLabel } from '../components/commonSidebarComponents.js';

// concatAccountNameWithCode :: Account -> String
const concatAccountNameWithCode = R.converge(R.concat, [
  R.propOr('', 'code'),
  R.compose(R.concat(' - '), R.propOr('', 'name')),
]);

const transactionLinesTableConfig = [
  ['Inv #', R.prop('number')],
  ['Inv Date', R.compose(formatDate, R.prop('invoiceDate'))],
  ['Project #', R.path(['project', 'projectId'])],
  ['Client', R.path(['client', 'name'])],
  ['GL Code', R.compose(concatAccountNameWithCode, R.prop('glAccount'))],
  [
    'Inv Amount',
    R.compose(formatTotal, convertCentsToDollars, R.prop('amount')),
  ],
  [
    'Deductions',
    R.compose(formatTotal, R.propOr(NOTHING_UI_STRING, 'deductionsAmount')),
  ],
  ['Recv`d Amount', R.compose(formatTotal, R.prop('paidAmount'))],
  ['Due Amount', R.compose(formatTotal, R.prop('balance'))],
];

const paymentTransactionDetailsTableStyles = css`
  th {
    text-transform: none;
    font-size: 13px;
  }

  ${moneyColumnStyles(6, 100)};
  ${moneyColumnStyles(7, 100)};
  ${moneyColumnStyles(8, 100)};
  ${moneyColumnStyles(9, 100)};
  td,
  th {
    width: 10%;
  }
  td:nth-child(4),
  th:nth-child(4),
  td:nth-child(5),
  th:nth-child(5) {
    width: 15%;
  }
  tr {
    margin: 0;
    width: 100%;
  }
`;

const PaymentTransactionTableS = styled(Table)`
  ${paymentTransactionDetailsTableStyles};
`;

export const TransactionDetailsContainer = styled(FlexColumn)`
  padding: 24px 0;
`;
export const TransactionHeader = styled.h1`
  font-size: 20px;
  margin: 0;
  font-weight: normal;
`;

export const TransactionLinesContainer = styled(FlexContainer)`
  border-top: 1px solid ${getThemeColor(['lighter'])};
`;

export const TransactionTotalFooterContainer = styled(FlexContainer)`
  border-top: 1px solid ${getThemeColor(['lighter'])};
  height: 50px;
  justify-content: flex-end;
  padding-top: 10px;
`;

const FlexContainerS = styled(FlexContainer)`
  gap: 10px;
`;

export const getDefaultARAccountQuery = gql`
  query getDefaultARAccountQuery($filters: AccountFilters) {
    getAccounts(filters: $filters) {
      hits {
        _id
        code
      }
    }
  }
`;

// getDefaultARAccountCode :: { getAccounts: GetAccountsResult } -> String
const getDefaultARAccountCode = R.path(['getAccounts', 'hits', '0', 'code']);

function ClientPaymentTransactionDetailsBody({
  tableProps,
  transactionInfo,
  closeOutSidebar,
  editJournalPostingDateProps,
}) {
  const {
    handleEditJournalPostingDate,
    isEditMode,
    toggleEditMode,
    postingDate,
    setPostingDate,
  } = editJournalPostingDateProps;

  return (
    <TransactionDetailsContainer>
      <SidebarRow justify>
        <TransactionHeader>Receive Payment Details</TransactionHeader>
        {isEditMode ? (
          <FlexContainerS>
            <Button
              size="tiny"
              styleType="accentDark"
              onClick={handleEditJournalPostingDate}
            >
              Save
            </Button>
            <Button
              size="tiny"
              styleType="primaryLighter"
              onClick={toggleEditMode}
            >
              Cancel
            </Button>
          </FlexContainerS>
        ) : (
          <FlexContainerS>
            <PrintTransactionDetailsPDF
              Component={ClientPaymentTransactionDetailsBody}
              transactionInfo={transactionInfo}
              tableProps={tableProps}
            />
            <LinkButton onClick={toggleEditMode}>Edit</LinkButton>
            <Button
              size="tiny"
              styleType="accentDark"
              onClick={() => closeOutSidebar(transactionsDetailsSidebarId)}
            >
              Close
            </Button>
          </FlexContainerS>
        )}
      </SidebarRow>
      <SidebarRow>
        <HeaderBlock title="Deposit To" content={transactionInfo.account} />
        <HeaderBlock
          title="Memo"
          content={transactionInfo.description}
          withLongText
        />
        <HeaderBlock
          title="Bank Clearing Date"
          content={formatDateOrNothingUI(transactionInfo.bankClearedDate)}
        />
      </SidebarRow>
      <SidebarRow>
        {isEditMode ? (
          <BlockWithLabel
            id="postingDate"
            label="Posting Date"
            width="25%"
            onChange={setPostingDate}
            value={postingDate}
            Component={DatePicker}
            leftMove="0"
          />
        ) : (
          <HeaderBlock
            title="Posting Date"
            content={formatDate(transactionInfo.date)}
          />
        )}
        <HeaderBlock
          title="Amount"
          content={formatTotal(transactionInfo.paidTotal)}
        />
        <HeaderBlock title="Created By" content={transactionInfo.createdBy} />
      </SidebarRow>
      <TransactionLinesContainer>
        <SidebarRow>
          <PaymentTransactionTableS {...tableProps} />
        </SidebarRow>
      </TransactionLinesContainer>
      <TransactionTotalFooterContainer>
        <HeaderBlock
          title="Total Deductions"
          content={formatTotal(transactionInfo.deductionsTotal)}
          width="auto"
          id="deductions-total"
        />
        <HeaderBlock
          title="Total Payment"
          content={formatTotal(transactionInfo.paidTotal)}
          width="auto"
          id="deductions-total"
        />
      </TransactionTotalFooterContainer>
    </TransactionDetailsContainer>
  );
}

ClientPaymentTransactionDetailsBody.propTypes = {
  isPrintPDF: bool,
  tableProps: shape({}),
  closeOutSidebar: func,
  transactionInfo: shape({
    account: string,
    description: string,
    bankClearedDate: oneOfType([instanceOf(Date), string]),
    date: oneOfType([instanceOf(Date), string]),
    paidTotal: number,
    deductionsTotal: number,
  }),
  editJournalPostingDateProps: shape({}),
};
ClientPaymentTransactionDetailsBody.defaultProps = {
  editJournalPostingDateProps: {},
};

export function ClientPaymentTransactionDetailsSidebar({ journals }) {
  const { closeOutSidebar } = useOutSidebarContext();

  const { data } = useQuery(getDefaultARAccountQuery, {
    variables: { filters: { isDefaultARAccount: true } },
    fetchPolicy: 'network-only',
  });

  const defaultARAccountCode = getDefaultARAccountCode(data);

  const transactionInfo =
    transformJournalsToTransactionInfo(defaultARAccountCode)(journals);

  const tableProps = useMapConfigToTableProps(
    R.always(transactionInfo.invoices),
    transactionLinesTableConfig,
  );

  const editJournalPostingDateProps =
    useEditJournalPostingDate(transactionInfo);

  return (
    <ClientPaymentTransactionDetailsBody
      transactionInfo={transactionInfo}
      tableProps={tableProps}
      closeOutSidebar={closeOutSidebar}
      editJournalPostingDateProps={editJournalPostingDateProps}
    />
  );
}

ClientPaymentTransactionDetailsSidebar.propTypes = {
  journals: arrayOf(journalPropTypes),
};
