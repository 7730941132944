export const entities = {
  PROJECT: 'project',
  RECURRING_PROJECT: 'recurringProject',
  PREVENTIVE_MAINTENANCE: 'maintenancePlan',
  SUPPLIER: 'supplier',
  CLIENT: 'client',
  PROPERTY: 'property',
  USER: 'user',
  INVOICE: 'invoice',
  OCR_INVOICE: 'ocrInvoice',
  BATCH: 'batch',
  MASTER_SUPPLIER: 'masterSupplier',
  ASSET: 'asset',
  CONTACT: 'contact',
  ADMIN_PURCHASE_ORDER: 'adminPurchaseOrder',
  USER_GROUP: 'userGroup',
};
