import React from 'react';
import { number } from 'prop-types';
import styled from 'styled-components';

import { ProjectsPageCommonTable } from '../projectsTable/ProjectsTable.js';

const ToolbarWrapper = styled.div`
  display: flex;
  position: absolute;
  left: 5px;
  top: -25px;
  z-index: 1;
`;

export function ProjectSearchTableComp({ counts, ...props }) {
  const countsText = `Results (${counts})`;

  return (
    <>
      <ToolbarWrapper>{countsText}</ToolbarWrapper>
      <ProjectsPageCommonTable {...props} />
    </>
  );
}

ProjectSearchTableComp.propTypes = {
  counts: number.isRequired,
};
