import {
  bool,
  func,
  node,
  shape,
  object,
  string,
  arrayOf,
  oneOfType,
} from 'prop-types';
import * as R from 'ramda';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Loader } from '@poly/admin-book';

import {
  GlobalSearchResultTabsS,
  GlobalSearchResultWrapperS,
  GlobalSearchResultActiveTabS,
} from './globalSearchResultStyles.js';
import { GlobalSearchResultTab } from './GlobalSearchResultTab.js';
import { NothingFoundSearchResultComponent } from './searchResultItems/NotFoundSearchResult.js';

function GlobalSearchResultC({ activeTab, changeTab, tabs, isLoading }) {
  return (
    <GlobalSearchResultWrapperS>
      {isLoading && <Loader />}
      {activeTab && (
        <GlobalSearchResultTabsS
          tabs={tabs}
          value={activeTab}
          onChange={changeTab}
          ActiveComponentContainer={GlobalSearchResultActiveTabS}
        />
      )}
      {!activeTab && !isLoading && <NothingFoundSearchResultComponent />}
    </GlobalSearchResultWrapperS>
  );
}

GlobalSearchResultC.propTypes = {
  activeTab: string.isRequired,
  changeTab: func.isRequired,
  tabs: arrayOf(arrayOf(oneOfType([string, node])).isRequired).isRequired,
  isLoading: bool.isRequired,
};

const createReactElementCurried = R.curryN(2, React.createElement);

// generateGlobalSearchTabs :: (GlobalSearchConfig, GlobalSearchData) -> TabsConfig
// GlobalSearchConfig = { index: String, title: String, Component: ReactComponent, urlGenerator: Document -> Url }
// GlobalSearchData = { searchProjects: { hits: [Object], total: Number }, ... }
const generateGlobalSearchTabs = R.curry(
  (globalSearchConfig, globalSearchData) =>
    R.compose(
      R.map(
        R.juxt([
          R.prop('title'),
          R.prop('index'),
          R.compose(
            createReactElementCurried(GlobalSearchResultTab),
            R.apply(R.mergeRight),
            R.juxt([
              R.omit('title'),
              R.compose(R.prop(R.__, globalSearchData), R.prop('index')),
            ]),
          ),
        ]),
      ),
      R.sortBy(
        R.compose(
          R.multiply(-1), // make descending order
          R.prop('maxScore'),
          R.prop(R.__, globalSearchData),
          R.prop('index'),
        ),
      ),
      R.reject(
        R.compose(
          R.either(R.isNil, R.equals(0)),
          R.prop('total'),
          R.prop(R.__, globalSearchData),
          R.prop('index'),
        ),
      ),
    )(globalSearchConfig),
);

// getGlobalSearchTabs :: (GlobalSearchConfig, Function, Object) -> Object
const getGlobalSearchTabs = (globalSearchTabsConfig, transformData, data) =>
  R.compose(
    generateGlobalSearchTabs(globalSearchTabsConfig),
    transformData,
    R.defaultTo([]),
  )(data);

export function GlobalSearchResultComponent({
  queryParams,
  transformData,
  globalSearchQuery,
  globalSearchTabsConfig,
}) {
  const [activeTab, setActiveTab] = useState(null);

  const globalSearchText = useSelector((state) => state.globalSearchText);

  const { data, loading } = useQuery(globalSearchQuery, {
    variables: { input: { searchTerm: globalSearchText, ...queryParams } },
    fetchPolicy: 'network-only',
  });

  const tabs = getGlobalSearchTabs(globalSearchTabsConfig, transformData, data);

  useEffect(() => {
    if (activeTab) {
      setActiveTab(null);
    }
  }, [loading]);

  return (
    <GlobalSearchResultC
      {...{
        tabs,
        changeTab: setActiveTab,
        isLoading: loading,
        activeTab: activeTab || R.pathOr('', [0, 1], tabs),
      }}
    />
  );
}

GlobalSearchResultComponent.propTypes = {
  transformData: func,
  globalSearchTabsConfig: arrayOf(
    shape({
      urlGenerator: func,
      index: string.isRequired,
      title: string.isRequired,
      Component: func.isRequired,
      LinkComponent: oneOfType([func, object]),
      highlightPath: arrayOf(arrayOf(string)).isRequired,
    }),
  ),
  /* eslint-disable react/forbid-prop-types */
  globalSearchQuery: object,
  queryParams: object,
};

GlobalSearchResultComponent.defaultProps = { transformData: R.identity };
