import React from 'react';
import { bool, string } from 'prop-types';
import { useOutSidebarContext } from '@poly/client-utils';
import { SidebarWidth, entities } from '@poly/admin-ui';
import { LinkButton } from '@poly/admin-book';

import { SecondSidebarLayout } from '../../../components/SidebarLayouts.js';
import { assignSupplierFormId } from '../../../modules/forms/assignSupplierForm/constants.js';
import { AssignSupplierForm } from '../../../modules/forms/assignSupplierForm/AssignSupplierForm.js';
import { assignSupplierFormTypes } from '../constants.js';
import { masterProjectSidebarTabsId } from '../../MasterRecurringProjectSidebar/constants.js';
import { useHasUserAccessToUpdateProject } from '../useHasUserAccessToUpdateProject.js';

export function AddSupplierToProjectButton({
  withLine,
  _id,
  isRecurringProject,
}) {
  const hasAccessToUpdateProject =
    useHasUserAccessToUpdateProject(isRecurringProject);

  const { openOutSidebar } = useOutSidebarContext();

  const entity = {
    _id,
    name: isRecurringProject ? entities.RECURRING_PROJECT : entities.PROJECT,
  };

  const onClick = () =>
    openOutSidebar({
      width: SidebarWidth,
      id: assignSupplierFormId,
      Layout: SecondSidebarLayout,
      hideSidebarId: masterProjectSidebarTabsId,
      content: (
        <AssignSupplierForm
          formType={assignSupplierFormTypes.add}
          entity={entity}
        />
      ),
    });

  return (
    <LinkButton
      withLine={withLine}
      onClick={onClick}
      disabled={!hasAccessToUpdateProject}
    >
      Add
    </LinkButton>
  );
}

AddSupplierToProjectButton.propTypes = {
  withLine: bool,
  _id: string.isRequired,
  isRecurringProject: bool,
};
AddSupplierToProjectButton.defaultProps = { withLine: false };
