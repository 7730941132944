import * as R from 'ramda';
import React from 'react';
import { arrayOf } from 'prop-types';
import { useMapConfigToTableProps } from '@poly/admin-ui';
import {
  formatCurrency,
  calculateTotal,
  formatDate,
  applyProp,
} from '@poly/utils';

import {
  CustomerHistoryTableS,
  CustomerHistoryFooterContainer,
} from './components.js';
import { customerTransactionPropType } from './propTypes.js';
import {
  getTransactionUserFriendlyName,
  getTransactionUserFriendlyType,
} from '../VendorLedgerReport/vendorLedgerReportUtils.js';

// getAmountByProp :: String -> SupplierPaymentTransaction -> String
const getAmountByProp = (prop) => R.compose(formatCurrency, R.propOr(0, prop));

const customerTransactionHistoryConfig = [
  ['Transaction', getTransactionUserFriendlyName],
  ['Date', applyProp(formatDate)('date')],
  ['Type', getTransactionUserFriendlyType],
  ['Project', R.path(['project', 'projectId'])],
  ['Invoice Amount', getAmountByProp('invoiceAmount')],
  ['Paid Amount', getAmountByProp('paymentAmount')],
  ['Credit Memo', getAmountByProp('creditMemo')],
];

// getTotalByProp :: (String, [Transaction]) -> String
const getTotalByProp = (prop, rows) =>
  R.compose(formatCurrency, calculateTotal(R.prop(prop)))(rows);

function TotalFooter({ rows }) {
  return (
    <CustomerHistoryFooterContainer>
      <td>Total</td>
      <td>{getTotalByProp('invoiceAmount', rows)}</td>
      <td>{getTotalByProp('paymentAmount', rows)}</td>
      <td>{getTotalByProp('creditMemo', rows)}</td>
    </CustomerHistoryFooterContainer>
  );
}

TotalFooter.propTypes = {
  rows: arrayOf(customerTransactionPropType),
};

export function CustomerHistoryTable({ transactions }) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    customerTransactionHistoryConfig,
    transactions,
  );

  return (
    <CustomerHistoryTableS
      {...tableProps}
      TableFooter={TotalFooter}
      footerHeight={60}
    />
  );
}

CustomerHistoryTable.propTypes = {
  transactions: arrayOf(customerTransactionPropType),
};
