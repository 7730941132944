import * as R from 'ramda';
import styled from 'styled-components';
import React from 'react';
import { ProjectType } from '@poly/constants';
import { isNilOrEmpty } from '@poly/utils';
import { TextButton, IconButton, defaultTheme, Select } from '@poly/admin-book';
import { func, arrayOf, string, shape, number } from 'prop-types';
import {
  REQUESTS_APP_NAME,
  CLIENT_PORTAL_APP_NAME,
  ASSET_SCANNER_APP_NAME,
} from '@poly/security';

import { AppTMRulesWarning } from './AppTMRulesWarning.js';
import {
  FlexContainer,
  FlexCenterAlign,
} from '../../../../components/FlexContainer.js';

const {
  colors: { accent1Lighter1 },
} = defaultTheme;

const AddBtn = styled(TextButton)`
  margin-left: 10px;
  padding-top: 8px;
`;

const SelectedItem = styled.div`
  width: 100%;
  padding: 10px 0;
  font-weight: 500;
  font-size: 14px;
  color: #3e4c5b;
`;

const FlexCenterAlignS = styled(FlexCenterAlign)`
  gap: 5px;
`;

// isEditClient :: { formData: FormData } -> Boolean
const isEditClient = R.pathSatisfies(R.is(String), ['formData', '_id']);

// isClientWithoutTMMarkupRules :: { formData: FormData } -> Boolean
const isClientWithoutTMMarkupRules = R.pathSatisfies(isNilOrEmpty, [
  'formData',
  'tmMarkupRules',
]);

// shouldDisplayWarning :: { formData: FormData, app: String } -> Boolean
const shouldDisplayWarning = R.either(
  R.allPass([
    isEditClient,
    isClientWithoutTMMarkupRules,
    R.propSatisfies(
      R.includes(R.__, [CLIENT_PORTAL_APP_NAME, ASSET_SCANNER_APP_NAME]),
      'app',
    ),
  ]),
  R.allPass([
    isEditClient,
    isClientWithoutTMMarkupRules,
    R.propEq(REQUESTS_APP_NAME, 'app'),
    R.pathEq(ProjectType.WORK_ORDER, [
      'formData',
      'configs',
      'requestsApp',
      'projectType',
    ]),
  ]),
);

export function ItemsSelectComp({
  value: selectedItems,
  onItemToAddChange,
  itemToAdd,
  availableOptions,
  onAddNewItem,
  onRemoveItem,
  optionLabelByKey,
  className,
  formData,
  ...fieldProps
}) {
  const selectProps = {
    options: availableOptions,
    value: itemToAdd,
    onChange: onItemToAddChange,
    ...fieldProps,
  };

  return (
    <div className={className}>
      <FlexContainer>
        <Select {...selectProps} />
        <AddBtn onClick={onAddNewItem}>Add</AddBtn>
      </FlexContainer>
      {selectedItems.map((item) => (
        <FlexCenterAlignS key={item}>
          {shouldDisplayWarning({ formData, app: item }) && (
            <AppTMRulesWarning />
          )}
          <SelectedItem>{optionLabelByKey(item)}</SelectedItem>
          <IconButton
            onClick={() => onRemoveItem(item)}
            name="close"
            size={8}
            initialColor={accent1Lighter1}
            hoverColor={accent1Lighter1}
          />
        </FlexCenterAlignS>
      ))}
    </div>
  );
}

ItemsSelectComp.propTypes = {
  itemToAdd: string.isRequired,
  availableOptions: arrayOf(shape({})).isRequired,
  value: arrayOf(string.isRequired).isRequired,
  onAddNewItem: func.isRequired,
  onItemToAddChange: func.isRequired,
  onRemoveItem: func.isRequired,
  optionLabelByKey: func.isRequired,
  className: string,
  formData: shape({
    configs: shape({
      requestsApp: shape({
        projectType: string,
      }),
    }),
    tmMarkupRules: arrayOf(
      shape({
        upTo: number,
        percent: number,
      }),
    ),
  }),
};
