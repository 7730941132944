import { gql } from '@apollo/client';

export const CLIENTS_BY_SEARCH_SUB = gql`
  subscription CLIENTS_BY_SEARCH_SUB($searchInput: CollectionSearchParams!) {
    searchClientChanged(input: $searchInput) {
      id
      type
    }
  }
`;
