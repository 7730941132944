import * as R from 'ramda';
import { alwaysNewDate, calculateTotal, propEqLegacy } from '@poly/utils';
import { endOfYear, startOfYear } from 'date-fns';

// getCurrentYear :: _ -> Number
export const getCurrentYear = R.compose(
  R.invoker(0, 'getFullYear'),
  alwaysNewDate,
);

const WIP_TOTAL_ROW_ID = 'wip_total_row_id';
const SALES_TOTAL_ROW_ID = 'sales_total_row_id';

export const formats = {
  sales: 'sales',
  wip: 'wip',
  total: 'total',
};

const isWIPTotalRow = propEqLegacy('_id', WIP_TOTAL_ROW_ID);
const isSalesTotalRow = propEqLegacy('_id', SALES_TOTAL_ROW_ID);
export const isTotalRow = R.either(isWIPTotalRow, isSalesTotalRow);

// getYearMonthDate :: (Number, Number) -> Date
const getYearMonthDate = (year, month) => new Date(year, month);

// getYearIntervalDate :: (Number, Number) -> [Date]
export const getYearIntervalDate = (year, month = 0) => {
  const yearDate = getYearMonthDate(year, month);
  const startDate = startOfYear(yearDate);
  const endDate = endOfYear(yearDate);
  return [startDate, endDate];
};

// formatYearReportBase :: (A -> MonthReport) -> Report -> YearReport
// YearReport = { clientName: String, [1-12]: MonthReport }
// MonthReport = { salesAmount: Number, wipAmount: Number, amount: Number }
// Report = Any
const formatYearReportBase = (getMonthReport) => (report) =>
  R.reduce(
    (reports, monthIndex) =>
      R.compose(
        R.mergeRight(reports),
        R.objOf(monthIndex),
        getMonthReport(monthIndex),
      )(report),
    {},
    R.range(0, 12),
  );

// calculateClientMonthAmounts :: Number -> Report -> MonthReport
const calculateClientMonthAmounts = (monthIndex) =>
  R.compose(
    R.applySpec({
      salesAmount: R.propOr(0, 'sales_amount'),
      wipAmount: R.propOr(0, 'wip_amount'),
      amount: R.converge(R.add, [
        R.propOr(0, 'sales_amount'),
        R.propOr(0, 'wip_amount'),
      ]),
    }),
    R.find((monthReport) => monthReport.month === monthIndex + 1),
  );

// calculateMonthTotalAmounts :: (String, Number) -> Report -> MonthReport
const calculateMonthTotalAmounts = R.curry((path, monthIndex) =>
  R.compose(
    R.objOf('amount'),
    calculateTotal(
      R.compose(
        R.propOr(0, path),
        R.find((monthReport) => monthReport.month === monthIndex + 1),
        R.prop('month_sales_reports'),
      ),
    ),
  ),
);

// extractClientReportRowBase :: (Report -> String, Report -> String, [String], Number -> Report -> MonthReport) -> Report -> YearReport
const extractClientReportRowBase = (
  getTitle,
  getId,
  reportPath,
  calculateMonthAmounts,
) =>
  R.compose(
    R.converge(R.mergeRight, [
      R.applySpec({
        clientName: getTitle,
        _id: getId,
      }),
      formatYearReportBase(calculateMonthAmounts),
    ]),
    R.pathOr([], reportPath),
  );

// getSalesRow :: Report -> YearReport
const getSalesRow = extractClientReportRowBase(
  R.always('Sales Total'),
  R.always(SALES_TOTAL_ROW_ID),
  ['clientsSalesReport', 'sales_reports'],
  calculateMonthTotalAmounts('sales_amount'),
);

// getWipRow :: Report -> YearReport
const getWipRow = extractClientReportRowBase(
  R.always('WIP Total'),
  R.always(WIP_TOTAL_ROW_ID),
  ['clientsSalesReport', 'sales_reports'],
  calculateMonthTotalAmounts('wip_amount'),
);

// generateSalesReportsRows :: ([ClientMonthSalesReport], String) -> [ClientSalesReportRow]
export const generateSalesReportsRows = (salesReports, format) =>
  R.compose(
    R.unnest,
    R.juxt([
      R.compose(
        R.map(
          R.converge(R.mergeRight, [
            R.applySpec({
              clientName: R.path(['client', 'nickName']),
              _id: R.path(['client', '_id']),
            }),
            R.compose(
              formatYearReportBase(calculateClientMonthAmounts),
              R.prop('month_sales_reports'),
            ),
          ]),
        ),
        R.pathOr([], ['clientsSalesReport', 'sales_reports']),
      ),
      R.cond([
        [R.always(format === formats.sales), R.juxt([getSalesRow])],
        [R.always(format === formats.wip), R.juxt([getWipRow])],
        [R.T, R.juxt([getSalesRow, getWipRow])],
      ]),
    ]),
  )(salesReports);

export const ALL_MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

// calculateMonthsTotals :: [ClientSalesReportRow] -> [Number]
export const calculateMonthsTotals = (rows) =>
  R.map(
    R.compose(calculateTotal(R.__, rows), (index) =>
      R.ifElse(isTotalRow, R.pathOr(0, [index, 'amount']), R.always(0)),
    ),
    R.range(0, 12),
  );
