import * as R from 'ramda';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setTimeSheetReport } from './TimeSheetReportReducer.js';

export const useTimeSheetReportFilter = () => {
  const dispatch = useDispatch();

  const timeSheetReport = useSelector((state) => state.timeSheetReport);

  const [ownerId, setOwnerId] = useState('');

  const value = {
    startDate: timeSheetReport?.startDate,
    endDate: timeSheetReport?.endDate,
  };

  const setDateRange = (newValue) =>
    dispatch(
      setTimeSheetReport({
        ...timeSheetReport,
        ...newValue,
      }),
    );

  const setTimeSheetOwner = (ownerIdValue) => {
    dispatch(setTimeSheetReport({ ...timeSheetReport, ownerId: ownerIdValue }));
    setOwnerId(ownerIdValue);
  };

  const setTimeSheetFilter = () =>
    dispatch(
      setTimeSheetReport({
        ...timeSheetReport,
        filteredConfig: R.omit(['filteredConfig'], timeSheetReport),
      }),
    );

  return {
    value,
    ownerId,
    setDateRange,
    setTimeSheetOwner,
    setTimeSheetFilter,
  };
};
