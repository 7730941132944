import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { useModalContext, useNotificationState } from '@poly/admin-ui';
import { InvoicesStatuses, InvoiceTypes } from '@poly/constants';
import { PopoverLink } from '@poly/admin-book';

const voidSupplierInvoiceFormId = 'voidSupplierInvoiceFormId';

const voidProjectInvoiceMutation = gql`
  mutation VOID_PROJECT_INVOICE_MUTATION($id: ID!) {
    voidInvoice(id: $id) {
      id
    }
  }
`;

const voidSupplierInvoiceMutation = gql`
  mutation VOID_SUPPLIER_INVOICE_MUTATION($id: ID!) {
    voidSupplierInvoice(id: $id) {
      id
    }
  }
`;

// isNotCreditCardOrBankExpenseInvType :: String -> Boolean
export const isNotCreditCardOrBankExpenseInvType = R.complement(
  R.includes(R.__, [InvoiceTypes.bankExpense, InvoiceTypes.creditCardExpense]),
);

export function VoidSupplierInvoiceButton({ _id, type, status }) {
  const { openConfirmSubmitFormModal } = useModalContext();
  const { showSuccessNotification } = useNotificationState();
  const [voidInvoice] = useMutation(voidProjectInvoiceMutation);
  const [voidSupplierInvoice] = useMutation(voidSupplierInvoiceMutation);

  const voidInvoiceMutation =
    type === InvoiceTypes.supplier ? voidSupplierInvoice : voidInvoice;

  const onClick = () =>
    openConfirmSubmitFormModal({
      btnCaption: 'Yes, Void',
      processId: voidSupplierInvoiceFormId,
      content: 'Are you sure you want to void this invoice?',
      onConfirm: (closeConfirmModal) => async () => {
        await voidInvoiceMutation({ variables: { id: _id } });
        showSuccessNotification('Invoice was successfully voided');
        closeConfirmModal();
      },
    });

  const isVoidActionEnabled =
    status === InvoicesStatuses.PENDING ||
    status === InvoicesStatuses.REPORT_ONLY;

  return (
    isVoidActionEnabled &&
    isNotCreditCardOrBankExpenseInvType(type) && (
      <PopoverLink {...{ onClick }}>Void Invoice</PopoverLink>
    )
  );
}

VoidSupplierInvoiceButton.propTypes = {
  type: string,
  _id: string.isRequired,
  status: string.isRequired,
};
