import * as R from 'ramda';

const CLEAR_INVOICES_ACTION = 'CLEAR_INVOICES_ACTION';
const TOGGLE_PROJECT_SELECTION_ACTION = 'TOGGLE_PROJECT_SELECTION_ACTION';
const SELECT_ALL_PROJECTS_ACTION = 'SELECT_ALL_PROJECTS_ACTION';

export const createClearInvoicesAction = () => ({
  type: CLEAR_INVOICES_ACTION,
});

export const createToggleProjectSelectionAction = (project) => ({
  type: TOGGLE_PROJECT_SELECTION_ACTION,
  project,
});

export const createSelectAllProjectsAction = (
  projects,
  isUnselectAll = false,
) => ({
  type: SELECT_ALL_PROJECTS_ACTION,
  projects,
  isUnselectAll,
});

// unselectAllProjects :: BatchEntriesStore -> StoreObject
// BatchEntriesStore = {[ID]: {isSelected: Bool}}
const unselectAllProjects = R.map(R.assoc('isSelected', false));

export function batchClientInvoicingReducer(state, action) {
  let projectId;

  switch (action.type) {
    case CLEAR_INVOICES_ACTION:
      return {};

    case TOGGLE_PROJECT_SELECTION_ACTION:
      projectId = action.project._id;
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          project: action.project,
          isSelected: !R.pathOr(false, [projectId, 'isSelected'], state),
        },
      };

    case SELECT_ALL_PROJECTS_ACTION:
      if (action.isUnselectAll) {
        return unselectAllProjects(state);
      }

      return action.projects.reduce(
        (acc, project) => ({
          ...acc,
          [project._id]: {
            ...acc[project._id],
            project,
            isSelected: true,
          },
        }),
        state,
      );

    default:
      return state;
  }
}
