import {
  bool,
  func,
  shape,
  number,
  object,
  string,
  arrayOf,
  oneOfType,
} from 'prop-types';
import * as R from 'ramda';
import styled from 'styled-components';
import React, { useContext } from 'react';
import { propEqLegacy } from '@poly/utils';

import { BaseSelect } from './BaseSelect.js';
import { ModalContext } from '../Modal/Modal.js';
import { ColumnDirection } from '../lib/ColumnDirection.js';
import { LabelS, BottomLabelS } from '../InputLabel/index.js';
import { InputErrorMsg, InputWarningMsg } from '../Input/index.js';
import { getThemeColor, getThemeFont } from '../utils.js';

// we need `key` prop with `value` to clear input on value change :facepalm:
// https://stackoverflow.com/questions/50412843/how-to-programmatically-clear-reset-react-select
const SelectComp = React.forwardRef((props, ref) => (
  <BaseSelect
    key={`uniq_select_key_${R.prop('value', props)}`}
    styles={{ menuPortal: (base) => ({ ...base, zIndex: 10002 }) }}
    menuPortalTarget={document.body}
    menuPosition="absolute"
    ref={ref}
    {...props}
  />
));

// getSelectBorderColor :: StylesFormatterProps -> String
const getSelectBorderColor = R.cond([
  [
    R.either(R.prop('error'), R.prop('hasError')),
    R.always(getThemeColor(['secondaryMid'])),
  ],
  [
    R.prop('warning'),
    R.always(getThemeColor(['notificator', 'warning', 'text'])),
  ],
  [R.T, R.always(getThemeColor(['midLight']))],
]);

const SelectCompS = styled(SelectComp)`
  .select__control,
  .select__control:hover,
  .select__control:focus,
  .select__control--is-focused {
    min-height: ${({ size }) => (size === 'small' ? '26px' : '30px')};
    box-shadow: unset;
    border: 1px solid ${getSelectBorderColor};
  }

  .select__indicator-separator {
    display: none;
  }

  .select__clear-indicator {
    padding: ${({ size }) => (size === 'small' ? '5px' : 'inherit')};
    padding-right: 0;
  }

  .select__dropdown-indicator {
    padding: 0 10px 0 3px;
  }

  .select__dropdown-indicator > svg {
    height: 13px;
    width: 13px;
    fill: ${getThemeColor(['black'])};
  }

  .select__clear-indicator > svg {
    height: 14px;
    width: 14px;
    fill: ${getThemeColor(['secondary'])};
    stroke: ${getThemeColor(['white'])};
    stroke-width: 1;
  }

  .select__value-container {
    padding: ${({ size }) => (size === 'small' ? '0 8px' : '2px 8px')};
  }

  .select__placeholder {
    font-size: ${({ size }) => (size === 'small' ? '10px' : '12px')};
    line-height: ${({ size }) => (size === 'small' ? '12px' : '18px')};
    font-weight: ${getThemeFont(['regular'])};
    color: ${getThemeColor(['midDark'])};
  }

  .select__menu {
    font-size: 12px;
    min-width: 100px;
    color: ${getThemeColor(['primary'])};
    z-index: 1000 !important;
  }

  .select__group-heading {
    font-size: 100%;
  }

  .select__group > div > .select__option {
    padding-left: 20px;
  }

  .select__option {
    word-break: break-word;
  }

  .select__single-value {
    font-size: ${({ size }) => (size === 'small' ? '10px' : '12px')};
    line-height: ${({ size }) => (size === 'small' ? '12px' : '18px')};
    overflow: initial;
  }

  .select__option--is-focused {
    background-color: ${getThemeColor(['secondaryLighter2'])};
  }

  .select__option--is-selected {
    background-color: ${getThemeColor(['primaryLight'])};
  }

  .select__input {
    font-size: 12px;
  }

  width: 100%;
  margin: 0;
  min-width: 0;
`;

const BaseSelectComp = React.forwardRef((props, ref) => (
  <>
    <SelectCompS ref={ref} classNamePrefix="select" {...props} />
    {!!R.prop('error', props) && !R.prop('withoutErrorMessage', props) && (
      <InputErrorMsg>{R.prop('error', props)}</InputErrorMsg>
    )}
    {!!R.prop('warning', props) && (
      <InputWarningMsg>{R.prop('warning', props)}</InputWarningMsg>
    )}
  </>
));

// getStyles :: StylesFormatterProps -> Object
// StylesFormatterProps = {
//   required: Boolean
//   error: String
//   hasError: Boolean
//   value: String
//   loading: Boolean
//   options: [Option]
// }
const getStyles = ({ required, error, hasError, value, loading, options }) => {
  const isSelectRequired =
    required && !hasError && !error && R.isEmpty(value) && !R.is(Number, value);
  const withRequiredStyles = isSelectRequired
    ? {
        boxShadow: 'inset 0px -2px 0 #dd6262 !important',
      }
    : {};

  return {
    dropdownIndicator: (base) => ({
      ...base,
      display: loading ? 'none' : 'flex',
    }),
    menuPortal: (base) => ({
      ...base,
      display: R.isEmpty(options) ? 'none' : 'block',
    }),
    control: (base) => ({ ...base, ...withRequiredStyles }),
  };
};

// mergeGroupsOptions :: [Option] -> [Option]
const mergeGroupsOptions = R.compose(
  R.reject(R.propSatisfies(R.isNil, 'value')),
  R.flatten,
  R.map(R.juxt([R.identity, R.propOr([], 'options')])),
);

// findValueByOptions :: Any -> [Option] -> Any
const findValueByOptions = (value) =>
  R.compose(R.find(propEqLegacy('value', value)), mergeGroupsOptions);

// prepareValue :: ValueFormatterProps -> Option
// ValueFormatterProps = {
//   options: [Option]
//   value: String
//   activeOptionText: String
// }
const prepareValue = ({ options, value, activeOptionText }) => {
  if (activeOptionText) {
    return { value, label: activeOptionText };
  }

  return findValueByOptions(value)(options);
};

export const Select = React.forwardRef((props, ref) => {
  const {
    width,
    error,
    label,
    options,
    loading,
    disabled,
    onChange,
    required,
    hasError,
    value = '',
    bottomLabel,
    isClearable,
    name = 'Select',
    placeholder = '',
    activeOptionText,
    direction = 'down',
    openMenuOnFocus = true,
    allowModalOverflow = false,
    hideSelectedOptions = true,
    ...rest
  } = props;

  const { setModalOverflow } = useContext(ModalContext);

  const menuPlacementDirectionMap = { up: 'top', down: 'bottom' };

  const commonSetModalOverflow = (overflowValue) => {
    if (setModalOverflow) {
      setModalOverflow(overflowValue);
    }
  };

  const overflowProps = {
    onMenuOpen: () => commonSetModalOverflow('unset'),
    onMenuClose: () => commonSetModalOverflow('auto'),
  };

  const modifiedSelectProps = {
    ...rest,
    name,
    error,
    options,
    required,
    hasError,
    placeholder,
    openMenuOnFocus,
    isLoading: loading,
    hideSelectedOptions,
    isDisabled: disabled,
    menuPortalTarget: null,
    isClearable: isClearable || false,
    'aria-label': bottomLabel || label || name,
    menuPlacement: menuPlacementDirectionMap[direction],
    value: prepareValue({ options, value, activeOptionText }),
    onChange: (option) => onChange(option ? option.value : '', option),
    styles: getStyles({
      required,
      error,
      hasError,
      value,
      loading,
      options,
    }),
    ...(allowModalOverflow ? overflowProps : {}),
  };

  return label || bottomLabel ? (
    <ColumnDirection width={width}>
      {label ? <LabelS>{label}</LabelS> : null}
      <BaseSelectComp ref={ref} {...modifiedSelectProps} />
      {bottomLabel ? <BottomLabelS>{bottomLabel}</BottomLabelS> : null}
    </ColumnDirection>
  ) : (
    <BaseSelectComp width={width} ref={ref} {...modifiedSelectProps} />
  );
});

const optionPropTypes = shape({
  label: oneOfType([string, number]),
  value: oneOfType([string, number, bool, object]),
});

Select.displayName = 'Select';
Select.propTypes = {
  name: string,
  width: string,
  label: string,
  error: string,
  loading: bool,
  disabled: bool,
  required: bool,
  hasError: bool,
  direction: string,
  isClearable: bool,
  showResetBtn: bool,
  arePropsReady: bool,
  bottomLabel: string,
  placeholder: string,
  openMenuOnFocus: bool,
  activeOptionText: string,
  allowModalOverflow: bool,
  hideSelectedOptions: bool,
  onChange: func.isRequired,
  options: arrayOf(optionPropTypes),
  value: oneOfType([string, number, bool, object]),
};
