import * as R from 'ramda';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRouterQuery } from '@poly/client-routing';

import { projectUIStatuses } from '../../modules/core/constants/projects.js';
import { useSearchRecurringProjectsByQuery } from '../../modules/tables/projectsTable/ProjectsTable.js';
import { generateSearchMasterProjectsQueryByFilter } from './masterProjectsDirectoryUtils.js';
import { recurringQueriesByEndDate } from '../../modules/tabs/common.js';

// getStatusByTab :: String -> String
const getStatusByTab = R.unless(
  R.equals(projectUIStatuses.ACTIVE),
  R.always(projectUIStatuses.PAST_DUE),
);

export const useMasterProjectsQueryByFilter = () => {
  const { tab } = useRouterQuery(['tab']);
  const filter = useSelector((state) => state.searchFilters.projectsFilter);

  const currentTab = tab || projectUIStatuses.ACTIVE;

  const query = useMemo(
    () =>
      filter
        ? generateSearchMasterProjectsQueryByFilter(filter)
        : recurringQueriesByEndDate[currentTab],
    [filter, currentTab],
  );

  const { result } = useSearchRecurringProjectsByQuery(query);

  const count = useMemo(
    () => R.pathOr(0, ['searchRecurringProjects', 'total'], result),
    [result],
  );

  const status = useMemo(
    () => (filter ? null : getStatusByTab(currentTab)),
    [currentTab, filter],
  );

  return { count, result, status };
};
