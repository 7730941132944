import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import { formatSelectOptions } from '@poly/admin-ui';
import { AssetStatuses } from '@poly/constants';
import { Select } from '@poly/admin-book';

const disabledAssetStatuses = [AssetStatuses.PENDING, AssetStatuses.BLOCKED];

export function AssetStatusSelect({ value, ...props }) {
  const disabled = R.any(R.equals(value), disabledAssetStatuses);

  const preparedStatuses = disabled
    ? AssetStatuses
    : R.without(disabledAssetStatuses, AssetStatuses);

  const options = formatSelectOptions(preparedStatuses);

  const selectProps = {
    ...props,
    value,
    options,
    disabled,
    name: 'AssetStatusSelect',
  };

  return <Select {...selectProps} />;
}

AssetStatusSelect.propTypes = { value: string };
