import React from 'react';
import * as R from 'ramda';
import { entityToOptionByLabelPath } from '@poly/client-utils';
import { Select } from '@poly/admin-book';

import { useBillingProfilesQueryByInput } from './useBillingProfilesQueryByInput.js';

// getBillingProfileSelectOptions :: ID -> BillingProfilesQueryResult -> [Option]
const getBillingProfileSelectOptions = R.compose(
  R.map(entityToOptionByLabelPath(['name'])),
  R.propOr([], 'billingProfiles'),
);

export function BillingProfileSelect(props) {
  const { data, loading } = useBillingProfilesQueryByInput();

  const options = getBillingProfileSelectOptions(data);

  const selectProps = {
    ...props,
    loading,
    options,
    isClearable: true,
    placeholder: 'Start typing billing profile',
  };

  return <Select {...selectProps} />;
}
