import { useEffect } from 'react';
import { debounce, tryCallFunction } from '@poly/utils';
import { useQuery, useSubscription } from '@apollo/client';
import { useSelector } from 'react-redux';
import { paginationToQueryParams, initialPagination } from './pagination.js';

export const extractSearchQueryOptionsFromProps = ({
  pagination = initialPagination,
  query: elasticQuery,
  sort = {},
  searchText,
  searchTextForQuery,
  additionalVars = {},
  graphQLOptions = {},
  fetchPolicy = 'cache-first',
}) => ({
  variables: {
    ...additionalVars,
    searchInput: {
      sort,
      searchTerm: searchText || searchTextForQuery,
      query: elasticQuery,
      ...paginationToQueryParams(pagination),
    },
  },
  ...graphQLOptions,
  fetchPolicy,
});

const useEntitiesBySearchWithCustomQuery = ({
  gqlSearchQuery,
  additionalVars = {},
  sort = {},
  searchText = '',
  searchTextForQuery = '',
  pagination = initialPagination,
  query = null,
  graphQLOptions = {},
  skipQuery = false,
  fetchPolicy = 'network-only',
  propsOfComponent = {},
  alias = 'useEntitiesBySearchWithCustomQuery',
}) => {
  const searchInput = {
    sort,
    searchTerm: searchText || searchTextForQuery,
    query,
    ...paginationToQueryParams(pagination),
  };

  const {
    data,
    loading,
    refetch,
    fetchMore,
    subscribeToMore,
    updateQuery,
    previousData,
    error,
  } = useQuery(gqlSearchQuery, {
    variables: {
      ...additionalVars,
      searchInput,
    },
    ...graphQLOptions,
    fetchPolicy,
    skip: skipQuery,
    alias,
  });

  const result = {
    ...data,
    ...propsOfComponent,
    searchInput,
    previousData,
    additionalVars,
    sort,
    searchText,
    searchTextForQuery,
    pagination,
    query,
    graphQLOptions,
    fetchPolicy,
    skipQuery,
    error,
    fetchMore,
    subscribeToMore,
    updateQuery,
    refetch,
  };

  return {
    loading,
    refetch,
    result,
  };
};

export const useSubscriptionByChanges = ({
  gqlQueryChanged,
  extractQueryParamsFromProps = extractSearchQueryOptionsFromProps,
  debounceTime = 2000,
  refetch,
  result,
  skipQuery = false,
}) => {
  const debounceUpdateQueryFn = debounce(debounceTime)(() =>
    tryCallFunction(refetch)(),
  );

  const handleRefetchFn = () => {
    if (!skipQuery) {
      debounceUpdateQueryFn();
    }
  };

  useEffect(() => {
    handleRefetchFn();
  }, [skipQuery]);

  useSubscription(gqlQueryChanged, {
    ...extractQueryParamsFromProps(result),
    onData: handleRefetchFn,
  });
};

export const useEntitiesByReactiveSearch = ({
  gqlSearchQuery,
  gqlSearchChangedQuery,
  searchText = '',
  searchTextForQuery = '',
  pagination = {},
  sort = {},
  query = null,
  fetchPolicy = 'network-only',
  propsOfComponent = {},
  additionalVars = {},
  skipQuery = false,
  alias = 'useEntitiesByReactiveSearch',
}) => {
  const { loading, refetch, result } = useEntitiesBySearchWithCustomQuery({
    searchTextForQuery,
    propsOfComponent,
    additionalVars,
    gqlSearchQuery,
    fetchPolicy,
    pagination,
    searchText,
    skipQuery,
    query,
    alias,
    sort,
  });

  useSubscriptionByChanges({
    gqlQueryChanged: gqlSearchChangedQuery,
    skipQuery,
    refetch,
    result,
  });

  return { loading, refetch, result };
};

export const useEntitiesByReactiveReduxSearch = ({
  gqlSearchQuery,
  gqlSearchChangedQuery,
  pagination = {},
  sort = {},
  query = null,
  fetchPolicy = 'network-only',
  propsOfComponent = {},
  additionalVars = {},
  skipQuery = false,
}) => {
  const searchText = useSelector((state) => state.searchText);

  const { loading, refetch, result } = useEntitiesByReactiveSearch({
    gqlSearchQuery,
    gqlSearchChangedQuery,
    searchText,
    pagination,
    sort,
    query,
    fetchPolicy,
    propsOfComponent,
    additionalVars,
    skipQuery,
  });

  return {
    loading,
    refetch,
    result,
  };
};
