export const InvoiceTypes = {
  breakdown: 'breakdown',
  fixed: 'fixed',
  supplier: 'supplierInvoiceType',
  creditCardExpense: 'creditCardExpense',
  bankExpense: 'bankExpense',
};

export const InvoicesStatuses = {
  PENDING: 'pending',
  PARTIALLY_PAID: 'partiallyPaidInvoiceStatus',
  PAID: 'paid',
  VOIDED: 'voidedInvoiceStatus',
  REPORT_ONLY: 'reportOnly',

  // virtual invoice statuses
  REQUESTED: 'requestedInvoiceStatus',
  RECEIVED: 'receivedInvoiceStatus',
  REJECTED: 'rejectedInvoiceStatus',
  MANUAL_FOLLOW_UP: 'manualFollowUpInvoiceStatus',
};

export const NonVirtualInvoiceStatuses = [
  InvoicesStatuses.PENDING,
  InvoicesStatuses.PARTIALLY_PAID,
  InvoicesStatuses.PAID,
  InvoicesStatuses.REPORT_ONLY,
];

export const VirtualInvoiceStatuses = [
  InvoicesStatuses.REQUESTED,
  InvoicesStatuses.RECEIVED,
  InvoicesStatuses.REJECTED,
  InvoicesStatuses.MANUAL_FOLLOW_UP,
];

export const InvoicePOStatuses = {
  pending: 'pending',
  blocked: 'blocked',
  approved: 'approved',
  posted: 'posted',
};

export const REPORT_ONLY_NEGATIVE_INVOICE_TOTAL_ERROR =
  "Report Only project's invoice amount must be positive.";

export const allowedCancelInvoiceStatuses = [
  InvoicesStatuses.REQUESTED,
  InvoicesStatuses.MANUAL_FOLLOW_UP,
  InvoicesStatuses.RECEIVED,
];

export const MAX_OCR_INVOICE_NUMBER_LENGTH = 50;

export const MAX_OCR_INVOICE_DESCRIPTION_LENGTH = 254;

export const PAYMENT_DUE_DATE_CHANGE_REASON_LENGTH_LIMIT = 200;
