import React from 'react';
import { PRINT_TYPES } from '@poly/constants';
import { arrayOf, func, shape, string } from 'prop-types';

import { TablePrintBtn } from '../../modules/core/tableToolbar/TablePrintPDFBtn.js';

export function QuoteDiscrepanciesPdfExportButton({
  tableRows,
  title,
  getTableConfig,
  prepareFooterData,
}) {
  return (
    <TablePrintBtn
      tableRows={tableRows}
      style={{ color: '#436CBE' }}
      printType={PRINT_TYPES.QUOTE_DISCREPANCIES}
      metaData={{ title }}
      getTableConfig={getTableConfig}
      prepareFooterData={prepareFooterData}
    />
  );
}

QuoteDiscrepanciesPdfExportButton.propTypes = {
  tableRows: arrayOf(shape({})),
  getTableConfig: func.isRequired,
  prepareFooterData: func.isRequired,
  title: string.isRequired,
};
