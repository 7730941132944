import * as R from 'ramda';
import React from 'react';
import { applyProp } from '@poly/utils';
import { Loader, IconButton } from '@poly/admin-book';
import { capitalizeFirstLetter } from '@poly/client-utils';
import { useMapConfigToTableProps, useModalContext } from '@poly/admin-ui';
import { arrayOf, bool, shape, string } from 'prop-types';

import { editCCUserFormId, ccUserStatusNamesMap } from './constants.js';
import { CreditCardUsersForm } from './CreditCardUsersForm.js';
import { CCName, CCUserName, CCUsersTableS } from './components.js';

const getCCName = (name) => <CCName>{name}</CCName>;
const getCCUserName = (name) => <CCUserName>{name}</CCUserName>;

function EditButton(props) {
  const { openModalForm } = useModalContext();
  const onClick = () =>
    openModalForm({
      id: editCCUserFormId,
      title: 'Edit Credit Card User',
      formId: editCCUserFormId,
      btnCaption: 'Save',
      content: <CreditCardUsersForm isNewCCUser={false} document={props} />,
    });

  return <IconButton size={15} name="edit" onClick={onClick} />;
}

// getReadableCCUserStatus :: String -> String
const getReadableCCUserStatus = R.prop(R.__, ccUserStatusNamesMap);

const CreditCardUsersPageTableConfig = [
  [
    'User Name',
    R.ifElse(
      R.prop('isCCUser'),
      R.compose(getCCUserName, R.propOr('', 'ccUserName')),
      R.compose(getCCName, R.propOr('', 'ccName')),
    ),
  ],
  ['Card Number', R.propOr('', 'cardNumber')],
  [
    'Status',
    R.compose(
      capitalizeFirstLetter,
      applyProp(getReadableCCUserStatus)('status'),
    ),
  ],
  ['', R.ifElse(R.prop('isCCUser'), EditButton, R.always(''))],
];

export function CreditCardUsersPageTable({ loading, ccUsersByTab }) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    CreditCardUsersPageTableConfig,
    ccUsersByTab,
  );

  return loading ? (
    <Loader />
  ) : (
    <CCUsersTableS {...tableProps} isLoading={loading} />
  );
}

CreditCardUsersPageTable.propTypes = {
  loading: bool,
  ccUsersByTab: arrayOf(
    shape({
      _id: string,
      status: string,
      cardNumber: string,
      ccUserName: string,
      ccName: string,
      isCCUser: bool,
    }),
  ),
};
