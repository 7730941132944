import {
  ADMIN_APP_NAME,
  ASSET_SCANNER_APP_NAME,
  CLIENT_PORTAL_APP_NAME,
  MOBILE_APP_NAME,
  SITE_APP_NAME,
  TIAA_PORTAL_APP_NAME,
} from '@poly/security';

export const ClientAppNames = {
  ADMIN: ADMIN_APP_NAME,
  SITE: SITE_APP_NAME,
  MOBILE: MOBILE_APP_NAME,
  TIAA_PORTAL: TIAA_PORTAL_APP_NAME,
  CLIENT_PORTAL: CLIENT_PORTAL_APP_NAME,
  ASSET_SCANNER: ASSET_SCANNER_APP_NAME,
};

export const ClientStatuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PROSPECT: 'prospect',
};

export const ClientFinancialTerms = {
  due_on_receipt: 'due_on_receipt',
  net_10: 'net_10',
  net_25: 'net_25',
  net_30: 'net_30',
  net_45: 'net_45',
  net_60: 'net_60',
  net_90: 'net_90',
  net_120: 'net_120',
  net_180: 'net_180',
};

export const ClientSpendReportTypes = {
  TRANSPARENT: 'transparentSpendReportType',
  NON_TRANSPARENT: 'nonTransparentSpendReportType',
};

export const ClientBranding = {
  POLY: 'poly',
  ESFMX: 'esfmx',
};

export const NON_CLIENT_SELECT_OPTION = 'NON_CLIENT_SELECT_OPTION';

export const ProjectTimeType = {
  rate: 'rate',
  overtime: 'overtime',
  doubleTime: 'doubleTime',
};

export const ProjectTimeTypeUIMap = {
  [ProjectTimeType.rate]: 'Straight Time',
  [ProjectTimeType.overtime]: 'Double Time',
  [ProjectTimeType.doubleTime]: 'Over Time',
};

export const RequesterSurveyQuestionTypes = {
  open: 'openQuestionType',
  yesNo: 'yesNoQuestionType',
  scoring: 'scoringQuestionType',
  submitMethod: 'submitMethodQuestionType',
};

export const RequesterSurveyQuestionTypesUI = {
  [RequesterSurveyQuestionTypes.open]: 'Open',
  [RequesterSurveyQuestionTypes.yesNo]: 'Yes/No',
  [RequesterSurveyQuestionTypes.scoring]: 'Scoring',
  [RequesterSurveyQuestionTypes.submitMethod]: 'Submit Method',
};

export const RequesterSurveyQuestionSubmitMethodOptions = [
  'Email',
  'In Person',
  'Online Form',
  'Phone',
];

export const DefaultRequesterSurveyQuestions = [
  {
    question: 'How was your request submitted?',
    type: RequesterSurveyQuestionTypes.submitMethod,
  },
  {
    question: 'The work I requested was completed to my satisfaction (5-Best)',
    type: RequesterSurveyQuestionTypes.scoring,
  },
  {
    question: 'The work was completed in a timely fashion (5-Best)',
    type: RequesterSurveyQuestionTypes.scoring,
  },
  {
    question: 'Staff completing the request were professional (5-Best)',
    type: RequesterSurveyQuestionTypes.scoring,
  },
  {
    question: 'Staff completing the request were courteous (5-Best)',
    type: RequesterSurveyQuestionTypes.scoring,
  },
  {
    question: 'Additional comments or suggestions',
    type: RequesterSurveyQuestionTypes.open,
  },
];

export const ClientBidRulesTypes = {
  flatFee: 'flatFeeBidRuleType',
  perAsset: 'perAssetBidRuleType',
  jobCostPercentage: 'jobCostPercentageBidRuleType',
};
