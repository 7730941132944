export const supplierInvoiceFormId = 'supplierInvoiceFormId';
export const NO_SUPPLIER_INVOICE_TYPE = 'noSupplierInvoiceType';

export const SupplierInvoiceFormActions = {
  add: 'addFormAction',
  edit: 'editFormAction',
  approve: 'approveFormAction',
  reject: 'rejectFormAction',
};

export const SupplierInvoiceFormSubmitType = {
  requestNewInvoice: 'requestNewInvoiceSubmitType',
  rejectAndComplete: 'rejectAndCompleteSubmitType',
};
