import { useState, useEffect } from 'react';

const loadGoogleMapScriptP = (key) =>
  new Promise((resolve) => {
    const source = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&callback=__onGoogleMapsApiReady`;

    const isScriptExist = !!window.document.querySelector(
      `script[src='${source}']`,
    );

    if (!isScriptExist) {
      window.__onGoogleMapsApiReady = () => {
        delete window.__onGoogleMapsApiReady;
        return resolve();
      };
      const script = window.document.createElement('script');
      script.src = source;
      script.type = 'text/javascript';
      script.async = false;
      window.document.body.appendChild(script);
    } else {
      resolve();
    }
  });

export const useLoadGoogleMapScript = (key) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await loadGoogleMapScriptP(key);
      setLoading(false);
    })();
  }, []);

  return loading;
};
