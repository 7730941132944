import React, { useCallback, useState } from 'react';
import * as R from 'ramda';
import arrayMutators from 'final-form-arrays';
import { func, shape, string } from 'prop-types';
import { usePristineSubscribe } from '@poly/client-routing';
import { Input, useNotificationContext } from '@poly/admin-book';
import { debounce, removePropDeep } from '@poly/utils';
import {
  commonSidebarFormSectionLayout,
  commonSidebarFormFieldLayout,
  useOnSubmitSetStopSubmitting,
  commonSidebarFormLayout,
} from '@poly/admin-ui';
import { DEBOUNCE_USER_INPUT_THRESHOLD } from '@poly/client-utils/src/constants.js';

import { DEFAULT_STEP } from './constants.js';
import { ProcedureFormCreator } from './components.js';
import { ProcedureStepConfigurationLine } from './ProcedureStepConfigurationLine.js';
import { AssetTypeSelect } from '../../sidebars/AssetSidebar/forms/form/components/AssetTypeSelect.js';
import { useAddEditProcedureMutation } from './hooks/useAddEditProcedureMutation.js';
import { useValidateProceduresName } from './hooks/useValidateProceduresName.js';
import { isEditProcedureForm } from './helpers.js';

function ProcedureNameInput({ value, onChange, ...props }) {
  const [internalValue, setInternalValue] = useState(value);

  const onChangeDebounced = useCallback(
    debounce(DEBOUNCE_USER_INPUT_THRESHOLD)((searchTerm) => {
      onChange(searchTerm);
    }),
    [],
  );

  const onChangeInternal = (e) => {
    setInternalValue(e.target.value);
    onChangeDebounced(e.target.value);
  };
  return <Input value={internalValue} onChange={onChangeInternal} {...props} />;
}

ProcedureNameInput.propTypes = {
  value: string,
  onChange: func.isRequired,
};

const procedureFormSections = [
  {
    order: 1,
    label: 'General Information',
    layout: { margin: 0 },
    fields: [
      {
        order: 1,
        required: true,
        label: 'Procedure Name',
        layout: { row: 1, width: '100%' },
        field: {
          name: 'name',
          Component: ProcedureNameInput,
          additionalProps: {
            placeholder: 'Enter Name',
            charactersLimit: 300,
            showCharactersLeft: true,
          },
        },
        validators: [[R.identity, 'Procedure Name is required']],
      },
      {
        order: 2,
        label: 'Asset Types',
        layout: { row: 2, width: '100%' },
        field: {
          name: 'assetTypeIds',
          Component: AssetTypeSelect,
          additionalProps: {
            isMultipleSelect: true,
            handleChange: () => null,
            changeFieldValue: () => null,
            placeholder: 'Enter Asset Type',
          },
        },
      },
      {
        order: 3,
        layout: { row: 3, width: '100%' },
        field: {
          name: 'steps',
          isArrayField: true,
          withChangeFieldValue: true,
          Component: ProcedureStepConfigurationLine,
        },
      },
    ],
  },
];

// prepareEditInitialValues :: Procedure -> FormData
const prepareEditInitialValues = R.compose(
  removePropDeep('__typename'),
  R.applySpec({
    name: R.prop('name'),
    assetTypeIds: R.compose(
      R.map(R.applySpec({ value: R.prop('_id'), label: R.prop('name') })),
      R.propOr([], 'assetTypes'),
    ),
    steps: R.compose(R.prepend({}), R.propOr([], 'steps')),
  }),
);

export function AddEditProcedureForm({ formId, onCancel, procedure }) {
  const validate = useValidateProceduresName(procedure?._id);
  const { showSuccessNotification } = useNotificationContext();
  const pristineSubscribeProps = usePristineSubscribe({ id: formId });
  const { createProcedure, editProcedure } = useAddEditProcedureMutation();

  const isEdit = isEditProcedureForm(formId);

  const onSubmitHandler = async (formData) => {
    const mutate = isEdit ? editProcedure : createProcedure;
    const successMessage = isEdit
      ? 'Procedure edited successfully.'
      : 'New Procedure created successfully.';

    await mutate({ id: procedure?._id, formData });

    showSuccessNotification(successMessage);

    onCancel();
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  const initialValues = isEdit
    ? prepareEditInitialValues(procedure)
    : {
        steps: [{}, DEFAULT_STEP],
      };

  return (
    <ProcedureFormCreator
      id={formId}
      onSubmit={onSubmit}
      mutators={arrayMutators}
      initialValues={initialValues}
      layout={commonSidebarFormLayout}
      fieldLayout={commonSidebarFormFieldLayout}
      sectionLayout={commonSidebarFormSectionLayout}
      sections={procedureFormSections}
      validate={validate}
      keepDirtyOnReinitialize
      {...pristineSubscribeProps}
    />
  );
}

AddEditProcedureForm.propTypes = {
  formId: string.isRequired,
  onCancel: func.isRequired,
  procedure: shape({ _id: string.isRequired }),
};
