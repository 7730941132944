import styled from 'styled-components';
import { getThemeColor } from '@poly/admin-book';

export const UserAlertWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  opacity: ${({ isArchivedDone }) => (isArchivedDone ? 0.5 : 1)};
`;

export const MessageWrapper = styled.div`
  margin-left: 18px;
  flex-grow: 2;
`;

export const MessageWrapperEntry = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MessageTitle = styled.div`
  max-width: 230px;
  margin-bottom: 4px;

  a {
    text-decoration: none;
  }
`;

export const MessageTime = styled.span`
  font-size: 10px;
  line-height: 12px;
  color: ${getThemeColor(['mid'])};
  white-space: nowrap;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
  height: 30px;
`;
