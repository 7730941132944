import React, { useState, useMemo } from 'react';
import { useTableInfiniteScrollQuery } from '@poly/client-utils';

import {
  PrintChecksTable,
  GET_SUPPLIER_CHECKS_QUERY,
} from './PrintChecksTable.js';
import { defaultSearchFilters } from './printChecksConstants.js';
import { PrintChecksPageHeader } from './PrintChecksPageHeader.js';
import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { prepareSupplierChecksFilters } from './printChecksUtils.js';

export function PrintChecksPage() {
  const [filters, setFilters] = useState(defaultSearchFilters);
  const [selectedChecks, setSelectedChecks] = useState([]);

  const input = useMemo(() => prepareSupplierChecksFilters(filters), [filters]);

  const { data, loading, tableProps } = useTableInfiniteScrollQuery(
    GET_SUPPLIER_CHECKS_QUERY,
    input,
    {
      pageSize: 100,
      endpointName: 'supplierChecks',
    },
  );

  return (
    <PageWithSearchHeader headerHeight="180px">
      <PrintChecksPageHeader
        loading={loading}
        setFilters={setFilters}
        setSelectedChecks={setSelectedChecks}
      />
      <PrintChecksTable
        data={data}
        tableProps={tableProps}
        filters={filters}
        selectedChecks={selectedChecks}
        setSelectedChecks={setSelectedChecks}
      />
    </PageWithSearchHeader>
  );
}
