import { useMemo } from 'react';
import * as R from 'ramda';
import { gql } from '@apollo/client';

import { useInfiniteScrollQueryWithSubscription } from '@poly/client-utils';
import { useCurrentUserByStoreOrQuery } from '@poly/client-utils/src/hooks/useCurrentUserByStoreOrQuery.js';
import { useTableSearch } from '@poly/admin-ui/src/redux/search-text.js';
import { ALL } from '@poly/admin-ui/src/constants/general.js';

const SEARCH_TASK_TEMPLATES_SUB = gql`
  subscription SEARCH_TASK_TEMPLATES_SUB($input: CollectionSearchParams!) {
    searchTaskTemplateChanged(input: $input) {
      id
      type
    }
  }
`;

const SEARCH_TASK_TEMPLATES_QUERY = gql`
  query SEARCH_TASK_TEMPLATES_QUERY($input: CollectionSearchParams!) {
    searchTaskTemplates(input: $input) {
      hits {
        _id
        name
        description
        priority

        createdBy {
          _id
          fullName
        }
      }
      total
    }
  }
`;

export const useTaskTemplates = ({ priority, sort }) => {
  const { user } = useCurrentUserByStoreOrQuery();
  const { searchText } = useTableSearch();

  const input = useMemo(
    () => ({
      sort,
      searchTerm: searchText,
      query: priority && priority !== ALL ? { term: { priority } } : null,
    }),
    [priority, user?._id, searchText, sort],
  );

  const { data, loading, tableProps } = useInfiniteScrollQueryWithSubscription(
    SEARCH_TASK_TEMPLATES_QUERY,
    input,
    { endpointName: 'searchTaskTemplates', skip: !user?._id },
    SEARCH_TASK_TEMPLATES_SUB,
    { input },
  );

  const taskTemplates = R.pathOr([], ['searchTaskTemplates', 'hits'], data);

  return {
    taskTemplates,
    loading,
    tableProps,
    searchText,
  };
};
