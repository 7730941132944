import * as R from 'ramda';
import { useSelector } from 'react-redux';
import {
  useScoredSortingWithSearchText,
  paginationToQueryParams,
} from '@poly/client-utils';

export const useMasterSuppliersSearchInput = ({ query, sort }) => {
  const searchText = useSelector(R.prop('searchText'));
  const pagination = useSelector(R.prop('pagination'));

  const modifiedSort = useScoredSortingWithSearchText({ sort, searchText });

  return {
    query,
    sort: modifiedSort,
    searchTerm: searchText,
    ...paginationToQueryParams(pagination),
  };
};
