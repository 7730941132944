import * as R from 'ramda';
import { prefixNumberWithLeadingZeros } from './leading-zeros.js';

// prefixNumberWith2Zeros :: Number -> String
const prefixNumberWith2Zeros = prefixNumberWithLeadingZeros(2);

// minutesToHours :: Number -> Number
export const minutesToHours = R.compose(Math.floor, R.divide(R.__, 60));

// formatTimesheetDuration :: Number -> String
export const formatTimesheetDuration = R.compose(
  R.join('.'),
  R.juxt([
    R.compose(R.concat(R.__, 'h'), prefixNumberWith2Zeros, minutesToHours),
    R.compose(
      R.concat(R.__, 'm'),
      prefixNumberWith2Zeros,
      R.converge(R.subtract, [
        R.identity,
        R.compose(R.multiply(60), minutesToHours),
      ]),
    ),
  ]),
);
