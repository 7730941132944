import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { commonSortQuery } from '@poly/client-utils';
import { arrayOf, shape, bool } from 'prop-types';
import { convertCentsToDollars, formatTotal, propEqLegacy } from '@poly/utils';
import { NOTHING_UI_STRING, ProjectSupplierStatus } from '@poly/constants';

import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import { SupplierLink } from '../../components/Links.js';

const SuppliersColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

// getPrintSuppliersColumnData :: [Supplier] -> String
const getPrintSuppliersColumnData = R.compose(
  R.join(', '),
  R.map(R.path(['company', 'name'])),
);

function SuppliersColumn({ suppliers, isPrintPDF }) {
  if (suppliers.length === 0) {
    return NOTHING_UI_STRING;
  }

  if (isPrintPDF) {
    return getPrintSuppliersColumnData(suppliers);
  }

  return (
    <SuppliersColumnWrapper>
      {suppliers.map((supplier) => {
        const name = supplier?.company?.name;
        return (
          <SupplierLink
            {...supplier}
            name={name}
            key={`${supplier._id}_${name}`}
          />
        );
      })}
    </SuppliersColumnWrapper>
  );
}

SuppliersColumn.propTypes = {
  isPrintPDF: bool,
  suppliers: arrayOf(shape({})),
};

export const commonQuoteDiscrepanciesTableConfig = [
  [
    'Discrepancy in %',
    R.compose(
      R.concat(R.__, '%'),
      R.toString,
      R.propOr(0, 'discrepancyPercentage'),
    ),
    commonSortQuery(['discrepancyPercentage']),
  ],
  [
    'Discrepancy',
    R.compose(formatTotal, convertCentsToDollars, R.propOr(0, 'discrepancy')),
    commonSortQuery(['discrepancy']),
  ],
];

export const getTableConfig = (isPrintPDF) => [
  ['Project #', ({ project }) => <ProjectLink {...project} />],
  ['Client Invoice #', R.prop('number')],
  ['Client NTE', R.compose(formatTotal, R.pathOr(0, ['project', 'nte']))],
  [
    'Client Invoice',
    R.compose(formatTotal, convertCentsToDollars, R.pathOr(0, ['amount'])),
  ],
  [
    'Suppliers',
    R.compose(
      SuppliersColumn,
      R.mergeLeft({ isPrintPDF }),
      R.objOf('suppliers'),
      R.filter(propEqLegacy('statusInProject', ProjectSupplierStatus.assigned)),
      R.pathOr([], ['project', 'suppliers']),
    ),
  ],
  ...commonQuoteDiscrepanciesTableConfig,
];
