export const MailToErrors = {
  ClientManagersMissedError: '*Client card is missing Client Managers',
  ClientManagersNoEmailError: '*Client card is missing manager email',
  SupplierMissedError: '*No Supplier assigned',
  SupplierNoEmailError: '*Supplier card is missing an email',
  RequestorError: '*User card is missing Requestor email',
  SiteContactError: '*User card is missing Site Contact email',
  IncorrectEmailError: 'Incorrect email address',
};

export const mailToInitValues = {
  requestor: {
    shouldSend: false,
    emails: [],
  },
  siteContact: {
    shouldSend: false,
    emails: [],
  },
  clientManager: {
    shouldSend: false,
    emails: [],
  },
  supplier: {
    shouldSend: false,
    emails: [],
  },
  other: {
    shouldSend: false,
    emails: [],
  },
};
