import * as R from 'ramda';
import {
  assocBy,
  getUserCellPhoneNumber,
  getUserFaxPhoneNumber,
  getUserWorkPhoneNumber,
  getUserWorkPhoneNumberExt,
  removePropDeep,
} from '@poly/utils';

import { prepareAddressObjectToForm } from '../../../../utils/address.js';

// getUserPhoneOrNull :: (User -> String) -> User -> String | Null
const getUserPhoneOrNull = (getPhone) => R.compose(R.defaultTo(null), getPhone);

// prepareContactDataToForm :: Object -> Object
export const prepareContactDataToForm = R.pipe(
  R.defaultTo({}),
  removePropDeep('__typename'),
  assocBy('workPhone', getUserPhoneOrNull(getUserWorkPhoneNumber)),
  assocBy('ext', getUserPhoneOrNull(getUserWorkPhoneNumberExt)),
  assocBy('mobilePhone', getUserPhoneOrNull(getUserCellPhoneNumber)),
  assocBy('fax', getUserPhoneOrNull(getUserFaxPhoneNumber)),
  assocBy('profile', R.pick(['address', 'addressTwo'])),
  prepareAddressObjectToForm('profile'),
  R.omit([
    '_id',
    'fullName',
    'links',
    'linkedClients',
    'linkedProperties',
    'linkedSuppliers',
    'linkedMasterSuppliers',
    'entity',
    'contact',
    'address',
    'addressTwo',
  ]),
);
