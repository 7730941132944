import * as R from 'ramda';

import {
  expiredProjectsUI,
  projectStatusesUI,
  projectUIStatuses,
  expiringMasterProjectsDays,
} from '../../modules/core/constants/projects.js';

export const MasterProjectsFilterId = 'master-projects-filter-id';

const { EXPIRING30, EXPIRING60, EXPIRING90 } = expiringMasterProjectsDays;
const { ACTIVE, PAST_DUE } = projectUIStatuses;

export const TABS_NAMES = [
  PAST_DUE,
  EXPIRING30,
  EXPIRING60,
  EXPIRING90,
  ACTIVE,
];

export const MasterProjectsTabsStatuses = {
  ...R.pick([projectUIStatuses.ACTIVE], projectStatusesUI),
  ...expiredProjectsUI,
  [projectUIStatuses.PAST_DUE]: 'Expired',
};
