import * as R from 'ramda';
import React, { useState } from 'react';
import { startOfToday, startOfMonth, endOfMonth } from 'date-fns';
import { AccountingMethods } from '@poly/constants';
import {
  Loader,
  DateRangePicker,
  DatePickerButtons,
  DateRangeToButton,
  ToolBarBtnDivider,
} from '@poly/admin-book';
import { alwaysNewDate, ensureIsDate } from '@poly/utils';

import {
  PageWithSearchHeader,
  SearchPageContentWrapper,
  SearchPageHeaderContainer,
} from '../../components/PageWithSearchHeader.js';
import { AccountingMethodSelect } from '../../components/AccountingMethodSelect.js';
import { SearchHeaderCreator } from '../../components/SearchHeaderCreator.js';
import { useGeneralReportQuery } from './useGeneralReportQuery.js';
import { GeneralLedgerPrintBtn } from './GeneralLedgerPrintBtn.js';
import { GeneralLedgerExportXlBtn } from './GeneralLedgerExportXlBtn.js';
import { MultipleAccountsSelect } from '../../modules/accounting/MultipleAccountSelect.js';
import {
  generalLedgerFiltersPropTypes,
  generalLedgerReportPropType,
} from './generalLedgerPropTypes.js';
import { GeneralLedgerTable } from './GeneralLedgerTable.js';
import { convertDateToDateRange } from './generalLedgerHelpers.js';
import {
  getLastYearDateFilter,
  getThisYearDateFilter,
  getCompassFYearDateFilter,
  getCurrentMonthDateFilter,
  getPreviousMonthDateFilter,
} from '../../components/FiscalYearSelect.js';

function ExportButtonsContainer({ reportData, generalLedgerFilters }) {
  return (
    <>
      <GeneralLedgerPrintBtn
        generalLedgerFilters={generalLedgerFilters}
        reportData={reportData}
        disabled={reportData.entries.length === 0}
      />
      <ToolBarBtnDivider />
      <GeneralLedgerExportXlBtn
        generalLedgerFilters={generalLedgerFilters}
        reportData={reportData}
        disabled={reportData.entries.length === 0}
      />
    </>
  );
}

ExportButtonsContainer.propTypes = {
  reportData: generalLedgerReportPropType,
  generalLedgerFilters: generalLedgerFiltersPropTypes,
};

function DateRangePickerComponent(props) {
  const customRangeButtons = {
    ...DatePickerButtons,
    THIS_MONTH: 'Current Month',
    LAST_MONTH: 'Previous Month',
    THIS_YEAR: 'This Calendar Year',
    COMPASS_FISCAL_YEAR: "Compass' Fiscal Year",
    LAST_FISCAL_YEAR: 'Last Calendar Year',
  };

  const getCustomRangeToButtons = () => ({
    ...DateRangeToButton,
    [customRangeButtons.THIS_MONTH]: {
      from: getCurrentMonthDateFilter(true, startOfToday())(),
      to: getCurrentMonthDateFilter(false, startOfToday())(),
    },
    [customRangeButtons.LAST_MONTH]: {
      from: getPreviousMonthDateFilter(true, startOfToday())(),
      to: getPreviousMonthDateFilter(false, startOfToday())(),
    },
    [customRangeButtons.THIS_YEAR]: {
      from: getThisYearDateFilter(true, startOfToday())(),
      to: getThisYearDateFilter(false, startOfToday())(),
    },
    [customRangeButtons.COMPASS_FISCAL_YEAR]: {
      from: getCompassFYearDateFilter(true, startOfToday())(),
      to: getCompassFYearDateFilter(false, startOfToday())(),
    },
    [customRangeButtons.LAST_FISCAL_YEAR]: {
      from: getLastYearDateFilter(true, startOfToday())(),
      to: getLastYearDateFilter(false, startOfToday())(),
    },
  });

  const customProps = { customRangeButtons, getCustomRangeToButtons };

  return (
    <DateRangePicker {...props} {...customProps} rangeStyles="width: 90px" />
  );
}

const generalLedgerSearchHeaderConfig = (
  currentDate,
  generalLedgerFilters,
  reportData,
) => [
  {
    order: 1,
    key: 'glCodeRow',
    rowLayout: { justifyContent: 'flex-start', columnWidth: '50%' },
    columns: [
      {
        name: 'accountIds',
        title: 'G/L Code / Name',
        columnLayout: { filterWidth: '476px' },
        filterProps: { width: '100%', changeOnBlur: true },
        column: 1,
        Component: (props) => (
          <MultipleAccountsSelect {...props} maxHeight="50px" />
        ),
      },
      {
        name: 'accountingMethod',
        defaultValue: AccountingMethods.ACCRUAL_BASIS,
        title: 'Type',
        filterProps: { width: '100%' },
        column: 1,
        Component: AccountingMethodSelect,
      },
      {
        name: 'date',
        nestedFields: ['startDate', 'endDate'],
        title: 'Date',
        columnLayout: { titleWidth: '80px' },
        defaultValue: {
          startDate: startOfMonth(ensureIsDate(currentDate)),
          endDate: endOfMonth(ensureIsDate(currentDate)),
        },
        column: 2,
        filterProps: { width: '100%' },
        Component: DateRangePickerComponent,
      },
      {
        name: 'export',
        columnLayout: {
          filterWidth: '100px',
          margin: 'auto',
        },
        column: 3,
        filterProps: {
          reportData,
          generalLedgerFilters,
        },
        Component: ExportButtonsContainer,
      },
    ],
  },
];

const defaultReportData = {
  entries: [],
  debits_total: 0,
  credits_total: 0,
};

export function GeneralLedgerPage() {
  const [generalLedgerFilters, setGeneralLedgerFilters] = useState({});
  const { data, loading } = useGeneralReportQuery(generalLedgerFilters);

  const onSearch = (filters) => {
    setGeneralLedgerFilters(convertDateToDateRange(filters));
  };

  const reportData = R.propOr(defaultReportData, 'generalLedgerReport', data);

  const onReset = () => setGeneralLedgerFilters({});

  return (
    <PageWithSearchHeader headerHeight="155px">
      <SearchPageHeaderContainer title="General Ledger">
        <SearchHeaderCreator
          config={generalLedgerSearchHeaderConfig(
            alwaysNewDate(),
            generalLedgerFilters,
            reportData,
          )}
          onSearch={onSearch}
          onReset={onReset}
          loading={loading}
        />
      </SearchPageHeaderContainer>
      <SearchPageContentWrapper>
        {loading ? (
          <Loader />
        ) : (
          <GeneralLedgerTable
            reportData={reportData}
            generalLedgerFilters={generalLedgerFilters}
          />
        )}
      </SearchPageContentWrapper>
    </PageWithSearchHeader>
  );
}
