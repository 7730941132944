import React from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';
import { alwaysNewDate } from '@poly/utils';
import {
  PageHeaderContainer,
  DefaultBodyWrapper,
  MainHeader,
  WhiteCard,
  Loader,
} from '@poly/admin-book';

import { MasterSupplierAPReportList } from './MasterSupplierAPReportList.js';
import { PrintMasterSupplierAPReportBtn } from './PrintMasterSupplierAPReportBtn.js';
import { useMasterSupplierOpeningInvoicesQuery } from './useMasterSupplierOpepingInvoicesQuery.js';

const MasterSupplierAPReportWhiteCard = styled(WhiteCard)`
  max-height: none;
`;

export function MasterSupplierAPReportPage() {
  const { loading, listConfig, masterSupplierName } =
    useMasterSupplierOpeningInvoicesQuery();

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>AP Report</MainHeader>
        {`As of ${format(alwaysNewDate(), 'MMMM dd, yyyy')}`}
        <PrintMasterSupplierAPReportBtn
          {...{ listConfig, masterSupplierName }}
        />
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <MasterSupplierAPReportWhiteCard>
          {loading ? (
            <Loader />
          ) : (
            <MasterSupplierAPReportList
              masterSupplierName={masterSupplierName}
              listConfig={listConfig}
            />
          )}
        </MasterSupplierAPReportWhiteCard>
      </DefaultBodyWrapper>
    </>
  );
}
