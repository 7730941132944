import React from 'react';
import { string, shape } from 'prop-types';
import { keywordSortQuery } from '@poly/client-utils';
import { ASC_SORT_ORDER } from '@poly/constants';
import { gql, useQuery } from '@apollo/client';

import { PropertySelect } from '../../../../../modules/selects/PropertySelect/PropertySelect.js';

const isNewAssetPropertyValidQuery = gql`
  query isNewAssetPropertyValidQuery($input: IsNewAssetPropertyValidInput!) {
    isNewAssetPropertyValid(input: $input)
  }
`;

export function AssetPropertySelect({ formData, value, ...props }) {
  const { clientId, showClient, _id, isEdit } = formData;

  const { data } = useQuery(isNewAssetPropertyValidQuery, {
    variables: { input: { assetId: _id, propertyId: value } },
    skip: !isEdit || !value || !_id,
  });

  const disabled = showClient && !clientId;

  const warning =
    data?.isNewAssetPropertyValid === false
      ? "This asset is linked to open projects. Changing its property will make it different from the projects' properties."
      : '';

  const selectorProps = {
    disabled,
    value,
    warning,
    fetchSize: 1000,
    withoutSkip: true,
    isClearable: true,
    includeAllOption: false,
    additionalSearchParams: {
      sort: keywordSortQuery(['name'])(ASC_SORT_ORDER),
      ...(clientId ? { query: { term: { clientId } } } : {}),
    },
  };

  return <PropertySelect {...props} {...selectorProps} />;
}

AssetPropertySelect.propTypes = {
  formData: shape({ clientId: string }).isRequired,
  value: string,
};
