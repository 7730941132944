import React from 'react';
import * as R from 'ramda';
import { bool, shape } from 'prop-types';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useModalContext, useNotificationState } from '@poly/admin-ui';
import { FormCreator, Loader } from '@poly/admin-book';

import { usePristineSubscribe } from '@poly/client-routing';
import { getFormCreatorSections } from './getFormSections.js';
import {
  addAccountFormId,
  editAccountFormId,
  glCodeTypes,
} from '../constants.js';
import {
  accountPropType,
  defaultChartOfAccountsPropTypes,
} from '../chartOfAccountsPropTypes.js';

const createSuccessMessage = 'Account was created successfully';
const editSuccessMessage = 'Account was edited successfully';

const GET_ACCOUNT_QUERY = gql`
  query GET_ACCOUNT_QUERY($input: SingleAccountInput!) {
    getSingleAccount(input: $input) {
      _id
      isParentWithTransactions
    }
  }
`;

const CREATE_ACCOUNT_MUTATION = gql`
  mutation CREATE_ACCOUNT($input: AccountCreateInput!) {
    createAccount(input: $input) {
      _id
    }
  }
`;

const UPDATE_ACCOUNT_MUTATION = gql`
  mutation UPDATE_ACCOUNT($id: ID!, $input: AccountUpdateInput!) {
    updateAccount(id: $id, input: $input) {
      _id
    }
  }
`;

// getInitialValues :: Account -> FormData
const getInitialValues = R.applySpec({
  glCodeType: R.ifElse(
    R.prop('isChild'),
    R.always(glCodeTypes.child),
    R.always(glCodeTypes.parent),
  ),
  name: R.prop('name'),
  code: R.prop('code'),
  accountTypeId: R.path(['accountType', '_id']),
  divisionId: R.path(['division', '_id']),
  status: R.prop('status'),
  parentAccountId: R.path(['parentAccount', '_id']),
  state: R.prop('state'),
  isTaxAccount: R.compose(R.complement(R.isNil), R.prop('state')),
  compass_gl_code: R.prop('compass_gl_code'),
  compass_gl_code_description: R.prop('compass_gl_code_description'),
});

const defaultPickFields = [
  'name',
  'state',
  'compass_gl_code',
  'compass_gl_code_description',
  'accountTypeId',
  'divisionId',
];

const newAccountPickFields = [
  ...defaultPickFields,
  'parentAccountId',
  'type',
  'code',
];

// getMutationVariables :: (ID, Boolean, FormData) -> AccountCreateInput
// FormData = {
//    glCodeType: String,
//    name: String,
//    code: String,
//    accountTypeId: ID,
//    divisionId: ID,
//    status: String,
//    parentAccountId: ID,
// }
const getMutationVariables = (accountId, isNewAccount, input) =>
  R.compose(
    R.objOf('variables'),
    R.assoc('id', accountId),
    R.objOf('input'),
    R.reject(R.anyPass([R.isEmpty, R.isNil])),
    R.ifElse(
      () => isNewAccount,
      R.pick(newAccountPickFields),
      R.pick([...defaultPickFields, 'status']),
    ),
  )(input);

export function AccountForm({
  parentAccounts,
  isNewAccount,
  document,
  ...props
}) {
  const accountId = R.propOr(null, '_id', document);

  const { data, loading } = useQuery(GET_ACCOUNT_QUERY, {
    variables: { input: { accountId } },
    skip: isNewAccount,
  });

  const isParentWithTransactions = R.path(
    ['getSingleAccount', 'isParentWithTransactions'],
    data,
  );

  const formId = isNewAccount ? addAccountFormId : editAccountFormId;
  const successMessage = isNewAccount
    ? createSuccessMessage
    : editSuccessMessage;
  const submitMutation = isNewAccount
    ? CREATE_ACCOUNT_MUTATION
    : UPDATE_ACCOUNT_MUTATION;

  const { showSuccessNotification } = useNotificationState();

  const { closeModal } = useModalContext();
  const [accountMutation] = useMutation(submitMutation);

  const onSubmit = (input) =>
    accountMutation(getMutationVariables(accountId, isNewAccount, input)).then(
      () => {
        showSuccessNotification(successMessage);
        closeModal(formId);
      },
    );

  const pristineSubscribeProps = usePristineSubscribe();

  if (loading) {
    return <Loader />;
  }

  return (
    <FormCreator
      {...props}
      id={addAccountFormId}
      formId={addAccountFormId}
      sections={getFormCreatorSections({
        parentAccounts,
        isNewAccount,
        ...document,
        isParentWithTransactions,
      })}
      initialValues={getInitialValues(document)}
      onSubmit={onSubmit}
      onCancel={closeModal}
      successMessage={successMessage}
      {...pristineSubscribeProps}
    />
  );
}

AccountForm.propTypes = {
  isNewAccount: bool.isRequired,
  document: shape(accountPropType),
  ...defaultChartOfAccountsPropTypes,
};
