import * as R from 'ramda';
import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { AccountingMethods } from '@poly/constants';
import { DateRangePicker, Loader, ToolBarBtnDivider } from '@poly/admin-book';
import { endOfDay, startOfDay, startOfYear } from 'date-fns';
import { alwaysNewDate, ensureIsDate } from '@poly/utils';
import styled from 'styled-components';

import {
  PageWithSearchHeader,
  SearchPageHeaderContainer,
  SearchPageConditionalWrapper,
} from '../../components/PageWithSearchHeader.js';
import { TrialBalanceTable } from './TrialBalanceTable.js';
import { TrialBalancePrintBtn } from './TrialBalancePrintBtn.js';
import { FlexContainer } from '../../components/FlexContainer.js';
import { TrialBalanceExportXlBtn } from './TrialBalanceExportBtn.js';
import { SearchHeaderCreator } from '../../components/SearchHeaderCreator.js';
import { AccountingMethodSelect } from '../../components/AccountingMethodSelect.js';
import {
  trialBalanceFiltersPropTypes,
  trialBalanceReportPropTypes,
} from './trialBalancePropTypes.js';

// prepareTrialBalanceInput :: Object -> TrialBalanceFilters
const prepareTrialBalanceInput = R.compose(
  R.omit(['exportBtn']),
  R.evolve({
    startDate: R.compose(startOfDay, ensureIsDate),
    endDate: R.compose(endOfDay, ensureIsDate),
  }),
  R.omit(['date']),
  R.converge(R.mergeRight, [R.prop('date'), R.identity]),
);

// getRenderingCondition :: (Object | Undefined | Null) -> Bool
const getRenderingCondition = R.compose(
  R.complement(R.identity),
  R.either(R.isNil, R.isEmpty),
);

const TrialBalanceExportButtonsContainerS = styled(FlexContainer)`
  width: 100%;
  justify-content: flex-end;
  margin-top: 5px;
`;

function TrialBalanceExportButtons({ trialBalanceReport, filters }) {
  return (
    <TrialBalanceExportButtonsContainerS>
      <TrialBalancePrintBtn
        trialBalanceReport={trialBalanceReport}
        filters={filters}
      />
      <ToolBarBtnDivider />
      <TrialBalanceExportXlBtn
        trialBalanceReport={trialBalanceReport}
        filters={filters}
      />
    </TrialBalanceExportButtonsContainerS>
  );
}

TrialBalanceExportButtons.propTypes = {
  trialBalanceReport: trialBalanceReportPropTypes,
  filters: trialBalanceFiltersPropTypes,
};

const getDefaultFilterValues = () => ({
  accountingMethod: AccountingMethods.ACCRUAL_BASIS,
  date: {
    startDate: startOfYear(alwaysNewDate()),
    endDate: alwaysNewDate(),
  },
});

const trialBalanceReportPageHeaderConfig = (trialBalanceReport, filters) => {
  const defaultFilterValues = getDefaultFilterValues();
  return [
    {
      key: 'typeRow',
      columns: [
        {
          name: 'accountingMethod',
          title: 'Type',
          column: 1,
          Component: AccountingMethodSelect,
          defaultValue: defaultFilterValues.accountingMethod,
          columnLayout: { titleWidth: '80px' },
          filterProps: {
            width: '100%',
          },
        },
        {
          name: 'exportBtn',
          column: 2,
          relatedFilters: ['accountingMethod', 'date'],
          Component: TrialBalanceExportButtons,
          filterProps: {
            width: '100%',
            trialBalanceReport,
            filters,
          },
        },
      ],
    },
    {
      key: 'dateRow',
      rowLayout: { justifyContent: 'flex-start' },
      columns: [
        {
          name: 'date',
          nestedFields: ['startDate', 'endDate'],
          title: 'Date',
          columnLayout: { titleWidth: '80px' },
          defaultValue: {
            startDate: defaultFilterValues.date.startDate,
            endDate: defaultFilterValues.date.endDate,
          },
          column: 2,
          filterProps: { width: '100%' },
          Component: DateRangePicker,
        },
      ],
    },
  ];
};

// eslint-disable-next-line import/no-unused-modules
export const trialBalanceQuery = gql`
  query trialBalanceReport($filters: TrialBalanceFilters) {
    trialBalanceReport(filters: $filters) {
      entries {
        account {
          _id
          code
          name
        }
        forward_balance
        debits
        credits
        ending_balance
      }
      current_year_earnings
      debits_total
      credits_total
    }
  }
`;

export function TrialBalancePage() {
  const [filters, setFilters] = useState(null);

  const { data, loading } = useQuery(trialBalanceQuery, {
    variables: {
      filters: prepareTrialBalanceInput(filters),
    },
    fetchPolicy: 'network-only',
    skip: !filters,
  });

  const trialBalanceReport = R.propOr(null, 'trialBalanceReport', data);
  const filtersForExport = filters || getDefaultFilterValues();
  const renderingCondition = getRenderingCondition(trialBalanceReport);

  return (
    <PageWithSearchHeader headerHeight="155px">
      <SearchPageHeaderContainer title="Trial Balance">
        <SearchHeaderCreator
          config={trialBalanceReportPageHeaderConfig(
            trialBalanceReport,
            filtersForExport,
          )}
          onSearch={setFilters}
          loading={loading}
          searchBtnTitle="Filter"
        />
      </SearchPageHeaderContainer>
      {loading ? (
        <Loader />
      ) : (
        <SearchPageConditionalWrapper condition={renderingCondition}>
          <TrialBalanceTable
            trialBalanceReport={trialBalanceReport}
            filters={filters}
          />
        </SearchPageConditionalWrapper>
      )}
    </PageWithSearchHeader>
  );
}
