import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { bool, number } from 'prop-types';
import { LinkButton, moneyColumnStyles } from '@poly/admin-book';
import { openPrintWindowWithData } from '@poly/client-utils';
import { isNilOrEmpty } from '@poly/utils';
import {
  useMapConfigToTableProps,
  useNotificationState,
  CommonPrintLayout,
} from '@poly/admin-ui';
import { PRINT_PDF_CAPTION } from '@poly/constants';

import { Supplier1099ReportTableComp } from './supplier1099ReportFilterComponents.js';
import { supplier1099ReportTableConfig } from './supplier1099ReportUtils.js';
import { filteredRowsPropTypes } from './Supplier1099ReportExportBtn.js';

const Supplier1099ReportTableCompForPrint = styled(Supplier1099ReportTableComp)`
  th,
  td {
    width: auto;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 90px;
  }
  th:nth-child(3),
  td:nth-child(3) {
    width: 40px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 90px;
  }

  ${moneyColumnStyles(11, 110)};
  ${moneyColumnStyles(12, 110)};
  ${moneyColumnStyles(13, 110)};
`;

const ReportSubtitle = styled.div`
  text-align: center;
`;

export function PrintSupplier1099ReportBtn({ disabled, year, filteredRows }) {
  const { showErrorNotification } = useNotificationState();

  // here we get headers and columns for print table
  const { headers, columns } = useMapConfigToTableProps(
    R.identity,
    supplier1099ReportTableConfig,
    [],
  );

  const onClick = async () => {
    if (isNilOrEmpty(filteredRows)) {
      showErrorNotification('No report records to print');
      return;
    }

    await openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: Supplier1099ReportTableCompForPrint,
      metaData: {
        title: 'Supplier 1099 Report',
        additional: <ReportSubtitle>{year}</ReportSubtitle>,
      },
      fileName: 'supplier_1099_report',
      rows: filteredRows,
      headers,
      columns,
    });
  };

  return (
    <LinkButton onClick={onClick} disabled={disabled}>
      {PRINT_PDF_CAPTION}
    </LinkButton>
  );
}

PrintSupplier1099ReportBtn.propTypes = {
  disabled: bool,
  year: number,
  filteredRows: filteredRowsPropTypes,
};
