import React from 'react';
import { string } from 'prop-types';
import { NOTHING_UI_STRING, FoodbuyVendorTypeUI } from '@poly/constants';

export function FoodBuyVendorType({ vendorType }) {
  return <span>{FoodbuyVendorTypeUI[vendorType] || NOTHING_UI_STRING}</span>;
}

FoodBuyVendorType.propTypes = {
  vendorType: string,
};
