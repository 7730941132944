import React, { useMemo, useCallback } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { bool, object, oneOf, shape, string } from 'prop-types';
import { LinkButton, Table } from '@poly/admin-book';
import { taskCollections } from '@poly/constants';

import { forceTitleCase } from '@poly/utils';
import {
  SidebarTabTitle,
  SidebarTabHeader,
  SidebarTabWrapper,
  SidebarTabTableWrapper,
} from '../sidebarTabComponents.js';
import { AddTaskForm } from '../../../modules/forms/taskForm/add/index.js';
import { CommonSidebarFormWrapper } from '../../CommonSidebarFormWrapper.js';
import { useTasksTabLogic } from './useTasksTabLogic.js';
import {
  SidebarWrapperForTableForm,
  useSidebarTableFormContext,
  FormPortalAnchor,
} from '../../SidebarWrapperForTableForm.js';
import { ApplyTaskTemplateButton } from '../../../taskTemplate/ApplyTaskTemplateButton.js';

const addTaskFormId = 'add_task_form_id';

const SidebarTabWrapperS = styled(SidebarTabWrapper)`
  form {
    height: unset;
  }
`;

const TasksTable = styled(Table)`
  thead {
    display: none;
  }

  tbody {
    height: 100%;
    vertical-align: middle;
  }

  tr {
    margin: 0;
    width: 100%;
    padding: 0 17px 0 14px;

    :last-child td {
      padding-bottom: 10px;
    }
  }

  td {
    height: 42px;
  }

  td:nth-child(2),
  th:nth-child(2) {
    width: 145px;
  }

  td:nth-child(3),
  th:nth-child(3) {
    width: 100px;
  }

  td:nth-child(4),
  th:nth-child(4) {
    width: 25px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

function AddTaskButton({ addTaskPayload }) {
  const { formSetter } = useSidebarTableFormContext();

  const onAddTask = () =>
    formSetter({
      id: addTaskPayload._id,
      Content: (
        <CommonSidebarFormWrapper
          skipMargin
          onCancel={addTaskPayload?.onCancel}
        >
          <AddTaskForm {...addTaskPayload} />
        </CommonSidebarFormWrapper>
      ),
    });

  return <LinkButton onClick={onAddTask}>Add Task</LinkButton>;
}

AddTaskButton.propTypes = {
  addTaskPayload: shape({}).isRequired,
};

function SidebarTasksTabContent({
  entity,
  readOnly,
  collection,
  isHQProject,
  tabTitlePrefix,
  additional = {},
}) {
  const titlePrefix = tabTitlePrefix || forceTitleCase(entity.preview);
  const titleByEntity = `${titlePrefix} Tasks`;

  const { tableProps } = useTasksTabLogic(
    {
      entity,
      collection,
      additional,
      isHQProject,
    },
    false,
    readOnly,
  );

  const { formSetter } = useSidebarTableFormContext();

  const onFormCancel = useCallback(() => {
    formSetter(null);
  }, []);

  const addTaskPayload = useMemo(
    () => ({
      _id: entity._id,
      formId: addTaskFormId,
      onCancel: onFormCancel,
      isHQProject,
      payload: {
        entity,
        collection,
        _id: entity._id,
      },
    }),
    [],
  );

  const isProjectTasks = collection === taskCollections.PROJECTS;

  return (
    <SidebarTabWrapperS>
      <SidebarTabHeader>
        <SidebarTabTitle>{titleByEntity}</SidebarTabTitle>
        <ButtonsWrapper>
          {isProjectTasks && (
            <ApplyTaskTemplateButton
              addTaskPayload={addTaskPayload}
              formAnchorId={addTaskPayload._id}
              projectId={entity?.humanReadableId}
            />
          )}
          <AddTaskButton addTaskPayload={addTaskPayload} />
        </ButtonsWrapper>
      </SidebarTabHeader>
      <FormPortalAnchor id={entity._id} />
      <SidebarTabTableWrapper offset={42}>
        <TasksTable {...tableProps} omitColumn={3} />
      </SidebarTabTableWrapper>
    </SidebarTabWrapperS>
  );
}

SidebarTasksTabContent.propTypes = {
  readOnly: bool,
  isHQProject: bool,
  // eslint-disable-next-line react/forbid-prop-types
  additional: object,
  entity: shape({
    _id: string.isRequired,
    name: string.isRequired,
    preview: string.isRequired,
    humanReadableId: string.isRequired,
  }).isRequired,
  collection: oneOf(R.values(taskCollections)).isRequired,
  tabTitlePrefix: string,
};

export function SidebarTasksTab(props) {
  return (
    <SidebarWrapperForTableForm>
      <SidebarTasksTabContent {...props} />
    </SidebarWrapperForTableForm>
  );
}
