import React from 'react';
import { useOutSidebarContext } from '@poly/client-utils';
import { CommonSidebarFormWidth } from '@poly/admin-ui';

import {
  SidebarLayoutS,
  SidebarFormLayoutS,
} from './components/userGroupFormComponents.js';
import { addUserGroupFormId } from '../constants.js';
import { AddUserGroupForm } from './AddUserGroupForm.js';

function AddUserGroupSidebarFrom(props) {
  return (
    <SidebarFormLayoutS
      formId={addUserGroupFormId}
      submitButtonCaption="Save"
      title="Add New User Group"
    >
      <AddUserGroupForm {...props} />
    </SidebarFormLayoutS>
  );
}

export const useOpenAddUserGroupSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();

  return (formProps) =>
    openOutSidebar({
      alwaysFirst: true,
      id: addUserGroupFormId,
      Layout: SidebarLayoutS,
      width: CommonSidebarFormWidth,
      content: <AddUserGroupSidebarFrom {...formProps} />,
    });
};
