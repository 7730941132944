import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { initialPagination } from '@poly/client-utils';

import { setPagination } from '../redux/pagination.js';

export const useSetInitialPaginationOnMount = (newPagination) => {
  const dispatch = useDispatch();

  const paginationOnMount = newPagination || initialPagination;

  useEffect(() => {
    dispatch(setPagination(paginationOnMount));
    return () => {
      dispatch(setPagination(initialPagination));
    };
  }, []);
};
