import * as R from 'ramda';
import { ensureIsDate } from '@poly/utils';
import { differenceInMinutes } from 'date-fns';
import { isManualTotalTimesheet } from '@poly/constants';

import {
  isValidTimesheetEntry,
  constructDatePropsForTimeEntry,
} from './common.js';

// getTimesheetDurationInMinutes :: Timesheet -> Number
export const getTimesheetDurationInMinutes = R.compose(
  R.ifElse(
    isManualTotalTimesheet,
    R.propOr(0, 'totalTimeInMinutes'),
    R.compose(
      R.reduce(R.add, 0),
      R.map(
        R.converge(differenceInMinutes, [
          R.compose(ensureIsDate, R.prop('timeOut')),
          R.compose(ensureIsDate, R.prop('timeIn')),
        ]),
      ),
      R.filter(isValidTimesheetEntry),
      R.map(constructDatePropsForTimeEntry),
      R.propOr([], 'entries'),
    ),
  ),
);
