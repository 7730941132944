import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState } from 'react';
import { string, func, oneOfType, object, arrayOf } from 'prop-types';
import { getThemeColor, getThemeProp, TextButton } from '@poly/admin-book';
import { openPrintWindowWithData } from '@poly/client-utils';
import { PRINT_PDF_CAPTION } from '@poly/constants';

export const CommonOuterButton = styled(TextButton)`
  color: ${getThemeColor(['midDark'])};
  transition: color ${getThemeProp(['defaultTransition'])};

  :hover,
  :focus {
    color: ${getThemeColor(['primaryRegular'])};
  }
`;

export function CommonTablePrintBtn({ btnName, data, style, ...props }) {
  const [isLoading, setIsLoading] = useState(false);

  const onClick = () => {
    if (isLoading) return;
    setIsLoading(true);
    openPrintWindowWithData({ data, ...props }).then(() => setIsLoading(false));
  };

  const isDisabled = R.isEmpty(data);

  return (
    <CommonOuterButton onClick={onClick} disabled={isDisabled} style={style}>
      {btnName || PRINT_PDF_CAPTION}
    </CommonOuterButton>
  );
}

CommonTablePrintBtn.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: object,
  btnName: string,
  fileName: string,
  Layout: func.isRequired,
  printType: string.isRequired,
  openWarningModal: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: oneOfType([object, arrayOf(object)]),
  Table: oneOfType([func, object]).isRequired,
};

CommonTablePrintBtn.displayName = 'CommonTablePrintBtn';
