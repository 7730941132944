import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { shape, string, bool } from 'prop-types';
import { CommonSidebarFormWidth, entities } from '@poly/admin-ui';
import { CREATE_CONTACT_USER_PERMISSION } from '@poly/security';
import { useOutSidebarContext } from '@poly/client-utils';
import { TextButton } from '@poly/admin-book';
import { useRouterQuery } from '@poly/client-routing/src/hooks.js';

import { setCreatedContact } from '../../../../redux/contactSidebarReducer.js';
import { useOnCloseFormSidebar } from '../../../useOnCloseFormSidebar.js';
import { useSidebarLogicContext } from '../../../SidebarLogicContext.js';
import { AddContactFrom, addContactFormId } from './AddContactFrom.js';

export const useOpenAddContactForm = (isCard) => {
  const onClose = useOnCloseFormSidebar(isCard);
  const { openOutSidebar } = useOutSidebarContext();

  const onCloseAddContactForm = (entity) => (contactId) =>
    isCard
      ? onClose(entities.CONTACT, contactId)
      : onClose(entity.name, entity._id);

  const { sidebarTab } = useRouterQuery(['sidebarTab']);

  return ({ entity, ...props }) =>
    openOutSidebar({
      alwaysFirst: true,
      id: addContactFormId,
      width: CommonSidebarFormWidth,
      content: (
        <AddContactFrom
          entity={entity}
          isCardLayout={false}
          formId={addContactFormId}
          onClose={onCloseAddContactForm(entity)}
          sidebarTab={sidebarTab}
          {...props}
        />
      ),
    });
};

export function AddContactButton({
  isCard,
  entity,
  isInternational,
  isClientContact,
}) {
  const createdContact = useSelector((state) => state.createdContact);
  const { openContactSidebar } = useSidebarLogicContext();
  const { isSidebarOpened } = useOutSidebarContext();
  const dispatch = useDispatch();

  const formAlreadyClosed = !isSidebarOpened(addContactFormId);

  const openAddContactForm = useOpenAddContactForm(isCard);

  useEffect(() => {
    if (createdContact && formAlreadyClosed) {
      openContactSidebar(createdContact);
      dispatch(setCreatedContact(null));
    }
  }, [createdContact, formAlreadyClosed]);

  const onClick = () =>
    openAddContactForm({
      entity,
      isInternational,
      isClientContact,
    });

  return (
    <TextButton
      onClick={onClick}
      permissions={[CREATE_CONTACT_USER_PERMISSION]}
    >
      Add New
    </TextButton>
  );
}

AddContactButton.propTypes = {
  entity: shape({
    _id: string.isRequired,
    name: string.isRequired,
  }),
  isInternational: bool,
  isClientContact: bool,
  isCard: bool,
};
