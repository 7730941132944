import { SupplierStatuses, SupplierTypes } from '@poly/constants';

/**
 * getSuppliersQuery :: (String, [String]) -> (String, [String]) -> ElasticQuery
 */
export const getSuppliersQuery =
  (source, types) =>
  (status, excludeIds = []) => ({
    bool: {
      must: [
        { terms: { type: types } },
        { term: { status } },
        { term: { 'misc.source': source } },
      ],
      must_not: { terms: { _id: excludeIds } },
    },
  });

export const omitAdministrativeAndBlockedSuppliersQuery = {
  bool: {
    must_not: [
      { match: { status: SupplierStatuses.BLOCKED } },
      { match: { type: SupplierTypes.ADMINISTRATIVE } },
    ],
  },
};
