import * as R from 'ramda';
import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { propEqLegacy } from '@poly/utils';

// getExemptSalesTax :: ProjectQueryResult -> Boolean
const getExemptSalesTax = R.pathOr(false, ['project', 'exemptSalesTax']);

// getSupplierNTE :: ID -> ProjectQueryResult -> Float
const getSupplierNTE = (supplierId) =>
  R.compose(
    R.prop('nte'),
    R.find(propEqLegacy('_id', supplierId)),
    R.pathOr([], ['project', 'suppliers']),
  );

const PROJECT_INFO_QUERY = gql`
  query PROJECT_INFO_QUERY($id: ID!) {
    project(id: $id) {
      _id
      exemptSalesTax
      suppliers {
        _id
        nte
      }
    }
  }
`;

export const useProjectInfoByIdQuery = (projectId, supplierId) => {
  const { data } = useQuery(PROJECT_INFO_QUERY, {
    variables: { id: projectId },
    skip: !projectId,
  });

  const exemptSalesTax = useMemo(() => getExemptSalesTax(data), [data]);
  const supplierNTE = useMemo(() => getSupplierNTE(supplierId)(data), [data]);

  return { exemptSalesTax, supplierNTE };
};
