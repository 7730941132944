import * as R from 'ramda';
import React, { useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { UserEmployeeInfoStatus, ClientStatuses } from '@poly/constants';
import { FilePicker, Select, Input } from '@poly/admin-book';
import { REQUESTS_APP_NAME } from '@poly/security';
import {
  getAdminUsersByEmploymentStatusQuery,
  formatSelectOptions,
  PhoneNumberInput,
  WebsiteFormInput,
  AddressSection,
  UserSelect,
} from '@poly/admin-ui';
import {
  commonFileValidators,
  validateFilesFunc,
  addressValidators,
  equalsOmitFields,
  validatePhone,
  ifNotEmpty,
} from '@poly/client-utils';
import { commonEmailValidators } from '@poly/client-utils/src/formValidators.js';
import { validateURL } from '@poly/utils/src/validators.js';
import { propEqLegacy } from '@poly/utils';

// getAllowedClientStatuses :: Client -> Boolean
const getAllowedClientStatuses = R.ifElse(
  R.either(R.prop('enableChildAccounts'), R.prop('parentClient')),
  R.compose(
    R.reject(R.equals(ClientStatuses.PROSPECT)),
    R.always(ClientStatuses),
  ),
  R.always(ClientStatuses),
);

// getClientStatusSelectWarningMessage :: FormData -> String
const getClientStatusSelectWarningMessage = R.ifElse(
  R.both(
    R.compose(R.includes(REQUESTS_APP_NAME), R.propOr([], 'apps')),
    propEqLegacy('status', ClientStatuses.INACTIVE),
  ),
  R.always("All client's properties Web Forms will be disabled"),
  R.always(''),
);

function ClientStatusSelect({ formData, ...props }) {
  const allowedStatuses = getAllowedClientStatuses(formData);
  const options = formatSelectOptions(allowedStatuses);

  useEffect(() => {
    if (
      formData.enableChildAccounts &&
      formData.status === ClientStatuses.PROSPECT
    ) {
      props.onChange(null);
    }
  }, [formData.enableChildAccounts]);

  const warningMsg = getClientStatusSelectWarningMessage(formData);

  return <Select {...props} warning={warningMsg} options={options} />;
}

ClientStatusSelect.propTypes = {
  formData: shape({
    enableChildAccounts: bool,
    parentClient: shape({
      _id: string,
    }),
  }),
  onChange: func.isRequired,
};

function ClientPhoneNumberInput({ formData, ...props }) {
  return <PhoneNumberInput {...props} required={!formData.isChildClient} />;
}

ClientPhoneNumberInput.propTypes = {
  formData: shape({
    isChildClient: bool,
  }),
};

function ContractFilePicker({ ...props }) {
  return <FilePicker multiple={false} {...props} />;
}

export const accountNameFormField = {
  label: 'Account Name',
  field: {
    name: 'name',
    Component: (props) => <Input {...props} showCharactersLeft />,
  },
  validators: [[R.identity, 'Name is required']],
};

export const nickNameFormField = {
  label: 'Nick Name',
  field: {
    name: 'nickName',
    Component: Input,
  },
  validators: [[R.identity, 'Nick Name is required']],
  required: true,
};

export const statusFormField = {
  label: 'Status',
  field: {
    name: 'status',
    Component: ClientStatusSelect,
    withFormData: true,
  },
  required: true,
  validators: [[R.identity, 'Status is required']],
};

export const addressFormField = {
  field: {
    name: 'address',
    Component: AddressSection,
    isEqual: equalsOmitFields([['formatted_address']]),
  },
  validators: addressValidators,
  required: true,
};

export const phoneFormField = {
  label: 'Phone',
  field: {
    name: 'phone',
    Component: ClientPhoneNumberInput,
    withFormData: true,
  },
  validators: [
    [ifNotEmpty(validatePhone), 'Phone is not correct'],
    [R.identity, 'Phone is required'],
  ],
  required: true,
};

export const faxFormField = {
  label: 'Fax',
  field: {
    name: 'fax',
    Component: PhoneNumberInput,
  },
  validators: [[ifNotEmpty(validatePhone), 'Fax is not correct']],
};

export const websiteFormField = {
  label: 'Website',
  field: {
    name: 'website',
    Component: WebsiteFormInput,
  },
  validators: [
    [ifNotEmpty(validateURL), 'URL should be in form http(s)://example.com'],
  ],
};

export const clientContractFormField = {
  label: 'Client Contract',
  field: {
    name: 'contractFile',
    Component: ContractFilePicker,
  },
  validators: commonFileValidators,
  validateFunction: validateFilesFunc,
};

export const managerAACFormField = {
  label: 'Assigned CSR',
  field: {
    name: 'managerId',
    withFormData: true,
    Component: (props) => (
      <UserSelect
        withoutSkip
        placeholder="Start typing manager"
        query={getAdminUsersByEmploymentStatusQuery(
          UserEmployeeInfoStatus.ACTIVE,
        )}
        {...props}
      />
    ),
  },
};

export const clientGroupMailFormField = {
  label: 'Client Group Email',
  field: {
    name: 'clientGroupEmail',
    withFormData: true,
    Component: Input,
  },
  validators: commonEmailValidators,
};
