import React from 'react';
import styled from 'styled-components';
import { shape, string } from 'prop-types';
import { gql, useQuery } from '@apollo/client';

import { LinkButton } from '@poly/admin-book';
import { useNotificationState, CommonPrintLayout } from '@poly/admin-ui';
import { PRINT_PDF_CAPTION } from '@poly/constants';

import { openPrintWindowWithData } from '@poly/client-utils';
import { formatDate } from '@poly/utils';
import { ClientInvoiceHistoryTable } from './ClientInvoiceHistoryTable.js';
import { clientInvoicesPropType } from './prop-types.js';

const PrintClientInvoicesPDFBtnS = styled(LinkButton)`
  width: 90px;
`;

const AdditionalInfoWrapper = styled.h4`
  text-align: center;
`;

const clientNameAndCardNumberQuery = gql`
  query CLIENT_NAME_QUERY($id: ID!) {
    client(id: $id) {
      _id
      name
      cardNumber
    }
  }
`;

const LabelAndValueContainer = styled.div`
  margin-bottom: 5px;
  margin-left: 40px;
`;

const LabelContainer = styled.strong`
  margin-right: 5px;
`;

function LabelAndValue({ label, value }) {
  return (
    <LabelAndValueContainer>
      <LabelContainer>{`${label}:`}</LabelContainer>
      <span>{value}</span>
    </LabelAndValueContainer>
  );
}

LabelAndValue.propTypes = {
  label: string.isRequired,
  value: string.isRequired,
};

function ClientInvoicesPDFExportTable({ client, ...tableProps }) {
  return (
    <>
      <AdditionalInfoWrapper>
        As of {formatDate(new Date())}
      </AdditionalInfoWrapper>
      <LabelAndValue label="Client Name" value={client.name} />
      <LabelAndValue label="Client Code" value={client.cardNumber} />
      <ClientInvoiceHistoryTable {...tableProps} />
    </>
  );
}

ClientInvoicesPDFExportTable.propTypes = {
  client: shape({ name: string.isRequired, cardNumber: string.isRequired })
    .isRequired,
};

export function InvoiceHistoryExportPDFBtn({ clientInvoices, clientId }) {
  const { showErrorNotification } = useNotificationState();
  const { data } = useQuery(clientNameAndCardNumberQuery, {
    variables: { id: clientId },
    skip: !clientId,
  });

  const onPrintPDFClick = async () => {
    if (clientInvoices.length === 0) {
      showErrorNotification('No records to export');
      return;
    }

    await openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: ClientInvoicesPDFExportTable,
      fileName: 'client_invoice_history',
      metaData: {
        title: 'Client Invoice History',
      },
      client: data?.client,
      clientInvoices,
    });
  };

  return (
    <PrintClientInvoicesPDFBtnS disabled={!clientId} onClick={onPrintPDFClick}>
      {PRINT_PDF_CAPTION}
    </PrintClientInvoicesPDFBtnS>
  );
}

InvoiceHistoryExportPDFBtn.propTypes = {
  clientInvoices: clientInvoicesPropType.isRequired,
  clientId: string,
};
