import React, { useMemo } from 'react';
import * as R from 'ramda';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import styled from 'styled-components';
import { gql } from '@apollo/client';
import {
  SidebarTabHeader,
  SidebarTabTitle,
  SidebarTabWrapper,
} from '@poly/admin-ui/src/sidebar/tabs/sidebarTabComponents.js';
import { LinkButton } from '@poly/admin-book/src/LinkButton/index.js';
import { ToolBarBtnDivider } from '@poly/admin-book/src/Table/styles.js';
import { useMapConfigToTableProps } from '@poly/admin-ui/src/hooks/useMapConfigToTableProps.js';
import { WindowedTable } from '@poly/admin-book/src/Table/WindowedTable.js';
import { Table } from '@poly/admin-book/src/Table/Table.js';
import {
  applyPathOrNothingUI,
  commonSortQuery,
  keywordSortQuery,
  openPrintWindowWithData,
  useInfiniteScrollQueryWithSubscription,
} from '@poly/client-utils';
import {
  centsToDollarsWithFormat,
  forceTitleCase,
  formatDate,
} from '@poly/utils';
import { FlexContainer } from '@poly/admin-ui/src/components/common.js';
import { Loader } from '@poly/admin-book/src/Loader/index.js';
import { Text } from '@poly/admin-book/src/Text/index.js';
import { CommonPrintLayout } from '@poly/admin-ui/src/components/CommonPrintLayout.js';
import { BodyRow } from '@poly/admin-book/src/Table/TableBodyRow.js';
import { Icon } from '@poly/admin-book/src/Icon/index.js';
import { Popover } from '@poly/admin-book/src/Popover/index.js';
import { useTableSorting } from '@poly/admin-ui/src/hooks/useTableSorting.js';
import { PRINT_PDF_CAPTION } from '@poly/constants';

import { defaultPOProjectsSort } from '../constants.js';
import { AddPurchaseOrderProjectButton } from './PurchaseOrderSidebarMastersTab.js';
import { PROJECTS_BY_SEARCH_SUB } from '../../../modules/core/hooks/projects/subscriptions.js';
import { ProjectOccurrence } from '../../../modules/core/constants/projects.js';
import { ProjectLink } from '../../ProjectSidebar/useOpenProjectSidebar.js';
import {
  ProjectStatusDot,
  commonSortWithId,
} from '../../../modules/tables/columns/projects.js';
import { PurchaseOrdersProjectsXLSButton } from './PurchaseOrdersProjectsXLSButton.js';
import { preparePurchaseOrderProjectsTableData } from './preparePurchaseOrderProjectsTableData.js';

const ButtonsWrapper = styled.div`
  display: flex;
  margin-right: 60px;
`;

const SidebarTabHeaderS = styled(SidebarTabHeader)`
  padding: 10px 20px;
`;

const TableS = styled(WindowedTable)`
  height: calc(100vh - 180px);

  th:nth-child(1),
  td:nth-child(1) {
    padding-left: 10px;
    width: 95px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 105px;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 85px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 135px;
  }

  th:nth-child(5),
  td:nth-child(5) {
    padding-right: 10px;
    width: 135px;
  }
`;

const PrintPDFTable = TableS.withComponent(Table);

const searchPurchaseOrderProjectsQuery = gql`
  query searchPurchaseOrderProjectsQuery(
    $searchInput: CollectionSearchParams!
  ) {
    searchProjects(input: $searchInput) {
      hits {
        _id
        projectId
        status
        workCompletionDate
        type
        suggestedClientInvoiceAmount
        createdAt
        clientInvoices {
          _id
          amount
          status
        }
        reportOnlyClientInvoices {
          _id
          amount
          status
        }
      }
      total
    }
  }
`;

const usePurchaseOrderProjects = (_id, initialBalance, sort) => {
  const searchInput = useMemo(
    () => ({
      query: {
        bool: {
          filter: {
            bool: {
              should: [
                {
                  term: {
                    adminPurchaseOrderId: _id,
                  },
                },
                {
                  term: {
                    'parent.adminPurchaseOrderId': _id,
                  },
                },
              ],
            },
          },
        },
      },
      sort,
    }),
    [sort, _id, initialBalance],
  );

  const { data, loading, tableProps } = useInfiniteScrollQueryWithSubscription(
    searchPurchaseOrderProjectsQuery,
    searchInput,
    {
      endpointName: 'searchProjects',
      pageSize: 100,
      inputName: 'searchInput',
    },
    PROJECTS_BY_SEARCH_SUB,
    { searchInput },
    !_id,
  );

  return {
    projects: preparePurchaseOrderProjectsTableData(initialBalance, data),
    loading,
    tableProps,
  };
};

const FlexContainerS = styled(FlexContainer)`
  align-items: center;
  gap: 2px;
`;

function ProjectStatusColumn({ status }) {
  return (
    <FlexContainerS>
      <ProjectStatusDot status={status} />
      <Text>{forceTitleCase(status)}</Text>
    </FlexContainerS>
  );
}

ProjectStatusColumn.propTypes = {
  status: string.isRequired,
};

const PopoverContent = styled.div`
  padding: 10px;
  text-align: start;
  background-color: #12347a;
  color: #fff;
  border-radius: 5px;
`;

const IconS = styled(Icon)`
  path {
    fill: #ffb841;
  }
`;

const TotalTextComp = styled.div`
  margin: 0;
  min-width: 16px;
  padding: 0;
`;

function ProjectPOEstimateWarning({ clientInvoicesTotal }) {
  if (clientInvoicesTotal) {
    return <TotalTextComp />;
  }

  return (
    <Popover
      position="left"
      bgColor="#12347a"
      title={<IconS name="confirm" size={16} />}
      content={
        <PopoverContent>
          The client invoice is estimated based on WIP
        </PopoverContent>
      }
    />
  );
}
ProjectPOEstimateWarning.propTypes = {
  clientInvoicesTotal: number,
};

function ClientInvoicesTotal({
  clientInvoicesTotal,
  suggestedClientInvoiceAmount,
  isPrintPDF,
}) {
  const invTotal = centsToDollarsWithFormat(
    clientInvoicesTotal || suggestedClientInvoiceAmount,
  );

  return (
    <FlexContainerS>
      {!isPrintPDF && (
        <ProjectPOEstimateWarning clientInvoicesTotal={clientInvoicesTotal} />
      )}
      <TotalTextComp>{invTotal}</TotalTextComp>
    </FlexContainerS>
  );
}

ClientInvoicesTotal.propTypes = {
  clientInvoicesTotal: number,
  suggestedClientInvoiceAmount: number,
  isPrintPDF: bool,
};

function RemainingBalanceComp({
  remainingBalance,
  clientInvoicesTotal,
  isPrintPDF,
}) {
  const balance = centsToDollarsWithFormat(remainingBalance);
  return (
    <FlexContainerS>
      {!isPrintPDF && (
        <ProjectPOEstimateWarning clientInvoicesTotal={clientInvoicesTotal} />
      )}
      <TotalTextComp>{balance}</TotalTextComp>
    </FlexContainerS>
  );
}

RemainingBalanceComp.propTypes = {
  clientInvoicesTotal: number,
  remainingBalance: number.isRequired,
  isPrintPDF: bool,
};

// getPOProjectTableRowColorStyle :: Row -> String
const getPOProjectTableRowColorStyle = R.compose(
  R.ifElse(
    R.prop('clientInvoicesTotal'),
    R.always('inherit'),
    R.always('#fbf8ef'),
  ),
  R.prop('row'),
);

const POProjectTableBodyRow = styled(BodyRow)`
  background-color: ${getPOProjectTableRowColorStyle};
`;

const getPurchaseOrdersProjectsTableConfig = (isPrintPDF = false) => [
  [
    'Project #',
    (props) => <ProjectLink {...props} isPrintPDF={isPrintPDF} />,
    keywordSortQuery(['projectId']),
  ],
  ['Status', ProjectStatusColumn, commonSortQuery(['status'])],
  [
    'Comp. Date',
    applyPathOrNothingUI(['workCompletionDate'], formatDate),
    commonSortWithId(commonSortQuery(['workCompletionDate'])),
  ],
  [
    'Client Invoice',
    (props) => <ClientInvoicesTotal {...props} isPrintPDF={isPrintPDF} />,
  ],
  [
    'Remaining Balance',
    (props) => <RemainingBalanceComp {...props} isPrintPDF={isPrintPDF} />,
  ],
];

function PurchaseOrderProjectsSidebarTable({
  projects,
  isPrintPDF,
  ...tableSortingProps
}) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    getPurchaseOrdersProjectsTableConfig(isPrintPDF),
    projects,
  );

  const TableComponent = isPrintPDF ? PrintPDFTable : TableS;

  return (
    <TableComponent
      {...tableSortingProps}
      RowComponent={POProjectTableBodyRow}
      {...tableProps}
    />
  );
}

PurchaseOrderProjectsSidebarTable.propTypes = {
  projects: arrayOf(
    shape({
      projectId: string.isRequired,
    }),
  ),
  isPrintPDF: bool,
};

function PurchaseOrdersProjectsPDFButton({ projects, loading, displayName }) {
  const handleClick = async () => {
    await openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: PurchaseOrderProjectsSidebarTable,
      metaData: {
        title: `PO ${displayName} Projects`,
      },
      fileName: 'po_projects',
      isPrintPDF: true,
      projects,
    });
  };

  return (
    <LinkButton disabled={!projects.length || loading} onClick={handleClick}>
      {PRINT_PDF_CAPTION}
    </LinkButton>
  );
}

PurchaseOrdersProjectsPDFButton.propTypes = {
  projects: arrayOf(
    shape({
      projectId: string.isRequired,
    }),
  ),
  loading: bool,
  displayName: string,
};

export function PurchaseOrderSidebarProjectsTab({
  _id,
  initialBalance,
  client,
  displayName,
}) {
  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: getPurchaseOrdersProjectsTableConfig(false),
    column: 1,
    defaultSort: defaultPOProjectsSort,
  });

  const { projects, loading, tableProps } = usePurchaseOrderProjects(
    _id,
    initialBalance,
    sort,
  );

  return (
    <SidebarTabWrapper>
      <SidebarTabHeaderS>
        <SidebarTabTitle>Projects</SidebarTabTitle>
        <ButtonsWrapper>
          <PurchaseOrdersProjectsPDFButton
            projects={projects}
            loading={loading}
            displayName={displayName}
          />
          <ToolBarBtnDivider />
          <PurchaseOrdersProjectsXLSButton
            projects={projects}
            loading={loading}
          />
          <ToolBarBtnDivider />
        </ButtonsWrapper>
        <AddPurchaseOrderProjectButton
          clientId={client._id}
          type={ProjectOccurrence.ONE_TIME_OCCURRENCE}
          purchaseOrderId={_id}
          client={client}
        />
      </SidebarTabHeaderS>
      {loading ? (
        <Loader />
      ) : (
        <PurchaseOrderProjectsSidebarTable
          projects={projects}
          {...{ ...tableSortingProps, ...tableProps }}
        />
      )}
    </SidebarTabWrapper>
  );
}

PurchaseOrderSidebarProjectsTab.propTypes = {
  client: shape({ _id: string.isRequired }),
  _id: string.isRequired,
  displayName: string.isRequired,
  initialBalance: number.isRequired,
};
