import React from 'react';
import { MainHeader } from '@poly/admin-book';

import { FormCardContainer } from '../../components/FormCardContainer.js';
import { InputPayrollForm, inputPayrollFormId } from './InputPayrollForm.js';
import { routesNames } from '../../routes/index.js';
import { DefaultPageLayout } from '../DefaultPageLayout.js';

export function InputPayrollPage() {
  return (
    <DefaultPageLayout headerContent={<MainHeader>Input Payroll</MainHeader>}>
      <FormCardContainer
        fallbackLink={routesNames.FINANCIAL_DIRECTORY}
        formId={inputPayrollFormId}
        submitBtnLabel="Post Payment"
      >
        <InputPayrollForm />
      </FormCardContainer>
    </DefaultPageLayout>
  );
}
