import styled from 'styled-components';
import { getThemeColor, getThemeFont } from '../utils.js';

export const LabelS = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => marginBottom || '8px'};
  ${({ labelPosition }) => (labelPosition === 'left' ? 'margin: 0 10px;' : '')};
  font-weight: ${getThemeFont(['regular'])};
  font-size: ${({ size = 12 }) => `${size}px`};
  line-height: 18px;
  color: ${({ color = 'primary', ...props }) => getThemeColor([color])(props)};
`;

export const BottomLabelS = styled(LabelS)`
  margin-top: 5px;
`;
