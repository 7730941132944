import { instanceOf, string, number, shape, arrayOf, bool } from 'prop-types';

export const generalLedgerFiltersPropTypes = shape({
  date: shape({ startDate: instanceOf(Date), endDate: instanceOf(Date) }),
  accountCode: string,
  accountingMethod: string,
});

const generalLedgerAccountPropType = shape({
  _id: string.isRequired,
  name: string.isRequired,
  code: string.isRequired,
  sub_account: bool,
});

export const generalLedgerEntryPropType = shape({
  account: generalLedgerAccountPropType.isRequired,
  forward_balance: number,
  debits: number,
  credits: number,
  ending_balance: number,
});

export const generalLedgerReportPropType = shape({
  entries: arrayOf(generalLedgerEntryPropType).isRequired,
  debits_total: number.isRequired,
  credits_total: number.isRequired,
});
