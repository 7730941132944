import { startOfToday } from 'date-fns';

import { projectUIStatuses } from '../core/constants/projects.js';
import {
  entityRecurringQueriesByEndDate,
  getProjectsQueries,
  getRecurringQueries,
} from '../core/utils/projects.js';

const {
  ALL,
  ACTIVE,
  CLOSED,
  ON_HOLD,
  BLOCKED,
  PENDING,
  PAST_DUE,
  DUE_TODAY,
  COMPLETED,
  DUE_TOMORROW,
} = projectUIStatuses;

export const projectsQueries = getProjectsQueries(startOfToday());
export const recurringQueries = getRecurringQueries(startOfToday());
export const recurringQueriesByEndDate = entityRecurringQueriesByEndDate(
  startOfToday(),
);

export const projectsTabsConfig = [
  [DUE_TODAY, 'due-today'],
  [DUE_TOMORROW, 'due-tomorrow'],
  [PAST_DUE, 'past-due'],
  [ON_HOLD, 'on-hold'],
  [PENDING, 'pending'],
  [COMPLETED, 'completed'],
  [BLOCKED, 'blocked'],
  [ACTIVE, 'active'],
];

export const projectsSubTabsConfig = [
  [ALL, 'all'],
  [DUE_TODAY, 'due-today-projects'],
  [DUE_TOMORROW, 'due-tomorrow-projects'],
  [PAST_DUE, 'past-due-projects'],
  [ON_HOLD, 'on-hold-projects'],
  [COMPLETED, 'completed-projects'],
  [BLOCKED, 'blocked-projects'],
  [ACTIVE, 'active-projects'],
];

export const recurringSubTabsConfig = [
  [ALL, 'all'],
  [PAST_DUE, 'past-due-recurring'],
  [CLOSED, 'closed-recurring'],
  [ACTIVE, 'active-recurring'],
];
