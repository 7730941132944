import * as R from 'ramda';
import styled from 'styled-components';
import React, { useEffect, useMemo } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { propEqLegacy, validateEmail } from '@poly/utils';
import { arrayOf, func, shape, string } from 'prop-types';
import { ASSET_SCANNER_APP_NAME } from '@poly/security';
import {
  useNotificationState,
  useOnSubmitSetStopSubmitting,
} from '@poly/admin-ui';
import { getThemeColor, LinkButton, Text } from '@poly/admin-book';

import { addClientUserFormId } from '../constants.js';
import { formatUserInputBase } from '../form/formatUserInput.js';

const findUserContactByEmailQuery = gql`
  query findUserContactByEmailQuery($email: Email!) {
    findUserContactByEmail(email: $email) {
      _id
      isStaff
      profile {
        firstName
        lastName
        workPhoneNumber
        workPhoneNumberExt
        cellPhoneNumber
        cellPhoneNumber
      }
      email
    }
  }
`;

const updateUserWithUserGroupsMutation = gql`
  mutation updateUserWithUserGroupsMutation(
    $update: UpdateUserWithUserGroupsInput!
  ) {
    updateUserWithUserGroups(update: $update) {
      _id
    }
  }
`;

const resendUserEnrollmentEmailMutation = gql`
  mutation resendUserEnrollmentEmailMutation(
    $input: ResendEnrollmentEmailInput!
  ) {
    resendUserEnrollmentEmail(input: $input) {
      sent
    }
  }
`;

const ExistingContactMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const DuplicateMsgTextS = styled(Text)`
  color: ${getThemeColor(['accent1Lighter1'])};
  font-size: 12px;
  margin-right: 10px;
`;

const ActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 40px;
  justify-content: space-between;
`;

const LinkButtonS = styled(LinkButton)`
  font-size: 12px;
`;

// getContact :: { findUserContactByEmail: ContactUser } -> ContactUser
const getContact = R.propOr({}, ['findUserContactByEmail']);

// isUserExists :: ContactUser -> Boolean
const isUserExists = R.propSatisfies(R.is(String), '_id');

// isUserExists :: ContactUser -> Boolean
const isStaffUser = propEqLegacy('isStaff', true);

const formatInput = (userGroupList) =>
  R.compose(formatUserInputBase(true, userGroupList), R.reject(R.isNil));

// isNotAssetScannerUser :: [UserGroup] -> { userGroupId: ID } -> Boolean
const isNotAssetScannerUser = (userGroupList) =>
  R.compose(
    R.complement(propEqLegacy('defaultLoginApp', ASSET_SCANNER_APP_NAME)),
    R.find(propEqLegacy('_id', R.__, userGroupList)),
    R.prop('userGroupId'),
  );

export function ExistingContactMessage({
  onConvertUser,
  changeFieldValue,
  formData,
  userGroupList,
}) {
  const { showSuccessNotification, showWarningNotification } =
    useNotificationState();
  const [updateUser, { loading: updateUserLoading }] = useMutation(
    updateUserWithUserGroupsMutation,
  );
  const [resendUserEnrollmentEmail] = useMutation(
    resendUserEnrollmentEmailMutation,
  );

  const preparedEmail = formData.email;

  const { data, loading } = useQuery(findUserContactByEmailQuery, {
    variables: { email: preparedEmail },
    skip: !validateEmail(preparedEmail),
    fetchPolicy: 'network-only',
  });

  const contact = useMemo(() => getContact(data), [data]);

  useEffect(() => {
    changeFieldValue('contact', contact);
  }, [contact]);

  const notConvertingHandler = () => {
    changeFieldValue('contact', {});
    changeFieldValue('email', '');
  };

  const convertingHandler = async () => {
    const userId = contact._id;

    if (!formData.userGroupId) {
      showWarningNotification('Please select User Group');
      return;
    }

    await updateUser({
      variables: {
        update: {
          userId,
          ...formatInput(userGroupList)(formData),
        },
      },
    });

    onConvertUser(userId);

    if (isNotAssetScannerUser(userGroupList)(formData)) {
      await resendUserEnrollmentEmail({
        variables: { input: { userId } },
      });
    }

    showSuccessNotification('Contact was converted successfully.');
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    addClientUserFormId,
    convertingHandler,
  );

  const userExists = isUserExists(contact);

  return userExists ? (
    <ExistingContactMessageWrapper>
      {isStaffUser(contact) ? (
        <DuplicateMsgTextS>
          This email address is being used by staff user.
        </DuplicateMsgTextS>
      ) : (
        <>
          <DuplicateMsgTextS>
            This email address is being used by contact. Are you sure you want
            to convert it to app user?
          </DuplicateMsgTextS>
          <ActionButtonWrapper>
            {!loading && (
              <>
                <LinkButtonS
                  type="button"
                  onClick={onSubmit}
                  disabled={updateUserLoading}
                >
                  yes
                </LinkButtonS>
                <LinkButtonS
                  type="button"
                  onClick={notConvertingHandler}
                  disabled={updateUserLoading}
                >
                  no
                </LinkButtonS>
              </>
            )}
          </ActionButtonWrapper>
        </>
      )}
    </ExistingContactMessageWrapper>
  ) : null;
}

ExistingContactMessage.propTypes = {
  onConvertUser: func.isRequired,
  changeFieldValue: func,
  userGroupList: arrayOf(shape({})),
  formData: shape({
    email: string,
    clientId: string,
  }),
};
