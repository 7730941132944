import { validateZipCode, ifNotEmpty, ifHasValue } from '@poly/client-utils';
import * as R from 'ramda';
import { isCountryConfigNotHasZipCode } from './utils.js';

export const internationalAddressValidators = [
  [
    R.complement(R.anyPass([R.isNil, R.isEmpty])),
    'Select address or set it manually',
  ],
  [
    R.ifElse(isCountryConfigNotHasZipCode, R.T, ifNotEmpty(validateZipCode)),

    'Zip is required. You can set it manually',
  ],
];

export const internationalAddressNotRequiredValidators = [
  [
    R.ifElse(isCountryConfigNotHasZipCode, R.T, ifHasValue(validateZipCode)),

    'Zip is required. You can set it manually',
  ],
];
