import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { func, string, object } from 'prop-types';
import React, { useState, useCallback } from 'react';
import { ASC_SORT_ORDER, ServiceTypeStatuses } from '@poly/constants';
import { Select } from '@poly/admin-book';
import { debounce } from '@poly/utils';
import {
  entityToOptionByLabelPath,
  keywordSortQuery,
  useReactiveQuery,
} from '@poly/client-utils';

import { SERVICE_TYPES_BY_SEARCH } from '../hocs/serviceTypes/queries.js';
import { SERVICE_TYPES_BY_SEARCH_SUB } from '../hocs/serviceTypes/subscriptions.js';

const SERVICE_TYPE_OPTION_QUERY = gql`
  query SERVICE_TYPE_OPTION_QUERY($id: ID!) {
    serviceType(id: $id) {
      _id
      name
    }
  }
`;

const activeServiceTypeQuery = { term: { status: ServiceTypeStatuses.active } };

// getServiceTypesSelectOptions :: (Object, Object) -> [Option]
const getServiceTypesSelectOptions = (defaultServiceType, data) =>
  R.compose(
    R.map(entityToOptionByLabelPath(['name'])),
    R.when(
      R.always(defaultServiceType),
      R.append(R.prop('serviceType', defaultServiceType)),
    ),
    R.pathOr([], ['searchServiceTypes', 'hits']),
  )(data);

export function ServiceTypesSelect({
  value,
  searchQuery,
  placeholder,
  ...props
}) {
  const [searchText, setSearchText] = useState('');
  const [searchTextForQuery, setSearchTextForQuery] = useState('');

  const setSearchTextForQueryDeb = useCallback(
    debounce(400)((text) => setSearchTextForQuery(text)),
    [],
  );

  const onInputChange = (text) => {
    setSearchText(text);
    setSearchTextForQueryDeb(text);
  };

  const subscriptionOptions = {
    variables: {
      searchInput: {
        searchTerm: searchTextForQuery,
        query: searchQuery || activeServiceTypeQuery,
        size: 1000,
        sort: keywordSortQuery(['name'])(ASC_SORT_ORDER),
      },
    },
  };

  const { data } = useReactiveQuery(
    SERVICE_TYPES_BY_SEARCH,
    SERVICE_TYPES_BY_SEARCH_SUB,
    {
      queryOptions: {
        ...subscriptionOptions,
        fetchPolicy: 'cache-first',
      },
      subscriptionOptions,
    },
  );
  const { data: defaultServiceType } = useQuery(SERVICE_TYPE_OPTION_QUERY, {
    variables: {
      id: value,
    },
    fetchPolicy: 'network-only',
    skip: !value,
  });

  const options = getServiceTypesSelectOptions(defaultServiceType, data);

  return (
    <Select
      value={value}
      options={options}
      searchText={searchText}
      onInputChange={onInputChange}
      placeholder={placeholder || 'Start typing service type'}
      {...props}
    />
  );
}

ServiceTypesSelect.propTypes = {
  value: string,
  name: string,
  width: string,
  onChange: func,
  // eslint-disable-next-line react/forbid-prop-types
  searchQuery: object,
  placeholder: string,
};
