import { gql } from '@apollo/client';
import { supplierAccountFields } from './fragments.js';

export const SUPPLIER_SEARCH_CHANGED = gql`
  subscription SUPPLIER_SEARCH_CHANGED($searchInput: CollectionSearchParams!) {
    searchSupplierChanged(input: $searchInput) {
      id
      type
    }
  }
`;

export const SUPPLIER_ACCOUNT_SUB = gql`
  subscription SUPPLIER_DETAILS_SUB($input: SingleDocSubInput!) {
    supplierChanged(input: $input) {
      id
      document {
        ...supplierAccountFields
      }
    }
  }
  ${supplierAccountFields}
`;
