import { gql } from '@apollo/client';

export const PROJECTS_BY_SEARCH_SUB = gql`
  subscription PROJECTS_BY_SEARCH_SUB($searchInput: CollectionSearchParams!) {
    searchProjectChanged(input: $searchInput) {
      id
      type
    }
  }
`;

export const RECURRING_PROJECTS_BY_SEARCH_SUB = gql`
  subscription RECURRING_PROJECTS_BY_SEARCH_SUB(
    $searchInput: CollectionSearchParams!
  ) {
    searchRecurringProjectChanged(input: $searchInput) {
      id
      type
    }
  }
`;
