import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import styled from 'styled-components';
import { getThemeColor, getThemeFont } from '../utils.js';
import { Tabs } from './index.js';

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1440px;
  min-width: ${({ width }) => width || '1220px'};
  width: calc(100% - 150px);
  height: 100%;
  margin: 0 70px 0 80px;
  flex-wrap: wrap;

  ${R.prop('contentStyles')};
`;

export const DefaultBodyWrapper = styled.div`
  display: flex;
  width: 100%;
  height: ${R.propOr('calc(100% - 90px)', 'height')};
  justify-content: center;
  background-color: ${getThemeColor(['lightest'])};
`;

export const ListBodyWrapper = styled(DefaultBodyWrapper)`
  align-content: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  background-color: ${getThemeColor(['secondaryLighter3'])};
`;

const HeaderWrapper = styled(ContentWrapper)`
  position: relative;
  height: 32px;
  width: calc(100% - 135px);
  margin: 0 70px 0 65px;

  & span,
  & div {
    font-weight: ${getThemeFont(['regular'])};
  }
`;

const SubTabsHeader = styled(HeaderWrapper)`
  height: 52px;
  padding-top: 20px;
  width: calc(100% - 60px);
  min-width: auto;
  margin: 0 30px;
`;

export const TabsWrapper = styled(Tabs)`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const ToolBarWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 10px;
  z-index: 1;
`;

const SubTabsToolBar = styled(ToolBarWrapper)`
  top: 23px;
`;

export const ActiveComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: ${getThemeColor(['lightest'])};
`;

const SubTabsActiveComponentContainer = styled(ActiveComponentContainer)`
  background-color: transparent;
`;

function TabsWrapperWithProps(props) {
  return (
    <TabsWrapper
      {...props}
      tabQueryKey="tab"
      HeaderWrapper={HeaderWrapper}
      ToolBarWrapper={ToolBarWrapper}
      ActiveComponentContainer={ActiveComponentContainer}
    />
  );
}

function SubTabsWrapperWithProps(props) {
  return (
    <TabsWrapper
      {...props}
      tabQueryKey="subTab"
      HeaderWrapper={SubTabsHeader}
      ToolBarWrapper={SubTabsToolBar}
      ActiveComponentContainer={SubTabsActiveComponentContainer}
    />
  );
}

export const PageGeneralTabsWrapper = styled(DefaultBodyWrapper)`
  position: relative;
  background-color: transparent;

  &:before {
    display: block;
    content: '';
    width: 100%;
    position: absolute;
    top: 0;
    border-top: 4px solid ${getThemeColor(['white'])};
    z-index: 1;
  }

  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 4px;
    position: absolute;
    top: 30px;
    box-shadow: inset 0 2px 2px 0 ${getThemeColor(['midLight'])};
  }

  ${R.prop('generalStyles')};
`;

const PageSubTabsWrapper = styled(PageGeneralTabsWrapper)`
  height: 100%;
  width: 100%;

  &:before {
    display: none;
  }

  &:after {
    margin: 0 30px;
    width: calc(100% - 60px);
    left: 0;
    top: 50px;
    height: 1px;
  }
`;

export function PageGeneralTabs({ generalStyles, ...props }) {
  return (
    <PageGeneralTabsWrapper {...{ generalStyles }}>
      <TabsWrapperWithProps {...props} />
    </PageGeneralTabsWrapper>
  );
}

PageGeneralTabs.propTypes = { generalStyles: string };
PageGeneralTabs.displayName = 'PageGeneralTabs';

export function PageSubTabs(props) {
  return (
    <PageSubTabsWrapper>
      <SubTabsWrapperWithProps {...props} />
    </PageSubTabsWrapper>
  );
}
