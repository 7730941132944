import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { paginationToQueryParams } from '@poly/client-utils';

import { useSetInitialPaginationOnMount } from './useSetInitialPaginationOnMount.js';

export const usePaginationParams = () => {
  const pagination = useSelector(R.prop('pagination'));
  useSetInitialPaginationOnMount();
  return paginationToQueryParams(pagination);
};
