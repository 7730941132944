import React from 'react';
import styled from 'styled-components';
import { func, number } from 'prop-types';

import { Select } from '../Select/Select.js';
import { FlexContainer } from '../lib/index.js';
import { itemsPerPageOptions } from './constants.js';
import { getThemeColor } from '../utils.js';

const SelectS = styled(Select)`
  margin-left: 10px;
  width: 105px;
  cursor: pointer;

  & input {
    cursor: pointer;
  }

  & > div:first-child > div {
    height: 25px;
    padding: 9px 15px;
    border: 1px solid ${getThemeColor(['primaryLighter5'])};
  }

  & > div:last-child {
    border-top: 1px solid ${getThemeColor(['primaryLighter5'])};
    border-left: 1px solid ${getThemeColor(['primaryLighter5'])};
    border-right: 1px solid ${getThemeColor(['primaryLighter5'])};
  }

  .select__control,
  .select__control:hover,
  .select__control:focus,
  .select__control--is-focused {
    min-height: 34px;
  }

  .select__option,
  .select__single-value {
    color: ${getThemeColor(['midDark'])};
  }
`;

export function ItemsPerPageSelect({ onChange, value }) {
  return (
    <FlexContainer>
      <SelectS
        required
        value={value}
        direction="up"
        onChange={onChange}
        name="items-per-page"
        options={itemsPerPageOptions}
      />
    </FlexContainer>
  );
}

ItemsPerPageSelect.propTypes = {
  onChange: func.isRequired,
  value: number.isRequired,
};
