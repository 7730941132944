import { gql } from '@apollo/client';
import React, { useMemo } from 'react';
import { string, func, shape } from 'prop-types';
import { useReactiveQuery } from '@poly/client-utils';
import { useRouterQuery } from '@poly/client-routing';
import { MAX_ITEMS } from '@poly/admin-ui';

import { BillingProfileConfigMultiSelect } from './BillingProfileConfigMultiSelect.js';
import {
  formatProjectConsolidationOptionsByName,
  generateProjectsQueryForConfigByTarget,
  onMultipleSelectValueChange,
  prepareMultipleSelectValue,
} from '../utils.js';
import { multiSelectorValueProperTypes } from './common.js';

const SEARCH_POS_QUERY = gql`
  query SEARCH_POS_QUERY($input: CollectionSearchParams!) {
    searchProjects(input: $input) {
      hits {
        _id
        clientReferenceNumber
        parent {
          clientReferenceNumber
        }
      }
    }
  }
`;

const SEARCH_POS_SUBSCRIPTION = gql`
  subscription SEARCH_POS_SUBSCRIPTION($input: CollectionSearchParams!) {
    searchProjectChanged(input: $input) {
      id
      type
    }
  }
`;

export function MultiplePOSelect({ value, values, onChange, ...props }) {
  const { clientId } = useRouterQuery(['clientId']);

  const query = useMemo(
    () =>
      generateProjectsQueryForConfigByTarget('clientReferenceNumber')({
        ...values,
        clientId,
      }),
    [values, clientId],
  );

  const queryOptions = {
    variables: { input: { query, size: MAX_ITEMS } },
  };

  const { data, loading } = useReactiveQuery(
    SEARCH_POS_QUERY,
    SEARCH_POS_SUBSCRIPTION,
    { queryOptions, subscriptionOptions: queryOptions },
  );

  const options = useMemo(
    () =>
      formatProjectConsolidationOptionsByName('clientReferenceNumber')(data),
    [data],
  );

  const preparedValue = useMemo(
    () => prepareMultipleSelectValue(value, options),
    [value, options],
  );

  const selectProps = {
    ...props,
    loading,
    options,
    value: preparedValue,
    placeholder: 'Start typing purchase order',
    handleChange: onMultipleSelectValueChange(onChange),
  };

  return <BillingProfileConfigMultiSelect {...selectProps} />;
}

MultiplePOSelect.propTypes = {
  onChange: func.isRequired,
  value: multiSelectorValueProperTypes,
  values: shape({
    costType: string,
    serviceTypeId: string,
  }),
};
