import * as R from 'ramda';
import { ALL } from '@poly/admin-ui';
import { isNilOrEmpty } from '@poly/utils';

// matchQueryGenerator :: String -> Any -> Object
export const matchQueryGenerator = (key) =>
  R.compose(R.objOf('match'), R.objOf(key));

// generateSearchQuery :: QueryGenerators -> SearchFilters -> ElasticQuery
// QueryGenerators, SearchFilters, ElasticQuery = Object
export const generateSearchQuery = (queryGenerators) =>
  R.compose(
    R.assocPath(['bool', 'must'], R.__, {}),
    R.values,
    R.mapObjIndexed(
      R.unapply(
        R.compose(
          R.apply(R.call),
          R.juxt([
            R.compose(R.prop(R.__, queryGenerators), R.nth(1)),
            R.nth(0),
          ]),
        ),
      ),
    ),
    R.reject(R.either(R.equals(ALL), isNilOrEmpty)),
    R.pick(R.keys(queryGenerators)),
  );

// generateTermsQuery :: String -> FieldValues -> SearchFilters -> TermsQuery
// TermsQuery = Object
// FieldValues = { [String]: [String] }
const generateTermsQuery = R.curry((field, values, filters) =>
  R.compose(
    R.objOf('terms'),
    R.objOf(field),
    R.flatten,
    R.values,
    R.pick(R.__, values),
    R.keys,
    R.filter(R.equals(true)),
    R.pick(R.keys(values)),
  )(filters),
);

// generateTermsQueries :: [TermsQueryConfig] -> SearchFilters -> [TermsQuery]
// TermsQueryConfig = Pair FieldName FieldValues
// FieldName = String
// FieldValues = { [String]: [String] }
export const generateTermsQueries = (config) =>
  R.compose(
    // filter empty terms
    R.reject(
      R.propSatisfies(
        R.compose(R.all(R.compose(R.isEmpty, R.last)), R.toPairs),
        'terms',
      ),
    ),
    R.converge(R.map, [
      R.compose(R.apply, (filters) => generateTermsQuery(R.__, R.__, filters)),
      R.always(config),
    ]),
  );
