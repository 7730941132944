import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import { validateCanadaZipCode, validateUSAZipCode } from '@poly/client-utils';
import { defaultTheme, Input, Select } from '@poly/admin-book';
import { SupplierStatuses } from '@poly/constants';
import {
  ServiceTypesSelect,
  ZipCodeInput,
  StateSelect,
  CityInput,
  countrySelectOptions,
} from '@poly/admin-ui';

import { SupplierFilterTypes } from './filter/constants.js';
import { CheckboxS, ErrorMessage } from './filter/styles.js';
import { SearchHeaderColumnTitle } from '../../../components/SearchHeaderColumn.js';
import { DistanceSelect } from '../../forms/assignSupplierForm/components/DistanceSelect.js';

const {
  colors: { midLight },
} = defaultTheme;
const ALL_SUPPLIER_TYPES_FILTER = 'allSupplierTypes';
const ALL_SUPPLIER_STATUSES_FILTER = 'allSupplierStatuses';

function ZipErrorMsg() {
  return (
    <ErrorMessage>Zip format is wrong. Check it and try to change</ErrorMessage>
  );
}

// isZipValid :: String -> Boolean
export const isZipValid = R.anyPass([
  validateCanadaZipCode,
  validateUSAZipCode,
]);

const renderErrorIf = R.compose(
  R.both(R.identity, R.complement(isZipValid)),
  R.prop('radiusStartPoint'),
);

function SearchHeaderTitle({ title }) {
  return (
    <SearchHeaderColumnTitle width="100px">{title}</SearchHeaderColumnTitle>
  );
}

SearchHeaderTitle.propTypes = {
  title: string.isRequired,
};

function CountrySelect(props) {
  return (
    <Select
      {...props}
      options={countrySelectOptions}
      width="100%"
      placeholder="Select Country"
    />
  );
}

export const supplierSearchHeaderConfig = [
  {
    key: 'supplierTypeRow',
    rowTitle: <SearchHeaderTitle title="Type" />,
    rowLayout: {
      justifyContent: 'flex-start',
      columnWidth: '170px',
      borderBottom: `1px solid ${midLight}`,
    },
    columns: [
      {
        name: SupplierFilterTypes.SUBCONTRACTOR,
        filterProps: { label: 'Subcontractor' },
        Component: CheckboxS,
        column: 1,
      },
      {
        name: SupplierFilterTypes.ADMINISTRATIVE,
        filterProps: { label: 'Administrative' },
        Component: CheckboxS,
        column: 2,
      },
      {
        name: ALL_SUPPLIER_TYPES_FILTER,
        filterProps: { label: 'All' },
        subscribers: [
          [SupplierFilterTypes.SUBCONTRACTOR, R.identity],
          [SupplierFilterTypes.ADMINISTRATIVE, R.identity],
        ],
        Component: CheckboxS,
        column: 3,
      },
    ],
  },
  {
    key: 'supplierStatusRow',
    rowTitle: <SearchHeaderTitle title="Status" />,
    rowLayout: {
      justifyContent: 'flex-start',
      columnWidth: '170px',
      borderBottom: `1px solid ${midLight}`,
      padding: '10px 0 0 0',
    },
    columns: [
      {
        name: SupplierStatuses.ACTIVE,
        filterProps: { label: 'Active' },
        Component: CheckboxS,
        column: 1,
      },
      {
        name: SupplierStatuses.BLOCKED,
        filterProps: { label: 'Blocked' },
        Component: CheckboxS,
        column: 3,
      },
      {
        name: ALL_SUPPLIER_STATUSES_FILTER,
        filterProps: { label: 'All' },
        subscribers: [
          [SupplierStatuses.ACTIVE, R.identity],
          [SupplierStatuses.BLOCKED, R.identity],
        ],
        Component: CheckboxS,
        column: 4,
      },
    ],
  },
  {
    key: 'supplierServiceTypeRow',
    rowLayout: {
      justifyContent: 'flex-start',
      padding: '10px 0 0 0',
    },
    columns: [
      {
        name: 'serviceTypeId',
        title: 'Service Type',
        columnLayout: {
          margin: '0 15px 0 0',
          titleWidth: '105px',
          filterWidth: '265px',
        },
        filterProps: { width: '100%' },
        Component: (props) => <ServiceTypesSelect {...props} isClearable />,
        column: 1,
      },
      {
        name: 'state',
        title: 'Address',
        columnLayout: {
          titleWidth: '80px',
          filterWidth: '180px',
          margin: '0 15px 0 25px',
        },
        filterProps: { width: '100%' },
        Component: StateSelect,
        column: 2,
      },
      {
        name: 'city',
        columnLayout: { filterWidth: '180px', margin: '0 15px 0 0' },
        filterProps: { width: '100%' },
        Component: CityInput,
        column: 3,
      },
      {
        name: 'zipCode',
        columnLayout: { filterWidth: '150px', margin: '0 15px 0 0' },
        filterProps: { width: '100%', changeOnBlur: true },
        Component: ZipCodeInput,
        column: 4,
      },
      {
        name: 'country',
        columnLayout: { filterWidth: '150px', margin: '0 15px 0 0' },
        filterProps: { width: '100%', changeOnBlur: true },
        Component: CountrySelect,
        column: 5,
      },
    ],
  },
  {
    key: 'supplierRadiusRow',
    rowLayout: {
      justifyContent: 'flex-start',
      padding: '10px 0 0 0',
    },
    columns: [
      {
        name: 'radiusStartPoint',
        title: 'Radius',
        columnLayout: { filterWidth: '150px', titleWidth: '105px' },
        filterProps: { width: '100%', changeOnBlur: true },
        Component: ZipCodeInput,
        column: 1,
      },
      {
        name: 'radiusDistance',
        columnLayout: { filterWidth: '100px' },
        filterProps: { width: '100%', withNoRadiusOption: false },
        defaultValue: '25',
        disabledIf: R.pathSatisfies(R.isNil, ['radiusStartPoint']),
        Component: DistanceSelect,
        column: 2,
      },
      {
        name: 'zipErrorMsg',
        columnLayout: { filterWidth: '350px' },
        renderIf: renderErrorIf,
        Component: ZipErrorMsg,
        column: 3,
      },
      {
        name: 'searchName',
        title: 'Name',
        columnLayout: {
          filterWidth: '180px',
          titleWidth: '80px',
          margin: '0 15px 0 25px',
        },
        filterProps: {
          width: '100%',
          changeOnBlur: true,
        },

        Component: Input,
        column: 4,
      },
    ],
  },
];
