import React from 'react';
import { getThemeColor, getThemeFont, useMoreScroll } from '@poly/admin-book';
import styled from 'styled-components';
import { arrayOf, string } from 'prop-types';
import { formatTotal } from '@poly/utils';

import { FlexColumn, FlexContainer } from '../../components/FlexContainer.js';
import {
  CategoryReportSection,
  SectionTitleContainer,
  TotalContainer,
} from './CategoryReportSection.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { TableWithToolbarContainer } from '../../components/TableWithToolBarContainer.js';
import {
  profitLossFiltersPropTypes,
  profitLossPropTypes,
} from './propTypes.js';
import {
  calculateTotalBalance,
  extractCogs,
  extractExpense,
  extractIncome,
} from './helpers.js';

const ProfitLossReportTableContainerS = styled(FlexColumn)`
  width: 100%;
  height: 100%;
  align-items: flex-start;
  padding: 15px 0;
`;

const ProfitLossTableTitle = styled(FlexSpaceBetween)`
  width: 100%;
  font-size: 18px;
  position: relative;
`;

const HeaderTitle = styled(FlexContainer)`
  width: 250px;
  align-items: flex-end;
  justify-content: flex-end;
  font-weight: ${getThemeFont(['regular'])};
  font-size: 13px;
  letter-spacing: 1px;
  color: ${getThemeColor(['primaryLighter4'])};
  &:last-child {
    padding-right: 20px;
  }
`;

const ProfitLossTotalSection = styled(SectionTitleContainer)`
  border: 1px solid rgba(197, 198, 201, 0.35);
  font-weight: bold;
  margin-bottom: 5px;
`;

const ProfitLossContentContainerS = styled(FlexColumn)`
  overflow: auto;
  scrollbar-width: thin;

  ${({ showScrollBar }) =>
    showScrollBar
      ? ''
      : `&::-webkit-scrollbar {
    background: transparent;
    display: none;
  }`}

  height: calc(100% - 35px);
`;

export function ProfitLossReportTable({
  profitLossReport,
  filters,
  cogsAccountCodes,
}) {
  const incomeReports = extractIncome(profitLossReport);
  const cogsReports = extractCogs(cogsAccountCodes)(profitLossReport);
  const expenseReports = extractExpense(cogsAccountCodes)(profitLossReport);

  const totalIncome = calculateTotalBalance(incomeReports);
  const totalCogs = calculateTotalBalance(cogsReports);
  const totalOverHead = calculateTotalBalance(expenseReports);

  const { MoreScrollIcon, onScroll } = useMoreScroll(
    '50px',
    'ProfitLossContentContainerS',
  );

  return (
    <TableWithToolbarContainer
      title="Profit and Loss"
      toolbar={
        <ProfitLossTableTitle>
          <FlexContainer>
            <HeaderTitle>Debit</HeaderTitle>
            <HeaderTitle>Credit</HeaderTitle>
          </FlexContainer>
        </ProfitLossTableTitle>
      }
    >
      <MoreScrollIcon />
      <ProfitLossReportTableContainerS onScroll={onScroll}>
        <ProfitLossContentContainerS
          id="ProfitLossContentContainerS"
          showScrollBar
        >
          {incomeReports.length > 0 && (
            <CategoryReportSection
              isCreditNormal
              title="Income"
              profitLossReports={incomeReports}
              filters={filters}
              totalTitle="Total Income"
            />
          )}
          {cogsReports.length > 0 && (
            <CategoryReportSection
              title="COGS Facility Services"
              profitLossReports={cogsReports}
              filters={filters}
              totalTitle="Total COGS Facility Services"
            />
          )}

          <ProfitLossTotalSection>
            Gross Profit
            <TotalContainer isCreditNormal>
              {formatTotal(totalIncome - totalCogs)}
            </TotalContainer>
          </ProfitLossTotalSection>

          {expenseReports.length > 0 && (
            <CategoryReportSection
              title="Overhead"
              profitLossReports={expenseReports}
              filters={filters}
              totalTitle="Total Overhead"
            />
          )}
        </ProfitLossContentContainerS>

        <ProfitLossTotalSection>
          Net Profit/(Loss)
          <TotalContainer isCreditNormal>
            {formatTotal(totalIncome - totalCogs - totalOverHead)}
          </TotalContainer>
        </ProfitLossTotalSection>
      </ProfitLossReportTableContainerS>
    </TableWithToolbarContainer>
  );
}

ProfitLossReportTable.propTypes = {
  profitLossReport: profitLossPropTypes,
  filters: profitLossFiltersPropTypes,
  cogsAccountCodes: arrayOf(string),
};
