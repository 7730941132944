import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { Link } from '@poly/client-routing';
import { string, shape } from 'prop-types';
import {
  FinancialEntityTypes,
  JournalPaymentMode,
  SystemAccountTypes,
} from '@poly/constants';
import {
  DatePicker,
  Input,
  Textarea,
  CharacterCount,
  Text,
} from '@poly/admin-book';
import { propEqLegacy, validationFuncWithAllValues } from '@poly/utils';
import { MoneyInput, SubmitBtn } from '@poly/admin-ui';
import { commonFileValidators, validateFilesFunc } from '@poly/client-utils';

import {
  PayByFormAddressComponent,
  payByFormAddressValidators,
} from './PayByFormAddressComponent.js';
import { halfWidth } from '../../modules/forms/common.js';
import { CCUsersSelect } from '../../components/CCUsersSelect.js';
import { ProjectSelect } from '../../components/ProjectsSelect.js';
import { payByBankFormId, payByCreditCardFormId } from './constants.js';
import { FormPageSectionButtons } from '../../components/FormPageSectionButtons.js';
import {
  GLCodeSelect,
  prepareAccountsToSelectBase,
} from '../../modules/accounting/GLCodeSelect/GLCodeSelect.js';
import { BankDepositsTypeComponent } from '../BankDeposits/BankDepositsTypeComponent.js';
import { BankDepositsPayeeNameComponent } from '../BankDeposits/BankDepositsPayeeNameComponent.js';
import { rejectReceivableAndPayableAccounts } from '../../modules/accounting/GLCodeSelect/glCodeSelectUtils.js';
import {
  getAccountSelectFormSection,
  getPaymentModeFormSection,
} from './payByFormUtils.js';
import { onKeyDownToPreventFormSubmit } from '../../utils/form.js';
import { AttachDocumentField } from '../../components/AttachDocumentField.js';
import { routesNames } from '../../routes/constants.js';

function PayByFormButtons({ formId, formData }) {
  const middleBtnAttr = 'print';

  const onMiddleButtonClick = () => {
    const form = document.getElementById(formId);
    form.setAttribute(middleBtnAttr, 'true');
  };

  const MiddleBtn =
    formData.paymentMode === JournalPaymentMode.CHECK
      ? (props) => (
          <SubmitBtn onClick={onMiddleButtonClick} {...props}>
            Save & Print
          </SubmitBtn>
        )
      : undefined;

  return (
    <FormPageSectionButtons
      formId={formId}
      MiddleBtn={MiddleBtn}
      middleBtnAttr={middleBtnAttr}
    />
  );
}

PayByFormButtons.propTypes = {
  formId: string.isRequired,
  formData: shape({ paymentMode: string }).isRequired,
};

function PayByDatePicker(props) {
  return (
    <DatePicker
      width="100%"
      {...props}
      onKeyDown={onKeyDownToPreventFormSubmit}
    />
  );
}

function PayByCheckNumberInput(props) {
  return (
    <Input
      {...props}
      type="numberMask"
      maxLength={6}
      onKeyDown={onKeyDownToPreventFormSubmit}
    />
  );
}

function PayByProjectSelect(props) {
  return <ProjectSelect {...props} onKeyDown={onKeyDownToPreventFormSubmit} />;
}

function PayByMemoField({ value, ...props }) {
  return (
    <>
      <Textarea {...props} value={value} rows={3} maxLength="200" />
      <CharacterCount limit={200} length={value.length} />
    </>
  );
}

PayByMemoField.propTypes = {
  value: string.isRequired,
};

function PayByCCUserSelect({ formData, ...props }) {
  const accountId = R.path(['bankAccount', '_id'], formData);
  return (
    <CCUsersSelect
      {...props}
      isAutoPopulateSingleUserFound
      placeholder="Select Credit Card user"
      queryInput={accountId ? { accountId } : null}
      onKeyDown={onKeyDownToPreventFormSubmit}
    />
  );
}

PayByCCUserSelect.propTypes = {
  formData: shape({ bankAccount: shape({ code: string }) }).isRequired,
};

// rejectAccountsBySystemType :: [Account] -> [Account]
const rejectAccountsBySystemType = (systemType) =>
  R.compose(
    R.reject(
      R.compose(R.equals(systemType), R.path(['accountType', 'system_type'])),
    ),
    rejectReceivableAndPayableAccounts,
  );

function PayByGLCodeSelect({ paymentSystemType, ...props }) {
  const formatAccountsOptions = prepareAccountsToSelectBase(R.prop('_id'));
  return (
    <GLCodeSelect
      {...props}
      filterAccounts={rejectAccountsBySystemType(paymentSystemType)}
      onKeyDown={onKeyDownToPreventFormSubmit}
      formatAccountsOptions={formatAccountsOptions}
    />
  );
}
PayByGLCodeSelect.propTypes = {
  paymentSystemType: string,
};

function PayByAmountInput({ formData, ...restProps }) {
  return (
    <MoneyInput
      allowNegative={formData.paymentMode === JournalPaymentMode.CREDIT_CARD}
      onKeyDown={onKeyDownToPreventFormSubmit}
      {...restProps}
    />
  );
}

PayByAmountInput.propTypes = {
  formData: shape({ paymentMode: string.isRequired }).isRequired,
};

const WarningPaySupplierInvoiceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  margin-bottom: 15px;

  div,
  a {
    margin-right: 5px;
  }
`;

// displayWarning :: FormData -> Boolean
const displayWarning = R.both(
  propEqLegacy('type', 'suppliers'),
  propEqLegacy('paymentMode', 'checkPaymentMode'),
);

function WarningPaySupplierInvoice() {
  const textColor = ['notificator', 'warning', 'text'];
  const firstTextPart =
    "* Heads up! If you're trying to pay supplier invoice then go to";

  return (
    <WarningPaySupplierInvoiceWrapper>
      <Text color={textColor}>{firstTextPart}</Text>
      <Link href={routesNames.PAY_SUPPLIERS}>Pay Suppliers</Link>
      <Text color={textColor}>page.</Text>
    </WarningPaySupplierInvoiceWrapper>
  );
}

export const payByFormSections = (formId) => {
  const isBankForm = formId === payByBankFormId;
  const isCCForm = formId === payByCreditCardFormId;

  return [
    {
      order: 1,
      layout: { column: 1 },
      fields: [
        {
          label: 'Type',
          order: 1,
          layout: { row: 1 },
          field: {
            name: 'type',
            withChangeFieldValue: true,
            Component: BankDepositsTypeComponent,
          },
          validators: [[R.identity, 'Type is required']],
        },
        {
          label: 'Name',
          order: 2,
          layout: { row: 2, width: halfWidth },
          field: {
            name: 'documentId',
            withFormData: true,
            withChangeFieldValue: true,
            Component: BankDepositsPayeeNameComponent,
          },
          validators: [[R.identity, 'Name is required']],
          renderIf: R.propSatisfies(R.complement(R.isNil), 'type'),
          required: true,
        },
        {
          order: 3,
          layout: { row: 3 },
          field: {
            name: 'address',
            withFormData: true,
            additionalProps: { onKeyDown: onKeyDownToPreventFormSubmit },
            Component: PayByFormAddressComponent,
          },
          renderIf: R.either(
            propEqLegacy('type', FinancialEntityTypes.OTHERS),
            R.both(
              R.propSatisfies(R.complement(R.isNil), 'type'),
              R.propSatisfies(R.complement(R.isNil), 'documentId'),
            ),
          ),
          validators: payByFormAddressValidators,
          validateFunction: validationFuncWithAllValues,
        },
        {
          order: 4,
          layout: { row: 4 },
          field: {
            name: 'receiptFiles',
            Component: (props) => (
              <AttachDocumentField
                {...props}
                isFileNameLimited
                label="Attach Receipt"
              />
            ),
          },
          renderIf: propEqLegacy('paymentMode', JournalPaymentMode.CREDIT_CARD),
          validators: commonFileValidators,
          validateFunction: validateFilesFunc,
        },
      ],
    },
    {
      order: 2,
      layout: { column: 2 },
      fields: [
        {
          order: 1,
          layout: { row: 0 },
          field: {
            name: 'warningPaySupplierInvoice',
            withFormData: true,
            Component: WarningPaySupplierInvoice,
          },
          renderIf: displayWarning,
        },
        ...getAccountSelectFormSection(isBankForm),
        {
          label: 'GL Code',
          order: 2,
          layout: { row: 1, width: halfWidth },
          field: {
            name: isBankForm ? 'glAccount' : '',
            Component: PayByGLCodeSelect,
            additionalProps: {
              paymentSystemType: SystemAccountTypes.BANK_ACCOUNT,
            },
          },
          validators: [[R.identity, 'GL Code is required']],
          renderIf: () => isBankForm,
          required: true,
        },
        {
          label: 'CC User',
          order: 2,
          layout: { row: 1, width: halfWidth },
          field: {
            name: 'creditCardUserId',
            withFormData: true,
            withChangeFieldValue: true,
            Component: PayByCCUserSelect,
          },
          renderIf: () => isCCForm,
          validators: [[R.identity, 'CC User is required']],
          required: true,
        },
        ...getPaymentModeFormSection(isBankForm),
        {
          label: 'Amount',
          order: 4,
          layout: { row: 3, width: halfWidth },
          field: {
            name: 'netAmount',
            withFormData: true,
            Component: PayByAmountInput,
          },
          validators: [[R.identity, 'Amount is required']],
          required: true,
        },
        {
          label: 'Check Number',
          order: 5,
          layout: { row: 3, width: halfWidth },
          field: {
            name: 'checkNumber',
            Component: PayByCheckNumberInput,
          },
          renderIf: propEqLegacy('paymentMode', JournalPaymentMode.CHECK),
          validators: [[R.identity, 'Check Number is required']],
          required: true,
        },
        {
          label: 'GL Code',
          order: 5,
          layout: { row: 3, width: halfWidth },
          field: {
            name: isCCForm ? 'glAccount' : '',
            Component: PayByGLCodeSelect,
            additionalProps: {
              paymentSystemType: SystemAccountTypes.CREDIT_CARD,
            },
          },
          validators: [[R.identity, 'GL Code is required']],
          renderIf: () => isCCForm,
          required: true,
        },
        {
          label: 'Date',
          order: 6,
          layout: { row: 4, width: halfWidth },
          field: {
            name: 'date',
            Component: PayByDatePicker,
          },
          required: true,
          validators: [[R.identity, 'Date is required']],
        },
        {
          label: 'Project #',
          order: 7,
          layout: { row: 4, width: halfWidth },
          field: {
            name: 'projectId',
            Component: PayByProjectSelect,
          },
        },
        {
          label: 'Invoice #',
          order: 8,
          layout: { row: 5, width: halfWidth },
          field: {
            name: 'invoiceNumber',
            Component: Input,
          },
          renderIf: R.both(
            R.prop('showInvoiceNumberInput'),
            R.prop('projectId'),
          ),
        },
        {
          label: 'Memo',
          order: 8,
          layout: { row: 6 },
          field: {
            name: 'description',
            Component: PayByMemoField,
          },
        },
        {
          order: 9,
          layout: { row: 7 },
          field: {
            name: 'formButtons',
            withFormData: true,
            Component: (props) => (
              <PayByFormButtons {...props} formId={formId} />
            ),
          },
        },
      ],
    },
  ];
};
