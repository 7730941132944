import React from 'react';
import { string } from 'prop-types';
import { BooleanTextWithDot, defaultTheme, Status } from '@poly/admin-book';
import { forceTitleCase } from '@poly/utils';

const {
  colors: {
    statuses: { active },
  },
} = defaultTheme;

export function SupplierMBEElement({ minority }) {
  return minority ? (
    <Status color={active} text={`Yes - ${forceTitleCase(minority)}`} />
  ) : (
    <BooleanTextWithDot isTrue={false} />
  );
}

SupplierMBEElement.propTypes = {
  minority: string,
};
