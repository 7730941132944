import styled from 'styled-components';
import { dateColumnStyles, Table } from '@poly/admin-book';

import { phoneColumnStyles, pipColumnStyles } from '../tablesStyles.js';

export const UsersTableComp = styled(Table)`
  ${pipColumnStyles(1)};

  th:nth-child(2),
  td:nth-child(2),
  th:nth-child(3),
  td:nth-child(3) {
    width: 170px;
  }

  ${phoneColumnStyles(4)}

  th:nth-child(5),
  td:nth-child(5) {
    width: 100px;
  }

  th:nth-child(6),
  td:nth-child(6) {
    width: 300px;
  }

  ${dateColumnStyles(7)}

  td:last-child {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
