import React from 'react';
import { SidebarTasksTab } from '@poly/admin-ui';
import { bool } from 'prop-types';

import { useProjectSidebarTasksProps } from '../useProjectSidebarTasksProps.js';

export function ProjectSidebarTasksTab({ readOnly, ...project }) {
  const tasksProps = useProjectSidebarTasksProps(project);

  return <SidebarTasksTab {...tasksProps} readOnly={readOnly} />;
}

ProjectSidebarTasksTab.propTypes = {
  readOnly: bool,
};
