import * as R from 'ramda';

import { assocBy } from './ramda.js';

// isAdminPOWithLowBalance :: AdminPurchaseOrder -> Boolean
export const isAdminPOWithLowBalance = R.ifElse(
  R.prop('lowBalancePercent'),
  R.compose(
    R.converge(R.lte, [R.prop('currentBalance'), R.prop('lowBalance')]),
    assocBy(
      'lowBalance',
      R.compose(
        R.divide(R.__, 100),
        R.converge(R.multiply, [
          R.prop('lowBalancePercent'),
          R.prop('initialBalance'),
        ]),
      ),
    ),
  ),
  R.F,
);
