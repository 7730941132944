import * as R from 'ramda';
import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { arrayOf, bool, func, object, string } from 'prop-types';
import styled from 'styled-components';
import { Loader } from '@poly/admin-book';
import {
  assocBy,
  calculateTotal,
  isClientInvoiceAmountValid,
  propEqLegacy,
} from '@poly/utils';
import { useModalContext, useNotificationState } from '@poly/admin-ui';
import { ProjectType } from '@poly/constants';

import { PayInvoicesTableCard } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { TableAndPanelWrapper } from '../VoidSupplierPayments/styles.js';
import {
  createClearInvoicesAction,
  createSelectAllProjectsAction,
  createToggleProjectSelectionAction,
} from './batch-invoicing-state.js';
import { EmptyListMessage } from '../../components/EmptyListMessage.js';
import {
  BottomPanelBody,
  BottomPanelContainer,
} from '../../components/BottomPanel.js';
import { getWoChargeAmount } from '../CreateClientInvoice/helpers.js';
import { isTotalsOutOfRange } from './batchInvoicingUtils.js';

export const TotalsTableWrapper = styled(BottomPanelBody)`
  flex-direction: row;
  justify-content: flex-end;
  padding: 0;
`;

export const BottomPanelContainerS = styled(BottomPanelContainer)`
  z-index: 1;
`;

export const createClientInvoiceBatchQuery = gql`
  mutation CREATE_CLIENT_INVOICE_BATCH($input: CreateClientInvoiceBatchInput!) {
    createClientInvoiceBatch(input: $input) {
      clientBatches {
        _id
      }
    }
  }
`;

// getProjectsByQueryResult :: QueryResult -> [Project]
// QueryResult = Object
export const getProjectsByQueryResult = R.pathOr(
  [],
  ['searchProjects', 'hits'],
);

// filterSelectedBatchEntries :: [BatchStoreEntry] -> [BatchStoreEntry]
//   BatchStoreEntry = {isSelected: Bool, amount: Number}
const filterSelectedBatchEntries = R.filter(propEqLegacy('isSelected', true));

// getSelectedProjectsIds :: BatchEntriesStore -> [ID]
export const getSelectedProjectsIds = R.compose(
  R.keys,
  filterSelectedBatchEntries,
);

// getMutationInputByStore :: BatchEntriesStore -> [CreateClientInvoiceBatchInputEntry]
const getMutationInputByStore = R.compose(
  R.map(
    R.applySpec({
      projectId: R.path(['project', '_id']),
      amount: R.path(['project', 'suggestedClientInvoiceAmount']),
    }),
  ),
  R.values,
  filterSelectedBatchEntries,
);

// getBatchTotalsByStore :: [String] -> BatchEntriesStore -> Object
export const getBatchTotalsByStore = (calculateTotalsProps) =>
  R.compose(
    R.fromPairs,
    R.zip(calculateTotalsProps),
    R.juxt(
      calculateTotalsProps.map((propName) =>
        calculateTotal(R.pathOr(0, ['project', propName])),
      ),
    ),
    R.map(
      R.over(
        R.lensProp('project'),
        R.when(
          propEqLegacy('type', ProjectType.WORK_ORDER),
          assocBy('woCharge', R.compose(R.defaultTo(0), getWoChargeAmount)),
        ),
      ),
    ),
    R.values,
    filterSelectedBatchEntries,
  );

// isCreateBatchButtonDisabled :: BatchEntriesStore -> Boolean
// all selected projects amounts should be valid
export const isCreateBatchButtonDisabled = R.compose(
  R.either(R.isEmpty, R.any(R.complement(isClientInvoiceAmountValid))),
  R.map(R.path(['project', 'suggestedClientInvoiceAmount'])),
  R.values,
  filterSelectedBatchEntries,
);

// getFileNamePrefixByBatchData :: QueryResult -> String
const getFileNamePrefixByBatchData = R.compose(
  R.ifElse(R.equals('Janitorial'), R.toLower, R.always(null)),
  R.path(['searchProjects', 'hits', '0', 'serviceType', 'name']),
);

// showConfirmation :: [TableBatchEntry] -> Boolean
const showConfirmation = R.compose(
  R.any(
    R.propSatisfies(
      R.both(
        isTotalsOutOfRange,
        R.complement(propEqLegacy('type', ProjectType.HOUSEKEEPING)),
      ),
      'project',
    ),
  ),
  R.values,
  filterSelectedBatchEntries,
);

// isWoCharge :: [Project] -> Boolean
export const isWoCharge = R.either(
  R.pathSatisfies(R.lt(0), ['0', 'client', 'woCharge']),
  R.compose(
    Boolean,
    R.find(
      R.both(
        propEqLegacy('type', ProjectType.WORK_ORDER),
        R.pathSatisfies(R.lt(0), ['property', 'woCharge']),
      ),
    ),
  ),
);

export function BatchInvoicingPageBody({
  data,
  tableProps,
  loading,
  batchEntriesMap,
  dispatch,
  refetch,
  calculateTotalsProps,
  TableComponent,
  TotalsTable,
  isHousekeeping,
  includeSupplierInvoices,
}) {
  const [isCreateBatchInProgress, setIsCreateBatchInProgress] = useState(false);
  const { openConfirmModal } = useModalContext();

  const [createClientInvoiceBatchMutation] = useMutation(
    createClientInvoiceBatchQuery,
  );
  const { showSuccessNotification } = useNotificationState();

  const inputEntries = getMutationInputByStore(batchEntriesMap);

  const onCreateBatchClick = async () => {
    setIsCreateBatchInProgress(true);

    try {
      await createClientInvoiceBatchMutation({
        variables: {
          input: {
            projects: inputEntries,
            ...(isHousekeeping ? {} : { includeSupplierInvoices }),
            customFileNamePrefix: getFileNamePrefixByBatchData(data),
          },
        },
      });
      return setTimeout(() => {
        showSuccessNotification(
          "Invoice batch successfully created. You'll be notified when PDF is ready",
        );
      }, 2000);
      // backend errors are reported automatically
    } finally {
      // we need delay otherwise refetch doesn't work properly
      // (due to ES index update delay, see useReactiveQuery)
      setTimeout(() => {
        dispatch(createClearInvoicesAction());
        refetch();
        setIsCreateBatchInProgress(false);
      }, 2000);
    }
  };

  const projects = getProjectsByQueryResult(data);

  const toggleRow = (projectId) => {
    const project = projects.find((doc) => doc._id === projectId);
    dispatch(createToggleProjectSelectionAction(project));
  };

  const toggleSelectAll = (isSelectAll) => {
    dispatch(createSelectAllProjectsAction(projects, !isSelectAll));
  };

  const totalsObj =
    getBatchTotalsByStore(calculateTotalsProps)(batchEntriesMap);

  const onCreateBatchWithConfirmationClick = (handleCreateAction) => {
    const handleConfirm = R.is(Function, handleCreateAction)
      ? handleCreateAction
      : onCreateBatchClick;

    if (showConfirmation(batchEntriesMap)) {
      return openConfirmModal({
        cancelBtnCaption: 'No',
        btnCaption: 'Yes',
        id: 'confirmBatchGenerationModal',
        content:
          'Current batch contains invoice(s) that exceed Job Cost amount. Are you sure you want to continue?',
        onConfirm: (closeConfirmModal) => () => {
          closeConfirmModal();
          handleConfirm();
        },
      });
    }
    return handleConfirm();
  };

  return (
    <TableAndPanelWrapper>
      <PayInvoicesTableCard height="calc(100% - 90px)">
        {loading && <Loader />}
        {!loading && projects.length === 0 && <EmptyListMessage />}
        {!loading && projects.length > 0 && (
          <TableComponent
            projects={projects}
            toggleRow={toggleRow}
            toggleSelectAll={toggleSelectAll}
            selectedRows={getSelectedProjectsIds(batchEntriesMap)}
            isWoCharge={isWoCharge(projects)}
            {...tableProps}
          />
        )}
      </PayInvoicesTableCard>
      <BottomPanelContainerS height="90px">
        <TotalsTableWrapper>
          <TotalsTable
            {...totalsObj}
            isWoCharge={isWoCharge(projects)}
            onCreateBatch={onCreateBatchWithConfirmationClick}
            disabled={
              isCreateBatchButtonDisabled(batchEntriesMap) ||
              isCreateBatchInProgress
            }
            loader={isCreateBatchInProgress}
            refetch={refetch}
            dispatch={dispatch}
            inputEntries={inputEntries}
          />
        </TotalsTableWrapper>
      </BottomPanelContainerS>
    </TableAndPanelWrapper>
  );
}

BatchInvoicingPageBody.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  batchEntriesMap: object.isRequired,
  dispatch: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: object,
  // eslint-disable-next-line react/forbid-prop-types
  tableProps: object,
  loading: bool,
  refetch: func.isRequired,
  calculateTotalsProps: arrayOf(string).isRequired,
  TableComponent: func.isRequired,
  TotalsTable: func.isRequired,
  isHousekeeping: bool,
  includeSupplierInvoices: bool,
};
