import { keywordSortQuery, useReactiveQuery } from '@poly/client-utils';
import { useRouterParams } from '@poly/client-routing';
import {
  SupplierStatuses,
  DESC_SORT_ORDER,
  collectionNames,
  ASC_SORT_ORDER,
  peopleStatuses,
} from '@poly/constants';
import {
  UPDATES_SEARCH_CHANGED,
  FILES_SEARCH_CHANGED,
  TASK_SEARCH_CHANGED,
  USER_SEARCH_CHANGED,
  ENTITY_FILES_QUERY,
  ENTITY_TASKS,
  MAX_ITEMS,
  useMasterSupplierBranchesQuery,
  useMasterSuppliersSearchInput,
  usePaginationParams,
  getTasksSubscriptionOptionsByQueryInput,
} from '@poly/admin-ui';

import {
  MASTER_SUPPLIER_PEOPLE_QUERY,
  MASTER_SUPPLIER_UPDATES_QUERY,
} from '../masterSupplierTabs/commonMasterSupplierTabs.js';
import { projectUIStatuses } from '../../../modules/core/constants/projects.js';
import { useMasterSupplierRecurringQuery } from './useMasterSupplierRecurringQuery.js';
import { useMasterSupplierProjectsQuery } from './useMasterSupplierProjectsQuery.js';
import { useMasterSupplierInvoicesQuery } from './useMasterSupplierInvoicesQuery.js';
import { useMasterSupplierDetailsWithInvoicesQuery } from './useMasterSupplierDetailsWithInvoicesQuery.js';

const usePrefetchMasterSupplierPeopleByStatus = (id, status, fetchPolicy) => {
  const searchInput = useMasterSuppliersSearchInput({
    query: {
      bool: {
        must: [
          { match: { status } },
          ...(id
            ? [
                {
                  nested: {
                    path: 'links',
                    query: {
                      term: { 'links.masterSupplierId': id },
                    },
                  },
                },
              ]
            : []),
        ],
      },
    },
    sort: keywordSortQuery(['profile', 'fullName'])(ASC_SORT_ORDER),
  });

  const subscriptionOptions = {
    variables: { searchInput },
    skip: !id,
    fetchPolicy,
  };
  const queryOptions = {
    ...subscriptionOptions,
    variables: {
      ...subscriptionOptions.variables,
      entityId: id,
    },
    skip: !id,
  };

  useReactiveQuery(MASTER_SUPPLIER_PEOPLE_QUERY, USER_SEARCH_CHANGED, {
    queryOptions,
    subscriptionOptions,
  });
};

export const usePrefetchMasterSupplierTabs = () => {
  const { supplierId } = useRouterParams(['supplierId']);
  const fetchPolicy = 'network-only';

  const paginationParams = usePaginationParams();

  const masterSupplierTasksOptions = {
    variables: {
      input: {
        ...paginationParams,
        collection: collectionNames.masterSuppliers,
        documentId: supplierId,
        sort: { complete: 1, createdAt: 1, dueDate: -1 },
      },
    },
    fetchPolicy,
  };

  const masterSupplierUpdatesOptions = {
    variables: {
      id: supplierId,
      documentUpdatesInput: {
        includeAudit: false,
        sort: { createdAt: -1 },
        from: 0,
        size: MAX_ITEMS,
      },
    },
    fetchPolicy,
  };

  const masterSupplierFilesInput = useMasterSuppliersSearchInput({
    sort: { createdAt: DESC_SORT_ORDER },
    query: {
      nested: {
        path: 'relations',
        query: {
          bool: {
            must: [
              {
                match: {
                  'relations.collection': collectionNames.masterSuppliers,
                },
              },
              { match: { 'relations._id': supplierId } },
            ],
          },
        },
      },
    },
  });
  const masterSupplierFilesOptions = {
    variables: { searchInput: masterSupplierFilesInput },
    fetchPolicy,
  };

  const details = useMasterSupplierDetailsWithInvoicesQuery(
    supplierId,
    fetchPolicy,
  );

  useMasterSupplierBranchesQuery(
    supplierId,
    SupplierStatuses.ACTIVE,
    null,
    fetchPolicy,
  );
  useMasterSupplierBranchesQuery(
    supplierId,
    SupplierStatuses.BLOCKED,
    null,
    fetchPolicy,
  );

  useMasterSupplierProjectsQuery(projectUIStatuses.ALL, null, fetchPolicy);
  useMasterSupplierProjectsQuery(
    projectUIStatuses.DUE_TODAY,
    null,
    fetchPolicy,
  );
  useMasterSupplierProjectsQuery(
    projectUIStatuses.DUE_TOMORROW,
    null,
    fetchPolicy,
  );
  useMasterSupplierProjectsQuery(projectUIStatuses.PAST_DUE, null, fetchPolicy);
  useMasterSupplierProjectsQuery(projectUIStatuses.ON_HOLD, null, fetchPolicy);
  useMasterSupplierProjectsQuery(projectUIStatuses.ACTIVE, null, fetchPolicy);

  useMasterSupplierRecurringQuery(projectUIStatuses.ALL, null, fetchPolicy);
  useMasterSupplierRecurringQuery(
    projectUIStatuses.PAST_DUE,
    null,
    fetchPolicy,
  );
  useMasterSupplierRecurringQuery(projectUIStatuses.CLOSED, null, fetchPolicy);
  useMasterSupplierRecurringQuery(projectUIStatuses.ACTIVE, null, fetchPolicy);

  useReactiveQuery(ENTITY_TASKS, TASK_SEARCH_CHANGED, {
    queryOptions: masterSupplierTasksOptions,
    subscriptionOptions: getTasksSubscriptionOptionsByQueryInput(
      masterSupplierTasksOptions.variables.input,
    ),
  });

  useReactiveQuery(MASTER_SUPPLIER_UPDATES_QUERY, UPDATES_SEARCH_CHANGED, {
    queryOptions: masterSupplierUpdatesOptions,
    subscriptionOptions: {
      variables: { searchInput: { documentId: supplierId } },
    },
  });

  usePrefetchMasterSupplierPeopleByStatus(
    supplierId,
    peopleStatuses.ACTIVE,
    fetchPolicy,
  );
  usePrefetchMasterSupplierPeopleByStatus(
    supplierId,
    peopleStatuses.INACTIVE,
    fetchPolicy,
  );

  useReactiveQuery(ENTITY_FILES_QUERY, FILES_SEARCH_CHANGED, {
    queryOptions: masterSupplierFilesOptions,
    subscriptionOptions: {
      ...masterSupplierFilesOptions,
      skip: !supplierId,
    },
  });

  useMasterSupplierInvoicesQuery(null, fetchPolicy);

  return details;
};
