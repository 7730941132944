import * as R from 'ramda';
import React from 'react';
import { TextButton } from '@poly/admin-book';
import {
  arrayOf,
  number,
  shape,
  string,
  instanceOf,
  oneOfType,
} from 'prop-types';
import { calculateTotal } from '@poly/utils';
import { useNotificationState } from '@poly/admin-ui';
import {
  performExcelExport,
  ExcelExportCellType,
  createExcelExportCell,
} from '@poly/client-utils';
import { EXPORT_XLS_CAPTION } from '@poly/constants';

import { formatDateHeader } from '../GeneralLedgerPage/generalLedgerHelpers.js';

// getARReportRow :: ReportEntry => [ExcelExportDataCell]
const getARReportRow = R.compose(
  R.zipWith(createExcelExportCell, [
    ExcelExportCellType.default,
    ExcelExportCellType.defaultCurrency,
  ]),
  R.juxt([R.path(['client', 'name']), R.prop('amount')]),
);

// getTotal :: ReportEntries => Number
const getTotal = calculateTotal(R.prop('amount'));

// eslint-disable-next-line import/no-unused-modules
export const getARReportExportExcelConfig = ({
  reportEntries,
  filterDate,
}) => ({
  exportFileName: 'accounts_receivable_report.xlsx',
  columnWidths: [50, 15],
  rows: [
    [
      {
        value: 'Accounts Receivable Report',
        cellType: ExcelExportCellType.title,
      },
    ],
    [
      {
        cellType: ExcelExportCellType.title,
        value: formatDateHeader(filterDate),
      },
    ],
    R.zipWith(
      createExcelExportCell,
      [
        ExcelExportCellType.tableHeader,
        ExcelExportCellType.tableCurrencyHeader,
      ],
      ['Client', 'Amount'],
    ),
    ...R.map(getARReportRow, reportEntries),
    R.zipWith(
      createExcelExportCell,
      [ExcelExportCellType.default, ExcelExportCellType.defaultCurrency],
      ['Total', getTotal(reportEntries)],
    ),
  ],
});

export function AccountsReceivableReportExcelExportButton({
  reportEntries,
  filter,
  defaultDateValue,
}) {
  const { showErrorNotification } = useNotificationState();

  const onClick = async () => {
    if (R.isEmpty(reportEntries)) {
      showErrorNotification('No records to export');
      return;
    }

    const exportConfig = getARReportExportExcelConfig({
      reportEntries,
      filterDate: filter.date || defaultDateValue,
    });
    performExcelExport(exportConfig);
  };

  return <TextButton onClick={onClick}>{EXPORT_XLS_CAPTION}</TextButton>;
}

AccountsReceivableReportExcelExportButton.propTypes = {
  reportEntries: arrayOf(
    shape({
      amount: number,
      client: shape({
        _id: string,
        name: string,
      }),
    }),
  ),
  filter: shape({
    date: oneOfType([string, instanceOf(Date)]),
  }),
  defaultDateValue: oneOfType([string, instanceOf(Date)]).isRequired,
};
