import * as R from 'ramda';
import styled from 'styled-components';
import React, { Fragment } from 'react';
import { shape, string, arrayOf, object } from 'prop-types';
import { Text, getThemeColor } from '@poly/admin-book';
import {
  tokenizeMessageByMentions,
  highlightMatchesInObject,
} from '@poly/client-utils';

const MentionWrapper = styled.span`
  color: ${getThemeColor(['secondaryMid'])};
`;

const MessageText = styled(Text)`
  white-space: pre-wrap;
`;

// withIndexAsKey :: (Object, Number) -> Object
const withIndexAsKey = R.compose(
  R.converge(R.mergeRight, [
    R.compose(R.identity, R.head),
    R.compose(R.objOf('key'), R.last),
  ]),
  R.take(2),
  Array.of,
);

// eslint-disable-next-line react/prop-types
const renderMessageToken = ({ text, isMention, key }) => {
  const WrapperComponent = isMention ? MentionWrapper : Fragment;
  return <WrapperComponent key={key}>{text}</WrapperComponent>;
};

// renderMessageTokenWithSearchMatches :: String -> (Object, Number) -> Node
const renderMessageTokenWithSearchMatches = (searchText) =>
  R.compose(
    renderMessageToken,
    highlightMatchesInObject,
    R.mergeRight({ search: searchText, paths: [['text']] }),
    R.objOf('object'),
    withIndexAsKey,
  );

// messageShouldIncludeRelatedProject :: String -> Boolean
const messageShouldIncludeRelatedProject = R.either(
  R.includes('This project was cloned from project'),
  R.includes('This project is related to project'),
);

export function MessageWithMentions({
  size,
  message: unsafeMessage,
  mentions,
  lineHeight,
  searchText,
  LinkToParentClone,
}) {
  const message = unsafeMessage || '';
  return (
    <MessageText size={size} lineHeight={lineHeight} color={['black']}>
      {tokenizeMessageByMentions(message, mentions).map(
        renderMessageTokenWithSearchMatches(searchText),
      )}
      {messageShouldIncludeRelatedProject(message) && !!LinkToParentClone && (
        <LinkToParentClone.Component {...LinkToParentClone.props} />
      )}
    </MessageText>
  );
}

export const mentionsShape = shape({
  groups: arrayOf(shape({ name: string.isRequired })),
  users: arrayOf(shape({ fullName: string.isRequired })),
});

MessageWithMentions.defaultProps = {
  size: '12px',
  mentions: {},
  searchText: '',
  lineHeight: '18px',
};

MessageWithMentions.propTypes = {
  size: string,
  searchText: string,
  lineHeight: string,
  mentions: mentionsShape,
  message: string, // Note: message may be null
  // eslint-disable-next-line react/forbid-prop-types
  LinkToParentClone: object,
};
