import React, { useMemo } from 'react';
import { arrayOf, func, object, oneOfType } from 'prop-types';

import { SidebarLogicContext } from './SidebarLogicContext.js';
import { useHistoryLocalStorageLogic } from '../components/appBar/HistoryPopUp/useHistoryLocalStorageLogic.js';
import {
  useNavigateToPropertySidebar,
  PropertyLink,
} from './PropertySidebar/useOpenPropertySidebar.js';
import {
  useNavigateToContactSidebar,
  ContactLink,
} from './ContactSidebar/useOpenContactSidebar.js';
import {
  useNavigateToProjectSidebar,
  ProjectLink,
} from './ProjectSidebar/useOpenProjectSidebar.js';
import {
  useNavigateToClientSidebar,
  ClientLink,
} from './ClientSidebar/useOpenClientSidebar.js';
import {
  useNavigateToUserSidebar,
  UserLink,
} from './UserSidebar/useOpenUserSidebar.js';
import {
  useNavigateToAssetSidebar,
  AssetLink,
} from './AssetSidebar/useOpenAssetSidebar.js';
import { useNavigateToPOSidebar } from './PurchaseOrderSidebar/useNavigateToPOSidebar.js';
import { PurchaseOrderLink } from './PurchaseOrderSidebar/PurchaseOrderLink.js';
import {
  useOpenUserGroupSidebar,
  UserGroupLink,
} from './UserGroupSidebar/useOpenUserGroupSidebar.js';

export function SidebarInnerContext({ children }) {
  const openPropertySidebar = useNavigateToPropertySidebar();
  const openProjectSidebar = useNavigateToProjectSidebar();
  const openContactSidebar = useNavigateToContactSidebar();
  const openClientSidebar = useNavigateToClientSidebar();
  const openAssetSidebar = useNavigateToAssetSidebar();
  const openUserSidebar = useNavigateToUserSidebar();
  const openPOSidebar = useNavigateToPOSidebar();
  const openUserGroupSidebar = useOpenUserGroupSidebar();

  const {
    historyRecords,
    setHistoryRecord,
    removeHistoryRecord,
    clearHistoryRecords,
    updateHistoryRecords,
  } = useHistoryLocalStorageLogic();

  // feel free to add here any sidebar related logic you need inside any sidebar
  // to omit circle imports for recursively sidebars components
  const contextValue = useMemo(
    () => ({
      UserLink,
      AssetLink,
      ClientLink,
      ContactLink,
      ProjectLink,
      PropertyLink,
      UserGroupLink,
      PurchaseOrderLink,
      openUserSidebar,
      openAssetSidebar,
      openClientSidebar,
      openContactSidebar,
      openProjectSidebar,
      openPropertySidebar,
      openUserGroupSidebar,
      historyRecords,
      setHistoryRecord,
      removeHistoryRecord,
      clearHistoryRecords,
      updateHistoryRecords,
      openPOSidebar,
    }),
    [
      historyRecords,
      openUserSidebar,
      openClientSidebar,
      openProjectSidebar,
      openPropertySidebar,
      openPOSidebar,
    ],
  );

  return (
    <SidebarLogicContext.Provider value={contextValue}>
      {children}
    </SidebarLogicContext.Provider>
  );
}

SidebarInnerContext.propTypes = {
  children: oneOfType([
    func,
    object,
    arrayOf(oneOfType([func, object, arrayOf(oneOfType([func, object]))])),
  ]).isRequired,
};
