import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useQuery, gql } from '@apollo/client';
import { propEqLegacy } from '@poly/utils';
import { shape, string, bool, oneOfType, func, object } from 'prop-types';
import { Select, markAsRequired } from '@poly/admin-book';
import { AccountStatuses } from '@poly/constants';

const SelectS = styled(Select)`
  .select__control {
    ${markAsRequired};
  }
`;

// eslint-disable-next-line import/no-unused-modules
export const GET_ACCOUNTS_QUERY = gql`
  query GET_ACCOUNTS($filters: AccountFilters) {
    getAccounts(filters: $filters) {
      hits {
        _id
        code
        name
        accountType {
          _id
          name
          category
          system_type
        }
        childAccounts {
          _id
          code
          name
          accountType {
            _id
            name
            system_type
          }
          is_client_payment_deduction
          is_travel_hq
        }
      }
    }
  }
`;

// prepareAccountLabel :: Account -> String
export const prepareAccountLabel = R.converge(R.concat, [
  R.pipe(R.propOr('', 'code'), R.concat(R.__, ' - ')),
  R.propOr('', 'name'),
]);

// prepareAccountsToSelectBase :: (Account -> Any) -> [Account] -> [PreparedAccountOptions]
// PreparedAccountOptions = {
//    label: String,
//    options: [Options],
// }
// Options = {
//    value: String,
//    label: String,
// }
export const prepareAccountsToSelectBase = (getOptionValue) =>
  R.map(
    R.applySpec({
      label: prepareAccountLabel,
      options: R.compose(
        R.map(
          R.applySpec({
            value: getOptionValue,
            label: prepareAccountLabel,
          }),
        ),
        R.propOr([], 'childAccounts'),
      ),
    }),
  );

// getAccountCode :: Account -> String
const getAccountCode = R.prop('code');

// prepareAccountsToSelect :: [Account] -> [PreparedAccountOptions]
const prepareAccountsToSelect = prepareAccountsToSelectBase(getAccountCode);

// rejectInactiveChildAccounts :: [Account] -> [Account]
const rejectInactiveChildAccounts = R.map((parentAccount) =>
  R.compose(
    R.assoc('childAccounts', R.__, parentAccount),
    R.reject(propEqLegacy('status', AccountStatuses.INACTIVE)),
    R.propOr([], 'childAccounts'),
  )(parentAccount),
);

// getAccountSelectOptionsByData ::
//   ([Account] -> [Account],
//   [Account] -> [SelectOption]) -> Object -> [SelectOption]
//
//   SelectOption = {
//     label: String,
//     value: String,
//     options: SelectOption
//   }
const getAccountSelectOptionsByData = (customFilterFn, formatFn) =>
  R.compose(
    formatFn,
    rejectInactiveChildAccounts,
    customFilterFn,
    R.pathOr([], ['getAccounts', 'hits']),
  );

export function GLCodeSelect({
  meta,
  value,
  isClearable = true,
  filterAccounts = R.identity,
  formatAccountsOptions = prepareAccountsToSelect,
  ...props
}) {
  const { error, touched } = meta;
  const variables = {
    filters: { subAccount: false, status: AccountStatuses.ACTIVE },
  };
  const { data, loading } = useQuery(GET_ACCOUNTS_QUERY, { variables });
  const options = getAccountSelectOptionsByData(
    filterAccounts,
    formatAccountsOptions,
  )(data);

  return (
    <SelectS
      {...props}
      value={value}
      loading={loading}
      options={options}
      blurInputOnSelect
      hideSelectedOptions
      isClearable={isClearable}
      error={touched ? error : ''}
      placeholder="Select GL Code"
      {...(value ? { required: false } : {})}
    />
  );
}

GLCodeSelect.displayName = 'GLCodeSelect';
GLCodeSelect.propTypes = {
  isClearable: bool,
  filterAccounts: func,
  formatAccountsOptions: func,
  value: oneOfType([string, object]),
  meta: shape({
    error: oneOfType([string, bool]),
    visited: bool,
    touched: bool,
  }).isRequired,
};
