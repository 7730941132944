import * as R from 'ramda';
import styled from 'styled-components';
import React, { useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { shape, string, arrayOf, bool, func } from 'prop-types';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import {
  MenuSubItem,
  PopUpWrapper,
  PopUpMenuItemS,
  PopUpContentWrapper,
  PopUpMenuItemCounter,
  PopUpElementContainer,
} from './styles.js';
import {
  popUpPropTypes,
  subItemPropTypes,
  menuPopUpPropTypes,
} from './propTypes.js';
import { Icon } from '../Icon/index.js';
import { Loader } from '../Loader/index.js';
import { MenuLinkWrapper, useMenuPopUpLogic } from './hooks.js';
import { formatRoutePath } from './utils.js';

function SubItemTrigger({ title }) {
  return (
    <MenuSubItem key={title}>
      {title}
      <Icon name="arrow-forward" size={12} color="rgba(30, 36, 44, 0.8)" />
    </MenuSubItem>
  );
}

SubItemTrigger.propTypes = { title: string.isRequired };

function PopUpContent({
  content,
  subItems,
  setIsOpen,
  menuPayload,
  handleOnMount,
  handleChildClick,
  ...props
}) {
  useEffect(() => {
    if (handleOnMount) {
      handleOnMount(menuPayload);
    }
  }, []);

  return (
    <PopUpContentWrapper {...content}>
      {subItems.map(
        ({
          title,
          route,
          onClick,
          inNewTab,
          tab = null,
          CounterComponent,
          permissions,
          typesByProps,
          counterStyles,
          subItems: subSubItems,
          getCountByMenuPayload,
          getLoaderByMenuPayload,
        }) =>
          subSubItems ? (
            <SubSubItems
              key={title}
              closeParent={setIsOpen}
              {...{ subSubItems, ...props }}
              title={title}
              Trigger={SubItemTrigger}
            />
          ) : (
            <MenuLinkWrapper
              key={title}
              dataTestId="menu-link"
              permissions={permissions}
              typesByProps={typesByProps}
              onClick={(e) => {
                handleChildClick(e);
                if (onClick) {
                  onClick();
                }
              }}
              {...(route && {
                href: formatRoutePath(route, tab),
                target: inNewTab ? '_blank' : '_self',
              })}
            >
              <PopUpMenuItemS>
                {title}
                {CounterComponent ? <CounterComponent /> : null}
                {getCountByMenuPayload && (
                  <PopUpMenuItemCounter counterStyles={counterStyles}>
                    {getLoaderByMenuPayload?.(menuPayload) ? (
                      <Loader size={20} />
                    ) : (
                      getCountByMenuPayload(menuPayload)
                    )}
                  </PopUpMenuItemCounter>
                )}
              </PopUpMenuItemS>
            </MenuLinkWrapper>
          ),
      )}
    </PopUpContentWrapper>
  );
}

PopUpContent.propTypes = popUpPropTypes;

function MenuPopUpComp({
  isSub,
  isOpen,
  Trigger,
  className,
  setIsOpen,
  menuPayload,
  onOutsideClick,
  handleParentClick,
  ...props
}) {
  return (
    <PopUpWrapper>
      <OutsideClickHandler {...{ onOutsideClick }}>
        <PopUpElementContainer
          className={className}
          onClick={handleParentClick}
          onMouseEnter={() => (isSub ? setIsOpen(true) : undefined)}
          onMouseLeave={() => (isSub ? setIsOpen(false) : undefined)}
        >
          <Trigger {...{ isSub, isOpen, ...props }} menuPayload={menuPayload} />
          {isOpen ? (
            <PopUpContent {...{ setIsOpen, menuPayload, ...props }} />
          ) : null}
        </PopUpElementContainer>
      </OutsideClickHandler>
    </PopUpWrapper>
  );
}

MenuPopUpComp.propTypes = menuPopUpPropTypes;

export function MenuPopUp({ renderButtonByPermission, permissions, ...props }) {
  const menuProps = useMenuPopUpLogic(props.closeParent);

  const hasAccess = R.compose(
    R.all(R.equals(true)),
    R.map(useHasUserAccessWithPermission),
  )(permissions);

  if (renderButtonByPermission && !hasAccess) {
    return null;
  }

  return <MenuPopUpComp {...props} {...menuProps} />;
}

MenuPopUp.displayName = 'MenuPopUp';
MenuPopUp.propTypes = {
  ...menuPopUpPropTypes,
  permissions: arrayOf(string),
  className: string,
  isSub: bool,
  renderButtonByPermission: bool,
  closeParent: func,
  content: shape({ width: string, position: string }),
};
MenuPopUp.defaultProps = {
  className: '',
  isSub: false,
  renderButtonByPermission: true,
  closeParent: () => undefined,
  content: { width: '195px', position: 'left' },
  permissions: [],
};

const SubItemsPopUp = styled(MenuPopUp)`
  & > div:nth-child(2) {
    left: 170px;
    top: -5px;
  }
`;

function SubSubItems({ subSubItems, ...props }) {
  return <SubItemsPopUp subItems={subSubItems} isSub {...props} />;
}

SubSubItems.propTypes = {
  subSubItems: arrayOf(shape(subItemPropTypes)).isRequired,
};
