import React from 'react';
import * as R from 'ramda';
import { bool, string } from 'prop-types';
import arrayMutators from 'final-form-arrays';
import { useHasUserAccessToProtectedSupplier } from '@poly/client-utils/src/suppliers/useHasUserAccessToUpdateSupplierProtected.js';
import { useHasUserAccessToUpdateSupplierProtected } from '@poly/client-utils';
import { CREATE_SUPPLIER_PERMISSION } from '@poly/security/src/permissions.js';
import { usePristineSubscribe } from '@poly/client-routing';
import { FormPage, Loader } from '@poly/admin-book';

import { supplierFormSections } from '../form/sections/index.js';
import { useOnSubmitSetStopSubmitting } from '../../../../hooks/useOnSubmitSetStopSubmitting.js';
import { prepareSupplierFormDataForMutation } from '../form/prepareFormDataForMutation.js';
import { omitSupplierProtectedFieldsIfNoPermission } from '../edit/formDataToMutation.js';
import { useSupplierTaxValidation } from '../form/useTaxIdValidation.js';
import { initialValuesBySupplierSource } from './addSupplierUtils.js';
import { useCreateSupplier } from './addSupplierMutation.js';

export function AddSupplierForm({
  id,
  rootUrl,
  supplierSource,
  forcedSupplierType,
  masterSupplierRootUrl,
  isSupplierTypeEditable,
  isAddressSectionRequired,
  skipMasterSupplierValidation,
}) {
  const { protectedFieldsAccess, accessLoading } =
    useHasUserAccessToUpdateSupplierProtected();
  const createSupplier = useCreateSupplier(rootUrl);
  const pristineSubscribeProps = usePristineSubscribe();

  const submitHandler = R.compose(
    createSupplier,
    R.mergeDeepRight({ misc: { source: supplierSource } }),
    omitSupplierProtectedFieldsIfNoPermission(protectedFieldsAccess),
    prepareSupplierFormDataForMutation,
  );

  const { onSubmit } = useOnSubmitSetStopSubmitting(id, submitHandler);

  const hasAccessToPrivateSupplier = useHasUserAccessToProtectedSupplier(
    CREATE_SUPPLIER_PERMISSION,
  );

  const validate = useSupplierTaxValidation(
    masterSupplierRootUrl,
    rootUrl,
    skipMasterSupplierValidation,
  );

  const initialValues = initialValuesBySupplierSource(
    supplierSource,
    forcedSupplierType,
  );

  return accessLoading ? (
    <Loader />
  ) : (
    <FormPage
      id={id}
      onSubmit={onSubmit}
      validate={validate}
      mutators={arrayMutators}
      initialValues={initialValues}
      sections={supplierFormSections(
        () => null,
        rootUrl,
        {},
        isSupplierTypeEditable,
        protectedFieldsAccess,
        isAddressSectionRequired,
        { supplierSource, hasAccessToPrivateSupplier },
      )}
      {...pristineSubscribeProps}
    />
  );
}

AddSupplierForm.propTypes = {
  supplierSource: string.isRequired,
  rootUrl: string.isRequired,
  masterSupplierRootUrl: string,
  forcedSupplierType: string,
  id: string.isRequired,
  skipMasterSupplierValidation: bool,
  isSupplierTypeEditable: bool,
  isAddressSectionRequired: bool,
};

AddSupplierForm.defaultProps = {
  isAddressSectionRequired: true,
};
