import React from 'react';
import { func, string, node } from 'prop-types';

import { useProcessState } from '../../../hooks/useProcessState.js';
import { useOnSubmitSetStopSubmitting } from '../../../hooks/useOnSubmitSetStopSubmitting.js';
import { ConfirmModalLayoutC } from './ConfirmModalLayoutC.js';

export function ConfirmSubmitFormModalLayout({
  onConfirm,
  closeCurrentModal,
  processId,
  children,
  ...config
}) {
  const { process } = useProcessState(processId);
  const confirmHandler = onConfirm(closeCurrentModal);
  const { onSubmit } = useOnSubmitSetStopSubmitting(processId, confirmHandler);

  return (
    <ConfirmModalLayoutC
      closeCurrentModal={closeCurrentModal}
      handleReject={closeCurrentModal}
      loading={process}
      onConfirm={onSubmit}
      {...config}
    >
      {children}
    </ConfirmModalLayoutC>
  );
}

ConfirmSubmitFormModalLayout.propTypes = {
  closeCurrentModal: func.isRequired,
  onConfirm: func.isRequired,
  processId: string.isRequired,
  children: node.isRequired,
};
