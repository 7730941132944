import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useReactiveQuery } from '@poly/client-utils';
import { commonTaskDocumentFields, TASK_SEARCH_CHANGED } from '@poly/admin-ui';

const GET_TASKS_QUERY = gql`
  query GET_TASKS_QUERY($input: TasksInput!) {
    tasks(input: $input) {
      hits {
        _id
        dueDate
        description
        complete
        action
        collection
        documentId
        priority
        supplierInvoice {
          _id
          status
        }
        document {
          ...commonTaskDocumentFields
        }
      }
    }
  }

  ${commonTaskDocumentFields}
`;

export const useMyTasksQuery = ({ managerId, ...restInput }) => {
  const options = {
    variables: {
      input: {
        managerId,
        sort: {
          priority: -1,
          dueDate: 1,
        },
        ...restInput,
      },
    },
    skip: !managerId,
    fetchPolicy: 'network-only',
  };

  const { data, loading } = useReactiveQuery(
    GET_TASKS_QUERY,
    TASK_SEARCH_CHANGED,
    {
      queryOptions: options,
      subscriptionOptions: {
        skip: !managerId,
        variables: {
          searchInput: {
            query: managerId ? { term: { managerId } } : null,
          },
        },
      },
    },
  );

  const tasks = R.pathOr([], ['tasks', 'hits'], data);

  return { tasks, loading };
};
