import React from 'react';

import { AccountTypesPageHeader } from './AccountTypesPageHeader.js';
import { AccountTypesTable } from './AccountTypesTable.js';

export function AccountTypesPage() {
  return (
    <>
      <AccountTypesPageHeader />
      <AccountTypesTable />
    </>
  );
}
