import React from 'react';
import styled from 'styled-components';
import arrayMutators from 'final-form-arrays';
import { useMutation, gql } from '@apollo/client';
import { usePristineSubscribe } from '@poly/client-routing';
import { useOutSidebarContext } from '@poly/client-utils';
import { InvoiceTypes } from '@poly/constants';
import { FormCreator } from '@poly/admin-book';
import { alwaysNewDate } from '@poly/utils';
import {
  commonSidebarFormSectionLayout,
  useOnSubmitSetStopSubmitting,
  commonSidebarFormFieldLayout,
  commonSidebarFormLayout,
  useNotificationState,
} from '@poly/admin-ui';

import { addSupplierInvoicesFormId } from '../constants.js';
import { addSupplierInvoicesFormSections } from './addSupplierInvoicesFormSections.js';
import { prepareSupplierInvoicesForMutation } from './prepareSupplierInvoicesForMutation.js';

const AddSupplierInvoicesFormS = styled(FormCreator)`
  overflow: auto;
  padding: 14px 24px;
  height: calc(100vh - 190px);
`;

const CREATE_MULTIPLE_INVOICES_MUTATION = gql`
  mutation CREATE_MULTIPLE_INVOICES_MUTATION(
    $input: CreateMultipleInvoicesInput!
  ) {
    createMultipleInvoices(input: $input) {
      invoicesIds
    }
  }
`;

export function AddSupplierInvoicesForm() {
  const { closeOutSidebar } = useOutSidebarContext();
  const { showSuccessNotification } = useNotificationState();
  const pristineSubscribeProps = usePristineSubscribe({
    id: addSupplierInvoicesFormId,
  });
  const [createMultipleInvoices] = useMutation(
    CREATE_MULTIPLE_INVOICES_MUTATION,
  );

  const initialValues = {
    invoices: [{}],
    invoiceFile: [],
    isSingleDescription: true,
    type: InvoiceTypes.breakdown,
    invoiceDate: alwaysNewDate(),
  };

  const onSubmitHandler = async (formData) => {
    const input = prepareSupplierInvoicesForMutation(formData);

    await createMultipleInvoices({ variables: { input } });

    showSuccessNotification('Supplier Invoices were successfully created');
    closeOutSidebar(addSupplierInvoicesFormId);
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    addSupplierInvoicesFormId,
    onSubmitHandler,
  );

  return (
    <AddSupplierInvoicesFormS
      onSubmit={onSubmit}
      mutators={arrayMutators}
      initialValues={initialValues}
      id={addSupplierInvoicesFormId}
      layout={commonSidebarFormLayout}
      sections={addSupplierInvoicesFormSections}
      fieldLayout={commonSidebarFormFieldLayout}
      sectionLayout={commonSidebarFormSectionLayout}
      {...pristineSubscribeProps}
    />
  );
}
