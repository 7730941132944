import { gql } from '@apollo/client';
import React, { useState } from 'react';
import { useTableInfiniteScrollQuery } from '@poly/client-utils';
import { TableCardWithoutTabs } from '@poly/admin-book';

import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { JournalEntryLedgerTable } from './JournalEntryLedgerTable.js';
import { JournalEntryLedgerHeader } from './JournalEntryLedgerHeader.js';

const journalEntriesQuery = gql`
  query JournalEntries($input: JournalEntryLedgerInput!) {
    getManualJournals(input: $input) {
      hits {
        _id
        transaction_number
        date
        reverse_journal {
          _id
          date
        }
        description
        type
        lines {
          cash_amount
          accrual_amount
          description
          payment_status
          reconciled_at
          account {
            code
            name
          }
        }
        created_by {
          _id
          profile {
            fullName
          }
        }
      }
      total
    }
  }
`;

export function JournalEntryLedgerPage() {
  const [query, setQuery] = useState({});

  const { data, loading, tableProps, refetch } = useTableInfiniteScrollQuery(
    journalEntriesQuery,
    query,
    {
      pageSize: 50,
      endpointName: 'getManualJournals',
    },
  );

  return (
    <PageWithSearchHeader headerHeight="114px">
      <JournalEntryLedgerHeader
        query={query}
        setQuery={setQuery}
        data={data}
        loading={loading}
      />
      <TableCardWithoutTabs>
        <JournalEntryLedgerTable
          data={data}
          loading={loading}
          refetch={refetch}
          tableProps={tableProps}
          isPrint={false}
        />
      </TableCardWithoutTabs>
    </PageWithSearchHeader>
  );
}
