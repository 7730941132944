import {
  arrayOf,
  number,
  shape,
  string,
  instanceOf,
  oneOfType,
} from 'prop-types';
import { generalLedgerEntryPropType } from '../GeneralLedgerPage/generalLedgerPropTypes.js';

export const trialBalanceReportPropTypes = shape({
  entries: arrayOf(generalLedgerEntryPropType).isRequired,
  current_year_earnings: number.isRequired,
  debits_total: number.isRequired,
  credits_total: number.isRequired,
});

export const dateRangePropType = shape({
  startDate: oneOfType([string, instanceOf(Date)]).isRequired,
  endDate: oneOfType([string, instanceOf(Date)]).isRequired,
});

export const trialBalanceFiltersPropTypes = shape({
  date: dateRangePropType.isRequired,
  accountingMethod: string.isRequired,
});
