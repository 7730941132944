import * as R from 'ramda';

export * from './project-ui-status.js';
export * from './queries.js';

/**
 * isChildRecurringProject :: Project -> Boolean
 */
export const isChildRecurringProject = R.pathSatisfies(R.is(String), [
  'parent',
  '_id',
]);
