import styled from 'styled-components';
import { Checkbox, getThemeColor } from '@poly/admin-book';

export const SearchProjectsHeaderCheckbox = styled(Checkbox)`
  width: 150px;
  color: ${({ disabled, ...props }) =>
    disabled ? getThemeColor(['secondary'])(props) : 'initial'};
`;

SearchProjectsHeaderCheckbox.displayName = 'SearchProjectsHeaderCheckbox';
