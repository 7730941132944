import * as R from 'ramda';
import { useQuery } from '@apollo/client';
import React, { useState, useCallback } from 'react';
import { bool, oneOfType, shape, string } from 'prop-types';
import { SupplierTypes } from '@poly/constants';
import { Select } from '@poly/admin-book';
import { debounce } from '@poly/utils';
import {
  composeSearchQueriesIntoBoolMust,
  DEFAULT_MASTER_SUPPLIER_QUERY,
  MASTER_SUPPLIERS_SELECT_QUERY,
  getSupplierSearchQueryByName,
  SEARCH_SUPPLIERS_QUERY,
  DEFAULT_SUPPLIER_QUERY,
} from '@poly/admin-ui';

// appendDefaultValue :: String -> QueryData -> [Supplier] -> [Supplier]
const appendDefaultValue = (propName, defaultData) =>
  R.when(R.always(defaultData), R.append(R.prop(propName, defaultData)));

// getSupplierSelectOptionsBase :: Boolean -> [Supplier] -> [SupplierSelectOption]
// SupplierSelectOption = {label: String, value: {_id: String, isMaster: Boolean}}
const getSupplierSelectOptionsBase = (isMaster) =>
  R.map(
    R.applySpec({
      value: R.compose(R.assoc('isMaster', isMaster), R.pick(['_id'])),
      label: R.path(['company', 'name']),
    }),
  );

// getSupplierAndMasterSupplierSelectOptions :: (SearchSuppliersQueryData ->  SearchMasterSuppliersQueryData
// -> SuppliersQueryData -> MasterSuppliersQueryData -> String) -> [SupplierSelectOption]
const getSupplierAndMasterSupplierSelectOptions = (
  supplierData,
  masterSupplierData,
  defaultSupplierData,
  defaultMasterSupplierData,
  searchText,
) =>
  R.compose(
    R.unnest,
    R.juxt([
      R.compose(
        getSupplierSelectOptionsBase(false),
        appendDefaultValue('supplier', defaultSupplierData),
        R.pathOr([], [0, 'searchSuppliers', 'hits']),
      ),
      R.compose(
        getSupplierSelectOptionsBase(true),
        appendDefaultValue('masterSupplier', defaultMasterSupplierData),
        R.pathOr([], [1, 'searchMasterSuppliers', 'hits']),
      ),
    ]),
    R.when(R.always(searchText.length === 0), R.always([])),
  )([supplierData, masterSupplierData]);

export function SupplierAndMasterSupplierSelect({ value, ...props }) {
  const [searchTextForQuery, setSearchTextForQuery] = useState('');

  const setSearchTextForQueryDeb = useCallback(
    debounce(400)((text) => setSearchTextForQuery(text)),
    [],
  );

  const onInputChange = (text) => setSearchTextForQueryDeb(text);

  const query = composeSearchQueriesIntoBoolMust([
    getSupplierSearchQueryByName(searchTextForQuery),
    { term: { type: SupplierTypes.SUBCONTRACTOR } },
  ]);

  const queryOptions = {
    variables: {
      searchInput: {
        from: 0,
        size: 15,
        query,
      },
    },
    fetchPolicy: 'cache-first',
    skip: !searchTextForQuery,
  };

  const { data: supplierData } = useQuery(SEARCH_SUPPLIERS_QUERY, queryOptions);

  const { data: masterSupplierData } = useQuery(
    MASTER_SUPPLIERS_SELECT_QUERY,
    queryOptions,
  );

  const defaultQueryOptions = {
    variables: {
      id: value?._id,
    },
    fetchPolicy: 'network-only',
  };

  const { data: defaultSupplier } = useQuery(DEFAULT_SUPPLIER_QUERY, {
    ...defaultQueryOptions,
    skip: !value?._id || value?.isMaster,
  });

  const { data: defaultMasterSupplier } = useQuery(
    DEFAULT_MASTER_SUPPLIER_QUERY,
    {
      ...defaultQueryOptions,
      skip: !value?._id || !value?.isMaster,
    },
  );

  const options = getSupplierAndMasterSupplierSelectOptions(
    supplierData,
    masterSupplierData,
    defaultSupplier,
    defaultMasterSupplier,
    searchTextForQuery,
  );

  const selectProps = {
    ...props,
    value,
    options,
    onInputChange,
    placeholder: 'Start typing supplier',
  };

  return <Select {...selectProps} />;
}

SupplierAndMasterSupplierSelect.propTypes = {
  value: oneOfType([
    shape({
      _id: string,
      isMaster: bool,
    }),
    string,
  ]),
};
