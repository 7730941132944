import React from 'react';
import { arrayOf, func, objectOf, string } from 'prop-types';

import { HIERARCHY_TREE_TYPE } from '../constants.js';
import {
  hasFirstChildrenProperty,
  hasFirstChildrenRegion,
  isPropertyNode,
  isRegionNode,
} from '../utils.js';
import { Tree } from '../../../components/Tree/Tree.js';
import { PropertyTreeNode } from './PropertyTreeNode.js';
import { treeNodePropTypes } from '../../../components/Tree/propTypes.js';
import { isSingleNodeTree } from '../../../components/Tree/utils.js';

// canDrop :: { node: TreeNode, nexParent: TreeNode } -> Boolean
const canDrop = ({ node, nextParent }) => {
  if (!nextParent) {
    return false;
  }
  if (isPropertyNode(nextParent)) {
    return false;
  }
  if (isPropertyNode(node)) {
    return !hasFirstChildrenRegion(nextParent);
  }
  if (isRegionNode(node)) {
    return !hasFirstChildrenProperty(nextParent);
  }
  return true;
};

export function PropertyHierarchyTree({
  treeData,
  validationErrors,
  setTreeData,
  onChangeNodeTitleHandler,
  onAddNodeHandler,
  onRemoveNodeHandler,
}) {
  const singleNodeTree = isSingleNodeTree(treeData);

  return (
    <Tree
      dndType={HIERARCHY_TREE_TYPE}
      nodeContentRenderer={PropertyTreeNode}
      hideRootLine={singleNodeTree}
      canDrop={canDrop}
      treeData={treeData}
      validationErrors={validationErrors}
      setTreeData={setTreeData}
      onChangeNodeTitleHandler={onChangeNodeTitleHandler}
      onAddNodeHandler={onAddNodeHandler}
      onRemoveNodeHandler={onRemoveNodeHandler}
    />
  );
}

PropertyHierarchyTree.propTypes = {
  treeData: arrayOf(treeNodePropTypes),
  setTreeData: func.isRequired,
  onChangeNodeTitleHandler: func.isRequired,
  onAddNodeHandler: func.isRequired,
  onRemoveNodeHandler: func.isRequired,
  validationErrors: objectOf(string),
};
