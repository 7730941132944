import React, { useState } from 'react';
import { useRedirectIfHasNoPermissions } from '@poly/client-utils/src/hooks/useHasUserAccessWithPermission.js';
import { READ_QUOTE_DISCREPANCIES_PERMISSION } from '@poly/security/src/permissions.js';
import { Loader } from '@poly/admin-book/src/Loader/index.js';

import { useSuppliersQuoteDiscrepanciesReportQuery } from './useSuppliersQuoteDiscrepanciesReportQuery.js';
import { QuoteDiscrepanciesPageHeader } from '../QuoteDiscrepancies/QuoteDiscrepanciesPageHeader.js';
import { QuoteDiscrepanciesContentWrapperS } from '../QuoteDiscrepancies/QuoteDiscrepanciesPage.js';
import { QuoteDiscrepanciesPageTable } from '../QuoteDiscrepancies/QuoteDiscrepanciesPageTable.js';
import { getSuppliersQuoteDiscrepanciesTable } from './getSuppliersQuoteDiscrepanciesTable.js';
import { prepareSupplierQuoteDiscrepanciesFooterData } from '../QuoteDiscrepancies/helpers.js';
import { QuoteDiscrepanciesPageFooter } from '../QuoteDiscrepancies/QuoteDiscrepanciesPageFooter.js';
import { getSupplierQuoteDiscrepanciesXlsConfig } from './getSupplierQuoteDiscrepanciesXlsConfig.js';

export function SuppliersQuoteDiscrepanciesPage() {
  useRedirectIfHasNoPermissions([READ_QUOTE_DISCREPANCIES_PERMISSION]);

  const [queryInput, setQueryInput] = useState(null);

  const { tableRows, loading, tableSortingProps } =
    useSuppliersQuoteDiscrepanciesReportQuery(queryInput);

  return (
    <>
      <QuoteDiscrepanciesPageHeader
        setQueryInput={setQueryInput}
        tableRows={tableRows}
        title="Quote Discrepancies by Supplier Report"
        getXLSConfig={getSupplierQuoteDiscrepanciesXlsConfig}
        getTableConfig={getSuppliersQuoteDiscrepanciesTable}
        prepareFooterData={prepareSupplierQuoteDiscrepanciesFooterData}
        withSupplierSelect
      />
      <QuoteDiscrepanciesContentWrapperS>
        {loading ? (
          <Loader />
        ) : (
          <QuoteDiscrepanciesPageTable
            tableRows={tableRows}
            tableSortingProps={tableSortingProps}
            getTableConfig={getSuppliersQuoteDiscrepanciesTable}
            prepareFooterData={prepareSupplierQuoteDiscrepanciesFooterData}
          />
        )}
      </QuoteDiscrepanciesContentWrapperS>
      <QuoteDiscrepanciesPageFooter
        tableRows={tableRows}
        prepareFooterData={prepareSupplierQuoteDiscrepanciesFooterData}
      />
    </>
  );
}
