import React from 'react';
import styled from 'styled-components';
import {
  UploadFilesBtn,
  Text,
  IconButton,
  defaultTheme,
} from '@poly/admin-book';
import { oneOfType, func, shape, string } from 'prop-types';

const FileNameWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const {
  colors: { accent1Lighter1 },
} = defaultTheme;

const ChooseFileFieldWrapper = styled.div`
  width: 100%;
`;

const TextS = styled(Text)`
  word-break: break-all;
`;

export function ChooseFileField({ onChange, value: file, name }) {
  return (
    <ChooseFileFieldWrapper>
      <UploadFilesBtn
        text="Choose File"
        onChange={(e) => onChange(e.target.files[0])}
        id={name}
      />
      <FileNameWrapper>
        <TextS size="13px" color={['secondary']}>
          {file.name || file.fileName}
        </TextS>
        {file && (
          <IconButton
            onClick={() => onChange(null)}
            name="close"
            size={8}
            initialColor={accent1Lighter1}
            hoverColor={accent1Lighter1}
          />
        )}
      </FileNameWrapper>
    </ChooseFileFieldWrapper>
  );
}

ChooseFileField.propTypes = {
  onChange: func.isRequired,
  value: oneOfType([string, shape({ name: string, fileName: string })])
    .isRequired,
  name: string.isRequired,
};
