import React from 'react';
import * as R from 'ramda';
import { func, oneOfType, object } from 'prop-types';
import { WhiteCard } from '@poly/admin-book';

import { AddEntityProjectBtn } from './ToolBar.js';
import { ProjectOccurrence } from '../../core/constants/projects.js';

export function EntityProjectsTabComp({
  TabsWithPrefetch,
  getAddEntityProjectQuery,
  ...props
}) {
  return (
    <WhiteCard>
      <TabsWithPrefetch
        toolBar={
          <AddEntityProjectBtn
            addEntityProjectQueries={getAddEntityProjectQuery(props)}
          />
        }
        {...props}
      />
    </WhiteCard>
  );
}

EntityProjectsTabComp.propTypes = {
  getAddEntityProjectQuery: func.isRequired,
  TabsWithPrefetch: oneOfType([func, object]).isRequired,
};

export function EntityRecurringProjectsTabComp({
  getAddEntityProjectQuery,
  ...restProps
}) {
  const getAddEntityProjectQueryHandler = R.compose(
    R.mergeRight({ type: ProjectOccurrence.RECURRING_OCCURRENCE }),
    getAddEntityProjectQuery,
  );

  return (
    <EntityProjectsTabComp
      {...restProps}
      getAddEntityProjectQuery={getAddEntityProjectQueryHandler}
    />
  );
}

EntityRecurringProjectsTabComp.displayName = 'EntityRecurringProjectsTabComp';

EntityRecurringProjectsTabComp.propTypes = {
  getAddEntityProjectQuery: func.isRequired,
  TabsWithPrefetch: oneOfType([func, object]).isRequired,
};
