import React from 'react';
import * as R from 'ramda';
import { arrayOf, func, shape, string, object } from 'prop-types';

import { LinkButtonS, ListWrapper } from './styles.js';

export function GoogleMapList({ suppliers, getRef, google }) {
  return (
    <ListWrapper>
      {suppliers.map((supplier) => (
        <LinkButtonS
          key={supplier._id}
          onClick={() =>
            google.maps.event.trigger(getRef(supplier._id).marker, 'click')
          }
        >
          {R.path(['company', 'name'], supplier)}
        </LinkButtonS>
      ))}
    </ListWrapper>
  );
}

GoogleMapList.propTypes = {
  suppliers: arrayOf(
    shape({
      _id: string.isRequired,
      company: shape({ name: string }),
    }),
  ).isRequired,
  getRef: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  google: shape({ maps: object }).isRequired,
};
