import * as R from 'ramda';
import { useMemo } from 'react';
import { isNilOrEmpty } from '@poly/utils';
import { useQuery, gql } from '@apollo/client';
import { DESC_SORT_ORDER } from '@poly/constants';
import { useTableSorting } from '@poly/admin-ui';

import { getTableConfig } from '../table-config.js';

const QUOTE_DISCREPANCIES_REPORT_QUERY = gql`
  query QUOTE_DISCREPANCIES_REPORT_QUERY(
    $input: QuoteDiscrepanciesReportInput!
  ) {
    quoteDiscrepanciesReport(input: $input) {
      _id
      number
      amount
      discrepancy
      discrepancyPercentage
      project {
        _id
        type
        nte
        projectId
        suppliers {
          _id
          statusInProject
          company {
            name
          }
        }
      }
    }
  }
`;

export const quoteDiscrepanciesInputQueryProps = [
  'clientId',
  'propertyId',
  'startDate',
  'endDate',
  'percentage',
];

// QuoteDiscrepancies :: ({ queryInput: Filters, sort: Pair String SortObject }, [String])
// -> QuoteDiscrepanciesReportInput
export const prepareQuoteDiscrepanciesQueryInput = (filter, props) =>
  R.compose(
    R.mergeAll,
    R.juxt([
      R.ifElse(
        R.propSatisfies(R.isNil, 'queryInput'),
        R.always({}),
        R.compose(R.pick(props), R.reject(isNilOrEmpty), R.prop('queryInput')),
      ),
      R.compose(
        R.objOf('sort'),
        R.map(
          R.compose(
            R.ifElse(R.equals(DESC_SORT_ORDER), R.always(1), R.always(-1)),
            R.prop('order'),
          ),
        ),
        R.last,
        R.prop('sort'),
      ),
    ]),
  )(filter);

// prepareTableRows :: { quoteDiscrepanciesReport: [QuoteDiscrepanciesReportRow] } -> [QuoteDiscrepanciesReportRow]
const prepareTableRows = R.propOr([], 'quoteDiscrepanciesReport');

export const useQuoteDiscrepanciesReportQuery = (queryInput) => {
  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: getTableConfig(),
    order: DESC_SORT_ORDER,
    column: 6,
  });

  const skip = !queryInput?.endDate || !queryInput?.startDate;
  const input = useMemo(
    () =>
      prepareQuoteDiscrepanciesQueryInput(
        { sort, queryInput },
        quoteDiscrepanciesInputQueryProps,
      ),
    [sort, queryInput],
  );

  const { data, loading } = useQuery(QUOTE_DISCREPANCIES_REPORT_QUERY, {
    variables: { input },
    fetchPolicy: 'network-only',
    skip,
  });

  const tableRows = useMemo(() => prepareTableRows(data), [data]);

  return { tableRows, loading, tableSortingProps };
};
