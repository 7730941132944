import * as R from 'ramda';
import { addHours, isBefore } from 'date-fns';
import { JournalPaymentStatus } from '@poly/constants';
import {
  formatCurrency,
  ensureIsDate,
  formatTotal,
  renameProp,
  assocBy,
  propEqLegacy,
} from '@poly/utils';

import { JournalEntryLineTypes } from './constants.js';

// getJournalLineAmount :: Line -> Number
const getJournalLineAmount = R.ifElse(
  R.propSatisfies(R.isNil, 'cash_amount'),
  R.prop('accrual_amount'),
  R.prop('cash_amount'),
);

// calculateJournalEntryTotalByType :: String -> [FormLine] -> String
export const calculateJournalEntryTotalByProp = (propName) =>
  R.compose(
    formatTotal,
    R.reduce(R.add, 0),
    R.reject(R.isNil),
    R.map(R.prop(propName)),
  );

// calculateJournalEntryTotalByType :: JournalEntryLineType -> FormData -> String
export const calculateJournalEntryTotalByType = (type) =>
  R.compose(
    R.reduce(R.add, 0),
    R.reject(R.isNil),
    R.map(R.prop('cash_amount')),
    R.filter(propEqLegacy('type', type)),
    R.propOr([], 'lines'),
  );

// extractLinesAccountCodes :: (Number -> Boolean) -> JournalLine -> String
export const extractLinesAccountCodes = (lineAmountChecker) =>
  R.compose(
    R.join(', '),
    R.map(R.path(['accountCode', 'code'])),
    R.filter(lineAmountChecker),
    R.propOr([], 'lines'),
  );

// prepareReceiptFile :: AccountFormLine
// AccountFormLine = {
//    accountId: String
//    description: String
//    netAmount: Money
//    receiptFile: {
//      fileName: String
//      upload: Upload
//    }
// }
const prepareReceiptFile = R.compose(
  R.mergeAll,
  R.juxt([
    R.omit(['receiptFile']),
    R.ifElse(
      R.propSatisfies(R.isEmpty, 'receiptFile'),
      R.always({}),
      R.compose(
        R.objOf('receiptFile'),
        R.pick(['fileName', 'upload']),
        R.head,
        R.prop('receiptFile'),
      ),
    ),
  ]),
);

// convertFormJournalsToMutationInput :: FormData -> FormData
const convertFormJournalsToMutationInput = R.over(
  R.lensProp('lines'),
  R.map(
    R.compose(
      prepareReceiptFile,
      R.omit(['debitAmount', 'creditAmount', 'accountCode']),
      assocBy('account_id', R.path(['accountCode', '_id'])),
      R.ifElse(
        R.prop('debitAmount'),
        assocBy('netAmount', R.prop('debitAmount')),
        assocBy('netAmount', R.pipe(R.prop('creditAmount'), R.negate)),
      ),
    ),
  ),
);

// validateJournalEntryForm :: FormData -> ErrorObj
export const validateJournalEntryForm = (formData) => {
  const isTotalEqual = R.converge(R.equals, [
    calculateJournalEntryTotalByProp('debitAmount'),
    calculateJournalEntryTotalByProp('creditAmount'),
  ])(formData.lines);
  const isEndDateInvalid =
    !formData.never && isBefore(formData.endDate, formData.startDate);
  return {
    ...(isTotalEqual ? {} : { total: 'Debits must equal credits' }),
    ...(isEndDateInvalid
      ? { endDate: 'End Date should be greater than Start Date' }
      : {}),
  };
};

// formatJournalEntryMutationData :: FormData -> CreateJournalEntryInput
export const formatJournalEntryMutationData = R.compose(
  // need to add 1 hour to date to fix 1 January edge case,
  R.over(R.lensProp('date'), (date) => addHours(ensureIsDate(date), 1)),
  R.reject(R.isNil),
  R.pick(['lines', 'date', 'accounting_method', 'description', 'client_id']),
  convertFormJournalsToMutationInput,
);

// formatRecurringJournalEntryMutationData :: FormData -> CreateJournalEntryInput
export const formatRecurringJournalEntryMutationData = R.compose(
  R.converge(R.mergeRight, [
    R.applySpec({
      endDate: R.ifElse(
        R.either(R.prop('never'), R.complement(R.prop('endDate'))),
        R.always(null),
        R.prop('endDate'),
      ),
    }),
    R.pick([
      'lines',
      'accounting_method',
      'startDate',
      'schedule',
      'status',
      'client_id',
    ]),
  ]),
  convertFormJournalsToMutationInput,
);

// checkLinesForLogic :: Function -> Journal -> Boolean
const checkLinesForLogic = (checker) =>
  R.compose(
    R.complement(R.isNil),
    R.find(checker),
    R.defaultTo([]),
    R.prop('lines'),
  );

// prepareJournalEntryLedgerLines :: JournalEntryQueryData -> [String] -> [JournalEntry]
export const prepareJournalEntryLinesByPath = (path) =>
  R.compose(
    R.map(
      R.compose(
        assocBy(
          'hasReconciledStatus',
          checkLinesForLogic(
            propEqLegacy('payment_status', JournalPaymentStatus.RECONCILED),
          ),
        ),
        assocBy(
          'wasReconciled',
          checkLinesForLogic(
            R.both(
              propEqLegacy('payment_status', JournalPaymentStatus.RECONCILED),
              R.propSatisfies(R.complement(R.isNil), 'reconciled_at'),
            ),
          ),
        ),
        R.over(
          R.lensProp('lines'),
          R.map(
            R.compose(
              renameProp('account', 'accountCode'),
              R.over(
                R.lensProp('cash_amount'),
                R.when(R.gt(0), (amount) => Math.abs(amount)),
              ),
              R.ifElse(
                R.propSatisfies(R.gt(0), 'cash_amount'),
                R.assoc('type', JournalEntryLineTypes.CREDIT),
                R.assoc('type', JournalEntryLineTypes.DEBIT),
              ),
              R.omit(['accrual_amount']),
              assocBy('cash_amount', getJournalLineAmount),
            ),
          ),
        ),
      ),
    ),
    R.pathOr([], path),
  );

// prepareJournalDataForTable :: Journal -> [JournalEntryTableRow]
export const prepareJournalDataForTable = ({ lines, ...restData }) =>
  R.compose(
    R.append({
      _id: restData.transaction_number,
      debitAmount: calculateJournalEntryTotalByProp('debitAmount')(lines),
      creditAmount: calculateJournalEntryTotalByProp('creditAmount')(lines),
    }),
    R.when(
      R.complement(R.isEmpty),
      R.over(R.lensIndex(0), R.mergeDeepLeft(restData)),
    ),
    R.map(
      R.compose(
        R.mergeAll,
        R.juxt([
          R.pick(['accountCode', 'description']),
          R.ifElse(
            R.prop('creditAmount'),
            R.compose(
              R.objOf('creditAmount'),
              formatCurrency,
              R.prop('creditAmount'),
            ),
            R.compose(
              R.objOf('debitAmount'),
              formatCurrency,
              R.prop('debitAmount'),
            ),
          ),
          R.compose(
            R.objOf('_id'),
            R.join('_'),
            R.juxt([
              R.prop('accountCode'),
              R.ifElse(
                R.prop('debitAmount'),
                R.always(['debit']),
                R.always(['credit']),
              ),
            ]),
          ),
          R.always({ date: restData.date }),
        ]),
        R.over(
          R.lensProp('accountCode'),
          R.compose(
            R.join(' - '),
            R.juxt([R.propOr('', 'code'), R.propOr('', 'name')]),
          ),
        ),
      ),
    ),
  )(lines);

// renameNetAmountProp :: JournalEntry -> JournalEntry
export const renameNetAmountProp = R.over(
  R.lensProp('lines'),
  R.map(
    R.compose(
      R.dissoc('type'),
      R.ifElse(
        propEqLegacy('type', JournalEntryLineTypes.CREDIT),
        renameProp('cash_amount', 'creditAmount'),
        renameProp('cash_amount', 'debitAmount'),
      ),
    ),
  ),
);
