import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { UPDATE_CLIENT_PERMISSION } from '@poly/security';
import { SidebarRow } from '@poly/admin-ui';

import { TabSectionWrapper } from '../commonComponents.js';
import { HousekeepingCalendarList } from './HousekeepingCalendarList.js';
import { EditHousekeepingCalendarBtn } from './EditHousekeepingCalendarBtn.js';

const SidebarRowS = styled(SidebarRow)`
  position: relative;

  > div {
    width: 100%;
  }

  > button {
    right: 22px;
  }
`;

export function HousekeepingCalendarSidebarTab(props) {
  const hasEditPermission = useHasUserAccessWithPermission(
    UPDATE_CLIENT_PERMISSION,
  );

  const { clientId, clientName, housekeepingCalendar } = R.applySpec({
    clientId: R.path(['client', '_id']),
    clientName: R.path(['client', 'name']),
    housekeepingCalendar: R.pathOr(
      [],
      ['client', 'configs', 'housekeepingCalendar'],
    ),
  })(props);

  return (
    <TabSectionWrapper>
      <SidebarRowS justify>
        <HousekeepingCalendarList housekeepingCalendar={housekeepingCalendar} />
        {hasEditPermission && (
          <EditHousekeepingCalendarBtn
            clientId={clientId}
            clientName={clientName}
            housekeepingCalendar={housekeepingCalendar}
          />
        )}
      </SidebarRowS>
    </TabSectionWrapper>
  );
}
