import React from 'react';
import { useOutSidebarContext } from '@poly/client-utils';
import styled from 'styled-components';

import { SidebarIDs } from '../../../sidebars/constants.js';
import { CustomSidebarLayout } from '../../../sidebars/components/CustomSidebarLayout.js';
import { AccountingPeriodsUpdatesSidebar } from './AccountingPeriodsUpdatesSidebar.js';

const CustomSidebarLayoutS = styled(CustomSidebarLayout)`
  padding-top: 0;
`;

export const useOpenAccountingPeriodsUpdatesSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();

  return () => {
    openOutSidebar({
      id: SidebarIDs.requestedSupplierDocument,
      content: <AccountingPeriodsUpdatesSidebar />,
      Layout: CustomSidebarLayoutS,
      width: 500,
    });
  };
};
