import { useUpdateQueryParams } from '@poly/client-routing/src/hooks.js';

import { SidebarIDs } from '../constants.js';
import { useClearPristineState } from '../useClearPristineOnSidebarMount.js';

export const useNavigateToPOSidebar = () => {
  const updateQueryParams = useUpdateQueryParams();
  const clearPristine = useClearPristineState();

  return (purchaseOrderId) => {
    clearPristine();
    updateQueryParams({ [SidebarIDs.purchaseOrder]: purchaseOrderId });
  };
};
