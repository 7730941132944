import * as R from 'ramda';
import {
  pathEqLegacy,
  convertCentsToDollars,
  formatTotal,
  roundTo,
} from '@poly/utils';

// prepareQuoteDiscrepanciesFooterDataBase :: [String] -> [QuoteDiscrepanciesReportRow]
// -> { averageDiscrepancy: Number, totalDiscrepancy: String }
const prepareQuoteDiscrepanciesFooterDataBase = (ntePath) =>
  R.applySpec({
    averageDiscrepancy: R.compose(
      roundTo(2),
      R.ifElse(R.isEmpty, R.always(0), R.mean),
      R.map(R.propOr(0, 'discrepancyPercentage')),
      R.reject(
        R.either(pathEqLegacy(ntePath, 0), R.pathSatisfies(R.isNil, ntePath)),
      ),
    ),
    totalDiscrepancy: R.compose(
      formatTotal,
      convertCentsToDollars,
      R.sum,
      R.map(R.propOr(0, 'discrepancy')),
    ),
  });

// prepareClientQuoteDiscrepanciesFooterData :: [QuoteDiscrepanciesReportRow] -> { averageDiscrepancy: Number, totalDiscrepancy: String }
export const prepareClientQuoteDiscrepanciesFooterData =
  prepareQuoteDiscrepanciesFooterDataBase(['project', 'nte']);

// prepareSupplierQuoteDiscrepanciesFooterData  :: [SupplierQuoteDiscrepanciesReportRow] -> { averageDiscrepancy: Number, totalDiscrepancy: String }
export const prepareSupplierQuoteDiscrepanciesFooterData =
  prepareQuoteDiscrepanciesFooterDataBase(['supplierNTE']);
