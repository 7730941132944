import styled from 'styled-components';
import { Loader } from '@poly/admin-book';
import React from 'react';
import { bool, node } from 'prop-types';
import { FlexColumn, FlexContainer } from './FlexContainer.js';

const ToolbarContainer = styled(FlexContainer)`
  width: 100%;
  height: 45px;
  border-bottom: 1px solid rgba(197, 198, 201, 0.35);
  position: relative;
  padding-bottom: 10px;
  align-items: flex-end;
`;

const TableContainer = styled(FlexContainer)`
  width: 100%;
  height: calc(100% - 45px);
`;

const TableWithToolBarContainer = styled(FlexColumn)`
  height: 100%;
  width: 100%;
  max-width: 1424px;
`;

const ToolBarWrapper = styled(FlexContainer)`
  position: absolute;
  right: 0;
`;

export function TableWithToolbarContainer({
  title,
  toolbar,
  children,
  loading,
}) {
  return (
    <TableWithToolBarContainer>
      <ToolbarContainer>
        {title}
        <ToolBarWrapper>{toolbar}</ToolBarWrapper>
      </ToolbarContainer>
      <TableContainer>{loading ? <Loader /> : children}</TableContainer>
    </TableWithToolBarContainer>
  );
}

TableWithToolbarContainer.propTypes = {
  toolbar: node,
  children: node.isRequired,
  loading: bool,
  title: node,
};
