import { Field } from 'react-final-form';
import React, { memo, useEffect, useRef } from 'react';
import { shape, number, func, string } from 'prop-types';
import { Input } from '@poly/admin-book';

import {
  getCalcWidth,
  CustomRateField,
  CustomRateFieldGroup,
} from './commonComponents.js';
import { RateInputField } from './RateInputField.js';
import { DeleteLineIcon } from '../../../components/DeleteLineIcon.js';

export const CustomRateLine = memo(({ name, index, value, remove, update }) => {
  const firstUpdate = useRef(false);

  useEffect(() => {
    if (firstUpdate.current) {
      update(index, {
        ...value,
        overtime: value.rate * 1.5,
        doubleTime: value.rate * 2,
      });
    }
  }, [value.rate]);

  useEffect(() => {
    firstUpdate.current = true;
  }, []);

  return (
    <CustomRateFieldGroup>
      <DeleteLineIcon name="delete" size={13} onClick={() => remove(index)} />
      <CustomRateField width={getCalcWidth(34)}>
        <Field
          name={`${name}.description`}
          validate={(descriptionValue) =>
            !descriptionValue && 'Description is required'
          }
        >
          {({ input, meta }) => (
            <Input
              {...input}
              maxLength={25}
              meta={meta}
              required
              error={meta.touched ? meta.error : undefined}
              dataTestId={`${name}.description`}
            />
          )}
        </Field>
      </CustomRateField>
      <CustomRateField width={getCalcWidth(22)}>
        <RateInputField
          name={`${name}.rate`}
          requiredName="Rate"
          dataTestId={`${name}.rate`}
        />
      </CustomRateField>
      <CustomRateField width={getCalcWidth(22)}>
        <RateInputField
          name={`${name}.overtime`}
          requiredName="Overtime"
          dataTestId={`${name}.overtime`}
        />
      </CustomRateField>
      <CustomRateField width={getCalcWidth(22)}>
        <RateInputField
          name={`${name}.doubleTime`}
          requiredName="Double Time"
          dataTestId={`${name}.doubleTime`}
        />
      </CustomRateField>
    </CustomRateFieldGroup>
  );
});

CustomRateLine.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  value: shape({ rate: number, overtime: number, doubleTime: number }),
  remove: func.isRequired,
  update: func.isRequired,
  dataTestId: string,
};
