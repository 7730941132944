import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { gql, useMutation } from '@apollo/client';
import { func, string } from 'prop-types';
import { Text } from '@poly/admin-book/src/Text/index.js';
import { FormCreator } from '@poly/admin-book/src/Form/FormCreator.js';
import { commonSidebarFormLayout } from '@poly/admin-ui/src/sidebar/commonSidebarFormConstants.js';
import { alwaysNewDate } from '@poly/utils/src/dates.js';
import { ScheduleRepeats } from '@poly/constants/src/schedule.js';
import { usePristineSubscribe } from '@poly/client-routing/src/hooks.js';

import { reportSendingConfigurationFormSections } from './reportSendingConfigurationFormSections.js';
import { useReportSendingConfigurationDetailQuery } from './useReportSendingConfigurationDetailQuery.js';
import {
  getReportSendingConfigurationInitialValues,
  prepareReportSendingConfigurationInput,
} from './utils.js';

export const reportSendingConfigsFormId = 'reportSendingConfigsFormId';

const Wrapper = styled.div`
  height: 80vh;
  margin: 0 24px;
`;

const SunTitle = styled(Text)`
  font-size: 14px;
`;

const reportSendingConfigurationInitialValues = {
  schedule: {
    repeats: ScheduleRepeats.MONTHLY,
    weekDay: false,
    every: 1,
    days: [],
  },
  startDate: alwaysNewDate(),
  emails: [],
  reportFilter: {
    ageTo: null,
    ageFrom: null,
  },
};

const createReportSendingConfigurationMutation = gql`
  mutation createReportSendingConfigurationMutation(
    $input: CreateReportSendingConfigurationInput!
  ) {
    createReportSendingConfiguration(input: $input) {
      _id
    }
  }
`;

const updateReportSendingConfigurationMutation = gql`
  mutation updateReportSendingConfigurationMutation(
    $id: ID!
    $input: UpdateReportSendingConfigurationInput!
  ) {
    updateReportSendingConfiguration(id: $id, input: $input) {
      _id
    }
  }
`;

export function ReportSendingConfigurationForm({ clientId, onClose }) {
  const { data, loading } = useReportSendingConfigurationDetailQuery(clientId);

  const pristineSubscribeProps = usePristineSubscribe({
    id: reportSendingConfigsFormId,
  });

  const [createReportSendingConfiguration] = useMutation(
    createReportSendingConfigurationMutation,
  );

  const [updateReportSendingConfiguration] = useMutation(
    updateReportSendingConfigurationMutation,
  );

  const configId = R.path(['reportSendingConfiguration', '_id'], data);

  const handleSubmit = async (values) => {
    const mutate = configId
      ? updateReportSendingConfiguration
      : createReportSendingConfiguration;

    await mutate({
      variables: {
        input: {
          ...prepareReportSendingConfigurationInput(values),
          ...(!configId ? { clientId } : {}),
        },
        ...(configId ? { id: configId } : {}),
      },
    });
    onClose();
  };

  if (loading) {
    return null;
  }

  const initialValues = configId
    ? getReportSendingConfigurationInitialValues(data)
    : reportSendingConfigurationInitialValues;

  return (
    <Wrapper>
      <SunTitle>
        The reports to the client will be sent based on the configs set here.
      </SunTitle>
      <FormCreator
        onSubmit={handleSubmit}
        id={reportSendingConfigsFormId}
        initialValues={initialValues}
        layout={commonSidebarFormLayout}
        sectionLayout={{ label: { size: '16px' } }}
        sections={reportSendingConfigurationFormSections}
        {...pristineSubscribeProps}
      />
    </Wrapper>
  );
}

ReportSendingConfigurationForm.propTypes = {
  clientId: string.isRequired,
  onClose: func.isRequired,
};
