import React from 'react';
import * as R from 'ramda';
import { node } from 'prop-types';
import { SentryLink } from 'apollo-link-sentry';
import { ApolloProvider } from '@apollo/client';
import { Provider as ReduxProvider } from 'react-redux';
import {
  useUserLogout,
  handleApplicationMaintenance,
} from '@poly/client-utils';
import { ADMIN_SETUP_2FA_PAGE_PATH } from '@poly/constants';
import { createApolloClient } from '@poly/apollo-client';
import { history } from '@poly/client-routing';
import {
  ThemeProvider as PolyBookThemeProvider,
  defaultTheme,
} from '@poly/admin-book';
import {
  createApolloErrorHandlerForAdminUI,
  CommonPrintLayout,
  ModalProvider,
} from '@poly/admin-ui';

import { store } from './store.js';
import { routesNames } from './routes/index.js';
import { authRoutesList } from './routes/constants.js';

export function StoreProvider({ children }) {
  return <ReduxProvider store={store}>{children}</ReduxProvider>;
}

StoreProvider.propTypes = {
  children: node,
};

export function ThemeProvider(props) {
  return <PolyBookThemeProvider {...props} theme={defaultTheme} />;
}

const defaultAdminUIErrorHandler = createApolloErrorHandlerForAdminUI({
  store,
  useVendorInfo: true,
  with2FASetup: true,
});

const allowedRoutesList = [...authRoutesList, ADMIN_SETUP_2FA_PAGE_PATH];

// shouldLogout :: String -> Boolean
const shouldLogout = R.compose(
  R.complement(R.includes(R.__, allowedRoutesList)),
  R.when(R.includes('set-password'), R.always(routesNames.RESET_PASSWORD)),
);

export function ApolloClientProvider({ children }) {
  const logOutUser = useUserLogout();

  const onTokenHasExpired = () => {
    if (shouldLogout(history.location.pathname)) {
      logOutUser({ localStorage, loginRoute: routesNames.LOGIN });
    }
  };

  const apolloClient = createApolloClient(
    onTokenHasExpired,
    defaultAdminUIErrorHandler,
    handleApplicationMaintenance,
    {
      additionalAfterWareLinks: process.env.SENTRY_DSN
        ? [
            new SentryLink({
              attachBreadcrumbs: {
                includeVariables: true,
              },
            }),
          ]
        : [],
    },
  );

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}

ApolloClientProvider.propTypes = {
  children: node,
};

export function PrintLayoutProvider(props) {
  return (
    <ApolloClientProvider>
      <ThemeProvider>
        <StoreProvider>
          <ModalProvider>
            <CommonPrintLayout {...props} />
          </ModalProvider>
        </StoreProvider>
      </ThemeProvider>
    </ApolloClientProvider>
  );
}
