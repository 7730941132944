import React, { memo } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import {
  string,
  number,
  shape,
  func,
  bool,
  instanceOf,
  arrayOf,
} from 'prop-types';
import {
  Icon,
  TextButton,
  FieldLayout,
  InputErrorMsg,
  getThemeColor,
  createGetFormFieldWidth,
  TimePicker,
} from '@poly/admin-book';

// define 4 columns with 5px between
const getFormColumnWidth = createGetFormFieldWidth(4, 5);

// getCommonError :: Error :: Index -> String
const getCommonError = (error, index) =>
  R.compose(
    R.ifElse(
      R.allPass([
        R.has('common'),
        R.propSatisfies(R.isNil, 'timeIn'),
        R.propSatisfies(R.isNil, 'timeOut'),
      ]),
      R.prop('common'),
      R.always(''),
    ),
    R.defaultTo({}),
    R.prop(index),
  )(error);

const FieldContainerS = styled.div`
  width: ${getFormColumnWidth(1)};
`;

const EntryControlsContainerS = styled.div`
  width: ${getFormColumnWidth(2)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TimesheetEntryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TimesheetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ErrorWrapper = styled(InputErrorMsg)`
  margin: -20px 0 15px 0;
`;

const DeleteTimeEntryIcon = styled(Icon).attrs((props) => ({
  color: getThemeColor(['secondaryMid'])(props),
}))``;

function TimesheetEntryC({
  name,
  timeIn,
  commonError,
  onRemoveItem,
  onAddNewItem,
  isAddNewItemVisible,
  isRemoveItemVisible,
}) {
  return (
    <TimesheetWrapper>
      <TimesheetEntryContainer>
        <FieldContainerS>
          <FieldLayout
            label="Time In"
            field={{
              name: `${name}.timeIn`,
              Component: TimePicker,
            }}
            required
          />
        </FieldContainerS>
        <FieldContainerS>
          <FieldLayout
            label="Time Out"
            field={{
              name: `${name}.timeOut`,
              Component: TimePicker,
              withFormData: true,
            }}
            required
            values={{ timeIn }}
          />
        </FieldContainerS>
        <EntryControlsContainerS>
          {isRemoveItemVisible && (
            <DeleteTimeEntryIcon
              name="delete"
              size={14}
              onClick={onRemoveItem}
            />
          )}
          {isAddNewItemVisible && (
            <TextButton onClick={onAddNewItem}>
              Add Another Time In/Time Out
            </TextButton>
          )}
        </EntryControlsContainerS>
      </TimesheetEntryContainer>
      {!!commonError && <ErrorWrapper>{commonError}</ErrorWrapper>}
    </TimesheetWrapper>
  );
}

TimesheetEntryC.propTypes = {
  name: string.isRequired,
  onRemoveItem: func.isRequired,
  onAddNewItem: func.isRequired,
  commonError: string.isRequired,
  isRemoveItemVisible: bool.isRequired,
  isAddNewItemVisible: bool.isRequired,
  timeIn: instanceOf(Date),
};

export const TimesheetEntry = memo(({ name, index, fields }) => {
  const { value: values, remove, push, error } = fields;

  const timeIn = values[index]?.timeIn;

  const isRemoveItemVisible = index > 0;
  const isAddNewItemVisible = index === values.length - 1;

  const commonError = getCommonError(error, index);

  const onRemoveItem = () => remove(index);
  const onAddNewItem = () => push({});

  return (
    <TimesheetEntryC
      name={name}
      timeIn={timeIn}
      commonError={commonError}
      onRemoveItem={onRemoveItem}
      onAddNewItem={onAddNewItem}
      isAddNewItemVisible={isAddNewItemVisible}
      isRemoveItemVisible={isRemoveItemVisible}
    />
  );
});

TimesheetEntry.displayName = 'TimesheetEntry';

TimesheetEntry.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  fields: shape({
    value: arrayOf(
      shape({
        timeIn: instanceOf(Date),
      }),
    ).isRequired,
    remove: func.isRequired,
    push: func.isRequired,
  }),
};
