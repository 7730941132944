import {
  getYear,
  getDate,
  isBefore,
  endOfDay,
  isFuture,
  getMonth,
  startOfDay,
} from 'date-fns';
import React from 'react';
import * as R from 'ramda';
import DayPicker from 'react-day-picker';
import styled, { css } from 'styled-components';
import { instanceOf, oneOfType, string, object, bool, func } from 'prop-types';
import { formatSelectOptionsByConstants } from '@poly/client-utils';
import { ensureIsDate } from '@poly/utils';

import { Months } from './constants.js';
import { Select } from '../Select/Select.js';
import { DatePickerContainer } from './styles.js';
import { getThemeColor } from '../utils.js';

const leftBorder = css`
  border-left: 1px solid ${getThemeColor(['primaryLighter8'])};
`;

const DatePickerContainerS = styled(DatePickerContainer)`
  box-shadow: none;

  .DayPicker-Day--disabled {
    background-color: ${getThemeColor(['primaryLighter8'])};
  }

  .DayPicker-Day {
    border-radius: 3px;
    margin: 0 4px;
    padding: 0 4px;
  }

  .DayPicker-Week > .DayPicker-Day.DayPicker-Day--selected {
    margin: 0 4px;
    padding: 0 4px;
  }

  ${({ single }) => !single && leftBorder};
`;

const CaptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const CaptionElementWrapper = styled.div`
  display: flex;
  margin: 0 5px;
`;

// getYearsOptions :: Date -> [Option]
// we start from 2019 - approved by Tony
const getYearsOptions = R.compose(
  R.map(R.applySpec({ value: R.identity, label: R.identity })),
  R.range(2019),
  R.inc,
  getYear,
  ensureIsDate,
);

function CaptionDayPickerComponent({ disabledBefore, value, onChange }) {
  const appropriatedDay = disabledBefore
    ? getDate(ensureIsDate(disabledBefore))
    : 1;
  const appropriatedMonth = disabledBefore
    ? getMonth(ensureIsDate(disabledBefore))
    : 0;

  const disableMonthIf = (month) => {
    const monthDate = endOfDay(
      new Date(getYear(ensureIsDate(value)), month, 31),
    );
    return isBefore(ensureIsDate(monthDate), disabledBefore);
  };

  const disableYearIf = (year) => {
    const monthDate = endOfDay(new Date(year, 11, 31));
    return isBefore(ensureIsDate(monthDate), disabledBefore);
  };

  const onMonthChange = (month) =>
    onChange(new Date(getYear(ensureIsDate(value)), month, appropriatedDay));

  const onYearChange = (year) =>
    onChange(new Date(year, appropriatedMonth, appropriatedDay));
  return (
    <CaptionWrapper>
      <CaptionElementWrapper>
        <Select
          width="130px"
          name="MonthSelect"
          allowModalOverflow
          onChange={onMonthChange}
          hideSelectedOptions={false}
          disableOptionIf={disableMonthIf}
          options={formatSelectOptionsByConstants(Months)}
          value={R.toString(getMonth(ensureIsDate(value)))}
        />
      </CaptionElementWrapper>
      <CaptionElementWrapper>
        {getDate(ensureIsDate(value))}
      </CaptionElementWrapper>
      <CaptionElementWrapper>
        <Select
          width="100px"
          name="YearSelect"
          allowModalOverflow
          onChange={onYearChange}
          hideSelectedOptions={false}
          disableOptionIf={disableYearIf}
          value={getYear(ensureIsDate(value))}
          options={getYearsOptions(new Date())}
        />
      </CaptionElementWrapper>
    </CaptionWrapper>
  );
}

CaptionDayPickerComponent.propTypes = {
  disabledBefore: instanceOf(Date),
  onChange: func.isRequired,
  value: instanceOf(Date).isRequired,
};

export function ProgressiveDayPicker({
  value,
  single,
  onChange,
  className,
  disabledBefore,
  isDisabledDays,
}) {
  const disabledDays = (day) =>
    isBefore(ensureIsDate(day), startOfDay(ensureIsDate(disabledBefore))) ||
    isFuture(ensureIsDate(day));

  const onDayClick = (date, modifiers, e) => {
    if (!modifiers.disabled) {
      onChange(date, e);
    }
  };

  return (
    <DatePickerContainerS {...{ single, className }}>
      <DayPicker
        month={value}
        showOutsideDays
        selectedDays={value}
        onDayClick={onDayClick}
        disabledDays={isDisabledDays ? disabledDays : null}
        captionElement={
          <CaptionDayPickerComponent
            disabledBefore={disabledBefore}
            value={value}
            onChange={onChange}
          />
        }
      />
    </DatePickerContainerS>
  );
}

ProgressiveDayPicker.displayName = 'ProgressiveDayPicker';
ProgressiveDayPicker.propTypes = {
  single: bool,
  onChange: func.isRequired,
  disabledBefore: instanceOf(Date),
  value: instanceOf(Date).isRequired,
  className: oneOfType([string, object]),
  isDisabledDays: bool,
};
