export const propertyStatuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const invoiceToTypes = {
  MAIN_ACCOUNT: 'main_account',
  BRANCH_ACCOUNT: 'branch_account',
};

export const propertyOccupancyTypes = {
  vacant: 'vacant',
  occupied: 'occupied',
};

export const propertyOwnershipTypes = {
  leased: 'leased',
  owned: 'owned',
};

export const propertyTiers = [1, 2, 3, 4, 5];

export const propertyAffiliates = {
  TIAA: 'TIAA',
  GBS: 'GBS',
  KASPICK: 'KASPICK',
  Covariance: 'Covariance',
  Churchill: 'Churchill',
};

export const propertyTypes = {
  OFFC: 'OFFC',
  RETL: 'RETL',
  EXEC: 'EXEC',
};

export const PROPERTY_SUPPLIER_RELATION_TYPES = {
  OCR_INVOICE: 'OCR_INVOICE',
  PROJECT: 'PROJECT',
  DIRECT: 'DIRECT',
};
