import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';

const propertyHierarchyQuery = gql`
  query propertyHierarchyQuery($clientId: ID!, $propertyId: ID!) {
    client(id: $clientId) {
      _id
      allPropertyHierarchies(id: $propertyId) {
        _id
        name
        description
        nodes {
          id
          name
          parentId
          properties {
            _id
            name
          }
        }
      }
    }
  }
`;
// getHierarchies :: PropertyHierarchyQuery -> [PropertyHierarchy]
const getHierarchies = R.path(['client', 'allPropertyHierarchies']);

export const usePropertyHierarchies = (clientId, propertyId) => {
  const { data, loading, refetch } = useQuery(propertyHierarchyQuery, {
    variables: { clientId, propertyId },
  });

  return { hierarchies: getHierarchies(data), loading, refetch };
};
