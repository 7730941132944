import { gql } from '@apollo/client';

export const USER_ALERTS_SEARCH_CHANGED = gql`
  subscription USER_ALERTS_SEARCH_CHANGED(
    $searchInput: CollectionSearchParams!
  ) {
    searchUserAlertChanged(input: $searchInput) {
      type
      id
    }
  }
`;
