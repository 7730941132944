import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string, shape, number, instanceOf } from 'prop-types';
import { Table, Cell, moneyColumnStyles } from '@poly/admin-book';
import { useMapConfigToTableProps } from '@poly/admin-ui';
import { Link } from '@poly/client-routing';
import {
  insertQueryParamsIntoURL,
  insertParamIntoURL,
  calculateTotal,
  formatTotal,
  assocBy,
} from '@poly/utils';

import { routesNames } from '../../routes/index.js';
import { SupplierLink } from '../../components/Links.js';
import { accountsPayableEntriesPropTypes } from './prop-types.js';
import { VendorLedgerTypes } from '../VendorLedgerReport/constants.js';

const AccountsPayableReportTableS = styled(Table)`
  ${moneyColumnStyles(2)};
  th:nth-child(2),
  td:nth-child(2) {
    width: 300px;
  }

  tfoot > tr > td {
    font-weight: bold;
  }
`;

// generateAPReportLink :: DateTime -> Supplier -> String
const generateAPReportLink = (date) =>
  R.compose(
    insertQueryParamsIntoURL({
      type: VendorLedgerTypes.PAYABLE,
      date: date.toDateString(),
    }),
    insertParamIntoURL('vendorId')(routesNames.VENDOR_LEDGER_REPORT),
    R.prop('_id'),
  );

function APAgingReportLink({ supplier, amount, date }) {
  const href = generateAPReportLink(date)(supplier);

  return (
    <Link href={href} target="_blank">
      {formatTotal(amount)}
    </Link>
  );
}

APAgingReportLink.propTypes = {
  supplier: shape({ _id: string.isRequired }),
  date: instanceOf(Date),
  amount: number,
};

const accountsPayableTableConfig = [
  [
    'Supplier',
    ({ supplier }) => (
      <SupplierLink
        _id={supplier._id}
        name={supplier.company.name}
        target="_blank"
      />
    ),
  ],
  ['Amount', APAgingReportLink],
];

// assignIdsToReportEntries :: [AccountsPayableReportEntry] -> [AccountsPayableReportEntry]
const assignIdsToReportEntries = R.compose(
  R.map(assocBy('_id', R.path(['supplier', '_id']))),
  R.defaultTo([]),
);

// calculateAPEntriesTotal :: [AccountsPayableReportEntry] -> Number
const calculateAPEntriesTotal = calculateTotal(R.propOr(0, 'amount'));

function AccountsPayableTotalFooter({ rows }) {
  const total = calculateAPEntriesTotal(rows);
  return (
    <tr>
      <Cell>Total</Cell>
      <Cell>{formatTotal(total)}</Cell>
    </tr>
  );
}

AccountsPayableTotalFooter.propTypes = {
  rows: accountsPayableEntriesPropTypes.isRequired,
};

export function AccountsPayableReportTable({ data, ...restTableOptions }) {
  const { rows, headers, columns, sortQueries } = useMapConfigToTableProps(
    assignIdsToReportEntries,
    accountsPayableTableConfig,
    data,
  );

  return (
    <AccountsPayableReportTableS
      headers={headers}
      rows={rows}
      columns={columns}
      sortQueries={sortQueries}
      TableFooter={AccountsPayableTotalFooter}
      moreScrollIconPosition="20px"
      overflow="auto"
      showScrollBar
      {...restTableOptions}
    />
  );
}

AccountsPayableReportTable.propTypes = {
  data: accountsPayableEntriesPropTypes.isRequired,
};
