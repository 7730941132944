import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from '@poly/client-routing';
import { ELASTIC_SCORE_FIELD, propertyStatuses } from '@poly/constants';
import { number, string, bool, func, shape } from 'prop-types';
import { defaultTheme, Loader, Status } from '@poly/admin-book';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { UPDATE_PROPERTY_HIERARCHY_PERMISSION } from '@poly/security';
import {
  SidebarRow,
  SidebarSubTabs,
  SearchInputComp,
  useSearchTabLogic,
  SidebarTabTableWrapper,
} from '@poly/admin-ui';
import {
  formatCount,
  forceTitleCase,
  insertQueryParamsIntoURL,
} from '@poly/utils';

import { routesNames } from '../../../../routes/constants.js';
import { PropertyTabs } from './constants.js';
import { useAddPropertySidebar } from '../../../PropertySidebar/forms/add/useAddPropertySidebar.js';
import {
  useClientPropertiesCountQuery,
  useClientPropertiesQuery,
} from '../useClientPropertiesQuery.js';
import { PropertiesExportToXLSBtn } from './PropertiesExportToXLSBtn.js';
import {
  SectionLabel,
  ToolbarButton,
  DoubleTabsWrapper,
  CommonSidebarSubEntityTable,
} from '../../../components/commonSidebarComponents.js';
import { useOnImportAssetsSidebar } from '../../../AssetSidebar/forms/import/useOnImportAssetsSidebar.js';

const {
  colors: {
    statuses: { active, inactive },
  },
} = defaultTheme;

const PropertyStatusColor = {
  [propertyStatuses.ACTIVE]: active,
  [propertyStatuses.INACTIVE]: inactive,
};

const ToolBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

function AddClientPropertyButton({
  isCard,
  clientId,
  buttonTitle,
  parentProperty,
  isMaster,
  right,
}) {
  const openAddPropertySidebar = useAddPropertySidebar(isCard);

  const onAddProperty = () =>
    openAddPropertySidebar({ clientId, parentProperty, isMaster });

  return (
    <ToolbarButton right={right} onClick={onAddProperty}>
      {buttonTitle}
    </ToolbarButton>
  );
}

AddClientPropertyButton.propTypes = {
  right: string,
  isCard: bool,
  clientId: string,
  buttonTitle: string,
  parentProperty: shape({}),
  isMaster: bool.isRequired,
};

function ClientPropertiesTabTitle({
  status,
  clientId,
  searchTerm,
  parentProperty,
}) {
  const count = useClientPropertiesCountQuery({
    clientId,
    status,
    searchTerm,
    parentProperty,
  });

  const title = `${forceTitleCase(status)} (${formatCount(count)})`;

  return <Status color={PropertyStatusColor[status]} text={title} />;
}

ClientPropertiesTabTitle.propTypes = {
  searchTerm: string,
  status: string.isRequired,
  clientId: string.isRequired,
  parentProperty: shape({}),
};

const ClientSubTabTableS = styled(CommonSidebarSubEntityTable)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 20px;
    padding-left: 0;
  }
  th:last-child,
  td:last-child {
    width: 110px;
  }
`;

function ClientPropertiesTab({
  status,
  clientId,
  searchTerm,
  setSort,
  setTotalCount,
  parentProperty,
}) {
  const { loading, tableProps, sort, count } = useClientPropertiesQuery(
    clientId,
    status,
    searchTerm,
    parentProperty,
  );

  useEffect(() => {
    if (setSort) {
      setSort(sort);
    }
  }, [sort, setSort]);

  useEffect(() => {
    if (setTotalCount) {
      setTotalCount(count);
    }
  }, [count, setTotalCount]);

  if (loading) return <Loader />;

  return <ClientSubTabTableS {...tableProps} isCollapsibleTable />;
}

ClientPropertiesTab.propTypes = {
  searchTerm: string,
  status: string.isRequired,
  clientId: string.isRequired,
  parentProperty: shape({}),
  setSort: func,
  setTotalCount: func,
};

const LinkS = styled(Link)`
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  right: ${R.prop('right')};
`;

function ClientHierarchiesLink({ clientId, isClientProperties }) {
  const hasAccess = useHasUserAccessWithPermission(
    UPDATE_PROPERTY_HIERARCHY_PERMISSION,
  );

  if (!hasAccess) {
    return null;
  }

  const right = isClientProperties ? '240px' : '110px';

  return (
    <LinkS
      right={right}
      href={insertQueryParamsIntoURL(
        { clientId },
        routesNames.PROPERTY_HIERARCHY_MANAGEMENT,
      )}
    >
      Manage Hierarchy
    </LinkS>
  );
}

ClientHierarchiesLink.propTypes = {
  clientId: string.isRequired,
  isClientProperties: bool,
};

export function ClientSidebarPropertiesTab({
  title,
  isCard,
  clientId,
  doubleTabWidth,
  parentProperty,
  isClientProperties,
}) {
  const [sort, setSort] = useState([ELASTIC_SCORE_FIELD]);
  const [totalCount, setTotalCount] = useState(0);
  const { searchTerm, ...inputProps } = useSearchTabLogic('properties');
  const openImportAssetSidebar = useOnImportAssetsSidebar();

  const onImportAsset = () => openImportAssetSidebar({ clientId });

  const commonProps = {
    clientId,
    searchTerm,
    setSort,
    setTotalCount,
    parentProperty,
  };

  return (
    <DoubleTabsWrapper width={doubleTabWidth} isCard={isCard}>
      <SidebarRow justify align>
        <SectionLabel>{title}</SectionLabel>
        <ToolBarWrapper>
          <PropertiesExportToXLSBtn
            clientId={clientId}
            searchTerm={searchTerm}
            totalCount={totalCount}
            parentProperty={parentProperty}
            sort={sort}
          />
          <SearchInputComp {...inputProps} />
        </ToolBarWrapper>
      </SidebarRow>
      <SidebarTabTableWrapper offset={48}>
        <SidebarSubTabs
          tabs={[
            [
              <ClientPropertiesTabTitle
                {...commonProps}
                status={propertyStatuses.ACTIVE}
              />,
              PropertyTabs.ACTIVE,
              <ClientPropertiesTab
                {...commonProps}
                status={propertyStatuses.ACTIVE}
              />,
            ],
            [
              <ClientPropertiesTabTitle
                {...commonProps}
                status={propertyStatuses.INACTIVE}
              />,
              PropertyTabs.INACTIVE,
              <ClientPropertiesTab
                {...commonProps}
                status={propertyStatuses.INACTIVE}
              />,
            ],
          ]}
          defaultValue={PropertyTabs.ACTIVE}
          toolBar={
            <>
              <ClientHierarchiesLink
                isClientProperties={isClientProperties}
                clientId={clientId}
              />
              {!parentProperty && (
                <ToolbarButton right="360px" onClick={onImportAsset}>
                  Import Assets to All Properties
                </ToolbarButton>
              )}
              {isClientProperties && (
                <AddClientPropertyButton
                  isMaster
                  right="110px"
                  isCard={isCard}
                  clientId={clientId}
                  buttonTitle="Add Master Property"
                  parentProperty={parentProperty}
                />
              )}
              <AddClientPropertyButton
                isCard={isCard}
                isMaster={false}
                clientId={clientId}
                buttonTitle="Add Property"
                parentProperty={parentProperty}
              />
            </>
          }
        />
      </SidebarTabTableWrapper>
    </DoubleTabsWrapper>
  );
}

ClientSidebarPropertiesTab.propTypes = {
  title: string,
  isCard: bool,
  parentProperty: shape({}),
  clientId: string.isRequired,
  doubleTabWidth: number.isRequired,
  isClientProperties: bool,
};
