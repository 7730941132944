import React from 'react';
import { useOutSidebarContext } from '@poly/client-utils';

import { SidebarIDs } from '../../../sidebars/constants.js';
import { CustomSidebarLayout } from '../../../sidebars/components/CustomSidebarLayout.js';
import { UpdateTasksSidebar } from './UpdateTasksSidebar.js';

export const useOpenUpdateTasksSidebar = () => {
  const { openOutSidebar, closeOutSidebar } = useOutSidebarContext();

  return (tasks, unselectTasks) => {
    openOutSidebar({
      id: SidebarIDs.requestedSupplierDocument,
      content: (
        <UpdateTasksSidebar
          onClose={closeOutSidebar}
          tasks={tasks}
          unselectTasks={unselectTasks}
        />
      ),
      Layout: CustomSidebarLayout,
      width: 615,
    });
  };
};
