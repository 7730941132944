import * as R from 'ramda';
import { isBefore, isSameDay } from 'date-fns';
import { isManualTotalTimesheet } from '@poly/constants';
import {
  areTimeEntriesOverlapWithOtherTimesheets,
  ensureIsDate,
  ofArrayLegacy,
} from '@poly/utils';

import { normalizeTimesheetEntriesByDate } from './normalize-entries.js';
import { constructDatePropsForTimeEntry } from './common.js';

// eslint-disable-next-line import/no-unused-modules
export const TIME_IN_GREATER_THAN_OUT_ERROR =
  '"Time out" should be greater than "time in"';

// eslint-disable-next-line import/no-unused-modules
export const TIME_ENTRIES_OVERLAP_ERROR =
  'Next "time in" should be greater than previous "time out"';

// eslint-disable-next-line import/no-unused-modules
export const MANUAL_TIME_REQUIRED_ERROR = 'Time is required';

// eslint-disable-next-line import/no-unused-modules
export const TIME_REQUIRED_ERROR = 'Time is required';

// eslint-disable-next-line import/no-unused-modules
export const TIME_ENTRIES_OVERLAP_OTHER_ENTRIES_ERROR =
  'This time overlaps other time sheet records';

// isBeforeCurried :: Date -> Date -> Boolean
const isBeforeCurried = R.curryN(2, isBefore);

// timesheetEntryToTimeEntryPair :: TimesheetEntry -> TimeEntryPair
//   TimeEntryPair = Pair Date Date
const timesheetEntryToTimeEntryPair = R.converge(Array.of, [
  R.prop('timeIn'),
  R.prop('timeOut'),
]);

// isTimeOutGoesAfterTimeIn :: TimeEntryPair -> Boolean
const isTimeOutGoesAfterTimeIn = R.ifElse(
  R.both(R.compose(R.is(Date), R.head), R.compose(R.is(Date), R.last)),
  R.apply(isBeforeCurried),
  R.always(true), // let required validation handle missing dates
);

// areTimeEntryPairsNotOverlap :: [TimeEntryPair] -> [Boolean]
const areTimeEntryPairsNotOverlap = R.compose(
  R.prepend(true), // compensate first entry that is gone
  R.map(isTimeOutGoesAfterTimeIn),
  R.converge(R.zip, [
    R.compose(R.init, R.map(R.last)), // timeOuts
    R.compose(R.tail, R.map(R.head)), // timeIns
  ]),
);

// ifThenValidationError :: (a -> Boolean) -> String -> String -> a -> ErrorObj
const ifThenValidationError = R.curry((predicate, error, fieldName) =>
  R.ifElse(predicate, R.always({ [fieldName]: error }), R.always({})),
);

// validateTimeRequiredAtPair = ((TimeEntryPair -> Date), String) -> TimeEntryPair -> ErrorObj
// ErrorObj = Object
const validateTimeRequiredAtPair = (getterFn, prop) =>
  ifThenValidationError(
    R.compose(R.complement(R.is(Date)), getterFn),
    TIME_REQUIRED_ERROR,
    prop,
  );

// validateTimeInEntry :: [TimeSheetEntry] -> [ErrorObj]
const validateTimeInEntry = R.map(
  R.compose(
    R.mergeAll,
    R.juxt([
      ifThenValidationError(
        R.complement(isTimeOutGoesAfterTimeIn),
        TIME_IN_GREATER_THAN_OUT_ERROR,
        'timeOut',
      ),
      validateTimeRequiredAtPair(R.head, 'timeIn'),
      validateTimeRequiredAtPair(R.last, 'timeOut'),
    ]),
  ),
);

// validateTimeOutEntry :: [TimeSheetEntry] -> [ErrorObj]
const validateTimeOutEntry = R.compose(
  R.map(
    ifThenValidationError(
      R.equals(false),
      TIME_ENTRIES_OVERLAP_ERROR,
      'timeIn',
    ),
  ),
  areTimeEntryPairsNotOverlap,
);

// validateTimeEntryWithOtherEntries :: ([TimeSheet], FormValuesObj) -> [TimeSheetEntry] -> [ErrorObj]
const validateTimeEntryWithOtherEntries = (timeSheets, allValues) =>
  R.map(
    R.compose(
      ifThenValidationError(
        R.identity,
        TIME_ENTRIES_OVERLAP_OTHER_ENTRIES_ERROR,
        'timeIn',
      ),
      R.converge(areTimeEntriesOverlapWithOtherTimesheets, [
        R.compose(
          R.objOf('entries'),
          ofArrayLegacy,
          R.zipObj(['timeIn', 'timeOut']),
        ),
        () =>
          R.compose(
            R.map(
              R.over(
                R.lensProp('entries'),
                R.compose(
                  R.map(constructDatePropsForTimeEntry),
                  R.defaultTo([]),
                ),
              ),
            ),
            R.filter(
              R.allPass([
                R.propSatisfies(R.complement(R.isNil), 'entries'),
                R.propSatisfies(R.complement(R.equals(allValues.type)), 'type'),
                ({ date }) =>
                  isSameDay(new Date(date), ensureIsDate(allValues.date)),
                R.propSatisfies(R.complement(R.equals(allValues._id)), '_id'),
              ]),
            ),
            R.defaultTo([]),
          )(timeSheets),
      ]),
    ),
  );

// validateTimeEntries :: [TimesheetEntry] -> [ErrorObj]
export const validateTimeEntries = R.curry((timeSheets, value, allValues) =>
  R.compose(
    R.converge(R.zipWith(R.mergeRight), [
      R.converge(R.zipWith(R.mergeRight), [
        validateTimeInEntry,
        validateTimeOutEntry,
      ]),
      validateTimeEntryWithOtherEntries(timeSheets, allValues),
    ]),
    R.map(timesheetEntryToTimeEntryPair),
    normalizeTimesheetEntriesByDate(allValues.date),
  )(value),
);

// validateTimesheetFormData :: Timesheet -> ErrorObj
export const validateTimesheetFormData = ifThenValidationError(
  R.both(
    isManualTotalTimesheet,
    R.propSatisfies(R.complement(R.is(Number)), 'totalTimeInMinutes'),
  ),
  MANUAL_TIME_REQUIRED_ERROR,
  'totalTimeInMinutes',
);

export const validateTimesheetFunc = R.curry((validators, value, allValues) =>
  R.pipe(
    R.find(([validator]) => !validator(value, allValues)),
    R.prop(1),
    R.when(R.isNil, R.always(undefined)),
  )(validators),
);
