import React from 'react';
import * as R from 'ramda';
import { NOTHING_UI_STRING } from '@poly/constants';

import {
  FixedWidthColumn,
  RightAlignedFlexColumn,
  FlexFullWidthJustifyStart,
  SearchResultItemContainer,
} from './common/wrappers.js';
import {
  SearchResultItemText,
  SearchResultItemTitle,
  SearchResultItemLinked,
  SearchResultItemDivider,
} from './common/components.js';
import { highlightedTextPropType } from './common/propTypes.js';

export function AssetsSearchResult({
  name,
  qrCodeId,
  description,
  manufacturerDoc,
  equipmentType,
}) {
  return (
    <SearchResultItemContainer>
      <FixedWidthColumn width="160px">
        {R.isEmpty(name) ? (
          <SearchResultItemLinked>
            {manufacturerDoc.name} ({qrCodeId})
          </SearchResultItemLinked>
        ) : (
          <SearchResultItemLinked>{name}</SearchResultItemLinked>
        )}
      </FixedWidthColumn>
      <RightAlignedFlexColumn>
        <SearchResultItemTitle>
          {R.when(
            R.anyPass([R.isNil, R.isEmpty]),
            R.always(NOTHING_UI_STRING),
          )(equipmentType)}
        </SearchResultItemTitle>
        <SearchResultItemDivider />
        <FlexFullWidthJustifyStart>
          <SearchResultItemText size="12px">
            {R.when(
              R.anyPass([R.isNil, R.isEmpty]),
              R.always(NOTHING_UI_STRING),
            )(description)}
          </SearchResultItemText>
        </FlexFullWidthJustifyStart>
      </RightAlignedFlexColumn>
    </SearchResultItemContainer>
  );
}

AssetsSearchResult.propTypes = {
  name: highlightedTextPropType,
  description: highlightedTextPropType,
  equipmentType: highlightedTextPropType,
  qrCodeId: highlightedTextPropType.isRequired,
  manufacturerDoc: highlightedTextPropType.isRequired,
};
