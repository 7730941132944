import * as R from 'ramda';
import { useRef, useEffect } from 'react';
import { useRouterQuery, useUpdateQueryParams } from '@poly/client-routing';
import { GLOBAL_SEARCH_SIDEBAR_ID } from '@poly/admin-ui';
import { useOutSidebarContext } from '@poly/client-utils';
import { isSidebarHidden } from '@poly/client-utils/src/slideOutSidebar.js';

import { DoubleWidthSidebarTabs } from '../sidebars/constants.js';

// checkIsDoubleWidthSidebarTab :: String -> Boolean
export const checkIsDoubleWidthSidebarTab = (tab) =>
  R.any(R.equals(tab))(DoubleWidthSidebarTabs);

export const useCloseExtendedTab = () => {
  const isActive = useRef(false);
  const { sidebarTab, cardTab } = useRouterQuery(['sidebarTab', 'cardTab']);
  const updateQueryParams = useUpdateQueryParams();
  const { isSidebarOpened, sidebarConfig } = useOutSidebarContext();

  const isHidden = isSidebarHidden(sidebarConfig);

  const isDoubleWidthTab = checkIsDoubleWidthSidebarTab(
    R.isEmpty(sidebarConfig) ? cardTab : sidebarTab,
  );
  const closeTab = () => {
    if (
      isActive.current &&
      (isHidden || !isSidebarOpened(GLOBAL_SEARCH_SIDEBAR_ID))
    ) {
      updateQueryParams({
        sidebarTab: undefined,
        sidebarSubTab: undefined,
        cardTab: !R.isEmpty(sidebarConfig) && cardTab ? cardTab : undefined,
      });
    }
  };

  useEffect(() => {
    if (isDoubleWidthTab && !isActive.current) {
      isActive.current = true;
    }

    if (!isDoubleWidthTab && isActive.current) {
      isActive.current = false;
    }
  }, [sidebarTab, cardTab]);

  return closeTab;
};
