import * as R from 'ramda';
import React, { useState, useContext } from 'react';
import { bool, node } from 'prop-types';
import { Loader, DefaultBodyWrapper, TableCard } from '@poly/admin-book';
import { gql, useQuery } from '@apollo/client';

import { WorkInProgressHeader } from './WorkInProgressHeader.js';
import {
  PageWithSearchHeader,
  PageWithSearchHeaderContext,
} from '../../components/PageWithSearchHeader.js';
import { WorkInProgressTable } from './WorkInProgressTable.js';

// eslint-disable-next-line import/no-unused-modules
export const workInProgressReportQuery = gql`
  query WIP_REPORT($input: WipRecordsInput!) {
    workInProgressReport(input: $input) {
      _id
      project {
        projectId
        endDate
        type
      }
      client {
        _id
        name
      }
      manager {
        fullName
      }
      projectAccountingStatus
      suggestedClientInvoiceAmount
      lines {
        _id
        name
        total
        markup
        supplier {
          _id
          company {
            name
          }
        }
      }
    }
  }
`;

export function WorkInProgressTableWrapper({ loading, children }) {
  const { contentHeight } = useContext(PageWithSearchHeaderContext);

  if (loading) {
    return <Loader />;
  }

  return (
    <DefaultBodyWrapper height={contentHeight}>
      <TableCard>{children}</TableCard>
    </DefaultBodyWrapper>
  );
}

WorkInProgressTableWrapper.propTypes = {
  loading: bool,
  children: node,
};

export function WorkInProgressReportPage() {
  const [queryFilters, setQueryFilters] = useState({});

  const [sorting, setSorting] = useState({
    columnKey: 0,
    dir: 1,
  });

  const { data, loading } = useQuery(workInProgressReportQuery, {
    variables: {
      input: queryFilters,
    },
    skip: R.isEmpty(queryFilters),
    fetchPolicy: 'network-only',
  });

  const wipRecords = R.propOr([], 'workInProgressReport', data);

  return (
    <PageWithSearchHeader headerHeight="165px">
      <WorkInProgressHeader
        setQueryFilters={setQueryFilters}
        queryFilters={queryFilters}
        wipRecords={wipRecords}
        sorting={sorting}
        loading={loading}
      />
      <WorkInProgressTableWrapper loading={loading}>
        <WorkInProgressTable
          wipRecords={wipRecords}
          updateSorting={setSorting}
          initialSorting={sorting}
        />
      </WorkInProgressTableWrapper>
    </PageWithSearchHeader>
  );
}
