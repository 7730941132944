import React from 'react';
import { string } from 'prop-types';
import { LinkButton } from '@poly/admin-book';
import { entities } from '@poly/admin-ui';

import { ProjectOccurrence } from '../../modules/core/constants/projects.js';
import { useAddProjectSidebar } from '../ProjectSidebar/forms/add/useAddProjectSidebar.js';

export function CloneMasterRecurringProjectButton({ _id, projectId }) {
  const openAddProjectSideBar = useAddProjectSidebar(
    false,
    entities.RECURRING_PROJECT,
    projectId,
  );

  const onClick = () => {
    openAddProjectSideBar({
      cloneFromMasterProjectId: _id,
      cloneWithoutSuppliers: false,
      type: ProjectOccurrence.RECURRING_OCCURRENCE,
    });
  };

  return <LinkButton onClick={onClick}>Clone Project</LinkButton>;
}

CloneMasterRecurringProjectButton.propTypes = {
  _id: string.isRequired,
  projectId: string.isRequired,
};
