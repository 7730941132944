import { useCallback } from 'react';
import * as R from 'ramda';
import { gql, useSubscription, useLazyQuery } from '@apollo/client';
import { DESC_SORT_ORDER, ELASTIC_SCORE_FIELD } from '@poly/constants';
import { useTableInfiniteScrollQuery } from '@poly/client-utils';
import { entities, commonAddressFields, MAX_ITEMS } from '@poly/admin-ui';
import { debounce, isNilOrEmpty, tryCallFunction } from '@poly/utils';
import { SEARCH_ASSET_PROCEDURES_SUBSCRIPTION } from '../../AssetSidebar/tabs/useSearchAssetProceduresQuery.js';

const assetFieldsForEntity = gql`
  fragment assetFieldsForEntity on Asset {
    _id
    type {
      _id
      name
      lifeExpectancy
    }
    displayName
    serial
    status
    qrCodeId
    location
    commissioningDate
    manufacturerDoc {
      _id
      name
    }
    modelDoc {
      _id
      name
      lifeExpectancy
    }
    suppliers {
      _id
      company {
        name
      }
    }
    recurringProjects {
      _id
      projectId
    }
    property {
      _id
      name
      addressTwo
      address {
        ...commonAddressFields
      }
    }
    client {
      _id
      name
    }
  }

  ${commonAddressFields}
`;

const SEARCH_ASSETS_SUB = gql`
  subscription SEARCH_ASSETS_SUB($input: CollectionSearchParams!) {
    searchAssetChanged(input: $input) {
      id
      type
    }
  }
`;

const PROJECT_ASSETS_QUERY = gql`
  query PROJECT_ASSETS_QUERY($id: ID, $input: CollectionSearchParams!) {
    project(id: $id) {
      _id
      type
      status
      assetIds
      parent {
        _id
        type
      }
      property {
        _id
        assets {
          _id
        }
      }
      subProperties {
        _id
      }
      client {
        _id
        apps
      }
      assetProcedures {
        asset {
          _id
          displayName
        }
        procedure {
          _id
          name
          assetTypes {
            _id
          }
        }
        executedProcedure {
          _id
          completedAt
          steps {
            name
            status
            comment
            description
            attachments {
              fileName
              fileType
              url
            }
          }
        }
      }
      searchAssets(input: $input) {
        hits {
          ...assetFieldsForEntity
        }
        total
      }
    }
  }

  ${assetFieldsForEntity}
`;

export const PROJECT_ASSETS_SUB = gql`
  subscription PROJECT_ASSETS_SUB($input: ProjectChangedSubInput!) {
    projectChanged(input: $input) {
      id
      type
    }
  }
`;

const RECURRING_PROJECT_ASSETS_QUERY = gql`
  query RECURRING_PROJECT_ASSETS_QUERY(
    $id: ID!
    $input: CollectionSearchParams!
  ) {
    recurringProject(id: $id) {
      _id
      type
      status
      childType
      property {
        _id
      }
      subProperties {
        _id
      }
      payload {
        tm {
          assetIds
        }
        bidProjectType {
          assetIds
        }
        passThrough {
          assetIds
        }
      }
      assetProcedures {
        asset {
          _id
          displayName
        }
        procedure {
          _id
          name
          assetTypes {
            _id
          }
        }
      }
      searchAssets(input: $input) {
        hits {
          ...assetFieldsForEntity
        }
        total
      }
    }
  }

  ${assetFieldsForEntity}
`;

export const RECURRING_PROJECT_ASSETS_SUB = gql`
  subscription RECURRING_PROJECT_ASSETS_SUB($input: SingleDocSubInput!) {
    recurringProjectChanged(input: $input) {
      id
      type
    }
  }
`;

const queryByEntityMap = {
  [entities.PROJECT]: [PROJECT_ASSETS_QUERY, PROJECT_ASSETS_SUB],
  [entities.RECURRING_PROJECT]: [
    RECURRING_PROJECT_ASSETS_QUERY,
    RECURRING_PROJECT_ASSETS_SUB,
  ],
};

// keeping it outside of component to prevent obsolete rerenders
// See useTableInfiniteScrollQuery notes for details.
const searchAssetsQueryInput = {
  sort: [{ createdAt: DESC_SORT_ORDER }, ELASTIC_SCORE_FIELD],
};

// getAssetProcedureIds :: Project -> [String]
const getAssetProcedureIds = R.compose(
  R.map(R.path(['procedure', '_id'])),
  R.propOr([], 'assetProcedures'),
  R.mergeAll,
  R.juxt([R.propOr({}, 'project'), R.pathOr({}, ['recurringProject'])]),
);

export const useSearchAssetsTabQuery = (entity) => {
  const { _id, name } = entity;

  const [entityQuery, entitySubscription] = queryByEntityMap[name];

  const { data, loading, refetch, tableProps } = useTableInfiniteScrollQuery(
    entityQuery,
    searchAssetsQueryInput,
    { endpointName: [name, 'searchAssets'], variables: { id: _id } },
  );

  const debouncedRefetch = useCallback(
    debounce(2000)(() => tryCallFunction(refetch)()),
    [refetch],
  );

  const procedureIds = getAssetProcedureIds(data);

  useSubscription(SEARCH_ASSETS_SUB, {
    shouldResubscribe: data,
    onData: debouncedRefetch,
    variables: { input: {} },
  });

  useSubscription(entitySubscription, {
    shouldResubscribe: data,
    onData: debouncedRefetch,
    variables: { input: { id: _id } },
  });

  useSubscription(SEARCH_ASSET_PROCEDURES_SUBSCRIPTION, {
    shouldResubscribe: data,
    onData: debouncedRefetch,
    variables: { input: { query: { terms: { _id: procedureIds } } } },
    skip: isNilOrEmpty(procedureIds),
  });

  const [queryExportHandler] = useLazyQuery(entityQuery, {
    variables: {
      id: _id,
      input: { ...searchAssetsQueryInput, size: MAX_ITEMS },
    },
    fetchPolicy: 'network-only',
  });

  return { data, loading, tableProps, queryExportHandler };
};
