import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, string, shape } from 'prop-types';
import { getThemeColor, Select } from '@poly/admin-book';
import { isNilOrEmpty, pathEqLegacy } from '@poly/utils';

import { usePropertyHierarchyTreeLogic } from '../../../../../pages/PropertyHierarchyManagement/PropertyHierarchyTreeWithSearch/usePropertyHierarchyTreeLogic.js';
import { useHierarchyDetailsQuery } from '../../../../../pages/PropertyHierarchyManagement/useHierarchyDetailsQuery.js';
import { hierarchyNodePropType } from '../../../PropertySidebarHierarchy.js';

const Label = styled.div`
  color: ${getThemeColor(['midDark'])};
  width: 100%;
  padding-bottom: 8px;
  font-size: 12px;
`;

const NodeSelect = styled.div`
  padding-top: 6px;
`;

// generateHierarchyOptions :: (TreeData, [String]) -> HierarchyOptions Function
// HierarchyOptions = [
//  { value: String, label: String },
//  HierarchyOptions
// ]
const generateHierarchyOptions = (treeData, path) =>
  R.map(
    R.ifElse(
      R.either(
        R.compose(isNilOrEmpty, R.prop('children')),
        pathEqLegacy(['children', '0', 'type'], 'property'),
      ),
      R.applySpec({
        value: R.prop('id'),
        label: R.compose(R.join(' / '), R.append(R.__, path), R.prop('title')),
      }),
      R.converge(generateHierarchyOptions, [
        R.prop('children'),
        R.compose(R.append(R.__, path), R.prop('title')),
      ]),
    ),
  )(treeData);

// getHierarchyOptions :: (TreeData, [String]) -> [{ value: String, label: String }]
export const getHierarchyOptions = (treeData, path) =>
  R.flatten(generateHierarchyOptions(treeData, path));

function HierarchyNodesWrapper({ hierarchyObj, ...props }) {
  const { treeData } = usePropertyHierarchyTreeLogic(hierarchyObj);

  const selectProps = {
    ...props,
    label: treeData.title,
    options: getHierarchyOptions(treeData, []),
  };

  return (
    <NodeSelect>
      <Label>{hierarchyObj.name}</Label>
      <Select {...selectProps} />
    </NodeSelect>
  );
}

HierarchyNodesWrapper.propTypes = {
  hierarchyObj: shape({
    _id: string,
    name: string,
    nodes: arrayOf(hierarchyNodePropType),
  }),
};

export function HierarchyNodesSelector({ clientId, hierarchyId, ...props }) {
  const { hierarchyObj, loading } = useHierarchyDetailsQuery(
    clientId,
    hierarchyId,
  );

  if (loading) {
    return null;
  }

  return <HierarchyNodesWrapper hierarchyObj={hierarchyObj} {...props} />;
}

HierarchyNodesSelector.displayName = 'HierarchyNodesSelector';
HierarchyNodesSelector.propTypes = {
  clientId: string,
  hierarchyId: string,
};
