import React from 'react';
import * as R from 'ramda';
import { bool, func, shape, string } from 'prop-types';
import styled from 'styled-components';
import { ClientSelect } from '@poly/admin-ui/src/modules/forms/fields/ClientSelect/ClientSelect.js';
import { ToolBarBtnDivider } from '@poly/admin-book/src/Table/styles.js';
import { Text } from '@poly/admin-book/src/Text/index.js';
import { LinkButton } from '@poly/admin-book/src/LinkButton/index.js';
import { isNilOrEmpty } from '@poly/utils/src/general.js';
import { useHasUserAccessWithPermission } from '@poly/client-utils/src/hooks/useHasUserAccessWithPermission.js';
import { READ_REPORT_SENDING_CONFIGURATION_PERMISSION } from '@poly/security/src/permissions.js';

import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import {
  SearchHeaderWrapper,
  SelectsWrapper,
} from '../WorkInProgressQCReport/WorkInProgressQCHeader.js';
import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { ButtonsWrapper } from '../UnassignedProjectsPage/UnassignedProjectsComponents.js';
import { ButtonsContainerS } from '../WorkInProgressReport/WorkInProgressHeader.js';
import { OpenWorkOrdersXLSBtn } from './OpenWorkOrdersXLSBtn.js';
import { OpenWorkOrdersPDFBtn } from './OpenWorkOrdersPDFBtn.js';
import { useOpenReportSendingConfigurationSidebar } from './sidebar/useOpenReportSendingConfigurationSidebar.js';
import { WOAgeInput } from './sidebar/reportSendingConfigurationFormSections.js';

const openWorkOrdersReportFiltersConfig = [
  { name: 'clientId' },
  { name: 'ageFrom', defaultValue: '' },
  { name: 'ageTo', defaultValue: '' },
];

const Wrapper = styled.div`
  width: 100%;
  margin-right: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SearchHeaderWrapperS = styled(SearchHeaderWrapper)`
  @media (max-width: 1470px) {
    flex-direction: row;
  }
`;

const SearchHeaderColumnS = styled(SearchHeaderColumn)`
  @media (max-width: 1430px) {
    margin-right: 5px;
    width: 100px;
  }
`;

function OpenWorkOrdersReportTitle({ clientId }) {
  const handleOpenSidebar = useOpenReportSendingConfigurationSidebar();

  const hasAccess = useHasUserAccessWithPermission(
    READ_REPORT_SENDING_CONFIGURATION_PERMISSION,
  );

  return (
    <Wrapper>
      <Text>Open Work Orders Report</Text>
      {hasAccess && (
        <LinkButton
          disabled={!clientId}
          onClick={() => handleOpenSidebar(clientId)}
        >
          Configure automated report sending
        </LinkButton>
      )}
    </Wrapper>
  );
}
OpenWorkOrdersReportTitle.propTypes = {
  clientId: string,
};

// isDisabledExport :: {data: SearchProjectsResult, searchFilters: {clientId: String} } -> Boolean
const isDisabledExport = R.either(
  R.compose(isNilOrEmpty, R.pathOr([], ['data', 'searchProjects', 'hits'])),
  R.complement(R.path(['searchFilters', 'clientId'])),
);

export function OpenWorkOrdersReportHeader({
  setQueryFilters,
  loading,
  data,
  sort,
}) {
  const { searchFilters, handlers, onReset } = useSearchFilters(
    openWorkOrdersReportFiltersConfig,
  );

  const onSearch = () => {
    setQueryFilters(searchFilters);
  };

  const handleReset = () => {
    setQueryFilters({});
    onReset();
  };

  const disabled = isDisabledExport({ data, searchFilters });

  return (
    <SearchPageHeaderContainer
      clientId={searchFilters.clientId}
      TitleComp={OpenWorkOrdersReportTitle}
    >
      <SearchHeaderWrapperS>
        <SelectsWrapper>
          <SearchHeaderColumn
            title="Client"
            titleWidth="60px"
            filterWidth="240px"
          >
            <ClientSelect
              value={searchFilters.clientId}
              onChange={handlers.clientId}
              width="100%"
              includeAllOption={false}
              withoutSkip
              isClearable
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn
            title="Age From"
            titleWidth="75px"
            filterWidth="220px"
          >
            <WOAgeInput
              value={searchFilters.ageFrom}
              onChange={handlers.ageFrom}
              width="100%"
              name="ageFrom"
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn title="To" titleWidth="30px" filterWidth="220px">
            <WOAgeInput
              value={searchFilters.ageTo}
              onChange={handlers.ageTo}
              width="100%"
              name="ageTo"
            />
          </SearchHeaderColumn>
        </SelectsWrapper>
        <ButtonsWrapper>
          <SearchHeaderColumnS filterWidth="250px">
            <ButtonsContainerS>
              <OpenWorkOrdersPDFBtn
                disabled={disabled}
                loader={loading}
                sort={sort}
                filter={searchFilters}
              />
              <ToolBarBtnDivider />
              <OpenWorkOrdersXLSBtn
                disabled={disabled}
                filter={searchFilters}
                loader={loading}
                sort={sort}
              />
            </ButtonsContainerS>
          </SearchHeaderColumnS>
          <SearchHeaderColumn filterWidth="190px">
            <SearchHeaderButton
              size="small"
              styleType="basicPrimary"
              onClick={handleReset}
            >
              Reset
            </SearchHeaderButton>
            <SearchHeaderButton
              size="small"
              onClick={onSearch}
              loader={loading}
              disabled={!searchFilters?.clientId}
            >
              Search
            </SearchHeaderButton>
          </SearchHeaderColumn>
        </ButtonsWrapper>
      </SearchHeaderWrapperS>
    </SearchPageHeaderContainer>
  );
}

OpenWorkOrdersReportHeader.propTypes = {
  setQueryFilters: func.isRequired,
  loading: bool,
  data: shape({}),
  sort: shape({}),
};
