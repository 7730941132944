import * as R from 'ramda';
import React from 'react';
import { assocBy, centsToDollarsWithFormat } from '@poly/utils';

import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import { commonQuoteDiscrepanciesTableConfig } from '../QuoteDiscrepancies/table-config.js';
import { SupplierLink } from '../../components/Links.js';

export const getSuppliersQuoteDiscrepanciesTable = () => [
  ['Project #', ({ project }) => <ProjectLink {...project} />],
  ['Supplier Invoice #', R.prop('invoiceNumber')],
  [
    'Supplier NTE',
    R.compose(centsToDollarsWithFormat, R.propOr(0, 'supplierNTE')),
  ],
  [
    'Supplier Invoice',
    R.compose(centsToDollarsWithFormat, R.propOr(0, 'total')),
  ],
  [
    'Supplier',
    R.compose(
      SupplierLink,
      assocBy('name', R.path(['company', 'name'])),
      R.prop('supplier'),
    ),
  ],
  ...commonQuoteDiscrepanciesTableConfig,
];
