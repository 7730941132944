import React from 'react';
import styled from 'styled-components';
import { arrayOf, shape, string } from 'prop-types';
import { firstLettersOfEachWord, formatDateTime } from '@poly/utils';
import { UserAvatar, Text } from '@poly/admin-book';
import { SYSTEM_USER_NAME } from '@poly/constants';

import { UpdatesFileLink } from './UpdateDetails.js';
import { PolyLogo, Container, UserAndDateContainer } from './UpdateMetaInfo.js';
import {
  MessageWithMentions,
  mentionsShape,
} from '../../../components/MessageWithMentions.js';

const UserAndDateContainerS = styled(UserAndDateContainer)`
  width: 100%;
  word-break: break-word;
`;

const FirstRow = styled(Container)`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export function UpdateMetaInfoTask({
  title,
  message,
  mentions,
  createdAt,
  attachments,
  createdBy: { fullName = SYSTEM_USER_NAME },
}) {
  return (
    <Container>
      {fullName === SYSTEM_USER_NAME ? (
        <PolyLogo />
      ) : (
        <UserAvatar>{firstLettersOfEachWord(fullName)}</UserAvatar>
      )}
      <UserAndDateContainerS>
        <FirstRow>
          <Text lineHeight="18px" size="12px">
            {fullName}
          </Text>
          <Text lineHeight="18px" color={['mid']} size="12px">
            {formatDateTime(createdAt)}
          </Text>
        </FirstRow>
        <Text weight="bold" size="12px" lineHeight="18px">
          {title}
        </Text>
        <MessageWithMentions {...{ message, mentions }} />
        {attachments?.map((file) => (
          <UpdatesFileLink key={file.url} {...file} />
        ))}
      </UserAndDateContainerS>
    </Container>
  );
}

UpdateMetaInfoTask.propTypes = {
  createdAt: string.isRequired,
  createdBy: shape({
    fullName: string.isRequired,
  }),
  title: string.isRequired,
  message: string.isRequired,
  mentions: mentionsShape,
  attachments: arrayOf(
    shape({
      fileName: string.isRequired,
      url: string.isRequired,
    }),
  ),
};

UpdateMetaInfoTask.defaultProps = {
  createdBy: {
    fullName: SYSTEM_USER_NAME,
  },
  mentions: {},
  attachments: [],
};
