export const addPropertyFormId = 'add_property_form_id';
export const editPropertyFormId = 'edit_property_form_id';
export const propertyTabsSidebarId = 'property_tabs_sidebar_id';

export const PropertyTabsSidebarWidth = 670;

export const customPropertyBuildingType = 'Custom';

export const buildingTypeOptionsConfig = [
  'Church',
  'Parsonage',
  'Fellowship Hall',
  'Camp',
  'Vacant Land',
  'School',
  'Cemetery',
];

export const PropertyFieldInheritanceMap = {
  SELF: 'Self',
  CLIENT: 'Client',
  MASTER_PROPERTY: 'Master Property',
};
