import React from 'react';
import * as R from 'ramda';
import { getThemeColor } from '@poly/admin-book';
import { arrayOf, bool, func, number, objectOf, string } from 'prop-types';
import SortableTree from '@nosferatu500/react-sortable-tree';
import styled from 'styled-components';

import { getNodeKey } from './useTreeLogic.js';
import { treeNodePropTypes } from './propTypes.js';
import { treeBaseStyles } from './styles.js';

export const TreeBase = styled(SortableTree)`
  ${treeBaseStyles};
  .rst__row,
  .rst__node,
  .rst__nodeContent {
    width: 400px !important;
    min-width: 400px !important;
  }
  .rst__rowWrapper {
    padding: 5px 0;
  }
  .rst__moveHandle {
    background-color: ${getThemeColor(['mid'])};
    box-shadow: none;
    border-right: none;
    border: 1px solid ${getThemeColor(['midLight'])};
    width: 35px;
  }
  .rst__rowContents {
    box-shadow: none;
    border: 1px solid ${getThemeColor(['midLight'])};
    border-left: none;

    display: flex;
    padding: 0;
  }
  .rst__rowLabel {
    display: flex;
    align-items: center;

    flex-grow: 1;
    padding: 0 5px;
    height: 100%;
    border-right: 1px solid ${getThemeColor(['midLight'])};
  }
  .rst__lineBlock:before,
  .rst__lineBlock:after,
  .rst__lineChildren:after {
    background-color: ${getThemeColor(['midDark'])};
  }
  .rst__collapseButton {
    box-shadow: 0 0 0 1px ${getThemeColor(['midDark'])};
  }
  .rst__lineHalfHorizontalRight:before {
    background: ${({ hideRootLine }) => hideRootLine && '#fff'};
  }
  .rst_buttons {
    display: flex;
    padding-left: 5px;
    > div {
      margin-right: 5px;
    }
  }
`;

export function Tree({
  dndType,
  nodeContentRenderer,
  hideRootLine,
  rowHeight,
  canDrop,
  treeData,
  validationErrors,
  setTreeData,
  onChangeNodeTitleHandler,
  onAddNodeHandler,
  onRemoveNodeHandler,
}) {
  return (
    <TreeBase
      hideRootLine={hideRootLine}
      dndType={dndType}
      nodeContentRenderer={nodeContentRenderer}
      treeData={treeData}
      getNodeKey={getNodeKey}
      rowHeight={rowHeight}
      style={{
        width: '100%',
      }}
      generateNodeProps={({ path, node }) => ({
        onChangeTitle: onChangeNodeTitleHandler(path),
        onAddNewNode: onAddNodeHandler(path),
        onRemoveNode: onRemoveNodeHandler(path, node),
        error: validationErrors[node.id],
      })}
      onChange={setTreeData}
      canDrop={canDrop}
    />
  );
}

Tree.propTypes = {
  treeData: arrayOf(treeNodePropTypes),
  setTreeData: func.isRequired,
  onChangeNodeTitleHandler: func.isRequired,
  onAddNodeHandler: func.isRequired,
  onRemoveNodeHandler: func.isRequired,
  validationErrors: objectOf(string),
  dndType: string.isRequired,
  nodeContentRenderer: func,
  canDrop: func,
  hideRootLine: bool,
  rowHeight: number,
};

Tree.defaultProps = {
  canDrop: R.F,
  rowHeight: 45,
};
