import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { removePropDeep, formatTimesheetDuration } from '@poly/utils';
import { getThemeColor } from '@poly/admin-book';
import { pathOrNothingUI } from '@poly/client-utils';
import {
  DeleteTimesheetButton,
  EditTimeSheetBtnComponent,
} from '../timesheetsTable/TimesheetButtons.js';
import { timesheetTypesMap } from '../../forms/timesheetForm/constants.js';
import { formatProjectLink } from '../timesheetsTable/index.js';
import { formatDateCurried } from '../timesheetsTable/utils.js';

const eightHoursInMinutes = 480;

const StyledCell = styled.span`
  color: ${getThemeColor(['gold'])};
`;

const getHoursStyled = (value) => <StyledCell>{value}</StyledCell>;

// getTimesheetsTableProps :: Array -> Object
const getTimesheetsTableProps = R.pipe(
  R.prop('timesheetsReport'),
  R.defaultTo([]),
  R.sortBy(R.path(['owner', 'fullName'])),
  R.groupBy(R.path(['owner', '_id'])),
  removePropDeep('__typename'),
  R.values,
  R.objOf('tableList'),
);

export const useMapTimeSheetReportForTable = ({ timesheetsReport }) => {
  const timeSheetsTableProps = getTimesheetsTableProps(timesheetsReport);

  return {
    ...timeSheetsTableProps,
  };
};

// formatHoursColor :: Number -> ReactComponent
const formatHoursColor = R.ifElse(
  R.lt(eightHoursInMinutes),
  R.pipe(formatTimesheetDuration, getHoursStyled),
  formatTimesheetDuration,
);

export const tableConfig = [
  ['Personnel', R.path(['owner', 'fullName'])],
  ['Date', R.compose(formatDateCurried('MM/dd/yyyy'), R.prop('date'))],
  ['Project ID', R.compose(formatProjectLink, R.prop('project'))],
  ['Description', pathOrNothingUI(['notes'])],
  ['Type', R.compose(R.prop(R.__, timesheetTypesMap), R.prop('type'))],
  ['Hours', R.compose(formatHoursColor, R.prop('totalTimeInMinutes'))],
  ['', EditTimeSheetBtnComponent],
  [' ', DeleteTimesheetButton],
];
