import { gql } from '@apollo/client';

import { commonFileFields } from './fragments.js';

export const ADD_FILES_MUTATION = gql`
  mutation ADD_FILES_MUTATION($addFilesInput: AttachFilesToDocumentInput!) {
    attachFilesToDocument(input: $addFilesInput) {
      files {
        ...commonFileFields
      }
      errors {
        error
      }
    }
  }

  ${commonFileFields}
`;
