import * as R from 'ramda';
import React from 'react';
import { Text } from '@poly/admin-book';
import { shape } from 'prop-types';
import { formatPhoneNumber, formatTotal } from '@poly/utils';
import { NOTHING_UI_STRING, ProjectSupplierStatus } from '@poly/constants';

import {
  PrintProjectFlexRow,
  PrintProjectFlexColumn,
  PrintProjectBlockWithLabel,
} from '../components.js';
import { isLastElement } from '../helpers.js';

// getProjectSuppliers :: Project -> [ProjectSupplier]
const getProjectSuppliers = R.propOr([], 'suppliers');

// getSupplierNameUI :: Supplier -> String
const getSupplierNameUI = R.pathOr(NOTHING_UI_STRING, ['company', 'name']);

// getSupplierNteUI :: Supplier -> String
const getSupplierNteUI = R.compose(
  R.ifElse(R.isNil, R.always(NOTHING_UI_STRING), formatTotal),
  R.prop('nte'),
);

// getSupplierStatusUI :: Supplier -> String
const getSupplierStatusUI = R.compose(
  R.cond([
    [R.equals(ProjectSupplierStatus.added), R.always('Added')],
    [R.equals(ProjectSupplierStatus.assigned), R.always('Assigned')],
    [R.T, R.always(NOTHING_UI_STRING)],
  ]),
  R.prop('statusInProject'),
);

// getSupplierPhoneNumberUI :: Supplier -> String
const getSupplierPhoneNumberUI = R.compose(
  R.ifElse(
    R.isEmpty,
    R.always(NOTHING_UI_STRING),
    R.compose(
      formatPhoneNumber('-'),
      R.applySpec({ number: R.path(['0', 'phone']) }),
    ),
  ),
  R.pathOr([], ['company', 'phones']),
);

export function PrintProjectSuppliers({ project }) {
  const projectSuppliers = getProjectSuppliers(project);

  if (R.isEmpty(projectSuppliers)) {
    return null;
  }

  return (
    <PrintProjectFlexColumn withBottomBorder>
      <PrintProjectFlexRow>
        <Text size="16px" weight="bold">
          Suppliers
        </Text>
      </PrintProjectFlexRow>
      {projectSuppliers.map((supplier, index) => (
        <PrintProjectFlexColumn key={`${supplier._id}-key`}>
          <PrintProjectFlexRow>
            <PrintProjectBlockWithLabel
              label="Supplier Name"
              value={getSupplierNameUI(supplier)}
            />
          </PrintProjectFlexRow>
          <PrintProjectFlexRow
            withBottomBorder={!isLastElement(index, projectSuppliers)}
          >
            <PrintProjectBlockWithLabel
              label="Supplier NTE"
              value={getSupplierNteUI(supplier)}
            />
            <PrintProjectBlockWithLabel
              label="Status"
              value={getSupplierStatusUI(supplier)}
            />
            <PrintProjectBlockWithLabel
              label="Phone Number"
              value={getSupplierPhoneNumberUI(supplier)}
            />
          </PrintProjectFlexRow>
        </PrintProjectFlexColumn>
      ))}
    </PrintProjectFlexColumn>
  );
}

PrintProjectSuppliers.propTypes = {
  project: shape({}),
};
