import {
  READ_PRIVATE_BANK_FIELD_SUPPLIER_PERMISSION,
  READ_PRIVATE_TAX_FIELD_SUPPLIER_PERMISSION,
} from '@poly/security';
import { useHasUserAccessWithPermission } from '../hooks/useHasUserAccessWithPermission.js';

export const useHasUserAccessToSupplierPrivateFields = () => {
  const hasUserTaxPrivateFieldsAccess = useHasUserAccessWithPermission(
    READ_PRIVATE_TAX_FIELD_SUPPLIER_PERMISSION,
  );

  const hasUserBankPrivateFieldsAccess = useHasUserAccessWithPermission(
    READ_PRIVATE_BANK_FIELD_SUPPLIER_PERMISSION,
  );

  return { hasUserTaxPrivateFieldsAccess, hasUserBankPrivateFieldsAccess };
};
