import React from 'react';
import { DefaultBodyWrapper } from '@poly/admin-book';
import { AddSupplierFormHeader } from '../modules/forms/supplierForm/AddSupplierFormHeader.js';
import { AddSupplierForm } from '../modules/forms/supplierForm/AddSupplierForm.js';

export function AddSupplierPage() {
  return (
    <>
      <AddSupplierFormHeader />
      <DefaultBodyWrapper>
        <AddSupplierForm />
      </DefaultBodyWrapper>
    </>
  );
}
