import { halfWidth } from '../modules/forms/common.js';

export const CommonSidebarFormWidth = 910;

export const commonSidebarFormSectionLayout = {
  padding: '0 24px 24px 24px',
  margin: '0 0 24px 0',
  label: {
    color: 'label',
    size: '16px',
    marginBottom: '10px',
  },
};

export const commonSidebarFormFieldLayout = {
  label: {
    color: 'midDark',
    size: '12px',
  },
  width: halfWidth,
};

export const commonSidebarFormLayout = {
  card: false,
};
