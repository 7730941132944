import * as R from 'ramda';
import { createAction, handleAction } from 'redux-actions';

export const getProcess = (prop) => R.path(['processes', prop]);

export const setProcesses = createAction('SET_PROCESSES');

export const processesReducerByDefaultState = (defaultState) =>
  handleAction(
    setProcesses,
    (state, { payload }) => ({ ...state, ...payload }),
    defaultState,
  );
