import * as R from 'ramda';
import {
  WIPInvoiceStatuses,
  WIPProjectAccountingStatuses,
  WorkOrderStatus,
} from '@poly/constants';
import { ofArrayLegacy } from '@poly/utils';

export const WORK_IN_PROGRESS_QC_SORT_INPUT = [{ 'project.projectId': 'asc' }];

// getWorkInProgressQCSearchQueryByFilters :: Object -> SearchQuery
export const getWorkInProgressQCSearchQueryByFilters = R.compose(
  R.assocPath(
    ['bool', 'must_not', 'term', 'project.status'],
    WorkOrderStatus.BLOCKED,
  ),
  R.assocPath(['bool', 'filter'], R.__, {}),
  R.append({
    terms: {
      status: WIPInvoiceStatuses,
    },
  }),
  R.reject(R.isNil),
  R.juxt([
    R.compose(
      R.unless(R.isNil, R.assocPath(['term', 'clientId'], R.__, {})),
      R.prop('clientId'),
    ),
    R.compose(
      R.assocPath(['terms', 'project.accountingStatus'], R.__, {}),
      R.ifElse(R.isNil, R.always(WIPProjectAccountingStatuses), ofArrayLegacy),
      R.prop('projectAccountingStatus'),
    ),
    R.compose(
      R.unless(R.isNil, R.assocPath(['term', 'project.managerId'], R.__, {})),
      R.prop('projectManagerId'),
    ),
  ]),
);
