import * as R from 'ramda';
import { history } from '@poly/client-routing';
import { graphqlUserLogoutRoute } from '@poly/utils';

import { retainedValueKeyPrefix } from './persists/usePersistentFormValue.js';
import { jsonPostRequest } from '../rest.js';

const { GRAPHQL_HTTP_URL = '' } = process.env;

// getRetainedValueKey :: [String] -> LocalStorage -> [String]
const getAllRetainedValueKeys = (storageItemsToRemove) =>
  R.compose(
    R.concat(storageItemsToRemove),
    R.filter(R.startsWith(retainedValueKeyPrefix)),
    R.keys,
  );

// logOutUser :: { client: ApolloClient, localStorage: LocalStorage, storageItemsToRemove: [String], customHandlerOnLogout: Function } -> _
export const logOutUser = async ({
  client,
  localStorage,
  customHandlerOnLogout,
  storageItemsToRemove = [],
}) => {
  await jsonPostRequest(graphqlUserLogoutRoute(GRAPHQL_HTTP_URL), {});

  if (client) {
    await client.clearStore();
  }

  getAllRetainedValueKeys(storageItemsToRemove)(localStorage).map(
    (storageItem) => localStorage.removeItem(storageItem),
  );

  if (!!customHandlerOnLogout && R.is(Function, customHandlerOnLogout)) {
    customHandlerOnLogout();
  }
};

export const useUserLogout =
  () =>
  ({ loginRoute, skipRedirect, routeParams, ...props }) => {
    logOutUser(props);

    if (history.location.pathname !== loginRoute && !skipRedirect) {
      const urlParams = window.location.search;

      const url = R.includes('previousUrl', urlParams)
        ? `${loginRoute}${urlParams}`
        : `${loginRoute}?previousUrl=${history.location.pathname}`;

      history.push(url, routeParams);
    }
  };
