import React from 'react';
import * as R from 'ramda';
import { bool, shape } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { FormPage, ToggleSwitch } from '@poly/admin-book';
import {
  useOnSubmitSetStopSubmitting,
  useNotificationState,
} from '@poly/admin-ui';
import {
  usePristineSubscribe,
  useUpdateQueryParams,
} from '@poly/client-routing';

import { editSettingsFormId } from './constants.js';

const UPDATE_USER_SETTINGS = gql`
  mutation UPDATE_USER_SETTINGS($input: UserSettingsInput!) {
    updateUserSettings(input: $input) {
      settings {
        notifications {
          updateCommentEmail
        }
      }
    }
  }
`;

export function UserSettingsForm({ notificationSettings }) {
  const id = editSettingsFormId;
  const [updateUserSettingsMutation] = useMutation(UPDATE_USER_SETTINGS);
  const { showSuccessNotification } = useNotificationState();
  const pristineSubscribeProps = usePristineSubscribe();
  const updateQueryParams = useUpdateQueryParams();

  const updateUserSettings = (variables) =>
    updateUserSettingsMutation({ variables }).finally(() => {
      showSuccessNotification('User Settings Successfully Updated');
      updateQueryParams({ isEdit: false });
    });

  const onSubmitHandler = R.compose(updateUserSettings, R.objOf('input'));

  const { onSubmit } = useOnSubmitSetStopSubmitting(id, onSubmitHandler);

  const formProps = {
    id,
    onSubmit,
    initialValues: notificationSettings,
    ...pristineSubscribeProps,
    sections: [
      {
        order: 1,
        id: 'main',
        label: 'Notifications',
        layout: { column: 1, margin: 0 },
        fields: [
          {
            order: 1,
            field: {
              name: 'notifications.updateCommentEmail',
              Component: ToggleSwitch,
              additionalProps: {
                label: 'Get Updates via Email',
                dataTestId: 'updates-via-email',
              },
            },
          },
        ],
      },
      {
        order: 2,
        id: 'links',
        label: 'Links',
        layout: { column: 1, margin: 0 },
        fields: [
          {
            order: 1,
            field: {
              name: 'links.openProjectInNewTab',
              Component: ToggleSwitch,
              additionalProps: {
                label: 'Open Project in New Tab',
                dataTestId: 'project-in-new-tab',
              },
            },
          },
        ],
      },
    ],
  };

  return <FormPage {...formProps} />;
}

UserSettingsForm.propTypes = {
  notificationSettings: shape({
    notifications: shape({ updateCommentEmail: bool }),
  }),
};

UserSettingsForm.defaultProps = { notificationSettings: {} };
