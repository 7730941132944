import React from 'react';
import { shape, string, bool } from 'prop-types';
import { Text } from '@poly/admin-book';

import { SubtitleWrap } from '../styles.js';

export function EntityInfoView({ value: { projectId, entityInfo }, section }) {
  return (
    <SubtitleWrap {...{ section }}>
      <Text size="12px" lineHeight="18px">
        {projectId}
      </Text>
      <Text size="12px" lineHeight="18px">
        {entityInfo}
      </Text>
    </SubtitleWrap>
  );
}

EntityInfoView.propTypes = {
  value: shape({
    entityInfo: string,
    projectId: string,
  }).isRequired,
  section: bool,
};

EntityInfoView.defaultProps = { section: false };
