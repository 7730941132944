import React from 'react';
import styled from 'styled-components';
import { number } from 'prop-types';

import { getThemeColor } from '../utils.js';

const CharacterCountComponent = styled.p`
  margin: 3px 0 3px 5px;
  font-size: 10px;
  color: ${getThemeColor(['secondary'])};
`;

export function CharacterCount({ limit, length }) {
  return (
    <CharacterCountComponent>
      {limit - length} chars left
    </CharacterCountComponent>
  );
}

CharacterCount.propTypes = {
  limit: number.isRequired,
  length: number.isRequired,
};
