import * as R from 'ramda';
import Holidays from 'date-holidays';
import { format, getYear } from 'date-fns';
import momentBusinessDays from 'moment-business-days';
import momentBusinessHours from 'moment-business-time';

import { ensureIsDate } from './dates.js';

const holidaysInstance = new Holidays('US');
const getHolidaysByYear = (year) => holidaysInstance.getHolidays(year);

// holidaysForThisAndNextYear :: Date -> [String]
export const holidaysForThisAndNextYear = R.compose(
  R.map((d) => format(d, 'MM-dd-yyyy')),
  R.map(ensureIsDate),
  R.map(R.prop('start')),
  R.filter(R.propSatisfies(R.includes(R.__, ['public', 'bank']), 'type')),
  R.converge(R.concat, [
    getHolidaysByYear,
    (year) => getHolidaysByYear(year + 1),
  ]),
  getYear,
  ensureIsDate,
);

const configureMomentWithHolidays = (momentInstance) => (baseHolidaysDate) => {
  const holidays = holidaysForThisAndNextYear(baseHolidaysDate);
  momentInstance.updateLocale('us', {
    holidayFormat: 'MM-DD-YYYY',
    holidays,
    /* eslint-disable-next-line @cspell/spellchecker */
    workinghours: {
      0: null,
      1: ['08:00:00', '17:00:00'],
      2: ['08:00:00', '17:00:00'],
      3: ['08:00:00', '17:00:00'],
      4: ['08:00:00', '17:00:00'],
      5: ['08:00:00', '17:00:00'],
      6: null,
    },
  });

  return momentInstance;
};

// addBusinessDays :: Int -> Date -> Date
export const addBusinessDays = R.curry((daysToAdd, startDate) =>
  R.compose(
    (m) => m(startDate).businessAdd(daysToAdd).toDate(),
    configureMomentWithHolidays(momentBusinessDays),
  )(startDate),
);

export const addBusinessHours = R.curry((hours, date) => {
  const configuredMoment =
    configureMomentWithHolidays(momentBusinessHours)(date);
  return configuredMoment(date).addWorkingTime(hours, 'hours').toDate();
});
