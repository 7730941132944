export const EQUALS_AIT_OPERATOR = 'equals';

export const FLIPPED_CONTAINS_AIT_OPERATOR = 'flippedContains';
export const NOT_FLIPPED_CONTAINS_AIT_OPERATOR = 'notFlippedContains';

export const GREATER_OR_EQUAL_AIT_OPERATOR = 'gte';
export const GREATER_THAN_AIT_OPERATOR = 'gt';

export const LESS_OR_EQUAL_AIT_OPERATOR = 'lte';
export const LESS_THAN_AIT_OPERATOR = 'lt';

export const VARIABLE_AIT_OPERATOR = 'userGroupVariableId';
