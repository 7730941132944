export const AssetStatuses = {
  ACTIVE: 'active',
  PENDING: 'pending',
  BLOCKED: 'blocked',
  INACTIVE: 'inactive',
};

export const ASSET_TYPES_LIST = [
  /* eslint-disable @cspell/spellchecker */
  'Cabinet/Hot Holding',
  'Can Opener/Industrial - Electric Motor',
  'Coffee Brewer',
  'Coffee Espresso Machine',
  'Dishwasher/Commercial',
  'Dough Equipment',
  'Drink Dispenser/Iced Tea',
  'Food Processor',
  'Freezer/Frost - Ice Cream',
  'Freezer/Reach In',
  'Freezer/Walk In',
  'Fryer/Deep Fat',
  'Garbage Disposal',
  'Griddle/Flat Top',
  'Grill/Charbroiler',
  'Hood/Exhaust',
  'Ice Machine',
  'Mixer/Countertop',
  'Mixer/Floor',
  'Oven/Combination',
  'Oven/Convection',
  'Oven/Conventional',
  'Oven/Express (e.g. Turbo Chef)',
  'Panini Press',
  'Pizza Oven',
  'Pizza Oven/Impinger',
  'Popcorn popper',
  'Range/Electric',
  'Range/Gas',
  'Refrigerator/Air Screen Cooler',
  'Refrigerator/Display',
  'Refrigerator/Blast Chiller',
  'Refrigerator/Drawer',
  'Refrigerator/Kegerator',
  'Refrigerator/Rack System',
  'Refrigerator/Reach In Cooler',
  'Refrigerator/Roll In Cooler',
  'Refrigerator/Walk In Cooler',
  'Rotisserie Oven',
  'Slicer/Automatic',
  'Slicer/Manual',
  'Small Appliance',
  'Soup Wells',
  'Steam Kettle',
  'Steamer',
  'Table/All Cold',
  'Table/All Hot',
  'Table/Combination Hot or Cold',
  'Tilt Skillet',
  'Warmer/Counter Top Food',
  /* eslint-enable @cspell/spellchecker */
];
