import React from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';
import { twoThirdWidth } from '@poly/admin-ui/src/modules/forms/common.js';

import { FieldValueContainer } from '../../CreateClientInvoice/styled.js';

const FieldValueContainerC = styled(FieldValueContainer)`
  overflow-wrap: break-word;
  display: inline-block;
  width: ${twoThirdWidth};

  @media (max-width: 450px) {
    font-size: 3vw;
    line-height: 4vw;
  }
`;

export function SupplierAttachInvoiceReadOnlyField({ value }) {
  return <FieldValueContainerC>{value}</FieldValueContainerC>;
}

SupplierAttachInvoiceReadOnlyField.propTypes = {
  value: string.isRequired,
};
