import React from 'react';
import * as R from 'ramda';
import { v4 as uuidV4 } from 'uuid';
import styled, { css } from 'styled-components';
import {
  string,
  arrayOf,
  shape,
  bool,
  element,
  node,
  oneOfType,
  number,
  func,
} from 'prop-types';

import { NOTHING_UI_STRING } from '@poly/constants';
import { Icon } from '../Icon/index.js';
import { getThemeFont, getThemeColor } from '../utils.js';

const Title = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  font-weight: ${getThemeFont(['medium'])};
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 24px;
  color: ${getThemeColor(['primary'])};
  padding-bottom: 10px;
`;

const Properties = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const withBtnStyles = css`
  display: flex;
  position: relative;
  width: fit-content;
`;

const ValueColumn = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: ${getThemeColor(['primary'])};
  overflow-wrap: break-word;

  ${({ withBtn }) => withBtn && withBtnStyles};
`;

const requiredLabel = css`
  &:after {
    content: '*';
    color: ${getThemeColor(['secondaryMid'])};
  }
`;

const KeyColumn = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: ${getThemeColor(['primaryLighter3'])};
  font-weight: ${getThemeFont(['medium'])};
  padding-bottom: 5px;

  ${({ required }) => required && requiredLabel};
`;

const Wrapper = styled(ValueColumn)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > p {
    padding-bottom: 5px;
    margin: 0;
  }
  & p:last-child {
    padding-bottom: 0;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  flex-wrap: wrap;
`;

// getSectionWidth :: { fullWidth: Boolean, width: String|Number } -> String
const getSectionWidth = R.cond([
  [R.prop('fullWidth'), R.always('calc(100%) + 15px')],
  [R.compose(R.is(String), R.prop('width')), R.prop('width')],
  [R.T, ({ width }) => `${width}%`],
]);

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(${getSectionWidth} - 15px);
  margin-bottom: 20px;
  font-weight: ${getThemeFont(['regular'])};
`;

const IconS = styled(Icon)`
  display: inline-block;
  margin-right: 20px;
`;

export function SectionIcon(props) {
  return <IconS {...props} />;
}

SectionIcon.defaultProps = {
  size: 26,
  color: 'rgba(30, 36, 44, 0.35)',
};

export function TableLayoutListWrapper({ icon, title, children, className }) {
  return (
    <div className={className}>
      {!!title && (
        <Title>
          {!!icon && <SectionIcon {...icon} />}
          {title}
        </Title>
      )}
      <Properties>{children}</Properties>
    </div>
  );
}

export function TableLayoutList({ list, fullWidth, ...props }) {
  return (
    <TableLayoutListWrapper {...props}>
      <BodyWrapper>
        {list.map(
          ({ ActionBtn, isHidden, ...item }, i) =>
            !isHidden && (
              <Section
                key={item.key || item.title || i}
                width={item.width || 50}
                fullWidth={item.fullWidth || fullWidth}
              >
                {item.title ? (
                  <KeyColumn required={item.required}>{item.title}</KeyColumn>
                ) : null}
                {R.is(Array, item.elem) ? (
                  <Wrapper>
                    {item.elem.map((it) => (
                      <ValueColumn key={uuidV4()}>{it}</ValueColumn>
                    ))}
                    {!!ActionBtn && <ActionBtn />}
                  </Wrapper>
                ) : (
                  <ValueColumn withBtn={!!ActionBtn}>
                    {item.elem || NOTHING_UI_STRING}
                    {!!ActionBtn && <ActionBtn />}
                  </ValueColumn>
                )}
              </Section>
            ),
        )}
      </BodyWrapper>
    </TableLayoutListWrapper>
  );
}

TableLayoutList.propTypes = {
  title: node,
  list: arrayOf(
    shape({
      title: node,
      elem: node,
      required: bool,
      key: string,
      verticalAlign: string,
      ActionBtn: func,
    }).isRequired,
  ).isRequired,
  className: string,
  fullWidth: bool,
};
TableLayoutListWrapper.propTypes = {
  title: node,
  icon: shape({
    size: number,
    color: string,
    name: string.isRequired,
  }),
  children: oneOfType([element, string]).isRequired,
  className: string,
};

TableLayoutList.defaultProps = {
  className: '',
  title: '',
};
TableLayoutListWrapper.defaultProps = {
  title: '',
  className: '',
};
