import * as R from 'ramda';
import React, { useMemo } from 'react';
import { string, func, shape, arrayOf } from 'prop-types';
import { Select } from '@poly/admin-book';

// getEntityOptionsBySchema :: UserGroupPermissionSchemaQueryResult -> [Option]
const getEntityOptionsBySchema = R.compose(
  R.map(R.applySpec({ value: R.prop('_id'), label: R.prop('name') })),
  R.defaultTo([]),
  R.prop('getUserGroupPermissionSchema'),
);

export function UserGroupPermissionEntitySelect({
  schema,
  onChange,
  afterEntitySelect,
  ...props
}) {
  const entityOptions = useMemo(
    () => getEntityOptionsBySchema(schema),
    [schema],
  );

  const handleOnChange = (value) => {
    onChange(value);
    afterEntitySelect(value);
  };

  return (
    <Select {...props} onChange={handleOnChange} options={entityOptions} />
  );
}

UserGroupPermissionEntitySelect.propTypes = {
  onChange: func.isRequired,
  afterEntitySelect: func.isRequired,
  schema: shape({
    getUserGroupPermissionSchema: arrayOf(shape({ _id: string.isRequired })),
  }),
};
