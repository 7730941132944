import * as R from 'ramda';
import React, { useMemo } from 'react';
import { string, func, shape, arrayOf, bool } from 'prop-types';
import { gql, useMutation, useQuery } from '@apollo/client';
import { InvoiceTypes } from '@poly/constants';
import { propEqLegacy, removePropDeep } from '@poly/utils';
import { Loader } from '@poly/admin-book';
import {
  commonAddressFields,
  completeTaskByPath,
  updateCacheHandler,
  mailToInitValues,
} from '@poly/admin-ui';

import {
  supplierInvoiceFormId,
  SupplierInvoiceFormActions,
  SupplierInvoiceFormSubmitType,
} from '../../../modules/forms/supplierInvoiceForm/constants.js';
import { Form } from '../../../modules/forms/supplierInvoiceForm/supplierInvoiceForm.js';
import {
  getCommonProjectFields,
  prepareDataToMutation,
  prepareFileToMutation,
  prepareInvoiceToForm,
} from '../../../modules/forms/supplierInvoiceForm/supplierInvoiceFormUtils.js';

// eslint-disable-next-line import/no-unused-modules
export const APPROVE_INVOICE_MUTATION = gql`
  mutation APPROVE_INVOICE_MUTATION($id: ID!, $input: ApproveInvoiceInput!) {
    approveInvoice(id: $id, input: $input) {
      _id
    }
  }
`;

// eslint-disable-next-line import/no-unused-modules
export const REJECT_INVOICE_MUTATION = gql`
  mutation REJECT_INVOICE_MUTATION($id: ID!, $input: RejectInvoiceInput!) {
    rejectInvoice(id: $id, input: $input) {
      _id
    }
  }
`;

// eslint-disable-next-line import/no-unused-modules
export const APPROVE_INVOICE_QUERY = gql`
  query APPROVE_INVOICE_QUERY($invoiceId: ID!) {
    invoice(id: $invoiceId) {
      _id
      type
      invoiceNumber
      invoiceDate
      taxAmount
      total
      invoiceFile {
        url
        fileName
        fileSize
        fileType
      }
      status
      materialCost
      hourlyRate
      labourCost
      project {
        _id
        type
        projectId
        client {
          _id
          name
        }
        property {
          _id
          name
          address {
            ...commonAddressFields
          }
        }
        suppliers {
          _id
          type
          nte
          statusInProject
          company {
            name
          }
        }
        clientInvoices {
          _id
        }
        invoiceDescription
      }
      supplier {
        _id
        company {
          name
        }
      }
      balance
    }
  }

  ${commonAddressFields}
`;

// getCurrentInvoice :: { invoice: Invoice } -> Invoice
const getCurrentInvoice = R.propOr({}, 'invoice');

export const useApproveInvoiceQuery = (invoiceId) => {
  const { loading, data } = useQuery(APPROVE_INVOICE_QUERY, {
    variables: { invoiceId },
    skip: !invoiceId,
    fetchPolicy: 'network-only',
  });

  return {
    invoice: getCurrentInvoice(data),
    loading,
  };
};

// isRejectActionExists :: { rejectAction: String } -> Boolean
const isRejectActionExists = R.propSatisfies(
  R.includes(R.__, R.values(SupplierInvoiceFormSubmitType)),
  'rejectAction',
);

// isRequestNewInvoice :: { rejectAction: String } -> Boolean
const isRequestNewInvoice = propEqLegacy(
  'rejectAction',
  SupplierInvoiceFormSubmitType.requestNewInvoice,
);

// checkIsBreakdownInvoice :: Invoice -> Boolean
const checkIsBreakdownInvoice = propEqLegacy('type', InvoiceTypes.breakdown);

export function ApproveSupplierInvoiceForm({
  taskId,
  formId,
  onCancel,
  invoiceId,
  taskSupplier,
  withoutTitle,
  withCloseAction,
  withPersistFields,
  withRemoveInvoice,
  readFromCacheParams,
  keepDirtyOnReinitialize,
}) {
  const [approveInvoice] = useMutation(APPROVE_INVOICE_MUTATION);
  const [rejectInvoice] = useMutation(REJECT_INVOICE_MUTATION);

  const { invoice, loading } = useApproveInvoiceQuery(invoiceId);

  const isBreakdownInvoice = useMemo(
    () => checkIsBreakdownInvoice(invoice),
    [invoice],
  );

  if (loading) {
    return <Loader />;
  }

  const approveInvoiceByInput = async ({ closeProject, ...input }) => {
    if (isRejectActionExists(input)) {
      await rejectInvoice({
        variables: {
          id: invoice._id,
          input: {
            rejectionReason: input.rejectionReason,
            resendRequest: isRequestNewInvoice(input),
          },
        },
      });
    }

    if (!input.rejectAction) {
      const variables = R.compose(
        R.mergeDeepLeft({ id: invoice._id }),
        R.objOf('input'),
        R.when(R.always(closeProject), R.mergeLeft({ closeProject: true })),
        prepareFileToMutation(invoice),
        removePropDeep('projectId'),
        prepareDataToMutation,
      )(input);
      const additionalParams = readFromCacheParams
        ? {
            update: updateCacheHandler(
              readFromCacheParams.query,
              readFromCacheParams.variables,
              completeTaskByPath(readFromCacheParams.path, taskId),
            ),
          }
        : {};

      await approveInvoice({
        variables,
        ...additionalParams,
      });
    }
  };

  const persistFormOption = withPersistFields
    ? {
        persistKey: `${formId}-${invoiceId}`,
        persistFields: [
          'rating',
          'taxAmount',
          'formAction',
          'labourCost',
          'hourlyRate',
          'invoiceDate',
          'materialCost',
          'closeProject',
          'invoiceNumber',
          'rejectionReason',
          'projectInvoiceDescription',
          ...(isBreakdownInvoice ? [] : ['total']),
        ],
      }
    : null;

  const formProps = {
    project: invoice.project,
    mutate: approveInvoiceByInput,
    submitCaption: 'Approve & Complete Task',
    formId,
    getSuccessMessage: (formAction) =>
      `Invoice was ${
        formAction === SupplierInvoiceFormActions.reject
          ? 'rejected.'
          : 'approved.'
      }`,
    initialValues: {
      taskSupplier,
      paidAmount: invoice.total - invoice.balance,
      ...getCommonProjectFields(invoice.project),
      ...prepareInvoiceToForm(invoice),
      mailTo: mailToInitValues,
      formAction: SupplierInvoiceFormActions.approve,
      rejectionReason: '',
      submitType: null,
      rating: { average: 0 },
      invoice,
      rejectAction: SupplierInvoiceFormSubmitType.requestNewInvoice,
    },
    projectId: invoice.project?._id,
    persistOptions: persistFormOption,
    onCancel,
    withoutTitle,
    withCloseAction,
    withRemoveInvoice,
    keepDirtyOnReinitialize,
  };

  return <Form {...formProps} />;
}

ApproveSupplierInvoiceForm.propTypes = {
  taskId: string,
  onCancel: func,
  invoiceId: string.isRequired,
  taskSupplier: shape({ _id: string.isRequired }),
  readFromCacheParams: shape({ path: arrayOf(string) }),
  withoutTitle: bool,
  withCloseAction: bool,
  formId: string,
  withRemoveInvoice: bool,
  withPersistFields: bool,
  keepDirtyOnReinitialize: bool,
};

ApproveSupplierInvoiceForm.defaultProps = {
  formId: supplierInvoiceFormId,
  keepDirtyOnReinitialize: false,
};
