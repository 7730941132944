import React from 'react';
import * as R from 'ramda';
import { number, func, string } from 'prop-types';

import { PageSelect } from './PageSelect.js';
import { FlexContainer } from '../lib/index.js';
import { ItemsPerPageSelect } from './PagesCountSelect.js';
import { itemsPerPageOptions, maxItems } from './constants.js';

export function Pagination({
  onChange,
  currentPage,
  itemsPerPage,
  allItemsCount,
  className = '',
}) {
  const itemsPerPageAtRange = R.when(
    R.compose(R.not, (it) =>
      R.includes(it, R.map(R.prop('value'), itemsPerPageOptions)),
    ),
    R.always(maxItems),
  )(itemsPerPage);

  const onItemsPerPageChange = (val) => {
    // prevent from rendering ALL items if there are too many
    const countItems =
      val === maxItems && maxItems < allItemsCount ? maxItems : val;

    // ensure current page is not outside of total range otherwise adjust
    const newCurrentPage = val * currentPage >= allItemsCount ? 1 : currentPage;

    onChange({
      itemsPerPage: countItems,
      // if all results are select redirect to first page to prevent blank results
      currentPage: newCurrentPage,
      allItemsCount,
    });
  };

  const onPageChange = (val) =>
    onChange({
      allItemsCount,
      currentPage: val,
      itemsPerPage: itemsPerPageAtRange,
    });

  return (
    <FlexContainer className={className}>
      <PageSelect
        onChange={onPageChange}
        currentPage={currentPage}
        pagesCount={Math.ceil(allItemsCount / itemsPerPageAtRange)}
      />
      <ItemsPerPageSelect
        value={itemsPerPageAtRange}
        onChange={onItemsPerPageChange}
      />
    </FlexContainer>
  );
}

Pagination.propTypes = {
  className: string,
  onChange: func.isRequired,
  currentPage: number.isRequired,
  itemsPerPage: number.isRequired,
  allItemsCount: number.isRequired,
};
