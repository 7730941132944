import React from 'react';
import { string } from 'prop-types';
import { filesCollections } from '@poly/constants';
import { SidebarFilesTab } from '@poly/admin-ui';

export function UserSidebarFilesTab({ userId }) {
  const tabProps = {
    collection: filesCollections.USERS,
    entity: { _id: userId, preview: 'user' },
  };

  return <SidebarFilesTab {...tabProps} />;
}

UserSidebarFilesTab.propTypes = { userId: string.isRequired };
