import * as R from 'ramda';
import React, { useEffect } from 'react';
import { gql } from '@apollo/client';
import { Select } from '@poly/admin-book';
import { AccountTypeStatus } from '@poly/constants';
import { func, string } from 'prop-types';
import { useReactiveQuery } from '@poly/client-utils';
import { propEqLegacy } from '@poly/utils';

import {
  formatAccountOptionText,
  ACCOUNT_CHANGED_SUBSCRIPTION,
} from './AccountsSelect.js';

const GET_DIVISION_OPTIONS = gql`
  query GET_DIVISION_OPTIONS {
    getDivisionOptions {
      _id
      name
      code
      status
      is_default_client_invoice_account
    }
  }
`;

// getDivisionSelectOptions :: ID -> AccountsQueryData -> [DivisionOption]
// DivisionOption = [ID String]
const getDivisionSelectOptions = R.curry((value, data) =>
  R.compose(
    R.map(
      R.applySpec({
        value: R.prop('_id'),
        label: formatAccountOptionText,
      }),
    ),
    R.reject(
      R.both(
        propEqLegacy('status', AccountTypeStatus.INACTIVE),
        R.complement(propEqLegacy('_id', value)),
      ),
    ),
    R.propOr([], 'getDivisionOptions'),
  )(data),
);

// getDefaultDivisionAccountId :: AccountsQueryData -> ID
const getDefaultDivisionAccountId = R.compose(
  R.prop('_id'),
  R.find(R.prop('is_default_client_invoice_account')),
  R.reject(propEqLegacy('status', AccountTypeStatus.INACTIVE)),
  R.propOr([], 'getDivisionOptions'),
);

export function DivisionSelect({ value, onChange, ...props }) {
  const { data, loading } = useReactiveQuery(
    GET_DIVISION_OPTIONS,
    ACCOUNT_CHANGED_SUBSCRIPTION,
    {
      queryOptions: {
        fetchPolicy: 'network-only',
      },
      subscriptionOptions: {},
    },
  );

  const options = getDivisionSelectOptions(value, data);

  useEffect(() => {
    const defaultAccountId = getDefaultDivisionAccountId(data);
    if (!value && defaultAccountId) {
      onChange(defaultAccountId);
    }
  }, [loading]);

  const selectProps = {
    value,
    options,
    loading,
    onChange,
    name: 'division',
    ...props,
  };

  return <Select {...selectProps} />;
}

DivisionSelect.propTypes = {
  value: string,
  onChange: func.isRequired,
};
