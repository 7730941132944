import { useEffect, useMemo } from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { gql } from '@apollo/client';
import {
  useInfiniteScrollQueryWithSubscription,
  useSortableTable,
} from '@poly/client-utils';
import { assocBy, isNilOrEmpty } from '@poly/utils';

import { supplierDocumentsForReviewTableConfig } from './RequestedSupplierDocumentsReviewTable.js';
import { setPreviewIds } from '../../redux/requestedSupplierDocumentsReducer.js';

const requestedSupplierDocumentSub = gql`
  subscription requestedSupplierDocumentSub($input: CollectionSearchParams!) {
    searchRequestedSupplierDocumentChanged(input: $input) {
      id
      type
    }
  }
`;

const REQUESTED_SUPPLIERS_DOCUMENTS = gql`
  query REQUESTED_SUPPLIERS_DOCUMENTS(
    $input: RequestedSupplierDocumentsInput!
  ) {
    requestedSupplierDocuments(input: $input) {
      hits {
        _id
        expiration
        documentType
        supplier {
          _id
          company {
            name
          }
        }
        masterSupplier {
          _id
          company {
            name
          }
        }
      }
      total
    }
  }
`;

// prepareFilter :: Object -> Object
const prepareFilter = R.compose(
  R.dissoc('supplier'),
  R.when(
    R.prop('supplier'),
    assocBy('supplierId', R.path(['supplier', '_id'])),
  ),
  R.reject(isNilOrEmpty),
);

// getTermQueryByProp :: String -> RequestedDocumentsFilter -> ElasticQuery
const getTermQueryByProp = (propName) =>
  R.ifElse(
    R.prop(propName),
    R.applySpec({ term: { [propName]: R.prop(propName) } }),
    R.always(null),
  );

// getRequestedDocumentsSubscriptionInput :: RequestedDocumentsFilter -> ElasticQuery
const getRequestedDocumentsSubscriptionInput = R.ifElse(
  isNilOrEmpty,
  R.always(null),
  R.compose(
    R.assocPath(['bool', 'filter'], R.__, {}),
    R.reject(isNilOrEmpty),
    R.juxt([
      getTermQueryByProp('supplierId'),
      getTermQueryByProp('documentType'),
    ]),
    prepareFilter,
  ),
);

export const useRequestedSupplierDocuments = ({ filter }) => {
  const dispatch = useDispatch();

  const { sort, ...tableSortProps } = useSortableTable({
    column: 1,
    tableConfig: supplierDocumentsForReviewTableConfig,
  });

  const input = useMemo(
    () => ({
      ...prepareFilter(filter),
      sort,
    }),
    [filter, sort],
  );

  const { data, loading, tableProps } = useInfiniteScrollQueryWithSubscription(
    REQUESTED_SUPPLIERS_DOCUMENTS,
    input,
    {
      pageSize: 100,
      endpointName: 'requestedSupplierDocuments',
    },
    requestedSupplierDocumentSub,
    { input: { query: getRequestedDocumentsSubscriptionInput(filter) } },
  );

  const documents = R.pathOr([], ['requestedSupplierDocuments', 'hits'], data);

  useEffect(() => {
    dispatch(setPreviewIds(R.pluck('_id', documents)));
  }, [documents]);

  return {
    documents,
    loading,
    tableProps: {
      ...tableProps,
      ...tableSortProps,
    },
  };
};
