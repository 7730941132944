export const WorkOrderPriority = {
  CUSTOM: 'a_customPriority',
  PLANNED: 'b_plannedPriority',
  NORMAL: 'c_normalPriority',
  URGENT: 'd_urgentPriority',
  EMERGENCY: 'e_emergencyPriority',
};

export const ProjectPrioritiesUIMap = {
  [WorkOrderPriority.EMERGENCY]: 'Emergency',
  [WorkOrderPriority.PLANNED]: 'Planned',
  [WorkOrderPriority.URGENT]: 'Urgent',
  [WorkOrderPriority.NORMAL]: 'Normal',
  [WorkOrderPriority.CUSTOM]: 'Custom',
};

export const ClientProjectPriorityUnit = {
  HOURS: 'hours',
  DAYS: 'days',
};

const { NORMAL, CUSTOM, URGENT, EMERGENCY } = WorkOrderPriority;

export const DEFAULT_CLIENT_PROJECT_PRIORITIES = [
  {
    id: CUSTOM,
    priority: ProjectPrioritiesUIMap[CUSTOM],
    unit: ClientProjectPriorityUnit.HOURS,
    amount: 1,
  },
  {
    id: NORMAL,
    priority: ProjectPrioritiesUIMap[NORMAL],
    unit: ClientProjectPriorityUnit.DAYS,
    amount: 5,
  },
  {
    id: URGENT,
    priority: ProjectPrioritiesUIMap[URGENT],
    unit: ClientProjectPriorityUnit.HOURS,
    amount: 24,
  },
  {
    id: EMERGENCY,
    priority: ProjectPrioritiesUIMap[EMERGENCY],
    unit: ClientProjectPriorityUnit.HOURS,
    amount: 4,
  },
];
