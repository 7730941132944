import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useMutation, useQuery } from '@apollo/client';
import { Loader } from '@poly/admin-book';

import { shape, string } from 'prop-types';
import { SERVICE_TYPE_DETAILS } from '../../../hocs/serviceTypes/queries.js';
import { UPDATE_SERVICE_TYPE_MUTATION } from '../../../hocs/serviceTypes/mutations.js';
import { useCloseCurrentModal } from '../../../redux/modal.js';
import { ServiceTypeForm } from './form/ServiceTypeForm.js';

export function UpdateServiceTypeForm(props) {
  const serviceTypeId = useSelector(
    R.path(['modal', 'payload', 'serviceTypeId']),
  );

  const closeCurrentModal = useCloseCurrentModal();

  const { data, loading } = useQuery(SERVICE_TYPE_DETAILS, {
    variables: { id: serviceTypeId },
    fetchPolicy: 'network-only',
  });

  const serviceType = R.propOr({}, 'serviceType', data);

  const [updateServiceType] = useMutation(UPDATE_SERVICE_TYPE_MUTATION);
  const mutate = ({ _id, name, status, description }) =>
    updateServiceType({
      variables: {
        id: _id,
        input: { name, status, description },
      },
    });

  const initialValues = R.pick(
    ['name', 'description', 'status', '_id'],
    serviceType,
  );

  return loading ? (
    <Loader />
  ) : (
    <ServiceTypeForm
      successMessage="Service type was updated"
      onCancel={closeCurrentModal}
      initialValues={initialValues}
      mutate={mutate}
      {...props}
    />
  );
}

UpdateServiceTypeForm.propTypes = {
  payload: shape({
    serviceTypeId: string.isRequired,
  }).isRequired,
};
