import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useQuery } from '@apollo/client';
import { commonSortQuery, paginationToQueryParams } from '@poly/client-utils';
import { ASC_SORT_ORDER } from '@poly/constants';
import { INVOICES_BY_SEARCH } from '../../modules/core/hooks/invoices/queries.js';

export const useSearchSupplierInvoices = (supplierId) => {
  const pagination = useSelector(R.prop('pagination'));
  const searchText = useSelector(R.prop('searchText'));
  const sort = useSelector(R.prop('entitySort'));

  const { data, loading } = useQuery(INVOICES_BY_SEARCH, {
    variables: {
      searchInput: {
        searchTerm: searchText,
        query: { match: { supplierId } },
        sort: sort || commonSortQuery(['invoiceNumber'])(ASC_SORT_ORDER),
        ...paginationToQueryParams(pagination),
      },
    },
  });
  return {
    data,
    loading,
  };
};
