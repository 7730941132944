import React from 'react';
import { object, node } from 'prop-types';
import {
  ThemeProvider as StyledThemeProvider,
  createGlobalStyle,
} from 'styled-components';

export const defaultTheme = {
  defaultBorderRadius: '3px',
  defaultTransition: '0.2s ease',
  defaultBoxShadow: '0 0 9px 0 #dbdde6',
  colors: {
    inspiniaPrimaryLighter2: '#ECECEC',
    inspiniaBlue: '#1c84c6',
    inspiniaBlueLighter: '#23c6c8',
    inspiniaGreen: '#1ab394',
    inspiniaGray: '#d1dade',
    navBarLighter: '#506fa9',
    navBarLighter1: '#a2b8eb',
    navBarLighter2: '#5f7bb1',
    primary: '#1a1514',
    primaryLighter: 'rgba(30, 36, 44, 0.8)',
    primaryLighter1: 'rgba(30, 36, 44, 0.7)',
    primaryLighter2: 'rgba(30, 36, 44, 0.6)',
    primaryLighter3: 'rgba(30, 36, 44, 0.55)',
    primaryLighter4: 'rgba(30, 36, 44, 0.5)',
    primaryLighter5: 'rgba(30, 36, 44, 0.45)',
    primaryLighter6: 'rgba(30, 36, 44, 0.4)',
    primaryLighter7: 'rgba(30, 36, 44, 0.22)',
    primaryLighter8: 'rgba(30, 36, 44, 0.15)',
    secondary: '#9a9da0',
    secondaryLighter1: '#a5a7ab',
    secondaryLighter2: '#f5f6f7',
    secondaryLighter3: '#fcfcfd',
    secondaryLighter5: '#f0f0f0',
    accent: 'rgb(255, 99, 99)',
    accent1Lighter1: '#dd6262',
    accent1Lighter2: '#f99090',
    accent1Lighter3: '#ffeeea',
    accent2: '#0056FF',
    accent2Lighter2: '#9dacdc',
    accent2Lighter3: 'rgba(71, 119, 216, 0.08)',
    accent2Lighter4: '#f6f9fd',
    accent2Lighter5: 'rgba(110, 220, 255, 0.18)',
    accent2Lighter6: '#e6f7ff',
    accent2Lighter7: '#1891ff',
    accent2Lighter8: '#f6fafe',
    accent3: '#fffab1',
    accent4: '#b32806',
    accent6: '#3cbf2f',

    // for step by step refactor Admin to new theme
    primaryDark: '#0E285D',
    primaryRegular: '#12347A',
    primaryMid: '#174198',
    primaryLight: '#436CBE',
    secondaryDark: '#9F2F2F',
    secondaryRegular: '#C34444',
    secondaryMid: '#FF6363',
    secondaryLight: '#FF8787',
    tertiaryDark: '#4C682E',
    tertiaryRegular: '#6B943E',
    tertiaryMid: '#85BB49',
    tertiaryLight: '#AFDC7C',
    darkest: '#333333',
    darker: '#404040',
    dark: '#575757',
    midDark: '#999999',
    mid: '#BCBCBC',
    midLight: '#EBEBEB',
    light: '#F2F2F2',
    lighter: '#F7F7F7',
    lightest: '#FAFAFA',
    black: '#000000',
    white: '#FFFFFF',
    amber: '#FFB841',

    newPrimary: '#5e6271',
    newDarkAccent2: '#014398',
    label: '#333',
    cancelBtn: '#575757',

    gold: '#f2b441',
    transparent: 'transparent',

    inputIcon: '#9197AC',

    notificator: {
      success: {
        text: '#5c9819',
        border: 'rgba(132, 188, 69, 0.31)',
        background: ' #DFEDCE',
      },
      warning: {
        text: '#d19c19',
        border: '#ba9027',
        background: '#ffeec4',
      },
      error: {
        text: '#cd1544',
        border: '#cd1544',
        background: '#ffceda',
      },
    },
    table: {
      rowBg: '#f9f9fa',
      sorting: {
        active: '#6d717f',
        disabled: '#a5aabc',
      },
    },
    statuses: {
      blocked: 'rgb(255, 99, 99)',
      error: '#e75858',
      pending: '#f5ad20',
      ready: '#ddd44c',
      inactive: '#F7B500',
      funded: '#8599f0',
      active: '#85BB49',
    },
    projectStatuses: {
      blocked: 'rgb(62, 76, 91)',
      inactive: 'rgb(165, 170, 188)',
    },
  },
  fonts: {
    bold: '700',
    semibold: '600',
    medium: '500',
    regular: '400',
    hairline: '100',
  },
};

const GlobalStyle = createGlobalStyle`
    @import url("/fonts.css");

    *,
    ::before,
    ::after {
      box-sizing: inherit;
      font-family: 'TTNormsMedium', 'FFMarkWebProNarrowBook', sans-serif;
    }

    html {
      line-height: 1;
      box-sizing: border-box;
      -ms-text-size-adjust: 100%;
      -webkit-text-size-adjust: 100%;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    }

    html,
    body {
      margin: 0;
      padding: 0;
      height: 100%;
    }

    body {
      font-family: 'TTNormsMedium', 'FFMarkWebProNarrowBook', sans-serif;
      font-weight: ${defaultTheme.fonts.regular};
      cursor: default;
    }

    a {
      color: ${defaultTheme.colors.primaryLight};
      background-color: transparent;
      cursor: pointer;
      font-weight: ${defaultTheme.fonts.regular};
      font-family: 'TTNormsMedium', 'FFMarkWebProNarrowMedium', sans-serif;
      text-decoration: none;

      &:hover,
      &:focus {
        font-weight: ${defaultTheme.fonts.medium};
        color: ${({ disabled }) => !disabled && defaultTheme.colors.accent2};
      }
    }

    em {
      font-style: normal;
      color: ${defaultTheme.colors.accent};
    }

    img {
      border-style: none;
    }

    @-webkit-keyframes autofill {
      to {
        color: #161616;
        background: transparent;
      }
    }

    input:-webkit-autofill {
      -webkit-animation-name: autofill;
      -webkit-animation-fill-mode: both;
    }

    button {
      font-family: 'TTNormsMedium', 'FFMarkWebProNarrowMedium', sans-serif;
    }

    button,
    select {
      text-transform: none;
    }

    button,
    html [type="button"],
    [type="reset"],
    [type="submit"] {
      -webkit-appearance: button;
    }

    button::-moz-focus-inner,
    [type="button"]::-moz-focus-inner,
    [type="reset"]::-moz-focus-inner,
    [type="submit"]::-moz-focus-inner {
      padding: 0;
      border-style: none;
    }

    button:-moz-focusring,
    [type="button"]:-moz-focusring,
    [type="reset"]:-moz-focusring,
    [type="submit"]:-moz-focusring {
      outline: none;
    }

    textarea {
      overflow: auto;
    }

    [type="checkbox"],
    [type="radio"] {
      padding: 0;
      box-sizing: border-box;
    }

    [type="number"]::-webkit-inner-spin-button,
    [type="number"]::-webkit-outer-spin-button {
      height: auto;
    }

    [type="search"] {
      -webkit-appearance: textfield;
      outline-offset: -2px;
    }

    [type="search"]::-webkit-search-cancel-button,
    [type="search"]::-webkit-search-decoration {
      -webkit-appearance: none;
    }

    [hidden] {
      display: none;
    }

    ::-webkit-file-upload-button {
      -webkit-appearance: button;
      font: inherit;
    }

    :-moz-ui-invalid,
    :-moz-ui-invalid:-moz-focusring {
      box-shadow: none;
    }

    :focus {
      outline: none;
    }
    
    .select__option {
      font-size: 12px;
      line-height: 18px;
    }
    
    .select__menu-portal {
      z-index: 10002 !important;
    }

    .pac-container {
      z-index: 10002 !important;
    }

    .multiselect__menu {
      font-size: 12px;

      color: ${defaultTheme.colors.primary};
    }
  `;

export function ThemeProvider({ theme = defaultTheme, children }) {
  return (
    <StyledThemeProvider {...{ theme }}>
      <GlobalStyle />
      {children}
    </StyledThemeProvider>
  );
}

ThemeProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: object.isRequired,
  children: node.isRequired,
};

ThemeProvider.displayName = 'ThemeProvider';
