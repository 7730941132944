import * as R from 'ramda';
import { useMemo } from 'react';
import { collectionNames } from '@poly/constants';
import { entities, useMasterSupplierDetailsQuery } from '@poly/admin-ui';
import { useRouterParams } from '@poly/client-routing';

export const useMasterSupplierTabProps = () => {
  const { supplierId } = useRouterParams(['supplierId']);
  const { data, loading } = useMasterSupplierDetailsQuery(supplierId);

  const entityId = useMemo(
    () => R.path(['masterSupplier', '_id'], data),
    [loading],
  );
  const humanReadableId = useMemo(
    () => R.path(['masterSupplier', 'company', 'name'], data),
    [loading],
  );

  return {
    loading,
    collection: collectionNames.masterSuppliers,
    entity: {
      _id: entityId,
      humanReadableId,
      name: entities.MASTER_SUPPLIER,
    },
  };
};
