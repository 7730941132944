import * as R from 'ramda';
import Delta from 'quill-delta';
import {
  HOUSEKEEPING_PROJECT_CHILD_PAYLOAD_FIELDS,
  REPORT_ONLY_PROJECT_CHILD_PAYLOAD_FIELDS,
  FEE_PROJECT_CHILD_PAYLOAD_FIELDS,
  BID_PROJECT_CHILD_PAYLOAD_FIELDS,
  TM_PROJECT_CHILD_PAYLOAD_FIELDS,
  RWOTaskListType,
  ProjectType,
  ChildType,
  LISTING_PROJECT_SPECIFIC_FIELDS,
  RecurringProjectTypes,
  WorkOrderPriority,
  ProjectSpendTypes,
} from '@poly/constants';
import {
  applySpecWithFields,
  removePropDeep,
  alwaysNewDate,
  renameProp,
  isNilOrEmpty,
  assocBy,
  propEqLegacy,
  ofArrayLegacy,
} from '@poly/utils';
import { omitClientReferenceNumberAndPOIfNeed } from '@poly/utils/src/projects/omitClientReferenceNumberAndPOIfNeed.js';

import {
  formTypeConstants,
  EMPTY_PROPERTY_LOCATION_VALUE,
} from '../form/utils/constants.js';
import { ALL } from '../../../../modules/core/constants/general.js';
import { ProjectOccurrence } from '../../../../modules/core/constants/projects.js';
import { prepareAdminPOAndReferenceNumber } from './prepareAdminPOAndReferenceNumber.js';
import {
  isNotRecurringAndFeeProject,
  isRecurringOrPMFormData,
  isRecurringFormData,
  isFeeProjectType,
  isBidProjectType,
} from '../form/utils/sections.js';
import {
  convertDateToNewYorkTimeZone,
  isProjectSubCategoryEnabled,
} from './helpers.js';

const projectFormFields = [
  'type',
  'serviceTypeId',
  'clientId',
  'managerId',
  'propertyId',
  'status',
  'startDate',
  'endDate',
  'clientManagerId',
  'description',
  'invoiceDescription',
  'division',
  'parentCloneId',
  'cloneFromProjectId',
  'divisionAccountId',
  'disableChildrenGeneration',
  'workCompletionDate',
  'propertyLocationId',
  'subCategoryId',
  'clientGLCodeId',
  'notRM',
  'adminPurchaseOrderId',
  'subPropertiesIds',
  'spendType',
];

// diffFields :: Object -> [fieldName]
const diffFields = (formData) =>
  isRecurringOrPMFormData(formData)
    ? [
        'schedule',
        'taskListType',
        'payload',
        'childType',
        'clientReferenceNumber',
      ]
    : [
        'type',
        'assetIds',
        'suppliers',
        'nte',
        'priority',
        'meta',
        'contactId',
        'siteContactId',
        'feeAmount',
        'feeType',
        'customFeeDescription',
        'bidAmount',
        'clientReferenceNumber',
        'exemptSalesTax',
        'isAfterHoursCall',
        'skipSupplierNotify',
        'notificationConfig',
        'costCenter',
        'nonBillable',
        'nonBillableReason',
      ];

// pickProjectFields :: FormProject -> MutationProject
const pickProjectFields = (fields) => (project) =>
  R.pick([...fields, ...diffFields(project)], project);

// getProjectCustomFields :: FormProject -> Object
const getProjectCustomFields = R.ifElse(
  propEqLegacy('type', ProjectType.LISTING),
  R.compose(R.objOf('listing'), R.pick(LISTING_PROJECT_SPECIFIC_FIELDS)),
  R.always({}),
);

// extractPropertyLocationId :: MutationProject -> MutationProject
const extractPropertyLocationId = R.over(
  R.lensProp('propertyLocationId'),
  R.when(R.equals(EMPTY_PROPERTY_LOCATION_VALUE), R.always(null)),
);

// prepareSubPropertiesIfNeed :: FormProject -> MutationProject
const prepareSubPropertiesIfNeed = R.when(
  R.compose(R.includes(ALL), R.defaultTo([]), R.prop('subPropertiesIds')),
  R.compose(
    R.dissoc('subPropertiesIds'),
    R.assoc('isAllSubPropertiesSelected', true),
  ),
);

/**
 * woFormDataToMutationDataBase :: FormProject -> MutationProject
 */
const woFormDataToMutationDataBase = R.compose(
  prepareSubPropertiesIfNeed,
  R.unless(
    R.compose(isNilOrEmpty, R.prop('subPropertiesIds')),
    R.assoc('costCenter', null),
  ),
  extractPropertyLocationId,
  R.mergeAll,
  R.juxt([
    pickProjectFields(projectFormFields),
    R.ifElse(
      propEqLegacy('formType', formTypeConstants.EDIT),
      R.pick(['scheduleDate']),
      R.always({}),
    ),
    R.ifElse(
      propEqLegacy('occurrence', ProjectOccurrence.ONE_TIME_OCCURRENCE),
      R.pick(['preventativeRepair']),
      R.always({}),
    ),
    R.ifElse(
      R.allPass([
        propEqLegacy('occurrence', ProjectOccurrence.ONE_TIME_OCCURRENCE),
        propEqLegacy('spendType', ProjectSpendTypes.PREVENTIVE_REPAIR),
        R.pathSatisfies(R.is(String), ['preventativeRepair', 'relatedAssetId']),
      ]),
      R.compose(
        R.objOf('assetIds'),
        ofArrayLegacy,
        R.path(['preventativeRepair', 'relatedAssetId']),
      ),
      R.always({}),
    ),
  ]),
  removePropDeep('__typename'),
  R.unless(isProjectSubCategoryEnabled, R.omit(['notRM'])),
  R.when(
    R.complement(R.path(['client', 'enableCostCenter'])),
    R.dissoc('costCenter'),
  ),
  R.unless(
    R.path(['client', 'enablePropertyLocationHierarchy']),
    R.omit(['propertyLocationId']),
  ),
  R.unless(
    propEqLegacy('formType', formTypeConstants.EDIT),
    R.omit(['nonBillable', 'nonBillableReason']),
  ),
  R.when(
    propEqLegacy('formType', formTypeConstants.EDIT),
    R.omit([
      'type',
      'meta',
      'clientId',
      'division',
      'skipSupplierNotify',
      'isEndDateChanged',
    ]),
  ),
  omitClientReferenceNumberAndPOIfNeed,
  R.unless(
    R.both(
      isNotRecurringAndFeeProject,
      R.pathSatisfies(R.isNil, ['parent', '_id']),
    ),
    R.omit(['isAfterHoursCall']),
  ),
  R.when(
    R.propSatisfies(
      R.includes(R.__, [ProjectType.BID, ProjectType.LISTING]),
      'type',
    ),
    R.omit(['nte']),
  ),
  R.ifElse(
    isBidProjectType,
    assocBy('bidAmount', R.propOr(null, 'bidAmount')),
    R.omit(['bidAmount']),
  ),
  R.unless(
    propEqLegacy('type', ProjectType.FEE),
    R.omit(['customFeeDescription', 'feeAmount', 'feeType']),
  ),
  R.unless(R.prop('propertyId'), R.omit(['propertyId'])),
  applySpecWithFields({
    spendType: R.propOr(null, 'spendType'),
    adminPurchaseOrderId: R.propOr(null, 'adminPurchaseOrderId'),
    managerId: R.propOr(null, 'managerId'),
    skipSupplierNotify: R.propOr(false, 'skipSupplierNotify'),
    startDate: R.prop('startDate'),
    endDate: R.prop('endDate'),
    propertyId: R.pathOr('', ['property', '_id']),
    status: R.prop('status'),
    priority: R.propOr(WorkOrderPriority.NORMAL, 'priority'),
    type: R.prop('type'),
    clientReferenceNumber: R.pipe(
      R.prop('clientReferenceNumber'),
      R.when(R.isNil, R.always('')),
    ),
    suppliers: R.ifElse(
      isFeeProjectType,
      R.always([]),
      R.compose(
        R.map(
          R.compose(
            renameProp('_id', 'supplierId'),
            R.reject(R.isNil),
            R.pick(['_id', 'nte', 'disableWOCEmail', 'statusInProject']),
          ),
        ),
        R.propOr([], 'suppliers'),
      ),
    ),
    clientGLCodeId: R.prop('clientGLCodeId'),
    costCenter: R.propOr(null, 'costCenter'),
  }),
  prepareAdminPOAndReferenceNumber,
);

// woFormDataToMutationData :: FormProject -> MutationProject
export const woFormDataToMutationData = R.converge(R.mergeRight, [
  woFormDataToMutationDataBase,
  getProjectCustomFields,
]);

const projectTypePayloadFieldsMap = {
  [ChildType.TM]: TM_PROJECT_CHILD_PAYLOAD_FIELDS,
  [ChildType.BID]: BID_PROJECT_CHILD_PAYLOAD_FIELDS,
  [ChildType.REPORT_ONLY]: REPORT_ONLY_PROJECT_CHILD_PAYLOAD_FIELDS,
  [ChildType.PASS_THROUGH]: REPORT_ONLY_PROJECT_CHILD_PAYLOAD_FIELDS,
  [ChildType.FEE]: FEE_PROJECT_CHILD_PAYLOAD_FIELDS,
  [ChildType.HOUSEKEEPING]: HOUSEKEEPING_PROJECT_CHILD_PAYLOAD_FIELDS,
};

const stringToDelta = (value) => new Delta([{ insert: value }]);

// getPayloadFieldsByProjectType :: Object -> [String]
const getPayloadFieldsByProjectType = R.compose(
  R.defaultTo([]),
  R.prop(R.__, projectTypePayloadFieldsMap),
  R.prop('type'),
);

// prepareRecurringProjectPayload :: ([String, FormData]) -> RecurringProjectPayload
const prepareRecurringProjectPayload = (fields, formData) =>
  R.compose(
    R.when(
      R.compose(isBidProjectType, R.always(formData)),
      assocBy('bidAmount', R.propOr(null, 'bidAmount')),
    ),
    R.pick([...fields, 'notRM']),
  )(formData);

// assignPayloadFields :: Object -> CreateRecurringProjectInput
const assignPayloadFields = (formData) => {
  const payloadFields = getPayloadFieldsByProjectType(formData);
  let payload = prepareRecurringProjectPayload(payloadFields, formData);

  if (payload.invoiceDescription && R.is(String, payload.invoiceDescription)) {
    payload = {
      ...payload,
      invoiceDescription: stringToDelta(payload.invoiceDescription),
    };
  }

  return {
    ...R.omit(payloadFields, formData),
    payload: {
      [formData.type]: payload,
    },
  };
};

// prepareRecurringProjectSuppliers :: FormProject -> [ProjectSupplier]
const prepareRecurringProjectSuppliers = (project) =>
  R.compose(
    R.filter(R.prop('supplierId')),
    R.map(
      R.compose(
        renameProp('_id', 'supplierId'),
        R.when(
          R.compose(R.is(String), R.path(['attachments', 0])),
          renameProp('attachments', 'attachmentsIds'),
        ),
        R.over(
          R.lensProp('attachments'),
          R.compose(
            R.reject(R.isNil),
            R.map(
              R.cond([
                [R.prop('upload'), R.pick(['upload', 'fileName'])],
                [R.prop('url'), R.prop('_id')],
                [R.T, R.always(null)],
              ]),
            ),
            R.defaultTo([]),
          ),
        ),
        R.over(
          R.lensProp('additionalSupplierWOCEmails'),
          R.unless(isNilOrEmpty, R.pluck('value')),
        ),
        R.pick([
          '_id',
          'nte',
          'supplierNote',
          'disableWOCEmail',
          'attachments',
          'additionalSupplierWOCEmails',
        ]),
        R.defaultTo({}),
      ),
    ),
    R.propOr([], 'masterProjectSuppliers'),
  )(project);

// recurringWOFormDataToMutationData :: FormProject -> RecurringProject
export const recurringWOFormDataToMutationData = R.compose(
  prepareSubPropertiesIfNeed,
  extractPropertyLocationId,
  pickProjectFields(projectFormFields),
  R.ifElse(
    propEqLegacy('formType', formTypeConstants.CREATE),
    R.converge(R.mergeLeft, [
      R.ifElse(
        isRecurringFormData,
        R.always({
          type: RecurringProjectTypes.recurringProject,
          disableChildrenGeneration: false,
        }),
        R.always({
          type: RecurringProjectTypes.preventiveMaintenanceProject,
          disableChildrenGeneration: true,
        }),
      ),
      R.identity,
    ]),
    R.omit(['type']),
  ),
  assignPayloadFields,
  R.when(
    propEqLegacy('formType', formTypeConstants.EDIT),
    R.omit([
      'schedule',
      'clientId',
      'childType',
      'division',
      'isEndDateChanged',
    ]),
  ),
  removePropDeep('__typename'),
  R.unless(R.prop('propertyId'), R.omit(['propertyId'])),
  applySpecWithFields({
    spendType: R.propOr(null, 'spendType'),
    adminPurchaseOrderId: R.propOr(null, 'adminPurchaseOrderId'),
    startDate: R.compose(
      convertDateToNewYorkTimeZone,
      R.when(R.isNil, alwaysNewDate),
      R.prop('startDate'),
    ),
    endDate: R.ifElse(
      R.either(R.prop('never'), R.complement(R.prop('endDate'))),
      () => null,
      R.ifElse(
        R.either(
          R.compose(R.not, propEqLegacy('formType', formTypeConstants.EDIT)),
          R.prop('isEndDateChanged'),
        ),
        R.compose(convertDateToNewYorkTimeZone, R.prop('endDate')),
        R.prop('endDate'),
      ),
    ),
    suppliers: prepareRecurringProjectSuppliers,
    propertyId: R.pathOr('', ['property', '_id']),
    childType: R.prop('type'),
    taskListType: R.ifElse(
      isFeeProjectType,
      R.always(RWOTaskListType.LONG_LIST),
      R.prop('taskListType'),
    ),
    clientReferenceNumber: R.pipe(
      R.prop('clientReferenceNumber'),
      R.when(R.isNil, R.always('')),
    ),
  }),
  prepareAdminPOAndReferenceNumber,
);

/**
 * getParamsByCreatedProject :: MutationResult -> Object
 */
export const getParamsByCreatedProject = R.cond([
  [
    R.path(['data', 'createRecurringProject']),
    R.compose(
      R.pick(['projectId']),
      R.path(['data', 'createRecurringProject', 'recurringProject']),
    ),
  ],
  [
    R.T,
    R.compose(
      R.pick(['projectId', 'type']),
      R.path(['data', 'createProject', 'project']),
    ),
  ],
]);
