import { gql } from '@apollo/client';
import { propertyDetailsFields } from './fragments.js';

export const PROPERTIES_BY_SEARCH_SUB = gql`
  subscription PROPERTIES_BY_SEARCH_SUB($searchInput: CollectionSearchParams!) {
    searchPropertyChanged(input: $searchInput) {
      id
      type
    }
  }
`;

export const PROPERTY_DETAILS_SUB = gql`
  subscription PROPERTY_DETAILS_SUB($input: SingleDocSubInput!) {
    propertyChanged(input: $input) {
      id
      document {
        ...propertyDetailsFields
      }
    }
  }
  ${propertyDetailsFields}
`;
