export const IncomeStatementTableHeaders = {
  COMPASS_GL_CODE_HEADER: 'Compass GL Code',
  UNIT_HEADER: 'Unit',
  SAP_DESCRIPTION_HEADER: 'SAP Description',
  POLY_ACCOUNT_HEADER: 'Poly Account',
  POLY_ACCOUNT_AND_CLIENT_NAME_HEADER: 'Poly Account Name/Client Name',
  SUBTOTAL_HEADER: 'Subtotal',
  TOTAL_HEADER: 'Total',
};

export const incomeStatementTableHeaderTitles = [
  { title: IncomeStatementTableHeaders.COMPASS_GL_CODE_HEADER },
  { title: IncomeStatementTableHeaders.UNIT_HEADER },
  { title: IncomeStatementTableHeaders.SAP_DESCRIPTION_HEADER },
  { title: IncomeStatementTableHeaders.POLY_ACCOUNT_HEADER },
  { title: IncomeStatementTableHeaders.POLY_ACCOUNT_AND_CLIENT_NAME_HEADER },
  { title: IncomeStatementTableHeaders.SUBTOTAL_HEADER },
  { title: IncomeStatementTableHeaders.TOTAL_HEADER },
];
