import React from 'react';
import { useDispatch } from 'react-redux';
import { FilesTab as CommonFilesTab, openModal } from '@poly/admin-ui';
import { TextButton } from '@poly/admin-book';

import { string, shape } from 'prop-types';
import { modalNames } from '../modals/index.js';

function AddFileBtn({ collection, entity }) {
  const dispatch = useDispatch();

  const onClick = () =>
    dispatch(
      openModal({
        name: modalNames.ADD_FILES,
        payload: {
          documentId: entity._id,
          collection,
        },
      }),
    );

  const additionalProps = {
    children: 'Add New',
    onClick,
  };

  return <TextButton {...additionalProps} />;
}

AddFileBtn.displayName = 'AddFileBtn';

AddFileBtn.propTypes = {
  collection: string,
  entity: shape({
    _id: string,
  }),
};

export function FilesTab(props) {
  return <CommonFilesTab {...props} AddFileBtn={AddFileBtn} />;
}

FilesTab.displayName = 'FilesTab';
