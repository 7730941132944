import * as R from 'ramda';
import styled from 'styled-components';
import { gql, useMutation } from '@apollo/client';
import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string, shape, func, bool } from 'prop-types';
import { useRouterQuery, useUpdateQueryParams } from '@poly/client-routing';
import { BillingProfileProjectType } from '@poly/constants';
import { Text, getThemeColor } from '@poly/admin-book';
import { useNotificationState } from '@poly/admin-ui';
import { isNilOrEmpty, propEqLegacy } from '@poly/utils';

import { BillingProfileConfigFields } from '../../constants.js';
import { useBillingProfilesQueryByInput } from './useBillingProfilesQueryByInput.js';
import { getBillingProfileQueryConfig } from '../utils.js';
import {
  getBillingProfileConfig,
  setBillingProfileConfig,
} from '../../../../redux/searchFilters/billingProfileConfig.js';
import {
  useSetBillingProfileConfigById,
  prepareBillingProfileConfig,
} from '../../useSetBillingProfileConfigById.js';

const CREATE_BILLING_PROFILE_MUTATION = gql`
  mutation CREATE_BILLING_PROFILE_MUTATION($input: BillingProfileCreateInput!) {
    createBillingProfile(input: $input) {
      _id
    }
  }
`;

const UPDATE_BILLING_PROFILE_MUTATION = gql`
  mutation UPDATE_BILLING_PROFILE_MUTATION(
    $id: ID!
    $input: BillingProfileUpdateInput!
  ) {
    updateBillingProfile(id: $id, input: $input) {
      _id
    }
  }
`;

const CreateBtnTextS = styled(Text)`
  cursor: pointer;
  font-size: 12px;
  line-height: 24px;
  align-self: baseline;
  color: ${getThemeColor(['secondaryMid'])};
`;

// getBillingProfileNameByConfig :: BillingProfileUIConfig -> String
const getBillingProfileNameByConfig = R.path(['billingProfileName', 'value']);

// isProfileNameValid :: BillingProfileUIConfig -> Boolean
const checkProfileNameValid = R.both(
  R.compose(R.complement(isNilOrEmpty), getBillingProfileNameByConfig),
  R.compose(R.not, R.path(['billingProfileName', 'invalid'])),
);

// checkProfileConfigValid :: BillingProfilesQueryResult -> Boolean
const checkProfileConfigValid = (id) =>
  R.compose(
    isNilOrEmpty,
    R.when(() => !!id, R.reject(propEqLegacy('_id', id))),
    R.defaultTo([]),
    R.prop('billingProfiles'),
  );

// getBillingProfileNameByQuery :: BillingProfilesQueryResult -> String
const getBillingProfileNameByQuery = R.path(['billingProfiles', 0, 'name']);

// checkConfigIsNotChanged :: BillingProfileUIConfig -> BillingProfileUIConfig -> Boolean
const checkConfigIsNotChanged = (initialConfig) =>
  R.compose(
    R.equals(R.reject(R.equals(false), initialConfig)),
    R.reject(R.either(isNilOrEmpty, R.equals(false))),
    R.dissocPath(['billingProfileName', 'invalid']),
    R.mergeAll,
    R.juxt([
      R.pick(BillingProfileConfigFields),
      R.pick(R.values(BillingProfileProjectType)),
      R.pick(['billingProfileName', 'consolidation']),
    ]),
  );

export function BillingProfileLogicButton({ values, onChange }) {
  const dispatch = useDispatch();
  const updateQueryParams = useUpdateQueryParams();
  const setBillingProfileConfigById = useSetBillingProfileConfigById();
  const { billingProfileId } = useRouterQuery(['billingProfileId']);
  const initialBillingProfileConfig = useSelector(getBillingProfileConfig);
  const [createBillingProfile] = useMutation(CREATE_BILLING_PROFILE_MUTATION);
  const [updateBillingProfile] = useMutation(UPDATE_BILLING_PROFILE_MUTATION);
  const {
    showSuccessNotification,
    showWarningNotification,
    showErrorNotification,
  } = useNotificationState();

  const queryConfig = useMemo(
    () => getBillingProfileQueryConfig(values),
    [values],
  );

  const { data, queryHandler, clientId } = useBillingProfilesQueryByInput(
    { config: queryConfig },
    false,
    true,
  );

  const isBillingProfileNameValid = useMemo(
    () => checkProfileNameValid(values),
    [values],
  );

  const isBillingProfileConfigValid = useMemo(
    () => checkProfileConfigValid(billingProfileId)(data),
    [data],
  );

  const isConfigIsNotChanged = useMemo(
    () =>
      initialBillingProfileConfig &&
      checkConfigIsNotChanged(initialBillingProfileConfig)(values),
    [values],
  );

  const onChangeBillingProfile = useCallback(async () => {
    onChange(true);

    if (!isBillingProfileNameValid) return;

    if (isConfigIsNotChanged) {
      showWarningNotification('There are no changes to update');

      return;
    }

    await queryHandler({
      variables: { input: { clientId, config: queryConfig } },
      skip: !clientId,
    });

    if (!isBillingProfileConfigValid) {
      const existedName = getBillingProfileNameByQuery(data);

      showErrorNotification(
        `Billing Profile with the same config already existed under the name "${existedName}"`,
      );

      return;
    }

    const newBillingProfileName = getBillingProfileNameByConfig(values);

    const mutation = billingProfileId
      ? updateBillingProfile
      : createBillingProfile;

    const message = `Billing Profile was successfully ${
      billingProfileId ? 'updated' : 'created'
    }`;

    const variables = billingProfileId
      ? {
          id: billingProfileId,
          input: { config: queryConfig, name: newBillingProfileName },
        }
      : {
          input: { clientId, config: queryConfig, name: newBillingProfileName },
        };

    const mutationResult = await mutation({ variables });

    if (!billingProfileId) {
      const newProfileId = mutationResult.data.createBillingProfile._id;

      updateQueryParams({ billingProfileId: newProfileId });
      setBillingProfileConfigById(newProfileId);
    } else {
      dispatch(
        setBillingProfileConfig(
          prepareBillingProfileConfig({
            billingProfile: {
              config: queryConfig,
              name: newBillingProfileName,
            },
          }),
        ),
      );
    }

    showSuccessNotification(message);
    onChange(false);
  }, [
    data,
    values,
    clientId,
    billingProfileId,
    isConfigIsNotChanged,
    isBillingProfileNameValid,
    isBillingProfileConfigValid,
  ]);

  const btnName = `${billingProfileId ? 'Update' : 'Save as'} Billing Profile`;

  return (
    <CreateBtnTextS onClick={onChangeBillingProfile}>{btnName}</CreateBtnTextS>
  );
}

BillingProfileLogicButton.propTypes = {
  onChange: func.isRequired,
  values: shape({
    billingProfileName: shape({ value: string, invalid: bool }),
  }),
};
