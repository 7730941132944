import React, { useState } from 'react';
import { ClientSelect } from '@poly/admin-ui';
import { PRINT_PDF_CAPTION } from '@poly/constants';
import { Button, Loader, NumberInputHTML5 } from '@poly/admin-book';
import { propEqLegacy } from '@poly/utils';
import styled from 'styled-components';
import { useRedirectIfHasNoPermissions } from '@poly/client-utils';
import { PRINT_CLIENT_INVOICE_PERMISSION } from '@poly/security';

import {
  PageWithSearchHeader,
  SearchPageContentWrapper,
  SearchPageHeaderContainer,
} from '../../components/PageWithSearchHeader.js';
import { SearchHeaderCreator } from '../../components/SearchHeaderCreator.js';
import {
  filterClientInvoiceByOptions,
  searchClientInvoicesFilters,
} from './constants.js';
import { SearchBySelector } from './SearchBySelector.js';
import { DatePicker } from '../../modules/pagesHeaders/ProjectSearchPageHeader/filter/components.js';
import { InvoiceStatusSelector } from './InvoiceStatusSelector.js';
import { PrintingStatusSelector } from './PrintingStatusSelector.js';
import { ProjectSelect } from '../../components/ProjectsSelect.js';
import { useSearchClientInvoicesQuery } from './useSearchClientInvoices.js';
import {
  BottomPanelBody,
  BottomPanelContainer,
} from '../../components/BottomPanel.js';
import { PrintClientInvoicesTable } from './PrintClientInvoicesTable.js';
import { FlexContainer } from '../../components/FlexContainer.js';
import { usePrintInvoicesMutation } from './usePrintInvoices.js';
import { ALL } from '../../modules/core/constants/general.js';
import { ConsolidatedInvoiceSelect } from '../../components/ConsolidatedInvoiceSelect.js';

const headerConfig = [
  {
    key: 'mainRow',
    columns: [
      {
        name: searchClientInvoicesFilters.clientId,
        title: 'Client',
        filterProps: {
          width: '100%',
          includeAllOption: false,
        },
        columnLayout: { titleWidth: '150px' },
        column: 1,
        Component: ClientSelect,
      },
      {
        name: searchClientInvoicesFilters.invoiceBy,
        title: 'Search Invoices By',
        defaultValue: filterClientInvoiceByOptions.one,
        filterProps: {
          width: '100%',
          includeAllOption: false,
        },
        columnLayout: { titleWidth: '150px' },
        column: 1,
        Component: SearchBySelector,
      },
      {
        name: searchClientInvoicesFilters.invoiceNumber,
        title: 'Invoice Number',
        filterProps: {
          width: '100%',
          maxLength: 9,
        },
        columnLayout: { titleWidth: '150px' },
        column: 1,
        Component: NumberInputHTML5,
        renderIf: propEqLegacy(
          searchClientInvoicesFilters.invoiceBy,
          filterClientInvoiceByOptions.one,
        ),
      },
      {
        name: searchClientInvoicesFilters.invoiceNumberFrom,
        title: 'From Invoice #',
        filterProps: {
          width: '100%',
          changeOnBlur: true,
        },
        columnLayout: { titleWidth: '150px' },
        column: 1,
        Component: NumberInputHTML5,
        renderIf: propEqLegacy(
          searchClientInvoicesFilters.invoiceBy,
          filterClientInvoiceByOptions.range,
        ),
      },
      {
        name: searchClientInvoicesFilters.status,
        title: 'Invoice Status',
        filterProps: {
          width: '100%',
        },
        columnLayout: { titleWidth: '100px', filterWidth: '325px' },
        column: 2,
        Component: InvoiceStatusSelector,
        defaultValue: ALL,
      },
      {
        name: searchClientInvoicesFilters.invoiceDate,
        title: 'Date',
        defaultValue: {},
        filterProps: {
          width: '135px',
        },
        nestedFields: ['startDate', 'endDate'],
        columnLayout: { titleWidth: '100px', filterWidth: '325px' },
        column: 2,
        Component: DatePicker,
      },
      {
        name: searchClientInvoicesFilters.invoiceNumberTo,
        title: 'To Invoice #',
        filterProps: {
          width: '100%',
          changeOnBlur: true,
        },
        columnLayout: { titleWidth: '100px', filterWidth: '325px' },
        column: 2,
        Component: NumberInputHTML5,
        renderIf: propEqLegacy(
          searchClientInvoicesFilters.invoiceBy,
          filterClientInvoiceByOptions.range,
        ),
      },
      {
        name: searchClientInvoicesFilters.isPrinted,
        title: 'Printing Status',
        filterProps: {
          width: '100%',
        },
        columnLayout: { titleWidth: '155px' },
        column: 3,
        Component: PrintingStatusSelector,
      },
      {
        name: searchClientInvoicesFilters.projectId,
        title: 'Project #',
        filterProps: {
          width: '100%',
        },
        columnLayout: { titleWidth: '155px' },
        column: 3,
        Component: ProjectSelect,
      },
      {
        name: searchClientInvoicesFilters.consolidatedClientInvoiceId,
        title: 'Consolidated Invoice #',
        filterProps: {
          width: '100%',
        },
        columnLayout: { titleWidth: '155px' },
        column: 3,
        Component: ConsolidatedInvoiceSelect,
      },
    ],
  },
];

const ButtonsContainer = styled(FlexContainer)`
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
`;

export function PrintClientInvoicesPage() {
  const [searchFilters, setSearchFilters] = useState(null);
  const [selectedClientInvoices, setSelectedClientInvoices] = useState([]);
  const onSubmit = usePrintInvoicesMutation(setSelectedClientInvoices);

  const { clientInvoices, loading, tableProps, refetch } =
    useSearchClientInvoicesQuery(searchFilters);

  const onReset = () => {
    setSearchFilters(null);
    setSelectedClientInvoices([]);
  };

  useRedirectIfHasNoPermissions([PRINT_CLIENT_INVOICE_PERMISSION]);

  return (
    <PageWithSearchHeader headerHeight="225px">
      <SearchPageHeaderContainer title="Print Client Invoice">
        <SearchHeaderCreator
          config={headerConfig}
          onSearch={setSearchFilters}
          onReset={onReset}
          loading={loading}
        />
      </SearchPageHeaderContainer>
      <SearchPageContentWrapper>
        {loading ? (
          <Loader />
        ) : (
          <PrintClientInvoicesTable
            tableProps={tableProps}
            refetch={refetch}
            selectedClientInvoices={selectedClientInvoices}
            setSelectedClientInvoices={setSelectedClientInvoices}
            clientInvoices={clientInvoices}
          />
        )}
      </SearchPageContentWrapper>
      <BottomPanelContainer height="70px">
        <BottomPanelBody>
          <ButtonsContainer>
            <Button
              size="small"
              onClick={() => onSubmit(selectedClientInvoices)}
              disabled={selectedClientInvoices.length === 0}
            >
              {PRINT_PDF_CAPTION}
            </Button>
          </ButtonsContainer>
        </BottomPanelBody>
      </BottomPanelContainer>
    </PageWithSearchHeader>
  );
}
