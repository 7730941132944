import * as R from 'ramda';

// ifNotEmpty :: Function -> DataObject -> Function
export const ifNotEmpty = (action) =>
  R.ifElse(R.isEmpty, R.always(null), action);

// ifNotNil :: Function -> DataObject -> Function
export const ifNotNil = (action) => R.ifElse(R.isNil, R.always(null), action);

// ifNotNilOrEmpty :: Function -> DataObject -> Function
const ifNotNilOrEmpty = (action) =>
  R.ifElse(R.either(R.isNil, R.isEmpty), R.always(null), action);

/**
 * getQueryFromCheckboxes :: FieldPathString -> FuncMapper -> DataObject -> QueryObject
 */
export const getQueryFromCheckboxes = (path, mapper) =>
  R.compose(
    ifNotEmpty(
      R.pipe(
        R.keys,
        R.map(mapper),
        R.flatten,
        R.assocPath(['terms', path], R.__, {}),
      ),
    ),
    R.filter(R.identity),
  );

/**
 * getEntityQuery :: FieldName -> QueryPathString -> DataObject -> QueryObject
 */
export const getEntityQuery = R.curry((name, path, data) =>
  R.pipe(R.prop(name), ifNotNil(R.pipe(R.objOf(path), R.objOf('term'))))(data),
);

/**
 * getFuzzinessQuery :: FieldName -> QueryPathString -> DataObject -> QueryObject
 */
export const getFuzzinessQuery = R.curry((name, path, data) =>
  R.pipe(
    R.prop(name),
    ifNotNilOrEmpty(
      R.pipe(
        R.objOf('query'),
        R.mergeRight({ fuzziness: 'AUTO', operator: 'and' }),
        R.objOf(path),
        R.objOf('match'),
      ),
    ),
  )(data),
);

/**
 * getWildCardQuery :: FieldName -> QueryPathString -> DataObject -> QueryObject
 */
export const getWildCardQuery = R.curry((path, data) =>
  ifNotNil(
    R.pipe(
      (value) => `*${value}*`,
      R.objOf('value'),
      R.objOf(path),
      R.objOf('wildcard'),
    ),
  )(data),
);
