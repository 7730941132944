import React from 'react';
import styled from 'styled-components';
import { AccountingPeriodTypes } from '@poly/constants/src/accounting-period.js';
import { MainHeader } from '@poly/admin-book/src/Header/index.js';
import { Loader } from '@poly/admin-book/src/Loader/index.js';
import { hideScrollBarCss } from '@poly/admin-book/src/Table/styles.js';
import { LinkButton } from '@poly/admin-book/src/LinkButton/index.js';

import { useAccountingPeriods } from '../ReopenAccountingPeriod/useAccountingPeriods.js';
import { DefaultPageLayout } from '../DefaultPageLayout.js';
import { LockAccountingMonthTable } from './LockAccountingMonthTable.js';
import { useLockUnlockMonthlyPeriods } from './useLockUnlockMonthlyPeriods.js';
import { LockPreviousAccountingMonth } from './LockPreviousAccountingMonth.js';
import { useOpenAccountingPeriodsUpdatesSidebar } from './sidebar/useOpenAccountingPeriodsUpdatesSidebar.js';

const Wrapper = styled.div`
  max-width: 1484px;
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  overflow: auto;
  scrollbar-width: none;
  ${hideScrollBarCss};
`;

export function LockPreviousMonthPage() {
  const { loading, closedAccountingPeriods, openedAccountingPeriods } =
    useAccountingPeriods(AccountingPeriodTypes.MONTH);

  const { lockMonth, isUpdating, unlockMonth } = useLockUnlockMonthlyPeriods();

  const openUpdatesSidebar = useOpenAccountingPeriodsUpdatesSidebar();

  return (
    <DefaultPageLayout
      headerContent={
        <>
          <MainHeader>Lock Previous Month</MainHeader>
          <LinkButton onClick={openUpdatesSidebar}>Show logs</LinkButton>
        </>
      }
    >
      {loading ? (
        <Loader />
      ) : (
        <Wrapper>
          <LockPreviousAccountingMonth
            accountingPeriods={[
              ...closedAccountingPeriods,
              ...openedAccountingPeriods,
            ]}
          />
          <LockAccountingMonthTable
            accountingPeriods={closedAccountingPeriods}
            title="List of Locked Months"
            buttonTitle="Open"
            onSubmit={unlockMonth}
            loading={loading || isUpdating}
            key="closed-month"
          />

          <LockAccountingMonthTable
            accountingPeriods={openedAccountingPeriods}
            title="List of Open Months"
            buttonTitle="Lock"
            onSubmit={lockMonth}
            loading={loading || isUpdating}
            key="opened-month"
          />
        </Wrapper>
      )}
    </DefaultPageLayout>
  );
}
