import React from 'react';
import styled from 'styled-components';
import { getThemeColor, moneyColumnStyles, Table } from '@poly/admin-book';

const RedText = styled.span`
  color: rgb(255, 99, 99);
  font-weight: bold;
`;

export const BatchInvoiceReportsDetailsTableWrapper = styled.div`
  padding: 0 75px;
`;

export const getVoidColumn = () => <RedText>Void</RedText>;

export const BatchInvoiceReportsDetailsTableS = styled(Table)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 100px;
  }
  th:nth-child(2),
  td:nth-child(2) {
    width: 100px;
  }
  th:nth-child(3),
  td:nth-child(3) {
    width: 400px;
  }
  th:nth-child(4),
  td:nth-child(4) {
    width: 200px;
  }
  th:nth-child(5),
  td:nth-child(5) {
    width: 100px;
  }
  height: 80vh;
  ${moneyColumnStyles(6)};

  @media print {
    height: auto;
    tbody > tr {
      padding-right: 10px;
      border: none;
      border-bottom: 1px solid ${getThemeColor(['secondary'])};
    }
  }
`;

export const BatchInvoiceReportsDetailsFooterContainer = styled.tr`
  padding: 20px 0;
  font-weight: bold;
  font-size: 15px;
  margin: 0;
  td:nth-child(1) {
    padding-left: 10px;
    width: 600px;
  }
  ${moneyColumnStyles(2)};
  td:nth-child(2) {
    padding-right: 20px;
  }
`;
