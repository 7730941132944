import * as R from 'ramda';
import { ClientStatuses } from '@poly/constants';
import { propEqLegacy } from '@poly/utils';

import { getSectionText } from '../components/commonSidebarSectionFormatters.js';

// getToggleSettingSectionText :: Boolean -> String
export const getToggleSettingSectionText = (isEnabled) =>
  getSectionText(isEnabled ? 'On' : 'Off');

// isAllowProjectCreationOnAdminByClient :: Client -> Boolean
export const isAllowProjectCreationOnAdminByClient = R.path([
  'configs',
  'adminApp',
  'allowProjectCreation',
]);

// isClientInactive :: Client -> Boolean
export const isClientInactive = propEqLegacy('status', ClientStatuses.INACTIVE);
