import * as R from 'ramda';
import React from 'react';
import { Text } from '@poly/admin-book';
import { shape } from 'prop-types';
import { formatPhoneNumber } from '@poly/utils';
import { NOTHING_UI_STRING } from '@poly/constants';

import {
  PrintProjectFlexRow,
  PrintProjectArrayText,
  PrintProjectFlexColumn,
  PrintProjectBlockWithLabel,
} from '../components.js';

// getAssignedCSRNameUI :: { assignedCSR: User } -> String
const getAssignedCSRNameUI = R.pathOr(NOTHING_UI_STRING, [
  'manager',
  'fullName',
]);

// getClientManagerUI :: { clientManager: User } -> String
const getClientManagerUI = R.pathOr(NOTHING_UI_STRING, [
  'clientManager',
  'fullName',
]);

// getSiteContactInfoUI :: { property: Property, siteContact: User } -> String
const getSiteContactInfoUI = R.compose(
  R.when(R.isEmpty, R.always([NOTHING_UI_STRING])),
  R.reject(R.isNil),
  R.juxt([
    R.compose(
      R.when(
        R.is(String),
        R.compose(
          R.concat(R.__, ' - Branch'),
          formatPhoneNumber('-'),
          R.objOf('number'),
        ),
      ),
      R.path(['property', 'phone']),
    ),
    R.path(['siteContact', 'fullName']),
  ]),
);

// getRequesterUI :: { requester: User } -> String
const getRequesterUI = R.pathOr(NOTHING_UI_STRING, ['contact', 'fullName']);

export function PrintProjectContactInformation({ project }) {
  return (
    <PrintProjectFlexColumn withBottomBorder>
      <PrintProjectFlexRow>
        <Text size="16px" weight="bold">
          Contact Information
        </Text>
      </PrintProjectFlexRow>
      <PrintProjectFlexRow>
        <PrintProjectBlockWithLabel
          label="Assigned CSR"
          value={getAssignedCSRNameUI(project)}
        />
        <PrintProjectBlockWithLabel
          label="Client Manager"
          value={getClientManagerUI(project)}
        />
      </PrintProjectFlexRow>
      <PrintProjectFlexRow>
        <PrintProjectBlockWithLabel
          label="Site Contact"
          Component={PrintProjectArrayText}
          arrayValue={getSiteContactInfoUI(project)}
          arrayKeyPart={project?.projectId}
        />
        <PrintProjectBlockWithLabel
          label="Requester"
          value={getRequesterUI(project)}
        />
      </PrintProjectFlexRow>
    </PrintProjectFlexColumn>
  );
}

PrintProjectContactInformation.propTypes = {
  project: shape({}),
};
