import React from 'react';
import { string, number, bool, shape } from 'prop-types';
import { ProjectType } from '@poly/constants';
import {
  SidebarTabTableWrapper,
  useSearchTabLogic,
  SearchInputComp,
  SidebarRow,
  entities,
} from '@poly/admin-ui';

import {
  SectionLabel,
  ToolbarButton,
  DoubleTabsWrapper,
} from '../../components/commonSidebarComponents.js';
import { CommonProjectsSubTabs } from '../../components/commonTabs/CommonProjectsSubTabs.js';
import { useAddProjectSidebar } from '../../ProjectSidebar/forms/add/useAddProjectSidebar.js';
import {
  isAllowProjectCreationOnAdminByClient,
  isClientInactive,
} from '../helpers.js';

function AddClientProjectButton({ client, isCard }) {
  const openAddProjectSidebar = useAddProjectSidebar(
    isCard,
    entities.CLIENT,
    client._id,
  );

  if (!isAllowProjectCreationOnAdminByClient(client)) {
    return null;
  }

  const onAddProject = () =>
    openAddProjectSidebar({
      clientId: client._id,
      projectType:
        client?.configs?.defaultProjectType || ProjectType.WORK_ORDER,
    });

  return (
    <ToolbarButton disabled={isClientInactive(client)} onClick={onAddProject}>
      Add Project
    </ToolbarButton>
  );
}

AddClientProjectButton.propTypes = {
  isCard: bool,
  client: shape({
    _id: string.isRequired,
    configs: shape({ defaultProjectType: string }),
  }).isRequired,
};

export function ClientSidebarProjectsTab({ isCard, doubleTabWidth, client }) {
  const { searchTerm, ...inputProps } = useSearchTabLogic('projects');

  const subTabsProps = {
    searchTerm,
    entity: { _id: client._id, name: entities.CLIENT },
    toolBar: <AddClientProjectButton client={client} isCard={isCard} />,
  };

  return (
    <DoubleTabsWrapper width={doubleTabWidth} isCard={isCard}>
      <SidebarRow justify align>
        <SectionLabel>Client Projects</SectionLabel>
        <SearchInputComp {...inputProps} />
      </SidebarRow>
      <SidebarTabTableWrapper offset={48}>
        <CommonProjectsSubTabs {...subTabsProps} />
      </SidebarTabTableWrapper>
    </DoubleTabsWrapper>
  );
}

ClientSidebarProjectsTab.propTypes = {
  isCard: bool,
  client: shape({ _id: string.isRequired }).isRequired,
  doubleTabWidth: number.isRequired,
};
