import styled from 'styled-components';
import { Table } from '@poly/admin-book';
import {
  dateColumnStyles,
  daysColumnStyles,
  descriptionColumnStyles,
  pipColumnStyles,
} from '../tablesStyles.js';

export const MyProjectsTableComp = styled(Table)`
  ${pipColumnStyles(1)};

  th:nth-child(2),
  td:nth-child(2) {
    width: 80px;
  }

  ${dateColumnStyles(3)};
  ${descriptionColumnStyles(4)};
  ${dateColumnStyles(7)};
  ${dateColumnStyles(8)};
  ${daysColumnStyles(9)};

  th:nth-child(6),
  td:nth-child(6) {
    width: 15%;
  }

  th:last-child,
  td:last-child {
    width: 6%;
  }
`;

export const MyRecurringProjectsTableComp = styled(Table)`
  ${pipColumnStyles(1)};
  ${dateColumnStyles(2)};
  th:nth-child(4),
  td:nth-child(4) {
    width: 25%;
  }
  th:nth-child(3),
  td:nth-child(3) {
    width: 40%;
    overflow-wrap: break-word;
  }
  ${dateColumnStyles(5)};
  ${dateColumnStyles(6)};
`;
