import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { func, string } from 'prop-types';
import { FormCreator } from '@poly/admin-book/src/Form/FormCreator.js';
import {
  commonSidebarFormFieldLayout,
  commonSidebarFormLayout,
  commonSidebarFormSectionLayout,
} from '@poly/admin-ui/src/sidebar/commonSidebarFormConstants.js';
import { TaskPriorities } from '@poly/constants/src/tasks.js';
import { useNotificationContext } from '@poly/admin-book/src/Notificator/NotificatorProvider.js';
import { usePristineSubscribe } from '@poly/client-routing/src/hooks.js';

import { createTaskTemplateFormId } from './constants.js';
import { taskTemplateFromSections } from './taskTemplateFromSections.js';
import {
  getTaskTemplateFormInitialValues,
  useTaskTemplateDetails,
} from '../useTaskTemplateDetails.js';

export const createTaskTemplateMutation = gql`
  mutation createTaskTemplate($input: TaskTemplateInput!) {
    createTaskTemplate(input: $input) {
      _id
    }
  }
`;

export const CREATE_TASK_TEMPLATE_SUCCESS_MSG =
  'Task template was successfully created';

export function CreateTaskTemplateFrom({ onClose, duplicateTaskTemplateId }) {
  const [createTaskTemplate] = useMutation(createTaskTemplateMutation);
  const { showSuccessNotification } = useNotificationContext();

  const { data, loading } = useTaskTemplateDetails(duplicateTaskTemplateId);

  const pristineSubscribeProps = usePristineSubscribe({
    id: createTaskTemplateFormId,
  });

  const handleSubmit = async (formData) => {
    await createTaskTemplate({ variables: { input: formData } });
    showSuccessNotification(CREATE_TASK_TEMPLATE_SUCCESS_MSG);
    onClose();
  };

  if (loading) {
    return null;
  }

  const initialValues = duplicateTaskTemplateId
    ? getTaskTemplateFormInitialValues(data)
    : {
        priority: TaskPriorities.NORMAL,
        name: '',
        description: '',
      };

  return (
    <FormCreator
      id={createTaskTemplateFormId}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      sections={taskTemplateFromSections}
      layout={commonSidebarFormLayout}
      sectionLayout={commonSidebarFormSectionLayout}
      fieldLayout={commonSidebarFormFieldLayout}
      {...pristineSubscribeProps}
    />
  );
}

CreateTaskTemplateFrom.propTypes = {
  onClose: func.isRequired,
  duplicateTaskTemplateId: string,
};
