import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components';
import { useLocation } from '@poly/client-routing';
import { node } from 'prop-types';
import { getThemeColor } from '@poly/admin-book';

import { MainLayout } from '../MainLayout.js';
import { OpenSidebarBtn } from './OpenSidebarBtn.js';
import { SidebarCloseIcon } from '../../components/SidebarLayouts.js';
import { routesNames } from '../../routes/index.js';
import { FinancialDirectorySidebarTabs } from './FinancialDirectorySidebarTabs.js';

const FinancialDirectoryPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  padding-left: 70px;
`;

const FinancialDirectorySidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  width: ${({ isOpened }) => (isOpened ? '285px' : '70px')};
  background: ${getThemeColor(['lighter'])};
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    background: transparent;
    display: none;
  }
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding-top: 27px;
  transition: width 0.1s;
`;

const SidebarCloseBtn = styled(SidebarCloseIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export function FinancialDirectoryPageLayout({ children }) {
  const [isOpened, setIsOpened] = useState(true);
  const location = useLocation();

  const isFinancialDirectory =
    location.pathname === routesNames.FINANCIAL_DIRECTORY;

  useEffect(() => {
    setIsOpened(isFinancialDirectory);
  }, [location.pathname]);

  return (
    <MainLayout>
      <FinancialDirectoryPageContainer>
        <OutsideClickHandler
          onOutsideClick={() => setIsOpened(isFinancialDirectory)}
        >
          <FinancialDirectorySidebar isOpened={isOpened}>
            {isOpened ? (
              <FinancialDirectorySidebarTabs />
            ) : (
              <OpenSidebarBtn onClick={() => setIsOpened(true)} />
            )}
            {isOpened && !isFinancialDirectory && (
              <SidebarCloseBtn onClick={() => setIsOpened(false)} />
            )}
          </FinancialDirectorySidebar>
        </OutsideClickHandler>
        {children}
      </FinancialDirectoryPageContainer>
    </MainLayout>
  );
}

FinancialDirectoryPageLayout.propTypes = {
  children: node.isRequired,
};
