import * as R from 'ramda';
import React, { useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { string, bool, shape, func } from 'prop-types';
import { usePristineSubscribe } from '@poly/client-routing';
import { peopleStatuses } from '@poly/constants';
import { FormCreator } from '@poly/admin-book';

import {
  peopleFormSections,
  internationalPeopleFormSections,
} from '../form/sections.js';
import { commonModalLayout } from '../../common.js';
import { ContactContext } from '../form/ContactContext.js';
import { CREATE_CONTACT_USER_MUTATION } from '../../../../hocs/users/mutations.js';
import { useOnSubmitSetStopSubmitting } from '../../../../hooks/useOnSubmitSetStopSubmitting.js';
import { useNotificationState } from '../../../../hooks/useNotificationState.js';
import { preparedInternationalPhones } from './addPeopleUtils.js';
import {
  commonSidebarFormSectionLayout,
  commonSidebarFormFieldLayout,
  commonSidebarFormLayout,
} from '../../../../sidebar/commonSidebarFormConstants.js';
import {
  peopleCreateFormToInput,
  validateFormData,
} from '../form/peopleFormUtils.js';

const { ACTIVE } = peopleStatuses;

// getEntityProps :: Entity -> Entity
const getEntityProps = R.pick(['_id', 'name']);

const useOnSubmitContactForm = (formId, entity, onCancel, onSuccess) => {
  const { showSuccessNotification } = useNotificationState();

  const [createContactMutation] = useMutation(CREATE_CONTACT_USER_MUTATION);

  return async (formData) => {
    const preparedInput = peopleCreateFormToInput({
      ...getEntityProps(entity),
      ...preparedInternationalPhones(formData),
    });

    const mutationResult = await createContactMutation({
      variables: preparedInput,
    });

    showSuccessNotification('Contact was created successfully');

    if (onSuccess) {
      const newContactId = R.path(['data', 'createContactUser', 'user', '_id'])(
        mutationResult,
      );
      onSuccess(newContactId);
    } else {
      onCancel(formId);
    }
  };
};

export function AddPeopleForm({
  entity,
  formId,
  onCancel,
  onSuccess,
  isInternational = false,
  isClientContact = false,
  isCardLayout = true,
}) {
  const pristineSubscribeProps = usePristineSubscribe({
    entity,
    id: formId,
    isInternational,
    isClientContact,
  });

  const onSubmitHandler = useOnSubmitContactForm(
    formId,
    entity,
    onCancel,
    onSuccess,
  );
  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  const sections = useMemo(
    () =>
      isInternational
        ? internationalPeopleFormSections
        : peopleFormSections(isClientContact),
    [isClientContact, isInternational],
  );

  const contextValue = useMemo(
    () => ({
      formId,
      onCancel,
    }),
    [formId, onCancel],
  );

  const initialValues = {
    entity,
    isCreateMode: true,
    status: ACTIVE,
  };

  const layouts = isCardLayout
    ? { layout: commonModalLayout }
    : {
        layout: commonSidebarFormLayout,
        fieldLayout: commonSidebarFormFieldLayout,
        sectionLayout: commonSidebarFormSectionLayout,
      };

  return (
    <ContactContext.Provider value={contextValue}>
      <FormCreator
        id={formId}
        validate={validateFormData}
        initialValues={initialValues}
        sections={sections}
        onSubmit={onSubmit}
        onCancel={onCancel}
        {...layouts}
        {...pristineSubscribeProps}
      />
    </ContactContext.Provider>
  );
}

AddPeopleForm.displayName = 'AddPeopleForm';

AddPeopleForm.propTypes = {
  entity: shape({
    _id: string.isRequired,
    name: string.isRequired,
  }),
  formId: string.isRequired,
  onCancel: func.isRequired,
  isInternational: bool,
  isClientContact: bool,
  isCardLayout: bool,
  onSuccess: func,
};
