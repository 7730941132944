import React from 'react';
import { Editor } from '@poly/admin-book';
import styled from 'styled-components';

const EditorS = styled(Editor)`
  .ql-editor {
    max-height: none;
  }
`;

export function PropertyRemarksInput(props) {
  return (
    <EditorS
      {...props}
      id="property-remarks-input"
      placeholder="Enter Remarks"
    />
  );
}

PropertyRemarksInput.displayName = 'PropertyRemarksInput';
