import { gql } from '@apollo/client';

import {
  propertySupplierFields,
  propertyFieldsForFilters,
} from './fragments.js';

export const PROPERTY_FOR_ADD_SUPPLIERS = gql`
  query PROPERTY_FOR_ADD_SUPPLIERS($id: ID!) {
    property(id: $id) {
      _id
      suppliers {
        ...propertySupplierFields
      }
    }
  }
  ${propertySupplierFields}
`;

export const PROPERTIES_BY_SEARCH = gql`
  query PROPERTIES_BY_SEARCH($searchInput: CollectionSearchParams!) {
    searchProperties(input: $searchInput) {
      hits {
        client {
          _id
          enablePropertyLocationHierarchy
        }
        ...propertyFieldsForFilters
      }
      total
    }
  }

  ${propertyFieldsForFilters}
`;

export const DEFAULT_PROPERTY_QUERY = gql`
  query DEFAULT_PROPERTY_QUERY($id: ID!) {
    property(id: $id) {
      ...propertyFieldsForFilters
    }
  }

  ${propertyFieldsForFilters}
`;
