import React, { useCallback, useState } from 'react';
import { string } from 'prop-types';
import { debounce } from '@poly/utils';
import { useMutation, gql } from '@apollo/client';
import { PopoverLink } from '@poly/admin-book/src/index.js';
import { useNotificationState } from '@poly/admin-ui/src/hooks/useNotificationState.js';

export const SEND_PROJECT_SUPPLIER_VOID_WOC = gql`
  mutation SEND_PROJECT_SUPPLIER_VOID_WOC(
    $input: SendProjectSupplierVoidWOCInput!
  ) {
    sendProjectSupplierVoidWOC(input: $input) {
      _id
    }
  }
`;

export function SendVoidWOCPopoverLink({ projectId, supplierId }) {
  const [loading, setLoading] = useState(false);
  const [sendProjectSupplierVoidWOC] = useMutation(
    SEND_PROJECT_SUPPLIER_VOID_WOC,
  );
  const { showSuccessNotification } = useNotificationState();

  const setLoadingDebounced = useCallback(debounce(2000)(setLoading), []);

  const onClick = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    await sendProjectSupplierVoidWOC({
      variables: {
        input: { projectId, supplierId },
      },
    });

    setLoadingDebounced(false);

    showSuccessNotification('Supplier Void WOC was sent.');
  };

  return <PopoverLink onClick={onClick}>Send Void WOC</PopoverLink>;
}

SendVoidWOCPopoverLink.propTypes = {
  projectId: string,
  supplierId: string,
};
