import * as R from 'ramda';
import { propEqLegacy } from '@poly/utils';

// unlinkContact :: ([Link], Entity) -> [Link]
// Link = Object
// Entity = Object
export const unlinkContact = R.curry((links, entity) =>
  R.reject(propEqLegacy(`${entity.name}Id`, entity._id), links),
);

// isLikedToProperties :: ([Link], ID) -> Boolean
export const isLikedToProperties = (links, clientId) =>
  R.find(
    R.both(propEqLegacy('clientId', clientId), R.prop('propertyId')),
    links,
  );
