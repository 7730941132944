import { InvoicesStatuses } from './invoices.js';
import { AccountingStatus } from './projects/constants.js';

export const WIPInvoiceStatuses = [
  InvoicesStatuses.PENDING,
  InvoicesStatuses.PARTIALLY_PAID,
  InvoicesStatuses.PAID,
];

export const WIPProjectAccountingStatuses = [
  AccountingStatus.PENDING,
  AccountingStatus.READY_TO_INVOICE,
];
