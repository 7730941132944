import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { func, shape, string } from 'prop-types';
import { Checkbox, Text } from '@poly/admin-book';
import { SubmitBtn, CancelBtn, useProcessState } from '@poly/admin-ui';

import { FieldWithTooltip } from '../../sidebars/ClientSidebar/forms/form/sideBarClientFormSections.js';

const FormActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 15px;
  margin: 10px 0 -25px 0;
`;

// checkIsSubmitDisabled :: Object -> Boolean
const checkIsSubmitDisabled = R.compose(R.all(R.equals(false)), R.values);

function FormActionButtons({ formId, value, onCancel }) {
  const { process } = useProcessState(formId);
  const isDisabled = checkIsSubmitDisabled(value);

  return (
    <FormActionButtonsWrapper>
      <CancelBtn onClick={onCancel}>Cancel</CancelBtn>
      <SubmitBtn
        form={formId}
        loader={process}
        disabled={process || isDisabled}
      >
        Save
      </SubmitBtn>
    </FormActionButtonsWrapper>
  );
}

FormActionButtons.propTypes = {
  formId: string,
  value: shape({}),
  onCancel: func,
};

const commonAdditionalProps = {
  Component: Checkbox,
  wrapperProps: { margin: '5px 0' },
};

export const getProjectPrintToPDFSections = (formId, onCancel) => [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        layout: { row: 1 },
        order: 1,
        field: {
          name: 'subTitle',
          Component: Text,
          additionalProps: { children: 'What to Print' },
        },
      },
      {
        layout: { row: 2 },
        order: 2,
        field: {
          name: 'config.isGeneralDetails',
          Component: FieldWithTooltip,
          additionalProps: {
            ...commonAdditionalProps,
            label: 'General Details',
          },
        },
      },
      {
        layout: { row: 3 },
        order: 3,
        field: {
          name: 'config.isProjectDetails',
          Component: FieldWithTooltip,
          additionalProps: {
            ...commonAdditionalProps,
            label: 'Project Details',
          },
        },
      },
      {
        layout: { row: 4 },
        order: 4,
        field: {
          name: 'config.isContactInformation',
          Component: FieldWithTooltip,
          additionalProps: {
            ...commonAdditionalProps,
            label: 'Contact Information',
          },
        },
      },
      {
        layout: { row: 5 },
        order: 5,
        field: {
          name: 'config.isSupplierInfo',
          Component: FieldWithTooltip,
          additionalProps: {
            ...commonAdditionalProps,
            label: 'Supplier Info',
          },
        },
      },
      {
        layout: { row: 6 },
        order: 6,
        field: {
          name: 'config.isProjectTasks',
          Component: FieldWithTooltip,
          additionalProps: {
            ...commonAdditionalProps,
            label: 'Project Tasks',
          },
        },
      },
      {
        layout: { row: 7, margin: '0 0 0 25px' },
        order: 7,
        field: {
          name: 'config.isIncludeCompletedTaskComments',
          Component: FieldWithTooltip,
          additionalProps: {
            ...commonAdditionalProps,
            label: 'Include Comments of completed tasks',
          },
        },
      },
      {
        layout: { row: 8 },
        order: 8,
        field: {
          name: 'config.isFinancialInfo',
          Component: FieldWithTooltip,
          additionalProps: {
            ...commonAdditionalProps,
            label: 'Financial Info',
          },
        },
      },
      {
        layout: { row: 9, margin: '0 0 0 25px' },
        order: 9,
        field: {
          name: 'config.isIncludeSupplierInvoiceFiles',
          Component: FieldWithTooltip,
          additionalProps: {
            ...commonAdditionalProps,
            label: 'Include Supplier Invoice Files',
          },
        },
      },
      {
        layout: { row: 10, margin: '0 0 0 25px' },
        order: 10,
        field: {
          name: 'config.isIncludeClientInvoiceFiles',
          Component: FieldWithTooltip,
          additionalProps: {
            ...commonAdditionalProps,
            label: 'Include Client Invoice Files',
          },
        },
      },
      {
        layout: { row: 11, margin: '0 0 0 25px' },
        order: 11,
        field: {
          name: 'config.isIncludeClientEstimateFile',
          Component: FieldWithTooltip,
          additionalProps: {
            ...commonAdditionalProps,
            label: 'Include Client Estimate File',
          },
        },
      },
      {
        layout: { row: 12 },
        order: 12,
        field: {
          name: 'config.isAttachedAssets',
          Component: FieldWithTooltip,
          additionalProps: {
            ...commonAdditionalProps,
            label: 'Attached Assets',
          },
        },
      },
      {
        layout: { row: 13, margin: '0 0 0 25px' },
        order: 13,
        field: {
          name: 'config.isIncludeAssetDetailsInfo',
          Component: FieldWithTooltip,
          additionalProps: {
            ...commonAdditionalProps,
            label: 'Include Asset Details Info',
          },
        },
      },
      {
        layout: { row: 14 },
        order: 14,
        field: {
          name: 'config',
          Component: FormActionButtons,
          additionalProps: { formId, onCancel },
        },
      },
    ],
  },
];
