import * as R from 'ramda';
import { useState, useRef, useEffect } from 'react';

import { useUnsupportedWorkerMessage } from '../../hooks/useUnsupportedWorkerMessage.js';
import { useRegisterSharedWorker } from './useRegisterSharedWorker.js';

// adjustDashboardDataResult :: [{dashboard: DashboardResult}] -> {dashboard: DashboardResult}
const adjustDashboardDataResult = R.compose(
  R.unless(
    R.isEmpty,
    R.converge(
      R.assocPath(['dashboard', 'globalProjectsCounts', 'unassigned']),
      [R.pathOr(0, ['dashboard', 'globalProjectsUnassignedCount']), R.identity],
    ),
  ),
  R.reduce(R.mergeDeepRight, {}),
  R.reject(R.isNil),
);

export const useDashboardQueryWorker = (skip = false) => {
  const dashboardWorkerRef = useRef(null);
  const [workerSkip, setWorkerSkip] = useState(true);
  const [dashboardData, setDashboardData] = useState({ loading: !skip });
  const showUnsupportedMessage = useUnsupportedWorkerMessage();

  useEffect(() => {
    try {
      dashboardWorkerRef.current = new SharedWorker(
        new URL('./dashBoardSharedWorker.js', import.meta.url),
        {
          name: 'admin-dashboard',
        },
      );
      setWorkerSkip(false);
    } catch (e) {
      showUnsupportedMessage();
    }
  }, []);

  useRegisterSharedWorker({
    skip: workerSkip || skip,
    setData: setDashboardData,
    workerInstance: dashboardWorkerRef.current,
  });

  const { projectsData, masterProjectsData, tasksData, invoicesData, loading } =
    dashboardData;

  return {
    data: adjustDashboardDataResult([
      projectsData,
      masterProjectsData,
      tasksData,
      invoicesData,
    ]),
    loading,
  };
};
