import * as R from 'ramda';
import { string } from 'prop-types';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Select } from '@poly/admin-book';

import { GET_ACCOUNTS_QUERY } from '../../components/AccountsSelect.js';

// getAccountLabel :: (String, String) -> String
const getAccountLabel = R.converge(
  (name, code) => `${name}(${code})`,
  [R.prop('name'), R.prop('code')],
);

export function DiscountSelect({ value, ...props }) {
  const [searchText, setSearchText] = useState('');

  const { data, loading } = useQuery(GET_ACCOUNTS_QUERY, {
    variables: {
      filters: {
        searchText,
        subAccount: true,
        isClientPaymentDeduction: true,
        from: 0,
        // minimize size to prevent performance issues
        size: 5,
      },
    },
  });

  const options = R.compose(
    R.map(R.applySpec({ value: R.prop('_id'), label: getAccountLabel })),
    R.pathOr([], ['getAccounts', 'hits']),
  )(data);

  const selectProps = {
    value,
    loading,
    searchText,
    name: 'async-account-select',
    options: [...options],
    onInputChange: setSearchText,
    ...props,
  };

  return <Select {...selectProps} />;
}

DiscountSelect.propTypes = {
  value: string,
};
