import React from 'react';
import styled from 'styled-components';
import { string, func } from 'prop-types';
import { useUpdateQueryParams } from '@poly/client-routing';
import { useOutSidebarContext } from '@poly/client-utils';
import { BaseSidebarLayout } from '@poly/admin-ui';
import { pathEqLegacy } from '@poly/utils';

import { SidebarFormLayout } from '../SidebarFormLayout.js';
import { projectSidebarTabs } from '../../../routes/constants.js';
import { ProcedureBulkAttachFrom } from './ProcedureBulkAttachFrom.js';

const procedureBulkAttachFormId = 'procedureBulkAttachFormId';

const ProcedureBulkAttachSidebarLayout = styled(BaseSidebarLayout)`
  background-color: #f9f9f9;
`;

const ProcedureBulkAttachSidebarFormLayout = styled(SidebarFormLayout)`
  justify-content: flex-start;

  > div:last-child {
    background-color: #f9f9f9;
  }
`;

function ProcedureBulkAttachSidebarFrom({ formId, onCancel, ...props }) {
  const updateQueryParams = useUpdateQueryParams();

  const isProjectEntity = pathEqLegacy(['entity', 'name'], 'project')(props);

  const onClose = () => {
    if (isProjectEntity) {
      updateQueryParams({ sidebarTab: projectSidebarTabs.projectAssets });
    }
  };

  const afterFormSubmit = () => {
    onCancel();
    onClose();
  };

  return (
    <ProcedureBulkAttachSidebarFormLayout
      formId={formId}
      onClose={onClose}
      submitButtonCaption="Save"
      title="Attach Asset in Bulk"
    >
      <ProcedureBulkAttachFrom
        {...props}
        formId={formId}
        onCancel={afterFormSubmit}
      />
    </ProcedureBulkAttachSidebarFormLayout>
  );
}

ProcedureBulkAttachSidebarFrom.propTypes = {
  formId: string.isRequired,
  onCancel: func.isRequired,
};

export const useOpenProcedureBulkAttachSidebar = () => {
  const { openOutSidebar, closeOutSidebar } = useOutSidebarContext();

  const onCancel = () => closeOutSidebar(procedureBulkAttachFormId);

  return ({ entity, assetProcedures }) =>
    openOutSidebar({
      alwaysFirst: true,
      id: procedureBulkAttachFormId,
      Layout: ProcedureBulkAttachSidebarLayout,
      width: 700,
      content: (
        <ProcedureBulkAttachSidebarFrom
          entity={entity}
          onCancel={onCancel}
          assetProcedures={assetProcedures}
          formId={procedureBulkAttachFormId}
        />
      ),
    });
};
