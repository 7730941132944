import * as R from 'ramda';
import { string } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import React, { useMemo, useEffect, useState } from 'react';
import { useMapConfigToTableProps, ALL, useTableSorting } from '@poly/admin-ui';
import { useNotificationContext } from '@poly/admin-book';
import { DESC_SORT_ORDER } from '@poly/constants';
import {
  useInfiniteScrollQueryWithSubscription,
  keywordSortQuery,
} from '@poly/client-utils';

import {
  projectIdColumn,
  projectExpiresColumn,
  projectPriorityColumn,
} from '../../modules/tables/columns/projects.js';
import { processes } from '../../redux/processes/processesReducer.js';
import { clientColumn } from '../../modules/tables/columns/clients.js';
import { PROJECTS_BY_SEARCH } from '../../modules/core/hooks/projects/queries.js';
import { PROJECTS_BY_SEARCH_SUB } from '../../modules/core/hooks/projects/subscriptions.js';
import { useSortingByReduxSearch } from '../../modules/core/hooks/useSortingByReduxSearch.js';
import { UnassignedProjectsTableComp } from './UnassignedProjectsComponents.js';
import { setSelectedRows } from '../../redux/selectedRowsReducer.js';
import { setProcesses } from '../../redux/processes/index.js';

const tableConfig = [
  projectPriorityColumn,
  projectExpiresColumn,
  projectIdColumn,
  ['Description', R.prop('description'), keywordSortQuery(['description'])],
  clientColumn,
  [
    'Property',
    R.path(['property', 'name']),
    keywordSortQuery(['property', 'name']),
  ],
];

export function UnassignedProjectsTable({ clientId }) {
  const dispatch = useDispatch();
  const [allSelected, setAllSelected] = useState(false);
  const { showSuccessNotification } = useNotificationContext();
  const selectedRows = useSelector((state) => state.selectedRows);

  const dispatchSelectedRows = (rows) => dispatch(setSelectedRows(rows));
  const dispatchProcess = (value) =>
    dispatch(setProcesses({ [processes.UNASSIGNED_PROJECTS_LOADING]: value }));

  const { sort: tableSort, ...tableSortingProps } = useTableSorting({
    order: DESC_SORT_ORDER,
    tableConfig,
    column: 1,
  });

  const { sort } = useSortingByReduxSearch({ sort: tableSort });

  const searchInput = useMemo(
    () => ({
      sort,
      query: {
        bool: {
          ...(clientId && clientId !== ALL
            ? { must: { match: { clientId } } }
            : {}),
          must_not: { exists: { field: 'manager' } },
        },
      },
    }),
    [clientId, sort],
  );

  const { data, refetch, tableProps } = useInfiniteScrollQueryWithSubscription(
    PROJECTS_BY_SEARCH,
    searchInput,
    {
      pageSize: 25,
      inputName: 'searchInput',
      endpointName: 'searchProjects',
    },
    PROJECTS_BY_SEARCH_SUB,
    { searchInput },
  );

  const projects = useMemo(
    () => R.pathOr([], ['searchProjects', 'hits'])(data),
    [data],
  );

  const total = useMemo(
    () => R.pathOr(0, ['searchProjects', 'total'])(data),
    [data],
  );

  useEffect(() => {
    if (allSelected && projects.length === total) {
      const selectedIds = projects.map((x) => x._id);

      dispatchSelectedRows(selectedIds);

      if (selectedIds.length > 0) {
        showSuccessNotification(
          `All ${selectedIds.length} Projects In Queue Selected`,
        );
      }

      dispatchProcess(false);

      setAllSelected(false);
    }
  }, [projects, allSelected]);

  const dataTableProps = useMapConfigToTableProps(
    R.identity,
    tableConfig,
    projects,
  );

  const toggleRow = (rowId) => {
    if (selectedRows.includes(rowId)) {
      dispatchSelectedRows(selectedRows.filter((x) => x !== rowId));
    } else {
      dispatchSelectedRows([...selectedRows, rowId]);
    }
  };

  const toggleSelectAll = () => {
    if (selectedRows.length === total) {
      dispatchSelectedRows([]);
    } else {
      dispatchProcess(true);
      refetch({ searchInput: { size: total } });
      setAllSelected(true);
    }
  };

  const fullTableProps = {
    toggleRow,
    selectedRows,
    toggleSelectAll,
    selectedRowsInEnd: true,
    ...dataTableProps,
    ...tableProps,
    ...tableSortingProps,
  };

  return <UnassignedProjectsTableComp {...fullTableProps} />;
}

UnassignedProjectsTable.displayName = 'UnassignedProjectsTable';
UnassignedProjectsTable.propTypes = { clientId: string };
