import {
  bool,
  node,
  func,
  shape,
  object,
  string,
  number,
  oneOfType,
  instanceOf,
} from 'prop-types';
import * as R from 'ramda';
import React, { useState } from 'react';
import DayPicker from 'react-day-picker';
import { addMonths, addWeeks } from 'date-fns';
import OutsideClickHandler from 'react-outside-click-handler';
import { ensureIsDate, alwaysNewDate, isValidDate } from '@poly/utils';

import {
  NextBtn,
  BackBtn,
  Container,
  CalendarIcon,
  MultiNextBtn,
  MultiBackBtn,
  InputWithIconS,
  NavbarContainer,
  CurrentMonthText,
  MultiNavBarBlock,
  DatePickerFooter,
  DatePickerAligner,
  MultiNavBarWrapper,
  DatePickerContainer,
  DatePickerFooterBtn,
  DayWithButtonsWrapper,
} from './styles.js';
import { LabelS } from '../InputLabel/index.js';
import { ModalOverlay } from '../Modal/Modal.js';
import { ColumnDirection } from '../lib/index.js';
import { DatePickerButtons } from './constants.js';
import {
  useMultipleDayPickerLogic,
  useDatePickerLogic,
  valuePropTypes,
} from './hooks.js';
import {
  getDefaultNavBarText,
  modifyStringToDate,
  modifyDateToString,
  modifyInputValue,
  isValueEmpty,
  getModifiers,
} from './utils.js';

// perhaps the multi arrows will be used after review
function Navbar({ month, changeMonth, getNavBarText }) {
  return (
    <NavbarContainer>
      <MultiBackBtn onClick={changeMonth(-12)} />
      <DayWithButtonsWrapper>
        <BackBtn onClick={changeMonth(-1)} />
        <CurrentMonthText>{getNavBarText(month)}</CurrentMonthText>
        <NextBtn onClick={changeMonth(1)} />
      </DayWithButtonsWrapper>
      <MultiNextBtn onClick={changeMonth(12)} />
    </NavbarContainer>
  );
}

Navbar.propTypes = {
  month: instanceOf(Date).isRequired,
  getNavBarText: func.isRequired,
  changeMonth: func.isRequired,
};

function MultiNavBar({ changeMonth }) {
  return (
    <MultiNavBarWrapper>
      <MultiNavBarBlock>
        <BackBtn onClick={changeMonth(-12)} isMulti />
        <BackBtn onClick={changeMonth(-1)} />
      </MultiNavBarBlock>
      <MultiNavBarBlock>
        <NextBtn onClick={changeMonth(1)} />
        <NextBtn onClick={changeMonth(12)} isMulti />
      </MultiNavBarBlock>
    </MultiNavBarWrapper>
  );
}

MultiNavBar.propTypes = {
  changeMonth: func.isRequired,
};

function MultipleDayPicker({ CustomDatePickerFooter, ...props }) {
  const { month, onDayClick, selectedDays, changeMonth, onFooterBtnClick } =
    useMultipleDayPickerLogic({
      ...props,
    });
  return (
    <>
      <DayPicker
        {...props}
        month={month}
        showOutsideDays
        numberOfMonths={2}
        modifiers={getModifiers(selectedDays)}
        {...{ selectedDays, changeMonth, onDayClick }}
        navbarElement={<MultiNavBar {...{ changeMonth }} />}
      />
      {CustomDatePickerFooter ? (
        <CustomDatePickerFooter {...{ onFooterBtnClick }} />
      ) : (
        <DatePickerFooter>
          {R.pipe(
            R.values,
            R.map((name) => (
              <DatePickerFooterBtn
                key={name}
                onClick={(e) => onFooterBtnClick(e, name)}
              >
                {name}
              </DatePickerFooterBtn>
            )),
          )(DatePickerButtons)}
        </DatePickerFooter>
      )}
    </>
  );
}

MultipleDayPicker.propTypes = {
  CustomDatePickerFooter: node,
};

function DayPickerC({ value, showFooter, getNavBarText, ...anotherProps }) {
  const [month, setMonth] = useState(value || alwaysNewDate());

  const changeMonth = (num) => () =>
    setMonth(addMonths(ensureIsDate(month), num));

  const onFooterBtnClick = (e, step) => {
    const newValue = step
      ? addWeeks(ensureIsDate(value), step)
      : alwaysNewDate();
    anotherProps.onDayMouseDown(newValue, true, e);
    setMonth(newValue);
  };

  return (
    <>
      <DayPicker
        {...anotherProps}
        month={month}
        selectedDays={R.when(R.isEmpty, R.always([]))(value)}
        captionElement={<Navbar {...{ month, getNavBarText, changeMonth }} />}
        showOutsideDays
      />
      {!!showFooter && (
        <DatePickerFooter>
          <DatePickerFooterBtn onClick={(e) => onFooterBtnClick(e, -1)}>
            Previous
          </DatePickerFooterBtn>
          <DatePickerFooterBtn onClick={(e) => onFooterBtnClick(e)}>
            Current
          </DatePickerFooterBtn>
          <DatePickerFooterBtn onClick={(e) => onFooterBtnClick(e, 1)}>
            Next
          </DatePickerFooterBtn>
        </DatePickerFooter>
      )}
    </>
  );
}

DayPickerC.propTypes = {
  getNavBarText: func,
  showFooter: bool,
  value: valuePropTypes,
};

function DayPickerWindowC({
  position,
  leftMove,
  overlay,
  isMultiple,
  value,
  getNavBarText,
  anotherProps,
}) {
  return (
    <DatePickerAligner
      {...{ position, leftMove, overlay }}
      onMouseDown={(e) => e.preventDefault()}
    >
      <DatePickerContainer {...{ isMultiple }}>
        {isMultiple ? (
          <MultipleDayPicker {...{ value, ...anotherProps }} />
        ) : (
          <DayPickerC {...{ value, getNavBarText, ...anotherProps }} />
        )}
      </DatePickerContainer>
    </DatePickerAligner>
  );
}

DayPickerWindowC.propTypes = {
  position: string,
  leftMove: string,
  overlay: bool,
  isMultiple: bool,
  value: valuePropTypes,
  getNavBarText: func,
  // eslint-disable-next-line react/forbid-prop-types
  anotherProps: object,
};

function DayPickerWindowOverlayC({
  isMultiple,
  value,
  getNavBarText,
  anotherProps,
  toggleIsOpen,
}) {
  return (
    <ModalOverlay isOpen>
      <OutsideClickHandler onOutsideClick={() => toggleIsOpen(false)}>
        <DatePickerContainer {...{ isMultiple }}>
          {isMultiple ? (
            <MultipleDayPicker {...{ value, ...anotherProps }} />
          ) : (
            <DayPickerC {...{ value, getNavBarText, ...anotherProps }} />
          )}
        </DatePickerContainer>
      </OutsideClickHandler>
    </ModalOverlay>
  );
}

DayPickerWindowOverlayC.propTypes = {
  isMultiple: bool,
  value: valuePropTypes,
  getNavBarText: func,
  // eslint-disable-next-line react/forbid-prop-types
  anotherProps: object,
  toggleIsOpen: func.isRequired,
};

function DayPickerWindowWrapper({ overlay, ...props }) {
  return overlay ? (
    <DayPickerWindowOverlayC {...props} />
  ) : (
    <DayPickerWindowC {...props} />
  );
}

DayPickerWindowWrapper.propTypes = {
  overlay: bool,
};

// isHandleOnchange :: (String, Boolean) -> Boolean
const isHandleOnchange = (dateStr, withOnChange) =>
  R.and(withOnChange, R.test(/\d{2}\/\d{2}\/\d{4}/, dateStr));

export const DatePicker = React.memo(
  ({
    id,
    size,
    value,
    error,
    width,
    label,
    onBlur,
    onFocus,
    overlay,
    onChange,
    hasError,
    position,
    leftMove,
    disabled,
    required,
    tabIndex,
    className,
    onKeyDown,
    isMultiple,
    labelProps,
    placeholder,
    showResetBtn,
    withOnChange,
    getNavBarText,
    labelPosition,
    openOnIconClick,
    formatDate = modifyDateToString,
    ...anotherProps
  }) => {
    const {
      isOpen,
      toggleIsOpen,
      onDayMouseDown,
      dateValue,
      setDateValue,
      onReset,
      modifiedValue,
      overflow,
    } = useDatePickerLogic({
      disableOverflowChange: anotherProps.disableOverflowChange,
      onChange,
      onBlur,
      isMultiple,
      formatDate,
      value,
    });
    const [internalError, setInternalError] = useState(null);

    const handleOnInputChange = (stringDate, callback = () => null) => {
      if (isValidDate(stringDate)) {
        onChange(modifyStringToDate(stringDate));
        callback();
        setInternalError(null);
      } else if (stringDate) {
        setInternalError("Incorrect format, date won't be saved");
        onChange(undefined);
      }
    };

    const errorMsg = R.or(error, internalError);

    return (
      <ColumnDirection width={width} {...{ labelPosition }}>
        {label ? (
          <LabelS
            {...labelProps}
            htmlFor={anotherProps.name}
            {...{ labelPosition }}
          >
            {label}
          </LabelS>
        ) : null}
        <OutsideClickHandler
          onOutsideClick={() => !overlay && isOpen && toggleIsOpen(false)}
        >
          <Container
            className={className}
            onClick={() => !openOnIconClick && toggleIsOpen(!disabled)}
            error={errorMsg}
            hasError={hasError}
          >
            <InputWithIconS
              size={size}
              width={width}
              hasError={hasError}
              error={errorMsg}
              onReset={onReset}
              showResetBtn={!!(showResetBtn && !isValueEmpty(dateValue))}
              icon={
                <CalendarIcon
                  onClick={() => openOnIconClick && toggleIsOpen(!isOpen)}
                />
              }
              containerProps={{ disabled }}
              required={required}
              inputProps={{
                readOnly: isMultiple,
                value: modifyInputValue(dateValue),
                placeholder,
                disabled,
                required,
                id,
                tabIndex,
                onBlur: (e) => {
                  handleOnInputChange(dateValue, () => onBlur(e));
                },
                onFocus: (e) => {
                  onFocus(e);
                  return !openOnIconClick && toggleIsOpen(true);
                },
                onChange: (e) => {
                  const { value: inputValue } = e.target;

                  if (isValidDate(inputValue) || !inputValue) {
                    setInternalError(null);
                  }
                  setDateValue(inputValue);

                  if (isHandleOnchange(inputValue, withOnChange)) {
                    handleOnInputChange(inputValue);
                  }
                },
                onKeyDown,
              }}
            />
            {isOpen ? (
              <DayPickerWindowWrapper
                isMultiple={isMultiple}
                value={modifiedValue}
                getNavBarText={getNavBarText}
                overlay={overlay}
                position={position}
                leftMove={leftMove}
                anotherProps={{
                  ...anotherProps,
                  onDayMouseDown,
                  formatDate,
                  overflow,
                }}
                toggleIsOpen={toggleIsOpen}
              />
            ) : null}
          </Container>
        </OutsideClickHandler>
      </ColumnDirection>
    );
  },
);

DatePicker.displayName = 'DatePicker';

DatePicker.propTypes = {
  id: string,
  onBlur: func,
  onFocus: func,
  overlay: bool,
  error: string,
  width: string,
  label: string,
  onChange: func,
  hasError: bool,
  disabled: bool,
  required: bool,
  onKeyDown: func,
  formatDate: func,
  position: string,
  isMultiple: bool,
  tabIndex: string,
  leftMove: string,
  className: string,
  showResetBtn: bool,
  withOnChange: bool,
  placeholder: string,
  getNavBarText: func,
  labelPosition: string,
  openOnIconClick: bool,
  value: valuePropTypes,
  size: oneOfType([string, number]),
  labelProps: shape({ color: string, size: number }),
};

DatePicker.defaultProps = {
  error: '',
  className: '',
  onBlur: R.T,
  onFocus: R.T,
  onChange: R.T,
  hasError: false,
  required: false,
  isMultiple: false,
  position: 'bottom',
  showResetBtn: false,
  withOnChange: false,
  labelPosition: 'top',
  getNavBarText: getDefaultNavBarText,
};
