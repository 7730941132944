import { gql } from '@apollo/client';
import { commonUpdateFields } from '../../hocs/updates/fragments.js';

export const ADD_UPDATE_MUTATION = gql`
  mutation ADD_UPDATE_MUTATION($input: CreateUpdateInput!) {
    createUpdate(input: $input) {
      ...commonUpdateFields
    }
  }
  ${commonUpdateFields}
`;
