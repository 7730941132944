import { collectionNames } from './collectionNames.js';

// taskActionTypes :: { k: TaskActionType }
export const taskActionTypes = {
  CONFIRM_SCOPE: 'confirmScope',
  SUPPLIER_ASSIGN: 'supplierAssign',
  SUPPLIER_SCHEDULING: 'confirmScheduling',
  SUPPLIER_FOLLOWUP: 'supplierFollowup',
  CONFIRM_SATISFACTION: 'confirmSatisfaction',
  PROJECT_COMPLETE: 'projectComplete',
  SUPPLIER_INVOICE: 'supplierInvoice',
  REPORT_ONLY_CLIENT_INVOICE: 'reportOnlyClientInvoice',
  CLOSE_PROJECT: 'projectClose',
  RECALL_PROJECT: 'projectRecall',
};

export const taskCollections = {
  CLIENTS: collectionNames.clients,
  RECURRING_PROJECTS: collectionNames.recurringProjects,
  PROJECTS: collectionNames.projects,
  PROPERTIES: collectionNames.properties,
  SUPPLIERS: collectionNames.suppliers,
  OCR_INVOICES: collectionNames.ocrInvoices,
  TASKS: collectionNames.tasks,
  MASTER_SUPPLIERS: collectionNames.masterSuppliers,
  PREVENTIVE_MAINTENANCE: collectionNames.maintenancePlans,
  ADMIN_PURCHASE_ORDERS: collectionNames.adminPurchaseOrders,
};

export const taskTypes = {
  PROJECT: 'Project',
  PROPERTY: 'Property',
  CLIENT: 'Client',
  SUPPLIER: 'Supplier',
  PERSONAL: 'Personal',
};

export const TaskDueDateFilterPreset = {
  ALL: 'all',
  ACTIVE: 'active',
  DUE_TODAY: 'dueToday',
  DUE_TOMORROW: 'dueTomorrow',
  PAST_DUE: 'pastDue',
  READY_TO_CLOSE: 'readyToClose',
};

// this approach resolves the priority sorting issue
export const TaskPriorities = {
  LOW: 'a_lowTaskPriority',
  NORMAL: 'b_normalTaskPriority',
  HIGH: 'c_highTaskPriority',
};

export const DEFAULT_TASK_PRIORITY = TaskPriorities.NORMAL;

export const TaskPriorityUIMap = {
  [TaskPriorities.LOW]: 'Low',
  [TaskPriorities.NORMAL]: 'Normal',
  [TaskPriorities.HIGH]: 'High',
};

export const clientReportTaskActionTypes = [
  taskActionTypes.CONFIRM_SCOPE,
  taskActionTypes.CONFIRM_SATISFACTION,
  taskActionTypes.PROJECT_COMPLETE,
  taskActionTypes.SUPPLIER_SCHEDULING,
];
