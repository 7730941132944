import * as R from 'ramda';
import {
  startOfDay,
  startOfWeek,
  startOfMonth,
  startOfYear,
  endOfDay,
  endOfWeek,
  endOfMonth,
} from 'date-fns';
import { alwaysNewDate } from '@poly/utils';

export const StaffReportDatePresets = {
  TODAY: 'todayDatePreset',
  THIS_WEEK: 'thisWeekDatePreset',
  THIS_MONTH: 'thisMonthDatePreset',
  YEAR_TO_DATE: 'yearToDatePreset',
};

// getStaffReportStartDateByPreset :: String -> Date
export const getStaffReportStartDateByPreset = R.cond([
  [
    R.equals(StaffReportDatePresets.TODAY),
    R.compose(startOfDay, alwaysNewDate),
  ],
  [
    R.equals(StaffReportDatePresets.THIS_WEEK),
    R.compose(startOfWeek, alwaysNewDate),
  ],
  [
    R.equals(StaffReportDatePresets.THIS_MONTH),
    R.compose(startOfMonth, alwaysNewDate),
  ],
  // StaffReportDatePresets.YEAR_TO_DATE
  [R.T, R.compose(startOfYear, alwaysNewDate)],
]);

// getStaffReportEndDateByPreset :: String -> Date
export const getStaffReportEndDateByPreset = R.cond([
  [R.equals(StaffReportDatePresets.TODAY), R.compose(endOfDay, alwaysNewDate)],
  [
    R.equals(StaffReportDatePresets.THIS_WEEK),
    R.compose(endOfWeek, alwaysNewDate),
  ],
  [
    R.equals(StaffReportDatePresets.THIS_MONTH),
    R.compose(endOfMonth, alwaysNewDate),
  ],
  // StaffReportDatePresets.YEAR_TO_DATE
  [R.T, alwaysNewDate],
]);
