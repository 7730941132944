export const collectionNames = {
  divisions: 'divisions',
  assets: 'assets',
  batches: 'batches',
  clients: 'clients',
  counters: 'counters',
  cronJobs: 'cron-jobs',
  files: 'files',
  clientInvoices: 'clientInvoices',
  clientInvoiceBatches: 'clientInvoiceBatches',
  consolidatedClientInvoices: 'consolidatedClientInvoices',
  invoices: 'invoices',
  ocrInvoices: 'ocrInvoices',
  poInvoices: 'poInvoices',
  materialSpends: 'material-spends',
  projects: 'projects',
  properties: 'properties',
  maintenancePlans: 'maintenancePlans',
  recurringProjects: 'recurringProjects',
  serviceTypes: 'service-types',
  suppliers: 'suppliers',
  tasks: 'tasks',
  timeLogs: 'time-logs',
  updates: 'updates',
  updateComments: 'updateComments',
  users: 'users',
  checks: 'checks',
  purchaseOrders: 'purchase-orders',
  userAlerts: 'user-alerts',
  mentionGroups: 'mention-groups',
  timesheets: 'timesheets',
  recurringOCRInvoices: 'recurringOCRInvoices',
  adminChecks: 'adminChecks',
  reconciliationStatements: 'reconciliationStatements',
  creditCardUsers: 'creditCardUsers',
  masterSuppliers: 'masterSuppliers',
  temporalAccessList: 'temporalAccessList',
  accountingPeriods: 'accountingPeriods',
  wipRecords: 'wipRecords',
  recurringJournals: 'recurringJournals',
  heavyJobs: 'heavyJobs',
  bannedEmailAttachments: 'bannedEmailAttachments',
  printedSupplierBatches: 'printedSupplierBatches',
  assetManufacturers: 'assetManufacturers',
  assetModels: 'assetModels',
  assetTypes: 'assetTypes',
  propertyHierarchies: 'propertyHierarchies',
  propertyLocationHierarchy: 'propertyLocationHierarchy',
  projectSubCategories: 'projectSubCategories',
  clientGLCodes: 'clientGLCodes',
  pushNotifications: 'pushNotifications',
  reportOnlyClientInvoices: 'reportOnlyClientInvoices',
  billingProfiles: 'billingProfiles',
  requestedSupplierDocuments: 'requestedSupplierDocuments',
  projectEstimates: 'projectEstimates',
  adminPurchaseOrders: 'adminPurchaseOrders',
  userGroups: 'userGroups',
  reportSendingConfigurations: 'reportSendingConfigurations',
  procedures: 'procedures',
  taskTemplates: 'taskTemplates',
  executedProcedures: 'executedProcedures',
};
