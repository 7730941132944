import React from 'react';
import {
  PageHeaderContainer,
  DefaultBodyWrapper,
  MainHeader,
} from '@poly/admin-book';

import { BankDepositsForm } from './BankDepositsForm.js';

export function BankDepositsPage() {
  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Bank Deposits</MainHeader>
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <BankDepositsForm />
      </DefaultBodyWrapper>
    </>
  );
}
