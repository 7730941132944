import React from 'react';
import { SubmitBtn, useProcessState } from '@poly/admin-ui';
import { formatBackLink, useLocation } from '@poly/client-routing';
import { string } from 'prop-types';

import { ButtonsContainerS } from '../../components/FormCardContainer.js';
import { CancelFormBtn } from '../../components/Buttons.js';
import { routesNames } from '../../routes/index.js';

export function ClientInvoicesButtons({ formId }) {
  const location = useLocation();

  const cancelRedirectUrl = formatBackLink({
    router: location.state,
    withQuery: false,
    fallbackLink: routesNames.CREATE_CLIENT_INVOICE_SEARCH,
  });
  const { process } = useProcessState(formId);

  const isMiddleBtnActive =
    !!document.getElementById(formId) &&
    document.getElementById(formId).hasAttribute('print');

  const onSaveAndPrintClick = () => {
    const form = document.getElementById(formId);
    form.setAttribute('print', 'true');
  };
  return (
    <ButtonsContainerS>
      <CancelFormBtn disabled={process} redirectUrl={cancelRedirectUrl} />
      <SubmitBtn
        loader={process && isMiddleBtnActive}
        disabled={process}
        form={formId}
        onClick={onSaveAndPrintClick}
      >
        Save & Print
      </SubmitBtn>
      <SubmitBtn
        loader={process && !isMiddleBtnActive}
        disabled={process}
        form={formId}
      >
        Save
      </SubmitBtn>
    </ButtonsContainerS>
  );
}

ClientInvoicesButtons.propTypes = {
  formId: string.isRequired,
};
