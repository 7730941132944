import React from 'react';
import styled from 'styled-components';
import { moneyColumnStyles, TableS, Body } from '@poly/admin-book';
import {
  bool,
  shape,
  number,
  string,
  arrayOf,
  oneOfType,
  instanceOf,
} from 'prop-types';

import { SubTitle } from '../ProfitLossReportPage/ProfitLossPrintTable.js';
import { IncomeStatementSectionTotal } from './components.js';
import { IncomeStatementTableBodySection } from './IncomeStatementTableBodySection.js';
import { IncomeStatementReportTableHeader } from './IncomeStatementReportTableHeader.js';
import { IncomeStatementReportTableFooter } from './IncomeStatementReportTableFooter.js';
import {
  getCogsReports,
  getIncomeReports,
  getExpenseReports,
  getReportDataTotal,
} from './helpers.js';

const customMoneyColumnStyles = (isPrint, columnNum) =>
  moneyColumnStyles(columnNum, isPrint ? 150 : 200);

const ReportTable = styled(TableS)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 125px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 50px;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: ${({ isPrint }) => (isPrint ? '150' : '250')}px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 100px;
  }

  th:nth-child(5),
  td:nth-child(5) {
    width: ${({ isPrint }) => (isPrint ? '200' : '300')}px;
  }

  ${({ isPrint }) => customMoneyColumnStyles(isPrint, 6)}
  ${({ isPrint }) => customMoneyColumnStyles(isPrint, 7)}

  @media print {
    tr {
      border: none;

      &:first-child {
        border: none;
      }

      &:last-child {
        border: none;
      }
    }
  }
`;

export function IncomeStatementReportTable({
  filters,
  isPrint,
  incomeStatements,
  cogsAccountCodes,
  ...restTableProps
}) {
  const incomeReports = getIncomeReports(incomeStatements);
  const cogsReports = getCogsReports(cogsAccountCodes)(incomeStatements);
  const expenseReports = getExpenseReports(cogsAccountCodes)(incomeStatements);

  const incomeReportsTotal = getReportDataTotal(incomeReports);
  const cogsReportsTotal = getReportDataTotal(cogsReports);
  const expenseReportsTotal = getReportDataTotal(expenseReports);

  return (
    <>
      {isPrint && <SubTitle {...restTableProps} />}
      <ReportTable isPrint={isPrint}>
        <IncomeStatementReportTableHeader />
        <Body footerHeight={50} showScrollBar>
          {incomeReports.length > 0 && (
            <IncomeStatementTableBodySection
              reportData={incomeReports}
              total={incomeReportsTotal}
              filters={filters}
              sectionTitle="Income"
              totalTitle="Total Income"
            />
          )}
          {cogsReports.length > 0 && (
            <IncomeStatementTableBodySection
              reportData={cogsReports}
              total={cogsReportsTotal}
              filters={filters}
              sectionTitle="COGS Facility Services"
              totalTitle="Total COGS Facility Services"
            />
          )}

          <IncomeStatementSectionTotal
            isBold
            title="Gross Profit"
            total={incomeReportsTotal + cogsReportsTotal}
          />

          {expenseReports.length > 0 && (
            <IncomeStatementTableBodySection
              reportData={expenseReports}
              total={expenseReportsTotal}
              filters={filters}
              sectionTitle="Overhead"
              totalTitle="Total Overhead"
            />
          )}
        </Body>
        <IncomeStatementReportTableFooter
          cogsReportsTotal={cogsReportsTotal}
          incomeReportsTotal={incomeReportsTotal}
          expenseReportsTotal={expenseReportsTotal}
        />
      </ReportTable>
    </>
  );
}

IncomeStatementReportTable.propTypes = {
  isPrint: bool,
  cogsAccountCodes: arrayOf(string),
  filters: shape({
    startDate: oneOfType([string, instanceOf(Date)]).isRequired,
    endDate: oneOfType([string, instanceOf(Date)]).isRequired,
    accountingMethod: string.isRequired,
  }),
  incomeStatements: arrayOf(
    shape({
      code: string,
      name: string,
      balance: number,
      notClientsBalance: number,
      compass_gl_code: string,
      compass_gl_code_description: string,
      accountType: shape({
        _id: string,
        normal_balance: string,
      }),
      clientsWithBalance: arrayOf(
        shape({
          code: string,
          balance: number,
          client: shape({
            _id: string,
            name: string,
          }),
        }),
      ),
    }),
  ),
};
