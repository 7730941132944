import React from 'react';
import { string, number, func, shape } from 'prop-types';
import { LinkButton } from '@poly/admin-book';
import styled from 'styled-components';

import {
  BlockWithLabel,
  threeBlocksProps,
} from '../../components/commonSidebarComponents.js';
import { useSidebarLogicContext } from '../../SidebarLogicContext.js';
import { calculatePorterHours } from '../projectSidebarUtils.js';
import {
  getSectionSidebarLink,
  getSectionText,
} from '../../components/commonSidebarSectionFormatters.js';

const LinkButtonS = styled(LinkButton)`
  width: 45px;
`;

const projectPropTypes = {
  project: shape({
    _id: string.isRequired,
    type: string.isRequired,
    parent: shape({ _id: string.isRequired }),
  }),
};

export function CloneProjectRow({ cloneFromProject }) {
  const { ProjectLink } = useSidebarLogicContext();

  return (
    <BlockWithLabel
      id="clone-from"
      label="Follow up to"
      {...threeBlocksProps}
      Component={getSectionSidebarLink(cloneFromProject, ProjectLink)}
    />
  );
}

CloneProjectRow.propTypes = {
  cloneFromProject: shape({
    type: string.isRequired,
    projectId: string.isRequired,
  }),
};

export function PorterHourlyRateRow({
  project,
  weeksNumber,
  supplierHourlyRate,
  clientHourlyRate,
  setShowCreditHours,
}) {
  const clientLabel = `Weekly Porter Client (${weeksNumber} wks)`;
  const supplierLabel = `Weekly Porter Supplier (${weeksNumber} wks)`;

  return (
    <>
      <BlockWithLabel
        {...threeBlocksProps}
        id="porter-client"
        label={clientLabel}
        Component={getSectionText(clientHourlyRate)}
        ActionBtn={
          calculatePorterHours(project) !== 0 ? (
            <LinkButtonS onClick={() => setShowCreditHours(true)}>
              edit
            </LinkButtonS>
          ) : null
        }
      />
      <BlockWithLabel
        {...threeBlocksProps}
        id="porter-supplier"
        label={supplierLabel}
        Component={getSectionText(supplierHourlyRate)}
      />
    </>
  );
}

PorterHourlyRateRow.propTypes = {
  ...projectPropTypes,
  weeksNumber: number.isRequired,
  supplierHourlyRate: string.isRequired,
  clientHourlyRate: string.isRequired,
  setShowCreditHours: func.isRequired,
};

export function ExtraPorterRow({
  weeksNumber,
  extraHoursClientRate,
  extraHoursSupplierRate,
}) {
  const clientLabel = `Extra Porter Client (${weeksNumber} wks)`;
  const supplierLabel = `Extra Porter Supplier (${weeksNumber} wks)`;

  return (
    <>
      <BlockWithLabel
        {...threeBlocksProps}
        id="extra-porter-client"
        label={clientLabel}
        Component={getSectionText(extraHoursClientRate)}
      />
      <BlockWithLabel
        {...threeBlocksProps}
        id="extra-porter-supplier"
        label={supplierLabel}
        Component={getSectionText(extraHoursSupplierRate)}
      />
    </>
  );
}

ExtraPorterRow.propTypes = {
  weeksNumber: number.isRequired,
  extraHoursClientRate: string.isRequired,
  extraHoursSupplierRate: string.isRequired,
};

export function ChildRecurringProjectRow({ parent }) {
  const { ProjectLink } = useSidebarLogicContext();

  return (
    <BlockWithLabel
      id="parent-link"
      {...threeBlocksProps}
      label="Master Project"
      Component={getSectionSidebarLink(parent, ProjectLink)}
    />
  );
}

ChildRecurringProjectRow.propTypes = {
  parent: shape({ _id: string.isRequired }),
};
