import * as R from 'ramda';
import React, { useMemo } from 'react';
import { shape, string, bool } from 'prop-types';
import { RecurringProjectStatuses, WorkOrderStatus } from '@poly/constants';
import { BLOCK_COMPLETED_PROJECT_PERMISSION } from '@poly/security';
import { UnknownProjectStatus } from '@poly/admin-ui';
import { isNilOrEmpty, propEqLegacy } from '@poly/utils';
import { Select } from '@poly/admin-book';
import {
  useHasUserAccessWithPermission,
  isChildRecurringProject,
  toDropDownOptions,
} from '@poly/client-utils';

import {
  isRecurringOrPMFormData,
  isNotFeeProjectType,
} from '../utils/sections.js';

// projectIsComplete :: Project -> Boolean
const projectIsComplete = R.propSatisfies(
  R.equals(WorkOrderStatus.COMPLETED),
  'status',
);

// projectIsBlockedAfterComplete :: Project -> Boolean
const projectIsBlockedAfterComplete = R.both(
  R.propSatisfies(R.complement(isNilOrEmpty), 'workCompletionDate'),
  propEqLegacy('status', WorkOrderStatus.BLOCKED),
);

// getOptions :: Project -> [Option]
const getOptions = R.compose(
  R.map(R.applySpec({ value: R.nth(0), label: R.nth(1) })),
  toDropDownOptions,
  R.cond([
    [isRecurringOrPMFormData, R.always(RecurringProjectStatuses)],
    [
      R.either(projectIsComplete, projectIsBlockedAfterComplete),
      R.always({
        BLOCKED: WorkOrderStatus.BLOCKED,
        COMPLETED: WorkOrderStatus.COMPLETED,
      }),
    ],
    [
      isChildRecurringProject,
      R.always({
        ACTIVE: WorkOrderStatus.ACTIVE,
        ON_HOLD_PARTS: WorkOrderStatus.ON_HOLD_PARTS,
        ON_HOLD_PROJECT: WorkOrderStatus.ON_HOLD_PROJECT,
        ON_HOLD_CLIENT: WorkOrderStatus.ON_HOLD_CLIENT,
        BLOCKED: WorkOrderStatus.BLOCKED,
      }),
    ],
    [
      R.T,
      R.always({
        ACTIVE: WorkOrderStatus.ACTIVE,
        PENDING: WorkOrderStatus.PENDING,
        ON_HOLD_PARTS: WorkOrderStatus.ON_HOLD_PARTS,
        ON_HOLD_PROJECT: WorkOrderStatus.ON_HOLD_PROJECT,
        ON_HOLD_CLIENT: WorkOrderStatus.ON_HOLD_CLIENT,
        BLOCKED: WorkOrderStatus.BLOCKED,
      }),
    ],
  ]),
);
export function ProjectStatusSelect({ formData, ...restProps }) {
  const hasPermissionToBlockCompleted = useHasUserAccessWithPermission(
    BLOCK_COMPLETED_PROJECT_PERMISSION,
  );

  const isProjectCompleted = useMemo(
    () => projectIsComplete(formData),
    [formData],
  );

  const options = useMemo(() => getOptions(formData), [formData]);

  const disabled = useMemo(
    () => isProjectCompleted && !hasPermissionToBlockCompleted,
    [isProjectCompleted, hasPermissionToBlockCompleted],
  );

  const bottomLabel =
    disabled && isNotFeeProjectType(formData)
      ? 'Please recall project to change status'
      : '';

  const emptyOption = ['', UnknownProjectStatus];

  const selectProps = {
    ...restProps,
    name: 'ProjectStatusSelect',
    options,
    disabled,
    bottomLabel,
    emptyOption,
  };

  return <Select {...selectProps} />;
}

ProjectStatusSelect.displayName = 'ProjectStatusSelect';

ProjectStatusSelect.propTypes = {
  formData: shape({
    status: string,
    isFeeProjectType: bool,
  }),
};
