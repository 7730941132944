import * as R from 'ramda';
import { func, shape, bool } from 'prop-types';
import React, { useState, useMemo, useEffect } from 'react';
import { useRouterQuery } from '@poly/client-routing';
import { InputHTML5 } from '@poly/admin-book';
import { propEqLegacy } from '@poly/utils';

import { useBillingProfilesQueryByInput } from './useBillingProfilesQueryByInput.js';

const NAME_DUPLICATED_ERROR =
  'Billing Profile with the same name already exists';

const NAME_REQUIRED_ERROR = 'Please fill in Billing Profile name';

// checkNameExists :: ID -> BillingProfilesQueryResult -> Boolean
const checkNameExists = (id) =>
  R.compose(
    R.complement(R.isEmpty),
    R.when(() => !!id, R.reject(propEqLegacy('_id', id))),
    R.defaultTo([]),
    R.prop('billingProfiles'),
  );

// checkNameRequired :: BillingProfileUIConfig -> Boolean
const checkNameRequired = R.both(
  propEqLegacy('saveBillingProfile', true),
  R.propSatisfies(R.isEmpty, 'nameSearch'),
);

// getInputError :: { nameAlreadyExists: Boolean, nameIsRequired: Boolean } -> String
const getInputError = R.cond([
  [R.prop('nameAlreadyExists'), R.always(NAME_DUPLICATED_ERROR)],
  [R.prop('nameIsRequired'), R.always(NAME_REQUIRED_ERROR)],
  [R.T, R.always('')],
]);

export function BillingProfileNameInput({ onChange, values, ...props }) {
  const [nameSearch, setNameSearch] = useState('');
  const { billingProfileId } = useRouterQuery(['billingProfileId']);

  const { data } = useBillingProfilesQueryByInput(
    { name: nameSearch },
    !nameSearch,
  );

  useEffect(() => {
    if (values.billingProfileName) {
      setNameSearch(values.billingProfileName.value);
    }
  }, []);

  const nameAlreadyExists = useMemo(
    () => checkNameExists(billingProfileId)(data),
    [data],
  );

  const nameIsRequired = useMemo(
    () => checkNameRequired({ ...values, nameSearch }),
    [values, nameSearch],
  );

  const hasError = nameAlreadyExists || nameIsRequired;

  const error = useMemo(
    () => getInputError({ nameAlreadyExists, nameIsRequired }),
    [nameAlreadyExists, nameIsRequired],
  );

  const onNameChange = (value) => {
    setNameSearch(value.trim());
    onChange({ value, invalid: hasError });
  };

  const inputProps = {
    ...props,
    error,
    hasError,
    value: nameSearch,
    onChange: onNameChange,
    showCharactersLeft: true,
  };

  return <InputHTML5 {...inputProps} />;
}

BillingProfileNameInput.propTypes = {
  onChange: func.isRequired,
  values: shape({ saveBillingProfile: bool }),
};
