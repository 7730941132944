import React from 'react';
import * as R from 'ramda';
import { GlobalSearchResultComponent } from '@poly/admin-ui';
import { duplicateField } from '@poly/client-utils';

import { globalSearchQuery } from '../globalSearchQuery.js';
import { globalSearchTabsConfig } from './globalSearchConfig.js';

// duplicateEntitySearchIds :: (String, String) -> Object -> Object
const duplicateEntitySearchIds = (entity, id) =>
  R.when(
    R.propSatisfies(R.identity, [entity]),
    R.over(
      R.lensPath([entity, 'hits']),
      R.map(duplicateField(id, 'projectLinkId')),
    ),
  );

// prepareGlobalSearchResultData :: GlobalSearchData -> GlobalSearchData
// GlobalSearchData = Object
const transformData = R.compose(
  duplicateEntitySearchIds('searchRecurringProjects', 'projectId'),
  duplicateEntitySearchIds('searchProjects', 'projectId'),
);

export function GlobalSearchResult() {
  return (
    <GlobalSearchResultComponent
      transformData={transformData}
      globalSearchQuery={globalSearchQuery}
      globalSearchTabsConfig={globalSearchTabsConfig}
    />
  );
}

GlobalSearchResult.displayName = 'GlobalSearchResult';
