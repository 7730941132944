import * as R from 'ramda';
import { FeeTypesUI, ProjectType } from '@poly/constants';
import { checkDateExpired, formatDateOrNothingUI } from '@poly/client-utils';
import { propEqLegacy } from '@poly/utils';

// getDetailsLinkProps :: Project -> Object
export const getDetailsLinkProps = R.compose(
  R.map(R.defaultTo({})),
  R.pickAll(['client', 'property']),
);

// getProjectAmountByType :: (String, [String]) -> Project -> String
export const getProjectAmountByType = (typeProp, bidPath) =>
  R.ifElse(
    propEqLegacy(typeProp, ProjectType.BID),
    R.path(bidPath),
    R.prop('nte'),
  );

// getProjectFeeTypeByPath :: [String] -> Project -> String
export const getProjectFeeTypeByPath = (path) =>
  R.compose(R.prop(R.__, FeeTypesUI), R.path(path));

// getDateByProp :: String -> Project -> String
export const getDateByProp = (prop) =>
  R.compose(formatDateOrNothingUI, R.prop(prop));

// checkProjectExpired :: Project -> Boolean
export const checkProjectExpired = R.converge(checkDateExpired, [
  R.prop('endDate'),
  R.prop('workCompletionDate'),
]);

// prepareProjectHistoryRecordByEntity :: String -> Project -> HistoryRecord
export const prepareProjectHistoryRecordByEntity = (entity) =>
  R.applySpec({
    id: R.prop('_id'),
    entity: R.always(entity),
    linkProps: R.pick(['_id', 'type', 'projectId']),
    description: R.compose(
      R.join(' - '),
      R.juxt([R.path(['client', 'name']), R.path(['property', 'name'])]),
    ),
    createdAt: () => new Date().toISOString(),
  });
