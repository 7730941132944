import * as R from 'ramda';
import styled from 'styled-components';
import React, { useMemo, useEffect, useState } from 'react';
import { bool, func, shape, string, oneOfType, object } from 'prop-types';
import { Input, Checkbox, Select } from '@poly/admin-book';
import {
  generateFormattedAddress,
  internationalPhoneUtils,
  addressConfig,
} from '@poly/client-utils';

import { GeoSelect } from '../../geoSelect/GeoSelect.js';
import { AddressInput } from '../../../modules/forms/fields/AddressSection/AddressSectionComp.js';
import {
  getAdministrativeAreaOptions,
  getPostalCodePlaceholder,
  getCountryCodeByAddress,
  isEmptyZipByAddress,
  getCountryOptions,
} from './utils.js';

const { getCountryFieldsByIso } = internationalPhoneUtils;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > div:not(:last-child) {
    padding-bottom: 20px;
  }
`;

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const halfWidth = 'calc(50% - 10px)';

const HalfInput = styled(Input)`
  width: ${halfWidth};
`;

const QuarterInput = styled(Input)`
  width: calc(25% - 20px);
`;

const HalfInputWrapper = styled.div`
  width: ${halfWidth};
`;

function AdministrativeAreaComponent({
  administrativeArea,
  onChangeState,
  value,
}) {
  const options = getAdministrativeAreaOptions(administrativeArea);

  const areaValue = R.pathOr(
    '',
    ['address_parts', 'administrative_area_level_1'],
    value,
  );

  if (options.length === 0) {
    return (
      <HalfInput
        name="state"
        label={administrativeArea.label}
        onChange={(e) => onChangeState(e.target.value)}
        value={areaValue}
      />
    );
  }

  return (
    <Select
      isClearable
      name="state"
      width={halfWidth}
      value={areaValue}
      options={options}
      onChange={onChangeState}
      label={administrativeArea.label}
    />
  );
}

AdministrativeAreaComponent.propTypes = {
  onChangeState: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  administrativeArea: object,
  value: oneOfType([
    string,
    shape({
      address_parts: shape({
        administrative_area_level_1: string,
      }),
    }),
  ]),
};

export function InternationalAddressSection({
  value,
  onChange,
  error,
  onBlur,
  onFocus,
  hasError,
  isClearable,
}) {
  const [isAddressManual, setIsAddressManual] = useState(false);

  useEffect(() => {
    if (value) {
      onChange({
        ...value,
        formatted_address: generateFormattedAddress(value),
      });
    }
  }, [value.address_parts]);

  const countryCode = getCountryCodeByAddress(value);

  const { localityName, postalCode, administrativeArea } = useMemo(
    () => getCountryFieldsByIso(countryCode)(addressConfig),
    [countryCode],
  );

  useEffect(() => {
    if (!postalCode) {
      onChange({
        ...value,
        address_parts: {
          ...value.address_parts,
          postal_code: '',
        },
      });
    }
  }, [postalCode]);

  const onChangeCity = (e) =>
    onChange({
      ...value,
      address_parts: { ...value.address_parts, locality: e.target.value },
    });

  const onChangeCountry = (country) => {
    onChange({
      ...value,
      address_parts: { country },
    });
  };

  const onChangeZipCode = (e) =>
    onChange({
      ...value,
      address_parts: { ...value.address_parts, postal_code: e.target.value },
    });

  const onChangeZipCodeSuffix = (e) =>
    onChange({
      ...value,
      address_parts: {
        ...value.address_parts,
        postal_code_suffix: e.target.value,
      },
    });

  const onChangeState = (state) =>
    onChange({
      ...value,
      address_parts: {
        ...value.address_parts,
        administrative_area_level_1: state,
      },
    });

  const onChangeStreetName = (e) =>
    onChange({
      ...value,
      address_parts: { ...value.address_parts, route: e.target.value },
    });

  const onChangeStreetNumber = (e) =>
    onChange({
      ...value,
      address_parts: {
        ...value.address_parts,
        street_number: e.target.value,
      },
    });

  const onChangeAddressTwo = (e) =>
    onChange({ ...value, addressTwo: e.target.value });

  const setAddressSearch = (search) => {
    onChange({ ...value, ...search });
    if (search && isEmptyZipByAddress(search)) {
      setIsAddressManual(true);
    }
  };

  const onChangeAddressManual = (isManual) => {
    onChange(R.omit(['geo', 'utc_offset', 'vicinity'], value));
    setIsAddressManual(isManual);
  };

  return (
    <Wrapper>
      <GeoSelect
        label="Address Search"
        {...{
          onBlur,
          onFocus,
        }}
        onChange={setAddressSearch}
      />
      <RowWrapper>
        <HalfInputWrapper>
          <AddressInput
            name="address1"
            label="Address"
            value={R.pathOr('', ['formatted_address'], value)}
            onChange={() => null}
            handleReset={() => onChange(null)}
            isClearable={isClearable}
            {...{ error, hasError, onFocus, onBlur }}
            disabled
          />
        </HalfInputWrapper>

        <HalfInput
          name="address2"
          label="Address 2"
          onChange={onChangeAddressTwo}
          value={R.pathOr('', ['addressTwo'], value)}
          {...{ onBlur, onFocus }}
        />
      </RowWrapper>
      <RowWrapper>
        <Checkbox
          name="isAddressManual"
          value={isAddressManual}
          onChange={onChangeAddressManual}
          label="Set Address Manually"
        />
      </RowWrapper>
      {isAddressManual && (
        <>
          <RowWrapper>
            <Select
              name="country"
              label="Country"
              width={halfWidth}
              value={countryCode}
              onChange={onChangeCountry}
              options={getCountryOptions(addressConfig)}
              {...{ onBlur, onFocus }}
            />
            {localityName && (
              <HalfInput
                name="city"
                label={localityName.label}
                onChange={onChangeCity}
                value={R.pathOr('', ['address_parts', 'locality'], value)}
                {...{ onBlur, onFocus }}
              />
            )}
          </RowWrapper>
          <RowWrapper>
            <HalfInput
              name="streetName"
              label="Street Name"
              onChange={onChangeStreetName}
              value={R.pathOr('', ['address_parts', 'route'], value)}
              {...{ onBlur, onFocus }}
            />
            <HalfInput
              name="streetNumber"
              label="Street Number"
              onChange={onChangeStreetNumber}
              value={R.pathOr('', ['address_parts', 'street_number'], value)}
              {...{ onBlur, onFocus }}
            />
          </RowWrapper>
          <RowWrapper>
            {administrativeArea && (
              <AdministrativeAreaComponent
                {...{
                  administrativeArea,
                  onChangeState,
                  value,
                  onBlur,
                  onFocus,
                }}
              />
            )}
            {postalCode && (
              <>
                <QuarterInput
                  name="zip"
                  label={postalCode.label}
                  placeholder={getPostalCodePlaceholder(postalCode)}
                  pattern={postalCode.format}
                  onChange={onChangeZipCode}
                  value={R.pathOr('', ['address_parts', 'postal_code'], value)}
                  {...{ onBlur, onFocus }}
                  maxLength={5}
                />
                <QuarterInput
                  name="suffix"
                  label="Suffix"
                  placeholder="Enter suffix"
                  onChange={onChangeZipCodeSuffix}
                  value={R.pathOr(
                    '',
                    ['address_parts', 'postal_code_suffix'],
                    value,
                  )}
                  {...{ onBlur, onFocus }}
                  maxLength={4}
                />
              </>
            )}
          </RowWrapper>
        </>
      )}
    </Wrapper>
  );
}

InternationalAddressSection.propTypes = {
  error: string,
  onBlur: func.isRequired,
  onFocus: func.isRequired,
  onChange: func.isRequired,
  hasError: bool.isRequired,
  value: oneOfType([
    string,
    shape({
      formatted_address: string,
      address_parts: shape({
        route: string,
        locality: string,
        postal_code: string,
        postal_code_suffix: string,
        street_number: string,
        administrative_area_level_1: string,
      }),
    }),
  ]),
  isClearable: bool,
};
