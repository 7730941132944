import * as R from 'ramda';
import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { commonSortQuery, keywordSortQuery } from '@poly/client-utils';
import { useRouterQuery } from '@poly/client-routing';
import { MAX_ITEMS } from '@poly/admin-ui';
import {
  InvoicesStatuses,
  DESC_SORT_ORDER,
  ASC_SORT_ORDER,
} from '@poly/constants';

// getMasterSupplierBranchesConfig :: MasterSupplier -> [BranchesConfigItem]
// BranchesConfigItem = { cardNumber: String, supplierName: String, terms: String, invoices: [Invoice] }
const getMasterSupplierBranchesConfig = R.compose(
  R.reject(R.isNil),
  R.map(
    R.ifElse(
      R.pathSatisfies(R.isEmpty, ['searchInvoices', 'hits']),
      R.always(null),
      R.applySpec({
        cardNumber: R.prop('cardNumber'),
        terms: R.path(['finance', 'terms']),
        supplierName: R.path(['company', 'name']),
        invoices: R.pathOr([], ['searchInvoices', 'hits']),
      }),
    ),
  ),
  R.pathOr([], ['masterSupplier', 'searchSupplierBranches', 'hits']),
);

const MASTER_SUPPLIER_OPENING_INVOICES = gql`
  query MASTER_SUPPLIER_OPENING_INVOICES(
    $id: ID!
    $suppliersInput: CollectionSearchParams!
    $invoicesInput: CollectionSearchParams!
  ) {
    masterSupplier(id: $id) {
      _id
      company {
        name
      }
      searchSupplierBranches(input: $suppliersInput) {
        hits {
          _id
          company {
            name
          }
          finance {
            terms
          }
          cardNumber
          searchInvoices(input: $invoicesInput) {
            hits {
              _id
              total
              invoiceDate
              invoiceNumber
              project {
                _id
                type
                projectId
              }
              balance
            }
          }
        }
      }
    }
  }
`;

export const useMasterSupplierOpeningInvoicesQuery = () => {
  const { supplierId } = useRouterQuery(['supplierId']);

  const { data, loading } = useQuery(MASTER_SUPPLIER_OPENING_INVOICES, {
    variables: {
      id: supplierId,
      suppliersInput: {
        from: 0,
        size: MAX_ITEMS,
        sort: keywordSortQuery(['company', 'name'])(ASC_SORT_ORDER),
      },
      invoicesInput: {
        from: 0,
        size: MAX_ITEMS,
        sort: commonSortQuery(['invoiceDate'])(DESC_SORT_ORDER),
        query: {
          terms: {
            status: [InvoicesStatuses.PARTIALLY_PAID, InvoicesStatuses.PENDING],
          },
        },
      },
    },
  });

  const masterSupplierName = R.path(
    ['masterSupplier', 'company', 'name'],
    data,
  );
  const listConfig = useMemo(
    () => getMasterSupplierBranchesConfig(data),
    [data],
  );

  return {
    loading,
    listConfig,
    masterSupplierName,
  };
};
