import React from 'react';
import * as R from 'ramda';
import {
  arrayOf,
  func,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { GallerySlider, Icon, getThemeColor } from '@poly/admin-book';

import { closeCurrentModal } from '../../redux/modal.js';

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px;
  border-radius: 30px;
  background-color: ${getThemeColor(['white'])};
  opacity: 0.5;
  cursor: pointer;
  z-index: 1;

  :hover {
    opacity: 0.8;
  }
`;

const IconS = styled(Icon)`
  z-index: 2;
`;

export function GalleryModalComp({ closeGallery, ...props }) {
  return (
    <>
      <IconWrapper onClick={closeGallery}>
        <IconS name="close" size={25} color="#777777" />
      </IconWrapper>
      <GallerySlider {...props} />
    </>
  );
}

GalleryModalComp.propTypes = { closeGallery: func.isRequired };

export function GalleryModal({ payload }) {
  const dispatch = useDispatch();

  const commonProps = {
    settings: {
      speed: 500,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      initialSlide: R.propOr(0, 'index', payload),
    },
    images: R.pipe(R.prop('images'), R.defaultTo([]))(payload),
  };

  const closeGallery = () => dispatch(closeCurrentModal());

  return <GalleryModalComp {...commonProps} closeGallery={closeGallery} />;
}

GalleryModal.displayName = 'GalleryModal';

GalleryModal.propTypes = {
  payload: shape({
    images: arrayOf(oneOfType([string, object])),
    index: number,
  }),
};
