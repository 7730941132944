import styled from 'styled-components';
import * as R from 'ramda';
import { Dot, getThemeColor, Text } from '@poly/admin-book';
import { FlexContainer } from '../../../components/FlexContainer.js';

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: ${R.prop('align')};
  justify-content: ${R.prop('justify')};
  margin: ${R.prop('margin')};
  align-self: stretch;
`;

export const FlexSpaceBetween = styled(FlexContainer)`
  justify-content: space-between;
  ${({ withError }) => withError && 'height: 40px'};
`;

export const FlexSpaceBetweenFullWidth = styled(FlexSpaceBetween)`
  width: 100%;
`;

export const SuppliersListContainer = styled(FlexColumn)`
  overflow-y: auto;
  min-height: 100px;
  max-height: 248px;

  > div:last-child {
    border-bottom: none;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex: ${R.prop('flex')};
  margin: ${R.propOr('0 0 0 7px', 'margin')};
  flex-direction: column;
  justify-content: space-between;

  ${Text} {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 8px;
  }
`;

export const StatusS = styled(Dot)`
  display: inline-flex;
  margin-left: 7px;
  flex-shrink: 0;
`;

export const LightText = styled(Text)`
  color: ${getThemeColor(['midDark'])};
  margin: ${R.prop('margin')};
  padding: ${R.prop('padding')};
  font-size: ${R.propOr('10px', 'size')};
  text-align: ${R.propOr('start', 'align')};
`;

export const DarkText = styled(Text)`
  margin: ${R.prop('margin')};
  font-size: ${R.propOr('10px', 'size')};
  text-align: ${R.propOr('end', 'align')};
  line-height: 18px;
`;

export const SectionWrapper = styled(FlexSpaceBetween)`
  width: 100%;
  padding: ${R.propOr('24px', 'padding')};
`;

export const SuppliersListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${getThemeColor(['lighter'])};
  border-bottom: 1px solid ${getThemeColor(['midLight'])};
  border-top: 1px solid ${getThemeColor(['midLight'])};
`;

export const SupplierItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 13px 24px;
  border-bottom: 1px solid ${getThemeColor(['midLight'])};
`;

export const SupplierItemGridRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 5fr 1.1fr 1.35fr 1.35fr 1.1fr;
`;
