import React from 'react';

import { RequesterSurveyForm } from './RequesterSurveyForm.js';
import { useAuthUserByTemporalAccess } from '../DocumentsRequest/DocumentsRequestPage.js';

export function RequesterSurveyPage() {
  const { isAuthorized } = useAuthUserByTemporalAccess();

  if (!isAuthorized) {
    return null;
  }

  return <RequesterSurveyForm />;
}
