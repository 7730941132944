import { Loader, MainHeader, WhiteCard } from '@poly/admin-book';
import React from 'react';
import { useNotificationState, useProcessState } from '@poly/admin-ui';
import styled from 'styled-components';
import { gql } from '@apollo/client';
import { AccountingPeriodTypes } from '@poly/constants/src/accounting-period.js';

import { useNavigate } from '@poly/client-routing';
import { insertQueryParamsIntoURL } from '@poly/utils';

import { AccountingPeriodsTable } from './AccountingPeriodsTable.js';
import { useSubmit } from './useSubmit.js';
import { routesNames } from '../../routes/index.js';
import { DefaultPageLayout } from '../DefaultPageLayout.js';

import { useAccountingPeriods } from './useAccountingPeriods.js';

const AccountingPeriodsContainerS = styled(WhiteCard)`
  padding: 15px;
  align-items: start;
  min-height: ${({ isFullHeight }) =>
    isFullHeight ? 'calc(100% - 60px)' : 'none'};
  max-height: none;
  height: min-content;
`;

const reopenAccountingPeriodMutation = gql`
  mutation reopenAccountingPeriod($input: ReopenAccountingPeriodInput!) {
    reopenAccountingPeriod(input: $input) {
      _id
    }
  }
`;

const reopenAccountingPeriodProcessId = 'reopenAccountingPeriodProcessId';
const closeAccountingPeriodProcessId = 'closeAccountingPeriodProcessId';

export function ReopenAccountingPeriod() {
  const navigate = useNavigate();
  const reopenAccountingPeriodProcess = useProcessState(
    reopenAccountingPeriodProcessId,
  );
  const closeAccountingPeriodProcess = useProcessState(
    closeAccountingPeriodProcessId,
  );
  const { showSuccessNotification } = useNotificationState();

  const { loading, closedAccountingPeriods, openedAccountingPeriods } =
    useAccountingPeriods(AccountingPeriodTypes.YEAR);

  const reopenPeriod = useSubmit(
    reopenAccountingPeriodMutation,
    reopenAccountingPeriodProcessId,
    () => {
      showSuccessNotification('Accounting period reopened successfully');
    },
  );

  const onReopen = (accountingPeriod) => reopenPeriod(accountingPeriod._id);

  const onClose = (accountingPeriod) =>
    navigate(
      insertQueryParamsIntoURL(
        {
          currentPeriodStartDate: accountingPeriod.startDate,
          currentPeriodEndDate: accountingPeriod.endDate,
        },
        routesNames.POST_CLOSING_JOURNAL,
      ),
    );

  return (
    <DefaultPageLayout
      headerContent={<MainHeader>Re-Open Closed Financial Year</MainHeader>}
    >
      {loading ? (
        <Loader />
      ) : (
        <AccountingPeriodsContainerS>
          <AccountingPeriodsTable
            accountingPeriods={closedAccountingPeriods}
            title="List of Closed Financial Year"
            buttonTitle="Open"
            onSubmit={onReopen}
            loading={reopenAccountingPeriodProcess.process}
            key="closed-periods"
          />

          <AccountingPeriodsTable
            accountingPeriods={openedAccountingPeriods}
            title="List of Open Financial Year"
            buttonTitle="Close"
            onSubmit={onClose}
            loading={closeAccountingPeriodProcess.process}
            key="opened-periods"
          />
        </AccountingPeriodsContainerS>
      )}
    </DefaultPageLayout>
  );
}
