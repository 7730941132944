import React, { useState } from 'react';
import styled from 'styled-components';
import { string, element, bool } from 'prop-types';

import { Text } from '../Text/index.js';
import { Icon } from '../Icon/index.js';
import { FlexContainer } from '../lib/index.js';

const Title = styled(FlexContainer)`
  cursor: pointer;
  width: fit-content;
`;

const IconS = styled(Icon)`
  margin-left: 15px;
`;

export function Collapse({ title, content, className, isCollapseOpened }) {
  const [isOpen, setIsOpen] = useState(isCollapseOpened || false);
  return (
    <div className={className}>
      <Title onClick={() => setIsOpen(!isOpen)}>
        <Text size="12px">{title}</Text>
        <IconS size={10} name={isOpen ? 'arrow-up' : 'arrow-down'} />
      </Title>
      {isOpen && content}
    </div>
  );
}

Collapse.propTypes = {
  title: string.isRequired,
  content: element.isRequired,
  className: string,
  isCollapseOpened: bool,
};
Collapse.displayName = 'Collapse';
