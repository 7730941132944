import * as R from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';
import { number, bool, func, string } from 'prop-types';
import { Table, Button } from '@poly/admin-book';
import { applyProp, centsToDollarsWithFormat } from '@poly/utils';
import { useMapConfigToTableProps } from '@poly/admin-ui';

import { tmBatchInvoicingTableStyles } from './BatchInvoicingTMBassProTable.js';

export const batchInvoicingCommonTotalsTableStyles = css`
  tbody {
    vertical-align: middle;
  }

  thead {
    height: 30px;
    overflow-y: initial;
  }

  tr {
    border: none !important;
    background-color: rgba(0, 0, 0, 0);
  }

  td {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const BatchInvoicingTMTotalsTableS = styled(Table)`
  ${tmBatchInvoicingTableStyles}
  ${batchInvoicingCommonTotalsTableStyles}

  th:last-child,
  td:last-child {
    width: 190px;
  }
`;

const getHousekeepingTotalsTableConfig = (isWoCharge) => [
  ['', R.always('')],
  ['', R.always('')],
  ['Job Cost', applyProp(centsToDollarsWithFormat)('jobCost')],
  ['Markup', applyProp(centsToDollarsWithFormat)('clientInvoiceMarkupAmount')],
  ...(isWoCharge
    ? [
        [
          'Work Order Charge',
          R.compose(centsToDollarsWithFormat, R.prop('woCharge')),
        ],
      ]
    : []),
  [
    'Total',
    applyProp(centsToDollarsWithFormat)('suggestedClientInvoiceAmount'),
  ],
  [
    '',
    ({ onCreateBatch, disabled, loader, btnText }) => (
      <Button
        size="small"
        onClick={onCreateBatch}
        disabled={disabled}
        loader={loader}
      >
        {btnText || 'Create Batch'}
      </Button>
    ),
  ],
];

export function BatchInvoicingTMBassProTotalsTable({
  suggestedClientInvoiceAmount,
  jobCost,
  onCreateBatch,
  disabled,
  loader,
  clientInvoiceMarkupAmount,
  btnText,
  woCharge,
  isWoCharge,
}) {
  const { rows, headers, columns } = useMapConfigToTableProps(
    R.identity,
    getHousekeepingTotalsTableConfig(isWoCharge),
    [
      {
        _id: 'totals',
        suggestedClientInvoiceAmount,
        jobCost,
        onCreateBatch,
        disabled,
        loader,
        clientInvoiceMarkupAmount,
        btnText,
        woCharge,
      },
    ],
  );

  return (
    <BatchInvoicingTMTotalsTableS
      headers={headers}
      rows={rows}
      columns={columns}
      enableHoverEffect={false}
      minHeightLess
    />
  );
}

BatchInvoicingTMBassProTotalsTable.propTypes = {
  suggestedClientInvoiceAmount: number.isRequired,
  jobCost: number.isRequired,
  onCreateBatch: func.isRequired,
  disabled: bool,
  loader: bool,
  clientInvoiceMarkupAmount: number.isRequired,
  btnText: string,
  woCharge: number.isRequired,
  isWoCharge: bool,
};
