import React from 'react';
import styled from 'styled-components';
import { number, string } from 'prop-types';

import { getThemeColor } from '../utils.js';

const getBorderColor = ({ theme, isActive }) =>
  getThemeColor(['table', 'sorting', isActive ? 'active' : 'disabled'])({
    theme,
  });

const TriangleBase = styled.div`
  width: 0;
  height: 0;
  border-left: 3.5px solid transparent;
  border-right: 3.5px solid transparent;
`;

const TriangleUp = styled(TriangleBase)`
  border-bottom: 4px solid ${getBorderColor};
`;

const TriangleDown = styled(TriangleBase)`
  margin-top: 2px;
  border-top: 4px solid ${getBorderColor};
`;

const Container = styled.div`
  display: inline-block;
  height: 10px;
`;

export function SortingUpDown({ dir, className }) {
  return (
    <Container className={className}>
      <TriangleUp isActive={dir === 1} />
      <TriangleDown isActive={dir === -1} />
    </Container>
  );
}

SortingUpDown.propTypes = {
  dir: number.isRequired,
  className: string,
};

SortingUpDown.defaultProps = {
  className: '',
};
