import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { FlexColumn } from '@poly/admin-ui';
import { InputErrorMsg, RadioButton } from '@poly/admin-book';
import { func, number, oneOfType, string } from 'prop-types';
import {
  RequesterSurveyQuestionTypes,
  RequesterSurveyQuestionSubmitMethodOptions,
} from '@poly/constants';

const ScoringQuestionTypeComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px 50px;

  @media (max-width: 700px) {
    flex-direction: column;
  }

  label {
    font-size: 14px;
  }
`;

const RadioButtonS = styled(RadioButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 14px;
`;

// getComponentProps :: (String, Int, () -> _) -> Props
const getComponentProps = (name, currentValue, onChange) =>
  R.map(
    R.applySpec({
      key: R.compose(R.concat('scoringQuestionType_'), R.toLower),
      name: R.compose(R.concat(name), R.toLower),
      label: R.identity,
      value: R.equals(currentValue),
      onChange: (value) => () => onChange(value),
    }),
  );

// getRadioButtonSelectOptions :: RequesterSurveyQuestionTypes -> [String]
const getRadioButtonSelectOptions = R.cond([
  [R.equals(RequesterSurveyQuestionTypes.yesNo), R.always(['Yes', 'No'])],
  [
    R.equals(RequesterSurveyQuestionTypes.scoring),
    R.compose(R.map(R.toString), R.range(1), R.always(6)),
  ],
  // Submit Method type
  [R.T, R.always(RequesterSurveyQuestionSubmitMethodOptions)],
]);

export function RadioButtonSelect({
  questionType,
  onChange,
  value,
  name,
  error,
}) {
  const radioButtonSelectOptions = getRadioButtonSelectOptions(questionType);

  const componentProps = getComponentProps(
    name,
    value,
    onChange,
  )(radioButtonSelectOptions);

  return (
    <FlexColumn>
      <ScoringQuestionTypeComponentWrapper>
        {componentProps.map((props) => (
          <RadioButtonS {...props} />
        ))}
      </ScoringQuestionTypeComponentWrapper>
      {error && <InputErrorMsg>{error}</InputErrorMsg>}
    </FlexColumn>
  );
}

RadioButtonSelect.propTypes = {
  name: string,
  value: oneOfType([number, string]),
  onChange: func,
  questionType: string,
  error: string,
};
