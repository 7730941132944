import React from 'react';
import { Loader } from '@poly/site-book';

export function ButtonLoader(props) {
  return (
    <Loader {...props}>
      <Loader.Circle {...props} />
    </Loader>
  );
}

ButtonLoader.defaultProps = { size: 20 };
