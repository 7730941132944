import React, { useState } from 'react';
import { bool, func, string } from 'prop-types';

export function InputOutForm({
  requiredErr,
  value,
  onChange,
  Component,
  hasError,
  ...props
}) {
  const [error, setError] = useState(null);

  const onSelectAccountBlur = () => {
    if (!requiredErr && !value) {
      setError(true);
      return;
    }
    if (!value) setError(requiredErr);
  };

  return (
    <Component
      {...props}
      value={value}
      onChange={(e) => {
        setError('');
        onChange(e);
      }}
      onBlur={onSelectAccountBlur}
      error={requiredErr ? error : ''}
      requiredWithOutError={!requiredErr ? !!error : undefined}
      hasError={!requiredErr ? !!error : hasError}
    />
  );
}

InputOutForm.propTypes = {
  requiredErr: string,
  value: string,
  onChange: func,
  Component: func,
  hasError: bool,
};
