import * as R from 'ramda';

// getUserCellPhoneNumber :: User -> String
export const getUserCellPhoneNumber = R.path(['profile', 'cellPhoneNumber']);

// getUserFaxPhoneNumber :: User -> String
export const getUserFaxPhoneNumber = R.path(['profile', 'faxPhoneNumber']);

// getUserWorkPhoneNumber :: User -> String
export const getUserWorkPhoneNumber = R.path(['profile', 'workPhoneNumber']);

// getUserWorkPhoneNumberExt :: User -> String
export const getUserWorkPhoneNumberExt = R.path([
  'profile',
  'workPhoneNumberExt',
]);

// getUserCellPhoneObject :: User -> { number: String }
export const getUserCellPhoneObject = R.applySpec({
  number: getUserCellPhoneNumber,
});

// getUserFaxPhoneObject :: User -> { number: String }
export const getUserFaxPhoneObject = R.applySpec({
  number: getUserFaxPhoneNumber,
});

// getUserWorkPhoneObject :: User -> { number: String, ext: String }
export const getUserWorkPhoneObject = R.applySpec({
  number: getUserWorkPhoneNumber,
  ext: getUserWorkPhoneNumberExt,
});
