import { gql, useQuery } from '@apollo/client';

const reportSendingConfigurationDetailQuery = gql`
  query reportSendingConfiguration($clientId: ID!) {
    reportSendingConfiguration(clientId: $clientId) {
      _id
      startDate
      endDate
      emails
      schedule {
        repeats
        every
        days
        weekDay
      }
      reportFilter {
        ageFrom
        ageTo
      }
    }
  }
`;

export const useReportSendingConfigurationDetailQuery = (clientId) => {
  const { data, loading } = useQuery(reportSendingConfigurationDetailQuery, {
    variables: { clientId },
    fetchPolicy: 'network-only',
    skip: !clientId,
  });

  return {
    data,
    loading,
  };
};
