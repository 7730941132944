import { gql } from '@apollo/client';

export const USER_GROUP_LIST_QUERY = gql`
  query USER_GROUP_LIST_QUERY($input: ListUserGroupsInput) {
    listUserGroups(input: $input) {
      _id
      name
      defaultLoginApp
      assignedUsersCount
      isTwoFactorAuthRequired
      config {
        isDefaultCSR
        isDefaultManagement
      }
      accessItems {
        uid
        permission
      }
      variables {
        id
        name
        type
      }
    }
  }
`;

export const SEARCH_USER_GROUP_CHANGED_SUB = gql`
  subscription SEARCH_USER_GROUP_CHANGED_SUB($input: CollectionSearchParams) {
    searchUserGroupChanged(input: $input) {
      id
      type
    }
  }
`;
