import React from 'react';
import { func, string } from 'prop-types';
import { UpdateTypes } from '@poly/constants';
import { Select } from '@poly/admin-book';

const updateTypesOptions = [{ value: UpdateTypes.INTERNAL, label: 'Normal' }];

export function UpdateTypesSelect({ onChange, value, ...props }) {
  return (
    <Select
      {...props}
      name="update-type"
      {...{ onChange, value }}
      options={updateTypesOptions}
    />
  );
}

UpdateTypesSelect.propTypes = {
  onChange: func.isRequired,
  value: string.isRequired,
};
