export const LISTING_PROJECT_SPECIFIC_FIELDS_UI_MAP = {
  bpoListPrice: 'BPO List Price',
  bpoLow: 'BPO Low',
  bpoHigh: 'BPO High',
  bpoDate: 'Broker Price Opinion',
  salesAddendumDate: 'Sales Addendum',
  listPrice: 'Current List Price',
  listDate: 'For Sale Date',
  underContractDate: 'Under Contract Date',
  dueDiligenceExp: 'Due Diligence Exp.',
  referralFee: 'Referral Fee',
  underContractPrice: 'Under Contract Price',
  listAgreementExp: 'List Agreement Exp.',
  expectedCloseDate: 'Expected Close Date',
};

export const LISTING_PROJECT_SPECIFIC_FIELDS = Object.keys(
  LISTING_PROJECT_SPECIFIC_FIELDS_UI_MAP,
);
