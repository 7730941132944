/* eslint-disable import/no-unused-modules */
import * as R from 'ramda';

// getSubscriptionOptionsEntityPath :: [String] -> Object -> SubscriptionOptions
const getSubscriptionOptionsEntityPath = (path) =>
  R.applySpec({
    skip: R.compose(R.isNil, R.path(path)),
    variables: R.compose(R.objOf('input'), R.objOf('id'), R.path(path)),
  });

// getAssetSubscriptionOptions :: Asset -> SubscriptionOptions
export const getAssetSubscriptionOptions = getSubscriptionOptionsEntityPath([
  'asset',
  '_id',
]);

// getAssetManufacturerSubscriptionOptions :: Asset -> SubscriptionOptions
export const getAssetManufacturerSubscriptionOptions =
  getSubscriptionOptionsEntityPath(['asset', 'manufacturerDoc', '_id']);

// getAssetSubscriptionOptionsByQrCode :: Asset -> SubscriptionOptions
export const getAssetSubscriptionOptionsByQrCode =
  getSubscriptionOptionsEntityPath(['assetByQrCodeId', '_id']);

// getAssetManufacturerSubscriptionOptions :: Asset -> SubscriptionOptions
export const getAssetManufacturerSubscriptionOptionsByQrCode =
  getSubscriptionOptionsEntityPath([
    'assetByQrCodeId',
    'manufacturerDoc',
    '_id',
  ]);
