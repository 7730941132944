import * as R from 'ramda';
import { useRouterParams } from '@poly/client-routing';
import { useQuery } from '@apollo/client';
import { SUPPLIER_SERVICE_TYPES, SUPPLIER_DETAILS } from './queries.js';

export const useSupplierDetailsQuery = () => {
  const routerParams = useRouterParams(['supplierId']);

  const { data, loading } = useQuery(SUPPLIER_DETAILS, {
    variables: { supplierId: routerParams.supplierId },
    fetchPolicy: 'network-only',
  });

  return {
    data,
    loading,
  };
};

export const useSupplierServiceTypes = (supplierId) => {
  const { data, loading } = useQuery(SUPPLIER_SERVICE_TYPES, {
    variables: { id: supplierId },
    skip: !supplierId,
  });

  return {
    supplierServiceTypes: R.pathOr(
      [],
      ['supplier', 'company', 'services'],
      data,
    ),
    loading,
  };
};
