import React from 'react';
import * as R from 'ramda';
import { string, shape, arrayOf, bool } from 'prop-types';
import {
  duplicateField,
  useHighlightMatchesBySearch,
} from '@poly/client-utils';
import { DESC_SORT_ORDER } from '@poly/constants';
import { Loader } from '@poly/admin-book';
import {
  useSetInitialPaginationOnMount,
  useMapConfigToTableProps,
  useSetItemsCount,
  useTableSorting,
} from '@poly/admin-ui';

import {
  projectIdColumn,
  ProjectStatusDot,
  projectStatusColumn,
  projectClientColumn,
  projectEndDateColumn,
  projectManagerColumn,
  projectPriorityColumn,
  projectStartDateColumn,
  projectDescriptionColumn,
} from '../columns/projects.js';
import {
  ProjectsPageProjectsTableComp,
  RecurringProjectsPageProjectsTableComp,
} from './ProjectsTableComp.js';
import { propertyEntityColumnWithSort } from '../columns/property.js';
import { useSetSearchEntityParams } from '../../../hooks/useSetSearchEntityParams.js';
import { useSortingByReduxSearch } from '../../core/hooks/useSortingByReduxSearch.js';
import { setDefaultProjectManager } from './projectsTableUtils.js';
import {
  useSupplierRecurringProjectsBySearch,
  useRecurringProjectsBySearch,
  useMyProjectsBySearch,
  useProjectsBySearch,
} from '../../core/hooks/projects/index.js';

export const tablePropTypes = {
  query: shape({}).isRequired,
};

// getSearchProjects :: SearchProjectsQueryResult -> [Project]
const getSearchProjects = (projectsPath, data) =>
  R.compose(
    R.map(duplicateField('projectId', 'projectLinkId')),
    setDefaultProjectManager,
    R.pathOr([], [...projectsPath, 'hits']),
  )(data);

const useMapPropsForQuery = (tableConfig, column = 8) => {
  useSetInitialPaginationOnMount();

  const { sort: tableSort, ...tableSortingProps } = useTableSorting({
    column,
    tableConfig,
    order: DESC_SORT_ORDER,
  });

  const { sort } = useSortingByReduxSearch({ sort: tableSort });

  return {
    sort,
    tableSortingProps,
  };
};

const useCommonTableLogic = ({
  result,
  tableConfig,
  projectsPath = ['searchProjects'],
}) => {
  useSetItemsCount(R.propOr(0, 'total'), R.path(projectsPath, result));

  const projects = getSearchProjects(projectsPath, result);

  const { rows, ...restTableProps } = useMapConfigToTableProps(
    R.identity,
    tableConfig,
    projects,
  );

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    [
      ['projectId'],
      ['description'],
      ['property', 'name'],
      ['client', 'name'],
      ['manager', 'fullName'],
    ],
    rows,
  );

  return {
    projects,
    paginationVisible: true,
    rows: highlightedRows,
    ...restTableProps,
  };
};

export const commonProjectTableConfig = [
  ['', ProjectStatusDot],
  projectIdColumn,
  projectDescriptionColumn,
  propertyEntityColumnWithSort,
  projectClientColumn,
  projectManagerColumn,
  projectStatusColumn,
  projectStartDateColumn,
  projectEndDateColumn,
];

export const projectsWithPriorityTableConfig = R.insert(
  1,
  projectPriorityColumn,
  commonProjectTableConfig,
);

// getTableComponent :: Boolean -> ReactComponent
const getTableComponent = (isRecurringSearch) =>
  isRecurringSearch
    ? RecurringProjectsPageProjectsTableComp
    : ProjectsPageProjectsTableComp;

function ProjectsTableCompWithLogic({ isRecurringSearch, ...props }) {
  useSetSearchEntityParams(props);

  const TableComponent = getTableComponent(isRecurringSearch);

  return <TableComponent {...props} />;
}

ProjectsTableCompWithLogic.propTypes = { isRecurringSearch: bool };

export function ProjectsPageCommonTable({
  isPrint,
  projects,
  isRecurringSearch,
  ...props
}) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    isRecurringSearch
      ? commonProjectTableConfig
      : projectsWithPriorityTableConfig,
    projects,
  );

  const PrintTableComponentByRecurring = getTableComponent(isRecurringSearch);

  const TableComp = isPrint
    ? PrintTableComponentByRecurring
    : ProjectsTableCompWithLogic;

  return (
    <TableComp
      {...tableProps}
      {...props}
      isRecurringSearch={isRecurringSearch}
    />
  );
}

ProjectsPageCommonTable.propTypes = {
  isPrint: bool,
  isRecurringSearch: bool,
  projects: arrayOf(shape({ _id: string.isRequired })),
};

export function ProjectsPageTable({ query }) {
  const { sort, tableSortingProps } = useMapPropsForQuery(
    projectsWithPriorityTableConfig,
    2,
  );

  const { loading, result } = useProjectsBySearch({ sort, query });

  const commonTableProps = useCommonTableLogic({
    result,
    tableConfig: projectsWithPriorityTableConfig,
  });

  if (loading) return <Loader />;

  return (
    <ProjectsPageCommonTable
      {...result}
      {...tableSortingProps}
      {...commonTableProps}
    />
  );
}

ProjectsPageTable.propTypes = tablePropTypes;

export const useSearchRecurringProjectsByQuery = (query) => {
  const { sort, tableSortingProps } = useMapPropsForQuery(
    commonProjectTableConfig,
  );

  const { loading, result } = useRecurringProjectsBySearch({
    sort,
    query,
  });

  const commonTableProps = useCommonTableLogic({
    result,
    tableConfig: commonProjectTableConfig,
    projectsPath: ['searchRecurringProjects'],
  });

  return { result, loading, tableSortingProps, commonTableProps };
};

export function RecurringProjectsPageTable({ query }) {
  const { result, loading, tableSortingProps, commonTableProps } =
    useSearchRecurringProjectsByQuery(query);

  if (loading) return <Loader />;

  return (
    <RecurringProjectsPageProjectsTableComp
      {...result}
      {...tableSortingProps}
      {...commonTableProps}
    />
  );
}

RecurringProjectsPageTable.propTypes = tablePropTypes;

export function MyProjectsPageTable({ query }) {
  const { sort, tableSortingProps } = useMapPropsForQuery(
    commonProjectTableConfig,
  );

  const { loading, result } = useMyProjectsBySearch({ sort, query });

  const commonTableProps = useCommonTableLogic({
    result,
    tableConfig: commonProjectTableConfig,
    projectsPath: ['me', 'searchManagerProjects'],
  });

  if (loading) return <Loader />;

  return (
    <ProjectsPageProjectsTableComp
      {...result}
      {...tableSortingProps}
      {...commonTableProps}
    />
  );
}

MyProjectsPageTable.propTypes = tablePropTypes;

export function SupplierRecurringProjectsTable({ query }) {
  const { sort, tableSortingProps } = useMapPropsForQuery(
    commonProjectTableConfig,
  );

  const { loading, result } = useSupplierRecurringProjectsBySearch({
    sort,
    query,
  });

  const commonTableProps = useCommonTableLogic({
    result,
    tableConfig: commonProjectTableConfig,
    projectsPath: ['supplier', 'searchRecurringProjects'],
  });

  if (loading) return <Loader />;

  return (
    <RecurringProjectsPageProjectsTableComp
      {...result}
      {...tableSortingProps}
      {...commonTableProps}
    />
  );
}

SupplierRecurringProjectsTable.propTypes = { query: shape({}) };
