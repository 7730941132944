import styled from 'styled-components';
import { TableWrapper } from '../commonTimeSheetTableComps.js';

export const TimeSheetReportTableComp = styled(TableWrapper)`
  td:nth-child(1),
  th:nth-child(1),
  th:nth-child(2) {
    width: 150px;
  }

  th:nth-child(3) {
    width: 120px;
  }

  th:nth-child(5) {
    width: 100px;
  }

  th:nth-child(6) {
    width: 75px;
  }

  th:nth-child(7),
  th:nth-child(8) {
    width: 40px;
  }

  td:nth-child(2) {
    padding: 0;
  }

  table {
    td:nth-child(1) {
      width: 150px;
    }

    td:nth-child(2) {
      width: 120px;
    }

    td:nth-child(4) {
      width: 100px;
    }

    td:nth-child(5) {
      width: 75px;
    }

    td:nth-child(6),
    td:nth-child(7) {
      width: 40px;
    }

    border-collapse: collapse;
  }

  thead,
  tbody {
    overflow-y: auto;
  }
`;
