import { string, shape, arrayOf, number } from 'prop-types';

const searchSupplierInvoicePropType = shape({
  _id: string.isRequired,
  invoiceNumber: string,
  invoiceFile: shape({ url: string.isRequired }),
  project: shape({ projectId: string.isRequired }),
  invoiceDate: string.isRequired,
  status: string.isRequired,
  total: number.isRequired,
  paidBy: shape({ fullName: string.isRequired }),
  paidAt: string,
});

export const searchSupplierInvoicesDataPropType = shape({
  searchInvoices: shape({
    hits: arrayOf(searchSupplierInvoicePropType).isRequired,
    total: number.isRequired,
  }),
});
