import * as R from 'ramda';
import { validateFormData } from '@poly/client-utils';
import { isNilOrEmpty } from '@poly/utils';

// prepareProjectTimeEntryBeforeUpdate :: FormData -> ProjectTimeEntry
export const prepareProjectTimeEntryBeforeUpdate = R.converge(R.mergeRight, [
  R.omit(['project', 'isManual', 'clientStaffRate']),
  R.applySpec({
    rateDescription: R.pathOr('', ['clientStaffRate', 'description']),
  }),
]);

const validationConfig = [
  {
    path: ['ownerId'],
    validators: [[R.identity, 'ESFM Staff is required']],
  },
  {
    path: ['clientStaffRate'],
    validators: [[R.complement(isNilOrEmpty), 'Description is required']],
  },
  {
    path: ['totalTimeInMinutes'],
    validators: [[R.identity, 'Total Time is required']],
  },
];

// validateProjectTime :: FormData -> ErrorsObject
export const validateProjectTime = validateFormData(validationConfig);
