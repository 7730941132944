import * as R from 'ramda';
import {
  useRouterQuery,
  useUpdateQueryParams,
  useSetQueryParams,
} from '@poly/client-routing';
import { useState } from 'react';
import { propEqLegacy } from '@poly/utils';

// valueExtractor :: Any -> Any
const valueExtractor = R.compose(
  // to remove empty strings from url
  R.when(R.isEmpty, R.always(undefined)),
  R.ifElse(
    R.hasPath(['target', 'value']),
    R.path(['target', 'value']),
    R.identity,
  ),
);

// prepareParamsToURLQuery :: Object -> Object
const prepareParamsToURLQuery = R.map(
  R.when(R.is(Date), (date) => date.toDateString()),
);

// getFilterNames :: [Object] -> [String]
const getFilterNames = R.reduce(
  (acc, filterConfig) =>
    filterConfig.nestedFields
      ? [...acc, ...filterConfig.nestedFields]
      : [...acc, filterConfig.name],
  [],
);

// valueNotEntered :: Any -> Boolean
const valueNotEntered = R.either(R.isNil, R.both(R.is(Object), R.isEmpty));

// runFilterSubscribers :: (Any, Object, [FilterSubscriber]) -> Object
// FilterSubscriber = Pair String Function
const runFilterSubscribers = (filterValue, values, subscribers) =>
  subscribers.reduce(
    (acc, [filterName, subscribeFunction]) => ({
      ...acc,
      [filterName]: subscribeFunction(filterValue, values[filterName]),
    }),
    {},
  );

// getInitialFiltersValues :: FilterValues -> [FilterConfig] -> FilterValues
//   FilterConfig = {name: String, preventReset: Bool, preventQueryClear: Bool}
//   FilterValues = Object
const getInitialFiltersValues = (filterValues) =>
  R.converge(R.mergeRight, [
    R.compose(
      R.pick(R.__, filterValues),
      R.map(R.prop('name')),
      R.filter(propEqLegacy('preventReset', true)),
    ),
    R.compose(
      R.fromPairs,
      R.map(R.juxt([R.prop('name'), R.prop('defaultValue')])),
      R.filter(propEqLegacy('preventQueryClear', true)),
    ),
  ]);

// prepareNestedFieldsFilter :: { name: String, nestedFields: [String] } -> Object -> Object
const prepareNestedFieldsFilter = (filterConfig) =>
  R.ifElse(
    R.has(filterConfig.nestedFields[0]),
    R.pick(filterConfig.nestedFields),
    R.compose(
      R.when(R.is(Object), R.pick(filterConfig.nestedFields)),
      R.prop(filterConfig.name),
    ),
  );

export const useSearchFiltersLogic = ({
  filtersValues,
  updateFiltersValues,
  setFiltersValues,
  filtersConfig,
  omitDateToString,
}) => {
  const onChangeFilter = (filterConfig) => (e) => {
    const value = valueExtractor(e);
    const filterValue = filterConfig.nestedFields
      ? R.pick(filterConfig.nestedFields, value)
      : { [filterConfig.name]: value };

    const filterWithSubscribersValues = filterConfig.subscribers
      ? {
          ...filterValue,
          ...runFilterSubscribers(
            value,
            filtersValues,
            filterConfig.subscribers,
          ),
        }
      : filterValue;

    // to show shorter string in url query for dates
    const preparedParams = omitDateToString
      ? filterWithSubscribersValues
      : prepareParamsToURLQuery(filterWithSubscribersValues);
    updateFiltersValues(preparedParams);
  };

  const config = filtersConfig.reduce(
    ({ values, handlers }, filterConfig) => {
      const value = filterConfig.nestedFields
        ? prepareNestedFieldsFilter(filterConfig)(filtersValues)
        : filtersValues[filterConfig.name];

      return {
        values: {
          ...values,
          [filterConfig.name]: valueNotEntered(value)
            ? filterConfig.defaultValue
            : value,
        },
        handlers: {
          ...handlers,
          [filterConfig.name]: onChangeFilter(filterConfig),
        },
      };
    },
    { values: {}, handlers: {} },
  );

  const onReset = () => {
    const initialValues = getInitialFiltersValues(config.values)(filtersConfig);
    setFiltersValues(initialValues);
  };

  return {
    searchFilters: config.values,
    handlers: config.handlers,
    updateFiltersValues,
    onReset,
  };
};

export const useSearchFilters = (filtersConfig, omitDateToString) => {
  const filtersValues = useRouterQuery(getFilterNames(filtersConfig));
  const updateFiltersValues = useUpdateQueryParams();
  const setFiltersValues = useSetQueryParams();

  return useSearchFiltersLogic({
    filtersValues,
    updateFiltersValues,
    setFiltersValues,
    filtersConfig,
    omitDateToString,
  });
};

export const useLocalSearchFilters = (
  filtersConfig,
  initialFilter,
  omitDateToString,
) => {
  const [filtersValues, setFiltersValues] = useState(initialFilter || {});
  const updateFiltersValues = (newValues) =>
    setFiltersValues((oldValues) => ({ ...oldValues, ...newValues }));

  return useSearchFiltersLogic({
    filtersValues,
    updateFiltersValues,
    setFiltersValues,
    filtersConfig,
    omitDateToString,
  });
};
