import React from 'react';
import {
  PageHeaderContainer,
  MainHeader,
  HeaderLinksWrapper,
} from '@poly/admin-book';
import { SupplierStatuses } from '@poly/constants';
import { TableSearchInput } from '@poly/admin-ui';

import { SuppliersTab } from '../modules/tabs/suppliersTab/SuppliersTab.js';
import { SuppliersTabTitle } from '../modules/tabs/suppliersTab/SuppliersTabTitle.js';
import { AddNewSupplierBtn } from '../modules/tabs/suppliersTab/SuppliersTabToolbar.js';
import { getSubcontractorSuppliersQuery } from '../modules/core/searchQueries/suppliers.js';
import { PageTabs } from '../modules/tabs/TabsWithRouter.js';

const { ACTIVE, BLOCKED } = SupplierStatuses;

export function SuppliersSubcontractorPage() {
  const activeSuppliersQuery = getSubcontractorSuppliersQuery(ACTIVE);
  const blockedSuppliersQuery = getSubcontractorSuppliersQuery(BLOCKED);

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Subcontractor Suppliers</MainHeader>
        <HeaderLinksWrapper>
          <AddNewSupplierBtn />
        </HeaderLinksWrapper>
      </PageHeaderContainer>
      <PageTabs
        toolBar={<TableSearchInput />}
        tabs={[
          [
            <SuppliersTabTitle
              query={activeSuppliersQuery}
              status={SupplierStatuses.ACTIVE}
              key={ACTIVE}
            />,
            ACTIVE,
            <SuppliersTab
              query={activeSuppliersQuery}
              showDateColumn
              key={ACTIVE}
            />,
          ],
          [
            <SuppliersTabTitle
              status={SupplierStatuses.BLOCKED}
              query={blockedSuppliersQuery}
              key={BLOCKED}
            />,
            BLOCKED,
            <SuppliersTab query={blockedSuppliersQuery} key={BLOCKED} />,
          ],
        ]}
        defaultValue={ACTIVE}
      />
    </>
  );
}
