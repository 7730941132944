import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useTableInfiniteScrollQuery } from '@poly/client-utils';
import { AccountingPeriodTypes } from '@poly/constants';
import { useMemo } from 'react';

const accountingPeriodsUpdatesQuery = gql`
  query accountingPeriodsUpdatesQuery($input: AccountingPeriodsUpdatesInput!) {
    accountingPeriodsUpdates(input: $input) {
      hits {
        _id
        title
        message
        createdAt
        createdBy {
          _id
          fullName
        }
      }
      total
    }
  }
`;

export const useAccountingPeriodsUpdates = () => {
  const input = useMemo(
    () => ({
      type: AccountingPeriodTypes.MONTH,
      sort: { createdAt: -1 },
    }),
    [],
  );

  const { data, loading, tableProps } = useTableInfiniteScrollQuery(
    accountingPeriodsUpdatesQuery,
    input,
    {
      pageSize: 50,
      endpointName: 'accountingPeriodsUpdates',
      inputName: 'input',
    },
  );

  return {
    loading,
    tableProps,
    updates: R.pathOr([], ['accountingPeriodsUpdates', 'hits'], data),
  };
};
