import { gql } from '@apollo/client';

import { commonInvoiceFields } from './fragments.js';

export const INVOICES_BY_SEARCH = gql`
  query INVOICES_BY_SEARCH($searchInput: CollectionSearchParams!) {
    searchInvoices(input: $searchInput) {
      hits {
        ...commonInvoiceFields
        isOwnSupplierInvoice
        paymentDueDate
        paymentDueDateChangeReason
        rejectionReason
        project {
          _id
          type
          projectId
        }
        supplier {
          _id
          company {
            name
          }
        }
        paidAt
        paidBy {
          _id
          fullName
        }
        supplierInvoiceTask {
          _id
          multiInvoiceNote
        }
      }
      total
    }
  }

  ${commonInvoiceFields}
`;
