import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { gql } from '@apollo/client';
import { func } from 'prop-types';
import { useSelector } from 'react-redux';
import {
  isSearchTextMatchedAtPaths,
  useReactiveQuery,
} from '@poly/client-utils';
import {
  useModalContext,
  TableSearchInput,
  useMapConfigToTablePropsWithSorting,
} from '@poly/admin-ui';
import {
  WindowedTable,
  Loader,
  IconButton,
  getThemeColor,
} from '@poly/admin-book';
import { NOTHING_UI_STRING } from '@poly/constants';
import { centsToDollarsWithFormat } from '@poly/utils/src/converters.js';

import { AssetTypesForm } from './AssetTypesForm.js';
import { editAssetTypesFormId } from './constants.js';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 100%;
`;

const WindowedTableS = styled(WindowedTable)`
  th:nth-child(4),
  td:nth-child(4) {
    width: 40px;
  }
  thead {
    background-color: ${getThemeColor(['lightest'])};
  }
`;

const ASSET_TYPES_QUERY = gql`
  query ASSET_TYPES_QUERY {
    assetTypes {
      _id
      name
      lifeExpectancy
      replacementCost
    }
  }
`;

const ASSET_TYPES_CHANGED_SUBSCRIPTION = gql`
  subscription ASSET_TYPES_CHANGED_SUBSCRIPTION(
    $input: CollectionSearchParams
  ) {
    searchAssetTypeChanged(input: $input) {
      id
      type
    }
  }
`;

function EditButton({ refetch, ...props }) {
  const { openModalForm } = useModalContext();
  const onClick = () =>
    openModalForm({
      id: editAssetTypesFormId,
      title: 'Edit Asset Type',
      formId: editAssetTypesFormId,
      btnCaption: 'Save',
      content: (
        <AssetTypesForm isNewType={false} assetType={props} refetch={refetch} />
      ),
    });

  return <IconButton size={15} name="edit" onClick={onClick} />;
}

EditButton.propTypes = {
  refetch: func.isRequired,
};

const assetTypesTableConfig = [
  ['Name', R.prop('name'), R.prop('name')],
  [
    'Life Expectancy',
    R.propOr(NOTHING_UI_STRING, 'lifeExpectancy'),
    R.prop('lifeExpectancy'),
  ],
  [
    'Asset Replacement Cost',
    R.compose(centsToDollarsWithFormat, R.prop('replacementCost')),
  ],
  ['', EditButton],
];

const searchTextPaths = [['name']];

// prepareDataForTableProps :: (String, () => Promise _ _) -> { assetTypes: [AssetType] } -> [AssetType]
const prepareDataForTableProps = (searchText, refetch) =>
  R.compose(
    R.map(R.assoc('refetch', refetch)),
    R.when(
      R.always(searchText),
      R.filter(isSearchTextMatchedAtPaths(searchTextPaths, searchText)),
    ),
    R.propOr([], 'assetTypes'),
  );

export function AssetTypesPageTable() {
  const searchText = useSelector((state) => state.searchText);

  const { data, loading, refetch } = useReactiveQuery(
    ASSET_TYPES_QUERY,
    ASSET_TYPES_CHANGED_SUBSCRIPTION,
    { queryOptions: {}, subscriptionOptions: {} },
  );

  const tableProps = useMapConfigToTablePropsWithSorting({
    items: prepareDataForTableProps(searchText, refetch)(data),
    tableConfig: assetTypesTableConfig,
    initialSorting: {
      columnKey: 0,
      dir: 1,
    },
  });

  return loading ? (
    <Loader />
  ) : (
    <Container>
      <TableSearchInput />
      <WindowedTableS {...tableProps} isLoading={loading} showScrollBar />
    </Container>
  );
}
