import * as R from 'ramda';
import React from 'react';
import { func, string } from 'prop-types';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import { gql, useMutation } from '@apollo/client';
import { FormField, Input } from '@poly/admin-book';
import { CLIENT_DISTRICT_NAME_MAX_LENGTH } from '@poly/constants';
import { useModalContext, useNotificationState } from '@poly/admin-ui';

const FormWrapper = styled.div`
  margin-bottom: 25px;
`;

// validateDivisionName :: String -> String Boolean
const validateDivisionName = R.ifElse(
  R.compose(R.lt(CLIENT_DISTRICT_NAME_MAX_LENGTH), R.length),
  R.always(`Maximum length is ${CLIENT_DISTRICT_NAME_MAX_LENGTH} characters`),
  R.F,
);

function ClientDistrictForm({ handleSubmit, formId }) {
  return (
    <FormWrapper>
      <form onSubmit={handleSubmit} id={formId}>
        <FormField
          name="name"
          labelId="name"
          Component={Input}
          validate={validateDivisionName}
          additionalProps={{ label: 'District name', required: true }}
        />
      </form>
    </FormWrapper>
  );
}

ClientDistrictForm.propTypes = {
  handleSubmit: func.isRequired,
  formId: string.isRequired,
};

export const createClientDistrictFormId = 'createDistrictFormId';

// eslint-disable-next-line import/no-unused-modules
export const CREATE_CLIENT_DISTRICT = gql`
  mutation CREATE_CLIENT_DISTRICT($clientId: ID!, $input: DistrictInput!) {
    createClientDistrict(clientId: $clientId, input: $input) {
      _id
    }
  }
`;

// eslint-disable-next-line import/no-unused-modules
export const UPDATE_CLIENT_DISTRICT = gql`
  mutation UPDATE_CLIENT_DISTRICT(
    $clientId: ID!
    $districtId: ID!
    $update: DistrictInput!
  ) {
    updateClientDistrict(
      clientId: $clientId
      districtId: $districtId
      update: $update
    ) {
      _id
    }
  }
`;

export function CreateClientDistrictForm({ clientId }) {
  const [createClientDistrict] = useMutation(CREATE_CLIENT_DISTRICT);

  const { showSuccessNotification } = useNotificationState();

  const { closeModal } = useModalContext();

  const handleCreate = async (input) => {
    await createClientDistrict({
      variables: {
        clientId,
        input,
      },
    });

    showSuccessNotification('Client district successfully added');
    closeModal(createClientDistrictFormId);
  };

  return (
    <Form
      initialValues={{ name: '' }}
      render={ClientDistrictForm}
      onSubmit={handleCreate}
      formId={createClientDistrictFormId}
    />
  );
}

CreateClientDistrictForm.propTypes = {
  clientId: string.isRequired,
};

export const editClientDistrictFormId = 'editDistrictFormId';

export function EditClientDistrictForm({ clientId, districtId, districtName }) {
  const [updateClientDistrict] = useMutation(UPDATE_CLIENT_DISTRICT);

  const { showSuccessNotification } = useNotificationState();

  const { closeModal } = useModalContext();

  const handleUpdate = async (update) => {
    await updateClientDistrict({
      variables: {
        clientId,
        districtId,
        update,
      },
    });

    showSuccessNotification('Client district successfully edited');
    closeModal(editClientDistrictFormId);
  };

  return (
    <Form
      initialValues={{ name: districtName }}
      render={ClientDistrictForm}
      onSubmit={handleUpdate}
      formId={editClientDistrictFormId}
    />
  );
}

EditClientDistrictForm.propTypes = {
  districtId: string.isRequired,
  clientId: string.isRequired,
  districtName: string.isRequired,
};
