import { gql } from '@apollo/client';

export const SERVICE_TYPES_BY_SEARCH_SUB = gql`
  subscription SERVICE_TYPES_BY_SEARCH_SUB(
    $searchInput: CollectionSearchParams!
  ) {
    searchServiceTypeChanged(input: $searchInput) {
      id
      type
    }
  }
`;
