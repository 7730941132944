// getTransactionUserFriendlyName :: Transaction -> String
import * as R from 'ramda';
import { JournalTypes, NOTHING_UI_STRING } from '@poly/constants';
import { propEqLegacy } from '@poly/utils';

export const getTransactionUserFriendlyName = R.cond([
  [
    propEqLegacy('type', JournalTypes.ACC_PAYABLE),
    R.always('Supplier Invoice'),
  ],
  [
    propEqLegacy('type', JournalTypes.ACC_RECEIVABLE),
    R.always('Client Invoice'),
  ],
  [R.T, R.always('Payment')],
]);

// getTransactionUserFriendlyNumber :: Transaction -> String
export const getTransactionUserFriendlyNumber = R.cond([
  [
    propEqLegacy('type', JournalTypes.ACC_PAYABLE),
    R.path(['invoice', 'invoiceNumber']),
  ],
  [
    propEqLegacy('type', JournalTypes.ACC_PAYABLE_PAYMENT),
    R.pathOr(NOTHING_UI_STRING, ['aacCheck', 'checkNumber']),
  ],
  [R.T, R.always(NOTHING_UI_STRING)],
]);

// getTransactionUserFriendlyType :: Transaction -> String
export const getTransactionUserFriendlyType = R.cond([
  [
    propEqLegacy('type', JournalTypes.ACC_RECEIVABLE),
    R.path(['invoice', 'number']),
  ],
  [
    propEqLegacy('type', JournalTypes.ACC_RECEIVABLE_PAYMENT),
    R.prop('accountName'),
  ],
  [
    R.path(['aacCheck', 'checkNumber']),
    R.compose(R.concat('Ck / '), R.prop('accountName')),
  ],
  [
    R.both(
      propEqLegacy('type', JournalTypes.ACC_PAYABLE_PAYMENT),
      R.pathSatisfies(R.isNil, ['aacCheck', 'checkNumber']),
    ),
    R.compose(R.concat('CC / '), R.prop('accountName')),
  ],
  [R.T, R.always(NOTHING_UI_STRING)],
]);

// getTransactionInvoiceNumber :: Transaction -> String
export const getTransactionInvoiceNumber = R.ifElse(
  R.either(
    propEqLegacy('type', JournalTypes.ACC_RECEIVABLE),
    propEqLegacy('type', JournalTypes.ACC_RECEIVABLE_PAYMENT),
  ),
  R.path(['invoice', 'number']),
  R.path(['invoice', 'invoiceNumber']),
);
