import { useQuery } from '@apollo/client';

import { useMultipleSubscriptions } from './useMultipleSubscriptions.js';
import { useDebounceRefetchByOptions } from './useDebounceRefetchByOptions.js';

export const useEntityMultipleSubscription = (
  query,
  subscriptions,
  { queryOptions, getSubscriptionOptions },
) => {
  const { data, loading, error, refetch } = useQuery(query, queryOptions);

  const subscriptionOptions = getSubscriptionOptions.map((getOptionsFn) =>
    getOptionsFn(data),
  );

  const debouncedRefetch = useDebounceRefetchByOptions({
    refetch,
    queryOptions,
    subscriptionOptions,
    refetchDebounce: 200,
  });

  useMultipleSubscriptions(subscriptions, subscriptionOptions, {
    debouncedRefetch,
    data,
  });

  return { data, loading, refetch, error };
};
