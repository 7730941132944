import { useState } from 'react';

import { graphqlForgotPasswordRoute } from '@poly/utils';

const { GRAPHQL_HTTP_URL = '' } = process.env;

export const commonFetchHeaders = {
  'Content-Type': 'application/json',
  'poly-client-version': process.env.APP_VERSION,
  'browser-location': window.location.host + window.location.pathname,
};

export const useForgotPasswordLogic = (onSuccess) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = async (email) => {
    setLoading(true);

    try {
      const res = await fetch(graphqlForgotPasswordRoute(GRAPHQL_HTTP_URL), {
        method: 'POST',
        headers: commonFetchHeaders,
        body: JSON.stringify({
          email,
        }),
      });
      const resJson = await res.json();
      if (res.ok) {
        return onSuccess(resJson);
      }
      setError(resJson.message);
    } catch (err) {
      setError(err.message);
    }
    return setLoading(false);
  };

  return { loading, error, onSubmit };
};
