import * as R from 'ramda';
import React from 'react';
import { number, shape, bool } from 'prop-types';
import { LinkButtonWithLoader } from '@poly/admin-book';
import {
  ExcelExportCellType,
  createExcelExportCell,
  performExcelExport,
} from '@poly/client-utils';
import { EXPORT_XLS_CAPTION } from '@poly/constants';
import {
  applyProp,
  convertCentsToDollars,
  formatDate,
  forceTitleCase,
} from '@poly/utils';
import {
  calculateWIPQCReportTotalsByInvoices,
  getMarkupAmount,
  getSupplierInvoiceAmount,
  getWIPQCTotal,
  sortTableRowsByConfig,
} from './helpers.js';
import {
  wipQCTableSortQueries,
  wipQCRecordsPropTypes,
} from './WorkInProgressQCTable.js';

// getWIPReportRowConfig :: Invoice -> [ExcelExportDataCell]
const getWIPReportRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 5),
    ...R.repeat(ExcelExportCellType.defaultCurrency, 3),
    ExcelExportCellType.default,
  ]),
  R.juxt([
    R.path(['project', 'projectId']),
    R.path(['client', 'name']),
    R.compose(
      R.join(', '),
      R.map(R.pathOr('', ['company', 'name'])),
      R.propOr([], 'suppliers'),
    ),
    applyProp(forceTitleCase)('projectAccountingStatus'),
    R.compose(formatDate, R.path(['project', 'endDate'])),
    R.compose(convertCentsToDollars, getSupplierInvoiceAmount),
    getMarkupAmount,
    getWIPQCTotal,
    R.path(['manager', 'fullName']),
  ]),
);

// getTotalRowConfig :: [Invoice] -> [ExcelExportDataCell]
const getTotalRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.tableStringFooter, 5),
    ...R.repeat(ExcelExportCellType.tableCurrencyFooter, 3),
    ExcelExportCellType.tableStringFooter,
  ]),
  R.unnest,
  R.juxt([
    R.always(R.repeat('', 4)),
    R.always('Total'),
    R.prop('supplierInvoiceTotal'),
    R.prop('markup'),
    R.prop('total'),
    R.always(''),
  ]),
  calculateWIPQCReportTotalsByInvoices,
);

// eslint-disable-next-line import/no-unused-modules
export const getWorkInProgressQCExportExcelConfig = ({ title, invoices }) => ({
  exportFileName: 'wip-qc-report.xlsx',
  columnWidths: [15, 20, 35, 15, 10, 15, 15, 15, 20],
  rows: [
    [
      {
        cellType: ExcelExportCellType.title,
        value: title,
      },
    ],
    R.zipWith(
      createExcelExportCell,
      [
        ...R.repeat(ExcelExportCellType.tableHeader, 5),
        ...R.repeat(ExcelExportCellType.tableCurrencyHeader, 3),
        ExcelExportCellType.tableHeader,
      ],
      [
        'Project #',
        'Client',
        'Supplier',
        'Acct Status',
        'End Date',
        'Supplier Inv',
        'Markup',
        'Total',
        'Assigned CSR',
      ],
    ),
    ...R.map(getWIPReportRowConfig, invoices),
    getTotalRowConfig(invoices),
  ],
});

const sortWipQCRecords = sortTableRowsByConfig(wipQCTableSortQueries);

export function WIPQCExportXLSBtn({ sorting, wipQCRecords, loading }) {
  const onExportClick = async () => {
    const wipRecordSorted = sortWipQCRecords(wipQCRecords, sorting);

    const excelConfig = getWorkInProgressQCExportExcelConfig({
      title: 'Work In Progress QC Report',
      invoices: wipRecordSorted,
    });
    performExcelExport(excelConfig);
  };

  return (
    <LinkButtonWithLoader loader={loading} onClick={onExportClick}>
      {EXPORT_XLS_CAPTION}
    </LinkButtonWithLoader>
  );
}

WIPQCExportXLSBtn.propTypes = {
  sorting: shape({
    columnKey: number.isRequired,
    dir: number.isRequired,
  }),
  wipQCRecords: wipQCRecordsPropTypes,
  loading: bool,
};
