import React from 'react';
import { DnDTextarea } from '@poly/admin-book';
import { useLoadMentionSuggestions } from '@poly/client-utils';

export function DnDAreaWithMentions(props) {
  const mentionsProps = useLoadMentionSuggestions(props);
  return <DnDTextarea {...props} {...mentionsProps} />;
}

DnDAreaWithMentions.displayName = 'DnDAreaWithMentions';
