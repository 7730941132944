import * as R from 'ramda';
import { ALLOWED_FILE_EXTENSIONS } from '@poly/constants';

const sizeMarks = ['B', 'kB', 'MB'];
const getSizeIndex = (size) => Math.floor(Math.log(size) / Math.log(1024));

// eslint-disable-next-line no-mixed-operators
const getIndexedSize = (size, index) => +(size / 1024 ** index).toFixed(2);

// fileSizeToHumanReadableFormat :: Number -> String
export const fileSizeToHumanReadableFormat = (size) => {
  const fileEmpty = size === 0;
  const sizeIndex = fileEmpty ? 0 : getSizeIndex(size);
  const indexedSize = fileEmpty ? 0 : getIndexedSize(size, sizeIndex);
  return `${indexedSize} ${sizeMarks[sizeIndex]}`;
};

// getExtensionByFileName :: String -> String
export const getExtensionByFileName = R.compose(
  R.ifElse(R.compose(R.gt(2), R.length), R.always(''), R.last),
  R.split('.'),
  R.defaultTo(''),
);

// isFileExtensionAllowed :: String -> Boolean
export const isFileExtensionAllowed = R.compose(
  R.includes(R.__, ALLOWED_FILE_EXTENSIONS),
  R.toLower,
);

// removeFileExtension :: String -> String
export const removeFileExtension = R.compose(
  R.ifElse(
    R.compose(R.equals(1), R.length),
    R.head,
    R.compose(R.join('.'), R.init),
  ),
  R.split('.'),
);
