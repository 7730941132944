import * as R from 'ramda';
import { useState, useEffect } from 'react';

export const useStateInputForInfiniteListQuery = (size, query) => {
  const [queryInput, setQueryInput] = useState({ size, query });

  useEffect(() => {
    if (!R.equals(queryInput, { size, query })) {
      setQueryInput({ size, query });
    }
  }, [query, size]);

  return queryInput;
};
