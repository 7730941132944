import React from 'react';
import { adminUserQuery, UserSelect } from '@poly/admin-ui';

export function ManagerSelect(props) {
  return (
    <UserSelect
      {...props}
      withoutSkip
      query={{ bool: { must: adminUserQuery } }}
    />
  );
}

ManagerSelect.defaultProps = {
  placeholder: 'Start typing managers',
};
