import React, { useState, useCallback, useEffect } from 'react';
import { Input } from '@poly/admin-book';
import styled from 'styled-components';
import { debounce } from '@poly/utils';
import { DEBOUNCE_USER_INPUT_THRESHOLD } from '@poly/client-utils';
import { bool, func, string } from 'prop-types';

const InputS = styled(Input)`
  width: 100%;
  height: 100%;
`;

export function SuppliersSearchInput({ value, onChange, disabled, ...props }) {
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    if (value === '') {
      setInternalValue(value);
    }
  }, [value]);

  const onChangeDebounced = useCallback(
    debounce(DEBOUNCE_USER_INPUT_THRESHOLD)((searchTerm) => {
      onChange(searchTerm);
    }),
    [],
  );

  const onChangeInternal = (e) => {
    setInternalValue(e.target.value);
    onChangeDebounced(e.target.value);
  };

  return (
    <InputS
      {...props}
      value={internalValue}
      disabled={disabled}
      onChange={onChangeInternal}
      name="suppliersSearch"
      placeholder="Search..."
    />
  );
}

SuppliersSearchInput.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
  disabled: bool.isRequired,
};
