import React from 'react';
import { useDispatch } from 'react-redux';
import { string, object } from 'prop-types';
import { formatDistance } from 'date-fns';
import { formatDateTime, ensureIsDate } from '@poly/utils';

import {
  FlexColumn,
  DescriptionText,
  HistoryItem,
  LinkWrapper,
  DeleteIcon,
  HistoryTime,
} from './historyComponents.js';
import { useGenerateHistoryRecordLink } from './useGenerateHistoryRecordLink.js';
import { setPopup } from '../../../redux/popupReducer.js';
import { useSidebarLogicContext } from '../../../sidebars/SidebarLogicContext.js';

export function HistoryRecord({
  entity,
  linkProps = {},
  description = '',
  id,
  createdAt,
}) {
  const RecordLink = useGenerateHistoryRecordLink(entity);
  const { removeHistoryRecord } = useSidebarLogicContext();
  const dispatch = useDispatch();

  return (
    <HistoryItem>
      <FlexColumn>
        <LinkWrapper>
          {`${entity.split(/(?=[A-Z])/).join(' ')}: `}
          <RecordLink {...linkProps} onClick={() => dispatch(setPopup(''))} />
        </LinkWrapper>
        <DescriptionText>{description}</DescriptionText>
      </FlexColumn>
      <FlexColumn>
        {createdAt && (
          <HistoryTime title={formatDateTime(createdAt)}>
            {formatDistance(ensureIsDate(new Date()), ensureIsDate(createdAt))}
            &nbsp;ago
          </HistoryTime>
        )}
        <DeleteIcon
          onClick={() => removeHistoryRecord(id)}
          data-testid={`delete-record-${id}`}
        />
      </FlexColumn>
    </HistoryItem>
  );
}

HistoryRecord.propTypes = {
  // eslint-disable-next-line
  linkProps: object,
  description: string,
  entity: string.isRequired,
  id: string.isRequired,
  createdAt: string,
};
