import * as R from 'ramda';
import { DEFAULT_PROPERTY_HIERARCHY_OPTION } from '@poly/constants';
import {
  removeBatchSpendReportPropertiesDuplicate,
  calculateTotal,
  isNilOrEmpty,
  assocBy,
} from '@poly/utils';
import { getPropertyInvoicesReportWithChildren } from './helpers.js';

// getListOfReportErrors :: PropertyInvoicesReport -> [String]
export const getListOfReportErrors = R.compose(
  R.reject(R.isNil),
  R.juxt([
    R.ifElse(
      R.path(['clientGLCode', 'code']),
      R.always(null),
      R.always('Please check that GLCode is missing'),
    ),
    R.ifElse(
      R.path(['approvedBy', 'fullName']),
      R.always(null),
      R.always('Please check that Approved By is missing'),
    ),
  ]),
);

// calculatePropertiesFieldTotal :: (BatchInvoiceSpendReportResult -> Number) -> BatchHierarchySpendReportResult-> Number
const calculatePropertiesFieldTotal = (getSubTotalFn) =>
  R.compose(
    R.sum,
    R.map(
      R.compose(
        calculateTotal(getSubTotalFn),
        getPropertyInvoicesReportWithChildren,
      ),
    ),
    R.propOr([], 'properties'),
  );

// preparePropertiesFieldErrors :: BatchHierarchySpendReportResult -> [String]
const preparePropertiesFieldErrors = R.compose(
  R.uniq,
  R.flatten,
  R.map(
    R.compose(
      R.map(getListOfReportErrors),
      getPropertyInvoicesReportWithChildren,
    ),
  ),
  R.propOr([], 'properties'),
);

// calculateTotalLevelByFields :: (String, String) -> BatchHierarchySpendReportResult -> Number
const calculateTotalLevelByFields = (filedName, childrenFieldName) =>
  R.compose(
    R.sum,
    R.juxt([
      R.compose(
        calculateTotal(R.propOr(0, childrenFieldName)),
        R.prop('children'),
      ),
      R.prop(filedName),
    ]),
  );

// concatErrorsTotal :: BatchHierarchySpendReportResult -> [String]
const concatErrorsTotal = R.converge(R.concat, [
  R.compose(
    R.uniq,
    R.reject(R.isNil),
    R.flatten,
    R.map(R.prop('totalLevelErrors')),
    R.prop('children'),
  ),
  R.prop('totalLevelErrors'),
]);

// calculateTotalsNodeLevelR :: BatchHierarchySpendReportResult -> BatchHierarchySpendReportResult
const calculateTotalsNodeLevelR = (node) =>
  R.unless(
    isNilOrEmpty,
    R.compose(
      assocBy(
        'totalLevel',
        calculateTotalLevelByFields('clientInvoicesSubtotal', 'totalLevel'),
      ),
      assocBy('totalLevelErrors', concatErrorsTotal),
      assocBy(
        'clientInvoicesSubtotal',
        calculatePropertiesFieldTotal(
          R.propOr(0, 'clientInvoiceAmountWithTax'),
        ),
      ),
      assocBy(
        'taxSubtotal',
        calculatePropertiesFieldTotal(R.propOr(0, 'taxAmount')),
      ),
      assocBy(
        'mgtFeeSubtotal',
        calculatePropertiesFieldTotal(R.propOr(0, 'managementFee')),
      ),
      assocBy(
        'invoicesSubtotal',
        calculatePropertiesFieldTotal(R.propOr(0, 'supplierInvoicesAmount')),
      ),
      assocBy('totalLevelErrors', preparePropertiesFieldErrors),
      assocBy(
        'children',
        R.compose(R.map(calculateTotalsNodeLevelR), R.propOr([], 'children')),
      ),
    ),
  )(node);

// prepareBatchSpendReport :: (ID, QueryData) -> [BatchHierarchySpendReportResult]
export const prepareBatchSpendReport = (hierarchyId, queryData) =>
  R.compose(
    R.map(calculateTotalsNodeLevelR),
    R.ifElse(
      R.compose(
        R.equals(DEFAULT_PROPERTY_HIERARCHY_OPTION),
        R.always(hierarchyId),
      ),
      R.map(
        R.over(R.lensProp('properties'), R.compose(R.uniq, R.defaultTo([]))),
      ),
      removeBatchSpendReportPropertiesDuplicate,
    ),
    R.propOr([], 'clientInvoiceBatchSpendReportPreview'),
  )(queryData);
