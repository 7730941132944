import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';

import {
  projectDetailsFields,
  recurringProjectDetailsFields,
} from '../../../../modules/core/hooks/projects/fragments.js';

const PROJECT_DETAILS = gql`
  query PROJECT_DETAILS($projectId: ID, $id: ID) {
    project(projectId: $projectId, id: $id) {
      ...projectDetailsFields
    }
  }

  ${projectDetailsFields}
`;

const RECURRING_PROJECT_DETAILS = gql`
  query RECURRING_PROJECT_DETAILS($projectId: ID, $id: ID) {
    recurringProject(projectId: $projectId, id: $id) {
      ...recurringProjectDetailsFields
    }
  }

  ${recurringProjectDetailsFields}
`;

// getClonedProject :: ProjectQueryResponse -> Project
const getClonedProject = (projectName) => R.prop(projectName);

export const useCloneFromProject = (
  cloneFromProjectId,
  cloneFromMasterProjectId,
) => {
  const { data: recurringProjectResponse, loading: masterProjectLoading } =
    useQuery(RECURRING_PROJECT_DETAILS, {
      variables: { id: cloneFromMasterProjectId },
      skip: !cloneFromMasterProjectId,
    });

  const { data: projectResponse, loading: projectLoading } = useQuery(
    PROJECT_DETAILS,
    {
      variables: { id: cloneFromProjectId },
      skip: !cloneFromProjectId,
    },
  );

  if (cloneFromMasterProjectId) {
    return {
      project: getClonedProject('recurringProject')(recurringProjectResponse),
      loading: masterProjectLoading,
    };
  }

  if (cloneFromProjectId) {
    return {
      project: getClonedProject('project')(projectResponse),
      loading: projectLoading,
    };
  }

  return {};
};
