import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { READ_BILLING_PROFILE_PERMISSION } from '@poly/security';
import { insertQueryParamsIntoURL } from '@poly/utils';
import { useHasUserAccessWithPermission } from '@poly/client-utils';

import { routesNames } from '../../routes/index.js';
import { defaultWarningMessage } from '../FinancialDirectory/constants.js';

const SEARCH_BILLING_PROFILES_QUERY = gql`
  query BILLING_PROFILE_LIST_QUERY {
    billingProfiles {
      _id
      name
      client {
        _id
        name
      }
      config {
        pos
        type
        nonPO
        costTypes
        propertyIds
        consolidateBy
        isTransparent
        serviceTypeIds
        costCenterNames
        attachBackupInvoices
        attachSupplierInvoices
        consolidation
        batch
      }
    }
  }
`;

// getBillingProfileConfig :: [BillingProfile] -> [Object]
export const getBillingProfileConfig = R.compose(
  R.values,
  R.map(
    R.applySpec({
      name: R.path(['0', 'client', 'name']),
      subLinks: R.map(
        R.applySpec({
          name: R.prop('name'),
          link: ({ _id, client }) =>
            insertQueryParamsIntoURL(
              {
                billingProfileId: _id,
                clientId: client._id,
              },
              routesNames.CLIENT_INVOICING_COLLECTION,
            ),
          permissions: R.always([READ_BILLING_PROFILE_PERMISSION]),
          warningMessage: R.always(defaultWarningMessage),
        }),
      ),
    }),
  ),
  R.collectBy(R.path(['client', '_id'])),
  R.propOr([], 'billingProfiles'),
);

export const useBillingProfileList = () => {
  const ifHasPermission = useHasUserAccessWithPermission(
    READ_BILLING_PROFILE_PERMISSION,
  );
  const { data, loading } = useQuery(SEARCH_BILLING_PROFILES_QUERY, {
    skip: !ifHasPermission,
  });

  return { billingProfileConfigs: getBillingProfileConfig(data), loading };
};
