import * as R from 'ramda';

import { convertCentsToDollars } from '../converters.js';

// isPropAnArray :: String -> Object -> Boolean
const isPropAnArray = (propName) =>
  R.both(
    R.propSatisfies(R.complement(R.isNil), propName),
    R.propSatisfies(R.is(Array), propName),
  );

// mergePropsToReportRecursive :: Object -> PropertySpendReportResult -> PropertySpendReportResult
const mergePropsToReportRecursive = (props) =>
  R.compose(
    R.when(
      isPropAnArray('childProperties'),
      R.over(
        R.lensProp('childProperties'),
        R.map((child) => mergePropsToReportRecursive(props)(child)),
      ),
    ),
    R.when(
      isPropAnArray('propertyInvoicesReport'),
      R.over(R.lensProp('propertyInvoicesReport'), R.map(R.mergeLeft(props))),
    ),
  );

// assocPropsForPropertyReportRecursive :: Object -> PropertyHierarchySpendReportResult
// -> PropertyHierarchySpendReportResult
export const assocPropsForPropertyReportRecursive = (props) =>
  R.when(
    isPropAnArray('properties'),
    R.over(R.lensProp('properties'), R.map(mergePropsToReportRecursive(props))),
  );

// getProjectSpendCostByConfig :: InvoiceSpendReportResult -> String
export const getProjectSpendCostByConfig = R.compose(
  convertCentsToDollars,
  R.converge(R.add, [
    R.ifElse(
      R.path(['spendCostConfig', 'includeSupplierInvoice']),
      R.propOr(0, 'total'),
      R.always(0),
    ),
    R.ifElse(
      R.both(
        R.propSatisfies(R.complement(R.equals(true)), 'skipClientInvoice'),
        R.path(['spendCostConfig', 'includeClientInvoice']),
      ),
      R.propOr(0, 'clientInvoicesAmount'),
      R.always(0),
    ),
  ]),
);
