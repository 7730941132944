import * as R from 'ramda';
import { getClientInvoiceTotalWithTax, propEqLegacy } from '@poly/utils';

import { calculateLinesTotal } from './helpers.js';

// calculateMarkupLinesTotal :: [ClientInvoiceLine] -> Number
const calculateMarkupLinesTotal = R.compose(
  calculateLinesTotal,
  R.reject(propEqLegacy('isNonMarkup', true)),
);

export const useClientInvoiceAmountsCalculation = (
  {
    lines = [],
    taxPercent = 0,
    taxRawAmount = 0,
    customTax = false,
    markupPercent = 0,
    suggestedAmount = 0,
    freightRawAmount = 0,
  },
  state,
) => {
  const linesTotal = calculateLinesTotal(lines);
  const markupLinesTotal = calculateMarkupLinesTotal(lines);
  const markupAmount = Math.round((markupLinesTotal * markupPercent) / 100);
  const linesSubTotal = markupAmount + linesTotal;
  const subTotal = linesSubTotal === 0 ? suggestedAmount : linesSubTotal;

  const { taxAmount, amount } = getClientInvoiceTotalWithTax({
    markupAmount,
    amount: subTotal,
    taxAccount: { state },
    ...(customTax ? { taxRawAmount, freightRawAmount } : { taxPercent }),
  });

  return {
    amount,
    subTotal,
    taxAmount,
    linesTotal,
    markupAmount,
  };
};
