import React from 'react';
import { bool, func, arrayOf, string, oneOfType } from 'prop-types';

import {
  ToggleWithLabelWrapper,
  ToggleSwitchWrapper,
  ToggleSwitchLabel,
  ToggleSwitchInner,
  ToggleSwitchBlock,
  ToggleLabel,
} from './styles.js';

export function ToggleSwitch({
  textLabels,
  className,
  onChange,
  disabled,
  label,
  value,
  name,
  dataTestId,
}) {
  return (
    <ToggleWithLabelWrapper>
      <ToggleSwitchWrapper {...{ className }}>
        <input
          id={name}
          type="checkbox"
          checked={value}
          data-testid={dataTestId}
          style={{ display: 'none' }}
          {...{ name, value, disabled }}
          onChange={() => onChange(!value)}
        />
        <ToggleSwitchLabel htmlFor={name} checked={value}>
          <ToggleSwitchInner checked={value} {...{ textLabels, disabled }} />
          <ToggleSwitchBlock checked={value} {...{ disabled }} />
        </ToggleSwitchLabel>
      </ToggleSwitchWrapper>
      {!!label && <ToggleLabel>{label}</ToggleLabel>}
    </ToggleWithLabelWrapper>
  );
}

ToggleSwitch.propTypes = {
  value: oneOfType([bool, string]),
  textLabels: arrayOf(string),
  onChange: func.isRequired,
  name: string.isRequired,
  className: string,
  disabled: bool,
  label: string,
  dataTestId: string,
};

ToggleSwitch.defaultProps = {
  textLabels: ['On', 'Off'],
  disabled: false,
  className: '',
  value: false,
  dataTestId: 'toggle-switch',
};
