import { USER_SEARCH_CHANGED, usePagination } from '@poly/admin-ui';
import { useEntitiesByReactiveReduxSearch } from '@poly/client-utils';

import { STAFF_USERS_BY_SEARCH_TABLE } from './queries.js';

export const useStaffUsersBySearchTable = ({
  query = null,
  skipQuery = false,
  sort = {},
  propsOfComponent,
  fetchPolicy = 'network-only',
  alias = 'useStaffUsersBySearchTable',
}) => {
  const { pagination } = usePagination();

  const { loading, refetch, result } = useEntitiesByReactiveReduxSearch({
    gqlSearchQuery: STAFF_USERS_BY_SEARCH_TABLE,
    gqlSearchChangedQuery: USER_SEARCH_CHANGED,
    query,
    sort,
    pagination,
    skipQuery,
    propsOfComponent,
    fetchPolicy,
    alias,
  });

  return {
    loading,
    refetch,
    result,
  };
};
