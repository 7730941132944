import React from 'react';
import * as R from 'ramda';
import arrayMutators from 'final-form-arrays';
import { shape, string, func, bool } from 'prop-types';
import { usePersistentFormValue } from '@poly/client-utils';
import { usePristineSubscribe } from '@poly/client-routing';
import { FormCreator } from '@poly/admin-book';
import {
  commonSidebarFormSectionLayout,
  useOnSubmitSetStopSubmitting,
  commonSidebarFormFieldLayout,
  commonSidebarFormLayout,
  useSaveContacts,
} from '@poly/admin-ui';

import { editProjectFormId } from '../../constants.js';
import { formTypeConstants } from '../form/utils/constants.js';
import { SidebarFormLayout } from '../../../components/SidebarFormLayout.js';
import { sideBarProjectFormSections } from '../form/sideBarProjectFormSections.js';
import { useOnSubmitEditProjectForm } from './useOnSubmitEditProjectForm.js';
import { projectToEditForm } from './gqlProjectToFormObject.js';

function EditProjectForm({ project, isCard }) {
  const {
    onChangePersistentValue,
    cleanupRetainedValue,
    retainedValue: invoiceDescription,
  } = usePersistentFormValue(`invoiceDescription.${project._id}`);

  const saveContacts = useSaveContacts();
  const pristineSubscribeProps = usePristineSubscribe({
    project,
    id: editProjectFormId,
  });

  const onSubmitHandler = useOnSubmitEditProjectForm(
    saveContacts,
    isCard,
    cleanupRetainedValue,
  );

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    editProjectFormId,
    onSubmitHandler,
  );

  const suppliers = R.propOr([], 'suppliers', project);

  const initialValues = {
    ...projectToEditForm(project),
    formType: formTypeConstants.EDIT,
    scheduleDate: project?.scheduleDate,
    ...(invoiceDescription ? { invoiceDescription } : {}),
  };

  const handleSubmit = (update) => onSubmit({ project, update });

  return (
    <FormCreator
      id={editProjectFormId}
      onSubmit={handleSubmit}
      mutators={arrayMutators}
      initialValues={initialValues}
      layout={commonSidebarFormLayout}
      fieldLayout={commonSidebarFormFieldLayout}
      sectionLayout={commonSidebarFormSectionLayout}
      sections={sideBarProjectFormSections(
        suppliers,
        true,
        onChangePersistentValue,
        false,
      )}
      initialValuesEqual={R.T}
      {...pristineSubscribeProps}
    />
  );
}

EditProjectForm.propTypes = {
  isCard: bool,
  project: shape({ _id: string.isRequired }).isRequired,
};

export function EditProjectSidebarForm({ onClose, ...props }) {
  return (
    <SidebarFormLayout
      onClose={onClose}
      title="Edit Project"
      formId={editProjectFormId}
      submitButtonCaption="Save"
    >
      <EditProjectForm {...props} />
    </SidebarFormLayout>
  );
}

EditProjectSidebarForm.propTypes = {
  onClose: func.isRequired,
};
