import * as R from 'ramda';
import { TOKEN_URL_SEPARATOR } from '@poly/constants';

// insertParamIntoURL :: String -> String -> String
export const insertParamIntoURL = R.curry((paramName, url, value) =>
  R.replace(`:${paramName}`, value, url),
);

// insertParams :: Object -> String -> String
export const insertParamsIntoURL = R.curry((params, url) =>
  R.pipe(
    R.toPairs,
    R.reduce(
      (urlAccumulator, [name, value]) =>
        R.replace(`:${name}`, value, urlAccumulator),
      url,
    ),
  )(params),
);

/**
 * encodeTokenForUrl :: String -> String
 */
export const encodeTokenForUrl = R.compose(
  R.replace(/\./g, TOKEN_URL_SEPARATOR),
  R.defaultTo(''),
);

// generateQueryParams :: Object -> String
export const generateQueryParams = R.compose(
  R.join('&'),
  R.map(R.join('=')),
  R.toPairs,
);

// insertQueryParamsIntoURL :: Object -> String -> String
export const insertQueryParamsIntoURL = R.curry((params, url) =>
  R.compose(R.concat(`${url}?`), generateQueryParams)(params),
);

// fileNameWithoutExt :: String -> String
export const getFileNameWithoutExt = R.replace(/\.[^.]+$/, '');
