import * as R from 'ramda';
import { createContext } from 'react';
import { setHours, startOfToday } from 'date-fns';
import { getContext } from '@poly/client-utils';
import { pathEqLegacy, propEqLegacy, ramdaUseWith } from '@poly/utils';
import {
  supplierOfficeHoursDays,
  TaxClassification,
  SupplierSources,
  supplierTypes,
} from '@poly/constants';

export const isBankACH = R.path(['bank', 'payByACH']);
export const isSoleProprietorship = pathEqLegacy(
  ['tax', 'classification'],
  TaxClassification.SOLE_PROPRIETORSHIP,
);
export const isSubcontractor = pathEqLegacy(
  ['type'],
  supplierTypes.SUBCONTRACTOR,
);
export const isAdministrative = pathEqLegacy(
  ['type'],
  supplierTypes.ADMINISTRATIVE,
);
export const isAdminSupplierSource = propEqLegacy(
  'supplierSource',
  SupplierSources.AAC,
);

const SupplierFormContext = createContext({});

export const getSupplierFormContext = getContext(SupplierFormContext);

// isAddressesEquals :: Object -> Boolean
export const isAddressesEquals = ramdaUseWith(R.equals, [
  R.prop('address_parts'),
  R.prop('address_parts'),
]);

// prepareInitialTime :: Int -> Date
const prepareInitialTime = (hour) => {
  const currentDate = startOfToday();
  return setHours(currentDate, hour);
};

// supplierInitialOfficeHours :: Object -> [OfficeHour]
export const supplierInitialOfficeHours = R.compose(
  R.map(
    R.compose(
      R.mergeDeepLeft({
        isActive: false,
        endTime: prepareInitialTime(18),
        startTime: prepareInitialTime(8),
      }),
      R.objOf('day'),
    ),
  ),
  R.values,
)(supplierOfficeHoursDays);
