import * as R from 'ramda';

import { defaultNotificationTime } from './constants.js';

const getRandomNotificationId = () => Math.random().toString();

/**
 * createNotificationByType :: NotificationType -> Notification|String -> Notification
 *
 * NotificationType = String
 * Notification = {
 *   id: ID
 *   type: NotificationType
 *   time: Number
 *   message: String
 *   component: ReactComponent
 *   payload: Object
 * }
 */
export const createNotificationByType = (type) =>
  R.compose(
    R.converge(R.mergeRight, [
      R.applySpec({
        id: R.ifElse(
          R.propIs(String, 'id'),
          R.prop('id'),
          getRandomNotificationId,
        ),
        type: R.always(type),
        time: R.propOr(defaultNotificationTime, 'time'),
        hideCloseBtn: R.propOr(false, 'hideCloseBtn'),
      }),
      R.pick(['message', 'component', 'payload']),
    ]),
    R.when(R.is(String), R.objOf('message')),
  );
