import * as R from 'ramda';
import styled from 'styled-components';
import { string, func } from 'prop-types';
import React, { Fragment, useCallback, useEffect } from 'react';
import { usePersistentFormValue } from '@poly/client-utils';
import { useUpdateQueryParams } from '@poly/client-routing';
import { FlexColumn } from '@poly/admin-ui';
import { debounce, propEqLegacy } from '@poly/utils';

import { ProjectInfoTab } from './ProjectInfoTab.js';
import { SidebarIDs } from '../../../sidebars/constants.js';
import { AdditionalInvoicesTab } from './AdditionalInvoicesTab.js';
import { SidebarCloseIcon } from '../../../components/SidebarLayouts.js';
import { SidebarTabs } from '../../../sidebars/components/SidebarTabs.js';
import { SidebarLabel } from '../../../sidebars/components/commonSidebarComponents.js';
import { ProjectLink } from '../../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import { ProjectSidebarTasksTab } from '../../../sidebars/ProjectSidebar/tabs/ProjectSidebarTasksTab.js';
import { ProjectSidebarFilesTab } from '../../../sidebars/ProjectSidebar/tabs/ProjectSidebarFilesTab.js';
import { ProjectSidebarUpdatesTab } from '../../../sidebars/ProjectSidebar/tabs/ProjectSidebarUpdatesTab.js';
import { ApproveSupplierInvoiceForm } from '../../../sidebars/ProjectSidebar/forms/ApproveSupplierInvoiceForm.js';
import { useSearchProjectInvoices } from '../../../sidebars/ProjectSidebar/tabs/useSearchProjectInvoices.js';
import { useSidebarDocumentsNavigation } from '../../../components/PreviewDocumentsNavigation.js';
import { useProjectSidebarData } from '../../../sidebars/ProjectSidebar/useProjectSidebarData.js';
import {
  ProjectDescriptionComp,
  ProjectHeaderInfoComp,
  ProjectStatusComp,
} from '../../../sidebars/ProjectSidebar/ProjectSidebarHeader.js';

export const approveInvoiceTabsSidebarId = 'approveInvoiceTabsSidebarId';

export const approveInvoiceTabs = {
  approveFrom: 'approveFrom',
  projectInfo: 'projectInfo',
  projectTasks: 'projectTasks',
  projectUpdates: 'projectUpdates',
  projectFiles: 'projectFiles',
  additionalInvoices: 'additionalInvoices',
};

export const ApproveInvoiceFormContainer = styled.div`
  padding: ${({ padding }) => padding || '15px'};
  position: relative;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 15px 0 15px;
  width: 100%;
  gap: 15px;
`;

const TabsContainer = styled(FlexColumn)`
  width: 550px;
  height: 100%;
  overflow-y: hidden;
`;

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const SpaceBetween = styled(FlexContainer)`
  justify-content: space-between;
`;

const SidebarLabelS = styled(SidebarLabel)`
  color: #000;
`;

const TabWrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    background: transparent;
    display: none;
  }
`;

// getAdditionalInvoices (SearchInvoicesData, ID) -> [Invoice]
const getAdditionalInvoices = (invoiceId, data) =>
  R.compose(
    R.reject(propEqLegacy('_id', invoiceId)),
    R.pathOr([], ['searchInvoices', 'hits']),
  )(data);

export function ApproveSupplierInvoiceSidebarTabs({
  formId,
  refetch,
  invoiceId,
  projectId,
}) {
  const updateQueryParams = useUpdateQueryParams();
  const { project, loading } = useProjectSidebarData(projectId);
  const { goToNextDocument, isNexDocument } = useSidebarDocumentsNavigation(
    SidebarIDs.approveSupplierInvoice,
  );

  const { data: invoicesData, loading: invoicesLoading } =
    useSearchProjectInvoices(project?._id);

  const { cleanupRetainedValue } = usePersistentFormValue(
    `${formId}-${invoiceId}`,
  );

  const cleanupRetainedValueDebounced = useCallback(
    debounce(1000)(() => {
      cleanupRetainedValue();
    }),
    [],
  );

  useEffect(
    () => () => {
      cleanupRetainedValueDebounced();
    },
    [],
  );

  const isRefetchPassed = R.is(Function, refetch);

  const refetchInvoices = useCallback(
    debounce(2000)(() => (isRefetchPassed ? refetch() : null)),
    [],
  );

  const closeSidebar = () =>
    updateQueryParams({ [SidebarIDs.approveSupplierInvoice]: undefined });

  const onCancel = (__, submit) => {
    if (isRefetchPassed) {
      refetchInvoices();
    }

    if (isNexDocument && submit) {
      return goToNextDocument();
    }
    return closeSidebar();
  };

  const isLoading = loading || invoicesLoading;

  const additionalInvoices = getAdditionalInvoices(invoiceId, invoicesData);

  const additionalInvoicesTabTitleColor = additionalInvoices.length
    ? '#dd6262'
    : null;

  if (isLoading || !project) {
    return null;
  }

  return (
    <TabsContainer>
      <HeaderContainer>
        <SpaceBetween>
          <SidebarLabel>Approve Invoice</SidebarLabel>
          <FlexContainer>
            <ProjectLink {...project} inNewTab>
              <SidebarLabelS>{project?.projectId}</SidebarLabelS>
            </ProjectLink>
            <ProjectStatusComp project={project} />
            <SidebarCloseIcon onClick={closeSidebar} />
          </FlexContainer>
        </SpaceBetween>
        <ProjectHeaderInfoComp project={project} />
        <ProjectDescriptionComp project={project} />
      </HeaderContainer>

      <Container>
        <SidebarTabs
          id={approveInvoiceTabsSidebarId}
          defaultValue={approveInvoiceTabs.approveFrom}
          params={[SidebarIDs.approveSupplierInvoice]}
          tabs={[
            [
              'Invoice',
              approveInvoiceTabs.approveFrom,

              <TabWrapper key={approveInvoiceTabs.approveFrom}>
                <ApproveInvoiceFormContainer>
                  <ApproveSupplierInvoiceForm
                    withoutTitle
                    formId={formId}
                    withCloseAction
                    withPersistFields
                    withRemoveInvoice
                    onCancel={onCancel}
                    invoiceId={invoiceId}
                    keepDirtyOnReinitialize
                  />
                </ApproveInvoiceFormContainer>
              </TabWrapper>,
            ],
            [
              'Project',
              approveInvoiceTabs.projectInfo,
              <TabWrapper key={approveInvoiceTabs.approveFrom}>
                <ProjectInfoTab project={project} loading={loading} />
              </TabWrapper>,
            ],
            [
              'Updates',
              approveInvoiceTabs.projectUpdates,
              <TabWrapper key={approveInvoiceTabs.projectUpdates}>
                <ProjectSidebarUpdatesTab {...project} />,
              </TabWrapper>,
            ],
            [
              'Tasks',
              approveInvoiceTabs.projectTasks,
              <TabWrapper key={approveInvoiceTabs.projectTasks}>
                <ProjectSidebarTasksTab {...project} readOnly />,
              </TabWrapper>,
            ],
            [
              'Additional Invoices',
              approveInvoiceTabs.additionalInvoices,
              <TabWrapper key={approveInvoiceTabs.additionalInvoices}>
                <AdditionalInvoicesTab invoices={additionalInvoices} />
              </TabWrapper>,
              null,
              Fragment,
              additionalInvoicesTabTitleColor,
            ],
            [
              'Files',
              approveInvoiceTabs.projectFiles,
              <TabWrapper key={approveInvoiceTabs.projectFiles}>
                <ProjectSidebarFilesTab {...project} />,
              </TabWrapper>,
            ],
          ]}
          hideCloseIcon
        />
      </Container>
    </TabsContainer>
  );
}

ApproveSupplierInvoiceSidebarTabs.propTypes = {
  refetch: func,
  formId: string.isRequired,
  invoiceId: string.isRequired,
  projectId: string.isRequired,
};
