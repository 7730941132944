import * as R from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { func, number, oneOfType, string } from 'prop-types';
import { convertCentsToDollars, convertDollarsToCents } from '@poly/utils';
import { getThemeColor, Input } from '@poly/admin-book';
import { MONEY_LIMIT } from '@poly/constants';

const commonMoneyInputProps = {
  prefix: '$',
  decimalScale: 2,
  floatValue: true,
  type: 'numberMask',
  thousandSeparator: true,
  maxValue: MONEY_LIMIT.upper,
};

export function MoneyInput(props) {
  return <Input {...R.omit(['formData'], props)} {...commonMoneyInputProps} />;
}

export function MoneyInputAsCents({ name, value, onChange, ...props }) {
  const onChangeValue = (changedValue) => {
    if (typeof onChange === 'function') {
      onChange(convertDollarsToCents(changedValue));
    }
  };

  const preparedValue = useMemo(() => convertCentsToDollars(value), [value]);

  return (
    <Input
      {...props}
      {...commonMoneyInputProps}
      name={name}
      value={preparedValue}
      onChange={onChangeValue}
    />
  );
}

MoneyInputAsCents.propTypes = {
  value: oneOfType([number, string]),
  name: string,
  onChange: func,
};

const BACK_SPACE_CODE = 8;

const NegativeInputS = styled(Input)`
  input {
    color: ${getThemeColor(['secondaryMid'])};
  }
`;

export function NegativeMoneyInput({ onChange, value, ...props }) {
  const onChangeHandler = R.compose(onChange, R.when(R.gt(R.__, 0), R.negate));
  const onKeyUp = (ev) => {
    // allows clear input completely
    if (ev.keyCode === BACK_SPACE_CODE && ev.target.selectionStart === 2) {
      onChange(null);
      // prevents jumping caret at the end of value when start typing
    } else if (Number(value).toString().length === 2) {
      ev.target.setSelectionRange(3, 3);
    }
  };
  return (
    <NegativeInputS
      {...props}
      {...commonMoneyInputProps}
      onChange={onChangeHandler}
      allowNegative
      onKeyUp={onKeyUp}
      value={value}
    />
  );
}

NegativeMoneyInput.propTypes = {
  onChange: func.isRequired,
  value: oneOfType([number, string]),
};

export function NegativeMoneyInputAsCents({ onChange, value, ...props }) {
  const onChangeValue = (changedValue) => {
    onChange(convertDollarsToCents(changedValue));
  };

  return (
    <NegativeMoneyInput
      {...props}
      onChange={onChangeValue}
      value={convertCentsToDollars(value)}
    />
  );
}

NegativeMoneyInputAsCents.propTypes = {
  onChange: func.isRequired,
  value: oneOfType([number, string]),
};
