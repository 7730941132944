import * as R from 'ramda';
import { Input } from '@poly/admin-book';

import { InvoiceSelectField } from '../components/InvoiceSelectField.js';

export const invoiceToFormField = {
  label: 'Invoice To',
  field: {
    name: 'invoiceTo',
    Component: InvoiceSelectField,
  },
};

export const codingFormField = {
  label: 'Coding',
  field: {
    name: 'financialCoding',
    Component: Input,
  },
  renderIf: R.path(['client', 'configs', 'propertyFields', 'financialCoding']),
};

export const costCenterFormField = {
  label: 'Cost Center',
  field: {
    name: 'costCenter',
    Component: Input,
  },
  renderIf: R.path(['client', 'enableCostCenter']),
};
