import React from 'react';
import { bool, object } from 'prop-types';
import { TableCard } from '@poly/admin-book';
import { TablePagination } from '@poly/admin-ui/src/components/Pagination.js';
import { TableWithPaginationContainer } from '@poly/admin-book/src/Card.js';

import { SuppliersTable } from '../../tables/suppliersTable/SuppliersTable.js';

export function SuppliersTab({ query, showDateColumn }) {
  return (
    <TableCard>
      <TableWithPaginationContainer {...{ paginationVisible: true }}>
        <SuppliersTable {...{ query }} showDateColumn={showDateColumn} />
      </TableWithPaginationContainer>
      <TablePagination />
    </TableCard>
  );
}

SuppliersTab.displayName = 'SuppliersTab';

SuppliersTab.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  query: object.isRequired,
  showDateColumn: bool,
};
