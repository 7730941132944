import * as R from 'ramda';
import { AccountTypeCategories } from '@poly/constants';
import { calculateTotal, propEqLegacy } from '@poly/utils';

// extractIncome :: [ProfitLossParentAccountReport] -> [ProfitLossParentAccountReport]
export const extractIncome = R.filter(
  propEqLegacy('category', AccountTypeCategories.income),
);

// isCogsAccount :: [String] -> ProfitLossParentAccountReport -> Boolean
const isCogsAccount = (cogsAccountCodes) =>
  R.pathSatisfies(R.includes(R.__, cogsAccountCodes), ['account', 'code']);

// extractCogs :: [String] -> [ProfitLossParentAccountReport] -> [ProfitLossParentAccountReport]
export const extractCogs = (cogsAccountCodes) =>
  R.filter(isCogsAccount(cogsAccountCodes));

// extractExpense :: [String] -> [ProfitLossParentAccountReport] -> [ProfitLossParentAccountReport]
export const extractExpense = (cogsAccountCodes) =>
  R.filter(
    R.both(
      propEqLegacy('category', AccountTypeCategories.expense),
      R.complement(isCogsAccount(cogsAccountCodes)),
    ),
  );

// calculateTotalBalance :: [ProfitLossParentAccountReport] -> Number
export const calculateTotalBalance = calculateTotal(R.prop('balance'));
