import * as R from 'ramda';

// isSearchTextMatchedAtPaths :: [[String]] -> String -> Entity -> Boolean
// Entity = Object
export const isSearchTextMatchedAtPaths = R.curry(
  (paths, searchText, account) =>
    R.any(
      R.compose(
        R.includes(searchText.toLowerCase()),
        R.toLower,
        R.unless(R.is(String), R.toString),
        R.pathOr('', R.__, account),
      ),
    )(paths),
);

// filterItemsBySearchText :: (String,[String], [Object]) -> [Object] -> [Object]
export const filterItemsBySearchText = (searchText, searchTextPaths, items) =>
  R.when(
    R.always(searchText),
    R.filter(isSearchTextMatchedAtPaths(searchTextPaths, R.trim(searchText))),
  )(items);
