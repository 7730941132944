import * as R from 'ramda';
import { useState, useEffect } from 'react';
import {
  toggleExpandedForAll,
  addNodeUnderParent,
  changeNodeAtPath,
  getNodeAtPath,
  removeNode,
  walk,
} from '@nosferatu500/react-sortable-tree';

// getNodeKey :: { node: TreeNode } -> String
export const getNodeKey = ({ node }) => node.id;

// validateNodeBase :: TreeNode -> String
const validateNodeBase = (node) =>
  !node?.title?.trim() ? 'Name is required' : '';

// validateTreeBase :: [TreeNode] -> Object
const validateTreeBase = (treeData) => {
  const errors = {};
  walk({
    treeData,
    getNodeKey,
    ignoreCollapsed: false,
    callback: ({ node }) => {
      errors[node.id] = validateNodeBase(node);
    },
  });
  return errors;
};

// isValid :: Object -> Boolean
const isValid = (validationErrors) =>
  Object.values(validationErrors).every((error) => !error);

export const useTreeLogic = (initialValues, getInitialNodeState) => {
  const [validationErrors, setValidationErrors] = useState({});
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    if (!R.equals(initialValues, treeData)) {
      setTreeData(initialValues);
    }
  }, [initialValues]);

  const validateTree = () => {
    const errors = validateTreeBase(treeData);
    setValidationErrors({
      ...validationErrors,
      ...errors,
    });
    return isValid(errors);
  };

  const onChangeNodeTitleHandler = (nodePath) => (newTitle) => {
    const { node } = getNodeAtPath({ treeData, path: nodePath, getNodeKey });
    const newNode = { ...node, title: newTitle };
    setTreeData(
      changeNodeAtPath({
        treeData,
        path: nodePath,
        newNode,
        getNodeKey,
      }),
    );
  };
  const onAddNodeHandler = (parentNodePath) => () => {
    const node = getNodeAtPath({ treeData, path: parentNodePath, getNodeKey });
    setTreeData((oldData) => {
      const result = addNodeUnderParent({
        treeData: oldData,
        parentKey: getNodeKey(node),
        newNode: getInitialNodeState(),
        getNodeKey,
      });
      return result.treeData;
    });
  };
  const onRemoveNodeHandler = (path, node) => () => {
    setValidationErrors({
      ...validationErrors,
      [node.id]: null,
    });
    setTreeData((oldData) => {
      const result = removeNode({
        treeData: oldData,
        getNodeKey,
        path,
      });
      return result.treeData;
    });
  };

  const addNode = () => setTreeData([...treeData, getInitialNodeState()]);

  const expandTree = () => {
    const expanded = toggleExpandedForAll({ treeData });
    setTreeData(expanded);
  };

  return {
    validationErrors,
    validateTree,
    treeData,
    setTreeData,
    onChangeNodeTitleHandler,
    onAddNodeHandler,
    onRemoveNodeHandler,
    addNode,
    expandTree,
  };
};
