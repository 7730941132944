import React, { useState } from 'react';
import { DefaultBodyWrapper } from '@poly/admin-book';
import styled from 'styled-components';

import { useAssetManufacturersQuery } from './useAssetManufacturersQuery.js';
import { AssetManufacturersPageTable } from './AssetManufacturersPageTable.js';
import { AssetManufacturersPageHeader } from './AssetManufacturersPageHeader.js';

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  padding-top: 15px;
`;

export function AssetManufacturersPage() {
  const [selectedRows, setSelectedRows] = useState([]);

  const { assetManufacturers, loading } = useAssetManufacturersQuery();

  return (
    <>
      <AssetManufacturersPageHeader
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        assetManufacturers={assetManufacturers}
      />
      <DefaultBodyWrapper>
        <TableContainer>
          <AssetManufacturersPageTable
            loading={loading}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            assetManufacturers={assetManufacturers}
          />
        </TableContainer>
      </DefaultBodyWrapper>
    </>
  );
}
