import * as R from 'ramda';
import { useQuery, gql } from '@apollo/client';

const propertyHierarchyQuery = gql`
  query propertyHierarchyQuery($clientId: ID!, $hierarchyId: ID!) {
    client(id: $clientId) {
      _id
      propertyHierarchy(id: $hierarchyId) {
        _id
        name
        description
        nodes {
          id
          name
          parentId
          poNumber
          properties {
            _id
            name
          }
        }
      }
    }
  }
`;

const getHierarchyFromResult = R.path(['client', 'propertyHierarchy']);

export const useHierarchyDetailsQuery = (clientId, hierarchyId) => {
  const { data, loading, refetch } = useQuery(propertyHierarchyQuery, {
    variables: { clientId, hierarchyId },
    skip: !hierarchyId || !clientId,
  });
  const hierarchyObj = getHierarchyFromResult(data);
  return {
    hierarchyObj,
    refetch,
    loading,
  };
};
