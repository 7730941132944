import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { object, shape, arrayOf, string } from 'prop-types';
import {
  MasterSupplierStatus,
  mapMasterSupplierStatusesUI,
} from '@poly/constants';
import { Link } from '@poly/client-routing';

import { routesNames } from '../../routes/index.js';
import { EntityStatisticsS, DividerS } from './entitiesStatisticsStyles.js';
import { findCountByStatus } from './entitiesStatisticsUtils.js';

const EntityStatisticsWithMarginS = styled(EntityStatisticsS)`
  margin-top: 50px;
`;

function StatisticsLink({ count, status, route }) {
  const href = `${route}?tab=${status}`;

  return <Link href={href}>{count}</Link>;
}

StatisticsLink.propTypes = {
  count: string,
  status: string,
  route: string,
};

// getListItemsByStatuses :: ([StatisticCount], String) -> [ListItems]
// StatisticCount = { count: number }
// ListItems = [Function]
const getListItemsByStatuses = (countByStatuses, route) =>
  R.compose(
    R.map((status) => [
      mapMasterSupplierStatusesUI[status],
      <StatisticsLink
        key={status}
        route={route}
        status={status}
        count={findCountByStatus(status, countByStatuses)}
      />,
    ]),
    R.values,
  )(MasterSupplierStatus);

function SubcontractorsStatistics({ adminAccountIndex }) {
  const countByStatuses = R.pathOr(
    [],
    ['masterSuppliersSubcontracts', 'countByStatuses'],
    adminAccountIndex,
  );

  const subcontractorsItems = getListItemsByStatuses(
    countByStatuses,
    routesNames.MASTER_SUPPLIERS_SUBCONTRACTORS,
  );

  return (
    <EntityStatisticsS header="Subcontractors" items={subcontractorsItems} />
  );
}

SubcontractorsStatistics.propTypes = {
  adminAccountIndex: shape({
    masterSuppliersSubcontracts: shape({
      // eslint-disable-next-line react/forbid-prop-types
      countByStatuses: arrayOf(object),
    }),
  }),
};

function AdministrativeStatisticsS({ adminAccountIndex }) {
  const countByStatuses = R.pathOr(
    [],
    ['masterSuppliersAdministrative', 'countByStatuses'],
    adminAccountIndex,
  );

  const administrativeItems = getListItemsByStatuses(
    countByStatuses,
    routesNames.MASTER_SUPPLIERS_ADMINISTRATIVE,
  );

  return (
    <EntityStatisticsWithMarginS
      header="Administrative"
      items={administrativeItems}
    />
  );
}

AdministrativeStatisticsS.propTypes = {
  adminAccountIndex: shape({
    masterSuppliersAdministrative: shape({
      // eslint-disable-next-line react/forbid-prop-types
      countByStatuses: arrayOf(object),
    }),
  }),
};

export function MasterSuppliersStatistics(props) {
  return (
    <div>
      <EntityStatisticsS header="Master Suppliers" items={[]} />
      <DividerS />
      <SubcontractorsStatistics {...props} />
      <AdministrativeStatisticsS {...props} />
    </div>
  );
}
