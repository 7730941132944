import React from 'react';
import { shape } from 'prop-types';
import { noCase } from 'change-case';

import {
  StatusS,
  SearchResultItemText,
  SearchResultItemTitle,
  SearchResultItemLinked,
  SearchResultItemDivider,
} from './common/components.js';
import { addressPath, clientNamePath } from './common/selectors.js';
import { FlexColumn, SearchResultItemContainer } from './common/wrappers.js';
import { propertyStatusesColors } from '../../../../constants/properties.js';
import { highlightedTextPropType } from './common/propTypes.js';

export function PropertiesSearchResult({ name, status, ...props }) {
  return (
    <SearchResultItemContainer>
      <FlexColumn>
        <SearchResultItemLinked>{name}</SearchResultItemLinked>
        <SearchResultItemDivider />
        <SearchResultItemText>{clientNamePath(props)}</SearchResultItemText>
        <SearchResultItemDivider />
        <SearchResultItemTitle>{addressPath(props)}</SearchResultItemTitle>
      </FlexColumn>
      <StatusS text={noCase(status)} color={propertyStatusesColors[status]} />
    </SearchResultItemContainer>
  );
}

PropertiesSearchResult.propTypes = {
  name: highlightedTextPropType.isRequired,
  address: shape({
    formatted_address: highlightedTextPropType.isRequired,
  }).isRequired,
  client: shape({
    name: highlightedTextPropType.isRequired,
  }).isRequired,
  status: highlightedTextPropType.isRequired,
};
