import React from 'react';
import styled from 'styled-components';
import { Icon } from '@poly/admin-book/src/Icon/index.js';
import { Text } from '@poly/admin-book/src/Text/index.js';
import { string } from 'prop-types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  height: 190px;
`;

const SmallText = styled(Text)`
  padding-top: 5px;
  font-size: 12px;
  color: #575757;
  text-align: center;
`;

export function NoSearchResultComponent({ iconName, title, content }) {
  return (
    <Wrapper>
      <ContentWrapper>
        <Icon name={iconName} />
        <Text size="20px">{title}</Text>
        <SmallText>{content}</SmallText>
      </ContentWrapper>
    </Wrapper>
  );
}

NoSearchResultComponent.propTypes = {
  iconName: string.isRequired,
  title: string.isRequired,
  content: string.isRequired,
};
