import * as R from 'ramda';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import { validateFormData } from '@poly/client-utils';
import { Loader } from '@poly/admin-book';
import {
  showSuccess,
  closeCurrentModal,
  useOnSubmitSetStopSubmitting,
} from '@poly/admin-ui';
import { getCheckedServices } from './form/utils.js';
import { validationRules } from './form/validationRules.js';
import {
  PropertySupplierFormComp,
  propertySuppliersFormId,
} from './form/PropertySupplierFormComp.js';
import {
  useAddPropertySupplier,
  usePropertyForAddSupplier,
} from '../../core/hooks/properties/index.js';

// getSuppliersProperty :: SearchPropertiesQueryResult -> [Property]
const getSuppliersProperty = R.compose(
  R.map(R.path(['supplier', '_id'])),
  R.prop('suppliers'),
);

export function PropertySupplierForm(props) {
  const dispatch = useDispatch();

  const { data, loading, propertyId } = usePropertyForAddSupplier();
  const { addPropertySupplier } = useAddPropertySupplier();

  const onSubmitHandler = ({ services, supplierId }) =>
    addPropertySupplier({
      supplierId,
      serviceTypesIds: getCheckedServices(services),
      propertyId,
    }).then(() => {
      dispatch(showSuccess('Supplier was successfully added'));
      dispatch(closeCurrentModal());
    });

  const propertySupplierIds = getSuppliersProperty(data?.property);

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    propertyId,
    onSubmitHandler,
  );

  if (loading) return <Loader />;

  return (
    <Form
      {...props}
      id={propertySuppliersFormId}
      validateOnBlur={false}
      initialValues={{ services: {} }}
      render={PropertySupplierFormComp}
      onCancelClick={closeCurrentModal}
      validate={validateFormData(validationRules)}
      propertySupplierIds={propertySupplierIds}
      onSubmit={onSubmit}
    />
  );
}
