import React from 'react';
import { number, object, shape } from 'prop-types';
import { Loader } from '@poly/admin-book';

import { SupplierSearchTableComp } from './SupplierSearchTableComp.js';
import { useSearchSuppliers } from './useSearchSuppliers.js';

export function SupplierSearchTable({ query, geocodeAddress }) {
  const { loading, ...tableProps } = useSearchSuppliers({
    query,
    geocodeAddress,
  });

  return loading ? <Loader /> : <SupplierSearchTableComp {...tableProps} />;
}

// eslint-disable-next-line
SupplierSearchTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  query: object,
  geocodeAddress: shape({ lat: number, lon: number }),
};
SupplierSearchTable.displayName = 'SupplierSearchTable';
