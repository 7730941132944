import { useQuery } from '@apollo/client';

import { useMultipleSubscriptions } from './useMultipleSubscriptions.js';
import { useDebounceRefetchByOptions } from './useDebounceRefetchByOptions.js';

export const useReactiveQuery = (
  query,
  subscription,
  { queryOptions, subscriptionOptions, refetchDebounce = 2000 },
) => {
  const { data, loading, error, refetch, fetchMore } = useQuery(
    query,
    queryOptions,
  );

  const debouncedRefetch = useDebounceRefetchByOptions({
    refetch,
    queryOptions,
    refetchDebounce,
    subscriptionOptions,
  });

  useMultipleSubscriptions(subscription, subscriptionOptions, {
    debouncedRefetch,
    data,
  });

  return { data, loading, error, refetch, debouncedRefetch, fetchMore };
};
