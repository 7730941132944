import * as R from 'ramda';
import React from 'react';
import { arrayOf } from 'prop-types';
import { LinkButton } from '@poly/admin-book';
import { CommonPrintLayout } from '@poly/admin-ui';
import { PRINT_PDF_CAPTION } from '@poly/constants';
import { openPrintWindowWithData } from '@poly/client-utils';

import { confirmSatisfactionProjectPropTypes } from './prop-types.js';
import { ConfirmSatisfactionReportTableContainer } from './ConfirmSatisfactionReportTableContainer.js';

export function ConfirmSatisfactionPrintPdfBtn({ projects }) {
  const disabled = R.isEmpty(projects);

  const onClick = () =>
    openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: ConfirmSatisfactionReportTableContainer,
      fileName: 'supplier_transaction_history',
      metaData: { title: <p>Confirm Satisfaction Report</p> },
      projects,
      isPrint: true,
      loading: false,
    });

  return (
    <LinkButton onClick={onClick} disabled={disabled}>
      {PRINT_PDF_CAPTION}
    </LinkButton>
  );
}

ConfirmSatisfactionPrintPdfBtn.propTypes = {
  projects: arrayOf(confirmSatisfactionProjectPropTypes),
};
