import styled from 'styled-components';
import React, { useRef, useState, useEffect } from 'react';

import { SubcontractorAgreementTitle } from './constants.js';

const HTMLFrameS = styled.iframe`
  display: block;
  width: 100%;
  border: none;
  overflow: hidden;
  position: relative;

  @media print {
    height: 3000px !important;
  }
`;

export function SubcontractorAgreementFrame() {
  const iframeRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(null);

  const subcontractorAgreementHTMLLink = `/assets/docs/${SubcontractorAgreementTitle}.html`;

  const onIFrameLoad = () => {
    const elHeight =
      iframeRef?.current?.contentWindow?.document?.body?.clientHeight;
    setContentHeight(elHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', onIFrameLoad);

    onIFrameLoad();

    return () => window.removeEventListener('resize', onIFrameLoad);
  }, []);

  return (
    <HTMLFrameS
      ref={iframeRef}
      onLoad={onIFrameLoad}
      title="Subcontractor Agreement"
      src={subcontractorAgreementHTMLLink}
      style={{ height: contentHeight || '100%' }}
    />
  );
}
