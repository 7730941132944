import {
  supplierTaxSection,
  supplierBankSection,
  supplierRatesSection,
  supplierRemarksSection,
  supplierDocumentsSection,
  supplierFinancialSection,
} from '../../../forms/supplierForm/index.js';
import { generalSection } from './generalSection.js';
import { masterSupplierSettingsSection } from './masterSupplierSettingsSection.js';
import { officeHoursSection } from '../../../forms/supplierForm/form/sections/officeHoursSection.js';
import { supplierRemitFormSection } from '../../../forms/supplierForm/form/sections/supplierRemitFormSection.js';

export const masterSupplierFormSections = (
  masterSupplierUrl,
  masterSupplier,
  isSupplierTypeEditable,
  protectedFieldsAccess,
  supplierSource,
  hasAccessToPrivateSupplier,
) => {
  const {
    hasUserAccessToUpdateTax,
    hasUserAccessToUpdateBank,
    hasUserAccessToUpdateFinance,
    hasUserAccessToUpdateRemit,
    hasUserAccessToUpdateDocuments,
  } = protectedFieldsAccess;

  return [
    ...masterSupplierSettingsSection(
      masterSupplier,
      isSupplierTypeEditable,
      hasAccessToPrivateSupplier,
    ),
    ...supplierRemarksSection,
    ...generalSection(masterSupplierUrl),
    ...(hasUserAccessToUpdateFinance
      ? supplierFinancialSection(supplierSource)
      : []),
    ...(hasUserAccessToUpdateRemit ? supplierRemitFormSection : []),
    ...officeHoursSection,
    ...supplierRatesSection(),
    ...(hasUserAccessToUpdateTax ? supplierTaxSection(null) : []),
    ...(hasUserAccessToUpdateBank ? supplierBankSection(null) : []),
    ...(hasUserAccessToUpdateDocuments ? supplierDocumentsSection(false) : []),
  ];
};
