import React from 'react';
import { MIME_TYPES } from '@poly/constants';
import { useNotificationState } from '@poly/admin-ui';
import { FilePicker } from '@poly/admin-book';

export function ImageLogoInput(props) {
  const { showErrorNotification } = useNotificationState();

  const acceptTypes = [MIME_TYPES.IMAGE_PNG];

  const onTypeError = () =>
    showErrorNotification('Only PNG files are supported as client logo');
  return (
    <FilePicker
      {...props}
      multiple={false}
      acceptTypes={acceptTypes}
      onTypeError={onTypeError}
    />
  );
}
