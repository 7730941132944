import { gql } from '@apollo/client';

export const CLIENTS_SEARCH_FOR_SELECT = gql`
  query CLIENTS_SEARCH_FILTER($searchInput: CollectionSearchParams!) {
    searchClients(input: $searchInput) {
      hits {
        _id
        name
        apps
      }
    }
  }
`;

export const CLIENT_CONFIGS_QUERY = gql`
  query DEFAULT_CLIENT_QUERY($id: ID!) {
    client(id: $id) {
      _id
      name
      financial {
        invoiceto
        costCenter
      }
      configs {
        propertyFields {
          division
          storeNumber
          districtFacilityManager
          buildingInfo {
            occupancy
            ownership
            lockBoxCode
            parcelTaxId
            buildingType
            buildingSize
            powerRequirements
            landlordResponsibility
          }
          alarmInfo
          financialCoding
        }
        requesterSurvey {
          enabled
        }
      }
      districts {
        _id
        name
      }
      enableCostCenter
    }
  }
`;

export const DEFAULT_CLIENT_QUERY = gql`
  query DEFAULT_CLIENT_QUERY($id: ID!) {
    client(id: $id) {
      _id
      name
      apps
    }
  }
`;
