import * as R from 'ramda';
import React, { memo } from 'react';
import { FieldLayout } from '@poly/admin-book';
import { MoneyInput } from '@poly/admin-ui';
import styled from 'styled-components';
import { arrayOf, func, number, shape, string } from 'prop-types';

import { formatAccountOptionText } from '../../components/AccountsSelect.js';
import { GLCodeSelect } from '../../modules/accounting/GLCodeSelect/GLCodeSelect.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { rejectReceivableAndPayableAccounts } from '../../modules/accounting/GLCodeSelect/glCodeSelectUtils.js';
import { DeleteLineIcon } from '../../components/DeleteLineIcon.js';
import { isGLCodeOptionDisabled } from '../../utils/account.js';
import { onKeyDownToPreventFormSubmit } from '../../utils/form.js';

// getAccountOption :: Account -> AccountOption
const getAccountOption = R.applySpec({
  value: R.prop('code'),
  label: formatAccountOptionText,
});

// formatAccountsOptions :: [Account] -> [AccountOption]
const formatAccountsOptions = R.compose(
  R.flatten,
  R.map(R.compose(R.map(getAccountOption), R.propOr([], 'childAccounts'))),
);

function PayRollAccountInput(props) {
  return <MoneyInput height="32px" {...props} />;
}

// getGLCodeOptions :: [FieldsValue] -> [String]
const getGLCodeOptions = R.compose(
  R.reject(R.isNil),
  R.map(R.path(['glCode', 'value'])),
);

// isFieldPropsNotChanged :: { oldProps: InputProps, newProps: InputProps } -> Boolean
// InputProps = { fields: { value: [FieldsValue] } }
// FieldsValue = { glCode: { value: String } }
const isFieldPropsNotChanged = R.converge(R.equals, [
  R.compose(getGLCodeOptions, R.path(['oldProps', 'fields', 'value'])),
  R.compose(getGLCodeOptions, R.path(['newProps', 'fields', 'value'])),
]);

const DeleteLineIconS = styled(DeleteLineIcon)`
  position: unset;
`;

function DeletePayRollLIneButton({ index, remove }) {
  return (
    index > 16 && (
      <DeleteLineIconS name="delete" size={14} onClick={() => remove(index)} />
    )
  );
}

DeletePayRollLIneButton.propTypes = {
  index: number,
  remove: func,
};

export const InputPayRollLIneField = memo(
  ({ name, index, fields: { value, remove } }) => (
    <FlexSpaceBetween>
      <FieldLayout
        required
        {...(index === 0 && { label: 'G/L Code' })}
        layout={{ width: '64%', padding: '0 0 5px 0' }}
        field={{
          name: `${name}.glCode`,
          additionalProps: {
            formatAccountsOptions,
            filterAccounts: rejectReceivableAndPayableAccounts,
            isOptionDisabled: isGLCodeOptionDisabled(
              value,
              ['glCode'],
              ['value'],
            ),
          },
          Component: GLCodeSelect,
        }}
        validators={[[R.identity, 'GL Code is required']]}
      />
      <FieldLayout
        {...(index === 0 && { label: 'Amount' })}
        layout={{ width: '29%', padding: '0 0 5px 0' }}
        field={{
          additionalProps: {
            onKeyDown: onKeyDownToPreventFormSubmit,
          },
          name: `${name}.amount`,
          Component: PayRollAccountInput,
        }}
      />
      <FieldLayout
        layout={{ width: '5%', padding: '8px 0 0 10px' }}
        field={{
          name: `${name}.delete`,
          additionalProps: { index, remove },
          Component: DeletePayRollLIneButton,
        }}
      />
    </FlexSpaceBetween>
  ),
  (oldProps, newProps) => isFieldPropsNotChanged({ oldProps, newProps }),
);

InputPayRollLIneField.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  fields: shape({
    remove: func,
    value: arrayOf(shape({ glCode: string })),
  }),
};
