import React, { useContext } from 'react';
import { bool } from 'prop-types';
import { TableCardWithPaginationContainer } from '@poly/admin-ui';
import { DefaultBodyWrapper, Loader } from '@poly/admin-book';

import { PageWithSearchHeaderContext } from '../../components/PageWithSearchHeader.js';
import { clientInvoicesPropType } from './prop-types.js';
import { ClientInvoiceHistoryTable } from './ClientInvoiceHistoryTable.js';

export function ClientInvoiceHistoryBody({ clientInvoices, loading }) {
  const { contentHeight } = useContext(PageWithSearchHeaderContext);

  if (loading) {
    return <Loader />;
  }

  return (
    <DefaultBodyWrapper height={contentHeight}>
      <TableCardWithPaginationContainer>
        <ClientInvoiceHistoryTable clientInvoices={clientInvoices} />
      </TableCardWithPaginationContainer>
    </DefaultBodyWrapper>
  );
}

ClientInvoiceHistoryBody.propTypes = {
  loading: bool,
  clientInvoices: clientInvoicesPropType.isRequired,
};
