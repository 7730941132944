import React from 'react';
import { arrayOf, element, oneOfType } from 'prop-types';
import styled from 'styled-components';

import { LayoutContainer } from './styles.js';

const EmptyLayoutContainer = styled(LayoutContainer)`
  justify-content: center;
`;

export function EmptyLayout({ children }) {
  return <EmptyLayoutContainer>{children}</EmptyLayoutContainer>;
}

EmptyLayout.propTypes = {
  children: oneOfType([element, arrayOf(element)]).isRequired,
};
