import React from 'react';
import * as R from 'ramda';
import { element, shape, string } from 'prop-types';
import { Loader } from '@poly/admin-book';
import {
  commonSortQuery,
  formatDateProp,
  keywordSortQuery,
  pathOrNothingUI,
} from '@poly/client-utils';
import {
  isRowDisabled,
  TaskCompleteBtn,
  TaskMoreBtn,
} from './TasksTableBtns.js';
import { TableWrapper, TasksTableComp } from './TasksTableComp.js';
import { useEntityTasksByCollection } from '../../../hooks/tasks/index.js';
import { useMapConfigToTableProps } from '../../../hooks/useMapConfigToTableProps.js';
import { useSetInitialPaginationOnMount } from '../../../hooks/useSetInitialPaginationOnMount.js';
import { useSetItemsCount } from '../../../hooks/useSetItemsCount.js';

function TaskMoreBtnComp(props) {
  return <TaskMoreBtn {...props} position="left" />;
}

const tableConfig = [
  ['', TaskCompleteBtn],
  ['Description', R.prop('description'), keywordSortQuery(['description'])],
  [
    'Assigned',
    pathOrNothingUI(['manager', 'fullName']),
    keywordSortQuery(['manager', 'profile', 'fullName']),
  ],
  ['Due Date', formatDateProp('dueDate'), commonSortQuery(['dueDate'])],
  [' ', TaskMoreBtnComp],
];

function TableContainer(props) {
  const tableTaskProps = useMapConfigToTableProps(
    R.prop('tasks'),
    tableConfig,
    props,
  );

  return (
    <TasksTableComp
      {...props}
      {...tableTaskProps}
      isRowDisabled={isRowDisabled}
      omitColumn={4}
    />
  );
}

// getTasksTotalCount :: SearchTasksQueryResult -> Number
const getTasksTotalCount = R.pathOr(0, ['tasks', 'total']);

export function TasksTable({ toolBar, collection, entity, ...rest }) {
  useSetInitialPaginationOnMount();

  const { data, loading } = useEntityTasksByCollection({
    collection,
    entity,
  });

  useSetItemsCount(getTasksTotalCount, data);

  if (loading) return <Loader />;

  return (
    <TableWrapper>
      {toolBar}
      <TableContainer {...{ tasks: data?.tasks?.hits, ...rest }} />
    </TableWrapper>
  );
}

TasksTable.displayName = 'TasksTable';

TasksTable.propTypes = {
  collection: string.isRequired,
  entity: shape({
    _id: string.isRequired,
    humanReadableId: string.isRequired,
  }).isRequired,
  toolBar: element.isRequired,
};
