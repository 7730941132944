import {
  arrayOf,
  instanceOf,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';

export const incomeStatementsFiltersPropTypes = {
  endDate: oneOfType([string, instanceOf(Date)]).isRequired,
  startDate: oneOfType([string, instanceOf(Date)]).isRequired,
  accountingMethod: string.isRequired,
};

export const incomeStatementsPropTypes = {
  _id: string,
  code: string,
  name: string,
  balance: number,
  compass_gl_code: string,
  notClientsBalance: number,
  compass_gl_code_description: string,
  accountType: shape({
    _id: string,
    normal_balance: string,
  }),
  clientsWithBalance: arrayOf(
    shape({
      code: string,
      balance: number,
      client: shape({
        _id: string,
        name: string,
      }),
    }),
  ),
};
