import * as R from 'ramda';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { string, object, bool } from 'prop-types';
import { DESC_SORT_ORDER } from '@poly/constants';
import { Loader } from '@poly/admin-book';
import {
  prepareSortingWithSearchText,
  usePaginationParams,
  useSetItemsCount,
} from '@poly/admin-ui';
import {
  useSortableTable,
  useReactiveQuery,
  duplicateField,
} from '@poly/client-utils';

import {
  PROJECTS_BY_SEARCH,
  RECURRING_PROJECTS_BY_SEARCH,
} from '../../core/hooks/projects/queries.js';
import {
  PROJECTS_BY_SEARCH_SUB,
  RECURRING_PROJECTS_BY_SEARCH_SUB,
} from '../../core/hooks/projects/subscriptions.js';
import { ProjectSearchTableComp } from './ProjectSearchTableComp.js';
import {
  projectsWithPriorityTableConfig,
  commonProjectTableConfig,
} from '../projectsTable/ProjectsTable.js';
import { setDefaultProjectManager } from '../projectsTable/projectsTableUtils.js';

// getProjects :: [Project] ->
const getProjects = R.compose(
  setDefaultProjectManager,
  R.map(duplicateField('projectId', 'projectLinkId')),
);

export function ProjectSearchTable({ query, searchTerm, isRecurringSearch }) {
  const pagination = useSelector(R.prop('pagination'));
  const paginationParams = usePaginationParams();

  const { sort: projectsSort, ...projectsTableSortingProps } = useSortableTable(
    {
      column: 2,
      order: DESC_SORT_ORDER,
      tableConfig: projectsWithPriorityTableConfig,
    },
  );

  const { sort: recurringSort, ...recurringTableSortingProps } =
    useSortableTable({
      column: 8,
      order: DESC_SORT_ORDER,
      tableConfig: commonProjectTableConfig,
    });

  const sort = isRecurringSearch ? recurringSort : projectsSort;

  const tableSortingProps = isRecurringSearch
    ? recurringTableSortingProps
    : projectsTableSortingProps;

  const searchInput = useMemo(
    () => ({
      query,
      searchTerm,
      sort: prepareSortingWithSearchText(searchTerm)(sort),
      ...paginationParams,
    }),
    [searchTerm, sort, paginationParams, isRecurringSearch],
  );

  const { loading: projectsLoading, data: projectsData } = useReactiveQuery(
    PROJECTS_BY_SEARCH,
    PROJECTS_BY_SEARCH_SUB,
    {
      queryOptions: {
        variables: { searchInput },
        skip: isRecurringSearch || !searchInput,
      },
      subscriptionOptions: {
        variables: { searchInput },
        skip: isRecurringSearch || !searchInput,
      },
    },
  );

  const { loading: recurringLoading, data: recurringData } = useReactiveQuery(
    RECURRING_PROJECTS_BY_SEARCH,
    RECURRING_PROJECTS_BY_SEARCH_SUB,
    {
      queryOptions: {
        variables: { searchInput },
        skip: !isRecurringSearch || !searchInput,
      },
      subscriptionOptions: {
        variables: { searchInput },
        skip: !isRecurringSearch || !searchInput,
      },
    },
  );

  const loading = projectsLoading || recurringLoading;

  let dataHits = [];
  let counts = 0;

  if (isRecurringSearch) {
    dataHits = R.pathOr([], ['searchRecurringProjects', 'hits'], recurringData);
    counts = R.pathOr(0, ['searchRecurringProjects', 'total'], recurringData);
  } else {
    dataHits = R.pathOr([], ['searchProjects', 'hits'], projectsData);
    counts = R.pathOr(0, ['searchProjects', 'total'], projectsData);
  }

  useSetItemsCount(R.identity, counts);

  const tableProps = {
    ...tableSortingProps,
    projects: getProjects(dataHits),
    searchText: searchTerm,
    isRecurringSearch,
    pagination,
    counts,
    query,
    sort,
  };

  if (loading) return <Loader />;

  return <ProjectSearchTableComp {...tableProps} />;
}

ProjectSearchTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  query: object,
  searchTerm: string,
  isRecurringSearch: bool.isRequired,
};

ProjectSearchTable.displayName = 'ProjectSearchTable';
