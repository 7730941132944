import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import React, { useState, useCallback } from 'react';
import { func, bool, string, object } from 'prop-types';
import {
  useReactiveQuery,
  entityToOptionByLabelPath,
} from '@poly/client-utils';
import { Select } from '@poly/admin-book';
import { debounce } from '@poly/utils';

import { ALL } from '../constants/general.js';
import { DEFAULT_SUPPLIER_QUERY } from '../hocs/suppliers/queries.js';
import { SUPPLIER_SEARCH_CHANGED } from '../modules/masterSupplier/hooks/useMasterSupplierBranchesQuery.js';
import { getSupplierSearchQueryByName } from '../utils/suppliers.js';

export const SEARCH_SUPPLIERS_QUERY = gql`
  query SEARCH_SUPPLIERS_QUERY($searchInput: CollectionSearchParams!) {
    searchSuppliers(input: $searchInput) {
      hits {
        _id
        company {
          name
        }
      }
    }
  }
`;

// getSupplierSelectOptions :: (Bool, String, Object, Object) -> [Option]
const getSupplierSelectOptions = (
  includeAllOption,
  searchText,
  defaultSupplierData,
  data,
) =>
  R.compose(
    R.when(
      R.always(includeAllOption),
      R.prepend({ value: ALL, label: 'All Suppliers' }),
    ),
    R.map(entityToOptionByLabelPath(['company', 'name'])),
    R.when(
      R.always(defaultSupplierData),
      R.append(R.prop('supplier', defaultSupplierData)),
    ),
    R.pathOr([], ['searchSuppliers', 'hits']),
    // prevent options from showing up,
    // if not enough characters for text search
    R.when(R.always(searchText.length === 0), R.always({})),
  )(data);

// composeSearchQueriesIntoBoolMust :: [SearchQuery] -> SearchQuery
export const composeSearchQueriesIntoBoolMust = R.compose(
  R.assocPath(['bool', 'must'], R.__, {}),
  R.reject(R.isNil),
);

export function SupplierSelect({
  value,
  query,
  includeAllOption,
  ...restDropdownProps
}) {
  const [searchText, setSearchText] = useState('');
  const [searchTextForQuery, setSearchTextForQuery] = useState('');

  const setSearchTextForQueryDeb = useCallback(
    debounce(400)((text) => setSearchTextForQuery(text)),
    [],
  );

  const onInputChange = (text) => {
    setSearchText(text);
    setSearchTextForQueryDeb(text);
  };

  const queryInput = composeSearchQueriesIntoBoolMust([
    getSupplierSearchQueryByName(searchTextForQuery),
    query,
  ]);

  const { data } = useReactiveQuery(
    SEARCH_SUPPLIERS_QUERY,
    SUPPLIER_SEARCH_CHANGED,
    {
      queryOptions: {
        variables: {
          searchInput: {
            searchTerm: searchTextForQuery,
            from: 0,
            size: 25,
            query: queryInput,
          },
        },
        fetchPolicy: 'cache-first',
        skip: !searchTextForQuery,
      },
    },
  );

  const { data: defaultSupplier } = useQuery(DEFAULT_SUPPLIER_QUERY, {
    variables: {
      id: value,
    },
    // some weird Apollo client issue causes undefined data
    // at some pages (e.g. Pay By Bank/CC). Fetch policy fixes that:
    fetchPolicy: 'network-only',
    skip: !value || value === ALL,
  });

  const options = getSupplierSelectOptions(
    includeAllOption,
    searchText,
    defaultSupplier,
    data,
  );

  return (
    <Select
      value={value}
      options={options}
      searchText={searchText}
      onInputChange={onInputChange}
      filterOption={() => true}
      {...restDropdownProps}
    />
  );
}

SupplierSelect.defaultProps = {
  includeAllOption: false,
  name: 'SupplierSelectDropdown',
  placeholder: 'Start typing supplier',
  width: '100%',
  // we utilize ES, so this one doesn't make sense
  searchAlgorithm: () => () => true,
};

SupplierSelect.propTypes = {
  includeAllOption: bool,
  value: string,
  name: string,
  placeholder: string,
  width: string,
  searchAlgorithm: func,
  // eslint-disable-next-line react/forbid-prop-types
  query: object,
};
