import React from 'react';
import { string, arrayOf, shape } from 'prop-types';
import styled from 'styled-components';
import { getThemeColor, Row } from '@poly/admin-book';

import {
  FigureLabel,
  FigureValue,
  FigureLabelAndValueWrapper,
  TimesheetSummaryWidgetWrapper,
} from '../timesheetsTable/styles.js';
import { useTimesheetSummaryFigures } from '../timesheetsTable/TimesheetSummaryWidget.js';

const SummaryBlockWrapper = styled(
  TimesheetSummaryWidgetWrapper.withComponent(Row),
)`
  margin: 0;
  width: 100% !important;
  border-top: 1px solid ${getThemeColor(['midLight'])};
  border-bottom-right-radius: unset !important;
  border-bottom-left-radius: unset !important;
`;

const EntryBlockWrapper = FigureLabelAndValueWrapper.withComponent('td');

function EntryBlock({ label, value }) {
  return (
    <EntryBlockWrapper>
      <FigureLabel>{label}</FigureLabel>
      <FigureValue>{value}</FigureValue>
    </EntryBlockWrapper>
  );
}

EntryBlock.propTypes = {
  value: string.isRequired,
  label: string.isRequired,
};

export function SummaryBlock({ myTimesheets }) {
  const { entries } = useTimesheetSummaryFigures({ myTimesheets });

  return (
    <SummaryBlockWrapper enableHoverEffect>
      {entries.map((entry) => (
        <EntryBlock key={entry.label} {...entry} />
      ))}
    </SummaryBlockWrapper>
  );
}

SummaryBlock.propTypes = {
  myTimesheets: arrayOf(
    shape({
      date: string,
      type: string,
    }),
  ),
};
