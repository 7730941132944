import React from 'react';
import { object, oneOfType, string, shape } from 'prop-types';
import { SidebarRow } from '@poly/admin-ui';
import { Text } from '@poly/admin-book';

import {
  SectionLabel,
  SectionWrapper,
} from '../components/commonSidebarComponents.js';

export function PropertySidebarSupplierWOInstructions({ property }) {
  return (
    <SectionWrapper>
      <SidebarRow>
        <SectionLabel>Default Supplier Work Order Instructions</SectionLabel>
      </SidebarRow>
      <SidebarRow>
        <Text size="12px">{property.defaultWOInstructions}</Text>
      </SidebarRow>
    </SectionWrapper>
  );
}

PropertySidebarSupplierWOInstructions.propTypes = {
  property: shape({ remarks: oneOfType([object, string]) }),
};
