import React from 'react';
import { SystemAccountTypes } from '@poly/constants';

import { ReconciliationReportPageBase } from './ReconciliationReportPageBase.js';

// eslint-disable-next-line import/no-unused-modules
export const bankReconciledTransactionsSections = [
  {
    propName: 'deposits',
    title: 'Deposits & Credits',
  },
  {
    propName: 'payments',
    title: 'Checks & Payments',
  },
];

// eslint-disable-next-line import/no-unused-modules
export const bankOutstandingTransactionsSections = [
  {
    propName: 'outstandingDeposits',
    title: 'Deposits & Credits',
  },
  {
    propName: 'outstandingPayments',
    title: 'Checks & Payments',
  },
];

export function BankReconciliationReportPage() {
  return (
    <ReconciliationReportPageBase
      title="Bank Reconciliation Report"
      reportSections={bankReconciledTransactionsSections}
      outstandingReportSections={bankOutstandingTransactionsSections}
      systemAccountType={SystemAccountTypes.BANK_ACCOUNT}
    />
  );
}
