import React from 'react';
import { gql } from '@apollo/client';
import { commonPeopleFields, commonUpdateFields } from '@poly/admin-ui';
import { Loader } from '@poly/admin-book';

import { FilesTab } from '../../../modules/tabs/FilesTab.js';
import { TasksTab } from '../../../modules/tabs/tasksTab/TasksTab.js';
import { UpdatesTab } from '../../../modules/tabs/UpdatesTab.js';
import { PeopleTab } from '../../../modules/tabs/peopleTab/PeopleTab.js';
import { useMasterSupplierTabProps } from '../hooks/useMasterSupplierTabProps.js';
import { AddContactButton } from '../../../sidebars/ContactSidebar/forms/add/AddContactButton.js';

export const MASTER_SUPPLIER_UPDATES_QUERY = gql`
  query MASTER_SUPPLIER_UPDATES_QUERY(
    $id: ID!
    $documentUpdatesInput: DocumentUpdatesInput!
  ) {
    masterSupplier(id: $id) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...commonUpdateFields
        }
        total
      }
    }
  }

  ${commonUpdateFields}
`;

export const MASTER_SUPPLIER_PEOPLE_QUERY = gql`
  query MASTER_SUPPLIER_PEOPLE_QUERY(
    $entityId: ID!
    $searchInput: CollectionSearchParams!
  ) {
    masterSupplier(id: $entityId) {
      _id
      searchContacts(input: $searchInput) {
        hits {
          ...commonPeopleFields
        }
        total
      }
    }
  }

  ${commonPeopleFields}
`;

export function MasterSupplierUpdatesTab() {
  const { loading, ...tabProps } = useMasterSupplierTabProps();

  return loading ? (
    <Loader />
  ) : (
    <UpdatesTab {...tabProps} updatesQuery={MASTER_SUPPLIER_UPDATES_QUERY} />
  );
}

export function MasterSupplierFilesTab() {
  const { loading, ...tabProps } = useMasterSupplierTabProps();

  return loading ? <Loader /> : <FilesTab {...tabProps} />;
}

export function MasterSupplierTasksTab() {
  const { loading, ...tabProps } = useMasterSupplierTabProps();

  return loading ? <Loader /> : <TasksTab {...tabProps} />;
}

export function MasterSupplierPeopleTab() {
  const { loading, ...tabProps } = useMasterSupplierTabProps();

  return loading ? (
    <Loader />
  ) : (
    <PeopleTab
      {...tabProps}
      gqlSearchQuery={MASTER_SUPPLIER_PEOPLE_QUERY}
      AddButton={AddContactButton}
    />
  );
}
