/* eslint-disable @cspell/spellchecker */
export const AccountStatuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const AccountingMethods = {
  CASH_BASIS: 'cashBasis',
  ACCRUAL_BASIS: 'accrualBasis',
};

export const AccountPositivePayBanks = {
  TOWNE_BANK: 'TowneBank',
};
