import React from 'react';
import styled from 'styled-components';
import { string, arrayOf, func, bool } from 'prop-types';

import { RejectAssetButton } from './RejectAssetButton.js';
import { ApproveAssetButton } from './ApproveAssetButton.js';

const AssetReviewPageFooterS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  max-width: 1440px;
  width: calc(100% - 60px);
  margin: 0 25px 0 35px;

  > button {
    margin-right: 15px;
  }
`;

export function AssetReviewPageFooter({
  hasError,
  rejectedAsset,
  selectedAssets,
  setSelectedAssets,
}) {
  const disabled = selectedAssets.length === 0;

  const onSuccess = () => setSelectedAssets([]);

  return (
    <AssetReviewPageFooterS>
      <RejectAssetButton
        onSuccess={onSuccess}
        id={selectedAssets[0]}
        assetName={rejectedAsset}
        disabled={disabled || selectedAssets.length > 1}
      />
      <ApproveAssetButton
        hasError={hasError}
        disabled={disabled}
        ids={selectedAssets}
        onSuccess={onSuccess}
      />
    </AssetReviewPageFooterS>
  );
}

AssetReviewPageFooter.propTypes = {
  hasError: bool,
  rejectedAsset: string,
  setSelectedAssets: func.isRequired,
  selectedAssets: arrayOf(string).isRequired,
};
