import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { func, object, string } from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import { debounce } from '@poly/utils';

import { Input } from '@poly/admin-book';
import { enterSupplierInvoiceFormId } from '../../../accounting/constants.js';
import { useCheckInvoiceNumberDuplicated } from '../../../../hooks/useCheckInvoiceNumberDuplicated.js';
import { isEditInvoiceFormDisabled } from '../helpers.js';

export function InvoiceNumberInput({ value, formData, onChange, ...props }) {
  const submitting = useSelector(
    R.path(['processes', enterSupplierInvoiceFormId]),
  );
  const [internalValue, setInternalValue] = useState(value);
  const { warning } = useCheckInvoiceNumberDuplicated(formData);

  useEffect(() => {
    if (submitting) {
      setInternalValue('');
    }
  }, [submitting]);

  useEffect(() => {
    if (value !== internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  const onChangeDebounced = useCallback(
    debounce(400)((invoiceNumber) => {
      onChange(invoiceNumber);
    }),
    [],
  );

  const onChangeInternal = (e) => {
    setInternalValue(e.target.value);
    onChangeDebounced(e.target.value);
  };

  return (
    <Input
      {...props}
      disabled={isEditInvoiceFormDisabled({ formData })}
      maxLength={50}
      warning={warning}
      value={internalValue}
      onChange={onChangeInternal}
    />
  );
}

InvoiceNumberInput.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formData: object,
};
