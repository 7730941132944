import * as R from 'ramda';
import { formatTotal, roundToCents } from './formatters.js';

// strToPositiveNumber :: String -> Number
export const strToPositiveNumber = (str) => Math.abs(Number(str));

// convertCentsToDollars :: Number -> Number
export const convertCentsToDollars = R.when(R.is(Number), R.divide(R.__, 100));

// convertDollarsToCents :: Float -> Int
export const convertDollarsToCents = R.when(
  R.is(Number),
  R.compose(roundToCents, R.multiply(100)),
);

// centsToDollarsWithFormat :: Number -> String
export const centsToDollarsWithFormat = R.compose(
  formatTotal,
  convertCentsToDollars,
);

// dollarsToCentsByPath :: [String] -> Number
export const dollarsToCentsByPath = (path) =>
  R.compose(convertDollarsToCents, R.pathOr(0, path));
