import * as R from 'ramda';
import styled from 'styled-components';

import { getThemeColor, getThemeFont } from '../utils.js';

export const FormFieldLabel = styled.label`
  width: ${({ width, position }) =>
    R.equals('top', position) ? '100%' : width};
  font-size: ${R.prop('size')};
  line-height: ${R.prop('lineHeight')};
  flex-shrink: 0;
  padding: ${R.prop('padding')};
  color: ${({ color }) => getThemeColor([color])};
  font-weight: ${getThemeFont(['regular'])};
  display: block;
  text-align: ${({ align }) => align};
`;

FormFieldLabel.defaultProps = {
  position: 'top',
  width: '190px',
  padding: '0 0 8px 0',
  size: '12px',
  lineHeight: '18px',
  color: 'darkest',
};
