import * as R from 'ramda';
import {
  isExpiredDateByPath,
  isNilByPath,
  ofArrayLegacy,
  pathEqLegacy,
} from '@poly/utils';

import { supplierTypes } from '@poly/constants';

// eslint-disable-next-line import/no-unused-modules
export const supplierDocumentStatuses = {
  MISSING: 'Missing',
  EXPIRED: 'Expired',
};

// isSubcontractorSupplier :: Supplier -> Boolean
const isSubcontractorSupplier = pathEqLegacy(
  ['type'],
  supplierTypes.SUBCONTRACTOR,
);

// isNilAndSubcontractor :: [String] -> Supplier -> Boolean
const isNilAndSubcontractor = (path) =>
  R.both(isSubcontractorSupplier, isNilByPath(path));

// isSubcontractorAndExpiredByPath :: [String] -> Supplier -> Boolean
const isSubcontractorAndExpiredByPath = (path) =>
  R.both(isSubcontractorSupplier, isExpiredDateByPath(path));

// isW9FileNotExempt :: Supplier -> Boolean
const isW9FileNotExempt = R.complement(R.pathOr(false, ['tax', 'exempt']));

// isSupplierDocumentNotExempt :: Supplier -> Boolean
const isSupplierDocumentNotExempt = R.complement(
  R.pathOr(false, ['documents', 'exempt']),
);

const warningMessagesConfigs = [
  {
    name: 'W-9',
    path: ['tax', 'w9File', 'url'],
    validation: (path) =>
      R.both(isNilAndSubcontractor(path), isW9FileNotExempt),
  },
  {
    name: 'Workers Comp Insurance',
    path: ['documents', 'wcFile', 'url'],
    validation: (path) =>
      R.both(isNilAndSubcontractor(path), isSupplierDocumentNotExempt),
    expPath: ['documents', 'wcExp'],
    expValidation: (path) =>
      R.both(
        isSubcontractorAndExpiredByPath(path),
        isSupplierDocumentNotExempt,
      ),
  },
  {
    name: 'Liability Insurance',
    path: ['documents', 'glFile', 'url'],
    validation: isNilAndSubcontractor,
    expPath: ['documents', 'glExp'],
    expValidation: isSubcontractorAndExpiredByPath,
  },
  {
    name: 'Subcontractor Agreement',
    path: ['documents', 'scaFile', 'url'],
    validation: (path) =>
      R.both(
        isNilAndSubcontractor(path),
        isNilAndSubcontractor([
          'documents',
          'subcontractorAgreement',
          'contactName',
        ]),
      ),
  },
  {
    name: 'Veteran Certification',
    path: ['documents', 'veteranCertificationFile', 'url'],
    validation: (path) =>
      R.both(isNilAndSubcontractor(path), R.prop('isVeteran')),
  },
  {
    name: 'WBE Certification',
    path: ['documents', 'wbeCertificationFile', 'url'],
    validation: (path) =>
      R.both(isNilAndSubcontractor(path), R.path(['mwbe', 'isWBE'])),
  },
  {
    name: 'MBE Certification',
    path: ['documents', 'mbeCertificationFile', 'url'],
    validation: (path) =>
      R.both(isNilAndSubcontractor(path), R.path(['mwbe', 'minority'])),
  },
];

// generateWarningMessage :: String -> SupplierDocumentConfig -> String
// SupplierDocumentConfig = {
//    name: String
//    path: [String]
//    validation: [String] -> Supplier -> Boolean
//    expPath: [String]
//    expValidation: [String] -> Supplier -> Boolean
// }
const generateWarningMessage = (suffix) =>
  R.compose(R.join(' '), R.append(suffix), ofArrayLegacy, R.prop('name'));

// checkFileExists :: Supplier -> SupplierDocumentConfig -> String
const checkFileExists = (supplier) =>
  R.ifElse(
    ({ validation, path }) => validation(path)(supplier),
    generateWarningMessage(supplierDocumentStatuses.MISSING),
    R.always(''),
  );

// checkFileExpired :: Supplier -> SupplierDocumentConfig -> String
const checkFileExpired = (supplier) =>
  R.ifElse(
    R.both(R.prop('expPath'), ({ expValidation, expPath }) =>
      expValidation(expPath)(supplier),
    ),
    generateWarningMessage(supplierDocumentStatuses.EXPIRED),
    R.always(''),
  );

// getSupplierDocumentWarnings :: Supplier -> [String]
export const getSupplierDocumentWarnings = (supplier) =>
  R.compose(
    R.reject(R.isEmpty),
    R.flatten,
    R.map(R.juxt([checkFileExists(supplier), checkFileExpired(supplier)])),
  )(warningMessagesConfigs);

// isDisplaySupplierDocumentWarning :: Supplier -> Boolean
export const isDisplaySupplierDocumentWarning = R.compose(
  R.complement(R.isEmpty),
  getSupplierDocumentWarnings,
);
