import React from 'react';
import * as R from 'ramda';
import { bool, shape, string } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { FormCreator, Input, Checkbox } from '@poly/admin-book';
import { usePristineSubscribe } from '@poly/client-routing';
import { useModalContext, useNotificationState } from '@poly/admin-ui';

const clientGLCodeFormSections = [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        label: 'Name',
        field: {
          name: 'name',
          Component: Input,
        },
        validators: [[R.identity, 'Name is required']],
      },
      {
        label: 'Code',
        field: {
          name: 'code',
          Component: Input,
        },
        validators: [[R.identity, 'Code is required']],
      },
      {
        label: 'Active',
        field: {
          name: 'isActive',
          Component: Checkbox,
        },
      },
    ],
  },
];

export const addClientGlCodeFormId = 'addClientGlCodeFormId';

export const editClientGlCodeFormId = 'editClientGlCodeFormId';

const CREATE_CLIENT_GL_CODE_MUTATION = gql`
  mutation CREATE_CLIENT_GL_CODE($input: CreateClientGLCodeInput!) {
    createClientGLCode(input: $input) {
      _id
    }
  }
`;

const EDIT_CLIENT_GL_CODE_MUTATION = gql`
  mutation EDIT_CLIENT_GL_CODE($id: ID!, $input: UpdateClientGLCodeInput!) {
    updateClientGLCode(id: $id, input: $input) {
      _id
    }
  }
`;

export const clientGLCodePropType = shape({
  _id: string.isRequired,
  code: string.isRequired,
  name: string.isRequired,
  isActive: bool.isRequired,
});

export function ClientGLCodeForm({ clientId, glCode }) {
  const isEdit = glCode?._id;

  const successMessage = `GL code was successfully ${
    isEdit ? 'edited' : 'added'
  }`;

  const formId = isEdit ? editClientGlCodeFormId : addClientGlCodeFormId;

  const mutation = isEdit
    ? EDIT_CLIENT_GL_CODE_MUTATION
    : CREATE_CLIENT_GL_CODE_MUTATION;

  const { showSuccessNotification } = useNotificationState();
  const { closeModal } = useModalContext();

  const pristineSubscribeProps = usePristineSubscribe();

  const [mutateClientGLCode] = useMutation(mutation);

  const handleSubmit = async (values) => {
    await mutateClientGLCode({
      variables: {
        ...(isEdit ? { id: glCode._id } : {}),
        input: {
          ...values,
          ...(isEdit ? {} : { clientId }),
        },
      },
    });
    showSuccessNotification(successMessage);
    closeModal(formId);
  };

  ClientGLCodeForm.propTypes = {
    clientId: string,
    glCode: clientGLCodePropType,
  };

  const initialValues = {
    isActive: true,
    ...(isEdit ? R.omit(['_id', '__typename'], glCode) : {}),
  };

  return (
    <FormCreator
      id={formId}
      formId={formId}
      sections={clientGLCodeFormSections}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      {...pristineSubscribeProps}
    />
  );
}
