export const projectSidebarTabsId = 'project_sidebar_tabs_id';
export const addTimeSidebarFormId = 'add_time_sidebar_form_id';
export const editProjectFormId = 'edit_project_form_id';
export const addProjectFormId = 'add_project_form_id';
export const updateAddedSupplierFormId = 'update_added_supplier_form_id';

export const assignSupplierFormTypes = {
  assign: 'assignSupplierFormTypes',
  add: 'addSupplierFormTypes',
  sendWOC: 'sendWorkOrderConfirmation',
  generateWOC: 'generateWorkOrderConfirmation',
};
