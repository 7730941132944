import React from 'react';
import { useOutSidebarContext } from '@poly/client-utils';

import { CustomSidebarLayout } from '../CustomSidebarLayout.js';
import { ProcedureDetailsSidebar } from './ProcedureDetailsSidebar.js';

const procedureDetailsSidebarId = 'procedureDetailsSidebarId';

export const useOpenProcedureDetailsSidebar = () => {
  const { openOutSidebar, closeOutSidebar } = useOutSidebarContext();

  const onSidebarClose = () => closeOutSidebar(procedureDetailsSidebarId);

  return (executedProcedure) => {
    openOutSidebar({
      id: procedureDetailsSidebarId,
      content: (
        <ProcedureDetailsSidebar
          executedProcedure={executedProcedure}
          onSidebarClose={onSidebarClose}
        />
      ),
      Layout: CustomSidebarLayout,
      width: 600,
    });
  };
};
