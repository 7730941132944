import { ensureIsDate } from '@poly/utils';
import {
  startOfToday,
  startOfWeek,
  endOfWeek,
  subWeeks,
  addWeeks,
} from 'date-fns';

import { MONDAY_DAY_NUMBER } from '../../forms/timesheetForm/constants.js';

const weekOptions = { weekStartsOn: MONDAY_DAY_NUMBER };

export const customRangeButtons = {
  PREVIOUS: 'Previous',
  CURRENT: 'Current',
  NEXT: 'Next',
};

export const getCustomRangeToButtons = ({ startDate, endDate }) => ({
  [customRangeButtons.CURRENT]: {
    from: startOfWeek(startOfToday(), weekOptions),
    to: endOfWeek(startOfToday(), weekOptions),
  },
  [customRangeButtons.PREVIOUS]: {
    from: subWeeks(ensureIsDate(startDate), 1),
    to: subWeeks(ensureIsDate(endDate), 1),
  },
  [customRangeButtons.NEXT]: {
    from: addWeeks(ensureIsDate(startDate), 1),
    to: addWeeks(ensureIsDate(endDate), 1),
  },
});
