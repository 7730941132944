import React from 'react';
import { useRouterParams } from '@poly/client-routing';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { defaultTheme, Loader, Status, TableCard } from '@poly/admin-book';
import { PageTabs, TableSearchInput } from '@poly/admin-ui';
import { UPDATE_CLIENT_GL_CODE_PERMISSION } from '@poly/security';

import { forceTitleCase } from '@poly/utils';
import { ClientGLCodesPageHeader } from './ClientGLCodesPageHeader.js';
import { ClientGLCodesTable } from './ClientGLCodesTable.js';
import { useClientGLCodes } from './useClientGLCodes.js';

const {
  colors: { statuses },
} = defaultTheme;

const getClientGLCodesTab = (
  status,
  glCodes,
  hasAccessToUpdateClientGlCode,
) => [
  <Status
    text={`${forceTitleCase(status)} (${glCodes.length})`}
    color={statuses[status]}
    key={`client-gl-status${status}`}
  />,
  status,
  <TableCard key={`client-gl-table${status}`}>
    <ClientGLCodesTable
      glCodes={glCodes}
      hasAccessToUpdateClientGlCode={hasAccessToUpdateClientGlCode}
    />
  </TableCard>,
];

const clientGLCodeTabStatuses = {
  active: 'active',
  inactive: 'inactive',
};

export function ClientGLCodesPage() {
  const { clientId } = useRouterParams(['clientId']);

  const hasAccessToUpdateClientGlCode = useHasUserAccessWithPermission(
    UPDATE_CLIENT_GL_CODE_PERMISSION,
  );

  const { glCodes: activeGlCodes, loading: isActiveLoading } = useClientGLCodes(
    clientId,
    true,
  );

  const { glCodes: inActiveGlCodes, loading: isInActiveLoading } =
    useClientGLCodes(clientId, false);

  const isLoading = isActiveLoading || isInActiveLoading;

  return (
    <>
      <ClientGLCodesPageHeader
        clientId={clientId}
        hasAccessToUpdateClientGlCode={hasAccessToUpdateClientGlCode}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <PageTabs
          toolBar={<TableSearchInput />}
          tabs={[
            getClientGLCodesTab(
              clientGLCodeTabStatuses.active,
              activeGlCodes,
              hasAccessToUpdateClientGlCode,
            ),
            getClientGLCodesTab(
              clientGLCodeTabStatuses.inactive,
              inActiveGlCodes,
              hasAccessToUpdateClientGlCode,
            ),
          ]}
          defaultValue={clientGLCodeTabStatuses.active}
        />
      )}
    </>
  );
}
