import {
  ConfirmCloseEditFormBtn,
  ConfirmCloseFormBtn,
  ConfirmCloseModalBtn,
} from './components/ConfirmCloseFormBtn.js';
import { confirmButtonNames } from './constants.js';
import { DeleteProjectTimeBtn } from './components/DeleteProjectTimeBtn.js';

export const confirmButtons = {
  [confirmButtonNames.CONFIRM_CLOSE_FORM]: ConfirmCloseFormBtn,
  [confirmButtonNames.CONFIRM_CLOSE_EDIT_FORM]: ConfirmCloseEditFormBtn,
  [confirmButtonNames.CONFIRM_CLOSE_MODAL]: ConfirmCloseModalBtn,
  [confirmButtonNames.DELETE_TIME]: DeleteProjectTimeBtn,
};
