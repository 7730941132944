import styled, { css } from 'styled-components';

import { getThemeColor } from '../utils.js';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 2px;

  .star-ratings {
    display: flex !important;
  }
`;

export const StarsNumber = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  position: relative;
  color: ${getThemeColor(['primaryLight'])};
  cursor: pointer;
  margin-left: 5px;
`;

export const TriangleCommon = css`
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  margin-left: -0.5em;
  box-shadow: ${({ disableBoxShadow }) =>
    disableBoxShadow ? 'none' : '-2px 2px 6px 0 #dbdde6'};
  transform-origin: 0 0;
  box-sizing: border-box;
  border: 15px solid ${getThemeColor(['black'])};
  border-color: ${getThemeColor(['transparent'])}${getThemeColor([
      'transparent',
    ])} ${getThemeColor(['white'])} ${getThemeColor(['white'])};
`;

export const PopUp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  box-shadow: ${({ disableBoxShadow, bgColor }) =>
    disableBoxShadow ? 'none' : `0 0 10px 0 ${bgColor || '#dbdde6'}`};
  border-radius: 8px;
  background-color: ${getThemeColor(['white'])};
  padding: 10px 15px;
  left: 35px;
  cursor: default;
  z-index: 1000;

  :after {
    ${TriangleCommon};

    left: -9px;
    top: 13px;
    transform: rotate(-45deg);
    margin: 0;
    border-width: 8px;
    border-color: ${getThemeColor(['white'])} ${getThemeColor(['transparent'])}
      ${getThemeColor(['transparent'])} ${getThemeColor(['white'])};
    box-shadow: ${({ disableBoxShadow, bgColor }) =>
      disableBoxShadow ? 'none' : `-3px -3px 5px 0 ${bgColor || '#dbdde6'}`};
  }
`;

export const PopUpCounter = styled.div`
  margin-left: 5px;
  line-height: 18px;
  cursor: default;
  color: ${getThemeColor(['newPrimary'])};
`;

export const LightStarsNumber = styled.div`
  font-size: 10px;
  line-height: 16px;
  color: ${getThemeColor(['secondary'])};
  margin-left: 5px;
`;
