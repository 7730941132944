import React from 'react';
import { func, number, oneOfType, shape, string, bool } from 'prop-types';

import { AmountInput } from './AmountInput.js';

export function DeductionsInput({
  deductionsAmount,
  currentBalance,
  changeDeductions,
  error,
  asCents,
}) {
  return (
    <AmountInput
      width="100%"
      error={error?.deductionsAmount}
      name="invoice-deductions-input"
      value={deductionsAmount}
      currentBalance={currentBalance}
      onChange={changeDeductions}
      dataTestId="deductions-amount-input"
      disabled={currentBalance < 0}
      asCents={asCents}
    />
  );
}

DeductionsInput.propTypes = {
  deductionsAmount: oneOfType([number, string]),
  currentBalance: number.isRequired,
  changeDeductions: func.isRequired,
  error: shape({
    deductionsAmount: string,
  }),
  asCents: bool,
};
