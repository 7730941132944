import * as R from 'ramda';
import { gql } from '@apollo/client';
import React, { useState, useMemo } from 'react';
import {
  useTableInfiniteScrollQuery,
  useRedirectIfHasNoPermissions,
  useScoredSortingWithSearchText,
} from '@poly/client-utils';
import { useTableSorting } from '@poly/admin-ui';
import { CREATE_CLIENT_INVOICE_PERMISSION } from '@poly/security';

import {
  PageWithSearchHeader,
  SearchPageHeaderContainer,
} from '../../components/PageWithSearchHeader.js';
import { CreateInvoiceSearchHeader } from './CreateInvoiceSearchHeader.js';
import { CreateClientInvoiceSearchBody } from './CreateClientInvoiceSearchBody.js';
import { createClientInvoiceTableConfig } from './createClientInvoiceTableConfig.js';
import {
  SuggestedInvoiceAmountContext,
  useSuggestedInvoiceAmountLogic,
} from './useSuggestedInvoiceAmountLogic.js';

const projectsSearchQuery = gql`
  query projectsSearchQuery($searchInput: CollectionSearchParams!) {
    searchProjects(input: $searchInput) {
      hits {
        _id
        type
        projectId
        description
        status
        accountingStatus
        workCompletionDate
        exemptSalesTax
        clientReferenceNumber
        suggestedClientInvoiceAmount
        closedAt
        parent {
          _id
          clientReferenceNumber
        }
        client {
          _id
          name

          woCharge
          configs {
            clientInvoicing {
              email {
                enabled
              }
            }
          }
        }
        jobCost
        property {
          woCharge
        }
      }
      total
    }
  }
`;

export function CreateClientInvoiceSearchPage() {
  const [searchInput, setSearchInput] = useState({});

  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: createClientInvoiceTableConfig,
    column: 1,
  });

  const modifiedSort = useScoredSortingWithSearchText({
    sort,
    searchText: searchInput.searchTerm || '',
  });

  const input = useMemo(
    () => ({
      ...searchInput,
      sort: modifiedSort,
    }),
    [searchInput, modifiedSort],
  );

  const { data, loading, tableProps } = useTableInfiniteScrollQuery(
    projectsSearchQuery,
    input,
    {
      endpointName: 'searchProjects',
      inputName: 'searchInput',
      pageSize: 1000,
    },
  );

  const projects = useMemo(
    () => R.pathOr([], ['searchProjects', 'hits'], data),
    [data],
  );

  const contextValue = useSuggestedInvoiceAmountLogic(projects);

  useRedirectIfHasNoPermissions([CREATE_CLIENT_INVOICE_PERMISSION]);

  return (
    <PageWithSearchHeader headerHeight="250px">
      <SearchPageHeaderContainer title="Create Client Invoice">
        <CreateInvoiceSearchHeader
          loading={loading}
          setSearchInput={setSearchInput}
        />
      </SearchPageHeaderContainer>
      <SuggestedInvoiceAmountContext.Provider value={contextValue}>
        <CreateClientInvoiceSearchBody
          loading={loading}
          projects={projects}
          tableProps={{ ...tableProps, ...tableSortingProps }}
        />
      </SuggestedInvoiceAmountContext.Provider>
    </PageWithSearchHeader>
  );
}
