import styled from 'styled-components';
import { Table, Button } from '@poly/admin-book';
import { Link } from '@poly/client-routing';
import { insertParamsIntoURL } from '@poly/utils';
import { string } from 'prop-types';
import React from 'react';

import { routesNames } from '../../routes/index.js';
import { FlexColumn } from '../../components/FlexContainer.js';

export const BatchInvoiceReportsFiltersContainerS = styled(FlexColumn)`
  width: 100%;
`;

export const BatchInvoiceReportsSearchHeaderButton = styled(Button)`
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
  padding: 5px 10px;
  line-height: 12px;
  min-width: 90px;
`;

export const BatchInvoiceReportsTableS = styled(Table)`
  tr {
    margin: 0 30px;
    width: calc(100% - 60px) !important;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 70px;
  }
  th:nth-child(2),
  td:nth-child(2) {
    width: 110px;
  }
  th:nth-child(3),
  td:nth-child(3) {
    width: 200px;
  }
  th:nth-child(4),
  td:nth-child(4) {
    width: 200px;
  }
  th:nth-child(5),
  td:nth-child(5) {
    width: 50px;
  }
`;

const FileLinkPdfS = styled.a`
  display: block;
`;

export function FileLinkPDF({ fileName, url }) {
  const linkProps = {
    target: '_blank',
    href: url || '#',
    children: fileName || '',
  };

  return <FileLinkPdfS key={url} {...linkProps} />;
}

FileLinkPDF.propTypes = {
  fileName: string,
  url: string,
};

export function DerailsLinkAR({ _id }) {
  const linkProps = {
    target: '_blank',
    href: insertParamsIntoURL(
      { batchId: _id },
      routesNames.BATCH_INVOICE_REPORTS_DETAILS,
    ),
    children: 'Details',
  };

  return <Link {...linkProps} />;
}

DerailsLinkAR.propTypes = {
  _id: string,
};
