import {
  bool,
  shape,
  string,
  object,
  number,
  arrayOf,
  oneOfType,
} from 'prop-types';
import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState } from 'react';
import { useCurrentUserByStoreOrQuery } from '@poly/client-utils/src/hooks/useCurrentUserByStoreOrQuery.js';
import { SiteContactInfo, SidebarRow, InfoPopover } from '@poly/admin-ui';
import { LinkButton, Text } from '@poly/admin-book';
import { pathOrNothingUI } from '@poly/client-utils';
import {
  PROJECT_QUEUE_STATUS_LABEL,
  ProjectSpendTypesUI,
  NOTHING_UI_STRING,
  AccountingStatus,
} from '@poly/constants';
import {
  formatCurrencyForViewOr,
  convertCentsToDollars,
  isNilOrEmpty,
} from '@poly/utils';
import { ShowMoreButton } from '@poly/admin-ui/src/components/Button/Button.js';

import {
  SectionLabel,
  BlockWithLabel,
  SectionWrapper,
  threeBlocksProps,
  CommonLinksWrapper,
  SidebarDetailsLinks,
  SectionWrapperWithoutBorder,
} from './commonSidebarComponents.js';
import { PurchaseOrderBalanceWarning } from '../../pages/PurchaseOrdersPage/PurchaseOrderBalanceWarning.js';
import { useSidebarLogicContext } from '../SidebarLogicContext.js';
import { getAdminPurchaseOrder } from './ClientReferenceComp.js';
import {
  getUnassignedProjectSuccessNotification,
  useUnassignedProjectsLogic,
} from '../../pages/UnassignedProjectsPage/UnassignedProjectsButtons.js';
import {
  getProjectFeeTypeByPath,
  getProjectAmountByType,
  getDetailsLinkProps,
} from './commonSidebarUtils.js';
import {
  getSectionContact,
  getSectionEditor,
  defaultTextProps,
  getSectionText,
  getContactLink,
} from './commonSidebarSectionFormatters.js';

const SidebarRowS = styled(SidebarRow)`
  flex-direction: column;
  justify-content: space-between;
  height: 50px;
`;

const projectPropTypes = {
  project: shape({
    _id: string.isRequired,
  }),
};

export function ProjectDetailsRow({ project }) {
  return (
    <SidebarRowS>
      <SectionLabel width="50%">Projects Details</SectionLabel>
      <SidebarDetailsLinks {...getDetailsLinkProps(project)} width="100%" />
    </SidebarRowS>
  );
}

ProjectDetailsRow.propTypes = projectPropTypes;

export function ServiceType({ project }) {
  return (
    <BlockWithLabel
      {...threeBlocksProps}
      id="service-type"
      label="Service Type"
      Component={getSectionText(
        pathOrNothingUI(['serviceType', 'name'])(project),
      )}
    />
  );
}

ServiceType.propTypes = projectPropTypes;

const getAdminPOCurrentBalance = R.compose(
  convertCentsToDollars,
  R.prop('currentBalance'),
  getAdminPurchaseOrder,
);

const POWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

// isProjectNotInvoiced :: Project -> Boolean
export const isProjectNotInvoiced = R.compose(
  R.includes(R.__, [
    AccountingStatus.PENDING,
    AccountingStatus.READY_TO_INVOICE,
  ]),
  R.prop('accountingStatus'),
);

function ProjectAmountComp({ isBid, project, amount }) {
  const currentBalance = getAdminPOCurrentBalance(project);

  const isExceedsBalance = currentBalance && amount && amount > currentBalance;

  const warning = `The ${
    isBid ? 'Bid Amount' : 'NTE'
  } amount exceeds the balance of the PO.`;

  return (
    <POWrapper>
      <Text {...defaultTextProps}>
        {formatCurrencyForViewOr(NOTHING_UI_STRING)(amount)}
      </Text>
      {!!isExceedsBalance && isProjectNotInvoiced(project) && (
        <PurchaseOrderBalanceWarning warning={warning} />
      )}
    </POWrapper>
  );
}

ProjectAmountComp.propTypes = {
  isBid: bool,
  amount: number,
  project: shape({}),
};

function getProjectAmountComp({ isBid, typeProp, bidPath, project }) {
  const amount = getProjectAmountByType(typeProp, bidPath)(project);

  return function () {
    return (
      <ProjectAmountComp isBid={isBid} project={project} amount={amount} />
    );
  };
}

export function ProjectAmount({ isBid, typeProp, bidPath, project }) {
  return (
    <BlockWithLabel
      {...threeBlocksProps}
      id={isBid ? 'bid-amount' : 'client-nte'}
      label={isBid ? 'Bid Amount' : 'Client NTE'}
      Component={getProjectAmountComp({ isBid, typeProp, bidPath, project })}
    />
  );
}

ProjectAmount.propTypes = {
  ...projectPropTypes,
  isBid: bool,
  typeProp: string,
  bidPath: arrayOf(string),
};

export function FeeType({ project, propPath }) {
  return (
    <BlockWithLabel
      {...threeBlocksProps}
      id="fee-type"
      label="Fee Type"
      Component={getSectionText(getProjectFeeTypeByPath(propPath)(project))}
    />
  );
}

FeeType.propTypes = {
  ...projectPropTypes,
  propPath: arrayOf(string).isRequired,
};

export function NotRMComponent({ id, text }) {
  return (
    <BlockWithLabel
      id={id}
      {...threeBlocksProps}
      label="Not R&M"
      Component={getSectionText(text)}
    />
  );
}

NotRMComponent.propTypes = {
  id: string.isRequired,
  text: string.isRequired,
};

export function MonthlyHousekeepingRow({
  clientMonthlyFee,
  supplierMonthlyFee,
  clientId,
  supplierId,
}) {
  return (
    <>
      <BlockWithLabel
        {...threeBlocksProps}
        id={clientId}
        label="Monthly Housekeeping Client"
        Component={getSectionText(clientMonthlyFee)}
      />
      <BlockWithLabel
        {...threeBlocksProps}
        id={supplierId}
        label="Monthly Housekeeping Supplier"
        Component={getSectionText(supplierMonthlyFee)}
      />
    </>
  );
}

MonthlyHousekeepingRow.propTypes = {
  clientMonthlyFee: string.isRequired,
  supplierMonthlyFee: string.isRequired,
  clientId: string.isRequired,
  supplierId: string.isRequired,
};

const QueueTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const TextS = styled(Text)`
  margin-right: 5px;
`;

// getProjectManager :: Project -> User
const getProjectManager = R.prop('manager');

function AACManagerComponent({ project, ...props }) {
  const { user } = useCurrentUserByStoreOrQuery();

  const aacManager = getProjectManager(project);
  const PopoverParent = getContactLink(aacManager);

  const notification = getUnassignedProjectSuccessNotification([project._id]);

  const pickupProject = useUnassignedProjectsLogic(user?._id, notification, [
    project._id,
  ]);

  return !aacManager ? (
    <QueueTextWrapper>
      <TextS {...{ ...props, ...defaultTextProps }}>
        {PROJECT_QUEUE_STATUS_LABEL}
      </TextS>
      <LinkButton onClick={pickupProject}>(pick-up)</LinkButton>
    </QueueTextWrapper>
  ) : (
    <InfoPopover
      {...{ ...props, ...aacManager, PopoverParent }}
      position="bottom"
    />
  );
}

AACManagerComponent.propTypes = {
  project: shape({
    _id: string,
    manager: shape({}),
  }),
};

export function AACManager({ project }) {
  return (
    <BlockWithLabel
      id="manager"
      label="Assigned CSR"
      {...threeBlocksProps}
      width="calc(50% - 12px)"
      project={project}
      Component={AACManagerComponent}
    />
  );
}

AACManager.propTypes = projectPropTypes;

export function ClientManager({ project }) {
  return (
    <BlockWithLabel
      id="client-manager"
      label="Client Manager"
      {...threeBlocksProps}
      width="calc(50% - 12px)"
      position="left"
      Component={getSectionContact(pathOrNothingUI(['clientManager'])(project))}
    />
  );
}

ClientManager.propTypes = projectPropTypes;

export function SiteContactor({ project }) {
  return (
    <BlockWithLabel
      id="site-contact"
      label="Site Contact"
      position="left"
      margin="0"
      width="calc(50% - 12px)"
      propertyPhone={R.path(['property', 'phone'])(project)}
      user={R.prop('siteContact')(project)}
      Component={SiteContactInfo}
    />
  );
}

SiteContactor.propTypes = projectPropTypes;

export function EntityRemarks({ entity, withBorder }) {
  const Wrapper = withBorder ? SectionWrapper : SectionWrapperWithoutBorder;
  return (
    <Wrapper>
      <SidebarRow>
        <SectionLabel>Remarks</SectionLabel>
      </SidebarRow>
      <SidebarRow>
        {getSectionEditor(entity.remarks)({ id: `remarks${entity._id}` })}
      </SidebarRow>
    </Wrapper>
  );
}

EntityRemarks.propTypes = {
  entity: shape({ remarks: oneOfType([object, string]) }),
  withBorder: bool,
};

const CommonLinksWrapperS = styled(CommonLinksWrapper)`
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
  max-height: ${({ showMore }) => (showMore ? 'auto' : '74px')};
  overflow: hidden;

  a:not(:last-child) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100% - 30px);
  }
`;

function getSidebarSubPropertiesLinks({ subProperties }) {
  return function () {
    const { PropertyLink } = useSidebarLogicContext();
    const [showMore, setShowMore] = useState(false);

    if (isNilOrEmpty(subProperties)) {
      return NOTHING_UI_STRING;
    }

    return (
      <>
        <CommonLinksWrapperS showMore={showMore}>
          {subProperties.map((property) => (
            <PropertyLink key={property._id} {...property} />
          ))}
        </CommonLinksWrapperS>
        {subProperties.length > 3 ? (
          <ShowMoreButton showMore={showMore} setShowMore={setShowMore} />
        ) : null}
      </>
    );
  };
}

const BlockWithLabelS = styled(BlockWithLabel)`
  position: relative;
`;

// isSubPropertiesAllowed :: Project -> Boolean
const isSubPropertiesAllowed = R.path(['property', 'isMaster']);

export function SubPropertiesLinks({ project }) {
  if (!isSubPropertiesAllowed(project)) {
    return null;
  }

  return (
    <SidebarRow>
      <BlockWithLabelS
        width="100%"
        id="sub-properties"
        // eslint-disable-next-line @cspell/spellchecker
        label="Subproperties"
        Component={getSidebarSubPropertiesLinks(project)}
      />
    </SidebarRow>
  );
}

SubPropertiesLinks.propTypes = {
  project: shape({
    subProperties: arrayOf(shape({ _id: string, name: string })),
  }),
};

// getProjectSpendTypeUI :: Project -> String
const getProjectSpendTypeUI = R.compose(
  R.defaultTo(NOTHING_UI_STRING),
  R.prop(R.__, ProjectSpendTypesUI),
  R.prop('spendType'),
);

export function ProjectSpendType({ project }) {
  return (
    <BlockWithLabel
      {...threeBlocksProps}
      id="spend-type"
      label="Spend Type"
      Component={getSectionText(getProjectSpendTypeUI(project))}
    />
  );
}

ProjectSpendType.propTypes = {
  ...projectPropTypes,
};
