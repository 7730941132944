import React from 'react';
import { Select } from '@poly/admin-book';
import { bool, func, instanceOf, shape, arrayOf, string } from 'prop-types';

import { changeFormEntries } from '../helpers/mutators.js';
import { getTimeSheetTypeOptions } from './getTimeSheetTypeOptions.js';

export function TimesheetTypeSelect({
  formData: { isManual, date, timeSheets, isEdit, currentTimesheet },
  changeFieldValue,
  onChange,
  ...restProps
}) {
  const options = getTimeSheetTypeOptions({
    isManual,
    value: currentTimesheet?.type,
  });

  const onChangeHandler = (type) => {
    onChange(type);
    if (!isEdit) {
      changeFormEntries({ date, type }, timeSheets, changeFieldValue);
    }
  };

  const selectProps = {
    ...restProps,
    options,
    name: 'SupplierStatusSelect',
    onChange: onChangeHandler,
  };

  return <Select {...selectProps} />;
}

TimesheetTypeSelect.displayName = 'SupplierStatusSelect';

TimesheetTypeSelect.propTypes = {
  formData: shape({
    isManual: bool,
    isEdit: bool,
    date: instanceOf(Date),
    timeSheets: arrayOf(
      shape({
        date: string,
        type: string,
      }),
    ),
  }),
  changeFieldValue: func,
  onChange: func,
  value: string,
};
