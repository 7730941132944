import * as R from 'ramda';
import { string } from 'prop-types';
import styled from 'styled-components';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHasUserAccessWithPermission } from '@poly/client-utils/src/hooks/useHasUserAccessWithPermission.js';
import { extractTablePropsFromConfig, useTableSorting } from '@poly/admin-ui';
import {
  applyOr,
  centsToDollarsWithFormat,
  formatTotal,
  propEqLegacy,
} from '@poly/utils';
import { FULL_ACCESS_PERMISSION } from '@poly/security';
import {
  keywordSortQuery,
  commonSortQuery,
} from '@poly/client-utils/src/sorting.js';
import {
  DefaultBodyWrapper,
  defaultTheme,
  TableCard,
  BodyRow,
  Loader,
  Status,
  Table,
} from '@poly/admin-book';
import {
  NOTHING_UI_STRING,
  InvoicesStatuses,
  ASC_SORT_ORDER,
} from '@poly/constants';

import { MixedBodyRow } from './MixedBodyRow.js';
import { ClickableRow } from './ClickableRow.js';
import { ApprovePostSupplierInvoicesTabsMap } from './constants.js';
import { setPreviewIds } from '../../redux/requestedSupplierDocumentsReducer.js';
import { useSidebarLogicContext } from '../../sidebars/SidebarLogicContext.js';
import { useApproveInvoices } from './useApproveInvoices.js';

const { TO_REVIEW, SUBMITTED_BY_ME } = ApprovePostSupplierInvoicesTabsMap;

const {
  colors: {
    statuses: { active, error },
  },
} = defaultTheme;

const TableS = styled(Table)`
  td:nth-child(1),
  th:nth-child(1) {
    width: 25px;
    padding-left: 0;
    padding-top: 15px;
  }
  td:nth-child(4),
  th:nth-child(4) {
    width: 20%;
  }
  td:nth-child(6),
  th:nth-child(6),
  td:nth-child(7),
  th:nth-child(7) {
    width: 150px;
  }
`;

function InvoicePropertyLink(invoice) {
  const { PropertyLink } = useSidebarLogicContext();

  const property = R.path(['project', 'property'], invoice);

  if (!property) {
    return NOTHING_UI_STRING;
  }
  return <PropertyLink {...property} />;
}

function InvoiceClientLink(invoice) {
  const { ClientLink } = useSidebarLogicContext();

  const client = invoice?.client;

  if (!client) {
    return NOTHING_UI_STRING;
  }

  return <ClientLink {...client} />;
}

function InvoiceProjectLink(invoice) {
  const { ProjectLink } = useSidebarLogicContext();

  const project = R.path(['project'], invoice);

  if (!project) {
    return NOTHING_UI_STRING;
  }
  return <ProjectLink skipOpen {...project} />;
}

function InvoiceStatusCircle({ status }) {
  const color = status === InvoicesStatuses.REJECTED ? error : active;
  return <Status color={color} />;
}

InvoiceStatusCircle.propTypes = {
  status: string.isRequired,
};

// getSupplierNte :: Project -> Money
const getSupplierNte = R.converge(R.compose(R.prop('nte'), R.find), [
  R.compose(propEqLegacy('_id'), R.path(['supplier', '_id'])),
  R.compose(R.pathOr([], ['project', 'suppliers'])),
]);

const approveInvoicesTableConfig = [
  ['', InvoiceStatusCircle],
  ['Project', InvoiceProjectLink],
  ['Supplier', R.path(['supplier', 'company', 'name'])],
  ['Property', InvoicePropertyLink],
  ['Client', InvoiceClientLink, keywordSortQuery(['client', 'name'])],
  // eslint-disable-next-line @cspell/spellchecker
  ['SNTE', R.compose(applyOr(formatTotal, NOTHING_UI_STRING), getSupplierNte)],
  ['Inv Amount', R.compose(centsToDollarsWithFormat, R.prop('total'))],
];

const getRowComponent = R.cond([
  [
    R.either(
      propEqLegacy('tab', TO_REVIEW),
      R.both(
        R.prop('isUserWithFullAccess'),
        propEqLegacy('tab', SUBMITTED_BY_ME),
      ),
    ),
    R.always(ClickableRow),
  ],
  [propEqLegacy('tab', SUBMITTED_BY_ME), R.always(BodyRow)],
  // next case for "All" tab
  [R.T, R.always(MixedBodyRow)],
]);

// getApproveInvoicesIds :: Boolean -> [Invoice] -> [ID]
const getApproveInvoicesIds = (isUserWithFullAccess) =>
  R.compose(
    R.pluck('_id'),
    R.unless(R.always(isUserWithFullAccess), R.reject(R.prop('isOwnInvoice'))),
    R.defaultTo([]),
  );

export function ApproveInvoicesTable({
  tab,
  userId,
  includeUserId,
  excludeUserId,
}) {
  const dispatch = useDispatch();

  const isUserWithFullAccess = useHasUserAccessWithPermission(
    FULL_ACCESS_PERMISSION,
  );

  const { sort, ...tableSortProps } = useTableSorting({
    column: 5,
    tableConfig: approveInvoicesTableConfig,
    defaultSort: commonSortQuery(['createdAt'])(ASC_SORT_ORDER),
  });

  const { invoices, refetch, tableProps, loading } = useApproveInvoices({
    sort,
    userId,
    includeUserId,
    excludeUserId,
  });

  useEffect(() => {
    dispatch(
      setPreviewIds(getApproveInvoicesIds(isUserWithFullAccess)(invoices)),
    );
  }, [invoices]);

  const { columns, headers, sortQueries } = extractTablePropsFromConfig(
    approveInvoicesTableConfig,
  );

  const RowComponent = getRowComponent({ tab, isUserWithFullAccess });

  return (
    <DefaultBodyWrapper>
      <TableCard>
        {loading ? (
          <Loader />
        ) : (
          <TableS
            showScrollBar
            overflow="auto"
            rows={invoices}
            columns={columns}
            headers={headers}
            sortQueries={sortQueries}
            RowComponent={RowComponent}
            tableRowProps={{ refetch, isUserWithFullAccess }}
            {...tableSortProps}
            {...tableProps}
          />
        )}
      </TableCard>
    </DefaultBodyWrapper>
  );
}

ApproveInvoicesTable.propTypes = {
  tab: string,
  userId: string,
  includeUserId: string,
  excludeUserId: string,
};
