import * as R from 'ramda';
import { JournalPaymentMode } from '@poly/constants';
import {
  isNilOrEmpty,
  applyIfOr,
  assocBy,
  ramdaUseWith,
  propEqLegacy,
} from '@poly/utils';

// transformInvoicesForPayment :: [Invoice] -> [InvoicePayment]
// InvoicePayment = Object
export const transformInvoicesForPayment = R.compose(
  R.map(
    R.applySpec({
      _id: R.prop('_id'),
      invoiceId: R.prop('invoiceId'),
      invoiceNumber: R.prop('invoiceNumber'),
      invoiceFile: R.prop('invoiceFile'),
      supplier: R.prop('supplier'),
      project: R.prop('project'),
      invoiceDate: R.prop('invoiceDate'),
      dueDate: R.prop('paymentDueDate'),
      paymentDueDateChangeReason: R.prop('paymentDueDateChangeReason'),
      total: R.prop('total'),
      balanceAmount: R.prop('balance'),
      // save current balance as it will be changed after calculation process
      currentBalance: R.prop('balance'),
      masterSupplierId: R.path(['supplier', 'masterSupplier', '_id']),
    }),
  ),
  R.reject(propEqLegacy('balance', 0)),
);

// calculateTotal :: (InvoicePayment -> Number) -> [InvoicePayment] -> Number
// eslint-disable-next-line import/no-unused-modules
export const calculateTotal = R.curry((getSubTotal, invoices) =>
  R.reduce(
    ramdaUseWith(R.add, [R.identity, R.compose(R.defaultTo(0), getSubTotal)]),
    0,
    invoices,
  ),
);

// isInvoiceSelected :: InvoicePayment -> Boolean
export const isInvoiceSelected = propEqLegacy('isSelected', true);

// isAllInvoicesSelected :: [InvoicePayment] -> Boolean
export const isAllInvoicesSelected = R.both(
  R.all(isInvoiceSelected),
  R.complement(R.isEmpty),
);

// filterSelectedInvoices :: [InvoicePayment] -> [InvoicePayment]
export const filterSelectedInvoices = R.filter(isInvoiceSelected);

// hasCommonMasterSupplier :: [InvoicePayment] -> Boolean
export const hasCommonMasterSupplier = (invoices) =>
  R.compose(
    R.both(
      propEqLegacy('length', 1),
      R.propSatisfies(R.complement(R.isNil), 0),
    ),
    R.uniq,
    R.map(R.prop('masterSupplierId')),
  )(invoices);

// checkMultipleSuppliers :: [InvoicePayment] -> Boolean
export const checkMultipleSuppliers = R.compose(
  R.lte(R.__, 1),
  R.length,
  R.keys,
  R.groupBy(R.prop('supplierName')),
);

// extractInvoicePaidAmount :: InvoicePayment -> Number
const extractInvoicePaidAmount = R.propOr(0, 'paidAmount');

// extractInvoiceDeductionsAmount :: InvoicePayment -> Number
const extractInvoiceDeductionsAmount = R.propOr(0, 'deductionsAmount');

// getTotalTransaction :: InvoicePayment -> Number
const getTotalTransaction = R.converge(R.add, [
  extractInvoiceDeductionsAmount,
  extractInvoicePaidAmount,
]);

// calculateDeductionsTotal :: [InvoicePayment] -> Number
export const calculateDeductionsTotal = R.compose(
  calculateTotal(extractInvoiceDeductionsAmount),
  filterSelectedInvoices,
);

// calculatePaidTotal :: [InvoicePayment] -> Number
export const calculatePaidTotal = R.compose(
  calculateTotal(extractInvoicePaidAmount),
  filterSelectedInvoices,
);

// preparePaymentsForSubmit :: ID -> [InvoicePayment] -> [PaySupplierInvoiceInput]
export const preparePaymentsForSubmit = (creditCardUserId) =>
  R.compose(
    R.map(
      R.applySpec({
        paidAmount: R.prop('paidAmount'),
        deductionsAmount: R.compose(
          R.ifElse(isNilOrEmpty, R.always(undefined), R.identity),
          R.prop('deductionsAmount'),
        ),
        discountAccountId: R.prop('discountAccountId'),
        invoiceId: R.prop('_id'),
        creditCardUserId: R.always(creditCardUserId),
      }),
    ),
    R.filter(isInvoiceSelected),
  );

// prepareFormDateForSubmit :: FormValues -> Object
// FormValues = Object
export const prepareFormDateForSubmit = R.applySpec({
  date: R.prop('date'),
  paymentMode: R.prop('paymentMode'),
  bankAccountId: R.prop('bankAccountId'),
  beginningCheckNumber: R.ifElse(
    propEqLegacy('paymentMode', JournalPaymentMode.CHECK),
    R.prop('beginningCheckNumber'),
    R.always(null),
  ),
  checkType: R.ifElse(
    propEqLegacy('paymentMode', JournalPaymentMode.CHECK),
    R.prop('checkType'),
    R.always(null),
  ),
});

// isCreditsMemoAvailable :: [InvoicePayment] -> Boolean
export const isCreditsMemoAvailable = R.any(
  R.propSatisfies(R.lt(R.__, 0), 'total'),
);

// filterSupplierDiscountAccounts :: [Account] -> [Account]
export const filterSupplierDiscountAccounts = R.filter(
  R.prop('is_supplier_payment_deduction'),
);

// transformInvoicesForExport :: ([Account], [InvoicePayment]) -> [InvoicePayment]
export const transformInvoicesForExport = (deductionsAccounts, invoices) =>
  R.map(
    R.compose(
      assocBy(
        'currentBalance',
        R.converge(R.subtract, [
          R.propOr(0, 'currentBalance'),
          getTotalTransaction,
        ]),
      ),
      assocBy(
        'deductionsAccount',
        applyIfOr(
          R.prop('discountAccountId'),
          R.compose(
            (id) => R.find(propEqLegacy('_id', id), deductionsAccounts),
            R.prop('discountAccountId'),
          ),
          {},
        ),
      ),
    ),
    invoices,
  );
