import * as R from 'ramda';
import { FormCreator, Loader } from '@poly/admin-book';
import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  NOTHING_UI_STRING,
  ProjectRequesterSurveyStatus,
} from '@poly/constants';
import {
  ESFMFullLogo,
  useModalContext,
  useOnSubmitSetStopSubmitting,
} from '@poly/admin-ui';

import { commonModalLayout } from '../../modules/forms/common.js';
import { RequesterSurveyAnswer } from './components/RequesterSurveyAnswer.js';
import { RequesterSurveyQuestion } from './components/RequesterSurveyQuestion.js';
import { RequesterSurveySubmittedCard } from './RequesterSurveySubmittedPage.js';
import {
  RequesterSurveyFormTitle,
  RequesterSurveyFormSubTitle,
} from './components/RequesterSurveyFormTitle.js';

const requesterSurveyFormId = 'requesterSurveyFormId';

const POST_REQUESTER_SURVEY_MUTATION = gql`
  mutation POST_REQUESTER_SURVEY_MUTATION(
    $id: ID!
    $input: PostRequesterSurveyInput!
  ) {
    postRequesterSurvey(id: $id, input: $input) {
      _id
      requesterSurvey {
        status
      }
    }
  }
`;

const REQUESTER_SURVEY_QUESTIONS_BY_TOKEN_QUERY = gql`
  query REQUESTER_SURVEY_QUESTIONS_BY_TOKEN_QUERY {
    requesterSurveyQuestionsByToken {
      _id
      projectId
      description
      workCompletionDate
      requesterSurvey {
        status
      }
      property {
        _id
        name
      }
      client {
        _id
        configs {
          requesterSurvey {
            questions {
              question
              type
            }
          }
        }
      }
    }
  }
`;

// getFieldRowNumberByPreviousField :: [{ layout: { row: Int } }] -> Int
const getFieldRowNumberByPreviousField = R.compose(
  R.inc,
  R.pathOr(4, ['layout', 'row']),
  R.last,
);

const getFormCreatorSections = (questions) => [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        label: '',
        order: 1,
        layout: { row: 1 },
        field: {
          name: 'logo',
          Component: ESFMFullLogo,
        },
      },
      {
        label: '',
        order: 2,
        layout: { row: 2 },
        field: {
          name: 'formTitle',
          Component: RequesterSurveyFormTitle,
        },
      },
      {
        label: '',
        order: 3,
        layout: { row: 3, margin: '0 0 10px 0' },
        field: {
          name: 'formSubTitle',
          Component: RequesterSurveyFormSubTitle,
        },
      },
      ...questions.reduce(
        (accumulator, question, index) => [
          ...accumulator,
          {
            label: '',
            order: 4,
            layout: {
              row: getFieldRowNumberByPreviousField(accumulator),
              margin: '10px 0 5px 0',
            },
            field: {
              name: `questions[${index}].question`,
              Component: RequesterSurveyQuestion,
            },
          },
          {
            label: '',
            order: 5,
            layout: {
              row: getFieldRowNumberByPreviousField(accumulator) + 1,
              margin: '5px 0 10px 0',
            },
            field: {
              name: `questions[${index}].answer`,
              Component: RequesterSurveyAnswer,
              additionalProps: { question },
            },
            validators: [[R.identity, 'Answer for this question is required']],
          },
        ],
        [],
      ),
    ],
  },
];

// prepareFormProps :: { requesterSurveyQuestionsByToken: Project } -> FormData
const prepareFormProps = R.compose(
  R.applySpec({
    projectId: R.prop('_id'),
    surveyStatus: R.pathOr(false, ['requesterSurvey', 'status']),
    formTitle: R.compose(
      R.concat('Survey for Project: '),
      R.propOr(NOTHING_UI_STRING, 'projectId'),
    ),
    formSubTitle: R.propOr('', 'description'),
    questions: R.pathOr(
      [],
      ['client', 'configs', 'requesterSurvey', 'questions'],
    ),
  }),
  R.prop('requesterSurveyQuestionsByToken'),
);

// prepareInputForMutation :: FormData -> PostRequesterSurveyInput
const prepareInputForMutation = R.compose(
  R.objOf('questions'),
  R.map(
    R.applySpec({
      question: R.prop('question'),
      type: R.prop('type'),
      answer: R.propOr(null, 'answer'),
    }),
  ),
  R.propOr([], 'questions'),
);

// isCompletedSurvey :: ProjectRequesterSurveyStatus -> Boolean
const isCompletedSurvey = R.equals(ProjectRequesterSurveyStatus.completed);

export function RequesterSurveyForm() {
  const [surveyPosted, setSurveyPosted] = useState(false);
  const { openModalForm, closeModal } = useModalContext();
  const [postRequesterSurvey] = useMutation(POST_REQUESTER_SURVEY_MUTATION);

  const { data, loading } = useQuery(
    REQUESTER_SURVEY_QUESTIONS_BY_TOKEN_QUERY,
    { fetchPolicy: 'network-only' },
  );

  const { projectId, formTitle, formSubTitle, questions, surveyStatus } =
    prepareFormProps(data);

  const onSubmitHandler = async (FormData) => {
    try {
      await postRequesterSurvey({
        variables: {
          id: projectId,
          input: prepareInputForMutation(FormData),
        },
      });

      setSurveyPosted(true);
    } finally {
      closeModal(requesterSurveyFormId);
    }
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    requesterSurveyFormId,
    onSubmitHandler,
  );

  useEffect(() => {
    if (isCompletedSurvey(surveyStatus)) {
      setSurveyPosted(true);
    }
  }, [loading, surveyStatus]);

  useEffect(() => {
    if (!loading && !R.isEmpty(questions) && !isCompletedSurvey(surveyStatus)) {
      openModalForm({
        id: requesterSurveyFormId,
        title: '',
        formId: requesterSurveyFormId,
        btnCaption: 'Submit',
        width: '800px',
        content: (
          <FormCreator
            id={requesterSurveyFormId}
            formId={requesterSurveyFormId}
            sections={getFormCreatorSections(questions)}
            layout={commonModalLayout}
            initialValues={{ formTitle, formSubTitle, questions }}
            onSubmit={onSubmit}
          />
        ),
      });
    } else {
      closeModal(requesterSurveyFormId);
    }
  }, [loading, surveyStatus]);

  if (loading) {
    return <Loader />;
  }

  if (surveyPosted) {
    return <RequesterSurveySubmittedCard />;
  }

  return null;
}
