import React from 'react';
import * as R from 'ramda';
import {
  MainHeader,
  PageHeaderContainer,
  HeaderLinksWrapper,
} from '@poly/admin-book';
import { TableSearchInput } from '@poly/admin-ui';

import { ToolBarBtnDivider } from '../modules/core/tableToolbar/tableToolBarStyles.js';
import {
  expiredProjectsUI,
  expiringMasterProjectsDays,
  projectStatusesUI,
  projectUIStatuses,
} from '../modules/core/constants/projects.js';
import { MyRecurringProjectsTab } from '../modules/tabs/projectsTab/ProjectsTab.js';
import { MyMasterProjectsTabTitle } from '../modules/tabs/projectsTab/ProjectsTabTitle.js';
import { MyRecurringProjectTableExportToXLSBtn } from '../modules/core/tableToolbar/TableExportToXLSBtn.js';
import { recurringQueriesByEndDate } from '../modules/tabs/common.js';
import { PageTabs } from '../modules/tabs/TabsWithRouter.js';
import { MyMasterProjectsPrintPDFBtn } from '../modules/tables/projectsTable/ProjectsTableToolBar.js';

const { EXPIRING30, EXPIRING60, EXPIRING90 } = expiringMasterProjectsDays;
const { ACTIVE, PAST_DUE } = projectUIStatuses;

const TABS_NAMES = [PAST_DUE, EXPIRING30, EXPIRING60, EXPIRING90, ACTIVE];

const masterProjectsTabsTitles = {
  ...R.pick([projectUIStatuses.ACTIVE], projectStatusesUI),
  ...expiredProjectsUI,
  [projectUIStatuses.PAST_DUE]: 'Expired',
};

const tabFromName = (tabName) => [
  <MyMasterProjectsTabTitle
    key={`${tabName}-title`}
    name={tabName}
    query={recurringQueriesByEndDate[tabName]}
    text={masterProjectsTabsTitles[tabName]}
  />,
  tabName,
  <MyRecurringProjectsTab
    key={tabName}
    query={recurringQueriesByEndDate[tabName]}
  />,
];

const tabs = TABS_NAMES.map(tabFromName);

export function MyMasterProjectsPage() {
  return (
    <>
      <PageHeaderContainer>
        <MainHeader>My Master Projects</MainHeader>
        <HeaderLinksWrapper>
          <MyMasterProjectsPrintPDFBtn defaultTab={projectUIStatuses.ACTIVE} />
          <ToolBarBtnDivider />
          <MyRecurringProjectTableExportToXLSBtn />
        </HeaderLinksWrapper>
      </PageHeaderContainer>
      <PageTabs
        toolBar={<TableSearchInput />}
        tabs={tabs}
        withPips
        defaultValue={projectUIStatuses.ACTIVE}
      />
    </>
  );
}
