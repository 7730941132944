import * as R from 'ramda';
import { InvoicesStatuses, ProjectType } from '@poly/constants';
import { endOfDay, startOfDay } from 'date-fns';
import { alwaysNewDate, ensureIsDate, isNilOrEmpty } from '@poly/utils';

import { searchInvoicesFilters } from '../paySuppliersConstants.js';
import {
  generateSearchQuery,
  matchQueryGenerator,
} from '../../../utils/generateSearchQuery.js';

export const paySupplierOptions = {
  PAY_BY_ACH: 'payByACH',
  PAY_BY_CREDIT_CARD: 'payByCreditCard',
  CHECK: 'Check',
};

const showOnlyWarningInvoicesQuery = {
  bool: {
    should: [
      { term: { 'supplier.hasDocumentsWarning': true } },
      { exists: { field: 'paymentDueDateChangeReason' } },
    ],
  },
};

// paymentTypeQueryGenerator :: String -> Object
const paymentTypeQueryGenerator = R.cond([
  [
    R.equals(paySupplierOptions.PAY_BY_ACH),
    R.always({ match: { 'supplier.bank.payByACH': true } }),
  ],
  [
    R.equals(paySupplierOptions.PAY_BY_CREDIT_CARD),
    R.always({ match: { 'supplier.finance.acceptsCreditCard': true } }),
  ],
  [
    R.T,
    R.always({
      bool: {
        must_not: [
          { match: { 'supplier.finance.acceptsCreditCard': true } },
          { match: { 'supplier.bank.payByACH': true } },
        ],
      },
    }),
  ],
]);

// getGteDateElasticQueryByProp :: String ->  Object -> Object
const getGteDateElasticQueryByProp = (propName) =>
  R.compose(
    R.objOf('gte'),
    R.when(R.identity, R.compose(startOfDay, ensureIsDate)),
    R.prop(propName),
  );

// getLteDateElasticQueryByProp ::  String ->  Object
const getLteDateElasticQueryByProp = (propName) =>
  R.compose(
    R.objOf('lte'),
    R.when(R.identity, R.compose(endOfDay, ensureIsDate)),
    R.prop(propName),
  );

// getQueryByBooleanField :: ElasticQuery -> Boolean -> ElasticQuery
const getQueryByBooleanField = (query) =>
  R.ifElse(R.identity, R.always(query), R.always(null));

const searchInvoicesQueryGenerators = {
  [searchInvoicesFilters.terms]: matchQueryGenerator('supplier.finance.terms'),
  [searchInvoicesFilters.paymentType]: paymentTypeQueryGenerator,
  [searchInvoicesFilters.serviceTypeId]: matchQueryGenerator(
    'project.serviceTypeId',
  ),
  [searchInvoicesFilters.clientId]: matchQueryGenerator('clientId'),
  [searchInvoicesFilters.projectId]: matchQueryGenerator('documentId'),
  [searchInvoicesFilters.supplierId]: matchQueryGenerator('supplierId'),
  [searchInvoicesFilters.masterSupplierId]:
    matchQueryGenerator('masterSupplierId'),
  [searchInvoicesFilters.invoiceId]: matchQueryGenerator('_id'),
  [searchInvoicesFilters.invoiceDate]: R.compose(
    R.assocPath(['range', 'invoiceDate'], R.__, {}),
    R.converge(R.mergeDeepLeft, [
      getGteDateElasticQueryByProp('startDate'),
      getLteDateElasticQueryByProp('endDate'),
    ]),
  ),
  [searchInvoicesFilters.paymentDueDate]: R.compose(
    R.assocPath(['range', 'paymentDueDate'], R.__, {}),
    R.converge(R.mergeDeepLeft, [
      getGteDateElasticQueryByProp('startDueDate'),
      getLteDateElasticQueryByProp('endDueDate'),
    ]),
  ),
  [searchInvoicesFilters.pastDueDate]: R.compose(
    R.assocPath(['range', 'paymentDueDate', 'lte'], R.__, {}),
    R.ifElse(R.identity, R.compose(startOfDay, alwaysNewDate), R.always(null)),
  ),
  [searchInvoicesFilters.showOnlyWarnings]: getQueryByBooleanField(
    showOnlyWarningInvoicesQuery,
  ),

  [searchInvoicesFilters.w9Absent]: getQueryByBooleanField({
    term: { 'supplier.w9Absent': true },
  }),
};

// eslint-disable-next-line import/no-unused-modules
export const mandatoryMust = [
  {
    terms: {
      status: [InvoicesStatuses.PENDING, InvoicesStatuses.PARTIALLY_PAID],
    },
  },
];

const skipReportOnlyInvoicesQuery = {
  bool: {
    must_not: [{ match: { 'project.type': ProjectType.REPORT_ONLY } }],
  },
};

// generateSearchInvoicesQuery :: SearchFilters -> ElasticQuery
// SearchFilters, ElasticQuery = Object
export const generateSearchInvoicesQuery = R.compose(
  R.assocPath(['bool', 'filter'], R.__, {}),
  R.mergeDeepLeft(skipReportOnlyInvoicesQuery),
  R.over(
    R.lensPath(['bool', 'must']),
    R.compose(
      R.unnest,
      R.juxt([
        R.compose(
          R.concat(mandatoryMust),
          R.reject(
            R.either(
              R.path(['range', 'paymentDueDate']),
              R.path(['range', 'invoiceDate']),
            ),
          ),
        ),
        R.compose(
          R.unless(R.isEmpty, R.assocPath(['bool', 'should'], R.__, {})),
          R.unless(
            R.find(
              R.either(
                R.path(['range', 'invoiceDate', 'lte']),
                R.path(['range', 'paymentDueDate', 'gte']),
              ),
            ),
            R.reject(R.complement(R.path(['range', 'paymentDueDate', 'gte']))),
          ),
          R.filter(
            R.either(
              R.path(['range', 'paymentDueDate', 'lte']),
              R.path(['range', 'invoiceDate', 'lte']),
            ),
          ),
        ),
      ]),
      R.reject(isNilOrEmpty),
    ),
  ),

  generateSearchQuery(searchInvoicesQueryGenerators),
);
