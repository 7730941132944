import React from 'react';
import { shape, string } from 'prop-types';
import { keywordSortQuery } from '@poly/client-utils';
import { NOTHING_UI_STRING } from '@poly/constants';
import { highlightedTextPropType } from '@poly/admin-ui';

import { useSidebarLogicContext } from '../../../sidebars/SidebarLogicContext.js';

function PropertySidebarLink({ property }) {
  const { PropertyLink } = useSidebarLogicContext();

  if (!property?._id) {
    return NOTHING_UI_STRING;
  }

  const { _id, name } = property;

  return <PropertyLink {...{ _id, name }} />;
}

PropertySidebarLink.propTypes = {
  property: shape({
    _id: string,
    name: highlightedTextPropType,
  }),
};

export const propertyEntityColumn = ['Property', PropertySidebarLink];

export const propertyEntityColumnWithSort = [
  ...propertyEntityColumn,
  keywordSortQuery(['property', 'name']),
];
