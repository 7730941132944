import styled from 'styled-components';
import React, { useState } from 'react';
import { string, func, shape } from 'prop-types';
import { TooltipButton, entities } from '@poly/admin-ui';
import {
  TriangleCommon,
  getThemeColor,
  getThemeProp,
  LinkButton,
  Checkbox,
  Button,
} from '@poly/admin-book';

import { ProjectOccurrence } from '../../modules/core/constants/projects.js';
import { useAddProjectSidebar } from '../ProjectSidebar/forms/add/useAddProjectSidebar.js';

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  bottom: -10px;
  left: -55px;
  position: absolute;
  background: white;
  width: ${({ width }) => width || '160px'};
  box-shadow: ${getThemeProp(['defaultBoxShadow'])};
  padding: 10px;
  border-radius: 5px;

  & > div {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 10px;
  }

  & > button {
    width: 100%;
  }

  :after {
    ${TriangleCommon};
    left: 75px;
    bottom: -27px;
    transform: rotate(-135deg);
    margin: 0;
    border-width: 8px;
    border-color: ${getThemeColor(['white'])} ${getThemeColor(['transparent'])}
      ${getThemeColor(['transparent'])} ${getThemeColor(['white'])};
    box-shadow: -3px -3px 5px 0 #dbdde6;
  }
`;

function CloneButtonTooltip({ entity, closeTooltip }) {
  const isRecurring = entity.name === entities.RECURRING_PROJECT;

  const [withAssets, setWithAssets] = useState(false);
  const [withSuppliers, setWithSuppliers] = useState(!isRecurring);
  const [isPreventativeRepair, setIsPreventativeRepair] = useState(true);
  const { projectId, _id, name } = entity;

  const onCloseParams = projectId ? [false, name, projectId] : [];
  const openAddProjectSidebar = useAddProjectSidebar(...onCloseParams);

  const onCloneProject = (e) => {
    closeTooltip(e);
    openAddProjectSidebar({
      cloneWithoutAssets: !withAssets,
      cloneWithoutSuppliers: !withSuppliers,
      cloneAsPreventativeRepair: isPreventativeRepair,
      ...(isRecurring
        ? {
            cloneFromMasterProjectId: _id,
            type: ProjectOccurrence.PREVENTIVE_MAINTENANCE_OCCURRENCE,
          }
        : {
            cloneFromProjectId: _id,
          }),
    });
  };

  return (
    <TooltipWrapper width={isRecurring ? null : '220px'}>
      {!isRecurring && (
        <Checkbox
          value={isPreventativeRepair}
          name="is-preventative-repair"
          onChange={setIsPreventativeRepair}
          label="Create as Preventative Repair"
        />
      )}
      <Checkbox
        value={withSuppliers}
        name="with-suppliers"
        onChange={setWithSuppliers}
        label={isRecurring ? 'Clone with Suppliers' : 'Copy Suppliers'}
      />
      {isRecurring && (
        <Checkbox
          value={withAssets}
          name="with-assets"
          onChange={setWithAssets}
          label="Clone with Assets"
        />
      )}
      <Button onClick={onCloneProject} size="small">
        Clone
      </Button>
    </TooltipWrapper>
  );
}

CloneButtonTooltip.propTypes = {
  entity: shape({
    _id: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  closeTooltip: func.isRequired,
};

export function ClonePMProjectButton({ entity, title }) {
  const btnProps = {
    Wrapper: LinkButton,
    tooltipBtnProps: { entity },
    Tooltip: CloneButtonTooltip,
    style: { position: 'relative' },
    tooltipProps: { position: 'right', top: '-20px' },
  };

  return <TooltipButton {...btnProps}>{title}</TooltipButton>;
}

ClonePMProjectButton.propTypes = {
  title: string,
  entity: shape({
    _id: string.isRequired,
    name: string.isRequired,
  }).isRequired,
};
