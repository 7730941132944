export const ServiceTypeStatuses = {
  active: 'active',
  inactive: 'inactive',
};

export const PSCategoryCodes = {
  76110000: '76110000',
  46000000: '46000000',
  81112201: '81112201',
  80161508: '80161508',
  50202310: '50202310',
  43000000: '43000000',
};

export const XeroAccountCodes = {
  502: '502',
  504: '504',
  506: '506',
  505: '505',
  507: '507',
  501: '501',
  503: '503',
};

// [<PS category code>, <PS GL code>, <PS category description>],
export const PSCategoriesDescription = [
  [PSCategoryCodes['76110000'], '82160155', 'Cleaning & Janitorial Services'],
  [PSCategoryCodes['46000000'], '82160155', 'Security Charges'],
  [PSCategoryCodes['81112201'], '83325248', 'Fees- Miscellaneous'],
  [PSCategoryCodes['80161508'], '85690001', 'Document Shredding'],
  [PSCategoryCodes['50202310'], '83290001', 'Spring or mineral water'],
  [
    PSCategoryCodes['43000000'],
    '83330194',
    'Information Technology Broadcasting and Telecom.',
  ],
];

export const PSCategoryByXeroAccountCode = {
  502: PSCategoryCodes['76110000'],
  504: PSCategoryCodes['46000000'],
  506: PSCategoryCodes['81112201'],
  505: PSCategoryCodes['80161508'],
  507: PSCategoryCodes['50202310'],
  501: PSCategoryCodes['43000000'],
  503: PSCategoryCodes['81112201'],
};

export const PSCategoryDescriptionByXeroAccountCode = {
  502: 'Cleaning & Janitorial Services',
  504: 'Security Charges',
  506: 'Utilities',
  505: 'Document Shredding',
  507: 'Spring or mineral water',
  501: 'Information Technology Broadcasting and Telecommunications',
  503: 'Fees- Miscellaneous',
};

export const TIAACategoryNameByXeroAccountCode = {
  502: 'Janitorial',
  504: 'Safety & Security',
  506: 'Utilities',
  505: 'Shredding',
  507: 'Water Services (non-utility)',
  501: 'Cable/Internet/Voice',
  503: 'Maintenance Break Fixes',
};
