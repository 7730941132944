import React, { useContext, useState } from 'react';
import { Loader } from '@poly/admin-book';
import { MAX_ITEMS } from '@poly/admin-ui';
import { useRedirectIfHasNoPermissions } from '@poly/client-utils';
import { VOID_CLIENT_INVOICE_PERMISSION } from '@poly/security';

import { EmptyListMessage } from '../../components/EmptyListMessage.js';
import { useSearchClientInvoicesQuery } from '../SearchClientInvoices/useSearchClientInvoices.js';
import { SearchVoidClientInvoicesPageTable } from './SearchVoidClientInvoicesPageTable.js';
import { SearchVoidClientInvoicesPageHeader } from './SearchVoidClientInvoicesPageHeader.js';
import {
  PageWithSearchHeader,
  PageWithSearchHeaderContext,
} from '../../components/PageWithSearchHeader.js';
import {
  WhiteBodyWrapper,
  PayInvoicesTableCard,
} from '../PaySuppliersPage/PaySuppliersComponents.js';

export function SearchVoidClientInvoicesPage() {
  const { contentHeight } = useContext(PageWithSearchHeaderContext);
  const [filters, setFilters] = useState(null);

  const { clientInvoices, loading, tableProps, refetch } =
    useSearchClientInvoicesQuery(filters, MAX_ITEMS);

  useRedirectIfHasNoPermissions([VOID_CLIENT_INVOICE_PERMISSION], false);

  return (
    <PageWithSearchHeader headerHeight="190px">
      <SearchVoidClientInvoicesPageHeader
        setFilters={setFilters}
        loading={loading}
      />
      {loading ? (
        <Loader />
      ) : (
        <WhiteBodyWrapper height={contentHeight}>
          <PayInvoicesTableCard height="100%">
            {clientInvoices.length === 0 ? (
              <EmptyListMessage />
            ) : (
              <SearchVoidClientInvoicesPageTable
                refetch={refetch}
                clientInvoices={clientInvoices}
                {...tableProps}
              />
            )}
          </PayInvoicesTableCard>
        </WhiteBodyWrapper>
      )}
    </PageWithSearchHeader>
  );
}
