import React from 'react';
import { Icon, getThemeColor } from '@poly/admin-book';
import styled from 'styled-components';

const IconS = styled(Icon)`
  margin: 2px 5px 0 0;
  cursor: pointer;
  &:hover path {
    fill: ${getThemeColor(['white'])};
  }
`;

const googleDocsURL =
  'https://docs.google.com/document/d/1f4owxXNMCaL0xtKgHj9EG7xRscrNUtP0/edit?usp=sharing';

export function GoogleDocsLink() {
  return (
    <a href={googleDocsURL} rel="noopener noreferrer" target="_blank">
      <IconS name="confirm" color="#879dd2" size={21} />
    </a>
  );
}
