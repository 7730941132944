import React from 'react';
import * as R from 'ramda';
import { bool, shape, string, func, number } from 'prop-types';
import { InvoiceTypes } from '@poly/constants';
import { Select } from '@poly/admin-book';

import { prepareConstantsToSelect } from '../supplierInvoiceFormUtils.js';
import { isEditInvoiceFormDisabled } from '../helpers.js';

// isInvoiceHaveType :: Invoice -> Boolean
const isInvoiceHaveType = R.complement(R.propSatisfies(R.isNil, 'type'));

const creditCardExpenseInvoiceOption = {
  value: InvoiceTypes.creditCardExpense,
  label: 'Credit Card Expense',
};

const bankExpenseInvoiceOption = {
  value: InvoiceTypes.bankExpense,
  label: 'Bank Expense',
};

const supplierInvoicesOptions = [InvoiceTypes.breakdown, InvoiceTypes.fixed];

// generateSelectOptions :: Boolean -> [Pair String String] -> [Pair String String]
const generateSelectOptions = (typeExists) =>
  R.compose(
    R.when(
      R.always(typeExists),
      R.concat([creditCardExpenseInvoiceOption, bankExpenseInvoiceOption]),
    ),
    R.map(R.applySpec({ value: R.nth(0), label: R.nth(1) })),
    prepareConstantsToSelect,
  );

export function InvoiceTypeSelect({
  formData,
  changeFieldValue,
  onChange: onChangeBase,
  ...props
}) {
  const { isEdit, invoice } = formData;

  const onChange = (value) => {
    if (value === InvoiceTypes.fixed) {
      const newTotal = R.pathOr(null, ['invoice', 'total'], formData);

      changeFieldValue('labourCost', null);
      changeFieldValue('materialCost', null);
      changeFieldValue('total', newTotal);
    }
    onChangeBase(value);
  };

  const dropdownProps = {
    ...props,
    onChange,
    disabled:
      (!!isEdit && isInvoiceHaveType(invoice)) ||
      isEditInvoiceFormDisabled({ formData }),
    options: generateSelectOptions(isInvoiceHaveType(invoice))(
      supplierInvoicesOptions,
    ),
  };

  return <Select {...dropdownProps} />;
}

InvoiceTypeSelect.propTypes = {
  formData: shape({
    isEdit: bool,
    invoice: shape({ type: string, total: number }),
  }),
  onChange: func.isRequired,
  changeFieldValue: func.isRequired,
};
