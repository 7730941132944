export const initialPagination = {
  allItemsCount: 0,
  itemsPerPage: 25,
  currentPage: 1,
};

// paginationToQueryParams :: PaginationParams -> QueryParams
// PaginationParams = Object
// QueryParams = Object
export const paginationToQueryParams = ({ itemsPerPage, currentPage }) => ({
  from: currentPage === 0 ? 0 : itemsPerPage * (Number(currentPage) - 1),
  size: itemsPerPage,
});
