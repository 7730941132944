import React from 'react';
import { useMutation } from '@apollo/client';
import { func, string, shape, bool, object } from 'prop-types';
import { useCurrentUserByStoreOrQuery } from '@poly/client-utils/src/hooks/useCurrentUserByStoreOrQuery.js';
import { usePristineSubscribe, useRouterQuery } from '@poly/client-routing';
import { UPDATE_PERSIST_KEY_PREFIX } from '@poly/constants/src/updates.js';
import { usePersistDnDAreaWithMentions } from '@poly/client-utils';
import { FormCreator } from '@poly/admin-book';

import { updateSections } from './sections.js';
import { commonModalLayout } from '../common.js';
import { ADD_UPDATE_MUTATION } from '../../../hooks/updates/mutations.js';
import { useOnSubmitSetStopSubmitting } from '../../../hooks/useOnSubmitSetStopSubmitting.js';
import { useNotificationState } from '../../../hooks/useNotificationState.js';
import { updateCacheHandler } from '../../../utils/apollo-cache.js';
import { getEmailsToSendTo } from '../fields/MailTo/mailToUtils.js';
import {
  mapCreateUpdateInputToUpdate,
  prependNewEntityUpdate,
} from '../../../utils/updates.js';
import {
  mapAttachmentsBeforeUpdate,
  initialValues,
} from './addUpdateFormUtils.js';

const useOnSubmitUpdateForm = (
  entity,
  collection,
  user,
  onCancel,
  updateCacheParams,
  successMessage,
  cleanupRetainedValue,
) => {
  const { showSuccessNotification, showErrorNotification } =
    useNotificationState();

  const [createUpdateMutation] = useMutation(ADD_UPDATE_MUTATION);

  return async ({ updateDetails, updateType, mailTo }) => {
    const appendUpdate = prependNewEntityUpdate(entity.name);

    const entityUpdateCacheHandler = (newUpdate) =>
      updateCacheHandler(
        updateCacheParams.query,
        updateCacheParams.variables,
        appendUpdate(newUpdate),
      );

    const emailsToSendUpdate = getEmailsToSendTo(mailTo);

    const updateInput = {
      collection,
      documentId: entity._id,
      message: updateDetails.text,
      mentions: updateDetails.mentions,
      type: updateType,
      filesAttachments: mapAttachmentsBeforeUpdate(updateDetails.attachments),
      emailTo: emailsToSendUpdate,
    };

    await createUpdateMutation({
      variables: { input: updateInput },
      update: entityUpdateCacheHandler(
        mapCreateUpdateInputToUpdate({
          ...updateInput,
          updateType,
          user,
        }),
      ),
    })
      .then(() => {
        showSuccessNotification(successMessage);
        cleanupRetainedValue();
        onCancel();
      })
      .catch((err) => showErrorNotification(err.message));
  };
};

export function AddUpdateForm({
  formId,
  collection,
  entity,
  onCancel,
  updateCacheParams,
  successMessage = 'Update was created',
  disableMentions = false,
}) {
  const {
    onChangePersistentValue,
    cleanupRetainedValue,
    retainedValue: updateDetails,
  } = usePersistDnDAreaWithMentions(
    `${UPDATE_PERSIST_KEY_PREFIX}${entity._id}`,
  );
  const { sidebarTab } = useRouterQuery(['sidebarTab']);

  const pristineSubscribeProps = usePristineSubscribe(
    { id: formId },
    { sidebarTab },
  );

  const { user } = useCurrentUserByStoreOrQuery();

  const onSubmitHandler = useOnSubmitUpdateForm(
    entity,
    collection,
    user,
    onCancel,
    updateCacheParams,
    successMessage,
    cleanupRetainedValue,
  );
  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  const formProps = {
    ...pristineSubscribeProps,
    initialValues: {
      ...initialValues,
      collection,
      ...(updateDetails ? { updateDetails } : {}),
    },
    sections: updateSections(entity, disableMentions, onChangePersistentValue),
    layout: commonModalLayout,
    id: formId,
    onSubmit,
  };

  return entity._id ? <FormCreator {...formProps} /> : null;
}

AddUpdateForm.displayName = 'AddUpdateForm';

AddUpdateForm.propTypes = {
  onCancel: func.isRequired,
  entity: shape({
    _id: string.isRequired,
  }),
  collection: string.isRequired,
  formId: string.isRequired,
  successMessage: string.isRequired,
  disableMentions: bool,
  updateCacheParams: shape({
    // eslint-disable-next-line react/prop-types,react/forbid-prop-types
    query: object,
  }),
};
