import { gql } from '@apollo/client';
import { commonAddressFields } from '../commonFragments.js';

export const commonUserFields = gql`
  fragment commonUserFields on User {
    _id
    fullName
  }
`;

export const commonContactPopoverFields = gql`
  fragment commonContactPopoverFields on User {
    _id
    fullName
    email
    profile {
      cellPhoneNumber
      workPhoneNumber
      workPhoneNumberExt
      faxPhoneNumber
      loginCellPhoneNumber
    }
  }
`;

const userLinks = gql`
  fragment userLinks on ContactUser {
    links {
      propertyId
      clientId
      supplierId
    }
  }
`;

export const commonPeopleFields = gql`
  fragment commonPeopleFields on ContactUser {
    _id
    fullName
    ...userLinks
    title
    email
    profile {
      cellPhoneNumber
      workPhoneNumber
      workPhoneNumberExt
      faxPhoneNumber
    }
    isClientManager
  }

  ${userLinks}
`;

const fullPeopleFields = gql`
  fragment fullPeopleFields on ContactUser {
    ...commonPeopleFields
    firstName
    lastName
    status
    remarks
    links {
      clientId
      propertyId
      supplierId
      masterSupplierId
      adminPurchaseOrderId
    }
    address {
      ...commonAddressFields
    }
    addressTwo
  }

  ${commonPeopleFields}
  ${commonAddressFields}
`;

export const contactDetailsFields = gql`
  fragment contactDetailsFields on ContactUser {
    ...fullPeopleFields
    linkedProperties {
      _id
      name
    }
    linkedClients {
      _id
      name
    }
    linkedSuppliers {
      _id
      company {
        name
      }
    }
    linkedMasterSuppliers {
      _id
      company {
        name
      }
    }
  }

  ${fullPeopleFields}
`;
