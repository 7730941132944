import * as R from 'ramda';

/**
 * assocBy :: String -> (Object -> a) -> Object -> Object
 */
export const assocBy = R.curry((propName, getPropValue, obj) =>
  R.assoc(propName, getPropValue(obj), obj),
);

/**
 * assocByPath :: [String] -> (Object -> a) -> Object
 */
export const assocByPath = R.curry((path, getPropValue, obj) =>
  R.assocPath(path, getPropValue(obj), obj),
);

// Added alias because of react-hooks/rules-of-hooks lint error
export const ramdaUseWith = R.useWith;

/**
 * mergeObjectWithArrayOfObjects :: a -> [b] -> [c]
 *   a, b, c = Object
 */
export const mergeObjectWithArrayOfObjects = ramdaUseWith(R.map, [
  R.mergeDeepRight,
  R.identity,
]);

// mergeObjectWithArrayOfObjectsLeft :: a -> [b] -> [c]
// a, b, c = Object
export const mergeObjectWithArrayOfObjectsLeft = ramdaUseWith(R.map, [
  R.mergeDeepLeft,
  R.identity,
]);

/**
 * renameProp :: String -> String -> Object -> Object
 */
export const renameProp = R.curry((from, to) =>
  R.when(R.has(from), R.compose(R.dissoc(from), assocBy(to, R.prop(from)))),
);

/**
 * propOrDefaultTo :: a -> String -> Object -> b | a
 * a, b = Any
 */
export const propOrDefaultTo = R.curry((defaultValue, prop, obj) =>
  R.compose(R.defaultTo(defaultValue), R.prop(prop), R.defaultTo({}))(obj),
);

// isNilByPath :: [String] -> Supplier -> Boolean
export const isNilByPath = (path) => R.pathSatisfies(R.isNil, path);

// overPropUnlessNil :: (String, a -> b) -> c -> c
// a, b = Any
// c = Object
export const overPropUnlessNil = (key, fn) =>
  R.unless(R.propSatisfies(R.isNil, key), R.over(R.lensProp(key), fn));

// mapIndexed :: ((a, Number) -> b) -> [a] -> [b]
export const mapIndexed = R.addIndex(R.map);
