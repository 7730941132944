import React from 'react';
import styled from 'styled-components';
import { Select } from '@poly/admin-book';

const SelectS = styled(Select)`
  width: 225px;
`;

const selectOptions = [
  { label: '>= 2%', value: { sign: 'gte', value: 2 } },
  { label: '< 2%', value: { sign: 'lt', value: 2 } },
];

export function DiscrepanciesPercentageSelect(props) {
  const selectProps = {
    ...props,
    options: selectOptions,
    placeholder: 'Select discrepancies percentage',
  };

  return <SelectS {...selectProps} />;
}
