import * as R from 'ramda';
import { assocBy } from '@poly/utils';

/**
 * prepareSupplierInvoiceToMutation :: FormData -> MutationInput
 */
export const prepareSupplierInvoiceToMutation = R.ifElse(
  R.propSatisfies(R.isEmpty, 'invoiceFile'),
  R.omit(['invoiceFile', 'isDueDateChanged']),
  assocBy(
    'invoiceFile',
    R.compose(R.pick(['fileName', 'upload']), R.path(['invoiceFile', 0])),
  ),
);

// isValidNewInvoiceAmount :: (Number, Invoice) -> Boolean
export const isNotValidNewInvoiceAmount = (newAmount, invoice) => {
  if (invoice.total === invoice.balance) {
    return false;
  }
  const paidAmount = (invoice.total - invoice.balance) / 100;
  if (invoice.total < 0) {
    return newAmount > paidAmount;
  }
  return newAmount < paidAmount;
};
