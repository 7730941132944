import React from 'react';
import { useApolloClient } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import {
  AddUpdateForm as CommonAddUpdateForm,
  closeCurrentModal,
  useProcessState,
} from '@poly/admin-ui';

import { addUpdatesFormId } from './constants.js';
import { ModalFormWrapper } from '../../modals/ModalFormWrapper.js';

function AddUpdateForm(props) {
  const dispatch = useDispatch();
  const apolloClient = useApolloClient();

  const { payload, ...rest } = useSelector((state) => state.modal);

  const onCancel = () => dispatch(closeCurrentModal());

  return (
    <CommonAddUpdateForm
      {...props}
      {...payload}
      {...rest}
      successMessage="Update was created"
      onCancel={onCancel}
      formId={addUpdatesFormId}
      apolloClient={apolloClient}
    />
  );
}

export function AddUpdateModal() {
  const { process } = useProcessState(addUpdatesFormId);

  return (
    <ModalFormWrapper
      actionInProgress={process}
      title="Add Update"
      buttonLabel="Add Update"
      formId={addUpdatesFormId}
      Form={AddUpdateForm}
    />
  );
}
