import * as R from 'ramda';
import React from 'react';
import { bool, func, string } from 'prop-types';
import { SystemAccountTypes } from '@poly/constants';

import { AccountsSelect } from '../../../components/AccountsSelect.js';
import { filterAccountsBySystemType } from '../../ChartOfAccounts/helper.js';

const { BANK_ACCOUNT } = SystemAccountTypes;

export function DepositAccountSelect({
  submitting,
  depositAccount,
  onDepositAccountChange,
}) {
  return (
    <AccountsSelect
      value={depositAccount}
      onChange={onDepositAccountChange}
      disabled={submitting}
      extractValue={R.prop('_id')}
      filterAccounts={filterAccountsBySystemType(BANK_ACCOUNT)}
    />
  );
}

DepositAccountSelect.propTypes = {
  submitting: bool,
  depositAccount: string,
  onDepositAccountChange: func,
};
