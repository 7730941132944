import styled from 'styled-components';
import { getThemeFont, Text } from '@poly/admin-book';
import * as R from 'ramda';
import { string } from 'prop-types';
import React from 'react';
import { NOTHING_UI_STRING } from '@poly/constants';

import { forceTitleCase } from '@poly/utils';
import { supplierSourceColors } from '../constants/suppliers.js';

const SupplierSourceTagWrapper = styled(Text)`
  font-size: ${R.propOr('23px', 'size')};
  color: ${R.prop('color')};
  font-weight: ${getThemeFont(['bold'])};
  margin: 0 15px;
`;

export function SupplierSourceTag({ source, type, ...rest }) {
  return (
    <SupplierSourceTagWrapper
      {...{ color: supplierSourceColors[source], ...rest }}
    >
      {type ? forceTitleCase(type) : NOTHING_UI_STRING}
    </SupplierSourceTagWrapper>
  );
}

SupplierSourceTag.propTypes = {
  source: string.isRequired,
  type: string,
};
