import React from 'react';
import * as R from 'ramda';
import { string, bool, func } from 'prop-types';
import styled from 'styled-components';
import { TOKEN_EXPIRED_ERROR } from '@poly/constants';
import { PasswordInputSection } from '@poly/client-utils';

import {
  ErrorText,
  Footer,
  Label,
  LoginButton,
  LoginPageLayout,
} from '../Components.js';
import { ButtonLoader } from '../Loader.js';

export const getFormValue = (name, event) =>
  R.path(['target', 'elements', name, 'value'], event);

// isTokenExpiredError :: String -> Boolean
const isTokenExpiredError = R.equals(R.prop('message', TOKEN_EXPIRED_ERROR));

const LabelS = styled(Label)`
  cursor: pointer;
  color: #cfcfcf;
  :hover {
    color: #9f9d9d;
    text-decoration: underline;
  }
`;

function ChangePasswordFormError({
  errorMsg,
  navigateToForgotPassword,
  isTokenExpired,
}) {
  if (isTokenExpired) {
    return (
      <>
        <ErrorText>
          Your invite has expired. Please Reset your password to get a new
          invite.
        </ErrorText>
        <LabelS onClick={navigateToForgotPassword}>Reset Your Password</LabelS>
      </>
    );
  }

  if (errorMsg) {
    return <ErrorText>{errorMsg}</ErrorText>;
  }

  return null;
}

ChangePasswordFormError.propTypes = {
  errorMsg: string,
  navigateToForgotPassword: func.isRequired,
  isTokenExpired: bool.isRequired,
};

export function ChangePasswordForm({
  title,
  handleSubmit,
  loading,
  error,
  navigateToForgotPassword,
}) {
  const onSubmit = (event) => {
    event.preventDefault();

    const creds = {
      password: getFormValue('password', event),
      confirmPassword: getFormValue('confirmPassword', event),
    };

    handleSubmit(creds);
  };

  const isTokenExpired = isTokenExpiredError(error);

  return (
    <LoginPageLayout onSubmit={onSubmit} title={title}>
      <PasswordInputSection
        title="New Password"
        inputProps={{
          name: 'password',
          placeholder: 'New Password',
          required: true,
          autoFocus: true,
        }}
      />
      <PasswordInputSection
        title="Confirm Password"
        inputProps={{
          name: 'confirmPassword',
          placeholder: 'Confirm Password',
          required: true,
        }}
      />
      <ChangePasswordFormError
        isTokenExpired={isTokenExpired}
        errorMsg={error}
        navigateToForgotPassword={navigateToForgotPassword}
      />
      {!isTokenExpired && (
        <Footer>
          <LoginButton type="submit" disabled={loading}>
            {loading && <ButtonLoader />}
            Update Password
          </LoginButton>
        </Footer>
      )}
    </LoginPageLayout>
  );
}

ChangePasswordForm.propTypes = {
  handleSubmit: func.isRequired,
  loading: bool.isRequired,
  error: string,
  title: string.isRequired,
  navigateToForgotPassword: func.isRequired,
};

ChangePasswordForm.defaultProps = {
  error: null,
};
