import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useShowByAllowedTabs } from '@poly/admin-ui';
import { LinkButtonWithLoader, ToolBarBtnDivider } from '@poly/admin-book';
import { PRINT_TYPES, EXPORT_XLS_CAPTION } from '@poly/constants';
import { performExcelExport } from '@poly/client-utils/src/index.js';

import { masterSupplierPageTabs } from '../../routes/constants.js';
import { FlexCenterAlign } from '../../components/FlexContainer.js';
import { TablePrintBtn } from '../../modules/core/tableToolbar/TablePrintPDFBtn.js';
import { useMasterSupplierInvoicesQuery } from './hooks/useMasterSupplierInvoicesQuery.js';
import { getSupplierInvoicesExportExcelConfig } from '../SupplierPage/ExportXLSSupplierInvoicesBtn.js';

const useCommonMasterSupplierInvoicesQueryLogic = () => {
  const sort = useSelector(R.prop('entitySort'));
  return useMasterSupplierInvoicesQuery(sort);
};

function MasterSupplierInvoicesPrintBtn() {
  const { data } = useCommonMasterSupplierInvoicesQueryLogic();

  const printBtnProps = {
    data,
    style: { marginLeft: 30 },
    printType: PRINT_TYPES.MASTER_SUPPLIER_INVOICES,
    metaData: {
      title: R.path(['masterSupplier', 'company', 'name'], data),
    },
  };

  return <TablePrintBtn {...printBtnProps} />;
}

function MasterSupplierInvoicesExportXLSBtn() {
  const { data, loading } = useCommonMasterSupplierInvoicesQueryLogic();

  const handleClick = () => {
    const invoices = R.pathOr(
      [],
      ['masterSupplier', 'searchInvoices', 'hits'],
      data,
    );
    const excelConfig = getSupplierInvoicesExportExcelConfig({
      invoices,
      fileName: 'master_supplier_invoices',
    });
    performExcelExport(excelConfig);
  };

  return (
    <LinkButtonWithLoader loader={loading} onClick={handleClick}>
      {EXPORT_XLS_CAPTION}
    </LinkButtonWithLoader>
  );
}

export function MasterSupplierInvoicesToolbar() {
  const isAllowedToShow = useShowByAllowedTabs([
    masterSupplierPageTabs.masterSupplierInvoices,
  ]);

  return (
    isAllowedToShow && (
      <FlexCenterAlign>
        <MasterSupplierInvoicesPrintBtn />
        <ToolBarBtnDivider />
        <MasterSupplierInvoicesExportXLSBtn />
      </FlexCenterAlign>
    )
  );
}
