import React, { useReducer } from 'react';
import { gql } from '@apollo/client';
import { ASC_SORT_ORDER } from '@poly/constants';
import {
  MainHeader,
  PageHeaderContainer,
  DefaultBodyWrapper,
} from '@poly/admin-book';
import {
  commonSortQuery,
  useRedirectIfHasNoPermissions,
  useTableInfiniteScrollQuery,
} from '@poly/client-utils';
import { CREATE_CLIENT_BATCH_PERMISSION } from '@poly/security';

import { batchClientInvoicingReducer } from '../BatchInvoicingTM/batch-invoicing-state.js';
import { housekeepingBatchInvoicingQuery } from '../BatchInvoicingTM/client-filters-presets.js';
import { BatchInvoicingPageBody } from '../BatchInvoicingTM/BatchInvoicingPageBody.js';
import { HousekeepingBatchInvoicingTable } from './HousekeepingBatchInvoicingTable.js';
import { HousekeepingBatchTotalsTable } from './HousekeepingBatchTotalsTable.js';

const housekeepingBatchInvoicingGqlQuery = gql`
  query ClientBatchInvoicingProjects($input: CollectionSearchParams!) {
    searchProjects(input: $input) {
      hits {
        _id
        type
        projectId
        description
        suggestedClientInvoiceAmount
        extraHoursClientInvoiceAmount
        jobCost
        total
      }
      total
    }
  }
`;

const housekeepingTotalsProps = [
  'suggestedClientInvoiceAmount',
  'extraHoursClientInvoiceAmount',
  'jobCost',
];

const housekeepingBatchQueryInput = {
  query: housekeepingBatchInvoicingQuery,
  sort: commonSortQuery(['projectId'])(ASC_SORT_ORDER),
};

export function HousekeepingBatchInvoicingPage() {
  const [batchEntriesMap, dispatch] = useReducer(
    batchClientInvoicingReducer,
    {},
  );

  const { data, loading, refetch, tableProps } = useTableInfiniteScrollQuery(
    housekeepingBatchInvoicingGqlQuery,
    housekeepingBatchQueryInput,
    {
      endpointName: 'searchProjects',
      fetchPolicy: 'network-only',
      pageSize: 500,
    },
  );

  const externalRefetch = () => {
    refetch({ input: housekeepingBatchQueryInput });
  };

  useRedirectIfHasNoPermissions([CREATE_CLIENT_BATCH_PERMISSION]);

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Bass Pro Housekeeping Batch Invoice</MainHeader>
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <BatchInvoicingPageBody
          data={data}
          tableProps={tableProps}
          loading={loading}
          batchEntriesMap={batchEntriesMap}
          dispatch={dispatch}
          refetch={externalRefetch}
          calculateTotalsProps={housekeepingTotalsProps}
          TableComponent={HousekeepingBatchInvoicingTable}
          TotalsTable={HousekeepingBatchTotalsTable}
          isHousekeeping
        />
      </DefaultBodyWrapper>
    </>
  );
}
