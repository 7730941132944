import React, { memo } from 'react';
import { shape, string, func } from 'prop-types';
import { Marker } from 'google-maps-react';
import { getSupplierCoordinates } from './googleMapUtils.js';

export const GoogleMapMarker = memo(
  ({ setter, supplier, setRef, ...restProps }) => {
    const onClick = (_, marker) => setter(marker);

    const name = supplier._id;
    const position = getSupplierCoordinates(supplier);

    return (
      <Marker
        {...restProps}
        ref={(el) => setRef(el, name)}
        name={name}
        position={position}
        onClick={onClick}
      />
    );
  },
);

GoogleMapMarker.displayName = 'GoogleMapMarker';

GoogleMapMarker.propTypes = {
  supplier: shape({ _id: string }).isRequired,
  setter: func.isRequired,
  setRef: func.isRequired,
};
