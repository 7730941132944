import * as R from 'ramda';
import { propEqLegacy } from '@poly/utils';

// buildNodeTreeBase :: (Node -> TreeNode) -> (Node, [Node]) ->  [TreeNode]
// Node = { id: String, parentId: String }
// TreeNode = { id: String, title: String, children: [TreeNode] }
const buildTreeNodeBase = (treeNodeBuilder) => (node, nodes) => {
  const treeNode = treeNodeBuilder(node);
  const children = nodes.filter((n) => n.parentId === node.id);
  if (children.length === 0) {
    return treeNode;
  }
  return {
    ...treeNode,
    expanded: true,
    children: children.map((child) =>
      buildTreeNodeBase(treeNodeBuilder)(child, nodes),
    ),
  };
};

// buildTreeBase :: (Node -> TreeNode) -> [Node] ->  [TreeNode]
export const buildTreeBase = (treeNodeBuilder) => (nodes) => {
  const buildNodeTree = buildTreeNodeBase(treeNodeBuilder);
  const roots = nodes.filter((node) => !node.parentId);
  return roots.map((root) => buildNodeTree(root, nodes));
};

// flattenTreeNodeBase :: (TreeNode -> Node) -> (TreeNode, String) ->  [Node]
const flattenTreeNodeBase = (nodeBuilder) => (node, parentId) => {
  const nodeFlattened = nodeBuilder(node, parentId);

  const { children } = node;

  if (!children) {
    return [nodeFlattened];
  }

  const childrenFlattened = children.reduce(
    (acc, childNode) => [
      ...acc,
      ...flattenTreeNodeBase(nodeBuilder)(childNode, node.id).filter((n) => n),
    ],
    [],
  );
  return [nodeFlattened, ...childrenFlattened];
};

// flattenTreeBase :: (TreeNode -> Node) -> [TreeNode] ->  [Node]
export const flattenTreeBase = (nodeBuilder) => (tree) =>
  tree.reduce(
    (acc, node) => [...acc, ...flattenTreeNodeBase(nodeBuilder)(node)],
    [],
  );

// isSingleNodeTree :: [TreeNode] -> Boolean
export const isSingleNodeTree = R.both(
  propEqLegacy('length', 1),
  R.pathSatisfies(R.either(R.isNil, R.isEmpty), [0, 'children']),
);
