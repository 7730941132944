import styled from 'styled-components';

import { Icon } from '../Icon/index.js';
import { getThemeColor } from '../utils.js';

export const UploadIcon = styled(Icon).attrs((props) => ({
  name: 'upload-cloud',
  size: 20,
  color: getThemeColor(['midDark'])(props),
}))`
  margin-right: 8px;
`;
