import { propertyStatuses } from '@poly/constants';
import { defaultTheme } from '@poly/admin-book';

const {
  colors: {
    statuses: { active, inactive },
  },
} = defaultTheme;

export const propertyStatusesColors = {
  [propertyStatuses.ACTIVE]: active,
  [propertyStatuses.INACTIVE]: inactive,
};
