import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { TextButton } from '@poly/admin-book';
import { propEqLegacy } from '@poly/utils';
import { format } from 'date-fns';
import { shape, string, instanceOf } from 'prop-types';
import {
  performExcelExport,
  ExcelExportCellType,
  createExcelExportCell,
} from '@poly/client-utils';
import { EXPORT_XLS_CAPTION } from '@poly/constants';

import { balanceSheetDataPropType } from './prop-types.js';
import {
  flattenBalanceSheetDataResult,
  BalanceSheetRowType,
} from './flatten-balance-sheet.js';
import { accountCategoryToString } from './BalanceSheetTable.js';

const BalanceSheetExportTextButton = styled(TextButton)`
  font-size: 14px;
  margin-right: 10px;
`;

const notAllowedRowTypes = [
  BalanceSheetRowType.category,
  BalanceSheetRowType.parent,
  BalanceSheetRowType.spacer,
];

// formatAccountBalanceColumn :: BalanceSheetRow -> String
const formatAccountBalanceColumn = R.ifElse(
  R.compose(R.includes(R.__, notAllowedRowTypes), R.prop('type')),
  R.always(''),
  R.compose(R.prop('balance')),
);

// formatAccountNameColumn :: BalanceSheetRow -> String
const formatAccountNameColumn = R.cond([
  [
    propEqLegacy('type', BalanceSheetRowType.category),
    R.compose(accountCategoryToString, R.prop('category')),
  ],
  [
    propEqLegacy('type', BalanceSheetRowType.parent),
    R.path(['account', 'name']),
  ],
  [
    propEqLegacy('type', BalanceSheetRowType.child),
    R.compose(
      R.join(' - '),
      R.reject(R.isNil),
      R.juxt([R.path(['account', 'code']), R.path(['account', 'name'])]),
    ),
  ],
  [
    propEqLegacy('type', BalanceSheetRowType.total),
    R.compose(R.concat('Total '), R.path(['account', 'name'])),
  ],
  [
    propEqLegacy('type', BalanceSheetRowType.categoryTotal),
    R.compose(R.concat('Total '), accountCategoryToString, R.prop('category')),
  ],
  [
    propEqLegacy('type', BalanceSheetRowType.grandTotal),
    R.always('Total Liabilities And Owners Equity'),
  ],
  [R.T, R.always('')],
]);

// getBalanceSheetRow :: BalanceSheetRow -> [ExcelExportDataCell]
const getBalanceSheetRow = R.compose(
  R.zipWith(createExcelExportCell, [
    ExcelExportCellType.default,
    ExcelExportCellType.defaultCurrency,
  ]),
  R.juxt([formatAccountNameColumn, formatAccountBalanceColumn]),
);

const filterDisplayTypes = {
  accrualBasis: 'Accrual Basis',
  cashBasis: 'Cash Basis',
};

// eslint-disable-next-line import/no-unused-modules
export const getBalanceSheetExportExcelConfig = ({
  preparedBalances,
  filters: { date, type },
}) => ({
  exportFileName: 'balance_sheet.xlsx',
  columnWidths: [50, 15],
  rows: [
    [
      {
        value: 'Balance Sheet',
        cellType: ExcelExportCellType.title,
      },
    ],
    [
      {
        value: `As Of ${format(date, 'dd MMMM yyyy')}`,
        cellType: ExcelExportCellType.title,
      },
    ],
    [
      {
        value: filterDisplayTypes[type],
        cellType: ExcelExportCellType.title,
      },
    ],
    R.zipWith(
      createExcelExportCell,
      [
        ExcelExportCellType.tableHeader,
        ExcelExportCellType.tableCurrencyHeader,
      ],
      ['Account', 'Balance'],
    ),
    ...R.map(getBalanceSheetRow, preparedBalances),
  ],
});

export function BalanceSheetExportExcelButton({ data, filters }) {
  const onClick = () => {
    const preparedBalances = flattenBalanceSheetDataResult(data);
    const exportConfig = getBalanceSheetExportExcelConfig({
      preparedBalances,
      filters,
    });
    performExcelExport(exportConfig);
  };

  return (
    <BalanceSheetExportTextButton onClick={onClick}>
      {EXPORT_XLS_CAPTION}
    </BalanceSheetExportTextButton>
  );
}

BalanceSheetExportExcelButton.propTypes = {
  filters: shape({
    type: string,
    date: instanceOf(Date),
  }),
  data: balanceSheetDataPropType,
};
