import React from 'react';
import { LinkButton } from '@poly/admin-book';
import { arrayOf, shape, string } from 'prop-types';
import { CommonPrintLayout } from '@poly/admin-ui';
import { openPrintWindowWithData } from '@poly/client-utils';
import { AccountingMethods, PRINT_PDF_CAPTION } from '@poly/constants';

import { IncomeStatementReportTable } from './IncomeStatementReportTable.js';
import {
  incomeStatementsPropTypes,
  incomeStatementsFiltersPropTypes,
} from './prop-types.js';

export function IncomeStatementsPDFButton({
  filters,
  incomeStatements,
  cogsAccountCodes,
}) {
  const { accountingMethod, startDate, endDate } = filters;

  const onClick = () =>
    openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: IncomeStatementReportTable,
      fileName: 'income_statement_report',
      metaData: {
        title: `Income Statement (${
          accountingMethod === AccountingMethods.CASH_BASIS ? 'Cash' : 'Accrual'
        } Basis)`,
      },
      isPrint: true,
      endDate,
      startDate,
      incomeStatements,
      cogsAccountCodes,
    });

  return <LinkButton onClick={onClick}>{PRINT_PDF_CAPTION}</LinkButton>;
}

IncomeStatementsPDFButton.propTypes = {
  filters: shape(incomeStatementsFiltersPropTypes),
  incomeStatements: arrayOf(shape(incomeStatementsPropTypes)),
  cogsAccountCodes: arrayOf(string),
};
