import * as R from 'ramda';

import {
  SupplierFormCheckbox,
  SupplierDocumentsCheckbox,
} from '../components/Checkbox.js';
import { halfWidth } from '../common.js';
import { FileExpDateInput } from '../components/index.js';
import { ChooseFileField } from '../../../fields/ChooseFileField.js';
import { isSubcontractor } from '../utils.js';

export const supplierDocumentsSection = (allowExempt) => [
  {
    label: 'Documents',
    order: 10,
    layout: { column: 2, position: 'relative' },
    renderIf: isSubcontractor,
    fields: [
      {
        label: 'Exempt from Document Requests',
        order: 1,
        layout: { row: 1, margin: '0 0 10px 0' },
        field: {
          name: 'documents.exemptDocumentsRequest',
          Component: SupplierFormCheckbox,
        },
      },
      {
        label: 'Workers Comp Insurance',
        order: 2,
        layout: { row: 2, width: halfWidth },
        field: {
          name: 'documents.wcFile',
          Component: ChooseFileField,
        },
      },
      {
        order: 3,
        layout: { row: 2, width: 0 },
        renderIf: () => !!allowExempt,
        field: {
          name: 'documents.exempt',
          Component: SupplierDocumentsCheckbox,
        },
      },
      {
        label: 'Exp',
        order: 4,
        layout: {
          row: 2,
          label: { position: 'row', width: '50px' },
          width: halfWidth,
        },
        field: {
          name: 'documents.wcExp',
          Component: FileExpDateInput,
        },
      },
      {
        label: 'Liability Insurance',
        order: 5,
        layout: { row: 3, width: halfWidth },
        field: {
          name: 'documents.glFile',
          Component: ChooseFileField,
        },
      },
      {
        label: 'Exp',
        order: 6,
        layout: {
          row: 3,
          label: { position: 'row', width: '50px' },
          width: halfWidth,
        },
        field: {
          name: 'documents.glExp',
          Component: FileExpDateInput,
        },
      },
      {
        label: 'Subcontractor Agreement',
        order: 7,
        layout: { row: 4, width: halfWidth },
        field: {
          name: 'documents.scaFile',
          Component: ChooseFileField,
        },
      },
      {
        label: 'Subcontractor Questionnaire',
        order: 8,
        layout: { row: 5, width: halfWidth },
        field: {
          name: 'documents.sqqFile',
          Component: ChooseFileField,
        },
      },

      {
        label: 'MBE Certification',
        order: 9,
        layout: { row: 6, width: halfWidth },
        field: {
          name: 'documents.mbeCertificationFile',
          Component: ChooseFileField,
        },

        renderIf: R.path(['mwbe', 'isMBE']),
      },

      {
        label: 'WBE Certification',
        order: 10,
        layout: { row: 7, width: halfWidth },
        field: {
          name: 'documents.wbeCertificationFile',
          Component: ChooseFileField,
        },

        renderIf: R.prop('isWBE'),
      },

      {
        label: 'Veteran Certification',
        order: 11,
        layout: { row: 8, width: halfWidth },
        field: {
          name: 'documents.veteranCertificationFile',
          Component: ChooseFileField,
        },
        renderIf: R.prop('isVeteran'),
      },
    ],
  },
];
