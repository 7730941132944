import * as R from 'ramda';
import React, { useEffect } from 'react';
import { gql } from '@apollo/client';
import { Select } from '@poly/admin-book';
import { bool, func, shape, string } from 'prop-types';
import { useReactiveQuery } from '@poly/client-utils';
import { propEqLegacy } from '@poly/utils';

const CREDIT_CARD_USERS_QUERY = gql`
  query CREDIT_CARD_USERS_QUERY($input: CreditCardUsersInput) {
    creditCardUsers(input: $input) {
      _id
      cardNumber
      user {
        _id
        profile {
          fullName
        }
      }
    }
  }
`;

const SEARCH_CREDIT_CARD_USERS_CHANGED = gql`
  subscription SEARCH_CREDIT_CARD_USERS_CHANGED {
    searchCreditCardUsersChanged {
      id
      type
    }
  }
`;

// getCCUserSelectOptions :: { creditCardUsers: [CreditCardUser] } -> [Pair String String]
const getCCUserSelectOptions = R.compose(
  R.map(
    R.applySpec({
      value: R.prop('_id'),
      label: R.compose(
        R.join(' '),
        R.values,
        R.applySpec({
          cardNumber: R.prop('cardNumber'),
          userFullName: R.path(['user', 'profile', 'fullName']),
        }),
      ),
    }),
  ),
  R.sortBy(R.prop('cardNumber')),
  R.propOr([], 'creditCardUsers'),
);

// getFirstOptionValue :: [Pair String String] -> String
const getFirstOptionValue = R.path(['0', 'value']);

// checkIsSingleOption :: [Pair String String] -> Boolean
const checkIsSingleOption = propEqLegacy('length', 1);

export function CCUsersSelect({
  name,
  queryInput,
  changeFieldValue,
  isAutoPopulateSingleUserFound,
  ...props
}) {
  const queryOptions = { variables: { input: queryInput }, skip: !queryInput };

  const { data, loading } = useReactiveQuery(
    CREDIT_CARD_USERS_QUERY,
    SEARCH_CREDIT_CARD_USERS_CHANGED,
    { queryOptions, subscriptionOptions: {} },
  );

  const options = getCCUserSelectOptions(data);
  const isSingleOption = checkIsSingleOption(options);
  const firstOptionValue = getFirstOptionValue(options);

  useEffect(() => {
    if (isSingleOption) {
      changeFieldValue(name, firstOptionValue);
    }
  }, [firstOptionValue]);

  const selectProps = {
    options,
    loading,
    name: 'CCUserSelect',
    ...props,
  };

  return <Select {...selectProps} />;
}

CCUsersSelect.propTypes = {
  direction: string,
  name: string,
  changeFieldValue: func,
  isAutoPopulateSingleUserFound: bool,
  queryInput: shape({
    status: string,
    cardNumber: string,
    userId: string,
    accountId: string,
  }),
};
