import * as R from 'ramda';
import { isPlainObject } from 'is-plain-object';
import { forceTitleCase } from './strings.js';

const removePropDeepDirectly = (prop) => (obj) => {
  R.keys(obj).forEach((key) => {
    const propType = R.type(obj[key]);
    if (propType === 'Object') {
      removePropDeepDirectly(prop)(obj[key]);
    } else if (propType === 'Array') {
      obj[key].forEach((val) => {
        if (R.type(val) === 'Object') {
          removePropDeepDirectly(prop)(val);
        }
      });
    } else if (key === prop) {
      // eslint-disable-next-line
      delete obj[key];
    }
  });

  return obj;
};

// removePropDeep :: String -> Object -> Object
export const removePropDeep = (prop) =>
  R.compose(removePropDeepDirectly(prop), R.clone);

const nullToUndefinedDirectly = (obj) => {
  R.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      // eslint-disable-next-line no-param-reassign
      obj[key] = undefined;
    } else if (R.type(obj[key] === 'Object')) {
      nullToUndefinedDirectly(obj[key]);
    }
  });

  return obj;
};

// nullToUndefinedDeep :: Object -> Object
export const nullToUndefinedDeep = R.pipe(R.clone, nullToUndefinedDirectly);

// keysToTitleCase :: Object -> Object
export const keysToTitleCase = R.pipe(
  R.toPairs,
  R.map(([key, val]) => [forceTitleCase(key), val]),
  R.fromPairs,
);

// applySpecWithFields :: Spec -> TargetObject -> WithSpecObject
//   Spec = Object
//   TargetObject = Object
//   WithSpecObject = Object
export const applySpecWithFields = (spec) => (target) =>
  R.mergeDeepRight(target, R.applySpec(spec)(target));

// isPlainObjectOrArray :: Any -> Boolean
const isPlainObjectOrArray = R.either(
  R.both(R.is(Object), isPlainObject),
  R.is(Array),
);

// mapObjectDeep :: (Any -> Any) -> Object -> Object
export const mapObjectDeep = (mapper) =>
  R.map((v) =>
    isPlainObjectOrArray(v) ? mapObjectDeep(mapper)(v) : mapper(v),
  );

/**
 * mergeAllDeepRight :: [Object] -> Object
 */
export const mergeAllDeepRight = R.reduce(R.mergeDeepRight, {});
