import React from 'react';
import { number } from 'prop-types';
import { HeaderLinksWrapper } from '@poly/admin-book';

import {
  SupplierSearchShowInMapBtn,
  SupplierSearchExportToXLSBtn,
} from './SupplierSearchTableToolBar.js';
import { SearchPageTableComp } from '../commonTableComps.js';
import { SupplierSearchTableS } from './supplierSearchTableUtils.js';
import { ToolBarBtnDivider } from '../../core/tableToolbar/tableToolBarStyles.js';

export function SupplierSearchTableComp({ counts, ...props }) {
  return (
    <SearchPageTableComp
      {...{ counts }}
      ToolBar={
        <HeaderLinksWrapper>
          <SupplierSearchShowInMapBtn {...props} />
          <ToolBarBtnDivider />
          <SupplierSearchExportToXLSBtn {...props} />
        </HeaderLinksWrapper>
      }
    >
      <SupplierSearchTableS {...props} moreScrollPosition="0px" />
    </SearchPageTableComp>
  );
}

SupplierSearchTableComp.propTypes = { counts: number.isRequired };
