import React from 'react';
import { useDispatch } from 'react-redux';
import { shape, string, object } from 'prop-types';
import { TextButton } from '@poly/admin-book';
import {
  UpdatesTab as CommonUpdatesTab,
  useModalContext,
  entities,
  openModal,
} from '@poly/admin-ui';

import { modalNames } from '../modals/index.js';
import { PrintLayoutProvider } from '../../providers.js';
import {
  updateProjectFormId,
  AddProjectUpdateFormWithSelectAction,
} from '../forms/addProjectUpdateForm/AddProjectUpdateFormWithSelectAction.js';

function AddUpdateBtn({ collection, entity, updateCacheParams }) {
  const dispatch = useDispatch();
  const { openModalForm, closeModal } = useModalContext();
  const onClick = () => {
    if (entity.name === entities.PROJECT) {
      return openModalForm({
        id: updateProjectFormId,
        formId: updateProjectFormId,
        title: 'Add Update',
        btnCaption: 'Add Update',
        content: (
          <AddProjectUpdateFormWithSelectAction
            {...{
              onCancel: () => closeModal(updateProjectFormId),
              updateCacheParams,
            }}
          />
        ),
      });
    }
    return dispatch(
      openModal({
        name: modalNames.ADD_UPDATE,
        payload: {
          collection,
          entity,
          updateCacheParams,
        },
      }),
    );
  };

  return <TextButton onClick={onClick}>Add New</TextButton>;
}

AddUpdateBtn.propTypes = {
  collection: string,
  entity: shape({
    name: string,
  }),
  updateCacheParams: shape({
    // eslint-disable-next-line react/prop-types,react/forbid-prop-types
    query: object,
  }),
};

export function UpdatesTab(props) {
  return (
    <CommonUpdatesTab
      {...props}
      AddUpdateBtn={AddUpdateBtn}
      PrintLayout={PrintLayoutProvider}
    />
  );
}
