import React from 'react';
import { string } from 'prop-types';
import { entities, SidebarTasksTab } from '@poly/admin-ui';
import { taskCollections } from '@poly/constants';

export function PropertySidebarTasksTab({ propertyId, propertyName }) {
  const tasksProps = {
    entity: {
      _id: propertyId,
      preview: 'property',
      name: entities.PROPERTY,
      humanReadableId: propertyName,
    },
    collection: taskCollections.PROPERTIES,
  };

  return <SidebarTasksTab {...tasksProps} />;
}

PropertySidebarTasksTab.propTypes = {
  propertyId: string.isRequired,
  propertyName: string.isRequired,
};
