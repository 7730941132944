import * as R from 'ramda';
import { isNilOrEmpty, propEqLegacy } from '@poly/utils';
import { InvoiceTypes } from '@poly/constants';

const commonFormPropsForInvoice = [
  'type',
  'supplierId',
  'invoiceDate',
  'invoiceNumber',
];

const fixedInvoiceFields = [
  'total',
  'projectId',
  'supplierId',
  'invoiceDate',
  'invoiceNumber',
];

const breakdownInvoiceFields = [
  ...fixedInvoiceFields,
  'materialCost',
  'labourCost',
  'taxAmount',
];

// prepareInvoiceNumberForInvoices :: [Invoice] -> [Invoice]
const prepareInvoiceNumberForInvoices = (invoices) =>
  invoices.map(({ invoiceNumber, ...invoice }, index) => ({
    ...invoice,
    invoiceNumber: `${invoiceNumber}-${index + 1}`,
  }));

// prepareSupplierInvoicesForMutation :: FormData -> CreateMultipleInvoicesInput
export const prepareSupplierInvoicesForMutation = R.converge(R.mergeLeft, [
  R.ifElse(
    R.propSatisfies(isNilOrEmpty, 'invoiceFile'),
    R.always({}),
    R.compose(
      R.objOf('invoiceFile'),
      R.pick(['upload', 'fileName']),
      R.path(['invoiceFile', 0]),
    ),
  ),
  R.compose(
    R.objOf('invoices'),
    R.map(
      R.converge(R.mergeRight, [
        R.pick(['projectInvoiceDescription']),
        R.ifElse(
          propEqLegacy('type', InvoiceTypes.breakdown),
          R.compose(
            R.objOf(InvoiceTypes.breakdown),
            R.pick(breakdownInvoiceFields),
          ),
          R.compose(R.objOf(InvoiceTypes.fixed), R.pick(fixedInvoiceFields)),
        ),
      ]),
    ),
    prepareInvoiceNumberForInvoices,
    R.converge(R.map, [
      R.compose(
        R.mergeDeepLeft,
        R.ifElse(
          R.prop('isSingleDescription'),
          R.pick([...commonFormPropsForInvoice, 'projectInvoiceDescription']),
          R.pick(commonFormPropsForInvoice),
        ),
      ),
      R.propOr([], 'invoices'),
    ]),
  ),
]);
