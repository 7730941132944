import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import Editor from '@draft-js-plugins/editor';
import '@draft-js-plugins/mention/lib/plugin.css';
import { ContentState, EditorState } from 'draft-js';
import { func, string, bool, shape } from 'prop-types';
import createMentionPlugin from '@draft-js-plugins/mention';
import { debounce } from '@poly/utils';

import { Icon } from '../Icon/index.js';
import { defaultTheme } from '../ThemeProvider/index.js';
import { addUniqMention, prepareMentionsObject } from './mention-helpers.js';
import { fileErrorPropTypes } from '../FilePicker/UploadFileLogic.js';
import { getThemeColor, getThemeFont } from '../utils.js';

const { secondaryMid } = defaultTheme.colors;

const getBorderColor = R.cond([
  [
    R.anyPass([R.prop('hasError'), R.prop('error')]),
    getThemeColor(['secondaryMid']),
  ],
  [R.T, getThemeColor(['midLight'])],
]);

const DraftEditorWrapperS = styled.div`
  width: 100%;
  padding: 12px 16px;
  font-size: 12px;
  line-height: 18px;
  font-weight: ${getThemeFont(['regular'])};
  border-radius: 3px;
  border: 1px solid ${getBorderColor};
  background-color: ${getThemeColor(['white'])};
  position: relative;

  .public-DraftEditorPlaceholder-root {
    position: absolute;
    color: ${getThemeColor(['midDark'])};
  }

  .public-DraftEditor-content {
    min-height: 88px;
  }
`;

const AutocompleteEntryInfo = styled.div`
  display: flex;
  align-items: center;
`;

const AutocompleteEntryIcon = styled(Icon)`
  height: ${(props) => props.size}px;
`;

const RequiredIconS = styled(Icon)`
  width: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const mentionTypeIconMap = {
  user: 'user',
  group: 'user-group',
};

function AutocompleteEntry(props) {
  const { mention, theme, ...parentProps } = props;

  const wrapperProps = R.omit([
    'role',
    'isFocused',
    'searchValue',
    'selectMention',
  ])(parentProps);

  return (
    <div {...wrapperProps}>
      <AutocompleteEntryInfo className={theme.mentionSuggestionsEntryContainer}>
        <AutocompleteEntryIcon
          name={mentionTypeIconMap[mention.type]}
          size={24}
        />

        <div className={theme.mentionSuggestionsEntryText}>{mention.name}</div>
      </AutocompleteEntryInfo>
    </div>
  );
}

AutocompleteEntry.propTypes = {
  mention: shape({ name: string.isRequired }),
  theme: shape({ mentionSuggestionsEntryText: string.isRequired }),
};

const defaultDebounce = debounce(300);

export function DraftEditorWithMentions(props) {
  const {
    placeholder,
    onFocus,
    onBlur,
    error,
    hasError,
    disableMentions,
    readOnly,
  } = props;

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionPrefix: '@',
      entityMutability: 'IMMUTABLE',
      supportWhitespace: true,
    });

    return {
      plugins: [mentionPlugin],
      MentionSuggestions: mentionPlugin.MentionSuggestions,
    };
  }, []);

  const [editorState, setEditorState] = useState(
    EditorState.moveFocusToEnd(
      EditorState.createWithContent(ContentState.createFromText(props.value)),
    ),
  );

  const [suggestions, setSuggestions] = useState([]);
  const [isMentionsOpen, setIsMentionsOpen] = useState(false);

  const mentions = useRef([]);
  const editorRef = useRef(null);

  const focus = useCallback(
    defaultDebounce(() => {
      if (editorRef.current) {
        editorRef.current.editor.focus();
      }
    }),
    [],
  );

  useEffect(() => {
    if (props.autoFocus) {
      focus();
    }
  }, []);

  const onChange = (newEditorState) => {
    const selection = newEditorState.getSelection();
    const isFocused = selection.getHasFocus();

    const contentState = newEditorState.getCurrentContent();
    const text = contentState.getPlainText('\n').trim();

    const mentionsValue = prepareMentionsObject(text, mentions.current);

    setEditorState(
      isFocused
        ? EditorState.forceSelection(newEditorState, selection)
        : newEditorState,
    );

    props.onChange({
      text,
      mentions: mentionsValue,
    });
  };

  const onSearchChange = useCallback(
    defaultDebounce(async ({ value }) => {
      const newSuggestions = await props.loadMentionSuggestions(value);
      setSuggestions(newSuggestions);
    }),
    [],
  );

  const onOpenMentions = (isOpen) => {
    setIsMentionsOpen(isOpen);
  };

  const onAddMention = (newMentionedUser) => {
    mentions.current = addUniqMention(newMentionedUser, mentions.current);
  };

  return (
    <DraftEditorWrapperS
      data-testid="editor-with-mentions"
      onClick={focus}
      {...{ error, hasError }}
    >
      {error && !props.value ? (
        <RequiredIconS name="required" color={secondaryMid} />
      ) : null}
      <Editor
        editorState={editorState}
        onChange={onChange}
        plugins={plugins}
        ref={editorRef}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        readOnly={readOnly}
        spellCheck
      />
      {!disableMentions ? (
        <MentionSuggestions
          open={isMentionsOpen}
          onOpenChange={onOpenMentions}
          onSearchChange={onSearchChange}
          suggestions={suggestions}
          onAddMention={onAddMention}
          entryComponent={AutocompleteEntry}
        />
      ) : null}
    </DraftEditorWrapperS>
  );
}

DraftEditorWithMentions.displayName = 'DraftEditorWithMentions';
DraftEditorWithMentions.defaultProps = {
  placeholder: '',
  readOnly: false,
  autoFocus: false,
};
DraftEditorWithMentions.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
  onFocus: func.isRequired,
  onBlur: func.isRequired,
  loadMentionSuggestions: func.isRequired,
  placeholder: string,
  error: fileErrorPropTypes,
  hasError: bool.isRequired,
  disableMentions: bool,
  readOnly: bool,
  autoFocus: bool,
};
