import React from 'react';
import { string, arrayOf, bool, shape } from 'prop-types';
import { convertFormDataToTimesheet } from './helpers/from-form-data.js';
import { convertTimesheetToFormData } from './helpers/to-form-data.js';
import { partialTimesheetFormOptions } from './sections.js';
import { TimesheetForm } from './TimesheetForm.js';
import { useUpdateTimesheet } from '../../core/hooks/timesheets/index.js';

export const editTimeSheetFormId = 'editTimeSheetFormId';

export function EditTimesheetForm({
  isManual,
  timesheet,
  withoutUser,
  myTimesheets,
  ...restProps
}) {
  const { updateTimesheet } = useUpdateTimesheet();

  const mutate = (params) => {
    const { _id, ownerId, ...rest } = convertFormDataToTimesheet(params);

    updateTimesheet(_id, {
      ...rest,
      ownerId: !ownerId && !withoutUser ? timesheet.owner._id : ownerId,
      notes: params.notes || '',
    });
  };

  const initialValues = {
    isManual,
    withoutUser,
    isEdit: true,
    timeSheets: myTimesheets,
    currentTimesheet: timesheet,
    ...convertTimesheetToFormData(timesheet),
  };

  const additionalProps = partialTimesheetFormOptions(myTimesheets);

  return (
    <TimesheetForm
      {...restProps}
      {...additionalProps}
      mutate={mutate}
      id={editTimeSheetFormId}
      formId={editTimeSheetFormId}
      successMessage="Time sheet successfully saved"
      initialValues={initialValues}
    />
  );
}

EditTimesheetForm.displayName = 'EditTimesheetForm';

EditTimesheetForm.propTypes = {
  isManual: bool,
  withoutUser: bool,
  myTimesheets: arrayOf(
    shape({
      date: string,
      entries: arrayOf(
        shape({
          timeIn: string,
          timeOut: string,
        }),
      ),
    }),
  ),
  timesheet: shape({
    date: string,
    entries: arrayOf(
      shape({
        timeIn: string,
        timeOut: string,
      }),
    ),
  }),
};
