import React from 'react';
import * as R from 'ramda';
import { string, func } from 'prop-types';
import { CommonSidebarFormWidth, entities } from '@poly/admin-ui';
import { useOutSidebarContext } from '@poly/client-utils';
import { Loader } from '@poly/admin-book';

import {
  SidebarLayoutS,
  SidebarFormLayoutS,
} from './components/userGroupFormComponents.js';
import { userGroupAssignUserFormId } from '../constants.js';
import { UserGroupAssignUserFrom } from './UserGroupAssignUserFrom.js';
import { useOnCloseFormSidebar } from '../../../sidebars/useOnCloseFormSidebar.js';
import { useUserGroupByIdQuery } from './useUserGroupByIdQuery.js';

// prepareSidebarTitle :: { name: String } -> String
const prepareSidebarTitle = R.compose(
  R.concat(R.__, '" User Group'),
  R.concat('Add User to "'),
  R.prop('name'),
);

function UserGroupAssignUserSidebarFrom({ userGroupId, debouncedRefetch }) {
  const onClose = useOnCloseFormSidebar();
  const { userGroup, loading } = useUserGroupByIdQuery(userGroupId);

  if (loading) {
    return <Loader />;
  }

  return (
    <SidebarFormLayoutS
      submitButtonCaption="Assign"
      formId={userGroupAssignUserFormId}
      title={prepareSidebarTitle(userGroup)}
    >
      <UserGroupAssignUserFrom
        userGroup={userGroup}
        debouncedRefetch={debouncedRefetch}
        onClose={() => onClose(entities.USER_GROUP, userGroupId)}
      />
    </SidebarFormLayoutS>
  );
}

UserGroupAssignUserSidebarFrom.propTypes = {
  userGroupId: string.isRequired,
  debouncedRefetch: func.isRequired,
};

export const useUserGroupAssignUserSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();

  return (props) =>
    openOutSidebar({
      alwaysFirst: true,
      Layout: SidebarLayoutS,
      id: userGroupAssignUserFormId,
      width: CommonSidebarFormWidth,
      content: <UserGroupAssignUserSidebarFrom {...props} />,
    });
};
