import React from 'react';
import styled from 'styled-components';
import { bool, func, string } from 'prop-types';
import { useModalContext } from '@poly/admin-ui';
import { Form } from 'react-final-form';
import { FormField, Loader, Text, Textarea } from '@poly/admin-book';
import { supplierDocumentNames } from '@poly/constants';

import { ApproveButtonS } from './components.js';
import { useRejectRequestedSupplierDocument } from './useRejectRequestedSupplierDocument.js';
import { useRequestedSupplierDocument } from './useRequestedSupplierDocument.js';

const RejectBtnS = styled(ApproveButtonS).attrs(() => ({
  size: 'middle',
}))`
  background: #ebebeb;
  color: #575757;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

const FormFieldS = styled(FormField)`
  margin: 16px 0;
  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #333333;
    opacity: unset;
  }
`;

const TextS = styled(Text)`
  margin-top: 10px;
  font-size: 12px;
  line-height: 18px;
`;

const rejectRequestedDocumentFormId = 'rejectRequestedDocumentFormId';

function RejectRequestedDocumentFormComp({
  handleSubmit,
  isSubmitting,
  supplierName,
  documentName,
  handleCloseModal,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <TextS>
        {/* eslint-disable-next-line @cspell/spellchecker */}
        You&apos;re going to reject the document {documentName} from Supplier{' '}
        {supplierName}. Please add rejection reason bellow.
      </TextS>
      <FormFieldS
        name="rejectionReason"
        additionalProps={{
          label: 'Reason *',
          required: true,
          rows: 5,
        }}
        Component={Textarea}
      />
      <ButtonWrapper>
        <RejectBtnS onClick={handleCloseModal} disabled={isSubmitting}>
          Cancel
        </RejectBtnS>
        <ApproveButtonS disabled={isSubmitting} loader={isSubmitting}>
          Reject Document
        </ApproveButtonS>
      </ButtonWrapper>
    </form>
  );
}

RejectRequestedDocumentFormComp.propTypes = {
  handleSubmit: func.isRequired,
  isSubmitting: bool,
  supplierName: string.isRequired,
  documentName: string.isRequired,
  handleCloseModal: func.isRequired,
};

function RejectRequestedDocumentForm({
  documentId,
  handleCloseSidebar,
  handleCloseModal,
}) {
  const { handleRejectDocument, isSubmitting } =
    useRejectRequestedSupplierDocument(documentId, handleCloseSidebar);

  const { document, loading } = useRequestedSupplierDocument(documentId);

  if (loading) {
    return <Loader />;
  }

  const handleSubmit = async ({ rejectionReason }) => {
    await handleRejectDocument(rejectionReason);
    handleCloseModal();
  };

  const initialValues = {
    rejectionReason: '',
  };

  return (
    <Form
      initialValues={initialValues}
      render={RejectRequestedDocumentFormComp}
      onSubmit={handleSubmit}
      supplierName={document?.supplier?.company?.name}
      documentName={supplierDocumentNames[document.documentType]}
      isSubmitting={isSubmitting}
      handleCloseModal={handleCloseModal}
    />
  );
}

RejectRequestedDocumentForm.propTypes = {
  documentId: string.isRequired,
  handleCloseSidebar: func,
  handleCloseModal: func.isRequired,
};

export function RejectRequestedDocumentButton({
  documentId,
  handleCloseSidebar,
  ...props
}) {
  const { openModal, closeModal } = useModalContext();

  const handleCloseModal = () => closeModal(rejectRequestedDocumentFormId);

  const handleClick = () =>
    openModal({
      id: rejectRequestedDocumentFormId,
      title: 'Reject Document',
      formId: rejectRequestedDocumentFormId,
      btnCaption: 'Reject Document',
      maxWidth: '495px',
      minWidth: '445px',
      content: (
        <RejectRequestedDocumentForm
          documentId={documentId}
          handleCloseSidebar={handleCloseSidebar}
          handleCloseModal={handleCloseModal}
        />
      ),
    });

  return (
    <RejectBtnS {...props} onClick={handleClick}>
      Reject
    </RejectBtnS>
  );
}

RejectRequestedDocumentButton.propTypes = {
  documentId: string,
  handleCloseSidebar: func,
};
