import * as R from 'ramda';
import React from 'react';
import { UserSelect, getAdminUsersBySystemStatusQuery } from '@poly/admin-ui';
import { isManualTotalTimesheet, UserStatuses } from '@poly/constants';
import { Input, createGetFormFieldWidth } from '@poly/admin-book';

import { TimesheetEntry } from './components/TimesheetEntry.js';
import { TimesheetDatePicker } from './components/TimesheetDatePicker.js';
import { TimesheetTypeSelect } from './components/TimesheetTypeSelect.js';
import { TimesheetTotalInput } from './components/TimesheetTotalInput.js';
import { isOutsideOfCurrentPayWeek } from './helpers/dates.js';
import {
  validateTimesheetFormData,
  validateTimesheetFunc,
  validateTimeEntries,
} from './helpers/validation.js';

// define 4 columns with 5px between
const getFormColumnWidth = createGetFormFieldWidth(4, 5);

const halfWidth = getFormColumnWidth(2);

const dateFieldOptions = {
  label: 'Day, Date',
  order: 2,
  layout: { row: 2, width: halfWidth },
  field: {
    name: 'date',
    withFormData: true,
    withChangeFieldValue: true,
    Component: TimesheetDatePicker,
  },
  required: true,
  validators: [
    [
      R.complement(isOutsideOfCurrentPayWeek),
      'Allowed to enter only current week days',
    ],
  ],
};

function UserSelectComponent(props) {
  const query = getAdminUsersBySystemStatusQuery(UserStatuses.ACTIVE);
  return <UserSelect {...props} query={query} withoutSkip />;
}

const userFieldOptions = {
  label: 'ESFM Staff',
  order: 1,
  layout: { row: 1 },
  field: {
    name: 'ownerId',
    Component: (props) => <UserSelectComponent {...props} />,
  },
  required: true,
  renderIf: R.both(R.prop('isManual'), R.complement(R.prop('withoutUser'))),
};

const typeFieldOptions = {
  label: 'Type',
  order: 3,
  layout: { row: 2, width: halfWidth },
  field: {
    name: 'type',
    withFormData: true,
    withChangeFieldValue: true,
    Component: TimesheetTypeSelect,
  },
  required: true,
};

const notesFieldOptions = {
  label: 'Notes',
  order: 5,
  layout: { row: 4, width: halfWidth },
  field: {
    name: 'notes',
    Component: Input,
  },
};

const totalTimeFieldOptions = {
  label: 'Total Time',
  order: 6,
  layout: { row: 4, width: halfWidth },
  field: {
    name: 'totalTimeInMinutes',
    withFormData: true,
    Component: TimesheetTotalInput,
    additionalProps: {
      allowModalOverflow: true,
    },
  },
};

export const partialTimesheetFormOptions = (timeSheets) => ({
  validate: validateTimesheetFormData,
  sections: [
    {
      label: '',
      order: 1,
      layout: { column: 1 },
      fields: [
        userFieldOptions,
        dateFieldOptions,
        typeFieldOptions,
        {
          order: 4,
          layout: { row: 3, width: '100%' },
          field: {
            name: 'entries',
            withFormData: true,
            isArrayField: true,
            Component: TimesheetEntry,
            validate: validateTimeEntries(timeSheets),
            validateFunction: validateTimesheetFunc,
          },
          renderIf: R.complement(isManualTotalTimesheet),
        },
        notesFieldOptions,
        totalTimeFieldOptions,
      ],
    },
  ],
});
