import React from 'react';
import {
  MainHeader,
  PageHeaderContainer,
  HeaderLinksWrapper,
} from '@poly/admin-book';

import { DivisionsExportToPDFButton } from './components/DivisionsExportToPDFButton.js';
import { AddDivisionButton } from './components/AddDivisionButton.js';
import { DivisionsExportToExcelButton } from './components/DivisionsExportToExcelButton.js';

export function DivisionsPageHeader(props) {
  return (
    <PageHeaderContainer>
      <MainHeader>Divisions</MainHeader>
      <HeaderLinksWrapper>
        <AddDivisionButton />
        <DivisionsExportToPDFButton {...props} />
        <DivisionsExportToExcelButton {...props} />
      </HeaderLinksWrapper>
    </PageHeaderContainer>
  );
}
