import * as R from 'ramda';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import React, { useEffect } from 'react';
import createDecorator from 'final-form-focus';
import { func, shape } from 'prop-types';

import { formTypes } from './formTypes.js';
import { FormLayout } from './layouts/FormLayout.js';
import { RetainFormFields } from './RetainFormFields.js';
import { FormValuesByDBDataButton } from './FormValuseByDBDataButton.js';
import {
  nullToNotRenderedDeep,
  mapLabelToIndex,
  trimFormValues,
} from './formUtils.js';

const FormS = styled.form`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  ${({ columnDirection }) => !!columnDirection && 'flex-direction: column'};
`;

const focusOnError = createDecorator();

export const useFormSubscription = (subscribe, formSubscription) => {
  useEffect(() => {
    let unsubscribe;
    if (formSubscription) {
      const { subscriber, subscription } = formSubscription;
      unsubscribe = subscribe(subscriber, subscription);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

function FormWithSubscriber(props) {
  const { subscribe, formSubscription } = props;

  useFormSubscription(subscribe, formSubscription);

  return <FormLayout {...{ subscribe, ...props }} />;
}

FormWithSubscriber.propTypes = {
  subscribe: func,
  formSubscription: shape({
    subscriber: func,
  }),
};

export function FormCreator({
  id,
  className,
  persistKey,
  persistFields,
  initialValues,
  formValuesByDB,
  formSubscription,
  resetFormOnSubmit,
  ...props
}) {
  const sections = mapLabelToIndex(props);
  const formConfig = {
    subscription: {},
    decorators: [focusOnError],
    onSubmit: R.compose(
      props.onSubmit,
      // Trim only first level fields, to avoid loose some custom objects data
      // nested should be processed manually
      trimFormValues,
      nullToNotRenderedDeep(sections),
    ),
    ...R.pick(
      [
        'validate',
        'mutators',
        'validateOnBlur',
        'initialValuesEqual',
        'keepDirtyOnReinitialize',
      ],
      props,
    ),
    initialValues,
  };

  return (
    <Form {...formConfig}>
      {({
        handleSubmit,
        form: { mutators, subscribe, change, restart, getState, batch },
      }) => (
        <FormS
          {...{ id, className }}
          data-testid="form-creator"
          columnDirection={!!persistKey && !!formValuesByDB}
          onSubmit={
            resetFormOnSubmit
              ? async (event) => {
                  await handleSubmit(event);
                  if (getState().valid) restart();
                }
              : handleSubmit
          }
        >
          <FormValuesByDBDataButton
            batch={batch}
            change={change}
            persistKey={persistKey}
            formValuesByDB={formValuesByDB}
          />
          <FormWithSubscriber
            {...{
              ...props,
              mutators,
              sections,
              subscribe,
              formSubscription,
              changeFieldValue: change,
            }}
          />
          {persistKey && persistFields && (
            <RetainFormFields
              persistKey={persistKey}
              persistFields={persistFields}
            />
          )}
        </FormS>
      )}
    </Form>
  );
}

FormCreator.displayName = 'FormCreator';

FormCreator.propTypes = formTypes;

FormCreator.defaultProps = {
  keepDirtyOnReinitialize: true,
  resetFormOnSubmit: false,
};
