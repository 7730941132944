import ReactQuill from 'react-quill';
import styled, { css } from 'styled-components';

import { defaultTheme } from '../ThemeProvider/index.js';
import { quillBabelStyles } from './theme.js';

const {
  colors: { midLight, white },
} = defaultTheme;

const buttonCommonStyles = css`
  height: 28px;
  display: flex;
  margin-right: 5px;
  align-items: center;
  justify-content: center;
  color: #d3dbea;
  border: 1px solid transparent;
  transition: background-color 0.2s ease, border-color 0.2s ease;
  line-height: 1.15;
  &.ql-active,
  &:hover {
    background-color: #2a3542;
    border-color: #23303c;
  }
`;

export const ReactQuillS = styled(ReactQuill)`
  ${quillBabelStyles};
  .ql-editor {
    max-height: 100px;
    padding: 13px 13px;
    border-radius: 3px;
    border: 1px solid ${midLight};
    background-color: ${white};
    font-size: 12px;
    line-height: 18px;

    .ql-size-small {
      font-size: 10px;
    }
    .ql-size-large {
      font-size: 14px;
    }
    .ql-size-huge {
      font-size: 18px;
    }
  }

  .ql-container.ql-bubble:not(.ql-disabled) a::before,
  .ql-container.ql-bubble:not(.ql-disabled) a::after {
    display: none;
  }

  .ql-bubble {
    .ql-tooltip-editor input[type='text'] {
      background: transparent;
      border: none;
      color: ${white};
      font-size: 13px;
      height: 100%;
      outline: none;
      padding: 10px 20px;
      position: absolute;
      width: 100%;
    }
    .ql-color-picker {
      .ql-picker-label {
        ${buttonCommonStyles};
        padding: 2px;
        svg {
          width: 100%;
        }
      }
      .ql-picker-options {
        transform: translateY(100%);
        background-color: #354350;
        border-radius: 3px;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
      }
      .ql-picker-item {
        width: 15px;
        height: 15px;
        margin: 5px;
        position: relative;
        border-radius: 50%;
        border: 1px solid #d3dbea;
        &.ql-selected::after {
          width: 50%;
          height: 100%;
          display: block;
          content: '';
          position: absolute;
          top: -25%;
          right: 25%;
          border: 2px solid ${white};
          border-top: none;
          border-left: none;
          transform: rotate(45deg);
        }
      }
    }
    .ql-transparent {
      opacity: 1;
    }
    .ql-tooltip {
      z-index: 5;
      background-color: #354350;
      border-radius: 3px;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
      &.ql-flip .ql-color-picker .ql-picker-options {
        top: -1px;
        bottom: auto;
        transform: translateY(-100%);
      }
      .ql-editing .ql-formats {
        visibility: hidden;
      }
    }
    .ql-picker {
      position: static;
      &.ql-expanded .ql-picker-options {
        width: auto;
        display: flex;
        top: auto;
        bottom: -1px;
        left: 0;
      }
    }
  }
`;

export const Toolbar = styled.div`
  display: flex;
  z-index: 1;
`;

export const Button = styled.button`
  &&&& {
    ${buttonCommonStyles};
  }
`;

export const ButtonQlSmaller = styled(Button)`
  font-weight: bold;
  padding-top: 5px;
  font-size: 10px;
`;

export const ButtonQlBigger = styled(Button)`
  font-weight: bold;
  font-size: 14px;
`;

export const ButtonQlDone = styled.button`
  &&&& {
    width: auto;
    height: auto;
    display: inline-flex;
    flex-shrink: 0;
    padding: 0 15px;
    position: relative;
    font-family: inherit;
    cursor: pointer;
    color: #d3dbea;
    background-color: #2a3542;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    z-index: 2;
    line-height: 35px;
  }
`;

export const QlFormats = styled.span`
  &&&&& {
    display: inline-flex;
    margin: 0;
    padding: 5px;
    position: relative;

    &:not(:last-child) {
      border-right: 1px solid #23303c;
    }
  }
`;

export const readOnlyStyles = css`
  .ql-editor {
    padding-left: 0;
    padding-top: 0;
    border: none;
  }
`;
