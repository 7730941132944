import * as R from 'ramda';
import styled from 'styled-components';
import React, { useCallback } from 'react';
import { object, string, bool, arrayOf } from 'prop-types';
import { useUpdateQueryParams, useRouterQuery } from '@poly/client-routing';
import { useOutSidebarContext } from '@poly/client-utils';
import {
  PageSubTabs as PageSubTabsC,
  PageGeneralTabs,
  getThemeColor,
  TabsWrapper,
} from '@poly/admin-book';
import { ofArrayLegacy } from '@poly/utils';

const SidebarTabsHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  width: 100%;
  padding: 5px 24px 0 10px;
  border-bottom: 2px solid ${getThemeColor(['light'])};

  > div {
    height: 38px;
    padding-right: 20px;

    > div {
      margin: 0 0 -2px 0;
      padding: 10px 10px;
      flex-shrink: 0;
    }
  }
`;

const SidebarTabsHeaderWrapperFull = styled(SidebarTabsHeaderWrapper)`
  padding: 5px 5px 0 5px;

  > div {
    padding: 0;

    > div {
      padding: 10px 12px;
    }
  }
`;

const SidebarSubTabsHeaderWrapper = styled(SidebarTabsHeaderWrapper)`
  padding: 0 24px 0 14px;

  > div {
    > div {
      margin: 0 10px -2px 10px;
      padding: 10px 0;
    }
  }
`;

export function PageTabs({ defaultValue, ...props }) {
  const tabQueryKey = 'tab';
  const queryByKey = useRouterQuery([tabQueryKey]);
  const preparedValue = queryByKey[tabQueryKey] || defaultValue;

  const updateQueryParams = useUpdateQueryParams();
  const onChange = (tab) => updateQueryParams({ [tabQueryKey]: tab });

  return (
    <PageGeneralTabs value={preparedValue} onChange={onChange} {...props} />
  );
}

PageTabs.propTypes = { defaultValue: string };

export function PageSubTabs({ defaultValue, ...props }) {
  const tabQueryKey = 'subTab';
  const queryByKey = useRouterQuery([tabQueryKey]);
  const preparedValue = queryByKey[tabQueryKey] || defaultValue;

  const updateQueryParams = useUpdateQueryParams();
  const onChange = (tab) => updateQueryParams({ [tabQueryKey]: tab });

  return <PageSubTabsC value={preparedValue} onChange={onChange} {...props} />;
}

PageSubTabs.propTypes = {
  defaultValue: string,
};

// getAvailableTabs :: String -> TabsProps -> Boolean
// TabsProps = { tabs: [[String]] }
const isTabAvailable = (tab) =>
  R.compose(
    R.both(() => R.complement(R.isNil)(tab), R.any(R.equals(tab))),
    R.map(R.prop(1)),
    R.defaultTo([]),
    R.prop('tabs'),
  );

// getParamsToOmit :: [String] -> Object
const getParamsToOmit = R.compose(
  R.fromPairs,
  R.map(R.compose(R.append(undefined), ofArrayLegacy)),
);

function CommonSidebarTabs({
  isCard,
  tabQueryKey,
  defaultValue,
  HeaderWrapper,
  omitSidebarByTabs,
  ...props
}) {
  const { sidebarConfig } = useOutSidebarContext();
  const updateQueryParams = useUpdateQueryParams();
  const query = useRouterQuery([tabQueryKey]);

  const tabFromQuery = query[tabQueryKey];

  const visibleSidebars = sidebarConfig.filter((config) => !config.isHidden);
  const isTabDisabled = !!isCard && visibleSidebars.length > 0;

  const paramsToOmit = omitSidebarByTabs
    ? getParamsToOmit(omitSidebarByTabs)
    : {};

  const onChange = useCallback(
    (tab) => {
      updateQueryParams({ [tabQueryKey]: tab, ...paramsToOmit });
    },
    [paramsToOmit],
  );

  const tabsProps = {
    ...props,
    onChange,
    tabQueryKey,
    HeaderWrapper,
    value:
      !isTabDisabled && isTabAvailable(tabFromQuery)(props)
        ? tabFromQuery
        : defaultValue,
  };

  return <TabsWrapper {...tabsProps} />;
}

CommonSidebarTabs.propTypes = {
  tabQueryKey: string.isRequired,
  defaultValue: string.isRequired,
  omitSidebarByTabs: arrayOf(string),
  // eslint-disable-next-line react/forbid-prop-types
  HeaderWrapper: object,
  isCard: bool,
};

export function SidebarTabs({ id, customTabQueryKey, ...props }) {
  return (
    <CommonSidebarTabs
      tabQueryKey={customTabQueryKey || 'sidebarTab'}
      HeaderWrapper={
        id ? SidebarTabsHeaderWrapper : SidebarTabsHeaderWrapperFull
      }
      {...props}
    />
  );
}

SidebarTabs.propTypes = { id: string, customTabQueryKey: string };

export function SidebarSubTabs(props) {
  return (
    <CommonSidebarTabs
      tabQueryKey="sidebarSubTab"
      HeaderWrapper={SidebarSubTabsHeaderWrapper}
      {...props}
    />
  );
}
