import { gql } from '@apollo/client';

export const apAgingReportQuery = gql`
  query APAgingReport($input: APAgingReportInput) {
    apAgingReport(input: $input) {
      hits {
        _id
        company {
          name
        }
        invoices {
          invoice {
            _id
            invoiceNumber
            invoiceDate
            paymentDueDate
            paymentDueDateChangeReason
            project {
              type
              projectId
            }
            total
          }
          clientInvoice {
            _id
            createdAt
            paidAt
          }
        }
      }
      total
    }
  }
`;
