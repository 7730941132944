import * as R from 'ramda';
import { useState, useEffect, useContext } from 'react';
import { DateUtils } from 'react-day-picker';
import { addMonths, isDate, startOfDay, endOfDay, isSameDay } from 'date-fns';
import { instanceOf, oneOfType, shape, string } from 'prop-types';
import { alwaysNewDate, ensureIsDate, renameProp } from '@poly/utils';

import { DateRangeToButton } from './constants.js';
import { ModalContext } from '../Modal/Modal.js';
import { modifyStringToDate } from './utils.js';

export const valuePropTypes = oneOfType([
  instanceOf(Date),
  string,
  shape({
    startDate: oneOfType([instanceOf(Date), string]),
    endDate: oneOfType([instanceOf(Date), string]),
  }),
]);

export const useDatePickerLogic = ({
  disableOverflowChange,
  onChange,
  onBlur,
  isMultiple,
  formatDate,
  value,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dateValue, setDateValue] = useState('');

  const { overflow, setModalOverflow } = useContext(ModalContext);

  useEffect(() => {
    setDateValue(value ? formatDate(value) : '');
  }, [value]);

  const toggleIsOpen = (newValue) => {
    setIsOpen(newValue);
    if (
      typeof setModalOverflow === 'function' &&
      !disableOverflowChange &&
      overflow !== 'unset'
    ) {
      setModalOverflow(newValue ? 'initial' : 'auto');
    }
  };

  const onDayMouseDown = (v, { disabled }, e) => {
    e.preventDefault();
    if (!disabled) {
      if (!isMultiple) toggleIsOpen(false);
      onChange(v);
      onBlur(e);
    }
  };

  const onReset = (e) => {
    e.stopPropagation();
    const resetValue = isMultiple ? { startDate: '', endDate: '' } : '';
    setDateValue(resetValue);
    onChange(resetValue);
  };
  return {
    isOpen,
    toggleIsOpen,
    onDayMouseDown,
    dateValue,
    setDateValue,
    onReset,
    overflow,
    modifiedValue: modifyStringToDate(value),
  };
};

export const useMultipleDayPickerLogic = ({
  value,
  onDayMouseDown,
  getCustomButtons,
  ...props
}) => {
  const [range, setRange] = useState({
    from: R.prop('startDate', value),
    to: R.prop('endDate', value),
  });

  const [month, setMonth] = useState(
    R.pipe(
      R.path(['from']),
      R.when(R.anyPass([R.isEmpty, R.isNil]), () => alwaysNewDate()),
    )(range),
  );

  const onDayClick = (day, disabled, e) => {
    if (
      isSameDay(ensureIsDate(range.from), ensureIsDate(range.to)) &&
      isSameDay(ensureIsDate(day), ensureIsDate(range.from))
    ) {
      return;
    }

    const { from, to } = isDate(day)
      ? DateUtils.addDayToRange(day, range)
      : day;

    const newRange = {
      from: isDate(from) ? startOfDay(from) : from,
      to: isDate(to) ? endOfDay(to) : to,
    };
    setRange(newRange);
    if (newRange.from && newRange.to) {
      const payloadDate = R.pipe(
        renameProp('from', 'startDate'),
        renameProp('to', 'endDate'),
      )(newRange);
      onDayMouseDown(payloadDate, disabled, e);
    } else {
      onDayMouseDown({ startDate: '', endDate: '' }, disabled, e);
    }
  };

  const changeMonth = (num) => () =>
    setMonth(addMonths(ensureIsDate(month), num));

  const onFooterBtnClick = (e, btnName) => {
    const FooterButtons = getCustomButtons
      ? getCustomButtons(props)
      : DateRangeToButton;
    onDayClick(FooterButtons[btnName], true, e);
    setMonth(FooterButtons[btnName].to);
  };

  return {
    month,
    onDayClick,
    changeMonth,
    selectedDays: [range.from, { from: range.from, to: range.to }],
    onFooterBtnClick,
  };
};
