import React from 'react';
import styled from 'styled-components';

import { element, arrayOf, oneOfType, string } from 'prop-types';
import { getThemeColor, getThemeFont } from '../utils.js';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: ${getThemeFont(['regular'])};
  font-size: 18px;
  color: ${getThemeColor(['primary'])};
  margin-bottom: 20px;
`;

const EntityList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const EntityRow = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-weight: ${getThemeFont(['regular'])};
  font-size: 18px;
  color: ${getThemeColor(['primary'])};
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const EntityName = styled.span`
  font-weight: ${getThemeFont(['semibold'])};
  font-size: 12px;
  line-height: 1;
  color: ${getThemeColor(['primaryLighter2'])};
`;

const Dots = styled.span`
  border-bottom: dashed 1px ${getThemeColor(['secondaryLighter1'])};
  width: 100%;
  margin: 0 10px 2px 10px;
`;

const EntityLink = styled.span`
  font-size: 14px;
  line-height: 1;
`;

Header.displayName = 'Header';
EntityList.displayName = 'EntityList';
EntityRow.displayName = 'EntityRow';
EntityName.displayName = 'EntityName';
Dots.displayName = 'Dots';
EntityLink.displayName = 'EntityLink';

export function EntityStatistics({ header, items, className }) {
  return (
    <div className={className}>
      <Header>
        <span>{header}</span>
      </Header>
      <EntityList>
        {items.map((item) => (
          <EntityRow key={item[0]}>
            <EntityName>{item[0]}</EntityName>
            <Dots />
            <EntityLink>{item[1]}</EntityLink>
          </EntityRow>
        ))}
      </EntityList>
    </div>
  );
}

EntityStatistics.propTypes = {
  header: string.isRequired,
  items: arrayOf(arrayOf(oneOfType([string, element]))).isRequired,
  className: string,
};
EntityStatistics.defaultProps = {
  className: '',
};
