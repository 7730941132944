import { useState } from 'react';
import * as R from 'ramda';
import { propEqLegacy } from '@poly/utils';

import { ModalFormLayout } from './modalLayouts/ModalFormLayout.js';
import { ConfirmSubmitFormModalLayout } from './modalLayouts/ConfirmSubmitFormLayout.js';
import { ConfirmModalLayout } from './modalLayouts/ConfirmModalLayout.js';
import { DefaultModalLayout } from './modalLayouts/DefaultModalLayout.js';

// defaultModalConfig :: String -> Object
const defaultModalConfig = (id) => ({
  isOpen: true,
  id: R.defaultTo(Math.random().toString(), id),
  Layout: DefaultModalLayout,
});

// rejectModalById :: String -> [ModalConfig] -> [ModalConfig]
// ModalConfig = {
//    id: String
//    Layout: ReactComponent
//    content: Node
//    ...
// }
const rejectModalById = (id) => R.reject(propEqLegacy('id', id));

export const useModal = () => {
  const [activeModals, setActiveModals] = useState([]);

  const closeModal = (modalId) => {
    setActiveModals(rejectModalById(modalId));
  };

  const openModal = ({ id, ...restConfig }) => {
    const newModalConfig = {
      ...defaultModalConfig(id),
      ...restConfig,
    };
    setActiveModals((modals) =>
      !modals.find((modal) => modal.id === id)
        ? [...modals, newModalConfig]
        : modals,
    );
  };

  const openConfirmModal = (modalConfig) =>
    openModal({
      ...modalConfig,
      Layout: ConfirmModalLayout,
    });

  const openConfirmSubmitFormModal = (modalConfig) =>
    openModal({
      ...modalConfig,
      Layout: ConfirmSubmitFormModalLayout,
    });

  const openModalForm = (modalConfig) =>
    openModal({
      ...modalConfig,
      Layout: ModalFormLayout,
    });

  return {
    openModal,
    openConfirmModal,
    openConfirmSubmitFormModal,
    openModalForm,
    closeModal,
    activeModals,
  };
};
