import * as R from 'ramda';
import { propEqLegacy } from '@poly/utils';
import { combineActions, createActions, handleActions } from 'redux-actions';
import {
  defaultNotificationTime,
  DO_NOT_HIDE_NOTIFICATION_TIME,
  notificationTypes,
} from '@poly/admin-book';

const { SUCCESS, WARNING, ERROR } = notificationTypes;

const showType =
  (type) =>
  (message, time = defaultNotificationTime) => ({
    type,
    message,
    time: type === ERROR ? DO_NOT_HIDE_NOTIFICATION_TIME : time,
  });

export const {
  /**
   * @deprecated use `withNotificator` HOC instead
   */
  showSuccess,
  /**
   * @deprecated use `withNotificator` HOC instead
   */
  showWarning,
  /**
   * @deprecated use `withNotificator` HOC instead
   */
  showError,
  /**
   * @deprecated use `withNotificator` HOC instead
   */
  hideNotification,
} = createActions({
  SHOW_SUCCESS: showType(SUCCESS),
  SHOW_WARNING: showType(WARNING),
  SHOW_ERROR: showType(ERROR),
  HIDE_NOTIFICATION: R.identity,
});

const showNotificationAction = combineActions(
  showSuccess,
  showWarning,
  showError,
);

export const notificatorReducer = handleActions(
  {
    [showNotificationAction]: (notifications, { payload }) => [
      ...notifications,
      {
        ...payload,
        id: String(notifications.length + Math.random()),
      },
    ],
    [hideNotification]: (notifications, { payload: id }) =>
      R.reject(propEqLegacy('id', id), notifications),
  },
  [],
);
