import { useMemo } from 'react';
import {
  useTableInfiniteScrollQuery,
  parseFieldToNumberIfExists,
} from '@poly/client-utils';
import { isNilOrEmpty, propEqLegacy } from '@poly/utils';
import { gql } from '@apollo/client';

import * as R from 'ramda';
import { ALL } from '../../modules/core/constants/general.js';
import { filterClientInvoiceByOptions } from './constants.js';

const CLIENT_INVOICES_QUERY = gql`
  query CLIENT_INVOICES_QUERY($input: ClientInvoicesInput) {
    clientInvoices(input: $input) {
      hits {
        _id
        number
        invoiceDate
        dueDate
        amount
        status
        project {
          _id
          projectId
          type
        }
        clientBatch {
          _id
        }
        clientNotified {
          emails {
            to
            cc
          }
          date
        }
        consolidatedClientInvoice {
          _id
          invoiceNumber
          clientNotified {
            emails {
              to
              cc
            }
            date
          }
        }
      }
      total
    }
  }
`;

// prepareClientInvoiceInput :: PageFilters -> ClientInvoicesInput
// PageFilters = {
//    clientId: ID
//    invoiceBy: String
//    invoiceDate: {
//      startDate: String
//      endDate: String
//    }
//    invoiceNumber: String
//    invoiceNumberFrom: String
//    invoiceNumberTo: String
//    isPrinted: Boolean
//    status: String
// }
const prepareClientInvoiceInput = R.compose(
  R.reject(R.anyPass([R.isEmpty, R.isNil])),
  parseFieldToNumberIfExists('invoiceNumber'),
  parseFieldToNumberIfExists('invoiceNumberFrom'),
  parseFieldToNumberIfExists('invoiceNumberTo'),
  // prepare filter by invoice status
  R.ifElse(propEqLegacy('status', ALL), R.omit(['status']), R.identity),
  // prepare filter by invoice number
  R.ifElse(
    R.either(
      propEqLegacy('invoiceBy', filterClientInvoiceByOptions.one),
      R.propSatisfies(R.isNil, 'invoiceBy'),
    ),
    R.omit(['invoiceBy', 'invoiceNumberFrom', 'invoiceNumberTo']),
    R.omit(['invoiceBy', 'invoiceNumber']),
  ),
  R.mergeAll,
  // prepare filter by invoice date
  R.juxt([
    R.omit(['invoiceDate']),
    R.applySpec({
      startDate: R.path(['invoiceDate', 'startDate']),
      endDate: R.path(['invoiceDate', 'endDate']),
    }),
  ]),
);

export const useSearchClientInvoicesQuery = (filters, pageSize) => {
  const input = useMemo(() => prepareClientInvoiceInput(filters), [filters]);

  const { data, loading, tableProps, refetch } = useTableInfiniteScrollQuery(
    CLIENT_INVOICES_QUERY,
    input,
    {
      endpointName: 'clientInvoices',
      pageSize: pageSize || 100,
      skip: isNilOrEmpty(filters),
    },
  );

  const clientInvoices = R.pathOr([], ['clientInvoices', 'hits'], data);
  return { clientInvoices, loading, tableProps, refetch };
};
