import * as R from 'ramda';
import React from 'react';
import { arrayOf } from 'prop-types';
import {
  performExcelExport,
  ExcelExportCellType,
  createExcelExportCell,
  pathOrNothingUI,
} from '@poly/client-utils';
import { EXPORT_XLS_CAPTION } from '@poly/constants';
import { LinkButton } from '@poly/admin-book';
import { convertCentsToDollars, formatDate } from '@poly/utils';

import { usePaySupplierInvoicesExportData } from './usePaySupplierInvoicesExportData.js';
import { invoicePaymentPropTypes } from './paySupplierInvoicesPropTypes.js';
import { paySupplierInvoiceFiltersPropTypes } from './propTypes.js';

const xlsExportHeaders = [
  ['Ref No.', 15, ExcelExportCellType.tableHeader],
  ['Inv No.', 15, ExcelExportCellType.tableHeader],
  ['Supplier', 30, ExcelExportCellType.tableHeader],
  ['Project', 15, ExcelExportCellType.tableHeader],
  ['Inv Date', 15, ExcelExportCellType.tableHeader],
  ['Due Date', 15, ExcelExportCellType.tableHeader],
  ['Inv Amount', 20, ExcelExportCellType.tableCurrencyHeader],
  ['Deductions', 20, ExcelExportCellType.tableCurrencyHeader],
  ['GL Disc Code', 20, ExcelExportCellType.tableHeader],
  ['Payment', 20, ExcelExportCellType.tableCurrencyHeader],
  ['Balance', 20, ExcelExportCellType.tableCurrencyHeader],
];

// getExcelHeaderCells :: [(String Number ExcelExportCellType)] -> [ExcelExportDataCell]
export const getExcelHeaderCells = R.map(
  R.converge(createExcelExportCell, [R.last, R.head]),
);

const filterEntries = [
  ['Supplier', 'supplierName'],
  ['Payment Terms', 'termsUI'],
  ['Project No', 'projectNumber'],
  ['Due Date', 'paymentDueDateUI'],
  ['Master Supplier', 'masterName'],
  ['Payment Type', 'paymentTypeUI'],
  ['Invoice No', 'invoiceNumber'],
  ['Include Past Due', 'pastDueDateUI'],
  ['Client', 'clientName'],
  ['Service Type', 'serviceTypeName'],
  ['Invoice Date', 'invoiceDateUI'],
  ['Show Only With Warnings', 'showOnlyWithWarningsUI'],
  ['W-9 absence', 'w9AbsentUI'],
];

// getFilterValuesRows :: PaySuppliersFilterValues -> [[ExcelExportDataCell]]
//   PaySuppliersFilterValues = Object
const getFilterValuesRows = R.compose(
  R.map(R.intersperse({})),
  R.unnest,
  R.juxt([
    R.compose(R.splitEvery(4), R.take(8)),
    R.compose(
      R.map(
        R.unless(R.compose(R.equals(4), R.length), R.concat(R.repeat({}, 3))),
      ),
      R.splitEvery(4),
      R.drop(8),
    ),
  ]),
  R.map(createExcelExportCell(ExcelExportCellType.default)),
  (values) => filterEntries.map(([label, prop]) => `${label}: ${values[prop]}`),
);

// getRowByInvoiceWithDeductions :: [Invoice] -> [[ExcelExportDataCell]]
const getRowByInvoiceWithDeductions = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 6),
    ...R.repeat(ExcelExportCellType.defaultCurrency, 2),
    ExcelExportCellType.default,
    ...R.repeat(ExcelExportCellType.defaultCurrency, 2),
  ]),
  R.juxt([
    R.prop('invoiceId'),
    R.prop('invoiceNumber'),
    R.path(['supplier', 'company', 'name']),
    R.path(['project', 'projectId']),
    R.compose(formatDate, R.prop('invoiceDate')),
    R.compose(formatDate, R.prop('dueDate')),
    R.compose(convertCentsToDollars, R.prop('total')),
    R.compose(convertCentsToDollars, R.prop('deductionsAmount')),
    pathOrNothingUI(['deductionsAccount', 'name']),
    R.compose(convertCentsToDollars, R.prop('paidAmount')),
    R.compose(convertCentsToDollars, R.prop('currentBalance')),
  ]),
);

// eslint-disable-next-line import/no-unused-modules
export const getPaySupplierInvoicesExcelExportConfig = ({
  invoices,
  filterValues,
  deductionsTotal,
  paidTotal,
}) => ({
  exportFileName: 'pay-supplier-invoices.xlsx',
  columnWidths: xlsExportHeaders.map(R.nth(1)),
  rows: [
    [{ value: 'Pay Supplier Invoices', cellType: ExcelExportCellType.title }],
    [{}],
    ...getFilterValuesRows(filterValues),
    [{}],
    getExcelHeaderCells(xlsExportHeaders),
    ...invoices.map(getRowByInvoiceWithDeductions),
    [
      ...R.repeat({ cellType: ExcelExportCellType.tableStringFooter }, 6),
      { value: 'Deductions', cellType: ExcelExportCellType.tableStringFooter },
      {
        value: convertCentsToDollars(deductionsTotal),
        cellType: ExcelExportCellType.tableCurrencyFooter,
      },
      { value: 'Total', cellType: ExcelExportCellType.tableStringFooter },
      {
        value: convertCentsToDollars(paidTotal),
        cellType: ExcelExportCellType.tableCurrencyFooter,
      },
      { cellType: ExcelExportCellType.tableStringFooter },
    ],
  ],
});

export function PaySupplierInvoicesXlsExportBtn({ invoices, filters }) {
  const exportXls = async (exportData) => {
    const config = getPaySupplierInvoicesExcelExportConfig(exportData);
    await performExcelExport(config);
  };

  const { onClick, disabled } = usePaySupplierInvoicesExportData(
    { invoices, filters },
    exportXls,
  );
  return (
    <LinkButton onClick={onClick} disabled={disabled}>
      {EXPORT_XLS_CAPTION}
    </LinkButton>
  );
}

PaySupplierInvoicesXlsExportBtn.propTypes = {
  invoices: arrayOf(invoicePaymentPropTypes),
  filters: paySupplierInvoiceFiltersPropTypes.isRequired,
};
