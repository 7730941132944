import React from 'react';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { element, number, oneOfType, shape, string, bool } from 'prop-types';

import { getThemeColor, getThemeFont } from '../utils.js';
import { defaultTheme } from '../ThemeProvider/index.js';

const {
  colors: { midLight: titleColor },
} = defaultTheme;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const StatusWrapper = styled(Wrapper)`
  > div {
    margin-right: 10px;
  }
`;

export const EmptyDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid ${R.prop('color')};
`;
EmptyDot.displayName = 'Dot';

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${R.prop('color')};
`;
Dot.displayName = 'Dot';

export function Status({ className, color, text, empty, dataTestId }) {
  const DotComponent = empty ? EmptyDot : Dot;
  return (
    <StatusWrapper className={className}>
      {!!color && <DotComponent color={color} data-testid={dataTestId} />}
      <span>{text}</span>
    </StatusWrapper>
  );
}

Status.displayName = 'Status';
Status.propTypes = {
  empty: bool,
  color: string,
  className: string,
  text: oneOfType([element, string]),
  dataTestId: string,
};
Status.defaultProps = {
  className: '',
  text: '',
  empty: false,
  dataTestId: 'color-dot',
};

const EntityTitle = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: ${getThemeColor(['primaryLighter1'])};
  letter-spacing: 1px;
  height: 100%;
  padding: 5px;
  text-transform: capitalize;
`;

const leftPositionBorderRadius = css`
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  margin-left: 5px;
`;

const rightPositionBorderRadius = css`
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  margin-right: 5px;
`;

const defaultPositionBorderRadius = css`
  border-radius: 13px;
`;

// getBorderRadiusByPosition :: ComponentProps -> String
// ComponentProps = { position: String }
const getBorderRadiusByPosition = R.compose(
  R.cond([
    [R.equals('left'), R.always(leftPositionBorderRadius)],
    [R.equals('right'), R.always(rightPositionBorderRadius)],
    [R.T, R.always(defaultPositionBorderRadius)],
  ]),
  R.prop('position'),
);

const EntityStatusContainer = styled.div`
  font-weight: ${getThemeFont(['regular'])};
  background-color: ${R.prop('color')};
  font-size: 12px;
  color: ${({ position, ...props }) =>
    position === 'left'
      ? getThemeColor(['primary'])(props)
      : getThemeColor(['white'])(props)};
  line-height: 18px;
  padding: 4px 12px;
  text-transform: capitalize;

  ${getBorderRadiusByPosition};
`;

const EntityDaysContainer = styled(EntityTitle)`
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: ${getThemeFont(['bold'])};
`;

export function EntityStatus({
  days,
  title,
  className,
  cardNumber,
  status: { color, text },
}) {
  return (
    <Wrapper className={className}>
      {!!cardNumber && <EntityTitle>{cardNumber}</EntityTitle>}
      <EntityStatusContainer
        color={titleColor}
        position="left"
        data-testid="status-left"
      >
        {title}
      </EntityStatusContainer>
      <EntityStatusContainer
        color={color}
        position="right"
        data-testid="status-right"
      >
        {text}
      </EntityStatusContainer>
      {days !== undefined && (
        <EntityDaysContainer>
          {days}
          {days === 1 ? ' Day' : ' Days'}
        </EntityDaysContainer>
      )}
    </Wrapper>
  );
}

EntityStatus.propTypes = {
  title: string.isRequired,
  cardNumber: string,
  status: shape({
    color: string,
    text: string,
  }).isRequired,
  days: number,
  className: string,
};
EntityStatus.defaultProps = {
  days: undefined,
  className: '',
};
