import React from 'react';

export const getContext = (ContextComp) => (BaseComp) =>
  function (props) {
    return (
      <ContextComp.Consumer>
        {(context) => <BaseComp {...{ ...props, ...context }} />}
      </ContextComp.Consumer>
    );
  };
