import React from 'react';
import { useOutSidebarContext } from '@poly/client-utils';
import { SidebarWidth } from '@poly/admin-ui';

import { userTabsSidebarId } from '../constants.js';
import { UserSidebarTabs } from './UserSidebarTabs.js';
import { SecondSidebarLayout } from '../../../components/SidebarLayouts.js';

export const useOpenUserTabsSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();

  return (user) =>
    openOutSidebar({
      width: SidebarWidth,
      id: userTabsSidebarId,
      Layout: SecondSidebarLayout,
      content: (
        <UserSidebarTabs userId={user._id} userName={user.profile.fullName} />
      ),
    });
};
