import { gql } from '@apollo/client';

export const USER_SEARCH_CHANGED = gql`
  subscription USER_SEARCH_CHANGED($searchInput: CollectionSearchParams!) {
    searchUserChanged(input: $searchInput) {
      id
      type
    }
  }
`;
