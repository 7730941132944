import * as R from 'ramda';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import React, { useMemo, useState } from 'react';
import { arrayOf, func, oneOfType, string } from 'prop-types';
import { Select, Icon, Popover, SelectComponents } from '@poly/admin-book';
import { isNilOrEmpty, ofArrayLegacy } from '@poly/utils';

import { ALL } from '../constants/general.js';
import { MultiSelectDropDown } from './MultiSelectDropDown.js';

const MultiSelectDropDownS = styled(MultiSelectDropDown)`
  .multiselect__control,
  .multiselect__control:hover,
  .multiselect__control:focus,
  .multiselect__control--is-focused {
    min-height: 32px;
  }

  .multiselect__control {
    padding: 0;
  }

  .multiselect__value-container {
    min-height: 30px;
    max-height: ${R.propOr('85px', 'maxHeight')};
    overflow-y: auto;

    > div:last-child {
      height: 22px;
    }
  }
`;

const IconS = styled(Icon)`
  margin: 0 4px;
`;

const PopoverContentS = styled.div`
  height: auto;
  padding: 10px;
  color: #fff;
  font-size: 12px;
  max-width: 200px;
  border-radius: 5px;
  background-color: #12347a;
`;

const LIST_USER_GROUPS_QUERY = gql`
  query LIST_USER_GROUPS_QUERY($input: ListUserGroupsInput) {
    listUserGroups(input: $input) {
      _id
      name
      defaultLoginApp
    }
  }
`;

// prepareUserGroupSelectOptions :: ([UserGroup] -> [UserGroup], Boolean)
// -> { listUserGroups: [UserGroup] } -> [Option]
const prepareUserGroupSelectOptions = (
  filterUserGroups,
  withAllValues = false,
) =>
  R.compose(
    R.unless(
      R.either(R.isEmpty, () => !withAllValues),
      R.prepend({ value: ALL, label: 'All' }),
    ),
    R.map(R.applySpec({ value: R.prop('_id'), label: R.prop('name') })),
    filterUserGroups,
    R.propOr([], 'listUserGroups'),
  );

export function UserGroupSelect({ filterUserGroups = R.identity, ...props }) {
  const [searchText, setSearchText] = useState('');

  const { data, loading } = useQuery(LIST_USER_GROUPS_QUERY, {
    variables: { input: null },
  });

  const options = prepareUserGroupSelectOptions(filterUserGroups)(data);

  const selectProps = {
    options,
    loading,
    searchText,
    setSearchText,
    name: 'UserGroupSelect',
    onInputChange: setSearchText,
    ...props,
  };

  return <Select {...selectProps} />;
}

UserGroupSelect.propTypes = {
  filterUserGroups: func,
};

// prepareSelectValue :: [ID] -> [Option] -> [Option]
const prepareSelectValue = (userGroupIds, options) => {
  const selectedValues = R.cond([
    [R.is(String), ofArrayLegacy],
    [isNilOrEmpty, R.always([])],
    [R.T, R.identity],
  ])(userGroupIds);

  return R.filter(
    R.compose((id) => R.any(R.equals(id))(selectedValues), R.prop('value')),
  )(options);
};

// prepareOptionsBeforeChange :: ID -> [Option] -> [ID]
const prepareOptionsBeforeChange = (value) =>
  R.compose(
    R.when(
      R.includes(ALL),
      R.ifElse(
        R.compose(R.includes(ALL), R.defaultTo([]), R.always(value)),
        R.reject(R.equals(ALL)),
        R.filter(R.equals(ALL)),
      ),
    ),
    R.map(R.prop('value')),
  );

// checkIfLastItem :: MultiValueRemoveProps -> Boolean
const checkIfLastItem = R.compose(
  R.equals(1),
  R.length,
  R.defaultTo([]),
  R.path(['selectProps', 'value']),
);

function UserGroupLastItemWarningIcon() {
  return <IconS name="confirm" size={12} color="#61646C" />;
}

function UserGroupLastItemWarning() {
  const warningMessage =
    "User Groups field can't be empty, please choose at least one option";

  return (
    <Popover
      withPortalAnchor
      bgColor="#12347a"
      Icon={UserGroupLastItemWarningIcon}
      content={<PopoverContentS>{warningMessage}</PopoverContentS>}
    />
  );
}

function MultiValueRemove(props) {
  const isLastItem = checkIfLastItem(props);

  if (isLastItem) {
    return <UserGroupLastItemWarning />;
  }

  return <SelectComponents.MultiValueRemove {...props} />;
}

export function UserGroupMultiSelect({
  value,
  onChange,
  filterUserGroups = R.identity,
  ...props
}) {
  const { data } = useQuery(LIST_USER_GROUPS_QUERY, {
    variables: { input: null },
  });

  const options = prepareUserGroupSelectOptions(filterUserGroups, true)(data);

  const preparedValue = useMemo(
    () => prepareSelectValue(value, options),
    [value, options],
  );

  const handleChange = (userGroups) => {
    const preparedCodes = prepareOptionsBeforeChange(value)(userGroups);
    onChange(preparedCodes);
  };

  const selectProps = {
    ...props,
    options,
    handleChange,
    value: preparedValue,
    components: { MultiValueRemove },
    placeholder: 'Start typing User Group',
  };

  return <MultiSelectDropDownS {...selectProps} />;
}

UserGroupMultiSelect.propTypes = {
  onChange: func,
  filterUserGroups: func,
  value: oneOfType([string, arrayOf(string)]),
};
