import * as R from 'ramda';

// repeatZeros :: Number -> String
const repeatZeros = R.invoker(1, 'repeat')(R.__, '0');

// generateMissingPrefixZeros :: Number -> String -> String
const generateMissingPrefixZeros = (desiredDigitCount) =>
  R.compose(
    R.ifElse(R.lte(R.__, 0), R.always(''), repeatZeros),
    R.subtract(desiredDigitCount),
    R.length,
  );

/**
 * prefixNumberWithLeadingZeros :: Number -> Number -> String
 */
export const prefixNumberWithLeadingZeros = (desiredDigitCount) =>
  R.compose(
    R.converge(R.concat, [
      generateMissingPrefixZeros(desiredDigitCount),
      R.identity,
    ]),
    R.when(R.complement(R.is(String)), R.toString),
  );
