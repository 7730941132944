import * as R from 'ramda';

import { wrapAsArrayIfSingleItem } from './useMultipleSubscriptions.js';

// isQueryOrSubscriptionsSkip :: Input -> Boolean
// InputOptions = { skip: Boolean }
// Input = {
//    queryOptions: InputOptions
//    subscriptionOptions: InputOptions | [InputOptions]
// }
export const isQueryOrSubscriptionsSkip = R.compose(
  R.any(R.equals(true)),
  R.flatten,
  R.juxt([
    R.pathOr(false, ['queryOptions', 'skip']),
    R.compose(
      R.map(R.propOr(false, 'skip')),
      wrapAsArrayIfSingleItem,
      R.prop('subscriptionOptions'),
    ),
  ]),
);
