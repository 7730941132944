import React from 'react';
import { LinkButton } from '@poly/admin-book';
import { openPrintWindowWithData } from '@poly/client-utils';
import { CommonPrintLayout } from '@poly/admin-ui';
import { PRINT_PDF_CAPTION } from '@poly/constants';
import { string, number } from 'prop-types';

import styled from 'styled-components';
import { forceTitleCase } from '@poly/utils';
import { SalesReportsTable } from './ClientSalesReportTable.js';
import { FlexColumn } from '../../components/FlexContainer.js';
import { salesReportRowsTypeProps } from './prop-types.js';

const SubTitleS = styled.p`
  font-size: 16px;
  margin-bottom: 0px;
`;

function ClientSalesReportTitle({ accountingMethod, year }) {
  return (
    <FlexColumn>
      <span>Client Sales Report</span>
      <SubTitleS>{forceTitleCase(accountingMethod)}</SubTitleS>
      <SubTitleS>{year}</SubTitleS>
    </FlexColumn>
  );
}

ClientSalesReportTitle.propTypes = {
  accountingMethod: string.isRequired,
  year: number.isRequired,
};

export function ClientSalesReportPrintBtn({
  salesReportRows,
  year,
  accountingMethod,
}) {
  const onClick = async () => {
    openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: SalesReportsTable,
      metaData: {
        title: (
          <ClientSalesReportTitle
            year={year}
            accountingMethod={accountingMethod}
          />
        ),
      },
      fileName: 'client_sales_report',
      salesReportRows,
    });
  };

  return (
    <LinkButton disabled={salesReportRows === 0} onClick={onClick}>
      {PRINT_PDF_CAPTION}
    </LinkButton>
  );
}

ClientSalesReportPrintBtn.propTypes = {
  accountingMethod: string,
  year: number,
  salesReportRows: salesReportRowsTypeProps,
};
