import React from 'react';
import { ProjectSpendTypes } from '@poly/constants';
import { Select } from '@poly/admin-book';

const projectSpendTypesSelectOptions = [
  { value: ProjectSpendTypes.REACTIVE_REPAIR, label: 'Reactive spend' },
  { value: ProjectSpendTypes.RECURRING_SERVICE, label: 'Recurring spend' },
  { value: ProjectSpendTypes.CAP_EX, label: 'Capital spend' },
];

export function ProjectSpendTypeSelect(props) {
  const selectProps = {
    placeholder: 'Select spend type',
    options: projectSpendTypesSelectOptions,
    ...props,
  };

  return <Select {...selectProps} />;
}
