import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { WindowedTable, Button } from '@poly/admin-book';
import { NOTHING_UI_STRING } from '@poly/constants';
import { arrayOf, func, number, shape, string } from 'prop-types';
import {
  applyPathOrNothingUI,
  commonSortQuery,
  keywordSortQuery,
} from '@poly/client-utils';
import { formatDate, pathEqLegacy, toDate } from '@poly/utils';
import { subDays } from 'date-fns';
import {
  extractTablePropsFromConfig,
  useNotificationState,
} from '@poly/admin-ui';

import { YellowWarningIcon } from '../../components/Icons.js';
import { SupplierLink } from '../../components/Links.js';
import { propOrNothingUI } from '../../modules/core/utils/general.js';
import { FlexColumn, FlexContainer } from '../../components/FlexContainer.js';
import { SearchPageContentWrapper } from '../../components/PageWithSearchHeader.js';
import { BottomPanelContainer } from '../../components/BottomPanel.js';
import { preparedOSIProject } from './propTypes.js';
import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';

const BottomPanelBodyS = styled(FlexColumn)`
  height: 100%;
  justify-content: space-between;
  padding: 15px 0 20px 0;
  width: calc(100% - 40px);
  margin: 0 0 0 50px;
`;

const ButtonsContainer = styled(FlexContainer)`
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
  padding-right: 20px;
`;

const TableS = styled(WindowedTable)`
  height: calc(100% - 125px);

  tr {
    margin: 0;
    width: 100%;
  }

  td:nth-child(1),
  th:nth-child(1) {
    width: 90px;
  }

  td:nth-child(2),
  th:nth-child(2) {
    width: 90px;
  }

  td:nth-child(3),
  th:nth-child(3) {
    width: 180px;
  }

  td:nth-child(5),
  th:nth-child(5) {
    width: 150px;
  }

  td:nth-child(6),
  th:nth-child(6) {
    width: 130px;
  }

  td:nth-child(7),
  th:nth-child(7) {
    width: 125px;
  }

  td:nth-child(8),
  th:nth-child(8) {
    width: 130px;
    text-align: center;
  }

  td:nth-child(9),
  th:nth-child(9) {
    width: 45px;

    label {
      margin-left: 9px;
    }
  }

  @media (max-width: 1250px) {
    td {
      font-size: 0.8rem;
    }

    tr {
      margin: 0;
      width: 100%;
    }

    td:nth-child(1),
    th:nth-child(1) {
      width: 95px;
    }

    td:nth-child(3),
    th:nth-child(3) {
      width: 170px;
    }

    td:nth-child(5),
    th:nth-child(5) {
      width: 130px;
    }

    td:nth-child(6),
    th:nth-child(6) {
      width: 120px;
    }

    td:nth-child(7),
    th:nth-child(7) {
      width: 100px;
    }

    td:nth-child(8),
    th:nth-child(8) {
      width: 85px;
      text-align: center;
    }

    td:nth-child(9),
    th:nth-child(9) {
      width: 25px;
      label {
        margin-left: 1px;
      }
    }
  }
`;

function getSupplierUILink({ supplierId, supplierName }) {
  return <SupplierLink _id={supplierId} name={supplierName} target="_blank" />;
}

getSupplierUILink.propTypes = {
  supplierId: string.isRequired,
  supplierName: string.isRequired,
};

// prepareLastContactDateToDisplay :: { reminder: InvoiceRequestReminder } -> String
export const prepareLastContactDateToDisplay = R.compose(
  R.join(''),
  R.juxt([
    applyPathOrNothingUI(
      ['reminder', 'nextRequestDate'],
      R.compose(formatDate, (date) => subDays(date, 7), toDate),
    ),
    R.ifElse(
      // if it's a regular request (not reminder)
      pathEqLegacy(['reminder', 'requestNumber'], 0),
      R.always(''),
      R.compose(
        R.concat(R.__, ')'),
        R.concat('('),
        R.toString,
        R.path(['reminder', 'requestNumber']),
      ),
    ),
  ]),
);

export const tableConfig = [
  [
    'Project #',
    (props) => <ProjectLink {...props} />,
    keywordSortQuery(['project.projectId']),
  ],
  [
    'Completed',
    applyPathOrNothingUI(['workCompletionDate'], formatDate),
    commonSortQuery(['project.workCompletionDate']),
  ],
  [
    'Supplier',
    R.ifElse(
      R.prop('supplierId'),
      getSupplierUILink,
      R.always(NOTHING_UI_STRING),
    ),
    commonSortQuery(['supplier.company.name']),
  ],
  [
    'Description',
    propOrNothingUI('description'),
    commonSortQuery(['project.description']),
  ],
  ['Client', propOrNothingUI('clientName'), keywordSortQuery(['client.name'])],
  [
    'Assigned CSR',
    propOrNothingUI('managerName'),
    commonSortQuery(['task.manager.profile.fullName']),
  ],
  [
    'Last Contact Date',
    R.ifElse(
      R.propSatisfies(R.isNil, 'reminder'),
      R.always(NOTHING_UI_STRING),
      prepareLastContactDateToDisplay,
    ),
    commonSortQuery(['reminder.nextRequestDate']),
  ],
  ['Invoice Status', R.prop('invoiceStatus'), commonSortQuery(['status'])],
];

// toggleRow :: ([ID], SetFunction) -> ID -> _
// SetFunction = ([ID]) -> _
const toggleRow = (selectedRows, setSelectedRows) => (id) =>
  R.compose(
    setSelectedRows,
    R.ifElse(R.includes(id), R.reject(R.equals(id)), R.append(id)),
  )(selectedRows);

// prepareRowsForSelect :: [ID] -> [Project] -> [ID]
const prepareRowsForSelect = (selectedRows) =>
  R.compose(
    R.when(R.eqProps('length', selectedRows), R.always([])),
    R.map(R.prop('_id')),
    R.filter(R.prop('supplierHaveEmail')),
  );

// getMutationVariables :: ([Project], [ID]) -> { input: SendSupplierInvoiceRequestInput }
const getMutationVariables = (projects, selectedRows) =>
  R.compose(
    R.assocPath(['input', 'sendRequestEntries'], R.__, {}),
    R.map(
      R.applySpec({
        supplierId: R.prop('supplierId'),
        projectId: R.prop('projectDBId'),
      }),
    ),
    R.filter(R.compose(R.includes(R.__, selectedRows), R.prop('_id'))),
  )(projects);

const sendSupplierInvoiceRequestMutation = gql`
  mutation SEND_SUPPLIER_INVOICE_REQUEST(
    $input: SendSupplierInvoiceRequestInput!
  ) {
    sendSupplierInvoiceRequest(input: $input) {
      projectIds
    }
  }
`;

function YellowWarningIconComp() {
  return (
    <YellowWarningIcon popoverText="Supplier accounting email is missing" />
  );
}

export function OSIPageTable({
  highlightedProjects,
  windowedTableProps,
  refetchTableData,
  ...sortProps
}) {
  const { showSuccessNotification } = useNotificationState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [submitting, setSubmitting] = useState(null);
  const [sendSupplierInvoiceRequest] = useMutation(
    sendSupplierInvoiceRequestMutation,
  );

  const tableProps = extractTablePropsFromConfig(tableConfig);

  const toggleSelectAll = () =>
    setSelectedRows(prepareRowsForSelect(selectedRows)(highlightedProjects));

  const onClick = async () => {
    setSubmitting(true);
    const variables = getMutationVariables(highlightedProjects, selectedRows);

    await sendSupplierInvoiceRequest({ variables });

    showSuccessNotification('Supplier request was sent.');

    await refetchTableData();

    setSubmitting(false);
  };

  return (
    <SearchPageContentWrapper>
      <TableS
        {...windowedTableProps}
        rows={highlightedProjects}
        selectedRowsInEnd
        selectedRows={selectedRows}
        isRowSelectable={R.prop('supplierHaveEmail')}
        toggleRow={toggleRow(selectedRows, setSelectedRows)}
        toggleSelectAll={toggleSelectAll}
        NotSelectableCheckboxComponent={YellowWarningIconComp}
        itemSize={55}
        showScrollBar
        {...tableProps}
        {...sortProps}
      />
      <BottomPanelContainer height="70px">
        <BottomPanelBodyS>
          <ButtonsContainer>
            <Button
              size="small"
              loader={submitting}
              onClick={onClick}
              disabled={submitting || R.isEmpty(selectedRows)}
            >
              Send Supplier Invoice Request
            </Button>
          </ButtonsContainer>
        </BottomPanelBodyS>
      </BottomPanelContainer>
    </SearchPageContentWrapper>
  );
}

OSIPageTable.propTypes = {
  refetchTableData: func.isRequired,
  highlightedProjects: arrayOf(preparedOSIProject),
  windowedTableProps: shape({
    loadMoreItems: func,
    itemCount: number,
    loadedCount: number,
  }),
};
