import * as R from 'ramda';
import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';

import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { AccountsPayableReportHeader } from './AccountsPayableReportHeader.js';
import { AccountsPayableReportBody } from './AccountsPayableReportBody.js';

const accountsPayableReportQuery = gql`
  query ACCOUNTS_PAYABLE_REPORT_QUERY($input: AccountsPayableReportInput!) {
    accountsPayableReport(input: $input) {
      supplier {
        _id
        company {
          name
        }
      }
      amount
    }
  }
`;

// getReportEntriesByFilter :: Object -> Object -> [AccountsPayableReportEntry]
const getReportEntriesByFilter = (filter) =>
  R.compose(
    R.map(R.assoc('date', filter.date)),
    R.reject(R.propSatisfies(R.isNil, 'supplier')),
    R.propOr([], 'accountsPayableReport'),
  );

export function AccountsPayableReportPage() {
  const [filter, setFilter] = useState({});
  const { data, loading } = useQuery(accountsPayableReportQuery, {
    variables: {
      input: filter,
    },
    skip: R.isEmpty(filter),
    fetchPolicy: 'network-only',
  });

  const reportEntries = getReportEntriesByFilter(filter)(data);

  return (
    <PageWithSearchHeader headerHeight="115px">
      <AccountsPayableReportHeader
        {...{ setFilter, reportEntries, filter, loading }}
      />
      <AccountsPayableReportBody
        loading={loading}
        reportEntries={reportEntries}
      />
    </PageWithSearchHeader>
  );
}
