import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { Table, moneyColumnStyles, getThemeColor } from '@poly/admin-book';
import { useMapConfigToTableProps } from '@poly/admin-ui';
import { AccountTypeCategories } from '@poly/constants';
import { propEqLegacy } from '@poly/utils';

import {
  flattenBalanceSheetDataResult,
  BalanceSheetRowType,
} from './flatten-balance-sheet.js';
import { balanceSheetDataPropType } from './prop-types.js';
import { formatAccountBalance } from '../GeneralLedgerPage/generalLedgerHelpers.js';

const BalanceSheetTableS = styled(Table)`
  ${moneyColumnStyles(2)};
  ${moneyColumnStyles(3)};

  th:nth-child(2),
  td:nth-child(2) {
    width: 200px;
  }

  td:nth-child(2) {
    padding-top: 0;
    padding-bottom: 0;
  }

  td {
    height: 28px;
  }

  /*
  Note: padding-bottom needs to fix cropped information
        on linux + firefox
   */
  @media print {
    padding-bottom: 20px;

    tbody {
      padding-bottom: 20px;
    }
  }
`;

const CategoryNameS = styled.div`
  font-weight: bold;
`;

const ParentAccountNameS = styled.div`
  font-weight: bold;
  margin-left: 20px;
`;

const ChildAccountNameS = styled.div`
  margin-left: 40px;
`;

// isRowTypeIn :: [BalanceSheetRowType] -> BalanceSheetRow -> Bool
const isRowTypeIn = (rowTypes) =>
  R.compose(R.includes(R.__, rowTypes), R.prop('type'));

const isTotalAmountRow = isRowTypeIn([
  BalanceSheetRowType.total,
  BalanceSheetRowType.categoryTotal,
  BalanceSheetRowType.grandTotal,
]);

const isGrandTotalRow = R.either(
  propEqLegacy('type', BalanceSheetRowType.grandTotal),
  R.both(
    propEqLegacy('type', BalanceSheetRowType.categoryTotal),
    propEqLegacy('category', AccountTypeCategories.asset),
  ),
);

const AccountBalanceCellS = styled.div`
  padding: 5px;
  font-weight: ${({ row }) => (isTotalAmountRow(row) ? 'bold' : 'normal')};
  border-top: ${({ row, ...props }) =>
    isTotalAmountRow(row)
      ? `2px solid ${getThemeColor(['black'])(props)}`
      : 'none'};
  border-bottom: ${({ row, ...props }) =>
    isGrandTotalRow(row)
      ? `7px double ${getThemeColor(['black'])(props)}`
      : 'none'};
`;

const getAccountBalanceCell = (row) => (
  <AccountBalanceCellS row={row}>
    {formatAccountBalance(row.balance)}
  </AccountBalanceCellS>
);

// formatAccountBalanceColumn :: BalanceSheetRow -> String
const formatAccountBalanceColumn = R.ifElse(
  isRowTypeIn([
    BalanceSheetRowType.category,
    BalanceSheetRowType.parent,
    BalanceSheetRowType.spacer,
  ]),
  R.always(''),
  getAccountBalanceCell,
);

// rowTypeIs :: BalanceSheetRowType -> BalanceSheetRow -> Bool
const rowTypeIs = propEqLegacy('type');

const accountCategoryNameMap = {
  [AccountTypeCategories.asset]: 'Assets',
  [AccountTypeCategories.liability]: 'Liabilities',
  [AccountTypeCategories.equity]: 'Owners Equity',
};

// accountCategoryToString :: AccountTypeCategory -> String
export const accountCategoryToString = R.prop(R.__, accountCategoryNameMap);

const getCategoryName = (name) => <CategoryNameS>{name}</CategoryNameS>;

const getParentAccountName = (name) => (
  <ParentAccountNameS>{name}</ParentAccountNameS>
);

const getChildAccountName = (name) => (
  <ChildAccountNameS>{name}</ChildAccountNameS>
);

// formatAccountNameColumn :: BalanceSheetRow -> ReactNode
const formatAccountNameColumn = R.cond([
  [
    rowTypeIs(BalanceSheetRowType.category),
    R.compose(getCategoryName, accountCategoryToString, R.prop('category')),
  ],
  [
    rowTypeIs(BalanceSheetRowType.parent),
    R.compose(getParentAccountName, R.path(['account', 'name'])),
  ],
  [
    rowTypeIs(BalanceSheetRowType.child),
    R.compose(
      getChildAccountName,
      R.join(' - '),
      R.reject(R.isNil),
      R.juxt([R.path(['account', 'code']), R.path(['account', 'name'])]),
    ),
  ],
  [
    rowTypeIs(BalanceSheetRowType.total),
    R.compose(
      getParentAccountName,
      R.concat('Total '),
      R.path(['account', 'name']),
    ),
  ],
  [
    rowTypeIs(BalanceSheetRowType.categoryTotal),
    R.compose(
      getCategoryName,
      R.concat('Total '),
      accountCategoryToString,
      R.prop('category'),
    ),
  ],
  [
    rowTypeIs(BalanceSheetRowType.grandTotal),
    R.compose(getCategoryName, R.always('Total Liabilities And Owners Equity')),
  ],
  [R.T, R.always('')],
]);

const balanceSheetTableConfig = [
  ['Account', formatAccountNameColumn],
  ['Balance', formatAccountBalanceColumn],
];

export function BalanceSheetTable({ data, ...restTableProps }) {
  const { rows, headers, columns } = useMapConfigToTableProps(
    flattenBalanceSheetDataResult,
    balanceSheetTableConfig,
    data,
  );

  return (
    <BalanceSheetTableS
      headers={headers}
      rows={rows}
      columns={columns}
      moreScrollIconPosition="20px"
      overflow="auto"
      showScrollBar
      {...restTableProps}
    />
  );
}

BalanceSheetTable.propTypes = {
  data: balanceSheetDataPropType,
};
