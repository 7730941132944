import * as R from 'ramda';
import styled, { css } from 'styled-components';

import { getThemeFont, getThemeColor } from '../utils.js';
import { getInputBorderColor } from '../Input/index.js';
import { markAsRequired } from '../lib/inputs.js';

const disabledInputStyle = css`
  cursor: not-allowed;
  background-color: ${getThemeColor(['secondaryLighter2'])};
`;

const RegularStyles = css`
  height: ${R.propOr('30px', 'height')};
  line-height: 18px;
  font-size: 11px;
  padding: 6px 10px;
`;

const SmallStyles = css`
  height: ${R.propOr('26px', 'height')};
  line-height: 12px;
  font-size: 10px;
  padding: 6px 10px;
`;

const BigStyles = css`
  height: 34px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 18px;
`;

const inputStyles = {
  small: SmallStyles,
  regular: RegularStyles,
  big: BigStyles,
};

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${R.propOr('34px', 'height')};
  border-radius: 4px;
  border: solid 1px ${getInputBorderColor};
  width: 100%;
  padding: 12px 16px;
  background-color: ${getThemeColor(['white'])};

  ${markAsRequired};
  ${({ disabled }) => disabled && disabledInputStyle};
  ${({ size }) => inputStyles[size]};
`;

export const Input = styled.input`
  font-weight: ${getThemeFont(['regular'])};
  width: 90%;
  border: none;
  border-bottom: solid 1px ${getThemeColor(['midLight'])};
  border-top: solid 1px ${getThemeColor(['midLight'])};
  background: transparent;
  color: ${getThemeColor(['primary'])};

  ${({ size }) => inputStyles[size]};
  padding-left: 0;
  ${({ disabled }) => disabled && disabledInputStyle};
`;

export const InputIconWrapper = styled.div`
  cursor: pointer;
  padding: 5px;
`;
