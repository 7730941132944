import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { Icon, Popover } from '@poly/admin-book';
import { shape, string } from 'prop-types';
import { NOTHING_UI_STRING } from '@poly/constants';
import { formatDateOrNothingUI } from '@poly/client-utils';

const RejectedInvoiceSupplierCompWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  color: #999;
`;

const SupplierNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const PopoverS = styled(Popover)`
  display: inline-block;

  > div > div > div {
    width: 100%;

    &:last-child {
      padding: 10px 15px;
    }
  }
`;

const PopoverContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  background-color: #12347a;
  color: #fff;
  border-radius: 5px;
  max-width: 230px;
  font-size: 14px;
`;

// getSupplierName :: Supplier -> String
const getSupplierName = R.pathOr(NOTHING_UI_STRING, ['company', 'name']);

export function RejectedInvoiceSupplierComp({ supplier, invoiceDate }) {
  const iconElement = <Icon name="reject" size={16} />;

  return (
    <RejectedInvoiceSupplierCompWrapper>
      <SupplierNameWrapper>
        {getSupplierName(supplier)}
        <PopoverS
          position="top"
          isClickable={false}
          bgColor="#12347a"
          title={iconElement}
          content={
            <PopoverContentWrapper>Rejected invoice</PopoverContentWrapper>
          }
        />
      </SupplierNameWrapper>
      <div>{formatDateOrNothingUI(invoiceDate)}</div>
    </RejectedInvoiceSupplierCompWrapper>
  );
}

RejectedInvoiceSupplierComp.propTypes = {
  invoiceDate: string,
  supplier: shape({
    company: shape({
      name: string,
    }),
  }),
};
