import React, { useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import {
  startOfMonth,
  endOfMonth,
  startOfDay,
  endOfDay,
  isEqual,
} from 'date-fns';
import { TableSearchInput, useSetItemsCount } from '@poly/admin-ui';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import {
  commonSortQuery,
  highlightMatchesInObject,
  useTableInfiniteScrollQuery,
} from '@poly/client-utils';
import {
  Button,
  DatePicker,
  Loader,
  MainHeader,
  PageHeaderContainer,
  ToolBarBtnDivider,
} from '@poly/admin-book';
import { gql } from '@apollo/client';
import {
  alwaysNewDate,
  assocBy,
  ensureIsDate,
  toDate,
  debounce,
} from '@poly/utils';
import {
  AccountingStatus,
  ASC_SORT_ORDER,
  ProjectType,
  WorkOrderStatus,
} from '@poly/constants';

import { FlexContainer } from '../../components/FlexContainer.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { SearchHeaderColumn } from '../../components/SearchHeaderColumn.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { HouseKeepingServiceTicketsReportTable } from './HouseKeepingServiceTicketsReportTable.js';
import {
  PageWithSearchHeader,
  SearchPageContentWrapper,
} from '../../components/PageWithSearchHeader.js';
import {
  generateSearchQuery,
  matchQueryGenerator,
} from '../../utils/generateSearchQuery.js';
import { HouseKeepingServiceTicketsReportPrintBtn } from './HouseKeepingServiceTicketsReportPrintBtn.js';
import { HouseKeepingServiceTicketsReportExportXLBtn } from './HouseKeepingServiceTicketsReportExportXLBtn.js';

const MainHeaderContainer = styled(FlexSpaceBetween)`
  width: 100%;
  align-items: center;
  &:last-child {
    margin-top: 5px;
  }
`;

const searchProjectsQuery = gql`
  query searchProjectsQuery($searchInput: CollectionSearchParams!) {
    searchProjects(input: $searchInput) {
      hits {
        _id
        projectId
        startDate
        endDate
        property {
          _id
          name
        }
        type
        parent {
          _id
          suppliers {
            _id
            company {
              name
              emails {
                type
                email
              }
              phones {
                type
                phone
              }
            }
          }
        }
        weeklyServiceTickets {
          week
          status
          attachment {
            fileName
            createdAt
          }
        }
      }
      total
    }
  }
`;

const filtersConfig = (currentDate) => [
  { name: 'startDate', defaultValue: startOfMonth(ensureIsDate(currentDate)) },
  { name: 'endDate', defaultValue: endOfMonth(ensureIsDate(currentDate)) },
];

const queryGenerators = {
  startDate: R.compose(
    R.assocPath(['range', 'startDate'], R.__, {}),
    R.compose(R.objOf('gte'), R.constructN(1, Date), startOfDay, ensureIsDate),
  ),
  endDate: R.compose(
    R.assocPath(['range', 'startDate'], R.__, {}),
    R.compose(R.objOf('lte'), R.constructN(1, Date), endOfDay, ensureIsDate),
  ),
};

// projectTypeQueryGenerator :: String -> Object
const projectTypeQueryGenerator = matchQueryGenerator('type');

// projectStatusQueryGenerator :: String -> Object
const projectStatusQueryGenerator = matchQueryGenerator('accountingStatus');

const generateProjectsSearchQuery = R.compose(
  R.over(
    R.lensProp('bool'),
    R.mergeRight({
      must_not: [
        { match: { status: WorkOrderStatus.BLOCKED } },
        { match: { status: WorkOrderStatus.COMPLETED } },
      ],
    }),
  ),
  R.over(
    R.lensPath(['bool', 'must']),
    R.concat([
      projectTypeQueryGenerator(ProjectType.HOUSEKEEPING),
      projectStatusQueryGenerator(AccountingStatus.PENDING),
    ]),
  ),
  generateSearchQuery(queryGenerators),
);

const FilterBtnS = styled(Button)`
  height: 35px;
  padding: 10px 18px;
`;

const PrintExportBtnContainer = styled(FlexContainer)`
  align-self: flex-end;
`;

// highlightProjectsMatches :: String -> Project -> Project
const highlightProjectsMatches = (search) => (object) =>
  highlightMatchesInObject({
    object,
    paths: [
      ['projectId'],
      ['property', 'name'],
      ['suppliers', 0, 'company', 'name'],
    ],
    search,
  });

// assocWithUrlParam :: [Project] -> [Project]
const assocWithUrlParam = assocBy('urlParam', R.prop('projectId'));

// prepareQueryVariables :: FormFilters -> FormFilters
// FormFilters = {
//    startDate: DateTime
//    endDate: DateTime
// }
const prepareQueryVariables = R.when(
  R.isEmpty,
  R.always({
    startDate: startOfMonth(alwaysNewDate()),
    endDate: endOfMonth(alwaysNewDate()),
  }),
);

// isDatesEqualCurried :: DateTime -> DateTime -> Boolean
const isDatesEqualCurried = R.curry((dateLeft, dateRight) =>
  R.compose(
    R.converge(isEqual, [R.prop('dateLeft'), R.prop('dateRight')]),
    R.over(R.lensProp('dateRight'), R.when(R.is(String), toDate)),
    R.over(R.lensProp('dateLeft'), R.when(R.is(String), toDate)),
  )({ dateLeft, dateRight }),
);

// isFiltersNotChanged :: { filters: FormFilters, searchFilters: FormFilters } -> Boolean
const isFiltersNotChanged = R.compose(
  R.all(R.equals(true)),
  R.juxt([
    R.converge(isDatesEqualCurried, [
      R.path(['filters', 'startDate']),
      R.path(['searchFilters', 'startDate']),
    ]),
    R.converge(isDatesEqualCurried, [
      R.path(['filters', 'endDate']),
      R.path(['searchFilters', 'endDate']),
    ]),
  ]),
);

// getProjects :: { searchProjects: { hits: [Project] } } -> [Project]
const getProjects = R.compose(
  // originalProjectId used at "Send Supplier Service Ticket Request" modal form
  // to display correct info (prevent highlighting mess)
  R.map(
    R.compose(
      assocBy('originalProjectId', R.prop('projectId')),
      assocBy('suppliers', R.path(['parent', 'suppliers'])),
    ),
  ),
  R.pathOr([], ['searchProjects', 'hits']),
);

export function HouseKeepingServiceTicketsReportPage() {
  const [filters, setFilters] = useState({});
  const [refetching, setRefetching] = useState(null);

  const searchTerm = useSelector(R.prop('searchText'));

  const input = useMemo(
    () => ({
      searchTerm,
      query: generateProjectsSearchQuery(prepareQueryVariables(filters)),
      sort: commonSortQuery(['projectId'])(ASC_SORT_ORDER),
    }),
    [searchTerm, filters],
  );

  const { data, loading, refetch, tableProps } = useTableInfiniteScrollQuery(
    searchProjectsQuery,
    input,
    {
      endpointName: 'searchProjects',
      inputName: 'searchInput',
      pageSize: 1000,
    },
  );

  useSetItemsCount(R.pathOr(0, ['searchProjects', 'total']), data);

  const projects = getProjects(data);

  const projectsWithUrlParam = projects.map(assocWithUrlParam);
  const projectsWithHighlight = projectsWithUrlParam.map(
    highlightProjectsMatches(searchTerm),
  );
  const { searchFilters, handlers } = useSearchFilters(
    filtersConfig(alwaysNewDate()),
  );

  const refetchTableData = async () => {
    setRefetching(true);
    await refetch();
    setRefetching(false);
  };

  const onClick = async () => {
    if (isFiltersNotChanged({ filters, searchFilters })) {
      setFilters(searchFilters);
      await refetchTableData();
    } else {
      setFilters(searchFilters);
    }
  };

  const refetchTableDataDebounce = useCallback(
    debounce(2000)(refetchTableData),
    [refetchTableData],
  );

  return (
    <PageWithSearchHeader headerHeight="120px">
      <PageHeaderContainer height="120px">
        <MainHeaderContainer>
          <MainHeader>Bass Pro Service Tickets</MainHeader>
          <PrintExportBtnContainer>
            <HouseKeepingServiceTicketsReportPrintBtn
              projects={projects}
              startDate={searchFilters.startDate}
              endDate={searchFilters.endDate}
            />
            <ToolBarBtnDivider />
            <HouseKeepingServiceTicketsReportExportXLBtn
              projects={projects}
              startDate={searchFilters.startDate}
              endDate={searchFilters.endDate}
            />
          </PrintExportBtnContainer>
        </MainHeaderContainer>
        <MainHeaderContainer>
          <FlexContainer>
            <SearchHeaderColumn
              titleWidth="100px"
              filterWidth="200px"
              margin="0"
              title="Date From"
            >
              <DatePicker
                value={searchFilters.startDate}
                onChange={handlers.startDate}
              />
            </SearchHeaderColumn>
            <SearchHeaderColumn
              titleWidth="100px"
              filterWidth="200px"
              margin="0"
              title="Date To"
            >
              <DatePicker
                value={searchFilters.endDate}
                onChange={handlers.endDate}
              />
            </SearchHeaderColumn>
            <FilterBtnS loader={loading || refetching} onClick={onClick}>
              Filter
            </FilterBtnS>
          </FlexContainer>
          <FlexContainer>
            <TableSearchInput />
          </FlexContainer>
        </MainHeaderContainer>
      </PageHeaderContainer>
      <SearchPageContentWrapper>
        {loading || refetching ? (
          <Loader />
        ) : (
          <HouseKeepingServiceTicketsReportTable
            windowedTableProps={tableProps}
            projects={projectsWithHighlight}
            searchTerm={searchTerm}
            refetchTableData={refetchTableDataDebounce}
          />
        )}
      </SearchPageContentWrapper>
    </PageWithSearchHeader>
  );
}
