import * as R from 'ramda';
import { object } from 'prop-types';
import React, { useMemo } from 'react';
import { FormSpy } from 'react-final-form';
import { mergeAllDeepRight } from '@poly/utils';

import { SectionComp } from './components/Section.js';
import { sectionTypes } from '../formTypes.js';
import { configRenderFields, isSectionDependsOnValues } from '../formUtils.js';

const shouldNotRenderSection = R.both(
  isSectionDependsOnValues,
  R.complement(R.prop('isVisible')),
);

// isFieldVisibleByValues :: FormValues -> FormFieldConfig -> Boolean
// FormValues, FormFieldConfig = Object
const isFieldVisibleByValues = (values) =>
  R.propSatisfies(
    R.either(R.isNil, (renderIfFn) => renderIfFn(values)),
    'renderIf',
  );

function SectionContent(props) {
  const {
    defaultLayout,
    layout,
    globalSectionLayout,
    renderIf,
    fields,
    values,
  } = props;

  const isVisible = useMemo(
    () =>
      R.any(isFieldVisibleByValues(values), fields) &&
      (R.isNil(renderIf) || renderIf(values)),
    [values],
  );

  const preparedFields = useMemo(
    () => configRenderFields(fields, values),
    [values],
  );

  if (shouldNotRenderSection({ isVisible, ...props })) return null;

  const sectionProps = {
    ...props,
    isVisible,
    fields: preparedFields,
    sectionLayout: mergeAllDeepRight([
      defaultLayout,
      globalSectionLayout,
      layout,
    ]),
    labelLayout: R.mergeAll([
      R.propOr({}, 'label', defaultLayout),
      R.propOr({}, 'label', globalSectionLayout),
      R.propOr({}, 'label', layout),
    ]),
  };

  return <SectionComp {...sectionProps} />;
}

/* eslint-disable react/forbid-prop-types */
SectionContent.displayName = 'SectionLayout';
SectionContent.propTypes = {
  ...sectionTypes,
  defaultLayout: object,
  layout: object,
  globalSectionLayout: object,
  values: object,
};
SectionContent.defaultProps = {
  defaultLayout: {
    width: '100%',
    margin: '18px 0 10px 0',
    padding: '0',
    label: {
      size: '20px',
      color: 'primary',
    },
  },
  layout: {},
  globalSectionLayout: {},
};

export const SectionLayout = React.memo((props) =>
  isSectionDependsOnValues(props) ? (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => <SectionContent {...props} values={values} />}
    </FormSpy>
  ) : (
    <SectionContent {...props} />
  ),
);
