import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { bool, func, oneOfType, shape, string } from 'prop-types';

import { SupplierFormCheckbox } from '../Checkbox.js';
import { EthnicitySelect } from './EthnicitySelect.js';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const EthnicitySelectS = styled(EthnicitySelect)`
  margin-top: 30px;
  width: calc(100% * 2 + 30px);
  margin-left: calc(-100% - 30px);
`;

export function MBEComponent({ name, value, onChange, error = '', ...props }) {
  const checkboxValue = R.propOr(false, 'isMBE', value);
  const selectValue = R.propOr(false, 'minority', value);
  const onCheckboxChange = (isMBE) =>
    onChange({ ...value, isMBE, minority: undefined });
  const onSelectChange = (minority) => onChange({ ...value, minority });

  return (
    <Wrapper isOpen={checkboxValue}>
      <SupplierFormCheckbox
        {...{ name }}
        value={checkboxValue}
        onChange={onCheckboxChange}
      />
      {!!checkboxValue && (
        <EthnicitySelectS
          value={selectValue}
          onChange={onSelectChange}
          error={error}
          {...props}
        />
      )}
    </Wrapper>
  );
}

MBEComponent.displayName = 'MBEComponent';

MBEComponent.propTypes = {
  name: string,
  value: oneOfType([
    string,
    shape({
      isMBE: bool,
      minority: oneOfType([string, bool]),
    }),
  ]),
  onChange: func,
  error: string,
};
