import * as R from 'ramda';
import {
  ASC_SORT_ORDER,
  DESC_SORT_ORDER,
  ELASTIC_SCORE_FIELD,
} from '@poly/constants';

export const keywordSortQueryBase = (path) => (order) => ({
  [`${R.join('.', path)}.keyword`]: { order },
});

export const keywordSortQuery = (path) => (order) =>
  [ELASTIC_SCORE_FIELD, keywordSortQueryBase(path)(order)];

export const keywordNestedSortQuery = (path) => (order) =>
  [
    ELASTIC_SCORE_FIELD,
    {
      [`${R.join('.', path)}.keyword`]: {
        order,
        nested: { path: path[0] },
      },
    },
  ];

export const commonNestedSortQuery = (path) => (order) =>
  [
    ELASTIC_SCORE_FIELD,
    {
      [`${R.join('.', path)}`]: {
        order,
        nested: { path: path[0] },
      },
    },
  ];

export const listLengthSortQuery = (path) => (order) => {
  const key = R.join('.', path);
  return [
    ELASTIC_SCORE_FIELD,
    {
      _script: {
        type: 'number',
        script: { source: `doc.${key}.length`, lang: 'painless' },
        order,
      },
    },
  ];
};

export const commonSortQuery = (path) => (order) => {
  const key = R.join('.', path);
  return [ELASTIC_SCORE_FIELD, { [key]: { order } }];
};

export const commonReverseSortQuery = (path) => (order) => {
  const key = R.join('.', path);
  const reverseOrder =
    order === ASC_SORT_ORDER ? DESC_SORT_ORDER : ASC_SORT_ORDER;
  return [ELASTIC_SCORE_FIELD, { [key]: { order: reverseOrder } }];
};
