import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

import React, { useEffect, useState } from 'react';
import { useUpdateQueryParams, useLocation } from '@poly/client-routing';
import { isNilOrEmpty } from '@poly/utils';
import { Loader } from '@poly/admin-book';
import {
  TableCardWithPaginationContainer,
  useSetInitialPaginationOnMount,
  useSetItemsCount,
  ALL,
} from '@poly/admin-ui';
import {
  parseFieldToNumberIfExists,
  paginationToQueryParams,
} from '@poly/client-utils';

import {
  PageWithSearchHeader,
  SearchPageContentWrapper,
} from '../../components/PageWithSearchHeader.js';
import {
  SupplierPaymentHistoryHeader,
  supplierPaymentHistoryPageHeaderConfig,
} from './SupplierPaymentHistoryHeader.js';
import { defaultFilters } from './constants.js';
import { SupplierPaymentHistoryTable } from './SupplierPaymentHistoryTable.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';

// prepareQueryInput :: PageFilters -> SupplierTransactionHistoryInput
// PageFilters = {
//   checkNumber: String
//   invoiceDate: {
//     startDate: String
//     endDate: String
//   },
//   invoiceId: String
//   invoiceStatus: String
//   projectId: String
//   supplierId: String
// }
const prepareQueryInput = R.compose(
  R.reject(isNilOrEmpty),
  parseFieldToNumberIfExists('checkNumber'),
  R.mergeAll,
  R.juxt([R.omit(['invoiceDate']), R.propOr({}, 'invoiceDate')]),
);

export const GET_SUPPLIER_TRANSACTION_HISTORY = gql`
  query GET_SUPPLIER_TRANSACTION_HISTORY(
    $input: SupplierTransactionHistoryInput!
  ) {
    getSupplierTransactionHistory(input: $input) {
      supplier {
        company {
          name
        }
      }
      transactions {
        hits {
          _id
          date
          type
          aacCheck {
            checkNumber
          }
          accountName
          project {
            _id
            projectId
          }
          invoice {
            _id
            invoiceNumber
          }
          invoiceAmount
          paymentAmount
          paymentMode
        }
        total
      }
    }
  }
`;

export function SupplierPaymentHistoryPage() {
  const pagination = useSelector(R.prop('pagination'));
  const updateQueryParams = useUpdateQueryParams();
  const location = useLocation();

  const [filters, setFilters] = useState(null);
  const { searchFilters } = useSearchFilters(
    supplierPaymentHistoryPageHeaderConfig,
  );

  useSetInitialPaginationOnMount();

  useEffect(() => {
    if (R.isEmpty(location.search)) {
      updateQueryParams(defaultFilters);
    } else {
      setFilters(searchFilters);
    }
  }, []);

  const variables = {
    input: {
      ...prepareQueryInput(filters),
      ...paginationToQueryParams(pagination),
    },
  };
  const skip = !filters?.supplierId || filters.supplierId === ALL;
  const { data, loading } = useQuery(GET_SUPPLIER_TRANSACTION_HISTORY, {
    variables,
    skip,
  });

  useSetItemsCount(
    R.pathOr(0, ['getSupplierTransactionHistory', 'transactions', 'total']),
    data,
  );

  const transactions = R.pathOr(
    [],
    ['getSupplierTransactionHistory', 'transactions', 'hits'],
    data,
  );
  const supplierName = R.pathOr(
    '',
    ['getSupplierTransactionHistory', 'supplier', 'company', 'name'],
    data,
  );
  const headerProps = {
    setFilters,
    exportProps: { transactions, filters, supplierName },
  };

  return (
    <PageWithSearchHeader headerHeight="230px">
      <SupplierPaymentHistoryHeader loading={loading} {...headerProps} />
      <SearchPageContentWrapper>
        <TableCardWithPaginationContainer>
          {loading ? (
            <Loader />
          ) : (
            <SupplierPaymentHistoryTable transactions={transactions} />
          )}
        </TableCardWithPaginationContainer>
      </SearchPageContentWrapper>
    </PageWithSearchHeader>
  );
}
