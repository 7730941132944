import { gql } from '@apollo/client';
import { contactDetailsFields } from './fragments.js';

export const USERS_EMAILS = gql`
  query STAFF_USERS_BY_SEARCH_TABLE($searchInput: CollectionSearchParams!) {
    searchUsers(input: $searchInput) {
      hits {
        _id
        fullName
        email
      }
      total
    }
  }
`;

export const USERS_BY_SEARCH = gql`
  query USERS_BY_SEARCH($searchInput: CollectionSearchParams!) {
    searchUsers(input: $searchInput) {
      hits {
        _id
        fullName
        emails {
          address
        }
      }
    }
  }
`;

export const DEFAULT_USER_QUERY = gql`
  query DEFAULT_USER_QUERY($id: ID!) {
    user(id: $id) {
      _id
      fullName
    }
  }
`;

export const CONTACT_DETAILS = gql`
  query CONTACT_DETAILS($id: ID!) {
    contactUser(id: $id) {
      isStaff
      ...contactDetailsFields
    }
  }

  ${contactDetailsFields}
`;

export const FIND_USER_CONTACT_BY_EMAIL = gql`
  query FIND_USER_CONTACT_BY_EMAIL($email: Email!) {
    findUserContactByEmail(email: $email) {
      _id
      email
      links {
        clientId
        propertyId
        supplierId
      }
    }
  }
`;
