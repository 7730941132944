import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useNotificationState } from '@poly/admin-ui/src/hooks/useNotificationState.js';

const editJournalPostingDateMutation = gql`
  mutation editJournalPostingDate(
    $transactionNumber: Int!
    $update: EditJournalPostingDateInput!
  ) {
    editJournalPostingDate(
      transactionNumber: $transactionNumber
      update: $update
    ) {
      _id
    }
  }
`;

export const useEditJournalPostingDate = ({ transaction_number, date }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [postingDate, setPostingDate] = useState(new Date(date));
  const { showSuccessNotification } = useNotificationState();

  const [editJournalPostingDate] = useMutation(editJournalPostingDateMutation);

  const toggleEditMode = () => setIsEditMode(!isEditMode);

  const handleEditJournalPostingDate = async () => {
    await editJournalPostingDate({
      variables: {
        transactionNumber: transaction_number,
        update: { date: postingDate },
      },
    });

    showSuccessNotification('Posting Date was successfully updated');
    toggleEditMode();
  };

  return {
    postingDate,
    isEditMode,
    toggleEditMode,
    setPostingDate,
    handleEditJournalPostingDate,
  };
};
