import React from 'react';
import * as R from 'ramda';
import { getUserWorkPhoneNumberExt, getUserWorkPhoneObject } from '@poly/utils';
import { commonSortQuery } from '@poly/client-utils';
import { NOTHING_UI_STRING } from '@poly/constants';

import { EmailLink, PhoneLink } from '../../components/Links.js';
import {
  getWorkPhoneFormatted,
  getCellPhoneFormatted,
} from '../tables/index.js';

export const usersPhoneWithExtColumn = ['Phone', getWorkPhoneFormatted];

export const usersMobileColumn = ['Phone', getCellPhoneFormatted];

export const userWorkPhoneExtColumn = [
  'Ext',
  R.compose(R.defaultTo(NOTHING_UI_STRING), getUserWorkPhoneNumberExt),
];

export const usersPhoneColumn = [
  'Phone',
  (user) => <PhoneLink number={getUserWorkPhoneObject(user).number} />,
];

export const usersEmailColumn = [
  'Email',
  EmailLink,
  commonSortQuery(['emailsStr']),
];
