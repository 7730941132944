import React from 'react';
import { shape } from 'prop-types';
import { noCase } from 'change-case';

import {
  FlexColumn,
  SearchResultItemContainer,
  FlexFullWidthJustifyStart,
} from './common/wrappers.js';
import {
  StatusS,
  SearchResultItemSpace,
  SearchResultItemTitle,
  SearchResultItemLinked,
  SearchResultItemDivider,
} from './common/components.js';
import { addressPath } from './common/selectors.js';
import { clientStatusesColors } from '../../../../constants/clients.js';
import { highlightedTextPropType } from './common/propTypes.js';

export function ClientsSearchResult({ name, status, cardNumber, ...props }) {
  return (
    <SearchResultItemContainer>
      <FlexColumn>
        <FlexFullWidthJustifyStart>
          <SearchResultItemLinked>{name}</SearchResultItemLinked>
          <SearchResultItemSpace />
          <SearchResultItemTitle>{cardNumber}</SearchResultItemTitle>
        </FlexFullWidthJustifyStart>
        <SearchResultItemDivider />
        <SearchResultItemTitle>{addressPath(props)}</SearchResultItemTitle>
      </FlexColumn>
      <StatusS text={noCase(status)} color={clientStatusesColors[status]} />
    </SearchResultItemContainer>
  );
}

ClientsSearchResult.propTypes = {
  name: highlightedTextPropType.isRequired,
  address: shape({
    formatted_address: highlightedTextPropType.isRequired,
  }).isRequired,
  status: highlightedTextPropType.isRequired,
  cardNumber: highlightedTextPropType.isRequired,
};
