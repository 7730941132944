import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  DefaultBodyWrapper,
  TableCardWithoutTabs,
  getThemeColor,
} from '@poly/admin-book';

import { TimeSheetReportTable } from '../modules/tables/timeSheetReportTable/TimeSheetReportTable.js';
import { TimeSheetReportPageHeader } from '../modules/pagesHeaders/TimeSheetReportPageHeader/index.js';
import {
  initialTimeSheetReport,
  setTimeSheetReport,
} from '../modules/pagesHeaders/TimeSheetReportPageHeader/TimeSheetReportReducer.js';

const BodyWrapper = styled(DefaultBodyWrapper)`
  background-color: ${getThemeColor(['white'])};
  height: auto;
  flex-grow: 1;
`;

export function TimeSheetReportPage() {
  useEffect(() => () => setTimeSheetReport(initialTimeSheetReport), []);

  return (
    <>
      <TimeSheetReportPageHeader />
      <BodyWrapper>
        <TableCardWithoutTabs>
          <TimeSheetReportTable />
        </TableCardWithoutTabs>
      </BodyWrapper>
    </>
  );
}
