import React from 'react';
import { formatSelectOptionsByConstantsValue } from '@poly/client-utils';
import { supplierTypes } from '@poly/constants';
import { Select } from '@poly/admin-book';

import { halfWidth } from '../../../forms/common.js';
import { SupplierStatusSelect } from '../../../forms/supplierForm/form/components/SupplierStatusSelect.js';
import { supplierPrivateFormFields } from '../../../forms/supplierForm/form/sections/accountSection.js';

function BranchSupplierTypeSelect(props) {
  const options = formatSelectOptionsByConstantsValue(supplierTypes);

  return (
    <Select
      {...props}
      name="BranchSupplierTypeSelect"
      options={options}
      disabled
    />
  );
}

export const settingsSection = (hasAccessToPrivateSupplier) => [
  {
    label: 'Supplier Settings',
    order: 1,
    layout: { column: 1 },
    fields: [
      {
        label: 'Supplier Type',
        order: 1,
        layout: { row: 1, width: halfWidth },
        required: true,
        field: {
          name: 'type',
          Component: BranchSupplierTypeSelect,
        },
      },
      {
        label: 'Status',
        order: 2,
        layout: { row: 1, width: halfWidth },
        required: true,
        field: {
          name: 'status',
          Component: SupplierStatusSelect,
        },
      },
      ...(hasAccessToPrivateSupplier ? [supplierPrivateFormFields] : []),
    ],
  },
];
