import * as R from 'ramda';
import React from 'react';
import { NOTHING_UI_STRING } from '@poly/constants';

import { ProcedureActionsButton } from './ProcedureActionsButton.js';
import { AssetTypeColumnComponent } from './components.js';

// getAssetTypeNamesByProcedure :: Procedure -> [String]
const getAssetTypeNamesByProcedure = R.compose(
  R.map(R.prop('name')),
  R.propOr([], 'assetTypes'),
);

function StyledAssetType(props) {
  const assetTypeNames = getAssetTypeNamesByProcedure(props);

  if (R.isEmpty(assetTypeNames)) {
    return NOTHING_UI_STRING;
  }

  return assetTypeNames.map((assetTypeName) => (
    <AssetTypeColumnComponent key={assetTypeName}>
      {assetTypeName}
    </AssetTypeColumnComponent>
  ));
}

export const procedureTableConfig = [
  ['Procedure Name', R.propOr(NOTHING_UI_STRING, 'name'), R.prop('name')],
  ['Asset Types', StyledAssetType],
  ['', ProcedureActionsButton],
];
