import * as R from 'ramda';
import React, { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useUserLogout, setCurrentSentryUser } from '@poly/client-utils';
import { firstLettersOfEachWord } from '@poly/utils';
import { useCurrentUserByStoreOrQuery } from '@poly/client-utils/src/hooks/useCurrentUserByStoreOrQuery.js';

import { UserPopupComp } from './UserPopupComp.js';
import { routesNames } from '../../routes/index.js';
import { HistoryStorageName } from './HistoryPopUp/constants.js';
import { useUserAuthBroadcastChannel } from '../../hooks/useBroadcastChannel.js';

// getRoleNameByUser :: User -> String
const getRoleNameByUser = R.compose(
  R.pathOr('Undefined', ['userGroup', 'name']),
  R.head,
  R.propOr([], 'userGroups'),
);

export function UserPopup() {
  const [isOpen, setIsOpen] = useState(false);
  const logOutUser = useUserLogout();

  const client = useApolloClient();

  const { user } = useCurrentUserByStoreOrQuery();

  const { postIsLoggedOut } = useUserAuthBroadcastChannel();

  if (!user) return null;

  const close = () => setIsOpen(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  const initials = firstLettersOfEachWord(user?.fullName);

  const onLogout = () => {
    logOutUser({
      client,
      localStorage,
      loginRoute: routesNames.LOGIN,
      storageItemsToRemove: [HistoryStorageName],
    });

    setCurrentSentryUser(user);
    postIsLoggedOut();
  };

  return (
    <UserPopupComp
      {...user}
      role={getRoleNameByUser(user)}
      close={close}
      isOpen={isOpen}
      initials={initials}
      onLogout={onLogout}
      toggleOpen={toggleOpen}
    />
  );
}
