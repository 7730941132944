import React, { useState } from 'react';
import { DefaultBodyWrapper, TableCardWithoutTabs } from '@poly/admin-book';

import { Supplier1099ReportTable } from './Supplier1099ReportTable.js';
import { EmptyListMessage } from '../../components/EmptyListMessage.js';
import { Supplier1099ReportHeader } from './Supplier1099ReportHeader.js';
import { useSupplier1099Report } from './useSupplier1099Report.js';

export function Supplier1099ReportPage() {
  const [query, setQuery] = useState(null);

  const { loading, filteredRows, tableProps } = useSupplier1099Report(query);

  return (
    <>
      <Supplier1099ReportHeader {...{ query, setQuery, filteredRows }} />
      <DefaultBodyWrapper>
        <TableCardWithoutTabs>
          {query ? (
            <Supplier1099ReportTable loading={loading} {...tableProps} />
          ) : (
            <EmptyListMessage />
          )}
        </TableCardWithoutTabs>
      </DefaultBodyWrapper>
    </>
  );
}
