import React from 'react';
import { defaultTheme } from '../ThemeProvider/index.js';

/* eslint-disable max-len, react/jsx-closing-bracket-location, react/no-unknown-property, react/prop-types, @cspell/spellchecker */
export const arrowsIcons = {
  'arrow-up': ({ color, ...props }) => (
    <svg
      {...props}
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(296.000000, 315.000000)">
          <path
            fill={color}
            transform="rotate(-180 6,6)"
            d="M303.869618,319.186387 C304.128209,318.937871 304.547467,318.937871 304.806057,319.186387 C305.064648,319.434902 305.064648,319.837825 304.806057,320.086341 L301.968219,322.813613 C301.709629,323.062129 301.290371,323.062129 301.031781,322.813613 L298.193943,320.086341 C297.935352,319.837825 297.935352,319.434902 298.193943,319.186387 C298.452533,318.937871 298.871791,318.937871 299.130382,319.186387 L301.5,321.463682 L303.869618,319.186387 Z"
            fillRule="nonzero"
          />
        </g>
      </g>
    </svg>
  ),
  'arrow-down': ({ color, ...props }) => (
    <svg
      {...props}
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-296.000000, -315.000000)">
          <path
            fill={color}
            d="M303.869618,319.186387 C304.128209,318.937871 304.547467,318.937871 304.806057,319.186387 C305.064648,319.434902 305.064648,319.837825 304.806057,320.086341 L301.968219,322.813613 C301.709629,323.062129 301.290371,323.062129 301.031781,322.813613 L298.193943,320.086341 C297.935352,319.837825 297.935352,319.434902 298.193943,319.186387 C298.452533,318.937871 298.871791,318.937871 299.130382,319.186387 L301.5,321.463682 L303.869618,319.186387 Z"
            fillRule="nonzero"
          />
        </g>
      </g>
    </svg>
  ),
  'arrow-back': ({ color, ...props }) => (
    <svg
      {...props}
      viewBox="0 0 15 11"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path
          d="M18.666,11.799875 L8.025,11.799875 L11.072,8.752875 C11.209,8.615875 11.277,8.436875 11.277,8.257875 C11.277,8.077875 11.209,7.898875 11.072,7.761875 C10.799,7.488875 10.355,7.488875 10.082,7.761875 L5.839,12.004875 C5.703,12.140875 5.634,12.320875 5.634,12.499875 C5.634,12.678875 5.703,12.857875 5.839,12.994875 L10.082,17.236875 C10.219,17.373875 10.398,17.442875 10.577,17.442875 C10.756,17.442875 10.936,17.373875 11.072,17.236875 C11.209,17.100875 11.277,16.921875 11.277,16.741875 C11.277,16.562875 11.209,16.383875 11.072,16.246875 L8.025,13.199875 L18.666,13.199875 C19.053,13.199875 19.366,12.886875 19.366,12.499875 C19.366,12.113875 19.053,11.799875 18.666,11.799875"
          id="path-1"
        />
      </defs>
      <g
        id="Icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icons-actions" transform="translate(-125.000000, -663.000000)">
          <g
            id="icons/ui/direction/arrow/left"
            transform="translate(120.000000, 656.000000)"
          >
            <rect id="dummy" x="0" y="0" width="25" height="25" />
            <mask id="mask-2" fill={defaultTheme.colors.white}>
              <use xlinkHref="#path-1" />
            </mask>
            <use fill={defaultTheme.colors.black} xlinkHref="#path-1" />
            <rect
              id="mask"
              fill={color}
              mask="url(#mask-2)"
              x="-0.451171875"
              y="0.801757812"
              width="24"
              height="24"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  'arrow-play-back': ({ color, ...props }) => (
    <svg
      {...props}
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-200.000000, -315.000000)">
          <path
            fill={color}
            d="M207.414214,321 L210.707107,324.292893 C211.097631,324.683418 211.097631,325.316582 210.707107,325.707107 C210.316582,326.097631 209.683418,326.097631 209.292893,325.707107 L205.292893,321.707107 C204.902369,321.316582 204.902369,320.683418 205.292893,320.292893 L209.292893,316.292893 C209.683418,315.902369 210.316582,315.902369 210.707107,316.292893 C211.097631,316.683418 211.097631,317.316582 210.707107,317.707107 L207.414214,321 Z M204,317 L204,325 C204,325.552285 203.552285,326 203,326 C202.447715,326 202,325.552285 202,325 L202,317 C202,316.447715 202.447715,316 203,316 C203.552285,316 204,316.447715 204,317 Z"
            fillRule="nonzero"
          />
        </g>
      </g>
    </svg>
  ),
  'arrow-forward': ({ color, ...props }) => (
    <svg
      {...props}
      viewBox="0 0 15 11"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M19.1605,12.00475 L14.9185,7.76275 C14.6445,7.48875 14.2015,7.48875 13.9285,7.76275 C13.7915,7.89975 13.7235,8.07875 13.7235,8.25775 C13.7235,8.43675 13.7915,8.61575 13.9285,8.75275 L16.9745,11.79975 L6.3345,11.79975 C5.9485,11.79975 5.6345,12.11275 5.6345,12.49975 C5.6345,12.88675 5.9485,13.19975 6.3345,13.19975 L16.9745,13.19975 L13.9275,16.24775 C13.7905,16.38375 13.7225,16.56375 13.7225,16.74275 C13.7225,16.92175 13.7905,17.10075 13.9275,17.23775 C14.0645,17.37475 14.2435,17.44275 14.4225,17.44275 C14.6015,17.44275 14.7815,17.37475 14.9175,17.23775 L19.1605,12.99475 C19.2975,12.85875 19.3655,12.67875 19.3655,12.49975 C19.3655,12.32075 19.2975,12.14175 19.1605,12.00475"
          id="path-1"
        />
      </defs>
      <g
        id="Icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icons-actions" transform="translate(-185.000000, -663.000000)">
          <g
            id="icons/ui/direction/arrow/right"
            transform="translate(180.000000, 656.000000)"
          >
            <rect id="dummy" x="0" y="0" width="25" height="25" />
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1" />
            </mask>
            <use fill="#2D2F33" xlinkHref="#path-1" />
            <rect
              id="mask"
              fill={color}
              mask="url(#mask-2)"
              x="0.682985938"
              y="0.099609375"
              width="24"
              height="24"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  'arrow-play-forward': ({ color, ...props }) => (
    <svg
      {...props}
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-272.000000, -315.000000)">
          <path
            fill={color}
            d="M277.585786,321 L274.292893,317.707107 C273.902369,317.316582 273.902369,316.683418 274.292893,316.292893 C274.683418,315.902369 275.316582,315.902369 275.707107,316.292893 L279.707107,320.292893 C280.097631,320.683418 280.097631,321.316582 279.707107,321.707107 L275.707107,325.707107 C275.316582,326.097631 274.683418,326.097631 274.292893,325.707107 C273.902369,325.316582 273.902369,324.683418 274.292893,324.292893 L277.585786,321 Z M281,317 C281,316.447715 281.447715,316 282,316 C282.552285,316 283,316.447715 283,317 L283,325 C283,325.552285 282.552285,326 282,326 C281.447715,326 281,325.552285 281,325 L281,317 Z"
            fillRule="nonzero"
          />
        </g>
      </g>
    </svg>
  ),
  'upload-arrow': ({ color, ...props }) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 31.059 31.059"
    >
      <g>
        <path
          fill={color}
          d="M29.959,31.059H1.1c-0.483,0-0.875-0.392-0.875-0.875v-9.735c0-0.483,0.392-0.875,0.875-0.875
          c0.482,0,0.875,0.392,0.875,0.875v8.86h27.108v-8.86c0-0.483,0.392-0.875,0.875-0.875s0.875,0.392,0.875,0.875v9.735
          C30.834,30.667,30.442,31.059,29.959,31.059z"
        />
        <path
          fill={color}
          d="M15.529,23.622c-0.483,0-0.875-0.392-0.875-0.875V1.22c0-0.482,0.392-0.875,0.875-0.875
          c0.483,0,0.875,0.393,0.875,0.875v21.527C16.404,23.231,16.012,23.622,15.529,23.622z"
        />
        <path
          fill={color}
          d="M26.292,12.858c-0.229,0-0.457-0.089-0.629-0.267L15.529,2.12L5.395,12.591
          c-0.336,0.347-0.889,0.356-1.237,0.021c-0.347-0.336-0.355-0.89-0.02-1.237L14.9,0.254c0.33-0.339,0.928-0.339,1.258,0
          l10.763,11.12c0.336,0.348,0.326,0.901-0.021,1.237C26.73,12.776,26.511,12.858,26.292,12.858z"
        />
      </g>
    </svg>
  ),
  'arrow-back-calendar': ({ color, ...props }) => (
    <svg
      {...props}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8 6.19995L7.5 10.5L11.8 14.7999"
        stroke={defaultTheme.colors.midDark}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  'arrow-forward-calendar': ({ color, ...props }) => (
    <svg
      {...props}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.19995 6.19995L12.5 10.5L8.19995 14.7999"
        stroke={defaultTheme.colors.midDark}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};
