import { gql, useMutation } from '@apollo/client';
import { useNotificationContext } from '@poly/admin-book';

const PRINT_CLIENT_INVOICES_PDF_MUTATION = gql`
  mutation PRINT_CLIENT_INVOICES_PDF_MUTATION(
    $input: PrintClientInvoicesPDFInput!
  ) {
    printClientInvoicesPDF(input: $input) {
      _id
    }
  }
`;

export const usePrintInvoicesMutation = (setSelectedIds) => {
  const [printClientInvoices] = useMutation(PRINT_CLIENT_INVOICES_PDF_MUTATION);
  const { showSuccessNotification } = useNotificationContext();

  const onSubmit = async (ids) => {
    await printClientInvoices({ variables: { input: { ids } } });

    if (setSelectedIds) {
      setSelectedIds([]);
    }

    showSuccessNotification(
      "Client Invoice(s) are being generated. You'll be notified when PDF is ready",
    );
  };

  return onSubmit;
};
