import React from 'react';
import { object, oneOfType, string, number, bool } from 'prop-types';
import {
  Text,
  Status,
  ReadOnlyEditor,
  SectionWithCard,
  TableLayoutListWrapper,
} from '@poly/admin-book';
import { formatCount } from '@poly/utils';

const RemarksWrapper = SectionWithCard.withComponent(TableLayoutListWrapper);

export function RemarksSection({ remarks, title }) {
  return (
    <RemarksWrapper title={title || 'Remarks'}>
      <ReadOnlyEditor value={remarks} />
    </RemarksWrapper>
  );
}

RemarksSection.propTypes = {
  title: string,
  remarks: oneOfType([object, string]),
};

export function TitleWithPip({ count, color, text, empty }) {
  return (
    <Status
      text={`${text} (${formatCount(count)})`}
      color={color}
      empty={empty}
    />
  );
}

TitleWithPip.propTypes = {
  count: number.isRequired,
  color: string.isRequired,
  text: string.isRequired,
  empty: bool,
};

export function TitleWithoutPip({ count, text }) {
  return (
    <Text lineHeight="18px" spacing="1px" color="rgba(30, 36, 44, 0.55)">
      {`${text} (${formatCount(count)})`}
    </Text>
  );
}

TitleWithoutPip.propTypes = {
  count: number.isRequired,
  text: string.isRequired,
};
