import styled from 'styled-components';
import { getThemeColor, Icon, Editor } from '@poly/admin-book';

export const SupplierInvoiceLineWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &:last-child {
    border-bottom: 1px solid #f2f2f2;
  }
`;

export const SupplierInvoiceFlexLineS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0 18px 0;
  font-size: 18px;
  border-top: 1px solid #f2f2f2;

  ${({ isLastLine }) =>
    !!isLastLine &&
    `
    padding-bottom: 24px;
    justify-content: flex-start;

    > div {
      margin-right: 13px;
    }
  `}
`;

export const SupplierInvoiceFormLineS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px 0;

  > div {
    margin-right: 13px;
  }
`;

export const ButtonsWrapperS = styled.div`
  display: flex;
  flex-direction: row;

  > button {
    position: relative;

    :not(:first-child) {
      margin-left: 10px;
    }

    :not(:last-child) {
      margin-right: 10px;

      ::after {
        display: block;
        position: absolute;
        top: 9px;
        right: -16px;
        content: '';
        width: 12px;
        transform: rotate(90deg);
        border: 0.5px solid #bcbcbc;
      }
    }
  }
`;

export const InvoiceDescriptionEditorS = styled(Editor)`
  .ql-editor.ql-blank::before {
    font-size: 12px;
  }
`;

export const DeleteIconS = styled(Icon).attrs((props) => ({
  color: getThemeColor(['secondaryMid'])(props),
  name: 'delete',
  size: 13,
}))`
  cursor: pointer;
  margin-top: 35px;
`;
