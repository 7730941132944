import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useQuery, gql } from '@apollo/client';
import { bool, func, object, string } from 'prop-types';
import { useOutSidebarContext } from '@poly/client-utils';
import { FlexContainer, entities } from '@poly/admin-ui';
import { Loader, Text } from '@poly/admin-book';

import { assignSupplierFormId } from '../../constants.js';
import { assignSupplierFormTypePropTypes } from '../../propTypes.js';
import { FlexColumn, LightText, SectionWrapper } from '../../styles.js';
import { CloseSidebarFormIcon } from '../../../../../sidebars/components/commonSidebarFormComponents.js';
import { PROJECT_ASSIGN_SUPPLIERS_QUERY } from '../../../../core/hooks/projects/queries.js';
import { SuppliersListWithSearch } from './SuppliersListWithSearch/SuppliersListWithSearch.js';
import { formatAssetTitle } from '../../../../../sidebars/AssetSidebar/assetSidebarUtils.js';
import {
  isAssignSupplierFormType,
  formatProjectBreadCrumb,
  pickSelectedSuppliers,
  getCoordinates,
  isSendWOCFormType,
  isGenerateWOCFormType,
} from '../../utils.js';

const ASSET_ADD_SUPPLIERS_QUERY = gql`
  query ASSET_ADD_SUPPLIERS_QUERY($id: ID!) {
    asset(id: $id) {
      _id
      type {
        _id
        name
      }
      qrCodeId
      displayName
      manufacturerDoc {
        _id
        name
      }
      property {
        _id
        name
        alreadyUsedSupplierIds
        address {
          geo {
            coordinates
          }
        }
      }
      client {
        _id
        name
        alreadyUsedHQProjectSupplierIds
        address {
          geo {
            coordinates
          }
        }
      }
      suppliers {
        _id
      }
    }
  }
`;

const RECURRING_PROJECT_ADD_SUPPLIERS_QUERY = gql`
  query RECURRING_PROJECT_ADD_SUPPLIERS_QUERY($id: ID!) {
    recurringProject(id: $id) {
      _id
      type
      projectId
      property {
        _id
        name
        alreadyUsedSupplierIds
        address {
          geo {
            coordinates
          }
        }
      }
      client {
        _id
        name
        alreadyUsedHQProjectSupplierIds
        address {
          geo {
            coordinates
          }
        }
      }
      addedSuppliers {
        _id
      }
      adminPurchaseOrder {
        _id
        currentBalance
      }
    }
  }
`;

const ProjectInfoWrapper = styled(FlexContainer)`
  width: 70%;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 2px;

  > div:first-child {
    margin-right: 15px;
  }
`;

const InfoWrapper = styled(FlexColumn)`
  width: 30%;
`;

// getSelectedSupplierId :: FormData -> ID
const getSelectedSupplierId = R.compose(
  R.head,
  pickSelectedSuppliers,
  R.propOr({}, 'suppliers'),
);

// getEntitySupplierIds :: Entity -> [ID]
const getEntitySupplierIds = R.compose(
  R.map(R.prop('_id')),
  R.flatten,
  R.juxt([R.propOr([], 'suppliers'), R.propOr([], 'addedSuppliers')]),
);

// getAlreadyUsedSupplierIds :: Client | Property -> [ID]
const getAlreadyUsedSupplierIds = R.ifElse(
  R.has('alreadyUsedSupplierIds'),
  R.prop('alreadyUsedSupplierIds'),
  R.propOr([], 'alreadyUsedHQProjectSupplierIds'),
);

// getMainTitleByFormType :: String -> String
const getMainTitleByFormType = R.cond([
  [isAssignSupplierFormType, R.always('Assign Supplier')],
  [isSendWOCFormType, R.always('Send WOC')],
  [isGenerateWOCFormType, R.always('Generate WOC')],
  [R.T, R.always('Add Supplier')],
]);

export function AssignSupplierFormWithSearch({
  formType,
  formData,
  supplierId,
  noSupplierRequired,
  setNoSupplierRequired,
  ...props
}) {
  const {
    entity: { _id, name },
  } = formData;
  const { closeOutSidebar } = useOutSidebarContext();
  const { data: projectData, loading: projectLoading } = useQuery(
    PROJECT_ASSIGN_SUPPLIERS_QUERY,
    {
      variables: { id: _id },
      fetchPolicy: 'network-only',
      skip: name !== entities.PROJECT,
    },
  );
  const { data: assetData, loading: assetLoading } = useQuery(
    ASSET_ADD_SUPPLIERS_QUERY,
    {
      variables: { id: _id },
      fetchPolicy: 'network-only',
      skip: name !== entities.ASSET,
    },
  );
  const { data: recurringProjectData, loading: recurringProjectLoading } =
    useQuery(RECURRING_PROJECT_ADD_SUPPLIERS_QUERY, {
      variables: { id: _id },
      fetchPolicy: 'network-only',
      skip: name !== entities.RECURRING_PROJECT,
    });

  if (projectLoading || assetLoading || recurringProjectLoading) {
    return <Loader />;
  }

  const queryData = assetData || projectData || recurringProjectData;

  const entityData = R.propOr({}, name, queryData);

  const selectedSupplierId = getSelectedSupplierId(formData);

  const resultData = entityData?.property || entityData?.client;

  const resultCoordinates = getCoordinates(resultData);

  const entityInfoBreadCrumb = formatProjectBreadCrumb(entityData);

  const entitySupplierIds = getEntitySupplierIds(entityData);

  const title =
    name === entities.ASSET
      ? formatAssetTitle(entityData)
      : R.prop('projectId', entityData);

  const alreadyUsedSupplierIds = getAlreadyUsedSupplierIds(resultData);

  const onClose = () => closeOutSidebar(assignSupplierFormId);

  return (
    <>
      <SectionWrapper>
        <InfoWrapper>
          <Text size="16px" lineHeight="19px">
            {getMainTitleByFormType(formType)}
          </Text>
          <LightText margin="5px 0">{title}</LightText>
        </InfoWrapper>
        <ProjectInfoWrapper>
          <Text size="11px" lineHeight="13px">
            {entityInfoBreadCrumb}
          </Text>
          <CloseSidebarFormIcon onCancel={onClose} />
        </ProjectInfoWrapper>
      </SectionWrapper>
      {!supplierId && (
        <SuppliersListWithSearch
          formType={formType}
          entitySupplierIds={entitySupplierIds}
          propertyCoordinates={resultCoordinates}
          selectedSupplierId={selectedSupplierId}
          noSupplierRequired={noSupplierRequired}
          setNoSupplierRequired={setNoSupplierRequired}
          alreadyUsedSupplierIds={alreadyUsedSupplierIds}
          {...props}
        />
      )}
    </>
  );
}

AssignSupplierFormWithSearch.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formData: object.isRequired,
  noSupplierRequired: bool.isRequired,
  setNoSupplierRequired: func.isRequired,
  formType: assignSupplierFormTypePropTypes.isRequired,
  supplierId: string,
};
