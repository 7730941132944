import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';

import { Icon } from '../Icon/index.js';

const MoreScrollIconS = styled(Icon)`
  position: absolute;
  bottom: ${({ bottom }) => bottom || '10px'};
  left: 50%;

  @media print {
    display: none;
  }
`;

export function MoreScrollIcon({ moreScrollPosition }) {
  return (
    <MoreScrollIconS name="arrow-down" size="20" bottom={moreScrollPosition} />
  );
}

MoreScrollIcon.propTypes = {
  moreScrollPosition: string,
};

const isShownByElement = (el) => {
  const { clientHeight, scrollTop, scrollHeight } = el;
  return clientHeight + scrollTop + 10 < scrollHeight;
};

export const useMoreScroll = (bottom, id) => {
  const [isShown, setIsShown] = useState(true);

  useEffect(() => {
    const scrollableElement = id
      ? document.getElementById(id)
      : document.getElementsByTagName('TBODY')[0];
    if (scrollableElement) {
      setIsShown(isShownByElement(scrollableElement));
    }
  }, []);

  const onScroll = (e) => {
    setIsShown(isShownByElement(e.target));
  };
  return {
    MoreScrollIcon: () =>
      isShown ? (
        <MoreScrollIconS name="arrow-down" size="20" bottom={bottom} />
      ) : null,
    onScroll,
  };
};
