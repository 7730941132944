import { gql, useMutation } from '@apollo/client';
import { useNotificationState } from '@poly/admin-ui';
import { InvoiceTypes } from '@poly/constants';

const voidProjectInvoiceMutation = gql`
  mutation VOID_PROJECT_INVOICE_MUTATION($id: ID!) {
    voidInvoice(id: $id) {
      id
    }
  }
`;

const voidSupplierInvoiceMutation = gql`
  mutation VOID_SUPPLIER_INVOICE_MUTATION($id: ID!) {
    voidSupplierInvoice(id: $id) {
      id
    }
  }
`;

export const useVoidInvoiceMutation = (invoice) => {
  const { showSuccessNotification } = useNotificationState();
  const [voidInvoice] = useMutation(voidProjectInvoiceMutation);
  const [voidSupplierInvoice] = useMutation(voidSupplierInvoiceMutation);

  const voidInvoiceMutation =
    invoice.type === InvoiceTypes.supplier ? voidSupplierInvoice : voidInvoice;

  return async () => {
    await voidInvoiceMutation({ variables: { id: invoice.invoiceId } });
    showSuccessNotification('Invoice was successfully voided');
  };
};
