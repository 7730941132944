import React from 'react';
import styled from 'styled-components';
import { string, shape } from 'prop-types';
import { useOutSidebarContext } from '@poly/client-utils';
import { Text } from '@poly/admin-book';

import { SidebarIDs } from '../constants.js';
import { SidebarCloseIcon } from '../../components/SidebarLayouts.js';
import {
  SubcontractorAgreementInfo,
  subcontractorAgreementPropTypes,
} from '../../pages/RequestedSupplierDocumentsReview/sidebar/SubcontractorAgreementInfo.js';

const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-size: 12px;
  padding: 10px 24px 24px 24px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled(Text)`
  font-size: 20px;
  line-height: 24px;
`;

export function SubcontractorAgreementSidebar({
  company,
  subcontractorAgreement,
}) {
  const { closeOutSidebar } = useOutSidebarContext();

  const handleCloseSidebar = () => {
    closeOutSidebar(SidebarIDs.subcontractorAgreement);
  };
  return (
    <SidebarWrapper>
      <HeaderWrapper>
        <Title>Subcontractor Agreement</Title>
        <SidebarCloseIcon onClick={handleCloseSidebar} />
      </HeaderWrapper>
      <SubcontractorAgreementInfo
        company={company}
        agreement={subcontractorAgreement}
      />
    </SidebarWrapper>
  );
}

SubcontractorAgreementSidebar.propTypes = {
  company: shape({ name: string.isRequired }),
  subcontractorAgreement: subcontractorAgreementPropTypes,
};
