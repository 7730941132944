import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import { supplierDocumentRequestTypes } from '@poly/constants';
import { setTemporalUserToken } from '@poly/apollo-client';
import { graphqlTemporalTokenRoute } from '@poly/utils';
import { useRouterParams } from '@poly/client-routing';
import { jsonPostRequest } from '@poly/client-utils';
import { VendorInfoSection } from '@poly/admin-ui';
import { Loader } from '@poly/admin-book';

import { PageContainer, ContentWrapper } from './components.js';
import { DocumentsRequestForm } from './DocumentsRequestForm.js';
import { DocumentsRequestHeader } from './DocumentRequestHeader.js';
import { useHandleTemporalTokenError } from '../../hooks/useHandleTemporalTokenError.js';
import { useSupplierDocumentsRequestInfo } from './useSupplierDocumentsRequestInfo.js';

const { GRAPHQL_HTTP_URL = '' } = process.env;

// isMWBEOrVeteranCertificates :: {documentTypes: [String]} -> Boolean
const isMWBEOrVeteranCertificates = R.compose(
  R.isEmpty,
  R.reject(
    R.includes(R.__, [
      supplierDocumentRequestTypes.WBE,
      supplierDocumentRequestTypes.MBE,
      supplierDocumentRequestTypes.VETERAN,
    ]),
  ),
  R.propOr([], 'documentTypes'),
);

// withCOIDocuments :: {documentTypes: [String]} -> Boolean
const withCOIDocuments = R.compose(
  R.includes(true),
  R.map(
    R.includes(R.__, [
      supplierDocumentRequestTypes.GL,
      supplierDocumentRequestTypes.WC,
    ]),
  ),
  R.propOr([], 'documentTypes'),
);

export const useAuthUserByTemporalAccess = () => {
  const { randomId } = useRouterParams(['randomId']);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const handleTemporalTokenError = useHandleTemporalTokenError();

  useEffect(() => {
    const authUser = async () => {
      try {
        const { token } = await jsonPostRequest(
          graphqlTemporalTokenRoute(GRAPHQL_HTTP_URL),
          {
            randomId,
          },
        );
        setTemporalUserToken(token);
        setIsAuthorized(true);
      } catch (error) {
        handleTemporalTokenError(error);
      }
    };
    authUser();
  }, []);

  return { isAuthorized };
};

export function DocumentsRequestPage() {
  const { isAuthorized } = useAuthUserByTemporalAccess();
  const { loading, ...data } = useSupplierDocumentsRequestInfo(isAuthorized);

  const isMWBEOrVeteran = isMWBEOrVeteranCertificates(data);

  const withInsuranceLink = withCOIDocuments(data);

  if (!isAuthorized) {
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <PageContainer>
      <ContentWrapper>
        <DocumentsRequestHeader
          {...data}
          isMWBEOrVeteran={isMWBEOrVeteran}
          withInsuranceLink={withInsuranceLink}
        />
        <DocumentsRequestForm {...data} />
        <VendorInfoSection withInsuranceLink={withInsuranceLink} />
      </ContentWrapper>
    </PageContainer>
  );
}
