import { usePersistentFormValue } from './usePersistentFormValue.js';

export const usePersistDnDAreaWithMentions = (persistKey) => {
  const { onChangePersistentValue, cleanupRetainedValue, retainedValue } =
    usePersistentFormValue(persistKey);

  const pickText = (value) => {
    onChangePersistentValue(value.text);
  };

  return {
    cleanupRetainedValue,
    onChangePersistentValue: pickText,
    retainedValue: { text: retainedValue || '', mentions: {}, attachments: [] },
  };
};
