import * as R from 'ramda';
import { useQuery, gql, useLazyQuery } from '@apollo/client';
import { ensureIsDate, isNilOrEmpty } from '@poly/utils';
import { usePaginationParams } from '@poly/admin-ui/src/hooks/usePaginationParams.js';
import { useSetItemsCount } from '@poly/admin-ui/src/hooks/useSetItemsCount.js';
import { MAX_ITEMS } from '@poly/admin-ui/src/constants/general.js';

const SUB_CATEGORY_REPORT_QUERY = gql`
  query SUB_CATEGORY_REPORT_QUERY($input: SubCategoryReportInput!) {
    subCategoryReport(input: $input) {
      hits {
        _id
        invoiceDate
        project {
          _id
          projectId
          type
          description
        }
        supplier {
          _id
          company {
            name
          }
        }
        invoiceAmount
        managementFee
        taxAmount
        total
        costCenter
        clientGLCode {
          code
          name
        }
        subCategoryName
        approvedBy {
          _id
          profile {
            fullName
          }
          emails {
            address
          }
        }
        areaName
        regionName
        property {
          _id
          name
        }
      }
      total
    }
  }
`;

// prepareSubCategoryReportInputByFilter :: SubCategoryReportFilter -> SubCategoryReportInput
const prepareSubCategoryReportInputByFilter = R.compose(
  R.reject(isNilOrEmpty),
  R.applySpec({
    projectId: R.prop('project'),
    supplierId: R.prop('supplier'),
    clientId: R.prop('client'),
    startDate: R.compose(ensureIsDate, R.path(['invoiceDate', 'startDate'])),
    endDate: R.compose(ensureIsDate, R.path(['invoiceDate', 'endDate'])),
  }),
);

export const useSubCategoryReport = (filter) => {
  const paginationParams = usePaginationParams();
  const { data, loading } = useQuery(SUB_CATEGORY_REPORT_QUERY, {
    variables: {
      input: {
        ...prepareSubCategoryReportInputByFilter(filter),
        ...paginationParams,
      },
    },
    fetchPolicy: 'network-only',
    skip: isNilOrEmpty(filter),
  });

  useSetItemsCount(R.pathOr(0, ['subCategoryReport', 'total']), data);

  return {
    loading,
    reportItems: R.pathOr([], ['subCategoryReport', 'hits'], data),
  };
};

export const useSubCategoryReportLazyQuery = () => {
  const [queryHandler] = useLazyQuery(SUB_CATEGORY_REPORT_QUERY);

  const handler = async ({ filter }) => {
    const { data } = await queryHandler({
      variables: {
        input: {
          ...prepareSubCategoryReportInputByFilter(filter),
          size: MAX_ITEMS,
        },
      },
      fetchPolicy: 'network-only',
    });

    return R.pathOr([], ['subCategoryReport', 'hits'], data);
  };

  return handler;
};
