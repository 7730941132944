import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { shape, instanceOf, bool } from 'prop-types';
import { TextButton } from '@poly/admin-book';
import { CommonPrintLayout, useNotificationState } from '@poly/admin-ui';
import {
  formatFromToDateTitle,
  openPrintWindowWithData,
} from '@poly/client-utils';
import { PRINT_PDF_CAPTION } from '@poly/constants';

import { JournalEntryLedgerTable } from './JournalEntryLedgerTable.js';
import { journalEntryLedgerResultPropTypes } from './prop-types.js';

const SubTitleWrapper = styled.div`
  text-align: center;
`;

// isNoRecords :: JournalEntryLedgerResult -> Boolean
const isNoRecords = R.compose(
  R.isEmpty,
  R.pathOr([], ['getManualJournals', 'hits']),
);

export function JournalEntryLedgerExportPDFButton({ data, query, loading }) {
  const { showErrorNotification } = useNotificationState();

  const onClick = async () => {
    if (isNoRecords(data)) {
      showErrorNotification('No records to export');
      return;
    }
    const subtitle = formatFromToDateTitle(
      query.checkFromDate,
      query.checkToDate,
    );

    await openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: JournalEntryLedgerTable,
      metaData: {
        title: 'Journal Entry Ledger',
        additional: <SubTitleWrapper>{subtitle}</SubTitleWrapper>,
      },
      fileName: 'journal_entry_ledger',
      data,
      isPrint: true,
      loading,
    });
  };

  return <TextButton onClick={onClick}>{PRINT_PDF_CAPTION}</TextButton>;
}

JournalEntryLedgerExportPDFButton.propTypes = {
  query: shape({
    checkFromDate: instanceOf(Date),
    checkToDate: instanceOf(Date),
  }),
  data: shape({
    getManualJournals: journalEntryLedgerResultPropTypes,
  }),
  loading: bool.isRequired,
};
