import { gql } from '@apollo/client';

export const CREATE_TIMESHEET = gql`
  mutation CREATE_TIMESHEET($input: CreateTimesheetInput!) {
    createTimesheet(input: $input) {
      timesheet {
        _id
      }
    }
  }
`;

export const UPDATE_TIMESHEET = gql`
  mutation UPDATE_TIMESHEET($id: ID!, $input: UpdateTimesheetInput!) {
    updateTimesheet(id: $id, input: $input) {
      timesheet {
        _id
      }
    }
  }
`;

export const DELETE_TIMESHEET = gql`
  mutation DELETE_TIMESHEET($id: ID!) {
    deleteTimesheet(id: $id) {
      timesheet {
        _id
      }
    }
  }
`;
