import * as R from 'ramda';

const EMAIL_REGEXP_VALIDATOR = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@/.source +
    /((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([-\w]+\.)+\w{2,}))$/.source,
);

// due to addr-spec defined in RFC 5322
// validateEmail :: String -> Boolean
export const validateEmail = R.test(EMAIL_REGEXP_VALIDATOR);

// validationFuncWithAllValues :: [Validator] -> FormFieldValue -> AllFormValues -> ErrorString
export const validationFuncWithAllValues = R.curry(
  (validators, value, allValues) =>
    R.pipe(
      R.find(([validator]) => !validator(value, allValues)),
      R.prop(1),
      R.when(R.isNil, R.always(undefined)),
    )(validators),
);

// hasOnlyUSKeyboardCharacters :: String -> Boolean
export const hasOnlyUSKeyboardCharacters = R.complement(
  R.test(/[^\w\s*.,;:!@#$%"<>+=?~'&^)(\\|`[\]}{\-/]+/),
);

// getStringByRegExp :: RegExp -> String
const getStringByRegExp = R.compose(R.tail, R.init, R.toString);

const BASE_EXT_REGEXP = /(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))/;
const ALT_EXT_REGEXP = /(?:(?:\w+-?)*\w+)/;
const DOMAIN_NAME_REGEXP = /(?:\.(?:\w+-?)*\w+)/;
const DOMAIN_REGEXP = /(?:\.(?:[a-z]{2,}))/;
const PROTOCOL_REGEXP = /^(?:(?:https?|ftp):\/\/)/;
const CREDENTIAL_REGEXP =
  /(?:(?<=:\/\/)((?=(?<credential>(\S+@)))\k<credential>))?/;
const PORT_REGEXP = /(?::\d{2,5})?/;

const URL_REGEXP_MAIN_PART = new RegExp(
  // eslint-disable-next-line
  /(?!10(?:\.\d{1,3}){3})(?!127(?:\.​\d{1,3}){3})/.source +
    /(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})/.source +
    // eslint-disable-next-line
    /(?!172\.(?:1[​6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})/.source +
    // eslint-disable-next-line
    /(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1​,2}|2[0-4]\d|25[0-5])){2}/
      .source +
    new RegExp(
      `${getStringByRegExp(BASE_EXT_REGEXP)}|${getStringByRegExp(
        ALT_EXT_REGEXP,
      )}`,
    ).source +
    new RegExp(
      `${getStringByRegExp(DOMAIN_NAME_REGEXP)}*${getStringByRegExp(
        DOMAIN_REGEXP,
      )}`,
    ).source,
);

const URL_REGEXP_VALIDATOR = new RegExp(
  PROTOCOL_REGEXP.source +
    CREDENTIAL_REGEXP.source +
    new RegExp(`(?:${getStringByRegExp(URL_REGEXP_MAIN_PART)})`).source +
    PORT_REGEXP.source +
    new RegExp(`(?${getStringByRegExp(/:\/[^\s]*/)})?$`).source,
  'i',
);

export const validateURL = R.test(URL_REGEXP_VALIDATOR);
