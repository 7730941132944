import React from 'react';
import styled from 'styled-components';
import { Text, DefaultBodyWrapper } from '@poly/admin-book';
import { Link } from '@poly/client-routing';
import { string } from 'prop-types';

const NotFoundContainer = styled(DefaultBodyWrapper)`
  height: calc(100vh - 180px);
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
  text-align: center;
  background-color: transparent;
`;

const NotFoundTitle = styled(Text)`
  margin: 25px 0;
`;

export function NotFoundEntity({ entityName }) {
  return (
    <NotFoundContainer>
      <Text
        spacing="5px"
        weight="hairline"
        size="70px"
        color={['primaryLighter3']}
      >
        Not Found
      </Text>
      <NotFoundTitle spacing="1px" size="24px">
        This isn&#39;t {entityName} you&#39;re looking for
      </NotFoundTitle>
      <Text weight="regular" size="17px" color={['primaryLighter3']}>
        <Link href="/">Go To Homepage</Link>
      </Text>
    </NotFoundContainer>
  );
}

NotFoundEntity.propTypes = {
  entityName: string,
};
