import React from 'react';

import { useProceduresQuery } from './hooks/useProceduresQuery.js';
import { ManageProceduresPageTable } from './ManageProceduresPageTable.js';
import { ManageProceduresPageHeader } from './ManageProceduresPageHeader.js';

export function ManageProceduresPage() {
  const { loading, procedures } = useProceduresQuery();

  return (
    <>
      <ManageProceduresPageHeader />
      <ManageProceduresPageTable loading={loading} procedures={procedures} />
    </>
  );
}
