import { v4 as uuidV4 } from 'uuid';
import { useMemo } from 'react';

import { HIERARCHY_NODE_TYPES } from '../constants.js';
import { buildTree, getTreePropertiesIds } from '../utils.js';
import { useTreeLogic } from '../../../components/Tree/useTreeLogic.js';

const getInitialNodeState = () => ({
  id: uuidV4(),
  title: '',
  type: HIERARCHY_NODE_TYPES.region,
  expanded: true,
});

export const usePropertyHierarchyTreeLogic = (hierarchyObj) => {
  const initialTreeData = useMemo(
    () => (hierarchyObj.nodes ? buildTree(hierarchyObj.nodes) : []),
    [hierarchyObj.nodes],
  );

  const {
    treeData,
    setTreeData,
    validationErrors,
    addNode,
    validateTree,
    onChangeNodeTitleHandler,
    onAddNodeHandler,
    onRemoveNodeHandler,
    expandTree,
  } = useTreeLogic(initialTreeData, getInitialNodeState);

  const treePropertiesIds = getTreePropertiesIds(treeData);

  return {
    validationErrors,
    treeData,
    treePropertiesIds,
    setTreeData,
    onChangeNodeTitleHandler,
    onAddNodeHandler,
    onRemoveNodeHandler,
    addNode,
    validateTree,
    expandTree,
  };
};
