import styled from 'styled-components';
import React from 'react';

import { AdministrativeStatistics } from './AdministrativeStatistics.js';
import { SubcontractorsStatistics } from './SubcontractorsStatistics.js';
import { EntityStatisticsS, DividerS } from '../entitiesStatisticsStyles.js';

function AACSuppliers() {
  return <EntityStatisticsS header="Suppliers" items={[]} />;
}

const AdministrativeStatisticsS = styled(AdministrativeStatistics)`
  margin-top: 50px;
`;

export function AACSuppliersStatistics(props) {
  return (
    <div>
      <AACSuppliers />
      <DividerS />
      <SubcontractorsStatistics {...props} />
      <AdministrativeStatisticsS {...props} />
    </div>
  );
}
