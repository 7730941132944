import * as R from 'ramda';
import styled from 'styled-components';

const DEFAULT_WRAPPER_WIDTH = '15vw';

export const SearchResultItemContainer = styled.div`
  width: 100%;
  border-bottom: 2px solid #e1e6f4;
  padding: 15px 20px;
  font-size: 14px;
  word-break: break-all;
  &:hover {
    background-color: #f6f9fd;
    cursor: pointer;
  }
  display: flex;
  align-content: center;
  justify-content: space-between;
  min-height: 75px;
`;

const Flex = styled.div`
  display: flex;
  align-content: center;
  justify-content: ${R.propOr('space-between', 'justifyContent')};
  width: 100%;
`;

export const FlexColumn = styled(Flex)`
  flex-direction: column;
`;

export const FixedWidthColumn = styled(FlexColumn)`
  width: ${({ width }) => width || DEFAULT_WRAPPER_WIDTH};
  flex-shrink: 0;
  flex-grow: 1;
`;

export const RightAlignedFlexColumn = styled(FlexColumn)`
  margin-right: auto;
  margin-left: 20px;
`;

export const FlexFullWidthJustifyStart = styled(Flex)`
  justify-content: flex-start;
`;
