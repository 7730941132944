import React from 'react';
import styled from 'styled-components';
import { PageHeaderContainer, MainHeader, Loader } from '@poly/admin-book';
import { useRedirectIfHasNoPermissions } from '@poly/client-utils';
import {
  CREATE_CLIENT_INVOICE_PERMISSION,
  EDIT_CLIENT_INVOICE_PERMISSION,
  READ_CLIENT_BATCH_PERMISSION,
  CREATE_CLIENT_BATCH_PERMISSION,
  PRINT_CLIENT_INVOICE_PERMISSION,
} from '@poly/security';

import { getClientInvoicingPageConfig } from './constants.js';
import { AdminSettingsGrid } from '../../components/AdminSettingsGrid.js';
import { useBillingProfileList } from './useBillingProfileList.js';

const AdminSettingsGridS = styled(AdminSettingsGrid)`
  justify-content: flex-start;
`;

export function ClientInvoicingPage() {
  const { billingProfileConfigs, loading } = useBillingProfileList();

  useRedirectIfHasNoPermissions(
    [
      CREATE_CLIENT_INVOICE_PERMISSION,
      EDIT_CLIENT_INVOICE_PERMISSION,
      READ_CLIENT_BATCH_PERMISSION,
      CREATE_CLIENT_BATCH_PERMISSION,
      PRINT_CLIENT_INVOICE_PERMISSION,
    ],
    false,
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Client Invoicing</MainHeader>
      </PageHeaderContainer>
      <AdminSettingsGridS
        items={getClientInvoicingPageConfig(billingProfileConfigs)}
        columnWidth="25%"
      />
    </>
  );
}
