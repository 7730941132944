import { useQuery } from '@apollo/client';
import { keywordSortQuery } from '@poly/client-utils';
import { ASC_SORT_ORDER, ServiceTypeStatuses } from '@poly/constants';
import { SERVICE_TYPES_BY_SEARCH } from '../hocs/serviceTypes/queries.js';

export const useQueryServiceTypesBySearch = (searchTerm, supplierSource) =>
  useQuery(SERVICE_TYPES_BY_SEARCH, {
    variables: {
      searchInput: {
        size: 100,
        searchTerm,
        query: {
          bool: {
            must: [
              ...(supplierSource ? [{ term: { supplierSource } }] : []),
              { term: { status: ServiceTypeStatuses.active } },
            ],
          },
        },
        sort: keywordSortQuery(['name'])(ASC_SORT_ORDER),
      },
    },
  });
