import * as R from 'ramda';
import {
  ExcelExportCellType,
  createExcelExportCell,
  performExcelExport,
  formatFromToDateTitle,
} from '@poly/client-utils';
import { formatDate, pathEqLegacy } from '@poly/utils';

import { WorkOrderStatus } from '@poly/constants';

// getRecentProjectsExcelRowConfig :: Array => [ExcelExportDataCell]
const getRecentProjectsExcelRowConfig = R.zipWith(createExcelExportCell, [
  ...R.repeat(ExcelExportCellType.default, 4),
]);

// mapActiveProjectsDataToArray :: [ActiveProjects] -> [ExcelExportDataCell]
const mapActiveProjectsDataToArray = R.map(
  R.compose(
    getRecentProjectsExcelRowConfig,
    R.converge(Array.of, [
      R.prop('projectId'),
      R.prop('description'),
      R.path(['property', 'name']),
      R.compose(formatDate, R.prop('startDate')),
    ]),
  ),
);

// mapCompletedProjectsDataToArray :: [CompletedProjects] -> [ExcelExportDataCell]
const mapCompletedProjectsDataToArray = R.map(
  R.compose(
    getRecentProjectsExcelRowConfig,
    R.converge(Array.of, [
      R.prop('projectId'),
      R.prop('description'),
      R.path(['property', 'name']),
      R.compose(formatDate, R.prop('workCompletionDate')),
    ]),
  ),
);

/**
 * prepareProjectDataForXLS :: QueryDataObject -> PrepareProjectsObject
 */
const prepareProjectDataForXLS = R.compose(
  R.unless(
    R.isEmpty,
    R.converge(R.mergeRight, [
      R.pipe(R.prop(0), R.pick(['status'])),
      R.pipe(
        R.ifElse(
          pathEqLegacy([0, 'status'], WorkOrderStatus.ACTIVE),
          mapActiveProjectsDataToArray,
          mapCompletedProjectsDataToArray,
        ),
        R.objOf('rows'),
      ),
    ]),
  ),
  R.defaultTo([]),
  R.path(['data', 'searchProjects', 'hits']),
);

// getTitleRowConfig :: PrepareProjectsObject -> [ExcelExportDataCell]
const getTitleRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.tableHeader, 4),
  ]),
  R.unnest,
  R.juxt([
    R.always(['Project Id', 'Description', 'Property']),
    R.ifElse(
      R.equals(R.__, WorkOrderStatus.ACTIVE),
      R.always('Started'),
      R.always('Completed'),
    ),
  ]),
  R.prop('status'),
);

// getRecentProjectsRowsConfig :: ({[RecentProjects], FilterDateObject}) -> [[ExcelExportDataCell]]
const getRecentProjectsRowsConfig = ({ recentProjects, filterDate }) => {
  const prepareProjects = prepareProjectDataForXLS(recentProjects);

  const preparedProjectsRows = R.isEmpty(prepareProjects)
    ? []
    : prepareProjects.rows;

  const rows = [getTitleRowConfig(prepareProjects), ...preparedProjectsRows];

  if (filterDate) {
    rows.unshift([
      {
        cellType: ExcelExportCellType.title,
        value: formatFromToDateTitle(filterDate.gte, filterDate.lte),
      },
    ]);
  }

  return rows;
};

// eslint-disable-next-line import/no-unused-modules
export const getRecentProjectsExcelExportConfig = ({
  recentProjects,
  filterDate,
}) => ({
  exportFileName: 'recent_projects.xlsx',
  columnWidths: [20, 35, 25, 15],
  rows: [
    [
      {
        cellType: ExcelExportCellType.title,
        value: 'Recent Projects',
      },
    ],

    ...getRecentProjectsRowsConfig({ recentProjects, filterDate }),
  ],
});

// getFilterDate :: QueryObject  -> FilterDateObject
const getFilterDate = R.ifElse(
  pathEqLegacy(['bool', 'must', 0, 'match', 'status'], WorkOrderStatus.ACTIVE),
  R.path(['bool', 'filter', 'range', 'startDate']),
  R.path(['bool', 'filter', 'range', 'workCompletionDate']),
);

// checkIfNoResults :: QueryDataObject -> Boolean
const checkIfNoResults = R.compose(
  R.isEmpty,
  R.pathOr([], ['data', 'searchProjects', 'hits']),
);

export const getRecentProjectsXLS = ({ query, queryData }) => {
  const isNoResults = checkIfNoResults(queryData);

  if (!isNoResults) {
    const excelConfig = getRecentProjectsExcelExportConfig({
      recentProjects: queryData,
      filterDate: getFilterDate(query),
    });

    performExcelExport(excelConfig);
  }
};
