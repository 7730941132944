import * as R from 'ramda';
import {
  DatePicker,
  Input,
  FieldLayout,
  useFormSubscription,
} from '@poly/admin-book';
import React from 'react';
import { SystemAccountTypes } from '@poly/constants';
import {
  MoneyInput,
  useNotificationState,
  useOnSubmitSetStopSubmitting,
} from '@poly/admin-ui';
import styled from 'styled-components';
import { alwaysNewDate } from '@poly/utils';
import { gql, useMutation } from '@apollo/client';
import { func, shape } from 'prop-types';
import { useNavigate, usePristineSubscribe } from '@poly/client-routing';
import { Form } from 'react-final-form';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { FlexColumn, FlexContainer } from '../../components/FlexContainer.js';
import { routesNames } from '../../routes/index.js';
import { filterAccountsBySystemType } from '../ChartOfAccounts/helper.js';
import { AccountsSelect } from '../../components/AccountsSelect.js';
import { halfWidth } from '../../modules/forms/common.js';
import { FormCardContainer } from '../../components/FormCardContainer.js';
import { onKeyDownToPreventFormSubmit } from '../../utils/form.js';

function BankAccountSelect(props) {
  return (
    <AccountsSelect
      {...props}
      filterAccounts={filterAccountsBySystemType(
        SystemAccountTypes.BANK_ACCOUNT,
      )}
      onKeyDown={onKeyDownToPreventFormSubmit}
      extractValue={R.prop('_id')}
    />
  );
}

function BankMoneyInput(props) {
  return <MoneyInput {...props} onKeyDown={onKeyDownToPreventFormSubmit} />;
}

function BankDataPicker(props) {
  return (
    <DatePicker
      {...props}
      width="100%"
      onKeyDown={onKeyDownToPreventFormSubmit}
    />
  );
}

function BankInput(props) {
  return <Input {...props} onKeyDown={onKeyDownToPreventFormSubmit} />;
}

const transferFundsFormId = 'transferFundsFormId';

const validateForm = R.ifElse(
  R.converge(R.equals, [R.prop('fromAccountId'), R.prop('toAccountId')]),
  R.always({ toAccountId: 'Accounts must be different' }),
  R.always({}),
);

const transferFundsMutation = gql`
  mutation transferFunds($input: TransferFundsInput!) {
    transferFunds(input: $input) {
      _id
    }
  }
`;

const FormS = styled.form`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const FormRowContainer = styled(FlexSpaceBetween)`
  width: 100%;
`;

const HalfSectionS = styled(FlexColumn)`
  width: ${halfWidth};
`;

const FlexContainerHalfS = styled(FlexContainer)`
  width: ${halfWidth};
`;

function TransferFundsFormRendered({ handleSubmit, form: { subscribe } }) {
  const { formSubscription } = usePristineSubscribe();
  useFormSubscription(subscribe, formSubscription);
  return (
    <FormCardContainer
      fallbackLink={routesNames.FINANCIAL_DIRECTORY}
      formId={transferFundsFormId}
    >
      <FormS id={transferFundsFormId} onSubmit={handleSubmit}>
        <HalfSectionS>
          <FormRowContainer>
            <FieldLayout
              required
              label="From Account"
              layout={{ width: halfWidth }}
              field={{
                name: 'fromAccountId',
                Component: BankAccountSelect,
              }}
              validators={[[R.identity, 'From account is required']]}
            />

            <FieldLayout
              required
              label="To Account"
              layout={{ width: halfWidth }}
              field={{
                name: 'toAccountId',
                Component: BankAccountSelect,
              }}
              validators={[[R.identity, 'To account is required']]}
            />
          </FormRowContainer>
          <FormRowContainer>
            <FieldLayout
              required
              label="Amount"
              layout={{ width: halfWidth }}
              field={{
                name: 'amount',
                Component: BankMoneyInput,
              }}
              validators={[[R.identity, 'Amount is required']]}
            />
            <FlexContainerHalfS>
              <FieldLayout
                required
                label="Date"
                field={{
                  name: 'date',
                  Component: BankDataPicker,
                }}
                validators={[[R.identity, 'Date is required']]}
              />
            </FlexContainerHalfS>
          </FormRowContainer>
        </HalfSectionS>
        <HalfSectionS>
          <FieldLayout
            label="Description"
            layout={{ width: '100%' }}
            field={{
              name: 'description',
              Component: BankInput,
            }}
          />
        </HalfSectionS>
      </FormS>
    </FormCardContainer>
  );
}

TransferFundsFormRendered.propTypes = {
  handleSubmit: func.isRequired,
  form: shape({
    subscribe: func.isRequired,
  }).isRequired,
};

export function TransferFundsForm() {
  const [transferFunds] = useMutation(transferFundsMutation);
  const { showSuccessNotification } = useNotificationState();
  const navigate = useNavigate();

  const submitHandler = async (values) => {
    await transferFunds({
      variables: { input: values },
    });
    showSuccessNotification('Transferred successfully');
    navigate(0);
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    transferFundsFormId,
    submitHandler,
  );

  return (
    <Form
      subscription={{}}
      keepDirtyOnReinitialize
      validate={validateForm}
      formId={transferFundsFormId}
      onSubmit={onSubmit}
      initialValues={{ date: alwaysNewDate() }}
    >
      {({ handleSubmit, form }) => (
        <TransferFundsFormRendered handleSubmit={handleSubmit} form={form} />
      )}
    </Form>
  );
}
