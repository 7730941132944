import React from 'react';
import styled from 'styled-components';
import { arrayOf, bool, shape, string } from 'prop-types';

import { ApReportTable } from './ApReportTable.js';

const MasterSupplierAPReportListS = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px 0;
`;

const MasterSupplierName = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 20px;
  margin: 20px 30px;
  height: 20px;
  flex-shrink: 0;
`;

const MasterSupplierTitle = styled.div`
  display: flex;
  font-weight: bold;
  margin-right: 15px;
`;

const MasterSupplierAPReportListWrapper = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 20px);
  overflow-y: auto;
  flex-direction: column;
`;

const ApReportTableS = styled(ApReportTable)`
  height: auto;

  table {
    max-height: none;

    thead {
      overflow-y: unset;
    }
  }
`;

export function MasterSupplierAPReportList({
  isPrintPDF,
  masterSupplierName,
  listConfig,
}) {
  return (
    <MasterSupplierAPReportListS>
      <MasterSupplierName>
        <MasterSupplierTitle>Master Supplier:</MasterSupplierTitle>
        {masterSupplierName}
      </MasterSupplierName>
      <MasterSupplierAPReportListWrapper>
        {listConfig.map((config) => (
          <ApReportTableS
            key={config.cardNumber}
            {...config}
            isPrintPDF={isPrintPDF}
          />
        ))}
      </MasterSupplierAPReportListWrapper>
    </MasterSupplierAPReportListS>
  );
}

MasterSupplierAPReportList.propTypes = {
  masterSupplierName: string,
  isPrintPDF: bool,
  listConfig: arrayOf(
    shape({
      terms: string,
      cardNumber: string,
      supplierName: string,
      invoices: arrayOf(shape({ _id: string.isRequired })),
    }),
  ),
};
