import * as R from 'ramda';
import styled from 'styled-components';
import React from 'react';
import {
  bool,
  func,
  shape,
  string,
  object,
  arrayOf,
  oneOfType,
} from 'prop-types';
import {
  Checkbox,
  Collapse,
  getThemeFont,
  getThemeColor,
  InputErrorMsg,
  Text,
} from '@poly/admin-book';

import { MultiSelect } from './styles.js';
import { shouldSend } from './mailToUtils.js';
import { FlexContainer } from '../../../../components/common.js';

const MailToCollapse = styled(Collapse)`
  & > div > div {
    font-size: 12px;
    color: ${getThemeColor(['primary'])};
  }

  & > div > svg {
    width: 12px;
    margin-left: 10px;

    & path {
      fill: ${getThemeColor(['primaryLighter2'])};
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const OtherBlock = styled.div`
  display: flex;
  min-height: 38px;

  & > div > span {
    color: ${getThemeColor(['black'])};
  }
`;

const CheckboxS = styled(Checkbox)`
  margin-top: 15px;
  width: fit-content;
  flex-shrink: 0;
  color: ${getThemeColor(['black'])};
`;

const LineError = styled(InputErrorMsg)`
  padding: 0 0 0 20px;
  display: flex;
  align-items: flex-end;
`;

const LineMessage = styled(LineError)`
  color: ${getThemeColor(['primaryLight'])};
  font-weight: ${getThemeFont(['semibold'])};
`;

const TextS = styled(Text)`
  color: ${getThemeColor(['secondary'])};
  margin-top: 5px;
`;

// checkIsMailToCollapseOpen :: MailToValue -> Boolean
// MailToValue = Object
export const checkIsMailToCollapseOpen = R.compose(
  R.any(R.equals(true)),
  R.values,
  R.map(R.propOr(false, 'shouldSend')),
  R.defaultTo({}),
);

export function MailToFieldsComp({
  isOther,
  otherEmails,
  emailOptions,
  changeOther,
  selectEmails,
  addNewEmail,
  formError,
  onBlur,
  onFocus,
  value,
  config,
  changeEntityHandler,
  msg,
  isCollapseOpened,
  ...props
}) {
  const isMailToCollapseOpen = checkIsMailToCollapseOpen(value);

  return (
    <MailToCollapse
      title="Mail To"
      isCollapseOpened={isMailToCollapseOpen || isCollapseOpened}
      content={
        <>
          <Wrapper {...{ onFocus, onBlur }}>
            {config.map(([label, name, getEmails, getNames]) => (
              <FlexContainer key={name}>
                <CheckboxS
                  name={name}
                  onChange={changeEntityHandler(name, getEmails(props))}
                  value={shouldSend(name, value)}
                  label={label}
                />
                {!!(
                  getNames &&
                  shouldSend(name, value) &&
                  !formError[name]
                ) && <LineMessage>{getNames(props)}</LineMessage>}
                <LineError>{formError[name]}</LineError>
              </FlexContainer>
            ))}
            <OtherBlock>
              <CheckboxS
                name="other"
                onChange={changeOther}
                value={isOther}
                label="Other"
              />
              {isOther && (
                <MultiSelect
                  placeholder="Start typing emails"
                  value={otherEmails}
                  handleChange={selectEmails}
                  options={emailOptions}
                  onInputChange={addNewEmail}
                  maxMenuHeight={95}
                  menuIsOpen={R.complement(R.isEmpty)(emailOptions)}
                />
              )}
            </OtherBlock>
          </Wrapper>
          {formError.other && <InputErrorMsg>{formError.other}</InputErrorMsg>}
          {formError.mailTo && (
            <InputErrorMsg>{formError.mailTo}</InputErrorMsg>
          )}
          <TextS>{msg}</TextS>
        </>
      }
    />
  );
}

const defaultOption = shape({ label: string, value: string });

MailToFieldsComp.propTypes = {
  value: shape({
    suppliers: shape({
      shouldSend: bool.isRequired,
    }),
    manager: shape({
      shouldSend: bool.isRequired,
    }),
    requestor: shape({
      shouldSend: bool.isRequired,
    }),
  }),
  changeEntityHandler: func.isRequired,
  isOther: bool.isRequired,
  otherEmails: arrayOf(defaultOption).isRequired,
  emailOptions: arrayOf(defaultOption).isRequired,
  changeOther: func.isRequired,
  selectEmails: func.isRequired,
  addNewEmail: func.isRequired,
  onBlur: func.isRequired,
  onFocus: func.isRequired,
  formError: oneOfType([string, object]).isRequired,
  config: arrayOf(arrayOf(oneOfType([string, func]))),
  msg: string,
  isCollapseOpened: bool,
};
