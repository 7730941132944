import { Textarea, Input } from '@poly/admin-book';
import * as R from 'ramda';
import { pathEqLegacy } from '@poly/utils';

import { OwnerShipSelect } from '../components/OwnerShipSelect.js';
import { OccupancySelector } from '../components/OccupancySelector.js';
import { BuildingTypeSelect } from '../components/BuildingTypeSelect.js';
import { customPropertyBuildingType } from '../../../constants.js';

// getBuildingInfoConfigs :: { client: Client } -> ClientBuildingInfoConfig
export const getBuildingInfoConfigs = R.pathOr({}, [
  'client',
  'configs',
  'propertyFields',
  'buildingInfo',
]);

// renderIfByProp :: { client: Client } -> Boolean
const renderIfByProp = (prop) =>
  R.compose(R.prop(prop), getBuildingInfoConfigs);

export const buildingSizeFormField = {
  label: 'Building Size',
  field: {
    name: 'buildingInfo.buildingSize',
    Component: Input,
  },
  renderIf: renderIfByProp('buildingSize'),
};

export const ownershipFormField = {
  label: 'Ownership',
  field: {
    name: 'buildingInfo.buildingOwnership',
    withFormData: true,
    Component: OwnerShipSelect,
  },
  renderIf: renderIfByProp('ownership'),
};

export const lockBoxCodeFormField = {
  label: 'LockBox Code',
  field: {
    name: 'buildingInfo.lockBoxCode',
    Component: Input,
  },
  renderIf: renderIfByProp('lockBoxCode'),
};

export const occupancyFormField = {
  label: 'Occupancy',
  field: {
    name: 'buildingInfo.occupancy',
    withFormData: true,
    Component: OccupancySelector,
  },
  renderIf: renderIfByProp('occupancy'),
};

export const powerRequirementsFormField = {
  label: 'Power Requirements',
  field: {
    name: 'buildingInfo.powerRequirements',
    Component: Input,
  },
  renderIf: renderIfByProp('powerRequirements'),
};

export const landlordResponsibilityFormField = {
  label: 'Landlord Responsibility',
  field: {
    name: 'buildingInfo.landlordResponsibility',
    Component: Textarea,
  },
  renderIf: renderIfByProp('landlordResponsibility'),
};

export const buildingTypeFormField = {
  label: 'Building Type',
  field: {
    name: 'buildingInfo.buildingType',
    Component: BuildingTypeSelect,
  },
  renderIf: renderIfByProp('buildingType'),
};

export const customBuildingTypeFormField = {
  label: 'Custom Building Type',
  field: {
    name: 'buildingInfo.customBuildingType',
    Component: Input,
  },
  renderIf: R.both(
    renderIfByProp('buildingType'),
    pathEqLegacy(['buildingInfo', 'buildingType'], customPropertyBuildingType),
  ),
};

export const parcelTaxIdFormField = {
  label: 'Parcel Tax ID',
  field: {
    name: 'buildingInfo.parcelTaxId',
    Component: Input,
  },
  renderIf: renderIfByProp('parcelTaxId'),
};
