import React from 'react';
import * as R from 'ramda';
import { node, string, oneOfType, object, func, bool, shape } from 'prop-types';
import {
  stringifyQuery,
  useLocation,
  useNavigate,
  parseQuery,
  Link,
} from '@poly/client-routing';

import { routesNames } from '../routes/index.js';
import { SidebarIDs } from '../sidebars/constants.js';
import { useClearPristineState } from '../sidebars/useClearPristineOnSidebarMount.js';

// prepareQueryParamsBySidebarIDs :: SidebarIDs -> QueryObject
const prepareQueryParamsBySidebarIDs = R.compose(
  R.fromPairs,
  R.map(R.pair(R.__, undefined)),
  // we want to leave project sidebar open at background at any other sidebar
  R.reject(
    R.includes(R.__, [SidebarIDs.approveSupplierInvoice, SidebarIDs.project]),
  ),
  R.values,
);

// prepareQueryParamsForSidebar :: QueryObject -> QueryObject
const prepareQueryParamsForSidebar = R.compose(
  // we don't close client/property sidebar on open contact
  R.when(
    R.propSatisfies(R.complement(R.isNil), SidebarIDs.contact),
    R.omit([SidebarIDs.client, SidebarIDs.property, SidebarIDs.purchaseOrder]),
  ),
  R.mergeDeepRight({
    sidebarTab: undefined,
    sidebarSubTab: undefined,
    ...prepareQueryParamsBySidebarIDs(SidebarIDs),
  }),
);

export function LinkToSidebar({
  onClick,
  onReject,
  inNewTab,
  children,
  className,
  linkParams,
  entityCardLink,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const clearPristine = useClearPristineState();

  const { query = {}, hash = '' } = linkParams;

  const newTabLink = `${routesNames.REPORTS}?${stringifyQuery(query)}${hash}`;

  const onClickHandler = (e) => {
    if (onReject) {
      e.preventDefault();
      onReject();
      return;
    }

    if (onClick) {
      onClick();
    }

    if (!inNewTab) {
      e.preventDefault();
      const oldParams = parseQuery(location.search);
      const newParams = prepareQueryParamsForSidebar(query);
      const mergedParams = R.mergeDeepLeft(newParams, oldParams);
      clearPristine();
      navigate(`${location.pathname}?${stringifyQuery(mergedParams)}${hash}`, {
        state: { previous: location },
      });
    }
  };

  const linkProps = {
    className,
    onClick: onClickHandler,
    href: entityCardLink || newTabLink,
    ...(inNewTab ? { target: '_blank' } : {}),
  };

  return <Link {...linkProps}>{children}</Link>;
}

LinkToSidebar.propTypes = {
  onClick: func,
  onReject: func,
  inNewTab: bool,
  entityCardLink: string,
  children: node,
  className: oneOfType([string, object]),
  // eslint-disable-next-line react/forbid-prop-types
  linkParams: shape({ query: object, hash: string }).isRequired,
};
