import { gql, useQuery } from '@apollo/client';
import * as R from 'ramda';
import { useRouterQuery } from '@poly/client-routing';

const closingAccountsQuery = gql`
  query closingAccountsQuery($filters: ClosingAccountsFilters!) {
    getClosingAccounts(filters: $filters) {
      _id
      name
      code
      balance
      cashBasisBalance
      is_retained_earnings_account
      type {
        category
        normal_balance
      }
    }
  }
`;

const retainedEarningsQuery = gql`
  query retainedEarningsQuery($filters: RetainedEarningsFilters!) {
    getRetainedEarnings(filters: $filters) {
      _id
      name
      code
      balance
      cashBasisBalance
      is_retained_earnings_account
    }
  }
`;

export const useClosingAccountsQuery = (fetchPolicy) => {
  const { currentPeriodEndDate } = useRouterQuery(['currentPeriodEndDate']);
  const closingAccountsRes = useQuery(closingAccountsQuery, {
    variables: {
      filters: { endDate: new Date(currentPeriodEndDate) },
    },
    fetchPolicy,
  });

  const retainedEarningsRes = useQuery(retainedEarningsQuery, {
    variables: {
      filters: { endDate: new Date(currentPeriodEndDate) },
    },
    fetchPolicy,
  });

  const closingAccounts = R.pathOr(
    [],
    ['data', 'getClosingAccounts'],
    closingAccountsRes,
  );

  const retainedEarningsAccount = R.pathOr(
    [],
    ['data', 'getRetainedEarnings'],
    retainedEarningsRes,
  );

  const loading = closingAccountsRes.loading || retainedEarningsRes.loading;

  return {
    closingAccounts,
    retainedEarningsAccount,
    loading,
  };
};
