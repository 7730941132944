import React, { useReducer } from 'react';
import * as R from 'ramda';
import { bool, func, number, object, shape } from 'prop-types';
import { Loader } from '@poly/admin-book';
import { propEqLegacy } from '@poly/utils';

import { TableAndPanelWrapper } from '../VoidSupplierPayments/styles.js';
import { PayInvoicesTableCard } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { EmptyListMessage } from '../../components/EmptyListMessage.js';
import { BatchInvoicingTMBassProTable } from '../BatchInvoicingTM/BatchInvoicingTMBassProTable.js';
import {
  BottomPanelContainerS,
  getBatchTotalsByStore,
  getProjectsByQueryResult,
  getSelectedProjectsIds,
  isCreateBatchButtonDisabled,
  isWoCharge,
  TotalsTableWrapper,
} from '../BatchInvoicingTM/BatchInvoicingPageBody.js';
import {
  batchClientInvoicingReducer,
  createSelectAllProjectsAction,
  createToggleProjectSelectionAction,
} from '../BatchInvoicingTM/batch-invoicing-state.js';
import { BatchInvoicingTMBassProTotalsTable } from '../BatchInvoicingTM/BatchInvoicingTMBassProTotalsTable.js';

const batchInvoicesTotalProps = [
  'suggestedClientInvoiceAmount',
  'jobCost',
  'clientInvoiceMarkupAmount',
  'woCharge',
];

// getSelectedProjects :: BatchEntriesMap -> [Project]
const getSelectedProjects = R.compose(
  R.map(R.prop('project')),
  R.reject(propEqLegacy('isSelected', false)),
  R.values,
);

export function BatchInvoicingMondelezTable({
  loading,
  data,
  setSelectedProjects,
  tableProps,
}) {
  const [batchEntriesMap, dispatch] = useReducer(
    batchClientInvoicingReducer,
    {},
  );

  const projects = getProjectsByQueryResult(data);

  const toggleRow = (projectId) => {
    const project = projects.find((doc) => doc._id === projectId);
    dispatch(createToggleProjectSelectionAction(project));
  };

  const toggleSelectAll = (isSelectAll) => {
    dispatch(createSelectAllProjectsAction(projects, !isSelectAll));
  };

  const totalsObj = getBatchTotalsByStore(batchInvoicesTotalProps)(
    batchEntriesMap,
  );

  const previewSpendReport = () =>
    setSelectedProjects(getSelectedProjects(batchEntriesMap));

  return (
    <TableAndPanelWrapper>
      <PayInvoicesTableCard height="calc(100% - 90px)">
        {loading && <Loader />}
        {!loading && projects.length === 0 && <EmptyListMessage />}
        {!loading && projects.length > 0 && (
          <BatchInvoicingTMBassProTable
            projects={projects}
            toggleRow={toggleRow}
            toggleSelectAll={toggleSelectAll}
            selectedRows={getSelectedProjectsIds(batchEntriesMap)}
            isWoCharge={isWoCharge(projects)}
            withProjectTimeWarning
            {...tableProps}
          />
        )}
      </PayInvoicesTableCard>
      <BottomPanelContainerS height="90px">
        <TotalsTableWrapper>
          <BatchInvoicingTMBassProTotalsTable
            {...totalsObj}
            disabled={isCreateBatchButtonDisabled(batchEntriesMap)}
            btnText="Preview Spend Report"
            onCreateBatch={previewSpendReport}
            isWoCharge={isWoCharge(projects)}
          />
        </TotalsTableWrapper>
      </BottomPanelContainerS>
    </TableAndPanelWrapper>
  );
}

BatchInvoicingMondelezTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: object,
  loading: bool,
  setSelectedProjects: func.isRequired,
  tableProps: shape({
    isLoading: bool,
    itemCount: number,
    loadMoreItems: func,
    loadedCount: number,
    onScroll: func,
  }),
};
