import * as R from 'ramda';
import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { convertDollarsToCents } from '@poly/utils';
import { arrayOf, number, shape, string } from 'prop-types';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { UPDATE_CLIENT_PERMISSION } from '@poly/security';
import { TMRulesTab } from './TMRulesTab.js';
import { PropertyFieldInheritanceMap } from '../../PropertySidebar/constants.js';

const editClientMarkupMutation = gql`
  mutation editClientMarkupMutation($input: UpdateClientMarkupInput!) {
    updateClientMarkup(input: $input) {
      _id
    }
  }
`;

// prepareMutationInput :: ID -> FormData -> UpdateClientMarkupInput
const prepareMutationInput = (clientId) =>
  R.compose(
    R.mergeRight({ clientId }),
    R.over(R.lensProp('woCharge'), convertDollarsToCents),
  );

export function ClientTMRulesTab({ client }) {
  const [editClientMarkup] = useMutation(editClientMarkupMutation);
  const hasAccess = useHasUserAccessWithPermission(UPDATE_CLIENT_PERMISSION);
  const onSubmit = async (formData) => {
    await editClientMarkup({
      variables: { input: prepareMutationInput(client._id)(formData) },
    });
  };
  return (
    <TMRulesTab
      hasAccess={hasAccess}
      onSubmit={onSubmit}
      woCharge={client.woCharge}
      tmMarkupRules={client.tmMarkupRules}
      successMsgPrefix="Client"
      woChargeInheritedFrom={PropertyFieldInheritanceMap.SELF}
      markupRulesInheritedFrom={PropertyFieldInheritanceMap.SELF}
    />
  );
}

ClientTMRulesTab.propTypes = {
  client: shape({
    _id: string.isRequired,
    tmMarkupRules: arrayOf(
      shape({
        upTo: number,
        percent: number,
      }),
    ),
  }),
};
