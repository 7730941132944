import styled from 'styled-components';
import React from 'react';

const FormTitle = styled.div`
  font-family: TTNormsMedium, FFMarkWebProNarrowBook, sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;

export function SupplierAttachInvoiceFileComment() {
  return <FormTitle>accepts only: pdf, png, jpg, jpeg</FormTitle>;
}
