import React, { useCallback, useState } from 'react';
import * as R from 'ramda';
import { bool, shape, string } from 'prop-types';
import { gql, useQuery, useApolloClient } from '@apollo/client';
import styled from 'styled-components';
import { LinkButton } from '@poly/admin-book/src/LinkButton/index.js';
import { Text } from '@poly/admin-book/src/Text/index.js';
import { Loader } from '@poly/admin-book/src/Loader/index.js';
import { debounceWithDefaultThreshold } from '@poly/client-utils/src/debounce.js';
import { isNilOrEmpty } from '@poly/utils/src/general.js';
import { Table } from '@poly/admin-book/src/Table/Table.js';

import { useSidebarTableFormContext } from '../sidebar/SidebarWrapperForTableForm.js';
import { CommonSidebarFormWrapper } from '../sidebar/CommonSidebarFormWrapper.js';
import { SearchInputComp } from '../components/TableSearchInput.js';
import { MAX_ITEMS } from '../constants/general.js';
import { AddTaskForm } from '../modules/forms/taskForm/add/index.js';
import { useMapConfigToTableProps } from '../hooks/useMapConfigToTableProps.js';

const SidebarTaskTemplatesTable = styled(Table)`
  margin-top: 5px;
  thead {
    display: none;
  }
  tbody {
    max-height: 160px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #ececed;
      border-radius: 6px;
    }
  }
  td {
    height: 42px;
  }
  tr:nth-child(1) {
    border-top: none;
  }
  td:nth-child(1),
  th:nth-child(1) {
    width: 200px;
  }

  td:nth-child(3),
  th:nth-child(3) {
    width: 35px;
    padding-right: 10px;
  }
`;

const TASK_TEMPLATES_QUERY = gql`
  query SEARCH_TASK_TEMPLATES_QUERY($input: CollectionSearchParams!) {
    searchTaskTemplates(input: $input) {
      hits {
        _id
        name
        description
        priority
      }
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const TableWrapper = styled.div`
  height: 160px;
`;

const TableCellComp = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

function AddTaskTemplateButton({ formAnchorId, addTaskPayload, taskTemplate }) {
  const apolloClient = useApolloClient();
  const { formSetter } = useSidebarTableFormContext();

  const onFormCancel = useCallback(() => {
    formSetter(null);
  }, []);

  const onAddTask = () =>
    formSetter({
      id: formAnchorId,
      Content: (
        <CommonSidebarFormWrapper skipMargin onCancel={onFormCancel}>
          <AddTaskForm
            {...addTaskPayload}
            taskTemplate={taskTemplate}
            apolloClient={apolloClient}
          />
        </CommonSidebarFormWrapper>
      ),
    });

  return <LinkButton onClick={onAddTask}>Add</LinkButton>;
}

AddTaskTemplateButton.propTypes = {
  formAnchorId: string.isRequired,
  addTaskPayload: shape({}).isRequired,
  taskTemplate: shape({}).isRequired,
};

const getSidebarTaskTemplatesTableProps = (formAnchorId, addTaskPayload) => [
  ['', ({ name }) => <TableCellComp>{name}</TableCellComp>],
  ['', ({ description }) => <TableCellComp>{description}</TableCellComp>],
  [
    '',
    (taskTemplate) => (
      <AddTaskTemplateButton
        taskTemplate={taskTemplate}
        formAnchorId={formAnchorId}
        addTaskPayload={addTaskPayload}
      />
    ),
  ],
];

// isNoTaskTemplates :: SearchTaskTemplatesResult -> Boolean
const isNoTaskTemplates = R.compose(
  isNilOrEmpty,
  R.path(['searchTaskTemplates', 'hits']),
);

function SidebarTaskTemplatesTableComp({
  data,
  loading,
  searchText,
  ...tableProps
}) {
  const isNoTaskTemplate = !loading && isNoTaskTemplates(data) && !searchText;

  if (isNoTaskTemplate) {
    return <Text>No task templates yet</Text>;
  }

  if (loading && !searchText) {
    return <Loader />;
  }

  return (
    <TableWrapper>
      <SidebarTaskTemplatesTable
        {...tableProps}
        rowHeight="40px"
        showScrollBar
      />
    </TableWrapper>
  );
}

SidebarTaskTemplatesTableComp.propTypes = {
  data: shape({}),
  loading: bool,
  searchText: string,
};

function ApplyTaskTemplateTable({ projectId, formAnchorId, addTaskPayload }) {
  const [searchText, setSearchText] = useState('');

  const { data, loading } = useQuery(TASK_TEMPLATES_QUERY, {
    variables: {
      input: { searchTerm: searchText, size: MAX_ITEMS },
    },
  });

  const tableProps = useMapConfigToTableProps(
    R.pathOr([], ['searchTaskTemplates', 'hits']),
    getSidebarTaskTemplatesTableProps(formAnchorId, addTaskPayload),
    data,
  );

  const onChange = useCallback(
    debounceWithDefaultThreshold((e) => setSearchText(e?.target?.value)),
    [],
  );

  return (
    <Wrapper>
      <Text size="18px">Add Task Template</Text>
      <Text size="12px">{projectId}</Text>
      <SearchInputComp
        wrapperWidth="100%"
        inputWidth="97%"
        iconSize={12}
        onChange={onChange}
        value={searchText}
        name="searchText"
        placeholder="Search by name & description"
      />
      <SidebarTaskTemplatesTableComp
        loading={loading}
        data={data}
        searchText={searchText}
        {...tableProps}
      />
    </Wrapper>
  );
}

ApplyTaskTemplateTable.propTypes = {
  projectId: string.isRequired,
  formAnchorId: string.isRequired,
  addTaskPayload: shape({}).isRequired,
};

// eslint-disable-next-line import/no-unused-modules
export function ApplyTaskTemplateButton({
  projectId,
  formAnchorId,
  addTaskPayload,
}) {
  const { formSetter } = useSidebarTableFormContext();

  const onFormCancel = useCallback(() => {
    formSetter(null);
  }, []);

  const onApplyTaskTemplate = () =>
    formSetter({
      id: formAnchorId,
      Content: (
        <CommonSidebarFormWrapper onCancel={onFormCancel}>
          <ApplyTaskTemplateTable
            projectId={projectId}
            formAnchorId={formAnchorId}
            addTaskPayload={addTaskPayload}
          />
        </CommonSidebarFormWrapper>
      ),
    });

  return (
    <LinkButton onClick={onApplyTaskTemplate}>Apply Task Template</LinkButton>
  );
}

ApplyTaskTemplateButton.propTypes = {
  formAnchorId: string.isRequired,
  projectId: string.isRequired,
  addTaskPayload: shape({}).isRequired,
};
