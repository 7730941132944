import styled from 'styled-components';
import { bool, func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button } from '@poly/admin-book';
import { MoneyInputAsCents } from '@poly/admin-ui';

const ProrateButtonS = styled(Button)`
  align-self: flex-end;
`;

const ProrateInputS = styled(MoneyInputAsCents)`
  width: 110px;

  input {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
`;

export function ProrateButton({ onChange, value, disabled }) {
  const [discount, setDiscount] = useState('');

  const onClick = () => {
    if (discount) {
      // allow prorate discount multiply times
      onChange(0);
      setTimeout(() => onChange(discount), 200);
    }
  };
  useEffect(() => {
    if (!value) {
      setDiscount('');
    }
  }, [value]);

  useEffect(() => onChange(''), []);
  return (
    <>
      <ProrateInputS
        name="prorate-input"
        value={discount}
        onChange={setDiscount}
        labelProps={{
          color: 'midDark',
          size: 10,
        }}
        label="Gross Discount Amount"
      />
      <ProrateButtonS
        disabled={disabled}
        onClick={onClick}
        styleType="primaryLighter"
        size="small"
      >
        Prorate Discount
      </ProrateButtonS>
    </>
  );
}

ProrateButton.propTypes = {
  onChange: func.isRequired,
  value: string,
  disabled: bool,
};
