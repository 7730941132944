import React from 'react';
import { arrayOf, func, shape } from 'prop-types';

import { SidebarFormLayout } from '../../../sidebars/components/SidebarFormLayout.js';
import {
  UpdateTasksSidebarForm,
  updateTasksSidebarFormId,
} from './UpdateTasksSidebarForm.js';

export function UpdateTasksSidebar({ tasks, onClose, unselectTasks }) {
  return (
    <SidebarFormLayout
      onClose={onClose}
      title="Bulk Updating of Tasks"
      formId={updateTasksSidebarFormId}
      submitButtonCaption="Update"
      justifyContent="flex-start"
    >
      <UpdateTasksSidebarForm
        tasks={tasks}
        onClose={onClose}
        unselectTasks={unselectTasks}
      />
    </SidebarFormLayout>
  );
}

UpdateTasksSidebar.propTypes = {
  onClose: func.isRequired,
  tasks: arrayOf(shape({})),
  unselectTasks: func.isRequired,
};
