function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const useGetJSONDataFromClipboard = () => async () => {
  const clipboardData = await navigator.clipboard.readText();

  let result = {};

  if (isJsonString(clipboardData)) {
    result = JSON.parse(clipboardData);
  }

  return result;
};
