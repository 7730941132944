/* eslint-disable node/file-extension-in-import */
/* eslint-disable import/extensions */
import React from 'react';
import * as R from 'ramda';
import ReactDOM from 'react-dom/client';
import styled from 'styled-components';
import { debounce, waitForTimeoutP } from '@poly/utils';

import { isFirefox } from './clipboard.js';

const copyStyles = (
  sourceDoc,
  targetDoc,
  skipFirefoxStyles,
  customStyles = '',
) => {
  Array.from(sourceDoc.styleSheets).forEach((styleSheet) => {
    const newStyleEl = sourceDoc.createElement('style');

    // we need this construction for Firefox to omit app crash when we can't copy one of rules
    try {
      Array.from(styleSheet.cssRules).forEach((cssRule) => {
        newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
      });
    } catch (e) {
      return;
    }

    targetDoc.head.appendChild(newStyleEl);
  });

  // fix for the FlexBox styles in Firefox
  if (isFirefox() || customStyles) {
    const printCSS = skipFirefoxStyles
      ? `body {height: auto;} ${customStyles}`
      : `body {height: auto;} body, div { display: block !important; overflow: visible !important; } ${customStyles}`;

    const printStyleEl = sourceDoc.createElement('style');
    printStyleEl.type = 'text/css';
    printStyleEl.appendChild(sourceDoc.createTextNode(printCSS));
    targetDoc.head.appendChild(printStyleEl);
  }
};

// openPrintWindow :: Window -> (Object, String) -> Promise _ undefined
// eslint-disable-next-line import/no-unused-modules
export const openPrintWindow =
  (window) =>
  async (content, title, skipFirefoxStyles, customStyles = '') => {
    const printWindow = await window.open();
    if (printWindow) {
      copyStyles(
        window.document,
        printWindow.document,
        skipFirefoxStyles,
        customStyles,
      );
      printWindow.document.body.appendChild(content);
      printWindow.document.title = title || 'Untitled Document';

      const printDebounced = debounce(2000)(() => {
        printWindow.print();
        printWindow.close();
      });

      const imageToLoad = printWindow.document.getElementById('load-image');

      if (imageToLoad) {
        const imageSrc = isFirefox()
          ? window.location.origin + imageToLoad.src
          : imageToLoad.src;

        if (isFirefox()) {
          imageToLoad.src = imageSrc;
        }

        const img = new Image();
        img.src = imageSrc;
        img.onload = function () {
          printDebounced();
        };
      } else {
        printDebounced();
      }
    }
  };

export const openPrintWindowWithData = async ({
  Table,
  Layout,
  metaData,
  fileName,
  skipFirefoxStyles = false,
  customStyles = '',
  ...tableProps
}) => {
  const containerElem = document.createElement('div');
  containerElem.style.width = '1100px';
  containerElem.style.height = 'max-content';

  const TableS = styled(Table)`
    display: flex;
  `;

  ReactDOM.createRoot(containerElem).render(
    <Layout {...metaData}>
      <TableS isPrintPDF {...tableProps} />
    </Layout>,
  );

  // timeout prevents empty pdf if we fetch some data before print
  await waitForTimeoutP(0);
  return openPrintWindow(window)(
    containerElem,
    fileName,
    skipFirefoxStyles,
    customStyles,
  );
};

export const base64ToBlob = (base64, type = 'application/octet-stream') => {
  const binStr = atob(base64);
  const len = binStr.length;
  const arr = new Uint8Array(len);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i);
  }
  return new Blob([arr], { type });
};

const createBlobURL = (base64String) => {
  const blob = base64ToBlob(base64String, 'application/pdf');
  const url = URL.createObjectURL(blob);
  return url;
};

export const showBase64PDF = async (base64String) => {
  const blobUrl = createBlobURL(base64String);
  window.open(blobUrl, '_blank');
};

// generateFileNameByTitle :: String -> String
export const generateFileNameByTitle = R.compose(
  R.join('_'),
  R.map(R.pipe(R.toLower, R.replace(/:/g, ''))),
  R.split(' '),
);
