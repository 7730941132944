import * as R from 'ramda';
import { useNotificationState, VENDOR_INFO_TYPES } from '@poly/admin-ui';
import { insertQueryParamsIntoURL } from '@poly/utils';
import { useNavigate } from '@poly/client-routing';
import {
  EXPIRED_ACCESS_LINK_ERROR_MESSAGE,
  MISSING_TOKEN_ERROR_MESSAGE,
  TOKEN_EXPIRED_ERROR,
} from '@poly/constants';

import { routesNames } from '../routes/constants.js';

// isTokenExpiredError :: String -> Boolean
const isTokenExpiredError = (error) =>
  R.includes(error, [
    TOKEN_EXPIRED_ERROR.message,
    MISSING_TOKEN_ERROR_MESSAGE,
    EXPIRED_ACCESS_LINK_ERROR_MESSAGE,
  ]);

export const useHandleTemporalTokenError = () => {
  const navigate = useNavigate();
  const { showErrorNotification } = useNotificationState();

  return (error) => {
    if (isTokenExpiredError(error)) {
      navigate(
        insertQueryParamsIntoURL(
          { type: VENDOR_INFO_TYPES.TOKEN_EXPIRED },
          routesNames.VENDOR_INFO,
        ),
      );
    } else {
      showErrorNotification(error);
    }
  };
};
