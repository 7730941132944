import React from 'react';
import { number, string, shape, element, bool } from 'prop-types';
import { Loader, Status } from '@poly/admin-book';
import {
  SidebarTabTableWrapper,
  useSearchTabLogic,
  SearchInputComp,
  SidebarSubTabs,
  SidebarWidth,
  SidebarRow,
} from '@poly/admin-ui';
import { formatCount, forceTitleCase } from '@poly/utils';

import {
  SectionLabel,
  DoubleTabsWrapper,
  CommonProjectsTabTable,
} from '../commonSidebarComponents.js';
import {
  projectStatusesUI,
  projectStatusesColors,
} from '../../../modules/core/constants/projects.js';
import { recurringSubTabsConfig } from '../../../modules/tabs/common.js';
import { useSearchSubTabsMastersQuery } from './useSearchSubTabsMastersQuery.js';

function CommonMastersTabTitle({ status, ...restProps }) {
  const { count } = useSearchSubTabsMastersQuery({ status, ...restProps });

  const title = `${projectStatusesUI[status]} (${formatCount(count)})`;

  return <Status color={projectStatusesColors[status]} text={title} />;
}

CommonMastersTabTitle.propTypes = { status: string.isRequired };

function CommonMastersTab(props) {
  const { loading, tableProps } = useSearchSubTabsMastersQuery(props);

  if (loading) return <Loader />;

  return <CommonProjectsTabTable {...tableProps} />;
}

export function SidebarMastersTab({
  tabTitlePrefix,
  tabWidth,
  entity,
  toolBar,
  isCard,
}) {
  const { searchTerm, ...inputProps } = useSearchTabLogic('masters');

  const titlePrefix = tabTitlePrefix || forceTitleCase(entity.name);

  const sidebarTabTitle = `${titlePrefix} Master Recurring Projects`;

  return (
    <DoubleTabsWrapper width={tabWidth} isCard={isCard}>
      <SidebarRow justify align>
        <SectionLabel>{sidebarTabTitle}</SectionLabel>
        <SearchInputComp {...inputProps} />
      </SidebarRow>
      <SidebarTabTableWrapper offset={48}>
        <SidebarSubTabs
          tabs={recurringSubTabsConfig.map(([status, title]) => [
            <CommonMastersTabTitle {...{ status, entity, searchTerm }} />,
            title,
            <CommonMastersTab {...{ status, entity, searchTerm }} />,
          ])}
          defaultValue="all"
          toolBar={toolBar}
        />
      </SidebarTabTableWrapper>
    </DoubleTabsWrapper>
  );
}

SidebarMastersTab.propTypes = {
  isCard: bool,
  toolBar: element,
  tabWidth: number,
  entity: shape({
    _id: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  tabTitlePrefix: string,
};
SidebarMastersTab.defaultProps = { tabWidth: SidebarWidth * 2 };
