import { useSubscription } from '@apollo/client';

import { useTableInfiniteScrollQuery } from './useTableInfiniteScrollQuery.js';
import { useDebounceRefetchByOptions } from './useDebounceRefetchByOptions.js';

export const useInfiniteScrollQueryWithSubscription = (
  query,
  input,
  queryProps,
  subscription,
  subscriptionVariables,
  skipSubscription,
) => {
  const { data, loading, refetch, tableProps, allItemsFetched } =
    useTableInfiniteScrollQuery(query, input, queryProps);

  const debouncedRefetch = useDebounceRefetchByOptions({
    refetch,
    queryOptions: queryProps,
    subscriptionOptions: { skip: skipSubscription },
    refetchDebounce: 2000,
  });

  useSubscription(subscription, {
    shouldResubscribe: data,
    onData: debouncedRefetch,
    variables: subscriptionVariables,
    skip: skipSubscription,
  });

  return {
    data,
    refetch,
    loading,
    tableProps,
    allItemsFetched,
    debouncedRefetch,
  };
};
