import React from 'react';
import { arrayOf, object, oneOfType, string, bool, array } from 'prop-types';
import { insertParamsIntoURL } from '@poly/utils';
import { Link } from '@poly/client-routing';

import { routesNames } from '../routes/index.js';

const namePropType = oneOfType([
  string,
  object,
  arrayOf(oneOfType([string, object])),
]);

const { SUPPLIER, MASTER_SUPPLIER } = routesNames;

export function SupplierLink({
  _id,
  name,
  createdAt,
  serviceTypesStr,
  statusInProject,
  disableWOCEmail,
  isVeteran,
  ...restProps
}) {
  const href = insertParamsIntoURL({ supplierId: _id })(SUPPLIER);

  return (
    <Link {...restProps} href={href}>
      {name}
    </Link>
  );
}

SupplierLink.displayName = 'SupplierLink';

SupplierLink.propTypes = {
  _id: string.isRequired,
  name: namePropType.isRequired,
  createdAt: string,
  serviceTypesStr: oneOfType([string, array]),
  statusInProject: string,
  disableWOCEmail: bool,
  isVeteran: bool,
};

export function MasterSupplierLink({ _id, name, ...props }) {
  const masterSupplierHref = insertParamsIntoURL({ supplierId: _id })(
    MASTER_SUPPLIER,
  );
  return (
    <Link href={masterSupplierHref} {...props}>
      {name}
    </Link>
  );
}

MasterSupplierLink.displayName = 'MasterSupplierLink';
MasterSupplierLink.propTypes = {
  _id: string.isRequired,
  name: namePropType.isRequired,
};
