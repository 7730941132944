import React from 'react';
import * as R from 'ramda';
import { gql } from '@apollo/client';
import styled from 'styled-components';
import { Status, defaultTheme, TableCard } from '@poly/admin-book';
import { CreditCardUserStatuses } from '@poly/constants';
import { useRouterQuery } from '@poly/client-routing';
import { useReactiveQuery } from '@poly/client-utils';
import {
  formatCount,
  forceTitleCase,
  propEqLegacy,
  ofArrayLegacy,
} from '@poly/utils';

import { ccUserStatusNamesMap } from './constants.js';
import { PageTabs } from '../../modules/tabs/TabsWithRouter.js';
import { CreditCardUsersPageTable } from './CreditCardUsersPageTable.js';
import { CreditCardUsersPageHeader } from './CreditCardUsersPageHeader.js';

const CREDIT_CARD_USERS_QUERY = gql`
  query CREDIT_CARD_USERS_QUERY($input: CreditCardUsersInput) {
    creditCardUsers(input: $input) {
      _id
      status
      cardNumber
      user {
        _id
        profile {
          fullName
        }
      }
      account {
        _id
        code
        name
      }
    }
  }
`;

const SEARCH_CREDIT_CARD_USERS_CHANGED = gql`
  subscription SEARCH_CREDIT_CARD_USERS_CHANGED {
    searchCreditCardUsersChanged {
      id
      type
    }
  }
`;

const {
  colors: { statuses },
} = defaultTheme;

const PageTabTitleS = styled(Status)`
  font-size: 13px;
`;

const creditCardUsersTabs = (props) =>
  R.compose(
    R.map((status) => [
      <PageTabTitleS
        key={status}
        text={`${forceTitleCase(ccUserStatusNamesMap[status])} (${formatCount(
          props.count[status],
        )})`}
        color={statuses[ccUserStatusNamesMap[status]]}
      />,
      status,
      <TableCard key={status}>
        <CreditCardUsersPageTable {...props} />
      </TableCard>,
    ]),
    R.values,
  )(CreditCardUserStatuses);

// getCCUsersCountByStatus :: String -> [CreditCardUser] -> { String: Int }
const getCCUsersCountByStatus = (status) =>
  R.compose(
    R.objOf(status),
    R.length,
    R.filter(propEqLegacy('status', status)),
  );

// getCCUsersCountByTab :: [CreditCardUser] -> CountResult
// CountResult = {
//    active: Number
//    inactive: Number
// }
const getCCUsersCountByTab = R.compose(
  R.mergeAll,
  R.juxt([
    getCCUsersCountByStatus(CreditCardUserStatuses.ACTIVE),
    getCCUsersCountByStatus(CreditCardUserStatuses.INACTIVE),
  ]),
);

// getCCUsersByTab :: String -> [CreditCardUser] -> [CCUserTableRow]
// CCUserTableRow = {
//    _id: ID
//    status: String
//    cardNumber: String
//    ccUserName: String
//    ccName: String
//    isCCUser: Boolean
//    userId: ID
//    accountCode: String
//    accountId: String
// }
const getCCUsersByTab = (tab) =>
  R.compose(
    R.flatten,
    R.map(
      R.juxt([
        R.compose(
          ofArrayLegacy,
          R.applySpec({
            _id: R.path(['account', 'name']),
            ccName: R.path(['account', 'name']),
            isCCUser: R.F,
          }),
          R.head,
        ),
        R.map(
          R.applySpec({
            _id: R.prop('_id'),
            ccUserName: R.pathOr('', ['user', 'profile', 'fullName']),
            cardNumber: R.propOr('', 'cardNumber'),
            status: R.propOr('', 'status'),
            isCCUser: R.T,
            userId: R.pathOr('', ['user', '_id']),
            accountCode: R.pathOr('', ['account', 'code']),
            accountId: R.path(['account', '_id']),
          }),
        ),
      ]),
    ),
    R.values,
    R.groupBy(R.path(['account', 'code'])),
    R.sortBy(R.path(['user', 'profile', 'fullName'])),
    R.filter(propEqLegacy('status', tab)),
  );

// getPageProps :: (Boolean, String, [CreditCardUser]) -> PageProps
// PageProps = {
//    count: CountResult
//    ccUsersByTab: [CCUserTableRow]
//    loading: Boolean
//    tab: String
// }
const getPageProps = (loading, tab, data) =>
  R.compose(
    R.applySpec({
      count: getCCUsersCountByTab,
      ccUsersByTab: getCCUsersByTab(tab),
      loading: R.always(loading),
      tab: R.always(tab),
    }),
    R.propOr([], 'creditCardUsers'),
  )(data);

export function CreditCardUsersPage() {
  const { tab = CreditCardUserStatuses.ACTIVE } = useRouterQuery(['tab']);
  const queryOptions = { variables: { input: {} } };

  const { data, loading } = useReactiveQuery(
    CREDIT_CARD_USERS_QUERY,
    SEARCH_CREDIT_CARD_USERS_CHANGED,
    { queryOptions, subscriptionOptions: {} },
  );

  const pageProps = getPageProps(loading, tab, data);

  return (
    <>
      <CreditCardUsersPageHeader />
      <PageTabs
        tabs={creditCardUsersTabs(pageProps)}
        defaultValue={CreditCardUserStatuses.ACTIVE}
      />
    </>
  );
}
