import * as R from 'ramda';
import {
  alwaysNewDate,
  calculateTotal,
  convertCentsToDollars,
  ofArrayLegacy,
  pathEqLegacy,
  toDate,
} from '@poly/utils';
import { SystemAccountTypes } from '@poly/constants';
import { formTypeConstants } from '@poly/admin-ui';

// getRecurringJournalLineAmount :: JournalLine -> Number
const getRecurringJournalLineAmount = R.compose(
  convertCentsToDollars,
  R.ifElse(
    R.prop('cash_amount'),
    R.prop('cash_amount'),
    R.prop('accrual_amount'),
  ),
);

// formatAccountSelectOption :: Account -> AccountSelectOption
// AccountSelectOption = {
//   _id: ID,
//   code: String,
//   isTravelHQ: Boolean
//   isCCAccount: Boolean
//   isBankAccount: Boolean
//   isClientPaymentDeduction: Boolean
// }
const formatAccountSelectOption = R.applySpec({
  _id: R.prop('_id'),
  code: R.prop('code'),
  name: R.prop('name'),
  isBankAccount: pathEqLegacy(
    ['accountType', 'system_type'],
    SystemAccountTypes.BANK_ACCOUNT,
  ),
  isCCAccount: pathEqLegacy(
    ['accountType', 'system_type'],
    SystemAccountTypes.CREDIT_CARD,
  ),
  isClientPaymentDeduction: R.prop('is_client_payment_deduction'),
  isTravelHQ: R.prop('is_travel_hq'),
});

// formatRecurringJournalFormData :: RecurringJournal -> FormData
export const formatRecurringJournalFormData = R.compose(
  R.applySpec({
    formType: R.always(formTypeConstants.EDIT),
    status: R.prop('status'),
    accounting_method: R.prop('accounting_method'),
    schedule: R.prop('schedule'),
    endDate: R.ifElse(
      R.prop('end_date'),
      R.compose(toDate, R.prop('end_date')),
      alwaysNewDate,
    ),
    startDate: R.compose(toDate, R.prop('start_date')),
    lines: R.compose(
      R.map(
        R.converge(R.compose(R.mergeAll, Array.of), [
          R.applySpec({
            accountCode: R.compose(
              formatAccountSelectOption,
              R.prop('account'),
            ),
            description: R.prop('description'),
            isDisabled: R.T,
          }),
          R.compose(
            R.ifElse(
              R.lt(R.__, 0),
              R.compose(R.objOf('creditAmount'), Math.abs),
              R.compose(R.objOf('debitAmount'), Math.abs),
            ),
            getRecurringJournalLineAmount,
          ),
          R.compose(
            R.objOf('receiptFile'),
            R.ifElse(
              R.prop('receipt_file'),
              R.compose(ofArrayLegacy, R.prop('receipt_file')),
              R.always([]),
            ),
          ),
        ]),
      ),
      R.prop('lines'),
    ),
    never: R.propSatisfies(R.isNil, 'end_date'),
  }),
  R.prop('recurringJournal'),
);

export const editRecurringJournalEntryFormId =
  'editRecurringJournalEntryFormId';

// formatRecurringJournalMutationInput :: FormData -> EditRecurringJournalInput
export const formatRecurringJournalMutationInput = R.applySpec({
  end_date: R.ifElse(
    R.either(R.prop('never'), R.complement(R.prop('endDate'))),
    R.always(null),
    R.prop('endDate'),
  ),
  status: R.prop('status'),
  lines: R.compose(
    R.map(
      R.converge(R.mergeRight, [
        R.ifElse(
          R.propSatisfies(R.isEmpty, 'receiptFile'),
          R.always({}),
          R.compose(
            R.objOf('receiptFile'),
            R.pick(['fileName', 'upload']),
            R.head,
            R.prop('receiptFile'),
          ),
        ),
        R.applySpec({
          account_id: R.path(['accountCode', '_id']),
          netAmount: R.ifElse(
            R.prop('debitAmount'),
            R.prop('debitAmount'),
            R.compose(R.negate, R.prop('creditAmount')),
          ),
          description: R.prop('description'),
        }),
      ]),
    ),
    R.reject(R.prop('isDisabled')),
    R.prop('lines'),
  ),
});

// isDebitLine :: JournalLine -> Boolean
export const isDebitLine = R.compose(
  R.gte(R.__, 0),
  getRecurringJournalLineAmount,
);

// calculateLineTotalBase :: (JournalLine -> Boolean) -> Journal -> Number
export const calculateLineTotalBase = (getAmountChecker) =>
  R.compose(
    calculateTotal(
      R.ifElse(
        getAmountChecker,
        R.compose(Math.abs, getRecurringJournalLineAmount),
        R.always(0),
      ),
    ),
    R.prop('lines'),
  );
