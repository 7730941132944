import React, { useEffect } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';

import { Button } from '@poly/admin-book';
import { arrayOf, shape, string, func, object, bool } from 'prop-types';
import { isNilOrEmpty } from '@poly/utils';
import { FlexColumn, FlexContainer } from './FlexContainer.js';
import {
  SearchHeaderColumn,
  SearchHeaderColumnTitle,
} from './SearchHeaderColumn.js';
import { useSearchFilters } from '../hooks/useSearchFilters.js';

const HeaderContainer = styled(FlexColumn)`
  width: 100%;
  height: 100%;
  position: relative;
`;

const SearchHeaderRowContainer = styled(FlexContainer)`
  justify-content: ${R.propOr('space-between', 'justifyContent')};
  align-items: flex-start;
  & > div {
    width: ${R.prop('columnWidth')};
  }
  & > div:first-child {
    width: ${R.ifElse(
      R.prop('titleWidth'),
      R.prop('titleWidth'),
      R.prop('columnWidth'),
    )};
  }
  border-bottom: ${R.prop('borderBottom')};
  padding: ${R.prop('padding')};
`;

const SearchHeaderButtonsContainer = styled(FlexContainer)`
  position: absolute;
  bottom: 10px;
  right: 0;
`;

const SearchHeaderButton = styled(Button)`
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
  padding: 5px 10px;
  line-height: 12px;
  min-width: 90px;
`;

const RowTitle = styled(SearchHeaderColumnTitle)`
  margin-bottom: 10px;
  height: 18px;
  width: auto !important;
`;

const SubColumnsContainer = styled(FlexColumn)`
  justify-content: flex-start;
  margin-right: 15px;
  &:last-child {
    margin-right: 0;
  }
`;

// getFilterDataConfig :: [SearchHeaderRowConfig] -> [Object]
// SearchHeaderRowConfig = Object
const getFilterDataConfig = R.compose(
  R.map(
    R.pick([
      'nestedFields',
      'name',
      'defaultValue',
      'subscribers',
      'preventQueryClear',
      'preventReset',
    ]),
  ),
  R.flatten,
  R.map(R.propOr([], 'columns')),
);

// isFunction :: Any -> Boolean
const isFunction = (f) => typeof f === 'function';

// transformFiltersConfigByValues :: ([SearchHeaderRowConfig], Object) => [SearchHeaderRowConfig]
const transformFiltersConfigByValues = (config, filtersValues) =>
  R.map(
    R.over(
      R.lensProp('columns'),
      R.compose(
        R.reject(R.all(R.complement(R.prop('renderIf')))),
        R.values,
        R.groupBy(R.prop('column')),
        R.map(
          R.compose(
            R.evolve({
              relatedFilters: R.pick(R.__, filtersValues),
              disabledIf: R.ifElse(
                isFunction,
                R.compose(R.apply(R.__, [filtersValues])),
                R.F,
              ),
              renderIf: R.ifElse(
                isFunction,
                R.compose(R.apply(R.__, [filtersValues])),
                R.F,
              ),
            }),
            R.mergeRight({ renderIf: R.T }),
          ),
        ),
      ),
    ),
    config,
  );

// checkSearchDisabled :: (Object, [String]) -> Boolean
const checkSearchDisabled = (requiredFields, filters) =>
  R.compose(R.any(isNilOrEmpty), R.values, R.pick(requiredFields))(filters);

// getFieldValue :: Object -> String -> Any
const getFieldValue = (searchFilters) =>
  R.ifElse(
    R.propSatisfies(R.isNil, R.__, searchFilters),
    R.always(''),
    R.prop(R.__, searchFilters),
  );

export function SearchHeaderCreator({
  config,
  onSearch,
  onReset,
  autoSearch,
  requiredFields,
  loading,
  searchBtnTitle,
}) {
  const {
    searchFilters,
    handlers,
    onReset: resetFilters,
  } = useSearchFilters(getFilterDataConfig(config));

  useEffect(() => {
    if (autoSearch) {
      onSearch(searchFilters);
    }
  }, []);

  const transformedConfig = transformFiltersConfigByValues(
    config,
    searchFilters,
  );

  const onResetClick = () => {
    resetFilters();
    if (typeof onReset === 'function') {
      onReset();
    }
  };

  const getValue = getFieldValue(searchFilters);

  return (
    <HeaderContainer>
      {transformedConfig.map(
        ({ key, columns, rowLayout, subColumnLayout, rowTitle }) => (
          <SearchHeaderRowContainer
            titleWidth={rowLayout?.titleWidth}
            key={key}
            {...rowLayout}
          >
            <>
              {rowTitle && <RowTitle>{rowTitle}</RowTitle>}
              {columns.map((subColumns) => (
                <SubColumnsContainer
                  key={`${key}-subColumn-${subColumns[0].name}`}
                  {...subColumnLayout}
                >
                  {subColumns.map(
                    ({
                      name,
                      title,
                      Component,
                      columnLayout,
                      filterProps,
                      disabledIf,
                      renderIf,
                      relatedFilters,
                    }) =>
                      renderIf && (
                        <SearchHeaderColumn
                          key={name}
                          title={title}
                          margin="0 0 10px 0"
                          {...columnLayout}
                        >
                          <Component
                            relatedFilters={relatedFilters}
                            disabled={!!disabledIf}
                            name={name}
                            key={name}
                            size="small"
                            componentSize="small"
                            value={getValue(name)}
                            onChange={handlers[name]}
                            {...filterProps}
                          />
                        </SearchHeaderColumn>
                      ),
                  )}
                </SubColumnsContainer>
              ))}
            </>
          </SearchHeaderRowContainer>
        ),
      )}
      <SearchHeaderButtonsContainer>
        <SearchHeaderButton
          size="small"
          styleType="basicPrimary"
          onClick={onResetClick}
          data-testid="reset-button"
        >
          Reset
        </SearchHeaderButton>
        <SearchHeaderButton
          loader={loading}
          size="small"
          data-testid="search-button"
          disabled={
            requiredFields.length > 0 &&
            checkSearchDisabled(requiredFields, searchFilters)
          }
          onClick={() => onSearch(searchFilters)}
        >
          {searchBtnTitle || 'Search'}
        </SearchHeaderButton>
      </SearchHeaderButtonsContainer>
    </HeaderContainer>
  );
}

SearchHeaderCreator.propTypes = {
  config: arrayOf(shape({ name: string })),
  // eslint-disable-next-line react/prop-types,react/forbid-prop-types
  defaultFilters: object,
  onSearch: func.isRequired,
  onReset: func,
  autoSearch: bool,
  loading: bool,
  requiredFields: arrayOf(string),
  searchBtnTitle: string,
};

SearchHeaderCreator.defaultProps = {
  requiredFields: [],
};
