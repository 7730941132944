import * as R from 'ramda';
import styled from 'styled-components';

export const ColumnDirection = styled.div`
  display: flex;
  flex-direction: ${({ labelPosition }) =>
    labelPosition === 'left' ? 'row' : 'column'};
  position: relative;

  ${({ labelPosition }) =>
    labelPosition === 'left' ? 'align-items: center' : ''};

  width: ${R.prop('width')};
`;

export const ColumnDirectionS = styled(ColumnDirection)`
  height: 100%;
`;
