import * as R from 'ramda';
import { propEqLegacy } from '@poly/utils';

import { MasterProjectsTabsStatuses } from './constants.js';
import { ALL } from '../../modules/core/constants/general.js';
import { getEntityQuery } from '../../modules/tables/commonTableUtils.js';
import { recurringQueriesByEndDate } from '../../modules/tabs/common.js';
import { getAssetTypeNestedQuery } from '../../modules/tables/projectSearchTable/projectSearchTableUtils.js';

// MasterProjectsQueryStatuses :: Object -> [String]
const MasterProjectsQueryStatuses = R.keys(MasterProjectsTabsStatuses);

// getFilterMustQuery :: SearchFilters -> MongoQuery
const getFilterMustQuery = R.compose(
  R.ifElse(R.isEmpty, R.always({}), R.objOf('must')),
  R.reject(R.isNil),
  R.converge(Array.of, [
    R.ifElse(
      propEqLegacy('type', ALL),
      R.always(null),
      getEntityQuery('type', 'childType'),
    ),
    getEntityQuery('clientId', 'clientId'),
    getEntityQuery('managerId', 'managerId'),
    getEntityQuery('serviceTypeId', 'serviceTypeId'),
    getAssetTypeNestedQuery,
  ]),
);

// getFilterQueryByStatus :: SearchFilters -> MongoQuery
const getFilterQueryByStatus = R.compose(
  R.unless(
    R.isEmpty,
    R.compose(
      R.objOf('should'),
      R.map(R.path(['bool', 'filter'])),
      R.values,
      R.pick(R.__, recurringQueriesByEndDate),
      R.keys,
    ),
  ),
  R.filter(R.equals(true)),
  R.pick(MasterProjectsQueryStatuses),
);

// combineMasterProjectsQueries :: (MongoQuery, MongoQuery) -> MongoQuery
const combineMasterProjectsQueries = (statusQuery, filterQuery) =>
  R.ifElse(
    R.isEmpty,
    R.mergeDeepLeft(statusQuery),
    R.unless(
      R.compose(R.isEmpty, R.always(statusQuery)),
      R.over(R.lensProp('must'), R.append(R.objOf('bool', statusQuery))),
    ),
  )(filterQuery);

// generateSearchMasterProjectsQueryByFilter :: SearchFilters -> MongoQuery
export const generateSearchMasterProjectsQueryByFilter = R.compose(
  R.ifElse(R.isEmpty, R.always(null), R.objOf('bool')),
  R.converge(combineMasterProjectsQueries, [
    getFilterQueryByStatus,
    getFilterMustQuery,
  ]),
  R.defaultTo({}),
);
