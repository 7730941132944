import React from 'react';
import { string } from 'prop-types';
import { CheckBoxS } from '../../../../../modules/forms/formsStyles.js';

export function CheckboxWithLabel({ label, ...restProps }) {
  return <CheckBoxS {...restProps} label={label} />;
}

CheckboxWithLabel.propTypes = {
  label: string,
};
