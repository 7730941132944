import { bool, func, number, object, string, arrayOf } from 'prop-types';
import React from 'react';
import { treeNodePropTypes } from '../propTypes.js';
import { classnames } from './utils.js';

export function ExpandButton({
  toggleChildrenVisibility,
  node,
  rowDirectionClass,
  path,
  treeIndex,
  scaffoldBlockPxWidth,
  isDragging,
  buttonStyle,
}) {
  return (
    <div>
      <button
        type="button"
        aria-label={node.expanded ? 'Collapse' : 'Expand'}
        className={classnames(
          node.expanded ? 'rst__collapseButton' : 'rst__expandButton',
          rowDirectionClass,
        )}
        style={buttonStyle}
        onClick={() =>
          toggleChildrenVisibility({
            node,
            path,
            treeIndex,
          })
        }
      />
      {node.expanded && !isDragging && (
        <div
          style={{ width: scaffoldBlockPxWidth }}
          className={classnames('rst__lineChildren', rowDirectionClass)}
        />
      )}
    </div>
  );
}

ExpandButton.propTypes = {
  toggleChildrenVisibility: func.isRequired,
  node: treeNodePropTypes,
  rowDirectionClass: string,
  path: arrayOf(string).isRequired,
  treeIndex: number.isRequired,
  scaffoldBlockPxWidth: number.isRequired,
  isDragging: bool.isRequired,
  // eslint-disable-next-line react/prop-types,react/forbid-prop-types
  buttonStyle: object,
};
