import React from 'react';
import { bool } from 'prop-types';
import { Loader } from '@poly/admin-book';

import { Supplier1099ReportTableComp } from './supplier1099ReportFilterComponents.js';

export function Supplier1099ReportTable({ loading, ...tableProps }) {
  return loading ? (
    <Loader />
  ) : (
    <Supplier1099ReportTableComp
      {...tableProps}
      moreScrollIconPosition="10px"
      showScrollBar
      overflow="auto"
    />
  );
}

Supplier1099ReportTable.propTypes = {
  loading: bool.isRequired,
};
