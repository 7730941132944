import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { number, shape, string, instanceOf } from 'prop-types';
import { Table, Cell, moneyColumnStyles } from '@poly/admin-book';
import { useMapConfigToTableProps } from '@poly/admin-ui';
import { Link } from '@poly/client-routing';
import {
  insertQueryParamsIntoURL,
  insertParamIntoURL,
  calculateTotal,
  formatTotal,
  assocBy,
} from '@poly/utils';

import { routesNames } from '../../routes/index.js';
import { accountsReceivableEntriesPropTypes } from './prop-types.js';
import { VendorLedgerTypes } from '../VendorLedgerReport/constants.js';
import { ClientLink } from '../../sidebars/ClientSidebar/useOpenClientSidebar.js';

const AccountsReceivableReportTableS = styled(Table)`
  ${moneyColumnStyles(2)};
  th:nth-child(2),
  td:nth-child(2) {
    width: 300px;
  }

  tfoot > tr > td {
    font-weight: bold;
  }
`;

// generateARReportLink :: DateTime -> Client -> String
const generateARReportLink = (date) =>
  R.compose(
    insertQueryParamsIntoURL({
      type: VendorLedgerTypes.RECEIVABLE,
      date: date.toDateString(),
    }),
    insertParamIntoURL('vendorId')(routesNames.VENDOR_LEDGER_REPORT),
    R.prop('_id'),
  );

function ARAgingReportLink({ client, amount, date }) {
  const href = generateARReportLink(date)(client);

  return (
    <Link href={href} target="_blank">
      {formatTotal(amount)}
    </Link>
  );
}

ARAgingReportLink.propTypes = {
  client: shape({ _id: string.isRequired }),
  date: instanceOf(Date),
  amount: number,
};

const accountsReceivableTableConfig = [
  ['Client', ({ client }) => <ClientLink {...client} target="_blank" />],
  ['Amount', ARAgingReportLink],
];

// assignIdsToReportEntries :: [AccountsReceivableReportEntry] -> [AccountsReceivableReportEntry]
const assignIdsToReportEntries = R.compose(
  R.map(assocBy('_id', R.path(['client', '_id']))),
  R.defaultTo([]),
);

// calculateAPEntriesTotal :: [AccountsReceivableReportEntry] -> Number
const calculateAPEntriesTotal = calculateTotal(R.propOr(0, 'amount'));

function AccountsReceivableReportFooter({ rows }) {
  const total = calculateAPEntriesTotal(rows);
  return (
    <tr>
      <Cell>Total</Cell>
      <Cell>{formatTotal(total)}</Cell>
    </tr>
  );
}

AccountsReceivableReportFooter.propTypes = {
  rows: accountsReceivableEntriesPropTypes.isRequired,
};

export function AccountsReceivableReportTable({ data, ...restTableOptions }) {
  const { rows, headers, columns, sortQueries } = useMapConfigToTableProps(
    assignIdsToReportEntries,
    accountsReceivableTableConfig,
    data,
  );

  return (
    <AccountsReceivableReportTableS
      headers={headers}
      rows={rows}
      columns={columns}
      sortQueries={sortQueries}
      TableFooter={AccountsReceivableReportFooter}
      showScrollBar
      overflow="auto"
      {...restTableOptions}
    />
  );
}

AccountsReceivableReportTable.propTypes = {
  data: accountsReceivableEntriesPropTypes.isRequired,
};
