import React from 'react';
import * as R from 'ramda';
import {
  arrayOf,
  number,
  string,
  bool,
  func,
  shape,
  oneOfType,
  object,
  oneOf,
} from 'prop-types';

import {
  CheckboxS,
  Head,
  HeaderCell,
  HeaderRow,
  SortingUpDownS,
} from './styles.js';
import { tableHeadersPropTypes } from './propTypes.js';

export function SortableHeaderCell({
  hasSortingValue,
  title,
  columnKey,
  onHeaderCellClick,
  isSortable,
  sortQueries,
  sorting,
}) {
  const isHeaderSortable = hasSortingValue && isSortable;

  const onHeaderClick = () =>
    isHeaderSortable
      ? onHeaderCellClick(columnKey, sortQueries[columnKey])
      : null;

  return (
    <HeaderCell
      isSortable={isHeaderSortable}
      // eslint-disable-next-line react/no-array-index-key
      key={title + columnKey}
      onClick={onHeaderClick}
    >
      <span>{title}</span>
      {isHeaderSortable ? (
        <SortingUpDownS
          dir={sorting && sorting.columnKey === columnKey ? sorting.dir : 0}
        />
      ) : null}
    </HeaderCell>
  );
}

SortableHeaderCell.defaultProps = {
  isSortable: true,
};

SortableHeaderCell.propTypes = {
  hasSortingValue: bool,
  title: oneOfType([string, object]).isRequired,
  columnKey: number.isRequired,
  onHeaderCellClick: func.isRequired,
  isSortable: bool,
  sortQueries: arrayOf(func),
  sorting: shape({ dir: number, columnKey: number }),
};

// rowsArePartiallySelected :: Object -> Boolean
const rowsArePartiallySelected = ({ selectedRows, rows }) =>
  !!selectedRows.length && selectedRows.length < rows.length;

const allRowsSelected = ({ selectedRows, rows }) =>
  !!selectedRows.length && selectedRows.length >= rows.length;

export function TableHead({
  rows,
  headers,
  sorting,
  tableKey,
  className,
  isSortable,
  sortQueries,
  selectedRows,
  toggleSelectAll,
  showAllSelector,
  isCollapsibleTable,
  onHeaderCellClick,
  selectedRowsInEnd,
  isPartiallySelectable,
  checkAllRowsSelected = allRowsSelected,
  checkRowsArePartiallySelected = rowsArePartiallySelected,
}) {
  return (
    <Head className={className}>
      <HeaderRow>
        {selectedRows && !selectedRowsInEnd ? (
          <HeaderCell>
            <CheckboxS
              onChange={toggleSelectAll}
              name={`all_check_${tableKey}`}
              isVisible={!isPartiallySelectable}
              value={checkAllRowsSelected({ selectedRows, rows })}
              indeterminate={checkRowsArePartiallySelected({
                selectedRows,
                rows,
              })}
            />
          </HeaderCell>
        ) : null}
        {isCollapsibleTable && <HeaderCell hide />}
        {headers.map(({ title, hasSortingValue }, columnKey) => (
          <SortableHeaderCell
            key={title}
            {...{
              key: columnKey,
              hasSortingValue,
              title,
              columnKey,
              onHeaderCellClick,
              isSortable,
              sortQueries,
              sorting,
            }}
          />
        ))}
        {selectedRows && selectedRowsInEnd ? (
          <HeaderCell>
            {showAllSelector && (
              <CheckboxS
                onChange={toggleSelectAll}
                name={`all_check_${tableKey}`}
                isVisible={!isPartiallySelectable}
                value={checkAllRowsSelected({ selectedRows, rows })}
                indeterminate={checkRowsArePartiallySelected({
                  selectedRows,
                  rows,
                })}
              />
            )}
          </HeaderCell>
        ) : null}
      </HeaderRow>
    </Head>
  );
}

TableHead.defaultProps = {
  tableKey: 'default',
  toggleSelectAll: R.T,
  isPartiallySelectable: false,
  isSortable: false,
  showAllSelector: true,
};

TableHead.propTypes = {
  headers: tableHeadersPropTypes.isRequired,
  rows: arrayOf(object.isRequired),
  selectedRows: arrayOf(oneOfType([string, number])),
  selectedRowsInEnd: bool,
  onHeaderCellClick: func.isRequired,
  toggleSelectAll: func,
  isPartiallySelectable: bool,
  sorting: shape({
    columnKey: number,
    dir: oneOf([-1, 1]),
  }),
  isSortable: bool,
  sortQueries: arrayOf(func),
  tableKey: string,
  className: string,
  showAllSelector: bool,
  isCollapsibleTable: bool,
  checkAllRowsSelected: func,
  checkRowsArePartiallySelected: func,
};
