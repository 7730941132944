import {
  func,
  bool,
  shape,
  string,
  number,
  arrayOf,
  oneOfType,
} from 'prop-types';
import * as R from 'ramda';
import { useQuery } from '@apollo/client';
import React, { useState, useCallback, useMemo } from 'react';
import { MultiSelectDropDown } from '@poly/admin-ui/src/components/MultiSelectDropDown.js';
import { useEntitiesByReactiveSearch } from '@poly/client-utils/src/entitiesSearch.js';
import { DEBOUNCE_USER_INPUT_THRESHOLD } from '@poly/client-utils/src/constants.js';
import { MoneyInput } from '@poly/admin-ui/src/modules/forms/fields/MoneyInput.js';
import {
  EQUALS_AIT_OPERATOR,
  TECHNICIAN_ID_AIT,
  USER_SELF_ID,
  USER_SELF_ID_LABEL,
  VARIABLE_AIT_OPERATOR,
} from '@poly/security';
import { initialPagination } from '@poly/client-utils/src/pagination.js';
import { debounce, ofArrayLegacy, propEqLegacy } from '@poly/utils';

import { entitySelectPropsByScopeMap } from './entitySelectPropsByScopeMap.js';
import { UserGroupScopeVariablesSelect } from './UserGroupScopeVariablesSelect.js';

const CURRENT_USER_OPTION = { label: USER_SELF_ID_LABEL, value: USER_SELF_ID };

// getDefaultValues :: [Option] -> [Option]
const getDefaultValues = R.ifElse(
  R.is(Array),
  R.filter(R.is(String)),
  R.always([]),
);

// getDefaultValuesDataByCustomQuery :: [ID] -> [Option]
const getDefaultValuesByOptions = (defaultValues) =>
  R.filter(R.propSatisfies(R.includes(R.__, defaultValues), 'value'));

function UserGroupScopeEntitySelectComp({
  value,
  onChange,
  formLineData,
  allLinesValues,
  ...props
}) {
  const [searchTerm, setSearchTerm] = useState('');

  const selectedScope = useMemo(
    () => R.prop('scope', formLineData),
    [formLineData],
  );

  const defaultValues = useMemo(() => getDefaultValues(value), [value]);

  const isSingleOperator = useMemo(
    () => propEqLegacy('operator', EQUALS_AIT_OPERATOR, formLineData),
    [formLineData],
  );

  const {
    placeholder,
    staticOptions,
    isCustomQuery,
    getSearchQuery,
    gqlSearchQuery,
    gqlSearchChangedQuery,
    optionsFromQueryResult,
    getDefaultValuesVariables,
  } = entitySelectPropsByScopeMap[selectedScope];

  const setSearchTermDebounced = useCallback(
    debounce(DEBOUNCE_USER_INPUT_THRESHOLD)(setSearchTerm),
    [],
  );

  const onInputChange = (text) => {
    setSearchTermDebounced(text.trim());
  };

  const { data: defaultValuesData } = useQuery(gqlSearchQuery, {
    variables: getDefaultValuesVariables(defaultValues),
    skip: R.isEmpty(defaultValues) || isCustomQuery,
  });

  const { data: customQueryResult } = useQuery(gqlSearchQuery, {
    skip: !isCustomQuery || !!staticOptions,
  });

  const { result: searchQueryResult } = useEntitiesByReactiveSearch({
    query: R.is(Function, getSearchQuery)
      ? getSearchQuery(allLinesValues)
      : null,
    pagination: initialPagination,
    skipQuery: isCustomQuery,
    searchText: searchTerm,
    gqlSearchChangedQuery,
    gqlSearchQuery,
  });

  const options = useMemo(() => {
    if (staticOptions) {
      return staticOptions;
    }

    if (isCustomQuery) {
      return optionsFromQueryResult(customQueryResult);
    }

    if (selectedScope === TECHNICIAN_ID_AIT) {
      const mainOptions = optionsFromQueryResult(searchQueryResult);

      return [CURRENT_USER_OPTION, ...mainOptions];
    }

    return optionsFromQueryResult(searchQueryResult);
  }, [customQueryResult, searchQueryResult, staticOptions]);

  const preparedValue = useMemo(() => {
    let finalArray = [];

    if (R.isEmpty(defaultValues)) {
      finalArray = value;
    } else {
      finalArray = isCustomQuery
        ? getDefaultValuesByOptions(defaultValues)(options)
        : optionsFromQueryResult(defaultValuesData);
    }

    if (
      selectedScope === TECHNICIAN_ID_AIT &&
      R.includes(USER_SELF_ID, defaultValues)
    ) {
      finalArray = [CURRENT_USER_OPTION, ...finalArray];
    }

    if (isSingleOperator) {
      return R.propOr('', 0, finalArray);
    }

    return finalArray;
  }, [
    value,
    defaultValues,
    isSingleOperator,
    customQueryResult,
    defaultValuesData,
  ]);

  const handleChange = (entity) =>
    onChange(R.is(Array, entity) ? entity : ofArrayLegacy(entity));

  return (
    <MultiSelectDropDown
      {...props}
      onInputChange={onInputChange}
      handleChange={handleChange}
      isMulti={!isSingleOperator}
      placeholder={placeholder}
      value={preparedValue}
      options={options}
    />
  );
}

UserGroupScopeEntitySelectComp.propTypes = {
  onChange: func.isRequired,
  allLinesValues: arrayOf(shape({ scope: string })),
  formLineData: shape({ scope: string, operator: string }).isRequired,
  value: oneOfType([
    string,
    shape({ value: string, label: string }),
    arrayOf(oneOfType([string, shape({ value: string, label: string })])),
  ]),
};

function UserGroupScopeNumberEntityInput({ value, ...props }) {
  const preparedValue = R.when(R.is(Array), R.head)(value);
  return <MoneyInput {...props} value={preparedValue} />;
}

UserGroupScopeNumberEntityInput.propTypes = {
  value: oneOfType([string, number, arrayOf(number)]),
};

export function UserGroupScopeEntitySelect({
  formLineData,
  onLineChange,
  allLinesValues,
  isNumberTypeScope,
  ...props
}) {
  if (!formLineData.scope) return null;

  if (formLineData.operator === VARIABLE_AIT_OPERATOR)
    return (
      <UserGroupScopeVariablesSelect
        {...props}
        formLineData={formLineData}
        onLineChange={onLineChange}
      />
    );

  if (isNumberTypeScope) return <UserGroupScopeNumberEntityInput {...props} />;

  return (
    <UserGroupScopeEntitySelectComp
      {...props}
      formLineData={formLineData}
      allLinesValues={allLinesValues}
    />
  );
}

UserGroupScopeEntitySelect.propTypes = {
  onLineChange: func,
  isNumberTypeScope: bool.isRequired,
  allLinesValues: arrayOf(shape({ scope: string })),
  formLineData: shape({ scope: string }).isRequired,
};
