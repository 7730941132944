import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { bool, func, string } from 'prop-types';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { entityToOptionByLabelPath } from '@poly/client-utils';
import { debounce, isNilOrEmpty, ofArrayLegacy } from '@poly/utils';
import { Select } from '@poly/admin-book';

const CONSOLIDATED_CLIENT_INVOICES_SELECT_QUERY = gql`
  query CONSOLIDATED_CLIENT_INVOICES_SELECT_QUERY(
    $input: ConsolidatedClientInvoicesInput!
  ) {
    consolidatedClientInvoices(input: $input) {
      hits {
        _id
        invoiceNumber
      }
    }
  }
`;

const DEFAULT_CONSOLIDATED_CLIENT_INVOICE_QUERY = gql`
  query DEFAULT_CONSOLIDATED_CLIENT_INVOICE_QUERY($id: ID!) {
    consolidatedClientInvoice(id: $id) {
      _id
      invoiceNumber
    }
  }
`;

// prepareOption :: ConsolidatedClientInvoice -> Option
const prepareOption = entityToOptionByLabelPath(['invoiceNumber']);

// getDefaultOptions :: { consolidatedClientInvoice: ConsolidatedClientInvoice } -> [Pair ID String]
const getDefaultOptions = R.compose(
  R.unless(R.isEmpty, R.compose(ofArrayLegacy, prepareOption)),
  R.propOr([], 'consolidatedClientInvoice'),
);

// getAllOptions :: { consolidatedClientInvoices: ConsolidatedClientInvoicesResult } -> [Pair ID String]
const getAllOptions = R.compose(
  R.map(prepareOption),
  R.pathOr([], ['consolidatedClientInvoices', 'hits']),
);

// concatOptions :: [Pair ID String] -> [Pair ID String] -> [Pair ID String]
const concatOptions = (defaultOptions) => R.concat(defaultOptions);

export function ConsolidatedInvoiceSelect({ value, ...props }) {
  const [queryData, setQueryData] = useState({});
  const [invoiceNumber, setInvoiceNumber] = useState(null);

  const input = useMemo(
    () => ({
      invoiceNumber,
      size: 10,
      sort: { invoiceNumber: 1 },
    }),
    [invoiceNumber],
  );

  const { data, loading } = useQuery(
    CONSOLIDATED_CLIENT_INVOICES_SELECT_QUERY,
    {
      variables: { input },
      skip: isNilOrEmpty(invoiceNumber),
    },
  );

  const { data: defaultData } = useQuery(
    DEFAULT_CONSOLIDATED_CLIENT_INVOICE_QUERY,
    {
      variables: { id: value },
      skip: isNilOrEmpty(value),
    },
  );

  useEffect(() => {
    if (!loading && data) {
      setQueryData(data);
    }
    if (isNilOrEmpty(invoiceNumber)) {
      setQueryData({});
    }
  }, [data, loading, invoiceNumber]);

  const consolidatedInvoiceSelectOptions = useMemo(() => {
    const defaultOptions = getDefaultOptions(defaultData);
    const allOptions = getAllOptions(queryData);

    return concatOptions(defaultOptions)(allOptions);
  }, [defaultData, queryData]);

  const onSearchChangeDebounced = useCallback(
    debounce(500)(setInvoiceNumber),
    [],
  );

  const selectProps = {
    value,
    loading,
    options: consolidatedInvoiceSelectOptions,
    onInputChange: onSearchChangeDebounced,
    ...props,
  };

  return <Select {...selectProps} />;
}

ConsolidatedInvoiceSelect.defaultProps = {
  isClearable: true,
  withoutError: true,
  includeAllOption: true,
  filterOption: () => true,
  name: 'ConsolidatedInvoiceSelect',
  placeholder: 'Start typing consolidated invoice',
};
ConsolidatedInvoiceSelect.propTypes = {
  name: string,
  value: string,
  isClearable: bool,
  filterOption: func,
  withoutError: bool,
  placeholder: string,
  includeAllOption: bool,
  onChange: func.isRequired,
};
