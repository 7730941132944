import { useMutation } from '@apollo/client';
import { usePagination } from '@poly/admin-ui';
import { ADD_UPDATE_MUTATION, CREATE_UPDATE_WITH_ACTION } from './mutations.js';

export const useCreateUpdateWithAction = () => {
  const { pagination } = usePagination();

  const [mutate] = useMutation(CREATE_UPDATE_WITH_ACTION, {
    alias: 'useCreateUpdateWithAction',
  });

  return {
    createUpdateWithAction: mutate,
    pagination,
  };
};

export const useAddNewUpdate = () => {
  const { pagination } = usePagination();

  const [mutate] = useMutation(ADD_UPDATE_MUTATION, {
    alias: 'useAddNewUpdate',
  });

  return {
    addNewUpdate: mutate,
    pagination,
  };
};
