import React from 'react';
import { PatternFormat } from 'react-number-format';
import { string } from 'prop-types';

export function NumberFormatDisplay({ value, format }) {
  return <PatternFormat value={value} displayType="text" format={format} />;
}

NumberFormatDisplay.propTypes = {
  value: string.isRequired,
  format: string.isRequired,
};
