import download from 'downloadjs';

export const useDownloadRemoteCheck = () => {
  const downloadRemoteCheck = ({ content, fileName, fileType }) => {
    const blob = new Blob([content], { type: 'text/plain' });

    download(blob, `${fileName}.${fileType}`);
  };

  return { downloadRemoteCheck };
};
