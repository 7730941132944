import {
  parse,
  format,
  getMinutes,
  set as dateFnsSet,
  differenceInMinutes,
} from 'date-fns';
import * as R from 'ramda';
import { ensureIsDate } from '@poly/utils';

// makeLastAsFirst :: [a] -> [a]
const makeLastAsFirst = R.converge(R.prepend, [R.last, R.init]);

// timeToDateValueBase :: (Date, String) -> Date
const timeToDateValueBase = (baseDate, timeStr) =>
  parse(timeStr, 'h:mm aaa', baseDate);

// dateValueToTime :: String -> Date
export const timeToDateValue = (timeStr) =>
  timeToDateValueBase(new Date(), timeStr);

const getTimeDifferenceInHours = (date, timeStr) => {
  const optionDate = timeToDateValueBase(date, timeStr);
  const diffInHrs =
    differenceInMinutes(optionDate, date, { roundingMethod: 'ceil' }) / 60;
  return diffInHrs.toFixed(2);
};

export const addOptionDurationByStartDate = (date) => (timeStr) =>
  date
    ? `${timeStr} (${getTimeDifferenceInHours(date, timeStr)} hrs)`
    : timeStr;

// generateTimeValues :: () -> [String]
const generateTimeValues = R.compose(
  R.map(R.compose(R.join(' '), R.reverse)),
  R.xprod(['am', 'pm']),
  R.map(
    R.compose(
      R.join(':'),
      R.juxt([R.compose(R.toString, R.head), R.compose(R.identity, R.last)]),
    ),
  ),
  R.xprod(makeLastAsFirst(R.range(1, 13))),
  R.always(['00', '15', '30', '45']),
);

const formatDateTime = (date) => format(ensureIsDate(date), 'h:mm aaa');

// generateTimePickerOptions :: Date -> [{label: String, value: String}]
export const generateTimePickerOptions = (date) =>
  R.compose(
    R.map(
      R.applySpec({
        value: R.identity,
        label: R.identity,
      }),
    ),
    (values) =>
      date ? values.slice(R.indexOf(formatDateTime(date), values) + 1) : values,
    generateTimeValues,
  )('');

const ROUND_PRECISION = 15; // in minutes
export const roundTimesheetMinutes = (date) => {
  const initialMinutes = getMinutes(ensureIsDate(date));
  const roundedMinutes =
    Math.round(initialMinutes / ROUND_PRECISION) * ROUND_PRECISION;

  return dateFnsSet(ensureIsDate(date), {
    minutes: roundedMinutes,
    seconds: 0,
    milliseconds: 0,
  });
};

// dateValueToTimeWithRound :: Date -> String
export const dateValueToTimeWithRound = R.when(
  R.is(Date),
  R.compose(formatDateTime, roundTimesheetMinutes),
);
