import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { shape, string } from 'prop-types';
import {
  getFullZipCodeFromAddress,
  calculateTotal,
  convertCentsToDollars,
} from '@poly/utils';
import {
  USAStates,
  WISCONSIN_STATE_SHORTNAME,
  EXPORT_XLS_CAPTION,
} from '@poly/constants';
import {
  formatDateOrNothingUI,
  createExcelExportCell,
  ExcelExportCellType,
  performExcelExport,
  formatFromToDateTitle,
} from '@poly/client-utils';

import { SalesReportLink } from './salesTaxReportComponents.js';
import { calculateSubTotal } from './salesTaxReportUtils.js';
import { taxReportProps } from './prop-types.js';
import { getSalesTaxReportNYTotal } from './SalesTaxReportTable.js';

const SalesReportLinkS = styled(SalesReportLink)`
  margin-right: 10px;
`;

// isWIState :: String -> Boolean
const isWIState = R.equals(WISCONSIN_STATE_SHORTNAME);

// cellsNumByState :: String -> Number
const cellsNumByState = (state) => (isWIState(state) ? 4 : 3);

// getFileNameByState :: String -> String
const getFileNameByState = R.ifElse(
  R.isNil,
  R.always('all_states_sales_tax_report.xlsx'),
  R.compose(
    R.join('_'),
    R.append('sales_tax_report.xlsx'),
    R.split(' '),
    R.toLower,
    R.prop(R.__, USAStates),
  ),
);

// salesTaxReportTableHeaderRow :: String -> [ExcelExportDataCell]
const salesTaxReportTableHeaderRow = (state) =>
  R.zipWith(
    createExcelExportCell,
    [
      ...R.repeat(ExcelExportCellType.tableHeader, 5),
      ...R.repeat(
        ExcelExportCellType.tableCurrencyHeader,
        cellsNumByState(state),
      ),
    ],
    [
      'Invoice Date',
      'Invoice #',
      'Project #',
      'Customer',
      'Service Zip Code',
      ...(isWIState(state)
        ? ['Job Cost', 'Invoice Amount', 'Markup']
        : ['Sub Total']),
      'Sales Tax',
      ...(isWIState(state) ? [] : ['Total']),
    ],
  );
// calculateTotalWithConvertToDollars :: (a -> Number) -> [Any] -> Number
const calculateTotalWithConvertToDollars = (fn) =>
  R.compose(convertCentsToDollars, calculateTotal(fn));

// getSalesTaxReportTableRow :: String -> ClientInvoice -> [ExcelExportDataCell]
const getSalesTaxReportTableRow = (state) =>
  R.compose(
    R.zipWith(createExcelExportCell, [
      ...R.repeat(ExcelExportCellType.default, 5),
      ...R.repeat(ExcelExportCellType.defaultCurrency, cellsNumByState(state)),
    ]),
    R.juxt([
      R.compose(formatDateOrNothingUI, R.prop('invoiceDate')),
      R.prop('number'),
      R.path(['project', 'projectId']),
      R.path(['client', 'nickName']),
      R.compose(
        getFullZipCodeFromAddress,
        R.path(['project', 'property', 'address']),
      ),
      ...(isWIState(state)
        ? [
            R.compose(
              calculateTotalWithConvertToDollars(R.prop('total')),
              R.prop('lines'),
            ),
            R.compose(convertCentsToDollars, R.prop('amount')),
            R.compose(convertCentsToDollars, R.prop('markupAmount')),
          ]
        : [R.compose(convertCentsToDollars, calculateSubTotal)]),
      R.compose(convertCentsToDollars, R.prop('taxAmount')),
      ...(isWIState(state)
        ? []
        : [R.compose(convertCentsToDollars, getSalesTaxReportNYTotal)]),
    ]),
  );

// getFooterJobCostTotal :: [ClientInvoice] -> Number
const getFooterJobCostTotal = R.compose(
  calculateTotalWithConvertToDollars(R.identity),
  R.map(R.compose(calculateTotal(R.prop('total')), R.prop('lines'))),
);

// getSalesTaxReportTotalRow :: String -> [ClientInvoice] -> [ExcelExportDataCell]
const getSalesTaxReportTotalRow = (state) =>
  R.compose(
    R.zipWith(createExcelExportCell, [
      ...R.repeat(ExcelExportCellType.tableStringFooter, 5),
      ...R.repeat(
        ExcelExportCellType.tableCurrencyFooter,
        cellsNumByState(state),
      ),
    ]),
    R.concat(['Total', ...R.repeat('', 4)]),
    R.juxt([
      ...(isWIState(state)
        ? [
            getFooterJobCostTotal,
            calculateTotalWithConvertToDollars(R.prop('amount')),
            calculateTotalWithConvertToDollars(R.prop('markupAmount')),
          ]
        : [calculateTotalWithConvertToDollars(calculateSubTotal)]),
      calculateTotalWithConvertToDollars(R.prop('taxAmount')),
      ...(isWIState(state)
        ? []
        : [calculateTotalWithConvertToDollars(getSalesTaxReportNYTotal)]),
    ]),
  );

// prepareSalesTaxReportByStates :: String -> [ClientInvoice] -> [[ExcelExportDataCell]]
const prepareSalesTaxReportByStates = (state) =>
  R.compose(
    R.prepend(salesTaxReportTableHeaderRow(state)),
    R.converge(R.append, [
      getSalesTaxReportTotalRow(state),
      R.map(getSalesTaxReportTableRow(state)),
    ]),
  );

const getSalesTaxReportExportConfig = (
  report,
  { state, endDate, startDate },
  title,
) => ({
  exportFileName: getFileNameByState(state),
  columnWidths: [15, 15, 15, 30, 20, 15, 15, 15, 15],
  rows: [
    [{ value: title, cellType: ExcelExportCellType.title }],
    [
      {
        value: formatFromToDateTitle(startDate, endDate),
        cellType: ExcelExportCellType.subTitle,
      },
    ],
    ...prepareSalesTaxReportByStates(state)(report),
  ],
});

export function SalesTaxReportExportXLBtn({ report, title, query }) {
  const onClick = () => {
    const config = getSalesTaxReportExportConfig(report, query, title);
    performExcelExport(config);
  };

  return (
    <SalesReportLinkS onClick={onClick} disabled={R.isEmpty(report)}>
      {EXPORT_XLS_CAPTION}
    </SalesReportLinkS>
  );
}

SalesTaxReportExportXLBtn.propTypes = {
  query: shape({ state: string }),
  title: string.isRequired,
  report: taxReportProps,
};
