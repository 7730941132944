import {
  UpdateWithActionsTypes,
  taskActionTypes,
  ListingTasksActionsTypesUIMap,
  ProjectType,
} from '@poly/constants';

export const initialCommonValues = {
  text: '',
  attachments: [],
  placeholder: 'Enter update notes',
  mentions: {},
};

const {
  CONFIRM_SCOPE,
  PROJECT_COMPLETE,
  CLOSE_PROJECT,
  SUPPLIER_SCHEDULING,
  SUPPLIER_FOLLOWUP,
  CONFIRM_SATISFACTION,
  RECALL_PROJECT,
  SUPPLIER_INVOICE,
  SUPPLIER_ASSIGN,
  REPORT_ONLY_CLIENT_INVOICE,
} = taskActionTypes;
const {
  confirmScope,
  projectComplete,
  projectClose,
  confirmScheduling,
  supplierFollowup,
  confirmSatisfaction,
  recall,
} = UpdateWithActionsTypes;

export const taskActionUpdateTypesMap = {
  [CONFIRM_SCOPE]: confirmScope,
  [PROJECT_COMPLETE]: projectComplete,
  [CLOSE_PROJECT]: projectClose,
  [SUPPLIER_SCHEDULING]: confirmScheduling,
  [SUPPLIER_FOLLOWUP]: supplierFollowup,
  [CONFIRM_SATISFACTION]: confirmSatisfaction,
  [RECALL_PROJECT]: recall,
};

export const projectTasksTitleMapByProjectType = (projectType) => ({
  [CONFIRM_SCOPE]: 'Confirm Job Scope',
  [PROJECT_COMPLETE]: 'Work Completed',
  [CLOSE_PROJECT]: 'Close Project',
  [SUPPLIER_SCHEDULING]: 'Confirm Scheduling',
  [SUPPLIER_FOLLOWUP]: 'Supplier Follow Up',
  [CONFIRM_SATISFACTION]: 'Confirm Satisfaction',
  [SUPPLIER_INVOICE]: 'Supplier Invoice',
  [RECALL_PROJECT]: 'Recall',
  [SUPPLIER_ASSIGN]: 'Assign Supplier',
  [REPORT_ONLY_CLIENT_INVOICE]: 'Client Invoice',
  ...(projectType === ProjectType.LISTING ? ListingTasksActionsTypesUIMap : {}),
});

export const workCompleteErrorMsg =
  'You need to Assign a Supplier to complete this task';

export const clientGLCodesNotFilledErrorMsg =
  'You need to fill client GL codes';

export const projectSubCategoryNotFilledErrorMsg =
  'You need to fill project sub category';

export const projectPONotFilledErrorMsg = 'You need to fill project PO';

export const UPDATE_TASK_DEFAULT_MSG = 'Task updated';
export const COMPLETE_TASK_DEFAULT_UPDATE_MSG = 'Task completed';
export const CREATE_TASK_DEFAULT_UPDATE_MSG = 'Task created';
