import * as R from 'ramda';
import React from 'react';
import { bool, func, number, shape, string } from 'prop-types';

import { AmountInput } from '../../../components/PaymentsFromComponents/AmountInput.js';

// isPaymentDeductionRequired :: String -> Boolean
const isPaymentDeductionRequired = R.both(
  R.is(String),
  R.complement(R.isEmpty),
);

export function PaymentDeductionInput({
  _id,
  errors,
  submitting,
  deductionGL,
  currentBalance,
  deductionAmount,
  onDeductionChange,
}) {
  const inputName = `payment-deduction-input-${_id}`;

  return (
    <AmountInput
      width="100%"
      name={inputName}
      value={deductionAmount}
      onChange={onDeductionChange}
      disabled={submitting || currentBalance < 0}
      required={isPaymentDeductionRequired(deductionGL)}
      dataTestId={inputName}
      currentBalance={currentBalance}
      error={errors.deductionAmount}
    />
  );
}

PaymentDeductionInput.defaultProps = {
  errors: { deductionAmount: '' },
};
PaymentDeductionInput.propTypes = {
  _id: string,
  errors: shape({ deductionAmount: string }),
  submitting: bool,
  deductionGL: string,
  currentBalance: number,
  deductionAmount: number,
  onDeductionChange: func,
};
