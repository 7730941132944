import { node, string, func, bool } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ContentWrapper } from '../Tabs/components.js';
import { getThemeColor } from '../utils.js';
import { HeaderWrapper, MainHeader } from './index.js';
import { Text } from '../Text/index.js';
import { Icon } from '../Icon/index.js';

const SearchPageHeaderWrapper = styled(HeaderWrapper)`
  padding: 0;
  background-color: ${getThemeColor(['secondaryLighter3'])};
  flex-shrink: 0;
  z-index: 3;
  height: ${({ isOpen, height }) => (isOpen ? `${height}` : '57px')};
`;

const SearchPageHeaderContent = styled(ContentWrapper)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
`;

const SearchPageTitle = styled(MainHeader)`
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid ${getThemeColor(['midLight'])};
  justify-content: space-between;
  margin-bottom: 10px;
`;

const FiltersWrapper = styled.div`
  color: ${getThemeColor(['primaryLight'])}
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  width: 60px;
  height: 25px;
  
  span {
    display: flex;
    margin-right: 5px;
  }
  
  svg {
    display: flex;
  }
`;

export function SearchPageHeaderContainer({
  title,
  height,
  children,
  isOpen,
  onArrowClick,
  TitleComp,
  // use this prop to set min width in header
  width,
  className,
  ...props
}) {
  return (
    <SearchPageHeaderWrapper {...{ isOpen, height, className }}>
      <SearchPageHeaderContent width={width}>
        <SearchPageTitle>
          {TitleComp ? <TitleComp {...props} /> : <Text>{title}</Text>}
          <FiltersWrapper onClick={onArrowClick}>
            <span>Filters</span>
            <Icon
              size={15}
              color="#4676b4"
              name={isOpen ? 'arrow-up' : 'arrow-down'}
            />
          </FiltersWrapper>
        </SearchPageTitle>
        {isOpen && children}
      </SearchPageHeaderContent>
    </SearchPageHeaderWrapper>
  );
}

SearchPageHeaderContainer.propTypes = {
  title: string,
  height: string,
  children: node.isRequired,
  isOpen: bool.isRequired,
  onArrowClick: func.isRequired,
  TitleComp: func,
  width: string,
  className: string,
};
