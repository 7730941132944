import {
  isPast,
  addDays,
  subDays,
  isToday,
  isFuture,
  isTomorrow,
} from 'date-fns';
import * as R from 'ramda';
import { UnknownProjectStatus } from '@poly/admin-ui';
import { ensureIsDate, propEqLegacy } from '@poly/utils';
import {
  onHoldWorkOrderStatuses,
  RecurringProjectStatuses,
  taskActionTypes,
  WorkOrderStatus,
} from '@poly/constants';
import {
  getTimezoneByDate,
  formatDateMongo,
  dateFieldGte,
  dateFieldEq,
  dateFieldLt,
} from '@poly/client-utils';

import { ITEMS_LIMIT } from '../constants/print.js';
import {
  expiringMasterProjectsDays,
  projectStatusesColors,
  projectUIStatuses,
} from '../constants/projects.js';

const { ACTIVE, PENDING, ON_HOLD, PAST_DUE, COMPLETED, BLOCKED, CLOSED } =
  projectStatusesColors;

const statusEq = propEqLegacy('status');

// endDate :: (Date -> Boolean) -> Object -> Boolean
const endDate = (checker) =>
  R.compose(checker, ensureIsDate, R.prop('endDate'));

// getCommonProjectStatusColor :: Object -> String
export const getCommonProjectStatusColor = R.compose(
  R.cond([
    [
      R.propSatisfies(R.includes(R.__, onHoldWorkOrderStatuses), 'status'),
      R.always(ON_HOLD),
    ],
    [statusEq(WorkOrderStatus.PENDING), R.always(PENDING)],
    [statusEq(WorkOrderStatus.COMPLETED), R.always(COMPLETED)],
    [statusEq(WorkOrderStatus.BLOCKED), R.always(BLOCKED)],
    [statusEq(RecurringProjectStatuses.CLOSED), R.always(CLOSED)],
    [endDate(isPast), R.always(PAST_DUE)],
    [R.T, R.always(ACTIVE)],
  ]),
);

// getUIProjectStatus :: Project -> String
export const getUIProjectStatus = R.cond([
  [
    R.propSatisfies(R.includes(R.__, onHoldWorkOrderStatuses), 'status'),
    R.always(projectUIStatuses.ON_HOLD),
  ],
  [
    R.both(endDate(isToday), statusEq(WorkOrderStatus.ACTIVE)),
    R.always(projectUIStatuses.DUE_TODAY),
  ],
  [
    R.both(endDate(isTomorrow), statusEq(WorkOrderStatus.ACTIVE)),
    R.always(projectUIStatuses.DUE_TOMORROW),
  ],
  [
    R.both(endDate(isPast), statusEq(WorkOrderStatus.ACTIVE)),
    R.always(projectUIStatuses.PAST_DUE),
  ],
  [
    R.both(endDate(isFuture), statusEq(WorkOrderStatus.ACTIVE)),
    R.always(projectUIStatuses.ACTIVE),
  ],
  [statusEq(WorkOrderStatus.PENDING), R.always(projectUIStatuses.PENDING)],
  [statusEq(WorkOrderStatus.BLOCKED), R.always(projectUIStatuses.BLOCKED)],
  [statusEq(WorkOrderStatus.COMPLETED), R.always(projectUIStatuses.COMPLETED)],
  [R.T, R.always(UnknownProjectStatus)],
]);

const projectHoldStatusesFilter = R.map(
  R.pipe(R.objOf('status'), R.objOf('match')),
  onHoldWorkOrderStatuses,
);

// createQueryBoolFilter :: Object -> Object
const createQueryBoolFilter = R.assocPath(['bool', 'filter', 'bool'], R.__, {});

export const getProjectsQueries = (today, mustQuery = []) => ({
  [projectUIStatuses.DUE_TODAY]: createQueryBoolFilter({
    must: [
      { match: { status: WorkOrderStatus.ACTIVE } },
      ...(today ? [dateFieldEq('endDate', today)] : []),
      ...mustQuery,
    ],
  }),

  [projectUIStatuses.DUE_TOMORROW]: createQueryBoolFilter({
    must: [
      { match: { status: WorkOrderStatus.ACTIVE } },
      ...(today
        ? [dateFieldEq('endDate', addDays(ensureIsDate(today), 1))]
        : []),
      ...mustQuery,
    ],
  }),
  [projectUIStatuses.PAST_DUE]: createQueryBoolFilter({
    must: [
      { match: { status: WorkOrderStatus.ACTIVE } },
      dateFieldLt('endDate', today),
      ...mustQuery,
    ],
  }),
  [projectUIStatuses.ON_HOLD]: createQueryBoolFilter({
    minimum_should_match: 1,
    should: projectHoldStatusesFilter,
    ...(mustQuery.length ? { must: mustQuery } : {}),
  }),

  [projectUIStatuses.PENDING]: createQueryBoolFilter({
    must: [{ match: { status: WorkOrderStatus.PENDING } }],
  }),

  [projectUIStatuses.ACTIVE]: createQueryBoolFilter({
    must_not: projectHoldStatusesFilter,
    must: [{ match: { status: WorkOrderStatus.ACTIVE } }, ...mustQuery],
  }),
  [projectUIStatuses.BLOCKED]: createQueryBoolFilter({
    must: [
      { match: { status: WorkOrderStatus.BLOCKED } },
      { exists: { field: 'blockedAt' } },
      dateFieldGte('blockedAt', subDays(today, 30)),
      ...mustQuery,
    ],
  }),
  [projectUIStatuses.COMPLETED]: createQueryBoolFilter({
    must: [
      { match: { status: WorkOrderStatus.COMPLETED } },
      { exists: { field: 'workCompletionDate' } },
      dateFieldGte('workCompletionDate', subDays(today, 30)),
      ...mustQuery,
    ],
  }),
  [projectUIStatuses.RECALLED]: createQueryBoolFilter({
    must: [{ match: { isRecalled: true } }, ...mustQuery],
    must_not: { match: { status: WorkOrderStatus.BLOCKED } },
  }),
  [projectUIStatuses.ALL]: mustQuery.length
    ? createQueryBoolFilter({ must: mustQuery })
    : null,
});

export const getRecurringQueries = (today) => ({
  [projectUIStatuses.ACTIVE]: createQueryBoolFilter({
    must: [{ match: { status: RecurringProjectStatuses.ACTIVE } }],
  }),
  [projectUIStatuses.PAST_DUE]: createQueryBoolFilter({
    must: [
      { match: { status: RecurringProjectStatuses.ACTIVE } },
      dateFieldLt('endDate', today),
    ],
  }),
  [projectUIStatuses.CLOSED]: createQueryBoolFilter({
    must: [{ match: { status: RecurringProjectStatuses.CLOSED } }],
  }),
  [projectUIStatuses.ALL]: null,
});

const getRecurringQueriesByEndDate = (today) => {
  const todayDate = ensureIsDate(today);
  return {
    [projectUIStatuses.ACTIVE]: createQueryBoolFilter({
      must: [{ match: { status: RecurringProjectStatuses.ACTIVE } }],
    }),
    [projectUIStatuses.PAST_DUE]: createQueryBoolFilter({
      must: [
        { match: { status: RecurringProjectStatuses.ACTIVE } },
        dateFieldLt('endDate', todayDate),
      ],
    }),
    [expiringMasterProjectsDays.EXPIRING30]: createQueryBoolFilter({
      must: [
        { match: { status: RecurringProjectStatuses.ACTIVE } },
        {
          range: {
            endDate: {
              lte: formatDateMongo(addDays(todayDate, 30)),
              gte: formatDateMongo(todayDate),
              time_zone: getTimezoneByDate(todayDate),
            },
          },
        },
      ],
    }),
    [expiringMasterProjectsDays.EXPIRING60]: createQueryBoolFilter({
      must: [
        { match: { status: RecurringProjectStatuses.ACTIVE } },
        {
          range: {
            endDate: {
              lte: formatDateMongo(addDays(todayDate, 60)),
              gt: formatDateMongo(addDays(todayDate, 30)),
              time_zone: getTimezoneByDate(todayDate),
            },
          },
        },
      ],
    }),
    [expiringMasterProjectsDays.EXPIRING90]: createQueryBoolFilter({
      must: [
        { match: { status: RecurringProjectStatuses.ACTIVE } },
        {
          range: {
            endDate: {
              lte: formatDateMongo(addDays(todayDate, 90)),
              gt: formatDateMongo(addDays(todayDate, 60)),
              time_zone: getTimezoneByDate(todayDate),
            },
          },
        },
      ],
    }),
  };
};

const withDefaultRecurringQueryByEndDate = (today) =>
  R.mergeDeepWith(
    R.concat,
    R.omit([projectUIStatuses.ALL], getRecurringQueriesByEndDate(today)),
  );

export const entityRecurringQueriesByEndDate = (today) =>
  withDefaultRecurringQueryByEndDate(today)({
    [projectUIStatuses.ACTIVE]: {},
    [projectUIStatuses.PAST_DUE]: {},
    [expiringMasterProjectsDays.EXPIRING30]: {},
    [expiringMasterProjectsDays.EXPIRING60]: {},
    [expiringMasterProjectsDays.EXPIRING90]: {},
  });

// modifyProjectsLimit :: Object -> Object
export const modifyProjectsLimit = R.when(
  R.propSatisfies(R.gt(R.__, ITEMS_LIMIT), 'size'),
  R.assoc('size', R.inc(ITEMS_LIMIT)),
);

// isDefaultTask :: TaskAction -> Boolean
// TaskAction = String
export const isDefaultTask = R.includes(R.__, R.values(taskActionTypes));
