import React from 'react';
import * as R from 'ramda';
import { arrayOf } from 'prop-types';
import styled from 'styled-components';
import { SidebarRow, useMapConfigToTableProps } from '@poly/admin-ui';
import {
  centsToDollarsWithFormat,
  formatTotal,
  mergeObjectWithArrayOfObjects,
  formatDate,
} from '@poly/utils';
import {
  formatDateOrNothingUI,
  useOutSidebarContext,
} from '@poly/client-utils';
import { moneyColumnStyles, Table } from '@poly/admin-book';
import { DatePicker } from '@poly/admin-book/src/DatePicker/index.js';

import { journalPropTypes } from './transactionDetailsPropTypes.js';
import {
  isBankJournalLine,
  getPaidByForJournal,
  transactionsDetailsSidebarId,
  getCreatorNameUIByJournal,
} from './helpers.js';
import { HeaderBlock } from './HeaderBlock.js';
import {
  TransactionDetailsContainer,
  TransactionHeader,
  TransactionLinesContainer,
} from './ClientPaymentTransactionDetailsSidebar.js';
import { useEditJournalPostingDate } from './useEditJournalPostingDate.js';
import { BlockWithLabel } from '../components/commonSidebarComponents.js';
import { TransactionDetailsButtons } from './TransactionDetailsButtons.js';

const transactionLinesTableConfig = [
  ['Ref No', R.prop('invoiceId')],
  ['Inv No', R.prop('invoiceNumber')],
  ['Name', R.path(['supplier', 'company', 'name'])],
  ['GL Code', R.prop('accountName')],
  ['Invoice Date', R.compose(formatDate, R.prop('invoiceDate'))],
  ['Invoice Amount', R.compose(centsToDollarsWithFormat, R.prop('total'))],
  ['Paid Amount', R.compose(formatTotal, R.prop('paidAmount'))],
];

// getPayrollInvoicesByJournal :: Journal -> [Invoice]
const getPayrollInvoicesByJournal = R.converge(mergeObjectWithArrayOfObjects, [
  R.prop('invoice'),
  R.compose(
    R.map(
      R.applySpec({
        _id: R.path(['account', 'code']),
        paidAmount: R.prop('cash_amount'),
        accountName: R.path(['account', 'name']),
      }),
    ),
    R.reject(isBankJournalLine),
    R.prop('lines'),
  ),
]);

// transformJournalsToTransactionInfo :: [Journal] -> Object
const transformJournalsToTransactionInfo = R.compose(
  R.mergeAll,
  R.juxt([
    R.compose(
      R.applySpec({
        bankAccountName: R.path(['account', 'name']),
        total: R.compose(R.negate, R.prop('cash_amount')),
        bankClearedDate: R.compose(
          formatDateOrNothingUI,
          R.prop('bank_cleared_date'),
        ),
      }),
      R.find(isBankJournalLine),
      R.prop('lines'),
    ),
    R.pick(['date']),
    R.applySpec({
      transaction_number: R.prop('transaction_number'),
      paidBy: getPaidByForJournal,
      invoices: getPayrollInvoicesByJournal,
      createdBy: getCreatorNameUIByJournal,
    }),
  ]),
  R.head,
);

const PaymentTransactionTableS = styled(Table)`
  ${moneyColumnStyles(6, 100)};
  ${moneyColumnStyles(7, 100)};
`;

export function PayrollPaymentTransactionDetailsSidebar({ journals }) {
  const { closeOutSidebar } = useOutSidebarContext();
  const transactionInfo = transformJournalsToTransactionInfo(journals);

  const {
    handleEditJournalPostingDate,
    isEditMode,
    toggleEditMode,
    postingDate,
    setPostingDate,
  } = useEditJournalPostingDate(transactionInfo);

  const tableProps = useMapConfigToTableProps(
    R.identity,
    transactionLinesTableConfig,
    transactionInfo.invoices,
  );

  return (
    <TransactionDetailsContainer>
      <SidebarRow justify>
        <TransactionHeader>Payment Details</TransactionHeader>
        <TransactionDetailsButtons
          toggleEditMode={toggleEditMode}
          isEditMode={isEditMode}
          handleEditJournalPostingDate={handleEditJournalPostingDate}
          closeOutSidebar={() => closeOutSidebar(transactionsDetailsSidebarId)}
        />
      </SidebarRow>
      <SidebarRow>
        <HeaderBlock title="Paid By" content={transactionInfo.paidBy} />
        <HeaderBlock
          title="Account"
          content={transactionInfo.bankAccountName}
        />
        <HeaderBlock
          title="Bank Clearing Date"
          content={transactionInfo.bankClearedDate}
        />
      </SidebarRow>
      <SidebarRow>
        {isEditMode ? (
          <BlockWithLabel
            id="postingDate"
            label="Posting Date"
            width="25%"
            onChange={setPostingDate}
            value={postingDate}
            Component={DatePicker}
            leftMove="0"
          />
        ) : (
          <HeaderBlock
            title="Posting Date"
            content={formatDate(transactionInfo.date)}
          />
        )}
        <HeaderBlock
          title="Amount"
          content={formatTotal(transactionInfo.total)}
        />
        <HeaderBlock title="Created By" content={transactionInfo.createdBy} />
      </SidebarRow>
      <TransactionLinesContainer>
        <SidebarRow>
          <PaymentTransactionTableS {...tableProps} />
        </SidebarRow>
      </TransactionLinesContainer>
    </TransactionDetailsContainer>
  );
}

PayrollPaymentTransactionDetailsSidebar.propTypes = {
  journals: arrayOf(journalPropTypes),
};
