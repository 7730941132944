import * as R from 'ramda';
import { startOfYear } from 'date-fns';
import React, { useState } from 'react';
import { ToolBarBtnDivider, Loader, DateRangePicker } from '@poly/admin-book';
import { AccountingMethods, NON_CLIENT_SELECT_OPTION } from '@poly/constants';
import { alwaysNewDate, propEqLegacy } from '@poly/utils';
import { ALL, ClientSelect } from '@poly/admin-ui';
import { useQuery, gql } from '@apollo/client';

import {
  PageWithSearchHeader,
  SearchPageContentWrapper,
  SearchPageHeaderContainer,
} from '../../components/PageWithSearchHeader.js';
import { AccountsSelect } from '../../components/AccountsSelect.js';
import { AccountTransactionsTable } from './AccountTransactionsTable.js';
import { SearchHeaderCreator } from '../../components/SearchHeaderCreator.js';
import { AccountTransactionsPrintBtn } from './AccountTransactionsPrintBtn.js';
import { AccountingMethodSelect } from '../../components/AccountingMethodSelect.js';
import { AccountTransactionsExportXlBtn } from './AccountTransactionsExportXlBtn.js';
import { convertDateToDateRange } from '../GeneralLedgerPage/generalLedgerHelpers.js';
import { useAccountTransactionsQuery } from './useAccountTransactionsQuery.js';
import {
  accountTransactionsFiltersPropTypes,
  accountTransactionsResultPropType,
} from './accountTransactionsPropTypes.js';

const accountQueryGql = gql`
  query FETCH_ACCOUNT_NAME($input: SingleAccountInput!) {
    getSingleAccount(input: $input) {
      _id
      name
    }
  }
`;

function TransactionsExportButtons({ result, filters }) {
  const { data } = useQuery(accountQueryGql, {
    variables: { input: { accountId: filters?.accountId } },
    skip: !filters?.accountId,
  });

  const accountName = R.path(['getSingleAccount', 'name'], data);

  return (
    <>
      <AccountTransactionsPrintBtn
        accountName={accountName}
        filters={filters}
        result={result}
      />
      <ToolBarBtnDivider />
      <AccountTransactionsExportXlBtn
        accountName={accountName}
        filters={filters}
        result={result}
      />
    </>
  );
}

TransactionsExportButtons.propTypes = {
  filters: accountTransactionsFiltersPropTypes,
  result: accountTransactionsResultPropType,
};

function AccountTransactionsClientSelect(props) {
  const extraOption = { value: NON_CLIENT_SELECT_OPTION, label: 'Non-Client' };

  return <ClientSelect {...props} extraOption={extraOption} />;
}

const accountTransactionsSearchHeaderConfig = (
  currentDate,
  filters,
  result,
) => [
  {
    key: 'glCodeRow',
    rowLayout: { justifyContent: 'flex-start', columnWidth: '35%' },
    columns: [
      {
        name: 'accountId',
        title: 'Account',
        filterProps: {
          width: '100%',
          changeOnBlur: true,
          filters: {},
          extractValue: R.prop('_id'),
        },
        column: 1,
        Component: AccountsSelect,
      },
      {
        name: 'accountingMethod',
        defaultValue: AccountingMethods.ACCRUAL_BASIS,
        title: 'Type',
        filterProps: { width: '100%' },
        column: 1,
        Component: AccountingMethodSelect,
      },
      {
        name: 'clientId',
        title: 'Client',
        filterProps: { width: '100%' },
        column: 1,
        Component: AccountTransactionsClientSelect,
      },
      {
        name: 'date',
        nestedFields: ['startDate', 'endDate'],
        title: 'Date',
        columnLayout: { titleWidth: '80px' },
        defaultValue: {
          startDate: startOfYear(currentDate),
          endDate: currentDate,
        },
        column: 2,
        Component: DateRangePicker,
      },
      {
        name: 'export',
        columnLayout: {
          margin: '8px 0 0 auto',
          filterWidth: '180px',
        },
        column: 3,
        Component: () => (
          <TransactionsExportButtons result={result} filters={filters} />
        ),
      },
    ],
  },
];

// prepareQueryFilters :: { clientId: ID } -> { clientId: ID }
const prepareQueryFilters = R.when(
  propEqLegacy('clientId', ALL),
  R.dissoc('clientId'),
);

export function AccountTransactionsPage() {
  const [filters, setFilters] = useState(null);

  const preparedQueryFilters = prepareQueryFilters(filters);

  const { data, loading, tableProps } =
    useAccountTransactionsQuery(preparedQueryFilters);

  const result = R.propOr(null, 'accountTransactions', data);

  const headerConfig = accountTransactionsSearchHeaderConfig(
    alwaysNewDate(),
    filters,
    result,
  );

  const onSearch = (newFilters) => {
    setFilters(convertDateToDateRange(newFilters));
  };

  return (
    <PageWithSearchHeader headerHeight="175px">
      <SearchPageHeaderContainer title="Account Transactions">
        <SearchHeaderCreator
          config={headerConfig}
          onSearch={onSearch}
          loading={loading}
          autoSearch
        />
      </SearchPageHeaderContainer>
      <SearchPageContentWrapper>
        {loading && <Loader />}

        {!loading && result && (
          <AccountTransactionsTable
            result={result}
            {...tableProps}
            showScrollBar
          />
        )}
      </SearchPageContentWrapper>
    </PageWithSearchHeader>
  );
}
