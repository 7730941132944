import React, { useState, useEffect } from 'react';
import { bool, object, string } from 'prop-types';
import { SidebarWidth } from '@poly/admin-ui';
import {
  useHasUserAccessWithPermission,
  useOutSidebarContext,
} from '@poly/client-utils';
import { UPDATE_PROPERTY_LOCATION_HIERARCHY_PERMISSION } from '@poly/security';

import {
  propertyTabsSidebarId,
  PropertyTabsSidebarWidth,
} from '../constants.js';
import { SidebarIDs } from '../../constants.js';
import { SidebarTabs } from '../../components/SidebarTabs.js';
import { propertySidebarTabs } from '../../../routes/constants.js';
import { PropertySidebarFilesTab } from './PropertySidebarFilesTab.js';
import { PropertySidebarTasksTab } from './PropertySidebarTasksTab.js';
import { PropertySidebarAssetsTab } from './PropertySidebarAssetsTab.js';
import { PropertySidebarUpdatesTab } from './PropertySidebarUpdatesTab.js';
import { PropertySidebarMastersTab } from './PropertySidebarMastersTab.js';
import { PropertySidebarProjectsTab } from './PropertySidebarProjectsTab.js';
import { PropertySidebarContactsTab } from './PropertySidebarContactsTab.js';
import { PropertySidebarSuppliersTab } from './PropertySidebarSuppliersTab.js';
import { PropertyLocationHierarchyTab } from './PropertyLocationHierarchyTab/PropertyLocationHierarchyTab.js';
import { importAssetsFormId } from '../../AssetSidebar/constants.js';
import { ClientSidebarPropertiesTab } from '../../ClientSidebar/tabs/ClientSidebarProperties/ClientSidebarPropertiesTab.js';

export function PropertySidebarTabs({
  isPropertyLocationHierarchyEnabled,
  customTabQueryKey,
  property = {},
  isCard = false,
}) {
  const [defaultTab, setDefaultTab] = useState(
    propertySidebarTabs.propertyUpdates,
  );

  const propertyId = property._id;
  const propertyName = property.name;
  const doubleWidth = SidebarWidth + PropertyTabsSidebarWidth;
  const hasAccessToLocationHierarchy = useHasUserAccessWithPermission(
    UPDATE_PROPERTY_LOCATION_HIERARCHY_PERMISSION,
  );

  const { isSidebarOpened } = useOutSidebarContext();

  const isOpen = isSidebarOpened(importAssetsFormId);

  useEffect(() => {
    if (isOpen) {
      setDefaultTab(propertySidebarTabs.propertyAssets);
    }
  }, [isOpen]);

  return (
    <SidebarTabs
      hideCloseIcon={isCard}
      isCard={isCard}
      id={propertyTabsSidebarId}
      params={[SidebarIDs.property]}
      defaultValue={defaultTab}
      customTabQueryKey={customTabQueryKey}
      tabs={[
        ...(property.isMaster
          ? [
              [
                'Properties',
                propertySidebarTabs.propertyChildProperties,
                <ClientSidebarPropertiesTab
                  key="mixedPropertiesTab"
                  title="Child Properties"
                  clientId={property.client._id}
                  parentProperty={property}
                  doubleTabWidth={doubleWidth}
                  isCard={isCard}
                />,
              ],
            ]
          : []),
        [
          'Projects',
          propertySidebarTabs.propertyProjects,
          <PropertySidebarProjectsTab {...{ property, doubleWidth, isCard }} />,
        ],
        [
          'Masters',
          propertySidebarTabs.propertyMasters,
          <PropertySidebarMastersTab {...{ property, doubleWidth, isCard }} />,
        ],
        [
          'Tasks',
          propertySidebarTabs.propertyTasks,
          <PropertySidebarTasksTab {...{ propertyId, propertyName }} />,
        ],
        [
          'Updates',
          propertySidebarTabs.propertyUpdates,
          <PropertySidebarUpdatesTab {...{ propertyId, propertyName }} />,
        ],
        [
          'Contacts',
          propertySidebarTabs.propertyContacts,
          <PropertySidebarContactsTab
            {...{ propertyId, doubleWidth, isCard }}
          />,
        ],
        [
          'Files',
          propertySidebarTabs.propertyFiles,
          <PropertySidebarFilesTab
            propertyId={propertyId}
            key={propertySidebarTabs.propertyFiles}
          />,
        ],
        [
          'Suppliers',
          propertySidebarTabs.propertySuppliers,
          <PropertySidebarSuppliersTab
            {...{ propertyId, doubleWidth, isCard }}
          />,
        ],
        [
          'Assets',
          propertySidebarTabs.propertyAssets,
          <PropertySidebarAssetsTab
            isMaster={property.isMaster}
            propertyName={property?.name}
            {...{ propertyId, doubleWidth, isCard }}
          />,
        ],
        ...(isPropertyLocationHierarchyEnabled && hasAccessToLocationHierarchy
          ? [
              [
                'Location',
                propertySidebarTabs.propertyLocationHierarchy,
                <PropertyLocationHierarchyTab
                  {...{ doubleWidth, isCard, propertyId }}
                />,
              ],
            ]
          : []),
      ]}
    />
  );
}

PropertySidebarTabs.propTypes = {
  isCard: bool,
  // eslint-disable-next-line react/forbid-prop-types
  property: object.isRequired,
  isPropertyLocationHierarchyEnabled: bool,
  customTabQueryKey: string,
};
