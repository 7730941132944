import * as R from 'ramda';
import { formatTotal, removePropDeep } from '@poly/utils';

import { UserGroupAIT_UIValuesUI } from './userGroupAITValuesUI.js';

// handleValues :: { values: [String|Number] } -> String
const handleValues = R.compose(
  R.join(', '),
  R.map(R.when(R.is(Number), formatTotal)),
  R.map(R.when(R.is(String), R.prop(R.__, UserGroupAIT_UIValuesUI))),
  R.propOr([], 'values'),
);

// getConditionByPath :: [String] -> Pair (() -> Boolean) (() -> String)
const getConditionByPath = (path) => [R.hasPath(path), R.path(path)];

// handleDocuments :: { documents: [Object] } -> String
const handleDocuments = R.compose(
  R.join(', '),
  R.map(
    R.cond([
      getConditionByPath(['profile', 'fullName']),
      getConditionByPath(['company', 'name']),
      getConditionByPath(['projectId']),
      getConditionByPath(['displayName']),
      [R.T, R.prop('name')],
    ]),
  ),
  R.propOr([], 'documents'),
);

// prepareScopeValue :: [UserGroupAITypeUIUserGroup] -> [String]
const prepareScopeValue = R.ifElse(
  R.pathSatisfies(R.is(String), ['variable', 'id']),
  R.applySpec({
    isVariable: R.T,
    value: R.compose(
      R.concat(R.__, '}]'),
      R.concat('[{'),
      R.path(['variable', 'name']),
    ),
  }),
  R.compose(
    R.objOf('value'),
    R.join(' | '),
    R.juxt([
      R.prop('scope'),
      R.prop('operation'),
      R.ifElse(R.has('values'), handleValues, handleDocuments),
    ]),
  ),
);

// preparePermissionScopes :: UserGroupAccessItem -> [String]
export const preparePermissionScopes = R.compose(
  R.map(prepareScopeValue),
  R.flatten,
  R.map(R.last),
  R.toPairs,
  removePropDeep('__typename'),
  R.propOr({}, 'typesUI'),
);
