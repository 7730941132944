import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { bool, func, string } from 'prop-types';
import { Icon } from '@poly/admin-book';
import { useSelector } from 'react-redux';
import { useRouterQuery, useUpdateQueryParams } from '@poly/client-routing';

const ButtonS = styled.button`
  background-color: #ebebeb;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

function IconButton({ onClick, disabled, ...props }) {
  return (
    <ButtonS onClick={onClick} disabled={disabled}>
      <Icon name="arrow-back" size="10" color="#999999" {...props} />
    </ButtonS>
  );
}

IconButton.propTypes = {
  onClick: func.isRequired,
  disabled: bool,
};

// getRequestedSupplierDocumentBase :: (Number -> Number) -> ([String], String) -> String
const getRequestedSupplierDocumentBase = (getIndex) => (docsIds, currentId) =>
  R.ifElse(
    R.both(R.always(currentId), R.includes(currentId)),
    R.compose(
      R.ifElse(R.gte(R.__, 0), R.prop(R.__, docsIds), R.always(null)),
      getIndex,
      R.indexOf(currentId),
    ),
    R.always(null),
  )(docsIds);

// getNextRequestedSupplierDocument :: ([String], String) -> String
const getNextRequestedSupplierDocument = getRequestedSupplierDocumentBase(
  R.add(1),
);

// getPreviousRequestedSupplierDocument :: ([String], String) -> String
const getPreviousRequestedSupplierDocument = getRequestedSupplierDocumentBase(
  R.add(-1),
);

export const useSidebarDocumentsNavigation = (documentIdKey) => {
  const { [documentIdKey]: documentId } = useRouterQuery([documentIdKey]);

  const documentIds = useSelector(R.propOr([], 'previewIds'));

  const updateQueryParams = useUpdateQueryParams();

  const nextDocumentId = getNextRequestedSupplierDocument(
    documentIds,
    documentId,
  );

  const previousDocumentId = getPreviousRequestedSupplierDocument(
    documentIds,
    documentId,
  );

  const goToNextDocument = () => {
    if (nextDocumentId) {
      updateQueryParams({
        [documentIdKey]: nextDocumentId,
      });
    }
  };

  const goToPreviousDocument = () => {
    if (previousDocumentId) {
      updateQueryParams({
        [documentIdKey]: previousDocumentId,
      });
    }
  };

  return {
    goToNextDocument,
    goToPreviousDocument,
    isNexDocument: !!nextDocumentId,
    isPreviousDocument: !!previousDocumentId,
  };
};

const NavigationContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export function PreviewDocumentsNavigation({ documentIdKey }) {
  const {
    goToNextDocument,
    goToPreviousDocument,
    isNexDocument,
    isPreviousDocument,
  } = useSidebarDocumentsNavigation(documentIdKey);

  return (
    <NavigationContainer>
      <IconButton
        onClick={goToPreviousDocument}
        disabled={!isPreviousDocument}
      />
      {isNexDocument && (
        <IconButton
          style={{ transform: 'rotate(180deg)' }}
          onClick={goToNextDocument}
        />
      )}
    </NavigationContainer>
  );
}

PreviewDocumentsNavigation.propTypes = {
  documentIdKey: string.isRequired,
};
