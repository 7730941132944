import { gql } from '@apollo/client';

export const CREATE_SERVICE_TYPE_MUTATION = gql`
  mutation CREATE_SERVICE_TYPE_MUTATION($input: CreateServiceTypeInput!) {
    createServiceType(input: $input) {
      _id
    }
  }
`;

export const UPDATE_SERVICE_TYPE_MUTATION = gql`
  mutation UPDATE_SERVICE_TYPE_MUTATION(
    $id: ID!
    $input: UpdateServiceTypeInput!
  ) {
    updateServiceType(id: $id, input: $input) {
      _id
    }
  }
`;
