import React from 'react';
import * as R from 'ramda';
import { Status, TextButton, defaultTheme } from '@poly/admin-book';
import { bool, shape, string } from 'prop-types';
import styled from 'styled-components';
import { formatDate } from '@poly/utils';
import { gql, useMutation } from '@apollo/client';
import { useModalContext } from './Modal/ModalProvider.js';
import { useNotificationState } from '../hooks/useNotificationState.js';

const disableUser2FAMutation = gql`
  mutation DISABLE_USER_2FA($userId: ID!) {
    disableUser2FactorAuth(userId: $userId) {
      id
    }
  }
`;

const {
  colors: {
    statuses: { error, active },
  },
} = defaultTheme;

const Disable2FAButton = styled(TextButton)`
  color: ${error};
  padding: 2px 0 0 18px;
  font-size: 14px;
`;

const confirmModalProps = {
  cancelBtnCaption: 'No',
  btnCaption: 'Yes, Disable',
  id: 'confirmDisable2FAId',
  content: 'Are you sure you want to disable 2 Factor Authentication?',
};

// get2FACreateDate :: TwoFASettings -> String
// TwoFASettings = Object
const get2FACreateDate = R.compose(
  R.unless(R.isEmpty, formatDate),
  R.propOr('', 'createdAt'),
);

export function Off2FAStatus() {
  return <Status text="2FA Off" color={error} />;
}

export function Confirmed2FAStatus({ twoFASettings }) {
  const createdAt = get2FACreateDate(twoFASettings);

  return <Status text={`Confirmed on ${createdAt}`} color={active} />;
}

Confirmed2FAStatus.propTypes = {
  twoFASettings: shape({
    createdAt: string,
  }),
};

export function Disable2FA({ twoFASettings, userId, isTwoFactorAuthRequired }) {
  const { openConfirmModal } = useModalContext();

  const { showSuccessNotification } = useNotificationState();

  const [disableUser2FactorAuth] = useMutation(disableUser2FAMutation);

  const disable2FAModal = () =>
    openConfirmModal({
      ...confirmModalProps,
      onConfirm: (closeConfirmModal) => async () => {
        await disableUser2FactorAuth({
          variables: {
            userId,
          },
        });
        showSuccessNotification(
          '2 Factor Authentication was successfully disabled',
        );
        closeConfirmModal();
      },
    });

  return (
    <>
      <Confirmed2FAStatus twoFASettings={twoFASettings} />
      {!isTwoFactorAuthRequired && (
        <Disable2FAButton onClick={disable2FAModal}>
          Disable 2FA
        </Disable2FAButton>
      )}
    </>
  );
}

Disable2FA.propTypes = {
  twoFASettings: shape({
    createdAt: string,
    isVerified: bool.isRequired,
  }),
  userId: string.isRequired,
  isTwoFactorAuthRequired: bool,
};

Disable2FA.defaultProps = {
  isTwoFactorAuthRequired: false,
};
