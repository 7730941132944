import React from 'react';
import { bool, func, number, shape, string } from 'prop-types';

import { AmountInput } from '../../../components/PaymentsFromComponents/AmountInput.js';

export function PaymentReceivedInput({
  _id,
  errors,
  submitting,
  receivedAmount,
  currentBalance,
  onAmountReceivedChange,
}) {
  const inputName = `payment-received-input-${_id}`;

  return (
    <AmountInput
      width="100%"
      name={inputName}
      value={receivedAmount}
      onChange={onAmountReceivedChange}
      disabled={submitting}
      dataTestId={inputName}
      currentBalance={currentBalance}
      error={errors.receivedAmount}
    />
  );
}

PaymentReceivedInput.defaultProps = {
  errors: { receivedAmount: '' },
};
PaymentReceivedInput.propTypes = {
  _id: string,
  errors: shape({ receivedAmount: string }),
  submitting: bool,
  currentBalance: number,
  receivedAmount: number,
  onAmountReceivedChange: func,
};
