import * as R from 'ramda';
import { useMemo } from 'react';
import { assocBy } from '@poly/utils';
import {
  useInfiniteScrollQueryWithSubscription,
  highlightMatchesInObjectCurried,
} from '@poly/client-utils';
import {
  prepareSortingWithSearchText,
  useMapConfigToTableProps,
  useTableSorting,
} from '@poly/admin-ui';

import { recurringQueries } from '../../../modules/tabs/common.js';
import { RECURRING_PROJECTS_BY_SEARCH } from '../../../modules/core/hooks/projects/queries.js';
import { commonProjectTableConfig } from '../../../modules/tables/projectsTable/ProjectsTable.js';
import { RECURRING_PROJECTS_BY_SEARCH_SUB } from '../../../modules/core/hooks/projects/subscriptions.js';
import { getProjectsQueryByEntityQuery } from './getProjectsQueryByEntityQuery.js';
import {
  getSubPropertiesElasticQuery,
  searchProjectTextPaths,
} from './useSearchSubTabsProjectsQuery.js';

// prepareMastersForTable :: String -> SearchRecurringProjectsResult -> [RecurringProject]
const prepareMastersForTable = (searchTerm) =>
  R.compose(
    R.map(highlightMatchesInObjectCurried(searchProjectTextPaths, searchTerm)),
    R.map(assocBy('projectLinkId', R.prop('projectId'))),
    R.pathOr([], ['searchRecurringProjects', 'hits']),
  );

export const useSearchSubTabsMastersQuery = ({
  status,
  entity,
  searchTerm = '',
}) => {
  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: commonProjectTableConfig,
    column: 7,
  });

  const { _id, name, queryField } = entity;

  const entityQueryField = queryField || `${name}Id`;

  const statusQuery = recurringQueries[status];

  const entityQuery = [
    {
      bool: {
        should: [
          { match: { [entityQueryField]: _id } },
          ...getSubPropertiesElasticQuery(name, _id),
        ],
      },
    },
  ];

  const searchInput = useMemo(
    () => ({
      searchTerm,
      sort: prepareSortingWithSearchText(searchTerm)(sort),
      query: getProjectsQueryByEntityQuery(entityQuery)(statusQuery),
    }),
    [sort, status, entity, searchTerm],
  );

  const { data, loading, tableProps } = useInfiniteScrollQueryWithSubscription(
    RECURRING_PROJECTS_BY_SEARCH,
    searchInput,
    {
      endpointName: 'searchRecurringProjects',
      inputName: 'searchInput',
    },
    RECURRING_PROJECTS_BY_SEARCH_SUB,
    { searchInput },
  );

  const dataTableProps = useMapConfigToTableProps(
    prepareMastersForTable(searchTerm),
    commonProjectTableConfig,
    data,
  );

  const count = R.pathOr(0, ['searchRecurringProjects', 'total'], data);

  return {
    count,
    loading,
    tableProps: {
      ...tableProps,
      ...dataTableProps,
      ...tableSortingProps,
    },
  };
};
