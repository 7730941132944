import React from 'react';
import styled from 'styled-components';
import { arrayOf, shape, string } from 'prop-types';

const ServicesList = styled.div``;

const ServiceItem = styled.span`
  &:not(:last-child) {
    margin-bottom: 5px;
    display: inline-block;
    &:after {
      content: ',\\00a0';
    }
  }
`;

export function SupplierServicesList({ services }) {
  return (
    <ServicesList>
      {services.map(({ _id, name }) => (
        <ServiceItem key={_id}>{name}</ServiceItem>
      ))}
    </ServicesList>
  );
}

SupplierServicesList.propTypes = {
  services: arrayOf(
    shape({
      _id: string,
      name: string,
    }),
  ).isRequired,
};
