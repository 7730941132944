export const NOTHING_UI_STRING = '–';
export const BODY_PORTAL_ANCHOR_ID = 'body-portal-anchor-id';

// to avoid passing . in url, which breaks redux-little-router
export const TOKEN_URL_SEPARATOR = '~';

export const ASC_SORT_ORDER = 'asc';
export const DESC_SORT_ORDER = 'desc';
export const ELASTIC_SCORE_FIELD = '_score';
export const ELASTIC_RESULT_WINDOW_MAX_SIZE = 10000;

export const SYSTEM_USER_NAME = 'Poly';
export const SYSTEM_USER_ID = '0';

export const PUBLIC_PATH = '/public';
export const TOKEN_PATH = '/token';
export const TOKEN_VIA_SMS_PATH = '/token-via-sms';
export const TEMPORAL_TOKEN_PATH = '/temporal-token';
export const SET_PASSWORD_PATH = '/set-password';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const USER_LOGOUT_PATH = '/user-logout';
export const API_PREFIX_PATH = '/api-v1-route';
export const INIT_2FA_PATH = '/init-2fa';
export const VERIFY_2FA_PATH = '/verify-2fa';
export const APP_STATUS_PATH = '/status';

export const SearchChangedResultType = {
  INSERT: 'insert',
  UPDATE: 'update',
  DELETE: 'delete',
};

export const FinancialEntityTypes = {
  CLIENTS: 'clients',
  SUPPLIERS: 'suppliers',
  EMPLOYEES: 'employees',
  OTHERS: 'others',
};

export const SpendReportFilters = {
  mainAccount: 'mainAccountSpendReportFilter',
  all: 'allSpendReportFilter',
};

export const PolyMultiLineError = {
  PREFIX: 'poly_multi_line_error:',
  SPLIT: ';\n',
  REG_EXP: /^poly_multi_line_error:/,
};

export const HREF_PLACEHOLDER = '#';

export const MAX_INT_32_VALUE = 2147483647;

export const MONEY_LIMIT = {
  upper: 9999999999,
  bottom: -9999999999,
};

export const MONEY_AS_CENTS_LIMIT = {
  upper: MONEY_LIMIT.upper * 100 + 99,
  bottom: MONEY_LIMIT.bottom * 100 - 99,
};

export const MBECertificationUrl =
  'https://www.mwbe-enterprises.com/minority-business-enterprise-mbe-certification/';

export const WBECertificationUrl =
  'https://www.mwbe-enterprises.com/women-business-enterprise-wbe-certification/';

export const veteranCertificationUrl = 'https://veterans.certify.sba.gov/';

// should match with one of the HTML file names version at admin/public/assets/docs
export const CURRENT_SUBCONTRACTOR_AGREEMENT_VERSION = '12.10.2021';
