import React from 'react';
import { useDispatch } from 'react-redux';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { UPDATE_MENTION_GROUP } from '@poly/security';
import { TextButton } from '@poly/admin-book';
import { openModal } from '@poly/admin-ui';
import { modalNames } from '../../modals/index.js';

export function EditMentionGroupButton(props) {
  const dispatch = useDispatch();

  const ifHasPermission = useHasUserAccessWithPermission(UPDATE_MENTION_GROUP);

  const onClick = () => {
    dispatch(
      openModal({
        name: modalNames.EDIT_MENTION_GROUP,
        payload: props,
      }),
    );
  };

  return ifHasPermission ? (
    <TextButton {...props} onClick={onClick}>
      Edit
    </TextButton>
  ) : null;
}

EditMentionGroupButton.displayName = 'EditMentionGroupButton';
