import * as R from 'ramda';
import { differenceInWeeks } from 'date-fns';

import { toDate } from './dates.js';

// getHousekeepingCalendarWeeks :: HousekeepingCalendar -> [Number]
// HousekeepingCalendar = [{startOfYear: Date, description: String}]
export const getHousekeepingCalendarWeeks = R.compose(
  R.map(R.converge(differenceInWeeks, [R.last, R.head])),
  R.map(R.map(toDate)),
  R.aperture(2),
  R.pluck('startOfYear'),
);
