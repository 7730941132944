import React from 'react';
import * as R from 'ramda';
import { bool, func, shape, string } from 'prop-types';

import { DragOverComp } from '../FilePicker/onDragComp.js';
import { DragAreaComponent } from '../FilePicker/dragArea.js';
import { DropzoneS, FieldBottom } from '../FilePicker/styles.js';
import { DraftEditorWithMentions } from './draftEditorWithMentions.js';
import { InputErrorMsg, InputWarningMsg } from '../Input/index.js';
import { fileErrorPropTypes } from '../FilePicker/UploadFileLogic.js';

// getEditorError :: [Errors] -> String
// Errors = {editorError: String}
const getEditorError = R.compose(R.head, R.pluck('editorError'));

// sanitizeReduxFormInputProps :: Object -> Object
const sanitizeReduxFormInputProps = R.pick([
  'placeholder',
  'loadMentionSuggestions',
  'onFocus',
  'onBlur',
]);

export function TextareaWithDropzone({
  isDragOver,
  onDrop,
  onDragEnter,
  onDragLeave,
  onFileUpload,
  onTextChange,
  name,
  hasError,
  error,
  value: { text },
  disableMentions,
  multiple,
  readOnly,
  autoFocus,
  warning,
  ...props
}) {
  const editorError = getEditorError(error);

  const hasEditorError = hasError && !!editorError;

  return (
    <>
      <DraftEditorWithMentions
        value={text}
        name={name}
        onChange={onTextChange}
        disableMentions={disableMentions}
        readOnly={readOnly}
        error={editorError}
        hasError={hasEditorError}
        autoFocus={autoFocus}
        {...sanitizeReduxFormInputProps(props)}
      />
      {warning && (
        <>
          <InputWarningMsg>{warning}</InputWarningMsg> <br />
        </>
      )}
      <DropzoneS
        data-testid="files-drop-zone"
        onDrop={onDrop}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        disableClick
        multiple={multiple}
      >
        {({ getInputProps, getRootProps }) => (
          <>
            {editorError && hasEditorError && (
              <InputErrorMsg>{editorError}</InputErrorMsg>
            )}
            <FieldBottom {...{ ...getRootProps(), isDragOver }}>
              {isDragOver ? (
                <DragOverComp />
              ) : (
                <DragAreaComponent
                  onChange={onFileUpload}
                  // we don't use ref from react-dropzone to skip upload behaviour
                  {...{ ...R.omit(['ref'], getInputProps()), multiple }}
                />
              )}
            </FieldBottom>
          </>
        )}
      </DropzoneS>
    </>
  );
}

TextareaWithDropzone.propTypes = {
  isDragOver: bool.isRequired,
  onDrop: func.isRequired,
  onDragEnter: func.isRequired,
  onDragLeave: func.isRequired,
  onFileUpload: func.isRequired,
  onTextChange: func.isRequired,
  name: string.isRequired,
  hasError: bool.isRequired,
  error: fileErrorPropTypes,
  value: shape({ text: string.isRequired }).isRequired,
  disableMentions: bool,
  multiple: bool,
  readOnly: bool,
  autoFocus: bool,
  warning: string,
};

TextareaWithDropzone.defaultProps = { multiple: true, readOnly: false };
