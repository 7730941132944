import * as R from 'ramda';
import styled from 'styled-components';
import { func, string } from 'prop-types';
import { gql, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useUploadAttachment } from '@poly/client-utils/src/files/useUploadAttachment.js';
import { InvoiceTypes, SIGN_OFF_SHEET_DOCUMENT_URL } from '@poly/constants';
import { validateFilesFunc } from '@poly/client-utils';
import { useNavigate } from '@poly/client-routing';
import {
  useOnSubmitSetStopSubmitting,
  VENDOR_INFO_TYPES,
  useModalContext,
  ESFMFullLogo,
  halfWidth,
  FileLink,
} from '@poly/admin-ui';
import {
  insertQueryParamsIntoURL,
  convertDollarsToCents,
  debounce,
} from '@poly/utils';
import {
  getThemeColor,
  FormCreator,
  Textarea,
  Loader,
  Input,
} from '@poly/admin-book';

import { routesNames } from '../../routes/constants.js';
import { commonModalLayout } from '../../modules/forms/common.js';
import { AttachDocumentField } from '../../components/AttachDocumentField.js';
import { InvoiceTotalInput } from '../../modules/forms/supplierInvoiceForm/sections.js';
import { SupplierAttachInvoiceDividerC } from './components/SupplierAttachInvoiceDivider.js';
import { SupplierAttachInvoiceFormTitle } from './components/SupplierAttachInvoiceFormTitle.js';
import { SupplierAttachInvoiceFormFooter } from './components/SupplierAttachInvoiceFormFooter.js';
import { SupplierAttachInvoiceFileComment } from './components/SupplierAttachInvoiceFileComment.js';
import { InvoiceTaxField } from '../../modules/forms/supplierInvoiceForm/components/InvoiceTaxField.js';
import { LabourCostInput } from '../../modules/forms/supplierInvoiceForm/components/LabourCostInput.js';
import { InvoiceDateField } from '../../modules/forms/supplierInvoiceForm/components/InvoiceDateField.js';
import { MaterialCostInput } from '../../modules/forms/supplierInvoiceForm/components/MaterialCostInput.js';
import { weeklyTicketValidators } from '../../sidebars/ProjectSidebar/forms/form/weeklyServiceTicketFormValidators.js';
import { isBreakdown } from '../../modules/forms/supplierInvoiceForm/supplierInvoiceFormUtils.js';
import { selectSingleFileNameWithUpload } from '../../utils/files.js';

const supplierAttachInvoiceFileFormId = 'supplierAttachInvoiceFileFormId';

const SUPPLIER_INVOICE_INFO_BY_TOKEN_QUERY = gql`
  query SUPPLIER_INVOICE_INFO_BY_TOKEN_QUERY {
    supplierInvoiceInfoByToken {
      _id
      isFoodbuy
      projectId
      description
      multiInvoiceNote
      invoiceId
      supplierName
      supplierNTE
      clientName
      propertyName
      propertyAddress
      propertyLocationState
      requiresSignOffSheet
      startDate
      endDate
      workCompletionDate
      supplierInvoicesTotal
    }
  }
`;

const UPDATE_INVOICE_MUTATION = gql`
  mutation UPDATE_INVOICE_BY_TEMPORAL_ACCESS_MUTATION(
    $input: UpdateInvoiceByTemporalAccessInput!
  ) {
    updateInvoiceByTemporalAccess(input: $input) {
      _id
    }
  }
`;

const CHECK_DUPLICATE = gql`
  query CHECK_DUPLICATE($invoiceNumber: String!) {
    supplierInvoiceNumberIsDuplicateByToken(invoiceNumber: $invoiceNumber)
  }
`;

function InvoiceNumberInput({ value, onChange, ...props }) {
  const [internalValue, setInternalValue] = useState(value);

  const { data } = useQuery(CHECK_DUPLICATE, {
    variables: { invoiceNumber: value },
    skip: !value,
  });

  const onChangeDebounced = useCallback(
    debounce(400)((invoiceNumber) => {
      onChange(invoiceNumber);
    }),
    [],
  );

  const onChangeInternal = (e) => {
    setInternalValue(e.target.value);
    onChangeDebounced(e.target.value);
  };

  const isInvoiceNumberDuplicated = useMemo(
    () => R.propOr(false, 'supplierInvoiceNumberIsDuplicateByToken', data),
    [data],
  );

  const warning = isInvoiceNumberDuplicated
    ? 'This is a duplicated invoice number'
    : null;

  return (
    <Input
      {...props}
      onChange={onChangeInternal}
      value={internalValue}
      warning={warning}
      maxLength={50}
      required
    />
  );
}

InvoiceNumberInput.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
};

const formCreatorSections = [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        label: '',
        order: 1,
        layout: { row: 1 },
        field: {
          name: 'logo',
          Component: ESFMFullLogo,
        },
      },
      {
        label: '',
        order: 2,
        layout: { row: 2 },
        field: {
          name: 'title',
          Component: SupplierAttachInvoiceFormTitle,
        },
      },
      {
        label: '',
        order: 3,
        layout: { row: 3, padding: '0 0 20px 0' },
        field: {
          name: 'divider',
          Component: SupplierAttachInvoiceDividerC,
        },
      },
      {
        label: 'Labor',
        order: 5,
        layout: { row: 4, width: halfWidth, position: 'relative' },
        field: {
          name: 'labourCost',
          withFormData: true,
          Component: LabourCostInput,
        },
        required: true,
        renderIf: isBreakdown,
      },
      {
        label: 'Material',
        order: 6,
        layout: { row: 4, width: halfWidth },
        field: {
          name: 'materialCost',
          withFormData: true,
          Component: MaterialCostInput,
        },
        required: true,
        renderIf: isBreakdown,
      },
      {
        label: 'Tax',
        order: 7,
        field: {
          name: 'taxAmount',
          withFormData: true,
          Component: InvoiceTaxField,
        },
        layout: { row: 5, width: halfWidth },
        renderIf: isBreakdown,
      },
      {
        order: 8,
        layout: { row: 5, width: halfWidth },
        required: true,
        validators: [[R.gt(R.__, 0), 'Total Invoice is required']],
        field: {
          name: 'invoiceTotal',
          withFormData: true,
          Component: (props) => (
            <InvoiceTotalInput
              {...props}
              allowNegative={false}
              getSupplierNTE={R.compose(
                convertDollarsToCents,
                R.prop('supplierNTE'),
              )}
              skipInvoicesQuery
            />
          ),
        },
      },
      {
        order: 4,
        required: true,
        label: 'Invoice Number',
        layout: { row: 6, width: halfWidth },
        field: {
          name: 'invoiceNumber',
          Component: InvoiceNumberInput,
        },
        validators: [[R.identity, 'Invoice is required']],
      },
      {
        order: 5,
        layout: { row: 6, width: halfWidth },
        required: true,
        field: {
          withFormData: true,
          name: 'invoiceDate',
          Component: (props) => (
            <InvoiceDateField disableOverflowChange {...props} disabled />
          ),
        },
      },
      {
        label: 'Describe Work Performed',
        order: 5,
        layout: { row: 7 },
        required: true,
        validators: [[R.identity, 'Work description is required']],
        field: {
          name: 'workDescription',
          Component: Textarea,
          additionalProps: { rows: 5 },
        },
      },
      {
        label: '',
        order: 7,
        layout: { row: 8, padding: '0' },
        required: true,
        validators: weeklyTicketValidators,
        validateFunction: validateFilesFunc,
        field: {
          name: 'attachment',
          Component: AttachDocumentField,
        },
      },
      {
        label: '',
        order: 9,
        layout: { row: 9, padding: '0' },
        field: {
          name: 'attachmentComment',
          Component: SupplierAttachInvoiceFileComment,
        },
      },
    ],
  },
];

// getFormProps :: { supplierInvoiceInfoByToken: SupplierInvoiceInfoByTokenResult }
// -> SupplierInvoiceInfoByTokenResult
const getFormProps = R.prop('supplierInvoiceInfoByToken');

const SignOffSheetMessageContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-self: center;
  font-size: 12px;
  color: ${getThemeColor(['accent'])};
`;

function SignOffSheetMessage() {
  return (
    <SignOffSheetMessageContainer>
      Ensure your &nbsp;
      <FileLink url={SIGN_OFF_SHEET_DOCUMENT_URL} fileName="Sign-off Sheet" />
      &nbsp; and invoice are attached as one file
    </SignOffSheetMessageContainer>
  );
}

// getInitialValues :: SupplierInvoiceInfoByTokenResult -> FormValues
const getInitialValues = R.applySpec({
  attachment: R.always([]),
  supplierNTE: R.prop('supplierNTE'),
  isFoodbuy: R.propOr(false, 'isFoodbuy'),
  type: R.propOr(InvoiceTypes.breakdown, 'type'),
  invoiceDate: R.always(new Date().toISOString()),
  propertyLocationState: R.propOr('', 'propertyLocationState'),
  supplierInvoicesTotal: R.compose(
    R.defaultTo(0),
    R.prop('supplierInvoicesTotal'),
  ),
});

export function SupplierAttachInvoiceFileForm() {
  const { openModalForm, closeModal } = useModalContext();

  const [updateInvoice] = useMutation(UPDATE_INVOICE_MUTATION);
  const navigate = useNavigate();

  const { data, loading } = useQuery(SUPPLIER_INVOICE_INFO_BY_TOKEN_QUERY, {
    fetchPolicy: 'network-only',
  });

  const formProps = getFormProps(data);

  const uploadFile = useUploadAttachment();

  const onSubmitHandler = async (values) => {
    let uploadedFileId = null;

    const attachment = selectSingleFileNameWithUpload(values.attachment);

    if (attachment) {
      uploadedFileId = await uploadFile(attachment.upload, attachment.fileName);
    }

    const input = {
      breakdown: {
        invoiceDate: values.invoiceDate,
        invoiceNumber: values.invoiceNumber,
        total: values.invoiceTotal,
        labourCost: values.labourCost,
        materialCost: values.materialCost,
        taxAmount: values.taxAmount,
        ...(uploadedFileId ? { uploadedFileId } : {}),
      },
      workDescription: values.workDescription,
    };

    try {
      await updateInvoice({
        variables: {
          input,
        },
      });

      navigate(
        insertQueryParamsIntoURL(
          { type: VENDOR_INFO_TYPES.DOCUMENTS_SUBMITTED },
          routesNames.VENDOR_INFO,
        ),
      );

      closeModal(supplierAttachInvoiceFileFormId);
      return true;
    } catch {
      return false;
    }
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    supplierAttachInvoiceFileFormId,
    onSubmitHandler,
  );

  useEffect(() => {
    if (!loading && formProps) {
      openModalForm({
        id: supplierAttachInvoiceFileFormId,
        title: '',
        formId: supplierAttachInvoiceFileFormId,
        btnCaption: 'Submit',
        width: '600px',
        additionalFooter: <SupplierAttachInvoiceFormFooter {...formProps} />,
        submitMessage: formProps.requiresSignOffSheet && (
          <SignOffSheetMessage />
        ),
        content: (
          <FormCreator
            id={supplierAttachInvoiceFileFormId}
            formId={supplierAttachInvoiceFileFormId}
            sections={formCreatorSections}
            layout={commonModalLayout}
            initialValues={getInitialValues(formProps)}
            onSubmit={onSubmit}
          />
        ),
      });
    }
  }, [loading]);

  return <div>{loading ? <Loader /> : null}</div>;
}
