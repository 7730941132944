import React from 'react';
import { string, number, bool, shape } from 'prop-types';
import { entities } from '@poly/admin-ui';

import { ToolbarButton } from '../../components/commonSidebarComponents.js';
import { ProjectOccurrence } from '../../../modules/core/constants/projects.js';
import { SidebarMastersTab } from '../../components/commonTabs/SidebarMastersTab.js';
import { generateProjectInvoiceDescription } from '../../ProjectSidebar/forms/form/components/utils.js';
import { useAddProjectSidebar } from '../../ProjectSidebar/forms/add/useAddProjectSidebar.js';
import { isAllowProjectCreationOnAdminByClient } from '../../ClientSidebar/helpers.js';
import {
  isInactivePropertyOrClient,
  getProjectTypeByProperty,
} from '../propertySidebarUtils.js';

function AddPropertyMasterProjectButton({ property }) {
  const openAddProjectSidebar = useAddProjectSidebar(
    false,
    entities.PROPERTY,
    property._id,
  );

  if (!isAllowProjectCreationOnAdminByClient(property?.client)) {
    return null;
  }

  const onAddProject = () =>
    openAddProjectSidebar({
      propertyId: property._id,
      type: ProjectOccurrence.RECURRING_OCCURRENCE,
      projectType: getProjectTypeByProperty(property),
      invoiceDescription: generateProjectInvoiceDescription({
        property,
      }),
    });

  return (
    <ToolbarButton
      disabled={isInactivePropertyOrClient(property)}
      onClick={onAddProject}
    >
      Add Project
    </ToolbarButton>
  );
}

AddPropertyMasterProjectButton.propTypes = {
  property: shape({ _id: string.isRequired }).isRequired,
};

export function PropertySidebarMastersTab({ isCard, property, doubleWidth }) {
  return (
    <SidebarMastersTab
      isCard={isCard}
      tabWidth={doubleWidth}
      entity={{ _id: property._id, name: entities.PROPERTY }}
      toolBar={<AddPropertyMasterProjectButton property={property} />}
    />
  );
}

PropertySidebarMastersTab.propTypes = {
  isCard: bool,
  property: shape({ _id: string.isRequired }).isRequired,
  doubleWidth: number.isRequired,
};
