import React from 'react';
import styled from 'styled-components';
import { UserSelect } from '@poly/admin-ui';
import { startOfYear } from 'date-fns';
import { alwaysNewDate } from '@poly/utils';
import { arrayOf, bool, func } from 'prop-types';
import { DateRangePicker, ToolBarBtnDivider } from '@poly/admin-book';

import { ProjectSelect } from '../../components/ProjectsSelect.js';
import { FiltersContainer } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { ConfirmSatisfactionPrintPdfBtn } from './ConfirmSatisfactionPrintPdfBtn.js';
import { ConfirmSatisfactionExportXLSBtn } from './ConfirmSatisfactionExportXLSBtn.js';
import { confirmSatisfactionProjectPropTypes } from './prop-types.js';
import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 370px;
`;

const confirmSatisfactionHeaderConfig = [
  { name: 'projectId' },
  { name: 'contactId' },
  {
    name: 'workCompletionDate',
    nestedFields: ['startDate', 'endDate'],
    defaultValue: {
      startDate: startOfYear(alwaysNewDate()).toDateString(),
      endDate: alwaysNewDate().toDateString(),
    },
  },
];

export function ConfirmSatisfactionReportHeader({
  setFilters,
  loading,
  projects,
}) {
  const { searchFilters, handlers, onReset } = useSearchFilters(
    confirmSatisfactionHeaderConfig,
  );

  const onResetFilters = () => {
    onReset();
    setFilters(null);
  };

  const onSearch = () => setFilters(searchFilters);

  return (
    <SearchPageHeaderContainer title="Confirm Satisfaction Report">
      <FiltersContainer>
        <FlexSpaceBetween>
          <SearchHeaderColumn
            title="Project Number"
            titleWidth="130px"
            filterWidth="250px"
          >
            <ProjectSelect
              width="100%"
              name="projectId"
              value={searchFilters.projectId}
              onChange={handlers.projectId}
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn
            title="Date"
            titleWidth="80px"
            filterWidth="250px"
          >
            <DateRangePicker
              width="100%"
              name="workCompletionDate"
              value={searchFilters.workCompletionDate}
              onChange={handlers.workCompletionDate}
            />
          </SearchHeaderColumn>
          <ButtonsWrapper>
            <ConfirmSatisfactionPrintPdfBtn projects={projects} />
            <ToolBarBtnDivider />
            <ConfirmSatisfactionExportXLSBtn projects={projects} />
          </ButtonsWrapper>
        </FlexSpaceBetween>
        <FlexSpaceBetween>
          <SearchHeaderColumn
            title="Requestor"
            titleWidth="130px"
            filterWidth="250px"
          >
            <UserSelect
              width="100%"
              name="contactId"
              value={searchFilters.contactId}
              onChange={handlers.contactId}
            />
          </SearchHeaderColumn>
          <ButtonsWrapper>
            <SearchHeaderButton
              size="small"
              styleType="basicPrimary"
              onClick={onResetFilters}
            >
              Reset
            </SearchHeaderButton>
            <SearchHeaderButton
              size="small"
              loader={loading}
              onClick={onSearch}
            >
              Search
            </SearchHeaderButton>
          </ButtonsWrapper>
        </FlexSpaceBetween>
      </FiltersContainer>
    </SearchPageHeaderContainer>
  );
}

ConfirmSatisfactionReportHeader.propTypes = {
  loading: bool,
  setFilters: func,
  projects: arrayOf(confirmSatisfactionProjectPropTypes),
};
