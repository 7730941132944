import * as R from 'ramda';

// addressPropSafe :: k -> {[k]: String} -> String
const addressPropSafe = R.propOr('-');

// addressPropSafe :: [k] -> Any -> String
const addressPathSafe = R.pathOr('-');

// getDocumentAddressLines :: Doc -> [String]
//   Doc = {name: String, address: Address, addressTwo: String}
export const getDocumentAddressLines = R.compose(
  R.flatten,
  R.juxt([
    R.compose(
      R.converge(R.unapply(R.join(' ')), [
        addressPropSafe('street_number'),
        addressPropSafe('route'),
      ]),
      addressPathSafe(['address', 'address_parts']),
    ),
    R.compose(
      R.when(R.either(R.isEmpty, R.isNil), R.always([])),
      R.propOr('', 'addressTwo'),
    ),
    R.compose(
      R.converge(R.unapply(R.join(' ')), [
        R.compose(R.concat(R.__, ','), addressPropSafe('locality')),
        addressPropSafe('administrative_area_level_1'),
        addressPropSafe('postal_code'),
      ]),
      addressPathSafe(['address', 'address_parts']),
    ),
  ]),
);

// getDocumentNameAndAddressLines :: Doc -> [String]
export const getDocumentNameAndAddressLines = R.compose(
  R.flatten,
  R.juxt([addressPropSafe('name'), getDocumentAddressLines]),
);
