import * as R from 'ramda';
import { applySpecWithFields } from './objects.js';

// isNotEndWithNewLineLastOps :: String -> Object -> Boolean
const isNotEndWithNewLineLastOps = (field) =>
  R.compose(
    R.ifElse(R.is(String), R.complement(R.endsWith('\n')), R.F),
    R.prop('insert'),
    R.last,
    R.pathOr([], [field, 'ops']),
  );

// addNewLineToLastOps :: String -> Object -> [OpsItem]
// OpsItem = Object
const addNewLineToLastOps = (field) =>
  R.compose(
    R.unnest,
    R.juxt([
      R.init,
      R.compose(
        applySpecWithFields({
          insert: R.compose(R.concat(R.__, '\n'), R.propOr('', 'insert')),
        }),
        R.last,
      ),
    ]),
    R.pathOr([], [field, 'ops']),
  );

/**
 * addNewLineToLastOpsToForm :: String -> FormObject
 * FormObject = Object
 *
 * We need to add \n at the last ops item if it doesn't have this.
 * It fixes close form message for "Editor" because "react-quill" add \n by default
 * and if the last item dont have \n we have a close form message
 */
export const addNewLineToLastOpsToForm = (field) =>
  R.when(
    isNotEndWithNewLineLastOps(field),
    R.compose(
      applySpecWithFields({
        [field]: {
          ops: addNewLineToLastOps(field),
        },
      }),
    ),
  );
