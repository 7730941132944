export const BillingProfileConfigurationSidebarId =
  'billing-profile-configuration-sidebar-id';

export const BillingProfileConfigFields = [
  'pos',
  'type',
  'nonPO',
  'costTypes',
  'propertyIds',
  'consolidateBy',
  'isTransparent',
  'serviceTypeIds',
  'costCenterNames',
  'attachBackupInvoices',
  'attachSupplierInvoices',
  'consolidation',
  'batch',
  'apReportingEnabled',
  'coverPageAndDetailsReportingEnabled',
  'isPassThroughFeeEnabled',
  'isFinancialCodingEnabled',
  'adminPOIds',
];
