import React from 'react';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { number, func, bool, string } from 'prop-types';

import { getThemeColor, getThemeFont } from '../utils.js';
import { Icon } from '../Icon/index.js';
import { defaultTheme } from '../ThemeProvider/index.js';

export const PageSelectContainer = styled.div`
  display: flex;
  height: 25px;
  width: fit-content;
  padding: 0 10px;
`;

const activeItem = css`
  color: ${getThemeColor(['primary'])};
  border-bottom: 2px solid ${getThemeColor(['accent'])};
`;

const PageNum = styled.div`
  height: 100%;
  margin: 0 10px;
  padding: 0 2px;
  font-size: 15px;
  line-height: 25px;
  cursor: pointer;
  color: ${getThemeColor(['primaryLighter4'])};
  font-weight: ${getThemeFont(['regular'])};
  ${R.when(R.prop('isActive'), R.always(activeItem))};
`;

export const Dots = styled(PageNum)`
  cursor: default;
`;

const TransparentBtn = styled.button`
  background: none;
  border: 0;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const {
  colors: { mid },
} = defaultTheme;

export function PageItem({ val, onChange, isLast, currentPage }) {
  return (
    <PageNum
      isLast={isLast}
      isActive={val === currentPage}
      onClick={() => onChange(val)}
    >
      {val}
    </PageNum>
  );
}

PageItem.propTypes = {
  val: number.isRequired,
  onChange: func.isRequired,
  isLast: bool,
  currentPage: number.isRequired,
};

PageItem.defaultProps = {
  isLast: false,
};

export function ControlBtn({ onClick, icon, disabled, ...props }) {
  return (
    <TransparentBtn {...{ disabled, onClick }}>
      <Icon color={mid} name={icon} size={12} {...props} />
    </TransparentBtn>
  );
}

ControlBtn.propTypes = {
  onClick: func.isRequired,
  icon: string.isRequired,
  disabled: bool.isRequired,
};
