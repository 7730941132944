import * as R from 'ramda';
import { formatFromToDateTitle } from '@poly/client-utils';
import { NOTHING_UI_STRING } from '@poly/constants';
import {
  ExcelExportCellType,
  convertCentsToDollars,
  createExcelExportCell,
} from '@poly/utils';
import { prepareSupplierQuoteDiscrepanciesFooterData } from '../QuoteDiscrepancies/helpers.js';
import {
  getQuoteDiscrepanciesHeaderRow,
  getQuoteDiscrepanciesTotalRow,
} from '../QuoteDiscrepancies/quote-discrepancies-xls-config.js';

// getExportRow :: SupplierQuoteDiscrepanciesReportRow -> [ExcelExportDataCell]
const getExportRow = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 2),
    ...R.repeat(ExcelExportCellType.defaultCurrency, 2),
    ExcelExportCellType.default,
    ...R.repeat(ExcelExportCellType.defaultCurrency, 2),
  ]),
  R.juxt([
    R.pathOr(NOTHING_UI_STRING, ['project', 'projectId']),
    R.propOr(NOTHING_UI_STRING, 'invoiceNumber'),
    R.compose(convertCentsToDollars, R.propOr(0, 'supplierNTE')),
    R.compose(convertCentsToDollars, R.propOr(0, 'total')),
    R.pathOr(NOTHING_UI_STRING, ['supplier', 'company', 'name']),
    R.propOr(0, 'discrepancyPercentage'),
    R.compose(convertCentsToDollars, R.propOr(0, 'discrepancy')),
  ]),
);

export const getSupplierQuoteDiscrepanciesXlsConfig = ({
  tableRows,
  startDate,
  endDate,
}) => {
  const { averageDiscrepancy, totalDiscrepancy } =
    prepareSupplierQuoteDiscrepanciesFooterData(tableRows);

  return {
    columnWidths: [15, 15, 15, 15, 25, 15, 15],
    rows: [
      [
        {
          value: 'Quote Discrepancies by Supplier Reports',
          cellType: ExcelExportCellType.title,
        },
      ],
      [
        {
          value: formatFromToDateTitle(startDate, endDate),
          cellType: ExcelExportCellType.subTitle,
        },
      ],
      getQuoteDiscrepanciesHeaderRow([
        'Project #',
        'Supplier Invoice #',
        'Supplier NTE',
        'Supplier Invoice',
        'Supplier',
        'Discrepancy in %',
        'Discrepancy',
      ]),
      ...tableRows.map((tableRow) => getExportRow(tableRow)),
      getQuoteDiscrepanciesTotalRow({ averageDiscrepancy, totalDiscrepancy }),
    ],
  };
};
