import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { PRINT_TYPES } from '@poly/constants';

import { TablePrintBtn } from '../../core/tableToolbar/TablePrintPDFBtn.js';
import { ITEMS_LIMIT } from '../../core/constants/print.js';
import { useTimeSheetReport } from '../../core/hooks/timesheets/index.js';

// getWarningModal :: Array -> Boolean
const getWarningModal = R.pipe(
  R.defaultTo([]),
  R.propSatisfies(R.gt(R.__, ITEMS_LIMIT), ['length']),
);

export function TimeSheetReportPrintPDFBtn() {
  const timeSheetReport = useSelector((state) => state.timeSheetReport);
  const { filteredConfig } = timeSheetReport;

  const input = R.when(
    R.propSatisfies(R.isEmpty, 'ownerId'),
    R.dissoc('ownerId'),
  )(filteredConfig);

  const { data } = useTimeSheetReport({ input });

  const timesheetsReport = data?.timesheetsReport;

  const showWarningModal = getWarningModal(timesheetsReport);

  return (
    <TablePrintBtn
      data={timesheetsReport}
      style={{ marginLeft: 30 }}
      showWarningModal={showWarningModal}
      printType={PRINT_TYPES.TIME_SHEET_REPORT}
      metaData={{ title: 'Time Sheet Report' }}
    />
  );
}
