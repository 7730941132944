import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { shape, string, bool } from 'prop-types';
import { SidebarRow, employeeStatusColors } from '@poly/admin-ui';
import { EntityStatus, LinkButton } from '@poly/admin-book';
import { UserEmployeeInfoStatus } from '@poly/constants';

import {
  SidebarLabel,
  BlockWithLabel,
  SectionWrapper,
  headerTextProps,
} from '../components/commonSidebarComponents.js';
import { SidebarIDs } from '../constants.js';
import { userTabsSidebarId } from './constants.js';
import { OpenFullInfoSidebarButton } from '../components/OpenFullInfoSidebarButton.js';
import { getSectionText } from '../components/commonSidebarSectionFormatters.js';
import { useOpenUserTabsSidebar } from './tabs/useOpenUserTabsSidebar.js';
import { useEditUserSidebar } from './forms/edit/useEditUserSidebar.js';
import { getEntityAddressPreview } from '../utils/address.js';

const EntityStatusS = styled(EntityStatus)`
  > div:first-child {
    margin-left: 0;
  }
`;

export function UserSidebarHeader({ user }) {
  const openEditUserForm = useEditUserSidebar();
  const openUserTabsSidebar = useOpenUserTabsSidebar();

  const {
    profile: { fullName },
    employeeInfo,
    isContractor,
  } = user;

  const userStatus = employeeInfo?.status || UserEmployeeInfoStatus.INACTIVE;

  return (
    <SectionWrapper>
      <SidebarRow justify align>
        <SidebarLabel margin={10}>{fullName}</SidebarLabel>
        <OpenFullInfoSidebarButton
          sidebarId={SidebarIDs.user}
          fullSidebarId={userTabsSidebarId}
          openSidebarHandler={() => openUserTabsSidebar(user)}
        />
      </SidebarRow>
      <SidebarRow justify>
        <EntityStatusS
          title={isContractor ? 'Contractor' : 'Employee'}
          status={{
            text: R.toLower(userStatus),
            color: employeeStatusColors[userStatus],
          }}
        />
        <LinkButton onClick={() => openEditUserForm(user)}>Edit</LinkButton>
      </SidebarRow>
      <SidebarRow>
        <BlockWithLabel
          margin="0"
          id="address"
          label="Address"
          Component={getSectionText(
            getEntityAddressPreview(user.profile),
            headerTextProps,
          )}
        />
      </SidebarRow>
    </SectionWrapper>
  );
}

UserSidebarHeader.propTypes = {
  user: shape({
    isContractor: bool,
    employeeInfo: shape({ status: string }),
    profile: shape({ fullName: string.isRequired }).isRequired,
  }),
};
