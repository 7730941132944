import { shape } from 'prop-types';
import React from 'react';
import { NOTHING_UI_STRING } from '@poly/constants';
import { constantCase, noCase } from 'change-case';
import * as R from 'ramda';
import {
  FixedWidthColumn,
  RightAlignedFlexColumn,
  SearchResultItemContainer,
} from './common/wrappers.js';
import {
  SearchResultItemDivider,
  SearchResultItemLinked,
  SearchResultItemText,
  SearchResultItemTitle,
  StatusS,
} from './common/components.js';
import { clientNamePath, propertyNamePath } from './common/selectors.js';
import { projectStatusesColors } from '../../../../constants/projects.js';
import { highlightedTextPropType } from './common/propTypes.js';

export function ProjectsSearchResult({
  projectId,
  description,
  status = '',
  ...props
}) {
  const propertyId = R.path(['property', '_id'], props);
  return (
    <SearchResultItemContainer>
      <FixedWidthColumn width="110px">
        <SearchResultItemLinked>{projectId}</SearchResultItemLinked>
      </FixedWidthColumn>
      <RightAlignedFlexColumn>
        <SearchResultItemText>{description}</SearchResultItemText>
        <SearchResultItemDivider />
        <SearchResultItemTitle>
          {clientNamePath(props)}
          {propertyId && (
            <>
              {' '}
              {NOTHING_UI_STRING} {propertyNamePath(props)}
            </>
          )}
        </SearchResultItemTitle>
      </RightAlignedFlexColumn>
      <StatusS
        text={noCase(status)}
        color={
          projectStatusesColors[status] ||
          projectStatusesColors[constantCase(status)]
        }
      />
    </SearchResultItemContainer>
  );
}

ProjectsSearchResult.propTypes = {
  projectId: highlightedTextPropType.isRequired,
  client: shape({
    name: highlightedTextPropType.isRequired,
  }).isRequired,
  property: shape({
    name: highlightedTextPropType.isRequired,
  }),
  description: highlightedTextPropType.isRequired,
  status: highlightedTextPropType.isRequired,
};
