import * as R from 'ramda';
import styled from 'styled-components';
import React, { useContext } from 'react';
import { applyProp } from '@poly/utils';
import { arrayOf, bool } from 'prop-types';
import { NOTHING_UI_STRING } from '@poly/constants';
import { formatDateOrNothingUI } from '@poly/client-utils';
import { useMapConfigToTableProps } from '@poly/admin-ui';
import { Loader, Table, WindowedTable } from '@poly/admin-book';

import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import { WhiteBodyWrapper } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { EmptyListMessage } from '../../components/EmptyListMessage.js';
import { PageWithSearchHeaderContext } from '../../components/PageWithSearchHeader.js';
import { confirmSatisfactionProjectPropTypes } from './prop-types.js';

const WhiteBodyWrapperS = styled(WhiteBodyWrapper)`
  margin: 0 auto;
  max-width: 1300px;
`;

const EmptyListMessageS = styled(EmptyListMessage)`
  margin-top: 40px;
`;

const WindowedTableS = styled(WindowedTable)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 100px;
  }
  th:nth-child(3),
  td:nth-child(3) {
    width: 150px;
  }
  th:nth-child(4),
  td:nth-child(4) {
    width: 100px;
  }
`;

const PrintTableS = styled(Table)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 100px;
  }
  th:nth-child(3),
  td:nth-child(3) {
    width: 150px;
  }
  th:nth-child(4),
  td:nth-child(4) {
    width: 100px;
  }
`;

const getTableConfig = (isPrintPDF) => [
  ['Project #', R.compose(ProjectLink, R.mergeRight({ isPrintPDF }))],
  ['Project description', R.propOr(NOTHING_UI_STRING, 'description')],
  [
    'Requestor',
    R.pathOr(NOTHING_UI_STRING, ['contact', 'profile', 'fullName']),
  ],
  ['Date completed', applyProp(formatDateOrNothingUI)('workCompletionDate')],
];

export function ConfirmSatisfactionReportTableContainer({
  loading,
  projects,
  isPrintPDF,
  ...restTableProps
}) {
  const { contentHeight } = useContext(PageWithSearchHeaderContext);

  const TableComponent = isPrintPDF ? PrintTableS : WindowedTableS;

  const tableProps = useMapConfigToTableProps(
    R.identity,
    getTableConfig(isPrintPDF),
    projects,
  );

  if (loading) {
    return <Loader />;
  }

  if (R.isEmpty(projects)) {
    return <EmptyListMessageS />;
  }

  return (
    <WhiteBodyWrapperS height={contentHeight}>
      <TableComponent {...tableProps} {...restTableProps} />
    </WhiteBodyWrapperS>
  );
}

ConfirmSatisfactionReportTableContainer.propTypes = {
  loading: bool,
  isPrintPDF: bool,
  projects: arrayOf(confirmSatisfactionProjectPropTypes),
};
