import { SidebarRow } from '@poly/admin-ui';
import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { getThemeColor, Loader } from '@poly/admin-book';
import styled from 'styled-components';
import { bool, number, string } from 'prop-types';
import React from 'react';
import {
  DoubleTabsWrapper,
  SectionLabel,
} from '../../../components/commonSidebarComponents.js';
import { PropertyLocationTree } from './PropertyLocationTree.js';

const LocationHierarchyTitle = styled(SidebarRow)`
  border-bottom: 1px solid ${getThemeColor(['midLight'])};
  padding-bottom: 15px;
`;

const propertyLocationHierarchyQuery = gql`
  query propertyLocationHierarchyQuery($propertyId: ID!) {
    property(id: $propertyId) {
      _id
      locationHierarchy {
        _id
        nodes {
          id
          parentId
          name
        }
      }
    }
  }
`;

// getLocationHierarchyFromQueryResult :: Object -> PropertyLocationHierarchy
const getLocationHierarchyFromQueryResult = R.path([
  'property',
  'locationHierarchy',
]);

export function PropertyLocationHierarchyTab({
  doubleWidth,
  propertyId,
  isCard,
}) {
  const { data, loading, refetch } = useQuery(propertyLocationHierarchyQuery, {
    variables: { propertyId },
    fetchPolicy: 'network-only',
  });
  const locationHierarchy = getLocationHierarchyFromQueryResult(data);

  if (loading) {
    return <Loader />;
  }
  return (
    <DoubleTabsWrapper width={doubleWidth} isCard={isCard}>
      <LocationHierarchyTitle>
        <SectionLabel>Property Location Hierarchy</SectionLabel>
      </LocationHierarchyTitle>
      <PropertyLocationTree
        locationHierarchy={locationHierarchy}
        propertyId={propertyId}
        refetch={refetch}
      />
    </DoubleTabsWrapper>
  );
}

PropertyLocationHierarchyTab.propTypes = {
  doubleWidth: number.isRequired,
  isCard: bool.isRequired,
  propertyId: string.isRequired,
};
