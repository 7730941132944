import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import { arrayOf, shape, string, bool } from 'prop-types';
import { InputHTML5 } from '@poly/admin-book';
import { Link } from '@poly/client-routing';
import styled from 'styled-components';

const Wrapper = styled.div`
  flex-direction: column;
  margin-top: 10px;
`;

const LinkSWrapper = styled.div`
  margin-top: 5px;
  margin-left: 10px;
`;

const SpanWrapper = styled.div`
  margin-top: 5px;
  font-size: 14px;
  line-height: 18px;
  color: rgba(30, 36, 44, 0.6);
  font-weight: 400;
`;

const LinkS = styled(Link)`
  font-size: 14px;
`;

// prepareValue :: String -> String
const prepareValue = R.compose(
  R.when(
    R.is(String),
    R.compose(R.join(' '), R.reject(R.isEmpty), R.split(' ')),
  ),
  R.trim,
);

// isEnteredNameDuplicated :: (String, [{ name: String }]) -> Boolean
const isEnteredNameDuplicated = (value, items) => {
  const trimmedValue = prepareValue(value);
  const foundDuplicatedItem = items.find((item) => item.name === trimmedValue);
  return !!foundDuplicatedItem;
};

export function SimilarNamesInputComp({
  showDuplicationError = false,
  items = [],
}) {
  return (
    !showDuplicationError &&
    items.length > 0 && (
      <>
        <SpanWrapper>
          <span>Similar names:</span>
        </SpanWrapper>
        {items.map(({ url, name }) => (
          <LinkSWrapper key={name}>
            <LinkS href={url} target="_blank">
              {name}
            </LinkS>
          </LinkSWrapper>
        ))}
      </>
    )
  );
}

SimilarNamesInputComp.propTypes = {
  showDuplicationError: bool,
  items: arrayOf(
    shape({
      url: string,
      name: string.isRequired,
    }),
  ),
};

export function InputWithSimilarItemsComp({
  items,
  error,
  value,
  enabledSimilarNamesInput = true,
  ...props
}) {
  const [showDuplicationError, setShowDuplicationError] = useState(false);

  useEffect(() => {
    if (value) {
      const isDuplicated = isEnteredNameDuplicated(value, items);
      setShowDuplicationError(isDuplicated);
    }
  }, [items, value]);

  const duplicationError =
    showDuplicationError && 'Entered name already exists';

  return (
    <Wrapper>
      <InputHTML5
        changeOnBlur
        value={value}
        error={error || duplicationError}
        {...props}
      />
      {enabledSimilarNamesInput ? (
        <SimilarNamesInputComp
          showDuplicationError={showDuplicationError}
          items={items}
        />
      ) : null}
    </Wrapper>
  );
}

InputWithSimilarItemsComp.propTypes = {
  items: arrayOf(
    shape({
      url: string,
      name: string.isRequired,
    }),
  ),
  value: string,
  error: string,
  enabledSimilarNamesInput: bool,
};
