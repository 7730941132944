import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import {
  node,
  func,
  arrayOf,
  string,
  shape,
  number,
  oneOfType,
} from 'prop-types';
import {
  NOTHING_UI_STRING,
  ClientInvoiceStatuses,
  ReportOnlyClientInvoiceStatuses,
} from '@poly/constants';
import { useMapConfigToTableProps, useModalContext } from '@poly/admin-ui';
import {
  propEqLegacy,
  applyProp,
  formatDate,
  centsToDollarsWithFormat,
} from '@poly/utils';
import {
  getThemeColor,
  defaultTheme,
  ModalActions,
  LinkButton,
  Button,
  Table,
  Text,
} from '@poly/admin-book';

import { ClientInvoicePDFLink } from '../../../components/ClientInvoicePDFLink.js';

const CloseModalBtn = styled(Button).attrs(() => ({
  size: 'small',
  styleType: 'basicSecondary',
  type: 'button',
  children: 'Close',
}))``;

const InvoiceStatusComp = styled.span`
  color: ${({ isVoided }) => isVoided && getThemeColor(['accent'])};
`;

const ClientInvoiceDetailsTableS = styled(Table)`
  tr {
    margin: 0;
    width: auto;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 100px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 150px;
  }

  th:nth-child(3),
  td:nth-child(3),
  th:nth-child(4),
  td:nth-child(4) {
    min-width: 90px;
  }

  th:nth-child(5),
  td:nth-child(5) {
    min-width: 40px;
    text-align: right;
  }
`;

const clientInvoiceStatusNamesMap = {
  [ClientInvoiceStatuses.PAID]: 'Paid',
  [ClientInvoiceStatuses.VOIDED]: 'Voided',
  [ClientInvoiceStatuses.PENDING]: 'Invoiced',
  [ClientInvoiceStatuses.WRITTEN_OFF]: 'Written-off',
  [ReportOnlyClientInvoiceStatuses.VOIDED]: 'Voided',
  [ClientInvoiceStatuses.PARTIALLY_PAID]: 'Partially Paid',
  [ReportOnlyClientInvoiceStatuses.REPORT_ONLY]: 'Report Only',
};

// getReadableClientInvoiceStatus :: String -> String
const getReadableClientInvoiceStatus = R.prop(
  R.__,
  clientInvoiceStatusNamesMap,
);

// getFormattedPaidDateByInvoice :: ClientInvoice -> String
const getFormattedPaidDateByInvoice = R.compose(
  R.ifElse(R.is(String), formatDate, R.always(NOTHING_UI_STRING)),
  R.prop('paidAt'),
);

// isInvoiceStatusVoided :: String -> Boolean
const isInvoiceStatusVoided = R.either(
  R.equals(ClientInvoiceStatuses.VOIDED),
  R.equals(ReportOnlyClientInvoiceStatuses.VOIDED),
);

function InvoiceStatus({ status }) {
  if (!status) {
    return NOTHING_UI_STRING;
  }

  const isVoided = isInvoiceStatusVoided(status);
  const isWrittenOff = status === ClientInvoiceStatuses.WRITTEN_OFF;

  return (
    <InvoiceStatusComp isVoided={isVoided || isWrittenOff}>
      {getReadableClientInvoiceStatus(status)}
    </InvoiceStatusComp>
  );
}

InvoiceStatus.propTypes = { status: string };

const clientInvoiceDetailsTableConfig = [
  ['Inv #', ClientInvoicePDFLink],
  ['Total', applyProp(centsToDollarsWithFormat)('amount')],
  ['Inv Date', applyProp(formatDate)('invoiceDate')],
  ['Paid Date', getFormattedPaidDateByInvoice],
  ['Status', InvoiceStatus],
];

function ClientInvoiceDetailsModalContent({ clientInvoices }) {
  const { rows, headers, columns } = useMapConfigToTableProps(
    R.identity,
    clientInvoiceDetailsTableConfig,
    clientInvoices,
  );

  return (
    <ClientInvoiceDetailsTableS
      headers={headers}
      rows={rows}
      columns={columns}
    />
  );
}

ClientInvoiceDetailsModalContent.propTypes = {
  clientInvoices: arrayOf(
    shape({
      _id: string.isRequired,
      number: oneOfType([number, string]),
      amount: number.isRequired,
      createdAt: string.isRequired,
    }).isRequired,
  ),
};

const ClientInvoiceModalWrapper = styled.div`
  max-width: 600px;
`;

const ClientInvoiceModalContentWrapper = styled.div`
  margin-bottom: 20px;
`;

function ClientInvoiceDetailsModalLayout({ children, closeCurrentModal }) {
  return (
    <ClientInvoiceModalWrapper>
      <Text size="20px">Client Invoice Details</Text>
      <ClientInvoiceModalContentWrapper>
        {children}
      </ClientInvoiceModalContentWrapper>
      <ModalActions>
        <CloseModalBtn onClick={closeCurrentModal} />
      </ModalActions>
    </ClientInvoiceModalWrapper>
  );
}

ClientInvoiceDetailsModalLayout.propTypes = {
  children: node.isRequired,
  closeCurrentModal: func.isRequired,
};

const OpenInvoiceDetailsButtonS = styled(LinkButton)`
  display: block;
  padding: 0;
  color: ${({ isVoided }) =>
    isVoided && getThemeColor(['accent'], defaultTheme)};

  &:hover,
  &:focus {
    color: ${({ isVoided }) =>
      isVoided && getThemeColor(['secondaryMid'], defaultTheme)};
  }
`;

// isClientInvoiceVoided :: { status: String } -> Boolean
export const isClientInvoiceVoided = R.either(
  propEqLegacy('status', ClientInvoiceStatuses.VOIDED),
  propEqLegacy('status', ReportOnlyClientInvoiceStatuses.VOIDED),
);

export function ProjectClientInvoicesList({ clientInvoices }) {
  const { openModal } = useModalContext();

  const onInvoiceClick = async () => {
    openModal({
      id: 'clientInvoiceDetails',
      content: (
        <ClientInvoiceDetailsModalContent clientInvoices={clientInvoices} />
      ),
      Layout: ClientInvoiceDetailsModalLayout,
    });
  };

  return (
    <div>
      {clientInvoices.map((invoice) => {
        const isVoided = isClientInvoiceVoided(invoice);
        return (
          <OpenInvoiceDetailsButtonS
            key={invoice._id}
            onClick={onInvoiceClick}
            isVoided={isVoided}
          >
            {centsToDollarsWithFormat(invoice.amount)}
            &nbsp;
            {isVoided && 'Voided'}
          </OpenInvoiceDetailsButtonS>
        );
      })}
    </div>
  );
}

ProjectClientInvoicesList.propTypes = {
  clientInvoices: arrayOf(
    shape({
      _id: string.isRequired,
      number: oneOfType([number, string]),
      amount: number.isRequired,
      createdAt: string.isRequired,
    }),
  ).isRequired,
};
