import * as R from 'ramda';

// Error = String || Undefined
// Value = Any
// Validator = [Rule, ErrorString]

// validateField :: [Validator] -> Value -> Error
export const validateField = R.curry((validators, value) =>
  R.ifElse(
    R.isNil,
    R.always(undefined),
    R.pipe(
      R.find(([validator]) => !validator(value)),
      R.prop(1),
      R.when(R.isNil, R.always(undefined)),
    ),
  )(validators),
);
