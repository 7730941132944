import * as R from 'ramda';
import { propEqLegacy } from '@poly/utils';
import { PageTabs as PageTabsImp } from '@poly/admin-ui';

import { projectsTabsConfig } from './common.js';

export const PageTabs = PageTabsImp;

export const projectTabByStatus = (status) =>
  R.pipe(R.find(propEqLegacy(0, status)), R.prop(1))(projectsTabsConfig);
