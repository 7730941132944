import * as R from 'ramda';
import { ofArrayLegacy } from '@poly/utils';
import { useSubscription, gql } from '@apollo/client';

// wrapAsArrayIfSingleItem :: Any -> [Any]
export const wrapAsArrayIfSingleItem = R.unless(R.is(Array), ofArrayLegacy);

const UNSUPPORTED_SUBS_COUNT_ERROR =
  "useMultipleSubscriptions doesn't support more than 5 subscriptions. Please adjust implementation.";

// Apollo client is not consistent enough
// to be used without breaking React hooks rules,
// so we must use default query here. Actual contents,
// do not matter, because this query should
// never be actually executed. Keeping it incorrect,
// let's us easily spot unintentional subscription execution.
/* eslint-disable graphql/template-strings */
const defaultSubGql = gql`
  subscription Test {
    me {
      _id
    }
  }
`;
/* eslint-enable graphql/template-strings */

function useSubscriptionWithRefetchBase(
  subscriptionArr,
  subscriptionOptionsArr,
  { debouncedRefetch, data, called = true },
  index,
) {
  const currentSub = subscriptionArr[index];
  const currentSubOptions = subscriptionOptionsArr[index] || {};
  const skip =
    !currentSub || currentSubOptions.skip || R.isNil(data) || !called;
  useSubscription(currentSub || defaultSubGql, {
    ...currentSubOptions,
    shouldResubscribe: data,
    onData: debouncedRefetch,
    skip,
  });
}

export function useMultipleSubscriptions(
  subscriptionGqlArg,
  subscriptionOptionArg,
  additionalOptions,
) {
  const subscriptionArr = wrapAsArrayIfSingleItem(subscriptionGqlArg);
  const subscriptionOptionsArr = wrapAsArrayIfSingleItem(subscriptionOptionArg);

  if (subscriptionArr.length > 5) {
    throw new Error(UNSUPPORTED_SUBS_COUNT_ERROR);
  }

  // this approach ensures we don't break hook rules
  // (prevents both eslint and SonarCube from failing)
  useSubscriptionWithRefetchBase(
    subscriptionArr,
    subscriptionOptionsArr,
    additionalOptions,
    0,
  );
  useSubscriptionWithRefetchBase(
    subscriptionArr,
    subscriptionOptionsArr,
    additionalOptions,
    1,
  );
  useSubscriptionWithRefetchBase(
    subscriptionArr,
    subscriptionOptionsArr,
    additionalOptions,
    2,
  );
  useSubscriptionWithRefetchBase(
    subscriptionArr,
    subscriptionOptionsArr,
    additionalOptions,
    3,
  );
  useSubscriptionWithRefetchBase(
    subscriptionArr,
    subscriptionOptionsArr,
    additionalOptions,
    4,
  );
}
