import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState } from 'react';
import { node, func, object, oneOfType } from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import { Button, getThemeColor } from '@poly/admin-book';

const TooltipButtonWrapper = styled.div`
  position: relative;
`;

const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${R.propOr('20px', 'top')};
  width: ${R.propOr('140px', 'width')};
  background-color: ${getThemeColor(['white'])};
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  z-index: 1;

  ${({ position }) => (position === 'right' ? 'left: 50%' : 'right: 50%')};
`;

const TooltipButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  > div {
    :first-child {
      border-bottom-left-radius: 5px;
    }

    :last-child {
      border-bottom-right-radius: 5px;
    }
  }
`;

const TooltipBtn = styled.div`
  display: flex;
  width: 50%;
  background-color: ${({ color, ...props }) =>
    color
      ? getThemeColor([color])(props)
      : getThemeColor(['secondaryMid'])(props)};
  color: ${getThemeColor(['white'])};
  font-size: 12px;
  line-height: 18px;
  padding: 6px 15px;
  cursor: pointer;
`;

export const TooltipConfirmText = styled.div`
  display: flex;
  padding: 8px 10px;
  font-size: 10px;
  line-height: 12px;
  color: ${getThemeColor(['primary'])};
`;

export const ConfirmTooltipBtn = styled(TooltipBtn)`
  width: 100%;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;

export function TooltipDefaultButtons({ onConfirm, onCancel }) {
  return (
    <TooltipButtonsWrapper>
      <TooltipBtn onClick={onConfirm}>Yes</TooltipBtn>
      <TooltipBtn onClick={onCancel} color="primaryLight">
        No
      </TooltipBtn>
    </TooltipButtonsWrapper>
  );
}

TooltipDefaultButtons.propTypes = {
  onConfirm: func.isRequired,
  onCancel: func.isRequired,
};

export function TooltipButton({
  Tooltip,
  onClick,
  children,
  tooltipProps,
  tooltipBtnProps,
  Wrapper = Button,
  ...btnProps
}) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const openTooltip = () => setIsTooltipOpen(true);
  const closeTooltip = (e) => {
    if (isTooltipOpen) {
      e.preventDefault();
      e.stopPropagation();
      setIsTooltipOpen(false);
    }
  };

  const onClickHandler = () => {
    if (onClick) {
      return onClick(openTooltip);
    }
    return openTooltip();
  };

  return (
    <OutsideClickHandler onOutsideClick={closeTooltip}>
      <TooltipButtonWrapper>
        <Wrapper {...btnProps} onClick={onClickHandler}>
          {children}
        </Wrapper>
        <TooltipWrapper {...tooltipProps}>
          {isTooltipOpen && (
            <Tooltip {...{ closeTooltip, ...tooltipBtnProps }} />
          )}
        </TooltipWrapper>
      </TooltipButtonWrapper>
    </OutsideClickHandler>
  );
}

TooltipButton.propTypes = {
  Wrapper: oneOfType([func, object]),
  onClick: func,
  children: node,
  /* eslint-disable react/forbid-prop-types */
  tooltipProps: object,
  tooltipBtnProps: object,
  /* eslint-enable react/forbid-prop-types */
  Tooltip: func.isRequired,
};
