import React from 'react';

/* eslint-disable max-len, react/jsx-closing-bracket-location, react/no-unknown-property, react/prop-types */
export const userIcons = {
  'user-group': (props) => (
    <svg
      {...props}
      enableBackground="new 0 0 24 24"
      version="1.0"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M9,9c0-1.7,1.3-3,3-3s3,1.3,3,3c0,1.7-1.3,3-3,3S9,10.7,9,9z M12,14c-4.6,0-6,3.3-6,3.3V19h12v-1.7C18,17.3,16.6,14,12,14z   " />
      </g>
      <g>
        <g>
          <circle cx="18.5" cy="8.5" r="2.5" />
        </g>
        <g>
          <path d="M18.5,13c-1.2,0-2.1,0.3-2.8,0.8c2.3,1.1,3.2,3,3.2,3.2l0,0.1H23v-1.3C23,15.7,21.9,13,18.5,13z" />
        </g>
      </g>
      <g>
        <g>
          <circle cx="18.5" cy="8.5" r="2.5" />
        </g>
        <g>
          <path d="M18.5,13c-1.2,0-2.1,0.3-2.8,0.8c2.3,1.1,3.2,3,3.2,3.2l0,0.1H23v-1.3C23,15.7,21.9,13,18.5,13z" />
        </g>
      </g>
      <g>
        <g>
          <circle cx="5.5" cy="8.5" r="2.5" />
        </g>
        <g>
          <path d="M5.5,13c1.2,0,2.1,0.3,2.8,0.8c-2.3,1.1-3.2,3-3.2,3.2l0,0.1H1v-1.3C1,15.7,2.1,13,5.5,13z" />
        </g>
      </g>
    </svg>
  ),
  user: (props) => (
    <svg
      {...props}
      enableBackground="new 0 0 24 24"
      version="1.0"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="8" r="4" />
      <path d="M12,14c-6.1,0-8,4-8,4v2h16v-2C20,18,18.1,14,12,14z" />
    </svg>
  ),
};
