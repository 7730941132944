import React from 'react';
import styled from 'styled-components';
import { bool, func, number } from 'prop-types';
import { getThemeColor, Input } from '@poly/admin-book';

import { FlexColumn } from '../../../../../../components/FlexContainer.js';

const AMOUNT_LIMIT = 500;

const Label = styled.p`
  margin: 3px 0 3px 5px;
  font-size: 10px;
  color: ${getThemeColor(['secondary'])};
`;

export function PriorityAmountInput({ onChange, ...props }) {
  const onChangePriorityAmount = (amount) => {
    onChange(amount * 1);
  };

  const inputProps = {
    ...props,
    type: 'numberMask',
    required: true,
    maxValue: AMOUNT_LIMIT,
    onChange: onChangePriorityAmount,
  };

  return (
    <FlexColumn>
      <Input {...inputProps} />
      <Label>max {AMOUNT_LIMIT}</Label>
    </FlexColumn>
  );
}

PriorityAmountInput.propTypes = {
  value: number,
  onChange: func,
  isDefaultPriority: bool,
};
