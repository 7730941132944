import React from 'react';
import { TableHead } from '@poly/admin-book';
import { arrayOf, func, shape } from 'prop-types';

import { incomeStatementTableHeaderTitles } from './constants.js';

export function IncomeStatementReportTableHeader(props) {
  return <TableHead {...props} headers={incomeStatementTableHeaderTitles} />;
}

IncomeStatementReportTableHeader.defaultProps = {
  onHeaderCellClick: () => null,
};

IncomeStatementReportTableHeader.propTypes = {
  onHeaderCellClick: func,
  headers: arrayOf(shape),
};
