import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { gql } from '@apollo/client';
import React, { useMemo, useReducer } from 'react';
import { ASC_SORT_ORDER, BillingProfileConsolidateBy } from '@poly/constants';
import { DefaultBodyWrapper } from '@poly/admin-book';
import { useRouterQuery } from '@poly/client-routing';
import { isNilOrEmpty, propEqLegacy, renameProp } from '@poly/utils';
import {
  useInfiniteScrollQueryWithSubscription,
  commonSortQuery,
  useReactiveQuery,
} from '@poly/client-utils';

import { ClientInvoicingCollectionTable } from './ClientInvoicingCollectionTable.js';
import { ClientInvoicingCollectionHeader } from './ClientInvoicingCollectionHeader.js';
import { ClientInvoicingCollectionFooter } from './ClientInvoicingCollectionFooter.js';
import { BatchInvoicingPageBody } from '../BatchInvoicingTM/BatchInvoicingPageBody.js';
import { batchClientInvoicingReducer } from '../BatchInvoicingTM/batch-invoicing-state.js';
import { getBillingProfileConfig } from '../../redux/searchFilters/billingProfileConfig.js';
import { PROJECTS_BY_SEARCH_SUB } from '../../modules/core/hooks/projects/subscriptions.js';
import { generateProjectsQueryByFullConfig } from './BillingProfileConfigurationSidebar/generateProjectsQueryByFullConfig.js';

const consolidatedInvoicesProjectsQuery = gql`
  query consolidatedInvoicesProjectsQuery($input: CollectionSearchParams!) {
    searchProjects(input: $input) {
      hits {
        _id
        type
        projectId
        description
        jobCost
        suggestedClientInvoiceAmount
        suggestedClientInvoiceAmountForMasterProperty
        clientInvoiceMarkupAmount
        clientInvoiceMarkupAmountForMasterProperty
        clientReferenceNumber
        nte
        endDate
        property {
          _id
          woCharge
        }
        parent {
          _id
          clientReferenceNumber
          adminPurchaseOrder {
            _id
            poNumber
            status
            endDate
          }
        }
        serviceType {
          name
        }
        client {
          _id
          woCharge
        }
        adminPurchaseOrder {
          _id
          poNumber
          status
          endDate
        }
      }
      total
    }
  }
`;

const totalProps = [
  'suggestedClientInvoiceAmount',
  'jobCost',
  'clientInvoiceMarkupAmount',
  'woCharge',
];

// checkConfigValid :: BillingProfileConfig -> Boolean
const checkConfigValid = R.either(
  R.both(
    propEqLegacy('consolidation', true),
    R.propSatisfies(R.complement(isNilOrEmpty), 'consolidateBy'),
  ),
  propEqLegacy('batch', true),
);

// checkConsolidatedByPO :: BillingProfileConfig -> Boolean
const checkConsolidatedByPO = R.both(
  propEqLegacy('consolidation', true),
  R.propSatisfies(
    R.includes(BillingProfileConsolidateBy.REFERENCE_NUMBER),
    'consolidateBy',
  ),
);

const clientConfigsQuery = gql`
  query clientConfigsQuery($clientId: ID!) {
    client(id: $clientId) {
      _id
      configs {
        enablePurchaseOrder
        clientInvoicing {
          email {
            enabled
          }
        }
      }
    }
  }
`;

const clientChangedSubscription = gql`
  subscription CLIENT_NICK_NAME_SUB($input: SingleDocSubInput!) {
    clientChanged(input: $input) {
      id
      type
    }
  }
`;

// isPORequiredByClientConfigs :: { client: Client } -> Boolean
const isPORequiredByClientConfigs = R.path([
  'client',
  'configs',
  'clientInvoicing',
  'email',
  'enabled',
]);

// isPOEnabledByClientConfigs :: { client: Client } -> Boolean
const isPOEnabledByClientConfigs = R.pathOr(false, [
  'client',
  'configs',
  'enablePurchaseOrder',
]);

// prepareDataForConsolidationByPO :: QueryResult -> QueryResult
const prepareDataForConsolidationByPO = R.compose(
  R.assocPath(['searchProjects', 'hits'], R.__, {}),
  R.map(
    R.compose(
      renameProp(
        'clientInvoiceMarkupAmountForMasterProperty',
        'clientInvoiceMarkupAmount',
      ),
      renameProp(
        'suggestedClientInvoiceAmountForMasterProperty',
        'suggestedClientInvoiceAmount',
      ),
    ),
  ),
  R.pathOr([], ['searchProjects', 'hits']),
);

// isBatchSelected :: BillingProfileIUIConfig -> Boolean
const isBatchSelected = R.prop('batch');

export function ClientInvoicingCollectionPage() {
  const { clientId } = useRouterQuery(['clientId']);

  const { data: clientData } = useReactiveQuery(
    clientConfigsQuery,
    clientChangedSubscription,
    {
      queryOptions: {
        variables: { clientId },
        skip: !clientId,
      },
      subscriptionOptions: {
        variables: { input: { id: clientId } },
        skip: !clientId,
      },
    },
  );

  const [consolidatedInvoicesMap, dispatch] = useReducer(
    batchClientInvoicingReducer,
    {},
  );
  const config = useSelector(getBillingProfileConfig);

  const isPORequired =
    isBatchSelected(config) && isPORequiredByClientConfigs(clientData);

  const isPOEnabled = isPOEnabledByClientConfigs(clientData);

  const isConfigValid = useMemo(() => checkConfigValid(config), [config]);

  const isConsolidatedByPO = useMemo(
    () => checkConsolidatedByPO(config),
    [config],
  );

  const query = useMemo(
    () =>
      isConfigValid
        ? generateProjectsQueryByFullConfig({
            ...config,
            clientId,
          })
        : null,
    [config, clientId],
  );

  const input = useMemo(
    () => ({
      query,
      sort: commonSortQuery(['projectId'])(ASC_SORT_ORDER),
    }),
    [query],
  );

  const isSkip = !isConfigValid || !clientData;

  const { data, loading, refetch, tableProps } =
    useInfiniteScrollQueryWithSubscription(
      consolidatedInvoicesProjectsQuery,
      input,
      {
        endpointName: 'searchProjects',
        pageSize: 500,
        skip: isSkip,
      },
      PROJECTS_BY_SEARCH_SUB,
      { searchInput: input },
      isSkip,
    );

  const externalRefetch = () => {
    refetch({ input });
  };

  return (
    <>
      <ClientInvoicingCollectionHeader
        dispatch={dispatch}
        refetch={externalRefetch}
        showRefetch={isConfigValid}
      />
      <DefaultBodyWrapper>
        <BatchInvoicingPageBody
          data={
            isConsolidatedByPO ? prepareDataForConsolidationByPO(data) : data
          }
          loading={loading}
          dispatch={dispatch}
          refetch={externalRefetch}
          calculateTotalsProps={totalProps}
          batchEntriesMap={consolidatedInvoicesMap}
          TotalsTable={ClientInvoicingCollectionFooter}
          TableComponent={ClientInvoicingCollectionTable}
          tableProps={{
            ...tableProps,
            isConsolidatedByPO,
            isPORequired,
            isPOEnabled,
          }}
        />
      </DefaultBodyWrapper>
    </>
  );
}
