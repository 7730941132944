import React, { useState } from 'react';
import styled from 'styled-components';
import { ALL, ClientSelect } from '@poly/admin-ui';
import {
  PageHeaderContainer,
  DefaultBodyWrapper,
  MainHeader,
} from '@poly/admin-book';
import { TableCard } from '@poly/admin-book/src/Card.js';

import {
  AssignUnassignedProjectBtn,
  PickupUnassignedProjectBtn,
} from './UnassignedProjectsButtons.js';
import {
  ButtonsWrapper,
  ClientSelectWrapper,
} from './UnassignedProjectsComponents.js';
import { UnassignedProjectsTable } from './UnassignedProjectsTable.js';

const TableCardWithoutTabs = styled(TableCard)`
  padding-top: 10px;
`;

export function UnassignedProjectsPage() {
  const [clientId, setClientId] = useState(ALL);

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Unassigned</MainHeader>
        <ClientSelectWrapper>
          <ClientSelect
            value={clientId}
            onChange={setClientId}
            placeholder="Start Typing Client Name"
            width="25%"
            size="small"
            isClearable
          />
        </ClientSelectWrapper>

        <ButtonsWrapper>
          <AssignUnassignedProjectBtn />
          <PickupUnassignedProjectBtn />
        </ButtonsWrapper>
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <TableCardWithoutTabs>
          <UnassignedProjectsTable clientId={clientId} />
        </TableCardWithoutTabs>
      </DefaultBodyWrapper>
    </>
  );
}
