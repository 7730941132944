import * as R from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { gql, useMutation, useQuery } from '@apollo/client';
import { arrayOf, bool, func, node, shape, string } from 'prop-types';
import { usePersistentFormValue } from '@poly/client-utils';
import { FormCreator, Loader } from '@poly/admin-book';
import { InvoiceTypes } from '@poly/constants';
import {
  useOnSubmitSetStopSubmitting,
  useNotificationState,
} from '@poly/admin-ui';
import { propEqLegacy } from '@poly/utils';

import { commonModalLayout } from '../common.js';
import { NO_SUPPLIER_INVOICE_TYPE } from './constants.js';
import { supplierInvoiceFormSections } from './sections.js';

export const projectNotToExceedQuery = gql`
  query projectNotToExceedQuery($projectId: ID!) {
    project(id: $projectId) {
      _id
      nonBillable
      suppliers {
        _id
        nte
      }
    }
  }
`;
// eslint-disable-next-line import/no-unused-modules
export const completeSupplierInvoiceTaskMutation = gql`
  mutation completeSupplierInvoiceTaskMutation(
    $supplierId: ID!
    $projectId: ID!
  ) {
    completeSupplierInvoiceTask(
      supplierId: $supplierId
      projectId: $projectId
    ) {
      _id
    }
  }
`;

// calculateTotalForBreakdown :: FormValues -> Float
const calculateTotalForBreakdown = R.compose(
  R.sum,
  R.juxt([
    R.propOr(0, 'labourCost'),
    R.propOr(0, 'materialCost'),
    R.propOr(0, 'taxAmount'),
  ]),
);

// checkIfTotalCalculatedCorrectly :: FormValues -> Boolean
const checkIfTotalCalculatedCorrectly = R.ifElse(
  propEqLegacy('type', InvoiceTypes.breakdown),
  R.converge(R.equals, [R.propOr(0, 'total'), calculateTotalForBreakdown]),
  R.T,
);

// calculateInvoiceTotalByType :: FormValues -> Float
const calculateInvoiceTotalByType = R.ifElse(
  propEqLegacy('type', InvoiceTypes.breakdown),
  calculateTotalForBreakdown,
  R.propOr(0, 'total'),
);

export function Form({
  formId,
  mutate,
  onCancel,
  projectId,
  invoiceType,
  withDueDate,
  showButtons,
  withoutTitle,
  initialValues,
  submitCaption,
  persistOptions,
  withCloseAction,
  getSuccessMessage,
  withRemoveInvoice,
  keepDirtyOnReinitialize,
}) {
  const { showSuccessNotification } = useNotificationState();

  const [completeSupplierInvoiceTask] = useMutation(
    completeSupplierInvoiceTaskMutation,
  );

  const { cleanupRetainedValue, getRetainedValue } = usePersistentFormValue(
    persistOptions?.persistKey,
  );

  const { data, loading } = useQuery(projectNotToExceedQuery, {
    variables: { projectId },
    skip: !projectId,
  });

  const projectSuppliers = R.pathOr([], ['project', 'suppliers'], data);
  const isNonBillableProject = R.pathOr(
    false,
    ['project', 'nonBillable'],
    data,
  );

  const persistentFormValues = useMemo(
    () => (persistOptions?.persistKey ? getRetainedValue() : {}),
    [persistOptions],
  );

  const formValuesByDB = useMemo(
    () => R.omit(['rating'], initialValues),
    [initialValues],
  );

  const onSubmitHandler = async ({ type, formAction, ...input }) => {
    const isTotalCalculatedCorrectly = checkIfTotalCalculatedCorrectly({
      type,
      ...input,
    });

    if (!isTotalCalculatedCorrectly) return;

    const isNoSupplierInvoice = type === NO_SUPPLIER_INVOICE_TYPE;

    const mutationHandler = isNoSupplierInvoice
      ? completeSupplierInvoiceTask
      : mutate;
    const mutationInput = isNoSupplierInvoice
      ? { variables: { projectId, supplierId: initialValues.supplierId } }
      : { type, ...input };
    const message = isNoSupplierInvoice
      ? 'Supplier Invoice task completed without invoice'
      : getSuccessMessage(formAction);

    await mutationHandler(mutationInput);
    showSuccessNotification(message);

    if (persistOptions?.persistKey) {
      cleanupRetainedValue();
    }

    if (onCancel) {
      onCancel(null, true);
    }
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  const formCreatorProps = {
    onSubmit,
    id: formId,
    formValuesByDB,
    // this prevents obsolete queries while validating invoice number
    validateOnBlur: true,
    layout: commonModalLayout,
    initialValues: {
      ...initialValues,
      projectSuppliers,
      ...persistentFormValues,
      total: calculateInvoiceTotalByType({
        ...initialValues,
        ...persistentFormValues,
      }),
    },
    sections: supplierInvoiceFormSections({
      formId,
      onCancel,
      projectId,
      invoiceType,
      withDueDate,
      showButtons,
      withoutTitle,
      submitCaption,
      withCloseAction,
      withRemoveInvoice,
      isNonBillableProject,
      suppliers: R.propOr([], 'suppliers', initialValues),
    }),
    ...(keepDirtyOnReinitialize ? { keepDirtyOnReinitialize } : {}),
    ...persistOptions,
  };

  if (loading || !data) {
    return <Loader />;
  }

  return <FormCreator {...formCreatorProps} />;
}

Form.displayName = 'SupplierInvoiceForm';

Form.propTypes = {
  isEdit: bool,
  onCancel: func,
  showButtons: bool,
  withoutTitle: bool,
  submitCaption: string,
  mutate: func.isRequired,
  initialValues: shape({
    type: string,
    supplierId: string,
    invoiceNumber: string,
  }).isRequired,
  formId: string.isRequired,
  projectId: string,
  getSuccessMessage: func.isRequired,
  invoiceType: string,
  withCloseAction: bool,
  withDueDate: bool,
  withRemoveInvoice: bool,
  persistOptions: shape({
    persistKey: string,
    persistFields: arrayOf(string).isRequired,
  }),
  keepDirtyOnReinitialize: bool,
};

Form.defaultProps = { showButtons: true, keepDirtyOnReinitialize: false };

const SupplierInvoiceFormContainer = styled.div`
  width: 600px;
`;

export function SupplierInvoiceModalLayout({ children }) {
  return (
    <SupplierInvoiceFormContainer>{children}</SupplierInvoiceFormContainer>
  );
}

SupplierInvoiceModalLayout.propTypes = {
  children: node.isRequired,
};
