import { arrayOf, number, shape, string } from 'prop-types';

export const linePropTypes = shape({
  cash_amount: number,
  accrual_amount: number,
  account: shape({ code: string.isRequired, name: string.isRequired }),
});

export const journalPropTypes = shape({
  type: string.isRequired,
  date: string.isRequired,
  lines: arrayOf(linePropTypes),
});
