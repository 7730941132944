import React from 'react';
import * as R from 'ramda';

import { GroupLayout } from './layouts/GroupLayout.js';
import { SectionLayout } from './layouts/SectionLayout.js';
import { checkGroupsLength, sortByOrder } from './formUtils.js';

const columnPath = R.pathOr(1, ['layout', 'column']);

/*
  Field = {
    label: String
    order: Number
    required: Boolean
    field: { 
      name: String
      Component: ReactComponent 
    } 
  }
  Section = { 
    label: String
    id: String 
    order: Number 
    fields: [Field] 
  }
*/

// generateGroup :: (Object, Object) -> ([Section | Field], String, Object) -> ReactComponent
const generateGroup = (
  values,
  layout,
  mutators,
  changeFieldValue,
  globalSectionLayout,
  globalFieldLayout,
) =>
  function (list, key, obj) {
    return (
      <GroupLayout
        key={key}
        card={R.prop('card', layout)}
        layout={R.propOr({}, 'group', layout)}
        total={checkGroupsLength(R.keys(obj))}
      >
        {sortByOrder(list).map(({ id = '', order, ...props }) => (
          <SectionLayout
            key={id + order}
            globalSectionLayout={globalSectionLayout}
            globalFieldLayout={globalFieldLayout}
            {...{
              id,
              order,
              values,
              mutators,
              changeFieldValue,
            }}
            {...props}
          />
        ))}
      </GroupLayout>
    );
  };

// mapListToGroups :: [SectionOrField] -> Object -> Object -> [ReactComponent]
export const mapListToGroups = (
  list,
  changeFieldValue,
  globalSectionLayout,
  globalFieldLayout,
  values = {},
  layout = {},
  mutators = {},
) =>
  R.pipe(
    R.sortBy(columnPath),
    R.groupBy(columnPath),
    R.mapObjIndexed(
      generateGroup(
        values,
        layout,
        mutators,
        changeFieldValue,
        globalSectionLayout,
        globalFieldLayout,
      ),
    ),
    R.values,
  )(list);
