import * as R from 'ramda';
import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { startOfMonth, endOfMonth, startOfDay, endOfDay } from 'date-fns';
import { ToolBarBtnDivider, DatePicker, Loader } from '@poly/admin-book';
import { alwaysNewDate, ensureIsDate } from '@poly/utils';
import { AccountingMethods } from '@poly/constants';

import {
  PageWithSearchHeader,
  SearchPageHeaderContainer,
  SearchPageConditionalWrapper,
} from '../../components/PageWithSearchHeader.js';
import { ProfitLossReportTable } from './ProfitLossReportTable.js';
import { SearchHeaderCreator } from '../../components/SearchHeaderCreator.js';
import { GenerateProfitLossReportBtn } from './GenerateProfitLossReportBtn.js';
import { ProfitLossExportExcelButton } from './ProfitLossExportExcelButton.js';
import { AccountingMethodSelect } from '../../components/AccountingMethodSelect.js';
import {
  getFiscalYearSubscribersByDate,
  FiscalYearSelect,
  CURRENT_MONTH,
} from '../../components/FiscalYearSelect.js';
import { useCogsAccount } from '../../hooks/useCogsAccount.js';

// formatProfitLossInput :: Object -> ProfitLossReportFilters
const formatProfitLossInput = R.compose(
  R.evolve({
    startDate: R.compose(startOfDay, ensureIsDate),
    endDate: R.compose(endOfDay, ensureIsDate),
  }),
  R.omit(['year']),
);

function ExportButtonsWrapper(props) {
  return (
    <>
      <GenerateProfitLossReportBtn {...props} />
      <ToolBarBtnDivider />
      <ProfitLossExportExcelButton {...props} />
    </>
  );
}

export const defaultFilterValues = {
  accountingMethod: AccountingMethods.ACCRUAL_BASIS,
  startDate: startOfMonth(alwaysNewDate()),
  endDate: endOfMonth(alwaysNewDate()),
  year: CURRENT_MONTH,
};

const profitLossReportPageHeaderConfig = (
  currentDate,
  profitLossReport,
  filters,
  cogsAccountCodes,
) => [
  {
    key: 'typeRow',
    columns: [
      {
        name: 'accountingMethod',
        title: 'Type',
        column: 1,
        Component: AccountingMethodSelect,
        defaultValue: defaultFilterValues.accountingMethod,
        filterProps: {
          width: '100%',
        },
      },
      {
        name: 'printBtn',
        column: 2,
        columnLayout: { filterWidth: '100px' },
        relatedFilters: ['accountingMethod', 'startDate', 'endDate'],
        Component: (props) => (
          <ExportButtonsWrapper
            {...props}
            filters={filters || defaultFilterValues}
            cogsAccountCodes={cogsAccountCodes}
          />
        ),
        filterProps: {
          width: '100%',
          profitLossReport,
        },
      },
    ],
  },
  {
    key: 'dateRow',
    rowLayout: { justifyContent: 'flex-start' },
    columns: [
      {
        name: 'startDate',
        title: 'Start Date',
        columnLayout: { filterWidth: '150px' },
        column: 1,
        defaultValue: defaultFilterValues.startDate,
        Component: DatePicker,
        filterProps: { width: '100%' },
      },
      {
        name: 'endDate',
        title: 'End Date',
        columnLayout: { titleWidth: '100px', filterWidth: '150px' },
        column: 2,
        defaultValue: defaultFilterValues.endDate,
        Component: DatePicker,
        filterProps: { width: '100%' },
      },
      {
        name: 'year',
        column: 3,
        Component: FiscalYearSelect,
        columnLayout: { filterWidth: '150px' },
        defaultValue: defaultFilterValues.year,
        filterProps: { width: '100%', required: true },
        subscribers: getFiscalYearSubscribersByDate(currentDate),
      },
    ],
  },
];

const profitLossReportQuery = gql`
  query profitLossReport($filters: ProfitLossReportFilters) {
    profitLossReport(filters: $filters) {
      account {
        _id
        name
        code
      }
      balance
      category
      subAccounts {
        account {
          _id
          name
          code
        }
        balance
      }
    }
  }
`;

export function ProfitLossReportPage() {
  const [filters, setFilters] = useState(null);

  const { loading: cogsLoading, cogsAccountCodes } = useCogsAccount();

  const { data, loading } = useQuery(profitLossReportQuery, {
    variables: {
      filters: formatProfitLossInput(filters),
    },
    fetchPolicy: 'network-only',
    skip: !filters,
  });

  const profitLossReport = R.propOr([], 'profitLossReport', data);
  return (
    <PageWithSearchHeader headerHeight="155px">
      <SearchPageHeaderContainer title="Profit And Loss">
        <SearchHeaderCreator
          config={profitLossReportPageHeaderConfig(
            alwaysNewDate(),
            profitLossReport,
            filters,
            cogsAccountCodes,
          )}
          onSearch={setFilters}
          loading={loading || cogsLoading}
          searchBtnTitle="Filter"
        />
      </SearchPageHeaderContainer>
      {loading || cogsLoading ? (
        <Loader />
      ) : (
        <SearchPageConditionalWrapper condition={profitLossReport.length > 0}>
          <ProfitLossReportTable
            profitLossReport={profitLossReport}
            filters={filters}
            cogsAccountCodes={cogsAccountCodes}
          />
        </SearchPageConditionalWrapper>
      )}
    </PageWithSearchHeader>
  );
}
