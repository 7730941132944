import React from 'react';
import { useRedirectIfHasNoPermissions } from '@poly/client-utils';
import { CREATE_CLIENT_BATCH_PERMISSION } from '@poly/security';

import { BatchInvoicingTMPage } from './BatchInvoicingTMPage.js';
import { regionalManagementJanitorialBatchInvoicingQuery } from './client-filters-presets.js';

export function RegionalManagementJanitorialBatchInvoicingPage() {
  useRedirectIfHasNoPermissions([CREATE_CLIENT_BATCH_PERMISSION]);

  return (
    <BatchInvoicingTMPage
      query={regionalManagementJanitorialBatchInvoicingQuery}
      title="Regional Management Janitorial Batch Invoice"
      withSupplierInvoices={false}
      showSupplierInvoicesCheckbox={false}
    />
  );
}
