import React from 'react';

import { useAuthUserByTemporalAccess } from '../DocumentsRequest/DocumentsRequestPage.js';
import { SupplierAttachInvoiceFileForm } from './SupplierAttachInvoiceFileForm.js';

export function SupplierAttachInvoiceFilePage() {
  const { isAuthorized } = useAuthUserByTemporalAccess();

  if (!isAuthorized) {
    return null;
  }

  return <SupplierAttachInvoiceFileForm />;
}
