import React from 'react';
import { DefaultBodyWrapper } from '@poly/admin-book';
import styled from 'styled-components';

import { AssetTypesPageTable } from './AssetTypesPageTable.js';
import { AssetTypesPageHeader } from './AssetTypesPageHeader.js';

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  padding-top: 15px;
`;

export function AssetTypesPage() {
  return (
    <>
      <AssetTypesPageHeader />
      <DefaultBodyWrapper>
        <TableContainer>
          <AssetTypesPageTable />
        </TableContainer>
      </DefaultBodyWrapper>
    </>
  );
}
