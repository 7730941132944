import React from 'react';
import styled from 'styled-components';
import { DatePicker } from '@poly/admin-book';
import { ClientSelect } from '@poly/admin-ui';
import { bool, func, string } from 'prop-types';

import { ProjectSelect } from '../../components/ProjectsSelect.js';
import { FiltersContainer } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { ClientInvoiceSelect } from '../../components/ClientInvoiceSelect.js';
import { searchPaymentsFilters } from './constants.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

function FilterContainer(props) {
  return <SearchHeaderColumn {...props} />;
}

FilterContainer.defaultProps = {
  titleWidth: '135px',
  filterWidth: '200px',
};

FilterContainer.propTypes = {
  titleWidth: string,
  filterWidth: string,
};

const headerConfig = [
  { name: searchPaymentsFilters.clientId },
  { name: searchPaymentsFilters.projectId },
  { name: searchPaymentsFilters.clientInvoiceId },
  { name: searchPaymentsFilters.paymentStartDate },
  { name: searchPaymentsFilters.paymentEndDate },
];

export function EditClientPaymentsPageHeader({
  loading,
  submitting,
  setFilters,
}) {
  const { searchFilters, handlers, onReset } = useSearchFilters(headerConfig);

  const onSearch = () => setFilters(searchFilters);

  const onResetSearch = () => {
    onReset();
    setFilters(null);
  };

  return (
    <SearchPageHeaderContainer title="Edit Client Payments">
      <FiltersContainer>
        <FlexSpaceBetween>
          <FlexRow>
            <FilterContainer title="Client">
              <ClientSelect
                width="100%"
                size="small"
                isClearable
                disabled={submitting}
                includeAllOption={false}
                onChange={handlers.clientId}
                value={searchFilters.clientId}
                placeholder="Start Typing Client Name"
              />
            </FilterContainer>
            <FilterContainer title="Project No">
              <ProjectSelect
                width="100%"
                size="small"
                isClearable
                disabled={submitting}
                onChange={handlers.projectId}
                value={searchFilters.projectId}
                placeholder="Start Typing Project Number"
              />
            </FilterContainer>
            <FilterContainer title="Invoice No">
              <ClientInvoiceSelect
                input={{}}
                width="100%"
                size="small"
                isClearable
                name="clientInvoiceId"
                disabled={submitting}
                onChange={handlers.clientInvoiceId}
                value={searchFilters.clientInvoiceId}
                placeholder="Start Typing Invoice Number"
              />
            </FilterContainer>
          </FlexRow>
        </FlexSpaceBetween>
        <FlexSpaceBetween>
          <FlexRow>
            <FilterContainer title="Payment Date From">
              <DatePicker
                value={searchFilters.paymentStartDate}
                onChange={handlers.paymentStartDate}
                disabled={submitting}
                width="100%"
              />
            </FilterContainer>
            <FilterContainer title="Payment Date To">
              <DatePicker
                value={searchFilters.paymentEndDate}
                onChange={handlers.paymentEndDate}
                disabled={submitting}
                width="100%"
              />
            </FilterContainer>
          </FlexRow>
          <ButtonsContainer>
            <SearchHeaderButton
              size="small"
              styleType="basicPrimary"
              onClick={onResetSearch}
            >
              Reset
            </SearchHeaderButton>
            <SearchHeaderButton
              loader={loading}
              size="small"
              onClick={onSearch}
            >
              Search
            </SearchHeaderButton>
          </ButtonsContainer>
        </FlexSpaceBetween>
      </FiltersContainer>
    </SearchPageHeaderContainer>
  );
}

EditClientPaymentsPageHeader.propTypes = {
  loading: bool.isRequired,
  submitting: bool.isRequired,
  setFilters: func.isRequired,
};
