import React from 'react';
import * as R from 'ramda';
import { string, func, bool } from 'prop-types';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Loader } from '@poly/admin-book';
import {
  CREATE_VIA_ASSET_SCANNER_PROJECT_PERMISSION,
  FLIPPED_CONTAINS_AIT_OPERATOR,
  READ_PROPERTY_PERMISSION,
} from '@poly/security';

import { editClientUserFormId } from './constants.js';
import { ClientUserFormBase } from './ClientUserFormBase.js';
import { formatUserInput } from './form/formatUserInput.js';
import { useUserGroupListQuery } from '../../../pages/UserGroupManagement/useUserGroupListQuery.js';

const editUserDetailsQuery = gql`
  query editUserDetailsQuery($userId: ID!) {
    user(id: $userId) {
      _id
      status
      isClientPortalUser
      userGroups {
        userGroup {
          _id
        }
      }
      profile {
        firstName
        lastName
        workPhoneNumber
        workPhoneNumberExt
        cellPhoneNumber
        cellPhoneNumber
        loginCellPhoneNumber
      }
      email
      accessItems {
        permission
        types {
          propertyId {
            equals
            flippedContains
          }
        }
      }
    }
  }
`;

// getInitialValues :: Object -> FormValues
const getInitialValues = R.compose(
  R.applySpec({
    status: R.prop('status'),
    firstName: R.path(['profile', 'firstName']),
    lastName: R.path(['profile', 'lastName']),
    email: R.prop('email'),
    workPhone: R.path(['profile', 'workPhoneNumber']),
    ext: R.path(['profile', 'workPhoneNumberExt']),
    mobilePhone: R.path(['profile', 'cellPhoneNumber']),
    userGroupId: R.path(['userGroups', '0', 'userGroup', '_id']),
    loginCellPhoneNumber: R.path(['profile', 'loginCellPhoneNumber']),
    isClientPortalUser: R.prop('isClientPortalUser'),
    isUserWithMoreThenOneUserGroup: R.compose(
      R.gt(R.__, 1),
      R.length,
      R.propOr([], 'userGroups'),
    ),
  }),
  R.prop('user'),
);

// getUserProperties :: Object -> [ID]
const getUserProperties = R.compose(
  R.pathOr([], ['types', 'propertyId', FLIPPED_CONTAINS_AIT_OPERATOR]),
  R.find(
    R.propSatisfies(
      R.includes(R.__, [
        READ_PROPERTY_PERMISSION,
        CREATE_VIA_ASSET_SCANNER_PROJECT_PERMISSION,
      ]),
      'permission',
    ),
  ),
  R.propOr([], 'accessItems'),
  R.prop('user'),
);

const UPDATE_USER_WITH_USER_GROUPS_MUTATION = gql`
  mutation UPDATE_USER_WITH_USER_GROUPS_MUTATION(
    $input: UpdateUserWithUserGroupsInput!
  ) {
    updateUserWithUserGroups(update: $input) {
      _id
    }
  }
`;

export function EditClientUserForm({
  userId,
  clientId,
  onCancel,
  isAssetScannerEnabled,
  isClientPortalEnabled,
}) {
  const [editUser] = useMutation(UPDATE_USER_WITH_USER_GROUPS_MUTATION);

  const { userGroupList, loading: userGroupLoading } = useUserGroupListQuery();

  const { data, loading } = useQuery(editUserDetailsQuery, {
    variables: { userId },
    fetchPolicy: 'network-only',
  });

  if (loading || userGroupLoading) {
    return <Loader />;
  }

  const mutate = async (values) => {
    await editUser({
      variables: {
        input: { userId, ...formatUserInput(userGroupList)(values) },
      },
    });
    onCancel();
  };

  return (
    <ClientUserFormBase
      mutate={mutate}
      title="Edit User"
      clientId={clientId}
      onCancel={onCancel}
      withResetPasswordBtn
      formId={editClientUserFormId}
      successMessage="User successfully edited"
      selectedPropertiesInitial={getUserProperties(data)}
      initialValues={{ ...getInitialValues(data), clientId }}
      userGroupList={userGroupList}
      isAssetScannerEnabled={isAssetScannerEnabled}
      isClientPortalEnabled={isClientPortalEnabled}
    />
  );
}

EditClientUserForm.propTypes = {
  onCancel: func.isRequired,
  userId: string.isRequired,
  clientId: string.isRequired,
  isAssetScannerEnabled: bool,
  isClientPortalEnabled: bool,
};
