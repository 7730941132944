import * as R from 'ramda';
import { Input } from '@poly/admin-book';
import { bool, func, string } from 'prop-types';
import React, { useState, useEffect } from 'react';

// extractValue :: Object -> String
const extractValue = R.path(['target', 'value']);

export function RefNumberInput({
  _id,
  refNumber,
  submitting,
  onRefNumberChange,
}) {
  const [internalValue, setInternalValue] = useState(refNumber);
  const inputName = `payment-ref-number-input-${_id}`;

  useEffect(() => {
    if (internalValue !== refNumber) {
      setInternalValue(refNumber);
    }
  }, [refNumber]);

  const onBlur = R.compose(onRefNumberChange, extractValue);

  return (
    <Input
      width="100%"
      name={inputName}
      value={internalValue}
      onBlur={onBlur}
      disabled={submitting}
      onChange={(event) => setInternalValue(extractValue(event))}
      dataTestId={inputName}
    />
  );
}

RefNumberInput.propTypes = {
  _id: string,
  refNumber: string,
  submitting: bool,
  onRefNumberChange: func,
};
