import React, { useState } from 'react';
import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { useWipQCReportQuery } from './useWipQCReportQuery.js';
import { WorkInProgressQCHeader } from './WorkInProgressQCHeader.js';
import { getWipQCRecordsRows } from './helpers.js';
import {
  wipQCRecordsTableInitialSorting,
  WorkInProgressQCTable,
} from './WorkInProgressQCTable.js';
import { WorkInProgressTableWrapper } from '../WorkInProgressReport/WorkInProgressReportPage.js';

export function WorkInProgressQCReportPage() {
  const [queryFilters, setQueryFilters] = useState({});
  const { data, loading, tableProps } = useWipQCReportQuery(queryFilters);
  const [sorting, setSorting] = useState(wipQCRecordsTableInitialSorting);

  const wipQCRecords = getWipQCRecordsRows(data);

  return (
    <PageWithSearchHeader headerHeight="145px">
      <WorkInProgressQCHeader
        setQueryFilters={setQueryFilters}
        loading={loading}
        sorting={sorting}
        wipQCRecords={wipQCRecords}
        updateSorting={setSorting}
      />
      <WorkInProgressTableWrapper loading={loading}>
        <WorkInProgressQCTable
          wipQCRecords={wipQCRecords}
          updateSorting={setSorting}
          showScrollBar
          {...tableProps}
        />
      </WorkInProgressTableWrapper>
    </PageWithSearchHeader>
  );
}
