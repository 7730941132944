import styled from 'styled-components';
import * as R from 'ramda';
import { bool, node, string } from 'prop-types';
import React from 'react';
import { Button, getThemeColor, getThemeFont, Text } from '@poly/admin-book';

import { FlexContainer } from './FlexContainer.js';

export const SearchHeaderColumnTitle = styled(Text.withComponent('label'))`
  display: inline-block;
  width: ${R.propOr('135px', 'width')};
  font-size: 14px;
  font-weight: ${getThemeFont(['bold'])};
  color: ${getThemeColor(['primaryLighter4'])};
  padding-right: 5px;
  line-height: 14px;
  margin-top: ${({ withError }) => withError && '5px'};
`;

const ColumnContainer = styled(FlexContainer)`
  align-items: ${({ withError }) => (withError ? 'start' : 'center')};
  margin: ${({ margin }) => margin || '0 15px 10px 0'};
  padding: ${({ padding }) => padding || '0'};
  &:last-child {
    margin-right: 0;
  }
`;

const SearchHeaderColumnFilter = styled(FlexContainer)`
  width: ${R.propOr('240px', 'width')};
  align-items: ${({ withError }) => (withError ? 'start' : 'center')};
  justify-content: ${({ position }) => position || 'flex-start'};
`;

export function SearchHeaderColumn({
  title,
  children,
  titleWidth,
  filterWidth,
  position,
  margin,
  padding,
  labelForId,
  className,
  withError,
}) {
  return (
    <ColumnContainer
      margin={margin}
      padding={padding}
      className={className}
      withError={withError}
    >
      {title && (
        <SearchHeaderColumnTitle
          width={titleWidth}
          htmlFor={labelForId}
          withError={withError}
        >
          {title}
        </SearchHeaderColumnTitle>
      )}
      <SearchHeaderColumnFilter
        width={filterWidth}
        position={position}
        withError={withError}
      >
        {children}
      </SearchHeaderColumnFilter>
    </ColumnContainer>
  );
}

SearchHeaderColumn.propTypes = {
  title: string,
  children: node,
  titleWidth: string,
  filterWidth: string,
  margin: string,
  padding: string,
  position: string,
  labelForId: string,
  className: string,
  withError: bool,
};

const SearchHeaderButtonS = styled(Button)`
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
  padding: 5px 10px;
  line-height: 12px;
  min-width: 90px;
`;

export function SearchHeaderButton({ ...props }) {
  const { disabled, loader } = props;
  return <SearchHeaderButtonS {...props} disabled={disabled || loader} />;
}
SearchHeaderButton.propTypes = {
  disabled: bool,
  loader: bool,
};
