export const ProjectFilterStatuses = {
  ACTIVE: 'active',
  PENDING: 'projectPending',
  ON_HOLD: 'onHold',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
};

export const ProjectFilterTypes = {
  REACTIVE: 'reactive',
  MASTER_RECURRING: 'masterRecurring',
  CHILD_RECURRING: 'childRecurring',
  PREVENTIVE_MAINTENANCE: 'preventiveMaintenance',
  CHILD_PREVENTIVE_MAINTENANCE: 'childPreventiveMaintenance',
};
