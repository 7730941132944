import { useMutation } from '@apollo/client';

import { TOGGLE_USER_ALERT_ARCHIVE } from '../../hocs/userAlerts/mutations.js';

export const useToggleUserAlertArchive = () => {
  const [mutate] = useMutation(TOGGLE_USER_ALERT_ARCHIVE, {
    alias: 'useToggleUserAlertArchive',
  });

  return {
    toggleUserAlertArchive: (id) => mutate({ variables: { id } }),
  };
};
