import React from 'react';
import * as R from 'ramda';
import { string, number, shape } from 'prop-types';
import {
  useSidebarTableFormContext,
  CommonSidebarFormWrapper,
} from '@poly/admin-ui';
import { useLocation } from '@poly/client-routing';

import { sendWOCFormId } from '../../../modules/forms/sendWOCForm/constants.js';
import { SendWOCForm } from '../../../modules/forms/sendWOCForm/SendWOCForm.js';

// isFormChanged :: {pristine: Boolean} -> Boolean
const isFormChanged = R.both(
  R.has('pristine'),
  R.complement(R.prop('pristine')),
);

export function UpdateAssignedSupplierWOCForm({ _id, nte, project }) {
  const { formSetter } = useSidebarTableFormContext();

  const { state } = useLocation();

  const onCancel = () => formSetter(null);

  return (
    <CommonSidebarFormWrapper
      isTableRow
      onCancel={onCancel}
      formId={sendWOCFormId}
      title="Update Assigned Supplier WOC and NTE"
      disableSubmitBtn={!isFormChanged(state)}
    >
      <SendWOCForm
        supplierId={_id}
        supplierNTE={nte}
        onCancel={onCancel}
        projectId={project._id}
        project={project}
      />
    </CommonSidebarFormWrapper>
  );
}

UpdateAssignedSupplierWOCForm.propTypes = {
  nte: number,
  _id: string.isRequired,
  project: shape({ _id: string.isRequired }).isRequired,
};
