import React from 'react';
import { Icon, Input } from '@poly/admin-book';
import styled from 'styled-components';
import { bool, func, string } from 'prop-types';

import { useModalContext } from './Modal/ModalProvider.js';

const IconS = styled(Icon)`
  position: absolute;
  cursor: pointer;
  top: 38px;
  right: 12px;
`;

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  input {
    padding-right: 25px;
  }
`;

export function ResetInput({
  handleReset,
  confirmContent,
  isConfirm,
  ...props
}) {
  const { openConfirmModal: openConfirmModalContext } = useModalContext();

  const confirmModalProps = {
    cancelBtnCaption: 'No',
    btnCaption: 'Yes',
    id: 'confirmResetButtonId',
    content: confirmContent,
  };

  const openResetModal = () =>
    openConfirmModalContext({
      ...confirmModalProps,
      onConfirm: (closeConfirmModal) => () => {
        handleReset();
        closeConfirmModal();
      },
    });

  return (
    <InputWrapper>
      <Input {...props} />
      <IconS
        name="close"
        size="10"
        color="#9a9da0"
        onClick={isConfirm ? openResetModal : handleReset}
      />
    </InputWrapper>
  );
}

ResetInput.propTypes = {
  handleReset: func.isRequired,
  confirmContent: string,
  isConfirm: bool,
};
