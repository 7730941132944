import React from 'react';
import * as R from 'ramda';
import { useQuery, gql } from '@apollo/client';

import { string } from 'prop-types';
import { Select } from '@poly/admin-book';
import { formatDate } from '@poly/utils';

const reconciliationStatementsQuery = gql`
  query RECONCILIATION_STATEMENTS($input: ReconciliationStatementsInput!) {
    reconciliationStatements(input: $input) {
      _id
      endingDate
    }
  }
`;

// dataToStatementEndingDateOptions :: Object -> [Pair ID String]
const dataToStatementEndingDateOptions = R.compose(
  R.map(
    R.applySpec({
      value: R.prop('_id'),
      label: R.compose(formatDate, R.prop('endingDate')),
    }),
  ),
  R.propOr([], 'reconciliationStatements'),
);

export function ReconciliationStatementSelect({
  accountCode,
  ...dropdownProps
}) {
  const { data } = useQuery(reconciliationStatementsQuery, {
    variables: { input: { accountCode } },
    fetchPolicy: 'network-only',
    skip: !accountCode,
  });

  const selectProps = {
    ...dropdownProps,
    options: dataToStatementEndingDateOptions(data),
  };

  return <Select {...selectProps} />;
}

ReconciliationStatementSelect.propTypes = {
  accountCode: string,
};
