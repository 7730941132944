import React from 'react';
import { string, bool } from 'prop-types';
import { SidebarWidth, SidebarRow, ProjectSidebarWidth } from '@poly/admin-ui';

import {
  SectionLabel,
  DoubleTabsWrapper,
} from '../../components/commonSidebarComponents.js';
import { CommonProjectsSubTabs } from '../../components/commonTabs/CommonProjectsSubTabs.js';

export function MasterRecurringProjectSidebarChildTab({ _id, isCard }) {
  const width = SidebarWidth + ProjectSidebarWidth;

  return (
    <DoubleTabsWrapper isCard={isCard} width={width}>
      <SidebarRow>
        <SectionLabel>Master Recurring Child Projects</SectionLabel>
      </SidebarRow>
      <CommonProjectsSubTabs entity={{ _id, name: 'parent' }} />
    </DoubleTabsWrapper>
  );
}

MasterRecurringProjectSidebarChildTab.propTypes = {
  isCard: bool,
  _id: string.isRequired,
};
