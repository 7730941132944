import React from 'react';

import { TextAreaS } from '../../../../../modules/forms/formsStyles.js';

export function DefaultSupplierWOInstructions(props) {
  return (
    <TextAreaS
      {...props}
      id="default-supplier-wo-instructions"
      placeholder="Enter Supplier WO Instructions"
    />
  );
}
