import { useState } from 'react';
import { useNotificationContext } from '@poly/admin-book';
import { useMutation, gql } from '@apollo/client';

const approveRequestedSupplierDocumentsMutation = gql`
  mutation approveRequestedSupplierDocuments($documentIds: [ID!]!) {
    approveRequestedSupplierDocuments(documentIds: $documentIds)
  }
`;

export const useApproveRequestedSupplierDocuments = (documentIds) => {
  const [isApproving, setIsApproving] = useState(false);

  const { showSuccessNotification } = useNotificationContext();

  const [approveRequestedSupplierDocuments] = useMutation(
    approveRequestedSupplierDocumentsMutation,
  );

  const handleApproveDocuments = async () => {
    setIsApproving(true);
    await approveRequestedSupplierDocuments({
      variables: { documentIds },
    });
    showSuccessNotification('Documents successfully approved');
    setIsApproving(false);
  };

  return {
    handleApproveDocuments,
    isApproving,
  };
};
