import React from 'react';
import { string } from 'prop-types';

import { SidebarIDs } from '../../constants.js';
import { userTabsSidebarId } from '../constants.js';
import { SidebarTabs } from '../../components/SidebarTabs.js';
import { UserSidebarFilesTab } from './UserSidebarFilesTab.js';
import { staffSidebarTabs } from '../../../routes/constants.js';
import { UserSidebarUpdatesTab } from './UserSidebarUpdatesTab.js';

export function UserSidebarTabs({ userId, userName }) {
  return (
    <SidebarTabs
      id={userTabsSidebarId}
      params={[SidebarIDs.user]}
      defaultValue={staffSidebarTabs.staffUpdates}
      tabs={[
        [
          'Updates',
          staffSidebarTabs.staffUpdates,
          <UserSidebarUpdatesTab {...{ userId, userName }} />,
        ],
        [
          'Files',
          staffSidebarTabs.staffFiles,
          <UserSidebarFilesTab
            userId={userId}
            key={staffSidebarTabs.staffFiles}
          />,
        ],
      ]}
    />
  );
}

UserSidebarTabs.propTypes = {
  userId: string.isRequired,
  userName: string.isRequired,
};
