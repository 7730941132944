import * as R from 'ramda';
import React, { useState } from 'react';
import { shape, string } from 'prop-types';
import { LinkButton } from '@poly/admin-book';
import { useApolloClient } from '@apollo/client';
import { useNotificationState } from '@poly/admin-ui';
import { convertCentsToDollars, formatDate, isNilOrEmpty } from '@poly/utils';
import {
  performExcelExport,
  ExcelExportCellType,
  createExcelExportCell,
} from '@poly/client-utils';
import { EXPORT_XLS_CAPTION } from '@poly/constants';

import { arAgingReportQuery } from './arAgingReportQuery.js';
import {
  getDaysFromNowByProp,
  getTotalByDaysPart,
} from '../APAgingReport/APAgingReportTableComp.js';

// getClientInvoiceTotal :: (Number, Number) -> ClientInvoice -> Number
const getClientInvoiceTotal = (from, to) =>
  getTotalByDaysPart(from, to, 'dueDate', 'amount');

// getClientInvoiceTotalWithConvertToDollars :: (Number, Number) -> ClientInvoice -> Number
const getClientInvoiceTotalWithConvertToDollars = (from, to) =>
  R.compose(convertCentsToDollars, getClientInvoiceTotal(from, to));

// xlsInvoicesTotalByMapper :: [ClientInvoice] -> Function -> String
const clientInvoicesTotalByMapper = (mapper) =>
  R.compose(
    convertCentsToDollars,
    R.reduce(R.add, 0),
    R.reject(isNilOrEmpty),
    R.map(mapper),
  );

// prepareClientInvoices :: [ARAgingReportClient] -> [ClientInvoice]
const prepareClientInvoices = R.compose(
  R.flatten,
  R.map(R.propOr([], 'invoices')),
);

// createExcelExportRow :: Array -> [ExcelExportDataCell]
const createExcelExportFooterRow = R.zipWith(createExcelExportCell, [
  ...R.repeat(ExcelExportCellType.tableStringFooter, 5),
  ...R.repeat(ExcelExportCellType.tableCurrencyFooter, 6),
]);

// getClientInvoiceRow :: ClientInvoice -> [ExcelExportDataCell]
const getClientInvoiceRow = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 5),
    ...R.repeat(ExcelExportCellType.defaultCurrency, 6),
  ]),
  R.juxt([
    R.prop('number'),
    R.path(['project', 'projectId']),
    R.compose(formatDate, R.prop('createdAt')),
    R.compose(formatDate, R.prop('dueDate')),
    getDaysFromNowByProp('createdAt'),
    getClientInvoiceTotalWithConvertToDollars(-Infinity, 0),
    getClientInvoiceTotalWithConvertToDollars(0, 30),
    getClientInvoiceTotalWithConvertToDollars(30, 60),
    getClientInvoiceTotalWithConvertToDollars(60, 90),
    getClientInvoiceTotalWithConvertToDollars(90, Infinity),
    R.compose(convertCentsToDollars, R.prop('amount')),
  ]),
);

const clientInvoicesHeaderRow = R.zipWith(
  createExcelExportCell,
  [
    ...R.repeat(ExcelExportCellType.tableHeader, 4),
    ...R.repeat(ExcelExportCellType.tableCurrencyHeader, 7),
  ],
  [
    'Invoice #',
    'Project #',
    'Invoice Date',
    'Due Date',
    'Age',
    'Current',
    '1-30 Days',
    '31-60 Days',
    '61-90 Days',
    'more 91 Days',
    'Total',
  ],
);

// getHeaderSubTitleRow :: String -> [ExcelExportDataCell]
const getHeaderSubTitleRow = (name) => [
  {
    value: name,
    cellType: ExcelExportCellType.subTitleRow,
  },
];

// getTotalReportRow :: (String, Array) => Array
const getTotalReportRow = ({ name, invoices }) => [
  `Total ${name}`,
  ...R.repeat('', 4),
  clientInvoicesTotalByMapper(getClientInvoiceTotal(-Infinity, 0))(invoices),
  clientInvoicesTotalByMapper(getClientInvoiceTotal(0, 30))(invoices),
  clientInvoicesTotalByMapper(getClientInvoiceTotal(30, 60))(invoices),
  clientInvoicesTotalByMapper(getClientInvoiceTotal(60, 90))(invoices),
  clientInvoicesTotalByMapper(getClientInvoiceTotal(90, Infinity))(invoices),
  clientInvoicesTotalByMapper(R.prop('amount'))(invoices),
];

// getReportsRowsConfig :: (Staring Array) -> [[ExcelExportDataCell]]
const getReportsRowsConfig = ({ name, invoices }) => [
  getHeaderSubTitleRow(name),
  clientInvoicesHeaderRow,
  ...R.map(getClientInvoiceRow, invoices),
  createExcelExportFooterRow(getTotalReportRow({ name, invoices })),
];

// eslint-disable-next-line import/no-unused-modules
export const getARAgingReportExportExcelConfig = ({ title, reportData }) => ({
  exportFileName: 'ar-aging-report.xlsx',
  columnWidths: [15, 15, 15, 15, 5, 15, 15, 15, 15, 15, 15],
  rows: [
    [
      {
        value: title,
        cellType: ExcelExportCellType.title,
      },
    ],
    ...R.compose(R.unnest, R.map(getReportsRowsConfig))(reportData),
    [
      {
        value: '',
        cellType: ExcelExportCellType.default,
      },
    ],
    createExcelExportFooterRow(
      getTotalReportRow({
        name: '',
        invoices: prepareClientInvoices(reportData),
      }),
    ),
  ],
});

// eslint-disable-next-line import/no-unused-modules
export const getReportData = R.pathOr([], ['arAgingReport', 'hits']);

export function ARAgingReportExportXLBtn({ title, query }) {
  const { showErrorNotification } = useNotificationState();
  const [isLoading, setIsLoading] = useState(false);

  const apolloClient = useApolloClient();
  const onClick = async () => {
    if (!query) {
      showErrorNotification('No report records to export');
      return;
    }
    setIsLoading(true);
    const { data } = await apolloClient.query({
      query: arAgingReportQuery,
      variables: { input: query },
      fetchPolicy: 'network-only',
    });
    const excelConfig = getARAgingReportExportExcelConfig({
      title,
      reportData: getReportData(data),
    });
    performExcelExport(excelConfig);
    setIsLoading(false);
  };

  return (
    <LinkButton onClick={onClick} loader={isLoading}>
      {EXPORT_XLS_CAPTION}
    </LinkButton>
  );
}
ARAgingReportExportXLBtn.propTypes = {
  title: string.isRequired,
  query: shape({
    clientId: string,
    country: string,
  }),
};
