import * as R from 'ramda';
import styled from 'styled-components';
import React, { useContext } from 'react';
import { bool } from 'prop-types';
import { useMapConfigToTableProps } from '@poly/admin-ui';
import { Loader, getThemeColor } from '@poly/admin-book';

import {
  AgingReportTableS,
  AgingReportTableCard,
} from '../APAgingReport/styledComponents.js';
import { EmptyListMessage } from '../../components/EmptyListMessage.js';
import { PageWithSearchHeaderContext } from '../../components/PageWithSearchHeader.js';
import { WhiteBodyWrapper } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { sumAndFormatTotal } from '../APAgingReport/APAgingReportTableComp.js';
import {
  ARAgingReportTableComp,
  getClientInvoiceTotal,
  ARAgingReportFooter,
} from './ARAgingReportTableComp.js';
import { invoicesPropTypes } from './prop-types.js';

const ARAgingReportFooterContainerS = styled(ARAgingReportFooter)`
  background-color: ${getThemeColor(['white'])} !important;
`;

// getARAgingReportTotalByMapper :: [ARAgingReportClient] -> Function -> String
const getARAgingReportTotalByMapper = (mapper) =>
  R.compose(
    sumAndFormatTotal,
    R.map(mapper),
    R.flatten,
    R.map(R.propOr([], 'invoices')),
  );

function TotalFooter({ rows }) {
  return (
    <ARAgingReportFooterContainerS>
      <td>Total</td>
      <td>
        {getARAgingReportTotalByMapper(getClientInvoiceTotal(-Infinity, 0))(
          rows,
        )}
      </td>
      <td>
        {getARAgingReportTotalByMapper(getClientInvoiceTotal(0, 30))(rows)}
      </td>
      <td>
        {getARAgingReportTotalByMapper(getClientInvoiceTotal(30, 60))(rows)}
      </td>
      <td>
        {getARAgingReportTotalByMapper(getClientInvoiceTotal(60, 90))(rows)}
      </td>
      <td>
        {getARAgingReportTotalByMapper(getClientInvoiceTotal(90, Infinity))(
          rows,
        )}
      </td>
      <td>{getARAgingReportTotalByMapper(R.prop('amount'))(rows)}</td>
    </ARAgingReportFooterContainerS>
  );
}

TotalFooter.propTypes = {
  rows: invoicesPropTypes.isRequired,
};

export function ARAgingReportTable({ report, isPrintPDF, ...restTableProps }) {
  const arAgingReportTableConfig = [
    [
      '',
      (props) => <ARAgingReportTableComp isPrintPDF={isPrintPDF} {...props} />,
    ],
  ];

  const tableProps = useMapConfigToTableProps(
    R.identity,
    arAgingReportTableConfig,
    report,
  );

  return (
    <AgingReportTableS
      {...tableProps}
      {...restTableProps}
      TableFooter={TotalFooter}
      moreScrollIconPosition="38px"
      showScrollBar
      overflow="auto"
    />
  );
}

ARAgingReportTable.propTypes = {
  report: invoicesPropTypes,
  isPrintPDF: bool,
};

export function ARAgingReportTableContainer({
  report,
  loading,
  ...restTableProps
}) {
  const { contentHeight } = useContext(PageWithSearchHeaderContext);
  return loading ? (
    <Loader />
  ) : (
    <WhiteBodyWrapper height={contentHeight}>
      <AgingReportTableCard height="100%">
        {R.isEmpty(report) ? (
          <EmptyListMessage />
        ) : (
          <ARAgingReportTable report={report} {...restTableProps} />
        )}
      </AgingReportTableCard>
    </WhiteBodyWrapper>
  );
}

ARAgingReportTableContainer.propTypes = {
  loading: bool.isRequired,
  report: invoicesPropTypes.isRequired,
};
