import {
  func,
  bool,
  shape,
  object,
  string,
  arrayOf,
  oneOfType,
} from 'prop-types';
import {
  Route,
  Routes,
  Outlet,
  useLocation,
  Link as RouterLink,
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom';
import React from 'react';
import * as R from 'ramda';
import { createBrowserHistory } from 'history';

export function Link({ href, ...props }) {
  const location = useLocation();

  let link = href;

  if (R.is(String, href)) {
    const parser = document.createElement('a');
    parser.href = href;

    link = R.pick(['pathname', 'search', 'hash'], parser);
  }

  return (
    <RouterLink
      to={link}
      state={{ previous: location }}
      {...R.omit(['isActive', 'projectId', 'isFileTab'], props)}
      {...{ 'data-testid': 'link' }}
    />
  );
}

Link.propTypes = { href: oneOfType([string, object]) };

export const history = createBrowserHistory({ window });

// based on https://reactrouter.com/docs/en/v6/api#unstable_historyrouter
// we need to implement it to keep access to history
export function RouterProvider(props) {
  return <HistoryRouter {...props} history={history} />;
}

export function RoutesGenerator({ config }) {
  return (
    <RouterProvider>
      <Routes>
        {config.map(({ layout: Layout, routes }) => (
          <Route
            key={routes[0][0]}
            element={
              <Layout>
                <Outlet />
              </Layout>
            }
          >
            {routes.map(([path, Component]) => (
              <Route key={path} path={path} element={<Component />} />
            ))}
          </Route>
        ))}
      </Routes>
    </RouterProvider>
  );
}

RoutesGenerator.propTypes = {
  config: arrayOf(
    shape({
      layout: func.isRequired,
      routes: arrayOf(arrayOf(oneOfType([string, func, bool, object])))
        .isRequired,
    }),
  ).isRequired,
};
