import { gql } from '@apollo/client';
import * as R from 'ramda';

import { useReactiveQuery } from '@poly/client-utils';

const purchaseOrderDetailQuery = gql`
  query purchaseOrderDetailQuery($purchaseOrderId: ID!) {
    adminPurchaseOrder(id: $purchaseOrderId) {
      _id
      poNumber
      displayName
      client {
        _id
        name
        status
        configs {
          adminApp {
            allowProjectCreation
          }
        }
      }
      properties {
        _id
        name
      }
      authorizedBy {
        _id
        fullName
      }
      endDate
      startDate
      initialBalance
      currentBalance
      status
      remarks
      lowBalancePercent
      type
      notificationContactsEmails
    }
  }
`;

export const purchaseOrderDetailSubs = gql`
  subscription purchaseOrderDetailSubs($input: SingleDocSubInput!) {
    adminPurchaseOrderChanged(input: $input) {
      id
      type
    }
  }
`;

export const usePurchaseOrderDetail = ({ purchaseOrderId, skip }) => {
  const queryOptions = {
    variables: { purchaseOrderId },
    fetchPolicy: 'network-only',
    skip,
  };

  const subscriptionOptions = {
    variables: { input: { id: purchaseOrderId } },
    skip,
  };
  const { data, loading } = useReactiveQuery(
    purchaseOrderDetailQuery,
    purchaseOrderDetailSubs,
    { queryOptions, subscriptionOptions },
  );

  return { purchaseOrder: R.prop('adminPurchaseOrder', data), loading };
};
