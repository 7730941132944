import React from 'react';
import parseReactHtml from 'html-react-parser';
import { string } from 'prop-types';

export function HtmlParser({ html }) {
  return <div>{parseReactHtml(html)}</div>;
}

HtmlParser.propTypes = {
  html: string.isRequired,
};
