import { useEffect, useCallback } from 'react';
import { debounce } from '@poly/utils';

import { SubcontractorAgreementTitle } from './constants.js';
import { DEFAULT_DOCUMENT_TITLE } from '../../sidebars/useReactiveSidebarDocumentTitle.js';

export const useSubcontractorAgreementPrintLogic = (isPrint) => {
  const openPrintWindowDebounced = useCallback(
    debounce(500)(() => {
      window.print();
    }),
    [],
  );

  useEffect(() => {
    const handleAfterPrint = () => {
      document.title = DEFAULT_DOCUMENT_TITLE;

      if (isPrint) {
        if (/mobile/i.test(navigator.userAgent)) {
          window.addEventListener('focus', () => window.close());
        } else {
          window.close();
        }
      }
    };

    const handleBeforePrint = () => {
      document.title = SubcontractorAgreementTitle;
    };

    window.addEventListener('afterprint', handleAfterPrint);
    window.addEventListener('beforeprint', handleBeforePrint);

    if (isPrint) {
      openPrintWindowDebounced();
    }

    return () => {
      window.removeEventListener('afterprint', handleAfterPrint);
      window.removeEventListener('beforeprint', handleBeforePrint);
    };
  }, [isPrint]);
};
