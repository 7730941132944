import styled from 'styled-components';
import { prop, propOr } from 'ramda';

import { getThemeFont, getThemeColor } from '../utils.js';

const getFont = ({ weight = 'regular', ...props }) =>
  getThemeFont([weight])(props);

const getColor = ({ color = ['darkest'], ...props }) =>
  getThemeColor(color)(props);

export const Text = styled.div`
  font-weight: ${getFont};
  font-size: ${prop('size')};
  color: ${getColor};
  letter-spacing: ${propOr('normal', 'spacing')};
  line-height: ${propOr('normal', 'lineHeight')};
`;
