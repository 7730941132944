import React from 'react';
import styled from 'styled-components';
import { shape, string } from 'prop-types';
import { Popover, TextButton } from '@poly/admin-book';
import { getMagicEmailForDocument } from '@poly/utils';
import { copyToClipBoard } from '@poly/client-utils';

import { useNotificationState } from '../../../hooks/useNotificationState.js';

const PopoverS = styled(Popover)`
  button {
    font-size: 12px;
  }
`;

const PopoverContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
`;

// getMagicEmailWithAddress :: Object -> Email
const getMagicEmailWithAddress = getMagicEmailForDocument(
  process.env.MAGIC_EMAIL,
);

export function CopyMagicEmailButton({ collection, entity }) {
  const { _id } = entity;

  const { showSuccessNotification } = useNotificationState();

  const onCopyEmailClick = (isClientUpdate) => () => {
    const magicEmail = getMagicEmailWithAddress({
      collection,
      isClientUpdate,
      documentId: _id,
    });

    copyToClipBoard(magicEmail);
    showSuccessNotification(`Copied ${magicEmail}`);
  };

  return (
    <PopoverS
      disabled={!_id}
      position="bottom"
      title="Magic Email"
      content={
        <PopoverContentWrapper>
          <TextButton onClick={onCopyEmailClick(false)}>Normal</TextButton>
          <TextButton onClick={onCopyEmailClick(true)}>
            Client Update
          </TextButton>
        </PopoverContentWrapper>
      }
    />
  );
}

CopyMagicEmailButton.displayName = 'CopyMagicEmailButton';
CopyMagicEmailButton.propTypes = {
  collection: string.isRequired,
  entity: shape({ _id: string.isRequired }).isRequired,
};
