import React from 'react';
import { func } from 'prop-types';
import { TableToolBarWrapper } from '@poly/admin-book';

import { TasksTable } from '../../tables/tasksTable/TasksTable.js';
import { AddTaskBtn } from '../../tables/tasksTable/TasksTableToolBar.js';
import { TableCardWithPaginationContainer } from '../../../components/index.js';

export function TasksTabComp({ onAddTask, ...props }) {
  return (
    <TableCardWithPaginationContainer>
      <TasksTable
        {...props}
        toolBar={
          <TableToolBarWrapper>
            <AddTaskBtn onClick={onAddTask} />
          </TableToolBarWrapper>
        }
      />
    </TableCardWithPaginationContainer>
  );
}

TasksTabComp.propTypes = { onAddTask: func.isRequired };
