import * as R from 'ramda';

// selectFileNameWithUpload :: UploadWithName -> UploadWithName
const selectFileNameWithUpload = R.when(
  R.is(Object),
  R.compose(
    R.when(R.propSatisfies(R.isEmpty, 'fileName'), R.omit(['fileName'])),
    R.pick(['upload', 'fileName']),
  ),
);

// selectSingleFileNameWithUpload :: [UploadWithName] -> UploadWithName
export const selectSingleFileNameWithUpload = R.compose(
  selectFileNameWithUpload,
  R.head,
  R.defaultTo([]),
);
