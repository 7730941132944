import * as R from 'ramda';
import React from 'react';
import { arrayOf, object, shape, string } from 'prop-types';
import styled from 'styled-components';
import { getThemeColor, Text, UserAvatar } from '@poly/admin-book';
import { SYSTEM_USER_NAME } from '@poly/constants';
import { firstLettersOfEachWord, formatDateTime } from '@poly/utils';
import {
  FlexColumn,
  MessageWithMentions,
  PolyLogo,
  UpdatesFileLink,
} from '@poly/admin-ui';

import { FlexContainer } from '../../../components/FlexContainer.js';
import { FlexSpaceBetweenFullWidth } from '../../forms/assignSupplierForm/styles.js';

const UpdateItemContainer = styled(FlexContainer)`
  padding: 15px;
  width: 100%;
`;

const CreatedUpdateItemContainer = styled(UpdateItemContainer)`
  border: 1px solid ${getThemeColor(['midLight'])};
  margin-top: 15px;
  background: #fff;
`;

const LogoContainer = styled(FlexContainer)`
  margin-right: 15px;
`;

const UpdateContentContainer = styled(FlexColumn)`
  width: 100%;
`;

const UpdateCreatorText = styled(Text)`
  margin-bottom: 10px;
  color: ${getThemeColor(['darkest'])};
  font-size: 12px;
`;

const UpdateDateText = styled(Text)`
  color: ${getThemeColor(['midDark'])};
  font-size: 10px;
`;

const UpdateTitleText = styled(Text)`
  color: ${getThemeColor(['primaryRegular'])};
  font-size: 12px;
  line-height: 18px;
`;

const NotesTitleText = styled(Text)`
  font-size: 18px;
  line-height: 21px;
  color: ${getThemeColor(['darkest'])}
  margin-bottom: 14px;
  margin-top: 24px;
`;

const updatePropTypes = {
  createdAt: string.isRequired,
  message: string.isRequired,
  title: string.isRequired,
  createdBy: shape({
    fullName: string.isRequired,
  }),
  attachments: arrayOf(shape({ url: string.isRequired })),
  // eslint-disable-next-line react/forbid-prop-types
  mentions: object,
};

function MentionsWithFilesSection({ mentions, message, attachments }) {
  return (
    <>
      <MessageWithMentions
        size="12px"
        lineHeight="18px"
        mentions={mentions}
        message={message}
      />
      {attachments?.map((file) => (
        <UpdatesFileLink key={file.url} {...file} />
      ))}
    </>
  );
}

MentionsWithFilesSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  mentions: object,
  message: string,
  attachments: arrayOf(shape({ url: string.isRequired })),
};

function CreatedUpdateComponent({
  createdAt,
  message,
  mentions,
  title,
  createdBy,
  attachments,
}) {
  const { fullName = SYSTEM_USER_NAME } = createdBy;

  return (
    <CreatedUpdateItemContainer>
      <LogoContainer>
        {fullName === SYSTEM_USER_NAME ? (
          <PolyLogo />
        ) : (
          <UserAvatar>{firstLettersOfEachWord(fullName)}</UserAvatar>
        )}
      </LogoContainer>
      <UpdateContentContainer>
        <FlexSpaceBetweenFullWidth>
          <UpdateCreatorText>{fullName}</UpdateCreatorText>
          <UpdateDateText>{formatDateTime(createdAt)}</UpdateDateText>
        </FlexSpaceBetweenFullWidth>
        <UpdateTitleText>{title}</UpdateTitleText>
        <MentionsWithFilesSection
          mentions={mentions}
          message={message}
          attachments={attachments}
        />
      </UpdateContentContainer>
    </CreatedUpdateItemContainer>
  );
}

CreatedUpdateComponent.propTypes = updatePropTypes;

const NoteContainer = styled(UpdateContentContainer)`
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid ${getThemeColor(['midLight'])};
`;

const UpdateTitleWithMargin = styled(UpdateTitleText)`
  margin-top: 10px;
`;

function NoteUpdate({
  createdAt,
  message,
  mentions,
  title,
  createdBy,
  attachments,
}) {
  const { fullName = SYSTEM_USER_NAME } = createdBy;
  return (
    <NoteContainer>
      <FlexSpaceBetweenFullWidth>
        <UpdateCreatorText>{fullName}</UpdateCreatorText>
        <UpdateDateText>{formatDateTime(createdAt)}</UpdateDateText>
      </FlexSpaceBetweenFullWidth>
      <MentionsWithFilesSection
        mentions={mentions}
        message={message}
        attachments={attachments}
      />
      <UpdateTitleWithMargin>{title}</UpdateTitleWithMargin>
    </NoteContainer>
  );
}

NoteUpdate.propTypes = updatePropTypes;

const isCreatedUpdate = R.propSatisfies(R.startsWith('Task Created:'), 'title');

export function TaskUpdates({ updates }) {
  const notes = R.reject(isCreatedUpdate, updates);
  const createdUpdate = R.filter(isCreatedUpdate, updates);
  return (
    <>
      {createdUpdate.length > 0 && (
        <CreatedUpdateComponent {...createdUpdate[0]} />
      )}
      {notes.length > 0 && <NotesTitleText>Notes</NotesTitleText>}
      {notes.map((update) => (
        <NoteUpdate key={update._id} {...update} />
      ))}
    </>
  );
}

TaskUpdates.propTypes = {
  updates: arrayOf(shape(updatePropTypes)),
};
