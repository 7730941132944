import { gql } from '@apollo/client';

export const arAgingReportQuery = gql`
  query ARAgingReport($input: ARAgingReportInput) {
    arAgingReport(input: $input) {
      hits {
        _id
        name
        invoices {
          _id
          number
          dueDate
          createdAt
          amount
          project {
            _id
            projectId
            type
          }
        }
      }
      total
    }
  }
`;
