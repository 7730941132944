import { gql } from '@apollo/client';

export const ENTITY_FILES_QUERY = gql`
  query ENTITY_FILES_QUERY($searchInput: CollectionSearchParams!) {
    searchFiles(input: $searchInput) {
      hits {
        _id
        url
        week
        fileType
        fileSize
        fileName
        createdAt
        createdBy {
          fullName
        }
        pinned
      }
      total
    }
  }
`;
