import * as R from 'ramda';
import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';

const getAssetsByQrCodeIdQuery = gql`
  query getAssetsByQrCodeIdQuery($input: CollectionSearchParams!) {
    searchAssets(input: $input) {
      hits {
        name
        qrCodeId
      }
    }
  }
`;

// formatTerms :: { name: String, qrCodeId: String } -> [{ term: { [String]: String } }]
const formatTerms = R.compose(
  R.map(R.objOf('term')),
  R.map(R.apply(R.objOf)),
  R.toPairs,
  R.reject(R.isNil),
);

export const useValidateAssetForm = (asset = {}) => {
  const [code, setCode] = useState(null);
  const [name, setName] = useState(null);

  const { data } = useQuery(getAssetsByQrCodeIdQuery, {
    variables: {
      input: {
        from: 0,
        size: 1,
        query: {
          bool: {
            should: formatTerms({
              qrCodeId: code,
              name,
            }),
          },
        },
      },
    },
    fetchPolicy: 'network-only',
    skip: !code && !name,
  });

  const foundAsset = R.compose(
    R.head,
    R.pathOr([], ['searchAssets', 'hits']),
  )(data);

  return ({ qrCodeId, name: enteredName }) => {
    if (!!qrCodeId && qrCodeId.length === 6) {
      if (qrCodeId === asset.qrCodeId) {
        setCode(null);
      } else {
        setCode(qrCodeId);
      }
    }

    if (enteredName) {
      if (enteredName === asset.name) {
        setName(null);
      } else {
        setName(enteredName);
      }
    }

    if (foundAsset) {
      if (foundAsset.qrCodeId === qrCodeId) {
        return { qrCodeId: 'This QR Code already exists' };
      }
      if (foundAsset.name === enteredName) {
        return { name: 'Asset name already exists' };
      }
    }

    return {};
  };
};
