import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { bool } from 'prop-types';
import { Popover, Icon } from '@poly/admin-book';

const getProjectTimeOrAfterHoursCallWarning = R.compose(
  R.join(' '),
  R.reject(R.isNil),
  R.juxt([
    R.ifElse(
      R.prop('isAfterHoursCall'),
      R.always('Has After Hours Call'),
      R.always(null),
    ),
    R.ifElse(
      R.path(['timeEntries', 0, '_id']),
      R.always('Has Project Time'),
      R.always(null),
    ),
  ]),
);

const PopoverWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: ${R.propOr('-45px', 'positionRight')};
  z-index: 9999;
`;

const PopoverContent = styled.div`
  padding: 10px;
  text-align: start;
`;

export function ProjectTimeOrAfterHoursCallWarning({
  withProjectTimeWarning,
  ...props
}) {
  const warningMsg = getProjectTimeOrAfterHoursCallWarning(props);

  if (!warningMsg || !withProjectTimeWarning) {
    return null;
  }

  return (
    <PopoverWrapper {...props}>
      <Popover
        maxWidth="150px"
        position="left"
        title={<Icon name="yellowWarning" size={16} />}
        content={<PopoverContent>{warningMsg}</PopoverContent>}
      />
    </PopoverWrapper>
  );
}

ProjectTimeOrAfterHoursCallWarning.propTypes = {
  withProjectTimeWarning: bool,
};
