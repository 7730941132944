import { gql, useQuery } from '@apollo/client';

const USER_GROUP_SCHEMA_QUERY = gql`
  query USER_GROUP_SCHEMA_QUERY {
    getUserGroupPermissionSchema {
      _id
      name
      types
      actions {
        name
        permission
      }
    }
  }
`;

export const useUserGroupSchemaQuery = () => {
  const { data, loading } = useQuery(USER_GROUP_SCHEMA_QUERY);

  return { schema: data, loading };
};
