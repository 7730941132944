/* To avoid hard-coded AIT names we're going to put declarations for all here.
 * Note: this module is used to generate appropriate GQL dynamically therefore
 * similar to permissions.js module
 *
 * By default all new AITs would be treated as ID type at GQL level,
 * unless custom type is specified at AccessItemTypesGQLConfigsOverrides
 * (at apps/graph/src/api/user-group/gql/ait-gql-configs.js).
 */
export const CLIENT_APP_AIT = 'clientApp';
export const PROJECT_TYPE_AIT = 'projectType';
export const PO_AMOUNT_AIT = 'poAmount';
export const SUPPLIER_SOURCE_AIT = 'supplierSource';
export const PRIVATE_SUPPLIER_AIT = 'privateSupplier';
export const PARENT_PROJECT_TYPE_AIT = 'parentProjectType';
export const UPDATE_TYPE_AIT = 'updateType';
export const FILE_COLLECTION_AIT = 'fileCollection';

export const CREATED_BY_AIT = 'createdBy';
export const CLIENT_ID_AIT = 'clientId';
export const PROJECT_ID_AIT = 'projectId';
export const PROPERTY_ID_AIT = 'propertyId';
export const TECHNICIAN_ID_AIT = 'technicianId';
export const USER_GROUP_ID_AIT = 'userGroupId';
export const CONTACT_ID_AIT = 'contactId';
export const SUPPLIER_ID_AIT = 'supplierId';
export const ASSET_ID_AIT = 'assetId';
