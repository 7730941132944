import * as R from 'ramda';
import { gql, useMutation } from '@apollo/client';
import { insertParamsIntoURL } from '@poly/utils';
import { useNavigate } from '@poly/client-routing';

import { useNotificationState } from '../../../hooks/useNotificationState.js';
import { prepareCommonSupplierDataForMutation } from '../../forms/supplierForm/form/prepareFormDataForMutation.js';
import { omitSupplierProtectedFieldsIfNoPermission } from '../../forms/supplierForm/edit/formDataToMutation.js';

const CREATE_MASTER_SUPPLIER_MUTATION = gql`
  mutation CREATE_MASTER_SUPPLIER_MUTATION($input: CreateMasterSupplierInput!) {
    createMasterSupplier(input: $input) {
      masterSupplier {
        _id
      }
    }
  }
`;

// prepareDataToMutation :: FormData -> FieldsAccess -> FormData
// FormData = Object
const prepareDataToMutation = R.curry((formData, protectedFieldsAccess) =>
  R.compose(
    omitSupplierProtectedFieldsIfNoPermission(protectedFieldsAccess),
    prepareCommonSupplierDataForMutation,
  )(formData),
);

export const useCreateMasterSupplierMutation = (
  masterSupplierRootUrl,
  supplierSource,
  protectedFieldsAccess,
) => {
  const navigate = useNavigate();
  const { showSuccessNotification } = useNotificationState();
  const [createMasterSupplier] = useMutation(CREATE_MASTER_SUPPLIER_MUTATION);

  return async (formData) => {
    const result = await createMasterSupplier({
      variables: {
        input: {
          supplierSource,
          ...prepareDataToMutation(formData, protectedFieldsAccess),
        },
      },
    });
    showSuccessNotification('Master Supplier Successfully Created');
    const supplierId = R.path(
      ['data', 'createMasterSupplier', 'masterSupplier', '_id'],
      result,
    );
    const link = insertParamsIntoURL({ supplierId })(masterSupplierRootUrl);
    return navigate(link);
  };
};
