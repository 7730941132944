import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, number, shape, string, func } from 'prop-types';
import { centsToDollarsWithFormat, formatDate, applyProp } from '@poly/utils';
import { useMapConfigToTableProps } from '@poly/admin-ui';
import { WindowedTable } from '@poly/admin-book';

import { clientInvoiceStatusOptions } from './constants.js';
import { InvoicePopoverButton } from './InvoicePopoverButton.js';

const SearchClientInvoicesTableS = styled(WindowedTable)`
  tr {
    width: calc(100% - 60px) !important;
  }

  th:nth-child(7),
  td:nth-child(7) {
    width: 50px;
  }
`;

export const CommonSearchClientInvoicesPageTableConfig = [
  ['Invoice #', R.propOr('-', 'number')],
  ['Invoice Date', applyProp(formatDate)('invoiceDate')],
  ['Invoice Due Date', applyProp(formatDate)('dueDate')],
  ['Project #', R.pathOr('-', ['project', 'projectId'])],
  ['Invoice Amount', applyProp(centsToDollarsWithFormat)('amount')],
  ['Status', applyProp(R.prop(R.__, clientInvoiceStatusOptions))('status')],
];

const SearchClientInvoicesPageTableConfig = [
  ...CommonSearchClientInvoicesPageTableConfig,
  ['', InvoicePopoverButton],
];

export function SearchClientInvoicesPageTable({
  refetch,
  clientInvoices,
  ...restTableProps
}) {
  const tableProps = useMapConfigToTableProps(
    R.map(R.mergeRight({ refetch })),
    SearchClientInvoicesPageTableConfig,
    clientInvoices,
  );

  return (
    <SearchClientInvoicesTableS
      {...tableProps}
      {...restTableProps}
      moreScrollPosition="0px"
    />
  );
}

SearchClientInvoicesPageTable.propTypes = {
  clientInvoices: arrayOf(
    shape({
      number,
      createdAt: string,
      dueDate: string,
      amount: number,
      status: string,
      project: shape({
        projectId: string,
      }),
    }),
  ),
  refetch: func.isRequired,
};
