import { createAction, handleAction } from 'redux-actions';

export const setMyTasksSortingAction = createAction('SET_MY_TASKS_SORTING');

export const defaultMyTasksSorting = { dir: 1, columnKey: 1 };

export const myTasksSortingReducer = handleAction(
  setMyTasksSortingAction,
  (_, { payload }) => payload,
  defaultMyTasksSorting,
);
