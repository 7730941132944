import styled, { css } from 'styled-components';
import { Select, getThemeColor } from '@poly/admin-book';

export const ProgressiveRangePickerDropdown = styled(Select)`
  .select__placeholder {
    color: ${getThemeColor(['primary'])};
  }
`;

export const CustomRangeModalLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomRangeModalRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  width: 100%;
  padding-top: 10px;

  button:not(:last-child) {
    margin-right: 10px;
  }
`;

const leftSideBorders = css`
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const rightSideBorders = css`
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: none;
`;

const activeBtnStyles = css`
  color: ${getThemeColor(['white'])};
  background-color: ${getThemeColor(['accent2Lighter7'])};
`;

export const CustomRangeModalTypeBtn = styled.div`
  display: flex;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  padding: 3px 10px;
  border: 1px solid ${getThemeColor(['primaryLighter8'])};

  ${({ isActive }) => isActive && activeBtnStyles};
  ${({ last }) => (last ? rightSideBorders : leftSideBorders)};
`;
