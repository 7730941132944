import { gql } from '@apollo/client';

export const SERVICE_TYPES_BY_SEARCH = gql`
  query SERVICE_TYPES_BY_SEARCH($searchInput: CollectionSearchParams!) {
    searchServiceTypes(input: $searchInput) {
      hits {
        _id
        name
        status
        description
      }
      total
    }
  }
`;

export const SERVICE_TYPES_SIMILAR_SEARCH = gql`
  query SERVICE_TYPES_SIMILAR_SEARCH($searchInput: CollectionSearchParams!) {
    searchServiceTypes(input: $searchInput) {
      hits {
        _id
        name
      }
      total
    }
  }
`;

export const SERVICE_TYPE_DETAILS = gql`
  query SERVICE_TYPE_DETAILS($id: ID!) {
    serviceType(id: $id) {
      _id
      name
      status
      description
    }
  }
`;
