import React from 'react';
import * as R from 'ramda';
import { arrayOf, number, shape, string } from 'prop-types';
import styled from 'styled-components';

const LinkS = styled.a`
  font-size: ${R.propOr('12px', 'size')}!important;
  line-height: ${R.propOr('18px', 'lineHeight')}!important;
`;

export function LinkToGoogleMap({ address, text, ...props }) {
  const coordinates = R.path(['geo', 'coordinates'])(address);

  return (
    <LinkS
      href={`http://maps.google.com/maps?q=&layer=c&cbll=${coordinates[1]},${coordinates[0]}`}
      rel="noopener noreferrer"
      target="_blank"
      {...props}
    >
      {text}
    </LinkS>
  );
}

LinkToGoogleMap.propTypes = {
  text: string,
  address: shape({ geo: shape({ coordinates: arrayOf(number) }) }),
};
