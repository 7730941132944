import { useState } from 'react';
import * as R from 'ramda';

export const useSelectableTableRowsProps = (
  selectedRows,
  setSelectedRows,
  data,
) => {
  const toggleRow = (rowId) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter((x) => x !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  const toggleSelectAll = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(data.map((x) => x._id));
    }
  };

  return { toggleRow, toggleSelectAll };
};

export const useSingleSelectableTable = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const toggleRow = (rowId) =>
    R.includes(rowId, selectedRows)
      ? setSelectedRows([])
      : setSelectedRows([rowId]);

  return { toggleRow, selectedRows };
};
