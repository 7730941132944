import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { func, shape, string } from 'prop-types';
import { MainHeader, PageHeaderContainer, Select } from '@poly/admin-book';
import {
  supplierDocumentRequestTypes,
  supplierDocumentNames,
} from '@poly/constants';

import { SupplierAndMasterSupplierSelect } from './SupplierAndMasterSupplierSelect.js';

const FlexRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: ${R.propOr(0, 'gap')};
`;

const SelectWrapper = styled.div`
  width: 250px;
`;

const supplierDocumentNamesIU = {
  ...supplierDocumentNames,
  [supplierDocumentRequestTypes.W9]: 'Supplier Onboarding',
};

// getDocumentTypesSelectOptions :: DocumentTypes -> [Option]
const getDocumentTypesSelectOptions = R.compose(
  R.map(
    R.applySpec({
      value: R.identity,
      label: R.prop(R.__, supplierDocumentNamesIU),
    }),
  ),
  R.values,
);

function DocumentTypesSelect(props) {
  const options = getDocumentTypesSelectOptions(supplierDocumentRequestTypes);

  const selectProps = {
    ...props,
    options,
    placeholder: 'Document types',
  };

  return <Select {...selectProps} />;
}

export function RequestedSupplierDocumentsReviewHeader({ filter, setFilter }) {
  const onChangeSupplier = (supplier) => setFilter({ ...filter, supplier });

  const onChangeDocumentType = (documentType) =>
    setFilter({ ...filter, documentType });

  return (
    <PageHeaderContainer>
      <FlexRow>
        <MainHeader>New Supplier Documents for Review </MainHeader>
        <FlexRow gap="16px">
          <SelectWrapper>
            <SupplierAndMasterSupplierSelect
              value={filter?.supplier}
              onChange={onChangeSupplier}
            />
          </SelectWrapper>
          <SelectWrapper>
            <DocumentTypesSelect
              value={filter?.documentType}
              onChange={onChangeDocumentType}
            />
          </SelectWrapper>
        </FlexRow>
      </FlexRow>
    </PageHeaderContainer>
  );
}

RequestedSupplierDocumentsReviewHeader.propTypes = {
  setFilter: func.isRequired,
  filter: shape({ supplierId: string, documentType: string }).isRequired,
};
