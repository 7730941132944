import { useMutation } from '@apollo/client';
import { useOnSubmitSetStopSubmitting } from '@poly/admin-ui';

export const useSubmit = (mutation, processId, onSuccess) => {
  const [mutate] = useMutation(mutation);

  const submitHandler = (accountingPeriodId) =>
    mutate({
      variables: { input: { accountingPeriodId } },
    }).then(onSuccess);

  const { onSubmit } = useOnSubmitSetStopSubmitting(processId, submitHandler);

  return onSubmit;
};
