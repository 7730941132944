import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import styled from 'styled-components';
import { FilePicker, Popover, Icon, FormField, Select } from '@poly/admin-book';
import { getExtensionByFileName } from '@poly/utils';
import {
  baseFileValidators,
  validateFilesFunc,
  validateFileBase,
} from '@poly/client-utils';

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #d9d9d9;
`;

export const ContentWrapper = styled(FlexColumn)`
  display: flex;
  flex-direction: column;
  padding: 32px 25px;
  width: 650px;
  background: #fff;
  box-shadow: 0px 2px 0px 8px rgba(0, 0, 0, 0.0004);
  border-radius: 5px;
  max-height: calc(100% - 50px);
  overflow: auto;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #cfcece;
  }
  @media (max-width: 1024px) {
    width: 100%;
    height: 100vh;
  }
`;

export const Text = styled.p`
  padding: ${R.propOr(0, 'padding')};
  margin: ${R.propOr(0, 'margin')};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
`;

export const LinkS = styled.a`
  font-weight: 400;
  font-size: 14px;
  color: #436cbe;
  text-decoration: underline;
`;

export const ForSectionWrapper = styled.div`
  padding-top: 24px;
`;

export const ForSectionTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
`;

export const FormFieldWrapper = styled.div`
  width: 100%;
  max-width: ${R.propOr('292px', 'maxWidth')};
`;

export const TwoColumnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const Label = styled.label`
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
  margin-bottom: 8px;
`;

function DocumentsFilePickerBase(props) {
  return (
    <FilePicker
      {...props}
      {...{
        dragAreaHeight: '34px',
        isFileNameLimited: false,
        isFileNameChangeable: false,
        multiple: false,
      }}
    />
  );
}

const IconS = styled(Icon)`
  margin-bottom: 8px;
  &:hover path {
    fill: #436cbe;
  }
`;

const PopoverContent = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  background-color: #12347a;
  padding: 12px 20px;
  color: #fff;
  border-radius: 5px;
`;

const FlexColumnCenter = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const DocumentHintText = styled.p`
  margin: 0;
  padding: 0;
`;

function FileExtensionPopover({ documentType, certificationUrl }) {
  return (
    <Popover
      position="top"
      title={<IconS name="confirm" size={11} />}
      bgColor="#12347a"
      disableBoxShadow
      content={
        <PopoverContent>
          {certificationUrl && (
            <DocumentHintText>
              Learn more about {documentType}
              <CertificateLink target="_blank" href={certificationUrl}>
                Here
              </CertificateLink>
            </DocumentHintText>
          )}
          Accepts only pdf, png, jpg, jpeg
        </PopoverContent>
      }
    />
  );
}

FileExtensionPopover.propTypes = {
  documentType: string,
  certificationUrl: string,
};

const allowedRequestedDocumentsExtensions = ['png', 'jpg', 'jpeg', 'pdf'];

const documentRequestFileValidators = [
  R.ifElse(
    R.complement(R.isEmpty),
    R.always({}),
    R.always({ commonError: 'Document is required' }),
  ),
  ...baseFileValidators,
  validateFileBase(
    R.compose(
      R.includes(R.__, allowedRequestedDocumentsExtensions),
      R.toLower,
      getExtensionByFileName,
      R.path(['upload', 'name']),
    ),
    `Document accepts only: ${R.join(
      ', ',
      allowedRequestedDocumentsExtensions,
    )}`,
  ),
];

export function DocumentsFilePicker({ label, name, maxWidth, ...props }) {
  return (
    <FormFieldWrapper maxWidth={maxWidth}>
      <FlexColumnCenter>
        <Label>{label} *</Label>
        <FileExtensionPopover {...props} />
      </FlexColumnCenter>
      <FormField
        name={name}
        Component={DocumentsFilePickerBase}
        validate={validateFilesFunc(documentRequestFileValidators)}
      />
    </FormFieldWrapper>
  );
}

DocumentsFilePicker.propTypes = {
  label: string.isRequired,
  name: string.isRequired,
  maxWidth: string,
};

function YesNoSelect(props) {
  const options = [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ];

  return <Select required options={options} {...props} />;
}

const CertificateLink = styled(LinkS)`
  color: #fff;
  font-size: 11px;
  margin-left: 3px;
  :hover {
    color: #fff;
  }
`;

export function YesNoSelectWithPopover({
  name,
  label,
  documentType,
  certificationUrl,
}) {
  return (
    <FormFieldWrapper>
      <FlexColumnCenter>
        <Label>{label} </Label>
        <Popover
          position="top"
          title={<IconS name="confirm" size={11} />}
          bgColor="#12347a"
          disableBoxShadow
          content={
            <PopoverContent>
              Learn more about {documentType}
              <CertificateLink target="_blank" href={certificationUrl}>
                Here
              </CertificateLink>
            </PopoverContent>
          }
        />
      </FlexColumnCenter>
      <FormField name={name} Component={YesNoSelect} />
    </FormFieldWrapper>
  );
}

YesNoSelectWithPopover.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  documentType: string.isRequired,
  certificationUrl: string.isRequired,
};
