import * as R from 'ramda';
import styled from 'styled-components';
import React, { memo, useEffect } from 'react';
import { arrayOf, string, number, shape, func, bool } from 'prop-types';
import { getThemeColor, getThemeFont, Icon, FormField } from '@poly/admin-book';
import { MoneyInput } from '@poly/admin-ui';
import { formatTotal, propEqLegacy } from '@poly/utils';

import { FlexCenterAlign } from '../../components/FlexContainer.js';
import { ClosingAccountsSelector } from './ClosingAccountsSelector.js';
import { InvoiceLinesSectionTitle } from '../CreateClientInvoice/styled.js';
import { validateRequiredField } from '../PaySuppliersPage/paySuppliersUtils/validationUtils.js';
import { useClosingAccountsQuery } from './useClosingAccountsQuery.js';
import {
  formatAccrualBasisClosingLine,
  formatCashBasisClosingLine,
  findAccountByCode,
} from './helpers.js';

const AccountSelectContainer = styled(FlexCenterAlign)`
  width: 32%;
`;

const BalanceContainer = styled(FlexCenterAlign)`
  width: 9%;
  font-size: 14px;
  font-weight: normal;
`;

const AmountInputContainer = styled(FlexCenterAlign)`
  width: 15%;
`;

const RemoveBtnContainer = styled(FlexCenterAlign)`
  width: 5%;
`;

const DeleteIcon = styled(Icon).attrs((props) => ({
  color: getThemeColor(['secondaryMid'])(props),
}))``;

const FieldTitle = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${getThemeColor(['primaryLighter3'])};
  font-weight: ${getThemeFont(['medium'])};
  padding-bottom: 5px;
`;

export function CloseAccountBalanceLineTitle() {
  return (
    <InvoiceLinesSectionTitle>
      <AccountSelectContainer>
        <FieldTitle>Account</FieldTitle>
      </AccountSelectContainer>
      <BalanceContainer>
        <FieldTitle>Balance Before</FieldTitle>
      </BalanceContainer>
      <AmountInputContainer>
        <FieldTitle>Debit</FieldTitle>
      </AmountInputContainer>

      <AmountInputContainer>
        <FieldTitle>Credit</FieldTitle>
      </AmountInputContainer>
      <RemoveBtnContainer />
      <BalanceContainer>
        <FieldTitle>Balance After</FieldTitle>
      </BalanceContainer>
    </InvoiceLinesSectionTitle>
  );
}

export const CloseAccountBalanceLine = memo(
  ({ name, index, fields: { value: values, remove, update } }) => {
    const {
      isCreditNormal,
      balance = 0,
      debit = 0,
      credit = 0,
      account,
      isCashBasis,
    } = values[index];
    const balanceAfter = isCreditNormal
      ? balance + (credit - debit)
      : balance + (debit - credit);

    const accountCode = R.prop('code', account);

    const isRetainedEarningsLine = propEqLegacy(
      'is_retained_earnings_account',
      true,
      account,
    );

    const { closingAccounts } = useClosingAccountsQuery();

    useEffect(() => {
      if (!isRetainedEarningsLine) {
        const currentAccount = findAccountByCode(accountCode, closingAccounts);

        update(index, {
          debit,
          credit,
          ...(isCashBasis
            ? formatCashBasisClosingLine(currentAccount)
            : formatAccrualBasisClosingLine(currentAccount)),
        });
      }
    }, [accountCode]);

    return (
      <InvoiceLinesSectionTitle>
        <AccountSelectContainer>
          <FormField
            name={`${name}.account`}
            Component={ClosingAccountsSelector}
            additionalProps={{
              width: '100%',
              required: true,
              disabled: isRetainedEarningsLine,
            }}
            validator={validateRequiredField('Account is required')}
          />
        </AccountSelectContainer>
        <BalanceContainer>{formatTotal(balance)}</BalanceContainer>
        <AmountInputContainer>
          <FormField
            name={`${name}.debit`}
            Component={MoneyInput}
            additionalProps={{
              width: '100%',
              disabled: isRetainedEarningsLine,
            }}
          />
        </AmountInputContainer>

        <AmountInputContainer>
          <FormField
            name={`${name}.credit`}
            Component={MoneyInput}
            additionalProps={{
              width: '100%',
              disabled: isRetainedEarningsLine,
            }}
          />
        </AmountInputContainer>
        <RemoveBtnContainer>
          {!isRetainedEarningsLine && (
            <DeleteIcon name="delete" size={14} onClick={() => remove(index)} />
          )}
        </RemoveBtnContainer>
        <BalanceContainer>{formatTotal(balanceAfter)}</BalanceContainer>
      </InvoiceLinesSectionTitle>
    );
  },
  (oldProps, newProps) =>
    R.equals(
      oldProps.fields.value[oldProps.index],
      newProps.fields.value[newProps.index],
    ),
);

CloseAccountBalanceLine.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  fields: shape({
    value: arrayOf(
      shape({
        account: shape({ _id: string.isRequired, code: string.isRequired }),
        isCashBasis: bool,
        isCreditNormal: bool,
        balance: number,
        debit: number,
        credit: number,
      }),
    ),
    remove: func.isRequired,
    update: func.isRequired,
  }).isRequired,
};
