import styled from 'styled-components';
import { getThemeColor, Icon } from '@poly/admin-book';

export const DeleteLineIcon = styled(Icon).attrs((props) => ({
  color: getThemeColor(['secondaryMid'])(props),
}))`
  position: absolute;
  right: 0;
  top: 13px;
  cursor: pointer;
`;
