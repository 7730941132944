import React from 'react';
import { string } from 'prop-types';
import { InvoicesStatuses, InvoiceTypes } from '@poly/constants';
import { useLocation, useNavigate } from '@poly/client-routing';
import { insertParamIntoURL } from '@poly/utils';
import { useModalContext } from '@poly/admin-ui';
import { PopoverLink } from '@poly/admin-book';

import { routesNames } from '../../routes/index.js';
import { EditProjectInvoiceForm } from '../../sidebars/ProjectSidebar/forms/EditProjectInvoiceForm.js';
import { supplierInvoiceFormId } from '../../modules/forms/supplierInvoiceForm/constants.js';
import { SupplierInvoiceModalLayout } from '../../modules/forms/supplierInvoiceForm/supplierInvoiceForm.js';

export function EditSupplierInvoiceButton({ _id, type, status }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { openModal, closeModal } = useModalContext();

  const onCancel = () => closeModal(supplierInvoiceFormId);

  const onClick = () => {
    if (type === InvoiceTypes.supplier) {
      navigate(
        insertParamIntoURL('invoiceId', routesNames.EDIT_SUPPLIER_INVOICE, _id),
        { state: { previous: location } },
      );
    } else {
      openModal({
        id: supplierInvoiceFormId,
        formId: supplierInvoiceFormId,
        Layout: SupplierInvoiceModalLayout,
        content: <EditProjectInvoiceForm invoiceId={_id} onCancel={onCancel} />,
      });
    }
  };

  return (
    status !== InvoicesStatuses.VOIDED && (
      <PopoverLink {...{ onClick }}>Edit Invoice</PopoverLink>
    )
  );
}

EditSupplierInvoiceButton.propTypes = {
  type: string,
  status: string.isRequired,
  _id: string.isRequired,
};
