import React from 'react';
import { LinkButton } from '@poly/admin-book';
import { shape, string } from 'prop-types';
import { useModalContext } from '@poly/admin-ui';

import { ProjectPrintToPDFForm } from './ProjectPrintToPDFForm.js';

const projectPrintToPDFFormId = 'projectPrintToPDFFormId';

export function ProjectPrintToPDFButton({ project }) {
  const { closeModal, openModal } = useModalContext();

  const onCancel = () => closeModal(projectPrintToPDFFormId);

  const onClick = () =>
    openModal({
      id: projectPrintToPDFFormId,
      title: 'Print to PDF Settings',
      formId: projectPrintToPDFFormId,
      btnCaption: 'Save',
      minWidth: '300px',
      content: (
        <ProjectPrintToPDFForm
          formId={projectPrintToPDFFormId}
          project={project}
          onCancel={onCancel}
        />
      ),
    });

  return <LinkButton onClick={onClick}>Print to PDF</LinkButton>;
}

ProjectPrintToPDFButton.propTypes = {
  project: shape({
    _id: string,
  }),
};
