import React from 'react';
import * as R from 'ramda';
import { object, oneOfType, string, array } from 'prop-types';
import { useUpdateQueryParams } from '@poly/client-routing';
import { useOutSidebarContext } from '@poly/client-utils';
import { insertParamsIntoURL } from '@poly/utils';
import { SidebarWidth } from '@poly/admin-ui';

import { SidebarIDs } from '../constants.js';
import { ClientSidebar } from './ClientSidebar.js';
import { routesNames } from '../../routes/constants.js';
import { LinkToSidebar } from '../../components/LinkToSidebar.js';
import { useClearPristineState } from '../useClearPristineOnSidebarMount.js';

export function ClientLink({
  _id,
  name,
  children,
  queryParams = {},
  ...restProps
}) {
  const linkParams = R.mergeDeepLeft(
    { query: { [SidebarIDs.client]: _id } },
    queryParams,
  );

  const linkProps = {
    linkParams,
    entityCardLink: insertParamsIntoURL(
      { clientId: _id },
      routesNames.CLIENT_CARD,
    ),
    ...restProps,
  };

  return <LinkToSidebar {...linkProps}>{children || name}</LinkToSidebar>;
}

ClientLink.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  queryParams: object,
  _id: string.isRequired,
  name: oneOfType([string, array, object]),
  children: oneOfType([string, object]),
};

export const useOpenClientSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();

  return (clientId, skipSteady) =>
    openOutSidebar(
      {
        alwaysFirst: true,
        width: SidebarWidth,
        id: SidebarIDs.client,
        content: clientId ? <ClientSidebar {...{ clientId }} /> : null,
      },
      skipSteady,
    );
};

export const useNavigateToClientSidebar = () => {
  const updateQueryParams = useUpdateQueryParams();
  const clearPristine = useClearPristineState();

  return (clientId) => {
    clearPristine();
    updateQueryParams({ [SidebarIDs.client]: clientId });
  };
};
