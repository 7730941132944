import React from 'react';
import { useModalContext, useNotificationState } from '@poly/admin-ui';
import { LinkButton } from '@poly/admin-book';
import { func, string } from 'prop-types';
import {
  addHierarchyFormId,
  AddPropertyHierarchyFrom,
} from './AddPropertyHierarchyForm.js';

export function AddPropertyHierarchyButton({ clientId, onSuccess }) {
  const { openModalForm, closeModal } = useModalContext();
  const { showSuccessNotification } = useNotificationState();

  const onSuccessWithShowNotification = (hierarchy) => {
    onSuccess(hierarchy);
    showSuccessNotification('Hierarchy was created successfully');
    closeModal(addHierarchyFormId);
  };
  const onClick = () => {
    openModalForm({
      title: 'New Hierarchy',
      btnCaption: 'Submit',
      id: addHierarchyFormId,
      formId: addHierarchyFormId,
      content: (
        <AddPropertyHierarchyFrom
          clientId={clientId}
          onSuccess={onSuccessWithShowNotification}
        />
      ),
    });
  };
  return <LinkButton onClick={onClick}>Add Hierarchy</LinkButton>;
}

AddPropertyHierarchyButton.propTypes = {
  clientId: string,
  onSuccess: func.isRequired,
};
