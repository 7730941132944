import * as R from 'ramda';
import { func, shape, instanceOf, bool } from 'prop-types';
import React, { useEffect } from 'react';
import { startOfDay, endOfDay, startOfMonth } from 'date-fns';
import { DatePicker, HeaderLinksWrapper } from '@poly/admin-book';
import { alwaysNewDate, ensureIsDate } from '@poly/utils';

import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import { JournalEntryLedgerFilters } from './constants.js';
import { JustifyFlexEnd } from '../../components/FlexContainer.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { FiltersContainer } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { constructAndAdjustDate } from '../VoidSupplierPayments/VoidSupplierPaymentsHeader.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { JournalEntryLedgerExportPDFButton } from './JournalEntryLedgerExportPDFButton.js';
import { journalEntryLedgerResultPropTypes } from './prop-types.js';
import { JournalEntryLedgerExportExcelButton } from './JournalEntryLedgerExportExcelButton.js';

const journalEntryLedgerFiltersDefaults = {
  [JournalEntryLedgerFilters.checkFromDate]: startOfMonth(alwaysNewDate()),
  [JournalEntryLedgerFilters.checkToDate]: endOfDay(alwaysNewDate()),
};

const journalEntryLedgerHeaderConfig = [
  {
    name: JournalEntryLedgerFilters.checkFromDate,
    defaultValue: startOfMonth(alwaysNewDate()),
  },
  {
    name: JournalEntryLedgerFilters.checkToDate,
    defaultValue: endOfDay(alwaysNewDate()),
  },
];

// generateJournalEntryLedgerQuery :: JournalEntryLedgerFilters -> JournalPaymentBatchQuery
const generateJournalEntryLedgerQuery = R.compose(
  constructAndAdjustDate(
    R.compose(startOfDay, ensureIsDate),
    JournalEntryLedgerFilters.checkFromDate,
  ),
  constructAndAdjustDate(
    R.compose(endOfDay, ensureIsDate),
    JournalEntryLedgerFilters.checkToDate,
  ),
  R.defaultTo({}),
);

function JournalEntryLedgerExportWrapper(props) {
  return (
    <HeaderLinksWrapper>
      <JournalEntryLedgerExportPDFButton {...props} />
      <JournalEntryLedgerExportExcelButton {...props} />
    </HeaderLinksWrapper>
  );
}

export function JournalEntryLedgerHeader({ query, setQuery, data, loading }) {
  const { searchFilters, handlers, onReset } = useSearchFilters(
    journalEntryLedgerHeaderConfig,
  );

  const onSearch = () =>
    setQuery(generateJournalEntryLedgerQuery(searchFilters));

  const onResetSearch = () => {
    onReset();
    setQuery(
      generateJournalEntryLedgerQuery(journalEntryLedgerFiltersDefaults),
    );
  };

  // apply initial filters
  useEffect(() => {
    onSearch();
  }, []);

  return (
    <SearchPageHeaderContainer title="Journal Entry Ledger">
      <FiltersContainer>
        <FlexSpaceBetween>
          <SearchHeaderColumn title="Date From" titleWidth="100px">
            <DatePicker
              value={searchFilters.checkFromDate}
              onChange={handlers.checkFromDate}
              width="100%"
              showResetBtn
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn title="Date To" titleWidth="100px">
            <DatePicker
              value={searchFilters.checkToDate}
              onChange={handlers.checkToDate}
              width="100%"
              showResetBtn
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn filterWidth="400px">
            <JustifyFlexEnd>
              <JournalEntryLedgerExportWrapper
                query={query}
                data={data}
                loading={loading}
              />
              <SearchHeaderButton
                size="small"
                styleType="basicPrimary"
                onClick={onResetSearch}
              >
                Reset
              </SearchHeaderButton>
              <SearchHeaderButton
                size="small"
                onClick={onSearch}
                loader={loading}
              >
                Search
              </SearchHeaderButton>
            </JustifyFlexEnd>
          </SearchHeaderColumn>
        </FlexSpaceBetween>
      </FiltersContainer>
    </SearchPageHeaderContainer>
  );
}

JournalEntryLedgerHeader.propTypes = {
  query: shape({
    checkFromDate: instanceOf(Date),
    checkToDate: instanceOf(Date),
  }),
  setQuery: func.isRequired,
  data: shape({
    getManualJournals: journalEntryLedgerResultPropTypes,
  }),
  loading: bool.isRequired,
};
