import * as R from 'ramda';
import { formatDistance, isAfter } from 'date-fns';
import { pathOrNothingUI } from '@poly/client-utils';
import { ensureIsDate, propEqLegacy } from '@poly/utils';

import { EXPIRED_LABEL } from '../constants/general.js';

// getExpiredDateInWords :: Object -> String
export const getExpiredDateInWords = (expiresAt) =>
  isAfter(ensureIsDate(expiresAt), Date.now())
    ? formatDistance(Date.now(), ensureIsDate(expiresAt))
    : EXPIRED_LABEL;

// propOrNothingUI :: String -> Object -> Any
export const propOrNothingUI = R.curry((prop, obj) =>
  pathOrNothingUI([prop], obj),
);

// findByProp :: (String, Any) -> [Any] -> Any
export const findByProp = (name, value) => R.find(propEqLegacy(name, value));
