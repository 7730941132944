import React from 'react';
import * as R from 'ramda';
import { string, shape, bool, arrayOf, number } from 'prop-types';
import {
  NOTHING_UI_STRING,
  supplierEmailsTypes,
  SupplierSources,
  supplierTypes,
} from '@poly/constants';
import {
  BooleanTextWithDot,
  SectionWithCard,
  EntityAddress,
} from '@poly/admin-book';
import { pathOrNothingUI } from '@poly/client-utils';

import {
  EmailLink,
  PhoneLink,
  WebsiteLink,
} from '../../../components/Links.js';
import { SupplierMBEElement } from '../../supplier/SupplierMBEElement.js';
import { SupplierServicesList } from '../../supplier/SupplierServicesList.js';
import { FoodBuyVendorType } from '../../supplier/FoodBuyVendorType.js';
import { MultiEmailsLinks } from '../../../components/MultiEmailsLinks.js';

export function MasterSupplierInfoGeneralSection({
  fax,
  name,
  type,
  isWBE,
  phone,
  emails,
  address,
  website,
  minority,
  service24,
  addressTwo,
  services = [],
  dba,
  foodBuy,
  supplierSource,
  afterHoursPhone,
  isVeteran,
}) {
  return (
    <SectionWithCard
      title="General Information"
      list={[
        {
          title: 'Master Supplier Name',
          elem: name,
          fullWidth: R.equals(type, supplierTypes.SUBCONTRACTOR),
        },
        ...(dba
          ? [
              {
                title: 'DBA',
                elem: dba,
              },
            ]
          : []),
        {
          title: 'Address',
          elem: <EntityAddress {...{ address, addressTwo }} />,
        },
        {
          title: '24/7 Service',
          elem: <BooleanTextWithDot isTrue={service24} />,
        },
        {
          title: 'Office Phone',
          elem: <PhoneLink number={phone} />,
        },
        { title: 'Fax', elem: <PhoneLink number={fax} /> },
        ...(service24
          ? [
              {
                title: 'After Hours Phone',
                elem: <PhoneLink number={afterHoursPhone} />,
              },
            ]
          : []),
        {
          title: 'Office email',
          elem: <EmailLink email={emails[supplierEmailsTypes.GENERAL]} />,
        },
        {
          title: 'Primary Service Email',
          elem: <EmailLink email={emails[supplierEmailsTypes.SERVICE]} />,
        },
        {
          title: 'Secondary Service Emails',
          elem: (
            <MultiEmailsLinks
              emails={emails[supplierEmailsTypes.SECONDARY_SERVICE]}
            />
          ),
        },
        {
          title: 'Accounting Emails',
          elem: (
            <MultiEmailsLinks emails={emails[supplierEmailsTypes.ACCOUNT]} />
          ),
        },
        {
          title: 'Website',
          elem: <WebsiteLink website={website} />,
        },

        {
          title: 'Service Types',
          elem: <SupplierServicesList {...{ services }} />,
        },
        {
          title: 'MBE',
          elem: <SupplierMBEElement minority={minority} />,
        },
        {
          title: 'WBE',
          elem: <BooleanTextWithDot isTrue={isWBE} />,
        },
        ...(supplierSource === SupplierSources.AAC
          ? [
              {
                title: 'Veteran',
                elem: <BooleanTextWithDot isTrue={isVeteran} />,
              },
              {
                title: 'Foodbuy',
                elem: <BooleanTextWithDot isTrue={foodBuy.isEnabled} />,
              },
              {
                title: 'SAP Vendor Number',
                elem: pathOrNothingUI(['sapVendorNumber'], foodBuy),
              },
              {
                title: 'Foodbuy Term Set',
                elem: <span>{foodBuy.termSet || NOTHING_UI_STRING}</span>,
                isHidden: !foodBuy.isEnabled,
              },
              {
                title: 'Foodbuy VA %',
                elem: <span>{foodBuy.vaPercentage || 0} %</span>,
                isHidden: !foodBuy.isEnabled,
              },
              {
                title: 'Foodbuy Vendor Type',
                elem: <FoodBuyVendorType vendorType={foodBuy.vendorType} />,
                isHidden: !foodBuy.isEnabled,
              },
              {
                title: 'Supplier VA Email ',
                elem: <span>{foodBuy.vaEmail || NOTHING_UI_STRING}</span>,
                isHidden: !foodBuy.isEnabled,
              },
            ]
          : []),
      ]}
    />
  );
}

MasterSupplierInfoGeneralSection.propTypes = {
  name: string,
  type: string,
  address: shape({ formatted_address: string }),
  addressTwo: string,
  service24: bool,
  phone: string,
  fax: string,
  emails: shape({
    SECONDARY_SERVICE: arrayOf(string),
    ACCOUNT: arrayOf(string),
    GENERAL: string,
    SERVICE: string,
  }),
  website: string,
  services: arrayOf(
    shape({
      _id: string,
      name: string,
    }),
  ),
  isWBE: bool,
  minority: string,
  dba: string,
  foodBuy: shape({
    isEnabled: bool,
    sapVendorNumber: string,
    vendorType: string,
    vaPercentage: number,
  }),
  supplierSource: string,
  afterHoursPhone: string,
  isVeteran: bool,
};
