import * as R from 'ramda';
import { AccountTypeCategories, NormalBalanceTypes } from '@poly/constants';
import { assocBy, calculateTotal, pathEqLegacy, renameProp } from '@poly/utils';

// isCreditNormalBalance :: AccountingIncomeStatement -> Boolean
const isCreditNormalBalance = pathEqLegacy(
  ['accountType', 'normal_balance'],
  NormalBalanceTypes.CREDIT,
);

// prepareCreditBalance :: { balance: Float } -> { balance: Float }
const prepareCreditBalance = R.over(R.lensProp('balance'), R.multiply(-1));

// prepareParentGLCodeRow :: AccountingIncomeStatement -> AccountingIncomeStatement
const prepareParentGLCodeRow = R.compose(
  assocBy('accountId', R.prop('_id')),
  R.assoc('isParentGLCodeRow', true),
  R.when(isCreditNormalBalance, prepareCreditBalance),
);

// prepareNotClientBalanceRow :: { notClientsBalance: Float } -> { name: String, balance: Float }
const prepareNotClientBalanceRow = R.ifElse(
  R.both(
    R.propIs(Number, 'notClientsBalance'),
    R.propSatisfies(R.complement(R.equals(0)), 'notClientsBalance'),
  ),
  R.applySpec({
    _id: R.compose(R.concat('notClientBalance'), R.prop('_id')),
    isNonClientBalanceRow: R.T,
    accountId: R.prop('_id'),
    code: R.propOr('', 'code'),
    compass_gl_code: R.propOr('', 'compass_gl_code'),
    compass_gl_code_description: R.propOr('', 'compass_gl_code_description'),
    name: R.ifElse(
      isCreditNormalBalance,
      R.always('Non-Client Income'),
      R.always('Non-Client Cost'),
    ),
    clientBalance: R.ifElse(
      isCreditNormalBalance,
      R.compose(
        R.prop('balance'),
        prepareCreditBalance,
        R.applySpec({ balance: R.prop('notClientsBalance') }),
      ),
      R.prop('notClientsBalance'),
    ),
  }),
  R.always([]),
);

// prepareClientBalanceRows :: AccountingIncomeStatement -> [ClientWithBalance]
const prepareClientBalanceRows = ({ _id, ...accountingIncomeStatement }) =>
  R.compose(
    R.map(
      R.compose(
        R.mergeLeft({
          compass_gl_code: accountingIncomeStatement?.compass_gl_code,
          compass_gl_code_description:
            accountingIncomeStatement?.compass_gl_code_description,
          accountId: _id,
        }),
        assocBy('_id', R.path(['client', '_id'])),
        renameProp('balance', 'clientBalance'),
      ),
    ),
    R.propOr([], 'clientsWithBalance'),
    R.when(
      isCreditNormalBalance,
      R.over(R.lensProp('clientsWithBalance'), R.map(prepareCreditBalance)),
    ),
  )(accountingIncomeStatement);

// prepareIncomeStatementRowData :: [AccountingIncomeStatement] -> [AccountingIncomeStatement]
const prepareIncomeStatementRowData = R.compose(
  R.flatten,
  R.map(
    R.juxt([
      prepareParentGLCodeRow,
      prepareClientBalanceRows,
      prepareNotClientBalanceRow,
    ]),
  ),
);

// getIncomeReports :: [AccountingIncomeStatement] -> [AccountingIncomeStatement]
export const getIncomeReports = R.compose(
  prepareIncomeStatementRowData,
  R.filter(
    pathEqLegacy(['accountType', 'category'], AccountTypeCategories.income),
  ),
);

// isCogsAccount :: [String] -> AccountingIncomeStatement -> Boolean
const isCogsAccount = (cogsAccountCodes) =>
  R.pathSatisfies(R.includes(R.__, cogsAccountCodes), [
    'parentAccount',
    'code',
  ]);

// getCogsReports :: [String] -> [AccountingIncomeStatement] -> [AccountingIncomeStatement]
export const getCogsReports = (cogsAccountCodes) =>
  R.compose(
    prepareIncomeStatementRowData,
    R.filter(isCogsAccount(cogsAccountCodes)),
  );

// getExpenseReports :: [String] -> [AccountingIncomeStatement] -> [AccountingIncomeStatement]
export const getExpenseReports = (cogsAccountCodes) =>
  R.compose(
    prepareIncomeStatementRowData,
    R.filter(
      R.both(
        pathEqLegacy(
          ['accountType', 'category'],
          AccountTypeCategories.expense,
        ),
        R.complement(isCogsAccount(cogsAccountCodes)),
      ),
    ),
  );

// getReportDataTotal :: [{ balance: Int }] -> Int
export const getReportDataTotal = calculateTotal(R.prop('balance'));
