import * as R from 'ramda';
import { createExcelExportCell, ExcelExportCellType } from '@poly/client-utils';
import { NOTHING_UI_STRING, SupplierPhoneTypes } from '@poly/constants';
import { propEqLegacy } from '@poly/utils';

// getSupplierPhone :: Supplier -> String
const getSupplierPhone = R.compose(
  R.defaultTo(''),
  R.prop('phone'),
  R.defaultTo({}),
  R.find(propEqLegacy('type', SupplierPhoneTypes.GENERAL)),
  R.pathOr([], ['company', 'phones']),
);

export const getSupplierSearchXLS = (suppliers) => ({
  exportFileName: 'supplier_search_export.xlsx',
  columnWidths: [20, 25, 15, 15, 10, 10],
  rows: [
    R.zipWith(
      createExcelExportCell,
      R.repeat(ExcelExportCellType.tableHeader, 6),
      ['Name', 'Address', 'Phone', 'Rating', 'Hourly Rate', 'Distance'],
    ),
    ...suppliers.map(
      R.compose(
        R.zipWith(
          createExcelExportCell,
          R.repeat(ExcelExportCellType.default, 6),
        ),
        R.juxt([
          R.path(['company', 'name']),
          R.path(['company', 'address', 'formatted_address']),
          getSupplierPhone,
          R.path(['rating', 'average']),
          R.path(['rates', 'hourly']),
          R.propOr(NOTHING_UI_STRING, 'distance'),
        ]),
      ),
    ),
  ],
});
