import React from 'react';

export function PinnedIcon(props) {
  return (
    <svg
      {...props}
      width={20}
      height={20}
      data-name="Layer 1"
      id="Layer_1"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title />
      <path d="M24,13H18.3l-2.75-1.83a1,1,0,0,0-1-.05A1,1,0,0,0,14,12v3H8a1,1,0,0,0,0,2h6v3a1,1,0,0,0,.53.88,1,1,0,0,0,1-.05L18.3,19H24a1,1,0,0,0,1-1V14A1,1,0,0,0,24,13Zm-1,4H18a1,1,0,0,0-.55.17l-1.45,1V13.87l1.45,1A1,1,0,0,0,18,15h5Z" />
    </svg>
  );
}
