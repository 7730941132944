import * as R from 'ramda';

export const updateCacheHandler = R.curry(
  (query, variables, update) => (store) => {
    const data = store.readQuery({
      query,
      variables,
    });

    store.writeQuery({
      query,
      data: update(data),
      variables,
    });
  },
);
