import * as R from 'ramda';

// propEqLegacy :: String -> Any -> Object -> Boolean
// !Deprecated: use original ramda "R.propEq" (ramda update 0.28.0 >> 0.30.1)
export const propEqLegacy = R.curry((prop, equalTo, obj) =>
  R.propEq(equalTo, prop, obj),
);

// pathEqLegacy :: [String] -> Any -> Object -> Boolean
// !Deprecated: use original ramda "R.pathEq" (ramda update 0.28.0 >> 0.30.1)
export const pathEqLegacy = R.curry((path, equalTo, obj) =>
  R.pathEq(equalTo, path, obj),
);

// ofArrayLegacy :: Any -> [Any]
// !Deprecated: use original ramda "R.of" (ramda update 0.28.0 >> 0.30.1)
export const ofArrayLegacy = R.of(Array);
