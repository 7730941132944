import React from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';

const Text = styled.div`
  font-size: 16px;
`;

export function RequesterSurveyQuestion({ value }) {
  return <Text>{value}</Text>;
}

RequesterSurveyQuestion.propTypes = {
  value: string,
};
