import * as R from 'ramda';
import { ProjectType } from '@poly/constants';
import { useMutation, gql } from '@apollo/client';
import { useModalContext, useNotificationState } from '@poly/admin-ui';
import { centsToDollarsWithFormat, pathEqLegacy } from '@poly/utils';

const voidClientInvoicesProcessId = 'voidClientInvoicesProcessId';

const voidClientInvoicesMutation = gql`
  mutation voidClientInvoiceMutation($ids: [ID!]!) {
    voidClientInvoices(ids: $ids) {
      _id
    }
  }
`;

const prepareHousekeepingWarning = R.compose(
  R.concat(`
    Next Invoices that you'r trying to void related to housekeeping projects.
    Invoice void must be done from the project card. Please check:
  `),
  R.join(', '),
  R.map(R.toString),
  R.map(R.prop('number')),
);

const prepareMutationProcessConfig = R.applySpec({
  clientInvoiceIds: R.map(R.prop('_id')),
  clientInvoicesTotal: R.compose(
    centsToDollarsWithFormat,
    R.sum,
    R.map(R.prop('amount')),
  ),
  housekeepingClientInvoicesWarning: R.compose(
    R.ifElse(R.isEmpty, R.always(null), prepareHousekeepingWarning),
    R.filter(pathEqLegacy(['project', 'type'], ProjectType.HOUSEKEEPING)),
  ),
});

export const useVoidClientInvoices = (refetch, setSelectedClientInvoices) => {
  const { openConfirmSubmitFormModal } = useModalContext();
  const [voidClientInvoices] = useMutation(voidClientInvoicesMutation);
  const { showSuccessNotification, showWarningNotification } =
    useNotificationState();

  const onVoidClientInvoices = async (clientInvoices) => {
    const {
      clientInvoiceIds,
      clientInvoicesTotal,
      housekeepingClientInvoicesWarning,
    } = prepareMutationProcessConfig(clientInvoices);

    if (housekeepingClientInvoicesWarning) {
      showWarningNotification(housekeepingClientInvoicesWarning);
    } else {
      openConfirmSubmitFormModal({
        content: `You're about to void ${clientInvoices.length} payment(s) worth ${clientInvoicesTotal} Are you sure?`,
        btnCaption: 'Yes, Void',
        processId: voidClientInvoicesProcessId,
        onConfirm: (closeConfirmModal) => async () => {
          await voidClientInvoices({ variables: { ids: clientInvoiceIds } });
          refetch();
          showSuccessNotification('Invoice successfully voided!');
          closeConfirmModal();
          setSelectedClientInvoices([]);
        },
      });
    }
  };

  return { onVoidClientInvoices };
};
