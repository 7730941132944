import * as R from 'ramda';
import { removePropDeep } from '@poly/utils';

// getContact :: Object -> Object
export const getContact = R.ifElse(
  R.complement(R.pathOr(false, ['contact', 'isCreateMode'])),
  R.applySpec({
    _id: R.prop('_id'),
    contactLinks: R.path(['contact', 'contactLinks']),
    isAddContactToProperty: R.path(['contact', 'isAddContactToProperty']),
  }),
  R.pick(['fullName', 'email', 'phone']),
);

export const saveContractActionNames = {
  SAVE: 'SAVE',
  ADD_TO_PROPERTY: 'ADD_TO_PROPERTY',
  RETAIN_EXISTING_ID: 'RETAIN_EXISTING_ID',
};
const getPayload = (actionName) => (data) => ({ data, actionName });

/**
 * Return Action name (one of saveContractActionNames) with payload for this Action
 * from Form State
 *
 * getSaveContractAction :: ObjectId -> ObjectId -> Contact -> ActionType
 */
export const getSaveContactAction = (propertyId, clientId) =>
  R.ifElse(
    R.prop('_id'),
    R.ifElse(
      R.pathOr(false, ['isAddContactToProperty']),
      R.pipe(
        R.applySpec({
          contactId: R.prop('_id'),
          contactLinks: R.compose(
            R.map(
              R.pipe(
                R.pickBy(R.complement(R.isNil)),
                removePropDeep('__typename'),
              ),
            ),
            R.pathOr([], ['contactLinks']),
          ),
          propertyId: R.always(propertyId),
          clientId: R.always(clientId),
        }),
        getPayload(saveContractActionNames.ADD_TO_PROPERTY),
      ),
      getPayload(saveContractActionNames.RETAIN_EXISTING_ID),
    ),
    R.ifElse(
      R.pathOr(false, ['fullName']),
      R.pipe(
        R.applySpec({
          fullName: R.path(['fullName']),
          email: R.path(['email']),
          phone: R.path(['phone']),
          propertyId: R.always(propertyId),
          clientId: R.always(clientId),
        }),
        getPayload(saveContractActionNames.SAVE),
      ),
      getPayload(saveContractActionNames.RETAIN_EXISTING_ID),
    ),
  );

// pickContactLinks :: Contact -> Object
// Contact = Object
export const pickContactLinks = R.compose(
  R.filter(R.identity),
  R.pick(['clientId', 'propertyId']),
);
