import * as R from 'ramda';
import React, { useMemo, useCallback } from 'react';
import { gql, useQuery, useSubscription } from '@apollo/client';
import { DuplicatedInvoiceNumberError } from '@poly/admin-ui';
import {
  convertCentsToDollars,
  tryCallFunction,
  debounce,
  assocBy,
} from '@poly/utils';

import { INVOICES_BY_SEARCH_SUB } from '../modules/core/hooks/invoices/subscriptions.js';

const INVOICES_NUMBER_DUPLICATED_QUERY = gql`
  query INVOICES_NUMBER_DUPLICATED_QUERY(
    $input: SupplierInvoiceNumberDuplicateInput!
  ) {
    supplierInvoiceNumberDuplicate(input: $input) {
      _id
      supplier {
        company {
          name
        }
      }
      project {
        _id
        projectId
      }
      invoiceNumber
      invoiceFile {
        url
      }
      invoiceDate
      total
    }
  }
`;

// getDuplicatedInvoiceInfo :: SupplierInvoiceNumberDuplicateQueryResult -> Invoice
const getDuplicatedInvoiceInfo = R.compose(
  R.unless(
    R.isNil,
    R.compose(
      R.when(
        R.has('total'),
        R.over(R.lensProp('total'), convertCentsToDollars),
      ),
      assocBy('invoiceFileLink', R.pathOr('', ['invoiceFile', 'url'])),
    ),
  ),
  R.prop('supplierInvoiceNumberDuplicate'),
);

export const useCheckInvoiceNumberDuplicated = ({
  _id,
  supplierId,
  invoiceNumber,
}) => {
  const { data, refetch } = useQuery(INVOICES_NUMBER_DUPLICATED_QUERY, {
    fetchPolicy: 'network-only',
    skip: !invoiceNumber || !supplierId,
    variables: { input: { supplierId, invoiceNumber, invoiceId: _id } },
  });

  const debouncedRefetch = useCallback(
    debounce(2000)(() => tryCallFunction(refetch)()),
    [refetch],
  );

  const duplicatedInvoiceInfo = useMemo(
    () => getDuplicatedInvoiceInfo(data),
    [data],
  );

  useSubscription(INVOICES_BY_SEARCH_SUB, {
    shouldResubscribe: data,
    onData: debouncedRefetch,
    skip: !duplicatedInvoiceInfo,
    variables: {
      searchInput: { query: { term: { _id: duplicatedInvoiceInfo?._id } } },
    },
  });

  const warning = duplicatedInvoiceInfo ? (
    <DuplicatedInvoiceNumberError
      {...duplicatedInvoiceInfo}
      position="bottom"
    />
  ) : null;

  return { isDuplicated: !!duplicatedInvoiceInfo, warning };
};
