import * as R from 'ramda';
import { func, number, shape, oneOfType, string } from 'prop-types';

export const placeholder = '$1,000,000.00';
export const costPropTypes = {
  formData: shape({
    labourCost: number,
    materialCost: number,
  }),
  onChange: func,
  value: oneOfType([string, number]),
};

// isProjectWithClientInvoice :: FormData -> Boolean
export const isProjectWithClientInvoice = R.pathSatisfies(
  R.complement(R.isNil),
  ['project', 'clientInvoices', '0'],
);
