import styled from 'styled-components';
import { getThemeColor, getThemeProp } from '@poly/admin-book';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: ${getThemeColor(['white'])};
  top: 35px;
  left: -167px;
  width: 408px;
  min-height: 300px;
  max-height: 600px;
  overflow: hidden;
`;

export const UserAlertsPopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
`;

export const PopupRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 24px;
`;

export const PageLabel = styled.div`
  font-size: 18px;
  line-height: 22px;
`;

export const AlertButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 75px;
  height: 25px;
  border-radius: 5px;
  margin: 0 9px;
  background-color: ${getThemeColor(['primaryRegular'])};
  cursor: pointer;
  transition: all ${getThemeProp(['defaultTransition'])};

  :hover,
  :focus {
    box-shadow: 0 0 0 2px rgba(255, 119, 111, 0.4);
  }
`;

export const TextBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  width: 100%;
  color: ${getThemeColor(['white'])};
`;

export const NumberBlock = styled(TextBlock)`
  padding: 0 10px;
  background-color: ${({ hasAlerts }) =>
    getThemeColor([hasAlerts ? 'secondaryMid' : 'primaryRegular'])};
  height: 100%;
  width: fit-content;
  border-radius: 0 5px 5px 0;
`;
