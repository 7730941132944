import {
  arrayOf,
  number,
  shape,
  string,
  oneOfType,
  instanceOf,
} from 'prop-types';

export const profitLossPropTypes = arrayOf(
  shape({
    account: shape({
      _id: string.isRequired,
      code: string.isRequired,
      name: string.isRequired,
    }),
    balance: number.isRequired,
    subAccounts: arrayOf(
      shape({
        account: shape({
          _id: string.isRequired,
          code: string.isRequired,
          name: string.isRequired,
        }),
        balance: number.isRequired,
      }),
    ),
  }),
);

export const datePropType = oneOfType([string, instanceOf(Date)]);

export const profitLossFiltersPropTypes = shape({
  startDate: datePropType.isRequired,
  endDate: datePropType.isRequired,
  accountingMethod: string.isRequired,
});
