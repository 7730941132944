import React from 'react';
import styled from 'styled-components';
import { SidebarTabs } from '@poly/admin-ui/src/components/Tabs.js';
import { useSearchTabLogic } from '@poly/admin-ui/src/hooks/useSearchTabLogic.js';
import { SearchInputComp } from '@poly/admin-ui/src/components/TableSearchInput.js';

import {
  ContentWrapper,
  PageLabel,
  PopupRow,
  UserAlertsPopupWrapper,
} from './alertComponents.js';
import { UserAlertsTable } from './UserAlertsTable.js';
import { userAlertsPopupTabs } from '../../../routes/constants.js';
import { ArchiveAllBtn } from './ArchiveAllBtn.js';

const PopupTabsS = styled(SidebarTabs)`
  > div {
    flex-direction: row-reverse;

    > div {
      height: 32px;
      padding-right: 7px;

      &:first-child {
        div {
          padding: 0;
        }
      }

      &:last-child {
        height: 38px;
        padding-right: 0;
      }
    }
  }
`;

export function UserAlertsContent() {
  const { searchTerm, ...inputProps } = useSearchTabLogic('alerts');

  return (
    <ContentWrapper>
      <UserAlertsPopupWrapper>
        <PopupRow>
          <PageLabel>Alerts</PageLabel>
          <ArchiveAllBtn />
        </PopupRow>
        <PopupTabsS
          toolBar={<SearchInputComp {...inputProps} />}
          defaultValue={userAlertsPopupTabs.userAlertsPopupActive}
          tabs={[
            [
              'Active',
              userAlertsPopupTabs.userAlertsPopupActive,
              <UserAlertsTable
                status="Active"
                searchTerm={searchTerm}
                key="Active"
              />,
            ],
            [
              'Archived',
              userAlertsPopupTabs.userAlertsPopupArchived,
              <UserAlertsTable
                status="Archived"
                searchTerm={searchTerm}
                key="Archived"
              />,
            ],
          ]}
        />
      </UserAlertsPopupWrapper>
    </ContentWrapper>
  );
}
