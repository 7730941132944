import * as R from 'ramda';
import { ProjectType } from '@poly/constants';

import { propEqLegacy } from '../ramdaLegacyFunctions.js';

// omitClientReferenceNumberAndPOIfNeed :: Project -> Project
export const omitClientReferenceNumberAndPOIfNeed = R.unless(
  R.either(
    propEqLegacy('type', ProjectType.WORK_ORDER),
    propEqLegacy('type', ProjectType.BID),
  ),
  R.omit(['clientReferenceNumber', 'adminPurchaseOrderId']),
);
