import * as R from 'ramda';
import React from 'react';
import { string, arrayOf, shape } from 'prop-types';
import styled from 'styled-components';
import { formatDistanceToNow } from 'date-fns';
import {
  HeavyJobStatuses,
  HeavyJobTypes,
  NOTHING_UI_STRING,
  ProjectTypeToNameMap,
} from '@poly/constants';
import { Table, WhiteCard } from '@poly/admin-book';
import { useMapConfigToTableProps } from '@poly/admin-ui';
import { applyPathOr, formatDate, propEqLegacy } from '@poly/utils';

const UNKNOWN_TEXT = 'Unknown';

const HeavyJobsTableS = styled(Table)`
  th:nth-child(2),
  td:nth-child(2),
  th:nth-child(3),
  td:nth-child(3),
  th:nth-child(4),
  td:nth-child(4) {
    width: 200px;
  }
`;

// getCreatedDateWithFormat :: HeavyJob -> String
const getCreatedDateWithFormat = R.compose(formatDate, R.prop('createdAt'));

// constructDescription :: [Any -> String] -> String
const constructDescription = (getDescriptionPartFunctions) =>
  R.compose(
    R.join(' '),
    R.reject(R.isNil),
    R.juxt(getDescriptionPartFunctions),
  );

// getBatchDescription :: HeavyJob -> String
const getBatchDescription = R.compose(
  constructDescription([
    R.path(['client', 'name']),
    R.compose(
      R.join('/'),
      R.reject(R.isNil),
      R.map(R.prop(R.__, ProjectTypeToNameMap)),
      R.propOr([], 'projectTypes'),
    ),
    R.always('Batch'),
    getCreatedDateWithFormat,
    R.compose(
      R.concat('('),
      R.concat(R.__, ')'),
      R.toString,
      R.propOr(0, 'invoicesCount'),
    ),
  ]),
  R.prop('clientInvoiceBatch'),
);

// getImportAssertsDescription :: HeavyJob -> String
const getImportAssertsDescription = constructDescription([
  R.path(['property', 'name']),
  R.always('Import Assets'),
  getCreatedDateWithFormat,
  R.compose(
    R.concat('('),
    R.concat(R.__, ')'),
    R.toString,
    R.propOr(0, 'assetsCount'),
  ),
]);

// getBatchReportsDescription :: HeavyJob -> String
const getBatchReportsDescription = constructDescription([
  R.path(['clientInvoiceBatch', 'client', 'name']),
  R.always('Reports'),
  getCreatedDateWithFormat,
]);

// getConsolidatedClientInvoicesDescription :: HeavyJob -> String
const getConsolidatedClientInvoicesDescription = constructDescription([
  R.path(['client', 'name']),
  R.always('Consolidated Client Invoices'),
  getCreatedDateWithFormat,
  R.compose(
    R.concat('('),
    R.concat(R.__, ')'),
    R.toString,
    R.propOr(0, 'consolidatedInvoicesCount'),
  ),
]);

// getPrintClientInvoicesPdfDescription :: HeavyJob -> String
const getPrintClientInvoicesPdfDescription = constructDescription([
  R.always('Print Client Invoices PDF'),
  getCreatedDateWithFormat,
  R.compose(
    R.concat('('),
    R.concat(R.__, ')'),
    R.toString,
    R.propOr(0, 'printClientInvoicesCount'),
  ),
]);

// getClientInvoiceBatchXlsAttachmentsDescription :: HeavyJob -> String
const getClientInvoiceBatchXlsAttachmentsDescription = constructDescription([
  R.always('Client Invoice XLS Attachments'),
  getCreatedDateWithFormat,
  R.compose(
    R.concat('('),
    R.concat(R.__, ')'),
    R.toString,
    R.pathOr(0, ['clientInvoiceBatch', 'invoicesCount']),
  ),
]);

// getHeavyJobDescription :: HeavyJob -> String
const getHeavyJobDescription = R.cond([
  [
    propEqLegacy('type', HeavyJobTypes.CLIENT_INVOICE_BATCH),
    getBatchDescription,
  ],
  [
    propEqLegacy('type', HeavyJobTypes.IMPORT_ASSETS),
    getImportAssertsDescription,
  ],
  [
    propEqLegacy('type', HeavyJobTypes.CLIENT_INVOICE_BATCH_REPORTS),
    getBatchReportsDescription,
  ],
  [
    propEqLegacy('type', HeavyJobTypes.CONSOLIDATED_CLIENT_INVOICE),
    getConsolidatedClientInvoicesDescription,
  ],
  [
    propEqLegacy('type', HeavyJobTypes.PRINT_CLIENT_INVOICES_PDF),
    getPrintClientInvoicesPdfDescription,
  ],
  [
    propEqLegacy('type', HeavyJobTypes.CLIENT_INVOICE_BATCH_XLS_ATTACHMENTS),
    getClientInvoiceBatchXlsAttachmentsDescription,
  ],
  [R.T, R.always(UNKNOWN_TEXT)],
]);

const HeavyJobStatusToNameMap = {
  [HeavyJobStatuses.PENDING]: 'Pending',
  [HeavyJobStatuses.IN_PROGRESS]: 'In Progress',
  [HeavyJobStatuses.DONE]: 'Done',
  [HeavyJobStatuses.FAILED]: 'Failed',
};

// applyFormatDurationByProp :: String -> Object -> String
const applyFormatDurationByProp = (prop) =>
  applyPathOr(
    [prop],
    R.compose(
      R.unless(R.is(String), R.always(NOTHING_UI_STRING)),
      (date) => formatDistanceToNow(date, { addSuffix: true }),
      R.constructN(1, Date),
    ),
    NOTHING_UI_STRING,
  );

const heavyJobsTableConfig = [
  ['Description', getHeavyJobDescription],
  [
    'Status',
    R.compose(
      R.propOr(UNKNOWN_TEXT, R.__, HeavyJobStatusToNameMap),
      R.prop('status'),
    ),
  ],
  ['Started', applyFormatDurationByProp('triggeredAt')],
  ['Finished', applyFormatDurationByProp('finishedAt')],
];

const WhiteCardS = styled(WhiteCard)`
  padding: 10px 0 0 0;
  margin: 20px 30px 22px 30px;
  max-height: initial;
`;

export function UserHeavyJobsTable({ heavyJobs }) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    heavyJobsTableConfig,
    heavyJobs,
  );

  return (
    <WhiteCardS>
      <HeavyJobsTableS {...tableProps} />
    </WhiteCardS>
  );
}

const heavyJobShape = shape({
  _id: string.isRequired,
  status: string.isRequired,
});

UserHeavyJobsTable.propTypes = {
  heavyJobs: arrayOf(heavyJobShape).isRequired,
};
