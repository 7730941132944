import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useRouterQuery } from '@poly/client-routing';
import {
  extractSearchQueryOptionsFromProps,
  useReactiveQuery,
} from '@poly/client-utils';
import { propEqLegacy } from '@poly/utils';

import {
  PROJECTS_BY_SEARCH_SUB,
  RECURRING_PROJECTS_BY_SEARCH_SUB,
} from '../../modules/core/hooks/projects/subscriptions.js';
import { projectsTabsConfig } from '../../modules/tabs/common.js';
import { RECURRING_PROJECTS_BY_SEARCH } from '../../modules/core/hooks/projects/queries.js';
import { ProjectFilterTypes } from '../../modules/pagesHeaders/ProjectSearchPageHeader/filter/constants.js';
import {
  projectStatusesColors,
  projectStatusesUI,
} from '../../modules/core/constants/projects.js';

const PROJECTS_TOOLBAR_QUERY = gql`
  query PROJECTS_TOOLBAR_QUERY($searchInput: CollectionSearchParams!) {
    searchProjects(input: $searchInput) {
      hits {
        _id
        type
        blockedAt
        projectId
        status
        createdAt
        description
        startDate
        endDate
        expiresAt
        priority
        workCompletionDate
        property {
          _id
          name
        }
        client {
          _id
          name
          nickName
        }
        manager {
          _id
          fullName
        }
      }
      total
    }
  }
`;

// getPrintTab :: String -> String
const getPrintTab = (tab) =>
  R.compose(R.prop(0), R.find(propEqLegacy(1, tab)))(projectsTabsConfig);

// checkRecurringSearch :: Filter -> Boolean
export const checkRecurringSearch = R.compose(
  R.any(R.equals(true)),
  R.props([
    ProjectFilterTypes.MASTER_RECURRING,
    ProjectFilterTypes.PREVENTIVE_MAINTENANCE,
  ]),
);

// checkIfNoPrioritySort :: { sort: [Sorting] } -> Boolean
const checkIfNoPrioritySort = R.compose(
  R.isNil,
  R.find(R.has('priority')),
  R.defaultTo([]),
  R.prop('sort'),
);

export const useProjectsToolbarLogic = ({ customFileName, customTitle }) => {
  const { tab } = useRouterQuery(['tab']);

  const filter = useSelector((state) => state.searchFilters.projectsFilter);
  const params = useSelector((state) => state.searchEntityParams);

  const queryOptions = params
    ? extractSearchQueryOptionsFromProps(params)
    : {
        skip: true,
        variables: { searchInput: {} },
      };

  const isNoPrioritySort = checkIfNoPrioritySort(params);
  const isRecurringSearch = !!filter && checkRecurringSearch(filter);

  let QUERY = PROJECTS_TOOLBAR_QUERY;
  let pathBySearch = 'searchProjects';
  let SUBSCRIPTION = PROJECTS_BY_SEARCH_SUB;

  if (isRecurringSearch && isNoPrioritySort) {
    pathBySearch = 'searchRecurringProjects';
    QUERY = RECURRING_PROJECTS_BY_SEARCH;
    SUBSCRIPTION = RECURRING_PROJECTS_BY_SEARCH_SUB;
  }

  const { data, loading } = useReactiveQuery(QUERY, SUBSCRIPTION, {
    queryOptions,
    subscriptionOptions: queryOptions,
  });

  const projects = R.pathOr([], [pathBySearch, 'hits'], data);

  const tabForPrint = tab ? getPrintTab(tab) : projectsTabsConfig[0][0];

  const fileName = filter ? 'search_projects' : 'projects_directory';

  const metaData = filter
    ? {
        title: 'Search Projects',
      }
    : {
        title: customTitle || 'Projects Directory',
        status: {
          text: projectStatusesUI[tabForPrint],
          color: projectStatusesColors[tabForPrint],
        },
      };

  return {
    loading,
    projects,
    fileName: customFileName || fileName,
    metaData,
    isRecurringSearch,
  };
};
