import { HierarchiesSelector } from '../components/HierarchiesSelector.js';

export const hierarchiesFormField = {
  field: {
    name: 'hierarchies',
    withFormData: true,
    isArrayField: true,
    withChangeFieldValue: true,
    Component: HierarchiesSelector,
  },
};
