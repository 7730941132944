import { useEntitiesByReactiveReduxSearch } from '@poly/client-utils';
import { SERVICE_TYPES_BY_SEARCH } from '../../hocs/serviceTypes/queries.js';
import { SERVICE_TYPES_BY_SEARCH_SUB } from '../../hocs/serviceTypes/subscriptions.js';
import { usePagination } from '../../redux/pagination.js';

export const useServiceTypesBySearchTable = ({
  query,
  sort,
  skipQuery = false,
}) => {
  const { pagination } = usePagination();

  const { result, loading } = useEntitiesByReactiveReduxSearch({
    gqlSearchQuery: SERVICE_TYPES_BY_SEARCH,
    gqlSearchChangedQuery: SERVICE_TYPES_BY_SEARCH_SUB,
    query,
    sort,
    pagination,
    skipQuery,
  });

  return {
    result,
    loading,
  };
};
