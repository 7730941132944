import React from 'react';
import * as R from 'ramda';
import { PRINT_TYPES } from '@poly/constants';
import { useRouterQuery } from '@poly/client-routing';

import { ITEMS_LIMIT } from '../../core/constants/print.js';
import { TablePrintBtn } from '../../core/tableToolbar/TablePrintPDFBtn.js';
import { useMyProjectsBySearch } from '../../core/hooks/projects/index.js';
import { projectsQueries, projectsTabsConfig } from '../../tabs/common.js';

// getManagerProjects :: SearchManagerProjectsQueryResult -> [Project]
const getManagerProjects = R.pathOr([], ['searchManagerProjects', 'hits']);

// showWarningModal :: SearchManagerProjectsQueryResult -> Bool
const showWarningModal = R.pathSatisfies(R.gt(R.__, ITEMS_LIMIT), [
  'searchManagerProjects',
  'hits',
  'length',
]);

// getPageStatusByTab :: { tab: String } -> String
const getPageStatusByTab = R.compose(
  R.head,
  R.defaultTo(projectsTabsConfig[0]),
  R.converge(R.find, [
    R.compose(R.propEq(R.__, '1'), R.propOr(projectsTabsConfig[0][1], 'tab')),
    R.always(projectsTabsConfig),
  ]),
);

export function MyProjectsPrintPDFBtn() {
  const routerQuery = useRouterQuery(['tab']);

  const status = getPageStatusByTab(routerQuery);

  const { result } = useMyProjectsBySearch({
    query: projectsQueries[status],
  });

  const data = getManagerProjects(result.me);

  const isWarningModalShowing = showWarningModal(result.me);

  return (
    <TablePrintBtn
      {...result}
      printType={PRINT_TYPES.MY_PROJECTS}
      style={{ marginLeft: 30 }}
      data={data}
      showWarningModal={isWarningModalShowing}
      metaData={{ title: 'My Projects' }}
    />
  );
}

MyProjectsPrintPDFBtn.displayName = 'MyProjectsPrintPDFBtn';
