import * as R from 'ramda';
import { createAction, handleAction } from 'redux-actions';

export const getSelectedRows = R.prop('selectedRows');

export const setSelectedRows = createAction('SET_SELECTED_ROWS');

export const selectedRowsReducer = handleAction(
  setSelectedRows,
  (_, { payload }) => payload,
  [],
);
