import styled from 'styled-components';
import React from 'react';

const FormTitle = styled.div`
  font-family: TTNormsMedium, FFMarkWebProNarrowBook, sans-serif;
  font-size: 21px;
  font-weight: 500;
`;

export function SupplierAttachInvoiceFormTitle() {
  return <FormTitle>Invoice Request - Service Complete</FormTitle>;
}
