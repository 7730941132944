import React from 'react';
import { string, shape } from 'prop-types';
import { usePristineSubscribe } from '@poly/client-routing';
import {
  commonSidebarFormSectionLayout,
  useOnSubmitSetStopSubmitting,
  commonSidebarFormFieldLayout,
  commonSidebarFormLayout,
} from '@poly/admin-ui';

import { editAssetFormId } from '../../constants.js';
import { AssetFormCreator } from '../add/AddAssetForm.js';
import { assetFormSections } from '../form/assetFormSections.js';
import { useOnSubmitEditAssetForm } from './useOnSubmitEditAssetForm.js';
import { prepareAssetFormInitialValues } from './editAssetFormUtils.js';
import { useValidateAssetForm } from '../form/useValidateAssetForm.js';

export function EditAssetForm({ asset }) {
  const pristineSubscribeProps = usePristineSubscribe({
    id: editAssetFormId,
    ...asset,
  });

  const onSubmitHandler = useOnSubmitEditAssetForm(asset);

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    editAssetFormId,
    onSubmitHandler,
  );

  const initialValues = {
    isEdit: true,
    ...prepareAssetFormInitialValues(asset),
  };

  const validate = useValidateAssetForm(asset);

  return (
    <AssetFormCreator
      onSubmit={onSubmit}
      validate={validate}
      validateOnBlur
      id={editAssetFormId}
      sections={assetFormSections}
      initialValues={initialValues}
      layout={commonSidebarFormLayout}
      fieldLayout={commonSidebarFormFieldLayout}
      sectionLayout={commonSidebarFormSectionLayout}
      {...pristineSubscribeProps}
    />
  );
}

EditAssetForm.propTypes = { asset: shape({ _id: string.isRequired }) };
