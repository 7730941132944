import * as R from 'ramda';
import { JournalPaymentStatus } from '@poly/constants';
import { propEqLegacy } from '@poly/utils';

// isMoneyTransactionReconciled :: MoneyTransaction -> Bool
export const isMoneyTransactionReconciled = R.both(
  propEqLegacy('payment_status', JournalPaymentStatus.RECONCILED),
  R.propSatisfies(R.complement(R.isNil), 'reconciled_at'),
);

// getMoneyTransactionAmount :: Bool -> MoneyTransaction -> Number
export const getMoneyTransactionAmount = (isPayment) =>
  R.compose(
    R.ifElse(isPayment ? R.gte(0) : R.lt(0), Math.abs, R.always(0)),
    R.prop('amount'),
  );
