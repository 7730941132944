import React, { useState, useMemo } from 'react';
import { string, bool } from 'prop-types';
import { Loader } from '@poly/admin-book';
import { useTableInfiniteScrollQuery } from '@poly/client-utils';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';

import {
  bankRegisterTransactionsQuery,
  getRegistersPageQueryVariables,
  bankRegisterAccountDetailsQuery,
} from './bankRegistersQuery.js';
import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { BankRegistersHeader } from './BankRegistersHeader.js';
import { BankRegistersBody } from './BankRegistersBody.js';

export function AccountRegistersPage({
  title,
  exportTitle,
  systemAccountType,
  hideCheckNumber,
}) {
  const [query, setQuery] = useState(null);
  const { input, accountId, fromDate } = useMemo(
    () => getRegistersPageQueryVariables(query),
    [query],
  );

  const { data: accountData, loading: accountLoading } = useQuery(
    bankRegisterAccountDetailsQuery,
    {
      variables: { accountId, fromDate },
      skip: !accountId,
    },
  );

  const {
    data: transactionsData,
    loading: transactionsLoading,
    tableProps,
  } = useTableInfiniteScrollQuery(bankRegisterTransactionsQuery, input, {
    endpointName: 'searchMoneyTransactions',
    pageSize: 1000,
    skip: !query,
  });

  const data = R.mergeRight(accountData, transactionsData);
  const loading = transactionsLoading || accountLoading;

  return (
    <PageWithSearchHeader headerHeight="165px">
      <BankRegistersHeader
        title={title}
        exportTitle={exportTitle}
        systemAccountType={systemAccountType}
        hideCheckNumber={hideCheckNumber}
        query={query}
        accountData={accountData}
        setQuery={setQuery}
        loading={loading}
        data={data}
      />
      {loading ? (
        <Loader />
      ) : (
        <BankRegistersBody
          query={query}
          data={data}
          tableProps={tableProps}
          hideCheckNumber={hideCheckNumber}
        />
      )}
    </PageWithSearchHeader>
  );
}

AccountRegistersPage.propTypes = {
  title: string.isRequired,
  exportTitle: string.isRequired,
  systemAccountType: string,
  hideCheckNumber: bool,
};
