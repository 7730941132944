import React from 'react';
import * as R from 'ramda';
import { TextButton } from '@poly/admin-book';
import { bool, shape, string } from 'prop-types';
import styled from 'styled-components';
import { Disable2FA, useModalContext } from '@poly/admin-ui';
import { Setup2FAForm, twoFAFormId } from './Setup2FAForm.js';

const Setup2FAButton = styled(TextButton)`
  font-size: 14px;
`;

const FormWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function TwoFASettings({
  twoFASettings,
  userId,
  isTwoFactorAuthRequired,
}) {
  const { openModal, closeModal } = useModalContext();

  const isVerified = R.propOr(false, 'isVerified', twoFASettings);

  const open2FASetupModal = () =>
    openModal({
      id: twoFAFormId,
      formId: twoFAFormId,

      content: (
        <FormWrapper>
          <Setup2FAForm handleAfterSubmit={() => closeModal(twoFAFormId)} />
        </FormWrapper>
      ),
      btnCaption: ' Confirm',
    });

  return isVerified ? (
    <Disable2FA
      twoFASettings={twoFASettings}
      userId={userId}
      isTwoFactorAuthRequired={isTwoFactorAuthRequired}
    />
  ) : (
    <Setup2FAButton onClick={open2FASetupModal}>Setup 2FA</Setup2FAButton>
  );
}

TwoFASettings.propTypes = {
  twoFASettings: shape({
    createdAt: string,
    isVerified: bool.isRequired,
  }),
  userId: string.isRequired,
  isTwoFactorAuthRequired: bool.isRequired,
};
