import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { calculateTotal } from '@poly/utils';
import {
  formatDateOrNothingUI,
  createExcelExportCell,
  ExcelExportCellType,
  performExcelExport,
  pathOrNothingUI,
} from '@poly/client-utils';
import { EXPORT_XLS_CAPTION } from '@poly/constants';

import { SalesReportLink } from '../SalesTaxReport/salesTaxReportComponents.js';
import { getBalanceAmount } from './VendorLedgerReportTable.js';
import { vendorLedgerReportPropTypes } from './prop-types.js';

const VendorLedgerReportLink = styled(SalesReportLink)`
  margin-right: 10px;
`;

const vendorLedgerReportTableHeaderRow = R.zipWith(
  createExcelExportCell,
  [
    ...R.repeat(ExcelExportCellType.tableHeader, 6),
    ...R.repeat(ExcelExportCellType.tableCurrencyHeader, 3),
  ],
  [
    'Transaction',
    'Date',
    'Num',
    'Invoice #',
    'Type',
    'Project #',
    'Invoice',
    'Payment',
    'Balance',
  ],
);

// getSalesTaxReportTableRow :: MoneyTransaction -> [ExcelExportDataCell]
const getVendorLedgerReportTableRow = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 6),
    ...R.repeat(ExcelExportCellType.defaultCurrency, 3),
  ]),
  R.juxt([
    R.prop('transactionName'),
    R.compose(formatDateOrNothingUI, R.prop('date')),
    pathOrNothingUI(['transactionNumber']),
    pathOrNothingUI(['invoiceNumber']),
    pathOrNothingUI(['transactionType']),
    pathOrNothingUI(['projectNumber']),
    R.propOr(0, 'invoiceAmount'),
    R.propOr(0, 'paymentAmount'),
    getBalanceAmount,
  ]),
);

// getVendorLedgerReportTotalRow :: [MoneyTransaction] -> [ExcelExportDataCell]
const getVendorLedgerReportTotalRow = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.tableStringFooter, 6),
    ...R.repeat(ExcelExportCellType.tableCurrencyFooter, 3),
  ]),
  R.concat(['Total', ...R.repeat('', 5)]),
  R.juxt([
    calculateTotal(R.propOr(0, 'invoiceAmount')),
    calculateTotal(R.propOr(0, 'paymentAmount')),
    calculateTotal(getBalanceAmount),
  ]),
);

// eslint-disable-next-line import/no-unused-modules
export const getVendorLedgerReportExportConfig = (
  transactions,
  type,
  title,
) => ({
  exportFileName: `vendor_${type}_ledger_report.xls`,
  columnWidths: [25, 15, 15, 15, 15, 15, 15, 15],
  rows: [
    [{ value: title, cellType: ExcelExportCellType.title }],
    vendorLedgerReportTableHeaderRow,
    ...transactions.map(getVendorLedgerReportTableRow),
    getVendorLedgerReportTotalRow(transactions),
  ],
});

export function VendorLedgerReportExportXLBtn({ transactions, title, type }) {
  const onClick = () => {
    const config = getVendorLedgerReportExportConfig(transactions, type, title);
    performExcelExport(config);
  };

  return (
    <VendorLedgerReportLink
      onClick={onClick}
      disabled={R.isEmpty(transactions)}
    >
      {EXPORT_XLS_CAPTION}
    </VendorLedgerReportLink>
  );
}

VendorLedgerReportExportXLBtn.propTypes = vendorLedgerReportPropTypes;
