import React, { useState } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, number, object, oneOfType, shape, string } from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { useRouterQuery } from '@poly/client-routing';
import { performExcelExport } from '@poly/client-utils';
import { EXPORT_XLS_CAPTION, propertyStatuses } from '@poly/constants';
import { createExcelExportCell, ExcelExportCellType } from '@poly/utils';
import { CommonOuterButton, MAX_ITEMS } from '@poly/admin-ui';

import { PropertyTabs } from './constants.js';
import { CLIENT_PROPERTIES_BY_SEARCH } from '../useClientPropertiesQuery.js';

const CommonOuterButtonS = styled(CommonOuterButton)`
  margin-right: 10px;
`;

const PropertyTabToStatusMap = {
  [PropertyTabs.ACTIVE]: propertyStatuses.ACTIVE,
  [PropertyTabs.INACTIVE]: propertyStatuses.INACTIVE,
};

// getQueryByTab :: String -> String
const getStatusByTab = R.compose(
  R.defaultTo(propertyStatuses.ACTIVE),
  R.prop(R.__, PropertyTabToStatusMap),
);

const PropertyStatusesUi = {
  [propertyStatuses.ACTIVE]: 'Active',
  [propertyStatuses.INACTIVE]: 'Inactive',
};

// getPropertyRowConfig :: Property -> [ExcelExportDataCell]
const getPropertyRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 8),
  ]),
  R.juxt([
    R.ifElse(
      R.propIs(String, 'status'),
      R.compose(R.prop(R.__, PropertyStatusesUi), R.prop('status')),
      R.always(''),
    ),
    R.propOr('', 'name'),
    R.pathOr('', ['address', 'formatted_address']),
    R.pathOr('', ['address', 'address_parts', 'street_number']),
    R.pathOr('', ['address', 'address_parts', 'route']),
    R.pathOr('', ['address', 'address_parts', 'locality']),
    R.pathOr('', ['address', 'address_parts', 'administrative_area_level_1']),
    R.pathOr('', ['address', 'address_parts', 'postal_code']),
  ]),
);

export const getXlsPropertiesConfig = (status, properties) => ({
  exportFileName: `${status}_properties_export.xlsx`,
  columnWidths: [10, 25, 45, 25, 25, 25, 10, 10],
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 8)],
      [
        'Status',
        'Name',
        'Address (full)',
        'Street Number',
        'Street Name',
        'City',
        'State',
        'Zip',
      ],
    ),
    ...R.map(getPropertyRowConfig, properties),
  ],
});

export function PropertiesExportToXLSBtn({
  clientId,
  searchTerm,
  totalCount,
  sort,
  parentProperty,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { sidebarSubTab } = useRouterQuery(['sidebarSubTab']);

  const status = getStatusByTab(sidebarSubTab);

  const [queryHandler] = useLazyQuery(CLIENT_PROPERTIES_BY_SEARCH);

  const onClick = async () => {
    setIsLoading(true);

    const { data } = await queryHandler({
      variables: {
        searchInput: {
          searchTerm,
          sort,
          size: MAX_ITEMS,
          query: {
            bool: {
              must: [
                { term: { status } },
                { term: { clientId } },
                ...(parentProperty?._id
                  ? [{ term: { masterPropertyId: parentProperty._id } }]
                  : []),
              ],
            },
          },
        },
      },
    });

    const foundProperties = R.pathOr([], ['searchProperties', 'hits'], data);

    if (foundProperties.length > 0) {
      const exportConfig = getXlsPropertiesConfig(status, foundProperties);
      performExcelExport(exportConfig);
    }

    setIsLoading(false);
  };

  const exportToXLSProps = {
    setIsLoading,
    onClick,
    loading: isLoading,
    disabled: totalCount === 0,
  };

  return (
    <CommonOuterButtonS {...exportToXLSProps}>
      {EXPORT_XLS_CAPTION}
    </CommonOuterButtonS>
  );
}

PropertiesExportToXLSBtn.propTypes = {
  clientId: string,
  searchTerm: string,
  totalCount: number,
  sort: arrayOf(oneOfType([string, object])),
  parentProperty: shape({ _id: string }),
};
