import React from 'react';
import * as R from 'ramda';
import { useQuery, useApolloClient } from '@apollo/client';
import { useRouterParams, useRouterQuery } from '@poly/client-routing';
import { SupplierSources } from '@poly/constants';
import { Loader } from '@poly/admin-book';
import {
  EditBranchSupplierForm as EditBranchSupplierFormBase,
  EditSupplierForm as EditSupplierFormBase,
  ConvertSupplierComponent,
} from '@poly/admin-ui';

import { editSupplierFormId } from './constants.js';
import { routesNames } from '../../../routes/index.js';
import { SUPPLIER_ACCOUNT } from '../../core/hooks/suppliers/queries.js';

const convertSupplierFormId = 'convertSupplierFormId';

function ConvertAACSupplierComponent() {
  return (
    <ConvertSupplierComponent
      formId={convertSupplierFormId}
      masterSupplierUrl={routesNames.MASTER_SUPPLIER}
    />
  );
}

export function EditSupplierForm() {
  const { supplierId } = useRouterParams(['supplierId']);
  const { isBranch } = useRouterQuery(['isBranch']);
  const apolloClient = useApolloClient();

  const { data, loading } = useQuery(SUPPLIER_ACCOUNT, {
    variables: { supplierId },
    skip: isBranch,
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <Loader />;
  }

  const supplier = R.propOr({}, 'supplier', data);

  return isBranch ? (
    <EditBranchSupplierFormBase
      supplierId={supplierId}
      formId={editSupplierFormId}
      supplierUrl={routesNames.SUPPLIER}
      supplierSource={SupplierSources.AAC}
    />
  ) : (
    <EditSupplierFormBase
      supplier={supplier}
      isSupplierTypeEditable
      apolloClient={apolloClient}
      formId={editSupplierFormId}
      supplierUrl={routesNames.SUPPLIER}
      supplierSource={SupplierSources.AAC}
      masterSupplierRootUrl={routesNames.MASTER_SUPPLIER}
      ConvertSupplierComponent={ConvertAACSupplierComponent}
    />
  );
}
