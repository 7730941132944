import React from 'react';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { string, arrayOf, shape, number, bool } from 'prop-types';
import { ProjectType, ProjectTypeToNameMap } from '@poly/constants';
import { propEqLegacy, applyProp, centsToDollarsWithFormat } from '@poly/utils';
import { useMapConfigToTableProps } from '@poly/admin-ui';
import {
  moneyColumnStyles,
  WindowedTable,
  getThemeColor,
  BodyRow,
  Text,
} from '@poly/admin-book';

import { ProjectErrorPopover } from './components/ProjectErrorPropover.js';
import { projectTypesColors } from '../../modules/core/constants/projects.js';
import { PONumberTableComponent } from './components/PONumberTableComponent.js';
import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import { isWoCharge } from '../BatchInvoicingTM/BatchInvoicingPageBody.js';
import { getWoChargeAmount } from '../CreateClientInvoice/helpers.js';
import {
  isClientInvoiceAmountInvalid,
  isProjectInvoiceAmountInvalid,
  isTotalsOutOfRange,
} from '../BatchInvoicingTM/batchInvoicingUtils.js';

// getEmptyCellStyles :: Number -> String
const getEmptyCellStyles = (colNum) => css`
  th:nth-child(${colNum}),
  td:nth-child(${colNum}) {
    display: none;
  }
`;

const ClientInvoicingCollectionTableCompS = styled(WindowedTable)`
  thead {
    height: 45px;
  }
  tr {
    width: 100%;
    margin: 0;
  }

  td {
    vertical-align: middle;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 90px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 20px;
    padding-left: 0;
  }

  td:nth-child(3) {
    overflow: hidden;
    display: -webkit-inline-box !important;
    -webkit-box-align: center;
    white-space: normal;
    padding: 0 5px;
    margin: 6px 0;
    height: 32px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    display: ${({ isConsolidatedByPO }) =>
      isConsolidatedByPO ? 'table-cell' : 'none'};
    width: 135px;
  }

  th:nth-child(5),
  td:nth-child(5) {
    width: 155px;
  }

  ${moneyColumnStyles(6)};
  ${moneyColumnStyles(7)};

  ${({ showWoCharge }) =>
    showWoCharge ? moneyColumnStyles(8) : getEmptyCellStyles(8)};

  ${moneyColumnStyles(9)};

  th:nth-child(10),
  td:nth-child(10) {
    width: 40px;
    text-align: right;
  }
`;

// hexToRgb :: { color: String } -> String
const getBackgroundColor = ({ color }) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  const getPoint = (value) => parseInt(value, 16);

  return result
    ? `rgba(${getPoint(result[1])}, ${getPoint(result[2])}, ${getPoint(
        result[3],
      )}, 0.4)`
    : '';
};

const ProjectCostTypeCompS = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 18px;
  padding: 3px 6px;
  border-radius: 6px;
  width: fit-content;
  color: ${R.prop('color')};
  background-color: ${getBackgroundColor};
`;

function ProjectCostType({ type }) {
  const title = ProjectTypeToNameMap[type];
  const color = projectTypesColors[type];

  return <ProjectCostTypeCompS color={color}>{title}</ProjectCostTypeCompS>;
}

ProjectCostType.propTypes = { type: string.isRequired };

function ProjectErrorIcon({ isPOEnabled, ...props }) {
  const showErrorPopover = isProjectInvoiceAmountInvalid(props);

  if (!showErrorPopover) return null;

  return <ProjectErrorPopover {...props} />;
}

ProjectErrorIcon.propTypes = { rowIndex: number.isRequired, isPOEnabled: bool };

const emptyCellConfig = [['', () => null]];

const getConsolidatedInvoiceProjectsTableConfig = (
  showWoCharge,
  isConsolidatedByPO,
) => [
  ['Project #', ProjectLink],
  ['', ProjectErrorIcon],
  ['Description', R.prop('description')],
  ...(isConsolidatedByPO
    ? [['PO Number', PONumberTableComponent]]
    : emptyCellConfig),
  ['Cost Type', ProjectCostType],
  ['Job Cost', applyProp(centsToDollarsWithFormat)('jobCost')],
  ['Markup', applyProp(centsToDollarsWithFormat)('clientInvoiceMarkupAmount')],
  ...(showWoCharge
    ? [
        [
          'Work Order Charge',
          R.compose(
            centsToDollarsWithFormat,
            R.ifElse(
              propEqLegacy('type', ProjectType.WORK_ORDER),
              R.compose(R.defaultTo(0), getWoChargeAmount),
              R.always(0),
            ),
          ),
        ],
      ]
    : emptyCellConfig),
  [
    'Inv. Amt.',
    applyProp(centsToDollarsWithFormat)('suggestedClientInvoiceAmount'),
  ],
];

// getAdminPurchaseOrderId :: Project -> String
const getAdminPurchaseOrderId = R.either(
  R.path(['adminPurchaseOrder', '_id']),
  R.path(['parent', 'adminPurchaseOrder', '_id']),
);

// hasClientReferenceNumber :: Boolean -> Project -> Boolean
const hasClientReferenceNumber = (isPORequired, isPOEnabled) =>
  R.ifElse(
    R.always(isPORequired),
    R.ifElse(
      R.always(isPOEnabled),
      getAdminPurchaseOrderId,
      R.either(
        R.either(
          R.prop('clientReferenceNumber'),
          R.path(['parent', 'clientReferenceNumber']),
        ),
        getAdminPurchaseOrderId,
      ),
    ),
    R.T,
  );

// getRowColorByProps :: Boolean ->  Row -> String
const getRowColorByProps = (isPORequired) =>
  R.cond([
    [
      R.compose(isTotalsOutOfRange, R.prop('row')),
      getThemeColor(['notificator', 'warning', 'text']),
    ],
    [
      R.compose(isClientInvoiceAmountInvalid, R.prop('row')),
      getThemeColor(['notificator', 'error', 'text']),
    ],
    [
      R.compose(
        R.complement(hasClientReferenceNumber(isPORequired)),
        R.prop('row'),
      ),
      getThemeColor(['notificator', 'error', 'text']),
    ],
    [R.T, getThemeColor(['primary'])],
  ]);

const getBodyRow = (isPORequired) => styled(BodyRow)`
  color: ${getRowColorByProps(isPORequired)};
`;

// prepareProjects :: Boolean -> [Project] -> [Project]
const prepareProjects = (isPOEnabled) => (projects) =>
  projects.map((project, index) => ({
    ...project,
    isPOEnabled,
    rowIndex: index,
  }));

function POMissingComponent() {
  return <Text>Missing PO#</Text>;
}

export function ClientInvoicingCollectionTable({
  projects,
  isPOEnabled,
  isPORequired,
  isConsolidatedByPO,
  ...tableProps
}) {
  const { rows, headers, columns, sortQueries } = useMapConfigToTableProps(
    prepareProjects(isPOEnabled),
    getConsolidatedInvoiceProjectsTableConfig(
      isWoCharge(projects),
      isConsolidatedByPO,
    ),
    projects,
  );

  return (
    <ClientInvoicingCollectionTableCompS
      rows={rows}
      showScrollBar
      headers={headers}
      columns={columns}
      selectedRowsInEnd
      sortQueries={sortQueries}
      showWoCharge={isWoCharge(projects)}
      isConsolidatedByPO={isConsolidatedByPO}
      RowComponent={getBodyRow(isPORequired)}
      NotSelectableCheckboxComponent={POMissingComponent}
      isRowSelectable={hasClientReferenceNumber(isPORequired, isPOEnabled)}
      {...tableProps}
    />
  );
}

ClientInvoicingCollectionTable.propTypes = {
  isPOEnabled: bool,
  isPORequired: bool,
  isConsolidatedByPO: bool,
  projects: arrayOf(shape({ _id: string.isRequired })),
};
