import React from 'react';
import * as R from 'ramda';
import { keywordSortQuery } from '@poly/client-utils';

import { useSidebarLogicContext } from '../../../sidebars/SidebarLogicContext.js';

// nestedClientName :: Object -> String
const nestedClientName = R.converge(
  (nickName, name) => nickName || name,
  [R.path(['client', 'nickName']), R.path(['client', 'name'])],
);

const nestedFormattedAddress = R.path(['address', 'formatted_address']);

export const clientColumn = [
  'Client',
  nestedClientName,
  keywordSortQuery(['client', 'name']),
];

export const addressColumn = [
  'Address',
  nestedFormattedAddress,
  keywordSortQuery(['address', 'formatted_address']),
];

function ClientSidebarLink(props) {
  const { ClientLink } = useSidebarLogicContext();

  return <ClientLink {...props} />;
}

function PropertySidebarLink(props) {
  const { PropertyLink } = useSidebarLogicContext();

  return <PropertyLink {...props} />;
}

export const clientLinkColumn = [
  'Name',
  ClientSidebarLink,
  keywordSortQuery(['name']),
];

export const propertyColumn = [
  'Property Name',
  PropertySidebarLink,
  keywordSortQuery(['name']),
];
