import { ClientStatuses } from '@poly/constants';

export const projectContactsByClient = (clientId) => ({
  bool: {
    should: [
      {
        bool: {
          must: [
            { term: { status: ClientStatuses.ACTIVE } },
            {
              nested: {
                path: 'userGroups',
                query: { exists: { field: 'userGroups.userGroupId' } },
              },
            },
          ],
        },
      },
      {
        nested: {
          path: 'links',
          query: {
            term: {
              'links.clientId': clientId,
            },
          },
        },
      },
    ],
  },
});
