import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { setCurrentSentryUser } from './sentry-user.js';

export const initSentry = (app) => {
  if (process.env.SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      release: `${app}@${process.env.APP_VERSION}`,
      environment: process.env.NODE_ENV,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0,
      ignoreErrors: [
        'Network request failed',
        'Failed to fetch',
        'NetworkError',
        'NetworkError: Load failed',
        'TypeError: network error',
      ],
    });

    setCurrentSentryUser();
  }
};
