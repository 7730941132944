import { gql } from '@apollo/client';

import { UpdateDocumentFragmentForLink } from '../../../../components/UpdateDocumentLink.js';
import { UserAlertCommonFields } from './fragments.js';

export const USER_ALERTS_SEARCH_QUERY = gql`
  query USER_ALERTS_SEARCH_QUERY($searchInput: CollectionSearchParams!) {
    searchUserAlerts(input: $searchInput) {
      hits {
        ...UserAlertCommonFields
      }
      total
    }
  }

  ${UserAlertCommonFields}
`;

export const USER_ALERTS_SEARCH_AND_ME_QUERY = gql`
  query USER_ALERTS_SEARCH_AND_ME_QUERY($searchInput: CollectionSearchParams!) {
    me {
      _id
    }
    searchUserAlerts(input: $searchInput) {
      hits {
        ...UserAlertCommonFields
        document {
          ...UpdateDocumentFragmentForLink
        }
      }
      total
    }
  }

  ${UserAlertCommonFields}
  ${UpdateDocumentFragmentForLink}
`;
