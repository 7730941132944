import { Checkbox } from '@poly/admin-book';
import { SupplierSources } from '@poly/constants';

import { halfWidth } from '../common.js';
import { FinancialTermsSelect } from '../components/FinancialTermsSelect.js';

export const supplierFinancialSection = (supplierSource) => {
  const isAACSupplierSource = supplierSource === SupplierSources.AAC;

  return [
    {
      label: 'Financial',
      order: 4,
      layout: { column: 2 },
      fields: [
        {
          label: 'Accepts Credit Card',
          order: 1,
          layout: { row: 1, width: halfWidth },
          field: {
            name: 'finance.acceptsCreditCard',
            Component: Checkbox,
          },
        },
        {
          label: 'Terms',
          order: 2,
          layout: { row: 1, width: halfWidth },
          required: isAACSupplierSource,
          field: {
            name: 'finance.terms',
            Component: FinancialTermsSelect,
            additionalProps: { isClearable: !isAACSupplierSource },
          },
        },
      ],
    },
  ];
};
