const hasNoCentFractions = (x) => {
  // this is the most reliable way considering JS's fucked up math
  const tokens = x.toString().split('.');
  // literally checks amount of characters after dot:
  return tokens.length === 1 || tokens[1].length < 3;
};

export const isClientInvoiceAmountValid = (invoiceAmount) =>
  !Number.isNaN(invoiceAmount) &&
  typeof invoiceAmount === 'number' &&
  invoiceAmount !== 0 &&
  hasNoCentFractions(invoiceAmount);
