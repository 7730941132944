import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';

import { getThemeColor } from './utils.js';
import { TableLayoutList } from './TableLayoutList/index.js';
import { FormCreator } from './Form/FormCreator.js';

export const WhiteCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1424px;
  width: calc(100% - 60px);
  height: auto;
  flex-wrap: wrap;
  margin: 30px;
  background-color: ${getThemeColor(['white'])};
  border-radius: 4px;
  box-shadow: 0 1px 4px -1px ${getThemeColor(['primaryLighter7'])};
  position: relative;
`;

export const TableCard = styled(WhiteCard)`
  background-color: transparent;
  box-shadow: none;
  margin: 0 auto;
  height: ${R.propOr('auto', 'height')};
  width: calc(100% - 125px);
  max-height: none;
`;

export const TableCardWithoutTabs = styled(TableCard)`
  padding-top: 20px;
  height: 100%;
`;

export const CardsWrapper = styled(WhiteCard)`
  justify-content: center;
  background-color: transparent;
  box-shadow: none;
  margin: 0;
  padding: 30px 20px;
  height: 100%;
  width: 100%;
  max-height: none;
  max-width: none;
  overflow: auto;
`;

export const SectionsWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  max-width: 1424px;
`;

export const SideSection = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 15px);
  height: min-content;
  min-height: 100%;
  margin-bottom: 30px;
  background-color: ${getThemeColor(['white'])};
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
`;

export const SectionWithCard = styled(TableLayoutList)`
  display: flex;
  flex-direction: column;
  padding: 20px 25px 0 25px;
  flex-shrink: 0;
`;

const getHeight = ({ paginationVisible }) =>
  paginationVisible ? 'calc(100% - 82px)' : '100%';

export const TableWithPaginationContainer = styled.div`
  padding-top: 5px;
  height: ${getHeight};
  width: 100%;
`;

export function FormPage(props) {
  return (
    <CardsWrapper>
      <FormCreator {...props} />
    </CardsWrapper>
  );
}
