import styled from 'styled-components';
import { moneyColumnStyles, Table } from '@poly/admin-book';

export const WorkInProgressTableS = styled(Table)`
  thead {
    height: 45px;
  }

  tr {
    width: 100%;
    margin: 0;
    min-height: 45px;
  }

  td {
    padding: 5px;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 100px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 110px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 120px;
  }

  th:nth-child(5),
  td:nth-child(5) {
    width: 100px;
  }

  th:nth-child(10),
  td:nth-child(10) {
    width: 120px;
  }

  ${moneyColumnStyles(7, 100)};
  ${moneyColumnStyles(8, 100)};
  ${moneyColumnStyles(9, 100)};

  tfoot {
    margin-top: 15px;
  }
`;
