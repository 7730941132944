import React from 'react';
import { useOutSidebarContext } from '@poly/client-utils';

import {
  propertyTabsSidebarId,
  PropertyTabsSidebarWidth,
} from '../constants.js';
import { PropertySidebarTabs } from './PropertySidebarTabs.js';
import { SecondSidebarLayout } from '../../../components/SidebarLayouts.js';

export const useOpenPropertyTabsSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();

  return ({ property, isPropertyLocationHierarchyEnabled }) =>
    openOutSidebar({
      id: propertyTabsSidebarId,
      Layout: SecondSidebarLayout,
      width: PropertyTabsSidebarWidth,
      content: (
        <PropertySidebarTabs
          {...{
            property,
            isPropertyLocationHierarchyEnabled,
          }}
        />
      ),
    });
};
