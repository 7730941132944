import React from 'react';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { arrayOf, shape, string, bool } from 'prop-types';
import { Table, getThemeColor } from '@poly/admin-book';

import {
  UpdatesTableRow,
  UpdatesTableInfoSection,
} from './UpdatesTableComponents.js';
import { TabsViews } from '../../../constants/tabs.js';
import { useMapConfigToTableProps } from '../../../hooks/useMapConfigToTableProps.js';
import { getUserAvatarComp } from './UpdateMetaInfo.js';

const hideIconsStyles = css`
  div[id='updates-icons'] {
    display: none;
  }
`;

const SidebarUpdatesTable = styled(Table)`
  thead {
    display: none;
  }

  tbody {
    height: 100%;
  }

  tr {
    background-color: ${getThemeColor(['white'])};
    padding: 0 24px;
  }

  td {
    vertical-align: top;
  }

  th:first-child,
  td:first-child {
    width: 35px;
    padding-left: 0;
  }

  th:last-child,
  td:last-child {
    padding-right: 0;
  }

  ${({ isPrint }) => isPrint && hideIconsStyles};
`;

const CardUpdatesTable = styled(Table)`
  td {
    vertical-align: top;
    padding: 10px;
  }

  th:first-child,
  td:first-child {
    width: 50px;
  }

  th:last-child,
  td:last-child {
    padding-right: 20px;
  }

  ${({ isPrint }) => isPrint && hideIconsStyles};
`;

const tableConfig = [
  ['User', R.compose(getUserAvatarComp, R.prop('createdBy'))],
  ['Update Comments & Time', UpdatesTableInfoSection],
];

export function UpdatesTable({
  updates,
  isPrint = false,
  tabView = TabsViews.sidebar,
}) {
  const tableProps = useMapConfigToTableProps(R.identity, tableConfig, updates);

  const TableComponent =
    tabView === TabsViews.sidebar ? SidebarUpdatesTable : CardUpdatesTable;

  return (
    <TableComponent
      {...tableProps}
      isPrint={isPrint}
      RowComponent={UpdatesTableRow}
      showScrollBar={tabView === TabsViews.sidebar}
    />
  );
}

UpdatesTable.propTypes = {
  isPrint: bool,
  tabView: string,
  updates: arrayOf(shape({ _id: string.isRequired })).isRequired,
};
