import React from 'react';
import { LinkButton } from '@poly/admin-book/src/LinkButton/index.js';
import { isNilOrEmpty } from '@poly/utils/src/general.js';
import { arrayOf, shape } from 'prop-types';
import { PRINT_PDF_CAPTION } from '@poly/constants';
import { openPrintWindowWithData } from '@poly/client-utils/src/print.js';

import { PrintLayoutProvider } from '../../providers.js';
import { PurchaseOrdersTable } from './PurchaseOrdersTable.js';

export function PurchaseOrdersPDFButton({ purchaseOrders }) {
  const handleClick = async () => {
    await openPrintWindowWithData({
      Layout: PrintLayoutProvider,
      Table: PurchaseOrdersTable,
      metaData: {
        title: 'PO Balance',
      },
      fileName: 'po_balance',
      purchaseOrders,
    });
  };

  return (
    <LinkButton disabled={isNilOrEmpty(purchaseOrders)} onClick={handleClick}>
      {PRINT_PDF_CAPTION}
    </LinkButton>
  );
}

PurchaseOrdersPDFButton.propTypes = {
  purchaseOrders: arrayOf(shape({})),
};
