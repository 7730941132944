import React from 'react';
import styled from 'styled-components';
import { func, string } from 'prop-types';
import { Select } from '@poly/admin-book';

const HierarchySelectorS = styled(Select)`
  margin-left: 15px;
  width: 200px;
`;

export function ClientHierarchiesSelector({
  clientId,
  changeClientId,
  ...props
}) {
  const onChange = (option) => {
    props.onChange(option);
    if (!clientId && option.clientId) {
      changeClientId(option.clientId);
    }
  };
  return <HierarchySelectorS {...props} onChange={onChange} />;
}

ClientHierarchiesSelector.propTypes = {
  clientId: string,
  onChange: func.isRequired,
  changeClientId: func.isRequired,
};
