import { gql } from '@apollo/client';

import { supplierDocumentsFields } from '../../modules/core/hooks/suppliers/fragments.js';

export const paySupplierInvoicesQuery = gql`
  query PAY_INVOICES_QUERY($searchInput: CollectionSearchParams!) {
    searchInvoices(input: $searchInput) {
      total
      hits {
        _id
        invoiceNumber
        invoiceId
        invoiceDate
        total
        balance
        paymentDueDate
        paymentDueDateChangeReason
        invoiceFile {
          url
        }
        project {
          _id
          projectId
          type
        }
        supplier {
          _id
          status
          type
          isVeteran
          mwbe {
            isWBE
            minority
          }
          company {
            name
          }
          masterSupplier {
            _id
          }
          tax {
            exempt
            w9File {
              url
            }
          }
          documents {
            ...supplierDocumentsFields
          }
        }
      }
    }
  }
  ${supplierDocumentsFields}
`;

export const paySupplierInvoicesMutation = gql`
  mutation paySupplierInvoicesMutation($input: PaySupplierInvoicesInput!) {
    paySupplierInvoices(input: $input) {
      invoices
    }
  }
`;
