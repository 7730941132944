import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setProjectsFilter } from '../../redux/searchFilters/projectsFilter.js';

export const useSearchProjectsFilter = () => {
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.searchFilters.projectsFilter);

  const headerProps = filter
    ? {
        height: '115px',
        headerStyles: 'padding-bottom: 55px',
      }
    : {};

  useEffect(() => () => dispatch(setProjectsFilter(null)), []);

  return { filter, headerProps };
};
