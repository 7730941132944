import React from 'react';
import arrayMutators from 'final-form-arrays';
import * as R from 'ramda';
import {
  FieldLayout,
  LinkButton,
  Loader,
  MainHeader,
  useFormSubscription,
} from '@poly/admin-book';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import { formatDate } from '@poly/utils';
import {
  useRouterQuery,
  usePristineSubscribe,
  useNavigate,
} from '@poly/client-routing';
import { shape, string, func } from 'prop-types';

import {
  useNotificationState,
  useOnSubmitSetStopSubmitting,
} from '@poly/admin-ui';
import { gql, useMutation } from '@apollo/client';
import { FormCardContainer } from '../../components/FormCardContainer.js';
import {
  CloseAccountBalanceLine,
  CloseAccountBalanceLineTitle,
} from './CloseAccountBalanceLine.js';
import {
  FlexCenterAlign,
  FlexColumn,
  FlexContainer,
} from '../../components/FlexContainer.js';
import {
  formatAccrualBasisClosingLine,
  formatAccrualBasisRetainedEarningsLine,
  formatCashBasisClosingLine,
  formatCashBasisRetainedEarningsLine,
  formatClosePeriodClosingLines,
} from './helpers.js';
import { useClosingAccountsQuery } from './useClosingAccountsQuery.js';
import { routesNames } from '../../routes/index.js';
import { DefaultPageLayout } from '../DefaultPageLayout.js';

const TitleContainer = styled(FlexContainer)`
  width: 100%;
  height: 30px;
  border-bottom: 1px solid rgba(197, 198, 201, 0.35);
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-size: 19px;
`;

const CloseBalancesSectionContainer = styled(FlexColumn)`
  margin-bottom: 20px;
  width: 100%;
`;

const FlexFullWidthJustifyStart = styled(FlexContainer)`
  justify-content: flex-start;
`;

const DateRangeContainerS = styled(FlexCenterAlign)`
  width: 100%;
  margin-bottom: 15px;
  justify-content: center;
  font-size: 18px;
`;

function CloseAccountsBalancesSection({ name, title, form }) {
  return (
    <CloseBalancesSectionContainer>
      <TitleContainer>{title}</TitleContainer>
      <CloseAccountBalanceLineTitle />
      <FieldLayout
        layout={{ padding: 0 }}
        field={{
          isArrayField: true,
          Component: CloseAccountBalanceLine,
          name,
        }}
      />
      <FlexFullWidthJustifyStart>
        <LinkButton
          type="button"
          onClick={() =>
            form.mutators.push(name, { debit: null, credit: null })
          }
        >
          Add New
        </LinkButton>
      </FlexFullWidthJustifyStart>
    </CloseBalancesSectionContainer>
  );
}

CloseAccountsBalancesSection.propTypes = {
  name: string.isRequired,
  title: string.isRequired,
  form: shape({
    mutators: shape({ push: func.isRequired }),
  }),
};

const formatClosingInitialValues = R.applySpec({
  accrualLines: R.map(formatAccrualBasisClosingLine),
  cashBasisLines: R.map(formatCashBasisClosingLine),
});

const closeAccountingPeriodMutation = gql`
  mutation closeAccountingPeriod($input: CloseAccountingPeriodInput!) {
    closeAccountingPeriod(input: $input) {
      _id
    }
  }
`;

const formatInitialValues = (closingAccounts, retainedEarningsAccount) => {
  const { accrualLines, cashBasisLines } =
    formatClosingInitialValues(closingAccounts);
  const cashBasisRetainedEarningsLine = formatCashBasisRetainedEarningsLine(
    closingAccounts,
    retainedEarningsAccount,
  );
  const accrualBasisRetainedEarningsLine =
    formatAccrualBasisRetainedEarningsLine(
      closingAccounts,
      retainedEarningsAccount,
    );

  return {
    accrualLines: [accrualBasisRetainedEarningsLine, ...accrualLines],
    cashBasisLines: [cashBasisRetainedEarningsLine, ...cashBasisLines],
  };
};

const FormS = styled.form`
  width: 100%;
`;

const closePeriodFormId = 'closePeriodFormId';

function ClosingFinancialYearForm({ handleSubmit, form }) {
  const { formSubscription } = usePristineSubscribe();
  useFormSubscription(form.subscribe, formSubscription);
  return (
    <FormS id={closePeriodFormId} onSubmit={handleSubmit}>
      <CloseAccountsBalancesSection
        name="cashBasisLines"
        title="Cash Basis"
        form={form}
      />
      <CloseAccountsBalancesSection
        name="accrualLines"
        title="Accrual Basis"
        form={form}
      />
    </FormS>
  );
}

ClosingFinancialYearForm.propTypes = {
  form: shape({
    subscribe: func.isRequired,
    mutators: shape({
      push: func.isRequired,
      remove: func.isRequired,
    }).isRequired,
  }).isRequired,
  handleSubmit: func.isRequired,
};

export function ClosingFinancialYearJournalPage() {
  const [closeAccountingPeriod] = useMutation(closeAccountingPeriodMutation);
  const { showSuccessNotification } = useNotificationState();
  const navigate = useNavigate();

  const { currentPeriodStartDate, currentPeriodEndDate } = useRouterQuery([
    'currentPeriodStartDate',
    'currentPeriodEndDate',
  ]);

  const { closingAccounts, retainedEarningsAccount, loading } =
    useClosingAccountsQuery('network-only');

  const closeCurrentAccountingPeriod = async (values) => {
    const input = {
      currentPeriod: {
        startDate: new Date(currentPeriodStartDate),
        endDate: new Date(currentPeriodEndDate),
        lines: formatClosePeriodClosingLines(
          values.cashBasisLines,
          values.accrualLines,
        ),
      },
    };
    await closeAccountingPeriod({ variables: { input } });
    showSuccessNotification('Accounting period successfully closed');
    navigate(routesNames.FINANCIAL_DIRECTORY);
  };
  const { onSubmit } = useOnSubmitSetStopSubmitting(
    closePeriodFormId,
    closeCurrentAccountingPeriod,
  );

  const initialValues = formatInitialValues(
    closingAccounts,
    retainedEarningsAccount,
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <DefaultPageLayout headerContent={<MainHeader>Closing Journal</MainHeader>}>
      <FormCardContainer
        submitBtnLabel="Post Closing Journal"
        formId={closePeriodFormId}
        fallbackLink={routesNames.CLOSE_FINANCIAL_YEAR}
      >
        <DateRangeContainerS>
          {`${formatDate(currentPeriodStartDate)} - ${formatDate(
            currentPeriodEndDate,
          )}`}
        </DateRangeContainerS>
        <Form
          subscription={{ values: true }}
          mutators={arrayMutators}
          onSubmit={onSubmit}
          initialValues={initialValues}
          keepDirtyOnReinitialize
        >
          {({ handleSubmit, form }) => (
            <ClosingFinancialYearForm {...{ handleSubmit, form }} />
          )}
        </Form>
      </FormCardContainer>
    </DefaultPageLayout>
  );
}
