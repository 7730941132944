import React from 'react';
import styled, { css } from 'styled-components';
import { element, bool, arrayOf, string, oneOfType, shape } from 'prop-types';

import { getThemeFont, getThemeColor, getThemeProp } from '../utils.js';
import { Loader } from '../Loader/index.js';
import { Icon } from '../Icon/index.js';

const sizes = {
  tiny: `
    height: 25px;
    font-size: 10px;
    padding: 5px 10px;
    line-height: 14px;
    border-radius: 5px;
  `,
  small: `
    height: 30px;
    font-size: 12px;
    padding: 8px 14px;
    line-height: 18px;
  `,
  default: `
    height: 40px;
    font-size: 14px;
    padding: 12px 18px;
    line-height: 16px;
  `,
  middle: `
    height: 34px;
    font-size: 12px;
    padding: 8px 14px;
    line-height: 18px;
`,
};

const styleTypes = {
  primaryLight: css`
    background-color: ${getThemeColor(['primaryLight'])};
    border: none;
    color: ${getThemeColor(['white'])};
  `,
  primaryLighter: css`
    background-color: ${getThemeColor(['primaryLighter2'])};
    border: none;
    color: ${getThemeColor(['white'])};
  `,
  basicSecondary: css`
    color: ${getThemeColor(['dark'])};
    border: none;
    background-color: ${getThemeColor(['midLight'])};
  `,
  basicPrimary: css`
    color: ${getThemeColor(['accent2'])};
    border: 1px solid ${getThemeColor(['accent2'])};
    background-color: ${getThemeColor(['white'])};
  `,
  accent: css`
    background-color: ${getThemeColor(['primaryLight'])};
    border: none;
    color: ${getThemeColor(['white'])};

    circle {
      stroke: ${getThemeColor(['white'])};
    }
  `,
  accentDark: css`
    background-color: ${getThemeColor(['primaryRegular'])};
    border: none;
    color: ${getThemeColor(['white'])};

    circle {
      stroke: ${getThemeColor(['white'])};
    }
  `,

  secondaryLighter: css`
    background-color: ${getThemeColor(['white'])};
    border: 1px solid ${getThemeColor(['secondaryMid'])};
    color: ${getThemeColor(['secondaryMid'])};
  `,

  default: css`
    background-color: ${getThemeColor(['secondaryMid'])};
    border: none;
    color: ${getThemeColor(['white'])};

    circle {
      stroke: ${getThemeColor(['white'])};
    }
  `,
};

const ButtonS = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: ${getThemeFont(['medium'])};
  line-height: 18px;
  border-radius: 5px;

  ${({ size }) => sizes[size] || sizes.default};
  ${({ styleType }) => styleTypes[styleType] || styleTypes.default};

  transition: background-color ${getThemeProp(['defaultTransition'])};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => disabled && '.7'};
  position: relative;
  color: ${({ loader }) => loader && 'transparent'};

  > svg {
    margin-right: 10px;

    > path {
      fill: ${({ loader }) => loader && 'rgba(0, 0, 0, 0)'};
    }
  }
`;

export function Button({ icon, ...props }) {
  return (
    <ButtonS {...props}>
      {!!icon && <Icon {...icon} />}
      {props.children}
      {props.loader && <Loader size={16} />}
    </ButtonS>
  );
}

const elementOrString = oneOfType([element, string]);

Button.propTypes = {
  children: oneOfType([elementOrString, arrayOf(elementOrString)]).isRequired,
  disabled: bool,
  loader: bool,
  icon: shape({ name: string }),
};

Button.defaultProps = {
  loader: false,
  disabled: false,
};

Button.displayName = 'Button';
