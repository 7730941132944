import { bool, number, string, shape, instanceOf, oneOfType } from 'prop-types';

export const invoicePaymentPropTypes = shape({
  _id: string.isRequired,
  invoiceId: number.isRequired,
  invoiceNumber: string.isRequired,
  supplierName: string,
  projectId: string,
  invoiceDate: string.isRequired,
  dueDate: oneOfType([instanceOf(Date), string]),
  total: number.isRequired,
  balanceAmount: number,
  currentBalance: number,
  isSelected: bool,
});
