import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

import React, { useState, useEffect } from 'react';
import {
  paginationToQueryParams,
  useRedirectIfHasNoPermissions,
} from '@poly/client-utils';
import { useUpdateQueryParams, useLocation } from '@poly/client-routing';
import { Loader } from '@poly/admin-book';
import {
  TableCardWithPaginationContainer,
  useSetInitialPaginationOnMount,
  useSetItemsCount,
} from '@poly/admin-ui';
import { READ_CLIENT_BATCH_PERMISSION } from '@poly/security';

import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { BatchInvoiceReportsTable } from './BatchInvoiceReportsTable.js';
import {
  PageWithSearchHeader,
  SearchPageContentWrapper,
} from '../../components/PageWithSearchHeader.js';
import {
  batchInvoiceReportsPageHeaderConfig,
  BatchInvoiceReportsHeader,
  defaultBatchInvoiceReportsFilters,
} from './BatchInvoiceReportsHeader.js';

// prepareQueryInput :: PageFilters -> BatchInvoiceReportInput
// PageFilters = {
//    clientId: String
//    type: String
//    clientInvoiceDate: {
//      startDate: String
//      endDate: String
//    },
// }
const prepareQueryInput = R.compose(
  R.mergeAll,
  R.juxt([R.omit(['clientInvoiceDate']), R.propOr({}, 'clientInvoiceDate')]),
);

const BATCH_INVOICE_REPORTS_QUERY = gql`
  query BATCH_INVOICE_REPORT($input: ClientInvoiceBatchesInput) {
    clientInvoiceBatches(input: $input) {
      total
      hits {
        _id
        invoicesCount
        createdAt
        projectTypes
        batchPDFFile {
          fileName
          url
        }
        attachments {
          fileName
          url
        }
      }
    }
  }
`;

export function BatchInvoiceReportsPage() {
  const pagination = useSelector(R.prop('pagination'));
  const updateQueryParams = useUpdateQueryParams();
  const location = useLocation();

  const [filters, setFilters] = useState(null);
  const { searchFilters } = useSearchFilters(
    batchInvoiceReportsPageHeaderConfig,
  );

  useSetInitialPaginationOnMount();

  useEffect(() => {
    if (R.isEmpty(location.search)) {
      updateQueryParams(defaultBatchInvoiceReportsFilters);
    } else {
      setFilters(searchFilters);
    }
  }, []);

  const variables = {
    input: {
      ...prepareQueryInput(filters),
      ...paginationToQueryParams(pagination),
    },
  };
  const skip = !filters?.clientId;
  const { data, loading } = useQuery(BATCH_INVOICE_REPORTS_QUERY, {
    variables,
    fetchPolicy: 'network-only',
    skip,
  });

  useSetItemsCount(R.pathOr(0, ['clientInvoiceBatches', 'total']), data);
  const batches = R.pathOr([], ['clientInvoiceBatches', 'hits'], data);

  useRedirectIfHasNoPermissions([READ_CLIENT_BATCH_PERMISSION]);

  return (
    <PageWithSearchHeader headerHeight="160px">
      <BatchInvoiceReportsHeader loading={loading} setFilters={setFilters} />
      <SearchPageContentWrapper>
        <TableCardWithPaginationContainer>
          {loading ? (
            <Loader />
          ) : (
            <BatchInvoiceReportsTable batches={batches} />
          )}
        </TableCardWithPaginationContainer>
      </SearchPageContentWrapper>
    </PageWithSearchHeader>
  );
}
