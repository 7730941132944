import React, { memo, useEffect } from 'react';
import * as R from 'ramda';
import { FieldLayout } from '@poly/admin-book';
import { func, number, shape, string } from 'prop-types';
import { MoneyInput } from '@poly/admin-ui';

import {
  LabelColumn,
  LineContainer,
  HeaderLineContainer,
  LineTotalAmount,
} from '../common.js';
import { SupplierLink } from '../../../../components/Links.js';

function MarkupEntriesLabel() {
  return (
    <HeaderLineContainer>
      <LabelColumn width="45%">Supplier</LabelColumn>
      <LabelColumn width="24%">Estimate</LabelColumn>
      <LabelColumn width="14%" align="end">
        Markup
      </LabelColumn>
      <LabelColumn width="14%" align="end">
        Total
      </LabelColumn>
    </HeaderLineContainer>
  );
}

function SupplierColumn({ value: { _id, name } }) {
  return <SupplierLink _id={_id} name={name} />;
}

SupplierColumn.propTypes = {
  value: shape({
    _id: string.isRequired,
    name: string.isRequired,
  }),
};

export const MarkupEntryField = memo(
  ({ name, index, fields: { value, update }, formData: { markupPercent } }) => {
    const { amount = 0, markup = 0 } = value[index];

    useEffect(() => {
      if (markupPercent) {
        update(index, {
          ...value[index],
          markup: amount * markupPercent,
        });
      }
    }, [amount, markupPercent]);

    return (
      <>
        {index === 0 && <MarkupEntriesLabel />}
        <LineContainer>
          <FieldLayout
            layout={{ width: '45%', padding: 0 }}
            field={{
              name: `${name}.supplierId`,
              Component: SupplierColumn,
            }}
          />
          <FieldLayout
            layout={{ width: '22%', padding: 0 }}
            field={{
              name: `${name}.amount`,
              Component: MoneyInput,
            }}
            validators={[[R.identity, 'Amount is required']]}
          />
          <FieldLayout
            layout={{
              width: '14%',
              label: { align: 'end' },
              padding: 0,
            }}
            field={{
              name: `${name}.markup`,
              Component: LineTotalAmount,
            }}
          />
          <LineTotalAmount value={amount + markup} width="14%" />
        </LineContainer>
      </>
    );
  },
);

MarkupEntryField.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  fields: shape({
    update: func.isRequired,
  }),
  formData: shape({
    markupPercent: number,
  }),
};
