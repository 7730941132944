export const EDITABLE_PAYMENTS_SELECTOR_PROP = 'editablePayments';

export const searchPaymentsFilters = {
  clientId: 'clientId',
  projectId: 'projectId',
  clientInvoiceId: 'clientInvoiceId',
  paymentStartDate: 'paymentStartDate',
  paymentEndDate: 'paymentEndDate',
};

export const paymentTypeOptions = {
  PART: 'partPaymentTypeOption',
  FINAL: 'finalPaymentTypeOption',
};
