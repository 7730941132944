import * as R from 'ramda';
import { useMutation } from '@apollo/client';
import { useNotificationContext } from '@poly/admin-book';
import {
  useModalContext,
  useProcessState,
  useOnSubmitSetStopSubmitting,
} from '@poly/admin-ui';

import { SimpleModalLayout } from '../../components/SimpleModalLayout.js';
import { getAssetDocumentNamesString } from './helpers.js';

const submitMergeAssetComponentsModalId = 'submitMergeAssetComponentsModalId';

// prepareConfirmMergeContent :: (String, String) -> [AssetManufacturer] -> String
const prepareConfirmMergeContent = (selectedName, confirmModalContent) =>
  R.compose(
    R.concat(R.__, ` ${confirmModalContent}`),
    R.concat(R.__, ` into one under "${selectedName}" name.`),
    R.concat("You're going to merge "),
    getAssetDocumentNamesString,
  );

export const useMergeAssetComponentsConfirmModal = (
  mergeAssetConfig,
  setSelectedRows,
) => {
  const {
    modalId,
    mutation,
    formTitle,
    confirmModalContent,
    prepareMutationInput,
    mutationSuccessMessage,
  } = mergeAssetConfig;

  const { openModal, closeModal } = useModalContext();
  const { showSuccessNotification } = useNotificationContext();
  const { process } = useProcessState(submitMergeAssetComponentsModalId);
  const [runMutation] = useMutation(mutation);

  const confirmHandler = async (formData) => {
    await runMutation({ variables: { input: prepareMutationInput(formData) } });

    showSuccessNotification(mutationSuccessMessage);

    if (setSelectedRows) {
      setSelectedRows([]);
    }

    closeModal(modalId);
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    submitMergeAssetComponentsModalId,
    confirmHandler,
  );

  return ({ selectedName, selectedDocuments, ...props }) =>
    openModal({
      loading: process,
      btnCaption: 'Merge',
      Layout: SimpleModalLayout,
      id: submitMergeAssetComponentsModalId,
      title: formTitle,
      content: prepareConfirmMergeContent(
        selectedName,
        confirmModalContent,
      )(selectedDocuments),
      onConfirm: (closeConfirmModal) => () => {
        onSubmit({ selectedName, selectedDocuments, ...props });
        closeConfirmModal();
      },
    });
};
