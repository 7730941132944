import * as R from 'ramda';
import { useReactiveQuery } from '@poly/client-utils';
import { useSelector } from 'react-redux';

import { useSetItemsCount } from './useSetItemsCount.js';
import { usePaginationParams } from './usePaginationParams.js';

export const useSearchQueryWithPagination = (
  query,
  subscription,
  { queryName, elasticQuery, sortQuery, ...options },
) => {
  const paginationParams = usePaginationParams();

  const searchText = useSelector(R.prop('searchText'));

  const searchTextOptions = searchText ? { searchTerm: searchText } : {};

  const variables = {
    searchInput: {
      sort: sortQuery,
      query: elasticQuery,
      ...paginationParams,
      ...searchTextOptions,
    },
  };

  const { data, loading } = useReactiveQuery(query, subscription, {
    queryOptions: {
      ...options,
      variables,
    },
    subscriptionOptions: {
      variables,
    },
  });

  useSetItemsCount(R.pathOr(0, [queryName, 'total']), data);

  return {
    data,
    loading,
  };
};
