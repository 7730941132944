import React from 'react';
import { shape } from 'prop-types';
import { SidebarRow } from '@poly/admin-ui';

import {
  AACManager,
  ClientManager,
  SiteContactor,
} from '../components/commonSidebarDetailsList.js';
import {
  SectionLabel,
  SectionWrapper,
  threeBlocksWrapperProps,
} from '../components/commonSidebarComponents.js';

export function MasterRecurringProjectSidebarContactInfo({ project }) {
  return (
    <SectionWrapper>
      <SidebarRow>
        <SectionLabel width="100%">Contact Information</SectionLabel>
      </SidebarRow>
      <SidebarRow {...threeBlocksWrapperProps}>
        <AACManager project={project} />
        <ClientManager project={project} />
        <SiteContactor project={project} />
      </SidebarRow>
    </SectionWrapper>
  );
}

MasterRecurringProjectSidebarContactInfo.propTypes = {
  project: shape({}),
};
