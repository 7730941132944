import React from 'react';
import { bool } from 'prop-types';
import { useOutSidebarContext } from '@poly/client-utils';
import { AddStaffUserForm } from '@poly/admin-ui';

import { addUserFormId } from '../../constants.js';
import { SidebarFormLayout } from '../../../components/SidebarFormLayout.js';
import { useSidebarLogicContext } from '../../../SidebarLogicContext.js';

function AddUserForm({ isContractor }) {
  const { closeOutSidebar } = useOutSidebarContext();
  const { openUserSidebar } = useSidebarLogicContext();

  const formProps = {
    isContractor,
    isCardLayout: false,
    formId: addUserFormId,
    onMutationSuccess: (newUserId) => {
      closeOutSidebar(addUserFormId);
      openUserSidebar(newUserId);
    },
  };

  return <AddStaffUserForm {...formProps} />;
}

AddUserForm.propTypes = { isContractor: bool };

export function AddUserSidebarForm({ isContractor = false }) {
  return (
    <SidebarFormLayout
      title="Add Staff"
      formId={addUserFormId}
      submitButtonCaption="Create"
    >
      <AddUserForm isContractor={isContractor} />
    </SidebarFormLayout>
  );
}

AddUserSidebarForm.propTypes = { isContractor: bool };
