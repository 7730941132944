import * as R from 'ramda';
import styled from 'styled-components';
import React, { useCallback, useMemo } from 'react';
import { arrayOf, shape, string, bool } from 'prop-types';
import { LinkButton, Icon, Popover } from '@poly/admin-book';
import { collectionNames } from '@poly/constants';
import { pathEqLegacy } from '@poly/utils';
import {
  useSidebarTableFormContext,
  CommonSidebarFormWrapper,
  entities,
} from '@poly/admin-ui';

import { AttachProcedureForm } from './AttachProcedureForm.js';
import { UnlinkProcedureButton } from './UnlinkProcedureButton.js';
import { useOpenProcedureDetailsSidebar } from './useOpenProcedureDetailsSidebar.js';

export const attachProcedureFormAnchorId = 'attachProcedureFormAnchorId';

const ProcedureLinkWrapperS = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ProcedureNameComponentWrapperS = styled(ProcedureLinkWrapperS)`
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;

  button {
    max-width: 75%;
  }
`;

const StatusIconS = styled(Icon)`
  margin: 0 5px;
`;

const PopoverContent = styled.div`
  padding: 10px;
  background: #12347a;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
`;

// getAssetProcedureByField :: (ID, String) -> [ProjectAssetProcedure] -> Object
const getAssetProcedureByField = (assetId, fieldName) =>
  R.compose(R.prop(fieldName), R.find(pathEqLegacy(['asset', '_id'], assetId)));

const projectCollectionByEntityMap = {
  [entities.PROJECT]: collectionNames.projects,
  [entities.RECURRING_PROJECT]: collectionNames.recurringProjects,
};

export function ProcedureNameComponent({
  _id,
  type,
  entity,
  assetIds,
  assetProcedures,
  isProcedureDisabled,
}) {
  const openProcedureDetailsSidebar = useOpenProcedureDetailsSidebar();
  const { formSetter } = useSidebarTableFormContext();

  const procedure = getAssetProcedureByField(_id, 'procedure')(assetProcedures);

  const executedProcedure = getAssetProcedureByField(
    _id,
    'executedProcedure',
  )(assetProcedures);

  const collection = projectCollectionByEntityMap[entity.name];

  const onCancel = useCallback(() => {
    formSetter(null);
  }, []);

  const formId = `${attachProcedureFormAnchorId}-${entity.projectId}`;

  const formProps = useMemo(
    () => ({
      formId,
      entity,
      onCancel,
      collection,
      assetId: _id,
      assetType: type,
    }),
    [assetIds],
  );

  const wrapperProps = useMemo(
    () => ({
      formId,
      onCancel,
      skipMargin: true,
      submitCaption: 'Attach',
      title: 'Attach Procedure',
    }),
    [],
  );

  const onAttachProcedure = () => {
    if (!isProcedureDisabled) {
      formSetter({
        id: formId,
        Content: (
          <CommonSidebarFormWrapper {...wrapperProps}>
            <AttachProcedureForm {...formProps} />
          </CommonSidebarFormWrapper>
        ),
      });
    }
  };

  const onViewProcedure = () => {
    if (!executedProcedure) {
      return;
    }

    openProcedureDetailsSidebar(executedProcedure);
  };

  if (!procedure?.name) {
    return (
      <LinkButton onClick={onAttachProcedure} disabled={isProcedureDisabled}>
        Attach Procedure
      </LinkButton>
    );
  }

  const statusProps = {
    size: 15,
    name: executedProcedure ? 'check' : 'time',
    color: executedProcedure ? '#85bb49' : '#8599f0',
  };

  const popoverText = executedProcedure ? 'Completed' : 'Pending';

  return (
    <ProcedureNameComponentWrapperS>
      <ProcedureLinkWrapperS>
        <LinkButton onClick={onViewProcedure}>{procedure?.name}</LinkButton>
        <Popover
          position="bottom"
          bgColor="#12347a"
          title={<StatusIconS {...statusProps} />}
          content={<PopoverContent>{popoverText}</PopoverContent>}
        />
      </ProcedureLinkWrapperS>
      {!executedProcedure && (
        <UnlinkProcedureButton
          assetId={_id}
          projectId={entity._id}
          collection={collection}
          procedureId={procedure?._id}
        />
      )}
    </ProcedureNameComponentWrapperS>
  );
}

ProcedureNameComponent.propTypes = {
  _id: string,
  type: shape({}),
  assetIds: arrayOf(string),
  assetProcedures: arrayOf(shape({})),
  isProcedureDisabled: bool.isRequired,
  entity: shape({ name: string, projectId: string }),
};
