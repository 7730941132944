import { DESC_SORT_ORDER, ELASTIC_SCORE_FIELD } from '@poly/constants';
import * as R from 'ramda';
import { gql, useMutation } from '@apollo/client';
import { propEqLegacy } from '@poly/utils';

import { ENTITY_FILES_QUERY } from '../../../hooks/files/queries.js';
import { updateCacheHandler } from '../../../utils/apollo-cache.js';

export const getFilesSearchQuery = ({
  searchTerm,
  collection,
  documentId,
}) => ({
  searchTerm,
  sort: [ELASTIC_SCORE_FIELD, { createdAt: DESC_SORT_ORDER }],
  query: {
    function_score: {
      query: {
        bool: {
          must: [
            {
              nested: {
                path: 'relations',
                query: {
                  bool: {
                    must: [
                      {
                        match: {
                          'relations.collection': collection,
                        },
                      },
                      { match: { 'relations._id': documentId } },
                    ],
                  },
                },
              },
            },
          ],
        },
      },
      boost: 5,
      functions: [
        {
          filter: { term: { pinned: true } },
          weight: 20,
        },
        {
          filter: {
            bool: {
              must_not: [{ term: { pinned: true } }],
            },
          },
          weight: 10,
        },
      ],
      score_mode: 'max',
      boost_mode: 'multiply',
    },
  },
});

const TOGGLE_FILE_PIN_MUTATION = gql`
  mutation TOGGLE_FILE_PIN_MUTATION($id: ID!) {
    toggleFilePin(id: $id) {
      file {
        _id
      }
    }
  }
`;

// toggleFilePinInCache :: (String, String) -> FileCacheData -> FileCacheData
// FileCacheData = { searchFiles: { hits: [File] } }
const toggleFilePinInCache = (fileId) =>
  R.over(
    R.lensPath(['searchFiles', 'hits']),
    R.converge(
      R.over(
        R.__,
        R.compose(
          R.evolve({
            pinned: R.compose(R.not, R.defaultTo(false)),
          }),
        ),
      ),
      [
        R.compose(R.lensIndex, R.findIndex(propEqLegacy('_id', fileId))),
        R.identity,
      ],
    ),
  );

export const useToggleFilePin = ({ queryVariables, _id: fileId }) => {
  const [toggleFilePinMutation, data] = useMutation(TOGGLE_FILE_PIN_MUTATION);
  const mutate = async () => {
    await toggleFilePinMutation({
      variables: { id: fileId },
      update: updateCacheHandler(
        ENTITY_FILES_QUERY,
        queryVariables,
        toggleFilePinInCache(fileId),
      ),
    });
  };
  return [mutate, data.loading];
};
