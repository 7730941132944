import * as R from 'ramda';
import React from 'react';
import { Text } from '@poly/admin-book';
import { shape } from 'prop-types';
import { formatTotal } from '@poly/utils';
import { NOTHING_UI_STRING, ProjectSpendTypesUI } from '@poly/constants';

import {
  PrintProjectFlexRow,
  PrintProjectArrayText,
  PrintProjectFlexColumn,
  PrintProjectBlockWithLabel,
} from '../components.js';

// getClientNameUI :: { client: Client } -> String
const getClientNameUI = R.pathOr(NOTHING_UI_STRING, ['client', 'name']);

// getPropertyNameUI :: { property: Property } -> String
const getPropertyNameUI = R.pathOr(NOTHING_UI_STRING, ['property', 'name']);

// getSubPropertyNamesUI :: { subProperties: [Property] } -> [String]
const getSubPropertyNamesUI = R.compose(
  R.ifElse(R.isEmpty, R.always([NOTHING_UI_STRING]), R.map(R.prop('name'))),
  R.propOr([], 'subProperties'),
);

// getFollowupToProjectNumberUI :: { clonedFromProject: Project } -> String
const getFollowupToProjectNumberUI = R.pathOr(NOTHING_UI_STRING, [
  'cloneFromProject',
  'projectId',
]);

// getServiceTypeNameUI :: { serviceType: ServiceType } -> String
const getServiceTypeNameUI = R.pathOr(NOTHING_UI_STRING, [
  'serviceType',
  'name',
]);

// getSpendTypeUI :: Project -> String
const getSpendTypeUI = R.compose(
  R.propOr(NOTHING_UI_STRING, R.__, ProjectSpendTypesUI),
  R.prop('spendType'),
);

// getClientNteUI :: Project -> String
const getClientNteUI = R.ifElse(
  R.propSatisfies(R.isNil, 'nte'),
  R.always(NOTHING_UI_STRING),
  R.compose(formatTotal, R.prop('nte')),
);

// getClientRefNumberUI :: Project -> String
const getClientRefNumberUI = R.cond([
  [
    R.pathSatisfies(R.is(String), ['adminPurchaseOrder', 'poNumber']),
    R.compose(
      R.join(' / '),
      R.reject(R.isNil),
      R.juxt([R.prop('poNumber'), R.prop('displayName')]),
      R.prop('adminPurchaseOrder'),
    ),
  ],
  [
    R.propSatisfies(
      R.both(R.is(String), R.complement(R.isEmpty)),
      'clientReferenceNumber',
    ),
    R.prop('clientReferenceNumber'),
  ],
  [R.T, R.always(NOTHING_UI_STRING)],
]);

export function PrintProjectProjectDetails({ project }) {
  return (
    <PrintProjectFlexColumn withBottomBorder>
      <PrintProjectFlexRow>
        <Text size="16px" weight="bold">
          Project Details
        </Text>
      </PrintProjectFlexRow>
      <PrintProjectFlexRow>
        <PrintProjectBlockWithLabel
          label="Client name"
          value={getClientNameUI(project)}
        />
      </PrintProjectFlexRow>
      <PrintProjectFlexRow>
        <PrintProjectBlockWithLabel
          label="Property"
          value={getPropertyNameUI(project)}
        />
        <PrintProjectBlockWithLabel
          label="Sub Properties"
          Component={PrintProjectArrayText}
          arrayValue={getSubPropertyNamesUI(project)}
          arrayKeyPart={project?.projectId}
        />
        <PrintProjectBlockWithLabel
          label="Followup to project #"
          value={getFollowupToProjectNumberUI(project)}
        />
      </PrintProjectFlexRow>
      <PrintProjectFlexRow>
        <PrintProjectBlockWithLabel
          label="Service type"
          value={getServiceTypeNameUI(project)}
        />
        <PrintProjectBlockWithLabel
          label="Spend Type"
          value={getSpendTypeUI(project)}
        />
        <PrintProjectBlockWithLabel
          label="Client NTE"
          value={getClientNteUI(project)}
        />
      </PrintProjectFlexRow>
      <PrintProjectFlexRow>
        <PrintProjectBlockWithLabel
          label="Client Ref # / PO"
          value={getClientRefNumberUI(project)}
        />
      </PrintProjectFlexRow>
    </PrintProjectFlexColumn>
  );
}

PrintProjectProjectDetails.propTypes = {
  project: shape({}),
};
