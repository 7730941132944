import React from 'react';
import styled from 'styled-components';
import { Text, Icon } from '@poly/admin-book';
import { formatDate } from '@poly/utils';
import { string, oneOfType, instanceOf } from 'prop-types';
import { Popover } from '@poly/admin-book/src/Popover/index.js';

const DueDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 5px;
`;

const TextS = styled(Text)`
  max-width: 350px;
  color: #fff;
`;

const PopoverContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: start;
  background-color: #12347a;
  color: #fff;
  border-radius: 5px;
  max-width: 230px;
  font-size: 12px;
`;

export function InvoiceDueDateWithWarning({
  dueDate,
  paymentDueDateChangeReason,
  popoverPosition = 'left',
}) {
  const iconElement = <Icon name="yellowWarning" size={16} />;

  return (
    <DueDateWrapper>
      <Text>{formatDate(dueDate)}</Text>
      {paymentDueDateChangeReason && (
        <Popover
          position={popoverPosition}
          bgColor="#12347a"
          isClickable={false}
          title={iconElement}
          content={
            <PopoverContentWrapper>
              <TextS size="14px">Due date was changed. Reason:</TextS>
              <TextS size="14px">{paymentDueDateChangeReason}</TextS>
            </PopoverContentWrapper>
          }
        />
      )}
    </DueDateWrapper>
  );
}

InvoiceDueDateWithWarning.propTypes = {
  dueDate: oneOfType([instanceOf(Date), string]),
  popoverPosition: string,
  paymentDueDateChangeReason: string,
};
