import React from 'react';
import { Button, ModalActions, Text } from '@poly/admin-book';
import { string } from 'prop-types';
import { CancelModalFormBtn } from '../../../components/Buttons.js';
import { MentionGroupForm, mentionGroupFormId } from './MentionGroupForm.js';
import { FieldsWrapper, FormWrapper } from '../common.js';
import { useFormButtonProps } from '../../core/hooks/users/form.js';

function SubmitButton(props) {
  const formProps = useFormButtonProps(mentionGroupFormId);
  return <Button {...props} {...formProps} />;
}

function CancelButton(props) {
  const formProps = useFormButtonProps(mentionGroupFormId, false);
  return <CancelModalFormBtn {...props} {...formProps} />;
}

export function MentionGroupFromWithButtons({
  title,
  submitCaption,
  ...restProps
}) {
  return (
    <FormWrapper>
      <Text size="21px">{title}</Text>
      <FieldsWrapper>
        <MentionGroupForm
          {...{ onCancel: restProps.closeCurrentModal, ...restProps }}
        />
      </FieldsWrapper>
      <ModalActions>
        <CancelButton />
        <SubmitButton size="small">{submitCaption}</SubmitButton>
      </ModalActions>
    </FormWrapper>
  );
}

MentionGroupFromWithButtons.displayName = 'MentionGroupFromWithButtons';
MentionGroupFromWithButtons.propTypes = {
  title: string.isRequired,
  submitCaption: string.isRequired,
};
