import styled from 'styled-components';
import { BaseSidebarLayout } from '@poly/admin-ui';
import {
  getThemeColor,
  FormCreator,
  LinkButton,
  Select,
  Icon,
} from '@poly/admin-book';

import { SidebarFormLayout } from '../../../../sidebars/components/SidebarFormLayout.js';

export const UserGroupPermissionLineWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid #f2f2f2;
`;

export const UserGroupVariableLineS = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  > button:not(:first-child) {
    margin-left: 10px;

    > svg {
      margin: 0;
    }
  }
`;

export const UserGroupFlexLineS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: ${({ align }) => align || 'flex-start'};
  padding-bottom: 5px;
  ${({ height }) => (height ? `height: ${height};` : '')}
`;

export const UserGroupPermissionSectionS = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #f2f2f2;
  padding: 12px 24px 0 24px;

  > div > div:last-child {
    margin-top: 7px;
  }
`;

export const UserGroupPermissionTitleS = styled(UserGroupFlexLineS)`
  height: 72px;
  padding: 24px;
  font-size: 18px;
  border-bottom: 1px solid #f2f2f2;
`;

export const MissingLoginPermissionWrapperS = styled.div`
  display: flex;
  padding: 4px 12px;
  border-radius: 5px;
  background-color: #ffeeea;
  font-size: 10px;
  color: #e75858;
  line-height: 13px;

  > svg {
    margin-right: 3px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  > button {
    position: relative;

    :not(:first-child) {
      margin-left: 10px;
    }

    :not(:last-child) {
      margin-right: 10px;

      ::after {
        display: block;
        position: absolute;
        top: 9px;
        right: -16px;
        content: '';
        width: 12px;
        transform: rotate(90deg);
        border: 0.5px solid #bcbcbc;
      }
    }
  }
`;

export const DeleteLinkButtonS = styled(LinkButton)`
  color: #c34444;
`;

export const UserGroupFormCreatorS = styled(FormCreator)`
  justify-content: flex-start;
  min-height: calc(100vh - 202px);
  margin-top: 12px;
`;

export const SidebarLayoutS = styled(BaseSidebarLayout)`
  background-color: #f9f9f9;
`;

export const SidebarFormLayoutS = styled(SidebarFormLayout)`
  > div:last-child {
    background-color: #f9f9f9;
  }
`;

export const AddVariableOptionS = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  color: ${getThemeColor(['newDarkAccent2'])};

  ${({ withBorder }) => withBorder && 'border-bottom: 1px solid #f2f2f2;'}
`;

export const UserGroupVariablesSelectS = styled(Select)`
  .select__single-value {
    width: calc(100% - 25px);
  }
`;

export const EditVariableIconS = styled(Icon)`
  top: 8px;
  right: 2px;
  cursor: pointer;
  background: #fff;
  position: absolute;
`;
