import { useMutation } from '@apollo/client';
import { useReactiveQuery } from '@poly/client-utils';
import { useCurrentUserByStoreOrQuery } from '@poly/client-utils/src/hooks/useCurrentUserByStoreOrQuery.js';

import {
  MY_TIMESHEETS,
  TIMESHEET_REPORT,
  TIMESHEET_SUBSCRIPTION,
} from './queries.js';
import { CREATE_TIMESHEET, UPDATE_TIMESHEET } from './mutations.js';
import { getTimeSheetReportSubscriptionQuery } from './getTimeSheetReportSubscriptionQuery.js';

export const useMyTimesheetsQuery = ({ timesheetDateRange }) => {
  const { user } = useCurrentUserByStoreOrQuery();

  const { startDate, endDate } = timesheetDateRange;

  const { data, loading } = useReactiveQuery(
    MY_TIMESHEETS,
    TIMESHEET_SUBSCRIPTION,
    {
      queryOptions: {
        variables: { input: { startDate, endDate } },
      },
      subscriptionOptions: {
        variables: {
          input: {
            query: getTimeSheetReportSubscriptionQuery({
              ...timesheetDateRange,
              ownerId: user?._id,
            }),
          },
        },
      },
    },
  );

  return {
    data,
    loading,
  };
};

export const useTimeSheetReport = ({ input }) => {
  const { data, loading } = useReactiveQuery(
    TIMESHEET_REPORT,
    TIMESHEET_SUBSCRIPTION,
    {
      queryOptions: { variables: { input } },
      subscriptionOptions: {
        variables: {
          input: { query: getTimeSheetReportSubscriptionQuery(input) },
        },
      },
    },
  );

  return {
    data,
    loading,
  };
};

export const useCreateTimesheet = () => {
  const [mutate] = useMutation(CREATE_TIMESHEET);

  return {
    createTimesheet: (input) => mutate({ variables: { input } }),
  };
};

export const useUpdateTimesheet = () => {
  const [mutate] = useMutation(UPDATE_TIMESHEET);

  return {
    updateTimesheet: (id, input) => mutate({ variables: { id, input } }),
  };
};
