import { ClientInvoiceStatuses } from '@poly/constants';

export const searchClientInvoicesFilters = {
  clientId: 'clientId',
  isPrinted: 'isPrinted',
  invoiceDate: 'invoiceDate',
  startDate: 'startDate',
  endDate: 'endDate',
  invoiceBy: 'invoiceBy',
  invoiceNumber: 'invoiceNumber',
  invoiceNumberFrom: 'invoiceNumberFrom',
  invoiceNumberTo: 'invoiceNumberTo',
  status: 'status',
  projectId: 'projectId',
  consolidatedClientInvoiceId: 'consolidatedClientInvoiceId',
};

export const filterClientInvoiceByOptions = {
  one: 'one',
  range: 'range',
};

export const clientInvoiceStatusOptions = {
  [ClientInvoiceStatuses.PENDING]: 'Pending',
  [ClientInvoiceStatuses.PARTIALLY_PAID]: 'Partially Paid',
  [ClientInvoiceStatuses.PAID]: 'Paid',
  [ClientInvoiceStatuses.VOIDED]: 'Voided',
  [ClientInvoiceStatuses.WRITTEN_OFF]: 'Written-off',
};
