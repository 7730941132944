import { gql } from '@apollo/client';

export const commonInvoiceFields = gql`
  fragment commonInvoiceFields on Invoice {
    _id
    type
    invoiceNumber
    invoiceDate
    total
    invoiceFile {
      url
      fileName
      fileSize
      fileType
    }
    status
    materialCost
    hourlyRate
    labourCost
    taxAmount
  }
`;
