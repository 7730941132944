import React from 'react';

export const withReactContext = (propName, ContextType) => (Component) => {
  function WithReactContextC(props) {
    return (
      <ContextType.Consumer>
        {(context) => <Component {...{ ...props, [propName]: context }} />}
      </ContextType.Consumer>
    );
  }

  WithReactContextC.displayName = `WithReactContextC(${
    ContextType.displayName || ''
  })`;
  return WithReactContextC;
};
