import * as R from 'ramda';
import { capitalizeFirstLetter } from '@poly/client-utils';
import {
  UpdateTypes,
  ProjectType,
  ClientAppNames,
  SupplierSources,
  filesCollections,
  RecurringProjectTypes,
} from '@poly/constants';

const customUserGroupAIT_UIValuesUI = {
  [ProjectType.WORK_ORDER]: 'T&M',
  [RecurringProjectTypes.recurringProject]: 'Recurring Project',
  [RecurringProjectTypes.preventiveMaintenanceProject]:
    'Preventive Maintenance',
};

const UPPER_CASE_WORDS_CONFIG = ['TIAA', 'OCR', 'AAC'];

// generateAITValuesUI :: String -> String
const generateAITValuesUI = R.compose(
  R.join(' '),
  R.map(
    R.ifElse(
      R.includes(R.__, UPPER_CASE_WORDS_CONFIG),
      R.identity,
      R.compose(capitalizeFirstLetter, R.toLower),
    ),
  ),
  R.split('_'),
);

// generateUserGroupAITValuesUI :: ([Object], Object) -> Object
export const generateUserGroupAITValuesUI = (types, customConfig = {}) =>
  R.compose(
    R.mergeLeft(customConfig),
    R.mergeAll,
    R.map(
      R.compose(
        R.map(generateAITValuesUI),
        R.fromPairs,
        R.map(R.reverse),
        R.toPairs,
      ),
    ),
  )(types);

export const UserGroupAIT_UIValuesUI = generateUserGroupAITValuesUI(
  [ClientAppNames, UpdateTypes, ProjectType, SupplierSources, filesCollections],
  customUserGroupAIT_UIValuesUI,
);
