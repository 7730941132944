import { string } from 'prop-types';
import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { InvoicesStatuses } from '@poly/constants';
import { MAX_ITEMS } from '@poly/admin-ui';
import { Loader } from '@poly/admin-book';

import { SupplierAccountTabComp } from './SupplierAccountTabComp.js';
import { prepareSupplierForDisplaying } from './supplierAccountUtils.js';
import { SUPPLIER_ACCOUNT_WITH_INVOICES } from '../../core/hooks/suppliers/queries.js';

const searchInput = {
  query: {
    bool: {
      must: [
        {
          terms: {
            status: [InvoicesStatuses.PARTIALLY_PAID, InvoicesStatuses.PENDING],
          },
        },
      ],
    },
  },
  size: MAX_ITEMS,
};

export function SupplierAccountInfo({ supplierId }) {
  const { data, loading, refetch } = useQuery(SUPPLIER_ACCOUNT_WITH_INVOICES, {
    variables: { supplierId, searchInput },
    fetchPolicy: 'network-only',
  });

  const supplier = useMemo(() => (data ? data.supplier : {}), [data]);

  if (loading) {
    return <Loader />;
  }

  const preparedProps = prepareSupplierForDisplaying({ supplier });

  return <SupplierAccountTabComp {...preparedProps} refetch={refetch} />;
}

SupplierAccountInfo.propTypes = { supplierId: string.isRequired };
SupplierAccountInfo.displayName = 'SupplierAccountTab';
