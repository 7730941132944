import React from 'react';

import { object, string, element, bool, func } from 'prop-types';

import { InputContainer, Input, InputIconWrapper } from './styles.js';
import { InputErrorMsg } from '../Input/index.js';
import { defaultTheme } from '../ThemeProvider/index.js';
import { Icon } from '../Icon/index.js';

const {
  colors: { secondaryLighter1 },
} = defaultTheme;

export function InputWithIcon({
  className,
  containerProps,
  inputProps,
  icon,
  size,
  hasError,
  error,
  showResetBtn,
  onReset,
  dataTestId,
  required,
}) {
  return (
    <div className={className}>
      <InputContainer
        size={size}
        error={error}
        hasError={hasError}
        className={className}
        required={required}
        value={inputProps.value}
        {...containerProps}
      >
        <Input
          {...inputProps}
          size={size}
          autoComplete="new-password"
          data-testid={dataTestId || 'input'}
        />
        {showResetBtn && !inputProps.disabled && (
          <InputIconWrapper onClick={onReset}>
            <Icon name="close" color={secondaryLighter1} size={8} />
          </InputIconWrapper>
        )}
        {icon}
      </InputContainer>
      {error && <InputErrorMsg>{error}</InputErrorMsg>}
    </div>
  );
}

InputWithIcon.propTypes = {
  icon: element.isRequired,
  className: string,
  /* eslint-disable react/forbid-prop-types */
  containerProps: object,
  inputProps: object,
  hasError: bool,
  error: string,
  showResetBtn: bool,
  onReset: func,
  warning: string,
  dataTestId: string,
  size: string,
  required: bool,
};

InputWithIcon.defaultProps = {
  hasError: false,
  error: '',
  className: '',
  inputProps: {},
  containerProps: {},
  showResetBtn: false,
  size: 'regular',
  required: false,
};
