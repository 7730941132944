import * as R from 'ramda';
import { isNilOrEmpty, isValidDate, propEqLegacy } from '@poly/utils';
import { WorkOrderStatus } from '@poly/constants';

import { ALL } from '../../core/constants/general.js';

// datePropOrNul :: String -> Object -> Date
const datePropOrNul = (propName) =>
  R.compose(R.unless(isValidDate, R.always(null)), R.prop(propName));

// generateRecentProjectsQuery :: RecentProjectsFilters -> ElasticQuery
// RecentProjectsFilters = {
//   clientId: ID,
//   endDate: Date,
//   status: String,
//   startDate: Date,
// }
export const generateRecentProjectsQuery = R.compose(
  R.objOf('bool'),
  R.converge(R.mergeLeft, [
    R.compose(
      R.objOf('must'),
      R.reject(isNilOrEmpty),
      R.juxt([
        R.compose(R.objOf('match'), R.pick(['status'])),
        R.compose(
          R.ifElse(
            R.either(isNilOrEmpty, R.equals(ALL)),
            R.always(null),
            R.compose(R.objOf('match'), R.objOf('clientId')),
          ),
          R.prop('clientId'),
        ),
      ]),
    ),
    R.compose(
      R.ifElse(
        R.both(
          R.propSatisfies(R.isNil, 'gte'),
          R.propSatisfies(R.isNil, 'lte'),
        ),
        R.always({}),
        R.compose(
          R.objOf('filter'),
          R.objOf('range'),
          R.converge(R.objOf, [R.prop('filterField'), R.pick(['gte', 'lte'])]),
        ),
      ),
      R.reject(isNilOrEmpty),
      R.applySpec({
        filterField: R.ifElse(
          propEqLegacy('status', WorkOrderStatus.ACTIVE),
          R.always('startDate'),
          R.always('workCompletionDate'),
        ),
        gte: datePropOrNul('startDate'),
        lte: datePropOrNul('endDate'),
      }),
    ),
  ]),
  R.defaultTo({}),
);
