import * as R from 'ramda';
import { useMutation, useQuery } from '@apollo/client';
import {
  useSubscriptionByChanges,
  paginationToQueryParams,
  initialPagination,
} from '@poly/client-utils';
import { WorkOrderStatus } from '@poly/constants';
import {
  ENTITY_TASKS,
  TASK_UPDATES,
  MY_TASKS_QUERY,
} from '../../hocs/tasks/queries.js';
import {
  TASK_SEARCH_CHANGED,
  getTasksSubscriptionOptionsByQueryInput,
} from '../../hocs/tasks/subscriptions.js';
import { usePagination } from '../../redux/pagination.js';
import {
  CREATE_TASK_MUTATION,
  UPDATE_TASK_MUTATION,
  POST_UPDATE_WITH_ACTION_MUTATION,
} from '../../hocs/tasks/mutations.js';
import { useEntityUpdatesByQuery } from '../updates/index.js';
import { MAX_ITEMS } from '../../constants/general.js';

// getTasksSubscriptionOptionsByProps :: {input: TasksInput}
//   -> ApolloOptions
const getTasksSubscriptionOptionsByProps = R.compose(
  getTasksSubscriptionOptionsByQueryInput,
  R.prop('input'),
);

export const useEntityTasksByCollection = ({
  collection,
  entity,
  sortingProp,
}) => {
  const { pagination } = usePagination();

  const input = {
    collection,
    documentId: entity?._id,
    sort: sortingProp || { createdAt: -1, complete: 1, dueDate: -1 },
    ...paginationToQueryParams(pagination),
  };

  const { data, loading, refetch, variables } = useQuery(ENTITY_TASKS, {
    variables: { input },
    skip: !entity?._id,
    fetchPolicy: 'network-only',
  });

  useSubscriptionByChanges({
    gqlQueryChanged: TASK_SEARCH_CHANGED,
    refetch,
    result: { ...data, variables, input },
    skipQuery: !entity?._id,
    extractQueryParamsFromProps: getTasksSubscriptionOptionsByProps,
  });

  return {
    data,
    loading,
  };
};

export const useCreateTask = () => {
  const [mutate] = useMutation(CREATE_TASK_MUTATION, {
    alias: 'useCreateTask',
  });

  return {
    createTask: (variables) => mutate({ variables }),
  };
};

export const useUpdateTask = () => {
  const [mutate] = useMutation(UPDATE_TASK_MUTATION, {
    alias: 'useUpdateTask',
  });

  return {
    updateTask: (variables, additionalProps = {}) =>
      mutate({ variables, ...additionalProps }),
  };
};

export const useTaskUpdatesById = (_id) => {
  const { data, loading } = useEntityUpdatesByQuery({
    query: TASK_UPDATES,
    entity: {
      _id,
      name: 'task',
    },
    maxItemsCount: 20,
  });

  return {
    task: data?.task,
    loading,
  };
};

export const useCreateUpdateWithAction = () => {
  const [mutate] = useMutation(POST_UPDATE_WITH_ACTION_MUTATION, {
    alias: 'useCreateUpdateWithAction',
  });

  return {
    createUpdateWithAction: (variables, additionalProps = {}) =>
      mutate({ variables, ...additionalProps }),
  };
};

const useMyTasks = (input) => {
  const { data, loading, refetch } = useQuery(MY_TASKS_QUERY, {
    variables: { input },
    skip: !input,
    fetchPolicy: 'network-only',
  });

  useSubscriptionByChanges({
    gqlQueryChanged: TASK_SEARCH_CHANGED,
    result: data,
    refetch,
    skipQuery: !input,
  });

  return {
    data,
    loading,
    refetch,
  };
};

const useMyTasksPropsByStatus = (tasksStatus) => ({
  tasksStatus,
  pagination: { ...initialPagination, itemsPerPage: MAX_ITEMS },
  input: {
    complete: false,
    notProjectStatus: [WorkOrderStatus.BLOCKED],
    dueDayStatus: tasksStatus,
  },
});

export const useMyTasksByStatusProp = (status) => {
  const input = useMyTasksPropsByStatus(status);

  const { data, loading, refetch } = useMyTasks(input);

  return {
    data,
    loading,
    refetch,
  };
};
