import { gql } from '@apollo/client';

import { commonPeopleFields } from '../users/fragments.js';
import { commonUpdateFields } from '../updates/fragments.js';

export const SUPPLIERS_SEARCH_NAME = gql`
  query SUPPLIERS_SEARCH_NAME($searchInput: CollectionSearchParams!) {
    searchSuppliers(input: $searchInput) {
      hits {
        _id
        company {
          name
        }
      }
      total
    }
  }
`;

export const DEFAULT_SUPPLIER_QUERY = gql`
  query DEFAULT_SUPPLIER_QUERY($id: ID!) {
    supplier(id: $id) {
      _id
      company {
        name
        address {
          formatted_address
        }
      }
    }
  }
`;

export const DEFAULT_MASTER_SUPPLIER_QUERY = gql`
  query DEFAULT_MASTER_SUPPLIER_QUERY($id: ID!) {
    masterSupplier(id: $id) {
      _id
      company {
        name
        address {
          formatted_address
        }
      }
    }
  }
`;

export const SUPPLIER_PEOPLE_QUERY = gql`
  query SUPPLIER_PEOPLE($entityId: ID!, $searchInput: CollectionSearchParams!) {
    supplier(id: $entityId) {
      _id
      searchContacts(input: $searchInput) {
        hits {
          ...commonPeopleFields
        }
        total
      }
    }
  }

  ${commonPeopleFields}
`;

export const SUPPLIER_UPDATES_QUERY = gql`
  query SUPPLIER_UPDATES(
    $id: ID!
    $documentUpdatesInput: DocumentUpdatesInput!
  ) {
    supplier(id: $id) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...commonUpdateFields
        }
        total
      }
    }
  }

  ${commonUpdateFields}
`;
