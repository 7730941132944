import styled, { css } from 'styled-components';

import { Checkbox } from '../Checkbox/index.js';
import { FlexContainer } from '../lib/index.js';
import { VerticalDivider } from '../Divider/index.js';
import { SortingUpDown } from './sorting-up-down.js';
import { getThemeColor, getThemeFont, getThemeProp } from '../utils.js';

export const hideScrollBarCss = css`
  &::-webkit-scrollbar {
    background: transparent;
    display: none;
  }
`;

export const HeaderRow = styled.tr`
  display: table;
  width: 100%;
  table-layout: fixed;
`;

const hoverRowStyles = css`
  &:hover {
    box-shadow: ${getThemeProp(['defaultBoxShadow'])};
    td,
    tr {
      border: none;
    }
    background: ${getThemeColor(['backGround'])};
  }
`;

export const Row = styled(HeaderRow)`
  position: relative;
  background-color: transparent;
  border-top: 1px solid ${getThemeColor(['midLight'])};
  border-top-left-radius: ${getThemeProp(['defaultBorderRadius'])};
  border-top-right-radius: ${getThemeProp(['defaultBorderRadius'])};
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  ${({ enableHoverEffect }) => enableHoverEffect && hoverRowStyles}
`;

const columnStyles = {
  disabled: css`
    color: ${getThemeColor(['midDark'])};

    div {
      color: ${getThemeColor(['midDark'])};
    }

    &:before {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      z-index: 1;
    }
  `,
  normal: css``,
};

export const Body = styled.tbody`
  display: block;
  overflow-y: ${({ overflow = 'scroll' }) => overflow};
  overflow-x: auto;
  width: 100%;
  height: ${({ footerHeight }) => `calc(100% - ${footerHeight + 45}px)`};
  vertical-align: top;

  ${({ showScrollBar }) => (showScrollBar ? '' : hideScrollBarCss)};
`;

export const Head = styled.thead`
  width: 100%;
  overflow-y: scroll;

  scrollbar-width: none;
  ${hideScrollBarCss};
`;

const noTableOuterBorderCss = css`
  tr {
    border: none;

    &:first-child {
      border: none;
    }

    &:last-child {
      border: none;
    }

    &:hover {
      box-shadow: none;
    }
  }
`;

export const TableS = styled.table.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  border-spacing: 0;
  min-height: ${({ minHeightLess }) => !minHeightLess && 'calc(100vh - 400px)'};
  height: 100%;

  svg {
    cursor: pointer;
  }

  ${({ isPrintPDF }) => (isPrintPDF ? noTableOuterBorderCss : '')};

  @media print {
    height: auto;

    // it fixes print bug for firefox
    tbody {
      height: auto !important;
      min-height: 0 !important;
      overflow: visible !important;
    }
  }
`;

const displayNoneCss = css`
  display: none;
`;

export const Cell = styled.td`
  font-weight: ${getThemeFont(['regular'])};
  height: auto;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  padding: 10px 5px;
  position: relative;
  overflow-wrap: break-word;
  ${({ hide }) => (hide ? displayNoneCss : '')}

  &:first-child {
    padding-left: 10px;
  }

  ${({ disabled }) => columnStyles[disabled ? 'disabled' : 'normal']};
`;

export const LoadingMsg = styled.div`
  position: absolute;
  font-weight: ${getThemeFont(['medium'])};
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 200px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 100000;
  border-radius: 5px 5px 0 0;
`;

export const HeaderCell = styled(Cell.withComponent('th'))`
  font-weight: ${getThemeFont(['regular'])};
  color: ${getThemeColor(['midDark'])};
  cursor: ${({ isSortable }) => (isSortable ? 'pointer' : 'default')};
  height: 30px;
  padding: 15px 0 15px 5px;
`;

export const SortingUpDownS = styled(SortingUpDown)`
  margin-left: 5px;
  padding-top: 1px;
`;

export const CheckboxS = styled(Checkbox)`
  visibility: ${({ isVisible }) => !isVisible && 'hidden'};
`;

export const TableToolBarWrapper = styled(FlexContainer)`
  position: absolute;
  right: 45px;
  top: 15px;
  height: 16px;
  z-index: 1;
`;

export const ToolBarBtnDivider = styled(VerticalDivider)`
  height: 17px;
  width: 1px;
  margin: 0 15px;
  border-right: 1px solid ${getThemeColor(['primary'])};
  flex-shrink: 0;
  opacity: 0.12;
  background-color: transparent;
`;

export const TableWithToolBarWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const TableFooterS = styled.tfoot`
  width: 100%;
  tr {
    display: table;
    width: calc(100% - 60px);
    table-layout: fixed;
    margin: 0 30px;
  }
`;

export const removeBorderPrintStyles = css`
  @media print {
    tr {
      border: none;

      &:first-child {
        border: none;
      }

      &:last-child {
        border: none;
      }
    }
  }
`;
