import React, { useEffect } from 'react';
import * as R from 'ramda';
import { arrayOf, shape, string } from 'prop-types';
import { SidebarRow } from '@poly/admin-ui';
import styled from 'styled-components';
import { isNilOrEmpty } from '@poly/utils';

import {
  BlockWithLabel,
  SectionLabel,
  SectionWrapper,
  threeBlocksWrapperProps,
} from '../components/commonSidebarComponents.js';
import { usePropertyHierarchies } from './usePropertyHierarchies.js';

const PathWrapper = styled.div`
  font-size: 12px;
`;

const propertyPropType = shape({
  _id: string.isRequired,
  name: string.isRequired,
  client: shape({
    _id: string.isRequired,
  }),
});

export const hierarchyNodePropType = shape({
  id: string.isRequired,
  name: string.isRequired,
  parentId: string,
  properties: arrayOf(
    shape({ name: string.isRequired, _id: string.isRequired }),
  ),
});

// findPropertyNode :: (PropertyHierarchy, ID) -> HierarchyNode
// HierarchyNode = {
//  id: ID
//  name: String
//  parentId: ID
//  properties: [Property]
// }
export const findPropertyNode = R.curry((hierarchy, propertyId) =>
  R.compose(
    R.find(
      R.compose(
        R.is(Object),
        R.find(R.compose(R.equals(propertyId), R.prop('_id'))),
        R.prop('properties'),
      ),
    ),
    R.prop('nodes'),
  )(hierarchy),
);

// mapHierarchyNodes :: PropertyHierarchy -> { [ID]: HierarchyNode }
const mapHierarchyNodes = R.compose(
  R.mergeAll,
  R.map(R.converge(R.objOf, [R.prop('id'), R.identity])),
  R.prop('nodes'),
);

// getNodePath :: ({ [ID]: HierarchyNode }, ID) -> [HierarchyNode]
const getNodePath = (hierarchyNodes, currentId) => {
  const current = hierarchyNodes[currentId];

  if (current.parentId) {
    return [...getNodePath(hierarchyNodes, current.parentId), current];
  }

  return [current];
};

// getHierarchyPath :: [HierarchyNode] -> String
const getHierarchyPath = R.compose(R.join(' / '), R.map(R.prop('name')));

function PropertyPath(path) {
  return function () {
    return <PathWrapper>{getHierarchyPath(path)}</PathWrapper>;
  };
}

function Hierarchy({ hierarchy, property }) {
  const propertyNode = findPropertyNode(hierarchy, property._id);
  const path = getNodePath(mapHierarchyNodes(hierarchy), propertyNode.id);

  return (
    <SidebarRow {...threeBlocksWrapperProps}>
      <BlockWithLabel
        id={hierarchy._id}
        label={hierarchy.name}
        margin="0"
        width="100%"
        Component={PropertyPath(path)}
      />
    </SidebarRow>
  );
}

Hierarchy.propTypes = {
  hierarchy: shape({
    _id: string.isRequired,
    name: string.isRequired,
    nodes: arrayOf(hierarchyNodePropType),
  }),
  property: propertyPropType,
};

export function PropertySidebarHierarchy({ property }) {
  const { _id, client } = property;

  const { hierarchies, loading, refetch } = usePropertyHierarchies(
    client._id,
    _id,
  );

  useEffect(() => {
    if (hierarchies) {
      refetch();
    }
  }, []);

  if (loading || isNilOrEmpty(hierarchies)) {
    return null;
  }

  return (
    <SectionWrapper>
      <SidebarRow>
        <SectionLabel>Hierarchies</SectionLabel>
      </SidebarRow>

      {hierarchies.map((hierarchy) => (
        <Hierarchy
          hierarchy={hierarchy}
          property={property}
          key={hierarchy._id}
        />
      ))}
    </SectionWrapper>
  );
}

PropertySidebarHierarchy.propTypes = {
  property: propertyPropType,
};
