import styled from 'styled-components';
import React from 'react';
import { CreditCardUserStatuses } from '@poly/constants';
import { capitalizeFirstLetter } from '@poly/client-utils';
import { TextButton, Table, Input, Select } from '@poly/admin-book';

import { ccUserStatusNamesMap } from './constants.js';

export const CCUserTextButtonS = styled(TextButton)`
  font-size: 14px;
`;

export const CCName = styled.div`
  font-weight: bold;
`;

export const CCUserName = styled.div`
  margin-left: 20px;
`;

export const CCUsersTableS = styled(Table)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 370px;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 80px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 40px;
    text-align: right;
    padding-right: 20px;
  }
`;

export const CardNumberInputS = styled(Input).attrs(() => ({
  type: 'numberMask',
  format: '####',
  mask: '_',
}))``;

const statusesOptions = [
  {
    value: CreditCardUserStatuses.ACTIVE,
    label: capitalizeFirstLetter(
      ccUserStatusNamesMap[CreditCardUserStatuses.ACTIVE],
    ),
  },
  {
    value: CreditCardUserStatuses.INACTIVE,
    label: capitalizeFirstLetter(
      ccUserStatusNamesMap[CreditCardUserStatuses.INACTIVE],
    ),
  },
];

export function CCUserStatusDropDown(props) {
  const selectProps = {
    ...props,
    width: '100%',
    options: statusesOptions,
  };

  return <Select {...selectProps} />;
}
