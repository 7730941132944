import styled from 'styled-components';
import { FormCreator } from '@poly/admin-book';
import { BaseSidebarLayout, FlexColumn } from '@poly/admin-ui';

import { SidebarFormLayout } from '../../sidebars/components/SidebarFormLayout.js';

export const ProcedureSidebarLayout = styled(BaseSidebarLayout)`
  background-color: #f9f9f9;
`;

export const ProcedureSidebarFormLayout = styled(SidebarFormLayout)`
  > div:last-child {
    background-color: #f9f9f9;
  }
`;

export const ProcedureFormCreator = styled(FormCreator)`
  min-height: calc(100vh - 202px);
  margin-top: 12px;
`;

export const ProcedureStepConfigurationLineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ProcedureStepConfigurationTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 72px;
  padding: 24px 0;
  font-size: 16px;
  letter-spacing: 1px;
  border-bottom: 1px solid #f2f2f2;
`;

export const ManageProceduresPageTableWrapper = styled(FlexColumn)`
  height: calc(100% - 90px);
  padding: 0 50px;
`;

export const AssetTypeColumnComponent = styled.span`
  display: inline-flex;
  padding: 2px 10px;
  border-radius: 5px;
  background-color: #e8ecf4;
  color: #4c66a2;
  margin: 2px 4px;
`;
