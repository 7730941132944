import {
  node,
  bool,
  shape,
  string,
  object,
  arrayOf,
  oneOfType,
} from 'prop-types';
import * as R from 'ramda';
import styled from 'styled-components';
import React, { useMemo, useEffect } from 'react';
import { useSubscription, gql } from '@apollo/client';
import { IconButton, defaultTheme, Popover } from '@poly/admin-book';
import { NOTHING_UI_STRING, ProjectType } from '@poly/constants';
import { prepareInvoiceDescription } from '@poly/utils';
import {
  useInfiniteScrollQueryWithSubscription,
  highlightMatchesInObject,
  keywordNestedSortQuery,
  useReactiveQuery,
  keywordSortQuery,
  pathOrNothingUI,
} from '@poly/client-utils';
import {
  useMapConfigToTableProps,
  commonAddressFields,
  useTableSorting,
  MAX_ITEMS,
  entities,
} from '@poly/admin-ui';

import { SupplierLink } from '../../../components/Links.js';
import { ProjectLink } from '../../ProjectSidebar/useOpenProjectSidebar.js';
import { ProjectOccurrence } from '../../../modules/core/constants/projects.js';
import { AssetStatusDot } from '../../components/commonTabs/SidebarAssetsTab.js';
import { AssetLinkWithWarning } from '../../AssetSidebar/useOpenAssetSidebar.js';
import { useAddProjectSidebar } from '../../ProjectSidebar/forms/add/useAddProjectSidebar.js';
import { useSidebarLogicContext } from '../../SidebarLogicContext.js';

const SEARCH_ASSETS_SUB = gql`
  subscription SEARCH_ASSETS_SUB($input: CollectionSearchParams!) {
    searchAssetChanged(input: $input) {
      id
      type
    }
  }
`;

const SEARCH_SUPPLIERS_SUB = gql`
  subscription SEARCH_SUPPLIERS_SUB($input: CollectionSearchParams!) {
    searchSupplierChanged(input: $input) {
      id
      type
    }
  }
`;

const SEARCH_RECURRING_PROJECTS_SUB = gql`
  subscription SEARCH_RECURRING_PROJECTS_SUB($input: CollectionSearchParams!) {
    searchRecurringProjectChanged(input: $input) {
      id
      type
    }
  }
`;

export const SEARCH_ASSETS_QUERY = gql`
  query SEARCH_ASSETS_QUERY($input: CollectionSearchParams!) {
    searchAssets(input: $input) {
      hits {
        _id
        type {
          _id
          name
          lifeExpectancy
        }
        displayName
        serial
        status
        qrCodeId
        location
        commissioningDate
        manufacturerDoc {
          _id
          name
        }
        modelDoc {
          _id
          name
          lifeExpectancy
        }
        suppliers {
          _id
          company {
            name
          }
        }
        recurringProjects {
          _id
          projectId
        }
        property {
          _id
          name
          addressTwo
          address {
            ...commonAddressFields
          }
        }
        client {
          _id
          name
        }
      }
      total
    }
  }

  ${commonAddressFields}
`;

const SEARCH_SUB_PROPERTIES_QUERY = gql`
  query SEARCH_SUB_PROPERTIES_QUERY($input: CollectionSearchParams!) {
    searchProperties(input: $input) {
      hits {
        _id
      }
    }
  }
`;

const SEARCH_SUB_PROPERTIES_SUB = gql`
  subscription SEARCH_SUB_PROPERTIES_SUB($input: CollectionSearchParams!) {
    searchPropertyChanged(input: $input) {
      id
      type
    }
  }
`;

const highlightFieldPropTypes = oneOfType([
  object,
  string,
  arrayOf(oneOfType([string, object])),
]);

function AssetLinkC({ displayName, ...props }) {
  return <AssetLinkWithWarning {...props}>{displayName}</AssetLinkWithWarning>;
}

AssetLinkC.propTypes = { displayName: highlightFieldPropTypes };

// getAssetLinkWithProps :: Object -> Object -> ReactComponent
const getAssetLinkWithProps = (parentProps) =>
  function (props) {
    return <AssetLinkC {...props} {...parentProps} />;
  };

const PopoverContentWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: start;
  border-radius: 5px;
  max-width: 500px;
`;

const PopoverTitleTextS = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`;

function SubPropertyLinkTooltip({ property }) {
  const { PropertyLink } = useSidebarLogicContext();

  if (!property) {
    return NOTHING_UI_STRING;
  }

  return (
    <PopoverContentWrapperS>
      <PopoverTitleTextS>Child Property:</PopoverTitleTextS>
      <PropertyLink {...property} />
    </PopoverContentWrapperS>
  );
}

SubPropertyLinkTooltip.propTypes = {
  property: shape({ _id: string.isRequired }),
};

function AssetLink({ onlyChildProperties, ...props }) {
  if (onlyChildProperties) {
    return (
      <Popover
        position="bottom"
        PopoverParent={getAssetLinkWithProps(props)}
        content={<SubPropertyLinkTooltip {...props} />}
      />
    );
  }

  return <AssetLinkC {...props} />;
}

AssetLink.propTypes = { onlyChildProperties: bool };

const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ListItemWrapper = styled(ListWrapper)`
  flex-wrap: nowrap;
`;

const ListItemSeparator = styled.div`
  font-size: 12px;
  margin: 0 5px 0 2px;
`;

// getMultipleLinksColumn :: ([String], ReactElement, Function) -> _ -> [ReactElement]
const getMultipleLinksColumn = (
  listPath,
  Component,
  getLinkProps = R.identity,
) =>
  function (props) {
    const list = R.pathOr([], listPath, props);
    return (
      <ListWrapper>
        {R.isEmpty(list)
          ? NOTHING_UI_STRING
          : list.map((item, index) => (
              <ListItemWrapper key={item._id}>
                <Component {...getLinkProps(item)} />
                {index !== list.length - 1 && (
                  <ListItemSeparator>,</ListItemSeparator>
                )}
              </ListItemWrapper>
            ))}
      </ListWrapper>
    );
  };

// getSupplierLinkProps :: Supplier -> SupplierLinkProps
const getSupplierLinkProps = R.applySpec({
  _id: R.prop('_id'),
  name: R.path(['company', 'name']),
});

// transformNameToString :: String || Array -> String
const transformNameToString = (inputString) => {
  if (Array.isArray(inputString)) {
    if (inputString[2]) {
      return `${inputString[0]}${inputString[1].props.children}${inputString[2]}`;
    }

    return `${inputString[0]}${inputString[1].props.children}`;
  }

  return inputString;
};

function AddProjectIcon({
  _id,
  client,
  property,
  suppliers,
  isCard,
  displayName,
  ...restAssetFields
}) {
  const openAddProjectForm = useAddProjectSidebar(
    isCard,
    entities.PROPERTY,
    property._id,
  );

  const onClick = () =>
    openAddProjectForm({
      assetId: _id,
      clientId: client._id,
      disableOccurrence: true,
      propertyId: property._id,
      projectType: ProjectType.PASS_THROUGH,
      type: ProjectOccurrence.ONE_TIME_OCCURRENCE,
      supplierIds: R.map(R.prop('_id'), suppliers),
      invoiceDescription: prepareInvoiceDescription({
        asset: {
          property,
          displayName: transformNameToString(displayName),
          ...restAssetFields,
        },
      }),
    });

  return (
    <IconButton
      size={18}
      name="add-file"
      onClick={onClick}
      initialColor={R.path(['colors', 'primaryLight'], defaultTheme)}
    />
  );
}

AddProjectIcon.propTypes = {
  isCard: bool,
  _id: string.isRequired,
  client: shape({ _id: string.isRequired }).isRequired,
  property: shape({ _id: string.isRequired }).isRequired,
  suppliers: arrayOf(shape({ _id: string.isRequired })),
  displayName: oneOfType([string, node, arrayOf(oneOfType([string, node]))]),
};

const assetsTableConfig = [
  ['', AssetStatusDot],
  ['Asset ID', AssetLink, keywordSortQuery(['displayName'])],
  ['Manufacturer', pathOrNothingUI(['manufacturerDoc', 'name'])],
  ['Location', pathOrNothingUI(['location'])],
  [
    'Model',
    pathOrNothingUI(['modelDoc', 'name']),
    keywordSortQuery(['modelDoc', 'name']),
  ],
  [
    'Suppliers',
    getMultipleLinksColumn(['suppliers'], SupplierLink, getSupplierLinkProps),
    keywordNestedSortQuery(['suppliers', 'company', 'name']),
  ],
  [
    'Type',
    pathOrNothingUI(['type', 'name']),
    keywordSortQuery(['type', 'name']),
  ],
  [
    'PMs',
    getMultipleLinksColumn(['recurringProjects'], ProjectLink),
    keywordNestedSortQuery(['recurringProjects', 'projectId']),
  ],
  [' ', AddProjectIcon],
];

// highlightAssetRows :: String -> Asset -> HighlightedAsset
const highlightAssetRows = (search) => (asset) =>
  highlightMatchesInObject({
    search,
    object: asset,
    paths: [
      ['type', 'name'],
      ['displayName'],
      ['modelDoc', 'name'],
      ['manufacturerDoc', 'name'],
    ],
  });

// preparePropertyAssets :: { searchTerm: String } -> SearchAssetsResult -> [Asset]
const preparePropertyAssets = ({ searchTerm, ...restProps }) =>
  R.compose(
    R.map(highlightAssetRows(searchTerm)),
    R.map(R.mergeLeft(restProps)),
    R.pathOr([], ['searchAssets', 'hits']),
  );

// prepareSubPropertiesIds :: SearchPropertiesResult -> [ID]
const prepareSubPropertiesIds = R.compose(
  R.map(R.prop('_id')),
  R.pathOr([], ['searchProperties', 'hits']),
);

export const usePropertyAssetsQuery = ({
  status,
  isCard,
  searchTerm,
  propertyId,
  setQueryInput,
  onlyChildProperties,
}) => {
  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: assetsTableConfig,
    column: 2,
  });

  const queryOptions = {
    variables: {
      input: {
        size: MAX_ITEMS,
        query: { term: { masterPropertyId: propertyId } },
      },
    },
    skip: !onlyChildProperties,
  };

  const { data: subPropertiesData } = useReactiveQuery(
    SEARCH_SUB_PROPERTIES_QUERY,
    SEARCH_SUB_PROPERTIES_SUB,
    { queryOptions, subscriptionOptions: queryOptions },
  );

  const subPropertiesIds = useMemo(
    () => prepareSubPropertiesIds(subPropertiesData),
    [subPropertiesData],
  );

  const input = useMemo(
    () => ({
      sort,
      searchTerm,
      query: {
        bool: {
          must: [
            { match: { status } },
            {
              terms: {
                propertyId: onlyChildProperties
                  ? subPropertiesIds
                  : [propertyId],
              },
            },
          ],
        },
      },
    }),
    [
      sort,
      status,
      searchTerm,
      propertyId,
      subPropertiesIds,
      onlyChildProperties,
    ],
  );

  const { data, loading, tableProps, debouncedRefetch } =
    useInfiniteScrollQueryWithSubscription(
      SEARCH_ASSETS_QUERY,
      input,
      { endpointName: 'searchAssets' },
      SEARCH_ASSETS_SUB,
      { input },
    );

  useSubscription(SEARCH_SUPPLIERS_SUB, {
    shouldResubscribe: data,
    onData: debouncedRefetch,
    variables: { input: {} },
  });

  useSubscription(SEARCH_RECURRING_PROJECTS_SUB, {
    shouldResubscribe: data,
    onData: debouncedRefetch,
    variables: { input: {} },
  });

  useEffect(() => {
    if (!!setQueryInput && !!data) {
      setQueryInput(input);
    }
  }, [input, data]);

  const dataTableProps = useMapConfigToTableProps(
    preparePropertyAssets({ isCard, searchTerm, onlyChildProperties }),
    assetsTableConfig,
    data,
  );

  const count = R.pathOr(0, ['searchAssets', 'total'], data);

  return {
    count,
    loading,
    tableProps: {
      ...tableProps,
      ...dataTableProps,
      ...tableSortingProps,
    },
    queryInput: input,
  };
};
