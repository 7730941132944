import * as R from 'ramda';
import moment from 'moment-timezone';
import {
  alwaysNewDate,
  isNilOrEmpty,
  renameProp,
  propEqLegacy,
  pathEqLegacy,
  getEndDateByProjectWithClient,
} from '@poly/utils';
import { formTypeConstants } from '@poly/admin-ui';
import {
  RecurringProjectTypes,
  WorkOrderPriority,
  RWOTaskListType,
  ScheduleRepeats,
  ProjectType,
  ProjectSpendTypes,
} from '@poly/constants';

import { ProjectOccurrence } from '../../../../modules/core/constants/projects.js';
import { projectToEditForm } from '../edit/gqlProjectToFormObject.js';
import {
  generateProjectInvoiceDescription,
  generateProjectDescription,
} from '../form/components/utils.js';
import { isRecurringProject, isPMProject } from '../form/utils/sections.js';
import { EMPTY_PROPERTY_LOCATION_VALUE } from '../form/utils/constants.js';

// convertDateToNewYorkTimeZone :: Date -> Date
export const convertDateToNewYorkTimeZone = (date) =>
  // Note: we support only 'America/New_York' at production
  // see packages/react-scripts/config/webpack.config.prod.js for details
  moment
    .tz(moment(date).format('YYYY-MM-DD'), 'YYYY-MM-DD', 'America/New_York')
    .toDate();

// clientIdFromParams :: Object -> String
const clientIdFromParams = R.either(
  R.pathOr('', ['routerQuery', 'clientId']),
  R.pathOr('', ['property', 'client', '_id']),
);

// projectTypeFromParams :: Object -> WorkOrderType
const projectTypeFromParams = R.cond([
  [
    R.path(['routerQuery', 'projectType']),
    R.path(['routerQuery', 'projectType']),
  ],
  [
    R.path(['client', 'configs', 'defaultProjectType']),
    R.path(['client', 'configs', 'defaultProjectType']),
  ],
  [R.T, () => ProjectType.WORK_ORDER],
]);

const defaultSchedule = {
  repeats: ScheduleRepeats.MONTHLY,
  weekDay: false,
  every: 1,
  days: [],
};

// commonInitialValues :: Object -> Object
const commonInitialValues = (
  routerQuery,
  priority = WorkOrderPriority.NORMAL,
) => {
  const startDate = alwaysNewDate();
  return {
    startDate,
    formType: formTypeConstants.CREATE,
    taskListType: RWOTaskListType.LONG_LIST,
    endDate: getEndDateByProjectWithClient({
      ...routerQuery,
      priority,
      startDate,
    }),
    ...(routerQuery.assetId ? { assetIds: [routerQuery.assetId] } : {}),
  };
};

// prepareDescriptions :: Project -> { description: String, invoiceDescription: String }
const prepareDescriptions = R.ifElse(
  isNilOrEmpty,
  R.always({ description: '', invoiceDescription: '' }),
  R.compose(
    R.applySpec({
      description: generateProjectDescription,
      invoiceDescription: R.ifElse(
        R.compose(R.isNil, R.path(['formData', 'invoiceDescription'])),
        generateProjectInvoiceDescription,
        R.path(['formData', 'invoiceDescription']),
      ),
    }),
    R.applySpec({
      property: R.prop('property'),
      formData: R.identity,
    }),
  ),
);

// clonedPMInitialValues :: Object -> RecurringProject -> Object
const clonedPMInitialValues = ({
  type,
  cloneWithoutAssets,
  cloneWithoutSuppliers,
  childType,
}) =>
  R.compose(
    R.mergeAll,
    R.juxt([
      R.ifElse(
        () => R.equals(cloneWithoutAssets, false),
        R.compose(
          R.objOf('assetIds'),
          R.ifElse(
            R.always(childType),
            R.pathOr([], ['payload', childType, 'assetIds']),
            R.prop('assetIds'),
          ),
        ),
        R.always({ assetIds: null }),
      ),
      R.ifElse(
        R.both(
          () => isPMProject({ type }),
          propEqLegacy(
            'type',
            RecurringProjectTypes.preventiveMaintenanceProject,
          ),
        ),
        R.compose(
          R.over(
            R.lensProp('masterProjectSuppliers'),
            R.when(
              () => R.either(R.isNil, R.equals(true))(cloneWithoutSuppliers),
              R.always([{ disableWOCEmail: true }]),
            ),
          ),
          R.pick(['masterProjectSuppliers']),
          renameProp('suppliers', 'masterProjectSuppliers'),
        ),
        R.always({}),
      ),
    ]),
  );

// shouldFillPreventativeRepairChildProject :: Input -> Boolean
// Input = {
//    routerQuery: { cloneAsPreventativeRepair: Boolean }
//    clonedProject: Project
// }
const shouldFillPreventativeRepairChildProject = R.both(
  R.path(['routerQuery', 'cloneAsPreventativeRepair']),
  R.pathSatisfies(R.is(String), ['clonedProject', 'parent', '_id']),
);

// isRecurringProjectOnCreate :: Object -> Boolean
const isRecurringProjectOnCreate = R.both(
  R.propSatisfies(R.complement(R.isNil), 'type'),
  isRecurringProject,
);

// commonInitialValues :: (Object, Object) -> Object
export const clonedProjectInitialValues = (routerQuery, clonedProject) => ({
  notRM: false,
  schedule: defaultSchedule,
  masterProjectSuppliers: [{}],
  ...projectToEditForm(clonedProject),
  ...commonInitialValues(routerQuery, clonedProject.priority),
  taskListType: clonedProject.taskListType,
  isPropertyDisabled: !clonedProject.property,
  ...prepareDescriptions(clonedProject),
  ...(clonedProject.parent
    ? {
        disablePopulateDescription: true,
        disablePopulateInvoiceDescription: true,
      }
    : {}),
  cloneFromProjectId: clonedProject._id,
  cloneFromProjectNumber: clonedProject.projectId,
  ...clonedPMInitialValues({
    ...routerQuery,
    childType: clonedProject.childType,
  })(clonedProject),
  ...(routerQuery.invoiceDescription
    ? { invoiceDescription: routerQuery.invoiceDescription }
    : {}),
  preventativeRepair: {
    childProjectId: shouldFillPreventativeRepairChildProject({
      routerQuery,
      clonedProject,
    })
      ? clonedProject._id
      : null,
    relatedAssetId: !routerQuery.cloneWithoutAssets
      ? R.path(['searchAssets', 'hits', '0', '_id'], clonedProject)
      : null,
  },
  // "scheduleDate" is set in "projectToEditForm"
  // need to rewrite scheduleDate if cloning as Preventative Repair
  ...(routerQuery.cloneAsPreventativeRepair ? { scheduleDate: null } : {}),
  spendType: R.cond([
    [
      R.always(routerQuery.cloneAsPreventativeRepair),
      R.always(ProjectSpendTypes.PREVENTIVE_REPAIR),
    ],
    [
      R.either(
        propEqLegacy('type', RecurringProjectTypes.recurringProject),
        propEqLegacy(
          'type',
          RecurringProjectTypes.preventiveMaintenanceProject,
        ),
      ),
      R.always(ProjectSpendTypes.RECURRING_SERVICE),
    ],
    [R.T, R.always(ProjectSpendTypes.REACTIVE_REPAIR)],
  ])(clonedProject),
});

// getClientDivision :: Client -> { divisionAccountId: ID} | {}
const getClientDivision = R.ifElse(
  isNilOrEmpty,
  R.always({}),
  R.applySpec({
    divisionAccountId: R.path(['financial', 'division', '_id']),
  }),
);

// getClientCostCenter :: {client: Client, property: Property} -> String
const getClientCostCenter = R.ifElse(
  R.path(['property', 'client', 'enableCostCenter']),
  R.applySpec({ costCenter: R.path(['property', 'costCenter']) }),
  R.ifElse(
    R.path(['client', 'enableCostCenter']),
    R.applySpec({ costCenter: R.path(['client', 'financial', 'costCenter']) }),
    R.always({}),
  ),
);

// getAdminPurchaseOrder :: { purchaseOrderId: ID } -> { adminPurchaseOrderId: ID } || {}
const getAdminPurchaseOrder = R.compose(
  R.ifElse(isNilOrEmpty, R.always({}), R.objOf('adminPurchaseOrderId')),
  R.prop('purchaseOrderId'),
);

// createProjectInitialValues :: (Object, Property, Client) -> FormData
export const createProjectInitialValues = (
  routerQuery,
  client,
  property = {},
) => ({
  occurrence: R.cond([
    [
      isRecurringProjectOnCreate,
      R.always(ProjectOccurrence.RECURRING_OCCURRENCE),
    ],
    [
      isPMProject,
      R.always(ProjectOccurrence.PREVENTIVE_MAINTENANCE_OCCURRENCE),
    ],
    [R.T, R.always(ProjectOccurrence.ONE_TIME_OCCURRENCE)],
  ])(routerQuery),
  spendType: R.ifElse(
    R.either(isPMProject, isRecurringProjectOnCreate),
    R.always(ProjectSpendTypes.RECURRING_SERVICE),
    R.always(ProjectSpendTypes.REACTIVE_REPAIR),
  )(routerQuery),
  type: projectTypeFromParams({ routerQuery, client }),
  clientId: clientIdFromParams({ routerQuery, property }),
  client: client || property.client,
  property: R.isEmpty(property)
    ? { _id: R.pathOr('', ['propertyId'], routerQuery) }
    : property,
  exemptSalesTax: false,
  check1: false,
  check2: false,
  notRM: false,
  preventativeRepair: {},
  priority: WorkOrderPriority.NORMAL,
  meta: {
    sendSiteContactServiceConfirmationEmail: true,
    sendRequesterServiceConfirmationEmail: true,
  },
  schedule: defaultSchedule,
  isPropertyDisabled: routerQuery.isPropertyDisabled,
  masterProjectSuppliers: [{ disableWOCEmail: true, attachments: [] }],
  notificationConfig: {
    toSiteContact: false,
    toRequester: false,
    toClientManager: false,
  },
  supplierHourlyRate: 0,
  clientHourlyRate: 0,
  weeklyPorterHours: 0,
  propertyLocationId: EMPTY_PROPERTY_LOCATION_VALUE,
  divisionAccountId: routerQuery.divisionAccountId,
  ...prepareDescriptions({ property }),
  ...getClientDivision(client || property.client),
  ...commonInitialValues(routerQuery, WorkOrderPriority.NORMAL),
  ...getClientCostCenter({ client, property }),
  ...getAdminPurchaseOrder(routerQuery),
  invoiceDescription: R.propOr('', 'invoiceDescription', routerQuery),
  propertyLimitIds: R.propOr([], 'propertyLimitIds', routerQuery),
});

// isProjectSubCategoryEnabled :: { client: Client } -> Boolean
export const isProjectSubCategoryEnabled = pathEqLegacy(
  ['client', 'configs', 'enableProjectSubCategory'],
  true,
);
