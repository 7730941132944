import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';

import { layoutType, sectionTypes } from '../../formTypes.js';
import { mapFieldsByRows } from '../FieldLayout.js';
import { getThemeColor } from '../../../utils.js';

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: ${R.prop('width')};
  margin: ${R.prop('margin')};
  padding: ${R.prop('padding')};
  height: ${R.propOr('fit-content', 'height')};
  flex-shrink: 0;
  position: ${R.prop('position')};
  border-bottom: ${R.prop('borderBottom')};
  border-bottom-color: ${({ borderBottomColor }) =>
    getThemeColor([borderBottomColor])};
`;

const Label = styled.div`
  font-size: ${R.prop('size')};
  letter-spacing: 1px;
  line-height: 24px;
  color: ${({ color }) => getThemeColor([color])};
  margin-bottom: ${R.propOr('30px', 'marginBottom')};
`;

export function SectionComp({
  label,
  fields,
  sectionLayout,
  labelLayout,
  mutators,
  changeFieldValue,
  globalFieldLayout,
}) {
  const sectionProps = R.omit(['label'], sectionLayout);

  return (
    <Section {...sectionProps}>
      {label && <Label {...labelLayout}>{label}</Label>}
      {mapFieldsByRows(mutators, changeFieldValue, globalFieldLayout)(fields)}
    </Section>
  );
}

SectionComp.propTypes = {
  ...sectionTypes,
  sectionLayout: layoutType.isRequired,
  labelLayout: layoutType.isRequired,
};
