import * as R from 'ramda';
import { calculateTotal, formatAmount, formatTotal } from '@poly/utils';
import { extractTablePropsFromConfig } from '@poly/admin-ui';
import React from 'react';
import { number, string } from 'prop-types';
import styled from 'styled-components';
import { removeBorderPrintStyles, Table } from '@poly/admin-book';
import { useRouterQuery } from '@poly/client-routing';

import {
  ALL_MONTHS,
  calculateMonthsTotals,
  formats,
  isTotalRow,
} from './clientSalesReportHelpers.js';
import { salesReportRowsTypeProps } from './prop-types.js';

const TableS = styled(Table)`
  ${removeBorderPrintStyles}
  th:nth-child(1),
  td:nth-child(1) {
    width: 140px;
  }

  th:last-child,
  td:last-child {
    width: 135px;
    text-align: right;
    padding-right: 20px;
  }

  tr {
    margin: 0;
    width: 100%;
  }

  td:not(:first-child),
  th:not(:first-child) {
    font-family: 'Monda', sans-serif;
    font-size: 10px;
    text-align: right;
  }

  tr:last-child,
  tr:nth-last-child(${R.prop('boldRows')}) {
    td:first-child {
      font-weight: bold;
    }
  }
  @media print {
    td {
      font-size: 10px !important;
    }
  }
`;

const FooterContainerS = styled.tr`
  padding-bottom: 15px;
  font-size: 14px;
`;

function TableFooter({ rows }) {
  const totals = calculateMonthsTotals(rows);

  const total = calculateTotal(R.identity)(totals);
  return (
    <FooterContainerS>
      <td>Total</td>
      <td />
      {totals.map((amount, index) => (
        <td key={ALL_MONTHS[index]}>{formatAmount(amount)}</td>
      ))}
      <td>{formatTotal(total)}</td>
    </FooterContainerS>
  );
}

TableFooter.propTypes = {
  rows: salesReportRowsTypeProps,
};

function SalesWipMarkCol(row, format = formats.total) {
  return isTotalRow(row) ? (
    ''
  ) : (
    <>
      {format !== formats.wip && 'Sales'}
      {format === formats.total && <br />}
      {format !== formats.sales && 'WIP'}
    </>
  );
}

function SalesWipColumnAmount({
  salesAmount,
  wipAmount,
  amount,
  format = formats.total,
  ...row
}) {
  if (isTotalRow(row)) {
    return formatAmount(amount);
  }
  return (
    <>
      {format !== formats.wip && formatAmount(salesAmount)}
      {format === formats.total && <br />}
      {format !== formats.sales && formatAmount(wipAmount)}
    </>
  );
}

SalesWipColumnAmount.propTypes = {
  salesAmount: number,
  wipAmount: number,
  amount: number,
  format: string,
};

// getSalesReportTableConfig :: String -> TableConfig
const getSalesReportTableConfig = (format) => [
  ['Client Name', R.prop('clientName')],
  ['', (row) => SalesWipMarkCol(row, format)],
  ...R.map(
    (index) => [
      ALL_MONTHS[index],
      R.compose(
        SalesWipColumnAmount,
        R.applySpec({
          salesAmount: R.pathOr(0, [index, 'salesAmount']),
          wipAmount: R.pathOr(0, [index, 'wipAmount']),
          amount: R.pathOr(0, [index, 'amount']),
          format: R.always(format),
          _id: R.prop('_id'),
        }),
      ),
    ],
    R.range(0, 12),
  ),
  [
    'Total',
    R.compose(
      SalesWipColumnAmount,
      R.converge(R.mergeRight, [
        R.compose(
          R.applySpec({
            salesAmount: calculateTotal(R.prop('salesAmount')),
            wipAmount: calculateTotal(R.prop('wipAmount')),
            amount: calculateTotal(R.prop('amount')),
            format: R.always(format),
          }),
          R.values,
          R.omit(['clientName', '_id']),
        ),
        R.pick(['_id']),
      ]),
    ),
  ],
];

export function SalesReportsTable({ salesReportRows }) {
  const { format } = useRouterQuery(['format']);

  const { headers, columns } = extractTablePropsFromConfig(
    getSalesReportTableConfig(format),
  );

  const isTotalFormat = !format || format === formats.total;

  return (
    <TableS
      headers={headers}
      columns={columns}
      rows={salesReportRows}
      TableFooter={isTotalFormat ? TableFooter : null}
      boldRows={isTotalFormat ? 2 : 1}
      overflow="auto"
      showScrollBar
    />
  );
}

SalesReportsTable.propTypes = {
  salesReportRows: salesReportRowsTypeProps,
};
