import React from 'react';
import { BodyRow } from '@poly/admin-book';
import { InvoicesStatuses } from '@poly/constants';
import { func, shape, string } from 'prop-types';

import { useOpenApproveSupplierInvoiceSidebar } from './ApproveSupplierInvoiceSidebar/ApproveSupplierInvoiceSidebar.js';

export function ClickableRow({ row, refetch, ...props }) {
  const openApproveSupplierInvoice = useOpenApproveSupplierInvoiceSidebar();

  const onClick = () => {
    if (row.status === InvoicesStatuses.RECEIVED) {
      openApproveSupplierInvoice({ ...row, refetch });
    }
  };
  return <BodyRow {...props} row={row} onClick={onClick} isClickable />;
}

ClickableRow.propTypes = {
  refetch: func.isRequired,
  row: shape({ _id: string.isRequired }).isRequired,
};
