import React from 'react';
import { includes } from 'ramda';
import {
  func,
  string,
  shape,
  number,
  arrayOf,
  bool,
  oneOfType,
  object,
} from 'prop-types';
import { mapIndexed } from '@poly/utils/src/ramda.js';

import { Row, CheckboxS, Cell } from './styles.js';
import { isFuncComponent } from './utils.js';
import { tableHeadersPropTypes } from './propTypes.js';
import { Icon } from '../Icon/index.js';

// prepareColumns :: [ReactNode] -> [{ColumnComp: ReactNode, columnNum: Number}]
const prepareColumns = mapIndexed((ColumnComp, columnNum) => ({
  ColumnComp,
  columnNum,
}));

function ToggleCollapsibleTableCell({
  childRow,
  isCollapsibleTableRow,
  isCollapsed,
  toggleCollapse,
}) {
  return (
    <Cell hide={!childRow && !isCollapsibleTableRow}>
      {isCollapsibleTableRow ? (
        <Icon
          name={isCollapsed ? 'arrow-up' : 'arrow-down'}
          color="#436CBE"
          size="14"
          onClick={toggleCollapse}
        />
      ) : null}
    </Cell>
  );
}

ToggleCollapsibleTableCell.propTypes = {
  childRow: bool,
  isCollapsibleTableRow: bool,
  isCollapsed: bool,
  toggleCollapse: func,
};

export const BodyRow = React.forwardRef(
  (
    {
      row,
      columns,
      index,
      selectedRows,
      selectedRowsInEnd,
      toggleRow,
      headers,
      isRowSelectable,
      isRowDisabled,
      omitColumn,
      className,
      style,
      onClick,
      isClickable,
      NotSelectableCheckboxComponent,
      enableHoverEffect,
      onMouseEnter,
      onMouseLeave,
      selectRowCheckboxOnClick,
      isCollapsibleTableRow,
      toggleCollapse,
      isCollapsibleTable,
      isCollapsed,
      childRow,
    },
    ref,
  ) => {
    const isCurrentRowDisabled = isRowDisabled(row);
    const refAnchor = row.setRef ? (el) => row.setRef(el, row._id) : ref;

    const preparedColumns = prepareColumns(columns);

    return (
      <Row
        id={row._id}
        isClickable={isClickable}
        onClick={onClick}
        style={style}
        key={`${row._id}${index}`}
        index={index}
        data-testid="table-body-row"
        disabled={isCurrentRowDisabled}
        enableHoverEffect={enableHoverEffect}
        className={className}
        ref={refAnchor}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {(selectedRows &&
          !selectedRowsInEnd &&
          !isRowSelectable(row) &&
          NotSelectableCheckboxComponent && (
            <Cell>
              <NotSelectableCheckboxComponent />
            </Cell>
          )) ||
          (selectedRows && !selectedRowsInEnd && (
            <Cell onClick={(e) => e.stopPropagation()}>
              <CheckboxS
                isVisible={isRowSelectable(row)}
                value={includes(row._id, selectedRows)}
                name={`${row._id}${index}`}
                disabled={isCurrentRowDisabled}
                onChange={() => !isCurrentRowDisabled && toggleRow(row._id)}
                onClick={selectRowCheckboxOnClick}
              />
            </Cell>
          )) ||
          null}
        {isCollapsibleTable && (
          <ToggleCollapsibleTableCell
            isCollapsibleTableRow={isCollapsibleTableRow}
            isCollapsed={isCollapsed}
            childRow={childRow}
            toggleCollapse={toggleCollapse}
          />
        )}
        {preparedColumns.map(({ ColumnComp, columnNum }) => (
          <Cell
            disabled={isCurrentRowDisabled && omitColumn !== columnNum}
            // eslint-disable-next-line react/no-array-index-key
            key={`${row._id}_${index}_${headers[columnNum].title}_${columnNum}`}
          >
            {isFuncComponent(ColumnComp) ? (
              <ColumnComp {...row} />
            ) : (
              ColumnComp(row)
            )}
          </Cell>
        ))}
        {(selectedRows &&
          selectedRowsInEnd &&
          !isRowSelectable(row) &&
          NotSelectableCheckboxComponent && (
            <Cell>
              <NotSelectableCheckboxComponent {...row} />
            </Cell>
          )) ||
          (selectedRows && selectedRowsInEnd && (
            <Cell>
              <CheckboxS
                isVisible={isRowSelectable(row)}
                value={includes(row._id, selectedRows)}
                name={`${row._id}${index}`}
                onChange={() => !isCurrentRowDisabled && toggleRow(row._id)}
              />
            </Cell>
          )) ||
          null}
      </Row>
    );
  },
);

BodyRow.propTypes = {
  row: shape({ _id: oneOfType([string, number]).isRequired }).isRequired,
  columns: arrayOf(oneOfType([func, object]).isRequired).isRequired,
  index: number.isRequired,
  // eslint-disable-next-line react/require-default-props
  selectedRows: arrayOf(oneOfType([string, number])),
  toggleRow: func.isRequired,
  headers: tableHeadersPropTypes.isRequired,
  selectedRowsInEnd: bool,
  isRowSelectable: func.isRequired,
  isRowDisabled: func.isRequired,
  omitColumn: number,
  className: string,
  enableHoverEffect: bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: object,
  onClick: func,
  isClickable: bool,
  NotSelectableCheckboxComponent: func,
  onMouseEnter: func,
  onMouseLeave: func,
  selectRowCheckboxOnClick: func,
  isCollapsibleTableRow: bool,
  toggleCollapse: func,
  isCollapsibleTable: bool,
  isCollapsed: bool,
  childRow: bool,
};

BodyRow.defaultProps = {
  omitColumn: undefined,
  selectedRowsInEnd: false,
  isClickable: false,
  enableHoverEffect: true,
  onClick: () => {},
};
