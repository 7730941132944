import React from 'react';
import styled from 'styled-components';
import { func, string, bool } from 'prop-types';
import {
  InputSection,
  PasswordInputSection,
  internationalPhoneUtils,
} from '@poly/client-utils';

import {
  ErrorText,
  Footer,
  LoginButton,
  LoginPageLayout,
} from '../Components.js';
import { ButtonLoader } from '../Loader.js';

export const ForgotPasswordLink = styled.span`
  font-family: 'TT Norms', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  text-decoration-line: underline;
  cursor: pointer;
  color: #bcbcbc;
  margin-bottom: 15px;
`;

const PhoneS = styled.div`
  color: #999;
  font-family: TTNormsMedium;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 12px;
`;

function LoginForm({
  loading,
  handleSubmit,
  signInError,
  forgotPasswordMessage,
  title,
  goToForgotPassword,
  hideLogo,
  className,
  // "phone" used at asset-scanner for staff users login
  phone,
}) {
  const onSubmit = (event) => {
    event.preventDefault();
    const creds = {
      password: event.target.elements.password.value,
      ...(phone
        ? { phone: internationalPhoneUtils.formattingInternationalPhone(phone) }
        : { email: event.target.elements.email.value }),
    };
    handleSubmit(creds);
  };

  return (
    <LoginPageLayout
      className={className}
      onSubmit={onSubmit}
      title={title}
      hideLogo={hideLogo}
    >
      {forgotPasswordMessage && <ErrorText>{forgotPasswordMessage}</ErrorText>}
      {phone ? (
        <PhoneS>{phone}</PhoneS>
      ) : (
        <InputSection
          title="EMAIL"
          inputProps={{
            type: 'email',
            name: 'email',
            placeholder: 'Email Address',
            autoFocus: true,
            required: true,
            lower: true,
          }}
        />
      )}
      <PasswordInputSection
        title="PASSWORD"
        inputProps={{
          type: 'password',
          name: 'password',
          placeholder: 'Password',
          required: true,
        }}
      />
      {signInError && <ErrorText>{signInError}</ErrorText>}
      {goToForgotPassword && (
        <ForgotPasswordLink onClick={goToForgotPassword}>
          forgotten your password?
        </ForgotPasswordLink>
      )}
      <Footer>
        <LoginButton type="submit" disabled={loading}>
          {loading && <ButtonLoader />}
          Log In
        </LoginButton>
      </Footer>
    </LoginPageLayout>
  );
}

LoginForm.propTypes = {
  handleSubmit: func.isRequired,
  loading: bool.isRequired,
  signInError: string,
  forgotPasswordMessage: string,
  title: string,
  goToForgotPassword: func,
  hideLogo: bool,
  className: string,
  phone: string,
};

LoginForm.defaultProps = {
  signInError: null,
  forgotPasswordMessage: null,
};

export default LoginForm;
