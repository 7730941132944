import * as R from 'ramda';

// getFullZipCodeFromAddress :: Address -> String
export const getFullZipCodeFromAddress = R.compose(
  R.join('-'),
  R.reject(R.isNil),
  R.juxt([
    R.path(['address_parts', 'postal_code']),
    R.path(['address_parts', 'postal_code_suffix']),
  ]),
);
