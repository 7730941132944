import React from 'react';
import styled from 'styled-components';
import { getThemeColor, Icon } from '@poly/admin-book';
import { arrayOf, func } from 'prop-types';
import { openPrintWindowWithData } from '@poly/client-utils';
import { CommonPrintLayout } from '@poly/admin-ui';

import { journalPropTypes } from './transactionDetailsPropTypes.js';

const PrintButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-color: ${getThemeColor(['mid'])};
  border-radius: 5px;
`;

function TransactionDetailsPrintButton({ onClick }) {
  return (
    <PrintButtonContainer onClick={onClick}>
      <Icon name="print" />
    </PrintButtonContainer>
  );
}

TransactionDetailsPrintButton.propTypes = {
  onClick: func.isRequired,
};

export function PrintTransactionDetailsPDF({ Component, ...componentProps }) {
  const onClick = async () => {
    await openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: Component,
      fileName: 'transaction_details',
      metaData: {
        title: 'Transaction Details',
      },
      headerHeight: '240px',
      isPrintPDF: true,
      ...componentProps,
    });
  };

  return <TransactionDetailsPrintButton onClick={onClick} />;
}

PrintTransactionDetailsPDF.propTypes = {
  journals: arrayOf(journalPropTypes),
  Component: func.isRequired,
};
