import React from 'react';
import styled from 'styled-components';

import { MasterProjectsDirectoryToolbar } from './MasterProjectsDirectoryToolbar.js';
import { useMasterProjectsQueryByFilter } from './useMasterProjectsQueryByFilter.js';

const SearchMasterProjectsHeaderWrapperS = styled.div`
  height: 45px;
  display: flex;
  margin: 0 auto;
  max-width: 1424px;
  flex-direction: row;
  align-items: flex-end;
  width: calc(100% - 50px);
  justify-content: space-between;
`;

const SearchMasterProjectsHeaderTextS = styled.div`
  display: flex;
  font-size: 16px;
`;

export function SearchMasterProjectsHeader() {
  const { count } = useMasterProjectsQueryByFilter();

  const title = `Results (${count})`;

  return (
    <SearchMasterProjectsHeaderWrapperS>
      <SearchMasterProjectsHeaderTextS>{title}</SearchMasterProjectsHeaderTextS>
      <MasterProjectsDirectoryToolbar />
    </SearchMasterProjectsHeaderWrapperS>
  );
}
