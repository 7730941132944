/* eslint-disable react/forbid-prop-types */
import {
  array,
  arrayOf,
  bool,
  element,
  func,
  number,
  object,
  oneOfType,
  shape,
  string,
  node,
} from 'prop-types';

export const commonFields = {
  label: oneOfType([string, element]),
  order: number,
  layout: object,
  renderIf: func,
};

export const layoutType = object;

export const fieldTypes = {
  field: shape({
    name: string.isRequired,
    Component: oneOfType([func, node, object]),
    subFields: arrayOf(
      shape({
        field: shape({
          name: string.isRequired,
          Component: oneOfType([func, node, object]).isRequired,
          withFormData: bool,
        }),
      }),
    ),
    isEqual: func,
    isArrayField: bool,
    withFormData: bool,
    withChangeFieldValue: bool,
  }).isRequired,
  required: bool,
  disabled: bool,
  validators: oneOfType([arrayOf(array), arrayOf(func)]),
  isVisible: bool,
  ...commonFields,
};

export const sectionTypes = {
  id: string,
  fields: arrayOf(shape(fieldTypes)).isRequired,
  ...commonFields,
};

export const formTypes = {
  id: string,
  sections: arrayOf(shape(sectionTypes)).isRequired,
  validate: func,
  validateOnBlur: bool,
  formValuesByDB: object,
  initialValues: object.isRequired,
  onSubmit: func.isRequired,
  handleSubmit: func,
  layout: object,
  formSubscription: shape({
    subscriber: func.isRequired,
  }),
  subscribe: func,
  mutators: object,
  resetFromOnSubmit: bool,
};
