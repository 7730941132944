import React from 'react';
import { MainHeader, PageHeaderContainer } from '@poly/admin-book';
import { BackBtn, SectionWithBackBtn } from '@poly/admin-ui';

import { routesNames } from '../../routes/index.js';
import { MentionGroupsTableToolbar } from '../tables/mentionGroupsTable/MentionGroupsTableToolBar.js';

export function MentionGroupsPageHeader() {
  return (
    <PageHeaderContainer>
      <SectionWithBackBtn>
        <BackBtn fallbackLink={routesNames.ADMIN_SETTINGS} />
        <MainHeader>Mention Groups</MainHeader>
      </SectionWithBackBtn>
      <MentionGroupsTableToolbar />
    </PageHeaderContainer>
  );
}
