import styled, { css } from 'styled-components';

import { getThemeColor } from '../utils.js';

export const ToggleWithLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const ToggleLabel = styled.div`
  display: flex;
  flex-shrink: 0;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: ${getThemeColor(['primaryLighter2'])};
  padding-left: 10px;
`;

const disabledStyles = css`
  background-color: rebeccapurple;
  cursor: not-allowed;

  &:before {
    background-color: rebeccapurple;
    cursor: not-allowed;
  }
`;

export const ToggleSwitchWrapper = styled.div`
  position: relative;
  width: 40px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

export const ToggleSwitchLabel = styled.label`
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: ${getThemeColor(['primaryLighter8'])};
  ${({ checked }) => (checked ? 'background-color: #3cbf2f' : '')};
  transition: all 0.3s ease-in 0s;
`;

export const ToggleSwitchInner = styled.span`
  display: block;
  width: 200%;
  height: 100%;
  margin-left: -100%;
  transition: all 0.3s ease-in 0s;

  &:before,
  &:after {
    display: block;
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 20px;
    font-size: 8px;
    font-weight: bold;
    box-sizing: border-box;
  }

  &:before {
    content: ${({ textLabels }) => `"${textLabels[0]}"`};
    padding-left: 7px;
    color: ${getThemeColor(['white'])};
  }

  &:after {
    content: ${({ textLabels }) => `"${textLabels[1]}"`};
    padding-right: 5px;
    color: ${getThemeColor(['accent'])};
    text-align: right;
  }

  ${({ checked }) => (checked ? 'margin-left: 0' : '')};
  ${({ disabled }) => disabled && disabledStyles};
`;

export const ToggleSwitchBlock = styled.span`
  display: block;
  width: 16px;
  margin: 2px;
  background: ${getThemeColor(['white'])};
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;

  ${({ checked }) => (checked ? 'right: 0' : '')};
  ${({ disabled }) => disabled && disabledStyles};
`;
