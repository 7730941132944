import { gql, useMutation } from '@apollo/client';

const UPDATE_TASK_MUTATION = gql`
  mutation UPDATE_TASK_MUTATION($id: ID!, $input: TaskUpdateInput!) {
    updateTask(id: $id, update: $input) {
      task {
        complete
      }
    }
  }
`;

export const useUpdateTask = () => {
  const [mutate] = useMutation(UPDATE_TASK_MUTATION, {
    alias: 'useUpdateTask',
  });

  return {
    updateTask: mutate,
  };
};
