import React from 'react';
import { Textarea } from '@poly/admin-book';
import styled from 'styled-components';

import { isEditInvoiceFormDisabled } from '../helpers.js';

const TextareaS = styled(Textarea)`
  textarea {
    ${({ disabled }) => (disabled ? 'background-color: #f5f6f7;' : '')}
  }
`;

export function RejectionReasonTextarea(props) {
  const textareaProps = {
    ...props,
    disabled: isEditInvoiceFormDisabled(props),
  };

  return <TextareaS {...textareaProps} />;
}
