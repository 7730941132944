import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import styled from 'styled-components';
import { Select, Checkbox, FormField } from '@poly/admin-book';
import { UserStatuses, mentionGroupStatuses } from '@poly/constants';
import { forceTitleCase } from '@poly/utils';
import {
  getAdminUsersBySystemStatusQuery,
  MultiSelectUserDropDown,
  ClientSelect,
} from '@poly/admin-ui';

import {
  HelpTooltip,
  ShortExplanationKeys,
} from '../../../components/HelpTooltip.js';
import { BasicInput } from '../fields/FormFields.js';

const mapCheckboxLabelByName = {
  notifyOnUnassignedProject: {
    labelText: 'Notify on unassigned project',
    tooltipKey: ShortExplanationKeys.notifyOnUnassignedProject,
  },
  notifyOnInternalProjectUpdateAdded: {
    labelText: 'Notify on internal project updates',
    tooltipKey: ShortExplanationKeys.notifyOnInternalProjectUpdateAdded,
  },
  notifyOnClientCommentAdded: {
    labelText: 'Notify on client comments',
    tooltipKey: ShortExplanationKeys.notifyOnClientCommentAdded,
  },
  notifyOnExternalRequest: {
    labelText: 'Notify on External Requests',
    tooltipKey: ShortExplanationKeys.notifyOnExternalRequest,
  },
};

// statusesToOptions :: Object -> Pair String String
const statusesToOptions = R.compose(
  R.map(
    R.applySpec({
      value: R.nth(0),
      label: R.nth(1),
    }),
  ),
  R.map(R.compose(R.over(R.lensIndex(1), forceTitleCase), R.flip(R.repeat)(2))),
  R.values,
);

function MentionGroupStatusSelect(props) {
  const selectProps = {
    width: '150px',
    name: 'MentionGroupStatusSelect',
    options: statusesToOptions(mentionGroupStatuses),
    ...props,
  };

  return <Select {...selectProps} />;
}

MentionGroupStatusSelect.displayName = 'MentionGroupStatusSelect';

function MentionGroupStatusField(props) {
  return (
    <FormField
      {...props}
      additionalProps={{ required: true }}
      Component={MentionGroupStatusSelect}
    />
  );
}

const layout = {
  label: {
    width: '235px',
    position: 'left',
    padding: '10px 30px 0 0',
  },
};

function ClientSelectComp(props) {
  return <ClientSelect includeAllOption={false} {...props} />;
}

// getOptions :: SearchUsersQueryResult -> [Option]
const getOptions = R.compose(
  R.map(({ _id, fullName: label }) => ({ value: _id, label })),
  R.pathOr([], ['searchUsers', 'hits']),
);

function MultiSelectUserDropDownComp(props) {
  return (
    <MultiSelectUserDropDown
      {...props}
      placeholder="Start typing users"
      query={getAdminUsersBySystemStatusQuery(UserStatuses.ACTIVE)}
      optionsFromQueryResult={getOptions}
      componentProps={{ withPortal: true }}
    />
  );
}

const LabelWrapperS = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

function CheckboxFieldLabel({ tooltipKey, labelText }) {
  return (
    <LabelWrapperS>
      {labelText}
      <HelpTooltip shortExplanation={tooltipKey} />
    </LabelWrapperS>
  );
}

CheckboxFieldLabel.propTypes = {
  labelText: string.isRequired,
  tooltipKey: string.isRequired,
};

const CheckboxS = styled(Checkbox)`
  margin-top: 12px;
`;

// getCheckboxFieldWithTooltip :: String -> FormField
const getCheckboxFieldWithTooltip = (name) => {
  const labelProps = mapCheckboxLabelByName[name];
  return {
    layout,
    renderIf: R.prop('clientId'),
    field: { name, Component: CheckboxS },
    label: <CheckboxFieldLabel {...labelProps} />,
  };
};

const DescriptionInput = styled(BasicInput)`
  width: 300px;
`;

const NameInput = styled(BasicInput).attrs(() => ({
  required: true,
}))`
  width: 150px;
`;

export const mentionGroupFormSections = [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        label: 'Name',
        layout,
        field: {
          name: 'name',
          Component: NameInput,
        },
        validators: [[R.identity, 'Name is required']],
      },
      {
        label: 'Description',
        layout,
        field: {
          name: 'description',
          Component: DescriptionInput,
        },
      },
      {
        label: 'Client',
        layout,
        field: {
          name: 'clientId',
          Component: ClientSelectComp,
        },
      },
      getCheckboxFieldWithTooltip('notifyOnUnassignedProject'),
      getCheckboxFieldWithTooltip('notifyOnInternalProjectUpdateAdded'),
      getCheckboxFieldWithTooltip('notifyOnClientCommentAdded'),
      getCheckboxFieldWithTooltip('notifyOnExternalRequest'),
      {
        label: 'Status',
        layout,
        field: {
          name: 'status',
          Component: MentionGroupStatusField,
        },
        required: true,
      },
      {
        label: 'Users',
        layout,
        field: {
          name: 'usersIds',
          Component: MultiSelectUserDropDownComp,
        },
      },
    ],
  },
];
