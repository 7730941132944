import * as R from 'ramda';
import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { gql, useMutation, useQuery } from '@apollo/client';
import { FormCreator, Text, ModalActions, Loader } from '@poly/admin-book';
import { commonFileValidators, validateFilesFunc } from '@poly/client-utils';
import {
  alwaysNewDate,
  ofArrayLegacy,
  propEqLegacy,
  validationFuncWithAllValues,
} from '@poly/utils';
import {
  CancelBtn,
  SubmitBtn,
  useModalContext,
  MoneyInputAsCents,
  useNotificationState,
  useOnSubmitSetStopSubmitting,
} from '@poly/admin-ui';
import { InvoiceDateField } from '../../../modules/forms/supplierInvoiceForm/components/InvoiceDateField.js';
import { InvoiceNumberInput } from '../../../modules/forms/supplierInvoiceForm/components/InvoiceNumberInput.js';
import { AttachDocumentField } from '../../../components/AttachDocumentField.js';
import { projectTaskCompleteCache } from '../../../modules/forms/utils.js';
import { transformInvoiceFileToPicker } from '../../../modules/core/utils/invoices.js';
import {
  thirdWidth,
  commonModalLayout,
} from '../../../modules/forms/common.js';
import { useFormButtonProps } from '../../../modules/core/hooks/users/form.js';

const clientInvoiceTaskFormId = 'clientInvoiceTaskFormId';

function CancelButton(props) {
  const formProps = useFormButtonProps(clientInvoiceTaskFormId);
  return <CancelBtn {...props} {...formProps} />;
}

function SubmitButton(props) {
  const formProps = useFormButtonProps(clientInvoiceTaskFormId, false);
  return <SubmitBtn {...props} {...formProps} />;
}

function ClientInvoiceFormTitle(props) {
  return (
    <Text {...props} size="18px" lineHeight="24px">
      Add Client Invoice
    </Text>
  );
}

function AmountMoneyInput(props) {
  return <MoneyInputAsCents {...props} placeholder="$1,000,000.00" />;
}

function SubmitButtons({ onCancel }) {
  const { closeModal } = useModalContext();

  const onCancelClick = () => {
    closeModal(clientInvoiceTaskFormId);

    if (onCancel) {
      onCancel();
    }
  };

  return (
    <ModalActions style={{ justifyContent: 'flex-end' }}>
      <CancelButton onClick={onCancelClick} />
      <SubmitButton formId={clientInvoiceTaskFormId}>Add Invoice</SubmitButton>
    </ModalActions>
  );
}

SubmitButtons.propTypes = {
  onCancel: func,
};

const getClientInvoiceTaskFormSections = (onCancel) => [
  {
    id: 'main',
    layout: { column: 1, margin: 0 },
    order: 1,
    fields: [
      {
        order: 1,
        layout: { row: 1 },
        field: {
          name: 'formTitle',
          Component: ClientInvoiceFormTitle,
        },
      },
      {
        order: 2,
        layout: { row: 2, width: thirdWidth },
        field: {
          withFormData: true,
          name: 'invoiceDate',
          Component: InvoiceDateField,
        },
      },
      {
        order: 3,
        required: true,
        leaveValues: true,
        label: 'Invoice Number',
        layout: { row: 2, width: thirdWidth },
        field: {
          name: 'number',
          withFormData: true,
          Component: InvoiceNumberInput,
        },
        validators: [[R.identity, 'Invoice Number is required']],
      },
      {
        order: 4,
        leaveValues: true,
        label: 'Amount',
        layout: { row: 2, width: thirdWidth },
        field: {
          name: 'amount',
          Component: AmountMoneyInput,
        },
        required: true,
        validators: [
          [R.is(Number), 'Amount is required'],
          [R.gt(R.__, 0), 'Amount must be greater than 0'],
        ],
        validateFunction: validationFuncWithAllValues,
      },
      {
        order: 5,
        layout: { row: 3, padding: '9px 0' },
        field: {
          withFormData: true,
          name: 'invoiceFile',
          Component: AttachDocumentField,
        },
        validators: commonFileValidators,
        validateFunction: validateFilesFunc,
      },
      {
        order: 6,
        layout: { row: 4, width: 'calc(40% - 10px)' },
        field: { name: 'space', Component: () => null },
      },
      {
        order: 7,
        layout: { row: 4, width: 'calc(60% - 10px)' },
        field: {
          name: 'buttons',
          withFormData: true,
          withChangeFieldValue: true,
          additionalProps: { onCancel },
          Component: SubmitButtons,
        },
      },
    ],
  },
];

const REPORT_ONLY_CLIENT_INVOICE_QUERY = gql`
  query REPORT_ONLY_CLIENT_INVOICE_QUERY($projectId: ID!) {
    reportOnlyClientInvoice(projectId: $projectId) {
      _id
      invoiceDate
      number
      amount
      invoiceFile {
        url
        fileName
        fileSize
        fileType
      }
    }
  }
`;

const CREATE_REPORT_ONLY_CLIENT_INVOICE_MUTATION = gql`
  mutation createReportOnlyClientInvoice(
    $input: CreateReportOnlyClientInvoiceInput!
  ) {
    createReportOnlyClientInvoice(input: $input) {
      _id
    }
  }
`;

const EDIT_REPORT_ONLY_CLIENT_INVOICE_MUTATION = gql`
  mutation editReportOnlyClientInvoice(
    $id: ID!
    $input: EditReportOnlyClientInvoiceInput!
  ) {
    editReportOnlyClientInvoice(id: $id, input: $input) {
      _id
    }
  }
`;

// prepareMutationInput :: FormData -> CreateReportOnlyClientInvoiceInput
const prepareMutationInput = R.compose(
  R.omit(['isEdit']),
  R.when(propEqLegacy('isEdit', true), R.omit(['projectId'])),
  R.evolve({
    invoiceFile: R.compose(
      R.unless(R.isNil, R.pick(['fileName', 'upload'])),
      R.head,
    ),
  }),
);

// prepareInitialValues :: Input -> FormData
// Input = {
//    project: Project
//    reportOnlyClientInvoice: ReportOnlyClientInvoice
// }
const prepareInitialValues = R.applySpec({
  projectId: R.path(['project', '_id']),
  number: R.path(['reportOnlyClientInvoice', 'number']),
  amount: R.path(['reportOnlyClientInvoice', 'amount']),
  invoiceDate: R.compose(
    R.when(R.isNil, alwaysNewDate),
    R.path(['reportOnlyClientInvoice', 'invoiceDate']),
  ),
  invoiceFile: R.compose(
    R.ifElse(
      R.isNil,
      R.always([]),
      R.compose(ofArrayLegacy, transformInvoiceFileToPicker),
    ),
    R.path(['reportOnlyClientInvoice', 'invoiceFile']),
  ),
});

// getReportOnlyClientInvoice :: { reportOnlyClientInvoice: ReportOnlyClientInvoice }
// -> ReportOnlyClientInvoice
const getReportOnlyClientInvoice = R.propOr({}, 'reportOnlyClientInvoice');

export function ClientInvoiceTaskForm({ onCancel, project, taskId, isEdit }) {
  const { showSuccessNotification } = useNotificationState();
  const [createReportOnlyClientInvoice] = useMutation(
    CREATE_REPORT_ONLY_CLIENT_INVOICE_MUTATION,
  );
  const [editReportOnlyClientInvoice] = useMutation(
    EDIT_REPORT_ONLY_CLIENT_INVOICE_MUTATION,
  );

  const { data, loading } = useQuery(REPORT_ONLY_CLIENT_INVOICE_QUERY, {
    variables: { projectId: project._id },
    fetchPolicy: 'network-only',
    skip: !isEdit || !project._id,
  });

  const reportOnlyClientInvoice = getReportOnlyClientInvoice(data);

  const mutation = isEdit
    ? editReportOnlyClientInvoice
    : createReportOnlyClientInvoice;

  const onSubmitHandler = async (formData) => {
    await mutation({
      variables: {
        ...(isEdit ? { id: reportOnlyClientInvoice._id } : {}),
        input: prepareMutationInput({ isEdit, ...formData }),
      },
      update: projectTaskCompleteCache(project._id, taskId),
    });
    showSuccessNotification(
      `Report Only Client Invoice was ${
        isEdit ? 'edited' : 'attached'
      } successfully.`,
    );

    if (onCancel) {
      onCancel();
    }
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    clientInvoiceTaskFormId,
    onSubmitHandler,
  );

  if (loading) {
    return <Loader />;
  }

  const formCreatorProps = {
    onSubmit,
    id: clientInvoiceTaskFormId,
    // this prevents obsolete queries while validating invoice number
    validateOnBlur: true,
    layout: commonModalLayout,
    initialValues: prepareInitialValues({ project, reportOnlyClientInvoice }),
    sections: getClientInvoiceTaskFormSections(onCancel),
  };

  return <FormCreator {...formCreatorProps} />;
}

ClientInvoiceTaskForm.propTypes = {
  onCancel: func,
  taskId: string,
  isEdit: bool,
  project: shape({
    _id: string,
  }),
};
