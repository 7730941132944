import React from 'react';
import * as R from 'ramda';
import { useRouterQuery } from '@poly/client-routing';
import { NOTHING_UI_STRING } from '@poly/constants';
import { propEqLegacy } from '@poly/utils';

import { SidebarIDs } from '../constants.js';
import { SupplierLink, MasterSupplierLink } from '../../components/Links.js';
import { ClientLink } from '../ClientSidebar/useOpenClientSidebar.js';
import { PropertyLink } from '../PropertySidebar/useOpenPropertySidebar.js';

// isSidebarUrlActive :: String -> UrlQuery -> Boolean
const isSidebarUrlActive = (propName) =>
  R.propSatisfies(R.complement(R.isNil), propName);

// checkSsReferenceSidebarActive :: (String, RouterQuery, { _id: ID }) -> Boolean
const checkIsReferenceSidebarActive = (queryProp, queryObj, props) =>
  isSidebarUrlActive(queryProp)(queryObj) && queryObj[queryProp] === props._id;

// getSidebarReferenceLink :: (Link, String, Function) -> _ -> ReactNode
const getSidebarReferenceLink = (ReferenceLink, queryProp, onClick) =>
  function (props) {
    const queryObj = useRouterQuery([queryProp]);

    const isReferenceSidebarActive = checkIsReferenceSidebarActive(
      queryProp,
      queryObj,
      props,
    );

    const sidebarLogicProps = isReferenceSidebarActive
      ? { onReject: onClick }
      : { onClick };

    return <ReferenceLink {...props} {...sidebarLogicProps} />;
  };

// formatEntityLink :: LinkedEntities -> Link -> ReactElement
const formatEntityLink = ({
  onClick,
  linkedClients = [],
  linkedSuppliers = [],
  linkedProperties = [],
  linkedMasterSuppliers = [],
}) =>
  R.cond([
    [
      R.both(R.prop('propertyId'), R.prop('clientId')),
      ({ propertyId }) =>
        R.compose(
          R.when(
            R.identity,
            getSidebarReferenceLink(PropertyLink, SidebarIDs.property, onClick),
          ),
          R.find(propEqLegacy('_id', propertyId)),
        )(linkedProperties),
    ],
    [
      R.prop('clientId'),
      ({ clientId }) =>
        R.compose(
          R.when(
            R.identity,
            getSidebarReferenceLink(ClientLink, SidebarIDs.client, onClick),
          ),
          R.find(propEqLegacy('_id', clientId)),
        )(linkedClients),
    ],
    [
      R.prop('supplierId'),
      ({ supplierId }) =>
        R.compose(
          R.when(R.identity, SupplierLink),
          R.applySpec({
            _id: R.prop('_id'),
            onClick: R.always(onClick),
            name: R.path(['company', 'name']),
          }),
          R.find(propEqLegacy('_id', supplierId)),
        )(linkedSuppliers),
    ],
    [
      R.prop('masterSupplierId'),
      ({ masterSupplierId }) =>
        R.compose(
          R.when(R.identity, MasterSupplierLink),
          R.applySpec({
            _id: R.prop('_id'),
            onClick: R.always(onClick),
            name: R.path(['company', 'name']),
          }),
          R.find(propEqLegacy('_id', masterSupplierId)),
        )(linkedMasterSuppliers),
    ],
  ]);

/**
 * getContactReferences :: ContactData -> [References]
 */
export const getContactReferences = ({ links, ...linkedEntities }) =>
  R.compose(
    R.objOf('references'),
    R.when(R.isEmpty, R.always(NOTHING_UI_STRING)),
    R.reject(R.isNil),
    R.map(formatEntityLink(linkedEntities)),
    R.defaultTo([]),
  )(links);
