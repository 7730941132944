import React from 'react';
import { bool, string } from 'prop-types';
import { defaultTheme } from '../ThemeProvider/index.js';
import { Status } from './index.js';

const {
  colors: {
    statuses: { error, active },
  },
} = defaultTheme;

export function BooleanTextWithDot({ isTrue, dataTestId }) {
  return (
    <Status
      text={isTrue ? 'Yes' : 'No'}
      color={isTrue ? active : error}
      dataTestId={dataTestId}
    />
  );
}

BooleanTextWithDot.propTypes = {
  isTrue: bool,
  dataTestId: string,
};
