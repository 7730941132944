import * as R from 'ramda';
import {
  alwaysNewDate,
  assocBy,
  isNilOrEmpty,
  removePropDeep,
  roundToCents,
  ramdaUseWith,
  forceTitleCase,
  getInvoiceDueDateByTerms,
  propEqLegacy,
  pathEqLegacy,
  ofArrayLegacy,
} from '@poly/utils';
import {
  InvoiceTypes,
  ProjectType,
  InvoicesStatuses,
  ProjectSupplierStatus,
  NOTHING_UI_STRING,
} from '@poly/constants';
import { getEmailsToSendTo } from '@poly/admin-ui';

import {
  checkInvoiceFileIsOld,
  transformInvoiceFileToPicker,
} from '../../core/utils/invoices.js';

// generateDescriptionString :: Project -> String
// eslint-disable-next-line import/no-unused-modules
export const generateDescriptionString = R.converge(
  R.unapply(R.join(` ${NOTHING_UI_STRING} `)),
  [
    R.path(['client', 'name']),
    R.path(['property', 'name']),
    R.prop('projectId'),
  ],
);

// getCommonProjectFields :: Project -> FormattedProjectFields
export const getCommonProjectFields = R.applySpec({
  project: R.identity,
  projectId: R.prop('_id'),
  suppliers: R.compose(
    R.filter(propEqLegacy('statusInProject', ProjectSupplierStatus.assigned)),
    R.propOr([], 'suppliers'),
  ),
  formTitle: generateDescriptionString,
  projectInvoiceDescription: R.prop('invoiceDescription'),
});

/**
 * prepareInvoiceToForm :: Invoice -> FormFields
 */
export const prepareInvoiceToForm = R.compose(
  R.mergeAll,
  R.juxt([
    R.compose(R.objOf('type'), R.propOr(InvoiceTypes.breakdown, 'type')),
    R.pick([
      '_id',
      'total',
      'status',
      'taxAmount',
      'hourlyRate',
      'labourCost',
      'materialCost',
      'invoiceNumber',
      'paymentDueDate',
      'paymentDueDateChangeReason',
      'projectInvoiceDescription',
    ]),
    R.applySpec({
      isEdit: R.T,
      supplierId: R.pathOr('', ['supplier', '_id']),
      invoiceFile: R.ifElse(
        R.propSatisfies(R.identity, 'invoiceFile'),
        R.pipe(
          R.prop('invoiceFile'),
          transformInvoiceFileToPicker,
          ofArrayLegacy,
        ),
        R.always([]),
      ),
      invoiceDate: R.propOr(alwaysNewDate(), 'invoiceDate'),
    }),
  ]),
  R.defaultTo({}),
);

/**
 * prepareConstantsToSelect :: [Status] -> [StatusOptions]
 */
export const prepareConstantsToSelect = R.map((status) => [
  R.toLower(status),
  R.compose(forceTitleCase, R.toLower)(status),
]);

// formatInvoiceFileUploadInput :: FormValues -> FormValues
export const formatInvoiceFileUploadInput = R.over(
  R.lensProp('invoiceFile'),
  R.compose(R.unless(R.isNil, R.pick(['upload', 'fileName'])), R.nth(0)),
);

// prepareCommonFields :: Object -> Object
const prepareCommonFields = R.compose(
  R.converge(R.mergeRight, [
    R.ifElse(
      R.prop('paymentDueDate'),
      R.pick(['paymentDueDate', 'paymentDueDateChangeReason']),
      R.always({}),
    ),
    R.pick([
      'total',
      'projectId',
      'supplierId',
      'invoiceDate',
      'invoiceFile',
      'invoiceNumber',
    ]),
  ]),
  formatInvoiceFileUploadInput,
);

// prepareBreakdownInvoice :: Object -> Object
const prepareBreakdownInvoice = R.converge(R.mergeRight, [
  prepareCommonFields,
  R.pick(['hourlyRate', 'materialCost', 'labourCost', 'taxAmount']),
]);

/**
 * prepareDataToMutation :: Object -> Object
 */
export const prepareDataToMutation = R.compose(
  R.converge(R.mergeRight, [
    R.pick(['projectInvoiceDescription', 'supplierRating', 'emailTo']),
    R.ifElse(
      R.propSatisfies(R.equals(InvoiceTypes.breakdown), 'type'),
      R.pipe(prepareBreakdownInvoice, R.objOf(InvoiceTypes.breakdown)),
      R.pipe(prepareCommonFields, R.objOf(InvoiceTypes.fixed)),
    ),
  ]),
  assocBy('supplierRating', R.pathOr(0, ['rating', 'average'])),
  R.when(
    R.propSatisfies(R.either(R.isNil, R.isEmpty), 'emailTo'),
    R.dissoc('emailTo'),
  ),
  assocBy('emailTo', R.compose(getEmailsToSendTo, R.prop('emailTo'))),
);

// checkIfFileIsOld :: Invoice -> FormData -> ModifiedFormData
// eslint-disable-next-line import/no-unused-modules
export const checkIfFileIsOld = (invoice) =>
  R.cond([
    [R.compose(checkInvoiceFileIsOld(invoice)), R.dissoc('invoiceFile')],
    [
      R.compose(R.pathSatisfies(isNilOrEmpty, ['invoiceFile'])),
      R.assoc('invoiceFile', null),
    ],
    [R.T, R.identity],
  ]);

// prepareFileToMutation :: Invoice -> FormData -> ModifiedFormData
export const prepareFileToMutation = (invoice) =>
  R.cond([
    [
      R.has(InvoiceTypes.fixed),
      R.over(R.lensProp(InvoiceTypes.fixed), checkIfFileIsOld(invoice)),
    ],
    [
      R.has(InvoiceTypes.breakdown),
      R.over(R.lensProp(InvoiceTypes.breakdown), checkIfFileIsOld(invoice)),
    ],
    [R.T, checkIfFileIsOld(invoice)],
  ]);

/**
 * modifyMutationDataForUpdate :: Invoice -> FormData -> ModifiedFromData
 */
export const modifyMutationDataForUpdate = (invoice) =>
  R.compose(
    R.objOf('input'),
    prepareFileToMutation(invoice),
    R.dissoc('supplierRating'),
    removePropDeep('supplierId'),
    removePropDeep('projectId'),
  );

/**
 * totalAmountValidator :: (Number, FormValues) -> Boolean
 * FormValues = { type: String, labourCost: Number, materialCost: Number }
 */
export const totalAmountValidator = (total, formValues) =>
  R.ifElse(
    propEqLegacy('type', InvoiceTypes.breakdown),
    R.compose(
      R.equals(total),
      roundToCents,
      R.reduce(R.add, 0),
      R.reject(R.isNil),
      R.props(['labourCost', 'materialCost', 'taxAmount']),
    ),
    R.T,
  )(formValues);

// isTotalValidAgainstPaidAmount :: (Number, FormValues) -> Boolean
// FormValues = { paidAmount: Number, status: String, type: String }
export const isTotalValidAgainstPaidAmount = (total, formValues) =>
  R.includes(formValues.status, [
    InvoicesStatuses.REPORT_ONLY,
    InvoicesStatuses.REQUESTED,
    InvoicesStatuses.RECEIVED,
  ]) ||
  R.includes(formValues.type, [
    InvoiceTypes.creditCardExpense,
    InvoiceTypes.bankExpense,
  ]) ||
  Math.abs(total) >= Math.abs(formValues.paidAmount);

// isTotalPositiveForReportOnlyProject :: (Number, Props) -> Boolean
// Props = { project: { type: String } }
export const isTotalPositiveForReportOnlyProject = R.complement(
  ramdaUseWith(R.and, [
    R.lt(R.__, 0),
    pathEqLegacy(['project', 'type'], ProjectType.REPORT_ONLY),
  ]),
);

// isBreakdown :: Invoice -> Boolean
export const isBreakdown = propEqLegacy('type', InvoiceTypes.breakdown);

// getSupplierInvoiceDueDateByTerms :: Invoice -> Date
export const getSupplierInvoiceDueDateByTerms = R.converge(
  getInvoiceDueDateByTerms,
  [R.path(['supplier', 'finance', 'terms']), R.identity],
);
