import React, { useState } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, string } from 'prop-types';
import { isNilOrEmpty } from '@poly/utils/src/general.js';

import { ShowMoreButton } from './Button/Button.js';
import { EmailLink, NothingUI } from './Links.js';

const EmailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
`;

export function MultiEmailsLinks({ emails }) {
  const [showMore, setShowMore] = useState(false);

  if (isNilOrEmpty(emails) || !Array.isArray(emails)) {
    return <NothingUI />;
  }

  const emailsToRender = showMore ? emails : R.take(3, emails);

  return (
    <EmailsWrapper>
      {emailsToRender.map((email) => (
        <div key={email}>
          <EmailLink email={email} />
        </div>
      ))}
      {emails.length > 3 ? (
        <ShowMoreButton showMore={showMore} setShowMore={setShowMore} />
      ) : null}
    </EmailsWrapper>
  );
}

MultiEmailsLinks.propTypes = {
  emails: arrayOf(string),
};
