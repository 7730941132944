import * as R from 'ramda';
import { Checkbox, Input } from '@poly/admin-book';
import { halfWidth, thirdWidth, getContactSelect } from '@poly/admin-ui';

import {
  aacManagerFormField,
  branchManagerFormField,
  districtFacilityManagerFormField,
} from './sections/peopleSection.js';
import {
  remarksFormField,
  getConvertPropertyFormField,
} from './sections/remarksSection.js';
import {
  codingFormField,
  invoiceToFormField,
  costCenterFormField,
} from './sections/financialSection.js';
import {
  faxFormField,
  nameFormField,
  phoneFormField,
  statusFormField,
  addressFormField,
  divisionFormField,
  storeNumberFormField,
  mainAccountFormField,
  masterPropertyFormField,
} from './sections/accountInfoSection.js';
import {
  passwordFormField,
  gateCodeFormField,
  alarmCodeFormField,
  alarmSystemFormField,
  accountNumberFormField,
  alarmCompanyPhoneFormField,
} from './sections/alarmLockBoxInfoSection.js';
import {
  occupancyFormField,
  ownershipFormField,
  lockBoxCodeFormField,
  parcelTaxIdFormField,
  buildingSizeFormField,
  buildingTypeFormField,
  getBuildingInfoConfigs,
  powerRequirementsFormField,
  customBuildingTypeFormField,
  landlordResponsibilityFormField,
} from './sections/buildinInformationSection.js';
import { hierarchiesFormField } from './sections/hierarchiesSection.js';
import { DefaultSupplierWOInstructions } from './components/DefaultSupplierWOInstructions.js';
import { ShortExplanationKeys } from '../../../../components/HelpTooltip.js';
import { FieldWithTooltip } from '../../../ClientSidebar/forms/form/sideBarClientFormSections.js';

const sectionBorderLayout = {
  borderBottom: '1px solid',
  borderBottomColor: 'light',
};

const sectionWithoutMarginLayout = { margin: 0 };

export const sideBarPropertyFormSections = (isEdit, isCard) => [
  {
    label: 'Account Information',
    order: 1,
    layout: sectionBorderLayout,
    fields: [
      {
        ...nameFormField,
        order: 1,
        layout: { row: 1, width: '100%' },
      },
      {
        ...mainAccountFormField,
        order: 2,
        layout: { row: 2, width: '100%' },
      },
      {
        ...masterPropertyFormField,
        order: 2,
        layout: { row: 3, width: '100%' },
      },
      {
        ...statusFormField,
        order: 3,
        layout: { row: 4, width: thirdWidth },
      },
      {
        ...storeNumberFormField,
        order: 1,
        layout: { row: 4, width: thirdWidth },
      },
      {
        ...divisionFormField,
        order: 2,
        layout: { row: 4, width: thirdWidth },
      },
      {
        order: 1,
        layout: { row: 5, width: thirdWidth },
        field: {
          name: 'requesterSurveyEnabled',
          withFormData: true,
          Component: FieldWithTooltip,
          additionalProps: {
            label: 'Requester Survey',
            Component: Checkbox,
            position: 'top',
            shortExplanation: ShortExplanationKeys.requesterSurveyEnabled,
          },
        },
        renderIf: R.path(['client', 'configs', 'requesterSurvey', 'enabled']),
      },
      {
        ...addressFormField,
        order: 4,
        layout: { row: 6, width: '100%' },
      },
      {
        ...phoneFormField,
        order: 5,
        layout: { row: 7, width: halfWidth },
      },
      {
        ...faxFormField,
        order: 6,
        layout: { row: 7, width: halfWidth },
      },
      {
        label: 'ESFM Property ID',
        field: {
          name: 'esfmPropertyId',
          Component: Input,
        },
        order: 7,
        layout: { row: 8, width: halfWidth },
      },
    ],
  },
  {
    label: 'Building Information',
    order: 2,
    layout: sectionBorderLayout,
    renderIf: R.compose(
      R.complement(R.isEmpty),
      R.reject(R.isNil),
      R.values,
      getBuildingInfoConfigs,
    ),
    fields: [
      {
        ...buildingSizeFormField,
        order: 1,
        layout: { row: 1, width: halfWidth },
      },
      {
        ...ownershipFormField,
        order: 2,
        layout: { row: 1, width: halfWidth },
      },
      {
        ...occupancyFormField,
        order: 3,
        layout: { row: 2, width: halfWidth },
      },
      {
        ...lockBoxCodeFormField,
        order: 4,
        layout: { row: 2, width: halfWidth },
      },
      {
        ...powerRequirementsFormField,
        order: 5,
        layout: { row: 3, width: halfWidth },
      },
      {
        ...buildingTypeFormField,
        order: 6,
        layout: { row: 4, width: halfWidth },
      },
      {
        ...customBuildingTypeFormField,
        order: 7,
        layout: { row: 4, width: halfWidth },
      },
      {
        ...parcelTaxIdFormField,
        order: 8,
        layout: { row: 5, width: halfWidth },
      },
      {
        ...landlordResponsibilityFormField,
        order: 9,
        layout: { row: 6 },
      },
    ],
  },

  {
    order: 3,
    label: 'Alarm Information',
    layout: sectionWithoutMarginLayout,
    renderIf: R.path(['client', 'configs', 'propertyFields', 'alarmInfo']),
    fields: [
      {
        ...alarmCodeFormField,
        order: 1,
        layout: { row: 1, width: halfWidth },
      },
      {
        ...alarmSystemFormField,
        order: 2,
        layout: { row: 1, width: halfWidth },
      },
      {
        ...accountNumberFormField,
        order: 3,
        layout: { row: 2, width: halfWidth },
      },
      {
        ...gateCodeFormField,
        order: 4,
        layout: { row: 2, width: halfWidth },
      },
      {
        ...passwordFormField,
        order: 5,
        layout: { row: 3, width: halfWidth },
      },
      {
        ...alarmCompanyPhoneFormField,
        order: 6,
        layout: { row: 3, width: halfWidth },
      },
    ],
  },
  {
    order: 4,
    label: 'Hierarchy',
    layout: sectionWithoutMarginLayout,
    renderIf: R.compose(R.complement(R.isEmpty), R.prop('clientHierarchies')),
    fields: [
      {
        ...hierarchiesFormField,
        order: 1,
        layout: { row: 1, width: '100%' },
      },
    ],
  },
  {
    order: 5,
    label: 'Remarks',
    layout: sectionWithoutMarginLayout,
    fields: [
      {
        ...remarksFormField,
        order: 1,
        layout: { row: 1, width: '100%' },
      },
      {
        ...getConvertPropertyFormField(isEdit, isCard),
        order: 2,
        layout: { row: 2 },
      },
    ],
  },
  {
    order: 6,
    label: 'Default Supplier Work Order Instructions',
    layout: sectionWithoutMarginLayout,
    fields: [
      {
        order: 1,
        layout: { row: 1, width: '100%' },
        field: {
          name: 'defaultWOInstructions',
          Component: DefaultSupplierWOInstructions,
        },
      },
    ],
  },
  {
    label: 'Financial Information',
    order: 7,
    layout: sectionBorderLayout,
    fields: [
      {
        ...invoiceToFormField,
        order: 1,
        layout: { row: 1, width: halfWidth },
      },
      {
        ...codingFormField,
        order: 2,
        layout: { row: 1, width: halfWidth },
      },
      {
        ...costCenterFormField,
        order: 2,
        layout: { row: 2, width: halfWidth },
      },
    ],
  },
  {
    order: 8,
    label: 'People',
    layout: sectionWithoutMarginLayout,
    fields: [
      {
        ...aacManagerFormField,
        layout: { row: 1, width: halfWidth },
        order: 1,
      },

      ...getContactSelect({
        ...branchManagerFormField,
        order: 2,
        layout: { row: 2, width: halfWidth },
      }),
      ...getContactSelect({
        ...districtFacilityManagerFormField,
        order: 4,
        layout: { row: 8, width: halfWidth },
      }),
    ],
  },
];
