import React, { useEffect } from 'react';
import { number, func, shape, string, oneOfType, bool } from 'prop-types';

import { AmountInput } from './AmountInput.js';
import { recalculatePaymentByDiscount } from './helpers.js';

export function PaymentInput({
  deductionsAmount = 0,
  currentBalance,
  paidAmount,
  error,
  changePayment,
  asCents,
}) {
  useEffect(() => {
    if (deductionsAmount > 0 && deductionsAmount <= currentBalance) {
      const newPaidAmount = recalculatePaymentByDiscount(
        currentBalance,
        deductionsAmount,
        paidAmount,
      );
      if (newPaidAmount !== paidAmount) {
        changePayment(newPaidAmount);
      }
    }
  }, [deductionsAmount]);

  return (
    <AmountInput
      width="100%"
      name="invoice-deductions-input"
      error={error?.paidAmount}
      value={paidAmount}
      currentBalance={currentBalance}
      onChange={changePayment}
      dataTestId="paid-amount-input"
      asCents={asCents}
    />
  );
}

PaymentInput.propTypes = {
  deductionsAmount: oneOfType([number, string]),
  currentBalance: number.isRequired,
  changePayment: func.isRequired,
  paidAmount: oneOfType([number, string]),
  error: shape({
    deductionsAmount: string,
  }),
  asCents: bool,
};
