import React from 'react';
import * as R from 'ramda';
import { bool, func, string, shape } from 'prop-types';
import { isNilOrEmpty } from '@poly/utils';
import { Select } from '@poly/admin-book';
import {
  entityToOptionByLabelPath,
  useMapPropsForDropdown,
  getAppendDefaultEntity,
  useQueryWithSearch,
} from '@poly/client-utils';

import { ALL } from '../../../../constants/general.js';
import { CLIENTS_SEARCH_FOR_SELECT } from '../../../../hooks/clients/queries.js';
import { CLIENTS_BY_SEARCH_SUB } from '../../../../hooks/clients/subscription.js';
import { useDefaultClient } from '../../../../hooks/clients/index.js';

// clientToOption :: Client -> Option
// eslint-disable-next-line import/no-unused-modules
export const clientToOption = entityToOptionByLabelPath(['name']);

// eslint-disable-next-line import/no-unused-modules
export const mapClientsToOptions = ({
  extraOption,
  mapEntityFunc,
  includeAllOption,
  filterOptions = R.identity,
  ...props
}) =>
  R.pipe(
    R.pathOr([], ['searchClients', 'hits']),
    filterOptions,
    R.map(mapEntityFunc),
    R.unless(() => isNilOrEmpty(extraOption), R.prepend(extraOption)),
    R.when(
      R.always(includeAllOption),
      R.prepend({ value: ALL, label: 'All clients' }),
    ),
  )(props);

export function ClientSelect({
  extraOption = {},
  isClearable = true,
  includeAllOption = true,
  filterOption = () => true,
  name = 'ClientSelectDropdown',
  mapEntityFunc = clientToOption,
  placeholder = 'Start typing clients',
  ...restProps
}) {
  const { value, onChange, error: errorFromProps } = restProps;

  const { data } = useDefaultClient(value);

  const { result, error, onSearchChange } = useQueryWithSearch({
    gqlSearchQuery: CLIENTS_SEARCH_FOR_SELECT,
    gqlSearchChangedQuery: CLIENTS_BY_SEARCH_SUB,
    error: errorFromProps,
    propsOfComponent: {
      name,
      isClearable,
      extraOption,
      placeholder,
      filterOption,
      mapEntityFunc,
      includeAllOption,
      ...restProps,
    },
    ...restProps,
  });

  const updatedEntityPath = getAppendDefaultEntity({
    entityPath: ['client'],
    allEntitiesPath: ['searchClients'],
    props: {
      client: data?.client,
      searchClients: result?.searchClients,
    },
  });

  const { options, onChange: onDropdownChange } = useMapPropsForDropdown({
    mapper: mapClientsToOptions,
    onChange,
    restProps: { ...result, ...updatedEntityPath },
  });

  return (
    <Select
      {...data?.client}
      {...result}
      error={error}
      options={options}
      onChange={onDropdownChange}
      onInputChange={onSearchChange}
    />
  );
}

ClientSelect.displayName = 'ClientSelect';

ClientSelect.propTypes = {
  name: string,
  value: string,
  isClearable: bool,
  filterOption: func,
  placeholder: string,
  mapEntityFunc: func,
  includeAllOption: bool,
  onChange: func.isRequired,
  extraOption: shape({ value: string, label: string }),
};
