import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string, shape } from 'prop-types';
import { useDispatch } from 'react-redux';
import { LinkButton } from '@poly/admin-book';
import { setPopup } from '../../redux/popupReducer.js';

const MESSAGE_LENGTH_THRESHOLDS = 50;

// shortenMessage :: String -> String
const shortenMessage = R.compose(
  R.when(
    R.propSatisfies(R.lt(MESSAGE_LENGTH_THRESHOLDS), 'length'),
    R.compose(R.concat(R.__, '...'), R.slice(0, MESSAGE_LENGTH_THRESHOLDS)),
  ),
  R.defaultTo(''),
);

const Message = styled.p`
  margin: 5px 0;
`;

const Button = styled(LinkButton)`
  float: right;
`;

export function NewAlertNotification({ payload }) {
  const dispatch = useDispatch();

  return (
    <>
      <strong>New Alert:</strong> <br />
      <Message>{shortenMessage(payload.message)}</Message>
      <Button onClick={() => dispatch(setPopup('alerts'))}>Go to alerts</Button>
    </>
  );
}

NewAlertNotification.displayName = 'NewAlertNotification';

NewAlertNotification.propTypes = {
  payload: shape({
    message: string.isRequired,
  }).isRequired,
};
