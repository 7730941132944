import { shape, instanceOf, string, number, arrayOf } from 'prop-types';

export const bankRegistersQueryPropType = shape({
  fromDate: instanceOf(Date),
  toDate: instanceOf(Date),
  accountId: string,
});
export const moneyTransactionShapePropType = shape({
  transaction_number: number.isRequired,
  date: string.isRequired,
  lines: arrayOf(
    shape({
      amount: number.isRequired,
      payment_status: string,
    }),
  ).isRequired,
  check_number: string,
  payee_name: string,
  reference_column: string.isRequired,
});

const singleAccountShapePropType = shape({
  _id: string.isRequired,
  name: string.isRequired,
  accountType: shape({
    _id: string.isRequired,
    normal_balance: string.isRequired,
  }).isRequired,
  accrualBalance: number.isRequired,
});

export const bankRegistersQueryDataPropType = shape({
  getSingleAccount: singleAccountShapePropType,
  searchMoneyTransactions: shape({
    hits: arrayOf(moneyTransactionShapePropType).isRequired,
  }),
});
