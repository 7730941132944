import React from 'react';
import * as R from 'ramda';
import { Select } from '@poly/admin-book';
import { ProjectTypeToNameMap } from '@poly/constants';

import { ALL } from '../modules/core/constants/general.js';

// prepareOptions :: Object -> [{ value: String, label: String }]
const prepareOptions = R.compose(
  R.prepend({ value: ALL, label: 'All' }),
  R.map(
    R.applySpec({
      value: R.nth(0),
      label: R.nth(1),
    }),
  ),
  R.toPairs,
);

export function ProjectTypeSelect(props) {
  const selectProps = {
    options: prepareOptions(ProjectTypeToNameMap),
    ...props,
  };

  return <Select {...selectProps} />;
}
