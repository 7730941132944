import * as R from 'ramda';

/**
 * createAccessItem :: PermissionName -> AccessItemTypes -> AccessItem
 */
// eslint-disable-next-line react-hooks/rules-of-hooks
export const createAccessItem = R.useWith(R.mergeRight, [
  R.objOf('permission'),
  R.objOf('types'),
]);
