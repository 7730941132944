import React from 'react';
import PropTypes from 'prop-types';
import { BaseSelect } from '../Select/BaseSelect.js';

export function MultiselectDropdown({
  options,
  handleChange,
  className,
  value,
  placeholder,
  ...additionalProps
}) {
  return (
    <BaseSelect
      isMulti
      value={value}
      onChange={handleChange}
      options={options}
      className={className}
      classNamePrefix="multiselect"
      placeholder={placeholder}
      {...additionalProps}
    />
  );
}

MultiselectDropdown.displayName = 'MultiselectDropdown';
MultiselectDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]).isRequired,
  handleChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};
