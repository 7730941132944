import * as R from 'ramda';
import { createPortal } from 'react-dom';
import { node, string } from 'prop-types';
import React, {
  useState,
  memo,
  createContext,
  useContext,
  useMemo,
} from 'react';

const SidebarTableFormContext = createContext({});

// getSidebarFormElementId :: String -> String
const getSidebarFormElementId = (id) => `sidebar-form-element-id-${id}`;

// getAnchorId :: String -> String
const getAnchorId = (id) => `row-portal-${id}`;

export const FormPortalAnchor = memo(
  ({ id }) => <div id={getSidebarFormElementId(id)} />,
  R.T,
);

FormPortalAnchor.propTypes = { id: string };

export const useSidebarTableFormContext = () =>
  useContext(SidebarTableFormContext);

// isNotNull :: ID -> Boolean
const isNotNull = R.complement(R.isNil);

function FormPortal() {
  const { activeForm } = useSidebarTableFormContext();

  if (!activeForm) return null;

  const { id, elementId, Content } = activeForm;

  const anchor = isNotNull(elementId)
    ? getAnchorId(elementId)
    : getSidebarFormElementId(id);

  const domNode = document.getElementById(anchor);

  return domNode ? createPortal(Content, domNode) : null;
}

// don't use any logic at the component where this wrapper added
// it prevents any form render on table props or data change
export const SidebarWrapperForTableForm = memo(({ children }) => {
  const [activeForm, setActiveForm] = useState(null);

  const formSetter = (form) => {
    if (!!activeForm && !!activeForm.elementId) {
      const existedAnchor = document.getElementById(
        getAnchorId(activeForm.elementId),
      );

      if (existedAnchor) {
        existedAnchor.remove();
      }
    }

    if (!form) return setActiveForm(null);

    const { elementId } = form;

    if (isNotNull(elementId)) {
      const referenceNode = document.getElementById(elementId);
      const anchorId = getAnchorId(elementId);
      let anchorRow = document.getElementById(anchorId);

      if (!anchorRow) {
        anchorRow = document.createElement('tr');
        anchorRow.id = anchorId;
        anchorRow.setAttribute('data-testid', anchorId);
        anchorRow.style.cssText =
          'width:100%;display:table;padding: 0 !important; table-layout: fixed';
      }

      referenceNode.parentNode.insertBefore(
        anchorRow,
        referenceNode.nextSibling,
      );
    }
    return setActiveForm(form);
  };

  const contextValue = useMemo(
    () => ({ activeForm, formSetter }),
    [activeForm],
  );

  return (
    <SidebarTableFormContext.Provider value={contextValue}>
      {children}
      <FormPortal />
    </SidebarTableFormContext.Provider>
  );
});

SidebarWrapperForTableForm.propTypes = {
  children: node.isRequired,
};
