import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import {
  array,
  arrayOf,
  bool,
  node,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { formatPhoneNumber, insertParamsIntoURL } from '@poly/utils';
import { Link, useNavigate } from '@poly/client-routing';
import { NOTHING_UI_STRING } from '@poly/constants';
import { TextButton } from '@poly/admin-book';

import { highlightedTextPropType } from '../modules/globalSearch/globalSearchResult/searchResultItems/common/propTypes.js';

const NothingUIWrap = styled.span`
  font-size: 12px;
  line-height: 18px;
`;

export function NothingUI() {
  return <NothingUIWrap>{NOTHING_UI_STRING}</NothingUIWrap>;
}

const namePropType = oneOfType([string, object, arrayOf(node)]);

export function FileLink({ url, fileName }) {
  return url && url !== NOTHING_UI_STRING ? (
    <a href={url} rel="noopener noreferrer" target="_blank">
      {fileName}
    </a>
  ) : (
    <NothingUI />
  );
}

FileLink.propTypes = {
  url: string,
  fileName: namePropType,
};

export function EmailLink({ email }) {
  return email && email !== NOTHING_UI_STRING ? (
    <a href={`mailto:${email}`}>{email}</a>
  ) : (
    <NothingUI />
  );
}

EmailLink.propTypes = {
  email: highlightedTextPropType,
};

export function PhoneLink({ number, ext, international }) {
  const phoneNumber = international
    ? number
    : formatPhoneNumber('-', 'x ')({ number, ext });

  return number ? <a href={`tel:${number}`}>{phoneNumber}</a> : <NothingUI />;
}

PhoneLink.displayName = 'PhoneLink';

PhoneLink.propTypes = {
  number: string,
  ext: string,
  international: bool,
};

export function InvoicePDFLink({ invoiceFileLink = '#', invoiceNumber = '' }) {
  return (
    <a href={invoiceFileLink} target="_blank" rel="noreferrer">
      {invoiceNumber}
    </a>
  );
}

InvoicePDFLink.propTypes = {
  invoiceFileLink: string,
  invoiceNumber: namePropType,
};

export function PageLink({ routeName, children }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(routeName);
  };

  return <TextButton onClick={onClick}>{children}</TextButton>;
}

PageLink.displayName = 'PageLink';

PageLink.propTypes = {
  routeName: string.isRequired,
  children: string.isRequired,
};

export function EntityLink({ _id, fullName, url }) {
  const href = insertParamsIntoURL({
    contactId: _id,
  })(url);

  return <Link href={href}>{fullName}</Link>;
}

EntityLink.displayName = 'EntityLink';

EntityLink.propTypes = {
  _id: string.isRequired,
  fullName: namePropType.isRequired,
  url: string.isRequired,
};

export function MasterSupplierLink({ _id, company, masterSupplierRootUrl }) {
  const masterSupplierHref = insertParamsIntoURL({ supplierId: _id })(
    masterSupplierRootUrl,
  );
  const name = R.prop('name', company);
  return <Link href={masterSupplierHref}>{name}</Link>;
}

MasterSupplierLink.propTypes = {
  _id: string.isRequired,
  company: shape({ name: oneOfType([string, array]).isRequired }),
  masterSupplierRootUrl: string.isRequired,
};

export function WebsiteLink({ website }) {
  return website && website !== NOTHING_UI_STRING ? (
    <a href={website} rel="noopener noreferrer" target="_blank">
      {website}
    </a>
  ) : (
    <NothingUI />
  );
}

WebsiteLink.propTypes = {
  website: string,
};
