import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string, oneOf, number } from 'prop-types';
import { Popover, Icon } from '@poly/admin-book';

import { ApproveNewEquipmentContent } from './ApproveNewEquipmentContent.js';
import { useUserHasAccessToApproveNewEquipment } from '../../pages/AssetReview/useUserHasAccessToUpdateAsset.js';

const EquipmentIconS = styled(Icon)`
  margin: 0 8px;
`;

const PopoverS = styled(Popover)`
  > div > div {
    left: -${R.prop('left')}px;

    ::after {
      display: none;
    }
  }
`;

function NewEquipmentIcon() {
  return <EquipmentIconS size={15} name="yellowWarning" />;
}

export function ApproveNewEquipmentPopup({
  className,
  left = 95,
  position = 'top',
  ...props
}) {
  const hasPermissionToApprove = useUserHasAccessToApproveNewEquipment();

  return (
    <PopoverS
      left={left}
      position={position}
      className={className}
      Icon={NewEquipmentIcon}
      content={
        hasPermissionToApprove ? (
          <ApproveNewEquipmentContent {...props} />
        ) : null
      }
    />
  );
}

ApproveNewEquipmentPopup.propTypes = {
  left: number,
  className: string,
  position: oneOf(['top', 'bottom']),
};
