import React from 'react';
import * as R from 'ramda';
import { useMutation, useQuery } from '@apollo/client';
import { string, arrayOf, func, shape, objectOf, bool } from 'prop-types';
import { entityToOptionByLabelPath } from '@poly/client-utils';
import { FormCreator, Checkbox } from '@poly/admin-book';
import {
  useOnSubmitSetStopSubmitting,
  AsyncSearchSelect,
  useNotificationState,
  halfWidth,
} from '@poly/admin-ui';
import {
  NOTHING_UI_STRING,
  SupplierStatuses,
  SupplierSources,
  SupplierTypes,
} from '@poly/constants';

import {
  SUPPLIER_OPTION_QUERY,
  SUPPLIER_SERVICE_TYPES,
  SUPPLIERS_BY_SEARCH_FOR_OPTIONS,
} from '../../../modules/core/hooks/suppliers/queries.js';
import { ADD_PROPERTY_SUPPLIER_MUTATION } from '../../../modules/core/hooks/properties/mutations.js';
import { getSuppliersQuery } from '../../../modules/core/searchQueries/suppliers.js';

export const propertySupplierFormId = 'property_supplier_form_id';

// getCheckedServices :: Object => [ServiceId]
const getCheckedServices = R.compose(R.keys, R.filter(R.identity));

function SupplierSelect({
  propertySupplierIds,
  changeFieldValue,
  onChange,
  ...props
}) {
  const searchSuppliersProps = {
    size: 50,
    valueEndpointName: 'supplier',
    placeholder: 'Search Suppliers',
    gqlValueQuery: SUPPLIER_OPTION_QUERY,
    optionsEndpointName: 'searchSuppliers',
    gqlOptionsQuery: SUPPLIERS_BY_SEARCH_FOR_OPTIONS,
    hitToOption: entityToOptionByLabelPath(['company', 'name']),
    searchQuery: getSuppliersQuery(SupplierSources.AAC, [
      SupplierTypes.ADMINISTRATIVE,
      SupplierTypes.SUBCONTRACTOR,
    ])(SupplierStatuses.ACTIVE, propertySupplierIds),
    onChange: (e) => {
      changeFieldValue('services', {});
      onChange(e);
    },
    ...props,
  };

  return <AsyncSearchSelect {...searchSuppliersProps} />;
}

SupplierSelect.propTypes = {
  onChange: func.isRequired,
  changeFieldValue: func.isRequired,
  propertySupplierIds: arrayOf(string),
};

function ServicesCheckboxGroup({ formData, value, onChange }) {
  const { supplierId } = formData;

  const { data, loading } = useQuery(SUPPLIER_SERVICE_TYPES, {
    variables: { id: supplierId },
    skip: !supplierId,
  });

  const services = R.pathOr([], ['supplier', 'company', 'services'], data);

  if (loading || R.isEmpty(services)) return NOTHING_UI_STRING;

  return services.map(({ _id, name }) => {
    const itemName = `services.${_id}`;
    const itemValue = value[_id] || false;
    const onItemChange = (val) => onChange({ ...value, [_id]: val });

    return (
      <Checkbox
        key={_id}
        label={name}
        name={itemName}
        value={itemValue}
        onChange={onItemChange}
      />
    );
  });
}

ServicesCheckboxGroup.propTypes = {
  onChange: func.isRequired,
  value: objectOf(bool).isRequired,
  formData: shape({ supplierId: string }).isRequired,
};

const propertySupplierFormSections = (propertySupplierIds) => [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        order: 1,
        layout: { row: 1, width: halfWidth },
        label: 'Supplier',
        field: {
          name: 'supplierId',
          withChangeFieldValue: true,
          Component: (props) => (
            <SupplierSelect {...{ propertySupplierIds, ...props }} />
          ),
        },
        validators: [[R.identity, 'Supplier is required']],
      },
      {
        order: 1,
        layout: { row: 2, width: halfWidth },
        label: 'Services',
        field: {
          name: 'services',
          withFormData: true,
          Component: ServicesCheckboxGroup,
        },
      },
    ],
  },
];

export function PropertySupplierForm({
  onCancel,
  propertyId,
  propertySupplierIds,
}) {
  const { showSuccessNotification } = useNotificationState();
  const [addPropertySupplier] = useMutation(ADD_PROPERTY_SUPPLIER_MUTATION);

  const onSubmitHandler = async ({ services, supplierId }) => {
    await addPropertySupplier({
      variables: {
        input: {
          supplierId,
          propertyId,
          serviceTypesIds: getCheckedServices(services),
        },
      },
    });
    showSuccessNotification('Supplier was successfully added');
    onCancel();
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    propertySupplierFormId,
    onSubmitHandler,
  );

  const initialValues = { services: {} };

  return (
    <FormCreator
      id={propertySupplierFormId}
      layout={{ card: false }}
      initialValues={initialValues}
      sections={propertySupplierFormSections(propertySupplierIds)}
      onSubmit={onSubmit}
    />
  );
}

PropertySupplierForm.propTypes = {
  onCancel: func.isRequired,
  propertyId: string.isRequired,
  propertySupplierIds: arrayOf(string),
};
