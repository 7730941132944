import { useMemo } from 'react';
import * as R from 'ramda';
import { useMapConfigToTableProps, useTableSorting } from '@poly/admin-ui';
import { ASC_SORT_ORDER } from '@poly/constants';
import {
  useTableInfiniteScrollQuery,
  keywordSortQuery,
} from '@poly/client-utils';
import { assocBy, isNilOrEmpty } from '@poly/utils';

import { useSelector } from 'react-redux';
import { getSearchSuppliersTableConfig } from './supplierSearchTableUtils.js';
import { SUPPLIERS_BY_SEARCH_TABLE } from '../../core/hooks/suppliers/queries.js';
import {
  convertToCoordinates,
  distanceBetweenLocations,
  getDistanceSortQuery,
} from '../../forms/assignSupplierForm/utils.js';

// getSuppliersWithDistance :: (Coordinates -> QueryData) -> Supplier
// Coordinates = {lat: Number, lon: Number}
const getSuppliersWithDistance = (coordinates, queryData) =>
  R.compose(
    R.when(
      R.compose(R.complement(isNilOrEmpty), R.always(coordinates)),
      R.map(
        assocBy(
          'distance',
          R.compose(
            distanceBetweenLocations(coordinates),
            convertToCoordinates,
            R.path(['company', 'address', 'geo', 'coordinates']),
          ),
        ),
      ),
    ),
    R.pathOr([], ['searchSuppliers', 'hits']),
  )(queryData);

// getSupplierSearchSortQuery :: Coordinates -> [QueryObject]
const getSupplierSearchSortQuery = (coordinates) =>
  R.compose(
    R.when(
      R.compose(R.complement(isNilOrEmpty), R.always(coordinates)),
      R.prepend(getDistanceSortQuery(coordinates)),
    ),
    keywordSortQuery(['company', 'name']),
  )(ASC_SORT_ORDER);

export const useSearchSuppliers = ({ geocodeAddress, query }) => {
  const searchTerm = useSelector((state) => state.searchText);

  const tableConfig = getSearchSuppliersTableConfig(geocodeAddress);

  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig,
    defaultSort: getSupplierSearchSortQuery(geocodeAddress),
  });

  const input = useMemo(
    () => ({
      query,
      searchTerm,
      sort,
    }),
    [query, searchTerm, sort],
  );

  const { data, loading, tableProps } = useTableInfiniteScrollQuery(
    SUPPLIERS_BY_SEARCH_TABLE,
    input,
    {
      pageSize: 50,
      endpointName: 'searchSuppliers',
      inputName: 'searchInput',
      skip: R.isEmpty(query),
    },
  );

  const counts = R.pathOr(0, ['searchSuppliers', 'total'], data);
  const suppliers = getSuppliersWithDistance(geocodeAddress, data);

  const dataProps = useMapConfigToTableProps(
    R.identity,
    tableConfig,
    suppliers,
  );

  return {
    loading,
    counts,
    suppliers,
    ...dataProps,
    ...tableProps,
    ...tableSortingProps,
  };
};
