// This is wild card card that allows ALL action and ALL targets
// Only users with ALL__ALL are able to access endpoints with this permission
// Covers all other permissions
// Note: We use "__" to split action and target
export const FULL_ACCESS_PERMISSION = 'ALL__ALL';

export const LOGIN_APP_PERMISSION = 'LOGIN__APP';
export const LOGIN_VIA_SMS_APP_PERMISSION = 'LOGIN_VIA_SMS__APP';

export const DISABLE_2FA_USER_PERMISSION = 'DISABLE_2FA__USER';

export const CREATE_CONTACT_USER_PERMISSION = 'CREATE__CONTACT_USER';
export const UPDATE_CONTACT_USER_PERMISSION = 'UPDATE__CONTACT_USER';
export const READ_CONTACT_USER_PERMISSION = 'READ__CONTACT_USER';

export const CREATE_USER_PERMISSION = 'CREATE__USER';
export const UPDATE_USER_PERMISSION = 'UPDATE__USER';
export const READ_SENSITIVE_FIELD_USER_PERMISSION =
  'READ_SENSITIVE_FIELD__USER';

export const READ_PROJECT_PERMISSION = 'READ__PROJECT';
export const CREATE_PROJECT_PERMISSION = 'CREATE__PROJECT';
export const UPDATE_PROJECT_PERMISSION = 'UPDATE__PROJECT';
export const CREATE_REQUEST_PROJECT_PERMISSION = 'CREATE__REQUEST_PROJECT';
export const BLOCK_INVOICED_PROJECT_PERMISSION = 'BLOCK_INVOICED__PROJECT';
export const BLOCK_COMPLETED_PROJECT_PERMISSION = 'BLOCK_COMPLETED__PROJECT';
export const MAKE_INVOICED_NON_BILLABLE_PROJECT_PERMISSION =
  'MAKE_INVOICED_NON_BILLABLE__PROJECT';

export const CREATE_RECURRING_PROJECT_PERMISSION = 'CREATE__RECURRING_PROJECT';
export const UPDATE_RECURRING_PROJECT_PERMISSION = 'UPDATE__RECURRING_PROJECT';
export const ESTIMATE_PROJECT_PERMISSION = 'ESTIMATE__PROJECT';
export const SUPPLIER_CHECK_IN_PROJECT_PERMISSION =
  'SUPPLIER_CHECK_IN__PROJECT';
export const CREATE_VIA_ASSET_SCANNER_PROJECT_PERMISSION =
  'CREATE_VIA_ASSET_SCANNER__PROJECT';
export const CREATE_ASSET_WORK_ORDER_PERMISSION = 'CREATE__ASSET_WORK_ORDER';

export const READ_PROPERTY_PERMISSION = 'READ__PROPERTY';
export const CREATE_PROPERTY_PERMISSION = 'CREATE__PROPERTY';
export const UPDATE_PROPERTY_PERMISSION = 'UPDATE__PROPERTY';
export const READ_REQUEST_APP_PROPERTY_PERMISSION =
  'READ__REQUEST_APP_PROPERTY';

export const CREATE_SUPPLIER_INVOICE_PERMISSION = 'CREATE__SUPPLIER_INVOICE';
export const UPDATE_SUPPLIER_INVOICE_PERMISSION = 'UPDATE__SUPPLIER_INVOICE';
export const APPROVE_SUPPLIER_INVOICE_PERMISSION = 'APPROVE__SUPPLIER_INVOICE';
export const REJECT_SUPPLIER_INVOICE_PERMISSION = 'REJECT__SUPPLIER_INVOICE';
export const DELETE_SUPPLIER_INVOICE_PERMISSION = 'DELETE__SUPPLIER_INVOICE';
export const PAY_SUPPLIER_INVOICE_PERMISSION = 'PAY__SUPPLIER_INVOICE';
export const CANCEL_REQUEST_INVOICE_PERMISSION = 'CANCEL_REQUEST__INVOICE';

export const READ_SUPPLIER_BATCH_PERMISSION = 'READ__SUPPLIER_BATCH';
export const MARK_AS_PRINTED_SUPPLIER_BATCH_PERMISSION =
  'MARK_AS_PRINTED__SUPPLIER_BATCH';

export const READ_OCR_INVOICE_PERMISSION = 'READ__OCR_INVOICE';
export const UPDATE_OCR_INVOICE_PERMISSION = 'UPDATE__OCR_INVOICE';
export const APPROVE_OCR_INVOICE_PERMISSION = 'APPROVE__OCR_INVOICE';

export const CREATE_RECURRING_OCR_INVOICE_PERMISSION =
  'CREATE__RECURRING_OCR_INVOICE';
export const UPDATE_RECURRING_OCR_INVOICE_PERMISSION =
  'UPDATE__RECURRING_OCR_INVOICE';

export const READ_BATCH_PERMISSION = 'READ__BATCH';
export const CREATE_BATCH_PERMISSION = 'CREATE__BATCH';
export const UPDATE_BATCH_PERMISSION = 'UPDATE__BATCH';

export const READ_RECURRING_INVOICE_PERMISSION = 'READ__RECURRING_INVOICE';

export const READ_SPEND_REPORT_PERMISSION = 'READ__SPEND_REPORT';
export const READ_QUOTE_DISCREPANCIES_PERMISSION = 'READ__QUOTE_DISCREPANCIES';
export const READ_SUPPLIER_TAX_REPORT_PERMISSION = 'READ__SUPPLIER_TAX_REPORT';
export const READ_SUPPLIER_SPEND_REPORT_PERMISSION =
  'READ__SUPPLIER_SPEND_REPORT';
export const CREATE_PROPERTY_HIERARCHY_PERMISSION =
  'CREATE__PROPERTY_HIERARCHY';
export const UPDATE_PROPERTY_HIERARCHY_PERMISSION =
  'UPDATE__PROPERTY_HIERARCHY';
export const UPDATE_PROPERTY_LOCATION_HIERARCHY_PERMISSION =
  'UPDATE__PROPERTY_LOCATION_HIERARCHY';

export const READ_SERVICE_TYPE_PERMISSION = 'READ__SERVICE_TYPE';
export const CREATE_SERVICE_TYPE = 'CREATE__SERVICE_TYPE';
export const UPDATE_SERVICE_TYPE = 'UPDATE__SERVICE_TYPE';

export const CREATE_MENTION_GROUP = 'CREATE__MENTION_GROUP';
export const UPDATE_MENTION_GROUP = 'UPDATE__MENTION_GROUP';

export const READ_PURCHASE_ORDER_PERMISSION = 'READ__PURCHASE_ORDER';
export const CREATE_PURCHASE_ORDER_PERMISSION = 'CREATE__PURCHASE_ORDER';
export const UPDATE_PURCHASE_ORDER_PERMISSION = 'UPDATE__PURCHASE_ORDER';

export const READ_PO_INVOICE_PERMISSION = 'READ__PO_INVOICE';
export const CREATE_PO_INVOICE_PERMISSION = 'CREATE__PO_INVOICE';
export const APPROVE_PO_INVOICE_PERMISSION = 'APPROVE__PO_INVOICE';
export const BLOCK_PO_INVOICE_PERMISSION = 'BLOCK__PO_INVOICE';
export const POST_PO_INVOICE_PERMISSION = 'POST__PO_INVOICE';

export const READ_SUPPLIER_PERMISSION = 'READ__SUPPLIER';
export const CREATE_SUPPLIER_PERMISSION = 'CREATE__SUPPLIER';
export const UPDATE_SUPPLIER_PERMISSION = 'UPDATE__SUPPLIER';

// We should use it for both Supplier and Master Supplier
export const UPDATE_FINANCIAL_SECTION_SUPPLIER_PERMISSION =
  'UPDATE_FINANCIAL_SECTION__SUPPLIER';
export const UPDATE_REMIT_SECTION_SUPPLIER_PERMISSION =
  'UPDATE_REMIT_SECTION__SUPPLIER';
export const UPDATE_TAX_SECTION_SUPPLIER_PERMISSION =
  'UPDATE_TAX_SECTION__SUPPLIER';
export const UPDATE_BANK_SECTION_SUPPLIER_PERMISSION =
  'UPDATE_BANK_SECTION__SUPPLIER';
export const UPDATE_DOCUMENTS_SECTION_SUPPLIER_PERMISSION =
  'UPDATE_DOCUMENTS_SECTION__SUPPLIER';
export const READ_PRIVATE_TAX_FIELD_SUPPLIER_PERMISSION =
  'READ_PRIVATE_TAX_FIELD__SUPPLIER';
export const READ_PRIVATE_BANK_FIELD_SUPPLIER_PERMISSION =
  'READ_PRIVATE_BANK_FIELD__SUPPLIER';

export const READ_FINANCIAL_DIRECTORY_PERMISSION = 'READ__FINANCIAL_DIRECTORY';

export const CREATE_TIMESHEET_PERMISSION = 'CREATE__TIMESHEET';
export const UPDATE_TIMESHEET_PERMISSION = 'UPDATE__TIMESHEET';
export const DELETE_TIMESHEET_PERMISSION = 'DELETE__TIMESHEET';
export const READ_TIMESHEET_REPORT_PERMISSION = 'READ__TIMESHEET_REPORT';

export const READ_ASSET_PERMISSION = 'READ__ASSET';
export const CREATE_ASSET_PERMISSION = 'CREATE__ASSET';
export const UPDATE_ASSET_PERMISSION = 'UPDATE__ASSET';

export const READ_ASSET_MANUFACTURER_PERMISSION = 'READ__ASSET_MANUFACTURER';
export const CREATE_ASSET_MANUFACTURER_PERMISSION =
  'CREATE__ASSET_MANUFACTURER';
export const UPDATE_ASSET_MANUFACTURER_PERMISSION =
  'UPDATE__ASSET_MANUFACTURER';

export const READ_ASSET_TYPE_PERMISSION = 'READ__ASSET_TYPE';
export const CREATE_ASSET_TYPE_PERMISSION = 'CREATE__ASSET_TYPE';
export const UPDATE_ASSET_TYPE_PERMISSION = 'UPDATE__ASSET_TYPE';

export const READ_CLIENT_PERMISSION = 'READ__CLIENT';

export const READ_INVOICE_PERMISSION = 'READ__INVOICE';
export const ATTACH_INVOICE_PERMISSION = 'ATTACH__INVOICE';
export const CREATE_INVOICE_PERMISSION = 'CREATE__INVOICE';
export const UPDATE_INVOICE_PERMISSION = 'UPDATE__INVOICE';
export const VOID_INVOICE_PERMISSION = 'VOID__INVOICE';
export const UPDATE_DUE_DATE_INVOICE_PERMISSION = 'UPDATE_DUE_DATE__INVOICE';

export const READ_MAINTENANCE_PLAN_PERMISSION = 'READ__MAINTENANCE_PLAN';
export const CREATE_MAINTENANCE_PLAN_PERMISSION = 'CREATE__MAINTENANCE_PLAN';
export const UPDATE_MAINTENANCE_PLAN_PERMISSION = 'UPDATE__MAINTENANCE_PLAN';

export const READ_MENTION_GROUP_PERMISSION = 'READ__MENTION_GROUP';

export const READ_USER_PERMISSION = 'READ__USER';

export const READ_SUPPLIER_CHECK_PERMISSION = 'READ__SUPPLIER_CHECK';
export const PRINT_SUPPLIER_CHECK_PERMISSION = 'PRINT__SUPPLIER_CHECK';

export const READ_TASK_PERMISSION = 'READ__TASK';
export const CREATE_TASK_PERMISSION = 'CREATE__TASK';
export const UPDATE_TASK_PERMISSION = 'UPDATE__TASK';
export const DELETE_SUPPLIER_INVOICE_TASK_PERMISSION =
  'DELETE__SUPPLIER_INVOICE_TASK';

export const READ_UPDATE_PERMISSION = 'READ__UPDATE';
export const CREATE_UPDATE_PERMISSION = 'CREATE__UPDATE';

export const READ_FILE_PERMISSION = 'READ__FILE';
export const CREATE_FILE_PERMISSION = 'CREATE__FILE';
export const UPDATE_FILE_PERMISSION = 'UPDATE__FILE';
export const DELETE_FILE_PERMISSION = 'DELETE__FILE';

export const READ_ACCOUNT_TYPE_PERMISSION = 'READ__ACCOUNT_TYPE';
export const CREATE_ACCOUNT_TYPE_PERMISSION = 'CREATE__ACCOUNT_TYPE';
export const UPDATE_ACCOUNT_TYPE_PERMISSION = 'UPDATE__ACCOUNT_TYPE';

export const READ_DIVISION_PERMISSION = 'READ__DIVISION';
export const CREATE_DIVISION_PERMISSION = 'CREATE__DIVISION';
export const UPDATE_DIVISION_PERMISSION = 'UPDATE__DIVISION';

export const READ_TIME_LOG_PERMISSION = 'READ__TIME_LOG';
export const CREATE_TIME_LOG_PERMISSION = 'CREATE__TIME_LOG';
export const UPDATE_TIME_LOG_PERMISSION = 'UPDATE__TIME_LOG';
export const DELETE_TIME_LOG_PERMISSION = 'DELETE__TIME_LOG';

export const CREATE_MATERIAL_SPEND_PERMISSION = 'CREATE__MATERIAL_SPEND';
export const UPDATE_MATERIAL_SPEND_PERMISSION = 'UPDATE__MATERIAL_SPEND';
export const DELETE_MATERIAL_SPEND_PERMISSION = 'DELETE__MATERIAL_SPEND';

export const READ_ACCOUNT_PERMISSION = 'READ__ACCOUNT';
export const CREATE_ACCOUNT_PERMISSION = 'CREATE__ACCOUNT';
export const UPDATE_ACCOUNT_PERMISSION = 'UPDATE__ACCOUNT';

export const VOID_SUPPLIER_PAYMENT_PERMISSION = 'VOID__SUPPLIER_PAYMENT';
export const READ_JOURNAL_PAYMENT_BATCH_PERMISSION =
  'READ__JOURNAL_PAYMENT_BATCH';
export const READ_REGISTER_TRANSACTION_PERMISSION =
  'READ__REGISTER_TRANSACTION';
export const READ_SUPPLIER_PAYMENT_HISTORY_PERMISSION =
  'READ__SUPPLIER_PAYMENT_HISTORY';

export const CREATE_JOURNAL_PERMISSION = 'CREATE__JOURNAL';
export const UPDATE_JOURNAL_PERMISSION = 'UPDATE__JOURNAL';
export const RECONCILE_JOURNAL_PERMISSION = 'RECONCILE__JOURNAL';
export const READ_RECONCILIATION_STATEMENT_PERMISSION =
  'READ__RECONCILIATION_STATEMENT';
export const READ_TRANSACTION_PERMISSION = 'READ__TRANSACTION';

export const READ_RECURRING_JOURNAL_PERMISSION = 'READ__RECURRING_JOURNAL';
export const CREATE_RECURRING_JOURNAL_PERMISSION = 'CREATE__RECURRING_JOURNAL';
export const UPDATE_RECURRING_JOURNAL_PERMISSION = 'UPDATE__RECURRING_JOURNAL';

export const READ_GENERAL_LEDGER_PERMISSION = 'READ__GENERAL_LEDGER';
export const READ_PROFIT_LOSS_PERMISSION = 'READ__PROFIT_LOSS';
export const TRANSFER_FUND_PERMISSION = 'TRANSFER__FUND';
export const READ_TRIAL_BALANCE_PERMISSION = 'READ__TRIAL_BALANCE';
export const INPUT_PAYROLL_PERMISSION = 'INPUT__PAYROLL';

export const READ_CLIENT_BATCH_PERMISSION = 'READ__CLIENT_BATCH';
export const CREATE_CLIENT_BATCH_PERMISSION = 'CREATE__CLIENT_BATCH';
export const READ_CLIENT_INVOICE_PERMISSION = 'READ__CLIENT_INVOICE';
export const EDIT_CLIENT_INVOICE_PERMISSION = 'EDIT__CLIENT_INVOICE';
export const VOID_CLIENT_INVOICE_PERMISSION = 'VOID__CLIENT_INVOICE';
export const PRINT_CLIENT_INVOICE_PERMISSION = 'PRINT__CLIENT_INVOICE';
export const PAY_CLIENT_INVOICE_PERMISSION = 'PAY__CLIENT_INVOICE';
export const READ_CLIENT_HISTORY_PERMISSION = 'READ__CLIENT_HISTORY';
export const CREATE_CLIENT_INVOICE_PERMISSION = 'CREATE__CLIENT_INVOICE';
export const WRITEOFF_CLIENT_INVOICE_PERMISSION = 'WRITEOFF__CLIENT_INVOICE';

export const CREATE_CLIENT_PERMISSION = 'CREATE__CLIENT';
export const UPDATE_CLIENT_PERMISSION = 'UPDATE__CLIENT';

export const READ_ACCOUNTS_RECEIVABLE_REPORT_PERMISSION =
  'READ__ACCOUNTS_RECEIVABLE_REPORT';
export const READ_ACCOUNTS_PAYABLE_REPORT_PERMISSION =
  'READ__ACCOUNTS_PAYABLE_REPORT';
export const READ_AP_AGING_REPORT_PERMISSION = 'READ__AP_AGING_REPORT';
export const READ_AR_AGING_REPORT_PERMISSION = 'READ__AR_AGING_REPORT';
export const READ_WIP_REPORT_PERMISSION = 'READ__WIP_REPORT';
export const READ_BALANCE_SHEET_PERMISSION = 'READ__BALANCE_SHEET';
export const READ_SALES_REPORT_PERMISSION = 'READ__SALES_REPORT';

export const READ_CREDIT_CARD_USER_PERMISSION = 'READ__CREDIT_CARD_USER';
export const CREATE_CREDIT_CARD_USER_PERMISSION = 'CREATE__CREDIT_CARD_USER';
export const UPDATE_CREDIT_CARD_USER_PERMISSION = 'UPDATE__CREDIT_CARD_USER';

export const PAY_BY_BANK_PERMISSION = 'PAY__BY_BANK';

export const UPDATE_SERVICE_TICKET_PERMISSION = 'UPDATE__SERVICE_TICKET';

export const READ_RECONCILIATION_REPORT_PERMISSION =
  'READ__RECONCILIATION_REPORT';
export const READ_STAFF_REPORT_PERMISSION = 'READ__STAFF_REPORT';

export const READ_MASTER_SUPPLIER_PERMISSION = 'READ__MASTER_SUPPLIER';
export const CREATE_MASTER_SUPPLIER_PERMISSION = 'CREATE__MASTER_SUPPLIER';
export const UPDATE_MASTER_SUPPLIER_PERMISSION = 'UPDATE__MASTER_SUPPLIER';

export const READ_ACCOUNTING_PERIOD_PERMISSION = 'READ__ACCOUNTING_PERIOD';
export const CREATE_ACCOUNTING_PERIOD_PERMISSION = 'CREATE__ACCOUNTING_PERIOD';
export const UPDATE_ACCOUNTING_PERIOD_PERMISSION = 'UPDATE__ACCOUNTING_PERIOD';

export const READ_ADMIN_ACCOUNT_INDEX_PERMISSION = 'READ__ADMIN_ACCOUNT_INDEX';

export const READ_SUPPLIERS_AAC_1099_REPORT_PERMISSION =
  'READ__SUPPLIERS_AAC_1099_REPORT';

export const UPDATE_CLIENT_GL_CODE_PERMISSION = 'UPDATE__CLIENT_GL_CODE';

export const READ_BILLING_PROFILE_PERMISSION = 'READ__BILLING_PROFILE';
export const CREATE_BILLING_PROFILE_PERMISSION = 'CREATE__BILLING_PROFILE';
export const UPDATE_BILLING_PROFILE_PERMISSION = 'UPDATE__BILLING_PROFILE';

export const READ_REPORT_ONLY_CLIENT_INVOICE_PERMISSION =
  'READ__REPORT_ONLY_CLIENT_INVOICE';
export const CREATE_REPORT_ONLY_CLIENT_INVOICE_PERMISSION =
  'CREATE__REPORT_ONLY_CLIENT_INVOICE';
export const EDIT_REPORT_ONLY_CLIENT_INVOICE_PERMISSION =
  'EDIT__REPORT_ONLY_CLIENT_INVOICE';
export const VOID_REPORT_ONLY_CLIENT_INVOICE_PERMISSION =
  'VOID__REPORT_ONLY_CLIENT_INVOICE';

export const SUBMIT_REQUESTED_SUPPLIER_DOCUMENT_PERMISSION =
  'SUBMIT__REQUESTED_SUPPLIER_DOCUMENT';

export const READ_REQUESTED_SUPPLIER_DOCUMENT_PERMISSION =
  'READ__REQUESTED_SUPPLIER_DOCUMENT';

export const READ_INCOME_STATEMENT_PERMISSION =
  'READ__ACCOUNTING_INCOME_STATEMENT';

export const READ_ASSET_REACTIVE_SPEND_REPORT_PERMISSION =
  'READ__ASSET_REACTIVE_SPEND_REPORT';

export const READ_CLIENT_PAYMENT_PERMISSION = 'READ__CLIENT_PAYMENT';
export const EDIT_CLIENT_PAYMENT_PERMISSION = 'EDIT__CLIENT_PAYMENT';

export const RECEIVE_ALERT_PERMISSION = 'RECEIVE__ALERT';

export const READ_ADMIN_PURCHASE_ORDER_PERMISSION =
  'READ__ADMIN_PURCHASE_ORDER';
export const CREATE_ADMIN_PURCHASE_ORDER_PERMISSION =
  'CREATE__ADMIN_PURCHASE_ORDER';
export const EDIT_ADMIN_PURCHASE_ORDER_PERMISSION =
  'EDIT__ADMIN_PURCHASE_ORDER';

export const READ_USER_GROUP_PERMISSION = 'READ__USER_GROUP';
export const CREATE_USER_GROUP_PERMISSION = 'CREATE__USER_GROUP';
export const UPDATE_USER_GROUP_PERMISSION = 'UPDATE__USER_GROUP';
export const ASSIGN_USER_GROUP_PERMISSION = 'ASSIGN__USER_GROUP';
export const DELETE_USER_GROUP_PERMISSION = 'DELETE__USER_GROUP';

export const SUBMIT_REQUESTER_SURVEY_PERMISSION = 'SUBMIT__REQUESTER_SURVEY';

export const READ_REPORT_SENDING_CONFIGURATION_PERMISSION =
  'READ__REPORT_SENDING_CONFIGURATION';
export const SUBMIT_REPORT_SENDING_CONFIGURATION_PERMISSION =
  'SUBMIT__REPORT_SENDING_CONFIGURATION';
export const READ_PROCEDURE_PERMISSION = 'READ__PROCEDURE';
export const CREATE_PROCEDURE_PERMISSION = 'CREATE__PROCEDURE';
export const UPDATE_PROCEDURE_PERMISSION = 'UPDATE__PROCEDURE';
export const DELETE_PROCEDURE_PERMISSION = 'DELETE__PROCEDURE';

export const USE_TASK_TEMPLATE_PERMISSION = 'USE__TASK_TEMPLATE';

export const PRINT_WOC_EMAIL_ASSETS_PERMISSION = 'PRINT__WOC_EMAIL_ASSETS';
// used only for complete `Confirm Satisfaction` by email
// https://gitlab.com/askpoly/poly-apps/-/issues/5441
export const CONFIRM_SATISFACTION_PERMISSION = 'CONFIRM__SATISFACTION';

export const UPDATE_POSTING_DATE_JOURNAL_PERMISSION =
  'UPDATE_POSTING_DATE__JOURNAL';

export const SEND_CONFIRMATION_PROJECT_PERMISSION =
  'SEND_CONFIRMATION__PROJECT';

export const READ_SUB_CATEGORY_REPORT_PERMISSION = 'READ__SUB_CATEGORY_REPORT';
