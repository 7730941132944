import React from 'react';
import { Button, Text } from '@poly/admin-book';
import { func, string, arrayOf } from 'prop-types';

import { CancelBtn } from '@poly/admin-ui';
import {
  FormS,
  ButtonsWrapper,
  FieldsWrapper,
  FieldLabel,
  FieldWrapper,
} from './styles.js';
import { PropertySuppliersSelectField } from './fields/PropertySuppliersSelect.js';
import { SupplierServicesCheckboxGroup } from './fields/SupplierServicesCheckboxGroup.js';
import { FlexContainer } from '../../../../components/FlexContainer.js';
import { useFormButtonProps } from '../../../core/hooks/users/form.js';

export const propertySuppliersFormId = 'propertySuppliersForm';

function SubmitButton(props) {
  const formProps = useFormButtonProps(propertySuppliersFormId);
  return <Button {...props} {...formProps} />;
}

function CancelButton(props) {
  const formProps = useFormButtonProps(propertySuppliersFormId, false);
  return <CancelBtn {...props} {...formProps} />;
}

export function PropertySupplierFormComp({
  handleSubmit,
  closeCurrentModal,
  id,
  propertySupplierIds,
}) {
  return (
    <FormS onSubmit={handleSubmit} id={id}>
      <Text size="21px">Add Supplier</Text>
      <FieldsWrapper>
        <FieldWrapper>
          <FieldLabel>Add Supplier</FieldLabel>
          <PropertySuppliersSelectField
            propertySupplierIds={propertySupplierIds}
          />
        </FieldWrapper>
        <FieldWrapper>
          <FieldLabel>Select Services</FieldLabel>
          <FlexContainer>
            <SupplierServicesCheckboxGroup />
          </FlexContainer>
        </FieldWrapper>
      </FieldsWrapper>
      <ButtonsWrapper>
        <CancelButton onClick={closeCurrentModal} />
        <SubmitButton size="small">Add Supplier</SubmitButton>
      </ButtonsWrapper>
    </FormS>
  );
}

PropertySupplierFormComp.propTypes = {
  handleSubmit: func.isRequired,
  closeCurrentModal: func.isRequired,
  id: string.isRequired,
  propertySupplierIds: arrayOf(string),
};
