import * as R from 'ramda';
import styled from 'styled-components';
import { isNilOrEmpty } from '@poly/utils';
import React, { useEffect, useState } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { getThemeColor, Icon, Input } from '@poly/admin-book';

import { getAssetDocumentNamesString } from './helpers.js';
import { useMergeAssetComponentsConfirmModal } from './useMergeAssetComponentsConfirmModal.js';

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FormTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  padding: 0 0 15px 0;
  color: ${getThemeColor(['darkest'])};
`;

const FormText = styled.div`
  font-weight: 500;
  line-height: 18px;
  font-size: 12px;
  word-break: normal;
  padding: ${({ padding }) => padding || '0'};
  color: ${getThemeColor(['darkest'])};
`;

const SuggestedItemS = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 4px 12px;
  background-color: ${getThemeColor(['accent2Lighter4'])};
  margin-bottom: 8px;
  font-size: 10px;
  line-height: 14px;
  color: ${getThemeColor(['primaryLight'])};
  cursor: pointer;

  > svg {
    fill: ${getThemeColor(['midDark'])};
  }

  :focus,
  :hover {
    > svg {
      fill: ${getThemeColor(['primaryMid'])};
    }
  }
`;

const NewItemSelectWrapperS = styled.div`
  display: flex;
  width: 100%;
  position: relative;

  input {
    padding-right: 25px;
  }

  > svg {
    fill: ${getThemeColor(['midDark'])};
    position: absolute;
    right: 8px;
    top: 8px;
    width: 16px;
    padding: 4px;
    cursor: pointer;

    :focus,
    :hover {
      fill: ${getThemeColor(['primaryMid'])};
    }
  }
`;

function CheckIcon(props) {
  return <Icon {...props} size={8} name="checkMark" />;
}

// getSelectedDocuments :: [ID] -> [MergeDocument] -> [MergeDocument]
// MergeDocument = AssetManufacturer|AssetModel
const getSelectedDocuments = (selectedRows) =>
  R.filter(R.propSatisfies(R.includes(R.__, selectedRows), '_id'));

// prepareFirstParagraph :: [MergeDocument] -> String
const prepareFirstParagraph = R.compose(
  R.concat(R.__, ' to be merged.'),
  R.concat('You selected '),
  getAssetDocumentNamesString,
);

// isNewNameUsed :: (String, [ID]) -> [Object] -> Boolean
const isNewNameUsed = (newName, selectedRows) =>
  R.compose(
    R.includes(newName),
    R.map(R.prop('name')),
    R.reject(R.propSatisfies(R.includes(R.__, selectedRows), '_id')),
    R.defaultTo([]),
  );

export function MergeAssetComponentsModalForm({
  documents,
  selectedRows,
  setSelectedRows,
  mergeAssetConfig,
}) {
  const [inputError, setInputError] = useState(null);
  const [newItemValue, setNewItemValue] = useState('');

  const { formTitle, inputValidationError } = mergeAssetConfig;

  const confirmMergeModal = useMergeAssetComponentsConfirmModal(
    mergeAssetConfig,
    setSelectedRows,
  );

  const selectedDocuments = getSelectedDocuments(selectedRows)(documents);

  const firstParagraph = prepareFirstParagraph(selectedDocuments);

  const onSelect = ({ selectedName, ...otherProps }) => {
    if (inputError) {
      return;
    }

    if (isNilOrEmpty(selectedName)) {
      setInputError(inputValidationError);
      return;
    }

    setInputError(null);
    confirmMergeModal({
      selectedName,
      selectedDocuments,
      ...otherProps,
    });
  };

  useEffect(() => {
    if (isNewNameUsed(newItemValue, selectedRows)(documents)) {
      setInputError('This name is used already');
      return;
    }

    setInputError(null);
  }, [selectedRows, newItemValue]);

  return (
    <FormWrapper>
      <FormTitle>{formTitle}</FormTitle>
      <FormText>{firstParagraph}</FormText>
      <FormText padding="0 0 10px 0">
        Select the name under which selected items will be merged.
      </FormText>
      {selectedDocuments.map(({ _id, name, ...props }) => (
        <SuggestedItemS
          key={_id}
          onClick={() => onSelect({ selectedName: name, ...props })}
        >
          {name}
          <CheckIcon />
        </SuggestedItemS>
      ))}
      <FormText padding="0 0 10px 0">
        or enter your custom name, in case none of the above is suitable
      </FormText>
      <NewItemSelectWrapperS>
        <Input
          required
          width="100%"
          error={inputError}
          value={newItemValue}
          onChange={(e) => setNewItemValue(e.target.value)}
          name="merged-asset-component-input"
        />
        <CheckIcon onClick={() => onSelect({ selectedName: newItemValue })} />
      </NewItemSelectWrapperS>
    </FormWrapper>
  );
}

MergeAssetComponentsModalForm.propTypes = {
  selectedRows: arrayOf(string),
  setSelectedRows: func,
  mergeAssetConfig: shape({}),
  documents: arrayOf(
    shape({
      _id: string,
      name: string,
    }),
  ),
};
