import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';

const getHierarchiesFromResult = R.pathOr([], ['propertyHierarchies']);

export const clientHierarchiesQuery = gql`
  query clientHierarchiesQuery($input: PropertyHierarchiesInput!) {
    propertyHierarchies(input: $input) {
      _id
      name
      client {
        _id
      }
      nodes {
        id
      }
    }
  }
`;

// formatHierarchiesOptions :: [PropertyHierarchy] -> [DropdownOption]
const formatHierarchiesOptions = R.map(
  R.applySpec({
    value: R.prop('_id'),
    label: R.prop('name'),
    clientId: R.path(['client', '_id']),
  }),
);

// removeEmptyHierarchies :: [PropertyHierarchy] -> [PropertyHierarchy]
const removeEmptyHierarchies = R.reject(
  R.compose(R.either(R.isEmpty, R.isNil), R.prop('nodes')),
);

export const useHierarchyOptions = (
  clientId,
  removeEmpty = false,
  propertyIds = null,
) => {
  const { data, loading, refetch } = useQuery(clientHierarchiesQuery, {
    variables: { input: { clientId, ...(propertyIds ? { propertyIds } : {}) } },
    skip: !clientId,
  });

  let hits = getHierarchiesFromResult(data);
  if (removeEmpty) {
    hits = removeEmptyHierarchies(hits);
  }

  const options = formatHierarchiesOptions(hits);
  return { options, loading, refetch };
};
