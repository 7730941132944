import { useHasUserAccessWithPermission } from '@poly/client-utils/src/hooks/useHasUserAccessWithPermission.js';
import {
  CREATE_ADMIN_PURCHASE_ORDER_PERMISSION,
  EDIT_ADMIN_PURCHASE_ORDER_PERMISSION,
  READ_ADMIN_PURCHASE_ORDER_PERMISSION,
} from '@poly/security/src/permissions.js';

export const useHasUserAccessToReadAdminPO = () => {
  const hasAccess = useHasUserAccessWithPermission(
    READ_ADMIN_PURCHASE_ORDER_PERMISSION,
  );

  return hasAccess;
};

export const useHasUserAccessToEditAdminPO = () => {
  const hasAccess = useHasUserAccessWithPermission(
    EDIT_ADMIN_PURCHASE_ORDER_PERMISSION,
  );

  return hasAccess;
};

export const useHasUserAccessToCreateAdminPO = () => {
  const hasAccess = useHasUserAccessWithPermission(
    CREATE_ADMIN_PURCHASE_ORDER_PERMISSION,
  );

  return hasAccess;
};
