export const checksSearchFilters = {
  bankAccountId: 'bankAccountId',
  checkNumber: 'checkNumber',
  isPrinted: 'isPrinted',
  paymentDateFrom: 'paymentDateFrom',
  paymentDateTo: 'paymentDateTo',
  checkType: 'checkType',
};

export const defaultSearchFilters = {
  [checksSearchFilters.paymentDateFrom]: '',
  [checksSearchFilters.paymentDateTo]: '',
  [checksSearchFilters.checkNumber]: null,
  [checksSearchFilters.isPrinted]: false,
};
