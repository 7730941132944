import React, { useEffect, useRef } from 'react';
import { number, func } from 'prop-types';

import {
  Dots,
  PageItem,
  ControlBtn,
  PageSelectContainer,
} from './components.js';
import { generateAvailablePagesConfig } from './utils.js';

export function PageSelect({ pagesCount, currentPage, onChange }) {
  const prevProps = useRef({ pagesCount, currentPage });

  useEffect(() => {
    if (
      prevProps.current.pagesCount !== pagesCount &&
      prevProps.current.currentPage > pagesCount &&
      pagesCount !== 0
    ) {
      onChange(pagesCount);
    }
    prevProps.current = { pagesCount, currentPage };
  }, [pagesCount, currentPage]);

  const availablePagesConfig = generateAvailablePagesConfig({
    currentPage,
    pagesCount,
  });

  return (
    <PageSelectContainer>
      <ControlBtn
        icon="arrow-back"
        onClick={() => onChange(currentPage - 1)}
        disabled={currentPage <= 1}
      />
      {availablePagesConfig.map((page, index) => {
        const dotsKey =
          index < availablePagesConfig.length / 2 ? 'previous' : 'next';

        return page === '..' ? (
          <Dots key={dotsKey}>...</Dots>
        ) : (
          <PageItem
            key={page}
            onChange={onChange}
            currentPage={currentPage}
            val={page}
            isLast={index + 1 === availablePagesConfig.length}
          />
        );
      })}
      <ControlBtn
        icon="arrow-back"
        onClick={() => onChange(currentPage + 1)}
        disabled={pagesCount <= 1 || currentPage === pagesCount}
        style={{ transform: 'rotate(180deg)' }}
      />
    </PageSelectContainer>
  );
}

PageSelect.propTypes = {
  pagesCount: number.isRequired,
  currentPage: number.isRequired,
  onChange: func.isRequired,
};
