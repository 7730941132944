import { ProjectType, WorkOrderStatus } from './constants.js';

export const RecurringProjectStatusesText = {
  ACTIVE: 'Active',
  CLOSED: 'Closed',
  EXPIRED: 'Expired',
};

export const RecurringProjectStatuses = {
  ACTIVE: 'active',
  CLOSED: 'closed',
};

export const ChildRecurringProjectStatuses = [
  WorkOrderStatus.ACTIVE,
  WorkOrderStatus.BLOCKED,
  WorkOrderStatus.COMPLETED,
];

export const ChildType = {
  TM: ProjectType.WORK_ORDER,
  REPORT_ONLY: ProjectType.REPORT_ONLY,
  PASS_THROUGH: ProjectType.PASS_THROUGH,
  FEE: ProjectType.FEE,
  BID: ProjectType.BID,
  HOUSEKEEPING: ProjectType.HOUSEKEEPING,
};

const commonTmProjectChildPayloadFields = [
  'assetIds',
  'contractLink',
  'invoiceDescription',
  'siteContactId',
  'suppliers',
];

export const TM_PROJECT_CHILD_PAYLOAD_FIELDS = [
  ...commonTmProjectChildPayloadFields,
  'nte',
  'technicians',
];

export const BID_PROJECT_CHILD_PAYLOAD_FIELDS = [
  ...commonTmProjectChildPayloadFields,
  'bidAmount',
];

export const REPORT_ONLY_PROJECT_CHILD_PAYLOAD_FIELDS =
  TM_PROJECT_CHILD_PAYLOAD_FIELDS;

export const FEE_PROJECT_CHILD_PAYLOAD_FIELDS = [
  'invoiceDescription',
  'feeAmount',
  'feeType',
  'customFeeDescription',
  'siteContactId',
];

export const HOUSEKEEPING_PROJECT_CHILD_PAYLOAD_FIELDS = [
  'invoiceDescription',
  'suppliers',
  'siteContactId',
  'contractLink',
  'clientMonthlyFee',
  'clientHourlyRate',
  'supplierMonthlyFee',
  'supplierHourlyRate',
  'weeklyPorterHours',
];

export const RWOTaskListType = {
  LONG_LIST: 'longList',
  SHORT_LIST: 'shortList',
};

export const RWOTaskListTypeUI = {
  [RWOTaskListType.LONG_LIST]: 'Long List',
  [RWOTaskListType.SHORT_LIST]: 'Short List',
};

export const TaskDueDateType = {
  MONTH_END: 'monthEnd',
};

export const RecurringProjectTypes = {
  recurringProject: 'recurringProject',
  // Admin specific Preventive Maintenance
  // completely independent from Site's maintenance schedules
  preventiveMaintenanceProject: 'preventiveMaintenanceProject',
};
