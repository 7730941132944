import React from 'react';
import { shape } from 'prop-types';
import { NOTHING_UI_STRING } from '@poly/constants';
import { forceTitleCase } from '@poly/utils';

import {
  FixedWidthColumn,
  RightAlignedFlexColumn,
  SearchResultItemContainer,
} from './common/wrappers.js';
import {
  SearchResultItemDivider,
  SearchResultItemLinked,
  SearchResultItemText,
  SearchResultItemTitle,
  StatusS,
} from './common/components.js';
import { clientNamePath, propertyNamePath } from './common/selectors.js';
import { masterProjectStatusesColors } from '../../../../constants/projects.js';
import { highlightedTextPropType } from './common/propTypes.js';

export function RecurringProjectsSearchResult({
  projectId,
  description,
  status = '',
  ...props
}) {
  return (
    <SearchResultItemContainer>
      <FixedWidthColumn width="110px">
        <SearchResultItemLinked>{projectId}</SearchResultItemLinked>
      </FixedWidthColumn>
      <RightAlignedFlexColumn>
        <SearchResultItemText>{description}</SearchResultItemText>
        <SearchResultItemDivider />
        <SearchResultItemTitle>
          {clientNamePath(props)} {NOTHING_UI_STRING} {propertyNamePath(props)}
        </SearchResultItemTitle>
      </RightAlignedFlexColumn>
      <StatusS
        text={forceTitleCase(status)}
        color={masterProjectStatusesColors[forceTitleCase(status)]}
      />
    </SearchResultItemContainer>
  );
}

RecurringProjectsSearchResult.propTypes = {
  projectId: highlightedTextPropType.isRequired,
  client: shape({
    name: highlightedTextPropType.isRequired,
  }).isRequired,
  property: shape({
    name: highlightedTextPropType.isRequired,
  }).isRequired,
  description: highlightedTextPropType.isRequired,
  status: highlightedTextPropType.isRequired,
};
