import * as R from 'ramda';
import React, { useMemo } from 'react';
import { string, shape, func, arrayOf } from 'prop-types';
import { PO_AMOUNT_AIT, EQUALS_AIT_OPERATOR } from '@poly/security';
import { Select } from '@poly/admin-book/src/Select/Select.js';
import {
  forceTitleCase,
  isNilOrEmpty,
  pathEqLegacy,
  propEqLegacy,
} from '@poly/utils';

import { userGroupVariablesMap } from '../../constants.js';

// prepareScopeOptions :: String -> [ScopeFormLine] -> [Option]
const prepareScopeOptions = (value) =>
  R.compose(
    R.map(
      R.applySpec({
        value: R.identity,
        label: R.compose(forceTitleCase, R.prop(R.__, userGroupVariablesMap)),
      }),
    ),
    R.unless(() => isNilOrEmpty(value), R.append(value)),
    R.defaultTo([]),
  );

// checkShouldUpdateOperator :: { scope: String, formLineData: ScopeFormLine } -> Boolean
const checkShouldUpdateOperator = R.both(
  R.pathSatisfies(R.complement(R.equals(EQUALS_AIT_OPERATOR)), [
    'formLineData',
    'operator',
  ]),
  R.either(
    propEqLegacy('scope', PO_AMOUNT_AIT),
    pathEqLegacy(['formLineData', 'scope'], PO_AMOUNT_AIT),
  ),
);

export function UserGroupScopeSelect({
  value,
  formLineData,
  onLineChange,
  allowedScopes,
  ...props
}) {
  const handleChange = (scope) => {
    const shouldUpdateOperator = checkShouldUpdateOperator({
      scope,
      formLineData,
    });

    onLineChange({
      ...formLineData,
      scope,
      entities: null,
      ...(shouldUpdateOperator ? { operator: EQUALS_AIT_OPERATOR } : {}),
    });
  };

  const options = useMemo(
    () => prepareScopeOptions(value)(allowedScopes),
    [allowedScopes],
  );

  const selectProps = {
    ...props,
    value,
    options,
    placeholder: 'Scope',
    onChange: handleChange,
  };

  return <Select {...selectProps} />;
}

UserGroupScopeSelect.propTypes = {
  value: string,
  onLineChange: func.isRequired,
  allowedScopes: arrayOf(string).isRequired,
  formLineData: shape({ scope: string, operator: string }).isRequired,
};
