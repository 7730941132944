import * as R from 'ramda';
import { func } from 'prop-types';
import React, { useEffect } from 'react';
import { gql, useQuery, useApolloClient } from '@apollo/client';
import { SearchChangedResultType } from '@poly/constants';
import { useNotificationContext } from '@poly/admin-book';
import { useReactiveQuery } from '@poly/client-utils';
import { pathEqLegacy } from '@poly/utils';

import {
  TextBlock,
  NumberBlock,
  AlertButtonWrapper,
} from './alertComponents.js';
import { NewAlertNotification } from '../NewAlertNotification.js';
import { USER_ALERTS_SEARCH_QUERY } from '../../../modules/core/hocs/userAlerts/queries.js';
import { USER_ALERTS_SEARCH_CHANGED } from '../../../modules/core/hocs/userAlerts/subscriptions.js';
import { useAppBarItemClick } from '../useAppBarItemClick.js';

const notifyOnNewAlert = (apolloClient, alertId, showWarningNotification) =>
  apolloClient
    .query({
      query: gql`
        query searchUserAlerts($alertId: ID!) {
          userAlert(id: $alertId) {
            _id
            message
          }
        }
      `,
      variables: { alertId },
    })
    .then(R.path(['data', 'userAlert', 'message']))
    .then((message) =>
      showWarningNotification({
        payload: { message },
        time: 6,
        component: NewAlertNotification,
      }),
    );

const newAlertInserted = pathEqLegacy(
  ['data', 'searchUserAlertChanged', 'type'],
  SearchChangedResultType.INSERT,
);

// getQueryOptionsByStatus :: Boolean -> OperationVariables
const getQueryOptionsByStatus = (status) => ({
  variables: {
    searchInput: {
      from: 0,
      query: { term: { isArchived: status } },
      size: 0,
      sort: {},
    },
  },
});

// getUserAlertsTotal :: { searchUserAlerts: { total: Int } } -> Int
const getUserAlertsTotal = R.pathOr(1, ['searchUserAlerts', 'total']);

export const useAlertsNumber = (status) => {
  const { data } = useReactiveQuery(
    USER_ALERTS_SEARCH_QUERY,
    USER_ALERTS_SEARCH_CHANGED,
    {
      queryOptions: getQueryOptionsByStatus(status),
      subscriptionOptions: getQueryOptionsByStatus(status),
    },
  );

  return getUserAlertsTotal(data);
};

const useNewAlertNotification = () => {
  const apolloClient = useApolloClient();
  const { showWarningNotification } = useNotificationContext();

  const { subscribeToMore } = useQuery(
    USER_ALERTS_SEARCH_QUERY,
    getQueryOptionsByStatus(false),
  );

  useEffect(() => {
    if (subscribeToMore) {
      subscribeToMore({
        document: USER_ALERTS_SEARCH_CHANGED,
        ...getQueryOptionsByStatus(false),
        updateQuery: (_, { subscriptionData }) => {
          if (newAlertInserted(subscriptionData)) {
            notifyOnNewAlert(
              apolloClient,
              R.path(
                ['data', 'searchUserAlertChanged', 'id'],
                subscriptionData,
              ),
              showWarningNotification,
            );
          }
        },
      });
    }
  }, []);
};

export function AlertButton({ onClick }) {
  const archivedAlertsNumber = useAlertsNumber(true);
  const alertsNumber = useAlertsNumber(false);
  useNewAlertNotification();

  const handleAlertsClick = useAppBarItemClick(() =>
    onClick(alertsNumber, archivedAlertsNumber),
  );

  return (
    <AlertButtonWrapper data-testid="alerts-button" onClick={handleAlertsClick}>
      <TextBlock>Alerts</TextBlock>
      <NumberBlock hasAlerts={alertsNumber}>{alertsNumber}</NumberBlock>
    </AlertButtonWrapper>
  );
}

AlertButton.propTypes = {
  onClick: func,
};
AlertButton.displayName = 'AlertButton';
