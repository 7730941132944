import React from 'react';
import { object, oneOfType, shape, string } from 'prop-types';
import { SidebarRow } from '@poly/admin-ui';

import {
  SectionLabel,
  SectionWrapper,
} from '../components/commonSidebarComponents.js';
import { getSectionEditor } from '../components/commonSidebarSectionFormatters.js';

export function UserSidebarRemarks({ user }) {
  return (
    <SectionWrapper>
      <SidebarRow>
        <SectionLabel>Remarks</SectionLabel>
      </SidebarRow>
      <SidebarRow>
        {getSectionEditor(user.remarks)({ id: 'remarks' })}
      </SidebarRow>
    </SectionWrapper>
  );
}

UserSidebarRemarks.propTypes = {
  user: shape({ remarks: oneOfType([object, string]) }).isRequired,
};
