import React from 'react';
import * as R from 'ramda';
import { func, string } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { ClientSelect, useOnSubmitSetStopSubmitting } from '@poly/admin-ui';
import { FormCreator, Input, Textarea } from '@poly/admin-book';

export const propertyHierarchyFormConfig = [
  {
    id: 'propertyHierarchyFormMainSection',
    layout: { column: 1 },
    fields: [
      {
        label: 'Client',
        order: 1,
        layout: {
          row: 1,
        },
        required: true,
        field: {
          name: 'clientId',
          Component: (props) => (
            <ClientSelect {...props} includeAllOption={false} />
          ),
        },
        renderIf: ({ isEdit }) => !isEdit,
      },
      {
        label: 'Name',
        order: 1,
        layout: {
          row: 2,
        },
        required: true,
        field: {
          name: 'name',
          Component: Input,
        },
        validators: [[R.identity, 'Name name is required']],
      },
      {
        label: 'Description',
        order: 1,
        layout: {
          row: 3,
        },
        field: {
          name: 'description',
          Component: Textarea,
        },
      },
    ],
  },
];

const addPropertyHierarchyMutation = gql`
  mutation addHierarchyMutation($input: AddPropertyHierarchyInput!) {
    addPropertyHierarchy(input: $input) {
      _id
      client {
        _id
      }
    }
  }
`;

export const addHierarchyFormId = 'addHierarchyFormId';

// getHierarchyIdFromCreateResult :: MutationResult -> String
const getHierarchyIdFromCreateResult = R.path(['data', 'addPropertyHierarchy']);

export function AddPropertyHierarchyFrom({ clientId, onSuccess }) {
  const [addPropertyHierarchy] = useMutation(addPropertyHierarchyMutation);
  const onSubmitHandler = async (values) => {
    const result = await addPropertyHierarchy({
      variables: { input: values },
    });
    const hierarchy = getHierarchyIdFromCreateResult(result);
    onSuccess(hierarchy);
  };
  const { onSubmit } = useOnSubmitSetStopSubmitting(
    addHierarchyFormId,
    onSubmitHandler,
  );
  return (
    <FormCreator
      id={addHierarchyFormId}
      formId={addHierarchyFormId}
      sections={propertyHierarchyFormConfig}
      onSubmit={onSubmit}
      initialValues={{ clientId }}
      layout={{ card: false }}
    />
  );
}

AddPropertyHierarchyFrom.propTypes = {
  clientId: string,
  onSuccess: func.isRequired,
};
