import { arrayOf, number, shape, string } from 'prop-types';

export const invoicesPropTypes = arrayOf(
  shape({
    invoices: arrayOf(
      shape({
        number: number.isRequired,
        createdAt: string.isRequired,
      }),
    ),
    name: string,
  }),
);
