import { useState } from 'react';
import { useQuery } from '@apollo/client';

export const useEntitySimilarItems = ({
  query,
  itemsFromProps,
  variablesFromProps,
  onChange: onChangeFromProps,
  propsOfComponent,
}) => {
  const [nameSearch, setNameSearch] = useState(propsOfComponent?.value || '');

  const { data } = useQuery(query, {
    variables: variablesFromProps({ nameSearch }),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    alias: 'useEntitySimilarItems',
  });

  const queryResult = itemsFromProps({ data, ownProps: propsOfComponent });

  const onChange = (value) => {
    setNameSearch(value.trim());
    onChangeFromProps(value);
  };

  return {
    ...queryResult,
    ...propsOfComponent,
    onChange,
  };
};
