import * as R from 'ramda';
import { Input } from '@poly/admin-book';
import { PhoneNumberInput } from '@poly/admin-ui';
import { ifNotEmpty, validatePhone } from '@poly/client-utils';

import { applyWidth } from '../../../../../modules/forms/formsStyles.js';

// isFieldAlarmLockBoxInfoEnabled :: String -> FormData -> Boolean
const isFieldAlarmLockBoxInfoEnabled = R.path([
  'client',
  'configs',
  'propertyFields',
  'alarmInfo',
]);

export const alarmCodeFormField = {
  label: 'Alarm Code',
  field: {
    name: 'alarmInfo.alarmCode',
    Component: Input,
  },
  renderIf: isFieldAlarmLockBoxInfoEnabled,
};

export const alarmSystemFormField = {
  label: 'Alarm System',
  field: {
    name: 'alarmInfo.alarmSystem',
    Component: Input,
  },
  renderIf: isFieldAlarmLockBoxInfoEnabled,
};

export const accountNumberFormField = {
  label: 'Account Number',
  field: {
    name: 'alarmInfo.accountNumber',
    Component: Input,
  },
  renderIf: isFieldAlarmLockBoxInfoEnabled,
};

export const alarmCompanyPhoneFormField = {
  label: 'Alarm Company Phone',
  field: {
    name: 'alarmInfo.alarmCompanyPhone',
    Component: applyWidth(PhoneNumberInput),
  },
  renderIf: isFieldAlarmLockBoxInfoEnabled,
  validators: [[ifNotEmpty(validatePhone), 'Incorrect phone']],
};

export const passwordFormField = {
  label: 'Password',
  field: {
    name: 'alarmInfo.password',
    Component: Input,
  },
  renderIf: isFieldAlarmLockBoxInfoEnabled,
};

export const gateCodeFormField = {
  label: 'Gate Code',
  field: {
    name: 'alarmInfo.gateCode',
    Component: Input,
  },
  renderIf: isFieldAlarmLockBoxInfoEnabled,
};
