import React from 'react';
import * as R from 'ramda';
import { gql, useMutation, useQuery } from '@apollo/client';
import { DatePicker, FormPage, Loader } from '@poly/admin-book';

import {
  halfWidth,
  useNotificationState,
  useOnSubmitSetStopSubmitting,
} from '@poly/admin-ui';
import {
  useNavigate,
  usePristineSubscribe,
  useRouterParams,
} from '@poly/client-routing';
import arrayMutators from 'final-form-arrays';
import { alwaysNewDate } from '@poly/utils';
import { JournalEntryAccountingTypeSelector } from '../JournalEntry/JournalEntryForm/JournalEntryAccountingTypeSelector.js';
import { quarterWidth } from '../../modules/forms/common.js';
import { CheckboxWithLabel } from '../../sidebars/ProjectSidebar/forms/form/components/CheckboxWithLabel.js';
import { RecurringScheduler } from '../../components/RecurringScheduler/RecurringScheduler.js';
import { JournalEntryLineComponent } from '../JournalEntry/JournalEntryForm/JournalEntryLineComponent.js';
import {
  editRecurringJournalEntryFormId,
  formatRecurringJournalFormData,
  formatRecurringJournalMutationInput,
} from './recurringJournalEntryHelpers.js';
import { routesNames } from '../../routes/index.js';
import { validateJournalEntryForm } from '../JournalEntry/journalEntryUtils.js';
import { RecurringJournalStatusSelector } from './RecurringJournalStatusSelector.js';

const recurringJournalQuery = gql`
  query recurringJournalQuery($id: ID!) {
    recurringJournal(id: $id) {
      _id
      start_date
      end_date
      status
      accounting_method
      schedule {
        repeats
        every
        weekDay
        days
      }
      lines {
        account {
          _id
          name
          code
          accountType {
            system_type
          }
          is_client_payment_deduction
          is_travel_hq
        }
        cash_amount
        accrual_amount
        description
        receipt_file {
          fileName
          url
        }
      }
    }
  }
`;

const editRecurringJournalEntryMutation = gql`
  mutation editRecurringJournalEntryMutation(
    $id: ID!
    $input: EditRecurringJournalInput!
  ) {
    editRecurringJournal(id: $id, input: $input) {
      _id
    }
  }
`;

// isNotNeverEnding :: ProjectFormObject -> Boolean
const isNotNeverEnding = R.compose(R.not, R.prop('never'), R.defaultTo({}));

function EndDatePicker(props) {
  return <DatePicker disabledDays={{ before: alwaysNewDate() }} {...props} />;
}

function StartDateDatePicker(props) {
  return <DatePicker {...props} disabled />;
}

const recurringJournalEntryFromSections = [
  {
    order: 1,
    layout: { column: 1, justifyContent: 'flex-start' },
    fields: [
      {
        order: 1,
        label: 'Type',
        layout: { row: 1, width: quarterWidth },
        field: {
          name: 'accounting_method',
          Component: JournalEntryAccountingTypeSelector,
          withFormData: true,
        },
      },
      {
        order: 2,
        label: 'Status',
        layout: { row: 1, width: quarterWidth },
        required: true,
        field: {
          name: 'status',
          Component: RecurringJournalStatusSelector,
        },
      },
      {
        order: 3,
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'space',
          Component: () => null,
        },
      },
      {
        label: 'Start Date',
        layout: { row: 2, width: quarterWidth },
        order: 1,
        field: {
          name: 'startDate',
          Component: StartDateDatePicker,
        },
      },
      {
        leaveValues: true,
        label: 'End Date',
        layout: { row: 2, width: quarterWidth },
        order: 2,
        field: {
          name: 'endDate',
          withFormData: true,
          Component: EndDatePicker,
        },
        renderIf: isNotNeverEnding,
      },
      {
        order: 2,
        layout: { row: 2, width: quarterWidth },
        field: {
          name: 'space',
          Component: () => null,
        },
        renderIf: R.prop('never'),
      },
      {
        layout: { row: 2, width: quarterWidth, padding: '28px 0' },
        order: 3,
        field: {
          name: 'never',
          Component: (props) => (
            <CheckboxWithLabel {...props} label="Never Ends'" />
          ),
        },
      },
      {
        order: 4,
        layout: { row: 2, width: quarterWidth },
        field: {
          name: 'space',
          Component: () => null,
        },
      },
      {
        order: 1,
        layout: { row: 3, width: halfWidth },
        field: {
          name: 'schedule',
          withFormData: true,
          Component: RecurringScheduler,
        },
      },
      {
        order: 1,
        layout: { row: 3 },
        field: {
          name: 'lines',
          isArrayField: true,
          Component: JournalEntryLineComponent,
        },
      },
    ],
  },
];

export function EditRecurringJournalEntryForm() {
  const { showSuccessNotification } = useNotificationState();
  const { journalId } = useRouterParams(['journalId']);
  const navigate = useNavigate();

  const { data, loading } = useQuery(recurringJournalQuery, {
    variables: { id: journalId },
    fetchPolicy: 'network-only',
  });

  const [editRecurringJournalEntry] = useMutation(
    editRecurringJournalEntryMutation,
  );

  const onSubmitHandler = async (formData) => {
    await editRecurringJournalEntry({
      variables: {
        id: journalId,
        input: formatRecurringJournalMutationInput(formData),
      },
    });
    showSuccessNotification('Journal Entry has successfully updated');
    navigate(routesNames.RECURRING_JOURNALS);
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    editRecurringJournalEntryFormId,
    onSubmitHandler,
  );

  const pristineSubscribeProps = usePristineSubscribe();
  if (loading) return <Loader />;

  const formProps = {
    onSubmit,
    id: editRecurringJournalEntryFormId,
    initialValues: formatRecurringJournalFormData(data),
    validate: validateJournalEntryForm,
    validateOnBlur: true,
    initialValuesEqual: R.T,
    keepDirtyOnReinitialize: false,
    mutators: arrayMutators,
    resetFormOnSubmit: false,
    ...pristineSubscribeProps,
    sections: recurringJournalEntryFromSections,
  };

  return <FormPage {...formProps} />;
}
