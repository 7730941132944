import React from 'react';
import * as R from 'ramda';
import { useModalContext } from '@poly/admin-ui';
import { IconButton } from '@poly/admin-book';
import { capitalizeFirstLetter } from '@poly/client-utils';
import { string } from 'prop-types';
import { propEqLegacy } from '@poly/utils';

import { editAccountTypeFormId } from './constants.js';
import { AccountTypeForm } from './forms/AccountTypeForm.js';
import {
  accountTypeNormalBalanceOptions,
  accountTypeCategoryOptions,
} from './forms/AccountTypeFormSections.js';

function EditButton(props) {
  const { openModalForm } = useModalContext();
  const onClick = () =>
    openModalForm({
      id: editAccountTypeFormId,
      title: 'Edit Account Type',
      formId: editAccountTypeFormId,
      btnCaption: 'Save',
      content: <AccountTypeForm isNewAccountType={false} document={props} />,
    });

  return !props.system_type ? (
    <IconButton size={15} name="edit" onClick={onClick} />
  ) : null;
}

EditButton.propTypes = {
  system_type: string,
};

export const accountTypesTableConfig = [
  ['Name', R.propOr('', 'name')],
  [
    'Category',
    ({ category }) =>
      R.compose(
        R.propOr('', 'label'),
        R.find(propEqLegacy('value', category)),
      )(accountTypeCategoryOptions),
  ],
  [
    'Normal Balance',
    ({ normal_balance }) =>
      R.compose(
        R.propOr('', 'label'),
        R.find(propEqLegacy('value', normal_balance)),
      )(accountTypeNormalBalanceOptions),
  ],
  ['Status', R.compose(capitalizeFirstLetter, R.propOr('', 'status'))],
  ['', EditButton],
];
