import React from 'react';
import * as R from 'ramda';
import { CommonPrintLayout } from '@poly/admin-ui';
import { openPrintWindowWithData } from '@poly/client-utils';
import { PRINT_PDF_CAPTION } from '@poly/constants';

import { VendorLedgerReportTable } from './VendorLedgerReportTable.js';
import { SalesReportLink } from '../SalesTaxReport/salesTaxReportComponents.js';
import { vendorLedgerReportPropTypes } from './prop-types.js';

export function VendorLedgerReportPrintPDFBtn({ transactions, title, type }) {
  const onClick = async () => {
    await openPrintWindowWithData({
      fileName: `vendor_${type}_ledger_report`,
      Layout: CommonPrintLayout,
      Table: VendorLedgerReportTable,
      metaData: { title },
      transactions,
    });
  };
  return (
    <SalesReportLink onClick={onClick} disabled={R.isEmpty(transactions)}>
      {PRINT_PDF_CAPTION}
    </SalesReportLink>
  );
}

VendorLedgerReportPrintPDFBtn.propTypes = vendorLedgerReportPropTypes;
