import styled from 'styled-components';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { number, bool, func, string, arrayOf, shape } from 'prop-types';
import { centsToDollarsWithFormat } from '@poly/utils';
import { Button, getThemeColor } from '@poly/admin-book';
import { useNotificationState } from '@poly/admin-ui';
import { useRouterQuery } from '@poly/client-routing';

import { getBillingProfileConfig } from '../../redux/searchFilters/billingProfileConfig.js';
import { createClearInvoicesAction } from '../BatchInvoicingTM/batch-invoicing-state.js';
import { getBillingProfileQueryConfig } from './BillingProfileConfigurationSidebar/utils.js';

const createConsolidatedClientInvoiceMutation = gql`
  mutation createConsolidatedClientInvoiceMutation(
    $input: CreateConsolidatedClientInvoiceInput!
  ) {
    createConsolidatedClientInvoice(input: $input) {
      consolidatedClientInvoices {
        _id
      }
    }
  }
`;

const FooterWrapperS = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const FooterBlockWrapperS = styled.div`
  display: flex;
  margin: 0 10px 0 90px;
  flex-direction: column;
  min-width: 100px;
`;

const FooterBlockAmountS = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`;

const FooterBlockLabelS = styled(FooterBlockAmountS)`
  color: ${getThemeColor(['midDark'])};
`;

function FooterBlock({ label, amount }) {
  return (
    <FooterBlockWrapperS>
      <FooterBlockLabelS>{label}</FooterBlockLabelS>
      <FooterBlockAmountS>
        {centsToDollarsWithFormat(amount)}
      </FooterBlockAmountS>
    </FooterBlockWrapperS>
  );
}

FooterBlock.propTypes = { label: string.isRequired, amount: number.isRequired };

export function ClientInvoicingCollectionFooter({
  jobCost,
  loader,
  clientInvoiceMarkupAmount,
  refetch,
  dispatch,
  woCharge,
  disabled,
  isWoCharge,
  inputEntries,
  onCreateBatch,
  suggestedClientInvoiceAmount,
}) {
  const config = useSelector(getBillingProfileConfig);
  const { clientId, billingProfileId } = useRouterQuery(['clientId']);
  const [isGenerationInProgress, setIsGenerationInProgress] = useState(false);
  const { showSuccessNotification } = useNotificationState();
  const [createConsolidatedClientInvoice] = useMutation(
    createConsolidatedClientInvoiceMutation,
  );

  const handleGenerateAction = async () => {
    setIsGenerationInProgress(true);

    try {
      await createConsolidatedClientInvoice({
        variables: {
          input: {
            clientId,
            projects: inputEntries,
            ...(billingProfileId
              ? { billingProfileId }
              : { config: getBillingProfileQueryConfig(config) }),
          },
        },
      });
      return setTimeout(() => {
        showSuccessNotification(
          "Consolidated Client Invoice(s) successfully created. You'll be notified when PDF is ready",
        );
      }, 2000);
    } finally {
      // we need delay otherwise refetch doesn't work properly
      // (due to ES index update delay, see useReactiveQuery)
      setTimeout(() => {
        dispatch(createClearInvoicesAction());
        refetch();
        setIsGenerationInProgress(false);
      }, 2000);
    }
  };

  const onGenerate = () => onCreateBatch(handleGenerateAction);

  return (
    <FooterWrapperS>
      <FooterBlock label="Job Cost" amount={jobCost} />
      <FooterBlock label="Markup" amount={clientInvoiceMarkupAmount} />
      {isWoCharge && (
        <FooterBlock label="Work Order Charge	" amount={woCharge} />
      )}
      <FooterBlock label="Total" amount={suggestedClientInvoiceAmount} />
      <Button
        size="small"
        disabled={disabled || isGenerationInProgress}
        onClick={onGenerate}
        loader={loader || isGenerationInProgress}
      >
        Generate
      </Button>
    </FooterWrapperS>
  );
}

ClientInvoicingCollectionFooter.propTypes = {
  loader: bool,
  jobCost: number,
  clientInvoiceMarkupAmount: number,
  disabled: bool,
  woCharge: number,
  isWoCharge: bool,
  refetch: func.isRequired,
  dispatch: func.isRequired,
  onCreateBatch: func.isRequired,
  suggestedClientInvoiceAmount: number,
  inputEntries: arrayOf(
    shape({ projectId: string.isRequired, amount: number.isRequired }),
  ),
};
