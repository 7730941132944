import React, { useState } from 'react';
import { DefaultBodyWrapper } from '@poly/admin-book';
import { useRedirectIfHasNoPermissions } from '@poly/client-utils';
import { READ_REQUESTED_SUPPLIER_DOCUMENT_PERMISSION } from '@poly/security';

import { RequestedSupplierDocumentsReviewHeader } from './RequestedSupplierDocumentsReviewHeader.js';
import { RequestedSupplierDocumentsReviewTable } from './RequestedSupplierDocumentsReviewTable.js';
import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { useRequestedSupplierDocuments } from './useRequestedSupplierDocuments.js';

export function RequestedSupplierDocumentsReviewPage() {
  useRedirectIfHasNoPermissions([READ_REQUESTED_SUPPLIER_DOCUMENT_PERMISSION]);
  const [filter, setFilter] = useState({});

  const { documents, loading, tableProps } = useRequestedSupplierDocuments({
    filter,
  });

  return (
    <PageWithSearchHeader headerHeight="85px">
      <RequestedSupplierDocumentsReviewHeader
        filter={filter}
        setFilter={setFilter}
      />
      <DefaultBodyWrapper>
        <RequestedSupplierDocumentsReviewTable
          documents={documents}
          loading={loading}
          {...tableProps}
        />
      </DefaultBodyWrapper>
    </PageWithSearchHeader>
  );
}
