import * as R from 'ramda';
import React from 'react';
import { Input } from '@poly/admin-book';
import { TAX_ID_MASK } from '@poly/client-utils';
import { shape, string } from 'prop-types';
import { isSoleProprietorship } from '../utils.js';

// checkDisabled :: FormData -> Bool
const checkDisabled = R.both(
  isSoleProprietorship,
  R.compose(R.complement(R.isNil), R.path(['tax', 'socialSecurityNumber'])),
);

export function TaxIDInput({ formData, ...props }) {
  return (
    <Input
      {...props}
      mask="_"
      type="numberMask"
      format={TAX_ID_MASK}
      disabled={checkDisabled(formData)}
    />
  );
}

TaxIDInput.propTypes = {
  formData: shape({
    tax: shape({
      classification: string,
      socialSecurityNumber: string,
    }),
  }),
};
