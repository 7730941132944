import * as R from 'ramda';

export const quillEmptyValue = { ops: [] };

const convertStringToQuillDelta = (str) => ({ ops: [{ insert: str }] });

// adjustQuillDelta :: Any -> QuillDelta
// QuillDelta = Object
//
// fixes various problems bugs that may come up
// because of invalid Delta
export const adjustQuillDelta = R.compose(
  R.over(R.lensProp('ops'), R.reject(R.propSatisfies(R.isNil, 'insert'))),
  R.when(R.is(String), convertStringToQuillDelta),
  R.defaultTo(quillEmptyValue),
);

// isEditorEmpty :: QuillDelta -> Boolean
export const isEditorEmpty = R.either(
  R.isEmpty,
  R.compose(
    R.both(
      R.compose(R.equals(1), R.length),
      R.pathSatisfies(R.equals('\n'), ['0', 'insert']),
    ),
    R.prop('ops'),
  ),
);
