import {
  CREATE_CLIENT_BATCH_PERMISSION,
  CREATE_CLIENT_INVOICE_PERMISSION,
  EDIT_CLIENT_INVOICE_PERMISSION,
  PRINT_CLIENT_INVOICE_PERMISSION,
  READ_CLIENT_BATCH_PERMISSION,
  VOID_CLIENT_INVOICE_PERMISSION,
} from '@poly/security';

import { routesNames } from '../../routes/index.js';
import { defaultWarningMessage } from '../FinancialDirectory/constants.js';
import { BatchGenerationQueueCounter } from '../BatchGenerationQueue/BatchGenerationQueueCounter.js';
import { BatchInvoiceCompletedCounter } from '../BatchInvoiceCompleted/BatchInvoiceCompletedCounter.js';

export const getClientInvoicingPageConfig = (billingProfilesLinks) => [
  {
    column: 1,
    title: 'Client Invoicing',
    links: [
      {
        name: 'Edit Client Invoice',
        link: routesNames.SEARCH_CLIENT_INVOICES,
        permissions: [EDIT_CLIENT_INVOICE_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Void Client Invoice',
        link: routesNames.SEARCH_VOID_CLIENT_INVOICES,
        permissions: [VOID_CLIENT_INVOICE_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Create Client Invoice',
        link: routesNames.CREATE_CLIENT_INVOICE_SEARCH,
        permissions: [CREATE_CLIENT_INVOICE_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Print Client Invoice',
        link: routesNames.PRINT_CLIENT_INVOICE,
        permissions: [PRINT_CLIENT_INVOICE_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
    ],
  },
  {
    column: 1,
    title: 'Batch Reporting',
    links: [
      {
        name: 'Batch Invoice Reports',
        link: routesNames.BATCH_INVOICE_REPORTS,
        permissions: [READ_CLIENT_BATCH_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Batch Invoice Generation Queue',
        link: routesNames.BATCH_GENERATION_QUEUE,
        Counter: BatchGenerationQueueCounter,
        permissions: [CREATE_CLIENT_BATCH_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Completed Batch Invoices',
        link: routesNames.COMPLETED_BATCHES,
        Counter: BatchInvoiceCompletedCounter,
        permissions: [CREATE_CLIENT_BATCH_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
    ],
  },
  {
    column: 2,
    title: 'Batch Invoicing',
    links: [
      {
        name: 'Bass Pro',
        subLinks: [
          {
            name: 'Bass Pro Housekeeping',
            link: routesNames.HOUSEKEEPING_BATCH_INVOICING,
            permissions: [CREATE_CLIENT_BATCH_PERMISSION],
            warningMessage: defaultWarningMessage,
          },
          {
            name: 'Bass Pro T&M',
            link: routesNames.BASS_PRO_TM_BATCH_INVOICING,
            permissions: [CREATE_CLIENT_BATCH_PERMISSION],
            warningMessage: defaultWarningMessage,
          },
          {
            name: 'Bass Pro Bid',
            link: routesNames.BASS_PRO_BID_BATCH_INVOICING,
            permissions: [CREATE_CLIENT_BATCH_PERMISSION],
            warningMessage: defaultWarningMessage,
          },
        ],
      },
      {
        name: 'Builders First Source',
        subLinks: [
          {
            name: 'BFS Dormant',
            link: routesNames.BFS_DORMANT_BATCH_INVOICING,
            permissions: [CREATE_CLIENT_BATCH_PERMISSION],
            warningMessage: defaultWarningMessage,
          },
          {
            name: 'BFS Active',
            link: routesNames.BFS_ACTIVE_BATCH_INVOICING,
            permissions: [CREATE_CLIENT_BATCH_PERMISSION],
            warningMessage: defaultWarningMessage,
          },
        ],
      },
      {
        name: 'UMC Batch Invoicing',
        permissions: [CREATE_CLIENT_BATCH_PERMISSION],
        link: routesNames.UMC_BATCH_INVOICING,
      },
      {
        name: 'Mondelez Batch Invoicing',
        permissions: [CREATE_CLIENT_BATCH_PERMISSION],
        link: routesNames.MONDELEZ_INVOICING,
      },
      {
        name: 'Regional Management',
        subLinks: [
          {
            name: 'Janitorial',
            link: routesNames.REGIONAL_MANAGEMENT_JANITORIAL_BATCH_INVOICING,
            permissions: [CREATE_CLIENT_BATCH_PERMISSION],
            warningMessage: defaultWarningMessage,
          },
          {
            name: 'R&M',
            link: routesNames.REGIONAL_MANAGEMENT_BATCH_INVOICING,
            permissions: [CREATE_CLIENT_BATCH_PERMISSION],
            warningMessage: defaultWarningMessage,
          },
        ],
      },
    ],
  },
  {
    column: 3,
    title: 'Client Invoicing ',
    links: [
      {
        name: 'Client Invoicing',
        link: routesNames.CLIENT_INVOICING_COLLECTION,
        permissions: [CREATE_CLIENT_INVOICE_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      ...billingProfilesLinks,
    ],
  },
];
