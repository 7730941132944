import * as R from 'ramda';
import { gql, useLazyQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { isNilOrEmpty, propEqLegacy } from '@poly/utils';

import {
  getBillingProfileConfig,
  setBillingProfileConfig,
} from '../../redux/searchFilters/billingProfileConfig.js';

const BILLING_PROFILE_QUERY = gql`
  query BILLING_PROFILE_QUERY($id: ID) {
    billingProfile(id: $id) {
      _id
      name
      config {
        pos
        type
        nonPO
        costTypes
        adminPOIds
        propertyIds
        consolidateBy
        isTransparent
        serviceTypeIds
        costCenterNames
        attachBackupInvoices
        attachSupplierInvoices
        consolidation
        batch
        reporting {
          accountsPayable
          coverPageAndDetails {
            enabled
            isPassThroughFeeEnabled
            isFinancialCodingEnabled
          }
        }
      }
    }
  }
`;

// prepareBillingProfileConfig :: BillingProfileQueryResult -> BillingProfileUIConfig
export const prepareBillingProfileConfig = R.compose(
  R.ifElse(
    R.isNil,
    // fix reducer error to do not set undefined
    R.always(null),
    R.compose(
      R.omit(['__typename', 'type']),
      R.unless(
        R.propSatisfies(isNilOrEmpty, 'type'),
        R.converge(R.mergeRight, [
          R.compose(R.mergeAll, R.map(R.objOf(R.__, true)), R.prop('type')),
          R.identity,
        ]),
      ),
      R.reject(R.isNil),
      R.converge(R.mergeLeft, [
        R.compose(
          R.objOf('billingProfileName'),
          R.objOf('value'),
          R.prop('name'),
        ),
        R.compose(
          R.converge(R.mergeRight, [
            R.applySpec({
              apReportingEnabled: R.path(['reporting', 'accountsPayable']),
              coverPageAndDetailsReportingEnabled: R.path([
                'reporting',
                'coverPageAndDetails',
                'enabled',
              ]),
              isPassThroughFeeEnabled: R.path([
                'reporting',
                'coverPageAndDetails',
                'isPassThroughFeeEnabled',
              ]),
              isFinancialCodingEnabled: R.path([
                'reporting',
                'coverPageAndDetails',
                'isFinancialCodingEnabled',
              ]),
            }),
            R.omit(['reporting']),
          ]),
          R.prop('config'),
        ),
      ]),
    ),
  ),
  R.prop('billingProfile'),
);

// checkIsNewBillingProfile :: ID -> BillingProfileUIConfig -> Boolean
const checkIsNewBillingProfile = (id) =>
  R.either(R.isNil, R.complement(propEqLegacy('billingProfileId', id)));

export const useSetBillingProfileConfigById = () => {
  const dispatch = useDispatch();
  const initialBillingProfileConfig = useSelector(getBillingProfileConfig);

  const [queryHandler] = useLazyQuery(BILLING_PROFILE_QUERY);

  return async (id) => {
    if (id) {
      const isNewBillingProfile = checkIsNewBillingProfile(id)(
        initialBillingProfileConfig,
      );

      if (isNewBillingProfile) {
        const { data } = await queryHandler({ variables: { id } });

        const billingProfileConfig = prepareBillingProfileConfig(data);

        dispatch(setBillingProfileConfig(billingProfileConfig));
      }
    } else {
      dispatch(setBillingProfileConfig(null));
    }
  };
};
