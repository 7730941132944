import * as R from 'ramda';
// import from 'ExcelJS' messes up browser production build
// see https://github.com/exceljs/exceljs/issues/1342#issuecomment-746895183
import Excel from 'exceljs/dist/exceljs.js';

import { ofArrayLegacy } from '../ramdaLegacyFunctions.js';
import { DEFAULT_WORKSHEET_NAME } from './helpers.js';
import { EXCEL_CELL_CONFIG, ExcelExportCellType } from './constants.js';

// convertDataCellToXlsCell :: ExcelExportDataCell -> Pair Object Object
const convertDataCellToXlsCell = R.compose(
  R.juxt([R.omit(['_polyInternal']), R.propOr({}, '_polyInternal')]),
  R.converge(R.mergeDeepRight, [
    R.compose(
      R.prop(R.__, EXCEL_CELL_CONFIG),
      R.propOr(ExcelExportCellType.default, 'cellType'),
    ),
    R.omit(['cellType']),
  ]),
);

// assignDefaultExportDataValues :: Any -> [ExcelExportData]
const assignDefaultExportDataValues = R.compose(
  R.map(
    R.compose(
      R.over(R.lensProp('name'), R.defaultTo(DEFAULT_WORKSHEET_NAME)),
      R.over(R.lensProp('images'), R.defaultTo([])),
      R.over(R.lensProp('columnWidths'), R.defaultTo([])),
      R.over(R.lensProp('rows'), R.defaultTo([])),
    ),
  ),
  R.unless(R.is(Array), ofArrayLegacy),
);

// prepareExcelWorkbookByConfig :: [ExcelExportData] -> ExcelWorkbook
//   ExcelExportData = {
//      name: String
//      columnWidths: [Int]
//      rows: [[ExcelExportDataCell]]
//      images: [{image: XlsImage, range: XlsImageRange}]
//   }
//
//   ExcelExportDataCell = {
//      value: String
//      cellType: ExcelExportCellType
//   }
//
//   ExcelWorkbook = Object
export const prepareExcelWorkbookByConfig = (rawConfig) => {
  const config = assignDefaultExportDataValues(rawConfig);

  const workbook = new Excel.Workbook({
    useStyles: true,
    useSharedStrings: true,
  });

  workbook.creator = 'Poly';
  workbook.created = new Date();

  config.forEach(({ name, columnWidths, rows, images }) => {
    const worksheet = workbook.addWorksheet(name);

    columnWidths.forEach((columnWidth, index) => {
      const columnNumber = index + 1;
      const column = worksheet.getColumn(columnNumber);
      column.width = columnWidth;
    });

    rows.forEach((rowCells, rowIndex) => {
      const currentRowNum = rowIndex + 1;

      rowCells.forEach((dataCell, index) => {
        const currentCellNumber = index + 1;
        const currentCell = worksheet
          .getRow(currentRowNum)
          .getCell(currentCellNumber);

        const [newCellValues, internalOptions] =
          convertDataCellToXlsCell(dataCell);

        if (internalOptions.singleCellRow) {
          worksheet.mergeCells(
            currentRowNum,
            1,
            currentRowNum,
            columnWidths.length,
          );
        }

        if (internalOptions.mergeDown) {
          const endMergedRowNumber =
            currentRowNum + (internalOptions.mergeDown - 1);
          worksheet.mergeCells(
            currentRowNum,
            currentCellNumber,
            endMergedRowNumber,
            currentCellNumber,
          );
        }

        if (internalOptions.mergeRight) {
          const endCellNumber =
            currentCellNumber + (internalOptions.mergeRight - 1);
          worksheet.mergeCells(
            currentRowNum,
            currentCellNumber,
            currentRowNum,
            endCellNumber,
          );
        }

        Object.assign(currentCell, newCellValues);
      });
    });

    images.forEach(({ image, range }) => {
      const imageId = workbook.addImage(image);
      worksheet.addImage(imageId, range);
    });
  });

  return workbook;
};
