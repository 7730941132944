import * as R from 'ramda';
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from '@poly/client-routing';
import {
  ProjectType,
  ProjectTypeToNameMap,
  RecurringProjectTypes,
} from '@poly/constants';
import { bool, func, shape, string } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { LinkButton, Button, Checkbox } from '@poly/admin-book';
import {
  SidebarRow,
  TooltipButton,
  useNotificationState,
} from '@poly/admin-ui';
import { propEqLegacy, debounce, insertParamsIntoURL } from '@poly/utils';
import { useOutSidebarContext } from '@poly/client-utils';
import { useDispatch } from 'react-redux';

import { TooltipWrapper } from './CloneProjectButton.js';
import { routesNames } from '../../../routes/constants.js';
import { setConversionRecurringProject } from '../../../redux/conversionRecurringProjectReducer.js';

const projectPropType = shape({
  type: string.isRequired,
});

const TooltipWrapperS = styled(TooltipWrapper)`
  padding-top: 10px;
  width: 200px;
`;

const ButtonS = styled(Button)`
  margin: 5px auto;

  svg {
    margin: 7px auto;
  }
`;

const CheckboxS = styled(Checkbox)`
  margin: 5px 0 5px 15px;

  label {
    top: -2px;
  }
`;

// getCheckBoxLabel :: ProjectType -> String
const getCheckBoxLabel = R.compose(
  R.concat('Convert to '),
  R.prop(R.__, ProjectTypeToNameMap),
);

// prepareMutationVariables :: (ProjectType, ID) -> { variables: { input: ChangeProjectTypeInput } }
const prepareMutationVariables = (newProjectType, id) => ({
  variables: {
    input: {
      id,
      newProjectType,
    },
  },
});

const changeProjectTypeMutation = gql`
  mutation changeProjectType($input: ChangeProjectTypeInput!) {
    changeProjectType(input: $input) {
      _id
    }
  }
`;

// getDefaultProjectTypeToConvert :: ProjectType -> ProjectType
const getDefaultProjectTypeToConvert = R.ifElse(
  R.complement(R.equals(ProjectType.WORK_ORDER)),
  R.always(ProjectType.WORK_ORDER),
  R.always(ProjectType.BID),
);

const allowedConversionProjectTypes = [
  ProjectType.WORK_ORDER,
  ProjectType.BID,
  ProjectType.PASS_THROUGH,
];

// getProjectTypesToConvert :: ProjectType -> [ProjectType]
const getProjectTypesToConvert = R.compose(
  R.reject(R.__, allowedConversionProjectTypes),
  R.equals,
);

// isRecurringProject :: RecurringProject -> Boolean
const isRecurringProject = propEqLegacy(
  'type',
  RecurringProjectTypes.recurringProject,
);

// isConvertingRecurringProjectToPM :: { masterProject: RecurringProject, selectedProjectType: String } -> Boolean
const isConvertingRecurringProjectToPM = R.both(
  R.compose(isRecurringProject, R.prop('masterProject')),
  propEqLegacy(
    'selectedProjectType',
    RecurringProjectTypes.preventiveMaintenanceProject,
  ),
);

// getNewPMProjectId :: { data: { changeRecurringProjectType: RecurringProject } } -> String
const getNewPMProjectId = R.path([
  'data',
  'changeRecurringProjectType',
  'projectId',
]);

function ConvertProjectButtonTooltip({
  type,
  projectId,
  mutate,
  loading,
  closeTooltip,
  masterProject,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { closeAllOutSidebars } = useOutSidebarContext();
  const [selectedProjectType, setSelectedProjectType] = useState(
    getDefaultProjectTypeToConvert(type),
  );
  const { showSuccessNotification } = useNotificationState();

  const successNotificationMessage = 'Project was converted successfully.';
  const isRecurringProjectToPM = isConvertingRecurringProjectToPM({
    masterProject,
    selectedProjectType,
  });

  const onChildConversion = async (event) => {
    await mutate(prepareMutationVariables(selectedProjectType, projectId));

    showSuccessNotification(successNotificationMessage);
    closeTooltip(event);
  };

  const setConversionRecurringProjectDebounced = useCallback(
    debounce(5000)(() => dispatch(setConversionRecurringProject(false))),
    [],
  );

  const onRecurringToPMConversion = async (event) => {
    dispatch(setConversionRecurringProject(true));

    const result = await mutate(
      prepareMutationVariables(selectedProjectType, projectId),
    );

    closeAllOutSidebars();

    const newRecurringProjectId = getNewPMProjectId(result);

    navigate(
      insertParamsIntoURL({ projectId: newRecurringProjectId })(
        routesNames.MASTER_PROJECT_CARD,
      ),
    );

    setConversionRecurringProjectDebounced();
    showSuccessNotification(successNotificationMessage);
    closeTooltip(event);
  };

  const projectTypesToConvert = getProjectTypesToConvert(type);

  const onClick = isRecurringProjectToPM
    ? onRecurringToPMConversion
    : onChildConversion;

  return (
    <TooltipWrapperS>
      {isRecurringProject(masterProject) && (
        <CheckboxS
          value={
            selectedProjectType ===
            RecurringProjectTypes.preventiveMaintenanceProject
          }
          onChange={() =>
            setSelectedProjectType(
              RecurringProjectTypes.preventiveMaintenanceProject,
            )
          }
          label="Convert to PM"
          name={RecurringProjectTypes.preventiveMaintenanceProject}
        />
      )}
      {projectTypesToConvert.map((projectType) => (
        <CheckboxS
          value={projectType === selectedProjectType}
          onChange={() => setSelectedProjectType(projectType)}
          label={getCheckBoxLabel(projectType)}
          key={projectType}
          name={projectType}
        />
      ))}
      <ButtonS loader={loading} size="small" onClick={onClick}>
        Convert
      </ButtonS>
    </TooltipWrapperS>
  );
}

ConvertProjectButtonTooltip.propTypes = {
  type: string.isRequired,
  mutate: func.isRequired,
  projectId: string.isRequired,
  closeTooltip: func.isRequired,
  loading: bool.isRequired,
  masterProject: shape({ type: string }),
};

// isProjectAllowedToConvert :: String -> Boolean
const isProjectAllowedToConvert = R.includes(
  R.__,
  allowedConversionProjectTypes,
);
export function ConvertProjectButton({
  type,
  projectId,
  mutate,
  loading,
  masterProject,
  isCard,
}) {
  if (!isProjectAllowedToConvert(type)) return null;

  const tooltipBtnProps = {
    style: { position: 'relative' },
    tooltipProps: { position: 'right', top: '-20px' },
    Wrapper: LinkButton,
    Tooltip: ConvertProjectButtonTooltip,
    tooltipBtnProps: {
      type,
      projectId,
      mutate,
      loading,
      masterProject,
      isCard,
    },
  };

  return (
    <SidebarRow>
      <TooltipButton {...tooltipBtnProps}>Convert Project</TooltipButton>
    </SidebarRow>
  );
}

ConvertProjectButton.propTypes = {
  isCard: bool,
  type: string.isRequired,
  mutate: func.isRequired,
  projectId: string.isRequired,
  loading: bool.isRequired,
  masterProject: shape({ type: string }),
};

export function ConvertRegularProjectButton({ project }) {
  const [mutate, { loading }] = useMutation(changeProjectTypeMutation);
  return (
    <ConvertProjectButton
      mutate={mutate}
      loading={loading}
      type={project.type}
      projectId={project._id}
    />
  );
}

ConvertRegularProjectButton.propTypes = {
  project: projectPropType,
};
