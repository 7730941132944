import * as R from 'ramda';
import { undefinedAsNullDeep } from '@poly/client-utils';
import {
  applySpecWithFields,
  isNilOrEmpty,
  overPropUnlessNil,
  propEqLegacy,
} from '@poly/utils';
import { ClientBranding } from '@poly/constants';

import { prepareAddressFormToObject } from '../../../utils/address.js';
import { selectSingleFileNameWithUpload } from '../../../../utils/files.js';
import {
  isAssetScannerAppEnabled,
  isClientPortalAppEnabled,
  isRequestsAppEnabled,
} from '../form/sections/configsSection.js';

// matchWithOldFileName :: (Client, String) -> FormClient -> Boolean
const matchWithOldFileName = (client, fieldName) =>
  R.converge(R.equals, [
    R.compose(R.path([fieldName, 'fileName']), R.always(client)),
    R.path([fieldName, 'fileName']),
  ]);

// isEmptyUpload :: String -> FormClient -> Boolean
const isEmptyUpload = (fieldName) =>
  R.pathSatisfies(R.isEmpty, [fieldName, 'upload']);

// selectFileByPath :: (Client, String) -> FormClient -> ChangedFormClient
const selectFileByFieldName = (client, fieldName) =>
  R.compose(
    R.when(
      R.either(
        isEmptyUpload(fieldName),
        matchWithOldFileName(client, fieldName),
      ),
      R.omit([fieldName]),
    ),
    R.unless(
      R.propSatisfies(R.isNil, fieldName),
      R.over(R.lensProp(fieldName), selectSingleFileNameWithUpload),
    ),
  );

// hasReportOnlyClientInvoiceCreation :: ClientFormData -> Boolean
const hasReportOnlyClientInvoiceCreation = R.compose(
  R.complement(isNilOrEmpty),
  R.path(['configs', 'clientInvoicing', 'reportOnlyClientInvoiceCreation']),
);

// getRequestsAppConfigs :: Client -> ClientFormData -> RequestAppConfigsInput
const getRequestsAppConfigs = (client) =>
  R.compose(
    R.reject(isNilOrEmpty),
    R.when(
      R.both(
        propEqLegacy('branding', ClientBranding.POLY),
        R.compose(
          R.both(isNilOrEmpty, R.equals(ClientBranding.POLY)),
          R.path(['requestsApp', 'branding']),
          R.always(client),
        ),
      ),
      R.dissocPath(['requestsApp', 'branding']),
    ),
    R.path(['configs', 'requestsApp']),
  );

// isClientInvoicesConsolidated :: ClientFormData -> Boolean
const hasClientInvoicingFields = R.compose(
  R.any(R.complement(isNilOrEmpty)),
  R.values,
  R.pick(['companyLogo', 'address', 'invoiceEmailRecipients']),
  R.pathOr({}, ['configs', 'clientInvoicing']),
);

// applyConfigsIf :: (CheckFunc, ApplyFunc) -> ClientFormData -> Object
// CheckFunc = ClientFormData -> Boolean
// ApplyFunc = ClientFormData -> Object
const applyConfigsIf = (checkFunc, applyFunc) =>
  R.ifElse(checkFunc, applyFunc, R.always({}));

// prepareClientPortalAppConfigs :: ClientFormData -> { clientPortalApp: ClientPortalAppConfigsInput }
const prepareClientPortalAppConfigs = R.applySpec({
  clientPortalApp: {
    allowProjectCreation: R.pathOr(false, [
      'configs',
      'clientPortalApp',
      'allowProjectCreation',
    ]),
    showPMsAndAssets: R.path([
      'configs',
      'clientPortalApp',
      'showPMsAndAssets',
    ]),
    projectServiceTypeId: R.path([
      'configs',
      'clientPortalApp',
      'projectServiceTypeId',
    ]),
  },
});

// prepareRequestsAppConfigs :: Client -> ClientFormData
// -> { logoImage: UploadWithName, requestsApp: RequestAppConfigsInput }
const prepareRequestsAppConfigs = (client) =>
  R.applySpec({
    requestsApp: getRequestsAppConfigs(client),
    logoImage: R.compose(
      R.prop('logoImage'),
      selectFileByFieldName(client, 'logoImage'),
      R.prop('configs'),
    ),
  });

// prepareAssetScannerAppConfigs :: ClientFormData -> { logoImage: UploadWithName, requestsApp: RequestAppConfigsInput }
const prepareAssetScannerAppConfigs = R.applySpec({
  assetScannerApp: {
    allowReplacementCost: R.pathOr(false, [
      'configs',
      'assetScannerApp',
      'allowReplacementCost',
    ]),
    allowProjectCreation: R.pathOr(true, [
      'configs',
      'assetScannerApp',
      'allowProjectCreation',
    ]),
  },
});

// prepareClientInvoicingConfigs :: Client -> ClientFormData -> { clientInvoicing: ClientInvoicingConfigsInput }
const prepareClientInvoicingConfigs = (client) =>
  R.applySpec({
    clientInvoicing: R.compose(
      R.converge(R.mergeRight, [
        R.compose(
          R.reject(isNilOrEmpty),
          R.when(
            R.pathSatisfies(R.isEmpty, ['address', 'formatted_address']),
            R.dissoc('address'),
          ),
          prepareAddressFormToObject,
          selectFileByFieldName(client, 'companyLogo'),
          R.pick(['companyLogo', 'address']),
        ),
        R.compose(
          overPropUnlessNil('invoiceEmailRecipients', R.map(R.prop('value'))),
          R.pick(['invoiceEmailRecipients', 'reportOnlyClientInvoiceCreation']),
        ),
      ]),
      R.path(['configs', 'clientInvoicing']),
    ),
  });

// applyClientConfigByOption :: String -> ClientFormData => ClientConfig
const applyClientConfigByOption = (propsName) =>
  applyConfigsIf(
    R.pathSatisfies(R.complement(R.isNil), ['configs', propsName]),
    R.compose(R.objOf(propsName), R.path(['configs', propsName])),
  );

// clientConfigsToMutation :: Client -> ClientFormData -> CreateClientInput
const clientConfigsToMutation = (client) =>
  R.compose(
    R.when(R.complement(R.isEmpty), R.objOf('configs')),
    R.mergeAll,
    R.juxt([
      applyConfigsIf(isClientPortalAppEnabled, prepareClientPortalAppConfigs),
      applyConfigsIf(isRequestsAppEnabled, prepareRequestsAppConfigs(client)),
      applyConfigsIf(isAssetScannerAppEnabled, prepareAssetScannerAppConfigs),
      applyConfigsIf(
        R.either(hasClientInvoicingFields, hasReportOnlyClientInvoiceCreation),
        prepareClientInvoicingConfigs(client),
      ),
      applyClientConfigByOption('requiresSignOffSheet'),
      applyClientConfigByOption('enablePurchaseOrder'),
      applyClientConfigByOption('requesterSurvey'),
      applyClientConfigByOption('isPORequiredForProject'),
      applyClientConfigByOption('projectPriorities'),
      R.applySpec({
        adminApp: {
          allowProjectCreation: R.pathOr(true, [
            'configs',
            'adminApp',
            'allowProjectCreation',
          ]),
        },
      }),
    ]),
  );

// formDataToClientMutation :: ( Client, ClientFormData ) -> CreateClientInput
export const formDataToClientMutation = R.curry((client, update) =>
  R.compose(
    undefinedAsNullDeep,
    R.converge(R.mergeRight, [
      R.omit(['configs']),
      clientConfigsToMutation(client),
    ]),
    R.omit([
      '_id',
      'aacStaffRates',
      'parentClient',
      'isChildClient',
      'clientHasChildren',
      'clientManagers',
      'isInvoicingEmailEnabled',
      'submitProjectWebFormUrl',
    ]),
    R.when(
      R.propSatisfies(R.isNil, 'parentClientId'),
      R.omit(['parentClientId']),
    ),
    prepareAddressFormToObject,
    undefinedAsNullDeep,
    selectFileByFieldName(client, 'contractFile'),
  )(update),
);

/* eslint-disable @cspell/spellchecker */
// prepareSAPGLCode :: { sapGLCode: String } -> String
const prepareSAPGLCode = R.ifElse(
  R.either(
    R.propSatisfies(isNilOrEmpty, 'sapGLCode'),
    R.propSatisfies(R.compose(R.isEmpty, R.trim), 'sapGLCode'),
  ),
  R.always(null),
  R.propOr(null, 'sapGLCode'),
);

// formDataToEditClientMutation :: ( Client, ClientFormData ) -> UpdateClientInput
export const formDataToEditClientMutation = (client, update) =>
  R.compose(
    formDataToClientMutation(client),
    applySpecWithFields({
      fax: R.propOr('', 'fax'),
      sapGLCode: prepareSAPGLCode,
      website: R.propOr(null, 'website'),
      managerId: R.propOr(null, 'managerId'),
      clientGroupEmail: R.propOr(null, 'clientGroupEmail'),
      financial: {
        divisionAccountId: R.pathOr('', ['financial', 'divisionAccountId']),
        invoiceAttentionTo: R.pathOr('', ['financial', 'invoiceAttentionTo']),
        ourvendorno: R.pathOr('', ['financial', 'ourvendorno']),
        costCenter: R.pathOr(null, ['financial', 'costCenter']),
        accountingEmail: R.pathOr('', ['financial', 'accountingEmail']),
        vaAccountNumber: R.pathOr('', ['financial', 'vaAccountNumber']),
        vaSplitPercent: R.pathOr(0, ['financial', 'vaSplitPercent']),
      },
    }),
    R.dissocPath(['configs', 'requesterSurvey', 'questions']),
    R.omit([
      'tmMarkupRules',
      'isUMCCheckboxDisabled',
      'districts',
      'woCharge',
      'financialSettingsTitle',
      'clientSettingsTitle',
      'portalSettingsTitle',
      'clientPortalConfigsTitle',
      'requestConfigsTitle',
      'clientPortalConfigsTitle',
    ]),
  )(update);
/* eslint-enable @cspell/spellchecker */
