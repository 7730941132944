import * as R from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';
import { bool, func, node, oneOfType, string } from 'prop-types';

import { getThemeColor, getThemeFont } from '../utils.js';
import { defaultTheme } from '../ThemeProvider/index.js';

const CheckboxLabel = styled.label`
  position: relative;
  padding: ${({ position }) =>
    position === 'left' ? '0 0 0 25px' : '0 25px 0 0'};
  z-index: 1;
  cursor: pointer;
`;

function CheckboxContainer({
  className,
  onChange,
  label,
  value,
  name,
  disabled,
  dataTestId,
  position,
  onClick,
}) {
  const onKeyDown = (e) => {
    e.preventDefault();
  };

  return (
    <div className={className}>
      {label && position === 'left' ? (
        <CheckboxLabel htmlFor={name} position={position}>
          {label}
        </CheckboxLabel>
      ) : null}
      <input
        type="checkbox"
        id={name}
        onChange={() => onChange(!value)}
        onClick={onClick}
        disabled={disabled}
        data-testid={dataTestId}
        {...{ value }}
        checked={value}
        onKeyDown={onKeyDown}
      />
      <div className="state-re-presenter" />
      {label && position === 'right' ? (
        <CheckboxLabel htmlFor={name} position={position}>
          {label}
        </CheckboxLabel>
      ) : null}
    </div>
  );
}

const checkboxContainerPropTypes = {
  onChange: func.isRequired,
  value: oneOfType([bool, string]).isRequired,
  name: string.isRequired,
  className: string,
  label: oneOfType([string, node]),
  disabled: bool,
  dataTestId: string,
  position: string,
  onClick: func,
};

CheckboxContainer.propTypes = checkboxContainerPropTypes;
CheckboxContainer.defaultProps = {
  className: '',
  label: '',
  disabled: false,
  dataTestId: 'checkbox',
  position: 'left',
};

const markBase = `
  content: "";
  display: block;
  position: absolute;
`;

const CheckMark = `
  ${markBase}
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid ${defaultTheme.colors.white};
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
`;

const HorizontalLine = css`
  ${markBase};
  left: 2px;
  top: 5px;
  width: 9px;
  border: solid ${defaultTheme.colors.primaryRegular};
  border-width: 0 1px 2px 0;
`;

const getMark = R.cond([
  [R.prop('indeterminate'), R.always(HorizontalLine)],
  [R.prop('value'), R.always(CheckMark)],
  [R.T, R.always('')],
]);

const getStateRePresenterBg = ({ value, indeterminate, ...props }) =>
  value && !indeterminate
    ? getThemeColor(['primaryRegular'])(props)
    : getThemeColor(['white'])(props);

export const Checkbox = styled(CheckboxContainer)`
  font-weight: ${getThemeFont(['regular'])};
  height: 14px;
  font-size: ${({ size }) => (size === 'small' ? '10px' : '11px')};
  line-height: 14px;
  color: ${getThemeColor(['midDark'])};
  width: ${({ label }) => (label ? 'auto' : '14px')};
  display: ${({ label }) => (label ? 'inline-block' : 'block')};
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  input {
    top: 0;
    left: ${({ position }) => (position === 'left' ? '0' : 'auto')};
    right: ${({ position }) => (position === 'left' ? 'auto' : '0')};
    position: absolute;
    opacity: 0;
    z-index: 1;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }

  .state-re-presenter {
    top: 0;
    left: ${({ position }) => (position === 'left' ? '0' : 'auto')};
    right: ${({ position }) => (position === 'left' ? 'auto' : '0')};
    height: 14px;
    width: 14px;
    position: absolute;
    border-radius: 3px;
    border: solid 1px ${getThemeColor(['primaryLighter6'])};
    background-color: ${({ disabled, ...props }) =>
      disabled ? getThemeColor(['midLight']) : getStateRePresenterBg(props)};

    &:after {
      ${getMark};
    }
  }
`;

Checkbox.displayName = 'Checkbox';
Checkbox.propTypes = {
  ...checkboxContainerPropTypes,
  indeterminate: bool,
  position: string,
};
Checkbox.defaultProps = {
  indeterminate: false,
  position: 'left',
};
