import React from 'react';
import { shape } from 'prop-types';

import {
  FixedWidthColumn,
  RightAlignedFlexColumn,
  SearchResultItemContainer,
} from './common/wrappers.js';
import {
  SearchResultItemDivider,
  SearchResultItemLinked,
  SearchResultItemTitle,
} from './common/components.js';
import { getWorkPhoneFormatted } from '../../../tables/index.js';
import { highlightedTextPropType } from './common/propTypes.js';

export function UsersSearchResultItem({ fullName, email, status, ...props }) {
  return (
    <SearchResultItemContainer>
      <FixedWidthColumn width="200px">
        <SearchResultItemLinked>{fullName}</SearchResultItemLinked>
      </FixedWidthColumn>
      <RightAlignedFlexColumn>
        <SearchResultItemLinked>{email}</SearchResultItemLinked>
        <SearchResultItemDivider />
        <SearchResultItemTitle>
          {getWorkPhoneFormatted(props)}
        </SearchResultItemTitle>
      </RightAlignedFlexColumn>
    </SearchResultItemContainer>
  );
}

UsersSearchResultItem.propTypes = {
  status: highlightedTextPropType.isRequired,
  client: shape({ name: highlightedTextPropType.isRequired }),
  fullName: highlightedTextPropType.isRequired,
  email: highlightedTextPropType,
};

UsersSearchResultItem.defaultProps = {
  email: '',
  client: null,
};
