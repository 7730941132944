import * as R from 'ramda';
import { isNilOrEmpty } from '@poly/utils';

// getDateIsoStringByProp :: TimesheetsReportInput -> String
const getDateIsoStringByProp = (propName) =>
  R.compose(R.invoker(0, 'toISOString'), R.prop(propName));

// getTimeSheetReportSubscriptionQuery :: TimesheetsReportInput -> ElasticQuery
export const getTimeSheetReportSubscriptionQuery = R.compose(
  R.ifElse(
    isNilOrEmpty,
    R.always(null),
    R.assocPath(['bool', 'filter'], R.__, {}),
  ),
  R.reject(isNilOrEmpty),
  R.juxt([
    R.ifElse(
      R.prop('ownerId'),
      R.applySpec({ term: { ownerId: R.prop('ownerId') } }),
      R.always(null),
    ),
    R.ifElse(
      R.both(R.propIs(Date, 'startDate'), R.propIs(Date, 'endDate')),
      R.compose(
        R.assocPath(['range', 'date'], R.__, {}),
        R.applySpec({
          gte: getDateIsoStringByProp('startDate'),
          lte: getDateIsoStringByProp('endDate'),
        }),
      ),
      R.always(null),
    ),
  ]),
);
