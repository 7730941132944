import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import {
  MainHeader,
  PageHeaderContainer,
  HeaderLinksWrapper,
  TextButton,
} from '@poly/admin-book';
import { gql, useQuery } from '@apollo/client';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { TableSearchInput, useModalContext } from '@poly/admin-ui';

import { MergeAssetComponentsButton } from '../AssetManufacturers/MergeAssetComponentsButton.js';
import { mergeAssetComponentsEntity } from '../AssetManufacturers/merge-config.js';
import {
  addAssetManufacturerModelFormId,
  AssetManufacturerModelForm,
} from './AssetManufacturerModelForm.js';

const FlexWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ASSET_MANUFACTURE_QUERY = gql`
  query ASSET_MANUFACTURE_QUERY($id: ID!) {
    assetManufacturer(id: $id) {
      name
    }
  }
`;

function AddManufactureModelButton({ manufacturerId }) {
  const { openModalForm } = useModalContext();
  const onClick = () =>
    openModalForm({
      id: addAssetManufacturerModelFormId,
      title: 'Add New Asset Model',
      formId: addAssetManufacturerModelFormId,
      btnCaption: 'Save',
      content: <AssetManufacturerModelForm manufacturerId={manufacturerId} />,
    });

  return <TextButton onClick={onClick}>Add New Model</TextButton>;
}

AddManufactureModelButton.propTypes = {
  manufacturerId: string.isRequired,
};

export function AssetManufactureModelPageHeader({
  manufacturerId,
  selectedModels,
  setSelectedModels,
  assetModels,
  hasAccessToUpdateAssetManufacture,
}) {
  const { data } = useQuery(ASSET_MANUFACTURE_QUERY, {
    variables: { id: manufacturerId },
  });

  const manufactureName = R.path(['assetManufacturer', 'name'], data);

  return (
    <PageHeaderContainer>
      <MainHeader>{manufactureName} Models</MainHeader>
      <FlexWrapper>
        <TableSearchInput />
        <HeaderLinksWrapper>
          <MergeAssetComponentsButton
            documents={assetModels}
            selectedRows={selectedModels}
            setSelectedRows={setSelectedModels}
            mergeEntity={mergeAssetComponentsEntity.MODEL}
          />
          {hasAccessToUpdateAssetManufacture && (
            <AddManufactureModelButton manufacturerId={manufacturerId} />
          )}
        </HeaderLinksWrapper>
      </FlexWrapper>
    </PageHeaderContainer>
  );
}

AssetManufactureModelPageHeader.propTypes = {
  manufacturerId: string.isRequired,
  selectedModels: arrayOf(string),
  setSelectedModels: func,
  assetModels: arrayOf(
    shape({ _id: string.isRequired, name: string.isRequired }),
  ),
  hasAccessToUpdateAssetManufacture: bool.isRequired,
};
