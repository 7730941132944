import * as R from 'ramda';
import React from 'react';
import { bool, func, number, string } from 'prop-types';

import { DiscountSelect } from '../../PayClientPayments/DiscountSelect.js';

// isDeductionGLRequired :: Float -> Boolean
const isDeductionGLRequired = R.both(R.is(Number), R.gt(R.__, 0));

export function DeductionGLSelect({
  submitting,
  deductionGL,
  currentBalance,
  deductionAmount,
  onDeductionGLChange,
}) {
  return (
    <DiscountSelect
      isClearable
      value={deductionGL}
      onChange={onDeductionGLChange}
      disabled={submitting || currentBalance < 0}
      required={isDeductionGLRequired(deductionAmount)}
    />
  );
}

DeductionGLSelect.propTypes = {
  submitting: bool,
  deductionGL: string,
  currentBalance: number,
  deductionAmount: number,
  onDeductionGLChange: func,
};
