import { arrayOf, number, oneOf, shape, string } from 'prop-types';

export const supplierPropTypes = shape({
  _id: string.isRequired,
  status: string.isRequired,
  company: shape({
    name: string.isRequired,
    phone: string,
    address: shape({
      formatted_address: string,
    }),
    email: string,
  }),
  rating: shape({
    average: number.isRequired,
    stars: arrayOf(number).isRequired,
  }),
  rates: shape({
    hourly: number,
    overTime: number,
    trip: number,
    emergency: number,
  }),
});

export const assignSupplierFormTypePropTypes = oneOf([
  'assignSupplierFormTypes',
  'addSupplierFormTypes',
  'assignAddedSupplierFormTypes',
  'sendWorkOrderConfirmation',
  'generateWorkOrderConfirmation',
]);
