import styled from 'styled-components';

export const NoRecordsHeader = styled.h2`
  text-align: center;
  color: ${(props) => props.theme.colors.primaryLighter4};
  font-size: 18px;
  padding: 100px;
`;

NoRecordsHeader.displayName = 'NoRecordsHeader';
