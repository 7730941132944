import styled from 'styled-components';
import { getThemeFont, getThemeProp, getThemeColor } from '@poly/admin-book';

import { TableWrapper } from '../commonTimeSheetTableComps.js';

export const TimesheetTableS = styled(TableWrapper)`
  height: auto;

  td:nth-child(1),
  th:nth-child(1),
  th:nth-child(2),
  th:nth-child(3) {
    width: 150px;
  }

  th:nth-child(4),
  th:nth-child(5),
  th:nth-child(6) {
    width: 90px;
  }

  th:nth-child(8),
  th:nth-child(9) {
    width: 40px;
  }

  td:nth-child(2) {
    padding: 0;
  }

  table {
    td:nth-child(1),
    td:nth-child(2) {
      width: 150px;
    }

    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5) {
      width: 90px;
    }

    td:nth-child(7),
    td:nth-child(8) {
      width: 40px;
    }

    tr:last-child {
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
    }

    border-collapse: collapse;
    td {
      background-color: ${getThemeColor(['white'])};
    }

    tbody {
      overflow-y: auto;

      > tr > td {
        vertical-align: baseline;
      }
    }
  }

  & > thead,
  & > tbody {
    overflow-y: auto;

    > tr > td {
      vertical-align: middle;
    }
  }
`;

export const TimesheetTableEmpty = styled.div.attrs(() => ({
  children: 'No entries for selected week',
}))`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 20px;
  color: ${getThemeColor(['primaryLighter1'])};
`;

export const TimesheetSummaryWidgetWrapper = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${getThemeColor(['accent2Lighter5'])} !important;
  border-left: 1px solid ${getThemeColor(['midLight'])};
  border-right: 1px solid ${getThemeColor(['midLight'])};
  border-bottom: 1px solid ${getThemeColor(['midLight'])};
  border-bottom-left-radius: ${getThemeProp(['defaultBorderRadius'])};
  border-bottom-right-radius: ${getThemeProp(['defaultBorderRadius'])};
`;

export const FigureLabel = styled.div`
  color: ${getThemeColor(['primaryLighter4'])};
  font-weight: ${getThemeFont(['regular'])};
  font-size: 14px;
  line-height: 20px;
`;

export const FigureValue = styled.div`
  color: ${getThemeColor(['primaryLighter'])};
  font-weight: ${getThemeFont(['regular'])};
  font-size: 14px;
  line-height: 20px;
  margin-left: 10px;
`;

export const FigureLabelAndValueWrapper = styled.div`
  display: flex;
  width: auto !important;
`;
