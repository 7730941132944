import React from 'react';
import { string } from 'prop-types';
import { useNavigate } from '@poly/client-routing';
import { ForgotPasswordPageBase } from '@poly/common-ui';

export function ForgotPasswordPageBaseAdmin({ title, loginRoute }) {
  const navigate = useNavigate();

  const onSuccess = (response) =>
    navigate(`${loginRoute}?forgotPasswordMessage=${response.message}`);
  const goToLogin = () => navigate(loginRoute);

  return (
    <ForgotPasswordPageBase
      title={title}
      goToLogin={goToLogin}
      onSuccess={onSuccess}
    />
  );
}

ForgotPasswordPageBaseAdmin.propTypes = {
  loginRoute: string.isRequired,
  title: string.isRequired,
};
