import {
  DatePicker,
  DefaultBodyWrapper,
  Loader,
  MainHeader,
  PageHeaderContainer,
  Text,
} from '@poly/admin-book';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import {
  AccountingPeriodStatuses,
  AccountingPeriodTypes,
} from '@poly/constants';
import { SubmitBtn } from '@poly/admin-ui';
import { formatBackLink, useLocation, useNavigate } from '@poly/client-routing';
import {
  alwaysNewDate,
  insertQueryParamsIntoURL,
  ensureIsDate,
  formatDate,
  ramdaUseWith,
} from '@poly/utils';

import { addDays, endOfYear, startOfYear } from 'date-fns';
import { instanceOf, string, bool, func, oneOfType } from 'prop-types';

import {
  ButtonsContainerS,
  FormContainerS,
} from '../../components/FormCardContainer.js';
import { FlexContainer } from '../../components/FlexContainer.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { CancelFormBtn } from '../../components/Buttons.js';
import { routesNames } from '../../routes/index.js';

const Label = styled(Text)`
  width: 200px;
  font-weight: bold;
`;

const PeriodContainerS = styled(FlexContainer)`
  margin-bottom: 15px;
  width: 100%;
`;

const DatePickerContainer = styled(FlexContainer)`
  margin-right: 15px;
`;

function PeriodContainer({
  label,
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  disabledStartDate,
}) {
  return (
    <PeriodContainerS>
      <Label>{label}</Label>
      <DatePickerContainer>
        <DatePicker
          width="250px"
          value={startDate}
          onChange={onChangeStartDate}
          disabled={disabledStartDate}
        />
      </DatePickerContainer>
      <DatePickerContainer>
        <DatePicker width="250px" value={endDate} onChange={onChangeEndDate} />
      </DatePickerContainer>
    </PeriodContainerS>
  );
}
PeriodContainer.propTypes = {
  label: string.isRequired,
  startDate: oneOfType([string, instanceOf(Date)]).isRequired,
  endDate: oneOfType([string, instanceOf(Date)]).isRequired,
  onChangeStartDate: func.isRequired,
  onChangeEndDate: func.isRequired,
  disabledStartDate: bool,
};

const filtersNames = {
  currentPeriodStartDate: 'currentPeriodStartDate',
  currentPeriodEndDate: 'currentPeriodEndDate',
  nextPeriodPeriodStartDate: 'nextPeriodStartDate',
  nextPeriodEndDate: 'nextPeriodEndDate',
};

const accountingPeriodsQuery = gql`
  query accountingPeriods($input: AccountingPeriodsInput) {
    accountingPeriods(input: $input) {
      hits {
        _id
        startDate
        endDate
        status
      }
    }
  }
`;

// stringifySearchFilterProps :: SearchFilter -> SearchFilter
const stringifySearchFilterProps = R.map(formatDate);

// isSearchFilterChanged :: SearchFilter -> SearchFilter -> Boolean
const isSearchFilterChanged = ramdaUseWith(R.equals, [
  stringifySearchFilterProps,
  stringifySearchFilterProps,
]);

const defaultInitialValues = (lastOpenedAccountingPeriod) => {
  if (lastOpenedAccountingPeriod) {
    const currentPeriodStartDate = lastOpenedAccountingPeriod.startDate;
    const currentPeriodEndDate = lastOpenedAccountingPeriod.endDate;
    const nextPeriodStartDate = addDays(ensureIsDate(currentPeriodEndDate), 1);
    const nextPeriodEndDate = endOfYear(ensureIsDate(nextPeriodStartDate));
    return {
      currentPeriod: {
        startDate: currentPeriodStartDate,
        endDate: currentPeriodEndDate,
      },
      nextPeriod: {
        startDate: nextPeriodStartDate,
        endDate: nextPeriodEndDate,
      },
    };
  }

  const currentDate = alwaysNewDate();
  const endOfCurrentYearDate = endOfYear(ensureIsDate(currentDate));
  const startOfNextYearDate = addDays(ensureIsDate(endOfCurrentYearDate), 1);
  const endOfNextYearDate = endOfYear(ensureIsDate(startOfNextYearDate));
  return {
    currentPeriod: {
      startDate: startOfYear(ensureIsDate(currentDate)),
      endDate: endOfCurrentYearDate,
    },
    nextPeriod: {
      startDate: startOfNextYearDate,
      endDate: endOfNextYearDate,
    },
  };
};

export function CloseFinancialYearPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const cancelRedirectUrl = formatBackLink({
    router: location.state,
    withQuery: false,
    fallbackLink: routesNames.FINANCIAL_DIRECTORY,
  });

  const { data, loading } = useQuery(accountingPeriodsQuery, {
    variables: {
      input: {
        status: AccountingPeriodStatuses.OPENED,
        type: AccountingPeriodTypes.YEAR,
        size: 1,
        sort: { endDate: 1 },
      },
    },
    fetchPolicy: 'network-only',
  });

  const lastOpenedAccountingPeriod = R.path(
    ['accountingPeriods', 'hits', 0],
    data,
  );

  const { currentPeriod, nextPeriod } = defaultInitialValues(
    lastOpenedAccountingPeriod,
  );

  const { searchFilters, handlers } = useSearchFilters([
    {
      name: filtersNames.currentPeriodStartDate,
      defaultValue: currentPeriod.startDate,
    },
    {
      name: filtersNames.currentPeriodEndDate,
      defaultValue: currentPeriod.endDate,
      subscribers: [
        [
          [filtersNames.nextPeriodPeriodStartDate],
          (date) => addDays(ensureIsDate(date), 1),
        ],
      ],
    },
    {
      name: filtersNames.nextPeriodPeriodStartDate,
      defaultValue: nextPeriod.startDate,
    },
    {
      name: filtersNames.nextPeriodEndDate,
      defaultValue: nextPeriod.endDate,
    },
  ]);

  const onGenerateClick = () => {
    navigate(
      insertQueryParamsIntoURL(searchFilters, routesNames.POST_CLOSING_JOURNAL),
    );
  };

  const defaultValues = {
    [filtersNames.currentPeriodStartDate]: currentPeriod.startDate,
    [filtersNames.currentPeriodEndDate]: currentPeriod.endDate,
    [filtersNames.nextPeriodPeriodStartDate]: nextPeriod.startDate,
    [filtersNames.nextPeriodEndDate]: nextPeriod.endDate,
  };

  const pristine = isSearchFilterChanged(defaultValues, searchFilters);

  useEffect(
    () =>
      navigate(`${location.pathname}${location.search}${location.hash}`, {
        state: { ...location.state, pristine },
        replace: true,
      }),
    [pristine],
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Financial Year Closing</MainHeader>
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <FormContainerS submitBtnLabel="Generate Closing Journal">
          <PeriodContainer
            label="Current Financial Year"
            startDate={searchFilters[filtersNames.currentPeriodStartDate]}
            endDate={searchFilters[filtersNames.currentPeriodEndDate]}
            onChangeStartDate={handlers[filtersNames.currentPeriodStartDate]}
            onChangeEndDate={handlers[filtersNames.currentPeriodEndDate]}
          />
          <PeriodContainer
            label="Next Financial Year"
            startDate={searchFilters[filtersNames.nextPeriodPeriodStartDate]}
            endDate={searchFilters[filtersNames.nextPeriodEndDate]}
            onChangeStartDate={handlers[filtersNames.nextPeriodPeriodStartDate]}
            onChangeEndDate={handlers[filtersNames.nextPeriodEndDate]}
          />
          <ButtonsContainerS>
            <CancelFormBtn redirectUrl={cancelRedirectUrl} />
            <SubmitBtn onClick={onGenerateClick}>
              Generate Closing Journal
            </SubmitBtn>
          </ButtonsContainerS>
        </FormContainerS>
      </DefaultBodyWrapper>
    </>
  );
}
