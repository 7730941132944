import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { Loader, Table } from '@poly/admin-book';
import {
  commonSortQuery,
  useHighlightMatchesBySearch,
} from '@poly/client-utils';
import {
  getServiceTypes,
  supplierAddressColumn,
  SUPPLIER_SEARCH_CHANGED,
  useMapConfigToTableProps,
  useSearchQueryWithPagination,
  useTableSorting,
} from '@poly/admin-ui';
import { assocBy, formatDate } from '@poly/utils';

import { bool, object } from 'prop-types';
import { supplierNameColumn } from '../columns/suppliers.js';
import { SUPPLIERS_BY_SEARCH_TABLE } from '../../core/hooks/suppliers/queries.js';

export const getTableConfig = (showDateColumn) => [
  supplierNameColumn,
  supplierAddressColumn,
  ['Service Types', R.prop('serviceTypesStr')],
  ...(showDateColumn
    ? [
        [
          'Date Created',
          R.compose(formatDate, R.prop('createdAt')),
          commonSortQuery(['createdAt']),
        ],
      ]
    : []),
];

const SuppliersTableComp = styled(Table)`
  td:last-child {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

// prepareSupplierData :: SearchSuppliers -> [{serviceTypesStr: String, ...Supplier}]
const prepareSupplierData = R.compose(
  R.map(assocBy('serviceTypesStr', getServiceTypes)),
  R.pathOr([], ['searchSuppliers', 'hits']),
);

export function SuppliersTable({ query, showDateColumn }) {
  const { sort: sortQuery, ...tableSortingProps } = useTableSorting({
    tableConfig: getTableConfig(showDateColumn),
    column: 1,
  });

  const { data, loading } = useSearchQueryWithPagination(
    SUPPLIERS_BY_SEARCH_TABLE,
    SUPPLIER_SEARCH_CHANGED,
    {
      queryName: 'searchSuppliers',
      fetchPolicy: 'network-only',
      elasticQuery: query,
      skip: !query,
      sortQuery,
    },
  );

  const { rows, ...tableProps } = useMapConfigToTableProps(
    prepareSupplierData,
    getTableConfig(showDateColumn),
    data,
  );

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    [
      ['company', 'address', 'formatted_address'],
      ['company', 'name'],
      ['serviceTypesStr'],
    ],
    rows,
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <SuppliersTableComp
      {...tableProps}
      {...tableSortingProps}
      rows={highlightedRows}
    />
  );
}

SuppliersTable.propTypes = {
  // eslint-disable-next-line react/prop-types,react/forbid-prop-types
  query: object,
  showDateColumn: bool,
};
SuppliersTable.displayName = 'SuppliersTable';
