import * as R from 'ramda';
import { gql, useApolloClient } from '@apollo/client';
import { isSameDay } from 'date-fns';
import { ALL } from '@poly/admin-ui';
import { pathOrNothingUI } from '@poly/client-utils';
import { formatDate, ensureIsDate } from '@poly/utils';
import { NOTHING_UI_STRING, supplierFinTermsUI } from '@poly/constants';

import {
  transformInvoicesForExport,
  calculateDeductionsTotal,
  calculatePaidTotal,
} from './paySuppliersUtils/payInvoicesUtils.js';
import { paySupplierOptions } from './paySuppliersUtils/generateSearchInvoicesQuery.js';

const getDiscountAccountsQuery = gql`
  query getDiscountAccountsQuery($filters: AccountFilters) {
    getAccounts(filters: $filters) {
      hits {
        _id
        code
        name
      }
    }
  }
`;

const supplierNameQuery = gql`
  query supplierNameQuery($supplierId: ID!) {
    supplier(id: $supplierId) {
      _id
      company {
        name
      }
    }
  }
`;

const masterSupplierNameQuery = gql`
  query masterSupplierNameQuery($masterSupplierId: ID!) {
    masterSupplier(id: $masterSupplierId) {
      _id
      company {
        name
      }
    }
  }
`;

const clientNameQuery = gql`
  query clientNameQuery($clientId: ID!) {
    client(id: $clientId) {
      _id
      name
    }
  }
`;

const serviceTypeNameQuery = gql`
  query serviceTypeNameQuery($serviceTypeId: ID!) {
    serviceType(id: $serviceTypeId) {
      _id
      name
    }
  }
`;

const projectIdQuery = gql`
  query projectIdQuery($projectId: ID!) {
    project(id: $projectId) {
      _id
      projectId
    }
  }
`;

const invoiceNumberQuery = gql`
  query invoiceNumberQuery($invoiceId: ID!) {
    invoice(id: $invoiceId) {
      _id
      invoiceNumber
    }
  }
`;

export const supplierPaymentTypeUICaptions = {
  [paySupplierOptions.CHECK]: 'Check',
  [paySupplierOptions.PAY_BY_CREDIT_CARD]: 'Credit Card',
  [paySupplierOptions.PAY_BY_ACH]: 'ACH',
};

function formatInvoiceDateRange(startDate, endDate) {
  if (!startDate && !endDate) {
    return 'All';
  }
  if (isSameDay(ensureIsDate(startDate), ensureIsDate(endDate))) {
    return formatDate(startDate);
  }

  return `${formatDate(startDate)} - ${formatDate(endDate)}`;
}

export function usePaySupplierInvoicesExportData(
  {
    invoices,
    filters: {
      terms,
      clientId,
      projectId,
      invoiceId,
      supplierId,
      invoiceDate,
      paymentType,
      pastDueDate,
      serviceTypeId,
      paymentDueDate,
      masterSupplierId,
      showOnlyWarnings,
      w9Absent,
    },
  },
  exportFn,
) {
  const apolloClient = useApolloClient();

  const onClick = async () => {
    let supplierName = NOTHING_UI_STRING;
    let masterName = NOTHING_UI_STRING;
    let clientName = NOTHING_UI_STRING;
    let serviceTypeName = NOTHING_UI_STRING;
    let projectNumber = NOTHING_UI_STRING;
    let invoiceNumber = NOTHING_UI_STRING;

    if (supplierId) {
      const supplierRes = await apolloClient.query({
        query: supplierNameQuery,
        variables: { supplierId },
      });
      supplierName = pathOrNothingUI(
        ['data', 'supplier', 'company', 'name'],
        supplierRes,
      );
    }

    if (masterSupplierId) {
      const masterSupplierRes = await apolloClient.query({
        query: masterSupplierNameQuery,
        variables: { masterSupplierId },
      });
      masterName = pathOrNothingUI(
        ['data', 'masterSupplier', 'company', 'name'],
        masterSupplierRes,
      );
    }

    if (clientId && clientId !== ALL) {
      const clientRes = await apolloClient.query({
        query: clientNameQuery,
        variables: { clientId },
      });
      clientName = pathOrNothingUI(['data', 'client', 'name'], clientRes);
    }

    if (serviceTypeId) {
      const serviceTypeRes = await apolloClient.query({
        query: serviceTypeNameQuery,
        variables: { serviceTypeId },
      });
      serviceTypeName = pathOrNothingUI(
        ['data', 'serviceType', 'name'],
        serviceTypeRes,
      );
    }

    if (projectId) {
      const projectRes = await apolloClient.query({
        query: projectIdQuery,
        variables: { projectId },
      });
      projectNumber = pathOrNothingUI(
        ['data', 'project', 'projectId'],
        projectRes,
      );
    }

    if (invoiceId) {
      const invoiceRes = await apolloClient.query({
        query: invoiceNumberQuery,
        variables: { invoiceId },
      });
      invoiceNumber = pathOrNothingUI(
        ['data', 'invoice', 'invoiceNumber'],
        invoiceRes,
      );
    }

    const { data } = await apolloClient.query({
      query: getDiscountAccountsQuery,
      variables: {
        filters: {
          isSupplierPaymentDeduction: true,
        },
      },
    });
    const deductionsAccounts = R.pathOr([], ['getAccounts', 'hits'], data);

    const deductionsTotal = calculateDeductionsTotal(invoices);
    const paidTotal = calculatePaidTotal(invoices);

    const invoicesWithDeductions = transformInvoicesForExport(
      deductionsAccounts,
      invoices,
    );

    await exportFn({
      invoices: invoicesWithDeductions,
      deductionsTotal,
      paidTotal,

      filterValues: {
        supplierName,
        masterName,
        clientName,
        serviceTypeName,
        projectNumber,
        invoiceNumber,
        invoiceDateUI: formatInvoiceDateRange(
          invoiceDate.startDate,
          invoiceDate.endDate,
        ),
        paymentDueDateUI: formatInvoiceDateRange(
          paymentDueDate.startDueDate,
          paymentDueDate.endDueDate,
        ),
        pastDueDateUI: pastDueDate ? 'Yes' : 'No',
        showOnlyWithWarningsUI: showOnlyWarnings ? 'Yes' : 'No',
        w9AbsentUI: w9Absent ? 'Yes' : 'No',
        termsUI: pathOrNothingUI([terms], supplierFinTermsUI),
        paymentTypeUI: pathOrNothingUI(
          [paymentType],
          supplierPaymentTypeUICaptions,
        ),
      },
    });
  };

  return { onClick, disabled: invoices.length === 0 };
}
