import React from 'react';
import styled from 'styled-components';
import { Text } from '@poly/admin-book';

const TextS = styled(Text)`
  margin-top: 20px;
  font-size: 14px;
`;

const NoResultBlock = styled(Text)`
  text-align: center;
  font-size: 14px;
`;

export function RecentProjectsTablePlaceholder() {
  return (
    <TextS>
      Please select a client and date range for the report then hit Search
    </TextS>
  );
}

export function RecentProjectsNoResultsPlaceholder() {
  return <NoResultBlock>No Search Results</NoResultBlock>;
}
