import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';
import { keywordSortQuery, highlightMatchesInObject } from '@poly/client-utils';
import { Loader, Table } from '@poly/admin-book';
import { assocBy } from '@poly/utils';
import { MasterSupplierLink } from '../../../components/Links.js';
import { getServiceTypes } from '../../tables/index.js';
import { useTableSorting } from '../../../hooks/useTableSorting.js';
import { useSearchMasterSuppliersQuery } from '../hooks/useSearchMasterSuppliersQuery.js';
import { useSetItemsCount } from '../../../hooks/useSetItemsCount.js';
import { useMapConfigToTableProps } from '../../../hooks/useMapConfigToTableProps.js';

const MasterSuppliersTableComp = styled(Table)`
  td:last-child {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

// highlightMasterSuppliersMatches :: String -> Supplier -> Supplier
const highlightMasterSuppliersMatches = (search) => (object) =>
  highlightMatchesInObject({
    object,
    paths: [
      ['company', 'name'],
      ['company', 'address', 'formatted_address'],
      ['serviceTypesStr'],
    ],
    search,
  });

// prepareMasterSuppliersForTable :: String -> MasterSuppliersSearchResult -> [MasterSupplier]
const prepareMasterSuppliersForTable = (searchTerm) =>
  R.compose(
    R.map(
      R.compose(
        highlightMasterSuppliersMatches(searchTerm),
        assocBy('serviceTypesStr', getServiceTypes),
      ),
    ),
    R.pathOr([], ['searchMasterSuppliers', 'hits']),
  );

const masterSuppliersTableConfig = (masterSupplierRootUrl) => [
  [
    'Name',
    (props) => (
      <MasterSupplierLink
        masterSupplierRootUrl={masterSupplierRootUrl}
        {...props}
      />
    ),
    keywordSortQuery(['company', 'name']),
  ],
  [
    'Address',
    R.path(['company', 'address', 'formatted_address']),
    keywordSortQuery(['company', 'address', 'formatted_address']),
  ],
  ['Service Types', R.prop('serviceTypesStr')],
];

export function MasterSuppliersTable({ status, type, masterSupplierRootUrl }) {
  const tableConfig = masterSuppliersTableConfig(masterSupplierRootUrl);
  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig,
    column: 1,
  });
  const searchTerm = useSelector(R.prop('searchText'));

  const { data, loading } = useSearchMasterSuppliersQuery(status, type, sort);

  useSetItemsCount(R.pathOr(0, ['searchMasterSuppliers', 'total']), data);

  const tableProps = useMapConfigToTableProps(
    prepareMasterSuppliersForTable(searchTerm),
    tableConfig,
    data,
  );

  return loading ? (
    <Loader />
  ) : (
    <MasterSuppliersTableComp {...tableSortingProps} {...tableProps} />
  );
}

MasterSuppliersTable.propTypes = {
  status: string.isRequired,
  type: string.isRequired,
  masterSupplierRootUrl: string.isRequired,
};
