import React from 'react';
import { Input } from '@poly/admin-book';
import { string } from 'prop-types';

import { paymentTypeOptions } from '../constants.js';

const PAYMENT_TYPE_UI = {
  [paymentTypeOptions.PART]: 'Part',
  [paymentTypeOptions.FINAL]: 'Final',
};

export function PaymentTypeSelect({ _id, paymentType }) {
  const componentName = `payment-type-select-${_id}`;

  return (
    <Input
      disabled
      name={componentName}
      value={PAYMENT_TYPE_UI[paymentType]}
      onChange={() => null}
      dataTestId={componentName}
    />
  );
}

PaymentTypeSelect.propTypes = {
  _id: string,
  paymentType: string,
};
