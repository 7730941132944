import { gql, useMutation } from '@apollo/client';
import { useNotificationState } from '@poly/admin-ui';

const REMOVE_PROJECT_SUPPLIER = gql`
  mutation REMOVE_PROJECT_SUPPLIER($input: RemoveSupplierFromProjectInput!) {
    removeSupplierFromProject(input: $input) {
      project {
        _id
      }
    }
  }
`;

const REMOVE_RECURRING_PROJECT_SUPPLIER = gql`
  mutation REMOVE_RECURRING_PROJECT_SUPPLIER(
    $input: RemoveSupplierFromRecurringProjectInput!
  ) {
    removeSupplierFromRecurringProject(input: $input) {
      _id
    }
  }
`;

export const useRemoveProjectSupplier = ({
  projectId,
  supplierId,
  isRecurringProject,
}) => {
  const [removeProjectSupplier] = useMutation(REMOVE_PROJECT_SUPPLIER);
  const [removeRecurringProjectSupplier] = useMutation(
    REMOVE_RECURRING_PROJECT_SUPPLIER,
  );
  const { showSuccessNotification } = useNotificationState();

  const removeSupplierMutation = isRecurringProject
    ? removeRecurringProjectSupplier
    : removeProjectSupplier;

  return async () => {
    await removeSupplierMutation({
      variables: {
        input: { projectId, supplierId },
      },
    });
    showSuccessNotification('Supplier was successfully deleted');
  };
};
