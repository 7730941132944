import React, { useEffect } from 'react';
import { string, shape } from 'prop-types';
import { useOutSidebarContext } from '@poly/client-utils';
import { SidebarWrapper } from '@poly/admin-ui';
import { Loader } from '@poly/admin-book';

import { assetTabsSidebarId } from './constants.js';
import { AssetSidebarPhoto } from './AssetSidebarPhoto.js';
import { AssetSidebarHeader } from './AssetSidebarHeader.js';
import { AssetSidebarDetails } from './AssetSidebarDetails.js';
import { AssetSidebarButtons } from './AssetSidebarButtons.js';
import { AssetSidebarSuppliers } from './AssetSidebarSuppliers.js';
import { NotFoundEntity } from '../../components/NotFoundEntity.js';
import { EntityRemarks } from '../components/commonSidebarDetailsList.js';
import { useReactiveSidebarDocumentTitle } from '../useReactiveSidebarDocumentTitle.js';
import { useOpenAssetTabsSidebar } from './tabs/useOpenAssetTabsSidebar.js';
import { useAssetSidebarData } from './useAssetSidebarData.js';
import { formatAssetTitle } from './assetSidebarUtils.js';

function AssetSidebarRouterLogic({ asset }) {
  const openAssetTabsSidebar = useOpenAssetTabsSidebar();
  const { isSidebarOpened } = useOutSidebarContext();

  useEffect(() => {
    if (!isSidebarOpened(assetTabsSidebarId)) {
      openAssetTabsSidebar(asset);
    }
  }, []);

  useReactiveSidebarDocumentTitle(formatAssetTitle(asset));

  return null;
}

AssetSidebarRouterLogic.propTypes = {
  asset: shape({
    _id: string,
    displayName: string,
  }),
};

export function AssetSidebar({ assetId }) {
  const { loading, asset } = useAssetSidebarData(assetId);

  if (loading) {
    return <Loader />;
  }

  return (
    <SidebarWrapper>
      {!asset ? (
        <NotFoundEntity entityName="asset" />
      ) : (
        <>
          <AssetSidebarHeader asset={asset} />
          <AssetSidebarDetails asset={asset} />
          <EntityRemarks entity={asset} withBorder />
          <AssetSidebarSuppliers asset={asset} />
          <AssetSidebarPhoto asset={asset} />
          <AssetSidebarRouterLogic asset={asset} />
          <AssetSidebarButtons asset={asset} />
        </>
      )}
    </SidebarWrapper>
  );
}

AssetSidebar.displayName = 'AssetSidebar';
AssetSidebar.propTypes = { assetId: string.isRequired };
