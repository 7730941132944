import * as R from 'ramda';
import { ASSET_SCANNER_APP_NAME } from '@poly/security';
import { pathOrNothingUI } from '@poly/client-utils';
import {
  getHousekeepingProjectBaseTotalHours,
  getHousekeepingClientBaseHoursCost,
  getHousekeepingProjectClientTotal,
  forceTitleCase,
  propEqLegacy,
  calculateTotal,
  formatCurrency,
  formatTotal,
  roundTo,
  pathEqLegacy,
} from '@poly/utils';
import {
  WeeklyServiceTicketStatuses,
  RecurringProjectTypes,
  ProjectTypeToNameMap,
  NOTHING_UI_STRING,
  ProjectType,
} from '@poly/constants';

// formatTicketHours :: Number -> String
export const formatTicketHours = R.compose(
  R.concat(R.__, ' hrs'),
  R.toString,
  roundTo(2),
);

// calculateBaseTotalHours :: [WeeklyServiceTicket] -> Number
export const calculateBaseTotalHours = calculateTotal(
  R.pathOr(0, ['ticket', 'hours']),
);

// calculateExtraTotalHours :: [WeeklyServiceTicket] -> Number
export const calculateExtraTotalHours = calculateTotal(
  R.pathOr(0, ['ticket', 'extraHours']),
);

// getWeekTicketInfo :: WeeklyServiceTicket -> String
export const getWeekTicketInfo = R.compose(
  R.apply(R.concat),
  R.juxt([
    R.compose(R.concat('Week '), R.toString, R.prop('week')),
    R.cond([
      [
        pathEqLegacy(
          ['ticket', 'status'],
          WeeklyServiceTicketStatuses.requested,
        ),
        R.always(' (requested)'),
      ],
      [
        pathEqLegacy(
          ['ticket', 'status'],
          WeeklyServiceTicketStatuses.received,
        ),
        R.always(' (approve)'),
      ],
      [
        pathEqLegacy(
          ['ticket', 'status'],
          WeeklyServiceTicketStatuses.notCompleted,
        ),
        R.always(' (not completed)'),
      ],
      [R.T, R.always('')],
    ]),
  ]),
);

// isTicketApprovedOrNotCompleted :: { ticket: WeeklyServiceTicket } -> Boolean
export const isTicketApprovedOrNotCompleted = R.compose(
  R.pathSatisfies(
    R.includes(R.__, [
      WeeklyServiceTicketStatuses.approved,
      WeeklyServiceTicketStatuses.notCompleted,
    ]),
    ['ticket', 'status'],
  ),
  R.defaultTo({}),
);

// sumWeeklyServiceTicketHours :: WeeklyServiceTicket -> Number
export const sumWeeklyServiceTicketHours = R.converge(
  R.compose(roundTo(2), R.add),
  [
    R.compose(parseFloat, R.propOr(0, 'extraHours')),
    R.compose(parseFloat, R.propOr(0, 'hours')),
  ],
);

// transformWeeklyServiceTickets :: (Number, Number, [Object]) -> [Object]
export const transformWeeklyServiceTickets = (weekNumbers, tickets) => {
  const list = R.repeat(null, weekNumbers);
  return list.map((_, index) => ({
    ticket: tickets.find(({ week }) => week === index + 1),
    week: index + 1,
    _id: index,
  }));
};

// prepareDescriptionForSection :: Description -> String -> Description
// Description = String || Object
export const prepareDescriptionForSection = R.curry((main, additional) =>
  R.cond([
    [R.is(String), R.concat(R.__, additional)],
    [
      R.both(R.is(Object), R.has('ops')),
      R.over(R.lensProp('ops'), R.append({ insert: additional })),
    ],
    [R.T, R.identity],
  ])(main),
);

// projectAccountingStatusToString :: Project -> String
export const projectAccountingStatusToString = R.compose(
  R.join(' '),
  R.map(forceTitleCase),
  R.split('_'),
  pathOrNothingUI(['accountingStatus']),
);

// formatHousekeepingWeeklyPorter :: (String, Project -> Number, Bool) -> Project -> String
export const formatHousekeepingWeeklyPorter =
  (rateProp, getTotalFn, getTotalHoursFn, showDashWhenZero) => (project) => {
    const total = getTotalFn(project);
    if (total === 0 && showDashWhenZero) {
      return NOTHING_UI_STRING;
    }

    const hours = R.compose(roundTo(2), getTotalHoursFn)(project);

    return `${hours} hrs @ ${formatTotal(project[rateProp])} = ${formatTotal(
      total,
    )}`;
  };

// getRowByHandlerIfHoursNotZero :: Function -> Project -> String
const getRowByHandlerIfHoursNotZero = (handler) =>
  R.ifElse(
    R.compose(R.equals(0), getHousekeepingProjectBaseTotalHours),
    R.always(''),
    handler,
  );

/**
 * getAdditionalDescriptionInfo :: Project -> String
 */
export const getAdditionalDescriptionInfo = R.ifElse(
  propEqLegacy('type', ProjectType.HOUSEKEEPING),
  R.compose(
    R.join(''),
    R.juxt([
      R.always('\n\nMonthly Housekeeping: '),
      R.compose(formatTotal, R.prop('clientMonthlyFee')),
      getRowByHandlerIfHoursNotZero(R.always('\nPorter Service Hours: ')),
      getRowByHandlerIfHoursNotZero(
        formatHousekeepingWeeklyPorter(
          'clientHourlyRate',
          getHousekeepingClientBaseHoursCost,
          getHousekeepingProjectBaseTotalHours,
        ),
      ),
      R.always('\n\nTotal Invoice: '),
      R.compose(formatTotal, getHousekeepingProjectClientTotal),
    ]),
  ),
  R.always(''),
);

/**
 * calculateInvoicesAmount :: Project -> String
 */
export const formatInvoicesAmount = R.compose(
  R.concat('$'),
  formatCurrency,
  R.prop('total'),
);

// calculatePorterHours :: Project -> Number
export const calculatePorterHours = R.compose(
  ({ weeklyServiceTickets, creditedPorterHours = 0 }) =>
    calculateTotal(R.prop('hours'))(weeklyServiceTickets) - creditedPorterHours,
  R.applySpec({
    creditedPorterHours: R.propOr(0, 'creditedPorterHours'),
    weeklyServiceTickets: R.propOr([], 'weeklyServiceTickets'),
  }),
);

// getUserFriendlyProjectTypeName :: String -> String
export const getUserFriendlyProjectTypeName = R.propOr(
  'Project',
  R.__,
  ProjectTypeToNameMap,
);

// isSubPropertiesWithAssets :: Project -> Boolean
const isSubPropertiesWithAssets = R.compose(
  R.gt(R.__, 0),
  R.length,
  R.flatten,
  R.map(R.propOr([], 'assets')),
  R.propOr([], 'subProperties'),
);

// checkIfShowAssetsByProject :: Project -> Boolean
export const checkIfShowAssetsByProject = R.compose(
  R.allPass([
    R.complement(R.isNil),
    R.propSatisfies(R.complement(R.isNil), 'property'),
    R.ifElse(
      R.propSatisfies(R.isNil, 'parent'),
      R.anyPass([
        R.pathSatisfies(R.compose(R.gt(R.__, 0)), [
          'property',
          'assets',
          'length',
        ]),
        isSubPropertiesWithAssets,
        R.both(
          R.either(
            R.pathSatisfies(
              R.both(R.identity, R.includes(ASSET_SCANNER_APP_NAME)),
              ['client', 'apps'],
            ),
            R.pathSatisfies(R.gt(R.__, 0), ['searchAssets', 'total']),
          ),
          R.propSatisfies(
            R.includes(R.__, [
              ProjectType.PASS_THROUGH,
              ProjectType.WORK_ORDER,
            ]),
            'type',
          ),
        ),
      ]),
      pathEqLegacy(
        ['parent', 'type'],
        RecurringProjectTypes.preventiveMaintenanceProject,
      ),
    ),
  ]),
);

// prepareProjectSidebarSuppliersTableRows :: ({ project: Project, isCard: Boolean }) -> [Supplier] -> [Supplier]
export const prepareProjectSidebarSuppliersTableRows = ({ project, isCard }) =>
  R.map(
    R.converge(R.mergeDeepLeft, [
      R.applySpec({
        supplierId: R.prop('_id'),
        project: R.always(project),
        _id: R.compose(
          R.concat('supplier_form_row_'),
          R.join('_'),
          R.reject(R.isEmpty),
          R.juxt([
            R.prop('_id'),
            R.always(project._id),
            R.always(isCard ? 'card' : ''),
          ]),
        ),
      }),
      R.identity,
    ]),
  );
