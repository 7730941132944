import React from 'react';
import * as R from 'ramda';
import { func, shape, string } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { FormCreator } from '@poly/admin-book';
import { ofArrayLegacy } from '@poly/utils';
import {
  commonSidebarFormSectionLayout,
  commonSidebarFormFieldLayout,
  useOnSubmitSetStopSubmitting,
  commonSidebarFormLayout,
  useNotificationState,
  MAX_ITEMS,
} from '@poly/admin-ui';

import { ProcedureSelect } from '../../../components/ProcedureSelect.js';

export const ATTACH_PROCEDURES_MUTATION = gql`
  mutation ATTACH_PROCEDURES_MUTATION($input: AttachProceduresInput!) {
    attachProcedures(input: $input)
  }
`;

// prepareQueryOptions :: ID -> QueryOptions
const prepareQueryOptions = (assetTypeId) => ({
  fetchPolicy: 'network-only',
  variables: {
    input: {
      size: MAX_ITEMS,
      query: {
        bool: {
          should: [
            { term: { assetTypeIds: assetTypeId } },
            {
              bool: {
                must_not: { exists: { field: 'assetTypeIds' } },
              },
            },
          ],
        },
      },
    },
  },
});

const getAttachProcedureSections = (assetTypeId) => [
  {
    order: 1,
    layout: { margin: '24px 0 0 0' },
    fields: [
      {
        order: 1,
        label: 'Procedure',
        layout: { row: 1, with: '100%' },
        validators: [[R.identity, 'Procedure is required']],
        field: {
          name: 'procedureId',
          Component: ProcedureSelect,
          additionalProps: {
            queryOptions: prepareQueryOptions(assetTypeId),
            subscriptionOptions: prepareQueryOptions(assetTypeId),
          },
        },
      },
    ],
  },
];

// prepareAttachedProcedureInput :: (Entity, ID, String) -> FormData -> { input: AttachProceduresInput }
// Entity = {
//    _id: ID
//    name: String
// }
const prepareAttachedProcedureInput = (entity, assetId, collection) =>
  R.applySpec({
    projectId: R.always(entity._id),
    collection: R.always(collection),
    attachPayload: R.compose(
      ofArrayLegacy,
      R.mergeLeft({ assetIds: [assetId] }),
    ),
  });

export function AttachProcedureForm({
  entity,
  formId,
  assetId,
  onCancel,
  assetType,
  collection,
}) {
  const { showSuccessNotification } = useNotificationState();
  const [attachProcedures] = useMutation(ATTACH_PROCEDURES_MUTATION);

  const onSubmitHandler = async (formData) => {
    const input = prepareAttachedProcedureInput(
      entity,
      assetId,
      collection,
    )(formData);

    await attachProcedures({ variables: { input } });

    showSuccessNotification('Procedure was successfully attached');

    onCancel();
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  return (
    <FormCreator
      id={formId}
      onSubmit={onSubmit}
      initialValues={{}}
      sections={getAttachProcedureSections(assetType._id)}
      layout={commonSidebarFormLayout}
      fieldLayout={{ ...commonSidebarFormFieldLayout, width: '100%' }}
      sectionLayout={{
        ...commonSidebarFormSectionLayout,
        padding: '0 0 24px 0',
      }}
    />
  );
}

AttachProcedureForm.propTypes = {
  formId: string,
  assetId: string,
  onCancel: func,
  collection: string,
  assetType: shape({ _id: string }),
  entity: shape({ _id: string, name: string }),
};
