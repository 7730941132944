import { gql } from '@apollo/client';

export const INVOICES_BY_SEARCH_SUB = gql`
  subscription INVOICES_BY_SEARCH_SUB($searchInput: CollectionSearchParams!) {
    searchInvoiceChanged(input: $searchInput) {
      id
      type
    }
  }
`;
