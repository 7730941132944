import React from 'react';
import { number, shape, string } from 'prop-types';

import { AmountInput } from '../../../components/PaymentsFromComponents/AmountInput.js';

export function DueAmountInput({ _id, dueAmount, currentBalance, errors }) {
  const inputName = `payment-due-amount-input-${_id}`;

  return (
    <AmountInput
      disabled
      allowNegative
      width="100%"
      name={inputName}
      dataTestId={inputName}
      value={dueAmount}
      currentBalance={currentBalance}
      error={errors.dueAmount}
      onChange={() => null}
    />
  );
}

DueAmountInput.defaultProps = {
  errors: { dueAmount: '' },
};
DueAmountInput.propTypes = {
  _id: string,
  errors: shape({ dueAmount: string }),
  dueAmount: number,
  currentBalance: number,
};
