import { useCallback } from 'react';

export const useDecoratePersistenceForOnChange = (
  onChangePersistentValue,
  onChange,
  isEmpty,
) => {
  const handleChange = useCallback(
    (value) => {
      onChangePersistentValue(isEmpty(value) ? '' : value);
      return onChange(value);
    },
    [onChange, onChangePersistentValue],
  );

  return handleChange;
};
