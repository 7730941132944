import * as R from 'ramda';
import { useEffect } from 'react';
import { arrayOf, bool, string } from 'prop-types';
import { useLocation, useNavigate } from '@poly/client-routing';

import { useIsTokenValid } from './hooks/useIsTokenValid.js';

// isNavigateToHome :: Input -> Boolean
// Input = {
//    loading: Boolean
//    isTokenValid: Boolean
//    authRoutesList: [String]
//    pathname: String
// }
const isNavigateToHome = R.allPass([
  R.complement(R.prop('loading')),
  R.prop('isTokenValid'),
  R.converge(R.includes, [R.prop('pathname'), R.prop('authRoutesList')]),
]);

export function AuthRoutesRedirect({
  homeRoute,
  authRoutesList,
  skipTokenValidation,
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isTokenValid, loading } = useIsTokenValid(skipTokenValidation);

  useEffect(() => {
    if (isNavigateToHome({ loading, isTokenValid, authRoutesList, pathname })) {
      navigate(homeRoute);
    }
  }, [authRoutesList, pathname, isTokenValid, loading]);

  return null;
}

AuthRoutesRedirect.propTypes = {
  homeRoute: string,
  authRoutesList: arrayOf(string),
  skipTokenValidation: bool,
};
