import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { func, string, oneOfType, object } from 'prop-types';
import { ModalActions, Text } from '@poly/admin-book';

import { SubmitBtn as SubmitBtnC } from '../../components/common.js';
import { CancelModalFormBtn } from '../../components/Button/CancelModalFormBtn.js';

const ModalWrapper = styled.div`
  min-width: 600px;
`;

function SubmitBtn({ formId, ...props }) {
  const processes = useSelector((state) => state.processes[formId]);

  return (
    <SubmitBtnC
      loader={processes}
      disabled={processes}
      form={formId}
      {...props}
    />
  );
}

SubmitBtn.propTypes = {
  formId: string.isRequired,
};

export function ModalFormWrapper({
  title,
  buttonLabel,
  formId,
  Form,
  confirmButtonName,
  ...props
}) {
  return (
    <ModalWrapper>
      <Text size="20px">{title}</Text>
      <Form {...{ ...props, formId }} />
      <ModalActions>
        <CancelModalFormBtn
          formId={formId}
          confirmButtonName={confirmButtonName}
        />
        <SubmitBtn formId={formId}>{buttonLabel}</SubmitBtn>
      </ModalActions>
    </ModalWrapper>
  );
}

ModalFormWrapper.propTypes = {
  title: string.isRequired,
  buttonLabel: string.isRequired,
  formId: string.isRequired,
  Form: oneOfType([func, object]).isRequired,
  confirmButtonName: string.isRequired,
};
