import * as R from 'ramda';
import { projectMailToConfig, shouldSend } from './mailToUtils.js';

// getMappingMailToProps :: Object -> Object
export const getMappingMailToProps = ({
  value,
  project,
  newEmail,
  usersEmails,
  changeProp,
  addNewEmail,
  formError,
  onFocus,
  onBlur,
  msg,
  isCollapseOpened,
}) => ({
  value,
  project,
  onBlur,
  onFocus,
  changeProp,
  addNewEmail,
  formError,
  msg,
  isCollapseOpened,
  isOther: shouldSend('other', value),
  otherEmails: R.pipe(
    R.path(['other', 'emails']),
    R.map((email) => ({ value: email, label: email })),
  )(value),
  emailOptions: R.isNil(newEmail)
    ? usersEmails
    : R.prepend({ value: newEmail, label: newEmail }, usersEmails),
  config: projectMailToConfig,
});

// getMailToHandlers :: Function -> {Function}
export const getMailToHandlers = (changeProp) => {
  const changeEntityHandler = (entity, entityEmail) => (value) =>
    changeProp({
      [entity]: {
        shouldSend: value,
        emails: value ? entityEmail : [],
      },
    });

  const changeOther = (value) => changeProp({ other: { shouldSend: value } });

  const selectEmails = (value) =>
    changeProp({
      other: {
        emails: R.pipe(R.defaultTo([]), R.map(R.prop('value')))(value),
      },
    });

  return {
    changeEntityHandler,
    changeOther,
    selectEmails,
  };
};
