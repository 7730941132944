import React from 'react';
import * as R from 'ramda';
import { object, string } from 'prop-types';
import { useOutSidebarContext } from '@poly/client-utils';
import { insertParamsIntoURL } from '@poly/utils';
import { SidebarWidth } from '@poly/admin-ui';

import { SidebarIDs } from '../constants.js';
import { routesNames } from '../../routes/constants.js';
import { UserGroupSidebar } from './UserGroupSidebar.js';
import { LinkToSidebar } from '../../components/LinkToSidebar.js';

export function UserGroupLink({ name, _id, queryParams = {}, ...restProps }) {
  const linkParams = R.mergeDeepLeft(
    { query: { [SidebarIDs.userGroup]: _id } },
    queryParams,
  );

  const linkProps = {
    linkParams,
    entityCardLink: insertParamsIntoURL(
      { userGroupId: _id },
      routesNames.USER_GROUP_MANAGEMENT,
    ),
    ...restProps,
  };

  return <LinkToSidebar {...linkProps}>{name}</LinkToSidebar>;
}

UserGroupLink.propTypes = {
  _id: string.isRequired,
  name: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  queryParams: object,
};

export const useOpenUserGroupSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();

  return (userGroupId, skipSteady) =>
    openOutSidebar(
      {
        alwaysFirst: true,
        width: SidebarWidth,
        id: SidebarIDs.userGroup,
        content: <UserGroupSidebar userGroupId={userGroupId} />,
      },
      skipSteady,
    );
};
