import * as R from 'ramda';

import { createAction, handleAction } from 'redux-actions';

export const activeSearchQueryParamsTypes = {
  XLS_EXPORT: 'XLS_EXPORT',
};

const defaultState = R.pipe(
  R.keys,
  R.reduce((obj, key) => R.assoc(key, null, obj), {}),
)(activeSearchQueryParamsTypes);

export const setActiveSearchQueryParams = createAction('SET_ACTIVE_QUERY');

export const activeSearchQueryParamsReducer = handleAction(
  setActiveSearchQueryParams,
  (state, { payload }) => ({ ...state, ...payload }),
  defaultState,
);
