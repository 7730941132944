import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string, shape, bool } from 'prop-types';
import { pathOrNothingUI, useOutSidebarContext } from '@poly/client-utils';
import { defaultTheme, Status } from '@poly/admin-book';
import { SidebarRow } from '@poly/admin-ui';

import {
  SectionLabel,
  BlockWithLabel,
  SectionWrapper,
} from '../components/commonSidebarComponents.js';
import { SidebarIDs } from '../constants.js';
import { getContactReferences } from './contactInfoHelpers.js';
import {
  getSectionEmail,
  getSectionText,
  getSectionPhoneLink,
} from '../components/commonSidebarSectionFormatters.js';

const {
  colors: {
    statuses: { error, active },
  },
} = defaultTheme;

const StatusS = styled(Status)`
  font-size: 12px;
`;

// getContactSectionClientManagerStatus :: Boolean -> _ -> ReactNode
const getContactSectionClientManagerStatus = (isClientManager) =>
  function () {
    return (
      <StatusS
        text={isClientManager ? 'Yes' : 'No'}
        color={isClientManager ? active : error}
      />
    );
  };

const ContactReferencesColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

// getContactSectionReferences :: User -> _ -> ReactNode
const getContactSectionReferences = (contactUser) =>
  function (props) {
    const { closeOutSidebar } = useOutSidebarContext();

    const onClick = () => closeOutSidebar(SidebarIDs.contact);

    const { references } = getContactReferences({ ...contactUser, onClick });

    return R.is(Array, references) ? (
      <ContactReferencesColumn>
        {references.map((item, index) => (
          <div key={item.props._id || index}>{item}</div>
        ))}
      </ContactReferencesColumn>
    ) : (
      getSectionText(references)(props)
    );
  };

// showClientManager :: User -> Boolean
const showClientManager = R.compose(
  R.complement(R.isEmpty),
  R.defaultTo([]),
  R.prop('linkedClients'),
  R.defaultTo({}),
);

export function ContactSidebarInfo({ contactUser }) {
  return (
    <SectionWrapper>
      <SidebarRow>
        <SectionLabel>Personal Information</SectionLabel>
      </SidebarRow>
      <SidebarRow>
        <BlockWithLabel
          id="name"
          label="Name"
          width="50%"
          Component={getSectionText(pathOrNothingUI(['fullName'])(contactUser))}
        />
        <BlockWithLabel
          id="title"
          label="Title"
          width="50%"
          Component={getSectionText(pathOrNothingUI(['title'])(contactUser))}
        />
      </SidebarRow>
      <SidebarRow>
        <BlockWithLabel
          id="email"
          label="Email"
          width="50%"
          Component={getSectionEmail(contactUser.email)}
        />
        <BlockWithLabel
          id="phone"
          label="Phone"
          width="50%"
          Component={getSectionPhoneLink(
            contactUser.profile.workPhoneNumber,
            contactUser.profile.workPhoneNumberExt,
          )}
        />
      </SidebarRow>
      <SidebarRow>
        <BlockWithLabel
          id="mobile"
          label="Mobile"
          width="50%"
          Component={getSectionPhoneLink(contactUser.profile.cellPhoneNumber)}
        />
        <BlockWithLabel
          id="fax"
          label="Fax"
          width="50%"
          Component={getSectionPhoneLink(contactUser.profile.faxPhoneNumber)}
        />
      </SidebarRow>
      <SidebarRow>
        <BlockWithLabel
          id="reference-cards"
          label="Reference Cards"
          width="50%"
          Component={getContactSectionReferences(contactUser)}
        />
        {showClientManager(contactUser) && (
          <BlockWithLabel
            id="client-manager"
            label="Client Manager"
            width="50%"
            Component={getContactSectionClientManagerStatus(
              contactUser.isClientManager,
            )}
          />
        )}
      </SidebarRow>
    </SectionWrapper>
  );
}

ContactSidebarInfo.propTypes = {
  contactUser: shape({
    email: string,
    title: string,
    fullName: string,
    isClientManager: bool,
    profile: shape({
      workPhoneNumber: string,
      workPhoneNumberExt: string,
    }).isRequired,
  }).isRequired,
};
