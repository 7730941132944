import * as R from 'ramda';
import { isChildRecurringProject } from '@poly/client-utils';
import { ProjectType, propertyStatuses } from '@poly/constants';
import { isNilOrEmpty, propEqLegacy } from '@poly/utils';

import { formTypeConstants } from './constants.js';
import { ProjectOccurrence } from '../../../../../modules/core/constants/projects.js';

// isFormType :: FormType -> ProjectFormData -> Boolean
//   FormType = String
export const isFormType = propEqLegacy('formType');

// isProjectOfType :: ProjectFormData -> Boolean
export const isProjectOfType = propEqLegacy('type');

// isFeeProjectType :: ProjectFormData -> Boolean
export const isFeeProjectType = isProjectOfType(ProjectType.FEE);

// isBidProjectType :: ProjectFormData -> Boolean
export const isBidProjectType = isProjectOfType(ProjectType.BID);

// isNotFeeProjectType :: ProjectFormData -> Boolean
export const isNotFeeProjectType = R.complement(isFeeProjectType);

// isHousekeepingProjectType :: Project -> Boolean
export const isHousekeepingProjectType = isProjectOfType(
  ProjectType.HOUSEKEEPING,
);

// isRecurringFormData :: ProjectFormData -> Boolean
export const isRecurringFormData = propEqLegacy(
  'occurrence',
  ProjectOccurrence.RECURRING_OCCURRENCE,
);

// isPMFormData :: ProjectFormData -> Boolean
export const isPMFormData = propEqLegacy(
  'occurrence',
  ProjectOccurrence.PREVENTIVE_MAINTENANCE_OCCURRENCE,
);

// isRecurringOrPMFormData :: FormData -> Boolean
export const isRecurringOrPMFormData = R.either(
  isRecurringFormData,
  isPMFormData,
);

// isNotRecurringAndFeeProject :: ProjectFormData -> Boolean
export const isNotRecurringAndFeeProject = R.both(
  R.complement(isRecurringOrPMFormData),
  isNotFeeProjectType,
);

// renderNotToExceedSection :: ProjectFormData -> Boolean
export const renderNotToExceedSection = R.allPass([
  isNotRecurringAndFeeProject,
  R.complement(isProjectOfType(ProjectType.HOUSEKEEPING)),
  R.complement(isProjectOfType(ProjectType.LISTING)),
]);

// renderPlanningSection :: ProjectFormData -> Boolean
export const renderPlanningSection = R.both(
  R.complement(isPMFormData),
  R.complement(isRecurringFormData),
);

// renderClientEmailSection :: ProjectFormData -> Boolean
export const renderClientEmailSection = R.both(
  isNotRecurringAndFeeProject,
  R.complement(isChildRecurringProject),
);

// isRecurringProject :: Project -> Boolean
export const isRecurringProject = R.compose(
  R.anyPass([
    R.equals(ProjectOccurrence.RECURRING_OCCURRENCE),
    R.isNil,
    R.isEmpty,
  ]),
  R.prop('type'),
  R.defaultTo({}),
);

// isPMProject :: RecurringProject -> Boolean
export const isPMProject = R.both(
  R.propSatisfies(R.complement(R.isNil), 'type'),
  propEqLegacy('type', ProjectOccurrence.PREVENTIVE_MAINTENANCE_OCCURRENCE),
);

// isRecurringOrPMProject :: Object -> Boolean
export const isRecurringOrPMProject = R.either(isRecurringProject, isPMProject);

const defaultPropertyQuery = {
  bool: { should: [{ match: { status: propertyStatuses.ACTIVE } }] },
};

export const allowPropertyProjectCreationQuery = [
  {
    term: {
      'client.configs.adminApp.allowProjectCreation': true,
    },
  },
  {
    term: {
      status: propertyStatuses.ACTIVE,
    },
  },
];

// getQueryForSelect :: (ProjectFormData, Boolean) -> ElasticQuery
export const getQueryForSelect = (formData, withAllowProjectCreation = false) =>
  R.compose(
    R.when(
      R.always(withAllowProjectCreation),
      R.assocPath(['bool', 'must'], allowPropertyProjectCreationQuery),
    ),
    R.ifElse(
      isNilOrEmpty,
      R.always(defaultPropertyQuery),
      R.assocPath(['bool', 'filter'], R.__, defaultPropertyQuery),
    ),
    R.reject(isNilOrEmpty),
    R.juxt([
      R.compose(
        R.unless(isNilOrEmpty, R.compose(R.objOf('term'), R.objOf('clientId'))),
        R.prop('clientId'),
      ),
      R.compose(
        R.unless(isNilOrEmpty, R.compose(R.objOf('terms'), R.objOf('_id'))),
        R.prop('propertyLimitIds'),
      ),
    ]),
  )(formData);

// isProjectWithProperty :: FormValues -> Boolean
const isProjectWithProperty = R.both(
  isFormType(formTypeConstants.EDIT),
  R.path(['property', '_id']),
);

// isPropertyEnabled :: FormValues -> Boolean
const isPropertyEnabled = R.compose(
  R.both(
    isFormType(formTypeConstants.CREATE),
    R.compose(R.not, R.prop('isPropertyDisabled')),
  ),
);

// checkIsProjectWithProperty :: FormValues -> Boolean
export const checkIsProjectWithProperty = R.either(
  isPropertyEnabled,
  isProjectWithProperty,
);

// isListingProjectType :: ProjectFormData -> Boolean
export const isListingProjectType = isProjectOfType(ProjectType.LISTING);
