import React, { useState } from 'react';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import {
  MultiselectDropdown,
  InputErrorMsg,
  getThemeColor,
  getThemeFont,
} from '@poly/admin-book';
import { arrayOf, func, shape, string } from 'prop-types';

const withCheckboxStyle = css`
  .multiselect__menu-list {
    & > div {
      border-bottom: 1px solid ${getThemeColor(['light'])};
      padding: 11px 15px;

      &:last-child {
        border-bottom: none;
      }
    }

    .state-re-presenter {
      width: 16px;
      height: 16px;

      &:after {
        top: 2px;
        left: 5px;
      }
    }

    label {
      font-size: 12px;
      line-height: 18px;

      input {
        margin-right: 10px;
      }
    }
  }
`;

const MultiSelect = styled(MultiselectDropdown)`
  .multiselect__indicators {
    display: none;
  }

  .multiselect__control {
    padding-right: 10px;
    min-height: 30px;
  }

  .multiselect__multi-value__label {
    font-size: 12px;
  }

  .multiselect__control:hover,
  .multiselect__control:focus,
  .multiselect__control--is-focused {
    border: 1px solid ${getThemeColor(['primaryLight'])};
    box-shadow: unset;
  }

  .multiselect__input {
    font-size: 12px;
  }

  .multiselect__single-value {
    font-size: ${({ size }) => (size === 'small' ? '10px' : '12px')};
    line-height: ${({ size }) => (size === 'small' ? '12px' : '18px')};
    overflow: initial;
  }

  .multiselect__option--is-focused {
    background-color: ${getThemeColor(['secondaryLighter2'])};
  }

  .multiselect__option--is-selected {
    background-color: ${getThemeColor(['primaryLight'])};
  }

  min-width: 282px;
  margin: 7px 0 0 20px;
`;

const MultiSelectS = styled(MultiSelect)`
  .multiselect__control,
  .multiselect__control:hover,
  .multiselect__control:focus,
  .multiselect__control--is-focused {
    border: 1px solid
      ${({ error }) =>
        error ? getThemeColor(['secondaryMid']) : getThemeColor(['midLight'])};
  }

  .multiselect__placeholder {
    font-size: 12px;
    font-weight: ${getThemeFont(['regular'])};
    color: ${getThemeColor(['primaryLighter6'])};
  }

  .multiselect__menu {
    font-size: 12px;
    z-index: 2;

    color: ${({ styleType, ...props }) =>
      styleType === 'withCheckBox'
        ? getThemeColor(['midDark'])(props)
        : getThemeColor(['primary'])(props)};
  }

  ${({ styleType }) => (styleType === 'withCheckBox' ? withCheckboxStyle : '')};

  width: 100%;
  margin: 0;
  min-width: 0;
`;

export function MultiSelectDropDown(props) {
  return (
    <>
      {/* you can assign a new style to a component with styleType="withCheckBox" */}
      <MultiSelectS {...props} />
      {!!R.prop('error', props) && (
        <InputErrorMsg>{R.prop('error', props)}</InputErrorMsg>
      )}
    </>
  );
}

export function MultiselectDropDownWithManualOption({
  onChange,
  additionalOptions,
  ...props
}) {
  const [email, setEmail] = useState('');
  const options = [
    {
      value: email,
      label: email,
    },
    ...additionalOptions,
  ];
  return (
    <MultiSelectDropDown
      options={options}
      onInputChange={setEmail}
      handleChange={onChange}
      {...props}
    />
  );
}

MultiselectDropDownWithManualOption.defaultProps = {
  additionalOptions: [],
};
MultiselectDropDownWithManualOption.propTypes = {
  onChange: func.isRequired,
  additionalOptions: arrayOf(
    shape({
      value: string,
      label: string,
    }),
  ),
};
