import React from 'react';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { getThemeColor, Text } from '@poly/admin-book';
import { UpdateCollections } from '@poly/constants';
import { removePropDeep, forceTitleCase } from '@poly/utils';
import {
  prepareEmailStringByCollection,
  getEmailsSeparator,
  prepareEmailString,
} from '@poly/client-utils';

const SUBJECT_KEY = 'subject';
const DATE_KEY = 'receivedDate';

const UpdateEmailDataWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid ${getThemeColor(['midLight'])};
`;

const DataRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 5px;
  word-break: break-all;
`;

const Label = styled(Text)`
  line-height: 1.6;
  color: ${getThemeColor(['secondary'])};
  width: 70px;
`;

const noWrapInfoTextStyles = css`
  max-width: calc(100% - 70px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const InfoText = styled(Text)`
  font-size: 12px;
  white-space: pre-wrap;
  line-height: 18px;
  padding-right: 5px;

  ${({ noWrap }) => !!noWrap && noWrapInfoTextStyles};
`;

const EmailLink = styled.a``;

// prepareUpdateEmailData :: EmailData -> [String, Sting]
const prepareUpdateEmailData = R.compose(
  R.toPairs,
  R.reject(R.isNil),
  R.omit([DATE_KEY]),
  removePropDeep('__typename'),
);

export function UpdateEmailData(props) {
  const preparedData = prepareUpdateEmailData(props);

  return (
    <UpdateEmailDataWrapper data-testid="update-email-data">
      {preparedData.map(([key, data]) => (
        <DataRow {...{ key }}>
          <Label>{forceTitleCase(key)}</Label>
          {R.is(Array, data) ? (
            data.map(({ address, name }, i) => (
              <DataRow key={address}>
                {!!name && <InfoText>{name}</InfoText>}
                <EmailLink href={`mailto:${address}`}>
                  {name
                    ? prepareEmailString(address)
                    : prepareEmailStringByCollection(UpdateCollections)(
                        address,
                      )}
                </EmailLink>
                {getEmailsSeparator(data.length, i)}
              </DataRow>
            ))
          ) : (
            <InfoText noWrap={key === SUBJECT_KEY}>{data}</InfoText>
          )}
        </DataRow>
      ))}
    </UpdateEmailDataWrapper>
  );
}

UpdateEmailData.displayName = 'UpdateEmailData';
