import * as R from 'ramda';
import {
  calculateTotal,
  convertCentsToDollars,
  isNilOrEmpty,
  ofArrayLegacy,
  roundTo,
  pathEqLegacy,
} from '@poly/utils';
import { ProjectType } from '@poly/constants';
import { sortTableRows } from '@poly/admin-book';

// getSupplierInvoiceAmount :: WipRecord -> Number
export const getSupplierInvoiceAmount = R.propOr(0, 'supplierInvoiceAmount');

// getMarkupAmount :: WipRecord -> Number
export const getMarkupAmount = R.propOr(0, 'markupAmount');

// getClientInvoiceTotal :: WipRecord -> Number
export const getClientInvoiceTotal = R.ifElse(
  R.has('suggestedTotalInvoice'),
  R.prop('suggestedTotalInvoice'),
  R.converge(R.add, [
    R.compose(convertCentsToDollars, getSupplierInvoiceAmount),
    getMarkupAmount,
  ]),
);

// getWIPTotal :: WipRecord -> Number
export const getWIPQCTotal = R.compose(roundTo(2), getClientInvoiceTotal);

// calculateWIPQCReportTotalsByInvoices :: [WipRecord] -> WIPTotals
//   WIPTotals = {
//     supplierInvoice: Number,
//     markup: Number,
//     total: Number,
//   }
export const calculateWIPQCReportTotalsByInvoices = R.applySpec({
  supplierInvoiceTotal: R.compose(
    convertCentsToDollars,
    calculateTotal(getSupplierInvoiceAmount),
  ),
  markup: calculateTotal(getMarkupAmount),
  total: calculateTotal(getWIPQCTotal),
});

// isTmProject :: Invoice -> Boolean
const isTmProject = pathEqLegacy(['project', 'type'], ProjectType.WORK_ORDER);

// getCommonWipQCRecordFields :: Invoice -> WipRecord
const getCommonWipQCRecordFields = R.applySpec({
  _id: R.prop('_id'),
  project: R.prop('project'),
  client: R.prop('client'),
  projectAccountingStatus: R.path(['project', 'accountingStatus']),
  manager: R.path(['project', 'manager']),
});

// getTmMarkupPercentByJobCostAndMarkup :: {project: Project} -> Number
const getTmMarkupPercentByJobCostAndMarkup = R.compose(
  roundTo(4),
  R.multiply(100),
  R.converge(R.divide, [
    R.pathOr(0, ['project', 'clientInvoiceMarkupAmount']),
    R.pathOr(0, ['project', 'jobCost']),
  ]),
);

// getMarkupAmountByJobCost :: Invoice -> Number
const getMarkupAmountByJobCost = R.ifElse(
  R.propSatisfies(R.lt(R.__, 0), 'total'),
  R.always(0),
  R.compose(
    roundTo(2),
    convertCentsToDollars,
    R.converge(R.multiply, [
      R.prop('total'),
      R.compose(R.divide(R.__, 100), getTmMarkupPercentByJobCostAndMarkup),
    ]),
  ),
);

// generateTmProjectWipQCRecords :: [Invoice] -> [WipRecord]
const generateTmProjectWipQCRecords = R.compose(
  R.unnest,
  R.map((invoices) =>
    R.map(
      R.converge(R.mergeRight, [
        getCommonWipQCRecordFields,
        R.applySpec({
          suppliers: R.compose(
            R.ifElse(isNilOrEmpty, R.always([]), ofArrayLegacy),
            R.prop('supplier'),
          ),
          supplierInvoiceAmount: R.propOr(0, 'total'),
          markupAmount: R.ifElse(
            R.always(invoices.length === 1),
            R.compose(
              convertCentsToDollars,
              R.pathOr(0, ['project', 'clientInvoiceMarkupAmount']),
            ),
            getMarkupAmountByJobCost,
          ),
        }),
      ]),
      invoices,
    ),
  ),
  R.values,
  R.groupBy(R.path(['project', '_id'])),
);

// generateProjectWipQCRecords :: [Invoice] -> [WipRecord]
const generateProjectWipQCRecords = R.compose(
  R.map(
    R.converge(R.mergeRight, [
      R.compose(getCommonWipQCRecordFields, R.head),
      R.applySpec({
        suppliers: R.compose(
          R.uniqBy(R.prop('_id')),
          R.reject(isNilOrEmpty),
          R.map(R.prop('supplier')),
        ),
        supplierInvoiceAmount: calculateTotal(R.propOr(0, 'total')),
        suggestedTotalInvoice: R.compose(
          R.path(['project', 'suggestedClientInvoiceTotalWithoutTime']),
          R.head,
        ),
        markupAmount: R.compose(
          R.converge(R.subtract, [
            R.path(['project', 'suggestedClientInvoiceTotalWithoutTime']),
            R.path(['project', 'total']),
          ]),
          R.head,
        ),
      }),
    ]),
  ),
  R.values,
  R.groupBy(R.path(['project', '_id'])),
);

// getWipQCRecordsRows :: Object -> [WipQCRecord]
export const getWipQCRecordsRows = R.compose(
  R.converge(R.concat, [
    R.compose(generateTmProjectWipQCRecords, R.filter(isTmProject)),
    R.compose(generateProjectWipQCRecords, R.reject(isTmProject)),
  ]),
  R.pathOr([], ['searchInvoices', 'hits']),
);

// sortTableRowsByConfig :: ([Object], Object) -> [Object]
export const sortTableRowsByConfig = (sortQueries) => (rows, sorting) =>
  sortQueries[sorting.columnKey]
    ? sortTableRows(sortQueries, sorting)(rows)
    : rows;
