import * as R from 'ramda';
import { useRef, useState, useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { debounce } from '@poly/utils';
import { INFINITE_SCROLL_MODE_VARIABLE } from '@poly/constants';

// @poly/client-utils should be independent from @poly/admin-book/@poly/site-book
// so copied it over from @poly/admin-book
const isScrolledToBottom = (el, threshold = 0) => {
  const offset = el.scrollTop + el.clientHeight + threshold;
  return offset >= el.scrollHeight;
};

// getHitsByQueryData :: EndpointName -> QueryData -> [Hit]
//  EndpointName = String | [String]
//  Hit = Object
//  QueryData = { [EndpointName]: { hits: [Hit] } }
const getHitsByQueryData = (endpointName) =>
  R.ifElse(
    R.compose(Array.isArray, R.always(endpointName)),
    R.pathOr([], [...endpointName, 'hits']),
    R.pathOr([], [endpointName, 'hits']),
  );

// getHitsCountByQueryData :: EndpointName -> QueryData -> Int
const getHitsCountByQueryData = (endpointName) =>
  R.compose(R.length, getHitsByQueryData(endpointName));

// getPageFromParamsByQueryData :: (String, [PageFromField]) -> QueryData -> Object
// PageFromField = { fieldName: String, valuePath: [String] }
const getPageFromParamsByQueryData = (endpointName, pageFromFields) =>
  R.compose(
    (record) =>
      R.compose(
        R.fromPairs,
        R.map(
          R.juxt([
            R.prop('fieldName'),
            ({ valuePath }) => R.path(valuePath, record),
          ]),
        ),
      )(pageFromFields),
    R.last,
    getHitsByQueryData(endpointName),
  );

// createApolloQueryOptions :: k -> ApolloQueryOptions
//   -> QueryInput -> QueryInput -> ApolloQueryOptions
//
//   k = String
//   QueryInput = {
//     from: Any
//     size: Number
//     ...
//   }
//   ApolloQueryOptions = {
//     variables: {k: QueryInput},
//     fetchPolicy: String,
//     skip: Bool
//   }
const createApolloQueryOptions = R.curry(
  (inputName, extraOptions, input, inputExtraProps) =>
    R.compose(
      R.mergeDeepRight(extraOptions),
      R.assocPath(['variables', INFINITE_SCROLL_MODE_VARIABLE], true),
      R.assocPath(['variables', inputName], R.__, {}),
      R.mergeLeft(inputExtraProps),
    )(input),
);

// isEndpointNameValid :: EndpointName -> Boolean
const isEndpointNameValid = R.compose(
  R.includes(true),
  R.juxt([R.is(String), Array.isArray]),
);

const createOnScrollHandler =
  (loadingMessage, debouncedLoadNextPage) => (event) => {
    const { target } = event;
    if (
      // prevent elements inside table from triggering
      // load next page action
      target.tagName === 'TBODY' &&
      isScrolledToBottom(target, 10) &&
      !loadingMessage
    ) {
      debouncedLoadNextPage();
    }
  };

const loadNextPageBase = async ({
  loading,
  allItemsFetched,
  extraQueryOptions,
  loadedCount,
  pageSize,
  setAllItemsFetched,
  setLoadingMessage,
  pageFromFields,
  endpointName,
  fetchMore,
  inputName,
  input,
  data,
}) => {
  if (loading || allItemsFetched || extraQueryOptions.skip) {
    return;
  }

  if (loadedCount < pageSize) {
    setAllItemsFetched(true);
    return;
  }

  try {
    setLoadingMessage(`Loading next ${pageSize}...`);

    const from = R.isEmpty(pageFromFields)
      ? loadedCount
      : getPageFromParamsByQueryData(endpointName, pageFromFields)(data);

    const { data: fetchMoreData } = await fetchMore(
      createApolloQueryOptions(inputName, extraQueryOptions, input, {
        size: pageSize,
        from,
      }),
    );

    const fetchMoreCount = getHitsCountByQueryData(endpointName)(fetchMoreData);

    if (fetchMoreCount < pageSize) {
      setAllItemsFetched(true);
    }

    setLoadingMessage(null);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error while loading next page', err);
    setLoadingMessage('Loading Error');
  }
};

// Will work only with endpoints that use pagination approach
// identical to `search*` based queries (size, from, hits, total etc.)
// `tableProps` cover both `Table` and `WindowedTable`.
//
// Notes:
// 1. It doesn't use deprecated Apollo's `updateQuery` API therefore
//    pagination should be configured
//    for the endpoint at packages/apollo-client/src/index.js
// 2. Keep `input` at state to prevent unpredicted behavior.
// 3. Custom `fetchPolicy` doesn't make sense,
//    because `fetchMore` relies on cache by design
export function useTableInfiniteScrollQuery(
  query,
  input,
  {
    endpointName,
    inputName = 'input',
    pageSize = 100,
    pageFromFields = [],
    ...extraQueryOptions
  },
) {
  if (!isEndpointNameValid(endpointName)) {
    throw new Error('`endpointName` option is required!');
  }

  const [loadingMessage, setLoadingMessage] = useState(null);
  const [allItemsFetched, setAllItemsFetched] = useState(false);

  useEffect(() => {
    setAllItemsFetched(false);
  }, [input]);

  const { data, loading, fetchMore, refetch } = useQuery(
    query,
    createApolloQueryOptions(inputName, extraQueryOptions, input, {
      size: pageSize,
    }),
  );

  const didUseCacheForFirstPage = useRef(true);
  useEffect(() => {
    didUseCacheForFirstPage.current = true;
  }, [input]);

  useEffect(() => {
    if (loading) {
      didUseCacheForFirstPage.current = false;
    }
  }, [loading]);
  useEffect(() => {
    // prevent data caching between page visits
    if (
      !loading &&
      !extraQueryOptions.skip &&
      didUseCacheForFirstPage.current
    ) {
      refetch();
    }
  }, [loading, extraQueryOptions.skip, input]);

  const loadedCount = getHitsCountByQueryData(endpointName)(data);

  // prevent more records indication
  // (relevant for Pay Suppliers)
  // if we loaded non full first page
  useEffect(() => {
    if (!allItemsFetched && loadedCount > 0 && loadedCount % pageSize !== 0) {
      setAllItemsFetched(true);
    }
  }, [data]);

  const loadNextPage = async () => {
    loadNextPageBase({
      loading,
      allItemsFetched,
      extraQueryOptions,
      loadedCount,
      pageSize,
      setAllItemsFetched,
      setLoadingMessage,
      pageFromFields,
      endpointName,
      fetchMore,
      inputName,
      input,
      data,
    });
  };

  const debouncedLoadNextPage = useCallback(debounce(200)(loadNextPage), [
    loadNextPage,
  ]);

  const onScroll = createOnScrollHandler(loadingMessage, debouncedLoadNextPage);

  // Note: keep it async to prevent SonarCube errors
  // related to obsolete await calls
  const internalRefetch = async (newVariables = {}) => {
    const refetchQueryOptions = createApolloQueryOptions(
      inputName,
      extraQueryOptions,
      input,
      { size: pageSize },
    );
    const adjustedVariables = R.mergeDeepRight(
      refetchQueryOptions.variables,
      newVariables,
    );
    // Note: putting await here causes
    // random test fails on Linux/CI
    refetch(adjustedVariables);
    setAllItemsFetched(false);
  };

  const tableProps = {
    // regular table specific props
    onScroll,

    // windowed table specific props
    loadMoreItems: debouncedLoadNextPage,
    // we need extra items at the end of list if there are more items to load
    itemCount: allItemsFetched ? loadedCount : loadedCount + 10,
    loadedCount,
    // start loading next page when user scrolled half of current page
    threshold: Math.floor(pageSize / 2),

    // common props
    loadingMessage,
    isLoading: !!loadingMessage,
  };

  return {
    data,
    loading,
    refetch: internalRefetch,
    tableProps,
    allItemsFetched,
  };
}
