import React from 'react';
import * as R from 'ramda';
import { func } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { useNotificationState, useModalContext } from '@poly/admin-ui';
import { removePropDeep, isNilOrEmpty } from '@poly/utils';
import { IconButton } from '@poly/admin-book';

const unassignUserFromUserGroupMutation = gql`
  mutation unassignUserFromUserGroupMutation(
    $input: UnassignUserFromUserGroupInput!
  ) {
    unassignUserFromUserGroup(input: $input) {
      _id
    }
  }
`;

// rejectNilValuesDeep :: Object -> Object
function rejectNilValuesDeep(props) {
  return R.compose(
    R.map(R.when(R.is(Object), rejectNilValuesDeep)),
    R.reject(isNilOrEmpty),
  )(props);
}

// prepareUnassignInputByUser :: User -> UnassignUserFromUserGroupInput
const prepareUnassignInputByUser = R.applySpec({
  userId: R.prop('_id'),
  userGroupId: R.prop('userGroupId'),
  variables: R.compose(
    R.map(
      R.compose(
        R.over(R.lensProp('value'), rejectNilValuesDeep),
        R.pick(['variableId', 'value']),
        removePropDeep('__typename'),
      ),
    ),
    R.prop('variables'),
  ),
});

export function UnassignUserButton({ debouncedRefetch, ...props }) {
  const { openConfirmModal } = useModalContext();
  const { showSuccessNotification } = useNotificationState();
  const [unassignUserFromUserGroup] = useMutation(
    unassignUserFromUserGroupMutation,
  );

  const handleUnassignUser = async () => {
    await unassignUserFromUserGroup({
      variables: { input: prepareUnassignInputByUser(props) },
    });

    showSuccessNotification('User was successfully unassigned from User Group');

    debouncedRefetch();
  };

  const onUnassignUser = () =>
    openConfirmModal({
      btnCaption: 'Yes',
      cancelBtnCaption: 'No',
      id: 'confirmUnassignUserModal',
      content: 'Are you sure you want to unassign user from user group?',
      onConfirm: (closeConfirmModal) => () => {
        handleUnassignUser();
        closeConfirmModal();
      },
    });

  return (
    <IconButton
      size={12}
      name="delete"
      hoverColor="red"
      onClick={onUnassignUser}
    />
  );
}

UnassignUserButton.propTypes = { debouncedRefetch: func.isRequired };
