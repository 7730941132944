import * as R from 'ramda';
import React from 'react';
import {
  capitalizeFirstLetter,
  openPrintWindowWithData,
} from '@poly/client-utils';
import { arrayOf, bool, shape, string } from 'prop-types';
import { CommonPrintLayout, useMapConfigToTableProps } from '@poly/admin-ui';
import { PRINT_PDF_CAPTION } from '@poly/constants';

import { DivisionTableS, DivisionsExportTextButton } from './common.js';

const exportTableConfig = [
  ['Name', R.propOr('', 'name')],
  ['Status', R.compose(capitalizeFirstLetter, R.propOr('', 'status'))],
];

function ExportTable({ divisions }) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    exportTableConfig,
    divisions,
  );

  return <DivisionTableS {...tableProps} isPrintPDF />;
}

ExportTable.propTypes = {
  divisions: arrayOf(
    shape({
      _id: string.isRequired,
      name: string.isRequired,
      status: string.isRequired,
    }),
  ),
};

export function DivisionsExportToPDFButton({ divisions, loading }) {
  const onClick = () =>
    openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: ExportTable,
      divisions,
      metaData: { title: 'Divisions' },
      fileName: 'divisions',
    });

  return (
    <DivisionsExportTextButton disabled={loading} onClick={onClick}>
      {PRINT_PDF_CAPTION}
    </DivisionsExportTextButton>
  );
}

DivisionsExportToPDFButton.propTypes = {
  loading: bool.isRequired,
  divisions: arrayOf(
    shape({
      _id: string.isRequired,
      name: string.isRequired,
      status: string.isRequired,
    }),
  ),
};
