import { useQuery, gql } from '@apollo/client';
import * as R from 'ramda';

import { isNilOrEmpty, ofArrayLegacy } from '@poly/utils';

// formatFilters :: Object -> GeneralLedgerFilters
const formatFilters = R.compose(
  R.omit(['export']),
  R.over(R.lensProp('accountIds'), R.when(R.is(String), ofArrayLegacy)),
  R.omit(['date']),
  R.converge(R.mergeRight, [R.identity, R.prop('date')]),
);

// eslint-disable-next-line import/no-unused-modules
export const generalLedgerQuery = gql`
  query generalLedgerReport($filters: GeneralLedgerFilters) {
    generalLedgerReport(filters: $filters) {
      entries {
        account {
          _id
          name
          code
          sub_account
        }
        forward_balance
        debits
        credits
        ending_balance
      }
      debits_total
      credits_total
    }
  }
`;

export const useGeneralReportQuery = (generalLedgerFilters) => {
  const filters = formatFilters(generalLedgerFilters);
  const { data, loading } = useQuery(generalLedgerQuery, {
    skip: isNilOrEmpty(generalLedgerFilters),
    variables: { filters },
    fetchPolicy: 'network-only',
  });

  return { data, loading };
};
