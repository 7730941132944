import * as R from 'ramda';
import { useState, useEffect } from 'react';
import { isNilOrEmpty, propEqLegacy } from '@poly/utils';

// checkIsEvent :: SyntheticEvent -> Boolean
const checkIsEvent = R.both(R.is(Object), R.has('target'));

// checkIsQuillDelta :: QuillDelta -> Boolean
const checkIsQuillDelta = R.both(R.is(Object), R.has('ops'));

// trimQuillDeltaOpsByIndex :: (Int, Function) -> [QuillDeltaOps]
// QuillDeltaOps = { insert: String }
const trimQuillDeltaOpsByIndex = (index, handleAdditionalLogic = R.identity) =>
  R.over(
    R.lensIndex(index),
    R.over(
      R.lensProp('insert'),
      R.compose(
        handleAdditionalLogic,
        R.compose(R.join('\n'), R.map(R.trim), R.split('\n')),
      ),
    ),
  );

// isDeltaInsertEmpty :: QuillDeltaOps -> Boolean
const isDeltaInsertEmpty = R.compose(
  R.ifElse(R.test(/\n/), R.F, R.compose(R.isEmpty, R.trim)),
  R.prop('insert'),
);

// trimQuillDeltaValue :: QuillDelta -> QuillDelta
// eslint-disable-next-line import/no-unused-modules
export const trimQuillDeltaValue = R.compose(
  R.objOf('ops'),
  R.cond([
    [propEqLegacy('length', 0), R.identity],
    [propEqLegacy('length', 1), trimQuillDeltaOpsByIndex(0)],
    [
      propEqLegacy('length', 2),
      R.compose(
        trimQuillDeltaOpsByIndex(0),
        trimQuillDeltaOpsByIndex(-1, R.concat(' ')),
      ),
    ],
    [
      R.T,
      R.compose(
        trimQuillDeltaOpsByIndex(0, R.concat(R.__, ' ')),
        trimQuillDeltaOpsByIndex(-1, R.concat(' ')),
        R.when(R.compose(isDeltaInsertEmpty, R.last), R.init),
        R.when(R.compose(isDeltaInsertEmpty, R.head), R.tail),
      ),
    ],
  ]),
  R.defaultTo([]),
  R.prop('ops'),
);

// trimTargetValueForEvent :: SyntheticEvent -> SyntheticEvent
// eslint-disable-next-line import/no-unused-modules
export const trimTargetValueForEvent = R.cond([
  [checkIsQuillDelta, trimQuillDeltaValue],
  [
    R.both(checkIsEvent, R.pathSatisfies(R.is(String), ['target', 'value'])),
    R.over(R.lensPath(['target', 'value']), R.trim),
  ],
  [R.is(String), R.trim],
  [R.T, R.identity],
]);

// prepareInternalValue :: SyntheticEvent -> String || [String]
const prepareInternalValue = R.cond([
  [checkIsEvent, R.path(['target', 'value'])],
  [checkIsQuillDelta, R.pick(['ops'])],
  [R.T, R.identity],
]);

// checkValuesAreDifferent :: ValuesContext -> Boolean
// ValuesContext :: { internalValue: Value, value: Value, skipTrim: Boolean }
// eslint-disable-next-line import/no-unused-modules
export const checkValuesAreDifferent = R.compose(
  R.converge(R.complement(R.equals), [R.head, R.last]),
  R.when(
    R.both(propEqLegacy(0, true), propEqLegacy(1, 0)),
    R.always(R.repeat(true, 2)),
  ),
  R.map(R.when(isNilOrEmpty, R.T)),
  R.props(['value', 'internalValue']),
  R.when(
    R.compose(R.not, R.propOr(false, 'skipTrim')),
    R.compose(
      R.over(R.lensProp('value'), trimTargetValueForEvent),
      R.over(R.lensProp('internalValue'), trimTargetValueForEvent),
    ),
  ),
);

// getInitialValue :: String -> String
const getInitialValue = R.when(R.isNil, R.always(''));

export const useTrimValueByComponentProps = ({ value, onChange, skipTrim }) => {
  const [internalValue, setInternalValue] = useState(getInitialValue(value));

  useEffect(() => {
    const areValuesDifferent = checkValuesAreDifferent({
      value,
      skipTrim,
      internalValue,
    });

    if (areValuesDifferent) {
      setInternalValue(value);
    }
  }, [value]);

  const onChangeTrimmed = (e) => {
    const newInternalValue = prepareInternalValue(e);
    setInternalValue(newInternalValue);

    if (onChange) {
      const eventWithTrimmedValue = trimTargetValueForEvent(e);
      onChange(eventWithTrimmedValue);
    }
  };

  return skipTrim
    ? { value, onChange }
    : { value: internalValue, onChange: onChangeTrimmed };
};
