import React from 'react';
import { string } from 'prop-types';
import { gql } from '@apollo/client';
import {
  commonUpdateFields,
  entities,
  SidebarUpdatesTab,
} from '@poly/admin-ui';
import { UpdateCollections } from '@poly/constants';

import { PrintLayoutProvider } from '../../../providers.js';

const CLIENT_UPDATES = gql`
  query CLIENT_UPDATES($id: ID!, $documentUpdatesInput: DocumentUpdatesInput!) {
    client(id: $id) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...commonUpdateFields
        }
        total
      }
    }
  }

  ${commonUpdateFields}
`;

export function ClientSidebarUpdatesTab({ clientId, clientName }) {
  const tabProps = {
    entity: {
      _id: clientId,
      preview: 'client',
      name: entities.CLIENT,
      humanReadableId: clientName,
    },
    updatesQuery: CLIENT_UPDATES,
    PrintLayout: PrintLayoutProvider,
    collection: UpdateCollections.clients,
  };

  return <SidebarUpdatesTab {...tabProps} />;
}

ClientSidebarUpdatesTab.propTypes = {
  clientId: string.isRequired,
  clientName: string.isRequired,
};
