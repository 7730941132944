import { collectionNames } from './collectionNames.js';

export const UpdateTypes = {
  // describes document fields' value changes
  AUDIT: 'audit',
  // posted by users
  INTERNAL: 'internal',
  // posted by users manually at Admin and for tasks/files/projects system operations
  CLIENT_REPORT: 'client_report',
  // posted by users at client portal and asset scanner
  CLIENT_UPDATE: 'client_update',
};

// We use it to keep track of collections that may be referenced from updates
export const UpdateCollections = Object.freeze({
  projects: collectionNames.projects,
  recurringProjects: collectionNames.recurringProjects,
  suppliers: collectionNames.suppliers,
  maintenancePlans: collectionNames.maintenancePlans,
  ocrInvoices: collectionNames.ocrInvoices,
  batches: collectionNames.batches,
  properties: collectionNames.properties,
  clients: collectionNames.clients,
  tasks: collectionNames.tasks,
  purchaseOrders: collectionNames.purchaseOrders,
  users: collectionNames.users,
  masterSuppliers: collectionNames.masterSuppliers,
  assets: collectionNames.assets,
  adminPurchaseOrders: collectionNames.adminPurchaseOrders,
  userGroups: collectionNames.userGroups,
});

export const UpdateWithActionsTypes = Object.freeze({
  projectClose: 'projectClose',
  projectComplete: 'projectComplete',
  confirmScope: 'confirmScope',
  supplierFollowup: 'supplierFollowup',
  recall: 'recall',
  confirmScheduling: 'confirmScheduling',
  confirmSatisfaction: 'confirmSatisfaction',
});

export const ActionTypesForbiddenForBlockedProject = [
  UpdateWithActionsTypes.projectClose,
  UpdateWithActionsTypes.projectComplete,
  UpdateWithActionsTypes.confirmScope,
  UpdateWithActionsTypes.recall,
  UpdateWithActionsTypes.supplierFollowup,
  UpdateWithActionsTypes.confirmScheduling,
  UpdateWithActionsTypes.confirmSatisfaction,
];

export const UPDATE_PERSIST_KEY_PREFIX = 'update.details.';
