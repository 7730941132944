import { InvoicesStatuses } from '@poly/constants';
import { alwaysNewDate } from '@poly/utils';
import { startOfYear } from 'date-fns';

import { ALL } from '../../modules/core/constants/general.js';

export const invoiceStatusSelectOptions = [
  { value: InvoicesStatuses.PAID, label: 'Paid' },
  { value: InvoicesStatuses.PARTIALLY_PAID, label: 'Partially Paid' },
  { value: InvoicesStatuses.PENDING, label: 'Pending' },
  { value: ALL, label: 'All' },
];

export const searchSupplierInvoicesFilters = {
  supplierId: 'supplierId',
  invoiceId: 'invoiceId',
  projectId: 'projectId',
  checkNumber: 'checkNumber',
  invoiceStatus: 'invoiceStatus',
  invoiceDate: 'invoiceDate',
  startDate: 'startDate',
  endDate: 'endDate',
  supplierTransactionType: 'supplierTransactionType',
};

export const defaultFilters = {
  [searchSupplierInvoicesFilters.invoiceStatus]: ALL,
  [searchSupplierInvoicesFilters.startDate]: startOfYear(
    alwaysNewDate(),
  ).toDateString(),
  [searchSupplierInvoicesFilters.endDate]: alwaysNewDate().toDateString(),
};
