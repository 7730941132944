import { gql } from '@apollo/client';

export const MENTION_GROUP_SEARCH_CHANGED = gql`
  subscription MENTION_GROUP_SEARCH_CHANGED(
    $searchInput: CollectionSearchParams!
  ) {
    searchMentionGroupChanged(input: $searchInput) {
      type
      id
    }
  }
`;
