import styled, { css } from 'styled-components';
import * as R from 'ramda';
import { getThemeColor } from '@poly/admin-book';
import { FlexColumn, FlexContainer } from './FlexContainer.js';

export const unpinnedBottomPanelStyles = css`
  display: flex;
  width: 100%;
  height: ${R.propOr('190px', 'height')};
  align-items: center;
  justify-content: center;
  background-color: ${getThemeColor(['secondaryLighter3'])};
  z-index: 2;
`;

const bottomPanelStyles = css`
  ${unpinnedBottomPanelStyles};
  box-shadow: 0 -2px 2px 0 ${getThemeColor(['midLight'])};
`;

export const BottomPanelContainer = styled(FlexContainer)`
  ${bottomPanelStyles}
`;
export const BottomPanelBody = styled(FlexColumn)`
  height: 100%;
  max-width: 1424px;
  width: calc(100% - 80px);
  margin: 0 30px 0 50px;
  padding: 15px 0 20px 0;
  justify-content: space-between;
`;

export const UnpinnedBottomPanelBody = styled(BottomPanelBody)`
  width: 100%;
  margin: 0;
  padding: 10px 0;
  background-color: ${getThemeColor(['white'])};
`;
