import * as R from 'ramda';

// getProjectsQueryByEntityQuery :: [ElasticQuery] -> ElasticQuery -> ElasticQuery
export const getProjectsQueryByEntityQuery = (entityQuery) =>
  R.ifElse(
    R.isNil,
    R.always({ bool: { must: entityQuery } }),
    R.over(
      R.lensProp('bool'),
      R.ifElse(
        R.has('must'),
        R.over(R.lensProp('must'), R.concat(entityQuery)),
        R.assoc('must', entityQuery),
      ),
    ),
  );
