import * as R from 'ramda';
import { mapConfigToTableProps } from '@poly/admin-book';

import { commonProjectTableConfig } from './ProjectsTable.js';
import { projectPriorityColumn } from '../columns/projects.js';
import { ProjectsPageProjectsTableComp } from './ProjectsTableComp.js';

const recalledProjectsTableConfig = R.insert(
  1,
  projectPriorityColumn,
  commonProjectTableConfig,
);

export const PrintProjectsTable = mapConfigToTableProps(
  R.prop('data'),
  commonProjectTableConfig,
)(ProjectsPageProjectsTableComp);

export const PrintRecalledProjectsTable = mapConfigToTableProps(
  R.prop('data'),
  recalledProjectsTableConfig,
)(ProjectsPageProjectsTableComp);
