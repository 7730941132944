import * as R from 'ramda';
import { aminPurchaseOrderStatuses } from '@poly/constants';
import { alwaysNewDate, ensureIsDate } from '@poly/utils';

// getAdminPOEndDate :: FormData -> Date
const getAdminPOEndDate = R.compose(
  ensureIsDate,
  R.path(['selectedAdminPO', 'endDate']),
);

export const OUTDATE_ADMIN_PO_WARNING_MSG =
  'Purchase Order is outdated or inactive';

// validateAdminPurchaseOrderBase :: FormData -> Boolean
export const validateAdminPurchaseOrderBase = R.allPass([
  R.compose(
    R.equals(aminPurchaseOrderStatuses.ACTIVE),
    R.path(['selectedAdminPO', 'status']),
  ),
  R.ifElse(
    R.path(['selectedAdminPO', 'endDate']),
    R.converge(R.gt, [getAdminPOEndDate, alwaysNewDate]),
    R.T,
  ),
  R.ifElse(
    R.both(R.path(['selectedAdminPO', 'endDate']), R.prop('endDate')),
    R.converge(R.gt, [
      getAdminPOEndDate,
      R.compose(ensureIsDate, R.path(['endDate'])),
    ]),
    R.T,
  ),
]);

// validateAminPurchaseOrder :: (String , FormData) -> Boolean
export const validateAminPurchaseOrder = (adminPOId, formData) =>
  R.ifElse(R.always(adminPOId), validateAdminPurchaseOrderBase, R.T)(formData);
