import React from 'react';
import * as R from 'ramda';
import { bool, func, shape, string } from 'prop-types';
import { Loader } from '@poly/admin-book/src/index.js';

import { TaskFormWrapper } from './form/index.js';
import { WarningComponent } from './complete/index.js';
import { initialCommonValues } from './form/constants.js';
import { useModalContext } from '../../../components/Modal/ModalProvider.js';
import { confirmCancelFormPayload } from '../../../components/common.js';
import { useProcessState } from '../../../hooks/useProcessState.js';
import { useTaskUpdatesById } from '../../../hooks/tasks/index.js';
import { prepareDetailsString } from '../../../utils/tasks.js';
import { mailToInitValues } from '../fields/MailTo/index.js';
import { useAddTaskUpdateHandler } from './form/hooks.js';

// getProjectWorkCompletionDateWithProjectId :: { project: Project } -> DateTime
const getProjectWorkCompletionDateWithProjectId = R.applySpec({
  workCompletionDate: R.path(['project', 'workCompletionDate']),
  projectId: R.path(['project', '_id']),
});

// getTaskProjectInfoByProp :: Task -> String
const getTaskProjectInfoByProp = (prop) =>
  R.ifElse(
    R.pathSatisfies(R.isNil, ['document', 'project']),
    R.path(['document', 'recurringProject', prop]),
    R.path(['document', 'project', prop]),
  );

export function PostTaskUpdateForm(props) {
  const {
    formId,
    isHQProject,
    onCancel: onCancelFromProps,
    payload: { _id, onCancel: onCancelFromPayload, collection },
  } = props;

  const modalContext = useModalContext();

  const { process } = useProcessState(formId);

  const { task, loading } = useTaskUpdatesById(_id);

  const { addTaskUpdate } = useAddTaskUpdateHandler({ task });

  const onCancel = () => {
    modalContext.closeModal(formId);

    const onCancelHandler = onCancelFromPayload || onCancelFromProps;

    if (onCancelHandler) {
      onCancelHandler();
    }
  };

  const openConfirmModal = () =>
    modalContext.openConfirmModal({
      ...confirmCancelFormPayload,
      onConfirm: (closeConfirmModal) => R.compose(closeConfirmModal, onCancel),
    });

  const initialValues = {
    collection,
    isHQProject,
    onlyUpdate: true,
    taskType: task?.action,
    mailTo: mailToInitValues,
    actionInProgress: process,
    details: { ...initialCommonValues, placeholder: null },
    ...getProjectWorkCompletionDateWithProjectId(props.payload),
  };

  const projectDbId = getTaskProjectInfoByProp('_id')(task);
  const projectId = getTaskProjectInfoByProp('projectId')(task);

  const mutate = (data) => addTaskUpdate({ documentId: task?._id, ...data });

  if (!_id) return null;

  if (loading) return <Loader />;

  if (!task && !loading) {
    return <WarningComponent onCancel={onCancel} />;
  }

  return (
    <TaskFormWrapper
      {...props}
      task={task}
      mutate={mutate}
      onCancel={onCancel}
      projectId={projectId}
      submitCaption="Update"
      projectDbId={projectDbId}
      actionInProgress={process}
      initialValues={initialValues}
      updates={task?.documentUpdates}
      openConfirmModal={openConfirmModal}
      successMessage="Task Update was added"
      title={`Post Update - ${task?.description}`}
      entityInfo={prepareDetailsString(task?.document)}
    />
  );
}

PostTaskUpdateForm.displayName = 'PostTaskUpdateForm';

PostTaskUpdateForm.propTypes = {
  onCancel: func,
  isHQProject: bool,
  formId: string.isRequired,
  payload: shape({ _id: string, onCancel: func, collection: string }),
};
