import React from 'react';
import { arrayOf, string, shape } from 'prop-types';

import {
  FigureLabel,
  FigureValue,
  FigureLabelAndValueWrapper,
  TimesheetSummaryWidgetWrapper,
} from './styles.js';
import { calculateTimesheetFigures } from './utils.js';

export const useTimesheetSummaryFigures = ({ myTimesheets = [] }) => {
  const entries = calculateTimesheetFigures(myTimesheets);

  return {
    entries,
  };
};

function FigureLabelAndValue({ label, value }) {
  return (
    <FigureLabelAndValueWrapper>
      <FigureLabel>{label}</FigureLabel>
      <FigureValue>{value}</FigureValue>
    </FigureLabelAndValueWrapper>
  );
}

FigureLabelAndValue.propTypes = {
  value: string.isRequired,
  label: string.isRequired,
};

function TimesheetSummaryWidgetC({ entries }) {
  return (
    <TimesheetSummaryWidgetWrapper>
      {entries.map((entry) => (
        <FigureLabelAndValue key={entry.label} {...entry} />
      ))}
    </TimesheetSummaryWidgetWrapper>
  );
}

TimesheetSummaryWidgetC.propTypes = {
  entries: arrayOf(
    shape({ value: string.isRequired, label: string.isRequired }),
  ),
};

export function TimesheetSummaryWidget({ myTimesheets, ...restProps }) {
  const { entries } = useTimesheetSummaryFigures({ myTimesheets });

  return <TimesheetSummaryWidgetC {...restProps} entries={entries} />;
}

TimesheetSummaryWidget.displayName = 'TimesheetSummaryWidget';

TimesheetSummaryWidget.propTypes = {
  myTimesheets: arrayOf(
    shape({
      date: string,
      type: string,
    }),
  ),
};
