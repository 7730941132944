import * as R from 'ramda';
import { isAfter } from 'date-fns';

import { ensureIsDate } from '../dates.js';
import { ramdaUseWith } from '../ramda.js';

// isAfterCurried :: Date -> Date -> Boolean
// (date1 > date2) => true
const isAfterCurried = R.curryN(2, isAfter);

// areTimesheetEntriesOverlap :: [TimesheetEntry] -> Boolean
export const areTimesheetEntriesOverlap = R.compose(
  R.any(R.apply(isAfterCurried)),
  R.converge(R.zip, [
    R.compose(R.map(ensureIsDate), R.init, R.map(R.prop('timeOut'))),
    R.compose(R.map(ensureIsDate), R.tail, R.map(R.prop('timeIn'))),
  ]),
);

// areTimeEntriesOverlapWithOtherTimesheets :: Timesheet -> [Timesheet] -> Boolean
export const areTimeEntriesOverlapWithOtherTimesheets = R.compose(
  areTimesheetEntriesOverlap,
  R.sortBy(R.prop('timeIn')),
  R.flatten,
  ramdaUseWith(R.append, [R.prop('entries'), R.map(R.prop('entries'))]),
);
