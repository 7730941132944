import React from 'react';
import styled from 'styled-components';
import { number, bool } from 'prop-types';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import {
  centsToDollarsWithFormat,
  insertQueryParamsIntoURL,
} from '@poly/utils';
import { READ_INVOICE_PERMISSION } from '@poly/security';
import { useRouterParams } from '@poly/client-routing';
import { useNotificationState } from '@poly/admin-ui';
import { LinkButton } from '@poly/admin-book';

import { routesNames } from '../../../routes/index.js';

const ApLinkBtnS = styled(LinkButton)`
  padding-left: 0;
`;

export function ApReportLink({ openingInvoicesTotal, isMaster = false }) {
  const { supplierId } = useRouterParams(['supplierId']);
  const hasAccess = useHasUserAccessWithPermission(READ_INVOICE_PERMISSION);
  const { showWarningNotification } = useNotificationState();

  const route = isMaster
    ? routesNames.MASTER_SUPPLIER_AP_REPORT
    : routesNames.SUPPLIER_AP_REPORT;

  const onClick = () => {
    if (!hasAccess) {
      return showWarningNotification('Not Authorized to read AP Report');
    }
    return window.open(
      insertQueryParamsIntoURL({ supplierId }, route),
      '_blank',
    );
  };
  return (
    <ApLinkBtnS onClick={onClick}>
      {centsToDollarsWithFormat(openingInvoicesTotal)}
    </ApLinkBtnS>
  );
}

ApReportLink.propTypes = {
  isMaster: bool,
  openingInvoicesTotal: number,
};
