import { createContext } from 'react';
import * as R from 'ramda';

export const PeopleTableContext = createContext({});

export const getPeopleContextFromProps = R.pick([
  'url',
  'formId',
  'entity',
  'SidebarLink',
  'gqlSearchQuery',
  'gqlSubscriptionQuery',
]);

export const addPeopleFormId = 'addPeopleFormId';
