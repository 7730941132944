import React from 'react';
import * as R from 'ramda';
import { LinkButton } from '@poly/admin-book/src/LinkButton/index.js';
import { EXPORT_XLS_CAPTION } from '@poly/constants/src/print.js';
import { arrayOf, bool, shape, string } from 'prop-types';
import { performExcelExport } from '@poly/client-utils/src/excel-export/export.js';
import { ExcelExportCellType } from '@poly/utils/src/excel-export/constants.js';
import { applyProp } from '@poly/utils/src/general.js';
import {
  centsToDollarsWithFormat,
  formatDate,
  createExcelExportCell,
  forceTitleCase,
} from '@poly/utils';

import { getExcelHeaderCells } from '../../../pages/PaySuppliersPage/PaySupplierInvoicesXlsExportBtn.js';
import { getPOClientInvoiceAmount } from './preparePurchaseOrderProjectsTableData.js';

const xlsExportHeaders = [
  ['Project #', 15, ExcelExportCellType.tableHeader],
  ['Status', 20, ExcelExportCellType.tableHeader],
  ['Completion Date', 20, ExcelExportCellType.tableHeader],
  ['Client Invoice', 20, ExcelExportCellType.tableCurrencyHeader],
  ['Remaining Balance', 20, ExcelExportCellType.tableCurrencyHeader],
];

// getRowPurchaseOrdersXLSConfig :: PurchaseOrders -> [[ExcelExportDataCell]]
const getRowPurchaseOrdersXLSConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 3),
    ...R.repeat(ExcelExportCellType.defaultCurrency, 2),
  ]),
  R.juxt([
    R.prop('projectId'),
    applyProp(forceTitleCase)('status'),
    applyProp(formatDate)('workCompletionDate'),
    R.compose(centsToDollarsWithFormat, getPOClientInvoiceAmount),
    applyProp(centsToDollarsWithFormat)('remainingBalance'),
  ]),
);

export const getPurchaseOrdersProjectsXLSConfig = (purchaseOrders) => ({
  exportFileName: 'po-projects.xlsx',
  columnWidths: xlsExportHeaders.map(R.nth(1)),
  rows: [
    [{ value: 'PO Projects', cellType: ExcelExportCellType.title }],
    getExcelHeaderCells(xlsExportHeaders),
    ...R.map(getRowPurchaseOrdersXLSConfig, purchaseOrders),
  ],
});

export function PurchaseOrdersProjectsXLSButton({ projects, loading }) {
  const handleClick = async () => {
    const config = getPurchaseOrdersProjectsXLSConfig(projects);
    await performExcelExport(config);
  };

  return (
    <LinkButton disabled={!projects.length || loading} onClick={handleClick}>
      {EXPORT_XLS_CAPTION}
    </LinkButton>
  );
}

PurchaseOrdersProjectsXLSButton.propTypes = {
  projects: arrayOf(
    shape({
      projectId: string.isRequired,
    }),
  ),
  loading: bool,
};
