import React from 'react';
import { GlobalAddBtn } from '@poly/admin-ui';
import {
  CREATE_MASTER_SUPPLIER_PERMISSION,
  CREATE_CLIENT_PERMISSION,
  CREATE_ASSET_PERMISSION,
} from '@poly/security';

import { routesNames } from '../../routes/index.js';
import { useAddProjectSidebar } from '../../sidebars/ProjectSidebar/forms/add/useAddProjectSidebar.js';
import { useAddClientSidebar } from '../../sidebars/ClientSidebar/forms/add/useAddClientSidebar.js';
import { useAddAssetSidebar } from '../../sidebars/AssetSidebar/forms/add/useAddAssetSidebar.js';
import { useAppBarItemClick } from './useAppBarItemClick.js';

const useGetGlobalAddButtonItems = () => {
  const openAddProjectSidebar = useAddProjectSidebar(true);
  const openAddClientSidebar = useAddClientSidebar(true);
  const openAddAssetSidebar = useAddAssetSidebar();

  const openProjectForm = useAppBarItemClick(() => openAddProjectSidebar({}));

  const openClientForm = useAppBarItemClick(() => openAddClientSidebar());

  const openAssetForm = useAppBarItemClick(() =>
    openAddAssetSidebar({ showClient: true }),
  );

  return [
    {
      title: 'Add Supplier',
      route: routesNames.ADD_SUPPLIER,
      permissions: [],
    },
    {
      title: 'Add Master Supplier',
      route: routesNames.ADD_MASTER_SUPPLIER,
      permissions: [CREATE_MASTER_SUPPLIER_PERMISSION],
    },
    {
      title: 'Add Client',
      onClick: openClientForm,
      permissions: [CREATE_CLIENT_PERMISSION],
    },
    {
      title: 'Add Project',
      onClick: openProjectForm,
      permissions: [],
    },
    {
      title: 'Add Asset',
      onClick: openAssetForm,
      permissions: [CREATE_ASSET_PERMISSION],
    },
  ];
};

export function GlobalAddButton() {
  const globalAddButtonItems = useGetGlobalAddButtonItems();

  return (
    <GlobalAddBtn
      renderButtonByPermission={false}
      subItems={globalAddButtonItems}
    />
  );
}
