import * as R from 'ramda';
import { NumericFormat, PatternFormat } from 'react-number-format';
import React, { useState, useCallback } from 'react';
import { bool, func, number, string } from 'prop-types';

export function InputNumber({
  type,
  maxValue,
  onChange,
  floatValue,
  dataTestId,
  decimalScale,
  allowNegative,
  onBlur: onBlurImp,
  onFocus: onFocusImp,
  skipFixedDecimalScale,
  onValueChange: onValueChangeImp,
  ...props
}) {
  const [fixedDecimalScale, setFixedDecimalScale] = useState(
    !skipFixedDecimalScale,
  );

  const onValueChange = useCallback((values) => {
    if (onChange) {
      const valueToPassUp = floatValue ? values.floatValue : values.value;
      onChange(valueToPassUp);
    }
    if (typeof onValueChangeImp === 'function') {
      onValueChangeImp(values);
    }
  }, []);

  const onBlur = useCallback((e) => {
    if (setFixedDecimalScale && !skipFixedDecimalScale) {
      setFixedDecimalScale(true);
    }
    if (onBlurImp) onBlurImp(e);
  }, []);

  const onFocus = useCallback((e) => {
    if (setFixedDecimalScale && !skipFixedDecimalScale) {
      setFixedDecimalScale(false);
    }
    if (onFocusImp) onFocusImp(e);
  }, []);

  const maxValueOrDefault = maxValue || 9999999999999999999n;

  const inputProps = {
    ...R.omit(['hasError'], props),
    onBlur,
    onFocus,
    onValueChange,
    fixedDecimalScale,
    'data-testid': dataTestId,
    decimalScale: decimalScale || 0,
    type: type === 'tel' ? type : 'text',
    allowNegative: allowNegative || false,
    ...(type !== 'tel'
      ? {
          isAllowed: (values) => {
            const { formattedValue, floatValue: floatValueFromValues } = values;
            return (
              formattedValue === '' || floatValueFromValues <= maxValueOrDefault
            );
          },
        }
      : {}),
  };

  const patternInputProps = R.omit(
    ['fixedDecimalScale', 'decimalScale', 'allowNegative'],
    inputProps,
  );

  if (props.format) {
    return <PatternFormat {...patternInputProps} />;
  }

  return <NumericFormat {...inputProps} />;
}

InputNumber.defaultProps = {
  dataTestId: 'number-input',
};

InputNumber.propTypes = {
  type: string,
  onBlur: func,
  onFocus: func,
  format: string,
  onChange: func,
  floatValue: bool,
  maxValue: number,
  dataTestId: string,
  allowNegative: bool,
  onValueChange: func,
  decimalScale: number,
  skipFixedDecimalScale: bool,
};
