import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, shape, string } from 'prop-types';
import { performExcelExport } from '@poly/client-utils';
import { EXPORT_XLS_CAPTION } from '@poly/constants';
import { LinkButton } from '@poly/admin-book';
import { Link } from '@poly/client-routing';

import { routesNames } from '../../../routes/index.js';
import { getSupplierSearchXLS } from './exportToXLSUtils.js';

const ShowInMapBtn = styled(Link)`
  font-size: 12px;
  line-height: 18px;
`;

// getSuppliersInfo :: Array -> String
const getSuppliersInfo = R.pipe(
  R.map(R.pick(['_id', 'company'])),
  (data) => JSON.stringify(data),
  (json) => localStorage.setItem('suppliers', json),
);
export function SupplierSearchShowInMapBtn({ suppliers }) {
  const onClick = () => getSuppliersInfo(suppliers);

  const additionalProps = {
    onClick,
    target: '_blank',
    children: 'Show In Map',
    href: routesNames.SUPPLIERS_MAP,
  };

  return <ShowInMapBtn {...additionalProps} />;
}

SupplierSearchShowInMapBtn.displayName = 'SupplierSearchShowInMapBtn';

SupplierSearchShowInMapBtn.propTypes = {
  suppliers: arrayOf(
    shape({
      _id: string.isRequired,
      company: shape({
        name: string,
        address: shape({
          formatted_address: string,
        }),
      }),
    }),
  ),
};

export function SupplierSearchExportToXLSBtn({ suppliers }) {
  const disabled = R.isEmpty(suppliers);

  const onClick = () => {
    const config = getSupplierSearchXLS(suppliers);
    performExcelExport(config);
  };

  return (
    <LinkButton {...{ disabled, onClick }}>{EXPORT_XLS_CAPTION}</LinkButton>
  );
}

SupplierSearchExportToXLSBtn.propTypes = {
  suppliers: arrayOf(
    shape({
      _id: string.isRequired,
    }),
  ),
};
