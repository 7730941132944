export const BillingProfileProjectType = {
  DEFAULT: 'defaultProjectType',
  CHILD_PM: 'childPMProjectType',
  CHILD_RECURRING: 'childRecurringProjectType',
};

export const BillingProfileConsolidateBy = {
  COST_CENTER: 'costCenter',
  REFERENCE_NUMBER: 'referenceNumber',
  ADMIN_PO: 'adminPO',
};
