import * as R from 'ramda';
import { string } from 'prop-types';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { isNilOrEmpty, pathEqLegacy } from '@poly/utils';
import {
  useMapConfigToTableProps,
  SidebarTabWrapper,
  SidebarTabHeader,
  usersPhoneColumn,
  usersEmailColumn,
  SearchInputComp,
  useSearchTabLogic,
} from '@poly/admin-ui';
import {
  getThemeColor,
  LinkButton,
  BodyRow,
  Loader,
  Table,
  Cell,
  Row,
} from '@poly/admin-book';

import { UnassignUserButton } from './UnassignUserButton.js';
import { SectionLabel } from '../../components/commonSidebarComponents.js';
import { usersNameColumn } from '../../../modules/tables/columns/users.js';
import { FlexRow } from '../../../components/appBar/HistoryPopUp/historyComponents.js';
import { useUserGroupAssignedUsersQuery } from '../../../pages/UserGroupManagement/hooks/useUserGroupAssignedUsersQuery.js';
import { useUserGroupAssignUserSidebar } from '../../../pages/UserGroupManagement/form/useUserGroupAssignUserSidebar.js';
import { preparePermissionScopes } from '../helpers/prepare-permission-scopes.js';

const FlexRowS = styled(FlexRow)`
  gap: 15px;
`;

const TableS = styled(Table)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 150px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 110px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 25px;
  }

  tr {
    padding: 0 19px 0 14px;

    &:last-child {
      border-bottom: 1px solid ${getThemeColor(['midLight'])};
    }
  }
`;

const VariablesRowS = styled(Row)`
  margin: 0 19px 0 14px;
  width: calc(100% - 33px);
  padding: 0;

  td:nth-child(1) {
    width: 30%;
    color: ${getThemeColor(['midDark'])};
  }

  td:nth-child(2) > span {
    display: inline-flex;
    padding: 2px 10px;
    border-radius: 5px;
    background-color: #e8ecf4;
    color: #4c66a2;
    margin: 0 10px 10px 0;
  }

  td:nth-child(3) {
    width: 5%;
  }
`;

// checkIfSingleUserGroupWithoutVariables :: User -> Boolean
const checkIfSingleUserGroupWithoutVariables = R.compose(
  R.isEmpty,
  R.reject(R.propSatisfies(isNilOrEmpty, 'variables')),
  R.defaultTo([]),
  R.prop('userGroups'),
);

function UnassignUserColumnComp(props) {
  const isSingleRow = checkIfSingleUserGroupWithoutVariables(props);

  if (isSingleRow) return <UnassignUserButton {...props} variables={[]} />;

  return null;
}

const assignedUsersTableConfig = [
  ['Name', usersNameColumn[1]],
  ['Phone', usersPhoneColumn[1]],
  ['Email', usersEmailColumn[1]],
  ['', UnassignUserColumnComp],
];

// prepareUserGroupVariables :: [UserGroupVariable] -> [PreparedVariable]
// PreparedVariable = { id: String, value: String }
const prepareUserGroupVariables = R.compose(
  R.map(
    R.applySpec({
      id: R.prop('variableId'),
      value: R.compose(
        R.prop('value'),
        R.head,
        preparePermissionScopes,
        R.objOf('typesUI'),
        R.prop('valueUI'),
      ),
    }),
  ),
  R.defaultTo([]),
);

// getVariablesRows :: { row: User } -> [UserGroup]
const getUserGroupRows = R.converge(R.filter, [
  R.compose(pathEqLegacy(['userGroup', '_id']), R.path(['row', 'userGroupId'])),
  R.pathOr([], ['row', 'userGroups']),
]);

// getUserProps :: { row: User } -> User
const getUserProps = R.compose(
  R.pick(['_id', 'userGroupId', 'debouncedRefetch']),
  R.prop('row'),
);

function UserRowComponent(props) {
  const { _id, userGroupId, debouncedRefetch } = useMemo(
    () => getUserProps(props),
    [props],
  );
  const preparedRows = useMemo(() => getUserGroupRows(props), [props]);

  return (
    <>
      <BodyRow {...props} />
      {preparedRows.map(({ variables }, index) => {
        const preparedVariables = prepareUserGroupVariables(variables);

        const isSingleRow = R.compose(
          checkIfSingleUserGroupWithoutVariables,
          R.prop('row'),
        )(props);

        if (isSingleRow) return null;

        return (
          <VariablesRowS key={`${userGroupId}_${R.toString(index)}`}>
            <Cell>{index === 0 ? 'Variables:' : ''}</Cell>
            <Cell>
              {preparedVariables.map(({ id, value }) => (
                <span key={id}>{value}</span>
              ))}
            </Cell>
            <Cell>
              <UnassignUserButton
                {...{ _id, userGroupId, variables, debouncedRefetch }}
              />
            </Cell>
          </VariablesRowS>
        );
      })}
    </>
  );
}

export function UserGroupSidebarUsersTab({ userGroupId }) {
  const { searchTerm, ...inputProps } = useSearchTabLogic('users');
  const onUserGroupAssignUser = useUserGroupAssignUserSidebar();

  const { assignedUsers, debouncedRefetch, loading } =
    useUserGroupAssignedUsersQuery(userGroupId, searchTerm);

  const tableProps = useMapConfigToTableProps(
    R.map(R.mergeLeft({ userGroupId, debouncedRefetch })),
    assignedUsersTableConfig,
    assignedUsers,
  );

  const onAddUsers = () =>
    onUserGroupAssignUser({ userGroupId, debouncedRefetch });

  return (
    <SidebarTabWrapper>
      <SidebarTabHeader skipMargin>
        <SectionLabel>Users</SectionLabel>
        <FlexRowS>
          <SearchInputComp {...inputProps} />
          <LinkButton onClick={onAddUsers}>Add Users</LinkButton>
        </FlexRowS>
      </SidebarTabHeader>
      {loading ? (
        <Loader />
      ) : (
        <TableS {...tableProps} RowComponent={UserRowComponent} />
      )}
    </SidebarTabWrapper>
  );
}

UserGroupSidebarUsersTab.propTypes = { userGroupId: string.isRequired };
