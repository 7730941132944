import React from 'react';
import { bool } from 'prop-types';
import styled from 'styled-components';

import { Text } from '../Text/index.js';
import { UploadIcon } from './UploadIcon.js';

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(246, 249, 253, 0.7);
  z-index: 1;
`;

// getDragAreaText :: Boolean -> String
export const getDragAreaText = (multi) =>
  `Drag & Drop file${multi ? 's' : ''} here or`;

export function DragOverComp({ multiple }) {
  return (
    <Wrapper>
      <UploadIcon />
      <Text color={['midDark']} size="16px">
        {getDragAreaText(multiple)}
      </Text>
    </Wrapper>
  );
}

DragOverComp.propTypes = { multiple: bool };
