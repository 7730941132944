import { gql } from '@apollo/client';

export const CREATE_CONTACT_USER_MUTATION = gql`
  mutation CREATE_CONTACT_USER_MUTATION($input: CreateContactUserInput!) {
    createContactUser(input: $input) {
      user {
        _id
      }
    }
  }
`;

export const UPDATE_CONTACT_USER_MUTATION = gql`
  mutation UPDATE_CONTACT_USER_MUTATION($update: UpdateContactUserInput!) {
    updateContactUser(update: $update) {
      _id
    }
  }
`;
