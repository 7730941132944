import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useOutSidebarContext } from '@poly/client-utils';

export const GLOBAL_SEARCH_SIDEBAR_ID = 'global_search_sidebar_id';

export const useGlobalSearchResultSidebar = (Component, isFocused) => {
  const { openOutSidebar, isSidebarOpened, closeOutSidebar } =
    useOutSidebarContext();
  const globalSearchText = useSelector((state) => state.globalSearchText);

  useEffect(() => {
    if (isFocused && globalSearchText.length > 2) {
      openOutSidebar({
        width: 800,
        isSteady: true,
        isHidden: false,
        alwaysFirst: true,
        content: <Component />,
        id: GLOBAL_SEARCH_SIDEBAR_ID,
      });
    } else if (isSidebarOpened(GLOBAL_SEARCH_SIDEBAR_ID)) {
      closeOutSidebar(GLOBAL_SEARCH_SIDEBAR_ID);
    }
  }, [globalSearchText, isFocused]);
};
