import * as R from 'ramda';
import { css } from 'styled-components';
import { ClientAppNames, TaskDueDateFilterPreset } from '@poly/constants';
import { getThemeColor } from '@poly/admin-book';
import {
  READ_FINANCIAL_DIRECTORY_PERMISSION,
  READ_TIMESHEET_REPORT_PERMISSION,
  READ_SPEND_REPORT_PERMISSION,
  LOGIN_APP_PERMISSION,
  CREATE_SERVICE_TYPE,
  UPDATE_SERVICE_TYPE,
  APPROVE_SUPPLIER_INVOICE_PERMISSION,
  READ_REQUESTED_SUPPLIER_DOCUMENT_PERMISSION,
  USE_TASK_TEMPLATE_PERMISSION,
} from '@poly/security';
import {
  getTaskMenuSubItemsByStatusAndRoute,
  AppBarTasksCountBubble,
} from '@poly/admin-ui';

import {
  projectStatusesUI,
  projectUIStatuses,
} from '../../modules/core/constants/projects.js';
import { routesNames } from '../../routes/index.js';
import { projectTabByStatus } from '../../modules/tabs/TabsWithRouter.js';
import {
  getUnassignedProjectsCountByDashboardQuery,
  ProjectsCountBubble,
} from './ProjectsCountBubble.js';
import { AccountingAppBarBubble } from './AccountingAppBarBubble.js';

const statusToDashboardDataPathMap = {
  [projectUIStatuses.DUE_TODAY]: ['ownProjectsCounts', 'dueToday'],
  [projectUIStatuses.DUE_TOMORROW]: ['ownProjectsCounts', 'dueTomorrow'],
  [projectUIStatuses.PAST_DUE]: ['ownProjectsCounts', 'overdue'],
};

const counterStyles = css`
  color: ${getThemeColor(['accent'])};
`;

// getProjectsMenuItemLoader :: MenuPayload -> Boolean
const getTasksMenuItemLoader = R.propOr(false, 'taskCountsLoading');

// getProjectsMenuItemByStatus :: String -> MenuSubItem
const getProjectsMenuItemByStatus = (status) => ({
  route: routesNames.MY_PROJECTS,
  tab: projectTabByStatus(status),
  title: projectStatusesUI[status],
  getLoaderByMenuPayload:
    status !== projectUIStatuses.DUE_TODAY
      ? R.propOr(false, 'projectCountsLoading')
      : null,
  counterStyles: status === projectUIStatuses.PAST_DUE ? counterStyles : '',
  getCountByMenuPayload: R.pathOr(0, statusToDashboardDataPathMap[status]),
});

const additionalTaskSubItemProps = {
  getLoaderByMenuPayload: getTasksMenuItemLoader,
};

export const appBarMenuItems = [
  {
    title: 'Projects',
    Bubble: ProjectsCountBubble,
    subItems: [
      getProjectsMenuItemByStatus(projectUIStatuses.PAST_DUE),
      getProjectsMenuItemByStatus(projectUIStatuses.DUE_TODAY),
      getProjectsMenuItemByStatus(projectUIStatuses.DUE_TOMORROW),
      {
        title: 'Unassigned',
        route: routesNames.UNASSIGNED_PROJECTS,
        getCountByMenuPayload: getUnassignedProjectsCountByDashboardQuery,
      },
      {
        title: 'Projects Directory',
        route: routesNames.PROJECTS_DIRECTORY,
      },
      {
        title: 'My Projects',
        route: routesNames.MY_PROJECTS,
      },
    ],
    isSub: true,
  },
  {
    title: 'Tasks',
    Bubble: AppBarTasksCountBubble,
    subItems: [
      ...getTaskMenuSubItemsByStatusAndRoute(
        TaskDueDateFilterPreset.PAST_DUE,
        routesNames.MY_TASKS,
        additionalTaskSubItemProps,
      ),
      ...getTaskMenuSubItemsByStatusAndRoute(
        TaskDueDateFilterPreset.DUE_TODAY,
        routesNames.MY_TASKS,
      ),
      ...getTaskMenuSubItemsByStatusAndRoute(
        TaskDueDateFilterPreset.DUE_TOMORROW,
        routesNames.MY_TASKS,
        additionalTaskSubItemProps,
      ),
      ...getTaskMenuSubItemsByStatusAndRoute(
        TaskDueDateFilterPreset.READY_TO_CLOSE,
        routesNames.MY_TASKS,
        additionalTaskSubItemProps,
      ),
      ...getTaskMenuSubItemsByStatusAndRoute(
        TaskDueDateFilterPreset.ALL,
        routesNames.MY_TASKS,
        additionalTaskSubItemProps,
      ),
      {
        title: 'Task Templates',
        route: routesNames.TASK_TEMPLATES,
        permissions: [USE_TASK_TEMPLATE_PERMISSION],
      },
    ],
    isSub: true,
  },
  {
    title: 'Accounts',
    relatedRoutes: [
      routesNames.SUPPLIER,
      routesNames.SUPPLIERS_SUBCONTRACTORS,
      routesNames.ADD_SUPPLIER,
    ],
    subItems: [
      { title: 'Account Index', route: routesNames.ACCOUNT_INDEX },
      { title: 'Search Suppliers', route: routesNames.SUPPLIER_SEARCH },
      { title: 'Search Clients', route: routesNames.CLIENTS },
      { title: 'Search Prospects', route: routesNames.PROSPECTS },
    ],
    isSub: true,
  },
  {
    title: 'Staff',
    subItems: [
      { title: 'Staff Directory', route: routesNames.STAFF_DIRECTORY },
      { title: 'Timesheet', route: routesNames.TIME_SHEET },
      { title: 'Mention Groups', route: routesNames.MENTION_GROUPS },
      {
        title: 'Timesheet Report',
        route: routesNames.TIME_SHEET_REPORT,
        permissions: [READ_TIMESHEET_REPORT_PERMISSION],
      },
    ],
    isSub: true,
  },
  { title: 'Reports', link: routesNames.REPORTS },
  {
    title: 'Accounting',
    Bubble: AccountingAppBarBubble,
    content: { width: '280px', position: 'left' },
    subItems: [
      {
        title: 'Financial Directory',
        route: routesNames.FINANCIAL_DIRECTORY,
        permissions: [READ_FINANCIAL_DIRECTORY_PERMISSION],
      },
      {
        title: 'Client Invoicing',
        route: routesNames.CLIENT_INVOICING,
        permissions: [READ_FINANCIAL_DIRECTORY_PERMISSION],
      },
      {
        title: 'Supplier Documents',
        route: routesNames.NEW_SUPPLIER_DOCUMENTS_REVIEW,
        permissions: [READ_REQUESTED_SUPPLIER_DOCUMENT_PERMISSION],
        getCountByMenuPayload: R.propOr(0, 'supplierDocumentsForReviewCount'),
      },
      {
        title: 'Supplier Invoices',
        route: routesNames.APPROVE_SUPPLIER_INVOICES,
        getCountByMenuPayload: R.propOr(0, 'receivedInvoicesTotal'),
        permissions: [APPROVE_SUPPLIER_INVOICE_PERMISSION],
      },
    ],
    permissions: [LOGIN_APP_PERMISSION, READ_FINANCIAL_DIRECTORY_PERMISSION],
    typesByProps: R.always({ clientApp: ClientAppNames.TIAA_PORTAL }),
    isSub: true,
  },
  {
    title: 'Admin',
    link: routesNames.ADMIN_SETTINGS,
    permissions: [
      CREATE_SERVICE_TYPE,
      UPDATE_SERVICE_TYPE,
      READ_SPEND_REPORT_PERMISSION,
    ],
  },
];
