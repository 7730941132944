import React from 'react';
import styled from 'styled-components';
import { useModalContext } from '@poly/admin-ui';
import { arrayOf, func, shape, string } from 'prop-types';
import { LinkButton, MainHeader, PageHeaderContainer } from '@poly/admin-book';

import { FlexRow } from '../../components/appBar/HistoryPopUp/historyComponents.js';
import { AssetManufacturersForm } from './AssetManufacturersForm.js';
import { addAssetManufacturerFormId } from './constants.js';
import { MergeAssetComponentsButton } from './MergeAssetComponentsButton.js';
import { mergeAssetComponentsEntity } from './merge-config.js';

const HeaderLinksWrapper = styled(FlexRow)`
  justify-content: space-between;
  width: 115px;
`;

function AddAssetManufacturerButton() {
  const { openModalForm } = useModalContext();
  const onClick = () =>
    openModalForm({
      id: addAssetManufacturerFormId,
      title: 'Create New Manufacturer',
      formId: addAssetManufacturerFormId,
      btnCaption: 'Create',
      content: <AssetManufacturersForm isNewManufacturer />,
    });

  return (
    <LinkButton
      onClick={onClick}
      data-testid="add-new-asset-manufacturer-button-id"
    >
      Add New
    </LinkButton>
  );
}

export function AssetManufacturersPageHeader({
  selectedRows,
  setSelectedRows,
  assetManufacturers,
}) {
  return (
    <PageHeaderContainer>
      <MainHeader>Asset Manufacturers</MainHeader>
      <HeaderLinksWrapper>
        <MergeAssetComponentsButton
          documents={assetManufacturers}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          mergeEntity={mergeAssetComponentsEntity.MANUFACTURER}
        />
        <AddAssetManufacturerButton />
      </HeaderLinksWrapper>
    </PageHeaderContainer>
  );
}

AssetManufacturersPageHeader.propTypes = {
  selectedRows: arrayOf(string),
  setSelectedRows: func,
  assetManufacturers: arrayOf(
    shape({
      _id: string,
      name: string,
    }),
  ),
};
