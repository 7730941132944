import React from 'react';
import { func } from 'prop-types';
import { formatSelectOptionsByConstants } from '@poly/client-utils';
import { Select } from '@poly/admin-book';

import { supplierTaxClassesUI } from '../../../../../constants/suppliers.js';

export function TaxClassSelect({ onChange, changeFieldValue, ...props }) {
  const options = formatSelectOptionsByConstants(supplierTaxClassesUI);

  const handleChange = (e) => {
    onChange(e);
    // to prevent save social number with tax ID
    changeFieldValue('tax.socialSecurityNumber', null);
  };

  return (
    <Select
      {...props}
      name="SupplierStatusSelect"
      onChange={handleChange}
      options={options}
      isClearable
    />
  );
}

TaxClassSelect.displayName = 'SupplierTaxClassSelect';

TaxClassSelect.propTypes = {
  onChange: func.isRequired,
  changeFieldValue: func.isRequired,
};
