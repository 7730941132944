import React from 'react';
import * as R from 'ramda';
import { shape, number } from 'prop-types';
import { AppBarMenuBubble } from './MenuBubble.js';

// getUnassignedProjectsCountByDashboardQuery ::
//   DashboardQueryData -> Number
export const getUnassignedProjectsCountByDashboardQuery = R.propOr(
  0,
  'globalProjectsUnassignedCount',
);

const getProjectsBubbleCountByPayload = R.compose(
  R.sum,
  R.juxt([
    getUnassignedProjectsCountByDashboardQuery,
    R.pathOr(0, ['ownProjectsCounts', 'dueToday']),
    R.pathOr(0, ['ownProjectsCounts', 'overdue']),
  ]),
);

export function ProjectsCountBubble({ menuPayload }) {
  const count = getProjectsBubbleCountByPayload(menuPayload);
  return <AppBarMenuBubble value={count} />;
}

ProjectsCountBubble.propTypes = {
  menuPayload: shape({
    globalProjectsUnassignedCount: number,
    ownProjectsCounts: shape({ dueToday: number }),
  }),
};
