import * as R from 'ramda';
import { applyIfNotNil } from './general.js';

export const SearchTermQueryCreators = {
  defaultMatch:
    ({ field, boost }) =>
    (searchTerm) => ({
      match: {
        [field]: {
          query: searchTerm,
          ...(boost ? { boost } : {}),
        },
      },
    }),

  /**
   * for plain text
   */
  fuzzyMatch:
    ({ field, boost }) =>
    (searchTerm) => ({
      match: {
        [field]: {
          fuzziness: 'AUTO',
          query: searchTerm,
          operator: 'and',
          ...(boost ? { boost } : {}),
        },
      },
    }),

  /**
   * for card numbers, ids etc.
   */
  wildcard:
    ({ field, boost = 1 }) =>
    (searchTerm) => ({
      wildcard: {
        [field]: {
          value: `*${searchTerm}*`,
          boost,
        },
      },
    }),
};

// eslint-disable-next-line import/no-unused-modules
export const FULL_TEXT_SEARCH_PROP = 'fullTextSearchStr';

export const createFullTextSearchQuery = SearchTermQueryCreators.fuzzyMatch({
  field: FULL_TEXT_SEARCH_PROP,
});

/**
 * getNestedQuery :: FieldName -> NestedPathString -> QueryPathString -> DataObject -> QueryObject
 */
export const getNestedQuery = R.curry((nestedPath, path, data) =>
  applyIfNotNil(
    R.pipe(
      R.objOf(`${nestedPath}.${path}`),
      R.objOf('match'),
      R.objOf('query'),
      R.mergeRight({ path: nestedPath }),
      R.objOf('nested'),
    ),
  )(data),
);
