import React from 'react';
import { string, func } from 'prop-types';
import { useMutation, gql } from '@apollo/client';
import { useNotificationState } from '@poly/admin-ui/src/hooks/useNotificationState.js';
import { LinkButton } from '@poly/admin-book/src/LinkButton/index.js';

// eslint-disable-next-line import/no-unused-modules
export const reverseWrittenOffClientInvoiceMutation = gql`
  mutation reverseWrittenOffClientInvoiceMutation($id: ID!) {
    reverseWrittenOffClientInvoice(id: $id) {
      id
    }
  }
`;

export function ReverseWrittenOffClientInvoiceButton({ _id, refetch }) {
  const { showSuccessNotification } = useNotificationState();
  const [reverseWrittenOffClientInvoice] = useMutation(
    reverseWrittenOffClientInvoiceMutation,
  );

  const onClick = async () => {
    await reverseWrittenOffClientInvoice({ variables: { id: _id } });
    refetch();
    showSuccessNotification('Project Invoices successfully reversed!');
  };

  return <LinkButton onClick={onClick}>Reverse Invoice</LinkButton>;
}

ReverseWrittenOffClientInvoiceButton.propTypes = {
  _id: string.isRequired,
  refetch: func.isRequired,
};
