import React from 'react';
import * as R from 'ramda';
import { string, func, shape } from 'prop-types';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useMutation, gql } from '@apollo/client';
import { AFTER_HOURS_CALL_LINE_NAME, ProjectType } from '@poly/constants';
import { useNavigate, useRouterQuery } from '@poly/client-routing';
import { alwaysNewDate, ofArrayLegacy, propEqLegacy } from '@poly/utils';
import {
  useOnSubmitSetStopSubmitting,
  useNotificationState,
} from '@poly/admin-ui';

import { routesNames } from '../../routes/index.js';
import { ClientInvoiceForm } from './ClientInvoiceForm.js';
import { projectInfoPropTypes } from './prop-types.js';
import { usePrintInvoicesMutation } from '../SearchClientInvoices/usePrintInvoices.js';

// eslint-disable-next-line import/no-unused-modules
export const createClientInvoiceMutation = gql`
  mutation createClientInvoice($input: CreateClientInvoiceInput!) {
    createClientInvoice(input: $input) {
      clientInvoice {
        _id
      }
    }
  }
`;

// getProjectTimeLines:: ProjectInfo -> [InvoiceLineItem]
const getProjectTimeLines = R.compose(
  R.map(
    R.applySpec({
      isNonMarkup: R.T,
      name: R.prop('rateDescription'),
      quantity: R.prop('hours'),
      rate: R.prop('rate'),
      isProjectTime: R.T,
    }),
  ),
  R.reject(propEqLegacy('total', 0)),
  R.reject(propEqLegacy('isInvoiced', true)),
  R.propOr([], 'timeTracking'),
);

const defaultInvoiceLine = { isNonMarkup: false };

const initialValues = R.applySpec({
  glCode: { value: R.prop('defaultAccountCode') },
  invoiceDate: alwaysNewDate,
  lines: R.converge(R.concat, [
    getProjectTimeLines,
    R.cond([
      [
        R.both(
          propEqLegacy('type', ProjectType.WORK_ORDER),
          R.propSatisfies(R.both(R.is(Number), R.lt(0)), 'woCharge'),
        ),
        R.compose(
          R.append(defaultInvoiceLine),
          ofArrayLegacy,
          R.applySpec({
            name: R.always('Work Order Charge'),
            isNonMarkup: R.always(true),
            quantity: R.always(1),
            rate: R.prop('woCharge'),
          }),
        ),
      ],
      [
        propEqLegacy('type', ProjectType.FEE),
        R.compose(
          ofArrayLegacy,
          R.mergeRight({ ...defaultInvoiceLine, quantity: 1 }),
          R.applySpec({
            name: R.prop('customFeeDescription'),
            rate: R.compose(
              parseFloat,
              R.either(R.prop('suggestedAmount'), R.prop('feeAmount')),
            ),
          }),
        ),
      ],
      [
        propEqLegacy('isAfterHoursCall', true),
        R.compose(
          R.append(defaultInvoiceLine),
          ofArrayLegacy,
          R.applySpec({
            name: R.always(AFTER_HOURS_CALL_LINE_NAME),
            isNonMarkup: R.always(true),
            quantity: R.always(1),
            rate: R.prop('afterHoursCallRate'),
          }),
        ),
      ],
      [
        propEqLegacy('type', ProjectType.LISTING),
        R.compose(
          ofArrayLegacy,
          R.mergeRight({ ...defaultInvoiceLine, quantity: 1 }),
          R.applySpec({
            rate: R.prop('referralFee'),
          }),
        ),
      ],
      [R.T, R.always([defaultInvoiceLine])],
    ]),
  ]),
  description: R.prop('invoiceDescription'),
  taxPercent: R.prop('rate'),
  suggestedAmount: R.prop('suggestedAmount'),
});

// prepareInputValues :: FormValues -> FormValues
const prepareInputValues = R.compose(
  R.omit(['suggestedAmount', 'glCode', 'customTax']),
  R.ifElse(
    R.prop('customTax'),
    R.dissoc('taxPercent'),
    R.omit(['freightRawAmount', 'taxRawAmount']),
  ),
  R.converge(R.mergeRight, [
    R.identity,
    R.compose(
      R.objOf('lines'),
      R.sortBy(R.propOr(false, 'isProjectTime')),
      R.propOr([], 'lines'),
    ),
  ]),
);

function ClientInvoiceFormC({ handleSubmit, form, ...props }) {
  const onSubmit = async (event) => {
    await handleSubmit(event);
    if (form.getState().valid) form.reset();
  };

  return <ClientInvoiceForm onSubmit={onSubmit} form={form} {...props} />;
}

ClientInvoiceFormC.propTypes = {
  handleSubmit: func.isRequired,
  form: shape({
    mutators: shape({
      push: func.isRequired,
    }),
  }).isRequired,
};

export function CreateClientInvoiceForm({ projectInfo, formId }) {
  const [createClientInvoice] = useMutation(createClientInvoiceMutation);
  const { showSuccessNotification } = useNotificationState();
  const navigate = useNavigate();
  const printClientInvoice = usePrintInvoicesMutation();
  const { suggestedAmount } = useRouterQuery(['suggestedAmount']);

  const submitHandler = async (values) => {
    const formElement = document.getElementById(formId);
    const isPrint = formElement.hasAttribute('print');
    const input = {
      ...prepareInputValues(values),
      projectId: projectInfo._id,
      clientId: projectInfo.clientId,
    };

    const res = await createClientInvoice({
      variables: { input },
    });
    formElement.removeAttribute('print');
    const clientInvoiceId = R.path(
      ['data', 'createClientInvoice', 'clientInvoice', '_id'],
      res,
    );
    showSuccessNotification('Invoice successfully created');
    if (isPrint) {
      printClientInvoice([clientInvoiceId]);
    }
    navigate(routesNames.CREATE_CLIENT_INVOICE_SEARCH);
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, submitHandler);

  return (
    <Form
      formId={formId}
      onSubmit={onSubmit}
      subscription={{ values: true }}
      mutators={arrayMutators}
      initialValues={initialValues({
        ...projectInfo,
        suggestedAmount: Number(suggestedAmount),
      })}
      keepDirtyOnReinitialize
    >
      {({ handleSubmit, form, values }) => (
        <ClientInvoiceFormC
          handleSubmit={handleSubmit}
          form={form}
          values={values}
          projectInfo={projectInfo}
          formId={formId}
        />
      )}
    </Form>
  );
}

CreateClientInvoiceForm.propTypes = {
  projectInfo: projectInfoPropTypes.isRequired,
  formId: string.isRequired,
};
