import * as R from 'ramda';
import { applySpecWithFields, propEqLegacy } from '@poly/utils';

// areRoutesEqual :: URL -> URL -> Boolean
const areRoutesEqual = R.curryN(2, R.compose(R.apply(R.equals), Array.of));

// setIsActiveByRoute :: URL -> MenuItem -> MenuItem
const setIsActiveByRoute = (route) =>
  R.pipe(
    R.when(
      R.anyPass([
        R.compose(areRoutesEqual(route), R.prop('link')),
        R.compose(areRoutesEqual(route), R.path(['link', 'pathname'])),
        R.compose(
          R.any(areRoutesEqual(route)),
          R.map(R.prop('route')),
          R.propOr([], 'subItems'),
        ),
        R.compose(R.any(areRoutesEqual(route)), R.propOr([], 'relatedRoutes')),
        R.prop('customActive'),
      ]),
      R.assoc('isActive', true),
    ),
  );

// setLinkByRoute :: RouterObject -> MenuItem -> MenuItem
const setLinkByRoute = R.curry((router, item) =>
  R.when(
    propEqLegacy('link', R.prop('pathname', router)),
    R.mergeDeepLeft({ link: R.pick(['pathname', 'query'], router) }),
  )(item),
);

/**
 * prepareLinksByRouter :: {menuItems: [MenuItem], routerObject} -> {[UpdatedMenuItem]}
 */
export const prepareLinksByRouter = applySpecWithFields({
  menuItems: ({ menuItems, router }) =>
    R.map(setLinkByRoute(router), menuItems),
});

/**
 * detectActiveMenuItem :: {currentRoute: URL, menuItems: [MenuItem]} -> [MenuItem]
 */
export const detectActiveMenuItem = R.converge(R.map, [
  R.compose(setIsActiveByRoute, R.prop('currentRoute')),
  R.prop('menuItems'),
]);

// prepareMenuItems :: {currentRoute: URL, menuItems: [MenuItem], routerObject} -> [MenuItem]
export const prepareMenuItems = R.compose(
  detectActiveMenuItem,
  prepareLinksByRouter,
);

// getRelatedRoutesPath :: MenuItem -> [Route]
const getRelatedRoutesPath = R.compose(
  R.map(R.objOf('path')),
  R.defaultTo([]),
  R.prop('relatedRoutes'),
);

// getRoutesByMenuItems :: [MenuItem] -> [Route]
// Route = { path: String }
export const getRoutesByMenuItems = R.compose(
  R.flatten,
  R.map(
    R.ifElse(
      R.prop('link'),
      R.juxt([R.applySpec({ path: R.prop('link') }), getRelatedRoutesPath]),
      R.juxt([
        getRelatedRoutesPath,
        R.compose(
          R.map(R.applySpec({ path: R.prop('route') })),
          R.defaultTo([]),
          R.prop('subItems'),
        ),
      ]),
    ),
  ),
);

/**
 * formatAlertsNumber :: Number -> String
 */
export const formatNotificatorDotValue = R.ifElse(
  R.gte(99),
  R.identity,
  R.always('99+'),
);

/**
 * formatRoutePath :: (String, String) -> String
 */
export const formatRoutePath = (route, tab = null) =>
  tab ? `${route}?tab=${tab}` : route;
