import {
  bool,
  func,
  shape,
  object,
  string,
  arrayOf,
  oneOfType,
} from 'prop-types';

const subItemDefaultProps = {
  route: oneOfType([string, object]),
  permissions: arrayOf(string),
  title: string.isRequired,
  withCounter: func, // DEPRECATED: use getCountByMenuPayload instead
  getCountByMenuPayload: func,
  inNewTab: bool,
  tab: string,
  onClick: func,
};

export const subItemPropTypes = {
  ...subItemDefaultProps,
  subItems: arrayOf(shape(subItemDefaultProps)),
};

export const menuItemWithSubItemsPropTypes = {
  isActive: bool,
  title: string,
  relatedRoutes: arrayOf(string),
  subItems: arrayOf(shape(subItemPropTypes)).isRequired,
};

export const menuPopUpPropTypes = {
  isSub: bool,
  className: string,
  closeParent: func,
  Trigger: oneOfType([func, object]).isRequired,
  content: shape({ width: string, position: string }),
  ...menuItemWithSubItemsPropTypes,
};

export const popUpPropTypes = {
  onMont: func,
  handleChildClick: func,
  setIsOpen: func.isRequired,
  content: shape({ width: string, position: string }),
  subItems: arrayOf(shape(subItemDefaultProps)).isRequired,
};
