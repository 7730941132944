export const AccountTypeStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const AccountTypeCategories = {
  expense: 'expenseTypeCategory',
  income: 'incomeTypeCategory',
  asset: 'assetTypeCategory',
  liability: 'liabilityTypeCategory',
  equity: 'equityTypeCategory',
};

export const NormalBalanceTypes = {
  DEBIT: 'debitNormalBalance',
  CREDIT: 'creditNormalBalance',
};

export const SystemAccountTypes = {
  CREDIT_CARD: 'creditCardSystemAccountType',
  BANK_ACCOUNT: 'bankAccountSystemAccountType',
  ACCOUNTS_PAYABLE: 'accountsPayableSystemAccountType',
  ACCOUNTS_RECEIVABLE: 'accountsReceivableSystemAccountType',
};
