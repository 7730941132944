import * as R from 'ramda';
import { prepareAddressDeepBeforeMutate } from '@poly/admin-ui';
import {
  prepareEmptySupplierCompanyFields,
  prepareEmptySupplierRatesFields,
} from '@poly/admin-ui/src/modules/forms/supplierForm/edit/formDataToMutation.js';
import { applySpecWithFields } from '@poly/utils/src/objects.js';

import { prepareRequestedDocumentsBeforeMutation } from '../../DocumentsRequest/DocumentsRequestForm.js';

// prepareApproveOnboardingInput :: FormData -> SupplierOnboardingInput
export const prepareApproveOnboardingInput = R.compose(
  R.over(
    R.lensProp('onboarding'),
    R.compose(
      applySpecWithFields({
        tax: {
          id: R.pathOr(null, ['tax', 'id']),
          classification: R.pathOr(null, ['tax', 'classification']),
          socialSecurityNumber: R.pathOr(null, ['tax', 'socialSecurityNumber']),
          is1099: R.pathOr(false, ['tax', 'is1099']),
        },
      }),
      prepareEmptySupplierRatesFields,
      prepareEmptySupplierCompanyFields,
    ),
  ),
  R.dissocPath(['onboarding', 'tax', 'w9File']),
  prepareRequestedDocumentsBeforeMutation(false),
  prepareAddressDeepBeforeMutate('company'),
  prepareAddressDeepBeforeMutate('remit'),
);
