import * as R from 'ramda';
import { roundTo, formatRawNumber } from '@poly/utils';
import {
  supplierPhoneTypes,
  SupplierWOCTypes,
  ASC_SORT_ORDER,
  supplierTypes,
} from '@poly/constants';

import { findByProp } from '../../core/utils/general.js';
import { assignSupplierFormTypes } from '../../../sidebars/ProjectSidebar/constants.js';

/*
 * formatProjectBreadCrumb :: Project => String
 */
export const formatProjectBreadCrumb = R.compose(
  R.join(' - '),
  R.filter(R.identity),
  R.values,
  R.applySpec({
    client: R.path(['client', 'name']),
    property: R.path(['property', 'name']),
    parent: R.path(['parent', 'projectId']),
  }),
);

/*
 * getCoordinates :: (Client | Property) => Coordinates
 * Coordinates = Object
 */
export const getCoordinates = R.compose(
  R.unless(R.isNil, R.applySpec({ lat: R.last, lon: R.head })),
  R.path(['address', 'geo', 'coordinates']),
);

/*
 * getGeneralPhone :: [Phone] => String
 * Phone = Object
 */
export const getGeneralPhone = R.compose(
  R.when(R.identity, formatRawNumber),
  R.prop('phone'),
  findByProp('type', supplierPhoneTypes.GENERAL),
);

/*
 * filterSelectedSuppliers :: Object => String => [String]
 */
const filterSelectedSuppliers = R.compose(
  R.keys,
  R.filter(R.equals(true)),
  R.defaultTo({}),
);

/*
 * pickSelectedSuppliers :: Object => [String]
 */
export const pickSelectedSuppliers = R.converge(R.concat, [
  R.compose(filterSelectedSuppliers, R.prop(supplierTypes.SUBCONTRACTOR)),
  R.compose(filterSelectedSuppliers, R.prop(supplierTypes.ADMINISTRATIVE)),
]);

/*
 * isSelectedSuppliersType :: String -> Object -> Boolean
 */
export const isSelectedSuppliersType = (type) =>
  R.propSatisfies(
    R.compose(R.complement(R.isEmpty), filterSelectedSuppliers),
    type,
  );

// getSupplierWOCType :: FromData -> SupplierWOCType
export const getSupplierWOCTypeByForm = R.compose(
  R.ifElse(
    R.isEmpty,
    R.always(SupplierWOCTypes.skip),
    R.compose(R.head, R.keys),
  ),
  R.filter(R.equals(true)),
  R.converge(R.pick, [
    R.compose(R.values, R.always(SupplierWOCTypes)),
    R.identity,
  ]),
);

const EARTH_RADIUS = 6371;

// degreesToRadians :: Number -> Number
const degreesToRadians = R.multiply(Math.PI / 180);

// radiansToDegrees :: Number -> Number
const radiansToDegrees = R.multiply(180 / Math.PI);

/*
locationDegreesToRadians :: Coordinates -> Number

Coordinates = {
  lat: Number
  lon: Number
}
*/
const locationDegreesToRadians = R.compose(degreesToRadians, R.prop('lat'));

// sinLocationsDegreesToRadians :: Coordinates -> Number
const sinLocationsDegreesToRadians = R.compose(
  Math.sin,
  locationDegreesToRadians,
);

// cosLocationsDegreesToRadians :: Coordinates -> Number
const cosLocationsDegreesToRadians = R.compose(
  Math.cos,
  locationDegreesToRadians,
);

/*
multiplyCosLocationsDegrees :: Locations -> Number

Locations = {
  locationX: {
    lat: Number
    lon: Number
  }
  locationY: {
    lat: Number
    lon: Number
  }
}
*/
const multiplyCosLocationsDegrees = R.compose(
  R.product,
  R.juxt([
    R.compose(
      Math.cos,
      degreesToRadians,
      Math.abs,
      R.converge(R.subtract, [
        R.path(['locationX', 'lon']),
        R.path(['locationY', 'lon']),
      ]),
    ),
    R.compose(cosLocationsDegreesToRadians, R.prop('locationX')),
    R.compose(cosLocationsDegreesToRadians, R.prop('locationY')),
  ]),
);

// multiplySinLocationsDegrees :: Locations -> Number
const multiplySinLocationsDegrees = R.converge(R.multiply, [
  R.compose(sinLocationsDegreesToRadians, R.prop('locationX')),
  R.compose(sinLocationsDegreesToRadians, R.prop('locationY')),
]);

// centralSubtendedAngle :: Locations -> Number
const centralSubtendedAngle = R.compose(
  radiansToDegrees,
  Math.acos,
  R.converge(R.add, [multiplyCosLocationsDegrees, multiplySinLocationsDegrees]),
);

// greatCircleDistance :: Number -> Number
const greatCircleDistance = R.compose(
  R.multiply(2),
  R.multiply(Math.PI),
  R.multiply(EARTH_RADIUS),
  R.divide(R.__, 360),
);

// distanceBetweenLocations :: Locations -> Mile
// Mile = Number
export const distanceBetweenLocations = R.curry((locationX, locationY) =>
  R.compose(
    R.defaultTo(null),
    roundTo(2),
    R.divide(R.__, 1.609344),
    greatCircleDistance,
    centralSubtendedAngle,
  )({ locationX, locationY }),
);

// convertToCoordinates :: [Number] -> Coordinates
export const convertToCoordinates = R.ifElse(
  R.isNil,
  R.always(null),
  R.applySpec({
    lat: R.last,
    lon: R.head,
  }),
);

// isAssignSupplierFormType :: String -> Boolean
export const isAssignSupplierFormType = R.equals(
  assignSupplierFormTypes.assign,
);

// isSendWOCFormType :: String -> Boolean
export const isSendWOCFormType = R.equals(assignSupplierFormTypes.sendWOC);

// isGenerateWOCFormType :: String -> Boolean
export const isGenerateWOCFormType = R.equals(
  assignSupplierFormTypes.generateWOC,
);

export const getDistanceSortQuery = (coordinates, order = ASC_SORT_ORDER) => ({
  _geo_distance: {
    'company.address.location': coordinates,
    unit: 'mi',
    mode: 'min',
    ignore_unmapped: true,
    order,
  },
});
