import { useEffect } from 'react';

const DEFAULT_DOCUMENT_TITLE = 'Poly Admin';

export const useReactiveDocumentTitle = (title) => {
  useEffect(() => {
    document.title = title || DEFAULT_DOCUMENT_TITLE;

    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE;
    };
  }, [title]);
};
