import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc/index.js';

const CREATE_SUPPLIER_INVOICE_MUTATION = gql`
  mutation CREATE_SUPPLIER_INVOICE_MUTATION(
    $input: SupplierInvoiceCreateInput!
  ) {
    createSupplierInvoice(input: $input) {
      invoice {
        _id
      }
    }
  }
`;

export const withCreateSupplierInvoice = graphql(
  CREATE_SUPPLIER_INVOICE_MUTATION,
  {
    alias: 'withCreateSupplierInvoice',
    props: ({ mutate }) => ({
      createSupplierInvoice: (onMutationSuccess) => (input) =>
        mutate({ variables: { input } }).then(onMutationSuccess),
    }),
  },
);
