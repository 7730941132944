import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { string, number, shape } from 'prop-types';
import { Popover, Text } from '@poly/admin-book';
import { pathOrNothingUI } from '@poly/client-utils';
import { formatAmount, formatDate } from '@poly/utils';

const PopoverS = styled(Popover)`
  button,
  button:hover {
    font-weight: 500;
    color: inherit;
    padding-top: 5px;
    display: inline-block;
    font-size: 16px;
    text-align: left;
    font-family: inherit;
    padding: 0;
  }
`;

const ExistingInvoiceInfo = styled.div`
  padding: 20px;
`;

// getSupplierNameByInvoice :: Invoice -> String
const getSupplierNameByInvoice = pathOrNothingUI(['company', 'name']);

// getProjectIdByInvoice :: Invoice -> String
const getProjectIdByInvoice = R.pathOr('NA', ['projectId']);

const InvoiceFileLink = styled.a.attrs(() => ({ target: '_blank' }))`
  display: inline-block;
`;

const InvoiceFieldLabel = styled.span`
  font-weight: bold;
  width: 100px;
  display: inline-block;
  text-align: right;
  padding-right: 10px;
  vertical-align: top;
`;

const InvoiceFieldValue = styled.span`
  max-width: 145px;
  display: inline-block;
`;

export function DuplicatedInvoiceNumberError({
  total,
  invoiceDate,
  supplier,
  project,
  invoiceFileLink,
  invoiceNumber,
  position,
}) {
  return (
    <PopoverS
      position={position}
      title="This is a duplicate invoice number for this supplier"
      isClickable={false}
      content={
        <ExistingInvoiceInfo>
          <Text>
            <InvoiceFieldLabel>Invoice Number:</InvoiceFieldLabel>
            <InvoiceFieldValue>
              <InvoiceFileLink href={invoiceFileLink}>
                {invoiceNumber}
              </InvoiceFileLink>
            </InvoiceFieldValue>
          </Text>
          <Text>
            <InvoiceFieldLabel>Supplier:</InvoiceFieldLabel>
            <InvoiceFieldValue>
              {getSupplierNameByInvoice(supplier)}
            </InvoiceFieldValue>
          </Text>
          <Text>
            <InvoiceFieldLabel>Project:</InvoiceFieldLabel>
            <InvoiceFieldValue>
              {getProjectIdByInvoice(project)}
            </InvoiceFieldValue>
          </Text>
          <Text>
            <InvoiceFieldLabel>Invoice Date:</InvoiceFieldLabel>
            <InvoiceFieldValue>{formatDate(invoiceDate)}</InvoiceFieldValue>
          </Text>
          <Text>
            <InvoiceFieldLabel>Total Amount:</InvoiceFieldLabel>
            <InvoiceFieldValue>${formatAmount(total)}</InvoiceFieldValue>
          </Text>
        </ExistingInvoiceInfo>
      }
    />
  );
}

DuplicatedInvoiceNumberError.propTypes = {
  invoiceDate: string.isRequired,
  total: number.isRequired,
  project: shape({ projectId: string }),
  supplier: shape({ company: shape({ name: string }) }).isRequired,
  invoiceFileLink: string.isRequired,
  invoiceNumber: string.isRequired,
  position: string,
};
