import React from 'react';
import { string } from 'prop-types';
import { gql } from '@apollo/client';
import {
  entities,
  SidebarUpdatesTab,
  commonUpdateFields,
} from '@poly/admin-ui';
import { UpdateCollections } from '@poly/constants';

import { PrintLayoutProvider } from '../../../providers.js';

const PROPERTY_UPDATES = gql`
  query PROPERTY_UPDATES(
    $id: ID!
    $documentUpdatesInput: DocumentUpdatesInput!
  ) {
    property(id: $id) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...commonUpdateFields
        }
        total
      }
    }
  }

  ${commonUpdateFields}
`;

export function PropertySidebarUpdatesTab({ propertyId, propertyName }) {
  const tabProps = {
    entity: {
      _id: propertyId,
      preview: 'property',
      name: entities.PROPERTY,
      humanReadableId: propertyName,
    },
    updatesQuery: PROPERTY_UPDATES,
    PrintLayout: PrintLayoutProvider,
    collection: UpdateCollections.properties,
  };

  return <SidebarUpdatesTab {...tabProps} />;
}

PropertySidebarUpdatesTab.propTypes = {
  propertyId: string.isRequired,
  propertyName: string.isRequired,
};
