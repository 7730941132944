import styled from 'styled-components';
import { getThemeColor, Status, Text } from '@poly/admin-book';

export const SearchResultItemTitle = styled(Text)`
  font-family: 'FFMarkWebProNarrowMedium', sans-serif;
  color: ${getThemeColor(['newPrimary'])};
`;

export const SearchResultItemLinked = styled(Text)`
  font-family: 'FFMarkWebProNarrowMedium', sans-serif;
  color: ${getThemeColor(['primaryLight'])};
  margin-right: 15px;
`;

export const SearchResultItemText = styled(Text)`
  color: ${getThemeColor(['newPrimary'])};
`;

export const StatusS = styled(Status)`
  font-size: 12px;
  min-width: 120px;
  margin-left: 20px;
  flex-shrink: 0;
  letter-spacing: 1px;
  color: ${getThemeColor(['navBarLighter'])};
  text-transform: uppercase;
`;

export const SearchResultItemDivider = styled.div`
  display: flex;
  width: 100%;
  height: 0;
  margin: 5px 0;
`;

export const SearchResultItemSpace = styled.div`
  display: flex;
  margin: 0 5px;
`;
