import * as R from 'ramda';
import React, { useCallback, useState } from 'react';
import { string } from 'prop-types';
import { debounce } from '@poly/utils';
import { gql, useLazyQuery } from '@apollo/client';
import { CommonPrintLayout, SidebarWidth, entities } from '@poly/admin-ui';
import {
  HtmlParser,
  useOutSidebarContext,
  openPrintWindowWithData,
} from '@poly/client-utils';

import { PopoverLink } from '@poly/admin-book/src/Popover/index.js';
import {
  AssignSupplierForm,
  printWOCCustomStyles,
} from '../../../modules/forms/assignSupplierForm/AssignSupplierForm.js';
import { SecondSidebarLayout } from '../../../components/SidebarLayouts.js';
import { assignSupplierFormId } from '../../../modules/forms/assignSupplierForm/constants.js';
import { assignSupplierFormTypes } from '../constants.js';
import { parseHtmlString } from '../../../utils/html.js';

const GENERATE_EXISTING_WOC_QUERY = gql`
  query GENERATE_EXISTING_WOC_QUERY($input: GenerateExistingWOCInput!) {
    generateExistingWOC(input: $input) {
      wocHtml
    }
  }
`;

// getWOCHtml :: { generateExistingWOC: GenerateExistingWOCResult } -> String
const getWOCHtml = R.path(['generateExistingWOC', 'wocHtml']);

export function GenerateWOCPopoverLink({ projectId, supplierId }) {
  const { openOutSidebar } = useOutSidebarContext();
  const [loading, setLoading] = useState(false);
  const [generateWorkOrderConfirmation] = useLazyQuery(
    GENERATE_EXISTING_WOC_QUERY,
  );

  const setLoadingDebounced = useCallback(debounce(2000)(setLoading), []);

  const onClick = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    const { data } = await generateWorkOrderConfirmation({
      variables: { input: { projectId, supplierId } },
      fetchPolicy: 'network-only',
    });

    setLoadingDebounced(false);

    const wocHtml = getWOCHtml(data);

    if (R.is(String, wocHtml)) {
      const { body } = parseHtmlString(wocHtml);

      openPrintWindowWithData({
        Layout: CommonPrintLayout,
        Table: HtmlParser,
        metaData: { title: 'Supplier WOC' },
        fileName: 'work-order-confirmation',
        html: body,
        customStyles: printWOCCustomStyles,
        skipFirefoxStyles: true,
      });

      return;
    }

    openOutSidebar({
      isSteady: true,
      width: SidebarWidth,
      id: assignSupplierFormId,
      Layout: SecondSidebarLayout,
      content: (
        <AssignSupplierForm
          supplierId={supplierId}
          entity={{ _id: projectId, name: entities.PROJECT }}
          formType={assignSupplierFormTypes.generateWOC}
        />
      ),
    });
  };

  return <PopoverLink onClick={onClick}>Generate WOC</PopoverLink>;
}

GenerateWOCPopoverLink.propTypes = {
  projectId: string,
  supplierId: string,
};
