import { createStore, combineReducers } from 'redux';
import {
  globalSearchTextReducer,
  myTasksSortingReducer,
  confirmModalReducer,
  notificatorReducer,
  searchTextReducer,
  paginationReducer,
  entityReducer,
  modalReducer,
} from '@poly/admin-ui';

import { invoicesReducer } from './redux/invoices.js';
import { popupReducer } from './redux/popupReducer.js';
import { updatesReducer } from './redux/updatesReducer.js';
import { globalTriggerReducer } from './redux/globalTriger.js';
import { currentUserReducer } from './redux/currentUserReducer.js';
import { entitySortReducer } from './redux/entitySort/entitySort.js';
import { selectedRowsReducer } from './redux/selectedRowsReducer.js';
import { journalEntryReducer } from './redux/journalEntryReducer.js';
import { searchFiltersReducer } from './redux/searchFilters/index.js';
import { editablePaymentsReducer } from './redux/editablePayments.js';
import { processesReducer } from './redux/processes/processesReducer.js';
import { contactSidebarReducer } from './redux/contactSidebarReducer.js';
import { searchEntityParamsReducer } from './redux/searchEntityParams.js';
import { userGroupVariablesReducer } from './redux/userGroupVariablesReducer.js';
import { previewIdsReducer } from './redux/requestedSupplierDocumentsReducer.js';
import { activeSearchQueryParamsReducer } from './redux/activeSearchQueryParamsReducer.js';
import { preventativeRepairChangedReducer } from './redux/preventativeRepairChangedReducer.js';
import { conversionRecurringProjectReducer } from './redux/conversionRecurringProjectReducer.js';
import { recentProjectsReducer } from './modules/tables/recentProjectsTable/recentProjectsTableReducer.js';
import { timeSheetReportReducer } from './modules/pagesHeaders/TimeSheetReportPageHeader/TimeSheetReportReducer.js';

export const store = createStore(
  combineReducers({
    modal: modalReducer,
    entity: entityReducer,
    invoices: invoicesReducer,
    withAudit: updatesReducer,
    popupEntity: popupReducer,
    processes: processesReducer,
    pagination: paginationReducer,
    searchText: searchTextReducer,
    entitySort: entitySortReducer,
    previewIds: previewIdsReducer,
    currentUser: currentUserReducer,
    selectedRows: selectedRowsReducer,
    confirmModal: confirmModalReducer,
    notifications: notificatorReducer,
    journalEntry: journalEntryReducer,
    searchFilters: searchFiltersReducer,
    globalTrigger: globalTriggerReducer,
    recentProjects: recentProjectsReducer,
    createdContact: contactSidebarReducer,
    myTasksSorting: myTasksSortingReducer,
    timeSheetReport: timeSheetReportReducer,
    editablePayments: editablePaymentsReducer,
    globalSearchText: globalSearchTextReducer,
    searchEntityParams: searchEntityParamsReducer,
    userGroupVariables: userGroupVariablesReducer,
    activeSearchQueryParams: activeSearchQueryParamsReducer,
    preventativeRepairChanged: preventativeRepairChangedReducer,
    conversionRecurringProject: conversionRecurringProjectReducer,
  }),
  {},
  window.__REDUX_DEVTOOLS_EXTENSION__?.(),
);
