import React from 'react';
import * as R from 'ramda';
import { gql } from '@apollo/client';
import { shape, string, func } from 'prop-types';
import { Link } from '@poly/client-routing';

import { forceTitleCase, propEqLegacy } from '@poly/utils';
import { routesNames } from '../routes/constants.js';
import { ProjectOccurrence } from '../modules/core/constants/projects.js';
import { useSidebarLogicContext } from '../sidebars/SidebarLogicContext.js';

export const UpdateDocumentFragmentForLink = gql`
  fragment UpdateDocumentFragmentForLink on UpdateDocument {
    property {
      _id
      name
    }
    client {
      _id
      name
    }
    project {
      _id
      projectId
      type
    }
    recurringProject {
      _id
      projectId
    }
    supplier {
      _id
      company {
        name
      }
    }
    masterSupplier {
      _id
      company {
        name
      }
    }
    user {
      _id
      fullName
    }
    asset {
      _id
      displayName
    }
    adminPurchaseOrder {
      _id
      displayName
    }
    userGroup {
      _id
      name
    }
  }
`;

const documentNamePathMap = {
  client: ['name'],
  property: ['name'],
  supplier: ['company', 'name'],
  masterSupplier: ['company', 'name'],
  project: ['projectId'],
  recurringProject: ['projectId'],
  user: ['fullName'],
  asset: ['displayName'],
  adminPurchaseOrder: ['displayName'],
};

const documentPageRouteMap = {
  // we need this holder for correct logic work
  project: routesNames.NOT_FOUND,
  recurringProject: routesNames.NOT_FOUND,
  client: routesNames.NOT_FOUND,
  property: routesNames.NOT_FOUND,
  supplier: routesNames.SUPPLIER,
  masterSupplier: routesNames.MASTER_SUPPLIER,
  user: routesNames.NOT_FOUND,
};

const documentTabSuffixMap = {
  // by default is equal to entity type itself
  user: 'staff',
  masterSupplier: 'master-supplier',
};

export const EntityReferenceLinkTabs = {
  UPDATES: '-updates',
};

// getNameByDocumentPair :: Pair String Object -> String
const getNameByDocumentPair = R.converge(R.path, [
  R.compose(R.prop(R.__, documentNamePathMap), R.head),
  R.last,
]);

// getTypeByDocumentPair :: Pair String Object -> String
const getTypeByDocumentPair = R.compose(forceTitleCase, R.head);

// getLinkTextByDocumentPair :: Pair String Object -> String
const getLinkTextByDocumentPair = R.compose(
  R.join(' '),
  R.converge(Array.of, [getTypeByDocumentPair, getNameByDocumentPair]),
);

// hrefByDocumentPair :: Pair String Object -> URL
const hrefByDocumentPair = R.compose(
  R.prop(R.__, documentPageRouteMap),
  R.head,
);

// getNonNullableDocumentPair :: UserAlert -> Pair String Object
const getNonNullableDocumentPairByAlert = R.compose(
  R.find(R.compose(R.complement(R.isNil), R.last)),
  R.toPairs,
  R.omit(['__typename']),
  R.prop('document'),
);

// replaceRouteIdParam :: ID -> URL -> URL
const replaceRouteIdParam = R.curry((userAlert, url) =>
  R.converge(R.replace(/:\w+$/), [
    R.always(R.prop('documentId', userAlert)),
    R.identity,
  ])(url),
);

// hrefQueryTabOptionByAlertAndPair :: UserAlert -> Pair String Object -> Object
const hrefQueryTabOptionByAlertAndPair = (userAlert) =>
  R.compose(
    R.concat('?tab='),
    R.converge(R.concat, [
      R.compose(
        R.when(
          R.has(R.__, documentTabSuffixMap),
          R.prop(R.__, documentTabSuffixMap),
        ),
        R.head,
      ),
      R.compose(R.prop('tab'), R.always(userAlert)),
    ]),
  );

// hrefUpdateHashByAlert :: UserAlert -> Pair String Object -> String
const hrefUpdateHashByAlert = (userAlert) =>
  R.compose(
    R.cond([
      [
        R.prop('documentUpdateId'),
        R.pipe(R.prop('documentUpdateId'), R.concat('#update_')),
      ],
      [
        R.prop('documentUpdateCommentId'),
        R.pipe(R.prop('documentUpdateCommentId'), R.concat('#comment_')),
      ],
      [R.T, R.always('')],
    ]),
    R.always(userAlert),
  );

// hrefByAlertAndPair :: UserAlert -> Pair String Object -> Object
const hrefByAlertAndPair = (userAlert) =>
  R.converge(R.concat, [
    R.compose(replaceRouteIdParam(userAlert), hrefByDocumentPair),
    R.ifElse(
      R.compose(R.has('tab'), R.always(userAlert)),
      R.converge(R.concat, [
        hrefQueryTabOptionByAlertAndPair(userAlert),
        hrefUpdateHashByAlert(userAlert),
      ]),
      R.always(''),
    ),
  ]);

const UNKNOWN_LINK = {
  href: { pathname: routesNames.NOT_FOUND },
  children: 'Error while update document link',
};

// alertToLinkProps :: UserAlert -> {href: Object, children: Any}
const alertToLinkProps = (userAlert) =>
  R.compose(
    R.ifElse(
      R.isNil,
      R.always(UNKNOWN_LINK),
      R.converge(R.unapply(R.mergeAll), [
        R.compose(R.objOf('href'), hrefByAlertAndPair(userAlert)),
        R.ifElse(
          R.compose(R.has('children'), R.always(userAlert)),
          R.compose(R.pick(['children']), R.always(userAlert)),
          R.compose(R.objOf('children'), getLinkTextByDocumentPair),
        ),
        R.compose(R.pick(['as']), R.always(userAlert)),
      ]),
    ),
    getNonNullableDocumentPairByAlert,
  )(userAlert);

// getQueryForSidebarLink :: UserAlert -> Pair String Object -> QueryObj
// QueryObj = { query: Object }
const getQueryForSidebarLink = (userAlert) =>
  R.compose(
    R.objOf('query'),
    R.objOf('sidebarTab'),
    R.concat(R.__, userAlert.tab),
    // we have tab query changes only for this one for now
    R.when(R.equals('userGroup'), R.always('user-group')),
    R.head,
  );

// getHashForSidebarLink :: UserAlert -> HashObj
// HashObj = { hash: String }
const getHashForSidebarLink = R.compose(
  R.objOf('hash'),
  R.cond([
    [
      R.prop('documentUpdateId'),
      R.compose(R.concat('#update_'), R.prop('documentUpdateId')),
    ],
    [
      R.prop('documentUpdateCommentId'),
      R.compose(R.concat('#comment_'), R.prop('documentUpdateCommentId')),
    ],
    [R.T, R.always('')],
  ]),
);

// isProjectEntity :: String -> Boolean
const isProjectEntity = R.includes(R.__, ['project', 'recurringProject']);

// isClientOrPropertyEntity :: String -> Boolean
const isSimpleEntityWithName = R.includes(R.__, [
  'client',
  'property',
  'userGroup',
]);

// isAssetOrAdminPOEntity :: String -> Boolean
const isAssetOrAdminPOEntity = R.includes(R.__, [
  'asset',
  'adminPurchaseOrder',
]);

// pickSidebarLinkPropsByEntity :: Pair String Object -> Object
const pickSidebarLinkPropsByEntity = R.cond([
  [
    R.compose(isProjectEntity, R.head),
    R.applySpec({
      projectId: R.compose(R.prop('projectId'), R.last),
      projectLinkId: R.compose(R.prop('projectId'), R.last),
      type: R.ifElse(
        propEqLegacy(0, 'project'),
        R.always(ProjectOccurrence.ONE_TIME_OCCURRENCE),
        R.always(null),
      ),
    }),
  ],
  [
    R.compose(isSimpleEntityWithName, R.head),
    R.compose(R.pick(['_id', 'name']), R.last),
  ],
  [
    propEqLegacy(0, 'user'),
    R.applySpec({ _id: R.path([1, '_id']), name: R.path([1, 'fullName']) }),
  ],
  [
    R.compose(isAssetOrAdminPOEntity, R.head),
    R.applySpec({
      _id: R.path([1, '_id']),
      children: R.path([1, 'displayName']),
    }),
  ],
  [R.T, R.always({})],
]);

// getUrlParamsForSidebarLink :: UserAlert -> Pair String Object -> URLParamsObj
// URLParamsObj = { urlParams: Object }
const getUrlParamsForSidebarLink = (userAlert) =>
  R.ifElse(
    R.compose(R.has('tab'), R.always(userAlert)),
    R.compose(
      R.objOf('queryParams'),
      R.converge(R.mergeLeft, [
        getQueryForSidebarLink(userAlert),
        () => getHashForSidebarLink(userAlert),
      ]),
    ),
    R.always({}),
  );

// alertToSidebarLinkProps :: UserAlert -> SidebarLinkProps
// eslint-disable-next-line import/no-unused-modules
export const alertToSidebarLinkProps = (userAlert) =>
  R.compose(
    R.ifElse(
      R.isNil,
      R.always({}),
      R.converge(R.mergeLeft, [
        pickSidebarLinkPropsByEntity,
        getUrlParamsForSidebarLink(userAlert),
      ]),
    ),
    getNonNullableDocumentPairByAlert,
  )(userAlert);

// getEntityName :: UserAlert -> String
const getEntityName = R.compose(
  R.propOr('', '0'),
  getNonNullableDocumentPairByAlert,
  R.objOf('document'),
);

// isEntityWithSidebar :: String -> Boolean
const isEntityWithSidebar = R.anyPass([
  isProjectEntity,
  R.equals('user'),
  isAssetOrAdminPOEntity,
  isSimpleEntityWithName,
]);

export function UpdateDocumentLink({
  tab,
  onClick,
  document,
  documentId,
  documentUpdateId,
  documentUpdateCommentId,
}) {
  const {
    UserLink,
    AssetLink,
    ClientLink,
    ProjectLink,
    PropertyLink,
    UserGroupLink,
    PurchaseOrderLink,
  } = useSidebarLogicContext();

  const entity = getEntityName(document);

  if (isEntityWithSidebar(entity)) {
    const sidebarLinkProps = alertToSidebarLinkProps({
      document,
      documentId,
      documentUpdateId,
      documentUpdateCommentId,
      tab,
    });

    const fullSidebarLinkProps = R.mergeDeepLeft(sidebarLinkProps, { onClick });

    if (isProjectEntity(entity))
      return <ProjectLink {...fullSidebarLinkProps} />;

    if (entity === 'client') return <ClientLink {...fullSidebarLinkProps} />;

    if (entity === 'property')
      return <PropertyLink {...fullSidebarLinkProps} />;

    if (entity === 'user') return <UserLink {...fullSidebarLinkProps} />;

    if (entity === 'asset') return <AssetLink {...fullSidebarLinkProps} />;

    if (entity === 'adminPurchaseOrder')
      return <PurchaseOrderLink {...fullSidebarLinkProps} />;

    if (entity === 'userGroup')
      return <UserGroupLink {...fullSidebarLinkProps} />;

    return null;
  }

  const { href, children } = alertToLinkProps({
    document,
    documentId,
    documentUpdateId,
    documentUpdateCommentId,
    tab,
  });

  return (
    <Link href={href} onClick={onClick}>
      {children}
    </Link>
  );
}

UpdateDocumentLink.propTypes = {
  document: shape({
    property: shape({
      _id: string.isRequired,
      name: string.isRequired,
    }),
    client: shape({
      _id: string.isRequired,
      name: string.isRequired,
    }),
    project: shape({
      _id: string.isRequired,
      projectId: string.isRequired,
      type: string.isRequired,
    }),
    recurringProject: shape({
      _id: string.isRequired,
      projectId: string.isRequired,
    }),
    supplier: shape({
      _id: string.isRequired,
      company: shape({
        name: string.isRequired,
      }),
    }),
    masterSupplier: shape({
      _id: string.isRequired,
      company: shape({
        name: string.isRequired,
      }),
    }),
    user: shape({
      _id: string.isRequired,
      fullName: string.isRequired,
    }),
  }).isRequired,
  documentId: string.isRequired,
  documentUpdateId: string,
  documentUpdateCommentId: string,
  tab: string,
  onClick: func,
};
