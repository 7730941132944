// recalculatePaymentByDiscount :: (Number, Number, Number) -> Number
export const recalculatePaymentByDiscount = (
  currentBalance,
  invoiceDiscount,
  paidAmount,
) => {
  const isPaymentExceedBalance = currentBalance < invoiceDiscount + paidAmount;
  if (isPaymentExceedBalance && invoiceDiscount <= currentBalance) {
    return currentBalance - invoiceDiscount;
  }
  return paidAmount;
};
