import React, { useState } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { DefaultBodyWrapper } from '@poly/admin-book/src/Tabs/components.js';
import { Loader } from '@poly/admin-book/src/Loader/index.js';
import { useSortableTable } from '@poly/client-utils/src/hooks/useSortableTable.js';
import { TableCardWithPaginationContainer } from '@poly/admin-ui/src/components/Table.js';

import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { OpenWorkOrdersReportHeader } from './OpenWorkOrdersReportHeader.js';
import { useOpenWorkOrdersReport } from './useOpenWorkOrdersReport.js';
import {
  OpenWorkOrdersReportTable,
  getOpenWorkOrdersReportTable,
} from './OpenWorkOrdersReportTable.js';

export function OpenWorkOrdersReport() {
  const [queryFilters, setQueryFilters] = useState({});

  const { sort, ...tableSortProps } = useSortableTable({
    column: 1,
    tableConfig: getOpenWorkOrdersReportTable,
  });

  const pagination = useSelector(R.prop('pagination'));

  const { data, loading } = useOpenWorkOrdersReport(queryFilters, sort);

  return (
    <PageWithSearchHeader headerHeight="145px">
      <OpenWorkOrdersReportHeader
        queryFilters={queryFilters}
        setQueryFilters={setQueryFilters}
        data={data}
        sort={sort}
      />

      <DefaultBodyWrapper height="calc(100% - 145px)">
        <TableCardWithPaginationContainer>
          {loading ? (
            <Loader />
          ) : (
            <OpenWorkOrdersReportTable
              data={data}
              pagination={pagination}
              {...tableSortProps}
            />
          )}
        </TableCardWithPaginationContainer>
      </DefaultBodyWrapper>
    </PageWithSearchHeader>
  );
}
