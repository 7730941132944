import React from 'react';
import { func } from 'prop-types';
import { CommonSidebarFormWidth, entities } from '@poly/admin-ui';
import { useOutSidebarContext } from '@poly/client-utils';

import { AddAssetForm } from './AddAssetForm.js';
import { addAssetFormId } from '../../constants.js';
import { SidebarFormLayout } from '../../../components/SidebarFormLayout.js';
import { useOnCloseFormSidebar } from '../../../useOnCloseFormSidebar.js';

function AddAssetSidebarContent({ onClose, ...formProps }) {
  return (
    <SidebarFormLayout
      onClose={onClose}
      formId={addAssetFormId}
      title="Create a New Asset"
      submitButtonCaption="Create"
    >
      <AddAssetForm {...formProps} />
    </SidebarFormLayout>
  );
}

AddAssetSidebarContent.propTypes = { onClose: func.isRequired };

export const useAddAssetSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();
  const onClose = useOnCloseFormSidebar();

  const onCloseHandler = (props) => () => {
    const { propertyId, project } = props || {};

    if (project) {
      onClose(entities.PROJECT, project.projectId, project.type);
    } else if (propertyId) {
      onClose(entities.PROPERTY, propertyId);
    }

    onClose();
  };

  return (formProps) =>
    openOutSidebar({
      alwaysFirst: true,
      id: addAssetFormId,
      width: CommonSidebarFormWidth,
      content: (
        <AddAssetSidebarContent
          {...formProps}
          onClose={onCloseHandler(formProps)}
        />
      ),
    });
};
