import styled from 'styled-components';

export const UserGroupVariableCompS = styled.span`
  display: inline-flex;
  padding: 2px 10px;
  border-radius: 5px;
  background-color: #e8ecf4;
  color: #4c66a2;
  margin-right: 8px;
`;
