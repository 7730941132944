import * as R from 'ramda';
import {
  validateEmail as commonEmailValidator,
  getExtensionByFileName,
  isFileExtensionAllowed,
  isNilOrEmpty,
  propEqLegacy,
} from '@poly/utils';
import {
  MAX_FILES_LIMIT,
  MAX_FILES_LIMIT_ERROR_MESSAGE,
  MAX_FILE_NAME_CHARS_LIMIT,
  MAX_FILE_SIZE,
  MAX_FILE_SIZE_ERROR_MESSAGE,
} from '@poly/constants';

export const INVALID_ADDRESS_FORMAT_ERROR =
  "Address fields should contain letters, numbers and symbols: # , . - '";

export const ifNotEmpty = (validator) =>
  R.either(R.complement(R.identity), validator);

export const ifHasValue = (validator) =>
  R.ifElse(R.anyPass([R.isNil, R.isEmpty]), R.T, validator);

/**
 * @deprecated use validateEmail from @poly/utils directly
 */
export const validateEmail = commonEmailValidator;

// positiveNumber :: Number -> Bool
// eslint-disable-next-line import/no-unused-modules
export const gteZero = R.gte(R.__, 0);

// preparePhoneToValidation :: String -> String
const preparePhoneToValidation = R.replace(/[\\~#%&*{}/:<>?|"-]/g, '');

// validatePhone :: String -> Bool
export const validatePhone = R.compose(
  propEqLegacy('length', 10),
  preparePhoneToValidation,
  R.defaultTo(''),
);

const internationalPhoneRegExp = new RegExp(
  /(([+][(]?\d{1,3}\)?)|([(]?\d{4}\)?))/.source +
    /\s*\)?[-\s.]?/.source +
    /[(]?\d{1,3}\)?([-\s.]?\d{3})([-\s.]?\d{3,4})/.source,
  'i',
);

// validateInternationalPhoneNumber :: String -> String
// eslint-disable-next-line import/no-unused-modules
export const validateInternationalPhoneNumber = R.compose(
  R.test(internationalPhoneRegExp),
  R.replace(/\s/g, ''),
  R.defaultTo(''),
);

// validateSocialSecurityNum :: String -> Bool
export const validateSocialSecurityNum = R.test(/^\d{9}$/);

// validateUSAZipCode :: String -> Bool
export const validateUSAZipCode = R.test(/^\d{5}(?:-\d{4})?$/);

// validateCanadaZipCode :: String -> Bool
export const validateCanadaZipCode = R.test(
  /^(?!.*[DFIOQU])[A-VXY]\d[A-Z] ?\d[A-Z]\d$/,
);

// regex from https://www.codegrepper.com/code-examples/whatever/street+address+validation+regex
// validateAddress :: String -> Bool
const validateAddress = R.test(/^[#.0-9a-zA-Z\s,'-]+$/);

// validateRegularField :: Object -> (Object, Object) -> Object
const validateRegularField =
  (formData) =>
  (errors, { path, validators }) =>
    R.pipe(
      R.find(([validator]) => !validator(R.path(path, formData), formData)),
      R.prop('1'),
      R.ifElse(
        R.identity,
        R.pipe(
          R.ifElse(
            R.is(Function),
            (msgFormatter) => msgFormatter(R.path(path, formData)),
            R.identity,
          ),
          R.assocPath(path, R.__, errors),
        ),
        R.always(errors),
      ),
    )(validators);

// validateArrayField :: Object -> (Object, Object) -> Object
const validateArrayField =
  (formData) =>
  (errors, { path, validators }) =>
    R.pipe(
      R.path(path),
      R.map((arrayItemToValidate) =>
        R.reduce(validateRegularField(arrayItemToValidate), {}, validators),
      ),
      R.ifElse(
        R.all(R.isEmpty),
        R.always(errors),
        R.assocPath(path, R.__, errors),
      ),
    )(formData);

// getErrors :: { k: v } -> (ErrorsAccumulator, { path: [String], validations: [[(v -> Boolean), ErrorMsg]]) -> Object
// ErrorsAccumulator = Object
// ErrorMsg = String
const validateField = (formData) => (errors, nextValidator) =>
  nextValidator.isArrayField
    ? validateArrayField(formData)(errors, nextValidator)
    : validateRegularField(formData)(errors, nextValidator);

// isEmptyFormattedAddress :: Address -> Boolean
const isEmptyFormattedAddress = R.compose(
  isNilOrEmpty,
  R.prop('formatted_address'),
);

const validateUSAOrCanadaZipCode = R.pipe(
  R.pathOr('', ['address_parts', 'postal_code']),
  R.anyPass([validateUSAZipCode, validateCanadaZipCode]),
);

// validatePostalCode :: [Address] -> Boolean
export const validateZipCode = R.pathSatisfies(
  R.complement(R.anyPass([R.isNil, R.isEmpty])),
  ['address_parts', 'postal_code'],
);

// validateFormData :: [Object] -> Object -> Object
export const validateFormData = (validationConfig) => (formData) =>
  R.reduce(validateField(formData), {}, validationConfig);

// validateAddressFormat :: Object -> Boolean
export const validateAddressFormat = R.compose(
  validateAddress,
  R.prop('formatted_address'),
);

// validateCountry :: Address -> Boolean
const validateCountry = R.complement(
  R.pathSatisfies(isNilOrEmpty, ['address_parts', 'country']),
);

const MISSING_COUNTRY_ERROR = 'Country is required. You can set it manually';

export const addressValidators = [
  [
    R.complement(R.anyPass([R.isNil, R.isEmpty])),
    'Select address or set it manually',
  ],
  [validateCountry, MISSING_COUNTRY_ERROR],
  [ifNotEmpty(validateZipCode), 'Zip is required. You can set it manually'],
  [
    ifNotEmpty(validateUSAOrCanadaZipCode),
    'Zip format is wrong. Check it and try to change',
  ],
  [ifNotEmpty(validateAddressFormat), INVALID_ADDRESS_FORMAT_ERROR],
];

// eslint-disable-next-line import/no-unused-modules
export const optionalCommonAddressValidators = [
  [
    R.ifElse(
      isEmptyFormattedAddress,
      R.T,
      ifHasValue(validateUSAOrCanadaZipCode),
    ),
    'Zip format is wrong. Check it and try to change',
  ],
  [
    R.ifElse(isEmptyFormattedAddress, R.T, validateAddressFormat),
    INVALID_ADDRESS_FORMAT_ERROR,
  ],
  [
    R.ifElse(isEmptyFormattedAddress, R.T, validateCountry),
    MISSING_COUNTRY_ERROR,
  ],
];

export const optionalAddressValidators = [
  [
    R.ifElse(isEmptyFormattedAddress, R.T, ifNotEmpty(validateZipCode)),
    'Zip is required. You can set it manually',
  ],
  ...optionalCommonAddressValidators,
];

// validateFileBase :: (File -> Boolean) -> DnDTextareaValue | [File] -> [FileError]
// DnDTextareaValue = {
//  attachments: [File]
// }
export const validateFileBase = (validate, errorMsg) =>
  R.compose(
    R.reject(R.isNil),
    R.map((file) =>
      validate(file) ? null : { fileId: file._id, error: errorMsg },
    ),
    R.unless(Array.isArray, R.propOr([], 'attachments')),
  );

// eslint-disable-next-line import/no-unused-modules
export const FILE_NAME_ERROR = 'File name required';
// eslint-disable-next-line import/no-unused-modules
export const FILE_EXTENSION_ERROR = 'File extension not allowed';
// eslint-disable-next-line import/no-unused-modules
export const MAX_FILE_NAME_CHARS_ERROR = `Maximum file name length is ${MAX_FILE_NAME_CHARS_LIMIT} characters`;

// validateFileSize :: DnDTextareaValue | [File] -> [FileError]
const validateFileSize = validateFileBase(
  R.compose(R.lt(R.__, MAX_FILE_SIZE), R.pathOr(0, ['upload', 'size'])),
  MAX_FILE_SIZE_ERROR_MESSAGE,
);

// validateFileName :: DnDTextareaValue | [File] -> [FileError]
export const validateFileName = validateFileBase(
  R.compose(R.complement(R.isEmpty), R.trim, R.propOr('', 'fileName')),
  FILE_NAME_ERROR,
);

// validateFileNameLength :: DnDTextareaValue | [File] -> [FileError]
const validateFileNameLength = validateFileBase(
  R.compose(
    R.gte(MAX_FILE_NAME_CHARS_LIMIT),
    R.length,
    R.propOr('', 'fileName'),
  ),
  MAX_FILE_NAME_CHARS_ERROR,
);

// validateFileExtension :: DnDTextareaValue | [File] -> [FileError]
const validateFileExtension = validateFileBase(
  R.compose(
    isFileExtensionAllowed,
    getExtensionByFileName,
    R.ifElse(
      R.compose(isNilOrEmpty, R.path(['upload', 'name'])),
      R.propOr('', 'url'),
      R.path(['upload', 'name']),
    ),
  ),
  FILE_EXTENSION_ERROR,
);

// validateFilesLimit :: DnDTextareaValue | [File] -> FileError
const validateFilesLimit = R.compose(
  R.ifElse(
    R.identity,
    R.always({}),
    R.always({ commonError: MAX_FILES_LIMIT_ERROR_MESSAGE }),
  ),
  R.lte(R.__, MAX_FILES_LIMIT),
  R.length,
  R.unless(Array.isArray, R.propOr([], 'attachments')),
);

export const baseFileValidators = [
  validateFileSize,
  validateFileName,
  validateFilesLimit,
  validateFileNameLength,
];

export const commonFileValidators = [
  validateFileSize,
  validateFileName,
  validateFileExtension,
  validateFilesLimit,
  validateFileNameLength,
];

// eslint-disable-next-line import/no-unused-modules
export const REQUIRED_DNT_TEXT_ERROR = 'Update description is required';

// validateDnDText :: DnDTextareaValue -> Object
const validateDnDText = R.ifElse(
  R.prop('text'),
  R.always({}),
  R.always({ editorError: REQUIRED_DNT_TEXT_ERROR }),
);

export const dNdTextAreaFileValidators = [
  ...commonFileValidators,
  validateDnDText,
];

// validateFilesFunc :: [(File -> [FileError])] -> DnDTextareaValue | [File] -> [FileErrors]
// FileError = {fileId: String, error: String}
export const validateFilesFunc = R.curry((validators, value) =>
  R.compose(
    R.unnest,
    R.reject(R.isEmpty),
    R.map((validator) => validator(value)),
  )(validators),
);

// getMaxLengthValidator :: Number -> [(String -> Boolean), String]
export const getMaxLengthValidator = (max) => [
  R.compose(R.gte(max), R.length),
  `Maximum length is ${max} characters`,
];

export const commonEmailValidators = [
  [ifNotEmpty(commonEmailValidator), 'Incorrect email'],
];
