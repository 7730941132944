import * as R from 'ramda';
import { debounce } from '@poly/utils';
import { DEBOUNCE_USER_INPUT_THRESHOLD } from '@poly/client-utils';
import { useState } from 'react';

// getUsersEmails :: SearchUsersEmailsQueryResult -> Object
const getUsersEmails = R.pipe(
  R.propOr([], 'hits'),
  R.reject(R.propSatisfies(R.isNil, 'email')),
  R.map(({ email }) => ({ value: email, label: email })),
);

export const useMailToHelpers = ({
  searchUsers,
  setSearch,
  onChange,
  onBlur,
  value,
}) => {
  const [newEmail, setNewEmail] = useState(null);

  const setSearchTerm = debounce(DEBOUNCE_USER_INPUT_THRESHOLD)(setSearch);

  const usersEmails = getUsersEmails(searchUsers);

  const changeProp = (input) => {
    const mergedValue = R.mergeDeepRight(value)(input);
    onChange(mergedValue);
    onBlur(mergedValue);
  };

  const addNewEmail = (input) => {
    setNewEmail(R.when(R.isEmpty, R.always(null))(input));
    setSearchTerm(input.trim());
  };

  return {
    changeProp,
    addNewEmail,
    usersEmails,
    newEmail,
  };
};
