import * as R from 'ramda';
import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';

const purchaseOrderPropertiesQuery = gql`
  query purchaseOrderPropertiesQuery($purchaseOrderId: ID!) {
    adminPurchaseOrder(id: $purchaseOrderId) {
      _id
      properties {
        _id
      }
    }
  }
`;

// getPropertyLimitIds :: AdminPurchaseOrderQueryResult -> [ID]
const getPropertyLimitIds = R.compose(
  R.map(R.prop('_id')),
  R.pathOr([], ['adminPurchaseOrder', 'properties']),
);

export const usePropertiesLimitByAdminPO = (purchaseOrderId) => {
  const { data } = useQuery(purchaseOrderPropertiesQuery, {
    variables: { purchaseOrderId },
    skip: !purchaseOrderId,
  });

  const propertyLimitIds = useMemo(() => getPropertyLimitIds(data), [data]);

  return { propertyLimitIds };
};
