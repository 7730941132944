import React from 'react';
import { string, bool } from 'prop-types';
import styled from 'styled-components';
import { FilePicker, InputLabel } from '@poly/admin-book';
import { useNotificationState } from '@poly/admin-ui';
import { MIME_TYPES } from '@poly/constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export function AttachDocumentField({ label, showLabel, required, ...props }) {
  const { showErrorNotification } = useNotificationState();

  const acceptTypes = [
    MIME_TYPES.PDF,
    MIME_TYPES.IMAGE_PNG,
    MIME_TYPES.IMAGE_JPG,
    MIME_TYPES.IMAGE_JPEG,
  ];

  const onTypeError = () =>
    showErrorNotification(`"${label}" accepts only: pdf, png, jpg, jpeg`);

  const preparedLabel = `${label}${required ? ' *' : ''}`;

  return (
    <Wrapper>
      {showLabel && <InputLabel>{preparedLabel}</InputLabel>}
      <FilePicker
        {...props}
        multiple={false}
        required={required}
        {...{ acceptTypes, onTypeError }}
      />
    </Wrapper>
  );
}

AttachDocumentField.defaultProps = {
  showLabel: true,
  label: 'Attach Invoice',
};

AttachDocumentField.propTypes = {
  label: string,
  required: bool,
  showLabel: bool,
};
