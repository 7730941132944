import {
  SupplierSources,
  SupplierStatuses,
  TaxClassification,
} from '@poly/constants';
import { defaultTheme } from '@poly/admin-book';

const {
  colors: {
    statuses: { active, blocked },
    accent4,
    primaryRegular,
  },
} = defaultTheme;

export const supplierStatusesColors = {
  [SupplierStatuses.ACTIVE]: active,
  [SupplierStatuses.BLOCKED]: blocked,
};

export const supplierTaxClassesUI = {
  [TaxClassification.SOLE_PROPRIETORSHIP]:
    'Sole Proprietorship / Single Member LLC',
  [TaxClassification.C_CORPORATION]: 'C Corporation',
  [TaxClassification.S_CORPORATION]: 'S Corporation',
  [TaxClassification.PARTNERSHIP]: 'Partnership',
  [TaxClassification.LLC_C_CORP]: 'Limited Liability Company - C Corp',
  [TaxClassification.LLC_S_CORP]: 'Limited Liability Company - S Corp',
  [TaxClassification.LLC_PARTNERSHIP]:
    'Limited Liability Company - Partnership',
};

export const supplierSourceColors = {
  [SupplierSources.TIAA]: primaryRegular,
  [SupplierSources.AAC]: accent4,
};

export const supplierStatuses = {
  ACTIVE: 'ACTIVE',
  BLOCKED: 'BLOCKED',
};
