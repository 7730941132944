import * as R from 'ramda';
import { NOTHING_UI_STRING, collectionNames } from '@poly/constants';
import { forceTitleCase, formatDateTime, ofArrayLegacy } from '@poly/utils';

import { entities } from '../../../constants/index.js';

const placeholderLegacy = 'legacy record';

// checkDate :: String -> String
const checkDate = R.ifElse(
  R.isNil,
  R.always(NOTHING_UI_STRING),
  formatDateTime,
);

// checkName :: String -> String
const checkName = R.ifElse(R.isNil, R.always(NOTHING_UI_STRING), R.identity);

// checkLegacy :: Object -> String
const checkLegacy = R.compose(
  R.ifElse(R.any(R.isNil), R.always(`(${placeholderLegacy})`), R.always('')),
  R.values,
);

/**
 createUpdateInfoString :: Object -> String
 */
export const createUpdateInfoString = ({ prefix, date, user }) =>
  `${prefix} ${checkDate(date)} by ${checkName(user)} ${checkLegacy({
    date,
    user,
  })}`;

// formatEntityName :: String -> String
// we want to see common entity name on printed page without specification
const formatEntityName = R.cond([
  [R.equals(entities.OCR_INVOICE), R.always(entities.INVOICE)],
  [R.equals(entities.RECURRING_PROJECT), R.always(entities.PROJECT)],
  [R.T, R.identity],
]);

/**
 * preparePrintTitleByEntity :: Entity -> String
 * Entity = { name: String, humanReadableId: String }
 */
export const preparePrintTitleByEntity = R.compose(
  R.join(': '),
  R.reject(R.isEmpty),
  R.converge(R.concat, [
    R.pipe(
      R.prop('name'),
      R.defaultTo(''),
      formatEntityName,
      R.when(R.complement(R.isEmpty), forceTitleCase),
      ofArrayLegacy,
    ),
    R.pipe(R.prop('humanReadableId'), R.defaultTo(''), ofArrayLegacy),
  ]),
);

// checkIfPinnedByCollection :: String -> Boolean
export const checkIfPinnedByCollection = R.includes(R.__, [
  collectionNames.clients,
  collectionNames.projects,
  collectionNames.properties,
  collectionNames.recurringProjects,
]);
