import * as R from 'ramda';

import { FONT_SIZES } from './constants.js';

export function onDoneClick() {
  const { index, length } = this.quill.getSelection();
  const tooltipElement = this.quill.theme.tooltip.root;

  if (tooltipElement.classList.contains('ql-editing')) {
    const href = tooltipElement.querySelector(
      '.ql-tooltip-editor input[type="text"]',
    ).value;
    this.quill.format('link', href);
  }

  this.quill.setSelection(index + length, 0);
}

const findIndexOfCurrentSize = R.pipe(
  R.invoker(0, 'getFormat'),
  R.prop('size'),
  R.defaultTo(false),
  (size) => R.findIndex(R.equals(size), FONT_SIZES),
);

const increaseCurrentSize = (quill) =>
  R.pipe(
    R.when(R.complement(R.equals(FONT_SIZES.length - 1)), R.add(1)),
    R.nth(R.__, FONT_SIZES),
    (newSize) => quill.format('size', newSize),
  );

const reduceCurrentSize = (quill) =>
  R.pipe(
    R.when(R.complement(R.equals(0)), R.subtract(R.__, 1)),
    R.nth(R.__, FONT_SIZES),
    (newSize) => quill.format('size', newSize),
  );

export function onBiggerClick() {
  const { quill } = this;
  R.pipe(findIndexOfCurrentSize, increaseCurrentSize(quill))(quill);
}

export function onSmallerClick() {
  const { quill } = this;
  R.pipe(findIndexOfCurrentSize, reduceCurrentSize(quill))(quill);
}
