import * as R from 'ramda';
import React from 'react';
import { Input } from '@poly/admin-book';
import { shape, string } from 'prop-types';
import { RequesterSurveyQuestionTypes } from '@poly/constants';

import { RadioButtonSelect } from './RadioButtonSelect.js';

const { yesNo, scoring, submitMethod } = RequesterSurveyQuestionTypes;

function TextareaComponent(props) {
  return (
    <Input
      showCharactersLeft
      charactersLimit={500}
      style={{ resize: 'none' }}
      {...props}
    />
  );
}

// isSelectType :: String -> Boolean
const isSelectType = R.includes(R.__, [yesNo, scoring, submitMethod]);

export function RequesterSurveyAnswer({ question, ...props }) {
  const AnswerComponent = isSelectType(question?.type)
    ? RadioButtonSelect
    : TextareaComponent;

  return <AnswerComponent {...props} questionType={question?.type} />;
}

RequesterSurveyAnswer.propTypes = {
  error: string,
  question: shape({
    question: string,
    type: string,
  }),
};
