import * as R from 'ramda';
import styled from 'styled-components';
import React, { useContext, useMemo } from 'react';
import { FixedSizeList } from 'react-window';
import {
  shape,
  instanceOf,
  oneOfType,
  arrayOf,
  func,
  string,
  node,
  number,
  object,
  array,
} from 'prop-types';

import { TableS, Body, TableFooterS } from './styles.js';
import { getThemeColor } from '../utils.js';

const VirtualTableFooter = styled(TableFooterS)`
  position: sticky;
  bottom: 0;
  background-color: ${getThemeColor(['secondaryLighter3'])};
  margin-top: 1px;
`;

// modifyTableStyles :: tableProps -> tableProps
// tableProps = { style: Object }
const modifyTableStyles = (footerHeight) =>
  R.over(
    R.lensPath(['style', 'height']),
    // common table header height = 45px + 2px to show body borders - change it to headerHeight prop if need
    R.add(footerHeight + 47),
  );

/** Context for cross component communication */
const VirtualTableContext = React.createContext({
  header: R.T,
  footer: R.T,
});

/**
 * The Inner component of the virtual list. This is the "Magic".
 * Capture what would have been the top elements position and apply it to the table.
 * Other than that, render an optional header and footer.
 * */
const Inner = React.forwardRef(({ children, ...rest }, ref) => {
  const { header, footer, className, footerHeight } =
    useContext(VirtualTableContext);
  const modifiedProps = footerHeight
    ? modifyTableStyles(footerHeight)(rest)
    : rest;
  return (
    <div {...modifiedProps} ref={ref}>
      <TableS className={className}>
        {header}
        <Body footerHeight={footerHeight}>{children}</Body>
        {!!footer && <VirtualTableFooter>{footer}</VirtualTableFooter>}
      </TableS>
    </div>
  );
});

Inner.propTypes = { children: oneOfType([node, arrayOf(node)]) };

export function VirtualTable({
  row: Component,
  header,
  footer,
  className,
  listRef,
  footerHeight,
  rowProps,
  ...rest
}) {
  const contextValue = useMemo(
    () => ({
      header,
      footer,
      className,
      footerHeight,
    }),
    [header, footer, className, footerHeight],
  );

  return (
    <VirtualTableContext.Provider value={contextValue}>
      <FixedSizeList {...rest} innerElementType={Inner} ref={listRef}>
        {(props) => <Component {...props} {...rowProps} />}
      </FixedSizeList>
    </VirtualTableContext.Provider>
  );
}

VirtualTable.propTypes = {
  row: oneOfType([func, object]).isRequired,
  header: node.isRequired,
  footer: node,
  className: string,
  listRef: oneOfType([func, shape({ current: instanceOf(Element) })]),
  footerHeight: number,
  rowProps: oneOfType([func, object, array, node]).isRequired,
};
