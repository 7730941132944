import React from 'react';
import { useOutSidebarContext } from '@poly/client-utils';

import { clientTabsSidebarId } from '../constants.js';
import { ClientSidebarTabs } from './ClientSidebarTabs.js';
import { SecondSidebarLayout } from '../../../components/SidebarLayouts.js';
import {
  getClientTabsWidth,
  showClientChildren,
  showClientPortal,
} from './clientSidebarTabsUtils.js';

export const useOpenClientTabsSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();

  return (client) => {
    const showChildren = showClientChildren(client);
    const showPortal = showClientPortal(client);

    const sidebarProps = {
      showPortal,
      showChildren,
      client,
      clientName: client.name,
    };

    openOutSidebar({
      id: clientTabsSidebarId,
      Layout: SecondSidebarLayout,
      content: <ClientSidebarTabs {...sidebarProps} />,
      width: getClientTabsWidth(showChildren, showPortal),
    });
  };
};
