import * as R from 'ramda';

import { useProcessState } from './useProcessState.js';

// isPromise :: Any -> Boolean
const isPromise = R.compose(
  R.is(Function),
  // in this case we're only interested in this method
  R.prop('finally'),
);

const ON_SUBMIT_RESULT_ERROR =
  'onSubmit result must be Promise while using withOnSubmitSetStopSubmitting';

export const useOnSubmitSetStopSubmitting = (formId, onSubmitHandler) => {
  const { setProcesses } = useProcessState(formId);

  const onSubmit = (input) => {
    const setSubmitting = (value) => setProcesses({ [formId]: value });

    setSubmitting(true);

    const submitResult = onSubmitHandler(input);

    if (isPromise(submitResult)) {
      submitResult
        .catch((e) => {
          // need to catch to prevent not handled rejections
          // eslint-disable-next-line no-console
          console.error('Mutation error', e);
        })
        .finally(() => setSubmitting(false));
    } else {
      throw new Error(ON_SUBMIT_RESULT_ERROR);
    }
  };

  return { onSubmit };
};
