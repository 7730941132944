import React, { useContext, useState } from 'react';
import { Loader } from '@poly/admin-book';
import { useRedirectIfHasNoPermissions } from '@poly/client-utils';
import { EDIT_CLIENT_INVOICE_PERMISSION } from '@poly/security';

import { EmptyListMessage } from '../../components/EmptyListMessage.js';
import { SearchClientInvoicesPageTable } from './SearchClientInvoicesPageTable.js';
import {
  PageWithSearchHeader,
  PageWithSearchHeaderContext,
} from '../../components/PageWithSearchHeader.js';
import {
  WhiteBodyWrapper,
  PayInvoicesTableCard,
} from '../PaySuppliersPage/PaySuppliersComponents.js';
import { SearchClientInvoicesPageHeader } from './SearchClientInvoicesPageHeader.js';
import { useSearchClientInvoicesQuery } from './useSearchClientInvoices.js';

export function SearchClientInvoicesPage() {
  const { contentHeight } = useContext(PageWithSearchHeaderContext);
  const [filters, setFilters] = useState(null);

  const { clientInvoices, loading, tableProps, refetch } =
    useSearchClientInvoicesQuery(filters);

  useRedirectIfHasNoPermissions([EDIT_CLIENT_INVOICE_PERMISSION], false);

  return (
    <PageWithSearchHeader headerHeight="165px">
      <SearchClientInvoicesPageHeader
        setFilters={setFilters}
        loading={loading}
      />
      {loading ? (
        <Loader />
      ) : (
        <WhiteBodyWrapper height={contentHeight}>
          <PayInvoicesTableCard height="100%">
            {clientInvoices.length === 0 ? (
              <EmptyListMessage />
            ) : (
              <SearchClientInvoicesPageTable
                clientInvoices={clientInvoices}
                refetch={refetch}
                {...tableProps}
              />
            )}
          </PayInvoicesTableCard>
        </WhiteBodyWrapper>
      )}
    </PageWithSearchHeader>
  );
}
