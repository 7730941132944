import * as R from 'ramda';
import { isNilOrEmpty } from '@poly/utils';
import {
  collectionNames,
  ListingTasksActionsTypes,
  taskActionTypes,
} from '@poly/constants';

export const defaultBlackList = [
  '_id',
  '__typename',
  'projectId',
  'description',
  'type',
  'startDate',
  'endDate',
  'weeklyServiceTickets',
  'configs',
  'notRM',
  'enableGlCodes',
];

// filterKeys :: [String] -> Object -> Object
const filterKeys = R.curry((blacklist) =>
  R.compose(
    R.fromPairs,
    R.filter(([keyword]) => !R.includes(keyword, blacklist)),
    R.toPairs,
  ),
);

// prepareDetailsString :: (Object, [String]) -> String
export const prepareDetailsString = R.curry(
  (document, blacklist = defaultBlackList) =>
    R.pipe(
      R.defaultTo({}),
      R.reject(R.isNil),
      filterKeys(blacklist),
      R.map(
        R.when(R.is(Object), (object) =>
          prepareDetailsString(object, blacklist),
        ),
      ),
      R.values,
      R.flatten,
      R.reject(isNilOrEmpty),
      R.join(' - '),
    )(document),
);

// getTaskProjectId :: (Object) -> String
export const getTaskProjectId = R.path(['project', 'projectId']);

// isDefaultTask :: TaskAction -> Boolean
// TaskAction = String
export const isDefaultTask = R.propSatisfies(
  R.includes(R.__, [
    ...R.values(taskActionTypes),
    ...R.values(ListingTasksActionsTypes),
  ]),
  'action',
);

// getEntityByCollection :: Collection -> String
export const getProjectEntityByCollection = (collection) =>
  collection === collectionNames.recurringProjects
    ? 'recurringProject'
    : 'project';
