import { useSupplierTaxValidation } from '@poly/admin-ui';

import { routesNames } from '../../../routes/constants.js';

export const useReviewSupplierOnboardingValidation = (initialValues) => {
  const validateSupplierTax = useSupplierTaxValidation(
    routesNames.MASTER_SUPPLIER,
    routesNames.SUPPLIER,
    false,
    initialValues,
  );

  const validate = async (values) => {
    const supplierTaxValidationResult = validateSupplierTax(values);

    return supplierTaxValidationResult;
  };

  return validate;
};
