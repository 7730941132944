import { ASC_SORT_ORDER, ServiceTypeStatuses } from '@poly/constants';
import { keywordSortQuery } from '@poly/client-utils';
import { serviceTypesByStatusQuery } from '../../searchQueries/serviceTypes.js';
import { useServiceTypesBySearchTable } from '../serviceTypes/index.js';

export const usePrefetchServiceTypes = ({ status, supplierSource }) => {
  const { result, loading } = useServiceTypesBySearchTable({
    sort: keywordSortQuery(['name'])(ASC_SORT_ORDER),
    query: serviceTypesByStatusQuery(status, supplierSource),
  });

  return {
    loading,
    result,
  };
};

export const usePrefetchServiceTypesPage = (supplierSource) => {
  const inactiveServiceTypes = usePrefetchServiceTypes({
    status: ServiceTypeStatuses.inactive,
    supplierSource,
  });
  const activeServiceTypes = usePrefetchServiceTypes({
    status: ServiceTypeStatuses.active,
    supplierSource,
  });

  return {
    inactiveServiceTypes,
    activeServiceTypes,
  };
};
