import React from 'react';
import styled from 'styled-components';
import { useRouterQuery, useUpdateQueryParams } from '@poly/client-routing';
import { PageHeaderContainer, MainHeader } from '@poly/admin-book';
import { TableSearchInput, ClientSelect } from '@poly/admin-ui';
import { keywordSortQuery } from '@poly/client-utils';
import { ASC_SORT_ORDER } from '@poly/constants';
import { isNilOrEmpty } from '@poly/utils';

import { PropertySelect } from '../../modules/selects/PropertySelect/PropertySelect.js';

const HeaderRowS = styled.div`
  display: flex;

  > *:not(:last-child) {
    margin-right: 30px;
  }
`;

const AssetReviewSearchS = styled(TableSearchInput)`
  width: 195px;

  div,
  input {
    width: 173px;
  }
`;

export function AssetReviewPageHeader() {
  const updateQueryParams = useUpdateQueryParams();
  const { clientId, propertyId } = useRouterQuery(['clientId', 'propertyId']);

  const onChange = (name) => (value) => {
    const preparedValue = isNilOrEmpty(value) ? undefined : value;

    updateQueryParams({
      [name]: preparedValue,
      ...(name === 'clientId' ? { propertyId: undefined } : {}),
    });
  };

  const clientSelectorProps = {
    width: '250px',
    value: clientId,
    includeAllOption: false,
    onChange: onChange('clientId'),
  };

  const propertySelectorProps = {
    width: '250px',
    value: propertyId,
    withoutSkip: true,
    isClearable: true,
    disabled: !clientId,
    includeAllOption: false,
    onChange: onChange('propertyId'),
    additionalSearchParams: {
      sort: keywordSortQuery(['name'])(ASC_SORT_ORDER),
      ...(clientId ? { query: { term: { clientId } } } : {}),
    },
  };

  return (
    <PageHeaderContainer>
      <MainHeader>Asset Review</MainHeader>
      <HeaderRowS>
        <ClientSelect {...clientSelectorProps} />
        <PropertySelect {...propertySelectorProps} />
        <AssetReviewSearchS placeholder="Search" />
      </HeaderRowS>
    </PageHeaderContainer>
  );
}
