import React from 'react';
import * as R from 'ramda';
import { commonEmailValidators } from '@poly/client-utils/src/formValidators.js';
import { Input, Select } from '@poly/admin-book';
import {
  SAP_VENDOR_NUMBER_LENGTH_LIMIT,
  SupplierFoodBuyVendorTypes,
} from '@poly/constants';

import { MBEComponent } from '../components/index.js';
import { halfWidth, quarterWidth } from '../common.js';
import { SupplierFormCheckbox } from '../components/Checkbox.js';
import { PercentInput } from '../../../../../components/PercentInput.js';
import { isAdminSupplierSource } from '../utils.js';

const vendorTypeOptions = [
  { label: 'NT', value: SupplierFoodBuyVendorTypes.NT },
  { label: 'Off Invoice', value: SupplierFoodBuyVendorTypes.OFF_INVOICE },
];

function VendorTypeSelector(props) {
  const selectProps = {
    ...props,
    placeholder: 'Select type',
    options: vendorTypeOptions,
  };

  return <Select {...selectProps} />;
}

const oneFifthWidth = 'calc(20% - 20px)';

export const supplierGeneralSectionMWBEFields = [
  {
    label: 'WBE',
    order: 14,
    layout: { row: 8, width: oneFifthWidth },
    field: {
      name: 'isWBE',
      Component: SupplierFormCheckbox,
      isEqual: R.equals,
    },
  },
  {
    label: 'MBE',
    order: 15,
    layout: { row: 8, width: oneFifthWidth },
    field: {
      name: 'mwbe',
      Component: MBEComponent,
      isEqual: R.equals,
    },
    validators: [
      [
        R.ifElse(R.prop('isMBE'), R.prop('minority'), R.T),
        'Ethnicity is required',
      ],
    ],
  },
  {
    label: 'Veteran',
    order: 17,
    layout: { row: 8, width: oneFifthWidth },
    field: {
      name: 'isVeteran',
      Component: SupplierFormCheckbox,
    },
    renderIf: isAdminSupplierSource,
  },
  {
    label: 'Foodbuy',
    order: 16,
    layout: { row: 8, width: oneFifthWidth },
    field: {
      name: 'foodBuy.isEnabled',
      Component: SupplierFormCheckbox,
    },
    renderIf: isAdminSupplierSource,
  },
  {
    label: 'SAP Vendor Number',
    order: 18,
    layout: { row: 9, width: halfWidth },
    field: {
      name: 'foodBuy.sapVendorNumber',
      Component: Input,
      additionalProps: {
        maxLength: SAP_VENDOR_NUMBER_LENGTH_LIMIT,
        charactersLimit: SAP_VENDOR_NUMBER_LENGTH_LIMIT,
        showCharactersLeft: true,
      },
    },
    renderIf: isAdminSupplierSource,
  },
  {
    label: 'Foodbuy Vendor Type',
    order: 19,
    layout: { row: 9, width: quarterWidth },
    field: {
      name: 'foodBuy.vendorType',
      Component: VendorTypeSelector,
    },
    renderIf: R.both(isAdminSupplierSource, R.path(['foodBuy', 'isEnabled'])),
  },
  {
    label: 'Foodbuy VA %',
    order: 20,
    layout: { row: 9, width: quarterWidth },
    field: {
      name: 'foodBuy.vaPercentage',
      Component: PercentInput,
    },
    renderIf: R.both(isAdminSupplierSource, R.path(['foodBuy', 'isEnabled'])),
  },
  {
    label: 'Foodbuy Term Set',
    order: 21,
    layout: { row: 10, width: halfWidth },
    field: {
      name: 'foodBuy.termSet',
      Component: Input,
    },
    renderIf: R.both(isAdminSupplierSource, R.path(['foodBuy', 'isEnabled'])),
  },
  {
    label: 'Supplier VA Email',
    order: 22,
    layout: { row: 10, width: halfWidth },
    field: {
      name: 'foodBuy.vaEmail',
      Component: Input,
    },
    validators: commonEmailValidators,
    renderIf: R.both(isAdminSupplierSource, R.path(['foodBuy', 'isEnabled'])),
  },
];
