import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { CommonSidebarFormWidth, entities } from '@poly/admin-ui';
import { useOutSidebarContext } from '@poly/client-utils';

import { addPropertyFormId } from '../../constants.js';
import { AddPropertySideBarForm } from './AddPropertySideBarForm.js';
import { SidebarFormLayout } from '../../../components/SidebarFormLayout.js';
import { useOnCloseFormSidebar } from '../../../useOnCloseFormSidebar.js';

function AddPropertyFrom({ onClose, clientId, parentProperty, isMaster }) {
  return (
    <SidebarFormLayout
      onClose={onClose}
      formId={addPropertyFormId}
      submitButtonCaption="Create"
      title="Create a New Property"
    >
      <AddPropertySideBarForm
        clientId={clientId}
        parentProperty={parentProperty}
        isMaster={isMaster}
      />
    </SidebarFormLayout>
  );
}

AddPropertyFrom.propTypes = {
  onClose: func.isRequired,
  clientId: string.isRequired,
  parentProperty: shape({}),
  isMaster: bool.isRequired,
};

export const useAddPropertySidebar = (isCard) => {
  const { openOutSidebar } = useOutSidebarContext();
  const onClose = useOnCloseFormSidebar(isCard);

  const openAddPropertySidebar = ({ clientId, parentProperty, isMaster }) =>
    openOutSidebar({
      alwaysFirst: true,
      id: addPropertyFormId,
      width: CommonSidebarFormWidth,
      content: (
        <AddPropertyFrom
          clientId={clientId}
          parentProperty={parentProperty}
          isMaster={isMaster}
          onClose={() => onClose(entities.CLIENT, clientId)}
        />
      ),
    });
  return openAddPropertySidebar;
};
