import React from 'react';
import { Text } from '@poly/admin-book';

import {
  FlexFullWidthJustifyStart,
  SearchResultItemContainer,
} from './common/wrappers.js';

export function NothingFoundSearchResultComponent() {
  return (
    <SearchResultItemContainer>
      <FlexFullWidthJustifyStart>
        <Text>Nothing found</Text>
      </FlexFullWidthJustifyStart>
    </SearchResultItemContainer>
  );
}
