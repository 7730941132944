import * as R from 'ramda';
import React, { useEffect } from 'react';
import { arrayOf, func, shape, string, oneOfType } from 'prop-types';
import { isNilOrEmpty } from '@poly/utils';
import { propertyStatuses } from '@poly/constants/src/properties.js';

import { MultiplePropertySelect } from '../../../../../pages/ClientInvoicingCollection/BillingProfileConfigurationSidebar/components/MultiplePropertySelect.js';

// getCostCenter :: { property: Property, client: Client } -> Boolean
const getCostCenter = R.either(
  R.path(['property', 'costCenter']),
  R.path(['client', 'financial', 'costCenter']),
);

function MultipleSubPropertySelect({
  formData,
  changeFieldValue,
  value,
  ...props
}) {
  const propertyId = R.path(['property', '_id'], formData);

  const query = {
    bool: {
      must: [
        { match: { masterPropertyId: propertyId } },
        { match: { status: propertyStatuses.ACTIVE } },
      ],
    },
  };

  useEffect(() => {
    if (isNilOrEmpty(value) && !formData.costCenter) {
      const costCenter = getCostCenter(formData);
      setTimeout(() => changeFieldValue('costCenter', costCenter), 100);
    }
  }, [value]);

  return (
    <MultiplePropertySelect
      {...props}
      value={value}
      withAllOption
      withBaseSelect
      skip={!propertyId}
      formData={formData}
      customQuery={query}
    />
  );
}

MultipleSubPropertySelect.propTypes = {
  changeFieldValue: func.isRequired,
  value: oneOfType([string, arrayOf(string)]),
  formData: shape({ property: shape({ _id: string }) }),
};

export const subPropertyFormField = {
  // eslint-disable-next-line @cspell/spellchecker
  label: 'Subproperties',
  field: {
    name: 'subPropertiesIds',
    key: 'subPropertiesIds',
    withFormData: true,
    withChangeFieldValue: true,
    Component: MultipleSubPropertySelect,
  },
  renderIf: R.path(['property', 'isMaster']),
};
