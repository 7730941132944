import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import { DESC_SORT_ORDER } from '@poly/constants';
import { Loader } from '@poly/admin-book';
import {
  useMapConfigToTableProps,
  useSetItemsCount,
  useTableSorting,
} from '@poly/admin-ui';
import {
  MasterSupplierSubTabComp,
  ProjectsTabTitleComp,
  MasterSupplierProjectsSubTabs,
  prepareMasterSupplierProjectsToTableByPath,
} from './commonMasterSupplierProjectsTabComps.js';
import { recurringSubTabsConfig } from '../../../modules/tabs/common.js';
import { ProjectOccurrence } from '../../../modules/core/constants/projects.js';
import { commonProjectTableConfig } from '../../../modules/tables/projectsTable/ProjectsTable.js';
import { RecurringProjectsPageProjectsTableComp } from '../../../modules/tables/projectsTable/ProjectsTableComp.js';
import { useMasterSupplierRecurringQuery } from '../hooks/useMasterSupplierRecurringQuery.js';

function MasterSupplierRecurringSubTabTitle({ status }) {
  const { data } = useMasterSupplierRecurringQuery(status);

  const count = R.pathOr(
    0,
    ['masterSupplier', 'searchRecurringProjects', 'total'],
    data,
  );

  return <ProjectsTabTitleComp count={count} status={status} />;
}

MasterSupplierRecurringSubTabTitle.propTypes = { status: string.isRequired };

function MasterSupplierRecurringTabTable({ status }) {
  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: commonProjectTableConfig,
    column: 7,
    DESC_SORT_ORDER,
  });

  const { data, loading } = useMasterSupplierRecurringQuery(status, sort);

  useSetItemsCount(
    R.pathOr(0, ['masterSupplier', 'searchRecurringProjects', 'total']),
    data,
  );

  const tableProps = useMapConfigToTableProps(
    prepareMasterSupplierProjectsToTableByPath([
      'masterSupplier',
      'searchRecurringProjects',
      'hits',
    ]),
    commonProjectTableConfig,
    data,
  );

  return loading ? (
    <Loader />
  ) : (
    <RecurringProjectsPageProjectsTableComp
      {...tableSortingProps}
      {...tableProps}
    />
  );
}

MasterSupplierRecurringTabTable.propTypes = { status: string.isRequired };

const masterSupplierRecurringTabs = R.map(
  ([status, tabName]) => [
    <MasterSupplierRecurringSubTabTitle key={tabName} status={status} />,
    tabName,
    <MasterSupplierSubTabComp
      key={status}
      status={status}
      Table={MasterSupplierRecurringTabTable}
    />,
  ],
  recurringSubTabsConfig,
);

export function MasterSupplierRecurringTab() {
  const subTabsProps = {
    tabs: masterSupplierRecurringTabs,
    newItemProps: { type: ProjectOccurrence.RECURRING_OCCURRENCE },
  };

  return <MasterSupplierProjectsSubTabs {...subTabsProps} />;
}
