import { useEffect } from 'react';
import {
  OLD_VERSION_DETECT_BROADCAST_CHANNEL,
  useBroadcastChannel,
  useUserAuthBroadcastChannel,
} from '../../hooks/useBroadcastChannel.js';

export const useRegisterSharedWorker = ({
  workerInstance,
  setData,
  skip,
  messageProps = {},
}) => {
  const { onLoggedOutListener } = useUserAuthBroadcastChannel();

  const oldVersionChannel = useBroadcastChannel(
    OLD_VERSION_DETECT_BROADCAST_CHANNEL,
  );

  const terminateWorkerInstance = () => {
    if (workerInstance) {
      workerInstance.port.postMessage({ terminate: true });
    }
  };

  onLoggedOutListener(terminateWorkerInstance);

  if (oldVersionChannel) {
    oldVersionChannel.onmessage = () => {
      terminateWorkerInstance();
      if (window.location.origin === oldVersionChannel.origin) {
        window.location.reload();
      }
    };
  }

  const postUnmountMsg = () =>
    workerInstance.port.postMessage({ unmount: true });

  const isNotSkip = !skip;

  useEffect(() => {
    if (isNotSkip) {
      window.addEventListener('beforeunload', postUnmountMsg);
    }

    return () => {
      window.removeEventListener('beforeunload', postUnmountMsg);
    };
  }, [isNotSkip]);

  useEffect(() => {
    if (!skip) {
      workerInstance.port.start();
      workerInstance.port.postMessage(messageProps);
      // eslint-disable-next-line no-param-reassign
      workerInstance.port.onmessage = (e) => {
        setData(e.data);
      };
    }

    return () => {
      if (isNotSkip) {
        setTimeout(postUnmountMsg, 2000);
      }
    };
  }, [skip]);
};
