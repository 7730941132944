import React from 'react';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { bool, shape, arrayOf, string } from 'prop-types';
import { Popover } from '@poly/admin-book/src/Popover/index.js';
import { getThemeColor } from '@poly/admin-book/src/utils.js';
import { Text } from '@poly/admin-book/src/Text/index.js';
import { NOTHING_UI_STRING } from '@poly/constants';
import {
  getDocumentRequestEmailBySupplier,
  getSupplierServicePhone,
} from '@poly/utils';

import { SupplierLink, MasterSupplierLink } from '../../../components/Links.js';

const linkStyles = css`
  font-size: 22px;
  color: ${getThemeColor(['darkest'])};

  :hover,
  :focus {
    color: ${getThemeColor(['darkest'])};
  }
`;

const SupplierLinkS = styled(SupplierLink)`
  ${linkStyles};
`;

const MasterSupplierLinkS = styled(MasterSupplierLink)`
  ${linkStyles};
`;

const PopoverContent = styled.div`
  padding: 12px 20px;
  background: #12347a;
  border-radius: 5px;
`;

const TextS = styled(Text)`
  color: #fff;
`;

// getSupplierLinkProps :: Supplier -> SupplierLinkProps
const getSupplierLinkProps = R.applySpec({
  _id: R.prop('_id'),
  target: R.always('_blank'),
  name: R.path(['company', 'name']),
});

export function SupplierContactsPopover({ supplier }) {
  const email = getDocumentRequestEmailBySupplier(supplier);
  const isMaster = R.propOr(false, 'isMaster')(supplier);
  const phone = getSupplierServicePhone(supplier);

  const LinkComponent = isMaster ? MasterSupplierLinkS : SupplierLinkS;

  return (
    <Popover
      position="bottom"
      bgColor="#12347a"
      title={<LinkComponent {...getSupplierLinkProps(supplier)} />}
      content={
        <PopoverContent>
          <TextS size="14px">Contact information</TextS>
          <TextS size="10px">Phone: {phone || NOTHING_UI_STRING}</TextS>
          <TextS size="10px">Email: {email || NOTHING_UI_STRING}</TextS>
        </PopoverContent>
      }
    />
  );
}

SupplierContactsPopover.propTypes = {
  supplier: shape({
    isMaster: bool,
    company: shape({
      name: string.isRequired,
      phones: arrayOf(shape({ number: string })),
      emails: arrayOf(shape({ email: string })),
    }),
  }),
};
