import React from 'react';

import { ClientRemarksInput } from '../components/ClientRemarksInput.js';

export const remarksFormField = (onChangePersistentValue) => ({
  field: {
    name: 'remarks',
    withFormData: true,
    withChangeFieldValue: true,
    Component: (props) => (
      <ClientRemarksInput
        {...props}
        onChangePersistentValue={onChangePersistentValue}
      />
    ),
  },
});
