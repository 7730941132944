import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { shape, string, bool } from 'prop-types';
import { EntityStatus, LinkButton } from '@poly/admin-book';
import { SidebarRow } from '@poly/admin-ui';

import {
  SidebarLabel,
  BlockWithLabel,
  SectionWrapper,
  headerTextProps,
} from '../components/commonSidebarComponents.js';
import {
  propertyStatuses,
  propertyStatusesColors,
} from '../../modules/core/constants/property.js';
import { SidebarIDs } from '../constants.js';
import { propertyTabsSidebarId } from './constants.js';
import { OpenFullInfoSidebarButton } from '../components/OpenFullInfoSidebarButton.js';
import { useOpenPropertyTabsSidebar } from './tabs/useOpenPropertyTabsSidebar.js';
import { useEditPropertySidebar } from './forms/edit/useEditPropertySidebar.js';
import { getEntityAddressPreview } from '../utils/address.js';
import { LinkToGoogleMap } from '../../components/LinkToGoogleMap.js';
import { SubmitProjectWebFormUrl } from './SubmitProjectWebFormUrl.js';
import { isRequestsAppEnabled } from '../ClientSidebar/forms/form/sections/configsSection.js';
import { getPropertyStatusTitle } from './getPropertyStatusTitle.js';

const EntityStatusS = styled(EntityStatus)`
  > div:first-child {
    margin-left: 0;
  }
`;

// isClientRequestAppEnabled :: Property -> Boolean
const isClientRequestAppEnabled = R.compose(
  isRequestsAppEnabled,
  R.prop('client'),
);

// checkPropertyLocationHierarchyEnabled :: Property -> Boolean
export const checkPropertyLocationHierarchyEnabled = R.path([
  'client',
  'enablePropertyLocationHierarchy',
]);

export function PropertySidebarHeader({ property, isCard = false }) {
  const openEditPropertySidebar = useEditPropertySidebar(isCard);
  const openFullProperty = useOpenPropertyTabsSidebar();

  const isRequestEnabled = isClientRequestAppEnabled(property);

  const isPropertyLocationHierarchyEnabled =
    checkPropertyLocationHierarchyEnabled(property);

  const { name, status, cardNumber } = property;

  const entityStatusTitle = getPropertyStatusTitle(property);

  return (
    <SectionWrapper>
      <SidebarRow justify align>
        <SidebarLabel margin={10}>{name}</SidebarLabel>
        <OpenFullInfoSidebarButton
          isCard={isCard}
          sidebarId={SidebarIDs.property}
          fullSidebarId={propertyTabsSidebarId}
          openSidebarHandler={() =>
            openFullProperty({
              property,
              isPropertyLocationHierarchyEnabled,
            })
          }
        />
      </SidebarRow>
      <SidebarRow justify>
        <EntityStatusS
          cardNumber={cardNumber}
          title={entityStatusTitle}
          status={{
            text: R.toLower(status),
            color: propertyStatusesColors[status],
          }}
        />
        <LinkButton onClick={() => openEditPropertySidebar({ property })}>
          Edit
        </LinkButton>
      </SidebarRow>
      <SidebarRow>
        <BlockWithLabel
          margin="0"
          id="address"
          label="Address"
          address={property.address}
          text={getEntityAddressPreview(property)}
          {...headerTextProps}
          Component={LinkToGoogleMap}
        />
      </SidebarRow>
      {isRequestEnabled && (
        <SidebarRow>
          <BlockWithLabel
            id="request-app-link"
            label="Web Form"
            property={property}
            Component={SubmitProjectWebFormUrl}
          />
        </SidebarRow>
      )}
    </SectionWrapper>
  );
}

PropertySidebarHeader.propTypes = {
  isCard: bool,
  property: shape({
    status: string,
    _id: string.isRequired,
    name: string.isRequired,
    cardNumber: string.isRequired,
  }),
};

PropertySidebarHeader.defaultProps = {
  property: { status: propertyStatuses.INACTIVE },
};
