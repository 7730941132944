import React from 'react';
import styled from 'styled-components';
import { string, func, bool } from 'prop-types';
import { useUpdateQueryParams } from '@poly/client-routing';
import { useOutSidebarContext } from '@poly/client-utils';
import { Button, Icon } from '@poly/admin-book';
import { FlexContainer } from '@poly/admin-ui';

import { SidebarCloseIconDecor } from '../../components/SidebarLayouts.js';
import { usePreviousSidebarParams } from '../usePreviousSidebarParams.js';

const CloseSidebarIcon = styled(Icon).attrs(() => ({
  'data-testid': 'exit',
  name: 'exit',
  size: 13,
}))`
  cursor: pointer;
`;

export function OpenFullInfoSidebarButton({
  title,
  isCard,
  sidebarId,
  fullSidebarId,
  CloseSidebarBtn,
  openSidebarHandler,
}) {
  const { isSidebarOpened, closeOutSidebar } = useOutSidebarContext();
  const previousParams = usePreviousSidebarParams();
  const updateQueryParams = useUpdateQueryParams();

  const closeFullSidebar = () => {
    closeOutSidebar(fullSidebarId);
    updateQueryParams(previousParams);
  };

  const closeMainSidebar = () => {
    closeOutSidebar(sidebarId);
    updateQueryParams({ [sidebarId]: undefined, ...previousParams });
  };

  if (isCard) return null;

  const CloseBtn = CloseSidebarBtn || CloseSidebarIcon;

  if (isSidebarOpened(fullSidebarId)) {
    return <CloseBtn onClick={closeFullSidebar} />;
  }

  return (
    <FlexContainer>
      <Button size="tiny" styleType="accentDark" onClick={openSidebarHandler}>
        {title}
      </Button>
      <SidebarCloseIconDecor data-testid="close" onClick={closeMainSidebar} />
    </FlexContainer>
  );
}

OpenFullInfoSidebarButton.propTypes = {
  isCard: bool,
  title: string,
  CloseSidebarBtn: func,
  fullSidebarId: string,
  sidebarId: string.isRequired,
  openSidebarHandler: func.isRequired,
};

OpenFullInfoSidebarButton.defaultProps = { title: 'Open Full' };
