import React from 'react';
import { gql } from '@apollo/client';
import { object, shape, string } from 'prop-types';
import {
  SidebarUpdatesTab,
  entities,
  commonUpdateFields,
} from '@poly/admin-ui';
import { UpdateCollections } from '@poly/constants';
import { isNilOrEmpty } from '@poly/utils';

import { PrintLayoutProvider } from '../../../providers.js';
import { LinkToParentClone } from '../../ProjectSidebar/tabs/ProjectSidebarUpdatesTab.js';

const RECURRING_PROJECT_UPDATES = gql`
  query RECURRING_PROJECT_UPDATES(
    $id: ID!
    $documentUpdatesInput: DocumentUpdatesInput!
  ) {
    recurringProject(id: $id) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...commonUpdateFields
        }
        total
      }
    }
  }

  ${commonUpdateFields}
`;

export function MasterRecurringProjectSidebarUpdatesTab({
  _id,
  projectId,
  property,
  cloneFromProject,
  parentClone,
}) {
  const tabProps = {
    entity: {
      _id,
      preview: 'project',
      name: entities.RECURRING_PROJECT,
      humanReadableId: projectId,
      isHQProject: isNilOrEmpty(property),
    },
    collection: UpdateCollections.recurringProjects,
    updatesQuery: RECURRING_PROJECT_UPDATES,
    PrintLayout: PrintLayoutProvider,
    LinkToParentClone: {
      Component: LinkToParentClone,
      props: { cloneFromProject, parentClone, inNewTab: true },
    },
  };

  return <SidebarUpdatesTab {...tabProps} />;
}

MasterRecurringProjectSidebarUpdatesTab.propTypes = {
  _id: string.isRequired,
  projectId: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  property: object,
  cloneFromProject: shape({
    projectId: string,
    type: string,
  }),
  parentClone: shape({
    projectId: string,
    _id: string,
  }),
};
