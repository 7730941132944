import * as R from 'ramda';
import { NOTHING_UI_STRING } from '@poly/constants';
import { applySpecWithFields, assocByPath, isNilOrEmpty } from '@poly/utils';

const addressPath = ['address', 'formatted_address'];

// splitAddress :: String -> [String]
const splitAddress = R.compose(R.split(','), R.path(addressPath));

// formatAddress :: EntityObject -> String
// EntityObject = { address: Address }
// Address = { formatted_address: String }
export const formatAddress = R.ifElse(
  R.pathSatisfies(isNilOrEmpty, addressPath),
  R.always(NOTHING_UI_STRING),
  R.compose(
    R.reject(isNilOrEmpty),
    R.juxt([
      R.compose(R.head, splitAddress),
      R.prop('addressTwo'),
      R.compose(R.trim, R.join(','), R.tail, splitAddress),
    ]),
  ),
);

// prepareAddressDeepBeforeMutate :: String -> Object -> Object
export const prepareAddressDeepBeforeMutate = (fieldName) =>
  R.ifElse(
    R.pathSatisfies(R.anyPass([R.isNil, R.isEmpty]), [fieldName, 'address']),
    R.dissocPath([fieldName, 'address']),
    R.compose(
      R.dissocPath([fieldName, 'address', 'addressTwo']),
      applySpecWithFields({
        [fieldName]: {
          addressTwo: R.pathOr('', [fieldName, 'address', 'addressTwo']),
        },
      }),
    ),
  );

// getPostalCode :: Address -> String
const getPostalCode = R.pathOr('', ['address_parts', 'postal_code']);

const preparePostalCode = R.compose(
  R.unless(
    R.compose(R.lte(R.__, 8), R.prop('length'), getPostalCode),
    applySpecWithFields({
      address_parts: {
        postal_code: R.compose(R.head, R.split('-'), getPostalCode),
        postal_code_suffix: R.compose(R.last, R.split('-'), getPostalCode),
      },
    }),
  ),
);

// prepareAddressObjectToForm :: String -> Object -> Object
export const prepareAddressObjectToForm = (fieldName) =>
  R.compose(
    R.when(
      R.pathSatisfies(R.complement(R.isNil), [fieldName, 'addressTwo']),
      R.dissocPath([fieldName, 'addressTwo']),
    ),
    R.when(
      R.pathSatisfies(R.complement(R.isNil), [fieldName, 'address']),
      assocByPath(
        [fieldName, 'address'],
        R.converge(R.mergeRight, [
          R.pipe(
            R.path([fieldName, 'address']),
            preparePostalCode,
            R.unless(
              R.path(['address_parts', 'country']),
              R.assocPath(['address_parts', 'country'], 'US'),
            ),
            R.defaultTo({}),
          ),
          R.pipe(R.prop(fieldName), R.pick(['addressTwo']), R.defaultTo({})),
        ]),
      ),
    ),
  );
