import * as R from 'ramda';
import { gql, useApolloClient } from '@apollo/client';

const SEARCH_PROCEDURES_NAMES_QUERY = gql`
  query SEARCH_PROCEDURES_NAMES_QUERY($input: CollectionSearchParams) {
    searchProcedures(input: $input) {
      hits {
        _id
      }
    }
  }
`;

export const useValidateProceduresName = (procedureIdToSkip) => {
  const client = useApolloClient();

  return async ({ name }) => {
    if (name) {
      const { data } = await client.query({
        query: SEARCH_PROCEDURES_NAMES_QUERY,
        variables: {
          input: {
            query: {
              bool: {
                must: {
                  regexp: {
                    name: {
                      value: name,
                      case_insensitive: true,
                    },
                  },
                },
                ...(procedureIdToSkip
                  ? { must_not: { term: { _id: procedureIdToSkip } } }
                  : {}),
              },
            },
          },
        },
      });

      const existProcedure = R.pathOr(
        null,
        ['searchProcedures', 'hits', 0, '_id'],
        data,
      );

      if (existProcedure) {
        return { name: 'This Procedure Name is already used' };
      }
      return {};
    }
    return {};
  };
};
