import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import { Text } from '@poly/admin-book';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 15px;
  align-items: flex-start;
`;

export function AddTimeEntityInfo({ projectId, entityInfo }) {
  return (
    <Wrapper>
      <Text lineHeight="22px">{projectId}</Text>
      <Text size="15px">{entityInfo}</Text>
    </Wrapper>
  );
}

AddTimeEntityInfo.propTypes = {
  entityInfo: string.isRequired,
  projectId: string.isRequired,
};
