import React from 'react';
import { Select } from '@poly/admin-book';
import { TaskPriorities } from '@poly/constants';
import { bool } from 'prop-types';

import { ALL } from '../../../../../constants/general.js';

export function TaskPrioritySelect({ withAllOption, ...props }) {
  const taskPrioritySelectOptions = [
    { value: TaskPriorities.LOW, label: 'Low' },
    { value: TaskPriorities.NORMAL, label: 'Normal' },
    { value: TaskPriorities.HIGH, label: 'High' },
    ...(withAllOption ? [{ value: ALL, label: 'All' }] : []),
  ];

  const selectProps = {
    required: true,
    allowModalOverflow: true,
    dataTestId: 'task-priority-select',
    options: taskPrioritySelectOptions,
    ...props,
  };

  return <Select {...selectProps} />;
}

TaskPrioritySelect.propTypes = {
  withAllOption: bool,
};
