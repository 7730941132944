import { number, shape, string } from 'prop-types';

export const supplierPaymentHistoryTransactionPropType = shape({
  date: string,
  type: string,
  accountName: string,
  aacCheck: shape({ checkNumber: number }),
  invoiceAmount: number,
  paymentAmount: number,
  project: shape({ projectId: string.isRequired }),
  invoice: shape({ invoiceNumber: string.isRequired }),
});

export const supplierPaymentHistoryFilterPropType = shape({
  checkNumber: string,
  invoiceDate: shape({
    startDate: string.isRequired,
    endDate: string.isRequired,
  }).isRequired,
  invoiceId: string,
  invoiceStatus: string.isRequired,
  projectId: string,
  supplierId: string,
});
