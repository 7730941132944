import * as R from 'ramda';
import { mapConfigToTableProps } from '@poly/admin-book';

import { projectsTableConfig } from './MyProjectsTable.js';
import { MyProjectsTableComp } from './MyProjectsTableComp.js';

export const PrintMyProjectsTable = mapConfigToTableProps(
  R.prop('data'),
  projectsTableConfig,
)(MyProjectsTableComp);
