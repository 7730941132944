export const AACCheckStatus = {
  ACTIVE: 'activeAACCheck',
  VOIDED: 'voidedAACCheck',
};

export const AdminCheckTypes = {
  manual: 'manualAdminCheckType',
  remote: 'remoteAdminCheckType',
};

export const AdminCheckTypesUI = {
  [AdminCheckTypes.manual]: 'Manual',
  [AdminCheckTypes.remote]: 'Remote',
};
