import React from 'react';
import { func } from 'prop-types';
import { useOutSidebarContext } from '@poly/client-utils';
import { CommonSidebarFormWidth } from '@poly/admin-ui';

import { addProjectFormId } from '../../constants.js';
import { AddProjectSideBarForm } from './AddProjectSideBarForm.js';
import { SidebarFormLayout } from '../../../components/SidebarFormLayout.js';
import { useOnCloseFormSidebar } from '../../../useOnCloseFormSidebar.js';

function AddProjectFrom({ onClose, ...props }) {
  return (
    <SidebarFormLayout
      onClose={onClose}
      formId={addProjectFormId}
      title="New Project Details"
      submitButtonCaption="Create"
    >
      <AddProjectSideBarForm {...props} />
    </SidebarFormLayout>
  );
}

AddProjectFrom.propTypes = {
  onClose: func.isRequired,
};

export const useAddProjectSidebarBase = (isCard) => {
  const { openOutSidebar } = useOutSidebarContext();
  const onClose = useOnCloseFormSidebar(isCard);

  const openAddProjectSidebar = ({
    formProps: { entity, sidebarId, type },
    ...restProps
  }) =>
    openOutSidebar({
      alwaysFirst: true,
      id: addProjectFormId,
      width: CommonSidebarFormWidth,
      content: (
        <AddProjectFrom
          {...restProps}
          formProps={{ entity, sidebarId, type }}
          onClose={() => onClose(entity, sidebarId, type)}
        />
      ),
    });
  return openAddProjectSidebar;
};

export const useAddProjectSidebar = (isCard, entity, sidebarId, type) => {
  const openAddProjectSidebarBase = useAddProjectSidebarBase(isCard);

  return (props) =>
    openAddProjectSidebarBase({
      ...props,
      formProps: { entity, sidebarId, type },
    });
};
