import React from 'react';
import { NotificationDotS, formatNotificatorDotValue } from '@poly/admin-book';
import styled from 'styled-components';
import { number } from 'prop-types';

const NotificationDotComp = styled(NotificationDotS)`
  left: unset;
  right: 5px;
  top: 10px;
`;

export function AppBarMenuBubble({ value }) {
  return (
    <NotificationDotComp>
      {formatNotificatorDotValue(value)}
    </NotificationDotComp>
  );
}

AppBarMenuBubble.propTypes = {
  value: number,
};
