import * as R from 'ramda';
import styled from 'styled-components';
import React, { useCallback, useMemo } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import { supplierTypes, SupplierStatuses } from '@poly/constants';
import { UPDATE_SUPPLIER_PERMISSION } from '@poly/security';
import { debounce, tryCallFunction, forceTitleCase } from '@poly/utils';
import { useRouterParams } from '@poly/client-routing';
import {
  MASTER_SUPPLIER_DETAILS_SUB,
  SupplierDocumentsWarning,
  supplierStatusesColors,
  SectionWithBackBtn,
  BackBtn,
} from '@poly/admin-ui';
import {
  PageHeaderContainer,
  EntityStatus,
  MainHeader,
  Rating,
} from '@poly/admin-book';

import { ActionButtons } from '../../components/Buttons.js';
import { supplierPageTabs } from '../../routes/constants.js';
import { SUPPLIER_DETAILS } from '../core/hooks/suppliers/queries.js';
import { editSupplierFormId } from '../forms/supplierForm/constants.js';
import { supplierCardNumber, supplierName } from '../core/utils/suppliers.js';
import { SUPPLIER_ACCOUNT_SUB } from '../core/hooks/suppliers/subscriptions.js';
import { makeSupplierBackLink } from './pageHeadersUtils.js';

const { SUBCONTRACTOR } = supplierTypes;

const SectionWithBackBtnS = styled(SectionWithBackBtn)`
  max-width: 84%;
`;

const MainHeaderS = styled(MainHeader)`
  max-width: 58%;
`;

const RatingS = styled(Rating)`
  > div {
    justify-content: center;
    align-items: center;
  }

  > div:last-child > div {
    margin: 0 0 5px 5px;
    font-size: 12px;
  }
`;

// checkIsBranchSupplier :: Supplier -> Boolean
const checkIsBranchSupplier = R.pathSatisfies(R.complement(R.isNil), [
  'masterSupplier',
  '_id',
]);

// getMasterSupplierSubVariables :: Supplier -> SubscriptionVariables
const getMasterSupplierSubVariables = R.compose(
  R.objOf('input'),
  R.objOf('id'),
  R.path(['masterSupplier', '_id']),
);

// getSupplierStatusColor :: Supplier -> String
const getSupplierStatusColor = R.compose(
  R.compose(R.prop(R.__, supplierStatusesColors), R.toLower),
  R.propOr(R.toUpper(SupplierStatuses.ACTIVE), 'status'),
);

export function SupplierPageHeader() {
  const { supplierId } = useRouterParams(['supplierId']);
  const { data, loading, refetch } = useQuery(SUPPLIER_DETAILS, {
    skip: !supplierId,
    variables: { supplierId },
    fetchPolicy: 'network-only',
  });

  const supplier = useMemo(() => data?.supplier || {}, [data]);
  const isBranchSupplier = checkIsBranchSupplier(supplier);

  const debouncedRefetch = useCallback(
    debounce(2000)(() => tryCallFunction(refetch)()),
    [refetch],
  );

  useSubscription(MASTER_SUPPLIER_DETAILS_SUB, {
    shouldResubscribe: data,
    onData: debouncedRefetch,
    variables: getMasterSupplierSubVariables(supplier),
    skip: !isBranchSupplier,
  });

  useSubscription(SUPPLIER_ACCOUNT_SUB, {
    shouldResubscribe: data,
    onData: debouncedRefetch,
    variables: { input: { id: supplier._id } },
    skip: R.isEmpty(supplier),
  });

  const title = `${isBranchSupplier ? 'Branch ' : ''}Supplier / ${R.toLower(
    supplier.type || '',
  )}`;
  const additionalQueryParams = isBranchSupplier ? { isBranch: true } : {};
  const statusColor = getSupplierStatusColor(supplier);

  return (
    <PageHeaderContainer headerStyles="z-index: unset" key={supplier._id}>
      <SectionWithBackBtnS>
        <BackBtn fallbackLink={makeSupplierBackLink(supplier)} />
        <MainHeaderS>{supplierName(supplier)}</MainHeaderS>
        <EntityStatus
          title={title}
          cardNumber={supplierCardNumber(supplier)}
          status={{
            color: statusColor,
            text: forceTitleCase(supplier.status || ''),
          }}
        />
        <SupplierDocumentsWarning supplier={supplier} loading={loading} />
        {supplier.type === SUBCONTRACTOR && (
          <RatingS starSize={14} value={supplier.rating} readOnly />
        )}
      </SectionWithBackBtnS>
      <ActionButtons
        formId={editSupplierFormId}
        processName={editSupplierFormId}
        tab={supplierPageTabs.supplierAccount}
        permission={UPDATE_SUPPLIER_PERMISSION}
        additionalQueryParams={additionalQueryParams}
      />
    </PageHeaderContainer>
  );
}

SupplierPageHeader.displayName = 'SupplierPageHeader';
