import React from 'react';
import styled from 'styled-components';
import { LinkButton, LoadingMsg } from '@poly/admin-book';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { bool, func, number } from 'prop-types';
import { FlexContainer } from '../FlexContainer.js';

const LoadingMsgS = styled(LoadingMsg)`
  bottom: ${({ offset }) => `${offset}px`};
`;

const LoadMoreContainerS = styled(FlexContainer)`
  width: 100%;
  position: relative;
`;

export function LoadMoreInvoicesBtn({
  loadedCount,
  itemCount,
  loadMoreItems,
  isLoading,
  offset,
  pageSize,
}) {
  const isScrolledToBottom = useSelector(R.prop('globalTrigger'));
  return (
    <LoadMoreContainerS>
      {isScrolledToBottom && loadedCount > 0 && loadedCount < itemCount && (
        <LoadingMsgS offset={offset}>
          {isLoading ? (
            'Loading...'
          ) : (
            <LinkButton onClick={loadMoreItems}>
              {`Load More (${pageSize})`}
            </LinkButton>
          )}
        </LoadingMsgS>
      )}
    </LoadMoreContainerS>
  );
}

LoadMoreInvoicesBtn.propTypes = {
  isLoading: bool,
  itemCount: number.isRequired,
  loadedCount: number.isRequired,
  loadMoreItems: func.isRequired,
  offset: number.isRequired,
  pageSize: number.isRequired,
};
