import React from 'react';
import * as R from 'ramda';
import { useQuery } from '@apollo/client';
import { useRouterParams, useRouterQuery } from '@poly/client-routing';
import { useReactiveDocumentTitle } from '@poly/client-utils';
import {
  TableCardWithPaginationContainer,
  SearchInputTabsDisplay,
  SUPPLIER_UPDATES_QUERY,
  TableSearchInput,
  entities,
} from '@poly/admin-ui';
import {
  UpdateCollections,
  filesCollections,
  taskCollections,
} from '@poly/constants';
import { Loader } from '@poly/admin-book/src/index.js';
import { useSupplierDetailsQuery } from '../../modules/core/hooks/suppliers/index.js';
import { FilesTab } from '../../modules/tabs/FilesTab.js';
import { supplierPageTabs } from '../../routes/constants.js';
import { UpdatesTab } from '../../modules/tabs/UpdatesTab.js';
import { PageTabs } from '../../modules/tabs/TabsWithRouter.js';
import { FlexContainer } from '../../components/FlexContainer.js';
import { TasksTab } from '../../modules/tabs/tasksTab/TasksTab.js';
import { PrintExportInvoicesToolBar } from './PrintSupplierInvoicesToolBar.js';
import { SUPPLIER_DETAILS } from '../../modules/core/hooks/suppliers/queries.js';
import { SupplierPageHeader } from '../../modules/pagesHeaders/SupplierPageHeader.js';
import { SupplierPeopleTab } from '../../modules/tabs/supplierPeopleTab/SupplierPeopleTab.js';
import { SupplierAccountInfo } from '../../modules/tabs/supplierAccountTab/SupplierAccountTab.js';
import { SupplierInvoicesTable } from '../../modules/tables/supplierInvoicesTable/SupplierInvoicesTable.js';

import {
  SupplierRecurringProjectsTab,
  SupplierProjectsTab,
} from '../../modules/tabs/supplierProjectsTab/SupplierProjectsTab.js';
import { EditSupplierForm } from '../../modules/forms/supplierForm/EditSupplierForm.js';
import { NotFoundEntity } from '../../components/NotFoundEntity.js';

const {
  supplierAccount,
  supplierProjects,
  supplierRecurring,
  supplierTasks,
  supplierUpdates,
  supplierPeople,
  supplierFiles,
  supplierInvoices,
} = supplierPageTabs;

const permit = R.compose(
  R.without([supplierAccount]),
  R.values,
)(supplierPageTabs);

const useSupplierDetails = (collection) => {
  const { data, loading } = useSupplierDetailsQuery();

  const entity = {
    _id: data?.supplier._id,
    humanReadableId: data?.supplier.company.name,
    name: entities.SUPPLIER,
  };

  return {
    entity,
    collection,
    updatesQuery: SUPPLIER_UPDATES_QUERY,
    data,
    loading,
  };
};

function SupplierUpdatesTab(props) {
  const { entity, collection, updatesQuery, data, loading } =
    useSupplierDetails(UpdateCollections.suppliers);

  if (loading) return <Loader />;

  return (
    <UpdatesTab
      {...props}
      {...data}
      entity={entity}
      collection={collection}
      updatesQuery={updatesQuery}
    />
  );
}

function SupplierFilesTab(props) {
  const { entity, collection, updatesQuery, data, loading } =
    useSupplierDetails(filesCollections.SUPPLIERS);

  if (loading) return <Loader />;

  return (
    <FilesTab
      {...props}
      {...data}
      entity={entity}
      collection={collection}
      updatesQuery={updatesQuery}
    />
  );
}

function SupplierTasksTab(props) {
  const { entity, collection, updatesQuery, data, loading } =
    useSupplierDetails(taskCollections.SUPPLIERS);

  if (loading) return <Loader />;

  return (
    <TasksTab
      {...props}
      {...data}
      entity={entity}
      collection={collection}
      updatesQuery={updatesQuery}
    />
  );
}

function SupplierInvoicesTab() {
  return (
    <TableCardWithPaginationContainer>
      <SupplierInvoicesTable />
    </TableCardWithPaginationContainer>
  );
}
export function SupplierPage() {
  const { supplierId } = useRouterParams(['supplierId']);
  const { isEdit } = useRouterQuery(['isEdit']);

  const { data, loading } = useQuery(SUPPLIER_DETAILS, {
    skip: !supplierId,
    variables: { supplierId },
    fetchPolicy: 'network-only',
  });

  const documentTitle = R.path(['supplier', 'company', 'name'], data);

  useReactiveDocumentTitle(documentTitle);

  if (!loading && data && !data.supplier) {
    return <NotFoundEntity entityName="supplier" />;
  }

  return (
    <>
      <SupplierPageHeader />
      {isEdit ? (
        <EditSupplierForm />
      ) : (
        <PageTabs
          toolBar={
            <SearchInputTabsDisplay {...{ permit }}>
              <FlexContainer>
                <TableSearchInput />
                <PrintExportInvoicesToolBar />
              </FlexContainer>
            </SearchInputTabsDisplay>
          }
          tabs={[
            [
              'Info',
              supplierAccount,
              <SupplierAccountInfo
                supplierId={supplierId}
                key={supplierAccount}
              />,
            ],
            [
              'Projects',
              supplierProjects,
              <SupplierProjectsTab key={supplierProjects} />,
            ],
            [
              'Masters',
              supplierRecurring,
              <SupplierRecurringProjectsTab key={supplierRecurring} />,
            ],
            ['Tasks', supplierTasks, <SupplierTasksTab key={supplierTasks} />],
            [
              'Updates',
              supplierUpdates,
              <SupplierUpdatesTab key={supplierUpdates} />,
            ],
            [
              'Contacts',
              supplierPeople,
              <SupplierPeopleTab key={supplierPeople} />,
            ],
            ['Files', supplierFiles, <SupplierFilesTab key={supplierFiles} />],
            [
              'Invoices',
              supplierInvoices,
              <SupplierInvoicesTab key={supplierInvoices} />,
            ],
          ]}
          defaultValue={supplierAccount}
        />
      )}
    </>
  );
}
