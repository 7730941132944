import { shape, string, number, arrayOf } from 'prop-types';

const invoiceReportEntryPropType = shape({
  _id: string.isRequired,
  projectNumber: string.isRequired,
  projectId: string.isRequired,
  projectType: string.isRequired,
  clientInvoicesAmount: number,
  invoices: arrayOf(
    shape({
      _id: string.isRequired,
      date: string.isRequired,
      supplier: string,
      supplierId: string,
      invoiceNumber: string,
      serviceType: string.isRequired,
      file: string,
      total: number,
    }),
  ),
});

export const spendReportEntryShapePropType = shape({
  propertyInvoicesReport: arrayOf(invoiceReportEntryPropType),
  propertyName: string,
  invoicesTotal: number.isRequired,
  clientInvoicesAmount: number,
});

export const spendReportHierarchyBranchPropType = shape({
  name: string,
  children: arrayOf(shape({})),
  properties: arrayOf(spendReportEntryShapePropType),
});
