import React from 'react';
import { MainHeader } from '@poly/admin-book';
import { TransferFundsForm } from './TransferFundsForm.js';
import { DefaultPageLayout } from '../DefaultPageLayout.js';

export function TransferFundsPage() {
  return (
    <DefaultPageLayout headerContent={<MainHeader>Transfer Funds</MainHeader>}>
      <TransferFundsForm />
    </DefaultPageLayout>
  );
}
