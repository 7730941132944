import React, { useState } from 'react';
import * as R from 'ramda';
import {
  commonSortQuery,
  useRedirectIfHasNoPermissions,
  useInfiniteScrollQueryWithSubscription,
} from '@poly/client-utils';
import { CREATE_CLIENT_BATCH_PERMISSION } from '@poly/security';
import {
  MainHeader,
  PageHeaderContainer,
  DefaultBodyWrapper,
} from '@poly/admin-book';
import {
  AccountingStatus,
  ASC_SORT_ORDER,
  WorkOrderStatus,
} from '@poly/constants';

import { batchInvoiceFiltersToSearchQuery } from '../BatchInvoicingTM/filters-to-query.js';
import { BatchInvoicingMondelezTable } from './BatchInvoicingMondelezTable.js';
import { batchInvoicingTMProjectsGqlQuery } from '../BatchInvoicingTM/BatchInvoicingTMPage.js';
import { BatchSpendReportPreview } from './BatchSpendReportPreview.js';
import { PROJECTS_BY_SEARCH_SUB } from '../../modules/core/hooks/projects/subscriptions.js';

const MONDELEZ_CLIENT_ID = '62616afce292a271d52ccda4';

const notRMQuery = {
  bool: {
    should: [
      { bool: { must_not: { exists: { field: 'notRM' } } } },
      { term: { notRM: false } },
    ],
  },
};

const mondelezFilterQuery = R.compose(
  R.over(R.lensPath(['bool', 'filter']), R.append(notRMQuery)),
  batchInvoiceFiltersToSearchQuery,
)({
  clientId: MONDELEZ_CLIENT_ID,
  [WorkOrderStatus.COMPLETED]: true,
  [AccountingStatus.READY_TO_INVOICE]: true,
});

const input = {
  query: mondelezFilterQuery,
  sort: commonSortQuery(['projectId'])(ASC_SORT_ORDER),
};

export function BatchInvoicingMondelezPage() {
  useRedirectIfHasNoPermissions([CREATE_CLIENT_BATCH_PERMISSION]);

  const [selectedProjects, setSelectedProjects] = useState(null);

  const { data, loading, tableProps } = useInfiniteScrollQueryWithSubscription(
    batchInvoicingTMProjectsGqlQuery,
    input,
    {
      endpointName: 'searchProjects',
      pageSize: 500,
      skip: selectedProjects,
    },
    PROJECTS_BY_SEARCH_SUB,
    { searchInput: input },
  );

  if (selectedProjects) {
    return (
      <BatchSpendReportPreview
        selectedProjects={selectedProjects}
        clientId={MONDELEZ_CLIENT_ID}
        setSelectedProjects={setSelectedProjects}
      />
    );
  }

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Mondelez Batch Invoicing</MainHeader>
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <BatchInvoicingMondelezTable
          data={data}
          loading={loading}
          tableProps={tableProps}
          setSelectedProjects={setSelectedProjects}
        />
      </DefaultBodyWrapper>
    </>
  );
}
