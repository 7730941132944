import * as R from 'ramda';
import styled from 'styled-components';
import { AACCheckStatus } from '@poly/constants';
import { applyPathOrNothingUI } from '@poly/client-utils';
import { formatTotal, propEqLegacy } from '@poly/utils';
import { getThemeColor } from '@poly/admin-book';

// isVoidedCheck :: AACCheck -> Boolean
const isVoidedCheck = propEqLegacy('status', AACCheckStatus.VOIDED);

// getCheckAmountCellAttrsByCheck :: AACCheck -> {children: String}
const getCheckAmountCellAttrsByCheck = R.compose(
  R.objOf('children'),
  R.join(' '),
  R.juxt([
    applyPathOrNothingUI(['total'], formatTotal),
    R.ifElse(isVoidedCheck, R.always(' VOIDED'), R.always('')),
  ]),
);

export const SupplierCheckAmountTableCell = styled.span.attrs(
  getCheckAmountCellAttrsByCheck,
)`
  color: ${(props) =>
    isVoidedCheck(props)
      ? getThemeColor(['secondaryMid'])(props)
      : getThemeColor(['black'])(props)};
`;
