import * as R from 'ramda';

import { getDocumentAddressLines } from '../get-address-lines.js';
import { ofArrayLegacy } from '../ramdaLegacyFunctions.js';

// prepareAssetProjectDescription :: { description: String, asset: Asset } -> String
export const prepareAssetProjectDescription = R.compose(
  R.join(' - '),
  R.reject(R.isNil),
  R.juxt([
    R.path(['asset', 'client', 'name']),
    R.path(['asset', 'property', 'name']),
    R.path(['asset', 'displayName']),
    R.prop('description'),
  ]),
);

// getAssetDescription :: { asset: Asset } -> String
const getAssetDescription = R.compose(
  R.concat(R.__, '\n'),
  R.concat('Asset: '),
  R.path(['asset', 'displayName']),
);

// prepareInvoiceDescription :: InputWithAssetAndService -> {ops: [{insert: String}]}
export const prepareInvoiceDescription = R.compose(
  R.objOf('ops'),
  ofArrayLegacy,
  R.objOf('insert'),
  R.join(''),
  R.juxt([
    getAssetDescription,
    R.always('Services Provided at '),
    R.compose(
      R.concat(R.__, ', '),
      R.pathOr('', ['asset', 'property', 'name']),
    ),
    R.compose(
      R.join(', '),
      getDocumentAddressLines,
      R.pathOr({}, ['asset', 'property']),
    ),
    R.compose(R.concat('\n'), R.propOr('', 'invoiceDescription')),
  ]),
);
