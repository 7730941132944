export const useMultipleRefs = () => {
  const refs = {};

  const setRef = (ref, key) => {
    refs[key] = ref;
  };

  const getRef = (id) => refs[id];

  return { setRef, getRef, refs };
};
