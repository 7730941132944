import * as R from 'ramda';
import {
  FORGOT_PASSWORD_PATH,
  TEMPORAL_TOKEN_PATH,
  SET_PASSWORD_PATH,
  USER_LOGOUT_PATH,
  VERIFY_2FA_PATH,
  API_PREFIX_PATH,
  APP_STATUS_PATH,
  INIT_2FA_PATH,
  PUBLIC_PATH,
  TOKEN_PATH,
} from '@poly/constants';

// getPathWithAPIPrefix :: String -> String
const getPathWithAPIPrefix = R.concat(API_PREFIX_PATH);

// graphqlTokenRoute :: String -> String
export const graphqlTokenRoute = (graphqlUrl) =>
  R.concat(graphqlUrl, getPathWithAPIPrefix(TOKEN_PATH));

// graphqlPublicRoute :: String -> String
export const graphqlPublicRoute = (graphqlUrl) =>
  R.concat(graphqlUrl, getPathWithAPIPrefix(PUBLIC_PATH));

// graphqlSetPasswordRoute :: String -> String
export const graphqlSetPasswordRoute = (graphqlUrl) =>
  R.concat(graphqlUrl, getPathWithAPIPrefix(SET_PASSWORD_PATH));

// graphqlForgotPasswordRoute :: String -> String
export const graphqlForgotPasswordRoute = (graphqlUrl) =>
  R.concat(graphqlUrl, getPathWithAPIPrefix(FORGOT_PASSWORD_PATH));

// graphqlTemporalTokenRoute :: String -> String
export const graphqlTemporalTokenRoute = (graphqlUrl) =>
  R.concat(graphqlUrl, getPathWithAPIPrefix(TEMPORAL_TOKEN_PATH));

// graphInit2FARoute :: String -> String
export const graphInit2FARoute = (graphqlUrl) =>
  R.concat(graphqlUrl, getPathWithAPIPrefix(INIT_2FA_PATH));

// graphVerify2FARoute :: String -> String
export const graphVerify2FARoute = (graphqlUrl) =>
  R.concat(graphqlUrl, getPathWithAPIPrefix(VERIFY_2FA_PATH));

// graphqlUserLogoutRoute :: String -> String
export const graphqlUserLogoutRoute = (graphqlUrl) =>
  R.concat(graphqlUrl, getPathWithAPIPrefix(USER_LOGOUT_PATH));

// graphqlUserLogoutRoute :: String -> String
export const graphqlAppStatusRoute = (graphqlUrl) =>
  R.concat(graphqlUrl, getPathWithAPIPrefix(APP_STATUS_PATH));

// graphqlWSSubscribeRoute :: String -> String
export const graphqlWSSubscribeRoute = R.concat(
  R.__,
  `${API_PREFIX_PATH}/subscriptions`,
);
