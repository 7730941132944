import {
  func,
  shape,
  string,
  arrayOf,
  oneOfType,
  instanceOf,
} from 'prop-types';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useMutation, gql, useQuery } from '@apollo/client';
import { endOfDay, startOfDay, subMonths } from 'date-fns';
import { ALL, ClientSelect } from '@poly/admin-ui';
import { isNilOrEmpty, ofArrayLegacy, propEqLegacy } from '@poly/utils';
import {
  CREATE_CLIENT_SPEND_REPORT_WITH_HIERARCHY_MUTATION,
  showBase64PDF,
} from '@poly/client-utils';
import {
  DEFAULT_PROPERTY_HIERARCHY_OPTION,
  DEFAULT_PROPERTY_HIERARCHY_NAME,
  SpendReportFilters,
  ProjectSpendTypes,
  PRINT_PDF_CAPTION,
} from '@poly/constants';
import {
  PageHeaderContainer,
  ToolBarBtnDivider,
  DateRangePicker,
  getThemeColor,
  LinkButton,
  MainHeader,
  Checkbox,
  Loader,
  Button,
  Text,
} from '@poly/admin-book';

import {
  FlexColumn,
  FlexCenterAlign,
} from '../../../components/FlexContainer.js';
import { ProjectSpendTypeSelect } from './ProjectSpendTypeSelect.js';
import { SpendReportsExportXlsBtn } from './SpendReportsExportXlsBtn.js';
import { spendReportHierarchyBranchPropType } from './spendReportsPropTypes.js';
import { FlexSpaceBetween } from '../../../modules/forms/assignSupplierForm/styles.js';
import { ClientPropertiesSelect } from '../../../modules/selects/PropertySelect/PropertySelect.js';
import { ClientHierarchiesSelector } from '../../PropertyHierarchyManagement/ClientHierarchiesSelector.js';
import { useHierarchyOptions } from '../../PropertyHierarchyManagement/useHierarchyOptions.js';
import { prepareSpendReportQueryInput } from '../helpers.js';

export const FilterContainerS = styled(FlexSpaceBetween)`
  margin-top: 10px;
  width: 100%;
`;

const SpendCostFilterContainerS = styled(FilterContainerS)`
  justify-content: flex-start;
  align-items: center;

  > div:not(:first-child) {
    margin-right: 15px;
  }
`;

const FilterTitleS = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  margin-right: 40px;
  color: ${getThemeColor(['primaryLighter4'])};
`;

export const FlexColumnFullWidth = styled(FlexColumn)`
  width: 100%;
`;

const GeneratePDFBtnContainer = styled.div`
  position: relative;
  width: 25px;
`;

export const FlexCenterAlignS = styled(FlexCenterAlign)`
  & > div {
    margin-left: 15px;
  }
  & > div:first-child {
    margin-left: 0;
  }
  & > button {
    margin-left: 15px;
  }
`;

export const SelectContainerS = styled.div`
  width: 185px;
`;

const headerStyles = css`
  height: 140px;
  z-index: 3;
`;

export const ButtonContainerS = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
`;

const ClientHierarchiesSelectorS = styled(ClientHierarchiesSelector)`
  margin-left: 0;
  width: 100%;
`;

export const predefinedPropertyOptions = [
  [SpendReportFilters.all, 'All Properties'],
  [SpendReportFilters.mainAccount, 'Main Account'],
  [ALL, 'All Properties + Main Account'],
];

// skipPropertySelectQuery :: String -> Boolean
const skipPropertySelectQuery = (value) =>
  R.includes(value, [SpendReportFilters.mainAccount, SpendReportFilters.all]);

// prependHierarchyDefaultOption :: [Options] -> [Options]
export const prependHierarchyDefaultOption = R.prepend({
  value: DEFAULT_PROPERTY_HIERARCHY_OPTION,
  label: DEFAULT_PROPERTY_HIERARCHY_NAME,
});

// getPDFFileString :: { data: { createClientSpendReportWithHierarchy: { fileString: String } } } -> String
const getPDFFileString = R.path([
  'data',
  'createClientSpendReportWithHierarchy',
  'fileString',
]);

// prepareFilterUpdates :: String -> { filterType: String, propertyId: String }
const prepareFilterUpdates = R.cond([
  [
    R.includes(R.__, [SpendReportFilters.all, SpendReportFilters.mainAccount]),
    R.applySpec({ filterType: R.identity, propertyId: R.identity }),
  ],
  [isNilOrEmpty, R.always({ filterType: null, propertyId: null })],
  [R.T, R.applySpec({ filterType: R.always(null), propertyId: R.identity })],
]);

const MASTER_PROPERTY_CHILDREN_QUERY = gql`
  query MASTER_PROPERTY_CHILDREN_QUERY($id: ID!) {
    property(id: $id) {
      _id
      childProperties {
        _id
      }
    }
  }
`;

// getChildPropertyIds :: { property: Property } -> [ID]
const getChildPropertyIds = R.compose(
  R.map(R.prop('_id')),
  R.pathOr([], ['property', 'childProperties']),
);

// isDefaultProperty :: ID -> Boolean
const isDefaultProperty = R.includes(R.__, [
  ALL,
  SpendReportFilters.all,
  SpendReportFilters.mainAccount,
]);

// shouldSkipMasterPropertyQuery :: Input -> Boolean
// Input = ID|String|[ID]
const shouldSkipMasterPropertyQuery = R.anyPass([
  R.isNil,
  R.is(Array),
  isDefaultProperty,
]);

// prepareFilters :: [ID] -> FormData -> FormData
const prepareFilters = (childPropertyIds) =>
  R.compose(
    R.over(
      R.lensProp('spendType'),
      R.compose(
        R.when(
          propEqLegacy(0, ProjectSpendTypes.RECURRING_SERVICE),
          R.append(ProjectSpendTypes.PREVENTIVE_REPAIR),
        ),
        R.ifElse(isNilOrEmpty, R.always(null), ofArrayLegacy),
      ),
    ),
    R.over(
      R.lensProp('propertyId'),
      R.ifElse(
        R.either(isDefaultProperty, R.isNil),
        R.always(null),
        R.compose(R.concat(childPropertyIds), ofArrayLegacy),
      ),
    ),
  );

export function SpendReportsPageHeaderC({
  reports,
  queryInput,
  setQueryInput,
}) {
  const [childPropertyIds, setChildPropertyIds] = useState([]);
  const [filters, setFilters] = useState({
    endDate: endOfDay(new Date()),
    startDate: startOfDay(subMonths(new Date(), 1)),
    hierarchyId: DEFAULT_PROPERTY_HIERARCHY_OPTION,
    spendCostConfig: {
      includeClientInvoice: false,
      includeSupplierInvoice: false,
    },
  });
  const [loading, setLoading] = useState(false);
  const [clientName, setClientName] = useState('');
  const [createClientSpendReportWithHierarchyMutation] = useMutation(
    CREATE_CLIENT_SPEND_REPORT_WITH_HIERARCHY_MUTATION,
  );

  const {
    endDate,
    clientId,
    spendType,
    startDate,
    propertyId,
    hierarchyId,
    spendCostConfig,
  } = filters;

  const { data } = useQuery(MASTER_PROPERTY_CHILDREN_QUERY, {
    variables: { id: propertyId },
    skip: shouldSkipMasterPropertyQuery(propertyId),
  });

  const { options, loading: optionsLoading } = useHierarchyOptions(clientId);
  const allOptions = prependHierarchyDefaultOption(options);

  const isPreviewDisabled =
    !clientId || (clientId && options.length > 0 && !hierarchyId);

  const generatePDF = async () => {
    setLoading(true);
    const result = await createClientSpendReportWithHierarchyMutation({
      variables: { input: prepareSpendReportQueryInput(queryInput) },
    });

    const fileString = getPDFFileString(result);
    await showBase64PDF(fileString);
    setLoading(false);
  };

  const onClientPropertyChange = (propertyValue) => {
    const filterUpdates = prepareFilterUpdates(propertyValue);
    setFilters({ ...filters, ...filterUpdates });
  };

  const onProjectSpendTypeChange = (value) => {
    setFilters({ ...filters, spendType: value });
  };

  const onProjectSpendCostChange = (field) => (value) => {
    setFilters({
      ...filters,
      spendCostConfig: { ...filters.spendCostConfig, [field]: value },
    });
  };

  useEffect(() => {
    setChildPropertyIds(getChildPropertyIds(data));
  }, [data?.property?._id]);

  const onPreview = () =>
    setQueryInput(prepareFilters(childPropertyIds)(filters));

  return (
    <PageHeaderContainer {...{ headerStyles }}>
      <FlexColumnFullWidth>
        <MainHeader>Spend Reports</MainHeader>
        <FilterContainerS>
          <FlexCenterAlignS>
            <SelectContainerS>
              <ClientSelect
                withoutSkip
                selectedOptionAtOnChange
                includeAllOption={false}
                value={clientId}
                onChange={(value, selectedOption) => {
                  if (selectedOption) {
                    setClientName(selectedOption.label);
                  }

                  setFilters({
                    ...filters,
                    clientId: value,
                    propertyId: null,
                    filterType: null,
                    hierarchyId: DEFAULT_PROPERTY_HIERARCHY_OPTION,
                  });
                }}
              />
            </SelectContainerS>
            <SelectContainerS>
              <ClientPropertiesSelect
                withoutSkip
                disabled={!clientId}
                clientId={clientId}
                value={propertyId}
                onChange={onClientPropertyChange}
                includeAllOption
                predefinedOptions={predefinedPropertyOptions}
                skipSelectedOptionQuery={skipPropertySelectQuery(propertyId)}
              />
            </SelectContainerS>
            {clientId && options.length > 0 && (
              <SelectContainerS>
                <ClientHierarchiesSelectorS
                  required
                  value={hierarchyId}
                  placeholder="Start typing hierarchy"
                  options={allOptions}
                  loading={optionsLoading}
                  clientId={clientId}
                  onChange={(value) =>
                    setFilters({ ...filters, hierarchyId: value })
                  }
                  changeClientId={() => null}
                />
              </SelectContainerS>
            )}
            <SelectContainerS>
              <ProjectSpendTypeSelect
                value={spendType}
                onChange={onProjectSpendTypeChange}
              />
            </SelectContainerS>
            <DateRangePicker
              width="115px"
              value={{ startDate, endDate }}
              onChange={(dates) => setFilters({ ...filters, ...dates })}
            />
            <Button
              size="small"
              onClick={onPreview}
              disabled={isPreviewDisabled}
            >
              Preview
            </Button>
          </FlexCenterAlignS>
          {reports.length > 0 && (
            <ButtonContainerS>
              <GeneratePDFBtnContainer>
                {!loading && (
                  <LinkButton onClick={generatePDF}>
                    {PRINT_PDF_CAPTION}
                  </LinkButton>
                )}
                {loading && <Loader size={16} />}
              </GeneratePDFBtnContainer>
              <ToolBarBtnDivider />
              <SpendReportsExportXlsBtn
                reports={reports}
                clientName={clientName}
                startDate={startDate}
                endDate={endDate}
              />
            </ButtonContainerS>
          )}
        </FilterContainerS>
        <SpendCostFilterContainerS>
          <FilterTitleS>Spend Cost Config</FilterTitleS>
          <Checkbox
            name="include-supplier-invoice"
            label="Include Supplier Invoice"
            value={spendCostConfig.includeSupplierInvoice}
            onChange={onProjectSpendCostChange('includeSupplierInvoice')}
          />
          <Checkbox
            name="include-client-invoice"
            label="Include Client Invoice"
            value={spendCostConfig.includeClientInvoice}
            onChange={onProjectSpendCostChange('includeClientInvoice')}
          />
        </SpendCostFilterContainerS>
      </FlexColumnFullWidth>
    </PageHeaderContainer>
  );
}

SpendReportsPageHeaderC.propTypes = {
  setQueryInput: func.isRequired,
  reports: arrayOf(spendReportHierarchyBranchPropType),
  queryInput: shape({
    clientId: string,
    propertyId: oneOfType([string, arrayOf(string)]),
    hierarchyId: string,
    startDate: instanceOf(Date),
    endDate: instanceOf(Date),
  }),
};
