import React from 'react';
import { Input } from '@poly/admin-book';
import { bool, func, string } from 'prop-types';

export function PriorityNameInput({ isDefaultPriority, ...props }) {
  const inputProps = {
    ...props,
    required: true,
    disabled: isDefaultPriority,
    charactersLimit: 50,
    showCharactersLeft: true,
  };

  return <Input {...inputProps} />;
}

PriorityNameInput.propTypes = {
  value: string,
  onUpdate: func,
  isDefaultPriority: bool,
};
