import React from 'react';
import { func, node } from 'prop-types';
import { ConfirmModalLayoutC } from './ConfirmModalLayoutC.js';

export function ConfirmModalLayout({
  closeCurrentModal,
  onConfirm,
  onReject,
  children,
  ...config
}) {
  const confirmHandler = onConfirm(closeCurrentModal);

  const handleReject = () => {
    if (onReject) {
      onReject();
    }

    if (closeCurrentModal) {
      closeCurrentModal();
    }
  };

  return (
    <ConfirmModalLayoutC
      closeCurrentModal={closeCurrentModal}
      handleReject={handleReject}
      onConfirm={confirmHandler}
      {...config}
    >
      {children}
    </ConfirmModalLayoutC>
  );
}

ConfirmModalLayout.propTypes = {
  closeCurrentModal: func.isRequired,
  onConfirm: func.isRequired,
  children: node.isRequired,
  onReject: func,
};
