import * as R from 'ramda';
import React from 'react';
import {
  formatDateProp,
  useHighlightMatchesBySearch,
} from '@poly/client-utils';
import { WorkOrderStatus } from '@poly/constants';
import { Loader } from '@poly/admin-book';
import {
  useSetInitialPaginationOnMount,
  useSetItemsCount,
  useMapConfigToTableProps,
} from '@poly/admin-ui';
import { objectOf, arrayOf, shape, string, instanceOf } from 'prop-types';
import { assocBy } from '@poly/utils/src/ramda.js';

import { RecentProjectsTableComp } from './RecentProjectsTableComp.js';
import { RecentProjectsNoResultsPlaceholder } from './RecentProjectsTablePlaceholder.js';
import {
  projectDescriptionColumn,
  projectIdColumn,
} from '../columns/projects.js';
import { useProjectsBySearch } from '../../core/hooks/projects/index.js';

export const completionDateColumn = [
  'Completed',
  formatDateProp('workCompletionDate'),
];

export const startDateColumn = ['Started', formatDateProp('startDate')];

export const tableConfig = [
  projectIdColumn,
  projectDescriptionColumn,
  ['Property', R.path(['property', 'name'])],
];

// getProjectsTotal :: SearchProjectsQueryResult -> Number
const getProjectsTotal = R.pathOr(0, ['searchProjects', 'total']);

// getProjects :: SearchProjectsQueryResult -> [Project]
const getProjects = R.pathOr([], ['searchProjects', 'hits']);

export function RecentProjectsTable({ status, query }) {
  useSetInitialPaginationOnMount();

  const { loading, result } = useProjectsBySearch({ query });

  useSetItemsCount(getProjectsTotal, result);

  const config = R.equals(status, WorkOrderStatus.ACTIVE)
    ? R.append(startDateColumn, tableConfig)
    : R.append(completionDateColumn, tableConfig);

  const { rows, ...restTableProps } = useMapConfigToTableProps(
    getProjects,
    config,
    result,
  );

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.map(assocBy('projectLinkId', R.prop('projectId'))),
    [['projectId'], ['description'], ['property', 'name']],
    rows,
  );

  if (loading) return <Loader />;
  if (getProjectsTotal(result) === 0)
    return <RecentProjectsNoResultsPlaceholder />;

  return <RecentProjectsTableComp {...restTableProps} rows={highlightedRows} />;
}

RecentProjectsTable.displayName = 'RecentProjectsTable';

RecentProjectsTable.propTypes = {
  status: string.isRequired,
  query: shape({
    bool: shape({
      filter: shape({
        range: shape({
          workCompletionDate: objectOf(instanceOf(Date)),
        }),
      }),
      must: arrayOf(
        shape({
          match: shape({
            status: string,
          }),
        }),
      ),
    }),
  }).isRequired,
};
