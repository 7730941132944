import React, { useState } from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';

import { Icon } from '../Icon/index.js';
import { defaultTheme } from '../ThemeProvider/index.js';

const {
  colors: { primaryLighter5, primaryLighter1 },
} = defaultTheme;

const IconS = styled(Icon)`
  cursor: pointer;
`;

export function IconButton({
  initialColor = primaryLighter5,
  hoverColor = primaryLighter1,
  ...props
}) {
  const [currentColor, setCurrentColor] = useState('');

  return (
    <IconS
      {...props}
      color={currentColor || initialColor}
      onMouseEnter={() => setCurrentColor(hoverColor)}
      onMouseLeave={() => setCurrentColor(initialColor)}
    />
  );
}

IconButton.propTypes = {
  initialColor: string,
  hoverColor: string,
};
IconButton.displayName = 'IconButton';
