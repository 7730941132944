import React from 'react';
import { func } from 'prop-types';
import { useOutSidebarContext } from '@poly/client-utils';

import { SidebarFormLayout } from '../../../sidebars/components/SidebarFormLayout.js';
import { updateTaskTemplateFormId } from './constants.js';
import { UpdateTaskTemplateFrom } from './UpdateTaskTemplateFrom.js';

function UpdateTaskTemplateSidebarContent({ onClose, ...props }) {
  return (
    <SidebarFormLayout
      onClose={onClose}
      formId={updateTaskTemplateFormId}
      title="Update Task Template"
      submitButtonCaption="Update"
      justifyContent="flex-start"
    >
      <UpdateTaskTemplateFrom onClose={onClose} {...props} />
    </SidebarFormLayout>
  );
}

UpdateTaskTemplateSidebarContent.propTypes = {
  onClose: func.isRequired,
};

export const useUpdateTaskTemplateSidebar = () => {
  const { openOutSidebar, closeOutSidebar } = useOutSidebarContext();

  const onCloseHandler = () => {
    closeOutSidebar(updateTaskTemplateFormId);
  };

  return (taskTemplateId) =>
    openOutSidebar({
      alwaysFirst: true,
      id: updateTaskTemplateFormId,
      width: 625,
      content: (
        <UpdateTaskTemplateSidebarContent
          taskTemplateId={taskTemplateId}
          onClose={onCloseHandler}
        />
      ),
    });
};
