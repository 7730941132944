import React from 'react';
import * as R from 'ramda';
import { func } from 'prop-types';
import { formatSelectOptionsByConstantsValue } from '@poly/client-utils';
import { ServiceTypeStatuses } from '@poly/constants';
import { Select, Input } from '@poly/admin-book';

import { InputWithSimilarItemsComp } from '../../fields/InputWithDuplicateSearch/InputWithSimilarItemsComp.js';
import { SERVICE_TYPES_SIMILAR_SEARCH } from '../../../../hocs/serviceTypes/queries.js';
import { useEntitySimilarItems } from '../../fields/InputWithDuplicateSearch/index.js';

const itemsFromProps = ({ data, ownProps }) => ({
  items: R.pipe(
    R.pathOr([], ['searchServiceTypes', 'hits']),
    R.filter(
      R.complement(
        R.eqProps('_id', {
          _id: R.propOr('', 'currentServiceTypeId', ownProps),
        }),
      ),
    ),
    R.map(
      R.applySpec({
        url: R.always('/'),
        name: R.prop('name'),
      }),
    ),
  )(data),
});

const queryVariablesFromProps = ({ nameSearch }) => ({
  searchInput: {
    size: 5,
    query: {
      bool: {
        must: [
          {
            match: {
              name: {
                query: nameSearch,
                fuzziness: 'AUTO',
                operator: 'and',
              },
            },
          },
        ],
      },
    },
  },
});

function ServiceTypeInput(props) {
  const entityProps = useEntitySimilarItems({
    query: SERVICE_TYPES_SIMILAR_SEARCH,
    itemsFromProps,
    variablesFromProps: queryVariablesFromProps,
    onChange: props.onChange,
    propsOfComponent: props,
  });

  return (
    <InputWithSimilarItemsComp
      {...entityProps}
      enabledSimilarNamesInput={false}
    />
  );
}

ServiceTypeInput.propTypes = {
  onChange: func,
};

function ServiceTypesStatusSelect(props) {
  const options = formatSelectOptionsByConstantsValue(ServiceTypeStatuses);

  return <Select {...props} options={options} />;
}

function ServiceTypeNameInput(props) {
  const currentServiceTypeId = R.pathOr('', ['formData', '_id'], props);

  return (
    <ServiceTypeInput currentServiceTypeId={currentServiceTypeId} {...props} />
  );
}

export const serviceTypesSections = [
  {
    label: '',
    order: 1,
    fields: [
      {
        label: 'Service Type',
        order: 1,
        field: {
          name: 'name',
          withFormData: true,
          Component: ServiceTypeNameInput,
        },
        validators: [[R.identity, 'Service name is required']],
        required: true,
      },
      {
        label: 'Description',
        order: 2,
        field: {
          name: 'description',
          Component: Input,
        },
      },
      {
        label: 'Status',
        order: 2,
        field: {
          name: 'status',
          Component: ServiceTypesStatusSelect,
        },
        required: true,
      },
    ],
  },
];
