import React from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import { object, string, shape, func, arrayOf } from 'prop-types';

import { Notification, notificationPropTypes } from './Notification.js';

const NotificatorContainer = styled.div`
  width: 290px;
  z-index: 20000;
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  margin: auto;
`;

export function Notificator({ notifications, className, onClose, root }) {
  return createPortal(
    <NotificatorContainer className={className} data-testid="notificator">
      {notifications.map((notification) => (
        <Notification
          key={notification.id}
          {...notification}
          onClose={onClose}
        />
      ))}
    </NotificatorContainer>,
    root,
  );
}

Notificator.propTypes = {
  notifications: arrayOf(shape(notificationPropTypes)).isRequired,
  onClose: func.isRequired,
  className: string,
  // eslint-disable-next-line react/forbid-prop-types
  root: object,
};

Notificator.defaultProps = {
  className: '',
  root: window.document.getElementById('root'),
};
