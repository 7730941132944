import { useEffect, useState } from 'react';
import { useUnsupportedWorkerMessage } from './useUnsupportedWorkerMessage.js';

export const OLD_VERSION_DETECT_BROADCAST_CHANNEL =
  'OLD_VERSION_DETECT_BROADCAST_CHANNEL';

const USER_AUTH_BROADCAST_CHANNEL_NAME = 'USER_AUTH_BROADCAST_CHANNEL_NAME';

export const useBroadcastChannel = (channelName) => {
  const showUnsupportedMessage = useUnsupportedWorkerMessage();

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (isError) {
      showUnsupportedMessage();
    }
  }, [isError]);

  try {
    const channel = new BroadcastChannel(channelName);
    return channel;
  } catch (e) {
    if (!isError) {
      setIsError(true);
    }

    return null;
  }
};

export const useUserAuthBroadcastChannel = () => {
  const userAuthChannel = useBroadcastChannel(USER_AUTH_BROADCAST_CHANNEL_NAME);

  const postIsLoggedIn = () => {
    if (userAuthChannel) {
      userAuthChannel.postMessage({ isLoggedIn: true });
    }
  };

  const postIsLoggedOut = () => {
    if (userAuthChannel) {
      userAuthChannel.postMessage({ isLoggedOut: true });
    }
  };

  const onLoggedInListener = (handler) => {
    if (userAuthChannel) {
      userAuthChannel.onmessage = (event) => {
        if (event?.data?.isLoggedIn && handler) {
          handler();
        }
      };
    }
  };

  const onLoggedOutListener = (handler) => {
    if (userAuthChannel) {
      userAuthChannel.onmessage = (event) => {
        if (event?.data?.isLoggedOut && handler) {
          handler();
        }
      };
    }
  };

  return {
    postIsLoggedIn,
    postIsLoggedOut,
    onLoggedInListener,
    onLoggedOutListener,
  };
};
