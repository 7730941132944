import * as R from 'ramda';
import { gql } from '@apollo/client';

export const mergeAssetComponentsEntity = {
  MODEL: 'mergeAssetModelEntity',
  MANUFACTURER: 'mergeAssetManufacturerEntity',
};

const MERGE_ASSET_MANUFACTURERS_MUTATION = gql`
  mutation MERGE_ASSET_MANUFACTURERS_MUTATION(
    $input: MergeAssetManufacturersInput!
  ) {
    mergeAssetManufacturers(input: $input) {
      _id
    }
  }
`;

const MERGE_ASSET_MODELS_MUTATION = gql`
  mutation MERGE_ASSET_MODELS_MUTATION($input: MergeAssetModelsInput!) {
    mergeAssetModels(input: $input) {
      _id
    }
  }
`;

// prepareMergeAssetManufacturersMutationInput :: Input -> MergeAssetManufacturersInput
// Input = {
//    selectedName: String
//    replacementCost: PotentiallyNegativeMoney
//    selectedDocuments: [AssetManufacturers]
// }
const prepareMergeAssetManufacturersMutationInput = R.applySpec({
  newName: R.prop('selectedName'),
  replacementCost: R.prop('replacementCost'),
  assetManufacturerIds: R.compose(
    R.map(R.prop('_id')),
    R.propOr([], 'selectedDocuments'),
  ),
});

// prepareMergeAssetModelsMutationInput :: Input -> MergeAssetModelsInput
// Input = {
//    selectedName: String
//    lifeExpectancy: Float
//    replacementCost: PotentiallyNegativeMoney
//    selectedDocuments: [AssetModel]
// }
const prepareMergeAssetModelsMutationInput = R.applySpec({
  newName: R.prop('selectedName'),
  lifeExpectancy: R.prop('lifeExpectancy'),
  replacementCost: R.prop('replacementCost'),
  assetModelIds: R.compose(
    R.map(R.prop('_id')),
    R.propOr([], 'selectedDocuments'),
  ),
});

const mergeAssetComponentsConfig = {
  [mergeAssetComponentsEntity.MANUFACTURER]: {
    formTitle: 'Merge Asset Manufacturers',
    modalId: 'mergeAssetManufacturersModalId',
    inputValidationError: 'Asset Manufacturer name is required.',
    mutation: MERGE_ASSET_MANUFACTURERS_MUTATION,
    prepareMutationInput: prepareMergeAssetManufacturersMutationInput,
    mutationSuccessMessage: 'Asset Manufacturers was successfully merged.',
    confirmModalContent:
      "Selected manufacturer/model's asset replacement cost will be set to the merged ones.",
  },
  [mergeAssetComponentsEntity.MODEL]: {
    formTitle: 'Merge Asset Models',
    modalId: 'mergeAssetModelsModalId',
    inputValidationError: 'Asset Model name is required.',
    mutation: MERGE_ASSET_MODELS_MUTATION,
    prepareMutationInput: prepareMergeAssetModelsMutationInput,
    mutationSuccessMessage: 'Asset Models was successfully merged.',
    confirmModalContent:
      "Selected model's replacement cost and life expectancy will be set to the merged one.",
  },
};

// getMergeAssetComponentsConfig :: String -> MergeAssetComponentsConfig
// MergeAssetComponentsConfig = {
//    modalId: String
//    mutation: String
//    formTitle: String
//    confirmModalContent: String
//    inputValidationError: String
//    prepareMutationInput: Object -> Object
//    mutationSuccessMessage: String
// }
export const getMergeAssetComponentsConfig = R.compose(
  R.defaultTo({}),
  R.prop(R.__, mergeAssetComponentsConfig),
);
