import { ScheduleRepeats } from '@poly/constants';

const { QUARTERLY, SEMIANNUALLY, YEARLY } = ScheduleRepeats;

export const repeatsByArray = [QUARTERLY, SEMIANNUALLY, YEARLY];

export const scheduleRepeatsTextUI = {
  [ScheduleRepeats.DAILY]: 'days',
  [ScheduleRepeats.WEEKLY]: 'weeks',
  [ScheduleRepeats.MONTHLY]: 'months',
  [ScheduleRepeats.QUARTERLY]: 'quarters',
  [ScheduleRepeats.SEMIANNUALLY]: 'semiannual',
  [ScheduleRepeats.YEARLY]: 'years',
};

export const scheduleRepeatsSingularText = {
  [ScheduleRepeats.DAILY]: 'day',
  [ScheduleRepeats.WEEKLY]: 'week',
  [ScheduleRepeats.MONTHLY]: 'month',
  [ScheduleRepeats.QUARTERLY]: 'quarter',
  [ScheduleRepeats.SEMIANNUALLY]: 'semiannual',
  [ScheduleRepeats.YEARLY]: 'year',
};

export const scheduleDaysArray = [
  { day: 0, name: 'S', fullName: 'Sunday' },
  { day: 1, name: 'M', fullName: 'Monday' },
  { day: 2, name: 'T', fullName: 'Tuesday' },
  { day: 3, name: 'W', fullName: 'Wednesday' },
  { day: 4, name: 'T', fullName: 'Thursday' },
  { day: 5, name: 'F', fullName: 'Friday' },
  { day: 6, name: 'S', fullName: 'Saturday' },
];

export const scheduleRepeatsBy = {
  month: 'day of month',
  week: 'day of week',
};
