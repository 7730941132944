import * as R from 'ramda';
import React from 'react';
import { Text } from '@poly/admin-book';
import { shape } from 'prop-types';
import { pathOrNothingUI } from '@poly/client-utils';
import { NOTHING_UI_STRING, NonVirtualInvoiceStatuses } from '@poly/constants';
import {
  formatTotal,
  isNilOrEmpty,
  forceTitleCase,
  convertCentsToDollars,
} from '@poly/utils';

import { isLastElement } from '../helpers.js';
import { ReadOnlyEditorS } from '../../../sidebars/components/commonSidebarSectionFormatters.js';
import {
  getAdditionalDescriptionInfo,
  prepareDescriptionForSection,
} from '../../../sidebars/ProjectSidebar/projectSidebarUtils.js';
import {
  PrintProjectFlexRow,
  PrintProjectArrayText,
  PrintProjectFlexColumn,
  PrintProjectBlockWithLabel,
} from '../components.js';

// getProjectEstimates :: Project -> [ProjectEstimate]
const getProjectEstimates = R.propOr([], 'estimates');

// getProjectInvoiceDescriptionUI :: Project -> String
const getProjectInvoiceDescriptionUI = R.converge(
  prepareDescriptionForSection,
  [pathOrNothingUI(['invoiceDescription']), getAdditionalDescriptionInfo],
);

// getProjectDivisionUI :: { financialDivision: DivisionOption } -> String
const getProjectDivisionUI = R.ifElse(
  R.propSatisfies(isNilOrEmpty, 'division'),
  R.always(NOTHING_UI_STRING),
  R.compose(
    ([name, code]) => `${name} (${code})`,
    R.juxt([R.prop('name'), R.prop('code')]),
    R.prop('division'),
  ),
);

// getProjectClientEstimateUI :: ProjectEstimate -> String
const getProjectClientEstimateUI = R.compose(
  formatTotal,
  convertCentsToDollars,
  R.propOr(0, 'total'),
);

// getProjectJobCostReportUI :: Project -> String
const getProjectJobCostReportUI = R.compose(
  R.ifElse(
    R.isEmpty,
    R.always(NOTHING_UI_STRING),
    R.compose(formatTotal, convertCentsToDollars, R.sum),
  ),
  R.map(R.prop('total')),
  R.filter(
    R.propSatisfies(R.includes(R.__, NonVirtualInvoiceStatuses), 'status'),
  ),
  R.propOr([], 'invoices'),
);

// getProjectInvoicingStatusUI :: Project -> String
const getProjectInvoicingStatusUI = R.ifElse(
  R.propSatisfies(R.isNil, 'accountingStatus'),
  R.always(NOTHING_UI_STRING),
  R.compose(
    R.join(' '),
    R.map(forceTitleCase),
    R.split('_'),
    R.prop('accountingStatus'),
  ),
);

// getProjectClientInvoicesUI :: Project -> String
const getProjectClientInvoicesUI = R.ifElse(
  R.propSatisfies(isNilOrEmpty, 'clientInvoices'),
  R.always(NOTHING_UI_STRING),
  R.compose(
    formatTotal,
    convertCentsToDollars,
    R.sum,
    R.map(R.prop('amount')),
    R.prop('clientInvoices'),
  ),
);

// getClientEstimateGrandTotalUI :: ProjectEstimate -> String
const getClientEstimateGrandTotalUI = R.compose(
  formatTotal,
  convertCentsToDollars,
  R.propOr(0, 'total'),
);

// isManualEntries :: ProjectEstimate -> Boolean
const isManualEntries = R.compose(
  R.complement(R.isEmpty),
  R.propOr([], 'manualEntries'),
);

// getManualEstimateDescriptionUI :: ProjectEstimate -> [String]
const getManualEstimateDescriptionUI = R.compose(
  R.map(R.prop('description')),
  R.propOr([], 'manualEntries'),
);

// getManualEstimateQtyUI :: ProjectEstimate -> [String]
const getManualEstimateQtyUI = R.compose(
  R.map(R.prop('quantity')),
  R.propOr([], 'manualEntries'),
);

// getManualEstimateCostPerUI :: ProjectEstimate -> [String]
const getManualEstimateCostPerUI = R.compose(
  R.map(formatTotal),
  R.map(convertCentsToDollars),
  R.map(R.prop('rate')),
  R.propOr([], 'manualEntries'),
);

// getManualEstimateTotalUI :: ProjectEstimate -> [String]
const getManualEstimateTotalUI = R.compose(
  R.map(formatTotal),
  R.map(convertCentsToDollars),
  R.map(R.prop('total')),
  R.propOr([], 'manualEntries'),
);

// getNonManualSupplierUI :: ProjectEstimate -> [String]
const getNonManualSupplierUI = R.compose(
  R.map(R.path(['supplier', 'company', 'name'])),
  R.propOr([], 'markupEntries'),
);

// getNonManualEstimateUI :: ProjectEstimate -> [String]
const getNonManualEstimateUI = R.compose(
  R.map(formatTotal),
  R.map(convertCentsToDollars),
  R.map(R.prop('amount')),
  R.propOr([], 'markupEntries'),
);

// getNonManualMarkupUI :: ProjectEstimate -> [String]
const getNonManualMarkupUI = R.compose(
  R.map(formatTotal),
  R.map(convertCentsToDollars),
  R.map(R.prop('markup')),
  R.propOr([], 'markupEntries'),
);

// getNonManualTotalUI :: ProjectEstimate -> [String]
const getNonManualTotalUI = R.compose(
  R.map(formatTotal),
  R.map(convertCentsToDollars),
  R.map(R.prop('total')),
  R.propOr([], 'markupEntries'),
);

export function PrintProjectFinancialInfo({ project, config }) {
  const { isIncludeClientEstimateFile } = config;

  const projectEstimates = getProjectEstimates(project);

  return (
    <PrintProjectFlexColumn withBottomBorder>
      <PrintProjectFlexRow>
        <Text size="16px" weight="bold">
          Financial Info
        </Text>
      </PrintProjectFlexRow>
      <PrintProjectFlexRow>
        <PrintProjectBlockWithLabel
          label="Invoice Description"
          value={getProjectInvoiceDescriptionUI(project)}
          Component={ReadOnlyEditorS}
        />
      </PrintProjectFlexRow>
      <PrintProjectFlexRow>
        <PrintProjectBlockWithLabel
          label="Division"
          value={getProjectDivisionUI(project)}
        />
        {!isIncludeClientEstimateFile &&
          projectEstimates.map((estimate, index) => (
            <PrintProjectBlockWithLabel
              key={`${estimate._id}-key`}
              label={`Client Estimate #${index + 1}`}
              value={getProjectClientEstimateUI(estimate)}
            />
          ))}
      </PrintProjectFlexRow>
      <PrintProjectFlexRow>
        <PrintProjectBlockWithLabel
          label="Job Cost Report"
          value={getProjectJobCostReportUI(project)}
        />
        <PrintProjectBlockWithLabel
          label="Invoicing Status"
          value={getProjectInvoicingStatusUI(project)}
        />
        <PrintProjectBlockWithLabel
          label="Client Invoices"
          value={getProjectClientInvoicesUI(project)}
        />
      </PrintProjectFlexRow>
      {isIncludeClientEstimateFile &&
        projectEstimates.map((estimate, index) => (
          <PrintProjectFlexColumn
            key={`${estimate._id}-key`}
            withBottomBorder={!isLastElement(index, projectEstimates)}
          >
            <PrintProjectFlexRow>
              <PrintProjectBlockWithLabel
                label={`Client Estimate #${index + 1}`}
                value={getClientEstimateGrandTotalUI(estimate)}
              />
              {isManualEntries(estimate) ? (
                <>
                  <PrintProjectBlockWithLabel
                    label="Description"
                    Component={PrintProjectArrayText}
                    arrayValue={getManualEstimateDescriptionUI(estimate)}
                    arrayKeyPart={project?.projectId}
                  />
                  <PrintProjectBlockWithLabel
                    label="Qty"
                    Component={PrintProjectArrayText}
                    arrayValue={getManualEstimateQtyUI(estimate)}
                    arrayKeyPart={project?.projectId}
                  />
                  <PrintProjectBlockWithLabel
                    label="Cost Per"
                    Component={PrintProjectArrayText}
                    arrayValue={getManualEstimateCostPerUI(estimate)}
                    arrayKeyPart={project?.projectId}
                  />
                  <PrintProjectBlockWithLabel
                    label="Total"
                    Component={PrintProjectArrayText}
                    arrayValue={getManualEstimateTotalUI(estimate)}
                    arrayKeyPart={project?.projectId}
                  />
                </>
              ) : (
                <>
                  <PrintProjectBlockWithLabel
                    label="Supplier"
                    Component={PrintProjectArrayText}
                    arrayValue={getNonManualSupplierUI(estimate)}
                    arrayKeyPart={project?.projectId}
                  />
                  <PrintProjectBlockWithLabel
                    label="Estimate"
                    Component={PrintProjectArrayText}
                    arrayValue={getNonManualEstimateUI(estimate)}
                    arrayKeyPart={project?.projectId}
                  />
                  <PrintProjectBlockWithLabel
                    label="Markup"
                    Component={PrintProjectArrayText}
                    arrayValue={getNonManualMarkupUI(estimate)}
                    arrayKeyPart={project?.projectId}
                  />
                  <PrintProjectBlockWithLabel
                    label="Total"
                    Component={PrintProjectArrayText}
                    arrayValue={getNonManualTotalUI(estimate)}
                    arrayKeyPart={project?.projectId}
                  />
                </>
              )}
            </PrintProjectFlexRow>
          </PrintProjectFlexColumn>
        ))}
    </PrintProjectFlexColumn>
  );
}

PrintProjectFinancialInfo.propTypes = {
  config: shape({}),
  project: shape({}),
};
