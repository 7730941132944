import React from 'react';
import { object, oneOfType, string, shape } from 'prop-types';
import { SidebarRow } from '@poly/admin-ui';

import {
  SectionLabel,
  SectionWrapper,
} from '../components/commonSidebarComponents.js';
import { getSectionEditor } from '../components/commonSidebarSectionFormatters.js';

export function PropertySidebarRemarks({ property }) {
  return (
    <SectionWrapper>
      <SidebarRow>
        <SectionLabel>Remarks</SectionLabel>
      </SidebarRow>
      <SidebarRow>
        {getSectionEditor(property.remarks)({ id: 'remarks' })}
      </SidebarRow>
    </SectionWrapper>
  );
}

PropertySidebarRemarks.propTypes = {
  property: shape({ remarks: oneOfType([object, string]) }),
};
