import React from 'react';
import { func } from 'prop-types';
import { useOutSidebarContext } from '@poly/client-utils';

import { SidebarFormLayout } from '../../../sidebars/components/SidebarFormLayout.js';
import { createTaskTemplateFormId } from './constants.js';
import { CreateTaskTemplateFrom } from './CreateTaskTemplateFrom.js';

function CreateTaskTemplateSidebarContent({ onClose, ...props }) {
  return (
    <SidebarFormLayout
      onClose={onClose}
      formId={createTaskTemplateFormId}
      title="Create Task Template"
      submitButtonCaption="Create"
      justifyContent="flex-start"
    >
      <CreateTaskTemplateFrom onClose={onClose} {...props} />
    </SidebarFormLayout>
  );
}

CreateTaskTemplateSidebarContent.propTypes = {
  onClose: func.isRequired,
};

export const useCreateTaskTemplateSidebar = () => {
  const { openOutSidebar, closeOutSidebar } = useOutSidebarContext();

  const onCloseHandler = () => {
    closeOutSidebar(createTaskTemplateFormId);
  };

  return (duplicateTaskTemplateId = '') =>
    openOutSidebar({
      alwaysFirst: true,
      id: createTaskTemplateFormId,
      width: 625,
      content: (
        <CreateTaskTemplateSidebarContent
          duplicateTaskTemplateId={duplicateTaskTemplateId}
          onClose={onCloseHandler}
        />
      ),
    });
};
