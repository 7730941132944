import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, object } from 'prop-types';
import { mapConfigToTableProps, Table } from '@poly/admin-book';

import { UpdateMetaInfoTask } from '../../../../tables/updatesTable/UpdateMetaInfoTask.js';

const tableConfig = [['', UpdateMetaInfoTask]];

const UpdatesTableContent = mapConfigToTableProps(
  R.prop('updates'),
  tableConfig,
)(Table);

const UpdatesTableContentS = styled(UpdatesTableContent)`
  margin-top: 18px;

  thead {
    display: none;
  }
  tbody {
    overflow: auto;
  }

  thead > tr > th {
    height: 0;
  }

  tbody {
    max-height: 400px;
  }

  th:first-child,
  td:first-child {
    padding-left: 0;
    padding-right: 0;
    width: 300px;
  }

  tr {
    padding: 5px 0 !important;
    margin: 0;
    width: 100%;

    :hover {
      box-shadow: unset;
    }
  }
`;

export function UpdatesList({ updates }) {
  if (updates.length === 0) return null;

  return <UpdatesTableContentS updates={updates} minHeightLess />;
}

UpdatesList.displayName = 'UpdatesList';
// eslint-disable-next-line react/forbid-prop-types
UpdatesList.propTypes = { updates: arrayOf(object).isRequired };
