import React from 'react';
import { formatSelectOptionsByConstants } from '@poly/client-utils';
import { MWBEEthnicitiesUI } from '@poly/constants';
import { Select } from '@poly/admin-book';

export function EthnicitySelect(props) {
  return (
    <Select
      {...props}
      name="EthnicitySelect"
      placeholder="Select Ethnicity"
      options={formatSelectOptionsByConstants(MWBEEthnicitiesUI)}
    />
  );
}

EthnicitySelect.displayName = 'EthnicitySelect';
