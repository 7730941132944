import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { defaultTheme, getThemeColor, Icon, Table } from '@poly/admin-book';

export const SidebarTabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 120px);
  padding-top: 15px;
  overflow-y: auto;
`;

export const SidebarTabHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 24px;
`;

export const SidebarTabTitle = styled.div`
  font-size: 18px;
  line-height: 22px;
`;

export const SidebarTabToolbar = styled(SidebarTabHeader)`
  padding: 6px 24px 14px 24px;

  > div:first-child {
    width: 192px;

    input {
      width: 170px;
    }
  }
`;

export const SidebarTabToolbarButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SidebarTabTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - ${R.propOr(0, 'offset')}px);
  position: relative;
  overflow: hidden;
`;

export const CommonSidebarTabTable = styled(Table)`
  thead {
    border-top: 1px solid ${getThemeColor(['midLight'])};
  }

  tr {
    padding: 0 24px !important;
  }

  th {
    font-size: 10px;
    line-height: 12px;
  }

  td:first-child,
  th:first-child {
    padding-left: 0;
  }

  td:last-child,
  th:last-child {
    padding-right: 0;
  }
`;

export const RemoveIcon = styled(Icon).attrs(() => ({
  name: 'delete',
  size: 10,
  color: defaultTheme.colors.primaryLight,
}))``;

export const oneRowSidebarTabTableStyles = css`
  > tbody {
    overflow: visible;
    vertical-align: middle;
  }

  tr {
    margin: 0;
    width: 100%;
    padding: 0 17px 0 14px;

    :last-child td {
      padding-bottom: 10px;
    }
  }

  td {
    height: 42px;
  }
`;
