import { useMutation, gql } from '@apollo/client';

const DELETE_PROCEDURE_MUTATION = gql`
  mutation DELETE_PROCEDURE_MUTATION($id: ID!) {
    deleteProcedure(id: $id) {
      _id
    }
  }
`;

export const useDeleteProcedureMutation = () => {
  const [deleteProcedure] = useMutation(DELETE_PROCEDURE_MUTATION);

  const mutation = async (id) => {
    await deleteProcedure({ variables: { id } });
  };

  return mutation;
};
