import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AddFilesForm as CommonAddFilesForm,
  closeCurrentModal,
  useProcessState,
} from '@poly/admin-ui';
import { addFilesFormId } from './constants.js';
import { ModalFormWrapper } from '../../modals/ModalFormWrapper.js';

function AddFilesForm(props) {
  const dispatch = useDispatch();

  const { payload, ...rest } = useSelector((state) => state.modal);

  const onCancel = () => {
    dispatch(closeCurrentModal());
  };

  return (
    <CommonAddFilesForm
      {...props}
      {...payload}
      {...rest}
      successMessage="File was successfully added"
      formId={addFilesFormId}
      onCancel={onCancel}
    />
  );
}

export function AddFilesModal() {
  const { process } = useProcessState(addFilesFormId);

  return (
    <ModalFormWrapper
      actionInProgress={process}
      title="Add Files"
      buttonLabel="Save"
      formId={addFilesFormId}
      Form={AddFilesForm}
    />
  );
}
