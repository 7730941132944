import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import { setProcesses as setProcessesAction } from '../redux/processes.js';

export const useProcessState = (processName) => {
  const dispatch = useDispatch();
  const processes = useSelector(R.propOr({}, 'processes'));

  const setProcesses = (process) => dispatch(setProcessesAction(process));

  return {
    processes,
    setProcesses,
    process: (!!processName && processes[processName]) || false,
  };
};
