import React from 'react';
import { object } from 'prop-types';
import { TableCard } from '@poly/admin-book';
import { TablePagination } from '@poly/admin-ui';
import { TableWithPaginationContainer } from '@poly/admin-book/src/Card.js';

import { MentionGroupsTable } from '../../tables/mentionGroupsTable/index.js';

export function MentionGroupsTabComp({ query }) {
  return (
    <TableCard>
      <TableWithPaginationContainer paginationVisible>
        <MentionGroupsTable {...{ query }} />
      </TableWithPaginationContainer>
      <TablePagination />
    </TableCard>
  );
}

MentionGroupsTabComp.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  query: object.isRequired,
};
