import React from 'react';
import { useOutSidebarContext } from '@poly/client-utils';
import { CommonSidebarFormWidth } from '@poly/admin-ui';

import {
  SidebarLayoutS,
  SidebarFormLayoutS,
} from './components/userGroupFormComponents.js';
import { editUserGroupFormId } from '../constants.js';
import { EditUserGroupForm } from './EditUserGroupForm.js';

function EditUserGroupSidebarFrom(props) {
  return (
    <SidebarFormLayoutS
      title="Edit User Group"
      submitButtonCaption="Save"
      formId={editUserGroupFormId}
    >
      <EditUserGroupForm {...props} />
    </SidebarFormLayoutS>
  );
}

export const useOpenEditUserGroupSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();

  return (formProps) =>
    openOutSidebar({
      alwaysFirst: true,
      Layout: SidebarLayoutS,
      id: editUserGroupFormId,
      width: CommonSidebarFormWidth,
      content: <EditUserGroupSidebarFrom {...formProps} />,
    });
};
