import * as R from 'ramda';
import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { mailToInitValues } from '@poly/admin-ui';
import { InvoiceTypes, ProjectSupplierStatus } from '@poly/constants';
import { propEqLegacy } from '@poly/utils';

import { Loader } from '@poly/admin-book';
import { shape, string } from 'prop-types';
import {
  SupplierInvoiceFormActions,
  supplierInvoiceFormId,
} from '../../../modules/forms/supplierInvoiceForm/constants.js';
import { PROJECT_DETAILS } from '../../../modules/core/hooks/projects/queries.js';
import {
  getCommonProjectFields,
  prepareDataToMutation,
} from '../../../modules/forms/supplierInvoiceForm/supplierInvoiceFormUtils.js';
import { projectTaskCompleteCache } from '../../../modules/forms/utils.js';
import { Form } from '../../../modules/forms/supplierInvoiceForm/supplierInvoiceForm.js';

// eslint-disable-next-line import/no-unused-modules
export const CREATE_INVOICE = gql`
  mutation CREATE_INVOICE($input: InvoiceCreateInput!) {
    createInvoice(input: $input) {
      invoice {
        _id
      }
    }
  }
`;

// prepareDataToAddMutation :: FormData -> InvoiceCreateInput
const prepareDataToAddMutation = R.compose(
  R.omit(['supplierRating']),
  prepareDataToMutation,
);
// getInitialValues :: Object -> Object
const getInitialValues = ({ taskSupplier, project }) => ({
  taskSupplier,
  paidAmount: 0,
  invoiceFile: [],
  invoiceDate: new Date().toISOString(),
  emailTo: mailToInitValues,
  ...getCommonProjectFields(project),
  type: InvoiceTypes.breakdown,
  supplierId: R.compose(
    R.cond([
      [() => !!taskSupplier, R.always(R.prop('_id', taskSupplier))],
      [R.compose(R.equals(1), R.length), R.compose(R.prop('_id'), R.head)],
      [R.T, R.always(null)],
    ]),
    R.filter(propEqLegacy('statusInProject', ProjectSupplierStatus.assigned)),
    R.propOr([], 'suppliers'),
  )(project),
  formAction: SupplierInvoiceFormActions.add,
});

const useCommonAddSupplierInvoiceLogic = ({
  projectId,
  project,
  taskId,
  taskSupplier,
}) => {
  const [mutate] = useMutation(CREATE_INVOICE, {
    alias: 'useCreateInvoice',
  });

  const initialValues = getInitialValues({ taskSupplier, project });

  return {
    createInvoice: (input) =>
      mutate({
        variables: { input: prepareDataToAddMutation(input) },
        update: projectTaskCompleteCache(projectId, taskId),
      }),
    submitCaption: 'Add Invoice',
    getSuccessMessage: () => 'Invoice was added',
    initialValues,
  };
};

function AddSupplierInvoiceComponent({
  projectId,
  taskId,
  taskSupplier,
  ...restProps
}) {
  const { data, loading } = useQuery(PROJECT_DETAILS, {
    variables: { id: projectId },
  });

  const { createInvoice, submitCaption, getSuccessMessage, initialValues } =
    useCommonAddSupplierInvoiceLogic({
      projectId,
      project: data?.project,
      taskId,
      taskSupplier,
    });

  if (loading) return <Loader />;

  return (
    <Form
      {...restProps}
      {...data}
      mutate={createInvoice}
      projectId={projectId}
      initialValues={initialValues}
      submitCaption={submitCaption}
      formId={supplierInvoiceFormId}
      getSuccessMessage={getSuccessMessage}
    />
  );
}

AddSupplierInvoiceComponent.propTypes = {
  projectId: string,
  taskId: string,
  taskSupplier: shape({
    company: shape({
      _id: string,
    }),
  }),
};

export function AddSupplierInvoiceForm({ entity: { _id }, ...restProps }) {
  return _id ? (
    <AddSupplierInvoiceComponent {...restProps} projectId={_id} />
  ) : null;
}

AddSupplierInvoiceForm.displayName = 'AddSupplierInvoiceForm';

AddSupplierInvoiceForm.propTypes = {
  entity: shape({ _id: string.isRequired }),
};
