import * as R from 'ramda';
import styled, { css } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { arrayOf, shape, object, oneOfType, string } from 'prop-types';
import { ReadOnlyEditor, getThemeColor, Text } from '@poly/admin-book';
import { InfoPopover, EmailLink, PhoneLink } from '@poly/admin-ui';
import { NOTHING_UI_STRING } from '@poly/constants';
import { isNilOrEmpty } from '@poly/utils';

import { formatAccountOptionText } from '../../components/AccountsSelect.js';
import { useSidebarLogicContext } from '../SidebarLogicContext.js';

export const defaultTextProps = { size: '12px', lineHeight: '18px' };

// isNotEmptyArray :: Any -> Boolean
const isNotEmptyArray = R.both(R.is(Array), R.complement(R.isEmpty));

// getExpiredColor :: Object -> StyleSheet
const getExpiredColor = R.when(
  R.prop('isProjectExpired'),
  R.compose(R.concat('color: '), getThemeColor(['secondaryMid'])),
);

const ExpiredText = styled.span`
  ${getExpiredColor};
`;

export const ReadOnlyEditorS = styled(ReadOnlyEditor)`
  .ql-container,
  .ql-blank:before {
    font-size: 12px;
  }

  .ql-editor {
    max-height: inherit;
    padding: 0;
  }
`;

const ShowMoreWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  max-height: ${({ showMore }) => (showMore ? 'auto' : '90px')};
  overflow: hidden;

  > div:first-child {
    width: 100%;
  }
`;

const ShowMoreButton = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 12px;
  line-height: 18px;
  color: ${getThemeColor(['primaryLight'])};
  background-color: ${getThemeColor(['white'])};
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) -15%,
    rgba(255, 255, 255, 1) 28%,
    rgba(255, 255, 255, 1) 99%
  );
  padding-left: 30px;
  cursor: pointer;
`;

const textStyles = css`
  font-size: 12px;
  line-height: 18px;
`;

const OutsideLink = styled.a`
  ${textStyles};
`;

// getStartEndDateSection :: (Object, Object) -> _ -> ReactNode
export const getStartEndDateSection = (
  { startDate, endDate, isProjectExpired },
  textProps,
) =>
  function (props) {
    return (
      <Text {...{ ...props, ...textProps }}>
        {R.concat(startDate, ' - ')}
        <ExpiredText {...{ isProjectExpired }}>{endDate}</ExpiredText>
      </Text>
    );
  };

// getSectionText :: (String, Object) -> _ -> ReactNode
export const getSectionText = (text, textProps = defaultTextProps) =>
  function (props) {
    return <Text {...{ ...props, ...textProps }}>{text}</Text>;
  };

// getSectionPhoneLink :: (String, String) -> _ -> ReactNode
export const getSectionPhoneLink = (number, ext) =>
  function (props) {
    return <PhoneLink {...props} number={number} ext={ext} />;
  };

// getSectionSidebarLink :: (Object, ReactNode) -> _ -> ReactNode
export const getSectionSidebarLink = (linkProps, SidebarLink) =>
  function (props) {
    return <SidebarLink {...{ ...props, ...linkProps }} />;
  };

// userExists :: User -> Boolean
const userExists = R.has('_id');

// getContactLink :: User -> _ -> ReactNode
export const getContactLink = (user) =>
  function (props) {
    const { ContactLink } = useSidebarLogicContext();

    return userExists(user) ? <ContactLink {...props} _id={user._id} /> : null;
  };

// getSectionContact :: (User, Boolean) -> _ -> ReactNode
export const getSectionContact = (user, isPopover) =>
  function (props) {
    const PopoverParent = getContactLink(user);

    return R.is(String, user) ? (
      <Text {...{ ...props, ...defaultTextProps }}>{user}</Text>
    ) : (
      <InfoPopover
        {...{ ...props, ...user, isPopover, PopoverParent }}
        position="bottom"
      />
    );
  };

function SectionEditor({ invoiceDescription, ...props }) {
  const { id } = props;
  const [showMore, setShowMore] = useState(false);
  const [editorHeight, setEditorHeight] = useState(0);

  useEffect(() => {
    const height = document.getElementById(id)
      ? document.getElementById(id).offsetHeight
      : 0;

    const timeout = setTimeout(() => setEditorHeight(height), 300);

    if (setEditorHeight) {
      setEditorHeight(height);
    }
    return () => clearTimeout(timeout);
  }, []);

  return (
    <ShowMoreWrapper showMore={showMore}>
      <ReadOnlyEditorS value={invoiceDescription} {...props} />
      {editorHeight > 90 ? (
        <ShowMoreButton onClick={() => setShowMore(!showMore)}>
          {showMore ? 'Show less' : 'Show more'}
        </ShowMoreButton>
      ) : null}
    </ShowMoreWrapper>
  );
}

SectionEditor.propTypes = {
  id: string.isRequired,
  invoiceDescription: oneOfType([string, object]),
};

// getSectionEditor :: InvoiceDescription -> _ -> ReactNode
export const getSectionEditor = (invoiceDescription) =>
  function (props) {
    return <SectionEditor invoiceDescription={invoiceDescription} {...props} />;
  };

// getSectionOutsideLink :: (String, String) -> _ -> ReactNode
export const getSectionOutsideLink = (link, holder) =>
  function (props) {
    if (!link && !holder) {
      return (
        <Text {...{ ...props, ...defaultTextProps }}>{NOTHING_UI_STRING}</Text>
      );
    }

    if (!link && !!holder) {
      return <Text {...{ ...props, ...defaultTextProps }}>{holder}</Text>;
    }

    return (
      <OutsideLink
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
      >
        {holder || link}
      </OutsideLink>
    );
  };

// getSectionEmail :: String -> _ -> ReactNode
export const getSectionEmail = (email) =>
  function (props) {
    return <EmailLink email={email} {...props} />;
  };

// getProjectDivisionAccount :: Project -> String
const getProjectDivisionAccount = R.compose(
  R.ifElse(isNilOrEmpty, R.always(NOTHING_UI_STRING), formatAccountOptionText),
  R.ifElse(
    R.hasPath(['division', '_id']),
    R.prop('division'),
    R.pathOr({}, ['client', 'financial', 'division']),
  ),
);

// getSectionDivision :: Project ->  _ -> ReactNode
export const getSectionDivision = (project) =>
  function (props) {
    const division = getProjectDivisionAccount(project);

    return <Text {...{ ...props, ...defaultTextProps }}>{division}</Text>;
  };

const ClientManagersWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

function ClientManagersColumn({ managers, ...props }) {
  const { ContactLink } = useSidebarLogicContext();

  return isNotEmptyArray(managers) ? (
    <ClientManagersWrapper {...props}>
      {managers.map((item) => (
        <ContactLink key={item._id} {...item} />
      ))}
    </ClientManagersWrapper>
  ) : (
    getSectionText(NOTHING_UI_STRING)(props)
  );
}

ClientManagersColumn.propTypes = {
  managers: arrayOf(
    shape({
      _id: string.isRequired,
      fullName: string.isRequired,
    }),
  ),
};

// getSectionClientManagers :: [User] -> _ -> ReactNode
export const getSectionClientManagers = (managers) =>
  function (props) {
    return <ClientManagersColumn managers={managers} {...props} />;
  };
