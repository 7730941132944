import * as R from 'ramda';
import { WorkOrderStatus } from '@poly/constants';
import { ALL } from '@poly/admin-ui/src/constants/general.js';
import { propEqLegacy } from '@poly/utils';

import { customTaskTypeOption, searchFiltersNames } from './constants.js';

// generateTasksInput :: Object -> TasksInput
export const generateTasksInput = R.compose(
  R.reject(R.isNil),
  R.mergeRight({
    complete: false,
    notProjectStatus: [WorkOrderStatus.BLOCKED],
  }),
  R.mergeAll,
  R.juxt([
    R.applySpec({
      dueDayStatus: R.prop(searchFiltersNames.dueDate),
      managerId: R.prop(searchFiltersNames.manager),
    }),
    R.cond([
      [propEqLegacy(searchFiltersNames.taskType, ALL), R.always({})],
      [
        propEqLegacy(searchFiltersNames.taskType, customTaskTypeOption),
        R.always({ onlyCustomTasks: true }),
      ],
      [R.T, R.applySpec({ collection: R.prop(searchFiltersNames.taskType) })],
    ]),
    R.ifElse(
      propEqLegacy(searchFiltersNames.priority, ALL),
      R.always({}),
      R.applySpec({ priority: R.prop(searchFiltersNames.priority) }),
    ),
  ]),
);
