import { string, shape, arrayOf } from 'prop-types';

export const attachmentsPropTypes = arrayOf(
  shape({
    _id: string,
    fileName: string.isRequired,
    upload: shape({
      path: string.isRequired,
      name: string.isRequired,
    }),
    url: string,
  }),
).isRequired;

const mentionsShape = shape({
  usersIds: arrayOf(string),
  groupsIds: arrayOf(string),
});

export const valuePropTypes = shape({
  text: string.isRequired,
  mentions: mentionsShape,
  attachments: attachmentsPropTypes,
});
