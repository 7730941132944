import * as R from 'ramda';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { string, shape, number, arrayOf, bool, func } from 'prop-types';
import { useReactiveQuery } from '@poly/client-utils';
import { Icon, Checkbox, getThemeColor, Text } from '@poly/admin-book';
import { ServiceTypesSelect, FlexContainer } from '@poly/admin-ui';
import { propEqLegacy } from '@poly/utils';

import { DistanceSelect } from '../../DistanceSelect.js';
import { InputWrapper, SectionWrapper } from '../../../styles.js';
import { SuppliersSearchInput } from '../../SuppliersSearchInput.js';
import { SuppliersListComp } from './SuppliersList/SuppliersListComp.js';
import { distanceOptionsValues, NO_RADIUS_VALUE } from '../../../constants.js';
import { SUPPLIER_SEARCH_CHANGED } from '../../../../../core/hooks/suppliers/subscriptions.js';
import { SUPPLIERS_BY_SEARCH_FOR_PROJECT_ASSIGN } from '../../../../../core/hooks/suppliers/queries.js';
import { getAssignSupplierSearchInput } from '../../../../../core/searchQueries/suppliers.js';
import { isAssignSupplierFormType } from '../../../utils.js';
import { assignSupplierFormTypePropTypes } from '../../../propTypes.js';

const CheckBoxesWrapper = styled(FlexContainer)`
  width: calc(100% - 70px);
  margin: 0 46px 0 24px;
  padding: 0 0 24px 0;
  border-bottom: 1px solid ${getThemeColor(['midLight'])};
`;

const SearchSupplierWrapper = styled.div`
  position: relative;

  input {
    padding-right: 35px;
  }
`;

const SearchIcon = styled(Icon)`
  position: absolute;
  top: 10px;
  right: 10px;
  margin-bottom: 0;
`;

const NoSuppliersMessage = styled.div`
  padding: 40px 0;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  align-self: center;
`;

// getSuppliers :: SearchSuppliers -> [Suppliers]
const getSuppliers = R.curry((suppliers, selectedSupplierId) =>
  R.compose(
    R.when(
      R.always(selectedSupplierId),
      R.filter(propEqLegacy('_id', selectedSupplierId)),
    ),
    R.pathOr([], ['searchSuppliers', 'hits']),
  )(suppliers),
);

const CheckboxS = styled(Checkbox)`
  margin: 0 10px 0 0;
`;

export function SuppliersListWithSearch({
  formType,
  isFormReset,
  entitySupplierIds,
  selectedSupplierId,
  noSupplierRequired,
  propertyCoordinates,
  setNoSupplierRequired,
  alreadyUsedSupplierIds,
}) {
  const initDistance = propertyCoordinates
    ? R.head(distanceOptionsValues)
    : NO_RADIUS_VALUE;

  const [distance, setDistance] = useState(initDistance);
  const [previouslyUsedLocation, setPreviouslyUsedLocation] = useState(true);
  const [serviceId, setServiceId] = useState('');
  const [searchText, setSearchText] = useState('');
  const [cache, setCache] = useState({});

  useEffect(() => {
    setDistance(initDistance);
    setServiceId('');
    setSearchText('');
    setPreviouslyUsedLocation(true);
    setNoSupplierRequired(false);
  }, [isFormReset]);

  const queryOptions = {
    variables: {
      searchInput: {
        searchTerm: searchText,
        ...getAssignSupplierSearchInput({
          distance,
          serviceId,
          entitySupplierIds,
          propertyCoordinates,
          alreadyUsedSupplierIds,
          previouslyUsedLocation,
          selectedSupplierId,
          searchText,
        }),
      },
    },
  };

  const { data } = useReactiveQuery(
    SUPPLIERS_BY_SEARCH_FOR_PROJECT_ASSIGN,
    SUPPLIER_SEARCH_CHANGED,
    { queryOptions, subscriptionOptions: {} },
  );

  useEffect(() => {
    if (data) {
      setCache(data);
    }
  }, [data]);

  const suppliers = getSuppliers(cache, selectedSupplierId);

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <>
      <CheckBoxesWrapper>
        {!noSupplierRequired && (
          <CheckboxS
            label="Previously Used Location"
            name="previouslyUsedLocation"
            value={previouslyUsedLocation}
            onChange={setPreviouslyUsedLocation}
            dataTestId="previouslyUsedLocationDataTestId"
          />
        )}
        {isAssignSupplierFormType(formType) && (
          <CheckboxS
            label="No Supplier Required"
            name="noSupplierRequired"
            value={noSupplierRequired}
            onChange={setNoSupplierRequired}
            dataTestId="noSupplierRequiredDataTestId"
          />
        )}
      </CheckBoxesWrapper>
      {!noSupplierRequired && (
        <SectionWrapper>
          <InputWrapper flex={1} margin={0}>
            <Text>Distance (mi)</Text>
            <DistanceSelect
              disabled={!propertyCoordinates}
              value={distance}
              onChange={setDistance}
              onKeyDown={onKeyDown}
              required
            />
          </InputWrapper>
          <InputWrapper flex={2}>
            <Text>Service Type</Text>
            <ServiceTypesSelect
              width="100%"
              isClearable
              value={serviceId}
              onChange={setServiceId}
              name="serviceTypesSelect"
              customIcon={<Icon name="search" />}
              placeholder="Enter service type..."
              onKeyDown={onKeyDown}
            />
          </InputWrapper>
          <InputWrapper flex={2}>
            <Text>Search Supplier Name</Text>
            <SearchSupplierWrapper>
              <SuppliersSearchInput
                value={searchText}
                onChange={setSearchText}
                disabled={false}
                onKeyDown={onKeyDown}
              />
              <SearchIcon name="search" size={13} color="#9a9da0" />
            </SearchSupplierWrapper>
          </InputWrapper>
        </SectionWrapper>
      )}

      {R.isEmpty(suppliers) && !noSupplierRequired ? (
        <NoSuppliersMessage>
          This location has no previously used suppliers
        </NoSuppliersMessage>
      ) : (
        !noSupplierRequired && (
          <SuppliersListComp
            suppliers={suppliers}
            propertyCoordinates={propertyCoordinates}
          />
        )
      )}
    </>
  );
}

SuppliersListWithSearch.propTypes = {
  isFormReset: bool.isRequired,
  selectedSupplierId: string,
  alreadyUsedSupplierIds: arrayOf(string),
  propertyCoordinates: shape({
    lat: number.isRequired,
    lon: number.isRequired,
  }),
  entitySupplierIds: arrayOf(string),
  noSupplierRequired: bool.isRequired,
  setNoSupplierRequired: func.isRequired,
  formType: assignSupplierFormTypePropTypes.isRequired,
};
