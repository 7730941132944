import * as R from 'ramda';
import { removePropDeep } from '@poly/utils/src/objects.js';
import { ensureIsDate } from '@poly/utils/src/dates.js';

// prepareReportSendingConfigurationInput :: FromData -> ReportSendingConfigurationInput
export const prepareReportSendingConfigurationInput = R.applySpec({
  emails: R.compose(R.map(R.prop('value')), R.propOr([], 'emails')),
  schedule: R.prop('schedule'),
  reportFilter: {
    ageFrom: R.pathOr(null, ['reportFilter', 'ageFrom']),
    ageTo: R.pathOr(null, ['reportFilter', 'ageTo']),
  },
  startDate: R.propOr(null, 'startDate'),
  endDate: R.propOr(null, 'endDate'),
});

// getReportSendingConfigurationInitialValues :: QueryData -> ReportSendingConfiguration
export const getReportSendingConfigurationInitialValues = R.compose(
  R.applySpec({
    schedule: R.prop('schedule'),
    reportFilter: R.prop('reportFilter'),
    emails: R.compose(
      R.map(R.applySpec({ value: R.identity, label: R.identity })),
      R.propOr([], 'emails'),
    ),
    startDate: R.compose(ensureIsDate, R.prop('startDate')),
    endDate: R.compose(ensureIsDate, R.prop('endDate')),
  }),
  removePropDeep('__typename'),
  R.propOr({}, 'reportSendingConfiguration'),
);
