import * as R from 'ramda';
import { string } from 'prop-types';
import React, { useState, useContext, useCallback } from 'react';
import { commonSortQuery, keywordSortQuery } from '@poly/client-utils';
import {
  centsToDollarsWithFormat,
  debounce,
  formatDate,
  formatTotal,
} from '@poly/utils';
import { MoneyInputAsCents, FlexColumn, FlexContainer } from '@poly/admin-ui';
import styled from 'styled-components';
import { Popover, Icon, getThemeColor } from '@poly/admin-book';

import { SuggestedInvoiceAmountContext } from './useSuggestedInvoiceAmountLogic.js';
import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import { getProjectWoCharge } from './helpers.js';
import {
  getProjectInvoiceAmountErrorMessage,
  isProjectInvoiceAmountInvalid,
} from '../BatchInvoicingTM/batchInvoicingUtils.js';

const DescriptionWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

function Description({ description }) {
  return <DescriptionWrapper>{description}</DescriptionWrapper>;
}

Description.propTypes = {
  description: string,
};

const ErrorMsgContainer = styled(FlexColumn)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 5px;
  color: ${getThemeColor(['accent'])};
  font-size: 10px;
`;

const PopoverWithMargin = styled(Popover)`
  margin-right: 5px;
`;

function InvoiceErrorTooltip(project) {
  return (
    <PopoverWithMargin
      maxWidth="150px"
      position="left"
      title={<Icon name="yellowWarning" size={16} />}
      content={
        <ErrorMsgContainer>
          {getProjectInvoiceAmountErrorMessage(project)}
        </ErrorMsgContainer>
      }
    />
  );
}
function SuggestInvoiceAmountInput({ _id, ...project }) {
  const [value, setValue] = useState(
    R.propOr(0, 'suggestedClientInvoiceAmount', project),
  );
  const { updateSuggestedAmount } = useContext(SuggestedInvoiceAmountContext);

  const updateSuggestedAmountDebounce = useCallback(
    debounce(100)((amount) => updateSuggestedAmount(_id, amount)),
    [],
  );

  const onChange = (amount) => {
    setValue(amount);
    updateSuggestedAmountDebounce(amount);
  };

  return (
    <FlexContainer>
      {isProjectInvoiceAmountInvalid(project) && (
        <InvoiceErrorTooltip {...project} />
      )}
      <MoneyInputAsCents
        disabled
        allowNegative
        width="100%"
        size="small"
        value={value}
        onChange={onChange}
        name="SuggestedInvoiceAmountInput"
      />
    </FlexContainer>
  );
}

SuggestInvoiceAmountInput.propTypes = { _id: string.isRequired };

// createClientInvoiceTableConfig :: [String, Function, Function]
export const createClientInvoiceTableConfig = [
  ['Project No.', ProjectLink, commonSortQuery(['projectId'])],
  ['Project Description', Description, keywordSortQuery(['description'])],
  ['Client', R.path(['client', 'name']), keywordSortQuery(['client', 'name'])],
  [
    'Date Closed',
    R.compose(formatDate, R.prop('closedAt')),
    commonSortQuery(['closedAt']),
  ],
  ['Job Cost', R.compose(centsToDollarsWithFormat, R.propOr(0, 'jobCost'))],
  ['Work Order Charge', R.compose(formatTotal, getProjectWoCharge)],
  /* eslint-disable-next-line @cspell/spellchecker */
  ['Sugg. Inv. Amount', SuggestInvoiceAmountInput],
];
