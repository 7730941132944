import { gql, useMutation } from '@apollo/client';
import { useNotificationState } from '@poly/admin-ui';

const UNASSIGN_PROJECT_SUPPLIER = gql`
  mutation UNASSIGN_PROJECT_SUPPLIER($input: UnassignSupplierAtProjectInput!) {
    unassignSupplierAtProject(input: $input) {
      project {
        _id
      }
    }
  }
`;

export const useUnassignProjectSupplier = ({ projectId, supplierId }) => {
  const [unassignProjectSupplier] = useMutation(UNASSIGN_PROJECT_SUPPLIER);
  const { showSuccessNotification } = useNotificationState();

  return async () => {
    await unassignProjectSupplier({
      variables: { input: { projectId, supplierId } },
    });
    showSuccessNotification('Supplier was successfully unassigned');
  };
};
