import * as R from 'ramda';
import { FilePicker } from '@poly/admin-book';
import { commonFileValidators, validateFilesFunc } from '@poly/client-utils';

export const filesSections = [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        order: 1,
        field: {
          name: 'files',
          additionalProps: { dragAreaHeight: '92px' },
          Component: FilePicker,
        },
        validators: [
          R.ifElse(
            R.complement(R.isEmpty),
            R.always({}),
            R.always({ commonError: 'Attach at least one file' }),
          ),
          ...commonFileValidators,
        ],

        validateFunction: validateFilesFunc,
      },
    ],
  },
];
