import styled, { css } from 'styled-components';
import { getThemeColor } from '@poly/admin-book';

export const documentIdColumnStyles = (columnNum) => css`
  th:nth-child(${columnNum}),
  td:nth-child(${columnNum}) {
    width: 10%;
  }
`;

export const dateColumnStyles = (columnNum) => css`
  th:nth-child(${columnNum}),
  td:nth-child(${columnNum}) {
    width: 100px;
  }
`;

export const textDateColumnStyles = (columnNum) => css`
  th:nth-child(${columnNum}),
  td:nth-child(${columnNum}) {
    width: 130px;
  }
`;

export const checkBoxColumn = (columnNum) => css`
  th:nth-child(${columnNum}),
  td:nth-child(${columnNum}) {
    width: 36px;
    padding: 10px;
  }
`;

export const daysColumnStyles = (columnNum) => css`
  th:nth-child(${columnNum}),
  td:nth-child(${columnNum}) {
    width: 8%;
  }
`;

export const pipColumnStyles = (columnNum) => css`
  th:nth-child(${columnNum}),
  td:nth-child(${columnNum}) {
    width: 25px;
    padding-top: 10px;
  }

  td:nth-child(${columnNum}) {
    padding-top: 15px;
  }
`;

export const descriptionColumnStyles = (columnNum) => css`
  th:nth-child(${columnNum}),
  td:nth-child(${columnNum}) {
    width: 25%;
    overflow-wrap: break-word;
  }
`;

export const RedSpan = styled.span`
  color: ${getThemeColor(['accent'])};
`;

export const phoneColumnStyles = (columnNum) => css`
  th:nth-child(${columnNum}),
  td:nth-child(${columnNum}) {
    width: 150px;
  }
`;

export const propertyColumnStyles = (columnNum) => css`
  th:nth-child(${columnNum}),
  td:nth-child(${columnNum}) {
    width: 200px;
  }
`;
