import React from 'react';
import { Select } from '@poly/admin-book';

import { ClientAppOptions } from './entitySelectPropsByScopeMap.js';

export function DefaultLoginAppSelect(props) {
  const selectProps = {
    ...props,
    options: ClientAppOptions,
  };

  return <Select {...selectProps} placeholder="Default Login App" />;
}
