import { AssetStatuses } from '@poly/constants';
import { defaultTheme } from '@poly/admin-book';

const {
  colors: {
    statuses: { active, pending },
    projectStatuses: { inactive, blocked },
  },
} = defaultTheme;

export const assetStatusesColors = {
  [AssetStatuses.ACTIVE]: active,
  [AssetStatuses.INACTIVE]: inactive,
  [AssetStatuses.PENDING]: pending,
  [AssetStatuses.BLOCKED]: blocked,
};

export const addAssetFormId = 'add_asset_form_id';
export const editAssetFormId = 'edit_asset_form_id';
export const assetTabsSidebarId = 'asset_tabs_sidebar_id';
export const importAssetsFormId = 'import_assets_form_id';
