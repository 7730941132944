import { gql } from '@apollo/client';

export const FILES_SEARCH_CHANGED = gql`
  subscription FILES_SEARCH_CHANGED($searchInput: CollectionSearchParams!) {
    searchFileChanged(input: $searchInput) {
      id
      type
    }
  }
`;
