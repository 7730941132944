import React from 'react';

import { SidebarIDs } from '../../constants.js';
import { userGroupTabsSidebarId } from '../constants.js';
import { SidebarTabs } from '../../components/SidebarTabs.js';
import { userGroupSidebarTabs } from '../../../routes/constants.js';
import { UserGroupSidebarUsersTab } from './UserGroupSidebarUsersTab.js';
import { UserGroupSidebarUpdatesTab } from './UserGroupSidebarUpdatesTab.js';

export function UserGroupTabsSidebar(props) {
  return (
    <SidebarTabs
      id={userGroupTabsSidebarId}
      params={[SidebarIDs.userGroup]}
      defaultValue={userGroupSidebarTabs.userGroupUsers}
      tabs={[
        [
          'Users',
          userGroupSidebarTabs.userGroupUsers,
          <UserGroupSidebarUsersTab {...props} />,
        ],
        [
          'Updates',
          userGroupSidebarTabs.userGroupUpdates,
          <UserGroupSidebarUpdatesTab {...props} />,
        ],
      ]}
    />
  );
}

UserGroupTabsSidebar.displayName = 'UserGroupTabsSidebar';
