import React, { useState } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { arrayOf, bool, func, shape, string, object } from 'prop-types';
import {
  getProjectTasksInput,
  ENTITY_TASKS,
  TASK_SEARCH_CHANGED,
  getTasksSubscriptionOptionsByQueryInput,
} from '@poly/admin-ui';
import {
  paginationToQueryParams,
  useSubscriptionByChanges,
} from '@poly/client-utils';
import { useRouterParams } from '@poly/client-routing';
import { Select, Loader } from '@poly/admin-book';
import { UpdateTypes } from '@poly/constants';

import { FormWrapper } from '../common.js';
import { AddProjectUpdateForm } from './AddProjectUpdateForm.js';
import { PROJECT_DETAILS } from '../../core/hooks/projects/queries.js';
import { getUpdateTypeOptions, taskByAction } from './addProjectUpdateUtils.js';

export const updateProjectFormId = 'updateProjectFormId';

const SelectS = styled(Select)`
  width: 50%;
  margin-top: 25px;
  margin-bottom: 10px;
`;

function AddProjectUpdateFormWithSelectActionC({
  showSelectAction,
  changeUpdateType,
  options,
  projectTask,
  updateType,
  projectId,
  project,
  formId,
  onCancel,
  width,
  updateCacheParams,
}) {
  const selectProps = {
    options,
    required: true,
    name: 'updateType',
    value: updateType,
    onChange: changeUpdateType,
  };

  return (
    <FormWrapper width={width}>
      {showSelectAction && <SelectS {...selectProps} />}
      <AddProjectUpdateForm
        {...{
          key: updateType,
          projectTask,
          project,
          updateType,
          formId,
          projectId,
          onCancel,
          updateCacheParams,
        }}
      />
    </FormWrapper>
  );
}

AddProjectUpdateFormWithSelectActionC.propTypes = {
  showSelectAction: bool.isRequired,
  changeUpdateType: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  options: arrayOf(object).isRequired,
  projectTask: shape({
    action: string,
    description: string.isRequired,
  }),
  updateType: string,
  project: shape({
    _id: string.isRequired,
    workCompletionDate: string,
    aacManagerId: string,
  }).isRequired,
  formId: string.isRequired,
  projectId: string.isRequired,
  onCancel: func.isRequired,
  width: string,
  updateCacheParams: shape({
    // eslint-disable-next-line react/prop-types,react/forbid-prop-types
    query: object,
  }),
};

// getTasksSubscriptionOptionsByProps :: {tasksInput: TasksInput}
//   -> ApolloOptions
const getTasksSubscriptionOptionsByProps = R.compose(
  getTasksSubscriptionOptionsByQueryInput,
  R.prop('tasksInput'),
);

// getOptions :: Object -> Object
const getOptions = R.compose(
  R.apply(getUpdateTypeOptions),
  R.props(['project', 'tasks']),
);

// getProjectTasks :: Object -> Object
const getProjectTasks = R.compose(
  R.apply(taskByAction),
  R.props(['updateType', 'tasks']),
);

function AddProjectUpdateFormWithSelectActionBase({
  action = UpdateTypes.INTERNAL,
  showSelectAction = true,
  title = 'Add Update',
  submitCaption = 'Add Update',
  successMessage = 'Update was created',
  project,
  formId,
  ...restProps
}) {
  const [updateType, setUpdateType] = useState(action);

  const pagination = { itemsPerPage: 10, currentPage: 1 };
  const tasksInput = getProjectTasksInput(project?._id, true);

  const { data, loading, refetch } = useQuery(ENTITY_TASKS, {
    variables: {
      input: {
        ...tasksInput,
        ...paginationToQueryParams(pagination),
      },
    },
    fetchPolicy: 'network-only',
  });

  useSubscriptionByChanges({
    gqlQueryChanged: TASK_SEARCH_CHANGED,
    extractQueryParamsFromProps: getTasksSubscriptionOptionsByProps,
    refetch,
    result: { ...data, tasksInput },
  });

  const tasks = data?.tasks?.hits || [];

  if (loading || !tasks.length) return <Loader />;

  const options = getOptions({ project, tasks });

  const projectTask = getProjectTasks({ updateType, tasks });

  const formIdValue = formId || updateProjectFormId;

  return (
    <AddProjectUpdateFormWithSelectActionC
      {...restProps}
      options={options}
      projectTask={projectTask}
      projectId={project._id}
      formId={formIdValue}
      project={project}
      updateType={updateType}
      changeUpdateType={setUpdateType}
      showSelectAction={showSelectAction}
      title={title}
      submitCaption={submitCaption}
      successMessage={successMessage}
    />
  );
}

AddProjectUpdateFormWithSelectActionBase.displayName =
  'AddProjectUpdateFormWithSelectAction';

AddProjectUpdateFormWithSelectActionBase.propTypes = {
  action: string,
  showSelectAction: bool,
  title: string,
  submitCaption: string,
  successMessage: string,
  formId: string,
  project: shape({ _id: string }),
};

export function AddProjectUpdateFormWithSelectAction({ projectId, ...props }) {
  const routerParams = useRouterParams(['projectId']);
  const variables = projectId
    ? { id: projectId }
    : { projectId: routerParams.projectId };
  const { data, loading } = useQuery(PROJECT_DETAILS, { variables });
  const project = R.prop('project', data);
  return loading || !project ? (
    <Loader />
  ) : (
    <AddProjectUpdateFormWithSelectActionBase project={project} {...props} />
  );
}

AddProjectUpdateFormWithSelectAction.propTypes = {
  projectId: string.isRequired,
  width: string,
};
