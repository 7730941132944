import React, { useContext, createContext } from 'react';
import {
  keywordSortQuery,
  useHighlightMatchesBySearch,
} from '@poly/client-utils';
import * as R from 'ramda';
import { element, string } from 'prop-types';
import { Dot, LinkButton, Loader } from '@poly/admin-book';
import { useServiceTypesBySearchTable } from '../../../hooks/serviceTypes/index.js';
import { useSetItemsCount } from '../../../hooks/useSetItemsCount.js';
import { useTableSorting } from '../../../hooks/useTableSorting.js';
import { useMapConfigToTableProps } from '../../../hooks/useMapConfigToTableProps.js';
import { useSetInitialPaginationOnMount } from '../../../hooks/useSetInitialPaginationOnMount.js';
import { serviceTypesStatusesColors } from '../../../constants/index.js';
import { serviceTypesByStatusQuery } from '../../../searchQueries/serviceTypes.js';
import { TableS } from './ServiceTypesTableStyles.js';

export const ServiceTypesContext = createContext({});

function ServiceTypesStatus({ status }) {
  return <Dot color={serviceTypesStatusesColors[status]} />;
}

ServiceTypesStatus.propTypes = {
  status: string,
};

function EditServiceTypeBtn(props) {
  const { onEditServiceType, ...restContextProps } =
    useContext(ServiceTypesContext);

  return (
    <LinkButton
      onClick={() => onEditServiceType({ ...restContextProps, ...props })}
    >
      Edit
    </LinkButton>
  );
}

const serviceTypesTableConfig = [
  ['', ServiceTypesStatus],
  ['Service Type', R.prop('name'), keywordSortQuery(['name'])],
  ['Description', R.prop('description')],
  ['', EditServiceTypeBtn],
];

// getServiceTypesTotalCount :: SearchServiceTypesQueryResult -> Number
const getServiceTypesTotalCount = R.pathOr(0, ['searchServiceTypes', 'total']);

// getServiceTypes :: SearchServiceTypesQueryResult -> [ServiceType]
const getServiceTypes = R.path(['searchServiceTypes', 'hits']);

export function ServiceTypesTable({ status, ...restProps }) {
  useSetInitialPaginationOnMount();

  const { supplierSource, ...restContextProps } =
    useContext(ServiceTypesContext);

  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: serviceTypesTableConfig,
    column: 2,
  });

  const { loading, result } = useServiceTypesBySearchTable({
    query: serviceTypesByStatusQuery(status, supplierSource),
    sort,
  });

  useSetItemsCount(getServiceTypesTotalCount, result);

  const { rows, ...tableProps } = useMapConfigToTableProps(
    getServiceTypes,
    serviceTypesTableConfig,
    result,
  );

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    [['name'], ['description']],
    rows,
  );

  if (loading) return <Loader />;

  return (
    <TableS
      {...restProps}
      {...tableSortingProps}
      {...restContextProps}
      {...tableProps}
      rows={highlightedRows}
    />
  );
}

ServiceTypesTable.displayName = 'ServiceTypesTable';

ServiceTypesTable.propTypes = {
  toolBar: element,
  status: string,
};
