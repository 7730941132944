import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import { validatePaySupplierInvoice } from './paySuppliersUtils/validationUtils.js';
import { setUpdatedInvoice, setAllInvoices } from '../../redux/invoices.js';
import { isAllInvoicesSelected } from './paySuppliersUtils/payInvoicesUtils.js';

const initialInvoiceValues = {
  paidAmount: '',
  deductionsAmount: '',
  discountAccountId: '',
};

const toZeroDefault = R.defaultTo(0);

export const useInvoicesHandlers = () => {
  const dispatch = useDispatch();
  const invoices = useSelector(R.prop('invoices'));
  const onSelectAll = (isSelected) => {
    const selectedInvoices = invoices.map((invoice) => ({
      ...invoice,
      ...(isSelected
        ? {
            paidAmount:
              invoice.currentBalance - toZeroDefault(invoice.deductionsAmount),
          }
        : initialInvoiceValues),
      isSelected,
    }));
    dispatch(setAllInvoices(selectedInvoices));
  };

  const isAllSelected = isAllInvoicesSelected(invoices);

  const rows = invoices.map((invoice, index) => {
    const onChangeInvoiceField = (fieldName) => (value) =>
      dispatch(
        setUpdatedInvoice({
          index,
          item: {
            ...invoice,
            [fieldName]: value,
          },
        }),
      );
    const changePayment = onChangeInvoiceField('paidAmount');
    const changeDeductions = onChangeInvoiceField('deductionsAmount');
    const changeBalance = onChangeInvoiceField('balanceAmount');
    const changeDiscountAccountId = onChangeInvoiceField('discountAccountId');
    const selectInvoice = (update) =>
      dispatch(
        setUpdatedInvoice({
          index,
          item: { ...invoice, ...update },
        }),
      );
    const dropdownOrientation = 'down';

    return {
      ...invoice,
      popoverPosition: index < 3 ? 'left' : 'top',
      dropdownOrientation,
      error: validatePaySupplierInvoice(invoice),
      changePayment,
      changeDeductions,
      changeBalance,
      selectInvoice,
      changeDiscountAccountId,
      onChangeInvoiceField,
    };
  });

  return {
    onSelectAll,
    isAllSelected,
    rows,
  };
};
