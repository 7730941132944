import React, { useEffect } from 'react';
import * as R from 'ramda';
import { func, bool, string, object } from 'prop-types';
import styled from 'styled-components';
import { TwoFACodeInput } from '@poly/client-utils';

import {
  ErrorText,
  Footer,
  LoginButton,
  LoginPageLayout,
} from './Components.js';
import { getFormValue } from './ChangePasswordPageBase/ChangePasswordForm.js';
import { ButtonLoader } from './Loader.js';

const GoToPasswordS = styled.span`
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #bcbcbc;
  cursor: pointer;
  margin: 0 20px 15px 20px;
`;

const EmailLoginInfo = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #78828c;
  width: 100%;
`;

function Form({
  loading,
  onSubmit,
  errorMsg,
  submitBtnText,
  goBack,
  email,
  title,
  hideLogo,
  className,
}) {
  return (
    <LoginPageLayout
      className={className}
      onSubmit={onSubmit}
      title={title}
      hideLogo={hideLogo}
    >
      {email && <EmailLoginInfo>{`Logging in as ${email}`}</EmailLoginInfo>}
      {errorMsg && <ErrorText>{errorMsg}</ErrorText>}
      <TwoFACodeInput />
      <Footer>
        <GoToPasswordS onClick={goBack}>Back to password</GoToPasswordS>
        <LoginButton type="submit" disabled={loading}>
          {loading && <ButtonLoader />}
          {submitBtnText}
        </LoginButton>
      </Footer>
    </LoginPageLayout>
  );
}

Form.propTypes = {
  onSubmit: func.isRequired,
  loading: bool.isRequired,
  errorMsg: string,
  submitBtnText: string,
  goBack: func.isRequired,
  email: string,
  title: string,
  hideLogo: bool,
  className: string,
};

export function TwoFAConfirmForm({
  credentials,
  errorMsg,
  setErrorMsg,
  handleSubmit,
  submitBtnText,
  goBack,
  loading,
  title,
  hideLogo,
  className,
}) {
  const email = R.propOr('', 'email', credentials);

  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit({ ...credentials, code: getFormValue('code', event) });
  };

  useEffect(() => {
    setErrorMsg('');
  }, []);

  return (
    <Form
      className={className}
      render={Form}
      onSubmit={onSubmit}
      errorMsg={errorMsg}
      goBack={goBack}
      submitBtnText={submitBtnText}
      loading={loading}
      email={email}
      title={title}
      hideLogo={hideLogo}
    />
  );
}

TwoFAConfirmForm.propTypes = {
  handleSubmit: func.isRequired,
  errorMsg: string,
  submitBtnText: string.isRequired,
  goBack: func.isRequired,
  setErrorMsg: func.isRequired,
  /* eslint-disable react/forbid-prop-types */
  credentials: object.isRequired,
  loading: bool.isRequired,
  title: string,
  hideLogo: bool,
  className: string,
};
