import React from 'react';
import { OldVersionDetector } from '@poly/admin-ui/src/components/OldVersionDetector.js';

import {
  OLD_VERSION_DETECT_BROADCAST_CHANNEL,
  useBroadcastChannel,
} from './hooks/useBroadcastChannel.js';

export function AdminOldVersionDetector() {
  const oldVersionChannel = useBroadcastChannel(
    OLD_VERSION_DETECT_BROADCAST_CHANNEL,
  );

  const beforePageReload = () => {
    if (oldVersionChannel) {
      oldVersionChannel.postMessage(true);
    }
  };

  return <OldVersionDetector beforePageReload={beforePageReload} />;
}
