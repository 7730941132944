import * as R from 'ramda';
import { gql } from '@apollo/client';

export const TASK_SEARCH_CHANGED = gql`
  subscription TASK_SEARCH_CHANGED($searchInput: CollectionSearchParams!) {
    searchTaskChanged(input: $searchInput) {
      id
      type
    }
  }
`;

// getTasksSubscriptionOptionsByQueryInput ::
//   TasksInput -> ApolloOptions
export const getTasksSubscriptionOptionsByQueryInput = R.compose(
  R.assocPath(['variables', 'searchInput', 'query'], R.__, {}),
  R.ifElse(
    R.isEmpty,
    R.always(null),
    R.assocPath(['bool', 'filter'], R.__, {}),
  ),
  R.reject(R.isNil),
  R.juxt([
    R.ifElse(
      R.pathSatisfies(R.is(String), ['actions', '0']),
      R.compose(R.assocPath(['terms', 'action'], R.__, {}), R.prop('actions')),
      R.always(null),
    ),
    R.ifElse(
      R.propIs(String, 'documentId'),
      R.compose(R.objOf('term'), R.pick(['documentId'])),
      R.always(null),
    ),
  ]),
);
