import React, { useState } from 'react';
import * as R from 'ramda';
import { arrayOf } from 'prop-types';
import { LinkButton } from '@poly/admin-book';
import styled from 'styled-components';
import { PRINT_PDF_CAPTION, ProjectType } from '@poly/constants';
import { gql, useMutation } from '@apollo/client';
import { showBase64PDF } from '@poly/client-utils';
import { isNilOrEmpty, ofArrayLegacy, propEqLegacy } from '@poly/utils';
import { getFeeProjectSupplierName } from '@poly/utils/src/projects/common.js';

import { batchSpendReportPropType } from './BatchSpendReportTable.js';

const LinkButtonS = styled(LinkButton)`
  margin-right: 20px;
`;

// whenIsArrayByPropName :: (String, [Any] -> [Any]) -> Any -> Any
const whenIsArrayByPropName = (propName, fn) =>
  R.compose(R.when(Array.isArray, fn), R.prop(propName));

// prepareInvoices :: [SpendReportInvoice] -> [SpendReportInvoice]
const prepareInvoices = R.map(
  R.applySpec({
    invoiceDate: R.prop('invoiceDate'),
    invoiceFileUrl: R.prop('invoiceFileUrl'),
    supplierName: R.path(['supplier', 'company', 'name']),
    invoiceAmount: R.prop('invoiceAmount'),
  }),
);

// preparePropertyInvoicesReport :: [BatchInvoiceSpendReportResult] -> [PropertyInvoicesReportPdfInput]
const preparePropertyInvoicesReport = R.map(
  R.applySpec({
    projectNumber: R.prop('projectNumber'),
    managementFee: R.prop('managementFee'),
    taxAmount: R.prop('taxAmount'),
    supplierInvoicesTotal: R.prop('supplierInvoicesAmount'),
    clientInvoicesTotal: R.prop('clientInvoiceAmountWithTax'),
    approvedBy: R.path(['approvedBy', 'fullName']),
    clientGLCode: R.path(['clientGLCode', 'code']),
    invoices: R.ifElse(
      propEqLegacy('projectType', ProjectType.FEE),
      R.compose(
        ofArrayLegacy,
        R.objOf('supplierName'),
        getFeeProjectSupplierName,
      ),
      whenIsArrayByPropName('invoices', prepareInvoices),
    ),
  }),
);

// prepareSpendReportProperties :: SpendReportProperty -> SpendReportProperty
const prepareSpendReportProperties = R.applySpec({
  propertyName: R.prop('propertyName'),
  propertyInvoicesReport: whenIsArrayByPropName(
    'propertyInvoicesReport',
    preparePropertyInvoicesReport,
  ),
});

// prepareSpendReportHierarchy :: BatchHierarchySpendReport -> BatchHierarchySpendReport
const prepareSpendReportHierarchy = (node) =>
  R.applySpec({
    name: R.prop('name'),
    properties: whenIsArrayByPropName(
      'properties',
      R.map(
        R.compose(
          R.mergeAll,
          R.juxt([
            prepareSpendReportProperties,
            R.ifElse(
              R.propSatisfies(isNilOrEmpty, 'childProperties'),
              R.always({}),
              R.compose(
                R.objOf('childProperties'),
                R.map(prepareSpendReportProperties),
                R.prop('childProperties'),
              ),
            ),
          ]),
        ),
      ),
    ),
    children: whenIsArrayByPropName(
      'children',
      R.map(prepareSpendReportHierarchy),
    ),
  })(node);

// prepareClientBatchSpendReportPdfInput :: [BatchHierarchySpendReport] -> [BatchHierarchySpendReport]
const prepareClientBatchSpendReportPdfInput = R.map(
  prepareSpendReportHierarchy,
);

const PREVIEW_BATCH_SPEND_REPORT_PDF_MUTATION = gql`
  mutation PREVIEW_BATCH_SPEND_REPORT_PDF_MUTATION(
    $input: [ClientBatchSpendReportPdfInput!]!
  ) {
    previewClientBatchSpendReportPdf(input: $input) {
      base64FileString
    }
  }
`;

// getPDFBase64FileString : QueryData -> String
const getPDFBase64FileString = R.path([
  'data',
  'previewClientBatchSpendReportPdf',
  'base64FileString',
]);

export function PreviewBatchSpendReportPdf({ reports }) {
  const [isLoading, setIsLoading] = useState(false);

  const [previewClientBatchSpendReportPdf] = useMutation(
    PREVIEW_BATCH_SPEND_REPORT_PDF_MUTATION,
  );

  const handleClick = async () => {
    setIsLoading(true);
    const input = prepareClientBatchSpendReportPdfInput(reports);
    const response = await previewClientBatchSpendReportPdf({
      variables: { input },
    });

    const fileBase64String = getPDFBase64FileString(response);
    await showBase64PDF(fileBase64String);

    setIsLoading(false);
  };

  return (
    <LinkButtonS disabled={isLoading} onClick={handleClick}>
      {PRINT_PDF_CAPTION}
    </LinkButtonS>
  );
}

PreviewBatchSpendReportPdf.propTypes = {
  reports: arrayOf(batchSpendReportPropType),
};
