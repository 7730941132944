import React from 'react';
import { string, arrayOf, shape } from 'prop-types';
import { useCurrentUserByStoreOrQuery } from '@poly/client-utils/src/hooks/useCurrentUserByStoreOrQuery.js';

import { TimesheetForm } from './TimesheetForm.js';
import { timesheetInitialValues } from './constants.js';
import { partialTimesheetFormOptions } from './sections.js';
import { convertFormDataToTimesheet } from './helpers/from-form-data.js';
import { modifyInitialValuesWithMutators } from './helpers/mutators.js';
import {
  useCreateTimesheet,
  useUpdateTimesheet,
} from '../../core/hooks/timesheets/index.js';

export const addTimesheetFormId = 'addTimesheetFormId';

export function AddTimesheetForm({ myTimesheets, ...restProps }) {
  const { createTimesheet } = useCreateTimesheet();
  const { updateTimesheet } = useUpdateTimesheet();

  const { user } = useCurrentUserByStoreOrQuery();

  const mutate = (params) => {
    const { ownerId, _id, ...rest } = convertFormDataToTimesheet(params);

    if (_id) {
      updateTimesheet(_id, { ...rest, ownerId: user._id });
    } else {
      createTimesheet({ ...rest, ownerId: user._id });
    }
  };

  const initialValues = {
    timeSheets: myTimesheets,
    ...timesheetInitialValues,
    ...modifyInitialValuesWithMutators(new Date())(myTimesheets),
  };

  const additionalProps = partialTimesheetFormOptions(myTimesheets);

  if (!user) return null;

  return (
    <TimesheetForm
      {...restProps}
      {...additionalProps}
      mutate={mutate}
      successMessage="Time sheet successfully added"
      id={addTimesheetFormId}
      formId={addTimesheetFormId}
      initialValues={initialValues}
    />
  );
}

AddTimesheetForm.displayName = 'AddTimesheetForm';

AddTimesheetForm.propTypes = {
  myTimesheets: arrayOf(
    shape({
      date: string,
      entries: arrayOf(
        shape({
          timeIn: string,
          timeOut: string,
        }),
      ),
    }),
  ),
};
