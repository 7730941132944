import React from 'react';
import styled from 'styled-components';
import { bool, oneOfType, string, func } from 'prop-types';

import { Text } from '../Text/index.js';
import { Loader } from '../Loader/index.js';

const getPointer = ({ disabled }) => (disabled ? 'not-allowed' : 'pointer');

const TextS = styled(Text).attrs(({ disabled }) => ({
  size: '12px',
  color: disabled ? ['secondary'] : ['primaryLight'],
  weight: 'medium',
}))`
  line-height: 18px;
  position: relative;
  cursor: ${getPointer};
  ${({ loading }) => loading && 'color: transparent;'};
  ${({ active }) => active && 'color: #dd6262;'};
`;

export function TextButton({ children, disabled, onClick, loading, ...props }) {
  const onClickHandler = (e) => (disabled ? e.preventDefault() : onClick(e));
  return (
    <TextS {...props} disabled={disabled} onClick={onClickHandler}>
      {children}
      {loading && <Loader size={16} />}
    </TextS>
  );
}

TextButton.propTypes = {
  onClick: func.isRequired,
  children: oneOfType([string, string]),
  disabled: bool,
  loading: bool,
};

TextButton.defaultProps = {
  loading: false,
  disabled: false,
};
