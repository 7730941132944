import React from 'react';
import { Select } from '@poly/admin-book';

import {
  customPropertyBuildingType,
  buildingTypeOptionsConfig,
} from '../../../constants.js';

export function BuildingTypeSelect(props) {
  const options = [
    ...buildingTypeOptionsConfig.map((option) => ({
      label: option,
      value: option,
    })),
    { label: customPropertyBuildingType, value: customPropertyBuildingType },
  ];

  return <Select {...props} options={options} />;
}
