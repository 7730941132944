import * as R from 'ramda';
import { useEffect } from 'react';
import { gql } from '@apollo/client';
import { useReactiveQuery, keywordSortQuery } from '@poly/client-utils';
import { USER_SEARCH_CHANGED, MAX_ITEMS } from '@poly/admin-ui';
import { ASC_SORT_ORDER } from '@poly/constants';
import { CLIENT_ID_AIT, getSearchAccessItemsQueryByAIT } from '@poly/security';

const CLIENT_PORTAL_USERS = gql`
  query CLIENT_PORTAL_USERS($searchInput: CollectionSearchParams!) {
    searchUsers(input: $searchInput) {
      hits {
        _id
        email
        fullName
        title
        userGroups {
          userGroup {
            _id
            name
          }
        }
        profile {
          cellPhoneNumber
          workPhoneNumber
          workPhoneNumberExt
          loginCellPhoneNumber
        }
      }
      total
    }
  }
`;

export const useClientPortalUsers = (clientId, status, searchTerm) => {
  const queryOptions = {
    variables: {
      searchInput: {
        query: {
          bool: {
            must: [
              getSearchAccessItemsQueryByAIT(CLIENT_ID_AIT, [], clientId),
              ...(status ? [{ term: { status } }] : []),
            ],
          },
        },
        sort: keywordSortQuery(['profile', 'fullName'])(ASC_SORT_ORDER),
        from: 0,
        searchTerm,
        size: MAX_ITEMS,
      },
    },
    fetchPolicy: 'no-cache',
    skip: !clientId,
  };

  const { data, loading, debouncedRefetch } = useReactiveQuery(
    CLIENT_PORTAL_USERS,
    USER_SEARCH_CHANGED,
    {
      queryOptions,
      subscriptionOptions: { variables: { searchInput: {} } },
    },
  );

  const count = R.pathOr(0, ['searchUsers', 'total'], data);

  const users = R.pathOr([], ['searchUsers', 'hits'], data);

  useEffect(() => {
    debouncedRefetch();
  }, []);

  return { loading, count, users };
};
