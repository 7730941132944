import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { bool, func } from 'prop-types';
import { useNavigate } from '@poly/client-routing';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { isNilOrEmpty, roundTo } from '@poly/utils';
import { Button, getThemeColor } from '@poly/admin-book';
import {
  useNotificationState,
  useOnSubmitSetStopSubmitting,
} from '@poly/admin-ui';

import { EDITABLE_PAYMENTS_SELECTOR_PROP } from './constants.js';

const editClientPaymentsFormId = 'editClientPaymentsFormId';

const EditClientPaymentsFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 50px;
  width: calc(100% - 100px);
`;

const EditPaymentsButton = styled(Button)`
  background: ${getThemeColor(['secondaryRegular'])};
`;

export const editClientPaymentsMutation = gql`
  mutation editClientPaymentsMutation($input: [EditClientPaymentInput]!) {
    editClientPayments(input: $input) {
      _id
    }
  }
`;

// isNoDeductionGLOrAmount :: { deductionAmount: Float, deductionGL: ID } -> Boolean
const isNoDeductionGLOrAmount = R.either(
  R.propSatisfies(isNilOrEmpty, 'deductionAmount'),
  R.propSatisfies(isNilOrEmpty, 'deductionGL'),
);

// prepareMutationInput :: [EditablePaymentRows] -> [EditClientPaymentInput]
const prepareMutationInput = R.compose(
  R.map(R.mergeAll),
  R.map(
    R.juxt([
      R.ifElse(
        isNoDeductionGLOrAmount,
        R.always({}),
        R.applySpec({
          deductionAmount: R.prop('deductionAmount'),
          deductionAccountId: R.prop('deductionGL'),
        }),
      ),
      R.applySpec({
        journalId: R.prop('_id'),
        date: R.prop('receivedDate'),
        receivedAmount: R.prop('receivedAmount'),
        depositAccountId: R.prop('depositAccount'),
        refNumber: R.prop('refNumber'),
        dueAmount: R.compose(
          roundTo(2),
          R.converge(R.subtract, [
            R.prop('clientInvoiceAmount'),
            R.converge(R.add, [
              R.propOr(0, 'receivedAmount'),
              R.propOr(0, 'deductionAmount'),
            ]),
          ]),
        ),
      }),
    ]),
  ),
  R.filter(R.prop('isSelected')),
);

// isNoClientPaymentsSelected :: [EditablePaymentRows] -> Boolean
const isNoClientPaymentsSelected = R.compose(
  R.isEmpty,
  R.filter(R.prop('isSelected')),
);

export function EditClientPaymentsFooter({ submitting, setSubmitting }) {
  const { showSuccessNotification } = useNotificationState();
  const [editClientPayments] = useMutation(editClientPaymentsMutation);
  const editablePayments = useSelector(R.prop(EDITABLE_PAYMENTS_SELECTOR_PROP));
  const navigate = useNavigate();

  const isNoPaymentsSelected = isNoClientPaymentsSelected(editablePayments);

  const submitHandler = async () => {
    try {
      if (isNoPaymentsSelected) return null;

      const input = prepareMutationInput(editablePayments);

      setSubmitting(true);
      await editClientPayments({ variables: { input } });
      showSuccessNotification('Payments was successfully edited.');
      navigate(0);

      return null;
    } finally {
      setSubmitting(false);
    }
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    editClientPaymentsFormId,
    submitHandler,
  );

  return (
    <EditClientPaymentsFooterWrapper>
      <EditPaymentsButton
        size="tiny"
        loader={submitting}
        onClick={onSubmit}
        disabled={isNoPaymentsSelected}
        data-testid="edit-payments-submit-button"
      >
        Edit Payments
      </EditPaymentsButton>
    </EditClientPaymentsFooterWrapper>
  );
}

EditClientPaymentsFooter.propTypes = {
  submitting: bool,
  setSubmitting: func,
};
