import * as R from 'ramda';
import { format, getDate } from 'date-fns';
import { TimesheetTypes, NOTHING_UI_STRING } from '@poly/constants';
import {
  assocBy,
  removePropDeep,
  applySpecWithFields,
  ensureIsDate,
  forceTitleCase,
  formatTimesheetDuration,
  propEqLegacy,
  ofArrayLegacy,
} from '@poly/utils';

import { getTimesheetDurationInMinutes } from '../../forms/timesheetForm/helpers/duration.js';

const timesheetFiguresEntries = [
  TimesheetTypes.STRAIGHT_TIME,
  TimesheetTypes.OVER_TIME,
  TimesheetTypes.DOUBLE_TIME,
  TimesheetTypes.PTO,
  TimesheetTypes.HOLIDAY,
];

// filterTimesheetsByType :: [Timesheet] -> String -> [Timesheet]
const filterTimesheetsByType = R.curry((timesheets, type) =>
  R.filter(propEqLegacy('type', type), timesheets),
);

// calculateTimesheetFigures :: [Timesheet] -> [{label: String, value: String}]
export const calculateTimesheetFigures = R.compose(
  R.map(
    R.compose(
      R.evolve({
        label: forceTitleCase,
        value: R.compose(
          formatTimesheetDuration,
          R.reduce(R.add, 0),
          R.map(R.prop('total')),
        ),
      }),
      R.zipObj(['label', 'value']),
    ),
  ),
  R.converge(R.append, [
    R.compose(R.prepend('total'), ofArrayLegacy),
    R.compose(
      R.zip(timesheetFiguresEntries),
      R.map(R.__, timesheetFiguresEntries),
      filterTimesheetsByType,
      R.identity, // forces partial application for filterTimesheetsByType
    ),
  ]),
  R.map(assocBy('total', getTimesheetDurationInMinutes)),
);

// formatDateCurried :: String -> Date -> String
export const formatDateCurried = R.curry((_format, date) =>
  format(ensureIsDate(date), _format),
);

// formatTime :: Date -> String
const formatTime = formatDateCurried('hh:mm a');

// formatTimesheetEntriesTimes :: String -> Timesheet -> String
export const formatTimesheetEntriesTimes = (prop) =>
  R.compose(
    R.join(' '),
    R.ifElse(
      R.is(String),
      ofArrayLegacy,
      R.compose(R.map(R.compose(formatTime, R.prop(prop)))),
    ),
    R.when(R.isEmpty, R.always(NOTHING_UI_STRING)),
    R.defaultTo([]),
    R.prop('entries'),
  );

// getTableList :: MyTimesheetsQueryResult=> [ModifiedTimeSheet]
const getTimesheetsForTableList = R.pipe(
  R.defaultTo([]),
  R.map(
    applySpecWithFields({
      sortProp: R.pipe(R.prop('date'), ensureIsDate, getDate),
    }),
  ),
  R.sortBy(R.prop('sortProp')),
  R.groupBy(R.prop('sortProp')),
  removePropDeep('__typename'),
  R.values,
);

export function useMapMyTimesheetForTable({ myTimesheets = [] }) {
  return {
    tableList: getTimesheetsForTableList(myTimesheets),
  };
}
