import * as R from 'ramda';
import {
  sortTableRows,
  toTableColumns,
  toTableHeaders,
} from '@poly/admin-book';
import { useState } from 'react';
import { updateSorting } from '@poly/admin-book/src/Table/utils.js';

export const extractTablePropsFromConfig = (tableConfig) => ({
  headers: toTableHeaders(tableConfig),
  columns: toTableColumns(tableConfig),
  sortQueries: R.map(R.nth(2), tableConfig),
});

export const useMapConfigToTableProps = (getRowsFunc, tableConfig, data) => {
  const rows = getRowsFunc(data);

  return {
    ...extractTablePropsFromConfig(tableConfig),
    rows: R.is(Array, rows) ? rows : [],
  };
};

export const useMapConfigToTablePropsWithSorting = ({
  tableConfig,
  items,
  initialSorting,
  onHeaderCellClick,
}) => {
  const [sorting, setSorting] = useState(initialSorting);

  const { sortQueries, columns, headers } =
    extractTablePropsFromConfig(tableConfig);

  return {
    onHeaderCellClick: (headerCellNum) => {
      if (sortQueries[headerCellNum]) {
        const newSorting = updateSorting(headerCellNum, sorting);
        setSorting(newSorting);
        if (onHeaderCellClick) {
          onHeaderCellClick(newSorting);
        }
      }
    },
    rows: sorting ? sortTableRows(sortQueries, sorting)(items) : items,
    isSortable: true,
    columns,
    headers,
    sortQueries,
    sorting,
  };
};
