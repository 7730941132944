import { gql } from '@apollo/client';
import * as R from 'ramda';
import { useEntityMultipleSubscription } from '@poly/client-utils';

import { propertyDetailsFields } from '../../modules/core/hooks/properties/fragments.js';
import { PROPERTY_DETAILS_SUB } from '../../modules/core/hooks/properties/subscriptions.js';

const PROPERTY_DETAILS = gql`
  query PROPERTY_DETAILS($propertyId: ID!) {
    property(id: $propertyId) {
      ...propertyDetailsFields
    }
  }
  ${propertyDetailsFields}
`;

const CLIENT_NAME_SUB = gql`
  subscription CLIENT_NICK_NAME_SUB($input: SingleDocSubInput!) {
    clientChanged(input: $input) {
      id
      document {
        _id
        nickName
        name
      }
    }
  }
`;

// getPropertySubOptions :: PropertyResult -> SubscriptionOptions
const getPropertySubOptions = R.applySpec({
  skip: R.pathSatisfies(R.isNil, ['property', '_id']),
  variables: R.compose(
    R.objOf('input'),
    R.objOf('id'),
    R.path(['property', '_id']),
  ),
});

// getPropertyClientSubOptions :: PropertyResult -> SubscriptionOptions
const getPropertyClientSubOptions = R.applySpec({
  skip: R.pathSatisfies(R.isNil, ['property', 'client', '_id']),
  variables: R.compose(
    R.objOf('input'),
    R.objOf('id'),
    R.path(['property', 'client', '_id']),
  ),
});

export const usePropertySidebarData = (propertyId) => {
  const { data, loading } = useEntityMultipleSubscription(
    PROPERTY_DETAILS,
    [PROPERTY_DETAILS_SUB, CLIENT_NAME_SUB],
    {
      queryOptions: { variables: { propertyId }, fetchPolicy: 'network-only' },
      getSubscriptionOptions: [
        getPropertySubOptions,
        getPropertyClientSubOptions,
      ],
    },
  );

  return { loading, ...data };
};
