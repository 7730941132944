import React from 'react';
import * as R from 'ramda';
import { number, string, bool } from 'prop-types';
import { defaultTheme, Status, Loader } from '@poly/admin-book';
import { ClientStatuses } from '@poly/constants';
import {
  useMapConfigToTableProps,
  SidebarSubTabs,
  SidebarRow,
} from '@poly/admin-ui';
import { formatCount, forceTitleCase } from '@poly/utils';

import {
  CommonSidebarSubEntityTable,
  DoubleTabsWrapper,
  SectionLabel,
  ToolbarButton,
} from '../../components/commonSidebarComponents.js';
import { clientLinkColumn } from '../../../modules/tables/columns/clients.js';
import { useClientChildAccountsQuery } from './useClientChildAccountsQuery.js';
import { useAddClientSidebar } from '../forms/add/useAddClientSidebar.js';

const {
  colors: {
    statuses: { active, inactive },
  },
} = defaultTheme;

const ClientStatusColor = {
  [ClientStatuses.ACTIVE]: active,
  [ClientStatuses.INACTIVE]: inactive,
};

function AddChildClientButton({ parentClientId, isCard }) {
  const openAddClientSidebar = useAddClientSidebar(isCard, parentClientId);

  return <ToolbarButton onClick={openAddClientSidebar}>Add</ToolbarButton>;
}

AddChildClientButton.propTypes = {
  isCard: bool,
  parentClientId: string.isRequired,
};

function ClientChildAccountTabTitle({ status, clientId }) {
  const { count } = useClientChildAccountsQuery(clientId, status);

  const title = `${forceTitleCase(status)} (${formatCount(count)})`;

  return <Status color={ClientStatusColor[status]} text={title} />;
}

ClientChildAccountTabTitle.propTypes = {
  status: string.isRequired,
  clientId: string.isRequired,
};

const childAccountsTableConfig = [
  clientLinkColumn,
  ['Status', R.prop('status')],
];

function ClientChildAccountTab({ status, clientId }) {
  const { loading, accounts } = useClientChildAccountsQuery(clientId, status);

  const tableProps = useMapConfigToTableProps(
    R.sortBy(R.prop('name')),
    childAccountsTableConfig,
    accounts,
  );

  if (loading) return <Loader />;

  return <CommonSidebarSubEntityTable {...tableProps} />;
}

ClientChildAccountTab.propTypes = {
  status: string.isRequired,
  clientId: string.isRequired,
};

// getClientChildAccountTab :: (String, ID) -> [ReactComponent, String, ReactComponent]
const getClientChildAccountTab = (status, clientId) => [
  <ClientChildAccountTabTitle {...{ status, clientId }} />,
  status,
  <ClientChildAccountTab {...{ status, clientId }} />,
];

export function ClientSidebarChildrenTab({ clientId, doubleTabWidth, isCard }) {
  return (
    <DoubleTabsWrapper width={doubleTabWidth} isCard={isCard}>
      <SidebarRow>
        <SectionLabel>Client Child Accounts</SectionLabel>
      </SidebarRow>
      <SidebarSubTabs
        tabs={[
          getClientChildAccountTab(ClientStatuses.ACTIVE, clientId),
          getClientChildAccountTab(ClientStatuses.INACTIVE, clientId),
        ]}
        defaultValue={ClientStatuses.ACTIVE}
        toolBar={
          <AddChildClientButton parentClientId={clientId} isCard={isCard} />
        }
      />
    </DoubleTabsWrapper>
  );
}

ClientSidebarChildrenTab.propTypes = {
  isCard: bool,
  clientId: string.isRequired,
  doubleTabWidth: number.isRequired,
};
