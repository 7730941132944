import React, { createContext, useContext, useMemo } from 'react';
import { node, object } from 'prop-types';
import { useModal } from './modalHooks.js';
import { ActiveModal } from './ActiveModal.js';

const ModalContext = createContext({});

export function ModalProvider({ children, root }) {
  const {
    openModal,
    openConfirmSubmitFormModal,
    openConfirmModal,
    openModalForm,
    closeModal,
    activeModals,
  } = useModal();

  const modalsContextValue = useMemo(
    () => ({
      openConfirmSubmitFormModal,
      openConfirmModal,
      openModalForm,
      openModal,
      closeModal,
      activeModals,
    }),
    [activeModals],
  );

  return (
    <ModalContext.Provider value={modalsContextValue}>
      {children}
      {activeModals.map((modalConfig) => {
        const closeCurrentModal = () => {
          closeModal(modalConfig.id);
          if (modalConfig.onCloseModal) {
            modalConfig.onCloseModal();
          }
        };

        return (
          <ActiveModal
            {...modalConfig}
            openConfirmModal={openConfirmModal}
            closeCurrentModal={closeCurrentModal}
            key={modalConfig.id}
            root={root}
          />
        );
      })}
    </ModalContext.Provider>
  );
}

ModalProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  root: object,
  children: node.isRequired,
};

export const useModalContext = () => {
  const modalContext = useContext(ModalContext);
  return modalContext;
};
