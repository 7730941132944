import * as R from 'ramda';
import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';

import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { AccountsReceivableReportHeader } from './AccountsReceivableReportHeader.js';
import { AccountsReceivableReportBody } from './AccountsReceivableReportBody.js';

const accountsReceivableReportQuery = gql`
  query ACCOUNTS_RECEIVABLE_REPORT_QUERY(
    $input: AccountsReceivableReportInput!
  ) {
    accountsReceivableReport(input: $input) {
      client {
        _id
        name
      }
      amount
    }
  }
`;

// getReportEntriesByFilter :: Object -> Object -> [AccountsReceivableReportEntry]
const getReportEntriesByFilter = (filter) =>
  R.compose(
    R.sortBy(R.path(['client', 'name'])),
    R.map(R.assoc('date', filter.date)),
    R.propOr([], 'accountsReceivableReport'),
  );

export function AccountsReceivableReportPage() {
  const [filter, setFilter] = useState({});
  const { data, loading } = useQuery(accountsReceivableReportQuery, {
    variables: {
      input: filter,
    },
    skip: R.isEmpty(filter),
    fetchPolicy: 'network-only',
  });

  const reportEntries = getReportEntriesByFilter(filter)(data);

  return (
    <PageWithSearchHeader headerHeight="115px">
      <AccountsReceivableReportHeader
        {...{ setFilter, reportEntries, filter }}
      />
      <AccountsReceivableReportBody
        loading={loading}
        reportEntries={reportEntries}
      />
    </PageWithSearchHeader>
  );
}
