import React from 'react';
import { Input } from '@poly/admin-book';

export function HoursInput(props) {
  return (
    <Input
      {...props}
      type="numberMask"
      maxValue={99}
      decimalScale={2}
      floatValue
    />
  );
}

HoursInput.displayName = 'HoursInput';
