import * as R from 'ramda';
import React, { useMemo } from 'react';
import { shape, string } from 'prop-types';
import { useSubscription, gql } from '@apollo/client';
import { useMapConfigToTableProps, useTableSorting } from '@poly/admin-ui';
import { useUpdateQueryParams } from '@poly/client-routing';
import { LinkButton } from '@poly/admin-book';
import {
  keywordNestedSortQuery,
  keywordSortQuery,
  formatDateProp,
  useTableInfiniteScrollQuery,
} from '@poly/client-utils';
import { useDebounceRefetchByOptions } from '@poly/client-utils/src/hooks/useDebounceRefetchByOptions.js';
import { propEqLegacy } from '@poly/utils';

import { assetSidebarTabs } from '../../../routes/constants.js';
import { editProcedureFormId } from '../../../pages/ManageProcedures/constants.js';
import { PROJECTS_BY_SEARCH_SUB } from '../../../modules/core/hooks/projects/subscriptions.js';
import { useOpenAddEditProcedureSidebar } from '../../../pages/ManageProcedures/hooks/useOpenAddEditProcedureSidebar.js';
import { useSidebarLogicContext } from '../../SidebarLogicContext.js';
import { useOpenProcedureDetailsSidebar } from '../../components/commonTabs/useOpenProcedureDetailsSidebar.js';

const SEARCH_ASSET_PROCEDURES_QUERY = gql`
  query SEARCH_ASSET_PROCEDURES_QUERY($input: CollectionSearchParams!) {
    searchProcedures(input: $input) {
      hits {
        _id
        name
        assetTypes {
          _id
          name
        }
        steps {
          name
          description
          isRequiredStep
          isImageUploadRequired
        }
        relations {
          assetId
          executionDate
          project {
            type
            projectId
            assetProcedures {
              asset {
                _id
              }
              executedProcedure {
                _id
                completedAt
                steps {
                  name
                  status
                  comment
                  description
                  attachments {
                    fileName
                    fileType
                    url
                  }
                }
              }
            }
          }
          recurringProject {
            type
            projectId
          }
        }
      }
      total
    }
  }
`;

export const SEARCH_ASSET_PROCEDURES_SUBSCRIPTION = gql`
  subscription SEARCH_ASSET_PROCEDURES_SUBSCRIPTION(
    $input: CollectionSearchParams
  ) {
    searchProcedureChanged(input: $input) {
      id
      type
    }
  }
`;

function ProjectSidebarLink({ project, recurringProject }) {
  const { ProjectLink } = useSidebarLogicContext();

  const projectLinkProps = project || recurringProject;

  return <ProjectLink {...projectLinkProps} />;
}

ProjectSidebarLink.propTypes = {
  project: shape({ type: string.isRequired, projectId: string.isRequired }),
  recurringProject: shape({
    type: string.isRequired,
    projectId: string.isRequired,
  }),
};

// getExecutedProcedure :: { project: ProcedureRelationProject } -> ExecutedProcedure
const getExecutedProcedure = R.compose(
  R.head,
  R.reject(R.isNil),
  R.map(R.prop('executedProcedure')),
  R.pathOr([], ['project', 'assetProcedures']),
);

function ProcedureLink(props) {
  const { name } = props;
  const executedProcedure = getExecutedProcedure(props);

  const updateQueryParams = useUpdateQueryParams();
  const openProcedureDetailsSidebar = useOpenProcedureDetailsSidebar();

  const onClose = () =>
    updateQueryParams({
      sidebarTab: assetSidebarTabs.assetProcedures,
    });

  const onEditProcedure = useOpenAddEditProcedureSidebar(
    editProcedureFormId,
    onClose,
  );

  const onClick = () => {
    if (executedProcedure) {
      openProcedureDetailsSidebar(executedProcedure);
      return;
    }

    onEditProcedure(props);
  };

  return <LinkButton onClick={onClick}>{name}</LinkButton>;
}

ProcedureLink.propTypes = { name: string.isRequired };

const proceduresTableConfig = [
  ['Procedure Name', ProcedureLink, keywordSortQuery(['name'])],
  [
    'Project #',
    ProjectSidebarLink,
    keywordNestedSortQuery(['relations', 'project', 'projectId']),
  ],
  [
    'Date',
    formatDateProp(['executionDate']),
    keywordNestedSortQuery(['relations', 'executionDate']),
  ],
];

// getProceduresByQuery :: SearchProceduresQueryResult -> [Procedure]
const getProceduresByQuery = R.pathOr([], ['searchProcedures', 'hits']);

// getAssetProcedures :: ID -> SearchProceduresQueryResult -> [Procedure]
const getAssetProcedures = (assetId) =>
  R.compose(
    R.map(
      R.converge(R.mergeLeft, [R.prop('procedure'), R.omit(['procedure'])]),
    ),
    R.flatten,
    R.map(
      R.converge(R.map, [
        R.compose(R.mergeLeft, R.objOf('procedure')),
        R.compose(
          R.filter(propEqLegacy('assetId', assetId)),
          R.prop('relations'),
        ),
      ]),
    ),
    getProceduresByQuery,
  );

// getAssetProceduresQuery :: ID -> ElasticQuery
const getAssetProceduresQuery = (assetId) => ({
  nested: {
    path: 'relations',
    query: { term: { 'relations.assetId': assetId } },
  },
});

// getProjectsSubscriptionVariables :: ID -> SubscriptionVariables
const getProjectsSubscriptionVariables = R.compose(
  R.objOf('searchInput'),
  R.objOf('query'),
  R.objOf('term'),
  R.objOf('assetIds'),
);

// getAssetProceduresSubscriptionVariables :: SearchProceduresQueryResult -> SubscriptionVariables
const getAssetProceduresSubscriptionVariables = R.compose(
  R.objOf('input'),
  R.objOf('query'),
  R.applySpec({ terms: { _id: R.pluck('_id') } }),
  R.pathOr([], ['searchProcedures', 'hits']),
);

// skipAssetProceduresSubscription :: SearchProceduresQueryResult -> Boolean
const skipAssetProceduresSubscription = R.complement(
  R.path(['searchProcedures', 'total']),
);

export const useSearchAssetProceduresQuery = (assetId) => {
  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: proceduresTableConfig,
    column: 3,
  });

  const input = useMemo(
    () => ({ sort, query: getAssetProceduresQuery(assetId) }),
    [sort, assetId],
  );

  const { data, loading, tableProps, refetch } = useTableInfiniteScrollQuery(
    SEARCH_ASSET_PROCEDURES_QUERY,
    input,
    {
      endpointName: 'searchProcedures',
    },
  );

  const debouncedRefetch = useDebounceRefetchByOptions({
    refetch,
    refetchDebounce: 2000,
  });

  useSubscription(PROJECTS_BY_SEARCH_SUB, {
    shouldResubscribe: data,
    onData: debouncedRefetch,
    variables: getProjectsSubscriptionVariables(assetId),
  });

  useSubscription(SEARCH_ASSET_PROCEDURES_SUBSCRIPTION, {
    shouldResubscribe: data,
    onData: debouncedRefetch,
    variables: getAssetProceduresSubscriptionVariables(data),
    skip: skipAssetProceduresSubscription(data),
  });

  const dataTableProps = useMapConfigToTableProps(
    getAssetProcedures(assetId),
    proceduresTableConfig,
    data,
  );

  return {
    loading,
    tableProps: {
      ...tableProps,
      ...dataTableProps,
      ...tableSortingProps,
    },
  };
};
