import React from 'react';
import {
  useNotificationState,
  useModalContext,
  TooltipButton,
  TooltipDefaultButtons,
  TooltipConfirmText,
} from '@poly/admin-ui';
import { gql, useMutation } from '@apollo/client';
import { string, shape, bool, func } from 'prop-types';
import { Icon } from '@poly/admin-book';

import {
  EditProjectTimeForm,
  editProjectTimeFormId,
} from './EditProjectTimeForm.js';
import { projectPropTypes } from './projectTimePropTypes.js';
import { AddProjectTimeFormId } from './projectTimeConstants.js';

const confirmDeleteText = 'Are you sure you want to delete this time record?';

const successText = 'Time record was successfully deleted';

const defaultIconProps = {
  size: 11,
  color: '#4676b4',
};

const REMOVE_PROJECT_TIME_ENTRY = gql`
  mutation ADD_TIME_TO_PROJECT($projectId: ID!, $projectTimeEntryId: ID!) {
    removeProjectTimeEntry(
      projectId: $projectId
      projectTimeEntryId: $projectTimeEntryId
    ) {
      totalTimeInMinutes
    }
  }
`;

const confirmModalProps = {
  cancelBtnCaption: 'No',
  btnCaption: 'Yes, Delete',
  id: 'removeTimeFromProjectId',
  content: confirmDeleteText,
};

function DeleteTimeWrapper(props) {
  return <div {...props} />;
}

function DeleteTimeTooltip({ onConfirm, closeTooltip }) {
  return (
    <>
      <TooltipConfirmText>{confirmDeleteText}</TooltipConfirmText>
      <TooltipDefaultButtons onConfirm={onConfirm} onCancel={closeTooltip} />
    </>
  );
}

DeleteTimeTooltip.propTypes = {
  onConfirm: func.isRequired,
  closeTooltip: func.isRequired,
};

export function DeleteProjectTimeBtn({
  _id,
  projectId,
  isInvoiced,
  isSidebar,
}) {
  const { showSuccessNotification } = useNotificationState();
  const { openConfirmModal } = useModalContext();

  const [removeProjectTimeEntry] = useMutation(REMOVE_PROJECT_TIME_ENTRY, {
    variables: {
      projectId,
      projectTimeEntryId: _id,
    },
  });

  if (isInvoiced) {
    return null;
  }

  const confirmRemoveTimeFromProject = () =>
    openConfirmModal({
      ...confirmModalProps,
      onConfirm: (closeConfirmModal) => async () => {
        await removeProjectTimeEntry();
        showSuccessNotification(successText);
        closeConfirmModal();
      },
    });

  const handleDeleteTime = async () => {
    await removeProjectTimeEntry();
    showSuccessNotification(successText);
  };

  return isSidebar ? (
    <TooltipButton
      Wrapper={DeleteTimeWrapper}
      Tooltip={DeleteTimeTooltip}
      tooltipBtnProps={{ onConfirm: handleDeleteTime }}
    >
      <Icon name="delete" {...defaultIconProps} />
    </TooltipButton>
  ) : (
    <Icon
      name="delete"
      onClick={confirmRemoveTimeFromProject}
      {...defaultIconProps}
    />
  );
}

DeleteProjectTimeBtn.propTypes = {
  _id: string.isRequired,
  projectId: string.isRequired,
  isInvoiced: bool,
  isSidebar: bool,
};

export function EditProjectTimeBtn({
  project,
  setEditTimeProps,
  ...projectTimeEntry
}) {
  const { openModal, closeModal } = useModalContext();

  if (projectTimeEntry.isInvoiced) {
    return null;
  }

  const openEditTimeForm = () =>
    openModal({
      id: editProjectTimeFormId,
      title: 'Edit Project Time',
      content: (
        <EditProjectTimeForm
          project={project}
          projectTimeEntry={projectTimeEntry}
        />
      ),
      width: '700px',
    });

  const handleClick = () => {
    if (typeof setEditTimeProps === 'function') {
      setEditTimeProps({ project, projectTimeEntry });
    } else {
      closeModal(AddProjectTimeFormId);
      openEditTimeForm();
    }
  };

  return <Icon name="edit" onClick={handleClick} {...defaultIconProps} />;
}

EditProjectTimeBtn.propTypes = {
  project: shape(projectPropTypes).isRequired,
  setEditTimeProps: func,
};
