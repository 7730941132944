import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, func, number, shape, string } from 'prop-types';
import {
  getThemeColor,
  getThemeFont,
  InputErrorMsg,
  Rating,
} from '@poly/admin-book';

import { mapRatingToSupplier } from '../taskFormUtils.js';
import { SupplierInvoiceRequestOptions } from './SupplierInvoiceRequestOptions.js';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div {
    padding-bottom: 10px;
  }
`;

const SupplierRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RatingColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const SupplierName = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  width: 100%;
  flex-shrink: 0;
  overflow-wrap: break-word;
  padding-bottom: 10px;
`;

const RatingC = styled(Rating)`
  width: 120px;
`;

const SupplierWithoutEmailErrorComponent = styled.div`
  display: block;
  margin-left: auto;
  margin-right: 0;
  font-size: 12px;
  color: ${getThemeColor(['secondaryMid'])};
`;

const MainComponentLabelWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 0;
  justify-content: space-between;
`;

const MainComponentLabel = styled.label`
  line-height: 18px;
  font-weight: ${getThemeFont(['regular'])};
  font-size: 12px;
  color: ${getThemeColor(['primaryLighter2'])};

  :last-child {
    width: 30%;
  }
`;

export function SupplierRatings({ value, error, onChange }) {
  const onSupplierRate = R.compose(onChange, mapRatingToSupplier(value));

  return (
    <Wrapper>
      <MainComponentLabelWrapper>
        <MainComponentLabel>Rate Supplier Work</MainComponentLabel>
        <MainComponentLabel>Send Invoice Requests</MainComponentLabel>
      </MainComponentLabelWrapper>
      {value.map(
        (
          {
            supplierId,
            supplierName,
            rating,
            readOnlyRating,
            supplierRequest,
            supplierHaveAccountEmail,
            hideRequestOptions,
            isRatedOnComplete,
          },
          index,
        ) => (
          <div key={supplierId}>
            <SupplierRow>
              <RatingColumn>
                <SupplierName>{supplierName}</SupplierName>
                <RatingC
                  readOnly={isRatedOnComplete}
                  value={
                    isRatedOnComplete ? readOnlyRating : { average: rating }
                  }
                  onChange={(stars) =>
                    onSupplierRate({
                      supplierId,
                      supplierRequest,
                      rating: stars,
                    })
                  }
                />
              </RatingColumn>
              <SupplierInvoiceRequestOptions
                supplierRequest={supplierRequest}
                index={index}
                onSupplierRate={onSupplierRate}
                value={value}
                supplierHaveAccountEmail={supplierHaveAccountEmail}
                hideRequestOptions={hideRequestOptions}
              />
            </SupplierRow>
            {!supplierHaveAccountEmail && (
              <SupplierWithoutEmailErrorComponent>
                Supplier has no email on file
              </SupplierWithoutEmailErrorComponent>
            )}
          </div>
        ),
      )}
      {!!error && <InputErrorMsg>{error}</InputErrorMsg>}
    </Wrapper>
  );
}

SupplierRatings.displayName = 'SupplierRatings';

SupplierRatings.propTypes = {
  error: string,
  value: arrayOf(
    shape({
      supplierId: string,
      supplierName: string,
      rating: number,
    }),
  ).isRequired,
  onChange: func.isRequired,
};

SupplierRatings.defaultProps = { error: null };
