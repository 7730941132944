import * as R from 'ramda';
import { calculateTotal, pathEqLegacy, propEqLegacy } from '@poly/utils';
import {
  JournalPaymentMode,
  SystemAccountTypes,
  NOTHING_UI_STRING,
  JournalTypes,
  collectionNames,
} from '@poly/constants';

import { transactionTypeUILabels } from '../../modules/core/constants/journals.js';

// isBankJournalLine :: JournalLinePostgre -> Boolean
export const isBankJournalLine = R.pathSatisfies(
  R.includes(R.__, [
    SystemAccountTypes.CREDIT_CARD,
    SystemAccountTypes.BANK_ACCOUNT,
  ]),
  ['account', 'accountType', 'system_type'],
);

const transactionPaymentTypeMap = {
  [JournalPaymentMode.CHECK]: 'Check',
  [JournalPaymentMode.ACH]: 'ACH',
  [JournalPaymentMode.WIRE]: 'Wire',
  [JournalPaymentMode.CREDIT_CARD]: 'Credit Card',
};

export const getPaidByForJournal = R.compose(
  R.propOr(NOTHING_UI_STRING, R.__, transactionPaymentTypeMap),
  R.prop('payment_mode'),
);

// isCashBasisLine :: JournalLine -> Boolean
export const isCashBasisLine = R.converge(R.equals, [
  R.compose(R.negate, R.prop('cash_amount')),
  R.prop('accrual_amount'),
]);

export const transactionsDetailsSidebarId = 'transactionsDetailsSidebarId';

export const sidebarWidthByTransactionType = R.ifElse(
  R.includes(R.__, [
    JournalTypes.ACC_PAYABLE_PAYMENT,
    JournalTypes.ACC_RECEIVABLE_PAYMENT,
    JournalTypes.PAYROLL,
  ]),
  R.always(950),
  R.always(530),
);

// getTransactionTypeByCollection :: Collection -> String
const getTransactionTypeByCollection = R.cond([
  [R.equals(collectionNames.clients), R.always('Client Payment')],
  [R.equals(collectionNames.suppliers), R.always('Supplier Payment')],
  [R.equals(collectionNames.users), R.always('Employee Payment')],
  [R.T, R.always('Other Payment')],
]);

// formatJournalType :: Journal -> String
const formatJournalTypeBase = R.ifElse(
  propEqLegacy('type', JournalTypes.MANUAL),
  R.compose(getTransactionTypeByCollection, R.prop('collection')),
  R.compose(R.prop(R.__, transactionTypeUILabels), R.prop('type')),
);

// formatJournalType :: Journal -> String
export const formatJournalType = R.ifElse(
  propEqLegacy('type', JournalTypes.REVERSE),
  R.compose(
    R.concat(R.__, ' reversal'),
    formatJournalTypeBase,
    R.prop('original_journal'),
  ),
  formatJournalTypeBase,
);

// isArJournalLine :: String -> JournalLine -> Boolean
export const isArJournalLine = (defaultARAccountCode) =>
  pathEqLegacy(['account', 'code'], defaultARAccountCode);

// getClientInvoiceWithAmountsByJournal :: String -> Journal -> ClientInvoice
export const getClientInvoiceWithAmountsByJournal = (defaultARAccountCode) =>
  R.converge(R.mergeRight, [
    R.prop('clientInvoice'),
    R.applySpec({
      paidAmount: R.compose(
        R.prop('cash_amount'),
        R.find(isBankJournalLine),
        R.prop('lines'),
      ),
      deductionsAmount: R.compose(
        R.prop('cash_amount'),
        R.find(
          R.complement(
            R.either(isBankJournalLine, isArJournalLine(defaultARAccountCode)),
          ),
        ),
        R.reject(isCashBasisLine),
        R.prop('lines'),
      ),
    }),
  ]);

// getCreatorNameUIByJournal :: JournalPostgre -> String
export const getCreatorNameUIByJournal = R.pathOr(NOTHING_UI_STRING, [
  'created_by',
  'profile',
  'fullName',
]);

// transformJournalsToTransactionInfo :: String -> [Journal] -> Object
export const transformJournalsToTransactionInfo = (defaultARAccountCode) =>
  R.converge(R.mergeRight, [
    R.compose(
      R.applySpec({
        invoices: R.identity,
        paidTotal: calculateTotal(R.prop('paidAmount')),
        deductionsTotal: calculateTotal(R.prop('deductionsAmount')),
      }),
      R.sortBy(R.prop('number')),
      R.map(getClientInvoiceWithAmountsByJournal(defaultARAccountCode)),
    ),
    R.compose(
      R.applySpec({
        transaction_number: R.prop('transaction_number'),
        account: R.compose(
          R.path(['account', 'name']),
          R.find(isBankJournalLine),
          R.prop('lines'),
        ),
        bankClearedDate: R.compose(
          R.prop('bank_cleared_date'),
          R.find(isBankJournalLine),
          R.prop('lines'),
        ),
        date: R.prop('date'),
        description: R.prop('description'),
        createdBy: getCreatorNameUIByJournal,
      }),
      R.head,
    ),
  ]);
