import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState } from 'react';
import { shape, string } from 'prop-types';
import { SidebarRow, CommonSidebarFormWrapper } from '@poly/admin-ui';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { UPDATE_CLIENT_PERMISSION } from '@poly/security';
import { formatTotal } from '@poly/utils';
import {
  ColumnList,
  TabSectionWrapper,
  EditClientTabButton,
} from './commonComponents.js';
import {
  staffRatesPropType,
  EditClientRatesForm,
} from './EditClientRatesForm.js';
import { editClientStaffRatesFormId } from '../constants.js';
import { BlockWithLabel } from '../../components/commonSidebarComponents.js';
import { getSectionText } from '../../components/commonSidebarSectionFormatters.js';

const SidebarRowS = styled(SidebarRow)`
  margin-top: 14px;
`;

// getAfterHoursCall :: Client -> String
const getAfterHoursCall = R.compose(
  formatTotal,
  R.path(['staffRates', 'afterHoursCall']),
);

// formatCustomRatesByProp :: (String, Function) -> Client -> [String]
const formatCustomRatesByProp = (prop, formatter = R.identity) =>
  R.compose(
    R.map(R.compose(formatter, R.prop(prop))),
    R.pathOr([], ['staffRates', 'customRates']),
  );

// formatCustomRatesTotal :: String -> Client -> [String]
const formatCustomRatesTotal = (prop) =>
  formatCustomRatesByProp(prop, formatTotal);

export function RatesTab({ client }) {
  const [showEditForm, setShowEditForm] = useState(false);
  const hasAccess = useHasUserAccessWithPermission(UPDATE_CLIENT_PERMISSION);

  const onCancel = () => setShowEditForm(false);

  return (
    <TabSectionWrapper>
      <SidebarRow justify>
        <BlockWithLabel
          id="after-hours-call"
          label="After Hours Call"
          Component={getSectionText(getAfterHoursCall(client))}
        />
        {hasAccess && (
          <EditClientTabButton onClick={() => setShowEditForm(true)}>
            edit
          </EditClientTabButton>
        )}
      </SidebarRow>
      {showEditForm && (
        <CommonSidebarFormWrapper
          onCancel={onCancel}
          title="Edit ESFM Staff Rates"
          formId={editClientStaffRatesFormId}
        >
          <EditClientRatesForm
            onCancel={onCancel}
            clientId={client._id}
            staffRates={client.staffRates}
            formId={editClientStaffRatesFormId}
          />
        </CommonSidebarFormWrapper>
      )}
      <SidebarRowS justify>
        <BlockWithLabel
          width="150px"
          id="description"
          label="Description"
          Component={ColumnList}
          list={formatCustomRatesByProp('description')(client)}
        />
        <BlockWithLabel
          id="rate"
          label="Rate"
          Component={ColumnList}
          list={formatCustomRatesTotal('rate')(client)}
        />
        <BlockWithLabel
          id="overtime"
          label="Overtime"
          Component={ColumnList}
          list={formatCustomRatesTotal('overtime')(client)}
        />
        <BlockWithLabel
          id="double-time"
          label="Double Time"
          Component={ColumnList}
          list={formatCustomRatesTotal('doubleTime')(client)}
        />
      </SidebarRowS>
    </TabSectionWrapper>
  );
}

RatesTab.propTypes = {
  client: shape({
    _id: string.isRequired,
    staffRates: staffRatesPropType,
  }),
};
