import React, { useState, useEffect } from 'react';
import { ASC_SORT_ORDER } from '@poly/constants';
import {
  concat,
  includes,
  curry,
  ifElse,
  is,
  map,
  nth,
  path,
  prop,
  without,
} from 'ramda';

import {
  isScrolledToBottom,
  sortTableRows,
  toTableColumns,
  toTableHeaders,
  updateGraphSorting,
  updateSorting,
} from './utils.js';

const getInitialSort = (column, order, tableConfig) => {
  const columnKey = column - 1;
  const getSortQuery = path([columnKey, 2], tableConfig);
  if (typeof getSortQuery !== 'function') {
    return {};
  }
  return {
    dir: order === ASC_SORT_ORDER ? 1 : -1,
    query: getSortQuery(order),
    columnKey,
  };
};

export const applyTableSort =
  ({ tableConfig, column, order = ASC_SORT_ORDER, isSortable = true }) =>
  (Component) =>
    function (props) {
      const [sorting, setSorting] = useState(
        getInitialSort(column, order, tableConfig),
      );
      return (
        <Component
          {...props}
          sorting={sorting}
          sort={sorting.query}
          onHeaderCellClick={(columnKey, getSortQuery) => {
            if (typeof getSortQuery === 'function') {
              const newSorting = updateGraphSorting(
                getSortQuery,
                columnKey,
                sorting,
              );
              setSorting(newSorting);
            }
          }}
          isServerSorting
          isSortable={isSortable}
        />
      );
    };

export const useTableSortable = ({ rows, sortQueries, setEntity }) => {
  const [sorting, setSorting] = useState({ dir: 1, columnKey: 0 });

  const onHeaderCellClick = (headerCellNum) => {
    if (sortQueries[headerCellNum]) {
      setSorting(updateSorting(headerCellNum, sorting));
    }
    if (setEntity) {
      setEntity({ tableSorting: updateSorting(headerCellNum, sorting) });
    }
  };
  const newRows = sorting ? sortTableRows(sortQueries, sorting)(rows) : rows;

  return { rows: newRows, isSortable: true, onHeaderCellClick, sorting };
};

export const mapConfigToTableProps = curry(
  (getRows, config) => (Component) =>
    function (props) {
      const checkedConfig = is(Function, config) ? config(props) : config;
      return (
        <Component
          {...props}
          rows={getRows(props)}
          headers={toTableHeaders(checkedConfig)}
          columns={toTableColumns(checkedConfig)}
          sortQueries={map(nth(2), checkedConfig)}
        />
      );
    },
);

export const handleToggleRow = (rowId) =>
  ifElse(includes(rowId), without([rowId]), concat([rowId]));

export const useSelectableTable = ({
  setSelectedRows,
  selectedRows,
  rows,
  isRowSelectable,
  selectAll = () => {},
  dispatch,
}) => {
  useEffect(
    () => () => {
      if (selectedRows.length) {
        dispatch(setSelectedRows([]));
      }
    },
    [],
  );

  const toggleRow = (rowId) =>
    dispatch(setSelectedRows(handleToggleRow(rowId)(selectedRows)));

  const toggleSelectAll = () => {
    if (selectedRows.length) {
      dispatch(setSelectedRows([]));
    } else {
      dispatch(setSelectedRows(map(prop('_id'), rows)));
      selectAll();
    }
  };

  return {
    setSelectedRows,
    selectedRows,
    isRowSelectable,
    toggleRow,
    toggleSelectAll,
    isPartiallySelectable: !!isRowSelectable,
  };
};

export const useInfiniteTable = (loadMore) => ({
  onScroll: (e) => isScrolledToBottom(e.target, 10) && loadMore(),
});
