import * as R from 'ramda';
import { supplierEmailsTypes, supplierPhoneTypes } from '@poly/constants';

import { isNilOrEmpty } from './general.js';
import { propEqLegacy } from './ramdaLegacyFunctions.js';

// supplierHaveEmailByType :: String -> Supplier -> Boolean
const supplierHaveEmailByType = (emailType) =>
  R.compose(
    R.is(String),
    R.prop('email'),
    R.defaultTo({}),
    R.find(propEqLegacy('type', emailType)),
    R.pathOr([], ['company', 'emails']),
  );

// supplierHaveServiceEmail :: Supplier -> Boolean
export const supplierHaveServiceEmail = supplierHaveEmailByType(
  supplierEmailsTypes.SERVICE,
);

// supplierHaveAccountEmail :: Supplier -> Boolean
export const supplierHaveAccountEmail = supplierHaveEmailByType(
  supplierEmailsTypes.ACCOUNT,
);

// supplierHaveAccountOrServiceEmail :: Supplier -> Boolean
export const supplierHaveAccountOrServiceEmail = R.either(
  supplierHaveEmailByType(supplierEmailsTypes.ACCOUNT),
  supplierHaveEmailByType(supplierEmailsTypes.SERVICE),
);

// supplierHaveMobilePhone :: Supplier -> Boolean
export const supplierHaveMobilePhone = R.compose(
  R.is(String),
  R.prop('phone'),
  R.defaultTo({}),
  R.find(propEqLegacy('type', supplierPhoneTypes.MOBILE)),
  R.pathOr([], ['company', 'phones']),
);

// getSupplierEmailByType :: Supplier -> String
const getSupplierEmailByType = (type) =>
  R.compose(
    R.propOr(null, 'email'),
    R.find(propEqLegacy('type', type)),
    R.pathOr([], ['company', 'emails']),
  );

// getDocumentRequestEmailBySupplier :: Supplier -> String
export const getDocumentRequestEmailBySupplier = (supplier) =>
  R.compose(
    R.when(
      isNilOrEmpty,
      R.compose(
        getSupplierEmailByType(supplierEmailsTypes.SERVICE),
        R.always(supplier),
      ),
    ),
    getSupplierEmailByType(supplierEmailsTypes.ACCOUNT),
  )(supplier);

// getSupplierServicePhone :: Supplier -> String
export const getSupplierServicePhone = R.compose(
  R.prop('phone'),
  R.find(propEqLegacy('type', supplierPhoneTypes.MOBILE)),
  R.pathOr([], ['company', 'phones']),
);
