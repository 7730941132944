import * as R from 'ramda';

export const getThemeProp = (path) => R.path(['theme', ...path]);
export const getThemeColor = (path) => getThemeProp(['colors', ...path]);
export const getThemeFont = (path) => getThemeProp(['fonts', ...path]);

/**
 * getTabsLength :: [String || ReactElement] => Number => ReactElement => Number
 */
export const getTabsLength = R.curry((tabs, multiplier, toolBar = null) =>
  R.pipe(
    R.map(
      R.ifElse(
        R.propSatisfies(R.is(String), 0),
        R.path([0, 'length']),
        R.path([0, 'props', 'name', 'length']),
      ),
    ),
    R.reduce(R.add, 0),
    R.multiply(multiplier),
    (size) => size + (R.isNil(toolBar) ? 40 : 240),
  )(tabs),
);
