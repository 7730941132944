import * as R from 'ramda';
import styled from 'styled-components';
import { assocBy, propEqLegacy } from '@poly/utils';
import React, { useState } from 'react';
import { ClientInvoiceStatuses } from '@poly/constants';
import { arrayOf, number, shape, string, func } from 'prop-types';
import { Button, DefaultBodyWrapper, WindowedTable } from '@poly/admin-book';
import {
  useMapConfigToTableProps,
  useSelectableTableRowsProps,
} from '@poly/admin-ui';

import { ButtonsContainer } from '../PrintChecksPage/PrintChecksTable.js';
import { useVoidClientInvoices } from './useVoidClientInvoices.js';
import { CommonSearchClientInvoicesPageTableConfig } from '../SearchClientInvoices/SearchClientInvoicesPageTable.js';
import {
  BottomPanelBody,
  BottomPanelContainer,
} from '../../components/BottomPanel.js';

const WindowedTableS = styled(WindowedTable)`
  th:nth-child(7),
  td:nth-child(7) {
    width: 50px;
  }
`;

const prepareRow = (refetch, selectedClientInvoices) =>
  R.compose(
    R.mergeRight({ refetch }),
    assocBy(
      'isSelected',
      R.propSatisfies(R.includes(R.__, selectedClientInvoices), '_id'),
    ),
  );

// getSelectedInvoices :: [ID] -> [ClientInvoice] -> [ClientInvoice]
const getSelectedInvoices = (selectedIds) =>
  R.filter(R.propSatisfies(R.includes(R.__, selectedIds), '_id'));

// isRowSelectable :: ClientInvoice -> Boolean
const isRowSelectable = propEqLegacy('status', ClientInvoiceStatuses.PENDING);

// getSelectableInvoices :: [ClientInvoice] -> [ClientInvoice]
const getSelectableInvoices = R.filter(isRowSelectable);

export function SearchVoidClientInvoicesPageTable({
  refetch,
  clientInvoices,
  ...restTableProps
}) {
  const [selectedClientInvoices, setSelectedClientInvoices] = useState([]);
  const { onVoidClientInvoices } = useVoidClientInvoices(
    refetch,
    setSelectedClientInvoices,
  );

  const { toggleRow, toggleSelectAll } = useSelectableTableRowsProps(
    selectedClientInvoices,
    setSelectedClientInvoices,
    getSelectableInvoices(clientInvoices),
  );

  const tableProps = useMapConfigToTableProps(
    R.map(prepareRow(refetch, selectedClientInvoices)),
    CommonSearchClientInvoicesPageTableConfig,
    clientInvoices,
  );

  const onSubmit = async () => {
    if (R.isEmpty(selectedClientInvoices)) {
      return;
    }

    const selectedInvoices = getSelectedInvoices(selectedClientInvoices)(
      clientInvoices,
    );

    await onVoidClientInvoices(selectedInvoices);
  };

  return (
    <>
      <DefaultBodyWrapper height="calc(100% - 70px)">
        <WindowedTableS
          {...tableProps}
          {...restTableProps}
          moreScrollPosition="0px"
          selectedRows={selectedClientInvoices}
          isRowSelectable={isRowSelectable}
          toggleSelectAll={toggleSelectAll}
          toggleRow={toggleRow}
          selectedRowsInEnd
        />
      </DefaultBodyWrapper>
      <BottomPanelContainer height="70px">
        <BottomPanelBody>
          <ButtonsContainer>
            <Button
              size="small"
              onClick={onSubmit}
              disabled={R.isEmpty(selectedClientInvoices)}
            >
              Void
            </Button>
          </ButtonsContainer>
        </BottomPanelBody>
      </BottomPanelContainer>
    </>
  );
}

SearchVoidClientInvoicesPageTable.propTypes = {
  clientInvoices: arrayOf(
    shape({
      number,
      createdAt: string,
      dueDate: string,
      amount: number,
      status: string,
      project: shape({
        projectId: string,
      }),
    }),
  ),
  refetch: func.isRequired,
  selectedClientInvoices: arrayOf(string),
  setSelectedClientInvoices: func,
};
