import React from 'react';
import arrayMutators from 'final-form-arrays';
import { string } from 'prop-types';
import { usePersistentFormValue } from '@poly/client-utils';
import { usePristineSubscribe } from '@poly/client-routing';
import { FormCreator, Loader } from '@poly/admin-book';
import {
  commonSidebarFormSectionLayout,
  useOnSubmitSetStopSubmitting,
  commonSidebarFormFieldLayout,
  commonSidebarFormLayout,
} from '@poly/admin-ui';
import {
  ClientSpendReportTypes,
  ClientFinancialTerms,
  ClientStatuses,
  ClientBranding,
  DEFAULT_CLIENT_PROJECT_PRIORITIES,
} from '@poly/constants';

import { addClientFormId } from '../../constants.js';
import { sideBarClientFormSections } from '../form/sideBarClientFormSections.js';
import { useDefaultClientInvoiceAccount } from '../../../../hooks/useDefaultClientInvoiceAccount.js';
import { useOnSubmitClientFrom } from './useOnSubmitForm.js';

export function AddClientSideBarForm({ parentClientId }) {
  const { loading, divisionAccountId } = useDefaultClientInvoiceAccount();

  const {
    onChangePersistentValue,
    cleanupRetainedValue,
    retainedValue: remarks,
  } = usePersistentFormValue('remarks.new');

  const pristineSubscribeProps = usePristineSubscribe({ id: addClientFormId });

  const onSubmitHandler = useOnSubmitClientFrom(
    parentClientId,
    cleanupRetainedValue,
  );
  const { onSubmit } = useOnSubmitSetStopSubmitting(
    addClientFormId,
    onSubmitHandler,
  );

  if (loading) return <Loader />;

  const initialValues = {
    status: ClientStatuses.ACTIVE,
    financial: {
      vaSplitPercent: 0,
      divisionAccountId,
      terms: ClientFinancialTerms.net_30,
      spendReportType: ClientSpendReportTypes.NON_TRANSPARENT,
    },
    contractFile: [],
    clientHasChildren: false,
    isChildClient: !!parentClientId,
    enableChildAccounts: false,
    isUMCAccountFormat: false,
    configs: {
      logoImage: [],
      requestsApp: { branding: ClientBranding.POLY },
      clientPortalApp: { allowProjectCreation: true, showPMsAndAssets: true },
      projectPriorities: DEFAULT_CLIENT_PROJECT_PRIORITIES,
      assetScannerApp: {
        allowReplacementCost: false,
        allowProjectCreation: true,
      },
      clientInvoicing: {
        companyLogo: [],
        reportOnlyClientInvoiceCreation: false,
      },
      adminApp: {
        allowProjectCreation: true,
      },
    },
    ...(remarks ? { remarks } : {}),
  };

  return (
    <FormCreator
      id={addClientFormId}
      layout={commonSidebarFormLayout}
      fieldLayout={commonSidebarFormFieldLayout}
      sectionLayout={commonSidebarFormSectionLayout}
      initialValues={initialValues}
      sections={sideBarClientFormSections(onChangePersistentValue)}
      onSubmit={onSubmit}
      mutators={{ ...arrayMutators }}
      {...pristineSubscribeProps}
    />
  );
}

AddClientSideBarForm.propTypes = {
  parentClientId: string,
};
