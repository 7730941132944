import React from 'react';
import * as R from 'ramda';

import { SidebarIDs } from '../../constants.js';
import { assetPropTypes } from './assetPropTypes.js';
import { assetTabsSidebarId } from '../constants.js';
import { AssetSidebarPMsTab } from './AssetSidebarPMsTab.js';
import { SidebarTabs } from '../../components/SidebarTabs.js';
import { assetSidebarTabs } from '../../../routes/constants.js';
import { AssetSidebarFilesTab } from './AssetSidebarFilesTab.js';
import { AssetSidebarUpdatesTab } from './AssetSidebarUpdatesTab.js';
import { AssetSidebarProjectsTab } from './AssetSidebarProjectsTab.js';
import { AssetSidebarProceduresTab } from './AssetSidebarProceduresTab.js';
import { formatAssetTitle } from '../assetSidebarUtils.js';

export function AssetSidebarTabs({ asset }) {
  const assetId = R.prop('_id', asset);
  const assetName = formatAssetTitle(asset);

  return (
    <SidebarTabs
      id={assetTabsSidebarId}
      params={[SidebarIDs.asset]}
      defaultValue={assetSidebarTabs.assetUpdates}
      tabs={[
        [
          'Projects',
          assetSidebarTabs.assetProjects,
          <AssetSidebarProjectsTab {...{ asset }} />,
        ],
        [
          'PMs',
          assetSidebarTabs.assetPMs,
          <AssetSidebarPMsTab {...{ asset }} />,
        ],
        [
          'Updates',
          assetSidebarTabs.assetUpdates,
          <AssetSidebarUpdatesTab {...{ assetId, assetName }} />,
        ],
        [
          'Files',
          assetSidebarTabs.assetFiles,
          <AssetSidebarFilesTab
            assetId={assetId}
            key={assetSidebarTabs.assetFiles}
          />,
        ],
        [
          'Procedures',
          assetSidebarTabs.assetProcedures,
          <AssetSidebarProceduresTab
            assetId={assetId}
            key={assetSidebarTabs.assetProcedures}
          />,
        ],
      ]}
    />
  );
}

AssetSidebarTabs.propTypes = {
  asset: assetPropTypes,
};
