import React from 'react';
import styled from 'styled-components';
import { func, shape, string, bool } from 'prop-types';
import {
  FieldLayout,
  LinkButton,
  Textarea,
  Checkbox,
  Input,
} from '@poly/admin-book';

import {
  FlexRow,
  FlexColumn,
} from '../../components/appBar/HistoryPopUp/historyComponents.js';

const ProcedureStepLineWrapper = styled(FlexColumn)`
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #f2f2f2;
`;

const ProcedureStepLineRow = styled(FlexRow)`
  justify-content: ${({ justify }) => justify || 'flex-start'};
`;

export function ProcedureStepLine({
  onRemoveStep,
  skipDeleteBtn,
  onIsRequiredStepChange,
  fields: { name, value },
}) {
  return (
    <ProcedureStepLineWrapper>
      <ProcedureStepLineRow justify="space-between">
        <FieldLayout
          required
          layout={{ width: '29%' }}
          field={{
            Component: Input,
            name: `${name}.name`,
            additionalProps: {
              charactersLimit: 100,
              showCharactersLeft: true,
              placeholder: 'Enter Step Name',
            },
          }}
        />
        <FieldLayout
          required
          layout={{ width: '69%' }}
          field={{
            name: `${name}.description`,
            Component: Textarea,
            additionalProps: {
              extendable: true,
              charactersLimit: 500,
              showCharactersLeft: true,
              placeholder: 'Enter Step Description',
            },
          }}
        />
      </ProcedureStepLineRow>
      <ProcedureStepLineRow justify="space-between">
        <ProcedureStepLineRow>
          <FieldLayout
            layout={{ width: 'auto' }}
            field={{
              name: `${name}.isRequiredStep`,
              Component: Checkbox,
              additionalProps: {
                label: 'Step is mandatory',
                onChange: (currentValue) =>
                  onIsRequiredStepChange({ currentValue, value }),
              },
            }}
          />
          {value?.isRequiredStep && (
            <FieldLayout
              layout={{ width: 'auto', margin: '0 0 0 15px' }}
              field={{
                name: `${name}.isImageUploadRequired`,
                Component: Checkbox,
                additionalProps: { label: 'Image upload is mandatory' },
              }}
            />
          )}
        </ProcedureStepLineRow>
        {!skipDeleteBtn && (
          <ProcedureStepLineRow>
            <LinkButton onClick={onRemoveStep}>Delete</LinkButton>
          </ProcedureStepLineRow>
        )}
      </ProcedureStepLineRow>
    </ProcedureStepLineWrapper>
  );
}

ProcedureStepLine.propTypes = {
  onRemoveStep: func.isRequired,
  skipDeleteBtn: bool.isRequired,
  fields: shape({ name: string }),
  onIsRequiredStepChange: func.isRequired,
};
