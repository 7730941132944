import { useMemo } from 'react';
import * as R from 'ramda';
import { gql } from '@apollo/client';
import { startOfDay, endOfDay } from 'date-fns';
import { useTableInfiniteScrollQuery } from '@poly/client-utils';
import { ensureIsDate } from '@poly/utils';

// formatFilters :: Object -> Object
const formatFilters = R.compose(
  R.evolve({
    startDate: R.compose(startOfDay, ensureIsDate),
    endDate: R.compose(endOfDay, ensureIsDate),
  }),
  R.omit(['date']),
  R.converge(R.mergeRight, [R.identity, R.prop('date')]),
);

const accountTransactionsQuery = gql`
  query accountTransactions($input: AccountTransactionsInput) {
    accountTransactions(input: $input) {
      forward_balance
      debits
      credits
      hits {
        amount
        balance
        journal {
          date
          type
          journal_number
          transaction_number
          collection
          description
          payment_mode
          payee_name
          supplier {
            _id
            company {
              name
            }
          }
          original_journal {
            _id
            type
            client {
              name
            }
          }
          client {
            name
          }
        }
        invoiceNumber
        split_accounts {
          _id
          name
        }
      }
    }
  }
`;

export const useAccountTransactionsQuery = (generalLedgerFilters) => {
  const input = useMemo(
    () => formatFilters(generalLedgerFilters),
    [generalLedgerFilters],
  );

  const { data, loading, tableProps } = useTableInfiniteScrollQuery(
    accountTransactionsQuery,
    input,
    {
      endpointName: 'accountTransactions',
      pageSize: 200,
      skip: !generalLedgerFilters?.accountId,
      pageFromFields: [
        { fieldName: 'lastJournalDate', valuePath: ['journal', 'date'] },
        {
          fieldName: 'lastJournalNumber',
          valuePath: ['journal', 'journal_number'],
        },
      ],
    },
  );
  return { data, loading, tableProps };
};
