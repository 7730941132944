import React, { useState } from 'react';
import * as R from 'ramda';
import { ChooseFileField, useNotificationState } from '@poly/admin-ui';
import {
  FormCreator,
  getThemeColor,
  InputErrorMsg,
  Loader,
  Text,
} from '@poly/admin-book';
import { gql, useMutation, useQuery } from '@apollo/client';
import { bool, func, shape, string } from 'prop-types';
import { getExtensionByFileName, isNilOrEmpty } from '@poly/utils';
import downloadFile from 'downloadjs';
import styled from 'styled-components';
import { PolyMultiLineError } from '@poly/constants';
import { usePristineSubscribe } from '@poly/client-routing';
import { base64ToBlob } from '@poly/client-utils';

import { importAssetsFormId } from '../../constants.js';

const IMPORT_ASSETS_MUTATION = gql`
  mutation IMPORT_ASSETS_MUTATION($input: ImportAssetsInput!) {
    importAssets(input: $input) {
      importedActiveAssetsCount
      importedPendingAssetsCount
    }
  }
`;

const IMPORT_ASSETS_TEMPLATE = gql`
  query IMPORT_ASSETS_TEMPLATE {
    assetImportTemplate {
      templateBase64
    }
  }
`;

// validateFileExtension :: File -> Boolean
const validateFileExtension = R.compose(
  R.includes(R.__, 'csv'),
  R.toLower,
  getExtensionByFileName,
  R.prop('name'),
);

function ChooseFileFieldComp({ meta, ...props }) {
  const errorMsg = meta?.touched && meta?.error;

  return (
    <>
      <ChooseFileField {...props} />
      {errorMsg && <InputErrorMsg>{errorMsg}</InputErrorMsg>}
    </>
  );
}

ChooseFileFieldComp.propTypes = {
  meta: shape({
    touched: bool,
    error: string,
  }),
};

const CSVTemplateBtnS = styled.span`
  color: ${getThemeColor(['accent2'])};
  font-size: 14px;
  cursor: pointer;
  margin: 0 5px;
`;

const TextS = styled.span`
  font-size: 12px;
  line-height: 18px;
  color: ${getThemeColor(['darkest'])};
  margin-top: 15px;
`;

const ErrorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin: 30px 0 10px 0;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #cfcece;
  }
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 120px;
`;

const FormWrapper = styled.div`
  height: calc(100vh - 190px);
  padding: 10px 24px;
  display: flex;
  flex-direction: column;
`;

const FormCreatorS = styled(FormCreator)`
  height: 70px;
`;

function DownloadImportAssetTemplate() {
  const [isSlipQuery, setIsSlipQuery] = useState(true);

  const { data, loading } = useQuery(IMPORT_ASSETS_TEMPLATE, {
    fetchPolicy: 'network-only',
    skip: isSlipQuery,
  });

  const templateBase64 = R.path(
    ['assetImportTemplate', 'templateBase64'],
    data,
  );

  if (templateBase64 && !loading) {
    downloadFile(
      base64ToBlob(templateBase64, 'text/csv'),
      'import_assets_template.csv',
    );
    setIsSlipQuery(true);
  }

  return (
    <TextS>
      Please use
      <CSVTemplateBtnS onClick={() => setIsSlipQuery(false)}>
        CSV
      </CSVTemplateBtnS>
      template if you want to import assets
    </TextS>
  );
}

// getErrorsByMultiLineError :: String -> [String]
const getErrorsByMultiLineError = R.compose(
  R.split(PolyMultiLineError.SPLIT),
  R.replace(PolyMultiLineError.PREFIX, ''),
);

// getImportedAssetCounts :: { data: { importAssets: ImportAssetsResult } } -> { active: Int, pending: Int }
const getImportedAssetCounts = R.compose(
  R.applySpec({
    active: R.propOr(0, 'importedActiveAssetsCount'),
    pending: R.propOr(0, 'importedPendingAssetsCount'),
  }),
  R.path(['data', 'importAssets']),
);

export function ImportAssetsForm({ propertyId, clientId, onCancel }) {
  const pristineSubscribeProps = usePristineSubscribe({
    id: importAssetsFormId,
    propertyId,
  });

  const [importAssets] = useMutation(IMPORT_ASSETS_MUTATION);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { showSuccessNotification, showWarningNotification } =
    useNotificationState();

  const formSections = [
    {
      order: 1,
      fields: [
        {
          order: 1,
          label: 'Assets import file',
          layout: { row: 1, width: '100%' },
          field: {
            name: 'file',
            Component: ChooseFileFieldComp,
          },
          validators: [
            [R.complement(isNilOrEmpty), 'File is required'],
            [validateFileExtension, 'File extension must be csv'],
          ],
        },
      ],
    },
  ];

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const res = await importAssets({
        variables: {
          input: {
            ...(clientId ? { clientId } : { propertyId }),
            ...values,
          },
        },
      });
      const { active, pending } = getImportedAssetCounts(res);
      const allAssets = active + pending;

      showSuccessNotification(
        `${active}/${allAssets} Assets were Imported and are active.`,
      );

      if (pending > 0) {
        showWarningNotification(
          `${pending}/${allAssets} Assets were Imported and are pending review`,
        );
      }

      setErrors([]);
      setTimeout(onCancel);
    } catch (e) {
      setErrors(getErrorsByMultiLineError(e.message));
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader size={30} />
      </LoaderWrapper>
    );
  }

  return (
    <FormWrapper>
      {propertyId && (
        <Text size="12px" color={['notificator', 'warning', 'text']}>
          * Please mind all the Property Card Numbers in the csv will be
          ignored, and all the assets will be added to the property.
        </Text>
      )}
      <DownloadImportAssetTemplate />
      <FormCreatorS
        id={importAssetsFormId}
        initialValues={{ file: null }}
        onSubmit={handleSubmit}
        sections={formSections}
        layout={{
          overflow: 'visible',
          card: false,
        }}
        {...pristineSubscribeProps}
      />
      {errors.length > 0 && (
        <ErrorsWrapper>
          {errors.map((errorMsg) => (
            <InputErrorMsg key={errorMsg}>{errorMsg}</InputErrorMsg>
          ))}
        </ErrorsWrapper>
      )}
    </FormWrapper>
  );
}

ImportAssetsForm.propTypes = {
  clientId: string,
  propertyId: string,
  onCancel: func.isRequired,
};
