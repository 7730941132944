import React from 'react';
import { TextButton } from '@poly/admin-book';
import { bool, shape, string } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { useOutSidebarContext } from '@poly/client-utils';
import { useModalContext, useNotificationState } from '@poly/admin-ui';

import { editPropertyFormId } from '../../../constants.js';
import { useSidebarLogicContext } from '../../../../SidebarLogicContext.js';

const convertToMasterPropertyMutation = gql`
  mutation convertToMasterPropertyMutation($id: ID!) {
    convertToMasterProperty(id: $id) {
      _id
    }
  }
`;

export function ConvertToMasterPropertyButton({ formData, isCard }) {
  const { closeOutSidebar } = useOutSidebarContext();
  const { openPropertySidebar } = useSidebarLogicContext();
  const { showSuccessNotification } = useNotificationState();
  const { openConfirmSubmitFormModal } = useModalContext();
  const [convertToMasterProperty] = useMutation(
    convertToMasterPropertyMutation,
  );

  const onClick = () =>
    openConfirmSubmitFormModal({
      content: (
        <div>
          Are you sure you want to convert the property to master property?
        </div>
      ),
      btnCaption: 'Convert to Master',
      processId: 'convertToMasterPropertyFormId',
      onConfirm: (closeConfirmModal) => async () => {
        await convertToMasterProperty({ variables: { id: formData._id } });
        showSuccessNotification('Property successfully converted');
        closeConfirmModal();
        closeOutSidebar(editPropertyFormId);

        if (!isCard) {
          openPropertySidebar(formData._id);
        }
      },
    });

  return <TextButton onClick={onClick}>Master Property</TextButton>;
}

ConvertToMasterPropertyButton.propTypes = {
  isCard: bool,
  formData: shape({
    _id: string,
  }),
};
