import React from 'react';
import * as R from 'ramda';
import arrayMutators from 'final-form-arrays';
import { func, shape, bool } from 'prop-types';
import { usePristineSubscribe } from '@poly/client-routing';
import { FormCreator } from '@poly/admin-book';
import { isNilOrEmpty } from '@poly/utils';
import {
  commonSidebarFormSectionLayout,
  useOnSubmitSetStopSubmitting,
  commonSidebarFormFieldLayout,
  commonSidebarFormLayout,
} from '@poly/admin-ui';
import { usePersistentFormValue } from '@poly/client-utils';

import { editClientFormId } from '../../constants.js';
import { SidebarFormLayout } from '../../../components/SidebarFormLayout.js';
import { sideBarClientFormSections } from '../form/sideBarClientFormSections.js';
import { useOnSubmitEditClientForm } from './useOnSubmitEditClientForm.js';
import { clientToEditForm } from './clientToEditForm.js';

// checkClientHasChildren :: Client -> Boolean
const checkClientHasChildren = R.propSatisfies(
  R.complement(isNilOrEmpty),
  'childClients',
);

// checkIsChildClient :: Client -> Boolean
const checkIsChildClient = R.propSatisfies(
  R.complement(isNilOrEmpty),
  'parentClient',
);

// isClientInvoicingEmailEnabled :: Client -> Boolean
const isClientInvoicingEmailEnabled = R.path([
  'configs',
  'clientInvoicing',
  'email',
  'enabled',
]);

function EditClientForm({ client, isCard }) {
  const {
    onChangePersistentValue,
    cleanupRetainedValue,
    retainedValue: remarks,
  } = usePersistentFormValue(`remarks.${client._id}`);

  const pristineSubscribeProps = usePristineSubscribe({
    id: editClientFormId,
    client,
  });
  const onSubmitHandler = useOnSubmitEditClientForm(
    isCard,
    cleanupRetainedValue,
  );
  const { onSubmit } = useOnSubmitSetStopSubmitting(
    editClientFormId,
    onSubmitHandler,
  );

  const isChildClient = checkIsChildClient(client);
  const clientHasChildren = !isChildClient && checkClientHasChildren(client);
  const isInvoicingEmailEnabled = isClientInvoicingEmailEnabled(client);

  const initialValues = {
    ...clientToEditForm(client),
    isChildClient,
    clientHasChildren,
    ...(remarks ? { remarks } : {}),
    isInvoicingEmailEnabled,
  };
  const handleSubmit = (update) => onSubmit({ client, update });

  return (
    <FormCreator
      id={editClientFormId}
      layout={commonSidebarFormLayout}
      fieldLayout={commonSidebarFormFieldLayout}
      sectionLayout={commonSidebarFormSectionLayout}
      initialValues={initialValues}
      sections={sideBarClientFormSections(onChangePersistentValue)}
      onSubmit={handleSubmit}
      mutators={{ ...arrayMutators }}
      {...pristineSubscribeProps}
    />
  );
}

EditClientForm.propTypes = {
  isCard: bool,
  client: shape({}),
};

export function EditClientSidebarForm({ onClose, ...props }) {
  return (
    <SidebarFormLayout
      onClose={onClose}
      title="Edit Client"
      formId={editClientFormId}
      submitButtonCaption="Save"
    >
      <EditClientForm {...props} />
    </SidebarFormLayout>
  );
}

EditClientSidebarForm.propTypes = {
  onClose: func.isRequired,
};
