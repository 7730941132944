import { defaultTheme } from '@poly/admin-book';
import { TaskDueDateFilterPreset } from '@poly/constants';

const {
  colors: {
    statuses: { active, error },
  },
} = defaultTheme;

export const taskStatusesUI = {
  [TaskDueDateFilterPreset.DUE_TODAY]: 'Due Today',
  [TaskDueDateFilterPreset.DUE_TOMORROW]: 'Due Tomorrow',
  [TaskDueDateFilterPreset.PAST_DUE]: 'Past Due',
  [TaskDueDateFilterPreset.READY_TO_CLOSE]: 'Ready to Close',
  [TaskDueDateFilterPreset.ACTIVE]: 'Active',
  [TaskDueDateFilterPreset.ALL]: 'All',
};

export const taskStatusesColors = {
  [TaskDueDateFilterPreset.ACTIVE]: active,
  [TaskDueDateFilterPreset.DUE_TODAY]: active,
  [TaskDueDateFilterPreset.DUE_TOMORROW]: active,
  [TaskDueDateFilterPreset.PAST_DUE]: error,
};

export const taskTypes = {
  PROJECT: 'Project',
  RECURRING_PROJECT: 'Recurring Project',
  PROPERTY: 'Property',
  CLIENT: 'Client',
  CONTRACT: 'Contract',
  SUPPLIER: 'Supplier',
  PERSONAL: 'Personal',
  MASTER_SUPPLIER: 'Master Supplier',
  UNDEFINED: 'Undefined',
};
