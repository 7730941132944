import * as R from 'ramda';
import { gql } from '@apollo/client';
import { CLIENTS_BY_SEARCH_SUB, MAX_ITEMS } from '@poly/admin-ui';
import { useReactiveQuery } from '@poly/client-utils';

const CLIENT_CHILD_ACCOUNTS = gql`
  query CLIENT_CHILD_ACCOUNTS($searchInput: CollectionSearchParams!) {
    searchClients(input: $searchInput) {
      hits {
        _id
        name
        status
      }
      total
    }
  }
`;

export const useClientChildAccountsQuery = (clientId, status) => {
  const queryOptions = {
    variables: {
      searchInput: {
        from: 0,
        size: MAX_ITEMS,
        query: {
          bool: {
            must: [
              { term: { status } },
              { term: { parentClientId: clientId } },
            ],
          },
        },
      },
    },
    fetchPolicy: 'no-cache',
  };

  const { data, loading } = useReactiveQuery(
    CLIENT_CHILD_ACCOUNTS,
    CLIENTS_BY_SEARCH_SUB,
    {
      queryOptions,
      subscriptionOptions: queryOptions,
    },
  );

  const count = R.pathOr(0, ['searchClients', 'total'], data);

  const accounts = R.pathOr([], ['searchClients', 'hits'], data);

  return { loading, count, accounts };
};
