import React from 'react';
import { LinkButton } from '@poly/admin-book';
import { bool } from 'prop-types';
import * as R from 'ramda';
import {
  performExcelExport,
  ExcelExportCellType,
  createExcelExportCell,
  formatFromToDateTitle,
} from '@poly/client-utils';
import { EXPORT_XLS_CAPTION } from '@poly/constants';

import { forceTitleCase, ofArrayLegacy } from '@poly/utils';
import {
  generalLedgerFiltersPropTypes,
  generalLedgerReportPropType,
} from './generalLedgerPropTypes.js';

// getAmountCellByEntryAndProp :: String -> GeneralLedgerReportEntry
//   -> ExcelExportDataCell
const getAmountCellByEntryAndProp = (prop) =>
  R.applySpec({
    value: R.propOr(0, prop),
    cellType: R.ifElse(
      R.path(['account', 'sub_account']),
      R.always(ExcelExportCellType.defaultCurrency),
      R.always(ExcelExportCellType.tableCurrencyFooter),
    ),
  });

// getAccountNameCell :: GeneralLedgerReportEntry -> ExcelExportDataCell
const getAccountNameCell = R.applySpec({
  value: ({ account }) =>
    `${account.sub_account ? '      ' : ''}${account.name} (${account.code})`,
  cellType: R.ifElse(
    R.path(['account', 'sub_account']),
    R.always(ExcelExportCellType.default),
    R.always(ExcelExportCellType.tableStringFooter),
  ),
});

// getAccountRow :: GeneralLedgerReportEntry -> [ExcelExportDataCell]
const getAccountRow = R.juxt([
  getAccountNameCell,
  getAmountCellByEntryAndProp('forward_balance'),
  getAmountCellByEntryAndProp('debits'),
  getAmountCellByEntryAndProp('credits'),
  getAmountCellByEntryAndProp('ending_balance'),
]);

const footerCellTypes = [
  ExcelExportCellType.tableStringFooter,
  ExcelExportCellType.tableStringFooter,
  ExcelExportCellType.tableCurrencyFooter,
  ExcelExportCellType.tableCurrencyFooter,
  ExcelExportCellType.tableStringFooter,
];

// getTotalsRow :: GeneralLedgerReportResult -> [Any]
const getTotalsRow = R.compose(
  R.zipWith(createExcelExportCell, footerCellTypes),
  R.juxt([
    R.always('Total'),
    R.always(''),
    R.prop('debits_total'),
    R.prop('credits_total'),
    R.always(''),
  ]),
);

const getExcelExportConfig = (reportData, { accountingMethod, date }) => ({
  exportFileName: 'general-ledger-summary.xlsx',
  columnWidths: [50, 30, 30, 30, 30],
  rows: [
    ...[
      {
        value: 'General Ledger Summary',
        cellType: ExcelExportCellType.title,
      },
      {
        value: formatFromToDateTitle(date.startDate, date.endDate),
        cellType: ExcelExportCellType.subTitle,
      },
      {
        value: forceTitleCase(accountingMethod),
        cellType: ExcelExportCellType.subTitle,
      },
    ].map(ofArrayLegacy),

    R.zipWith(
      createExcelExportCell,
      [
        ExcelExportCellType.tableHeader,
        ExcelExportCellType.tableCurrencyHeader,
        ExcelExportCellType.tableCurrencyHeader,
        ExcelExportCellType.tableCurrencyHeader,
        ExcelExportCellType.tableCurrencyHeader,
      ],
      ['Account', 'Forward Balance', 'Debit', 'Credit', 'Ending Balance'],
    ),

    ...reportData.entries.map(getAccountRow),
    getTotalsRow(reportData),
  ],
});

export function GeneralLedgerExportXlBtn({
  reportData,
  generalLedgerFilters,
  disabled,
}) {
  const onClick = () => {
    const config = getExcelExportConfig(reportData, generalLedgerFilters);
    performExcelExport(config);
  };

  return (
    <LinkButton disabled={disabled} onClick={onClick}>
      {EXPORT_XLS_CAPTION}
    </LinkButton>
  );
}

GeneralLedgerExportXlBtn.propTypes = {
  generalLedgerFilters: generalLedgerFiltersPropTypes,
  reportData: generalLedgerReportPropType,
  disabled: bool,
};
