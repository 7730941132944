import { string } from 'prop-types';
import styled from 'styled-components';
import React, { useCallback } from 'react';
import { LinkButton, Loader, dateColumnStyles } from '@poly/admin-book';
import { useOutSidebarContext } from '@poly/client-utils';
import { useNavigate } from '@poly/client-routing';
import { debounce } from '@poly/utils';
import {
  SidebarTabTableWrapper,
  CommonSidebarTabTable,
  SidebarTabWrapper,
  SidebarTabHeader,
} from '@poly/admin-ui';

import { routesNames } from '../../../routes/constants.js';
import { SectionLabel } from '../../components/commonSidebarComponents.js';
import { addProcedureFormId } from '../../../pages/ManageProcedures/constants.js';
import { useOpenAddEditProcedureSidebar } from '../../../pages/ManageProcedures/hooks/useOpenAddEditProcedureSidebar.js';
import { useSearchAssetProceduresQuery } from './useSearchAssetProceduresQuery.js';

const AssetProceduresTableS = styled(CommonSidebarTabTable)`
  ${dateColumnStyles(2)};
  ${dateColumnStyles(3)};
`;

function AddProcedureButton() {
  const navigate = useNavigate();
  const { closeAllOutSidebars } = useOutSidebarContext();

  const onAddProcedure = useOpenAddEditProcedureSidebar(
    addProcedureFormId,
    closeAllOutSidebars,
  );

  const openAddProcedureFormDebounced = useCallback(
    debounce(300)(onAddProcedure),
  );

  const onClick = () => {
    navigate(routesNames.MANAGE_PROCEDURES);
    openAddProcedureFormDebounced();
  };

  return <LinkButton onClick={onClick}>Add Procedure</LinkButton>;
}

export function AssetSidebarProceduresTab({ assetId }) {
  const { loading, tableProps } = useSearchAssetProceduresQuery(assetId);

  return (
    <SidebarTabWrapper>
      <SidebarTabHeader>
        <SectionLabel>Procedures</SectionLabel>
        <AddProcedureButton />
      </SidebarTabHeader>
      <SidebarTabTableWrapper offset={42}>
        {loading ? <Loader /> : <AssetProceduresTableS {...tableProps} />}
      </SidebarTabTableWrapper>
    </SidebarTabWrapper>
  );
}

AssetSidebarProceduresTab.propTypes = { assetId: string.isRequired };
