import { shape, string, object, node, bool } from 'prop-types';

export const peopleTabTypes = {
  entity: shape({
    _id: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  gqlSearchQuery: object.isRequired,
  gqlSubscriptionQuery: object,
  isInternational: bool,
  AddButtons: node,
};
