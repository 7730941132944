import * as R from 'ramda';
import React from 'react';
import { LinkButton } from '@poly/admin-book';
import { string, bool } from 'prop-types';
import {
  performExcelExport,
  ExcelExportCellType,
  createExcelExportCell,
} from '@poly/client-utils';
import { EXPORT_XLS_CAPTION } from '@poly/constants';
import {
  formatMoneyTransactionPayeeNameColumn,
  formatDate,
  propEqLegacy,
} from '@poly/utils';

import {
  reconciliationReportDataPropType,
  reconciliationReportSectionsPropType,
} from './prop-types.js';
import { getExcelExportFileNameByTitle } from '../BankRegistersPage/BankRegistersExportXlsButton.js';
import { generateReconciliationReportRows } from './generateTableRows.js';
import { RECONCILIATION_CUSTOM_ROW_TYPE } from './generateTableRowsHelpers.js';

// getAccountNameByData :: Object -> String
const getAccountNameByData = R.pathOr('-', [
  'reconciliationReport',
  'account',
  'name',
]);

// getStatementEndingDate :: Object -> String
const getStatementEndingDate = R.compose(
  R.concat('Statement Ending Date: '),
  formatDate,
  R.path(['reconciliationReport', 'endingDate']),
);

// zipRowValuesWithTypes :: [Any] -> [ExcelExportDataCell]
const zipRowValuesWithTypes = R.zipWith(createExcelExportCell, [
  ...R.repeat(ExcelExportCellType.default, 4),
  ...R.repeat(ExcelExportCellType.defaultCurrency, 2),
]);

// getTransactionRow :: ReconciliationReportRow -> [ExcelExportDataCell]
const getTransactionRow = R.compose(
  zipRowValuesWithTypes,
  R.juxt([
    R.compose(formatDate, R.prop('date')),
    R.prop('reference_column'),
    formatMoneyTransactionPayeeNameColumn,
    R.compose(formatDate, R.prop('bank_cleared_date')),
    R.prop('amount'),
    R.always(undefined),
  ]),
);

// getIndentLevel :: ReconciliationReportRow -> Number
const getIndentLevel = R.propOr(0, 'indentLevel');

// getTextWhenIndentLevel :: Number -> ReconciliationReportRow -> String
const getTextWhenIndentLevel = (indentLevel) =>
  R.ifElse(
    R.compose(R.equals(indentLevel), getIndentLevel),
    R.compose(
      R.join(' '),
      R.reject(R.isEmpty),
      R.juxt([R.prop('text'), R.propOr('', 'subText')]),
    ),
    R.always(undefined),
  );

// assocBoldFontStyleByIndex :: (Number, [ExcelExportDataCell]) -> [ExcelExportDataCell]
const assocBoldFontStyleByIndex = (index, rows) =>
  R.assocPath([index, 'style', 'font', 'bold'], true, rows);

// getCustomReportRow :: ReconciliationReportRow -> [ExcelExportDataCell]
const getCustomReportRow = R.converge(assocBoldFontStyleByIndex, [
  getIndentLevel,
  R.compose(
    zipRowValuesWithTypes,
    R.juxt([
      getTextWhenIndentLevel(0),
      getTextWhenIndentLevel(1),
      getTextWhenIndentLevel(2),
      getTextWhenIndentLevel(3),
      R.prop('amount'),
      R.prop('balance'),
    ]),
  ),
]);

// getXlsRowByReportRow :: ReconciliationReportRow -> [ExcelExportDataCell]
const getXlsRowByReportRow = R.cond([
  [propEqLegacy('isEmpty', true), R.always([{}])],
  [propEqLegacy('type', RECONCILIATION_CUSTOM_ROW_TYPE), getCustomReportRow],
  [R.T, getTransactionRow],
]);

// eslint-disable-next-line import/no-unused-modules
export const getReconciliationReportXlsConfig = ({
  data,
  title,
  reportSections,
  outstandingReportSections,
  isCreditCard,
}) => ({
  exportFileName: getExcelExportFileNameByTitle(title),
  columnWidths: [15, 20, 40, 15, 15, 15],
  rows: [
    [{ value: title, cellType: ExcelExportCellType.title }],
    [
      {
        value: getAccountNameByData(data),
        cellType: ExcelExportCellType.subTitle,
      },
    ],
    [
      {
        value: getStatementEndingDate(data),
        cellType: ExcelExportCellType.subTitle,
      },
    ],

    R.zipWith(
      createExcelExportCell,
      [
        ...R.repeat(ExcelExportCellType.tableHeader, 4),
        ...R.repeat(ExcelExportCellType.tableCurrencyHeader, 2),
      ],
      ['Date', 'Number', 'Name', 'Clearing Date', 'Amount', 'Balance'],
    ),

    ...generateReconciliationReportRows(
      reportSections,
      outstandingReportSections,
      isCreditCard,
    )(data).map(getXlsRowByReportRow),
  ],
});

export function ReconciliationReportXlsBtn({
  data,
  title,
  reportSections,
  outstandingReportSections,
  isCreditCard,
}) {
  const onClick = () => {
    const config = getReconciliationReportXlsConfig({
      data,
      title,
      reportSections,
      outstandingReportSections,
      isCreditCard,
    });
    performExcelExport(config);
  };

  return (
    <LinkButton onClick={onClick} disabled={!data?.reconciliationReport}>
      {EXPORT_XLS_CAPTION}
    </LinkButton>
  );
}

ReconciliationReportXlsBtn.propTypes = {
  data: reconciliationReportDataPropType,
  title: string.isRequired,
  reportSections: reconciliationReportSectionsPropType.isRequired,
  outstandingReportSections: reconciliationReportSectionsPropType.isRequired,
  isCreditCard: bool,
};
