import React from 'react';
import styled from 'styled-components';
import { oneOfType, number, bool, func, string } from 'prop-types';

import { getThemeFont, getThemeColor, getThemeProp } from '../utils.js';
import { Loader } from '../Loader/index.js';

export const LinkButton = styled.button`
  border: none;
  background: transparent;
  font-weight: ${getThemeFont(['regular'])};
  font-size: 12px;
  line-height: 18px;
  padding: 0;
  color: ${({ disabled }) =>
    disabled ? getThemeColor(['secondary']) : getThemeColor(['primaryLight'])};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: all ${getThemeProp(['defaultTransition'])};

  ${({ withLine }) => withLine && 'text-decoration: underline'};

  &:hover,
  &:focus {
    color: ${({ disabled }) => !disabled && getThemeColor(['accent2'])};
  }
`;

LinkButton.displayName = 'LinkButton';

const LinkButtonWithLoaderWrapperS = styled.div`
  min-width: 40px;
`;

export function LinkButtonWithLoader({
  loader,
  children,
  className,
  ...restButtonProps
}) {
  return (
    <LinkButtonWithLoaderWrapperS className={className}>
      {!loader && (
        <LinkButton type="button" {...restButtonProps}>
          {children}
        </LinkButton>
      )}
      {loader && <Loader size={16} />}
    </LinkButtonWithLoaderWrapperS>
  );
}

LinkButtonWithLoader.propTypes = {
  loader: bool,
  onClick: func.isRequired,
  children: oneOfType([number, string]).isRequired,
  className: string,
};
