import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { TreeNode } from '../../../components/Tree/TreeNode/TreeNode.js';
import {
  hasChildrenProperty,
  hasFirstChildrenProperty,
  isPropertyNode,
  isRegionNode,
} from '../utils.js';
import { PropertyLink } from '../../../sidebars/PropertySidebar/useOpenPropertySidebar.js';

const PropertyNodeLink = styled(PropertyLink)`
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

// isAddBtnHidden :: TreeNode -> Boolean
const isAddBtnHidden = R.either(hasFirstChildrenProperty, isPropertyNode);

// isAddBtnHidden :: (TreeNode, TreeNode) -> Boolean
const isRemoveBtnHidden = (node, parentNode) => {
  if (isRegionNode(node)) {
    return false;
  }
  return isPropertyNode(node) && !parentNode;
};

function NodeContentRenderer(node) {
  return <PropertyNodeLink name={node.title} _id={node.id} />;
}

export function PropertyTreeNode(props) {
  return (
    <TreeNode
      isEditable={isRegionNode}
      isDisabled={isPropertyNode}
      isAddBtnHidden={isAddBtnHidden}
      isRemoveButtonDisabled={hasChildrenProperty}
      isRemoveBtnHidden={isRemoveBtnHidden}
      NodeContentRenderer={NodeContentRenderer}
      {...props}
    />
  );
}
