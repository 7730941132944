import React from 'react';
import { DefaultBodyWrapper } from '@poly/admin-book';

import { CreateJournalEntryForm } from './JournalEntryForm/CreateJournalEntryForm.js';
import { CreateJournalEntryPageHeader } from './CreateJournalEntryPageHeader.js';

export function CreateJournalEntryPage() {
  return (
    <>
      <CreateJournalEntryPageHeader />
      <DefaultBodyWrapper>
        <CreateJournalEntryForm />
      </DefaultBodyWrapper>
    </>
  );
}
