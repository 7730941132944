import { gql } from '@apollo/client';

export const STAFF_USERS_BY_SEARCH_TABLE = gql`
  query STAFF_USERS_BY_SEARCH_TABLE($searchInput: CollectionSearchParams!) {
    searchUsers(input: $searchInput) {
      hits {
        _id
        fullName
        email
        status
        userGroups {
          userGroup {
            _id
            name
          }
        }
        profile {
          cellPhoneNumber
          workPhoneNumber
          workPhoneNumberExt
          faxPhoneNumber
        }
        address {
          formatted_address
        }
        employeeInfo {
          status
          startDate
        }
        companyLocation
        isContractor
      }
      total
    }
  }
`;
