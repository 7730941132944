import React from 'react';
import { func, instanceOf, shape, string } from 'prop-types';
import { DateRangePicker } from '@poly/admin-book';

import {
  BlockWrapper,
  FieldLabel,
  FilterButton,
  FilterWrapper,
  PersonnelSelect,
} from './components.js';
import {
  customRangeButtons,
  getCustomRangeToButtons,
} from './customDateRangeButtons.js';
import { MONDAY_DAY_NUMBER } from '../../forms/timesheetForm/constants.js';
import { TimeSheetReportPrintPDFBtn } from './TimeSheetReportPrintPDFBtn.js';
import { useTimeSheetReportFilter } from './useTimeSheetReportFilter.js';

function FilterComponent({
  value,
  ownerId,
  setDateRange,
  setTimeSheetOwner,
  setTimeSheetFilter,
}) {
  return (
    <FilterWrapper>
      <BlockWrapper>
        <FieldLabel>Personnel</FieldLabel>
        <PersonnelSelect value={ownerId} onChange={setTimeSheetOwner} />
      </BlockWrapper>
      <BlockWrapper>
        <FieldLabel>Date Range</FieldLabel>
        <DateRangePicker
          onChange={setDateRange}
          firstDayOfWeek={MONDAY_DAY_NUMBER}
          {...{
            value,
            customRangeButtons,
            getCustomRangeToButtons,
            isClosedRange: false,
          }}
        />
      </BlockWrapper>
      <BlockWrapper>
        <FilterButton onClick={setTimeSheetFilter} />
        <TimeSheetReportPrintPDFBtn />
      </BlockWrapper>
    </FilterWrapper>
  );
}

FilterComponent.propTypes = {
  value: shape({
    endDate: instanceOf(Date).isRequired,
    startDate: instanceOf(Date).isRequired,
  }).isRequired,
  ownerId: string.isRequired,
  setDateRange: func.isRequired,
  setTimeSheetOwner: func.isRequired,
  setTimeSheetFilter: func.isRequired,
};

export function TimeSheetReportFilter() {
  const filterProps = useTimeSheetReportFilter();
  return <FilterComponent {...filterProps} />;
}
