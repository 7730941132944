import * as R from 'ramda';
import React, { useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import styled, { css } from 'styled-components';
import {
  number,
  string,
  shape,
  arrayOf,
  instanceOf,
  oneOfType,
} from 'prop-types';
import { useMapConfigToTableProps } from '@poly/admin-ui';
import { assocBy, insertParamsIntoURL } from '@poly/utils';
import {
  Table,
  Head,
  HeaderRow,
  HeaderCell,
  moneyColumnStyles,
} from '@poly/admin-book';
import { Link } from '@poly/client-routing';

import { StaffReportHeader } from './StaffReportHeader.js';
import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { ReportPageTableWrapper } from '../../components/ReportPageTableWrapper.js';
import { routesNames } from '../../routes/constants.js';
import { staffReportMetricTitles } from './metricTitle.js';
import { useUserGroupListQuery } from '../UserGroupManagement/useUserGroupListQuery.js';

const StaffReportPageTableWrapper = styled(ReportPageTableWrapper)`
  & > div {
    justify-content: center;
  }
`;

const staffReportQuery = gql`
  query STAFF_REPORT($input: StaffReportInput!) {
    staffReport(input: $input) {
      user {
        _id
        profile {
          fullName
        }
      }
      activeChildrenProjects
      activeProjects
      activeRecurringProjects
      completedProjects
      completedProjectTasks
      createdProjects
      createdSuppliers
      postedUpdates
    }
  }
`;

const staffReportNumberColumnStyles = (columnNumber) => css`
  ${moneyColumnStyles(columnNumber)};

  th:nth-child(${columnNumber}),
  td:nth-child(${columnNumber}) {
    width: 100px;
  }
`;

const StaffReportTableS = styled(Table)`
  width: 1100px;

  ${staffReportNumberColumnStyles(2)};
  ${staffReportNumberColumnStyles(3)};
  ${staffReportNumberColumnStyles(4)};
  ${staffReportNumberColumnStyles(5)};
  ${staffReportNumberColumnStyles(6)};
  ${staffReportNumberColumnStyles(7)};
  ${staffReportNumberColumnStyles(8)};
  ${staffReportNumberColumnStyles(9)};

  thead {
    height: 112px;
    border: 1px solid rgba(0, 0, 0, 0);

    & > tr:nth-child(1) {
      th:nth-child(2) {
        width: 300px;
        text-align: center;
        border-right: 1px solid rgba(197, 198, 201, 0.35);
      }
      th:nth-child(3) {
        width: 500px;
        text-align: center;
      }
    }

    & > tr:nth-child(2) > th:nth-child(4) {
      border-right: 1px solid rgba(197, 198, 201, 0.35);
    }
  }

  td:nth-child(4) {
    border-right: 1px solid rgba(197, 198, 201, 0.35);
  }
`;

function StaffReportTableHeader({ headers, className }) {
  return (
    <Head className={className}>
      <HeaderRow>
        <HeaderCell />
        <HeaderCell>Current Assignments</HeaderCell>
        <HeaderCell>Activity</HeaderCell>
      </HeaderRow>
      <HeaderRow>
        {headers.map(({ title }) => (
          <HeaderCell key={title}>{title}</HeaderCell>
        ))}
      </HeaderRow>
    </Head>
  );
}

StaffReportTableHeader.propTypes = {
  className: string,
  headers: arrayOf(shape({ title: string.isRequired })),
};

function StaffReportMetricCount({ metricType, query, user, ...metrics }) {
  return (
    <Link
      target="_blank"
      href={insertParamsIntoURL(
        {
          userId: user._id,
          type: metricType,
          startDate: query.startDate.toISOString(),
          endDate: query.endDate.toISOString(),
        },
        routesNames.STAFF_ACTIVITY_REPORT_DETAILS,
      )}
    >
      {R.propOr(0, metricType, metrics)}
    </Link>
  );
}

StaffReportMetricCount.propTypes = {
  metricType: string,
  metrics: oneOfType([string, number]),
  query: shape({
    startDate: instanceOf(Date).isRequired,
    endDate: instanceOf(Date).isRequired,
  }),
  user: shape({
    _id: string.isRequired,
  }),
};

const createStaffReportMetricCount = (metricType, query) =>
  function (metrics) {
    return (
      <StaffReportMetricCount
        metricType={metricType}
        query={query}
        {...metrics}
      />
    );
  };

const getStaffReportTableConfig = (query) => [
  ['Assigned CSR', R.path(['user', 'profile', 'fullName'])],
  ...staffReportMetricTitles.map(([type, title]) => [
    title,
    createStaffReportMetricCount(type, query),
  ]),
];

// assocUserIdToRoot :: [StaffReportEntry] -> [StaffReportEntry]
const assocUserIdToRoot = R.map(assocBy('_id', R.path(['user', '_id'])));

function StaffReportTable({ query, data, ...restTableOptions }) {
  const { rows, headers, columns, sortQueries } = useMapConfigToTableProps(
    assocUserIdToRoot,
    getStaffReportTableConfig(query),
    R.propOr([], 'staffReport', data),
  );

  return (
    <StaffReportTableS
      headers={headers}
      HeadComponent={StaffReportTableHeader}
      rows={rows}
      columns={columns}
      sortQueries={sortQueries}
      {...restTableOptions}
    />
  );
}

StaffReportTable.propTypes = {
  query: shape({
    startDate: instanceOf(Date).isRequired,
    endDate: instanceOf(Date).isRequired,
  }),
  data: shape({
    staffReport: arrayOf(
      shape({
        user: shape({ _id: string.isRequired }),
        postedUpdates: number.isRequired,
        createdProjects: number.isRequired,
        createdSuppliers: number.isRequired,
        completedProjectTasks: number.isRequired,
        activeProjects: number.isRequired,
        completedProjects: number.isRequired,
        activeRecurringProjects: number.isRequired,
      }),
    ),
  }),
};

const getDefaultUserGroupIds = R.compose(
  R.map(R.prop('_id')),
  R.filter(
    R.either(
      R.path(['config', 'isDefaultCSR']),
      R.path(['config', 'isDefaultManagement']),
    ),
  ),
);

export function StaffReportPage() {
  const [query, setQuery] = useState(null);
  const { userGroupList, loading: userGroupsLoading } = useUserGroupListQuery();

  const defaultUserGroupIds = useMemo(
    () => getDefaultUserGroupIds(userGroupList),
    [userGroupList],
  );

  const { loading, data } = useQuery(staffReportQuery, {
    variables: { input: query },
    fetchPolicy: 'network-only',
    skip: !query,
  });

  return (
    <PageWithSearchHeader headerHeight="170px">
      <StaffReportHeader
        setQuery={setQuery}
        loading={loading}
        userGroupsLoading={userGroupsLoading}
        defaultUserGroupIds={defaultUserGroupIds}
      />
      <StaffReportPageTableWrapper loading={loading}>
        <StaffReportTable data={data} query={query} />
      </StaffReportPageTableWrapper>
    </PageWithSearchHeader>
  );
}
