import React, { useState } from 'react';
import styled from 'styled-components';
import { DefaultBodyWrapper, TableCard } from '@poly/admin-book';

import { AssetReviewTable } from './AssetReviewTable.js';
import { AssetReviewPageHeader } from './AssetReviewPageHeader.js';
import { AssetReviewPageFooter } from './AssetReviewPageFooter.js';
import { BottomPanelContainer } from '../../components/BottomPanel.js';

const AssetReviewContentBodyS = styled(DefaultBodyWrapper)`
  flex-direction: column;
  align-items: center;
  height: calc(100% - 85px);
`;

const AssetReviewTableCardS = styled(TableCard)`
  height: calc(100% - 90px);
  max-width: 1440px;
  width: calc(100% - 60px);
  margin: 0 25px 0 35px;
  flex-grow: 1;
  max-height: none;
`;

export function AssetReviewPage() {
  const [hasError, setHasError] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [rejectedAsset, setRejectedAsset] = useState(null);

  return (
    <>
      <AssetReviewPageHeader />
      <AssetReviewContentBodyS>
        <AssetReviewTableCardS>
          <AssetReviewTable
            {...{
              hasError,
              setHasError,
              rejectedAsset,
              selectedAssets,
              setRejectedAsset,
              setSelectedAssets,
            }}
          />
        </AssetReviewTableCardS>
        <BottomPanelContainer height="90px">
          <AssetReviewPageFooter
            {...{ selectedAssets, setSelectedAssets, rejectedAsset, hasError }}
          />
        </BottomPanelContainer>
      </AssetReviewContentBodyS>
    </>
  );
}
