import React from 'react';
import { bool, string } from 'prop-types';
import { useOutSidebarContext } from '@poly/client-utils';
import { SidebarWidth, entities } from '@poly/admin-ui';
import { LinkButton } from '@poly/admin-book';

import { SecondSidebarLayout } from '../../../components/SidebarLayouts.js';
import { assignSupplierFormId } from '../../../modules/forms/assignSupplierForm/constants.js';
import { AssignSupplierForm } from '../../../modules/forms/assignSupplierForm/AssignSupplierForm.js';
import { assignSupplierFormTypes } from '../constants.js';

export function AssignSupplierToProjectButton({ withLine, _id }) {
  const { openOutSidebar } = useOutSidebarContext();

  const onClick = () =>
    openOutSidebar({
      isSteady: true,
      width: SidebarWidth,
      id: assignSupplierFormId,
      Layout: SecondSidebarLayout,
      content: (
        <AssignSupplierForm
          entity={{ _id, name: entities.PROJECT }}
          formType={assignSupplierFormTypes.assign}
        />
      ),
    });

  return (
    <LinkButton withLine={withLine} onClick={onClick}>
      Assign
    </LinkButton>
  );
}

AssignSupplierToProjectButton.propTypes = {
  withLine: bool,
  _id: string.isRequired,
};
AssignSupplierToProjectButton.defaultProps = { withLine: false };
