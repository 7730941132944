import React from 'react';
import { arrayOf, func, string, number, oneOfType, shape } from 'prop-types';

import { HeaderText, SubHeaderText, SectionContainer } from '../styles.js';

const marginSubSection = { margin: '0 65px 0 0' };

export function TotalInfoSection({
  label,
  items,
  width,
  margin,
  sub,
  ...props
}) {
  return (
    <SectionContainer width={width} {...(sub ? marginSubSection : {})}>
      <HeaderText margin={margin}>{label}</HeaderText>
      {!!sub && <SubHeaderText>{sub}</SubHeaderText>}
      {items.map(({ Component, key }) => (
        <Component key={key} isStyled={!!sub} {...props} />
      ))}
    </SectionContainer>
  );
}

const totalInfoSectionPropTypes = {
  label: string.isRequired,
  items: arrayOf(shape({ Component: func.isRequired, key: string.isRequired }))
    .isRequired,
  width: string,
  margin: oneOfType([number, string]),
  sub: string,
};

TotalInfoSection.propTypes = totalInfoSectionPropTypes;

export function TotalInfoSections({ config, ...props }) {
  return (
    <>
      {config.map(({ label, ...configProps }) => (
        <TotalInfoSection
          key={label}
          label={label}
          {...{ ...props, ...configProps }}
        />
      ))}
    </>
  );
}

TotalInfoSections.propTypes = {
  config: arrayOf(shape(totalInfoSectionPropTypes)),
};
