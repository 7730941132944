import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { string, func } from 'prop-types';
import arrayMutators from 'final-form-arrays';
import { gql, useMutation } from '@apollo/client';
import React, { useMemo, useEffect } from 'react';
import { usePristineSubscribe } from '@poly/client-routing';
import { useOutSidebarContext } from '@poly/client-utils';
import { Loader } from '@poly/admin-book';
import {
  commonSidebarFormSectionLayout,
  useOnSubmitSetStopSubmitting,
  commonSidebarFormFieldLayout,
  commonSidebarFormLayout,
  useNotificationState,
} from '@poly/admin-ui';

import { editUserGroupFormId } from '../constants.js';
import { userGroupFormSections } from './userGroupFormSections.js';
import { UserGroupFormCreatorS } from './components/userGroupFormComponents.js';
import { setUserGroupVariables } from '../../../redux/userGroupVariablesReducer.js';
import { useUserGroupSchemaQuery } from './useUserGroupSchemaQuery.js';
import { useUserGroupByIdQuery } from './useUserGroupByIdQuery.js';
import {
  prepareUserGroupVariablesSelector,
  prepareUserGroupFormForMutation,
  prepareUserGroupForFormValues,
  checkIfHasLoginPermission,
  checkIfLineInEditMode,
} from '../userGroupManagementUtils.js';

const updateUserGroupMutation = gql`
  mutation updateUserGroupMutation($id: ID!, $input: UpdateUserGroupInput!) {
    updateUserGroup(id: $id, input: $input) {
      _id
    }
  }
`;

export function EditUserGroupForm({ userGroupId, refetch }) {
  const dispatch = useDispatch();
  const { closeOutSidebar } = useOutSidebarContext();
  const { showSuccessNotification } = useNotificationState();
  const pristineSubscribeProps = usePristineSubscribe({
    id: editUserGroupFormId,
  });

  const { schema, loading } = useUserGroupSchemaQuery();
  const { userGroup, loading: userGroupLoading } =
    useUserGroupByIdQuery(userGroupId);
  const [updateUserGroup] = useMutation(updateUserGroupMutation);

  const userGroupVariablesForSelector = useMemo(
    () => prepareUserGroupVariablesSelector(userGroup),
    [userGroup],
  );

  useEffect(() => {
    if (userGroupVariablesForSelector) {
      dispatch(setUserGroupVariables(userGroupVariablesForSelector));
    }
  }, [userGroupVariablesForSelector]);

  const initialValues = useMemo(
    () => prepareUserGroupForFormValues(schema)(userGroup),
    [schema, userGroup],
  );

  const onSubmitHandler = async (formData) => {
    const hasLoginPermission = checkIfHasLoginPermission(formData);
    const isLineInEditMode = checkIfLineInEditMode(formData);

    if (!hasLoginPermission || isLineInEditMode) return;

    const input = prepareUserGroupFormForMutation(formData);

    await updateUserGroup({ variables: { input, id: userGroupId } });

    if (R.is(Function, refetch)) {
      await refetch();
    }

    showSuccessNotification('User Group was successfully edited');
    closeOutSidebar(editUserGroupFormId);
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    editUserGroupFormId,
    onSubmitHandler,
  );

  useEffect(
    () => () => {
      dispatch(setUserGroupVariables({}));
    },
    [],
  );

  if (loading || userGroupLoading) return <Loader />;

  return (
    <UserGroupFormCreatorS
      onSubmit={onSubmit}
      id={editUserGroupFormId}
      mutators={arrayMutators}
      initialValues={initialValues}
      layout={commonSidebarFormLayout}
      sections={userGroupFormSections}
      fieldLayout={commonSidebarFormFieldLayout}
      sectionLayout={commonSidebarFormSectionLayout}
      {...pristineSubscribeProps}
    />
  );
}

EditUserGroupForm.propTypes = { userGroupId: string.isRequired, refetch: func };
