import React from 'react';
import styled from 'styled-components';
import { func, string, bool, node } from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import {
  getThemeColor,
  getThemeProp,
  defaultTheme,
  Icon,
} from '@poly/admin-book';

import { GLOBAL_SEARCH_INPUT_ID } from './globalSearchConstants.js';

const {
  colors: { secondaryMid, white },
} = defaultTheme;

const SearchIconS = styled(Icon)`
  flex-shrink: 0;
  & path {
    transition: all ${getThemeProp(['defaultTransition'])};
  }
  cursor: pointer;
`;

const ClearIconS = styled(Icon)`
  cursor: pointer;
  padding-right: 5px;
  border-right: 1px solid #a6a6a6;
`;

const IconsWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

const GlobalSearchInputCompWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 34px;
  padding: 0 10px 0 7px;
  background-color: ${({ isGlobalSearchFocused }) =>
    isGlobalSearchFocused
      ? getThemeColor(['white'])
      : getThemeColor(['primaryLight'])};
  border-radius: 3px;
  transition: all ${getThemeProp(['defaultTransition'])};
  position: relative;
`;

const OutsideClickHandlerS = styled.div`
  display: flex;
  width: 252px;

  > div {
    width: 100%;
  }
`;

export function GlobalSearchInputWrapper({
  children,
  className,
  closeSearch,
  isGlobalSearchHidden,
  isGlobalSearchFocused,
  handleSearch,
  clearSearchText,
  searchText,
}) {
  return (
    <OutsideClickHandlerS>
      <OutsideClickHandler
        onOutsideClick={closeSearch}
        disabled={!isGlobalSearchFocused || isGlobalSearchHidden}
      >
        <GlobalSearchInputCompWrapper
          id={GLOBAL_SEARCH_INPUT_ID}
          {...{ isGlobalSearchFocused, className }}
        >
          {children}
          <IconsWrapper>
            {searchText && (
              <ClearIconS
                name="close"
                size={17}
                color="#929292"
                onClick={clearSearchText}
              />
            )}

            <SearchIconS
              size={15}
              name="search"
              color={isGlobalSearchFocused ? secondaryMid : white}
              onClick={handleSearch}
            />
          </IconsWrapper>
        </GlobalSearchInputCompWrapper>
      </OutsideClickHandler>
    </OutsideClickHandlerS>
  );
}

GlobalSearchInputWrapper.propTypes = {
  className: string,
  children: node.isRequired,
  isGlobalSearchHidden: bool,
  closeSearch: func.isRequired,
  isGlobalSearchFocused: bool.isRequired,
  handleSearch: func.isRequired,
  clearSearchText: func.isRequired,
  searchText: string,
};

GlobalSearchInputWrapper.defaultProps = { className: '' };
