import React from 'react';
import styled from 'styled-components';
import { func, node, object, oneOfType, shape, string, bool } from 'prop-types';
import { getThemeFont, Icon, Status } from '@poly/admin-book';
import { OutSidebarProvider } from '@poly/client-utils';
import { RouterProvider } from '@poly/client-routing';

const TitleStatus = styled(Status)`
  font-size: 18px;
  padding: 10px 20px;
`;

const Title = styled.h2`
  padding: ${({ additional }) =>
    additional ? '10px 30px 0 30px' : '10px 30px'};
  white-space: pre-wrap;
  text-align: center;
`;

const Additional = styled.h4`
  font-weight: ${getThemeFont(['medium'])};
  padding: 0 30px 10px 30px;
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 45px;
`;

function Logo({ isAAC }) {
  return (
    <LogoWrapper>
      {isAAC ? (
        <Icon name="print-logo" />
      ) : (
        <img
          id="load-image"
          alt="esfm-logo"
          style={{ width: 160 }}
          src="/images/esfm-logo.webp"
        />
      )}
    </LogoWrapper>
  );
}

Logo.propTypes = { isAAC: bool };

export function CommonPrintLayout({
  isAAC,
  title,
  status,
  Context,
  children,
  additional,
}) {
  return (
    <RouterProvider>
      <OutSidebarProvider InnerContext={Context}>
        <div style={{ position: 'relative' }}>
          <Title {...{ additional }}>{title}</Title>
          <Logo isAAC={isAAC} />
          {status && <TitleStatus text={status.text} color={status.color} />}
          {additional && <Additional>{additional}</Additional>}
          {children}
        </div>
      </OutSidebarProvider>
    </RouterProvider>
  );
}

CommonPrintLayout.propTypes = {
  isAAC: bool,
  title: oneOfType([node, string]),
  children: node.isRequired,
  Context: oneOfType([func, object]),
  additional: oneOfType([string, node]),
  status: shape({ text: string, color: string }),
};

CommonPrintLayout.defaultProps = {
  title: '',
};
