import styled from 'styled-components';
import {
  getThemeColor,
  moneyColumnStyles,
  Table,
  WindowedTable,
} from '@poly/admin-book';
import { formatTotal } from '@poly/utils';
import React from 'react';
import { number } from 'prop-types';

export const WorkInProgressQCTableS = styled(WindowedTable)`
  thead {
    height: 45px;
  }

  tr {
    width: 100%;
    margin: 0;
  }

  td {
    padding: 5px;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 150px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 150px;
  }

  th:nth-child(9),
  td:nth-child(9) {
    width: 150px;
  }

  th:nth-child(5),
  td:nth-child(5) {
    width: 100px;
  }

  ${moneyColumnStyles(6)};
  ${moneyColumnStyles(7)};
  ${moneyColumnStyles(8)};
`;

export const WorkInProgressQCPrintTableS =
  WorkInProgressQCTableS.withComponent(Table);

const TotalCellS = styled.td`
  font-weight: bold;
`;

const TableFooterRow = styled.tr`
  padding: 10px 0;
  box-shadow: 0 -2px 2px 0 ${getThemeColor(['secondaryLighter4'])};
`;

export function WorkInProgressFooter({ supplierInvoiceTotal, markup, total }) {
  return (
    <TableFooterRow data-testid="wip-footer">
      <td />
      <td />
      <td />
      <td />
      <TotalCellS>Total</TotalCellS>
      <td>{formatTotal(supplierInvoiceTotal)}</td>
      <td>{formatTotal(markup)}</td>
      <td>{formatTotal(total)}</td>
      <td />
    </TableFooterRow>
  );
}

WorkInProgressFooter.propTypes = {
  supplierInvoiceTotal: number.isRequired,
  markup: number.isRequired,
  total: number.isRequired,
};
