import * as R from 'ramda';

import { prefixNumberWithLeadingZeros } from './leading-zeros.js';

// formatManualJournalNumber :: Number -> String
export const formatManualJournalNumber = R.ifElse(
  R.is(Number),
  R.compose(R.concat('JE-'), prefixNumberWithLeadingZeros(6)),
  R.always(''),
);
