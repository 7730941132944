import {
  shape,
  number,
  string,
  arrayOf,
  oneOfType,
  instanceOf,
} from 'prop-types';

export const journalEntryLedgerResultPropTypes = shape({
  total: number,
  hits: arrayOf(
    shape({
      _id: string,
      date: string,
      transactionNumber: number,
      lines: arrayOf(
        shape({
          netAmount: number,
          description: string,
          payment_status: string,
          account: shape({ code: string, name: string }),
          reconciled_at: oneOfType([string, instanceOf(Date)]),
        }),
      ),
    }),
  ),
});
