import { gql } from '@apollo/client';

export const ACCOUNT_INDEX = gql`
  query ACCOUNT_INDEX {
    adminAccountIndex {
      clients {
        countByStatuses {
          status
          count
        }
      }
      aacSuppliersAdministrative {
        countByStatuses {
          status
          count
        }
      }
      aacSuppliersSubcontracts {
        countByStatuses {
          status
          count
        }
      }
      masterSuppliersSubcontracts {
        countByStatuses {
          status
          count
        }
      }
      masterSuppliersAdministrative {
        countByStatuses {
          status
          count
        }
      }
    }
  }
`;
