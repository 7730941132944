import styled from 'styled-components';
import React, { useState } from 'react';
import { DefaultBodyWrapper, Loader } from '@poly/admin-book';
import { useRedirectIfHasNoPermissions } from '@poly/client-utils';
import { READ_QUOTE_DISCREPANCIES_PERMISSION } from '@poly/security';

import { QuoteDiscrepanciesPageTable } from './QuoteDiscrepanciesPageTable.js';
import { QuoteDiscrepanciesPageHeader } from './QuoteDiscrepanciesPageHeader.js';
import { QuoteDiscrepanciesPageFooter } from './QuoteDiscrepanciesPageFooter.js';
import { useQuoteDiscrepanciesReportQuery } from './hooks/useQuoteDiscrepanciesReportQuery.js';
import { getTableConfig } from './table-config.js';
import { prepareClientQuoteDiscrepanciesFooterData } from './helpers.js';
import { getQuoteDiscrepanciesXlsConfig } from './quote-discrepancies-xls-config.js';

export const QuoteDiscrepanciesContentWrapperS = styled(DefaultBodyWrapper)`
  align-items: center;
  height: calc(100% - 140px);
  flex-direction: column;
  padding: 0 40px;
`;

export function QuoteDiscrepanciesPage() {
  useRedirectIfHasNoPermissions([READ_QUOTE_DISCREPANCIES_PERMISSION]);

  const [queryInput, setQueryInput] = useState(null);

  const { tableRows, loading, tableSortingProps } =
    useQuoteDiscrepanciesReportQuery(queryInput);

  return (
    <>
      <QuoteDiscrepanciesPageHeader
        setQueryInput={setQueryInput}
        tableRows={tableRows}
        title="Quote Discrepancies Report"
        getXLSConfig={getQuoteDiscrepanciesXlsConfig}
        getTableConfig={getTableConfig}
        prepareFooterData={prepareClientQuoteDiscrepanciesFooterData}
      />
      <QuoteDiscrepanciesContentWrapperS>
        {loading ? (
          <Loader />
        ) : (
          <QuoteDiscrepanciesPageTable
            tableRows={tableRows}
            tableSortingProps={tableSortingProps}
            getTableConfig={getTableConfig}
            prepareFooterData={prepareClientQuoteDiscrepanciesFooterData}
          />
        )}
      </QuoteDiscrepanciesContentWrapperS>
      <QuoteDiscrepanciesPageFooter
        tableRows={tableRows}
        prepareFooterData={prepareClientQuoteDiscrepanciesFooterData}
      />
    </>
  );
}
