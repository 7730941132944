import * as R from 'ramda';
import { func } from 'prop-types';
import React, { useMemo } from 'react';
import { ServiceTypeStatuses, ASC_SORT_ORDER } from '@poly/constants';
import {
  SERVICE_TYPES_BY_SEARCH_SUB,
  SERVICE_TYPES_BY_SEARCH,
  MAX_ITEMS,
} from '@poly/admin-ui';
import {
  entityToOptionByLabelPath,
  useReactiveQuery,
  keywordSortQuery,
} from '@poly/client-utils';

import { BillingProfileConfigMultiSelect } from './BillingProfileConfigMultiSelect.js';
import {
  prepareMultipleSelectValue,
  onMultipleSelectValueChange,
} from '../utils.js';
import { multiSelectorValueProperTypes } from './common.js';

// formatServiceTypeOptions :: SearchServiceTypesQueryResult -> [Option]
const formatServiceTypeOptions = R.compose(
  R.map(entityToOptionByLabelPath(['name'])),
  R.pathOr([], ['searchServiceTypes', 'hits']),
);

export function MultipleServiceTypeSelect({ value, onChange, ...props }) {
  const queryOptions = {
    variables: {
      searchInput: {
        size: MAX_ITEMS,
        sort: keywordSortQuery(['name'])(ASC_SORT_ORDER),
        query: { term: { status: ServiceTypeStatuses.active } },
      },
    },
  };

  const { data, loading } = useReactiveQuery(
    SERVICE_TYPES_BY_SEARCH,
    SERVICE_TYPES_BY_SEARCH_SUB,
    { queryOptions, subscriptionOptions: queryOptions },
  );

  const options = useMemo(() => formatServiceTypeOptions(data), [data]);

  const preparedValue = useMemo(
    () => prepareMultipleSelectValue(value, options),
    [value, options],
  );

  const selectProps = {
    ...props,
    loading,
    options,
    value: preparedValue,
    placeholder: 'Start typing service types',
    handleChange: onMultipleSelectValueChange(onChange),
  };

  return <BillingProfileConfigMultiSelect {...selectProps} />;
}

MultipleServiceTypeSelect.propTypes = {
  value: multiSelectorValueProperTypes,
  onChange: func.isRequired,
};
