import React from 'react';
import { number } from 'prop-types';
import styled from 'styled-components';
import { getThemeColor, defaultTheme, Popover, Icon } from '@poly/admin-book';

import { getProjectInvoiceAmountErrorMessage } from '../../BatchInvoicingTM/batchInvoicingUtils.js';

const {
  colors: { primaryRegular },
} = defaultTheme;

const PopoverContentS = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 60px;
  background: ${getThemeColor(['primaryRegular'])};
  border-radius: 5px;
  color: ${getThemeColor(['light'])};
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  z-index: 9999;
`;

const PopoverTitleS = styled.p`
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 5px 0;
`;

export function ProjectErrorPopover({ rowIndex, ...props }) {
  const message = getProjectInvoiceAmountErrorMessage(props);
  const position = rowIndex === 0 ? 'right' : 'top';

  return (
    <Popover
      maxWidth="150px"
      position={position}
      bgColor={primaryRegular}
      title={<Icon name="yellowWarning" size={15} />}
      content={
        <PopoverContentS>
          <PopoverTitleS>Something wrong</PopoverTitleS>
          {message}
        </PopoverContentS>
      }
    />
  );
}

ProjectErrorPopover.propTypes = { rowIndex: number.isRequired };
