import * as R from 'ramda';
import { createAction, handleAction } from 'redux-actions';

export const getBillingProfileConfig = R.path([
  'searchFilters',
  'billingProfileConfig',
]);

export const setBillingProfileConfig = createAction(
  'SET_BILLING_PROFILE_CONFIG',
);

export const billingProfileConfigReducer = handleAction(
  setBillingProfileConfig,
  (_, { payload }) => payload,
  null,
);
