import * as R from 'ramda';

const isIECase = () => window?.clipboardData?.setData;

export const isFirefox = () =>
  window.navigator.userAgent.search(/firefox/gi) !== -1;

const handleCopyInIE = (text) => window.clipboardData.setData('Text', text);

const isCopyCommandSupported = () => window.navigator.clipboard?.writeText;

const handleUsualCopy = async (text) => {
  const textarea = window.document.createElement('textarea');
  textarea.textContent = text;
  textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
  window.document.body.appendChild(textarea);
  textarea.select();

  try {
    await window.navigator.clipboard.writeText(text);
    return true;
  } catch (e) {
    return false;
  } finally {
    window.document.body.removeChild(textarea);
  }
};

// copyToClipBoard :: String -> Any
export const copyToClipBoard = R.cond([
  [isIECase, handleCopyInIE],
  [isCopyCommandSupported, handleUsualCopy],
  [R.T, R.F],
]);
