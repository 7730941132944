import React from 'react';
import * as R from 'ramda';
import { useRouterParams, useRouterQuery } from '@poly/client-routing';
import { DefaultBodyWrapper, Loader } from '@poly/admin-book';
import { useReactiveDocumentTitle } from '@poly/client-utils';

import {
  PageCardBody,
  PageCardFirstSection,
  PageCardSecondSection,
} from '../components/PageCardComponents.js';
import { clientSidebarTabs } from '../routes/constants.js';
import { NotFoundEntity } from '../components/NotFoundEntity.js';
import { ClientSidebarSections } from '../sidebars/ClientSidebar/ClientSidebar.js';
import { ClientSidebarTabs } from '../sidebars/ClientSidebar/tabs/ClientSidebarTabs.js';
import { useClientSidebarData } from '../sidebars/ClientSidebar/useClientSidebarData.js';
import { useCloseExtendedTab } from '../hooks/useCloseExtendedTab.js';
import {
  showClientChildren,
  showClientPortal,
} from '../sidebars/ClientSidebar/tabs/clientSidebarTabsUtils.js';

// isFullWidthTabByTabName :: String -> Boolean
const isFullWidthTabByTabName = R.includes(R.__, [
  clientSidebarTabs.clientMasters,
  clientSidebarTabs.clientProjects,
  clientSidebarTabs.clientContacts,
  clientSidebarTabs.clientMasterProperties,
  clientSidebarTabs.clientProperties,
  clientSidebarTabs.clientPortalUsers,
  clientSidebarTabs.clientChildAccounts,
]);

export function ClientCard() {
  const { clientId } = useRouterParams(['clientId']);
  const { cardTab } = useRouterQuery(['cardTab']);
  const { loading, client } = useClientSidebarData(clientId);

  const closeExtendedTab = useCloseExtendedTab();
  useReactiveDocumentTitle(client ? client.name : null);

  if (loading) {
    return <Loader />;
  }

  const pageCardSectionProps = {
    fullWidth: isFullWidthTabByTabName(cardTab),
    adjustWidth: R.both(showClientPortal, showClientChildren)(client),
  };

  const clientTabsProps = client
    ? {
        client,
        isCard: true,
        clientName: client.name,
        showPortal: showClientPortal(client),
        showChildren: showClientChildren(client),
        customTabQueryKey: 'cardTab',
      }
    : {};

  return (
    <DefaultBodyWrapper height="100%">
      <PageCardBody>
        {!client ? (
          <NotFoundEntity entityName="client" />
        ) : (
          <>
            <PageCardFirstSection
              {...pageCardSectionProps}
              onClick={closeExtendedTab}
            >
              <ClientSidebarSections client={client} isCard />
            </PageCardFirstSection>
            <PageCardSecondSection {...pageCardSectionProps}>
              <ClientSidebarTabs {...clientTabsProps} />
            </PageCardSecondSection>
          </>
        )}
      </PageCardBody>
    </DefaultBodyWrapper>
  );
}
