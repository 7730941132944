import React from 'react';
import { formatSelectOptions } from '@poly/admin-ui';
import { Select } from '@poly/admin-book';

import { clientInvoiceTo } from '../../../../../modules/core/constants/client.js';

export function ClientInvoiceToSelect(props) {
  const options = formatSelectOptions(clientInvoiceTo);

  return <Select {...props} options={options} />;
}

ClientInvoiceToSelect.displayName = 'ClientInvoiceToSelect';
