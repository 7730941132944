import * as R from 'ramda';
import { endOfDay, startOfDay } from 'date-fns';
import { parseFieldToNumberIfExists } from '@poly/client-utils';
import { isNilOrEmpty, ensureIsDate, renameProp } from '@poly/utils';
import { AACCheckStatus } from '@poly/constants';

// prepareSupplierChecksFilters :: Object -> Object
export const prepareSupplierChecksFilters = R.compose(
  renameProp('checkType', 'type'),
  R.mergeRight({ status: AACCheckStatus.ACTIVE }),
  R.reject(isNilOrEmpty),
  parseFieldToNumberIfExists('checkNumber'),
  R.evolve({
    paymentDateFrom: R.when(
      R.complement(isNilOrEmpty),
      R.compose(R.constructN(1, Date), startOfDay, ensureIsDate),
    ),
    paymentDateTo: R.when(
      R.complement(isNilOrEmpty),
      R.compose(R.constructN(1, Date), endOfDay, ensureIsDate),
    ),
  }),
);
