import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { TextButton } from '@poly/admin-book';
import { gql, useMutation } from '@apollo/client';
import { useNotificationState } from '@poly/admin-ui';
import { arrayOf, func, shape, string } from 'prop-types';
import { isNilOrEmpty, removePropDeep } from '@poly/utils';
import { RequesterSurveyQuestionTypes } from '@poly/constants';

import { ClientRequesterQuestionLine } from './ClientRequesterQuestionLine.js';

const AddQuestionButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;

  div {
    text-align: right;
    width: 85px;
  }
`;

const updateQuestionsMutation = gql`
  mutation updateClientRequesterSurveyQuestionsMutation(
    $id: ID!
    $input: UpdateClientRequesterSurveyQuestionsInput!
  ) {
    updateClientRequesterSurveyQuestions(id: $id, input: $input) {
      _id
    }
  }
`;

const prepareInitialValues = R.compose(
  removePropDeep('__typename'),
  R.objOf('questions'),
  R.when(isNilOrEmpty, R.always([{}])),
);

export function EditClientRequesterSurveyForm({
  formId,
  onCancel,
  clientId,
  requesterSurveyQuestions,
}) {
  const { showSuccessNotification } = useNotificationState();
  const [updateQuestions] = useMutation(updateQuestionsMutation);

  const onSubmit = async (formData) => {
    await updateQuestions({
      variables: {
        id: clientId,
        input: formData,
      },
    });
    showSuccessNotification('Client Requester Survey successfully updated');
    onCancel();
  };

  return (
    <Form
      onSubmit={onSubmit}
      validateOnBlur
      initialValues={prepareInitialValues(requesterSurveyQuestions)}
      mutators={{ ...arrayMutators }}
    >
      {({
        handleSubmit,
        form: {
          mutators: { push },
        },
      }) => (
        <form
          onSubmit={handleSubmit}
          id={formId}
          {...{ 'data-testid': 'editClientRequesterSurveyFormTestId' }}
        >
          <FieldArray name="questions">
            {({ fields }) =>
              fields.map((name, index) => (
                <ClientRequesterQuestionLine
                  key={name}
                  id={name}
                  name={name}
                  index={index}
                  remove={fields.remove}
                />
              ))
            }
          </FieldArray>
          <AddQuestionButtonWrapper>
            <TextButton
              onClick={() =>
                push('questions', { type: RequesterSurveyQuestionTypes.open })
              }
            >
              + Add question
            </TextButton>
          </AddQuestionButtonWrapper>
        </form>
      )}
    </Form>
  );
}

EditClientRequesterSurveyForm.propTypes = {
  formId: string.isRequired,
  onCancel: func.isRequired,
  clientId: string.isRequired,
  requesterSurveyQuestions: arrayOf(
    shape({
      question: string.isRequired,
      type: string.isRequired,
    }),
  ),
};
