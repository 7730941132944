import React from 'react';
import * as R from 'ramda';
import { string, number, func, shape } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { SupplierWOCTypes } from '@poly/constants';
import { FormCreator } from '@poly/admin-book';
import {
  useOnSubmitSetStopSubmitting,
  useNotificationState,
  useModalContext,
} from '@poly/admin-ui';
import { usePristineSubscribe, useRouterQuery } from '@poly/client-routing';

import {
  sendWOCFormId,
  reviseWOCNote,
  requestFreeQuoteNote,
} from './constants.js';
import { TextAreaS } from '../formsStyles.js';
import { commonModalLayout } from '../common.js';
import { CheckWOComponent } from '../assignSupplierForm/components/CheckWOCComponent.js';
import { getSupplierWOCTypeByForm } from '../assignSupplierForm/utils.js';
import { SupplierNTEInput } from '../assignSupplierForm/AssignSupplierFormComp.js';

const partWidth = 'calc(35% - 10px)';

function SupplierNoteComp(props) {
  return (
    <TextAreaS {...props} rows={4} placeholder="Enter supplier instructions" />
  );
}

function SupplierNTEComp(props) {
  return <SupplierNTEInput {...props} placeholder="$ Not to exceed" />;
}

const sendWOCFormSections = [
  {
    id: sendWOCFormId,
    layout: {
      column: 1,
      margin: 0,
    },
    order: 1,
    fields: [
      {
        layout: { row: 1, width: '55%', position: 'center' },
        field: {
          withFormData: true,
          name: SupplierWOCTypes.revise,
          withChangeFieldValue: true,
          Component: (props) => (
            <CheckWOComponent
              {...props}
              relatedText={reviseWOCNote}
              label="Revise Supplier WO Confirmation"
              relatedCheckbox={SupplierWOCTypes.request}
            />
          ),
        },
      },
      {
        layout: { row: 1, width: partWidth },
        field: {
          withFormData: true,
          name: SupplierWOCTypes.request,
          withChangeFieldValue: true,
          Component: (props) => (
            <CheckWOComponent
              {...props}
              label="Free Quote"
              relatedText={requestFreeQuoteNote}
              relatedCheckbox={SupplierWOCTypes.revise}
            />
          ),
        },
      },
      {
        layout: { row: 1, width: '120px' },
        field: {
          name: 'nte',
          withFormData: true,
          Component: SupplierNTEComp,
        },
      },
      {
        label: 'Supplier Instructions',
        layout: { row: 2 },
        field: {
          name: 'supplierNote',
          Component: SupplierNoteComp,
        },
        renderIf: R.either(
          R.prop(SupplierWOCTypes.revise),
          R.prop(SupplierWOCTypes.request),
        ),
      },
    ],
  },
];

const sendProjectSupplierWOCMutation = gql`
  mutation sendProjectSupplierWOC($input: SendProjectSupplierWOCInput!) {
    sendProjectSupplierWOC(input: $input) {
      project {
        _id
      }
    }
  }
`;

export function SendWOCForm({
  supplierId,
  projectId,
  supplierNTE,
  onCancel,
  project,
}) {
  const { closeModal } = useModalContext();
  const { showSuccessNotification } = useNotificationState();
  const [sendWOCMutation] = useMutation(sendProjectSupplierWOCMutation);
  const { sidebarTab } = useRouterQuery(['sidebarTab']);

  const pristineSubscribeProps = usePristineSubscribe(
    {
      id: sendWOCFormId,
    },
    { sidebarTab },
  );

  const onSubmitHandler = async ({ nte, supplierNote, ...props }) => {
    const supplierWOCType = getSupplierWOCTypeByForm(props);

    const successMessage =
      supplierWOCType === SupplierWOCTypes.skip
        ? ' Supplier NTE was updated'
        : 'Supplier Work Order Confirmation was sent';

    await sendWOCMutation({
      variables: {
        input: {
          projectId,
          supplierId,
          supplierNote,
          ...(R.is(Number, nte) ? { nte } : {}),
          supplierWOCType,
        },
      },
    });

    showSuccessNotification(successMessage);
    closeModal(sendWOCFormId);

    if (onCancel) {
      onCancel();
    }
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    sendWOCFormId,
    onSubmitHandler,
  );

  const formProps = {
    onSubmit,
    id: sendWOCFormId,
    formId: sendWOCFormId,
    layout: commonModalLayout,
    sections: sendWOCFormSections,
    initialValues: {
      project,
      nte: supplierNTE || undefined,
      supplierNote: reviseWOCNote,
      [SupplierWOCTypes.revise]: true,
      [SupplierWOCTypes.request]: false,
    },
    ...pristineSubscribeProps,
  };

  return <FormCreator {...formProps} />;
}

SendWOCForm.propTypes = {
  supplierId: string.isRequired,
  projectId: string.isRequired,
  supplierNTE: number,
  onCancel: func,
  project: shape({}),
};
