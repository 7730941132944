import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { bool, func, object, shape, string } from 'prop-types';
import { Button, Icon, Modal, ModalActions, Text } from '@poly/admin-book';

import { FlexContainer } from '../../components/common.js';
import { useConfirmModal } from '../../redux/confirmModal.js';

const ModalContainer = styled.div`
  width: 400px;
`;

const ContentWrapper = styled(Text)`
  display: flex;
  width: 100%;
  padding: 15px 0;
  justify-content: ${({ justify }) => (justify ? 'space-between' : 'center')};
`;

const TitleWrapper = styled(Text)`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 5px;
`;

const ButtonS = styled(Button)`
  margin-right: 20px;
`;

const IconS = styled(Icon)`
  margin-right: 10px;
`;

function ConfirmModalC({
  title,
  message,
  iconName,
  confirmButtonName,
  textStyleProps,
  cancelBtnCaption,
  confirmBtnPayload,
  closeModal,
  confirmButtons,
  afterConfirm,
}) {
  const ConfirmBtn = confirmButtons[confirmButtonName];
  return (
    <ModalContainer>
      {!!title && <TitleWrapper size="21px">{title}</TitleWrapper>}
      <ContentWrapper {...textStyleProps} size="15px">
        <FlexContainer>
          {iconName && <IconS name={iconName} />}
          <Text>{message}</Text>
        </FlexContainer>
      </ContentWrapper>
      <ModalActions>
        <ButtonS
          size="small"
          styleType="basicSecondary"
          type="button"
          onClick={closeModal}
        >
          {cancelBtnCaption}
        </ButtonS>
        <ConfirmBtn
          afterConfirm={afterConfirm}
          confirmBtnPayload={confirmBtnPayload}
        />
      </ModalActions>
    </ModalContainer>
  );
}

ConfirmModalC.propTypes = {
  closeModal: func.isRequired,
  cancelBtnCaption: string,
  title: string,
  iconName: string,
  message: string,
  confirmButtonName: string,
  textStyleProps: shape({ justify: bool }),
  // eslint-disable-next-line react/forbid-prop-types
  confirmBtnPayload: object,
  // eslint-disable-next-line react/forbid-prop-types
  confirmButtons: object,
  afterConfirm: func,
};

ConfirmModalC.defaultProps = {
  cancelBtnCaption: 'Close',
  iconName: 'confirm',
};

export function ConfirmModal({ confirmButtons }) {
  const { closeModal: closeConfirmModal } = useConfirmModal();
  const { payload, isOpen, afterConfirm } = useSelector(
    (state) => state.confirmModal,
  );

  return (
    <Modal onClose={closeConfirmModal} isOpen={isOpen}>
      <ConfirmModalC
        {...{
          closeModal: closeConfirmModal,
          confirmButtons,
          afterConfirm,
          ...payload,
        }}
      />
    </Modal>
  );
}

ConfirmModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  confirmButtons: object,
};
