import React from 'react';
import { propertyOccupancyTypes } from '@poly/constants';
import { formatSelectOptions } from '@poly/admin-ui';
import { Select } from '@poly/admin-book';

export function OccupancySelector(props) {
  const options = formatSelectOptions(propertyOccupancyTypes);

  return <Select {...props} options={options} />;
}
