import React from 'react';
import { DefaultBodyWrapper } from '@poly/admin-book';

import { FormPageHeaderCreator } from '../components/FormPageHeaderCreator.js';
import { EnterSupplierInvoiceForm } from '../modules/accounting/enterSupplierInvoice/enterSupplierInvoiceForm/EnterSupplierInvoiveForm.js';
import { enterSupplierInvoiceFormId } from '../modules/accounting/constants.js';

export function EnterSupplierInvoicePage() {
  return (
    <>
      <FormPageHeaderCreator
        title="Enter Supplier Invoice"
        formId={enterSupplierInvoiceFormId}
      />
      <DefaultBodyWrapper>
        <EnterSupplierInvoiceForm />
      </DefaultBodyWrapper>
    </>
  );
}
