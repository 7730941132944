import * as R from 'ramda';
import React from 'react';
import { TextButton } from '@poly/admin-book';
import {
  arrayOf,
  number,
  shape,
  string,
  instanceOf,
  oneOfType,
} from 'prop-types';
import { calculateTotal } from '@poly/utils';
import { useNotificationState } from '@poly/admin-ui';
import {
  createExcelExportCell,
  ExcelExportCellType,
  performExcelExport,
} from '@poly/client-utils';
import { EXPORT_XLS_CAPTION } from '@poly/constants';
import { formatDateHeader } from '../GeneralLedgerPage/generalLedgerHelpers.js';

// getAPReportConfig :: Array -> [ExcelExportDataCell]
const getAPReportConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ExcelExportCellType.default,
    ExcelExportCellType.defaultCurrency,
  ]),
);

// getAPReportRowsConfig :: ReportEntry -> [ExcelExportDataCell]
const getAPReportRowsConfig = R.compose(
  getAPReportConfig,
  R.juxt([R.path(['supplier', 'company', 'name']), R.prop('amount')]),
);

// getTotalRowConfig :: [ReportEntry] -> [ExcelExportDataCell]
const getTotalRowConfig = R.compose(
  getAPReportConfig,
  R.juxt([R.always('Total'), calculateTotal(R.prop('amount'))]),
);

// eslint-disable-next-line import/no-unused-modules
export const getAPReportExportExcelConfig = ({
  title,
  filterDate,
  reportEntries,
}) => ({
  exportFileName: 'accounts_payable_report.xlsx',
  columnWidths: [50, 15],
  rows: [
    [
      {
        cellType: ExcelExportCellType.title,
        value: title,
      },
    ],
    [
      {
        cellType: ExcelExportCellType.title,
        value: formatDateHeader(filterDate),
      },
    ],
    R.zipWith(
      createExcelExportCell,
      [
        ExcelExportCellType.tableHeader,
        ExcelExportCellType.tableCurrencyHeader,
      ],
      ['Supplier', 'Amount'],
    ),
    ...R.map(getAPReportRowsConfig, reportEntries),
    getTotalRowConfig(reportEntries),
  ],
});

export function AccountsPayableReportExcelExportButton({
  reportEntries,
  filter,
  defaultDateValue,
}) {
  const { showErrorNotification } = useNotificationState();

  const onClick = () => {
    if (R.isEmpty(reportEntries)) {
      showErrorNotification('No records to export');
      return;
    }

    const excelConfig = getAPReportExportExcelConfig({
      reportEntries,
      title: 'Accounts Payable Report',
      filterDate: filter.date || defaultDateValue,
    });
    performExcelExport(excelConfig);
  };

  return <TextButton onClick={onClick}>{EXPORT_XLS_CAPTION}</TextButton>;
}

AccountsPayableReportExcelExportButton.propTypes = {
  reportEntries: arrayOf(
    shape({
      amount: number,
      supplier: shape({
        _id: string,
        company: shape({
          name: string,
        }),
      }),
    }),
  ),
  filter: shape({
    date: oneOfType([string, instanceOf(Date)]),
  }),
  defaultDateValue: oneOfType([string, instanceOf(Date)]).isRequired,
};
