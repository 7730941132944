import { useMutation } from '@apollo/client';
import { useModalContext, useNotificationState } from '@poly/admin-ui';
import { useState } from 'react';
import * as R from 'ramda';

import { createClientInvoiceBatchQuery } from '../BatchInvoicingTM/BatchInvoicingPageBody.js';
import { isTotalsOutOfRange } from '../BatchInvoicingTM/batchInvoicingUtils.js';

// getMutationInput :: [Project] -> [CreateClientInvoiceBatchInputEntry]
const getMutationInput = R.compose(
  R.map(
    R.applySpec({
      projectId: R.prop('_id'),
      amount: R.prop('suggestedClientInvoiceAmount'),
    }),
  ),
);

// showConfirmation :: [Projects] -> Boolean
const showConfirmation = R.any(isTotalsOutOfRange);

export const useOnCreateBatch = ({ selectedProjects, setSelectedProjects }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { openConfirmModal } = useModalContext();

  const [createClientInvoiceBatchMutation] = useMutation(
    createClientInvoiceBatchQuery,
  );
  const { showSuccessNotification } = useNotificationState();

  const onCreateBatchClick = async () => {
    setIsLoading(true);

    const inputEntries = getMutationInput(selectedProjects);

    try {
      await createClientInvoiceBatchMutation({
        variables: {
          input: {
            projects: inputEntries,
            includeSupplierInvoices: true,
          },
        },
      });
      return setTimeout(() => {
        showSuccessNotification(
          "Invoice batch successfully created. You'll be notified when PDF is ready",
        );
      }, 2000);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
        setSelectedProjects(null);
      }, 2000);
    }
  };

  const onCreateBatch = () => {
    if (showConfirmation(selectedProjects)) {
      return openConfirmModal({
        cancelBtnCaption: 'No',
        btnCaption: 'Yes',
        id: 'confirmBatchGenerationModal',
        content:
          'Current batch contains invoice(s) that exceed Job Cost amount. Are you sure you want to continue?',
        onConfirm: (closeConfirmModal) => () => {
          closeConfirmModal();
          onCreateBatchClick();
        },
      });
    }
    return onCreateBatchClick();
  };

  return { isLoading, onCreateBatch };
};
