import * as R from 'ramda';
import { NOTHING_UI_STRING, JournalTypes } from '@poly/constants';

import { propEqLegacy } from './ramdaLegacyFunctions.js';

// pathOrNothingUI :: [String] -> Object -> Any
const pathOrNothingUI = R.pathOr(NOTHING_UI_STRING);

// formatMoneyTransactionPayeeNameColumnBase :: (MoneyTransaction -> String) -> MoneyTransaction -> String
export const formatMoneyTransactionPayeeNameColumnBase = (
  getLineDescriptionFn,
) =>
  R.converge(R.concat, [
    R.cond([
      [
        R.both(
          R.prop('is_split'),
          R.pathSatisfies(R.is(String), ['masterSupplier', 'company', 'name']),
        ),
        pathOrNothingUI(['masterSupplier', 'company', 'name']),
      ],
      [
        R.both(
          propEqLegacy('type', JournalTypes.MANUAL),
          R.propSatisfies(R.isNil, 'payee_name'),
        ),
        getLineDescriptionFn,
      ],
      [
        R.pathSatisfies(R.complement(R.isNil), ['supplier', 'company']),
        pathOrNothingUI(['supplier', 'company', 'name']),
      ],
      [R.T, pathOrNothingUI(['payee_name'])],
    ]),
    R.ifElse(R.prop('is_split'), R.always(' (Split)'), R.always('')),
  ]);

// formatMoneyTransactionPayeeNameColumn :: MoneyTransaction -> String
export const formatMoneyTransactionPayeeNameColumn =
  formatMoneyTransactionPayeeNameColumnBase(pathOrNothingUI(['description']));
