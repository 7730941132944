import React from 'react';
import { string } from 'prop-types';
import { entities, SidebarTasksTab } from '@poly/admin-ui';
import { taskCollections } from '@poly/constants';

export function PurchaseOrderSidebarTasksTab({
  _id,
  displayName,
  tabTitlePrefix,
}) {
  const tasksProps = {
    tabTitlePrefix,
    entity: {
      _id,
      preview: 'Purchase Order',
      name: entities.ADMIN_PURCHASE_ORDER,
      humanReadableId: displayName,
    },
    collection: taskCollections.ADMIN_PURCHASE_ORDERS,
  };

  return <SidebarTasksTab {...tasksProps} />;
}

PurchaseOrderSidebarTasksTab.propTypes = {
  _id: string.isRequired,
  displayName: string.isRequired,
  tabTitlePrefix: string,
};
