import * as R from 'ramda';
import styled from 'styled-components';
import React, { useContext } from 'react';
import { string, shape, oneOfType, arrayOf, node, bool } from 'prop-types';
import { Loader, Table } from '@poly/admin-book';
import {
  getUserWorkPhoneNumber,
  getUserCellPhoneNumber,
  getUserWorkPhoneObject,
  getUserCellPhoneObject,
} from '@poly/utils';
import { PeopleTableContext } from './hocs.js';
import { UnlinkContactBtn } from './UnlinkContactBtn.js';
import { EmailLink, EntityLink, PhoneLink } from '../../../components/Links.js';
import { InternationalPhoneDisplay } from '../../../components/InternationalAddressAndPhone/InternationalPhone/InternationalPhoneDisplay.js';
import { useContactUsersSearchByQuery } from '../../../hooks/users/index.js';
import { useSetInitialPaginationOnMount } from '../../../hooks/useSetInitialPaginationOnMount.js';
import { usePagination } from '../../../redux/pagination.js';
import { useSetItemsCount } from '../../../hooks/useSetItemsCount.js';
import { useMapConfigToTableProps } from '../../../hooks/useMapConfigToTableProps.js';

function ContractLink({ _id, fullName }) {
  const { url, SidebarLink } = useContext(PeopleTableContext);

  if (SidebarLink) return <SidebarLink {...{ _id, fullName }} />;

  return <EntityLink {...{ _id, fullName, url }} />;
}

ContractLink.propTypes = {
  _id: string.isRequired,
  fullName: oneOfType([string, arrayOf(node)]).isRequired,
};

const profilePropTypes = {
  profile: shape({
    cellPhoneNumber: string,
    workPhoneNumber: string,
    workPhoneNumberExt: string,
    faxPhoneNumber: string,
  }),
};

function InternationalWorkPhone({ profile }) {
  const phone = getUserWorkPhoneNumber({ profile });
  return <InternationalPhoneDisplay {...{ phone }} />;
}

InternationalWorkPhone.propTypes = profilePropTypes;

function InternationalMobilePhone({ profile }) {
  const phone = getUserCellPhoneNumber({ profile });
  return <InternationalPhoneDisplay {...{ phone }} />;
}

InternationalMobilePhone.propTypes = profilePropTypes;

const getTableConfig = (showClientManagerColumn) => [
  ['Name', ContractLink],
  ['Title', R.prop('title')],
  ['Phone', (contact) => <PhoneLink {...getUserWorkPhoneObject(contact)} />],
  ['Mobile', (contact) => <PhoneLink {...getUserCellPhoneObject(contact)} />],
  ['Email', EmailLink],
  ...(showClientManagerColumn
    ? [
        [
          'Client Manager',
          R.ifElse(R.prop('isClientManager'), R.always('Yes'), R.always('No')),
        ],
      ]
    : []),
  ['', UnlinkContactBtn],
];

const internationalTableConfig = [
  ['Name', ContractLink],
  ['Title', R.prop('title')],
  ['Phone', InternationalWorkPhone],
  ['Mobile', InternationalMobilePhone],
  ['Email', EmailLink],
  ['', UnlinkContactBtn],
];

const TableS = styled(Table)`
  th:last-child,
  td:last-child {
    width: 50px;
  }
`;

function PeopleTableComp(props) {
  const { isInternational, showClientManagerColumn } = props;

  useSetItemsCount(R.prop('count'), props);

  const internationalProps = useMapConfigToTableProps(
    R.prop('people'),
    internationalTableConfig,
    props,
  );

  const clientManagerShowProps = useMapConfigToTableProps(
    R.prop('people'),
    getTableConfig(true),
    props,
  );

  const clientManagerNotShowProps = useMapConfigToTableProps(
    R.prop('people'),
    getTableConfig(false),
    props,
  );

  if (isInternational) return <TableS {...internationalProps} />;

  if (showClientManagerColumn) return <TableS {...clientManagerShowProps} />;

  return <TableS {...clientManagerNotShowProps} />;
}

PeopleTableComp.propTypes = {
  isInternational: bool,
  showClientManagerColumn: bool,
};

// getContactsTotalCount :: (SearchContactsQueryResult, Name) -> Number
const getContactsTotalCount = (data, name) =>
  R.pathOr(0, [name, 'searchContacts', 'total'], data);

// getContacts :: (SearchContactsQueryResult, Name) -> Number
const getContacts = (data, name) =>
  R.pathOr(0, [name, 'searchContacts', 'hits'], data);

export function PeopleTable({ status, ...restProps }) {
  useSetInitialPaginationOnMount();

  const { pagination, setPagination } = usePagination();

  const { gqlSearchQuery, entity } = useContext(PeopleTableContext);

  const { result, loading } = useContactUsersSearchByQuery({
    gqlSearchQuery,
    entity,
    status,
  });

  if (loading) return <Loader />;

  return (
    <PeopleTableComp
      {...restProps}
      {...result}
      count={getContactsTotalCount(result, entity?.name)}
      people={getContacts(result, entity?.name)}
      pagination={pagination}
      setPagination={setPagination}
    />
  );
}

PeopleTable.displayName = 'PeopleTable';

PeopleTable.propTypes = {
  status: string.isRequired,
};
