import React, { useState } from 'react';
import { ALL } from '@poly/admin-ui/src/index.js';
import { useTableSorting } from '@poly/admin-ui/src/hooks/useTableSorting.js';
import { DefaultBodyWrapper } from '@poly/admin-book/src/Tabs/components.js';
import { Loader } from '@poly/admin-book/src/Loader/index.js';

import { TaskTemplatesHeader } from './TaskTemplatesHeader.js';
import { useTaskTemplates } from './useTaskTemplates.js';
import {
  TaskTemplatesTable,
  taskTemplatesTableConfig,
} from './TaskTemplatesTable.js';
import { NoSearchResultComponent } from './NoSearchResultComponent.js';

export function TaskTemplatesPage() {
  const [priority, setPriority] = useState(ALL);

  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: taskTemplatesTableConfig,
    column: 1,
  });

  const { taskTemplates, tableProps, searchText, loading } = useTaskTemplates({
    priority,
    sort,
  });

  const isNoTaskTemplates = !loading && !taskTemplates.length;
  const isNoSearchResult = isNoTaskTemplates && searchText;
  const isNoTaskTemplatesYet = isNoTaskTemplates && !searchText;

  return (
    <>
      <TaskTemplatesHeader priority={priority} setPriority={setPriority} />
      <DefaultBodyWrapper>
        {loading && <Loader />}
        {isNoSearchResult && (
          <NoSearchResultComponent
            iconName="noSearch"
            title="No search results"
            content="Unfortunately, nothing was found for your query, please try again"
          />
        )}
        {isNoTaskTemplatesYet && (
          <NoSearchResultComponent
            iconName="noItems"
            title="No task templates yet"
            content="Unfortunately, you don't have any templates created yet, but you can
          create them now"
          />
        )}
        {!isNoTaskTemplates && (
          <TaskTemplatesTable
            taskTemplates={taskTemplates}
            {...{ ...tableProps, ...tableSortingProps }}
          />
        )}
      </DefaultBodyWrapper>
    </>
  );
}
