import { useDispatch, useSelector } from 'react-redux';
import { createAction, handleAction } from 'redux-actions';
import { initialPagination } from '@poly/client-utils';

export const setPagination = createAction('SET_PAGINATION');

export const paginationReducer = handleAction(
  setPagination,
  (_, { payload }) => payload,
  initialPagination,
);

export const usePagination = () => {
  const dispatch = useDispatch();
  const pagination = useSelector((store) => store.pagination);

  return {
    pagination,
    setPagination: (payload) => dispatch(setPagination(payload)),
  };
};
