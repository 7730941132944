import React from 'react';
import { format } from 'date-fns';
import { Loader } from '@poly/admin-book';

import {
  tasksTotalItems,
  projectsTotalItems,
  masterProjectsTotalItems,
} from './totalItems.js';
import { UserDashboard } from './components/UserDashboard.js';
import { TotalInfoSections } from './components/TotalInfoSections.js';
import { useDashboardQueryWorker } from './useDashboardQueryWorker.js';
import {
  CompanyWorkflowContainer,
  MyWorkflowContainer,
  SectionTitleText,
  SubHeaderText,
  PageContainer,
} from './styles.js';

export function HomePage() {
  const { data = {}, loading } = useDashboardQueryWorker();

  if (loading) {
    return <Loader />;
  }

  const {
    dashboard: {
      globalMastersCounts,
      globalProjectsCounts,
      supplierInvoicesCounts,
    } = {},
  } = data;

  const date = format(new Date(), 'MMMM d, yyyy');

  const companyWorkflowSections = [
    {
      label: 'Global Projects',
      items: projectsTotalItems,
      counts: globalProjectsCounts,
    },
    {
      label: 'Masters',
      counts: globalMastersCounts,
      items: masterProjectsTotalItems,
    },
    {
      label: 'Tasks',
      items: tasksTotalItems,
      counts: supplierInvoicesCounts,
    },
  ];

  return (
    <PageContainer>
      <CompanyWorkflowContainer>
        <SectionTitleText>Workflow</SectionTitleText>
        <SubHeaderText margin="20px">{date}</SubHeaderText>
        <TotalInfoSections config={companyWorkflowSections} margin="30px" />
      </CompanyWorkflowContainer>
      <MyWorkflowContainer>
        <UserDashboard dashboardData={data} />
      </MyWorkflowContainer>
    </PageContainer>
  );
}
