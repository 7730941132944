import React from 'react';
import { bool } from 'prop-types';
import { BooleanTextWithDot, SectionWithCard } from '@poly/admin-book';
import { supplierAccountPropsTypes } from './supplierPropTypes.js';
import { MultiEmailsLinks } from '../../components/MultiEmailsLinks.js';

export function SupplierBankACHInfoSection({
  payByACH,
  routingNumber,
  accountingNumber,
  remittanceEmails,
  hasUserBankPrivateFieldsAccess,
}) {
  return (
    <SectionWithCard
      title="Bank ACH"
      list={[
        { title: 'Pay By ACH', elem: <BooleanTextWithDot isTrue={payByACH} /> },
        ...(!payByACH || !hasUserBankPrivateFieldsAccess
          ? []
          : [
              {
                title: 'Routing Number',
                elem: routingNumber,
              },
              {
                title: 'Account Number',
                elem: accountingNumber,
              },
              {
                title: 'Remittance Emails',
                elem: <MultiEmailsLinks emails={remittanceEmails} />,
              },
            ]),
      ]}
    />
  );
}

SupplierBankACHInfoSection.propTypes = {
  ...supplierAccountPropsTypes.bankACHPropTypes,
  hasUserBankPrivateFieldsAccess: bool.isRequired,
};
