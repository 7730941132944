import React from 'react';
import { useOutSidebarContext } from '@poly/client-utils';

import { SidebarIDs } from '../constants.js';
import { SubcontractorAgreementSidebar } from './SubcontractorAgreementSidebar.js';

export const useOpenSubcontractorAgreementSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();

  return ({ subcontractorAgreement, company }) =>
    openOutSidebar({
      width: 615,
      id: SidebarIDs.subcontractorAgreement,
      content: (
        <SubcontractorAgreementSidebar
          company={company}
          subcontractorAgreement={subcontractorAgreement}
        />
      ),
    });
};
