import * as R from 'ramda';
import {
  calculateTotal,
  isNilOrEmpty,
  ofArrayLegacy,
  propEqLegacy,
} from '@poly/utils';

/**
 * getReconciledPaymentsAmount :: ([Payment], [ID]) -> Number
 */
export const getReconciledPaymentsAmount = R.curry((payments, reconciledIDs) =>
  R.compose(
    calculateTotal(R.propOr(0, 'amount')),
    R.filter(R.converge(R.includes, [R.prop('_id'), R.always(reconciledIDs)])),
  )(payments),
);

// formatReconcileEntryInput :: MoneyTransaction -> ReconcileEntryInput
// ReconcileEntryInput = {
//   transactionNumber = String
//   bankClearingDate = Date
// }
const formatReconcileEntryInput = R.compose(
  R.reject(isNilOrEmpty),
  R.applySpec({
    transactionNumber: R.prop('transaction_number'),
    bankClearingDate: R.prop('bank_cleared_date'),
  }),
);

// isTransactionSelected :: [ID] -> MoneyTransaction -> Boolean
const isTransactionSelected = (selectedTransactions) =>
  R.propSatisfies(R.includes(R.__, selectedTransactions), '_id');

// formatReconcileTransactionsInput :: ([ID], [MoneyTransaction]) -> [ReconcileEntryInput]
export const formatReconcileTransactionsInput = (
  selectedTransactions,
  transactions,
) =>
  R.compose(
    R.map(formatReconcileEntryInput),
    R.filter(isTransactionSelected(selectedTransactions)),
  )(transactions);

// formatPendingReconcileTransactionsInput :: ([ID], [MoneyTransaction]) -> [ReconcileEntryInput]
export const formatPendingReconcileTransactionsInput = (
  selectedTransactions,
  transactions,
) =>
  R.compose(
    R.map(formatReconcileEntryInput),
    R.reject(isTransactionSelected(selectedTransactions)),
  )(transactions);

export const transactionChangedFieldName = 'isChanged';

// onChangeClearedDateHandler :: (MoneyTransaction, Date) -> [MoneyTransaction] -> [MoneyTransaction]
export const onChangeClearedDateHandler =
  (transaction, value) => (oldTransactions) =>
    R.converge(R.adjust(R.__, R.__, oldTransactions), [
      R.compose(
        R.findIndex(propEqLegacy('_id', transaction._id)),
        R.always(oldTransactions),
      ),
      R.compose(
        R.mergeLeft,
        R.assoc(transactionChangedFieldName, true),
        R.objOf('bank_cleared_date'),
      ),
    ])(value);

// getChangedTransactions :: ([MoneyTransaction], [String]) -> [MoneyTransaction]
export const getChangedTransactions = (transactions, selected) =>
  R.compose(
    R.uniq,
    R.unnest,
    R.juxt([
      R.compose(
        R.ifElse(R.isNil, R.always([]), ofArrayLegacy),
        R.find(R.complement(isTransactionSelected(selected))),
      ),
      R.filter(R.prop(transactionChangedFieldName)),
    ]),
  )(transactions);

// isTransactionsNotChanged :: [MoneyTransaction] -> Boolean
export const isTransactionsNotChanged = R.compose(
  isNilOrEmpty,
  R.find(R.prop(transactionChangedFieldName)),
);

// getRefetchDataTimeOut :: [MoneyTransaction] -> Number
export const getRefetchDataTimeOut = R.compose(
  R.add(2000),
  R.multiply(200),
  R.length,
);
