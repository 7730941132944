import { ADMIN_PO_SIDEBAR_ID } from '@poly/constants/src/appsRoutes.js';
import {
  assetSidebarTabs,
  clientSidebarTabs,
  propertySidebarTabs,
  masterProjectSidebarTabs,
  purchaseOrderSidebarTabs,
} from '../routes/constants.js';

// add here other tabs which need the same styles
export const DoubleWidthSidebarTabs = [
  assetSidebarTabs.assetPMs,
  assetSidebarTabs.assetProjects,
  clientSidebarTabs.clientMasters,
  clientSidebarTabs.clientProjects,
  clientSidebarTabs.clientContacts,
  clientSidebarTabs.clientProperties,
  clientSidebarTabs.clientMasterProperties,
  propertySidebarTabs.propertyAssets,
  propertySidebarTabs.propertyMasters,
  clientSidebarTabs.clientPortalUsers,
  propertySidebarTabs.propertyContacts,
  propertySidebarTabs.propertyChildProperties,
  propertySidebarTabs.propertyProjects,
  propertySidebarTabs.propertySuppliers,
  propertySidebarTabs.propertyLocationHierarchy,
  clientSidebarTabs.clientChildAccounts,
  masterProjectSidebarTabs.masterProjectChild,
  purchaseOrderSidebarTabs.purchaseOrderContacts,
  purchaseOrderSidebarTabs.purchaseMastersProjects,
];

export const SidebarIDs = {
  user: 'userSidebarId',
  asset: 'assetSidebarId',
  client: 'clientSidebarId',
  contact: 'contactSidebarId',
  project: 'projectSidebarId',
  property: 'propertySidebarId',
  userGroup: 'userGroupSidebarId',
  purchaseOrder: ADMIN_PO_SIDEBAR_ID,
  masterProject: 'masterProjectSidebarId',
  approveSupplierInvoice: 'approveSupplierInvoiceSidebarId',
  subcontractorAgreement: 'subcontractorAgreementSidebarId',
  accountingPeriodsUpdates: 'accountingPeriodsUpdatesSidebarId',
  requestedSupplierDocument: 'requestedSupplierDocumentSidebarId',
};
