export const getAssetModelsQuery = (manufacturerId) => ({
  bool: {
    ...(manufacturerId ? { must: { term: { manufacturerId } } } : {}),
    must_not: {
      exists: {
        field: 'deletedAt',
      },
    },
  },
});
