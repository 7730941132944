import { gql } from '@apollo/client';

export const DEFAULT_PROPERTY_QUERY = gql`
  query DEFAULT_PROPERTY_QUERY($id: ID!) {
    property(id: $id) {
      _id
      name
      address {
        formatted_address
      }
    }
  }
`;

export const CREATE_PROPERTY_MUTATION = gql`
  mutation CREATE_PROPERTY_MUTATION($input: CreatePropertyInput!) {
    createProperty(input: $input) {
      property {
        _id
      }
    }
  }
`;
