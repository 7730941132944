import React from 'react';
import { shape, string } from 'prop-types';
import { TextButton } from '@poly/admin-book';

import { useAddProjectSidebar } from '../../../sidebars/ProjectSidebar/forms/add/useAddProjectSidebar.js';

export function AddEntityProjectBtn({
  addEntityProjectQueries: { propertyId, clientId, projectType, type },
}) {
  const openAddProjectSidebar = useAddProjectSidebar(true);
  const onClick = () => {
    openAddProjectSidebar({
      type,
      clientId,
      propertyId,
      projectType,
    });
  };
  return <TextButton onClick={onClick}>Add New</TextButton>;
}

AddEntityProjectBtn.propTypes = {
  addEntityProjectQueries: shape({
    type: string,
    clientId: string,
    propertyId: string,
    projectType: string,
  }),
};
