import React from 'react';
import { PageLink } from '@poly/admin-ui';

import { routesNames } from '../../../routes/index.js';

export function AddNewSupplierBtn() {
  const additionalProps = {
    routeName: routesNames.ADD_SUPPLIER,
    children: 'Add New',
  };

  return <PageLink {...additionalProps} />;
}

AddNewSupplierBtn.displayName = 'AddNewSupplierBtn';
