import React from 'react';
import { func } from 'prop-types';
import { useOutSidebarContext } from '@poly/client-utils';

import { SidebarFormLayout } from '../../components/SidebarFormLayout.js';
import { editPurchaseOrderFormId } from '../constants.js';
import { EditPurchaseOrderForm } from './EditPurchaseOrderForm.js';

function EditPurchaseOrderSidebarContent({ onClose, ...formProps }) {
  return (
    <SidebarFormLayout
      onClose={onClose}
      formId={editPurchaseOrderFormId}
      title="Edit PO"
      submitButtonCaption="Save"
    >
      <EditPurchaseOrderForm {...formProps} />
    </SidebarFormLayout>
  );
}

EditPurchaseOrderSidebarContent.propTypes = {
  onClose: func.isRequired,
};

export const useEditPurchaseOrderSidebar = () => {
  const { openOutSidebar, closeOutSidebar } = useOutSidebarContext();

  const onCloseHandler = () => {
    closeOutSidebar(editPurchaseOrderFormId);
  };

  return (purchaseOrder) =>
    openOutSidebar({
      alwaysFirst: true,
      id: editPurchaseOrderFormId,
      width: 555,
      content: (
        <EditPurchaseOrderSidebarContent
          purchaseOrder={purchaseOrder}
          onClose={onCloseHandler}
        />
      ),
    });
};
