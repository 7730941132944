import { ProjectType, ProjectSupplierStatus } from '@poly/constants';
import {
  calculateTotal,
  getMarkupPercentBase,
  isNilOrEmpty,
  convertDollarsToCents,
  convertCentsToDollars,
  removePropDeep,
  propEqLegacy,
  pathEqLegacy,
} from '@poly/utils';
import * as R from 'ramda';

const { WORK_ORDER, BID } = ProjectType;

// takeOnlyAssignedSuppliers :: [ProjectSupplier] -> [ProjectSupplier]
export const takeOnlyAssignedSuppliers = R.filter(
  propEqLegacy('statusInProject', ProjectSupplierStatus.assigned),
);

// isMarkupsDisabled :: Project -> Boolean
const isMarkupsDisabled = R.anyPass([
  R.complement(R.propSatisfies(R.includes(R.__, [WORK_ORDER, BID]), 'type')),
  R.both(
    R.pathSatisfies(isNilOrEmpty, ['client', 'tmMarkupRules']),
    R.pathSatisfies(isNilOrEmpty, ['property', 'tmMarkupRules']),
  ),
  R.compose(isNilOrEmpty, takeOnlyAssignedSuppliers, R.propOr([], 'suppliers')),
]);

// prepareMarkupEntries :: { suppliers: [Supplier], markupEntries: [EstimateMarkupEntry] } -> [PreparedMarkupEntry]
// PreparedMarkupEntry = {
//    markup: Float
//    amount: Float
//    supplierId: {
//      _id: ID
//      name: String
//    }
// }
const prepareMarkupEntries = ({ suppliers, markupEntries }) =>
  R.map(({ _id, company: { name } = {} }) =>
    R.compose(
      R.applySpec({
        supplierId: {
          _id: R.pathOr(_id, ['supplier', '_id']),
          name: R.pathOr(name, ['supplier', 'company', 'name']),
        },
        markup: R.compose(convertCentsToDollars, R.propOr(0, 'markup')),
        amount: R.compose(
          R.when(R.is(Number), convertCentsToDollars),
          R.prop('amount'),
        ),
      }),
      R.find(pathEqLegacy(['supplier', '_id'], _id)),
    )(markupEntries),
  )(suppliers);

// getEstimateInitialValues :: Project -> ProjectEstimateFormValues
// ProjectEstimateFormValues = {
//   isManualMode: Boolean
//   description: Object
//   markupEntries: [{ supplierId: { _id: String, name: String } }]
//   afterHoursCall: Number
//   isBreakdown: Boolean
//   emailTo: [{ value: String, label: String }]
// }
export const getEstimateInitialValues = R.compose(
  removePropDeep('__typename'),
  R.applySpec({
    projectId: R.prop('_id'),
    woCharge: R.ifElse(
      R.pathSatisfies(R.isNil, ['property', 'woCharge']),
      R.pathOr(0, ['client', 'woCharge']),
      R.pathOr(0, ['property', 'woCharge']),
    ),
    existingEstimateTotal: R.pathOr(0, ['estimate', 'total']),
    description: R.ifElse(
      R.propSatisfies(isNilOrEmpty, 'persistScopeOfWork'),
      R.either(
        R.path(['estimate', 'description']),
        R.prop('invoiceDescription'),
      ),
      R.prop('persistScopeOfWork'),
    ),
    markupEntries: R.compose(
      prepareMarkupEntries,
      R.applySpec({
        suppliers: R.compose(
          takeOnlyAssignedSuppliers,
          R.propOr([], 'suppliers'),
        ),
        markupEntries: R.pathOr([], ['estimate', 'markupEntries']),
      }),
    ),
    emailTo: R.compose(
      R.map(R.applySpec({ value: R.identity, label: R.identity })),
      R.uniq,
      R.reject(R.isNil),
      R.ifElse(
        R.pathSatisfies(R.is(String), ['estimate', 'emailTo', '0']),
        R.path(['estimate', 'emailTo']),
        R.juxt([
          R.path(['manager', 'email']),
          R.path(['clientManager', 'email']),
          R.path(['siteContact', 'email']),
          R.path(['property', 'branchManager', 'email']),
          R.path(['client', 'clientGroupEmail']),
        ]),
      ),
    ),
    manualEntries: R.compose(
      R.when(R.isEmpty, R.always([{}])),
      R.map(R.over(R.lensProp('rate'), convertCentsToDollars)),
      R.pathOr([], ['estimate', 'manualEntries']),
    ),
    isAfterHours: R.pathSatisfies(R.complement(R.isNil), [
      'estimate',
      'afterHoursCall',
    ]),
    afterHoursCall: R.compose(
      convertDollarsToCents,
      R.pathOr(0, ['client', 'staffRates', 'afterHoursCall']),
    ),
    attachments: R.pathOr([], ['estimate', 'attachments']),
    isBreakdown: R.pathOr(false, ['estimate', 'isBreakdown']),
    timeEntries: R.compose(
      R.map(R.evolve({ rate: convertCentsToDollars })),
      R.pathOr([], ['estimate', 'timeEntries']),
    ),
    clientContactOptions: R.compose(
      R.map(
        R.applySpec({
          value: R.prop('email'),
          label: ({ email, fullName }) => `${fullName} (${email})`,
        }),
      ),
      R.reject(R.propSatisfies(R.isNil, 'email')),
      R.pathOr([], ['client', 'searchContacts', 'hits']),
    ),
  }),
);

// getClientConfigs :: Project -> EstimateConfig
// EstimateConfig = {
//   rates: { [String]: ClientStaffRate }
//   tmMarkupRules: [ClientMarkupRule]
//   afterHoursCallAmount: Number
//   taxPercent: Number
//   isMarkupsDisabled: Boolean
// }
export const getClientConfigs = R.applySpec({
  tmMarkupRules: R.ifElse(
    R.pathSatisfies(isNilOrEmpty, ['property', 'tmMarkupRules']),
    R.path(['client', 'tmMarkupRules']),
    R.path(['property', 'tmMarkupRules']),
  ),
  afterHoursCall: R.compose(
    convertDollarsToCents,
    R.pathOr(0, ['client', 'staffRates', 'afterHoursCall']),
  ),
  rates: R.compose(
    R.reduce((acc, rate) => ({ ...acc, [rate.description]: rate }), {}),
    R.pathOr([], ['client', 'staffRates', 'customRates']),
  ),
  taxPercent: R.ifElse(
    R.prop('exemptSalesTax'),
    R.always(0),
    R.ifElse(
      R.prop('property'),
      R.path(['property', 'taxRate']),
      R.path(['client', 'taxRate']),
    ),
  ),
  isMarkupsDisabled,
});

// getMarkupPercent :: [ClientMarkupRule] -> [MarkupEntry] -> Number
export const getMarkupPercent = (tmMarkupRules) =>
  R.compose(
    R.divide(R.__, 100),
    getMarkupPercentBase(R.__, tmMarkupRules),
    calculateTotal(R.propOr(0, 'amount')),
  );

// formatEntriesInput :: Boolean -> ProjectEstimateFormValues -> ProjectEstimateInput
export const formatEntriesInput = (isManualMode) =>
  R.compose(
    R.evolve({
      markupEntries: R.compose(
        R.map(
          R.compose(
            R.evolve({
              amount: convertDollarsToCents,
              markup: R.compose(Math.round, convertDollarsToCents),
              supplierId: R.prop('_id'),
            }),
            R.omit(['total']),
          ),
        ),
      ),
      manualEntries: R.compose(
        R.map(
          R.compose(
            R.evolve({
              rate: R.compose(Math.round, convertDollarsToCents),
            }),
            R.omit(['total']),
          ),
        ),
      ),
      timeEntries: R.compose(
        R.map(
          R.compose(
            R.evolve({ rate: convertDollarsToCents }),
            R.omit(['total']),
          ),
        ),
      ),
    }),
    R.ifElse(
      R.always(isManualMode),
      R.omit(['markupEntries']),
      R.omit(['manualEntries']),
    ),
    R.unless(R.prop('isAfterHours'), R.omit(['afterHoursCall'])),
  );

// formatProjectEstimateMutationInput :: Boolean -> ProjectEstimateFormValues -> ProjectEstimateInput
export const formatProjectEstimateMutationInput = (isManualMode) =>
  R.compose(
    R.reject(R.either(R.isEmpty, R.equals(0))),
    R.omit([
      'isAfterHours',
      'isManualMode',
      'existingEstimateTotal',
      'clientContactOptions',
    ]),
    R.evolve({
      attachments: R.map(R.pick(['upload', 'fileName'])),
      emailTo: R.compose(R.reject(isNilOrEmpty), R.map(R.prop('value'))),
    }),
    formatEntriesInput(isManualMode),
  );
