import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';

import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { BalanceSheetHeader } from './BalanceSheetHeader.js';
import { BalanceSheetTable } from './BalanceSheetTable.js';
import { ReportPageTableWrapper } from '../../components/ReportPageTableWrapper.js';

// eslint-disable-next-line import/no-unused-modules
export const balanceSheetQuery = gql`
  query BALANCE_SHEET($input: BalanceSheetInput!) {
    balanceSheet(input: $input) {
      category
      parentAccounts {
        parentAccount {
          _id
          name
          code
        }
        accounts {
          account {
            _id
            code
            name
            accountType {
              _id
              is_contra_type
            }
          }
          balance
        }
      }
    }
  }
`;

export function BalanceSheetPage() {
  const [filters, setFilters] = useState(null);

  const { data, loading } = useQuery(balanceSheetQuery, {
    variables: { input: filters },
    fetchPolicy: 'network-only',
    skip: !filters,
  });

  return (
    <PageWithSearchHeader headerHeight="120px">
      <BalanceSheetHeader
        setFilters={setFilters}
        loading={loading}
        filters={filters}
        data={data}
      />
      <ReportPageTableWrapper loading={loading}>
        <BalanceSheetTable data={data} />
      </ReportPageTableWrapper>
    </PageWithSearchHeader>
  );
}
