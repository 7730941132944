import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { AccountTypeStatus } from '@poly/constants';
import { propEqLegacy } from '@poly/utils';

const DIVISIONS_QUERY = gql`
  query DIVISIONS_QUERY {
    getDivisionOptions {
      _id
      status
      is_default_client_invoice_account
    }
  }
`;

// getDefaultClientInvoiceAccountId :: DivisionOptionsQuery -> ID
const getDefaultClientInvoiceAccountId = R.compose(
  R.prop('_id'),
  R.find(
    R.both(
      R.prop('is_default_client_invoice_account'),
      propEqLegacy('status', AccountTypeStatus.ACTIVE),
    ),
  ),
  R.propOr([], 'getDivisionOptions'),
);

export const useDefaultClientInvoiceAccount = () => {
  const { data, loading } = useQuery(DIVISIONS_QUERY);

  const divisionAccountId = getDefaultClientInvoiceAccountId(data);

  return { loading, divisionAccountId };
};
