import React from 'react';
import { useAddressSectionLogic } from '@poly/client-utils';
import { AddressSectionComp } from './AddressSectionComp.js';

export function AddressSection(props) {
  const addressSectionProps = useAddressSectionLogic(props);

  return <AddressSectionComp {...props} {...addressSectionProps} />;
}

AddressSection.displayName = 'AddressSection';
