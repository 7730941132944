import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { RadioButton } from '@poly/admin-book';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { supplierInvoiceRequestOptions } from '@poly/constants';

const RadioButtonWrapper = styled.div`
  display: inline-block;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 30%;
  margin-left: 10px;
`;

const RadioButtonC = styled(RadioButton)`
  margin-right: 10px;
  margin-bottom: 10px;
`;

const getCurrentSupplierId = (index) => R.path([index, 'supplierId']);
const getCurrentSupplierRating = (index) => R.path([index, 'rating']);

export function SupplierInvoiceRequestOptions(props) {
  const {
    index,
    value,
    supplierRequest,
    onSupplierRate,
    supplierHaveAccountEmail,
    hideRequestOptions,
  } = props;

  const supplierId = getCurrentSupplierId(index)(value);
  const supplierRating = getCurrentSupplierRating(index)(value);

  const onChangeOption = (optionValue) =>
    onSupplierRate({
      supplierId,
      supplierRequest: optionValue,
      rating: { average: supplierRating },
    });

  return (
    <RadioButtonWrapper>
      {supplierHaveAccountEmail && !hideRequestOptions && (
        <RadioButtonC
          name={`sendNow_${index}`}
          label="Send Now"
          onChange={() => onChangeOption(supplierInvoiceRequestOptions.sendNow)}
          value={supplierRequest === supplierInvoiceRequestOptions.sendNow}
        />
      )}
      {!hideRequestOptions && (
        <RadioButtonC
          name={`manualFollowUp_${index}`}
          label="Manual Follow Up"
          onChange={() =>
            onChangeOption(supplierInvoiceRequestOptions.manualFollowUp)
          }
          value={
            supplierRequest === supplierInvoiceRequestOptions.manualFollowUp
          }
        />
      )}
      {!hideRequestOptions && (
        <RadioButtonC
          name={`sendLater_${index}`}
          label="Send Later"
          onChange={() =>
            onChangeOption(supplierInvoiceRequestOptions.sendLater)
          }
          value={supplierRequest === supplierInvoiceRequestOptions.sendLater}
        />
      )}
    </RadioButtonWrapper>
  );
}

SupplierInvoiceRequestOptions.propTypes = {
  supplierRequest: string.isRequired,
  index: number.isRequired,
  onSupplierRate: func.isRequired,
  supplierHaveAccountEmail: bool.isRequired,
  hideRequestOptions: bool.isRequired,
  value: arrayOf(
    shape({
      supplierId: string,
      supplierName: string,
      rating: number,
    }),
  ).isRequired,
};
