import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  arrayOf,
  bool,
  element,
  func,
  number,
  oneOfType,
  string,
} from 'prop-types';

import { getThemeColor, getThemeFont, getThemeProp } from '../utils.js';
import { IconButton } from '../IconButton/index.js';
import { DO_NOT_HIDE_NOTIFICATION_TIME } from './constants.js';

const getNotificatorColor =
  (prop) =>
  ({ type, theme }) =>
    getThemeColor(['notificator', type, prop])({ theme });

const NotificationWrapper = styled.div`
  font-weight: ${getThemeFont(['regular'])};
  font-size: 15px;
  background-color: ${getNotificatorColor('background')};
  border-radius: ${getThemeProp(['defaultBorderRadius'])};
  border: solid 1px ${getNotificatorColor('secondaryLighter1')};
  color: ${getNotificatorColor('text')};
  padding: 15px 20px 15px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const Message = styled.div`
  max-width: ${({ hideCloseBtn }) => (hideCloseBtn ? '230px' : '210px')};
  word-break: break-word;
`;

export function Notification({
  type,
  message,
  id,
  onClose,
  time,
  hideCloseBtn,
}) {
  useEffect(() => {
    let timerId;
    if (time !== DO_NOT_HIDE_NOTIFICATION_TIME) {
      timerId = setTimeout(() => onClose(id), time * 1000);
    }
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, []);

  return (
    <NotificationWrapper type={type}>
      <Message hideCloseBtn={hideCloseBtn}>{message}</Message>
      {!hideCloseBtn && (
        <IconButton
          name="close"
          color="#6f7882"
          hoverColor="#292d33"
          onClick={() => onClose(id)}
          size={8}
        />
      )}
    </NotificationWrapper>
  );
}

export const notificationPropTypes = {
  type: string.isRequired,
  message: oneOfType([arrayOf(oneOfType([string, element])), string, element])
    .isRequired,
  id: string.isRequired,
  time: number.isRequired,
  hideCloseBtn: bool,
};

Notification.propTypes = {
  ...notificationPropTypes,
  onClose: func.isRequired,
};
