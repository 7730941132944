import * as R from 'ramda';
import { taskActionTypes, ProjectType } from '@poly/constants';
import {
  hasHousekeepingMissingWeeklyServiceTickets,
  isHousekeepingOverBilledBySupplier,
} from '@poly/utils';

// invoicesExceedsAllowedCharges :: Project -> Boolean
const invoicesExceedsAllowedCharges = R.converge(
  isHousekeepingOverBilledBySupplier,
  [R.prop('total'), R.identity],
);

// getCompleteTaskValidation :: (String, Project, NotificationHandler) -> CompleteTaskValidationHelpers
// CompleteTaskValidationHelpers = {
//   isSupplierAssignTask: Boolean,
//   isSupplierInvoiceTask: Boolean,
//   validateBeforeComplete: Function,
// }
export const getCompleteTaskValidation = (
  action,
  project,
  showWarningNotification,
) => {
  const isCloseProjectTask = action === taskActionTypes.CLOSE_PROJECT;
  const isSupplierAssignTask = action === taskActionTypes.SUPPLIER_ASSIGN;
  const isSupplierInvoiceTask = action === taskActionTypes.SUPPLIER_INVOICE;
  const isClientInvoiceTask =
    action === taskActionTypes.REPORT_ONLY_CLIENT_INVOICE;
  const isCompleteProjectTask = action === taskActionTypes.PROJECT_COMPLETE;
  const isHouseKeepingProjectTask =
    !!project && project.type === ProjectType.HOUSEKEEPING;

  // eslint-disable-next-line consistent-return
  const validateBeforeComplete = () => {
    if (
      isHouseKeepingProjectTask &&
      isCompleteProjectTask &&
      hasHousekeepingMissingWeeklyServiceTickets(project)
    ) {
      return showWarningNotification(
        'You can not Complete this Project without all weekly tickets being approved.',
      );
    }

    if (
      isHouseKeepingProjectTask &&
      isCloseProjectTask &&
      invoicesExceedsAllowedCharges(project)
    ) {
      return showWarningNotification(
        'You can not Close Project.  The Supplier Invoices exceed the approved charges.',
      );
    }
  };

  return {
    isCloseProjectTask,
    isSupplierAssignTask,
    isSupplierInvoiceTask,
    isClientInvoiceTask,
    validateBeforeComplete,
  };
};
