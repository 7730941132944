import { assocBy, calculateTotal, propEqLegacy } from '@poly/utils';
import * as R from 'ramda';

export const RECONCILIATION_CUSTOM_ROW_TYPE = 'custom_table_row_type';

// alwaysClearedTransactionsRow :: (String, String) -> _ -> ReconciliationCustomRow
//   ReconciliationCustomRow = {
//     _id: String
//     text: String
//     type: String
//     isBold: Bool
//     indentLevel: Number
//     amount: Number
//   }
export const alwaysTextHeaderRow = (text, _id) =>
  R.always({
    _id,
    text,
    type: RECONCILIATION_CUSTOM_ROW_TYPE,
    isBold: true,
    indentLevel: 1,
  });

// getTotalTransactionsRow :: (String, String) -> ReconciliationReportResult -> ReconciliationCustomRow
export const getTotalTransactionsRow = (text, _id) =>
  R.compose(
    R.mergeRight({
      _id,
      text,
      type: RECONCILIATION_CUSTOM_ROW_TYPE,
      isBold: true,
      hasAmountBorder: true,
      hasNoTopAmountBorder: true,
      indentLevel: 1,
    }),
    R.objOf('amount'),
  );

// createGetSectionTitleRow :: ReportSection -> _ -> ReconciliationCustomRow
//   ReportSection = {
//     title: String
//     propName: String
//   }
const createGetSectionTitleRow = ({ title }) =>
  R.always({
    _id: `${title}_title`,
    type: RECONCILIATION_CUSTOM_ROW_TYPE,
    text: title,
    isBold: true,
    indentLevel: 2,
  });

// createGetSectionTotalRow :: ReportSection -> ReconciliationReportResult -> ReconciliationCustomRow
const createGetSectionTotalRow = ({ title, propName }) =>
  R.compose(
    R.mergeRight({
      _id: `${title}_total`,
      type: RECONCILIATION_CUSTOM_ROW_TYPE,
      text: `Total ${title}`,
      isBold: true,
      hasAmountBorder: true,
      indentLevel: 2,
    }),
    R.objOf('amount'),
    R.prop(`${propName}Total`),
  );

export const alwaysEmptyRow = R.always({
  type: RECONCILIATION_CUSTOM_ROW_TYPE,
  isEmpty: true,
});

// assocTargetTransactionLinesForProp :: ReconciliationReportResult -> ReconciliationReportResult
export const assocTargetTransactionLinesForProp = (prop) =>
  assocBy(
    prop,
    R.converge(R.map, [
      R.compose(
        (accountId) =>
          R.converge(R.mergeRight, [
            R.identity,
            R.compose(
              R.find(propEqLegacy('account_id', accountId)),
              R.propOr([], 'lines'),
            ),
          ]),
        R.path(['account', '_id']),
      ),
      R.propOr([], prop),
    ]),
  );

// assocWithTotal :: (String, String) -> ReconciliationReportResult -> ReconciliationReportResult
export const assocWithTransactionsTotal = (key, prop) =>
  assocBy(
    key,
    R.compose(calculateTotal(R.propOr(0, 'amount')), R.propOr([], prop)),
  );

// getTransactionsSection :: ReportSection :: ReconciliationReportResult -> [ReconciliationCustomRow]
export const getTransactionsSection = (reportSections) =>
  R.compose(
    R.flatten,
    R.map(
      R.juxt([
        createGetSectionTitleRow,
        R.compose(R.propOr([]), R.prop('propName')),
        createGetSectionTotalRow,
      ]),
    ),
  )(reportSections);
