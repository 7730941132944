import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';

const USER_GROUP_QUERY = gql`
  query USER_GROUP_QUERY($id: ID!) {
    userGroup(id: $id) {
      _id
      name
      defaultLoginApp
      isTwoFactorAuthRequired
      accessItems {
        uid
        permission
        types {
          clientId {
            equals
            flippedContains
            notFlippedContains
            userGroupVariableId
          }
          assetId {
            equals
            flippedContains
            notFlippedContains
            userGroupVariableId
          }
          createdBy {
            equals
            flippedContains
            notFlippedContains
            userGroupVariableId
          }
          contactId {
            equals
            flippedContains
            notFlippedContains
            userGroupVariableId
          }
          technicianId {
            equals
            flippedContains
            notFlippedContains
            userGroupVariableId
          }
          projectId {
            equals
            flippedContains
            notFlippedContains
            userGroupVariableId
          }
          supplierId {
            equals
            flippedContains
            notFlippedContains
            userGroupVariableId
          }
          propertyId {
            equals
            flippedContains
            notFlippedContains
            userGroupVariableId
          }
          userGroupId {
            equals
            flippedContains
            notFlippedContains
            userGroupVariableId
          }
          clientApp {
            equals
            flippedContains
            notFlippedContains
            userGroupVariableId
          }
          updateType {
            equals
            flippedContains
            notFlippedContains
            userGroupVariableId
          }
          projectType {
            equals
            flippedContains
            notFlippedContains
            userGroupVariableId
          }
          parentProjectType {
            equals
            flippedContains
            notFlippedContains
            userGroupVariableId
          }
          supplierSource {
            equals
            flippedContains
            notFlippedContains
            userGroupVariableId
          }
          fileCollection {
            equals
            flippedContains
            notFlippedContains
            userGroupVariableId
          }
          poAmount {
            equals
            gt
            gte
            lt
            lte
            userGroupVariableId
          }
        }
      }
      variables {
        id
        name
        type
      }
    }
  }
`;

export const useUserGroupByIdQuery = (userGroupId) => {
  const { data, loading } = useQuery(USER_GROUP_QUERY, {
    variables: { id: userGroupId },
    skip: !userGroupId,
  });

  return { userGroup: R.pathOr({}, ['userGroup'], data), loading };
};
