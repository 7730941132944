import React from 'react';
import styled from 'styled-components';
import { Link, useRouterQuery } from '@poly/client-routing';
import { MenuGenerator, Icon } from '@poly/admin-book';
import { GlobalSearchInput } from '@poly/admin-ui';
import { isBeta } from '@poly/client-utils';

import { UserPopup } from './UserPopupCont.js';
import { appBarMenuItems } from './constants.js';
import { GoogleDocsLink } from './GoogleDocsLink.js';
import { GlobalAddButton } from './GlobalAddButton.js';
import { AlertPopup } from './AlertPopUp/AlertPopup.js';
import { SidebarIDs } from '../../sidebars/constants.js';
import { HistoryPopUp } from './HistoryPopUp/HistoryPopUp.js';
import { AppBarPart, AppBarWrapper } from './appBarStyles.js';
import { GlobalSearchResult } from '../../modules/globalSearch/globalSearchResult/GlobalSearchResult.js';
import { useAppBarPayload } from './useAppBarPayload.js';

const LogoImageS = styled.img`
  width: 32px;
  margin: 6px;
`;

function GlobalSearch() {
  const {
    [SidebarIDs.asset]: assetSidebarId,
    [SidebarIDs.contact]: contactSidebarId,
  } = useRouterQuery([SidebarIDs.asset, SidebarIDs.contact]);
  const isLocked = !!(assetSidebarId || contactSidebarId);
  return (
    <GlobalSearchInput
      GlobalSearchResult={GlobalSearchResult}
      isLocked={isLocked}
    />
  );
}

export function AppBar() {
  const menuPayload = useAppBarPayload();

  return (
    <AppBarWrapper id="app-bar">
      <AppBarPart>
        <Link href="/">
          {isBeta ? (
            <Icon size={43} name="beta" />
          ) : (
            <LogoImageS src="/images/poly-icon.png" alt="logo-icon" />
          )}
        </Link>
        <MenuGenerator menuItems={appBarMenuItems} menuPayload={menuPayload} />
      </AppBarPart>
      <AppBarPart>
        <GlobalAddButton />
        <GlobalSearch />
        <AlertPopup />
        <HistoryPopUp />
        <GoogleDocsLink />
        <UserPopup />
      </AppBarPart>
    </AppBarWrapper>
  );
}
