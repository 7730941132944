import React from 'react';
import { instanceOf, number, shape } from 'prop-types';
import styled from 'styled-components';
import { LinkButtonWithLoader } from '@poly/admin-book';
import { alwaysNewDate, formatDate } from '@poly/utils';
import { CommonPrintLayout } from '@poly/admin-ui';
import { openPrintWindowWithData } from '@poly/client-utils';
import { PRINT_PDF_CAPTION } from '@poly/constants';

import {
  wipRecordsPropTypes,
  WorkInProgressTable,
} from './WorkInProgressTable.js';

export const ExportPDFBtn = styled(LinkButtonWithLoader)`
  text-align: end;
`;

export const CenteredSubtitle = styled.div`
  text-align: center;
`;

export function WorkInProgressExportPDFBtn({
  queryFilters,
  sorting,
  wipRecords,
}) {
  const handleClick = async () => {
    await openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: WorkInProgressTable,
      fileName: 'work_in_progress_report',
      metaData: {
        title: 'Work In Progress Report',
        additional: (
          <CenteredSubtitle>
            {`As of ${formatDate(queryFilters.date || alwaysNewDate())}`}
          </CenteredSubtitle>
        ),
      },
      initialSorting: sorting,
      isPrintPDF: true,
      wipRecords,
    });
  };

  return <ExportPDFBtn onClick={handleClick}>{PRINT_PDF_CAPTION}</ExportPDFBtn>;
}

WorkInProgressExportPDFBtn.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  queryFilters: shape({
    date: instanceOf(Date),
  }),
  sorting: shape({
    columnKey: number.isRequired,
    dir: number.isRequired,
  }),
  wipRecords: wipRecordsPropTypes,
};
