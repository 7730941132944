import React from 'react';
import { bool, func } from 'prop-types';
import { ClientSelect } from '@poly/admin-ui';
import { DateRangePicker, NumberInputHTML5 } from '@poly/admin-book';
import { propEqLegacy } from '@poly/utils';

import { ProjectSelect } from '../../components/ProjectsSelect.js';
import { JustifyFlexEnd } from '../../components/FlexContainer.js';
import { SearchBySelector } from '../SearchClientInvoices/SearchBySelector.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { FiltersContainer } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import {
  searchClientInvoicesFilters,
  filterClientInvoiceByOptions,
} from '../SearchClientInvoices/constants.js';
import {
  EmptyColumnS,
  searchClientInvoicesPageHeaderConfig,
} from '../SearchClientInvoices/SearchClientInvoicesPageHeader.js';
import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';

const searchVoidClientInvoicesPageHeaderConfig = [
  ...searchClientInvoicesPageHeaderConfig,
  {
    name: searchClientInvoicesFilters.invoiceBy,
    defaultValue: filterClientInvoiceByOptions.one,
  },
  {
    name: searchClientInvoicesFilters.invoiceNumberFrom,
    defaultValue: '',
  },
  {
    name: searchClientInvoicesFilters.invoiceNumberTo,
    defaultValue: '',
  },
];

// checkIsSearchByRangeInvoiceNumbers :: { invoiceBy: String } -> Boolean
const checkIsSearchByRangeInvoiceNumbers = propEqLegacy(
  searchClientInvoicesFilters.invoiceBy,
  filterClientInvoiceByOptions.range,
);

export function SearchVoidClientInvoicesPageHeader({ setFilters, loading }) {
  const { searchFilters, onReset, handlers } = useSearchFilters(
    searchVoidClientInvoicesPageHeaderConfig,
  );

  const isSearchByRangeInvoiceNumbers =
    checkIsSearchByRangeInvoiceNumbers(searchFilters);

  const onResetSearch = () => {
    onReset();
    setFilters(null);
  };

  const onSearch = () => setFilters(searchFilters);

  return (
    <SearchPageHeaderContainer title="Void Client Invoice">
      <FiltersContainer>
        <FlexSpaceBetween>
          <SearchHeaderColumn titleWidth="120px" title="Client">
            <ClientSelect
              value={searchFilters.clientId}
              onChange={handlers.clientId}
              includeAllOption={false}
              width="100%"
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn titleWidth="120px" title="Date">
            <DateRangePicker
              value={searchFilters.invoiceDate}
              onChange={handlers.invoiceDate}
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn filterWidth="390px">
            <JustifyFlexEnd>
              <SearchHeaderButton
                size="small"
                styleType="basicPrimary"
                onClick={onResetSearch}
              >
                Reset
              </SearchHeaderButton>
              <SearchHeaderButton
                size="small"
                onClick={onSearch}
                loader={loading}
              >
                Search
              </SearchHeaderButton>
            </JustifyFlexEnd>
          </SearchHeaderColumn>
        </FlexSpaceBetween>
        <FlexSpaceBetween>
          <SearchHeaderColumn titleWidth="120px" title="Invoice #">
            <SearchBySelector
              width="100%"
              onChange={handlers.invoiceBy}
              value={searchFilters.invoiceBy}
              name="InvoiceBy"
              isClearable={false}
              required
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn titleWidth="120px" title="Project #">
            <ProjectSelect
              width="100%"
              onChange={handlers.projectId}
              value={searchFilters.projectId}
            />
          </SearchHeaderColumn>
          <EmptyColumnS />
        </FlexSpaceBetween>
        <FlexSpaceBetween>
          {!isSearchByRangeInvoiceNumbers && (
            <SearchHeaderColumn titleWidth="120px" title="Invoice #">
              <NumberInputHTML5
                width="100%"
                onChange={handlers.invoiceNumber}
                value={searchFilters.invoiceNumber}
                name="InvoiceNumber"
                maxLength={9}
                changeOnBlur
              />
            </SearchHeaderColumn>
          )}
          {isSearchByRangeInvoiceNumbers && (
            <>
              <SearchHeaderColumn titleWidth="120px" title="From Invoice #">
                <NumberInputHTML5
                  width="100%"
                  onChange={handlers.invoiceNumberFrom}
                  value={searchFilters.invoiceNumberFrom}
                  name="FromInvoiceNumber"
                  maxLength={9}
                  changeOnBlur
                />
              </SearchHeaderColumn>
              <SearchHeaderColumn titleWidth="120px" title="To Invoice #">
                <NumberInputHTML5
                  width="100%"
                  onChange={handlers.invoiceNumberTo}
                  value={searchFilters.invoiceNumberTo}
                  name="ToInvoiceNumber"
                  maxLength={9}
                  changeOnBlur
                />
              </SearchHeaderColumn>
            </>
          )}
          <EmptyColumnS />
        </FlexSpaceBetween>
      </FiltersContainer>
    </SearchPageHeaderContainer>
  );
}

SearchVoidClientInvoicesPageHeader.propTypes = {
  loading: bool,
  setFilters: func,
};
