import { gql } from '@apollo/client';
import { commonSortQuery, useReactiveQuery } from '@poly/client-utils';
import { useRouterParams } from '@poly/client-routing';
import { ASC_SORT_ORDER } from '@poly/constants';
import { useMasterSuppliersSearchInput } from '@poly/admin-ui';

import { commonInvoiceFields } from '../../../modules/core/hooks/invoices/fragments.js';
import { INVOICES_BY_SEARCH_SUB } from '../../../modules/core/hooks/invoices/subscriptions.js';

const MASTER_SUPPLIER_INVOICES = gql`
  query MASTER_SUPPLIER_INVOICES($id: ID!, $input: CollectionSearchParams!) {
    masterSupplier(id: $id) {
      _id
      company {
        name
      }
      searchInvoices(input: $input) {
        hits {
          ...commonInvoiceFields
          paymentDueDate
          paymentDueDateChangeReason
          rejectionReason
          project {
            _id
            type
            projectId
          }
          paidBy {
            _id
            fullName
          }
          paidAt
        }
        total
      }
    }
  }

  ${commonInvoiceFields}
`;

export const useMasterSupplierInvoicesQuery = (sort, fetchPolicy) => {
  const { supplierId } = useRouterParams(['supplierId']);
  const input = useMasterSuppliersSearchInput({
    sort: sort || commonSortQuery(['invoiceNumber'])(ASC_SORT_ORDER),
  });

  const queryOptions = {
    ...(!!fetchPolicy && { fetchPolicy }),
    variables: { id: supplierId, input },
  };

  const subscriptionOptions = {
    ...(!!fetchPolicy && { fetchPolicy }),
    variables: {
      searchInput: {
        ...input,
        query: { match: { masterSupplierId: supplierId } },
      },
    },
  };

  const { data, loading } = useReactiveQuery(
    MASTER_SUPPLIER_INVOICES,
    INVOICES_BY_SEARCH_SUB,
    { queryOptions, subscriptionOptions },
  );

  return { data, loading };
};
