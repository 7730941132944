import React from 'react';
import styled from 'styled-components';
import { string, bool } from 'prop-types';
import { getThemeColor } from '@poly/admin-book';
import { isBeta } from '@poly/client-utils';

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ImageS = styled.img`
  width: ${({ size }) => size}px;
`;

const BetaHolder = styled.div.attrs(() => ({ children: 'Beta' }))`
  display: flex;
  font-size: 18px;
  font-family: 'TTNormsMedium', sans-serif;
  color: ${getThemeColor(['accent'])};
  margin-left: 15px;
`;

const propTypes = {
  isAAC: bool,
  skipBeta: bool,
  className: string,
};
const defaultProps = {
  isAAC: false,
  className: '',
  skipBeta: false,
};

export function ESFMFullLogo({ className, skipBeta, isAAC }) {
  const size = isAAC ? 200 : 150;
  const imageSrc = `/images/${isAAC ? 'aac-logo-full.png' : 'esfm-logo.webp'}`;

  return (
    <LogoWrapper>
      <ImageS
        size={size}
        src={imageSrc}
        alt="acc-logo-full"
        className={className}
      />
      {isBeta && !skipBeta && <BetaHolder />}
    </LogoWrapper>
  );
}

ESFMFullLogo.displayName = 'AACFullLogo';
ESFMFullLogo.propTypes = propTypes;
ESFMFullLogo.defaultProps = defaultProps;
