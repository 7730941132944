import React from 'react';
import {
  DefaultBodyWrapper,
  MainHeader,
  PageHeaderContainer,
} from '@poly/admin-book';
import { useRedirectIfHasNoPermissions } from '@poly/client-utils';
import { READ_CLIENT_BATCH_PERMISSION } from '@poly/security';

import { UserHeavyJobsTable } from '../BatchGenerationQueue/HeavyJobsTable.js';
import { useCompletedHeavyJobs } from '../BatchGenerationQueue/useHeavyJobs.js';

export function BatchInvoiceCompletedPage() {
  const heavyJobs = useCompletedHeavyJobs();

  useRedirectIfHasNoPermissions([READ_CLIENT_BATCH_PERMISSION]);

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Completed Batch Invoices</MainHeader>
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <UserHeavyJobsTable heavyJobs={heavyJobs} />
      </DefaultBodyWrapper>
    </>
  );
}
