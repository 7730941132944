import styled from 'styled-components';

export const FlexContainer = styled.div`
  display: flex;
`;

FlexContainer.displayName = 'FlexContainer';

export const SpaceBetween = styled(FlexContainer)`
  justify-content: space-between;
`;

SpaceBetween.displayName = 'SpaceBetween';

export const FlexCenterAlign = styled(FlexContainer)`
  align-items: center;
`;

FlexCenterAlign.displayName = 'FlexCenterAlign';

export const FlexColumn = styled(FlexContainer)`
  flex-direction: column;
`;

export const JustifyFlexEnd = styled(FlexContainer)`
  width: 100%;
  justify-content: flex-end;
`;
