import React from 'react';
import { formatSelectOptionsWithTitleCased } from '@poly/client-utils';
import { Select } from '@poly/admin-book';

import { supplierStatuses } from '../../../../../constants/suppliers.js';

export function SupplierStatusSelect(props) {
  const options = formatSelectOptionsWithTitleCased(supplierStatuses);

  return <Select {...props} name="SupplierStatusSelect" options={options} />;
}
