import * as R from 'ramda';
import { useCallback } from 'react';
import { gql, useQuery } from '@apollo/client';
import { tryCallFunction, debounce } from '@poly/utils';

const UserGroupAITypeUIStringValueFragment = gql`
  fragment UserGroupAITypeUIStringValueFragment on UserGroupAITypeUIStringValue {
    scope
    values
    operation
    variable {
      id
      name
    }
  }
`;

const USER_GROUP_ASSIGNED_USERS_QUERY = gql`
  query USER_GROUP_ASSIGNED_USERS_QUERY($searchInput: CollectionSearchParams) {
    searchUsers(input: $searchInput) {
      hits {
        _id
        email
        fullName
        profile {
          workPhoneNumber
          workPhoneNumberExt
        }
        userGroups {
          userGroup {
            _id
          }
          variables {
            variableId
            valueUI {
              clientId {
                scope
                operation
                variable {
                  id
                  name
                }
                documents {
                  _id
                  name
                }
              }
              assetId {
                scope
                operation
                variable {
                  id
                  name
                }
                documents {
                  _id
                  displayName
                }
              }
              createdBy {
                scope
                operation
                variable {
                  id
                  name
                }
                documents {
                  _id
                  profile {
                    fullName
                  }
                }
              }
              contactId {
                scope
                operation
                variable {
                  id
                  name
                }
                documents {
                  _id
                  profile {
                    fullName
                  }
                }
              }
              technicianId {
                scope
                operation
                variable {
                  id
                  name
                }
                documents {
                  _id
                  profile {
                    fullName
                  }
                }
              }
              projectId {
                scope
                operation
                variable {
                  id
                  name
                }
                documents {
                  _id
                  projectId
                }
              }
              supplierId {
                scope
                operation
                variable {
                  id
                  name
                }
                documents {
                  _id
                  company {
                    name
                  }
                }
              }
              propertyId {
                scope
                operation
                variable {
                  id
                  name
                }
                documents {
                  _id
                  name
                }
              }
              userGroupId {
                scope
                operation
                variable {
                  id
                  name
                }
                documents {
                  _id
                  name
                }
              }
              poAmount {
                scope
                values
                operation
                variable {
                  id
                  name
                }
              }
              clientApp {
                ...UserGroupAITypeUIStringValueFragment
              }
              updateType {
                ...UserGroupAITypeUIStringValueFragment
              }
              projectType {
                ...UserGroupAITypeUIStringValueFragment
              }
              parentProjectType {
                ...UserGroupAITypeUIStringValueFragment
              }
              supplierSource {
                ...UserGroupAITypeUIStringValueFragment
              }
              fileCollection {
                ...UserGroupAITypeUIStringValueFragment
              }
            }
            value {
              clientId {
                equals
                flippedContains
                notFlippedContains
                userGroupVariableId
              }
              assetId {
                equals
                flippedContains
                notFlippedContains
                userGroupVariableId
              }
              createdBy {
                equals
                flippedContains
                notFlippedContains
                userGroupVariableId
              }
              contactId {
                equals
                flippedContains
                notFlippedContains
                userGroupVariableId
              }
              technicianId {
                equals
                flippedContains
                notFlippedContains
                userGroupVariableId
              }
              projectId {
                equals
                flippedContains
                notFlippedContains
                userGroupVariableId
              }
              supplierId {
                equals
                flippedContains
                notFlippedContains
                userGroupVariableId
              }
              propertyId {
                equals
                flippedContains
                notFlippedContains
                userGroupVariableId
              }
              userGroupId {
                equals
                flippedContains
                notFlippedContains
                userGroupVariableId
              }
              clientApp {
                equals
                flippedContains
                notFlippedContains
                userGroupVariableId
              }
              updateType {
                equals
                flippedContains
                notFlippedContains
                userGroupVariableId
              }
              projectType {
                equals
                flippedContains
                notFlippedContains
                userGroupVariableId
              }
              parentProjectType {
                equals
                flippedContains
                notFlippedContains
                userGroupVariableId
              }
              supplierSource {
                equals
                flippedContains
                notFlippedContains
                userGroupVariableId
              }
              fileCollection {
                equals
                flippedContains
                notFlippedContains
                userGroupVariableId
              }
              poAmount {
                equals
                gt
                gte
                lt
                lte
                userGroupVariableId
              }
            }
          }
        }
      }
    }
  }

  ${UserGroupAITypeUIStringValueFragment}
`;

// prepareAssignedUsers :: { searchUsers: { hits: [User] } } -> [User]
const prepareAssignedUsers = R.pathOr([], ['searchUsers', 'hits']);

export const useUserGroupAssignedUsersQuery = (userGroupId, searchTerm) => {
  const query = {
    nested: {
      path: 'userGroups',
      query: { match: { 'userGroups.userGroupId': userGroupId } },
    },
  };

  const { data, loading, refetch } = useQuery(USER_GROUP_ASSIGNED_USERS_QUERY, {
    variables: { searchInput: { searchTerm, query, size: 500 } },
    skip: !userGroupId,
  });

  const debouncedRefetch = useCallback(
    debounce(2000)(() => tryCallFunction(refetch)()),
    [refetch],
  );

  return {
    loading,
    debouncedRefetch,
    assignedUsers: prepareAssignedUsers(data),
  };
};
