import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string, shape, bool } from 'prop-types';
import {
  propEqLegacy,
  formatTotal,
  isNilOrEmpty,
  forceTitleCase,
  ofArrayLegacy,
} from '@poly/utils';
import { PopoverLink, Status, Text } from '@poly/admin-book';
import {
  useSidebarTableFormContext,
  useMapConfigToTableProps,
  supplierStatusesColors,
  ThreeDotsPopover,
  InfoPopover,
  SidebarRow,
} from '@poly/admin-ui';
import {
  ProjectSupplierStatus,
  SupplierEmailsTypes,
  supplierPhoneTypes,
  NOTHING_UI_STRING,
  ProjectType,
} from '@poly/constants';

import {
  SectionLabel,
  SectionWrapper,
} from '../components/commonSidebarComponents.js';
import { SupplierLink } from '../../components/Links.js';
import { SidebarSuppliers } from './components/projectSidebarComponents.js';
import { UpdateAddedSupplierForm } from './forms/UpdateAddedSupplierForm.js';
import { SendVoidWOCPopoverLink } from './components/SendVoidWOCPopoverLink.js';
import { AddInvoiceTaskPopoverLink } from './components/AddInvoiceTaskPopoverLink.js';
import { AddSupplierToProjectButton } from './components/AddSupplierToProjectButton.js';
import { UpdateAssignedSupplierWOCForm } from './forms/UpdateAssignedSupplierWOCForm.js';
import { ToolBarBtnDivider } from '../../modules/core/tableToolbar/tableToolBarStyles.js';
import { AssignSupplierToProjectButton } from './components/AssignSupplierToProjectButton.js';
import { AssignAddedSupplierPopoverLink } from './components/AssignAddedSupplierPopoverLink.js';
import { PurchaseOrderBalanceWarning } from '../../pages/PurchaseOrdersPage/PurchaseOrderBalanceWarning.js';
import { isNTEExceedAdminPOBalance } from '../../modules/forms/assignSupplierForm/AssignSupplierFormComp.js';
import { prepareProjectSidebarSuppliersTableRows } from './projectSidebarUtils.js';
import { useUnassignProjectSupplier } from './tabs/useUnassignProjectSupplier.js';
import { useRemoveProjectSupplier } from './tabs/useRemoveProjectSupplier.js';
import { propOrNothingUI } from '../../modules/core/utils/general.js';
import { SendWOCPopoverLink } from './components/SendWOCPopoverLink.js';
import { GenerateWOCPopoverLink } from './components/GenerateWOCPopoverLink.js';
import { isProjectNotInvoiced } from '../components/commonSidebarDetailsList.js';
import { defaultTextProps } from '../components/commonSidebarSectionFormatters.js';
import { useHasUserAccessToUpdateProject } from './useHasUserAccessToUpdateProject.js';

// getProjectSidebarSuppliersInfo :: Boolean -> Project -> [ProjectSupplier]
const getProjectSidebarSuppliersInfo = (isRecurringProject) => (project) =>
  R.compose(
    R.map(R.mergeLeft({ isRecurringProject, project })),
    R.sortBy(R.path(['company', 'name'])),
    R.converge(R.concat, [
      R.propOr([], 'suppliers'),
      R.propOr([], 'suppliersAdded'),
    ]),
  )(project);

// getSupplierLinkInfo :: Supplier -> Object
const getSupplierLinkInfo = R.applySpec({
  _id: R.ifElse(
    R.propIs(String, 'supplierId'),
    R.prop('supplierId'),
    R.prop('_id'),
  ),
  name: R.path(['company', 'name']),
  target: R.always('_blank'),
});

// getSupplierNTE :: Supplier -> String
const getSupplierNTE = R.compose(
  R.ifElse(R.is(Number), formatTotal, R.always(NOTHING_UI_STRING)),
  R.prop('nte'),
);

// checkProjectSupplierStatus :: ProjectSupplierStatus -> { statusInProject: ProjectSupplierStatus } -> Boolean
const checkProjectSupplierStatus = (status) =>
  propEqLegacy('statusInProject', status);

// checkSupplierIsAssigned :: { statusInProject: ProjectSupplierStatus } -> Boolean
const checkSupplierIsAssigned = checkProjectSupplierStatus(
  ProjectSupplierStatus.assigned,
);

// checkSupplierIsAdded :: { statusInProject: ProjectSupplierStatus } -> Boolean
const checkSupplierIsAdded = checkProjectSupplierStatus(
  ProjectSupplierStatus.added,
);

// isListingProjectType :: Project -> Boolean
const isListingProjectType = propEqLegacy('type', ProjectType.LISTING);

// getSupplierPhoneArrByProp :: String -> SupplierPhone -> [String, String]
const getSupplierPhoneArrByProp = (prop) =>
  R.compose(R.prepend(prop), ofArrayLegacy, R.prop('phone'));

// getSupplierPopoverProps :: Supplier -> SupplierPopoverProps
// SupplierPopoverProps :: { title: String, email: String, fullName: String, profile: PhonesProfile }
// PhonesProfile = { workPhoneNumber: String, cellPhoneNumber: String }
const getSupplierPopoverProps = R.applySpec({
  title: R.compose(R.concat(R.__, ' Supplier'), forceTitleCase, R.prop('type')),
  email: R.compose(
    R.when(R.has('email'), R.prop('email')),
    R.find(propEqLegacy('type', SupplierEmailsTypes.SERVICE)),
    R.pathOr([], ['company', 'emails']),
  ),
  fullName: R.path(['company', 'name']),
  profile: R.compose(
    R.fromPairs,
    R.reject(R.isNil),
    R.map(
      R.cond([
        [
          propEqLegacy('type', supplierPhoneTypes.MOBILE),
          getSupplierPhoneArrByProp('cellPhoneNumber'),
        ],
        [R.T, getSupplierPhoneArrByProp('workPhoneNumber')],
      ]),
    ),
    R.pathOr([], ['company', 'phones']),
  ),
});

function SupplierMoreBtn({ hasAccessToUpdateProject, ...props }) {
  const { formSetter } = useSidebarTableFormContext();

  const { _id, supplierId, project, isRecurringProject } = props;
  const isSupplierAssigned = checkSupplierIsAssigned(props);
  const isSupplierAdded = checkSupplierIsAdded(props);

  const onDelete = useRemoveProjectSupplier({
    supplierId,
    isRecurringProject,
    projectId: project._id,
  });

  const onUnassign = useUnassignProjectSupplier({
    supplierId,
    projectId: project._id,
  });

  const onUpdate = () => {
    const SupplierUpdateForm = isSupplierAdded
      ? UpdateAddedSupplierForm
      : UpdateAssignedSupplierWOCForm;

    formSetter({
      elementId: _id,
      Content: <SupplierUpdateForm {...props} _id={supplierId} />,
    });
  };

  if (isRecurringProject && isSupplierAssigned) {
    return null;
  }

  return (
    <ThreeDotsPopover
      content={
        <>
          {isSupplierAdded &&
            !isListingProjectType(project) &&
            !isRecurringProject && (
              <AssignAddedSupplierPopoverLink
                {...props}
                _id={project._id}
                supplierId={supplierId}
              />
            )}
          {!isRecurringProject && (
            <PopoverLink onClick={onUpdate}>Update</PopoverLink>
          )}
          {hasAccessToUpdateProject && (
            <PopoverLink onClick={onDelete}>Delete</PopoverLink>
          )}
          {isSupplierAssigned && (
            <>
              <PopoverLink onClick={onUnassign}>Unassign</PopoverLink>
              <AddInvoiceTaskPopoverLink {...props} />
              <SendVoidWOCPopoverLink
                projectId={project._id}
                supplierId={supplierId}
              />
              <SendWOCPopoverLink
                projectId={project._id}
                supplierId={supplierId}
              />
              <GenerateWOCPopoverLink
                projectId={project._id}
                supplierId={supplierId}
              />
            </>
          )}
        </>
      }
    />
  );
}

SupplierMoreBtn.propTypes = {
  isCard: bool,
  _id: string.isRequired,
  supplierId: string.isRequired,
  project: shape({ _id: string.isRequired }).isRequired,
  isRecurringProject: bool,
  hasAccessToUpdateProject: bool,
};

function SupplierStatusInfo({ status }) {
  return (
    <Status
      color={supplierStatusesColors[R.toLower(status)]}
      text={forceTitleCase(status)}
    />
  );
}

SupplierStatusInfo.propTypes = { status: string.isRequired };

// getSupplierPopoverParentLink :: Supplier -> _ -> ReactNode
const getSupplierPopoverParentLink = (props) =>
  function () {
    const linkProps = getSupplierLinkInfo(props);
    return <SupplierLink {...linkProps} />;
  };

export function SupplierPopoverLink(props) {
  if (!props._id) {
    const textProps = { ...props, ...defaultTextProps };

    return <Text {...textProps}>{NOTHING_UI_STRING}</Text>;
  }

  const supplierPopoverProps = getSupplierPopoverProps(props);

  const PopoverParent = getSupplierPopoverParentLink(props);

  return (
    <InfoPopover
      {...supplierPopoverProps}
      position="bottom"
      PopoverParent={PopoverParent}
    />
  );
}

SupplierPopoverLink.propTypes = {
  _id: string,
};

export const supplierSidebarColumn = ['Supplier', SupplierPopoverLink];

export const SupplierStatusInProjectUI = {
  [ProjectSupplierStatus.added]: 'Added',
  [ProjectSupplierStatus.assigned]: 'Assigned',
};

const NTEWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

export function SupplierNTEComp(props) {
  const isExceedsBalance = isNTEExceedAdminPOBalance(props);

  return (
    <NTEWrapper>
      <span>{getSupplierNTE(props)}</span>
      {!!isExceedsBalance && isProjectNotInvoiced(props?.project) && (
        <PurchaseOrderBalanceWarning warning="The NTE amount exceeds the balance of the PO." />
      )}
    </NTEWrapper>
  );
}
SupplierNTEComp.propTypes = {
  project: shape({
    accountingStatus: string,
  }),
};

const projectSidebarSuppliersConfig = (
  isNteHidden,
  hasAccessToUpdateProject,
) => [
  supplierSidebarColumn,
  ...(isNteHidden ? [] : [['Supplier NTE', SupplierNTEComp]]),
  [
    'Status',
    R.compose(
      propOrNothingUI(R.__, SupplierStatusInProjectUI),
      R.prop('statusInProject'),
    ),
  ],
  [
    '',
    (props) => (
      <SupplierMoreBtn
        hasAccessToUpdateProject={hasAccessToUpdateProject}
        {...props}
      />
    ),
  ],
];

export const VerticalAddAssignSupplierDividerS = styled(ToolBarBtnDivider)`
  display: inline-block;
  height: 12px;
`;

export function ProjectSidebarSuppliers({
  isCard,
  project,
  showActionButtons,
  isRecurringProject,
  skipAssignSupplierButton,
}) {
  const suppliers = getProjectSidebarSuppliersInfo(isRecurringProject)(project);

  const hasAccessToUpdateProject =
    useHasUserAccessToUpdateProject(isRecurringProject);

  const isNteHidden = project.type === ProjectType.LISTING;

  const tableProps = useMapConfigToTableProps(
    prepareProjectSidebarSuppliersTableRows({ project, isCard }),
    projectSidebarSuppliersConfig(isNteHidden, hasAccessToUpdateProject),
    suppliers,
  );

  return (
    <SectionWrapper skipMargin>
      <SidebarRow justify>
        <SectionLabel>Suppliers</SectionLabel>
        {showActionButtons && (
          <div>
            <AddSupplierToProjectButton
              _id={project._id}
              isRecurringProject={isRecurringProject}
            />
            {!isListingProjectType(project) && !skipAssignSupplierButton && (
              <>
                <VerticalAddAssignSupplierDividerS />
                <AssignSupplierToProjectButton _id={project._id} />
              </>
            )}
          </div>
        )}
      </SidebarRow>
      {!isNilOrEmpty(suppliers) && (
        <SidebarSuppliers
          {...tableProps}
          isNteHidden={isNteHidden}
          minHeightLess
        />
      )}
    </SectionWrapper>
  );
}

ProjectSidebarSuppliers.propTypes = {
  project: shape({ _id: string.isRequired }),
  showActionButtons: bool,
  isRecurringProject: bool,
  skipAssignSupplierButton: bool,
  isCard: bool,
};

ProjectSidebarSuppliers.defaultProps = {
  showActionButtons: true,
  skipAssignSupplierButton: false,
  isCard: false,
};
