export const SupplierStatuses = {
  ACTIVE: 'active',
  BLOCKED: 'blocked',
};

// Warning: This can be used only at backend!!! (messed up GQL enum)
export const SupplierTypes = {
  ADMINISTRATIVE: 'Administrative',
  SUBCONTRACTOR: 'Subcontractor',
};

// Warning: This can used only at frontend!!! (messed up GQL enum)
export const supplierTypes = {
  ADMINISTRATIVE: 'ADMINISTRATIVE',
  SUBCONTRACTOR: 'SUBCONTRACTOR',
};

export const SupplierSources = {
  TIAA: 'TIAA',
  AAC: 'AAC',
  Eurest: 'Eurest',
};

export const SupplierMBEMinority = {
  nativeAmerican: 'nativeAmerican',
  asianIndianSubcontinent: 'asianIndianSubcontinent',
  asianPacific: 'asianPacific',
  hispanic: 'hispanic',
  black: 'black',
  unknown: 'unknown',
};

export const MWBEEthnicitiesUI = {
  [SupplierMBEMinority.nativeAmerican]: 'Native American',
  [SupplierMBEMinority.asianIndianSubcontinent]: 'Asian-Indian Subcontinent',
  [SupplierMBEMinority.asianPacific]: 'Asian-Pacific',
  [SupplierMBEMinority.hispanic]: 'Hispanic',
  [SupplierMBEMinority.black]: 'Black',
  [SupplierMBEMinority.unknown]: 'Unknown',
};

export const supplierFinTerms = {
  DAYS_5: 'DAYS_5',
  DAYS_10: 'DAYS_10',
  DAYS_15: 'DAYS_15',
  DAYS_20: 'DAYS_20',
  DAYS_30: 'DAYS_30',
  DAYS_45: 'DAYS_45',
  DAYS_60: 'DAYS_60',
  ON_RECEIPT: 'ON_RECEIPT',
};

export const supplierFinTermsUI = {
  [supplierFinTerms.DAYS_5]: '5 days',
  [supplierFinTerms.DAYS_10]: '10 days',
  [supplierFinTerms.DAYS_15]: '15 days',
  [supplierFinTerms.DAYS_20]: '20 days',
  [supplierFinTerms.DAYS_30]: '30 days',
  [supplierFinTerms.DAYS_45]: '45 days',
  [supplierFinTerms.DAYS_60]: '60 days',
  [supplierFinTerms.ON_RECEIPT]: 'On Receipt',
};

export const DEFAULT_SUPPLIER_FINANCIAL_TERMS = supplierFinTerms.DAYS_30;

export const supplierEmailsTypes = {
  SERVICE: 'SERVICE',
  ACCOUNT: 'ACCOUNT',
  GENERAL: 'GENERAL',
  SECONDARY_SERVICE: 'SECONDARY_SERVICE',
};

export const supplierPhoneTypes = {
  GENERAL: 'GENERAL',
  MOBILE: 'MOBILE',
  OFFICE: 'OFFICE',
  AFTER_HOURS: 'AFTER_HOURS',
};

export const TaxClassification = {
  SOLE_PROPRIETORSHIP: 'SOLE_PROPRIETORSHIP',
  C_CORPORATION: 'C_CORPORATION',
  S_CORPORATION: 'S_CORPORATION',
  PARTNERSHIP: 'PARTNERSHIP',
  LLC_C_CORP: 'LLC_C_CORP',
  LLC_S_CORP: 'LLC_S_CORP',
  LLC_PARTNERSHIP: 'LLC_PARTNERSHIP',
};

export const INVALID_SUPPLIER_NAME_MESSAGE =
  'Company name contains invalid characters';

export const supplierInvoiceRequestOptions = {
  sendNow: 'sendNowSupplierInvoiceRequest',
  manualFollowUp: 'manualFollowUpSupplierInvoiceRequest',
  sendLater: 'sendLaterSupplierInvoiceRequest',
};

// used for Assign Supplier WOC logic
export const SupplierWOCTypes = {
  confirm: 'confirmWorkOrder',
  revise: 'reviseWorkOrder',
  request: 'requestFreeQuote',
  skip: 'skipSendConfirmation',
};

export const supplierDocumentRequestStatuses = {
  ACTIVE: 'active',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

export const supplierDocumentRequestTypes = {
  WC: 'WC',
  GL: 'GL',
  SA: 'SA',
  W9: 'W9',
  WBE: 'WBE',
  MBE: 'MBE',
  VETERAN: 'VETERAN',
  W9_MANUALLY: 'W9_MANUALLY',
};

export const supplierDocumentNames = {
  [supplierDocumentRequestTypes.WC]: 'Workers Comp Insurance',
  [supplierDocumentRequestTypes.GL]: 'Liability Insurance',
  [supplierDocumentRequestTypes.SA]: 'Subcontractor Agreement',
  [supplierDocumentRequestTypes.W9]: 'W-9',
  [supplierDocumentRequestTypes.MBE]: 'MBE Evidence',
  [supplierDocumentRequestTypes.WBE]: 'WBE Evidence',
  [supplierDocumentRequestTypes.VETERAN]: 'Veteran Evidence',
  [supplierDocumentRequestTypes.W9_MANUALLY]: 'W-9',
};

export const SupplierFoodBuyVendorTypes = {
  NT: 'nonTransactionalFoodBuyVendorType',
  OFF_INVOICE: 'offInvoiceFoodBuyVendorType',
};

export const FoodbuyVendorTypeUI = {
  [SupplierFoodBuyVendorTypes.NT]: 'NT',
  [SupplierFoodBuyVendorTypes.OFF_INVOICE]: 'Off Invoice',
};

export const SAP_VENDOR_NUMBER_LENGTH_LIMIT = 40;

export const SupplierDocumentRequestDefaultMessage =
  'Our records indicate we need the following documents from you.';

export const SupplierDocumentRequestInsuranceMessage =
  'Our records indicate we are missing the following insurance certificates.';

export const SupplierDocumentRequestOnboardingMessage =
  'Welcome to the family! We need to collect some information to get you set up in the system. Once done we will be able to issue payments.';

export const supplierOfficeHoursDays = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

export const SupplierPaymentTransactionType = {
  SUPPLIER_INVOICE: 'supplierInvoice',
  PAYMENT: 'payment',
};
