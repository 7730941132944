import * as Sentry from '@sentry/react';

export function setCurrentSentryUser(user) {
  if (process.env.SENTRY_DSN) {
    if (user) {
      Sentry.setUser({ email: user.email });
    } else {
      Sentry.setUser(null);
    }
  }
}
