import { taskActionTypes } from '../tasks.js';
import { RWOTaskListType } from './recurring.js';

const defaultRecurringLongList = [
  {
    description: 'Confirm Scheduling',
    action: taskActionTypes.SUPPLIER_SCHEDULING,
    dueDateConfig: {
      default: 0,
    },
  },
  {
    description: 'Confirm Satisfaction',
    action: taskActionTypes.CONFIRM_SATISFACTION,
  },
  {
    description: 'Work Completed',
    action: taskActionTypes.PROJECT_COMPLETE,
  },
  {
    description: 'Close Project',
    action: taskActionTypes.CLOSE_PROJECT,
  },
];

const defaultRecurringShortList = [
  {
    description: 'Work Completed',
    action: taskActionTypes.PROJECT_COMPLETE,
  },
  {
    description: 'Close Project',
    action: taskActionTypes.CLOSE_PROJECT,
  },
];

export const defaultRecurringProjectTasksConfig = {
  [RWOTaskListType.LONG_LIST]: defaultRecurringLongList,
  [RWOTaskListType.SHORT_LIST]: defaultRecurringShortList,
};
