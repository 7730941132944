import * as R from 'ramda';
import React from 'react';
import { UserEmployeeInfoStatus } from '@poly/constants';
import {
  getAdminUsersByEmploymentStatusQuery,
  UserSelect,
} from '@poly/admin-ui';
import { bool, object, shape } from 'prop-types';

import {
  isPMFormData,
  isFeeProjectType,
  isRecurringFormData,
} from '../utils/sections.js';

// isProjectManagerRequired :: Project -> Boolean
const isProjectManagerRequired = R.anyPass([
  isPMFormData,
  isFeeProjectType,
  isRecurringFormData,
]);

export function AACManagerSelector(props) {
  const { formData, meta } = props;

  const inputProps = isProjectManagerRequired(formData)
    ? {
        required: true,
        error:
          meta.touched && !formData.managerId ? 'Assigned CSR is required' : '',
      }
    : { staticOptions: [{ value: '', label: 'Queue' }] };

  return (
    <UserSelect
      {...props}
      {...inputProps}
      placeholder="Start typing managers"
      query={getAdminUsersByEmploymentStatusQuery(
        UserEmployeeInfoStatus.ACTIVE,
      )}
      withoutSkip
    />
  );
}

AACManagerSelector.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formData: object.isRequired,
  meta: shape({ touched: bool }),
};
