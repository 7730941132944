import { shape, arrayOf, string, number } from 'prop-types';

const monthProps = shape({ amount: number, wipAmount: number });

export const salesReportRowsTypeProps = arrayOf(
  shape({
    clientName: string.isRequired,
    0: monthProps,
    1: monthProps,
    2: monthProps,
    3: monthProps,
    4: monthProps,
    5: monthProps,
    6: monthProps,
    7: monthProps,
    8: monthProps,
    9: monthProps,
    10: monthProps,
    11: monthProps,
  }),
);
