import * as R from 'ramda';
import { NOTHING_UI_STRING } from '@poly/constants';
import { isNilOrEmpty, forceTitleCase } from '@poly/utils';

// getFormattedValue :: (Any, Function) -> String
export const getUserFormattedValue = R.curry((value, formatter) =>
  R.ifElse(isNilOrEmpty, R.always(NOTHING_UI_STRING), formatter)(value),
);

// titleCaseCurried :: String -> String
export const titleCaseCurried = (value) => forceTitleCase(value);

// getEmailOrNothingUI :: User -> String
export const getUserEmailOrNothingUI = R.compose(
  R.ifElse(
    R.isEmpty,
    R.always(NOTHING_UI_STRING),
    R.compose(R.propOr(NOTHING_UI_STRING, 'address'), R.nth(0)),
  ),
  R.defaultTo([]),
  R.prop('emails'),
);

// getUserRateType :: String -> String
export const getUserRateType = R.compose(
  titleCaseCurried,
  R.when(R.equals('weekly'), R.always('Salary')),
);
