import React from 'react';
import { openPrintWindowWithData } from '@poly/client-utils/src/print.js';
import { PRINT_PDF_CAPTION } from '@poly/constants';
import { shape } from 'prop-types';
import { CommonPrintLayout } from '@poly/admin-ui/src/components/CommonPrintLayout.js';

import { ExportPDFBtn } from '../WorkInProgressReport/WorkInProgressExportPDFBtn.js';
import { OpenWorkOrdersReportTable } from './OpenWorkOrdersReportTable.js';
import { useOpenWorkOrdersReportLazyQuery } from './useOpenWorkOrdersReport.js';

export function OpenWorkOrdersPDFBtn({ filter, sort, ...props }) {
  const queryHandler = useOpenWorkOrdersReportLazyQuery();

  const onPrintPDFClick = async () => {
    const { data } = await queryHandler({ filter, sort });

    await openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: OpenWorkOrdersReportTable,
      fileName: 'open_work_orders_report',
      metaData: {
        title: 'Open Work Orders Report',
      },
      data,
      isPrint: true,
    });
  };

  return (
    <ExportPDFBtn {...props} onClick={onPrintPDFClick}>
      {PRINT_PDF_CAPTION}
    </ExportPDFBtn>
  );
}

OpenWorkOrdersPDFBtn.propTypes = { filter: shape({}), sort: shape({}) };
