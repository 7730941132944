import React from 'react';
import * as R from 'ramda';
import { shape, oneOf, string } from 'prop-types';
import { taskCollections } from '@poly/constants';

import { TasksTabComp } from './TasksTabComp.js';
import {
  TasksTableContext,
  getContextData,
} from '../../tables/tasksTable/tasksTableHocs.js';

export function TasksTab(props) {
  const tasksContext = getContextData(props);

  return (
    <TasksTableContext.Provider value={tasksContext}>
      <TasksTabComp {...props} />
    </TasksTableContext.Provider>
  );
}

TasksTab.displayName = 'EntityTasksTab';

TasksTab.propTypes = {
  collection: oneOf(R.values(taskCollections)).isRequired,
  entity: shape({
    _id: string.isRequired,
    humanReadableId: string.isRequired,
  }).isRequired,
};
