import { gql } from '@apollo/client';

import { commonTimeSheetFields } from './fragments.js';

export const TIMESHEET_REPORT = gql`
  query TIMESHEET_REPORT($input: TimesheetsReportInput!) {
    timesheetsReport(input: $input) {
      ...commonTimeSheetFields
    }
  }

  ${commonTimeSheetFields}
`;

export const MY_TIMESHEETS = gql`
  query MY_TIMESHEETS($input: MyTimesheetsInput!) {
    myTimesheets(input: $input) {
      ...commonTimeSheetFields
    }
  }

  ${commonTimeSheetFields}
`;

export const TIMESHEET_SUBSCRIPTION = gql`
  subscription TIMESHEET_REPORT_SUBSCRIPTION($input: CollectionSearchParams) {
    searchTimeSheetChanged(input: $input) {
      id
      type
    }
  }
`;
