import * as R from 'ramda';
import { useQuery, gql } from '@apollo/client';

export const isTokenValidQuery = gql`
  query isTokenValidQuery {
    isTokenValid
  }
`;

// checkIsTokenValid :: Input -> Boolean
// Input = {
//    data: { isTokenValid: Boolean }
//    error: ApolloError
//    loading: Boolean
// }
const checkIsTokenValid = R.cond([
  [R.prop('error'), R.F],
  [R.prop('loading'), R.T],
  [
    R.both(R.complement(R.prop('loading')), R.complement(R.prop('error'))),
    R.path(['data', 'isTokenValid']),
  ],
  [R.T, R.F],
]);

export const useIsTokenValid = (skip = false) => {
  const { data, error, loading } = useQuery(isTokenValidQuery, {
    fetchPolicy: 'network-only',
    skip,
  });

  const isTokenValid = checkIsTokenValid({ data, error, loading });

  return { isTokenValid, loading, error };
};
