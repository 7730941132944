import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, number, shape, string } from 'prop-types';
import { LinkButton, removeBorderPrintStyles, Table } from '@poly/admin-book';
import {
  applyIfOr,
  formatTotal,
  isNilOrEmpty,
  formatDate,
  convertCentsToDollars,
} from '@poly/utils';
import { NOTHING_UI_STRING, PRINT_PDF_CAPTION } from '@poly/constants';
import { openPrintWindowWithData, pathOrNothingUI } from '@poly/client-utils';
import { extractTablePropsFromConfig, CommonPrintLayout } from '@poly/admin-ui';

import { invoicePaymentPropTypes } from './paySupplierInvoicesPropTypes.js';
import { FlexColumn, FlexContainer } from '../../components/FlexContainer.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { usePaySupplierInvoicesExportData } from './usePaySupplierInvoicesExportData.js';
import { paySupplierInvoiceFiltersPropTypes } from './propTypes.js';

const applyOrNothingUI = applyIfOr(
  R.complement(isNilOrEmpty),
  R.__,
  NOTHING_UI_STRING,
);

const tableConfig = [
  ['Ref No.', R.prop('invoiceId')],
  ['Inv No.', R.prop('invoiceNumber')],
  ['Supplier', R.path(['supplier', 'company', 'name'])],
  ['Project', R.path(['project', 'projectId'])],
  ['Inv Date', R.compose(formatDate, R.prop('invoiceDate'))],
  ['Due Date', R.compose(formatDate, R.prop('dueDate'))],
  [
    'Inv Amount',
    R.compose(
      applyOrNothingUI(formatTotal),
      convertCentsToDollars,
      R.prop('total'),
    ),
  ],
  [
    'Deductions',
    R.compose(
      applyOrNothingUI(formatTotal),
      convertCentsToDollars,
      R.prop('deductionsAmount'),
    ),
  ],
  ['GL Disc Code', pathOrNothingUI(['deductionsAccount', 'name'])],
  [
    'Payment',
    R.compose(
      applyOrNothingUI(formatTotal),
      convertCentsToDollars,
      R.prop('paidAmount'),
    ),
  ],
  [
    'Balance',
    R.compose(
      applyOrNothingUI(formatTotal),
      convertCentsToDollars,
      R.prop('currentBalance'),
    ),
  ],
];

const borderStyles = '1px solid rgba(197, 198, 201, 0.95)';

const TableWithoutBorderS = styled(Table)`
  ${removeBorderPrintStyles}
  td,
  th {
    border-left: ${borderStyles};
  }
  th:last-child,
  td:last-child {
    border-right: ${borderStyles};
  }
  tr {
    border-bottom: ${borderStyles};
  }
  tr:first-child {
    border-top: ${borderStyles};
    border-bottom: ${borderStyles};
  }
  tr:last-child {
    border-bottom: ${borderStyles};
  }

  td:nth-child(3),
  th:nth-child(3) {
    width: 15%;
  }
  td:nth-child(7),
  th:nth-child(7),
  td:nth-child(10),
  th:nth-child(10),
  td:nth-child(11),
  th:nth-child(11) {
    width: 8%;
  }

  td:nth-child(8),
  th:nth-child(8) {
    width: 9%;
  }

  td:nth-child(1),
  th:nth-child(1) {
    width: 12%;
  }
  td:nth-child(9),
  th:nth-child(9) {
    width: 10%;
  }
  td:nth-child(5),
  th:nth-child(5),
  td:nth-child(6),
  th:nth-child(6),
  td:nth-child(4),
  th:nth-child(4),
  td:nth-child(2),
  th:nth-child(2) {
    width: 9%;
  }

  /*
  Note: padding-bottom needs to fix cropped information
        on linux + firefox
   */
  @media print {
    padding-bottom: 20px;

    tbody {
      padding-bottom: 20px;
    }
  }
`;

const TotalLabelS = styled(FlexContainer)`
  margin-right: 10px;
  font-weight: normal;
`;

const TotalColumnContainerS = styled(FlexContainer)`
  margin-right: 30px;
  font-weight: bold;
`;

function TotalColumn({ title, children }) {
  return (
    <TotalColumnContainerS>
      <TotalLabelS>{title}</TotalLabelS>
      {children}
    </TotalColumnContainerS>
  );
}

const TotalContainerS = styled(FlexContainer)`
  justify-content: flex-end;
  padding-top: 20px;
`;

TotalColumn.propTypes = {
  title: string.isRequired,
  children: string.isRequired,
};

const SearchFiltersContainerS = styled(FlexColumn)`
  margin: 0 40px;
`;

const TitleContainer = styled(FlexContainer)`
  font-size: 12px;
  color: #9fa1ab;
  width: 150px;
`;

const ColumnContainer = styled(FlexContainer)`
  margin-bottom: 10px;
  align-items: center;
`;

function SearchHeaderColumn({ title, children }) {
  return (
    <ColumnContainer>
      <TitleContainer>{title}</TitleContainer>
      {children}
    </ColumnContainer>
  );
}

SearchHeaderColumn.propTypes = {
  title: string.isRequired,
  children: string.isRequired,
};

const filterValuesPropTypes = shape({
  projectId: string,
  supplierName: string,
  masterName: string,
  clientName: string,
  serviceTypeName: string,
  projectNumber: string,
  invoiceNumber: string,
  invoiceDateUI: string,
  termsUI: string,
  acceptsCreditCardUI: string,
});

export function SearchFilters({ filterValues }) {
  return (
    <SearchFiltersContainerS>
      <FlexSpaceBetween>
        <FlexColumn>
          <SearchHeaderColumn title="Supplier">
            {filterValues.supplierName}
          </SearchHeaderColumn>
          <SearchHeaderColumn title="Supplier Master">
            {filterValues.masterName}
          </SearchHeaderColumn>
          <SearchHeaderColumn title="Client">
            {filterValues.clientName}
          </SearchHeaderColumn>
          <SearchHeaderColumn title="Due Date">
            {filterValues.paymentDueDateUI}
          </SearchHeaderColumn>
        </FlexColumn>
        <FlexColumn>
          <SearchHeaderColumn title="Payment Terms">
            {filterValues.termsUI}
          </SearchHeaderColumn>
          <SearchHeaderColumn title="Payment Type">
            {filterValues.paymentTypeUI}
          </SearchHeaderColumn>
          <SearchHeaderColumn title="Service Type">
            {filterValues.serviceTypeName}
          </SearchHeaderColumn>
          <SearchHeaderColumn title="Include Past Due">
            {filterValues.pastDueDateUI}
          </SearchHeaderColumn>
        </FlexColumn>
        <FlexColumn>
          <SearchHeaderColumn title="Project No" margin="0 0 10px 0">
            {filterValues.projectNumber}
          </SearchHeaderColumn>
          <SearchHeaderColumn title="Invoice No" margin="0 0 10px 0">
            {filterValues.invoiceNumber}
          </SearchHeaderColumn>
          <SearchHeaderColumn title="Invoice Date">
            {filterValues.invoiceDateUI}
          </SearchHeaderColumn>
          <SearchHeaderColumn title="Show Only With Warnings">
            {filterValues.showOnlyWithWarningsUI}
          </SearchHeaderColumn>
          <SearchHeaderColumn title="W-9 absence">
            {filterValues.w9AbsentUI}
          </SearchHeaderColumn>
        </FlexColumn>
      </FlexSpaceBetween>
    </SearchFiltersContainerS>
  );
}

SearchFilters.propTypes = { filterValues: filterValuesPropTypes.isRequired };

const MainPrintTableContainer = styled(FlexColumn)`
  display: flex !important;

  div {
    display: flex !important;
  }
`;

export function PrintSupplierInvoicesTable({
  invoices,
  paidTotal,
  deductionsTotal,
  filterValues,
}) {
  const { columns, headers } = extractTablePropsFromConfig(tableConfig);

  return (
    <MainPrintTableContainer>
      <SearchFilters filterValues={filterValues} />
      <TableWithoutBorderS
        columns={columns}
        headers={headers}
        rows={invoices}
      />
      <TotalContainerS>
        <TotalColumn title="Deductions:">
          {formatTotal(convertCentsToDollars(deductionsTotal))}
        </TotalColumn>
        <TotalColumn title="Total:">
          {formatTotal(convertCentsToDollars(paidTotal))}
        </TotalColumn>
      </TotalContainerS>
    </MainPrintTableContainer>
  );
}

PrintSupplierInvoicesTable.propTypes = {
  invoices: arrayOf(invoicePaymentPropTypes),
  paidTotal: number.isRequired,
  deductionsTotal: number.isRequired,
  filterValues: filterValuesPropTypes.isRequired,
};

const ExportBtnContainer = styled(FlexContainer)`
  justify-content: flex-end;
`;

export function PaySupplierInvoicesPdfExportBtn({ invoices, filters }) {
  const exportPdf = (exportData) =>
    openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: PrintSupplierInvoicesTable,
      metaData: {
        title: 'Pay Supplier Invoices',
      },
      fileName: 'pay_supplier_invoices_report',
      ...exportData,
    });

  const { onClick, disabled } = usePaySupplierInvoicesExportData(
    { invoices, filters },
    exportPdf,
  );

  return (
    <ExportBtnContainer>
      <LinkButton disabled={disabled} onClick={onClick}>
        {PRINT_PDF_CAPTION}
      </LinkButton>
    </ExportBtnContainer>
  );
}

PaySupplierInvoicesPdfExportBtn.propTypes = {
  invoices: arrayOf(invoicePaymentPropTypes),
  filters: paySupplierInvoiceFiltersPropTypes.isRequired,
};
