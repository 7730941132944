import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string, func, bool, arrayOf, shape } from 'prop-types';
import { InputErrorMsg } from '@poly/admin-book/src/Input/index.js';
import { Checkbox } from '@poly/admin-book/src/Checkbox/index.js';

const RadioComponentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 15px;
`;

const RadioCheckboxesRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  > div {
    margin-right: 8%;
  }
`;

export function RadioButtons({
  name,
  value,
  error,
  options,
  hasError,
  onChange,
  className,
}) {
  const valueByType = R.equals(value);

  return (
    <RadioComponentWrapper className={className}>
      <RadioCheckboxesRow>
        {options.map(({ value: option, label, disabled = false }) => (
          <Checkbox
            key={option}
            label={label}
            disabled={disabled}
            name={`${name}.${option}`}
            value={valueByType(option)}
            onChange={onChange(option)}
          />
        ))}
      </RadioCheckboxesRow>
      {hasError && !!error && <InputErrorMsg>{error}</InputErrorMsg>}
    </RadioComponentWrapper>
  );
}

RadioButtons.propTypes = {
  name: string,
  value: string,
  error: string,
  className: string,
  hasError: bool.isRequired,
  onChange: func.isRequired,
  options: arrayOf(shape({ value: string, label: string, disabled: bool }))
    .isRequired,
};
