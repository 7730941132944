import * as R from 'ramda';
import React, { useMemo, useState } from 'react';
import { useTableInfiniteScrollQuery } from '@poly/client-utils';
import { TitleWithBackButton } from '@poly/admin-ui/src/components/pagesHeadersComps.js';

import { ARAgingReportHeader } from './ARAgingReportHeader.js';
import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { ARAgingReportTableContainer } from './ARAgingReportTable.js';
import { arAgingReportQuery } from './arAgingReportQuery.js';

function TitleComp() {
  return <TitleWithBackButton title="AR Aging Report" />;
}

export function ARAgingReportPage() {
  const [query, setQuery] = useState(null);

  const { data, loading, tableProps } = useTableInfiniteScrollQuery(
    arAgingReportQuery,
    query,
    {
      endpointName: 'arAgingReport',
      pageSize: 500,
      skip: !query,
    },
  );

  const report = useMemo(
    () => R.pathOr([], ['arAgingReport', 'hits'], data),
    [data],
  );

  return (
    <PageWithSearchHeader headerHeight="165px">
      <ARAgingReportHeader
        exportProps={{ report, query }}
        title="AR Aging Report"
        TitleComp={TitleComp}
        setQuery={setQuery}
        loading={loading}
      />
      <ARAgingReportTableContainer {...{ loading, report, ...tableProps }} />
    </PageWithSearchHeader>
  );
}
