import * as R from 'ramda';
import { node } from 'prop-types';
import React, { createContext, useContext } from 'react';

export const TasksTableContext = createContext({});

export const getContextData = R.pick([
  'collection',
  'entity',
  'onAddTask',
  'onCompleteTask',
  'onUpdateTask',
  'onEditTask',
]);

export const useTasksContext = () => {
  const context = useContext(TasksTableContext);

  return context;
};

export function MyTasksContextProvider({ children, ...props }) {
  const contextData = getContextData(props);
  return (
    <TasksTableContext.Provider value={contextData}>
      {children}
    </TasksTableContext.Provider>
  );
}

MyTasksContextProvider.propTypes = {
  children: node.isRequired,
};
