import React from 'react';
import * as R from 'ramda';
import { bool, shape, string } from 'prop-types';
import { Select } from '@poly/admin-book';
import {
  formatSelectOptionsByConstantsValue,
  formatSelectOptionsWithTitleCased,
} from '@poly/client-utils';
import {
  mapMasterSupplierStatusesUI,
  SupplierSources,
  supplierTypes,
} from '@poly/constants';
import { propEqLegacy } from '@poly/utils';

import { supplierPrivateFormFields } from '../../../forms/supplierForm/form/sections/accountSection.js';
import { halfWidth } from '../../../forms/common.js';

// supplierTypeOptions :: Object -> [Option]
const supplierTypeOptions = formatSelectOptionsByConstantsValue(supplierTypes);

// supplierTypeOptionsTIAA :: [Option] -> [Option]
const supplierTypeOptionsTIAA = R.reject(
  propEqLegacy('value', supplierTypes.SUBCONTRACTOR),
  supplierTypeOptions,
);

// supplierTypeOptionsTIAA :: Object -> [Option]
const supplierTypeOptionsBySource = R.ifElse(
  propEqLegacy('supplierSource', SupplierSources.TIAA),
  R.always(supplierTypeOptionsTIAA),
  R.always(supplierTypeOptions),
);

// isMasterSupplierTypeSelectEditable :: MasterSupplier -> Boolean
const isMasterSupplierTypeSelectEditable = propEqLegacy(
  'type',
  supplierTypes.SUBCONTRACTOR,
);

function MasterSupplierTypeSelect({
  formData,
  masterSupplier,
  isSupplierTypeEditable,
  ...props
}) {
  return (
    <Select
      {...props}
      name="MasterSupplierTypeSelect"
      options={supplierTypeOptionsBySource(formData)}
      disabled={
        !isSupplierTypeEditable ||
        isMasterSupplierTypeSelectEditable(masterSupplier)
      }
    />
  );
}

MasterSupplierTypeSelect.propTypes = {
  isSupplierTypeEditable: bool,
  masterSupplier: shape({ type: string }),
  formData: shape({ supplierSource: string.isRequired }),
};

function MasterSupplierStatusSelect(props) {
  const options = formatSelectOptionsWithTitleCased(
    mapMasterSupplierStatusesUI,
  );

  return (
    <Select {...props} name="MasterSupplierStatusSelect" options={options} />
  );
}

export const masterSupplierSettingsSection = (
  masterSupplier,
  isSupplierTypeEditable,
  hasAccessToPrivateSupplier,
) => [
  {
    label: 'Supplier Settings',
    order: 1,
    layout: { column: 1 },
    fields: [
      {
        label: 'Supplier Type',
        order: 1,
        layout: { row: 1, width: halfWidth },
        required: true,
        field: {
          name: 'type',
          Component: (props) => (
            <MasterSupplierTypeSelect
              {...props}
              masterSupplier={masterSupplier}
              isSupplierTypeEditable={isSupplierTypeEditable}
            />
          ),
          withFormData: true,
        },
      },
      {
        label: 'Status',
        order: 2,
        layout: { row: 1, width: halfWidth },
        required: true,
        field: {
          name: 'status',
          Component: MasterSupplierStatusSelect,
        },
      },
      ...(hasAccessToPrivateSupplier ? [supplierPrivateFormFields] : []),
    ],
  },
];
