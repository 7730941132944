import React from 'react';
import { string, shape } from 'prop-types';
import { DateRangePicker } from '@poly/admin-book';

import { getQueryForSelect } from '../../../../sidebars/ProjectSidebar/forms/form/utils/sections.js';
import { PropertySelect as CommonPropertySelect } from '../../../selects/PropertySelect/PropertySelect.js';

export function PropertySelect({ relatedFilters, ...props }) {
  return (
    <CommonPropertySelect
      withoutSkip
      includeAllOption={false}
      includeMissingOption
      query={getQueryForSelect(relatedFilters)}
      filterOption={() => true}
      {...props}
    />
  );
}

PropertySelect.propTypes = {
  placeholder: string,
  relatedFilters: shape({ clientId: string }),
};

PropertySelect.defaultProps = {
  placeholder: 'Start typing property',
};

export function DatePicker(props) {
  return <DateRangePicker {...props} showResetBtn />;
}

DatePicker.defaultProps = {
  placeholder: 'Select date',
};
