import * as R from 'ramda';

import { ADMIN_APP_NAME } from './app-names.js';
import { FULL_ACCESS_PERMISSION } from './permissions.js';
import {
  EQUALS_AIT_OPERATOR,
  FLIPPED_CONTAINS_AIT_OPERATOR,
  NOT_FLIPPED_CONTAINS_AIT_OPERATOR,
} from './operators.js';
import { CLIENT_APP_AIT } from './access-item-types.js';

export const getAITPathByAITOperator = (aitKey, aitOperator) =>
  `accessItems.types.${aitKey}.${aitOperator}`;

// getSearchAccessItemsQueryByAIT :: String -> SearchQuery -> String -> SearchQuery
export const getSearchAccessItemsQueryByAIT = R.curry(
  (aitKey, additionalShouldQuery, aitValue) => {
    const valueIsArray = R.is(Array, aitValue);

    const equalsAITPart = {
      [getAITPathByAITOperator(aitKey, EQUALS_AIT_OPERATOR)]: aitValue,
    };
    const shouldEqualsAITPart = valueIsArray
      ? { terms: equalsAITPart }
      : { term: equalsAITPart };

    const flippedAITPart = {
      [getAITPathByAITOperator(aitKey, FLIPPED_CONTAINS_AIT_OPERATOR)]:
        aitValue,
    };
    const shouldFlippedAITPart = valueIsArray
      ? { terms: flippedAITPart }
      : { term: flippedAITPart };

    const notFlippedAITPart = {
      [getAITPathByAITOperator(aitKey, NOT_FLIPPED_CONTAINS_AIT_OPERATOR)]:
        aitValue,
    };
    const shouldNotFlippedAITPart = valueIsArray
      ? { terms: notFlippedAITPart }
      : { term: notFlippedAITPart };

    return {
      bool: {
        filter: [
          {
            nested: {
              path: 'accessItems',
              query: {
                bool: {
                  must: [
                    {
                      bool: {
                        should: [
                          shouldEqualsAITPart,
                          shouldFlippedAITPart,
                          ...additionalShouldQuery,
                        ],
                      },
                    },
                    { bool: { must_not: shouldNotFlippedAITPart } },
                  ],
                },
              },
            },
          },
          {
            nested: {
              path: 'accessItems',
              query: { exists: { field: 'accessItems' } },
            },
          },
        ],
      },
    };
  },
);

const commonAdditionalFullPermissionQuery = [
  { term: { 'accessItems.permission': FULL_ACCESS_PERMISSION } },
];

export const searchAccessItemsByClientAppAndFullPermission =
  getSearchAccessItemsQueryByAIT(
    CLIENT_APP_AIT,
    commonAdditionalFullPermissionQuery,
  )(ADMIN_APP_NAME);
