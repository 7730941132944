import * as R from 'ramda';
import { assocBy } from '@poly/utils';

import { pickCommonTimesheetProps } from './common.js';
import { normalizeTimesheetEntries } from './normalize-entries.js';
import { getTimesheetDurationInMinutes } from './duration.js';

// nilToNull :: Any -> Any
export const nilToNull = R.when(R.isNil, R.always(null));

// ensureEmptyFieldsAreUnset :: Timesheet -> Timesheet
// ensure we unset empty fields at backend
const ensureEmptyFieldsAreUnset = R.compose(
  R.map(nilToNull),
  R.over(R.lensProp('entries'), nilToNull),
);

// convertFormDataToTimesheet :: Timesheet -> Timesheet
export const convertFormDataToTimesheet = R.compose(
  ensureEmptyFieldsAreUnset,
  // total time should always be present
  R.when(
    R.propSatisfies(R.isNil, 'totalTimeInMinutes'),
    assocBy('totalTimeInMinutes', getTimesheetDurationInMinutes),
  ),
  R.omit(['withoutUser', 'isEdit']),
  R.when(R.prop('withoutUser'), R.omit(['ownerId'])),
  R.when(R.has('entries'), normalizeTimesheetEntries),
  pickCommonTimesheetProps,
);
