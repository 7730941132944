import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import { PopoverLink } from '@poly/admin-book';
import { useModalContext } from '@poly/admin-ui';

import {
  AddSupplierInvoiceForm,
  addSupplierInvoiceTaskFormId,
} from '../forms/AddInvoiceTaskForm.js';

// getSubTitle :: Project -> String
const getSubTitle = R.compose(
  R.join(' / '),
  R.reject(R.isNil),
  R.juxt([
    R.path(['client', 'name']),
    R.path(['property', 'name']),
    R.prop('projectId'),
  ]),
  R.prop('project'),
);

export function AddInvoiceTaskPopoverLink({ supplierId, ...props }) {
  const { openModalForm, closeModal } = useModalContext();

  const onClick = () =>
    openModalForm({
      btnCaption: 'Create',
      subTitle: getSubTitle(props),
      id: addSupplierInvoiceTaskFormId,
      title: 'Add Supplier Invoice Task',
      formId: addSupplierInvoiceTaskFormId,
      content: (
        <AddSupplierInvoiceForm
          {...props}
          _id={supplierId}
          closeModal={closeModal}
        />
      ),
    });

  return <PopoverLink onClick={onClick}>Add Invoice Task</PopoverLink>;
}

AddInvoiceTaskPopoverLink.propTypes = { supplierId: string.isRequired };
