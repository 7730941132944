import * as R from 'ramda';

import { convertCentsToDollars } from './converters.js';
import { createExcelExportCell } from './excel-export/helpers.js';
import { ExcelExportCellType } from './excel-export/constants.js';
import { formatDate } from './dates.js';

// centsToDollarsByProp :: String -> Object -> Number
const centsToDollarsByProp = (propName) =>
  R.compose(convertCentsToDollars, R.prop(propName));

// getApprovedBy :: BatchSubCategoryReport -> String
const getApprovedBy = R.compose(
  R.join('#'),
  R.juxt([R.path(['profile', 'fullName']), R.path(['emails', 0, 'address'])]),
  R.prop('approvedBy'),
);

// getClientGLCode :: BatchSubCategoryReport -> String
const getClientGLCode = R.compose(
  R.join('_'),
  R.juxt([R.prop('code'), R.prop('name')]),
  R.prop('clientGLCode'),
);

// getSubCategoryReportRow :: [SubCategoryReport] -> [ExcelExportDataCell]
const getSubCategoryReportRow = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 4),
    ...R.repeat(ExcelExportCellType.dollarCurrency, 4),
    ...R.repeat(ExcelExportCellType.default, 7),
  ]),
  R.juxt([
    R.compose(formatDate, R.prop('invoiceDate')),
    R.path(['project', 'projectId']),
    R.path(['project', 'description']),
    R.path(['supplier', 'company', 'name']),
    centsToDollarsByProp('invoiceAmount'),
    centsToDollarsByProp('managementFee'),
    centsToDollarsByProp('taxAmount'),
    centsToDollarsByProp('total'),
    R.prop('costCenter'),
    getClientGLCode,
    R.prop('subCategoryName'),
    getApprovedBy,
    R.prop('areaName'),
    R.prop('regionName'),
    R.path(['property', 'name']),
  ]),
);

// getSubCategoryReportRows :: [SubCategoryReport] -> [[ExcelExportDataCell]]
const getSubCategoryReportRows = R.compose(
  R.unnest,
  R.map(R.compose(R.append([{}]), R.map(getSubCategoryReportRow))),
  R.values,
  R.groupBy(R.prop('regionName')),
);

// getSubCategoryReportXLSConfig :: [SubCategoryReport] -> [ExcelExportData]
export const getSubCategoryReportXLSConfig = (report) => ({
  name: 'Sub Category Report',
  columnWidths: [15, 15, 55, 25, 15, 15, 15, 15, 25, 35, 35, 35, 20, 20, 20],
  rows: [
    R.zipWith(
      createExcelExportCell,
      [
        ...R.repeat(ExcelExportCellType.tableHeader, 4),
        ...R.repeat(ExcelExportCellType.tableCurrencyHeader, 4),
        ...R.repeat(ExcelExportCellType.tableHeader, 7),
      ],
      [
        'Invoice Date',
        'Project #',
        'Description',
        'Supplier',
        'Amount',
        'Mgt Fee',
        'Tax ',
        'Total',
        'Cost Center',
        'GL Code',
        'Sub Category',
        'Approved By',
        'Area',
        'Region',
        'Property',
      ],
    ),
    ...getSubCategoryReportRows(report),
  ],
});
