import React from 'react';
import { bool, object, func, oneOfType } from 'prop-types';

import { ProjectsTabContainer } from './ProjectsTabContainer.js';

export function ProjectsTabComp({
  query,
  Table,
  isGeneral,
  additionalProps,
  paginationVisible,
}) {
  return (
    <ProjectsTabContainer
      isGeneral={isGeneral}
      paginationVisible={paginationVisible}
    >
      <Table {...{ query, ...additionalProps }} />
    </ProjectsTabContainer>
  );
}

ProjectsTabComp.propTypes = {
  paginationVisible: bool.isRequired,
  Table: oneOfType([func, object]).isRequired,
  /* eslint-disable react/forbid-prop-types */
  query: object,
  isGeneral: bool,
  additionalProps: object,
};

ProjectsTabComp.defaultProps = {
  query: null,
  isGeneral: false,
  additionalProps: {},
};
