import {
  bool,
  func,
  shape,
  string,
  number,
  object,
  arrayOf,
  oneOfType,
} from 'prop-types';
import * as R from 'ramda';
import { useLazyQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { entities, skipIfEmpty } from '@poly/admin-ui';
import { Select } from '@poly/admin-book';
import { propEqLegacy } from '@poly/utils';
import {
  entityToOptionByLabelPath,
  useQueryWithSearch,
} from '@poly/client-utils';

import {
  PROPERTIES_BY_SEARCH,
  DEFAULT_PROPERTY_QUERY,
} from '../../core/hooks/properties/queries.js';
import { ALL } from '../../core/constants/general.js';
import { PROPERTIES_BY_SEARCH_SUB } from '../../core/hooks/properties/subscriptions.js';

export const MAIN_ACCOUNT_OPTION_VALUE = 'MAIN_ACCOUNT_OPTION_VALUE';

// getClientIdByProperty :: Property -> ID
const getClientIdByProperty = R.path(['client', '_id']);

// getProperty :: ID -> { hits: [Property] } -> Property
const getProperty = (propertyId) =>
  R.compose(
    R.defaultTo({}),
    R.find(propEqLegacy('_id', propertyId)),
    R.propOr([], 'hits'),
  );

const skipPropertyQueryChecker = R.either(
  propEqLegacy('value', MAIN_ACCOUNT_OPTION_VALUE),
  skipIfEmpty('value'),
);

// getPropertySelectOptions :: (Boolean, Boolean) -> { searchProperties: { hits: [Property] } } -> [SelectOption]
// eslint-disable-next-line import/no-unused-modules
export const getPropertySelectOptions = (
  includeAllOption,
  includeMainAccountOption,
) =>
  R.compose(
    R.when(
      R.always(includeMainAccountOption),
      R.prepend({ value: MAIN_ACCOUNT_OPTION_VALUE, label: 'Main Account' }),
    ),
    R.when(
      R.always(includeAllOption),
      R.prepend({ value: ALL, label: 'All Properties' }),
    ),
    R.map(entityToOptionByLabelPath(['name'])),
    R.reject(R.isNil),
    R.flatten,
    R.juxt([
      R.prop('property'),
      R.pathOr([], ['properties', 'searchProperties', 'hits']),
    ]),
  );

const useDefaultPropertyQuery = ({ value, skipSelectedOptionQuery }) => {
  const [queryHandler] = useLazyQuery(DEFAULT_PROPERTY_QUERY);
  const [property, setProperty] = useState(null);

  const fetchPolicy = skipPropertyQueryChecker({ value })
    ? 'cache-only'
    : 'cache-first';

  useEffect(() => {
    if (!skipSelectedOptionQuery && value) {
      queryHandler({
        variables: { id: value },
        skip: skipPropertyQueryChecker({ value }),
        fetchPolicy,
      }).then(({ data }) => {
        setProperty(data?.property);
      });
    }

    return () => {
      setProperty(null);
    };
  }, [skipSelectedOptionQuery, value]);

  return property;
};

export function PropertySelect({
  isClearable = true,
  includeAllOption = true,
  name = 'PropertySelectDropdown',
  includeMainAccountOption = false,
  placeholder = 'Start typing properties',
  ...restProps
}) {
  const {
    value,
    onChange,
    additionalSearchParams,
    withoutSkip,
    fetchSize,
    meta,
    error: errorFromProps,
    changeFieldValue,
    skipQuery,
    query,
    skipSelectedOptionQuery,
  } = restProps;

  const { result, error, onSearchChange } = useQueryWithSearch({
    gqlSearchQuery: PROPERTIES_BY_SEARCH,
    gqlSearchChangedQuery: PROPERTIES_BY_SEARCH_SUB,
    entity: entities.PROPERTY,
    fetchSize,
    withoutSkip,
    meta,
    error: errorFromProps,
    propsOfComponent: {
      name,
      placeholder,
      isClearable,
      includeAllOption,
      includeMainAccountOption,
      ...restProps,
    },
    skipQuery,
    query,
    ...additionalSearchParams,
  });

  const propertyValue = useDefaultPropertyQuery({
    value,
    skipSelectedOptionQuery,
  });

  const onChangeHandler = (propertyId) => {
    const property = getProperty(propertyId)(result.searchProperties);
    const clientId = getClientIdByProperty(property);

    if (typeof changeFieldValue === 'function') {
      changeFieldValue('clientId', clientId);
      changeFieldValue('fullProperty', property);
    }

    onChange(propertyId, property);
  };

  const selectProps = {
    ...result,
    ...restProps,
    error,
    value,
    withoutSkip,
    onInputChange: onSearchChange,
    onChange: onChangeHandler,
    options: getPropertySelectOptions(
      includeAllOption,
      includeMainAccountOption,
    )({ properties: result, property: propertyValue }),
  };

  return <Select {...selectProps} />;
}

PropertySelect.displayName = 'PropertySelect';

PropertySelect.propTypes = {
  name: string,
  value: string,
  error: string,
  onChange: func,
  isClearable: bool,
  withoutSkip: bool,
  fetchSize: number,
  placeholder: string,
  includeAllOption: bool,
  changeFieldValue: func,
  includeMainAccountOption: bool,
  additionalSearchParams: shape({
    sort: arrayOf(oneOfType([string, object])),
    query: shape({ term: shape({ clientId: string }) }),
  }),
  meta: oneOfType([bool, string, object]),
};

export function ClientPropertiesSelect({ clientId, ...restProps }) {
  const query = { bool: { must: [{ match: { clientId: clientId || '' } }] } };
  const skipQuery = !clientId;
  return <PropertySelect {...restProps} query={query} skipQuery={skipQuery} />;
}

ClientPropertiesSelect.displayName = 'ClientPropertiesSelect';

ClientPropertiesSelect.propTypes = { clientId: string };
