import * as R from 'ramda';
import { Input } from '@poly/admin-book';
import { halfWidth } from '@poly/admin-ui/src/modules/forms/common.js';

import { DivisionStatusDropDown } from '../components/DivisionStatusDropDown.js';
import { onKeyDownToPreventFormSubmit } from '../../../utils/form.js';

const getNameFieldLayout = (isNewDivision) =>
  isNewDivision ? { row: 1 } : { row: 1, width: halfWidth };

const prepareNameToValidation = R.replace(/[\\~#%&*{}/:<>?|"-]/g, '');

// validateDivisionName :: String -> Boolean
const validateDivisionName = R.compose(
  R.gt(R.__, 0),
  R.length,
  prepareNameToValidation,
  R.defaultTo(''),
);

export const getDivisionFormSections = (isNewDivision) => [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        label: 'Name',
        order: 1,
        layout: getNameFieldLayout(isNewDivision),
        required: true,
        validators: [[validateDivisionName, 'Division is required']],
        field: {
          name: 'name',
          additionalProps: { onKeyDown: onKeyDownToPreventFormSubmit },
          Component: Input,
        },
      },
      {
        label: 'Status',
        order: 2,
        layout: { row: 1, width: halfWidth },
        renderIf: () => !isNewDivision,
        required: true,
        field: {
          name: 'status',
          additionalProps: { onKeyDown: onKeyDownToPreventFormSubmit },
          Component: DivisionStatusDropDown,
        },
      },
    ],
  },
];
