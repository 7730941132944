import React from 'react';
import * as R from 'ramda';
import { object, oneOfType, string, arrayOf, bool } from 'prop-types';
import { useUpdateQueryParams } from '@poly/client-routing';
import { useOutSidebarContext } from '@poly/client-utils';
import { SidebarWidth } from '@poly/admin-ui';
import styled from 'styled-components';
import { Popover, Text } from '@poly/admin-book';

import { SidebarIDs } from '../constants.js';
import { AssetSidebar } from './AssetSidebar.js';
import { LinkToSidebar } from '../../components/LinkToSidebar.js';
import { formatAssetTitle } from './assetSidebarUtils.js';
import { useClearPristineState } from '../useClearPristineOnSidebarMount.js';
import {
  AssetLifeExpectancyWarning,
  WarningIcon,
} from './AssetLifeExpectancyWarning.js';

const FlexContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export function AssetLink({ _id, children, queryParams = {}, ...restProps }) {
  const linkParams = R.mergeDeepLeft(
    { query: { [SidebarIDs.asset]: _id } },
    queryParams,
  );

  const linkProps = { linkParams, ...restProps };

  return (
    <LinkToSidebar {...linkProps}>
      {children || formatAssetTitle(restProps)}
    </LinkToSidebar>
  );
}

AssetLink.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  queryParams: object,
  _id: string.isRequired,
  children: oneOfType([object, string, arrayOf(oneOfType([string, object]))]),
};

const Wrapper = styled.div`
  padding: 15px;
  width: 315px;
  word-break: break-word;
  background-color: #12347a;
  border-radius: 5px;
`;

const TextS = styled(Text)`
  font-size: 14px;
  color: #fff;
`;

function AssetPropertyWarning({ isDifferentProperty }) {
  if (!isDifferentProperty) {
    return null;
  }

  return (
    <Popover
      Icon={WarningIcon}
      position="bottom"
      bgColor="#12347a"
      content={
        <Wrapper>
          <TextS>
            This asset belongs to a different property. You can unlink it from
            the project if needed.
          </TextS>
        </Wrapper>
      }
    />
  );
}

AssetPropertyWarning.propTypes = {
  isDifferentProperty: bool,
};

export function AssetLinkWithWarning(props) {
  return (
    <FlexContainer>
      <AssetLifeExpectancyWarning asset={props} />
      <AssetLink {...props} />
      <AssetPropertyWarning {...props} />
    </FlexContainer>
  );
}

export const useOpenAssetSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();

  return (assetId, skipSteady) =>
    openOutSidebar(
      {
        alwaysFirst: true,
        width: SidebarWidth,
        id: SidebarIDs.asset,
        content: assetId ? <AssetSidebar assetId={assetId} /> : null,
      },
      skipSteady,
    );
};

export const useNavigateToAssetSidebar = () => {
  const updateQueryParams = useUpdateQueryParams();
  const clearPristine = useClearPristineState();

  return (assetId) => {
    clearPristine();
    updateQueryParams({
      [SidebarIDs.asset]: assetId,
      [SidebarIDs.property]: undefined,
    });
  };
};
