import * as R from 'ramda';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';
import React, { useState, useRef, useEffect } from 'react';
import { string, func, bool, oneOfType, element, shape } from 'prop-types';
import { BODY_PORTAL_ANCHOR_ID } from '@poly/constants';
import { propEqLegacy } from '@poly/utils';

import { PopUp } from '../Rating/styles.js';
import { LinkButton } from '../LinkButton/index.js';
import { getThemeColor, getThemeProp, getThemeFont } from '../utils.js';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ParentBox = styled(Wrapper)`
  position: relative;
`;

const TextButtonS = styled(LinkButton).attrs({
  'data-testid': 'link-button',
})`
  font-size: 14px;
  letter-spacing: normal;
  padding-right: 15px;
  margin-right: -15px;

  color: ${({ isActive, ...props }) =>
    isActive
      ? getThemeColor(['accent2'])(props)
      : getThemeColor(['primaryLight'])(props)};

  ${({ disabled }) => disabled && 'color: #999;'};
`;

const Text = styled.div`
  margin: 0;
`;

const visibleStyles = css`
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
`;

const hiddenStyles = css`
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.2s, opacity 0.2s ease-in-out;
`;

const rightPosition = css`
  left: ${R.prop('left')}px;

  :after {
    left: -7px;
    box-shadow: none;
    border-color: ${R.propOr('none', 'bgColor')};
  }
`;

const leftPosition = css`
  right: ${R.prop('right')}px;
  left: auto;

  :after {
    left: auto;
    right: -17px;
    box-shadow: none;
    transform: rotate(135deg);
    border-color: ${R.propOr('none', 'bgColor')};
  }
`;

const bottomPosition = css`
  left: 0;
  top: ${({ height }) => height + 10}px;

  :after {
    top: -7px;
    left: 20px;
    box-shadow: none;
    transform: rotate(45deg);
    border-color: ${R.propOr('none', 'bgColor')};
  }
`;

const topPosition = css`
  left: ${({ contentWidth }) => -contentWidth / 2}px;
  top: ${({ contentHeight }) => -contentHeight - 15}px;

  :after {
    box-shadow: none;
    border-color: ${R.propOr('none', 'bgColor')};
    top: ${({ contentHeight }) => contentHeight}px;
    left: ${({ contentWidth }) => contentWidth / 2}px;
  }
`;

export const PopoverBox = styled(PopUp)`
  width: max-content;
  align-items: flex-start;
  border-radius: 6px;
  top: ${R.prop('top')}px;
  padding: 0;
  max-width: ${R.propOr('none', 'maxWidth')};
  :after {
    top: 17px;
    border-width: 5px;
  }

  ${R.cond([
    [propEqLegacy('position', 'left'), R.always(leftPosition)],
    [propEqLegacy('position', 'bottom'), R.always(bottomPosition)],
    [propEqLegacy('position', 'top'), R.always(topPosition)],
    [R.T, R.always(rightPosition)],
  ])};

  ${({ isActive }) => (isActive ? visibleStyles : hiddenStyles)};
`;

export const PopoverLink = styled.div`
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  padding: 10px 15px;
  background: transparent;
  font-weight: ${getThemeFont(['regular'])};
  color: ${getThemeColor(['primary'])};
  transition: background-color ${getThemeProp(['defaultTransition'])};

  :hover {
    background-color: ${getThemeColor(['secondaryLighter2'])};
    transition: background-color 0.1s linear;
  }
`;

export function Popover({
  Icon,
  title,
  bgColor,
  position,
  maxWidth,
  disabled,
  className,
  isClickable,
  contentProps,
  PopoverParent,
  withPortalAnchor,
  disableBoxShadow,
  content: ContentComp,
}) {
  const [isActive, setIsActive] = useState(false);
  const [parentAttrs, setParentAttrs] = useState({
    top: -7,
    left: 0,
    right: 0,
  });

  const iconPadding = Icon ? 7 : 15;

  const ref = useRef(null);
  const contentRef = useRef(null);

  const anchor = document.getElementById(BODY_PORTAL_ANCHOR_ID);

  const isPortal = !!anchor && withPortalAnchor;

  const TitleCompByProps = isClickable ? TextButtonS : Text;

  const TitleComp = PopoverParent || TitleCompByProps;

  const isContentFunction = R.is(Function, ContentComp);

  const ContentComponent = (
    <PopoverBox
      {...{
        ...parentAttrs,
        isActive,
        position,
        maxWidth,
        ref: contentRef,
        bgColor,
        disableBoxShadow,
      }}
    >
      {isContentFunction ? (
        <ContentComp
          {...{ isActive, setActive: setIsActive }}
          {...contentProps}
        />
      ) : (
        ContentComp
      )}
    </PopoverBox>
  );

  const Content = isPortal
    ? createPortal(ContentComponent, anchor)
    : ContentComponent;

  useEffect(() => {
    if (ref.current) {
      const { top, left, width, height } = ref.current.getBoundingClientRect();
      const { height: contentHeight, width: contentWidth } =
        contentRef.current.getBoundingClientRect();

      const maxAllowedTop = window.innerHeight - contentHeight;
      const calculatedPortalTop = top < maxAllowedTop ? top : maxAllowedTop;
      setParentAttrs({
        top: (isPortal ? calculatedPortalTop : 0) - 7,
        left: (isPortal ? left : 0) + width + iconPadding,
        right: (isPortal ? window.innerWidth - left : width) + iconPadding,
        height,
        contentHeight,
        contentWidth,
      });
    }
  }, [isActive]);

  const hoverProps = disabled
    ? {}
    : {
        onMouseEnter: () => setIsActive(true),
        onMouseLeave: () => setIsActive(false),
      };

  return (
    <Wrapper {...{ className }} title="popover">
      <ParentBox ref={ref} title="" {...hoverProps}>
        {Icon ? (
          <Icon {...{ isActive }} />
        ) : (
          <TitleComp {...{ isActive, disabled }}>{title}</TitleComp>
        )}
        {Content}
      </ParentBox>
    </Wrapper>
  );
}

Popover.propTypes = {
  Icon: func,
  disabled: bool,
  bgColor: string,
  position: string,
  maxWidth: string,
  className: string,
  isClickable: bool,
  PopoverParent: func,
  withPortalAnchor: bool,
  disableBoxShadow: bool,
  contentProps: shape({}),
  title: oneOfType([element, string]),
  content: oneOfType([element, func]),
};

Popover.defaultProps = {
  Icon: null,
  title: null,
  className: '',
  content: null,
  disabled: false,
  maxWidth: 'none',
  contentProps: {},
  position: 'right',
  isClickable: true,
  withPortalAnchor: false,
};
