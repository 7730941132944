import * as R from 'ramda';
import { createActions, handleActions } from 'redux-actions';
import { useDispatch } from 'react-redux';

export const { openModal, closeCurrentModal } = createActions(
  'OPEN_MODAL',
  'CLOSE_CURRENT_MODAL',
);

export const useOpenModal = () => {
  const dispatch = useDispatch();
  return (payload) => dispatch(openModal(payload));
};

export const useCloseCurrentModal = () => {
  const dispatch = useDispatch();
  return () => dispatch(closeCurrentModal());
};

const defaultState = { name: '', payload: {} };

export const modalReducer = handleActions(
  {
    [openModal]: (_, { payload }) => payload,
    [closeCurrentModal]: R.always(defaultState),
  },
  defaultState,
);
