import React from 'react';
import { useOutSidebarContext } from '@poly/client-utils';

import { SidebarIDs } from '../../../sidebars/constants.js';
import { CustomSidebarLayout } from '../../../sidebars/components/CustomSidebarLayout.js';
import { RequestedSupplierDocumentReviewSidebar } from './RequestedSupplierDocumentReviewSidebar.js';

export const useOpenRequestedDocumentReviewSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();

  return () => {
    openOutSidebar({
      id: SidebarIDs.requestedSupplierDocument,
      content: <RequestedSupplierDocumentReviewSidebar />,
      Layout: CustomSidebarLayout,
    });
  };
};
