import { useUpdateQueryParams } from '@poly/client-routing';

import { gql, useMutation } from '@apollo/client';
import { useNotificationState } from '../../../../hooks/useNotificationState.js';

const editSupplierMutation = gql`
  mutation editSupplierMutation(
    $supplierId: ID!
    $update: UpdateSupplierInput!
  ) {
    updateSupplier(id: $supplierId, update: $update) {
      supplier {
        _id
      }
    }
  }
`;

export const useEditSupplier = () => {
  const [mutate] = useMutation(editSupplierMutation);
  const updateQueryParams = useUpdateQueryParams();
  const { showSuccessNotification } = useNotificationState();

  const editSupplier = async ({ supplierId, update }) => {
    await mutate({ variables: { supplierId, update } });

    updateQueryParams({ isEdit: 'false' });
    showSuccessNotification('The supplier was successfully edited');
  };

  return editSupplier;
};
