import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useMutation, gql } from '@apollo/client';
import { string, arrayOf, shape } from 'prop-types';
import { LinkButton, Table, useNotificationContext } from '@poly/admin-book';
import { useOutSidebarContext } from '@poly/client-utils';
import { propEqLegacy } from '@poly/utils';
import {
  supplierEmailsTypes,
  supplierPhoneTypes,
  supplierTypes,
} from '@poly/constants';
import {
  oneRowSidebarTabTableStyles,
  useMapConfigToTableProps,
  SidebarWidth,
  SidebarRow,
  RemoveIcon,
  PhoneLink,
  EmailLink,
  entities,
} from '@poly/admin-ui';

import {
  SectionLabel,
  SectionWrapper,
} from '../components/commonSidebarComponents.js';
import { assetTabsSidebarId } from './constants.js';
import { SecondSidebarLayout } from '../../components/SidebarLayouts.js';
import { assignSupplierFormTypes } from '../ProjectSidebar/constants.js';
import { supplierSidebarColumn } from '../ProjectSidebar/ProjectSidebarSuppliers.js';
import { assignSupplierFormId } from '../../modules/forms/assignSupplierForm/constants.js';
import { AssignSupplierForm } from '../../modules/forms/assignSupplierForm/AssignSupplierForm.js';
import { useHasUserAccessToUpdateAsset } from './useHasUserAccessToUpdateAsset.js';

const REMOVE_SUPPLIER_FROM_ASSET_MUTATION = gql`
  mutation REMOVE_SUPPLIER_FROM_ASSET_MUTATION($input: SupplierToAssetInput!) {
    removeSupplierFromAsset(input: $input) {
      _id
    }
  }
`;

function AddSupplierToAssetButton({ _id }) {
  const { openOutSidebar } = useOutSidebarContext();

  const onClick = () =>
    openOutSidebar({
      width: SidebarWidth,
      id: assignSupplierFormId,
      Layout: SecondSidebarLayout,
      hideSidebarId: assetTabsSidebarId,
      content: (
        <AssignSupplierForm
          entity={{ _id, name: entities.ASSET }}
          formType={assignSupplierFormTypes.add}
        />
      ),
    });

  return <LinkButton onClick={onClick}>Add</LinkButton>;
}

AddSupplierToAssetButton.propTypes = { _id: string.isRequired };

const SuppliersTable = styled(Table)`
  ${oneRowSidebarTabTableStyles};

  td:nth-child(4),
  th:nth-child(4) {
    width: 25px;
  }
`;

// getPhoneLinkProps :: Supplier -> Phone
const getPhoneLinkProps = R.compose(
  R.objOf('number'),
  R.prop('phone'),
  R.defaultTo({}),
  R.find(
    R.both(
      propEqLegacy('type', supplierPhoneTypes.GENERAL),
      propEqLegacy('legacyFormat', false),
    ),
  ),
  R.pathOr([], ['company', 'phones']),
);

function AssetSupplierPhone(props) {
  const linkProps = getPhoneLinkProps(props);
  return <PhoneLink {...linkProps} />;
}

// getEmailLinkProps :: Supplier -> Email
const getEmailLinkProps = R.converge(R.find, [
  R.compose(
    (typeProp) => propEqLegacy('type', typeProp),
    R.ifElse(
      propEqLegacy('type', supplierTypes.ADMINISTRATIVE),
      R.always(supplierEmailsTypes.GENERAL),
      R.always(supplierEmailsTypes.SERVICE),
    ),
  ),
  R.pathOr([], ['company', 'emails']),
]);

function AssetSupplierEmail(props) {
  const linkProps = getEmailLinkProps(props);
  return <EmailLink {...linkProps} />;
}

function RemoveAssetSupplierButton({ _id, assetId }) {
  const { showSuccessNotification } = useNotificationContext();
  const [removeSupplierFromAsset] = useMutation(
    REMOVE_SUPPLIER_FROM_ASSET_MUTATION,
  );

  const onDelete = async () => {
    await removeSupplierFromAsset({
      variables: { input: { assetId, supplierId: _id } },
    });
    showSuccessNotification('Supplier was removed');
  };

  return <RemoveIcon onClick={onDelete} />;
}

RemoveAssetSupplierButton.propTypes = {
  _id: string.isRequired,
  assetId: string.isRequired,
};

const getAssetSidebarSuppliersConfig = (hasUpdateAssetPermission) => [
  supplierSidebarColumn,
  ['Phone', AssetSupplierPhone],
  ['Email', AssetSupplierEmail],
  ...(hasUpdateAssetPermission ? [['', RemoveAssetSupplierButton]] : []),
];

// prepareAssetSuppliers :: Asset -> [Supplier]
const prepareAssetSuppliers = R.converge(R.map, [
  R.compose(R.mergeLeft, R.objOf('assetId'), R.prop('_id')),
  R.compose(R.defaultTo([]), R.prop('suppliers')),
]);

// assetHasSuppliers :: Asset -> Boolean
const assetHasSuppliers = R.propSatisfies(
  R.both(R.complement(R.isNil), R.complement(R.isEmpty)),
  'suppliers',
);

export function AssetSidebarSuppliers({ asset }) {
  const hasUpdateAssetPermission = useHasUserAccessToUpdateAsset();

  const tableProps = useMapConfigToTableProps(
    prepareAssetSuppliers,
    getAssetSidebarSuppliersConfig(hasUpdateAssetPermission),
    asset,
  );

  return (
    <SectionWrapper>
      <SidebarRow justify align>
        <SectionLabel>Suppliers</SectionLabel>
        {hasUpdateAssetPermission && (
          <AddSupplierToAssetButton _id={asset._id} />
        )}
      </SidebarRow>
      {assetHasSuppliers(asset) && (
        <SuppliersTable {...tableProps} minHeightLess />
      )}
    </SectionWrapper>
  );
}

AssetSidebarSuppliers.propTypes = {
  asset: shape({
    _id: string.isRequired,
    suppliers: arrayOf(
      shape({
        _id: string.isRequired,
        company: shape({ name: string.isRequired }),
      }),
    ),
  }),
};
