import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import arrayMutators from 'final-form-arrays';
import { arrayOf, func, shape, string } from 'prop-types';
import { usePristineSubscribe } from '@poly/client-routing';
import { assocBy, isNilOrEmpty, propEqLegacy } from '@poly/utils';
import {
  useNotificationContext,
  getThemeColor,
  FormCreator,
} from '@poly/admin-book';
import {
  commonSidebarFormSectionLayout,
  commonSidebarFormFieldLayout,
  useOnSubmitSetStopSubmitting,
  commonSidebarFormLayout,
} from '@poly/admin-ui';

import { ALL } from '../../../modules/core/constants/general.js';
import { ATTACH_PROCEDURES_MUTATION } from './AttachProcedureForm.js';
import { ProcedureBulkAttachLine } from './ProcedureBulkAttachLine.js';

const ProcedureBulkAttachFromCreator = styled(FormCreator)`
  min-height: calc(100vh - 202px);
  margin-top: 12px;
`;

const ProcedureBulkAttachFormHolderTextS = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  padding-bottom: 8px;
  color: ${getThemeColor(['midDark'])};
`;

function ProcedureBulkAttachFormHolderText() {
  return (
    <ProcedureBulkAttachFormHolderTextS>
      First, select a procedure, then attach some relevant assets to it
    </ProcedureBulkAttachFormHolderTextS>
  );
}

const getProcedureBulkAttachFormSections = (entity) => [
  {
    order: 1,
    layout: { margin: 0 },
    fields: [
      {
        order: 1,
        layout: { row: 1, width: '100%' },
        field: {
          name: 'textHolder',
          Component: ProcedureBulkAttachFormHolderText,
        },
      },
      {
        order: 2,
        layout: { row: 2, width: '100%' },
        field: {
          withFormData: true,
          isArrayField: true,
          name: 'attachPayload',
          Component: (props) => (
            <ProcedureBulkAttachLine {...props} entity={entity} />
          ),
        },
      },
    ],
  },
];

// prepareValuesByAttachedProcedures :: [ProjectAssetProcedure] -> FormData
const prepareValuesByAttachedProcedures = R.converge(R.mergeLeft, [
  R.compose(
    R.objOf('attachPayload'),
    R.ifElse(
      R.isEmpty,
      R.prepend({ assetIds: [] }),
      R.compose(
        R.map(
          R.applySpec({
            isProcedureExecuted: R.pathSatisfies(R.complement(R.isNil), [
              0,
              'executedProcedure',
              '_id',
            ]),
            procedureId: R.path([0, 'procedure', '_id']),
            assetIds: R.map(
              R.applySpec({
                value: R.path(['asset', '_id']),
                label: R.path(['asset', 'displayName']),
              }),
            ),
            types: R.compose(
              R.map(R.prop('_id')),
              R.pathOr([], [0, 'procedure', 'assetTypes']),
            ),
          }),
        ),
        R.values,
        R.groupBy(R.path(['procedure', '_id'])),
      ),
    ),
  ),
  R.compose(
    R.objOf('executedProcedureAssets'),
    R.map(R.path(['asset', '_id'])),
    R.reject(R.propSatisfies(isNilOrEmpty, 'executedProcedure')),
    R.defaultTo([]),
  ),
]);

// prepareValuesByEntity :: Object -> Object
const prepareValuesByEntity = R.pick(['subPropertiesIds', 'propertyId']);

// prepareFormValuesToMutation :: Object -> FormValues -> AttachProceduresInput
const prepareFormValuesToMutation = (entity) =>
  R.converge(R.mergeLeft, [
    ({ executedProcedureAssets, attachPayload }) =>
      R.compose(
        R.objOf('attachPayload'),
        R.map(
          R.compose(
            R.pick(['procedureId', 'assetIds']),
            R.when(
              R.propSatisfies(R.includes(ALL), 'assetIds'),
              assocBy(
                'assetIds',
                R.compose(
                  R.concat(executedProcedureAssets),
                  R.propOr([], 'allAssetsIds'),
                ),
              ),
            ),
            R.over(R.lensProp('assetIds'), R.map(R.prop('value'))),
          ),
        ),
      )(attachPayload),
    R.compose(
      R.applySpec({
        isBulkAttach: R.T,
        projectId: R.prop('_id'),
        collection: R.ifElse(
          propEqLegacy('name', 'project'),
          R.always('projects'),
          R.always('recurringProjects'),
        ),
      }),
      R.always(entity),
    ),
  ]);

export function ProcedureBulkAttachFrom({
  formId,
  entity,
  onCancel,
  assetProcedures,
}) {
  const { showSuccessNotification } = useNotificationContext();
  const [attachProcedures] = useMutation(ATTACH_PROCEDURES_MUTATION);
  const pristineSubscribeProps = usePristineSubscribe({ id: formId });

  const onSubmitHandler = async (formData) => {
    const input = prepareFormValuesToMutation(entity)(formData);

    await attachProcedures({ variables: { input } });

    showSuccessNotification('Procedures were updated successfully');

    onCancel();
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  const initialValues = {
    ...prepareValuesByEntity(entity),
    ...prepareValuesByAttachedProcedures(assetProcedures),
  };

  return (
    <ProcedureBulkAttachFromCreator
      id={formId}
      onSubmit={onSubmit}
      mutators={arrayMutators}
      initialValues={initialValues}
      layout={commonSidebarFormLayout}
      sections={getProcedureBulkAttachFormSections(entity)}
      fieldLayout={commonSidebarFormFieldLayout}
      sectionLayout={commonSidebarFormSectionLayout}
      {...pristineSubscribeProps}
    />
  );
}

ProcedureBulkAttachFrom.propTypes = {
  onCancel: func.isRequired,
  formId: string.isRequired,
  entity: shape({
    _id: string.isRequired,
    name: string.isRequired,
    propertyId: string.isRequired,
    subPropertiesIds: arrayOf(string),
  }).isRequired,
  assetProcedures: arrayOf(
    shape({ procedure: shape({ _id: string.isRequired }) }),
  ),
};
