import React, { useState } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { ClientSelect, FlexColumn, FlexContainer } from '@poly/admin-ui';
import {
  Button,
  getThemeColor,
  InputOutForm,
  NumberInputHTML5,
  PageHeaderContainer,
} from '@poly/admin-book';
import { bool, func } from 'prop-types';

import { ProjectSelect } from '../../components/ProjectsSelect.js';
import { ProrateButton } from './ProrateButton.js';
import { ReceiveClientPaymentsForm } from './ReceiveClientPaymentsForm.js';
import { useFetchClientInvoices } from './useFetchClientInvoices.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { ConsolidatedInvoiceSelect } from '../../components/ConsolidatedInvoiceSelect.js';

const HeaderText = styled.div`
  font-size: 18px;
  line-height: 21px;
  color: ${getThemeColor(['darkest'])};
`;

const ResetButton = styled(Button)`
  background: ${getThemeColor(['primaryLight'])};
  margin-right: 10px;
`;

const SearchButton = styled(Button)`
  background: ${getThemeColor(['primaryRegular'])};
`;

const SearchSectionContainer = styled(FlexContainer)`
  & > div:not(:last-child) {
    margin-right: 15px;
  }
  margin: 20px 0 20px 0;
  justify-content: flex-start;
  align-items: flex-end;

  > div:nth-child(2) {
    width: 170px;
  }
`;

const PageContainer = styled(FlexColumn)`
  height: calc(100% - 90px);
  align-items: center;
  background: ${getThemeColor(['lightest'])};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1150px;
  max-width: 1440px;
  width: calc(100% - 150px);
  margin: 0 70px 0 80px;
  height: 100%;
`;

const TableContainer = styled.div`
  width: 100%;
  flex-grow: 1;
`;

const commonSelectProps = {
  fontSize: '10px',
  size: 'small',
  width: '160px',
  labelProps: {
    color: 'midDark',
    size: 10,
  },
};

function ReceiveClientPaymentsPageHeader({
  isDisabled,
  onReset,
  onSearch,
  loading,
}) {
  return (
    <PageHeaderContainer width="1150px">
      <HeaderText>Receive Payments</HeaderText>
      <FlexContainer>
        <ResetButton size="tiny" onClick={onReset}>
          Reset
        </ResetButton>
        <SearchButton
          disabled={isDisabled}
          size="tiny"
          onClick={onSearch}
          loader={loading}
        >
          Search
        </SearchButton>
      </FlexContainer>
    </PageHeaderContainer>
  );
}

ReceiveClientPaymentsPageHeader.propTypes = {
  onReset: func.isRequired,
  onSearch: func.isRequired,
  isDisabled: bool.isRequired,
  loading: bool.isRequired,
};

const searchFiltersNames = {
  clientId: 'clientId',
  projectId: 'projectId',
  consolidatedClientInvoiceId: 'consolidatedClientInvoiceId',
  invoiceNumber: 'invoiceNumber',
  invoiceNumberFrom: 'invoiceNumberFrom',
  invoiceNumberTo: 'invoiceNumberTo',
  discount: 'discount',
};

const getInputValue = (name, filters) => R.propOr('', name, filters);

export function ReceiveClientPaymentsPage() {
  const [filters, setFilters] = useState(null);
  const { searchFilters, handlers, onReset } = useSearchFilters([
    { name: 'clientId' },
    { name: 'projectId' },
    { name: 'consolidatedClientInvoiceId' },
    { name: 'invoiceNumber' },
    { name: 'invoiceNumberFrom' },
    { name: 'invoiceNumberTo' },
    { name: 'discount' },
  ]);

  const { loading, tableProps, invoices, pageSize } =
    useFetchClientInvoices(filters);

  const onResetHandler = () => {
    onReset();
    setFilters(null);
  };

  return (
    <>
      <ReceiveClientPaymentsPageHeader
        onSearch={() => !loading && setFilters(searchFilters)}
        onReset={onResetHandler}
        isDisabled={!searchFilters.clientId}
        loading={loading}
      />
      <PageContainer>
        <ContentContainer>
          <SearchSectionContainer>
            <InputOutForm
              value={searchFilters[searchFiltersNames.clientId]}
              onChange={handlers[searchFiltersNames.clientId]}
              label="Client"
              placeholder="Start typing Client"
              includeAllOption
              required
              Component={ClientSelect}
              {...commonSelectProps}
            />
            <ProjectSelect
              value={searchFilters[searchFiltersNames.projectId]}
              onChange={handlers[searchFiltersNames.projectId]}
              label="Project"
              width="40px"
              placeholder="Start typing Project"
              {...commonSelectProps}
            />
            <ConsolidatedInvoiceSelect
              labelColor="midDark"
              labelSize={10}
              value={
                searchFilters[searchFiltersNames.consolidatedClientInvoiceId]
              }
              onChange={
                handlers[searchFiltersNames.consolidatedClientInvoiceId]
              }
              label="Consolidated Invoice #"
              placeholder="#"
              {...commonSelectProps}
            />
            <NumberInputHTML5
              value={getInputValue(
                searchFiltersNames.invoiceNumber,
                searchFilters,
              )}
              onChange={handlers[searchFiltersNames.invoiceNumber]}
              label="Invoice Number"
              name="invoice-number"
              placeholder="#"
              changeOnBlur
              maxLength={10}
              {...commonSelectProps}
            />
            <NumberInputHTML5
              value={getInputValue(
                searchFiltersNames.invoiceNumberFrom,
                searchFilters,
              )}
              onChange={handlers[searchFiltersNames.invoiceNumberFrom]}
              label="From Invoice Number"
              name="from-invoice-number"
              placeholder="#"
              type="number"
              changeOnBlur
              maxLength={10}
              {...commonSelectProps}
            />
            <NumberInputHTML5
              value={getInputValue(
                searchFiltersNames.invoiceNumberTo,
                searchFilters,
              )}
              onChange={handlers[searchFiltersNames.invoiceNumberTo]}
              name="to-invoice-number"
              label="To Invoice Number"
              placeholder="#"
              type="number"
              changeOnBlur
              maxLength={10}
              {...commonSelectProps}
            />
            <ProrateButton
              value={getInputValue(searchFiltersNames.discount, searchFilters)}
              onChange={handlers[searchFiltersNames.discount]}
              disabled={invoices.length === 0}
              {...commonSelectProps}
            />
          </SearchSectionContainer>
          <TableContainer>
            <ReceiveClientPaymentsForm
              virtualized
              tableProps={tableProps}
              loading={loading}
              pageSize={pageSize}
            />
          </TableContainer>
        </ContentContainer>
      </PageContainer>
    </>
  );
}
