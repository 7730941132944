import { shape, string, arrayOf, number } from 'prop-types';

const batchPaymentPropType = shape({
  _id: string.isRequired,
  type: string.isRequired,
  transaction_number: number.isRequired,
  check_number: number.isRequired,
  date: string.isRequired,
  payee_name: string.isRequired,
  amount: number.isRequired,
});

const accountPropType = shape({
  name: string.isRequired,
  code: string.isRequired,
});

export const reconciliationReportDataPropType = shape({
  bankReconciliationReport: shape({
    beginningBalance: number.isRequired,
    clearedBalance: number.isRequired,
    registerBalance: number.isRequired,
    endingBalance: number.isRequired,
    account: accountPropType.isRequired,
    endingDate: string.isRequired,
    payments: arrayOf(batchPaymentPropType).isRequired,
    deposits: arrayOf(batchPaymentPropType).isRequired,
    outstandingPayments: arrayOf(batchPaymentPropType).isRequired,
    outstandingDeposits: arrayOf(batchPaymentPropType).isRequired,
  }),
});

export const reconciliationReportSectionsPropType = arrayOf(
  shape({ propName: string.isRequired, title: string.isRequired }),
);
