import React from 'react';
import styled, { css } from 'styled-components';
import { bool, element, func, number, string } from 'prop-types';

import { getThemeColor, getThemeFont, getThemeProp } from '../utils.js';

export const PopUpMenuItemStyles = css`
  padding: 7px 10px;
  font-size: 13px;
  line-height: 17px;
  font-weight: ${getThemeFont(['regular'])};
  color: ${getThemeColor(['primaryLighter'])};
  transition: all ${getThemeProp(['defaultTransition'])};

  &:hover {
    color: ${getThemeColor(['primary'])};
    background-color: ${getThemeColor(['secondaryLighter5'])};
  }
`;

export const PopUpMenuItem = styled.div`
  ${PopUpMenuItemStyles};
`;

PopUpMenuItem.displayName = 'PopUpMenuItem';

const PopUpContainer = styled.div`
  position: relative;
  height: 100%;
`;

export const DefaultPopUpContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 60px;
  z-index: 10000;
  padding: 5px 0;
  width: 180px;
  border-radius: ${getThemeProp(['defaultBorderRadius'])};
  background-color: ${getThemeColor(['white'])};
  box-shadow: 0 0 4px 1px rgba(30, 36, 44, 0.06),
    0 12px 18px -6px rgba(30, 36, 44, 0.16);
`;

DefaultPopUpContentWrapper.displayName = 'DefaultPopUpContentWrapper';

export function PopUp({ trigger, children, isOpen, ...props }) {
  return (
    <PopUpContainer {...props}>
      {trigger}
      {isOpen ? children : null}
    </PopUpContainer>
  );
}

PopUp.propTypes = {
  trigger: element.isRequired,
  onBlur: func,
  children: element.isRequired,
  isOpen: bool.isRequired,
  tabIndex: number,
  className: string,
};

PopUp.defaultProps = {
  className: '',
  onBlur: () => {},
  tabIndex: -1,
};
