import * as R from 'ramda';
import { useState, useEffect, useCallback } from 'react';
import { debounceWithDefaultThreshold } from '@poly/client-utils';
import { forceTitleCase } from '@poly/utils';

export const useSearchTabLogic = (entity) => {
  const [searchTerm, setSearchTerm] = useState('');

  const callSetSearchInput = useCallback(
    debounceWithDefaultThreshold((text) => setSearchTerm(text)),
    [],
  );

  useEffect(() => {
    setSearchTerm('');
  }, []);

  return {
    searchTerm,
    skipTrim: true,
    name: `sidebar-${entity}-tab-search-input`,
    placeholder: `Search ${forceTitleCase(entity)}`,
    onChange: R.compose(callSetSearchInput, R.path(['target', 'value'])),
  };
};
