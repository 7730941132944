import React from 'react';
import { func, string } from 'prop-types';
import styled from 'styled-components';
import {
  confirmCancelFormPayload,
  FlexColumn,
  FlexContainer,
  useModalContext,
} from '@poly/admin-ui';
import { useLocation } from '@poly/client-routing';
import { Button, getThemeColor } from '@poly/admin-book';

import { SidebarCloseIcon } from '../../components/SidebarLayouts.js';

export const useCloseSidebarForm = (onCancel) => {
  const { openConfirmModal } = useModalContext();
  const { state } = useLocation();

  return (...args) =>
    state?.pristine
      ? onCancel(...args)
      : openConfirmModal({
          ...confirmCancelFormPayload,
          onConfirm: (closeConfirmModal) => () => {
            onCancel(...args);
            closeConfirmModal();
          },
        });
};

const CloseButton = styled(Button)`
  margin-left: 15px;
`;

export function CloseSidebarFormIcon({ onCancel }) {
  const closeSidebarForm = useCloseSidebarForm(onCancel);

  return <SidebarCloseIcon onClick={closeSidebarForm} />;
}

CloseSidebarFormIcon.propTypes = { onCancel: func.isRequired };

export function CloseSidebarFormButton({ onCancel, size }) {
  const closeSidebarForm = useCloseSidebarForm(onCancel);

  return (
    <CloseButton
      size={size}
      styleType="basicSecondary"
      onClick={closeSidebarForm}
    >
      Close
    </CloseButton>
  );
}

CloseSidebarFormButton.propTypes = {
  onCancel: func.isRequired,
  size: string,
};

export const SideBarFormHeader = styled(FlexContainer)`
  font-size: 20px;
  font-weight: normal;
  color: ${getThemeColor(['label'])};
  padding: 24px 24px 10px 24px;
  width: 100%;
  justify-content: space-between;
`;

export const SideBarFormWrapper = styled(FlexColumn)`
  min-height: calc(100vh - 80px);
  position: relative;
`;

export const FormButtonsContainer = styled(FlexContainer)`
  align-items: center;
  justify-content: flex-start;
  height: 56px;
  padding: 24px;
  background: #fff;
`;
