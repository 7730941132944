import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useLocation, parseQuery } from '@poly/client-routing';
import { useOutSidebarContext } from '@poly/client-utils';
import { propEqLegacy } from '@poly/utils';

import {
  addClientFormId,
  editClientFormId,
} from './ClientSidebar/constants.js';
import {
  addProjectFormId,
  editProjectFormId,
} from './ProjectSidebar/constants.js';
import {
  addPropertyFormId,
  editPropertyFormId,
} from './PropertySidebar/constants.js';
import { addUserFormId, editUserFormId } from './UserSidebar/constants.js';
import {
  addAssetFormId,
  editAssetFormId,
  importAssetsFormId,
} from './AssetSidebar/constants.js';
import { useEditPropertySidebar } from './PropertySidebar/forms/edit/useEditPropertySidebar.js';
import { useAddProjectSidebarBase } from './ProjectSidebar/forms/add/useAddProjectSidebar.js';
import { useEditProjectSidebar } from './ProjectSidebar/forms/edit/useEditProjectSidebar.js';
import { useAddPropertySidebar } from './PropertySidebar/forms/add/useAddPropertySidebar.js';
import { useEditClientSidebar } from './ClientSidebar/forms/edit/useEditClientSidebar.js';
import { useAddClientSidebar } from './ClientSidebar/forms/add/useAddClientSidebar.js';
import { useEditAssetSidebar } from './AssetSidebar/forms/edit/useEditAssetSidebar.js';
import { useAddAssetSidebar } from './AssetSidebar/forms/add/useAddAssetSidebar.js';
import { useEditUserSidebar } from './UserSidebar/forms/edit/useEditUserSidebar.js';
import { useAddUserSidebar } from './UserSidebar/forms/add/useAddUserSidebar.js';
import {
  useOpenEditContactForm,
  editContactFormId,
} from './ContactSidebar/forms/edit/EditContactButton.js';
import { useOpenAddContactForm } from './ContactSidebar/forms/add/AddContactButton.js';
import { useOnImportAssetsSidebar } from './AssetSidebar/forms/import/useOnImportAssetsSidebar.js';
import {
  addPurchaseOrderFormId,
  editPurchaseOrderFormId,
} from './PurchaseOrderSidebar/constants.js';
import { useAddPurchaseOrderSidebar } from './PurchaseOrderSidebar/form/useAddPurchaseOrderSidebar.js';
import { useEditPurchaseOrderSidebar } from './PurchaseOrderSidebar/form/useEditPurchaseOrderSidebar.js';
import { addContactFormId } from './ContactSidebar/forms/add/AddContactFrom.js';
import { useCreateTaskTemplateSidebar } from '../pages/TaskTemplates/sidebar/useCreateTaskTemplateSidebar.js';
import {
  createTaskTemplateFormId,
  updateTaskTemplateFormId,
} from '../pages/TaskTemplates/sidebar/constants.js';
import { useUpdateTaskTemplateSidebar } from '../pages/TaskTemplates/sidebar/useUpdateTaskTemplateSidebar.js';

const useOpenSidebarFormMapById = (id) => {
  const openAddUserForm = useAddUserSidebar();
  const openEditUserForm = useEditUserSidebar();
  const openAddAssetForm = useAddAssetSidebar();
  const openEditAssetForm = useEditAssetSidebar();
  const openAddClientForm = useAddClientSidebar();
  const openEditClientForm = useEditClientSidebar();
  const openAddContactForm = useOpenAddContactForm();
  const openEditProjectForm = useEditProjectSidebar();
  const openAddPropertyForm = useAddPropertySidebar();
  const openEditContactForm = useOpenEditContactForm();
  const openAddProjectForm = useAddProjectSidebarBase();
  const openEditPropertyForm = useEditPropertySidebar();
  const openImportAssetsForm = useOnImportAssetsSidebar();
  const openAddPurchaseOrderForm = useAddPurchaseOrderSidebar();
  const openEditPurchaseOrderForm = useEditPurchaseOrderSidebar();
  const openCreateTaskTemplateFrom = useCreateTaskTemplateSidebar();
  const openUpdateTaskTemplateFrom = useUpdateTaskTemplateSidebar();

  const notFoundHandler = () => null;

  if (!id) return notFoundHandler;

  const openSidebarMap = {
    [addUserFormId]: openAddUserForm,
    [editUserFormId]: openEditUserForm,
    [addAssetFormId]: openAddAssetForm,
    [editAssetFormId]: openEditAssetForm,
    [addClientFormId]: openAddClientForm,
    [editClientFormId]: openEditClientForm,
    [addContactFormId]: openAddContactForm,
    [addProjectFormId]: openAddProjectForm,
    [editContactFormId]: openEditContactForm,
    [editProjectFormId]: openEditProjectForm,
    [addPropertyFormId]: openAddPropertyForm,
    [editPropertyFormId]: openEditPropertyForm,
    [importAssetsFormId]: openImportAssetsForm,
    [addPurchaseOrderFormId]: openAddPurchaseOrderForm,
    [editPurchaseOrderFormId]: openEditPurchaseOrderForm,
    [createTaskTemplateFormId]: openCreateTaskTemplateFrom,
    [updateTaskTemplateFormId]: openUpdateTaskTemplateFrom,
  };

  const openSidebarHandler = openSidebarMap[id];
  return openSidebarHandler || notFoundHandler;
};

// isNotOpenSidebar :: ID -> SidebarConfig -> Boolean
const isNotOpenSidebar = (id) =>
  R.compose(R.isNil, R.find(propEqLegacy('id', id)));

// isNotFormQuery :: String -> Boolean
const isNotFormQuery = R.compose(R.not, R.has('pristine'), parseQuery);

export const useSidebarFormByCache = () => {
  const { state, search } = useLocation();
  const [formId, setFormId] = useState(null);
  const { sidebarConfig, closeAllOutSidebars } = useOutSidebarContext();

  const { id, ...formProps } = R.pathOr({}, ['form'])(state);

  const openSidebarHandler = useOpenSidebarFormMapById(id);

  useEffect(() => {
    if (!!id && !!formId && formId !== id) {
      setFormId(id);
    }
  }, [id]);

  useEffect(() => {
    if (!!formId && isNotOpenSidebar(formId)(sidebarConfig)) {
      setFormId(null);
    }
  }, [sidebarConfig]);

  useEffect(() => {
    if (!!formId && isNotOpenSidebar(formId)(sidebarConfig)) {
      openSidebarHandler(formProps);
    }

    if (
      !id &&
      !isNotOpenSidebar(formId)(sidebarConfig) &&
      isNotFormQuery(search)
    ) {
      closeAllOutSidebars();
    }
  }, [formId, state]);
};
