/*
  Note: We don't need ad-hoc cell types that are used by single export only.
        At least 2 pages should use new cell type (same styles/formatting)
        in order to be added here. Otherwise all ad-hoc cell types should be
        specified inline (e.g. inside specific export config)
*/
export const ExcelExportCellType = {
  default: 'default',
  defaultText: 'defaultText',
  defaultCentered: 'defaultCentered',
  defaultCurrency: 'defaultCurrency',
  dollarCurrency: 'dollarCurrency',
  hyperlink: 'hyperlink',
  title: 'title',
  subTitle: 'subTitle',
  subTitleRow: 'subTitleRow',
  tableHeader: 'tableHeader',
  tableHeaderCentered: 'tableHeaderCentered',
  tableCurrencyHeader: 'tableCurrencyHeader',
  tableStringFooter: 'tableStringFooter',
  tableCurrencyFooter: 'tableCurrencyFooter',
  tableTitleRow: 'tableTitleRow',
  tableDollarCurrencyFooter: 'tableDollarCurrencyFooter',
};

const BASE_HEADER_STYLE = {
  font: {
    bold: true,
  },
  fill: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '00C5D9F6' },
  },
};

export const XLS_BASE_FOOTER_STYLE = {
  font: { bold: true },
  fill: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'e6e6e6' },
  },
};

export const XLS_CURRENCY_FORMAT = '#,##0.00';

const DOLLAR_CURRENCY_FORMAT = '$#,##0.00';

export const EXCEL_CELL_CONFIG = {
  [ExcelExportCellType.default]: { style: {} },
  [ExcelExportCellType.defaultCurrency]: {
    style: {
      numFmt: XLS_CURRENCY_FORMAT,
      alignment: { horizontal: 'right' },
    },
  },
  [ExcelExportCellType.dollarCurrency]: {
    style: {
      numFmt: DOLLAR_CURRENCY_FORMAT,
      alignment: { horizontal: 'right' },
    },
  },
  [ExcelExportCellType.defaultText]: {
    style: {
      alignment: { horizontal: 'left' },
    },
  },
  [ExcelExportCellType.hyperlink]: {
    style: {
      font: { color: { argb: 'FF436CBE' } },
    },
  },
  [ExcelExportCellType.title]: {
    _polyInternal: { singleCellRow: true },
    style: {
      font: { size: 15, bold: true },
      alignment: { vertical: 'middle', horizontal: 'center' },
    },
  },
  [ExcelExportCellType.subTitle]: {
    _polyInternal: { singleCellRow: true },
    style: {
      font: { bold: true },
      alignment: { vertical: 'middle', horizontal: 'center' },
    },
  },
  [ExcelExportCellType.subTitleRow]: {
    _polyInternal: { singleCellRow: true },
    style: {
      font: { bold: true },
      alignment: { horizontal: 'left' },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '1c84c6' },
      },
    },
  },
  [ExcelExportCellType.tableTitleRow]: {
    _polyInternal: { singleCellRow: true },
    style: BASE_HEADER_STYLE,
  },
  [ExcelExportCellType.tableHeader]: {
    style: BASE_HEADER_STYLE,
  },
  [ExcelExportCellType.tableHeaderCentered]: {
    style: { ...BASE_HEADER_STYLE, alignment: { horizontal: 'center' } },
  },
  [ExcelExportCellType.tableCurrencyHeader]: {
    style: {
      ...BASE_HEADER_STYLE,
      alignment: { horizontal: 'right' },
    },
  },
  [ExcelExportCellType.tableStringFooter]: { style: XLS_BASE_FOOTER_STYLE },
  [ExcelExportCellType.tableCurrencyFooter]: {
    style: {
      ...XLS_BASE_FOOTER_STYLE,
      numFmt: XLS_CURRENCY_FORMAT,
      alignment: { horizontal: 'right' },
    },
  },
  [ExcelExportCellType.tableDollarCurrencyFooter]: {
    style: {
      ...XLS_BASE_FOOTER_STYLE,
      alignment: { horizontal: 'right' },
      numFmt: DOLLAR_CURRENCY_FORMAT,
    },
  },
  [ExcelExportCellType.defaultCentered]: {
    style: {
      alignment: { horizontal: 'center' },
    },
  },
};
