import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { arrayOf, string, shape } from 'prop-types';
import {
  tableConfig,
  startDateColumn,
  completionDateColumn,
} from './RecentProjectsTable.js';
import { RecentProjectsTableComp } from './RecentProjectsTableComp.js';

const prinTablePropTypes = {
  data: arrayOf(
    shape({
      projectId: string,
      description: string,
      status: string,
      client: shape({
        name: string,
        nickName: string,
        _id: string,
      }),
      property: shape({
        name: string,
        _id: string,
      }),
    }),
  ).isRequired,
};

const PrintTableComponent = styled(RecentProjectsTableComp)`
  tr {
    border: none !important;
  }
`;

const activeTableConfig = R.append(startDateColumn, tableConfig);
const completedTableConfig = R.append(completionDateColumn, tableConfig);

export function PrintRecentActiveProjectsTable({ data, ...restProps }) {
  const headers = R.map(R.pipe(R.nth(0), R.objOf('title')), activeTableConfig);
  const columns = R.map(R.nth(1), activeTableConfig);

  return (
    <PrintTableComponent
      {...restProps}
      rows={data}
      headers={headers}
      columns={columns}
    />
  );
}

PrintRecentActiveProjectsTable.displayName = 'PrintRecentActiveProjectsTable';

PrintRecentActiveProjectsTable.propTypes = prinTablePropTypes;

export function PrintRecentCompletedProjectsTable({ data, ...restProps }) {
  const headers = R.map(
    R.pipe(R.nth(0), R.objOf('title')),
    completedTableConfig,
  );
  const columns = R.map(R.nth(1), completedTableConfig);

  return (
    <PrintTableComponent
      {...restProps}
      rows={data}
      headers={headers}
      columns={columns}
    />
  );
}

PrintRecentCompletedProjectsTable.displayName =
  'PrintRecentCompletedProjectsTable';

PrintRecentCompletedProjectsTable.propTypes = prinTablePropTypes;
