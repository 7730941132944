import React, { useState } from 'react';
import { shape, string } from 'prop-types';
import styled from 'styled-components';
import { FlexColumn } from '@poly/admin-ui';
import { getThemeColor, Icon } from '@poly/admin-book';

import {
  ReconciliationSection,
  ReconciliationSectionTitle,
  ReconciliationSectionTotal,
} from './reconciliationEntityComponents.js';
import { ReconciliationEntityTable } from './ReconciliationEntityTable.js';
import { ReconciliationPageFooter } from './ReconciliationPageFooter.js';
import { useReconciliationLogic } from './useReconciliationLogic.js';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1150px;
  max-width: 1440px;
  width: calc(100% - 150px);
  margin: 0 70px 0 80px;
  height: calc(100% - 100px);
  flex-grow: 1;
`;

const ReconciliationSectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
  background-color: ${getThemeColor(['white'])};
  border-bottom: 1px solid ${getThemeColor(['midLight'])};
`;

const TransactionsContainer = styled(FlexColumn)`
  flex-grow: 1;
  overflow-y: auto;
  justify-content: flex-start;
`;

const IconS = styled(Icon)`
  margin: 0 6px;
`;

export function ReconciliationPageBody({
  query,
  paymentsSection,
  depositsSection,
  confirmationMessage,
}) {
  const [isOpen, setIsOpen] = useState({
    [depositsSection.title]: true,
    [paymentsSection.title]: true,
  });

  const {
    skip,
    payments,
    setPayments,
    deposits,
    setDeposits,
    paymentsAmount,
    depositsAmount,
    paymentsLoading,
    depositsLoading,
    selectedPayments,
    selectedDeposits,
    refetchTablesData,
    paymentsTableProps,
    depositsTableProps,
    setSelectedPayments,
    setSelectedDeposits,
    allPaymentsFetched,
    allDepositFetched,
  } = useReconciliationLogic(query);

  const reconciliationSectionsProps = [
    {
      title: depositsSection.title,
      tableHeight: depositsSection.tableHeight,
      tableProps: {
        data: deposits,
        setData: setDeposits,
        loading: depositsLoading,
        tableKey: depositsSection.tableKey,
        selectedData: selectedDeposits,
        setSelectedData: setSelectedDeposits,
        ...depositsTableProps,
      },
      total: depositsAmount,
      allItemsFetched: allDepositFetched,
    },
    {
      title: paymentsSection.title,
      tableHeight: paymentsSection.tableHeight,
      tableProps: {
        data: payments,
        setData: setPayments,
        tableKey: paymentsSection.tableKey,
        loading: paymentsLoading,
        selectedData: selectedPayments,
        setSelectedData: setSelectedPayments,
        ...paymentsTableProps,
      },
      total: paymentsAmount,
      allItemsFetched: allPaymentsFetched,
    },
  ];

  const labels = {
    payments: paymentsSection.totalLabel,
    deposits: depositsSection.totalLabel,
    confirmation: confirmationMessage,
  };

  return (
    <>
      <ContentContainer>
        <TransactionsContainer>
          {reconciliationSectionsProps.map(
            ({ title, tableProps, total, allItemsFetched }) => (
              <ReconciliationSection
                key={tableProps.tableKey}
                isOpen={isOpen[title]}
              >
                <ReconciliationSectionTitleWrapper
                  onClick={() =>
                    setIsOpen({ ...isOpen, [title]: !isOpen[title] })
                  }
                >
                  <IconS
                    size={20}
                    name={isOpen[title] ? 'arrow-up' : 'arrow-down'}
                  />
                  <ReconciliationSectionTitle>
                    {title}
                  </ReconciliationSectionTitle>
                </ReconciliationSectionTitleWrapper>
                {isOpen[title] && (
                  <ReconciliationEntityTable
                    {...tableProps}
                    searchTerm={query.searchTerm}
                    allItemsFetched={allItemsFetched}
                  />
                )}
                <ReconciliationSectionTotal>
                  Total Amount: <span>{total}</span>
                </ReconciliationSectionTotal>
              </ReconciliationSection>
            ),
          )}
        </TransactionsContainer>
      </ContentContainer>
      <ReconciliationPageFooter
        {...{
          skip,
          labels,
          payments,
          deposits,
          selectedDeposits,
          selectedPayments,
          refetchTablesData,
          ...query,
        }}
      />
    </>
  );
}

export const sectionConfigShape = shape({
  title: string.isRequired,
  totalLabel: string.isRequired,
  tableHeight: string.isRequired,
  tableKey: string.isRequired,
});

ReconciliationPageBody.propTypes = {
  query: shape({ accountCode: string, searchTerm: string }),
  paymentsSection: sectionConfigShape.isRequired,
  depositsSection: sectionConfigShape.isRequired,
  confirmationMessage: string.isRequired,
};
