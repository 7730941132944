import { SubmitBtn, useProcessState } from '@poly/admin-ui';
import React from 'react';
import styled from 'styled-components';
import { FormCreator, WhiteCard } from '@poly/admin-book';
import { bool, node, string } from 'prop-types';
import { formatBackLink, useLocation } from '@poly/client-routing';
import { ButtonsContainer } from '@poly/admin-ui/src/components/Button/Button.js';

import { CancelFormBtn } from './Buttons.js';

export const FormContainerS = styled(WhiteCard)`
  padding: 15px;
  align-items: start;
  min-height: ${({ isFullHeight }) =>
    isFullHeight ? 'calc(100% - 60px)' : 'none'};
  max-height: none;
  height: min-content;
`;

export const ButtonsContainerS = styled(ButtonsContainer)`
  align-self: flex-end;
  width: 100%;
  justify-content: flex-end;
`;

export function FormCardContainer({
  children,
  fallbackLink,
  submitBtnLabel,
  formId,
  isFullHeight,
}) {
  const { process } = useProcessState(formId);
  const location = useLocation();
  const cancelRedirectUrl = formatBackLink({
    router: location.state,
    withQuery: false,
    fallbackLink,
  });
  return (
    <FormContainerS isFullHeight={isFullHeight}>
      {children}
      <ButtonsContainerS>
        <CancelFormBtn disabled={process} redirectUrl={cancelRedirectUrl} />
        <SubmitBtn loader={process} disabled={process} form={formId}>
          {submitBtnLabel}
        </SubmitBtn>
      </ButtonsContainerS>
    </FormContainerS>
  );
}

FormCardContainer.propTypes = {
  children: node.isRequired,
  fallbackLink: string.isRequired,
  formId: string.isRequired,
  submitBtnLabel: string,
  isFullHeight: bool,
};

FormCardContainer.defaultProps = {
  submitBtnLabel: 'Save',
  isFullHeight: false,
};

export function FormPageWithButtons({
  id,
  fallbackLink,
  isFullHeight,
  ...props
}) {
  return (
    <FormCardContainer
      fallbackLink={fallbackLink}
      formId={id}
      isFullHeight={isFullHeight}
    >
      <FormCreator id={id} {...props} />
    </FormCardContainer>
  );
}

FormPageWithButtons.propTypes = {
  id: string.isRequired,
  fallbackLink: string.isRequired,
  isFullHeight: bool,
};

FormPageWithButtons.defaultProps = {
  isFullHeight: true,
};
