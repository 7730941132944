import { ClientAppsSelect } from '../components/ClientAppsSelect.js';

export const appsFormField = {
  label: 'Client Apps',
  field: {
    name: 'apps',
    withFormData: true,
    Component: ClientAppsSelect,
  },
};
