import React, { useEffect, useMemo } from 'react';
import * as R from 'ramda';
import { taskActionTypes } from '@poly/constants';
import { func, string, bool, object, arrayOf, shape } from 'prop-types';
import { FormCreator } from '@poly/admin-book';
import { usePersistDnDAreaWithMentions } from '@poly/client-utils';

import { taskFormSections } from './sections.js';
import { commonModalLayout } from '../../common.js';
import { useOnSubmitSetStopSubmitting } from '../../../../hooks/useOnSubmitSetStopSubmitting.js';
import { useNotificationState } from '../../../../hooks/useNotificationState.js';
import { useSendConfirmSatisfactionEmailMutation } from './useSendConfirmSatisfactionEmailMutation.js';

const updateMessage = 'Task was successfully updated';
const completeMessage = 'Task was successfully completed';

// prepareMessageResponse :: Input -> String
// Input = {
//    successMessage: String
//    createUpdateWithAction: CreateUpdateWithActionResult
//    updateTask: TaskUpdateResponse
// }
const prepareMessageResponse = R.cond([
  [R.prop('successMessage'), R.prop('successMessage')],
  [
    R.anyPass([
      R.path(['createUpdateWithAction', 'update']),
      R.path(['updateTask', 'task', 'complete']),
    ]),
    R.always(completeMessage),
  ],
  [R.T, R.always(updateMessage)],
]);

// getProjectPropertyIdByTask :: Task -> ID
const getProjectPropertyIdByTask = R.path([
  'document',
  'project',
  'property',
  '_id',
]);

// isNoEmailSelected :: FormData -> Boolean
const isNoEmailSelected = R.compose(
  R.isEmpty,
  R.flatten,
  R.map(R.propOr([], 'emails')),
  R.filter(R.prop('shouldSend')),
  R.values,
  R.propOr({}, 'mailTo'),
);

export function TaskForm({
  task,
  formId,
  mutate,
  payload,
  onCancel,
  projectDbId,
  initialValues,
  isTaskTemplate,
  successMessage,
  isCreateAction,
  customFormConfig,
  isSendAutomaticEmail,
  setIsSendAutomaticEmail,
  disableMentions = false,
  isTaskUpdateMessageOptional,
  setIsSupplierInvoiceTaskDisabled,
}) {
  const sendConfirmSatisfactionEmail =
    useSendConfirmSatisfactionEmailMutation();
  const {
    onChangePersistentValue,
    cleanupRetainedValue,
    retainedValue: details,
  } = usePersistDnDAreaWithMentions(
    `task.details.${task ? task._id : projectDbId}`,
  );

  useEffect(
    () => () => {
      if (isTaskTemplate) {
        cleanupRetainedValue();
      }
    },
    [],
  );

  const { showSuccessNotification } = useNotificationState();
  const isSupplierInvoiceTask =
    task && task.action === taskActionTypes.PROJECT_COMPLETE;
  const isConfirmSatisfactionTask =
    task && task.action === taskActionTypes.CONFIRM_SATISFACTION;

  const onSubmitHandler = async (input) => {
    if (isSendAutomaticEmail && isNoEmailSelected(input)) {
      return;
    }

    if (isSendAutomaticEmail) {
      await sendConfirmSatisfactionEmail({ task, ...input });

      cleanupRetainedValue();
      onCancel();
      return;
    }

    const { data } = await mutate(
      R.omit(['isHQProject', 'isSendAutomaticEmail'], input),
    );

    if (isSupplierInvoiceTask && setIsSupplierInvoiceTaskDisabled) {
      setIsSupplierInvoiceTaskDisabled(true);
    }

    const messageResponse = prepareMessageResponse({ successMessage, ...data });

    showSuccessNotification(messageResponse);
    cleanupRetainedValue();
    onCancel();

    if (isSupplierInvoiceTask && setIsSupplierInvoiceTaskDisabled) {
      setTimeout(() => setIsSupplierInvoiceTaskDisabled(false), 2000);
    }
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  const sections = useMemo(
    () =>
      taskFormSections({
        projectDbId,
        isCreateAction,
        disableMentions,
        isTaskUpdateMessageOptional,
        customFormConfig,
        task,
        payload,
        onChangePersistentValue,
        isSendAutomaticEmail,
        setIsSendAutomaticEmail,
        projectPropertyId: getProjectPropertyIdByTask(task),
      }),
    [isSendAutomaticEmail],
  );

  return (
    <FormCreator
      id={formId}
      onSubmit={onSubmit}
      sections={sections}
      layout={commonModalLayout}
      initialValues={{
        ...initialValues,
        ...(!isTaskTemplate && details ? { details } : {}),
        ...(isConfirmSatisfactionTask ? { isSendAutomaticEmail: false } : {}),
      }}
    />
  );
}

TaskForm.displayName = 'TaskForm';

TaskForm.propTypes = {
  mutate: func,
  projectDbId: string,
  isCreateAction: bool,
  disableMentions: bool,
  successMessage: string,
  formId: string.isRequired,
  onCancel: func.isRequired,
  isSendAutomaticEmail: bool,
  setIsSendAutomaticEmail: func,
  isTaskUpdateMessageOptional: bool,
  // eslint-disable-next-line react/forbid-prop-types
  customFormConfig: arrayOf(object),
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: object,
  task: shape({ _id: string.isRequired }),
  // eslint-disable-next-line react/forbid-prop-types
  payload: object,
  setIsSupplierInvoiceTaskDisabled: func,
  isTaskTemplate: bool,
};
