import React from 'react';
import * as R from 'ramda';
import { oneOfType, string, number, objectOf } from 'prop-types';
import { TaskDueDateFilterPreset } from '@poly/constants';
import { insertQueryParamsIntoURL } from '@poly/utils';

import {
  projectUIStatuses,
  expiringMasterProjectsDays,
  recurringProjectsDashboardUIStatuses,
} from '../../modules/core/constants/projects.js';
import { routesNames } from '../../routes/constants.js';
import { TotalItemComp } from './components/TotalItemComp.js';
import { projectTabByStatus } from '../../modules/tabs/TabsWithRouter.js';
import { OpenSupplierInvoicesTotalComponent } from './components/OpenSupplierInvoicesTotalComponent.js';

const {
  HOME,
  MY_TASKS,
  MY_PROJECTS,
  UNASSIGNED_PROJECTS,
  PROJECTS_DIRECTORY,
  EMERGENCY_PROJECTS,
  MY_EMERGENCY_PROJECTS,
  MASTER_PROJECTS_DIRECTORY,
  MY_MASTER_PROJECTS_DIRECTORY,
  RECALLED_PROJECTS,
  MY_RECURRING_PROJECTS_DIRECTORY,
} = routesNames;

const { EXPIRING30, EXPIRING60, EXPIRING90 } = expiringMasterProjectsDays;

function TotalItem({ fieldName, counts, ...props }) {
  return <TotalItemComp total={R.propOr(0, fieldName, counts)} {...props} />;
}

function getTotalItemComp(configProps) {
  return function (props) {
    return <TotalItem {...{ ...configProps, ...props }} />;
  };
}

TotalItem.propTypes = {
  fieldName: string,
  counts: objectOf(oneOfType([number, string])),
};

const getTotalItems = (config) =>
  config.map((configProps) => ({
    Component: getTotalItemComp(configProps),
    key: configProps?.label,
  }));

const getProjectDueDaysItems = (page) => [
  {
    label: 'Due Today',
    href: insertQueryParamsIntoURL(
      { tab: projectTabByStatus(projectUIStatuses.DUE_TODAY) },
      page,
    ),
    fieldName: 'dueToday',
  },
  {
    label: 'Due Tomorrow',
    href: insertQueryParamsIntoURL(
      { tab: projectTabByStatus(projectUIStatuses.DUE_TOMORROW) },
      page,
    ),
    fieldName: 'dueTomorrow',
  },
  {
    label: 'Past Due',
    href: insertQueryParamsIntoURL(
      { tab: projectTabByStatus(projectUIStatuses.PAST_DUE) },
      page,
    ),
    fieldName: 'overdue',
    color: 'secondaryMid',
  },
];

const getMasterProjectsExpiredItems = (
  page,
  firstItemProps = {},
  commonProps = {},
) => [
  {
    label: 'Expired',
    href: insertQueryParamsIntoURL({ tab: projectUIStatuses.PAST_DUE }, page),

    fieldName: 'expired',
    ...commonProps,
    ...firstItemProps,
  },
  {
    label: 'Expiring 30 Days',
    href: insertQueryParamsIntoURL({ tab: EXPIRING30 }, page),
    fieldName: 'expiring30Days',
    ...commonProps,
  },
  {
    label: 'Expiring 60 Days',
    href: insertQueryParamsIntoURL({ tab: EXPIRING60 }, page),
    fieldName: 'expiring60Days',
    ...commonProps,
  },
  {
    label: 'Expiring 90 Days',
    href: insertQueryParamsIntoURL({ tab: EXPIRING90 }, page),
    fieldName: 'expiring90Days',
    ...commonProps,
  },
];

const projectsConfig = [
  {
    label: 'Unassigned',
    href: UNASSIGNED_PROJECTS,
    margin: '0 0 30px 0',
    fieldName: 'unassigned',
  },
  ...getProjectDueDaysItems(PROJECTS_DIRECTORY),
  {
    label: 'Emergency',
    href: EMERGENCY_PROJECTS,
    fieldName: 'emergency',
  },
  {
    label: 'Active',
    href: insertQueryParamsIntoURL(
      { tab: projectTabByStatus(projectUIStatuses.ACTIVE) },
      PROJECTS_DIRECTORY,
    ),
    margin: '0 0 30px 0',
    fieldName: 'active',
  },
  {
    label: 'Recalls',
    href: RECALLED_PROJECTS,
    fieldName: 'recalls',
  },
  {
    label: 'Rejections',
    href: HOME,
  },
  {
    label: 'On Hold',
    href: insertQueryParamsIntoURL(
      { tab: projectTabByStatus(projectUIStatuses.ON_HOLD) },
      PROJECTS_DIRECTORY,
    ),
    fieldName: 'onHold',
  },
  {
    label: 'Proposed',
    href: HOME,
  },
  {
    label: 'Proposed Submitted',
    href: HOME,
  },
];

const myProjectsItemsConfig = [
  ...getProjectDueDaysItems(MY_PROJECTS),
  {
    label: 'Emergency',
    href: MY_EMERGENCY_PROJECTS,
    fieldName: 'emergency',
  },

  {
    label: 'On Hold',
    href: insertQueryParamsIntoURL(
      { tab: projectTabByStatus(projectUIStatuses.ON_HOLD) },
      MY_PROJECTS,
    ),
    fieldName: 'onHold',
  },
];

const myMasterProjectsConfig = [
  {
    label: 'My Masters',
    fieldName: 'active',
    href: insertQueryParamsIntoURL(
      { tab: projectUIStatuses.ACTIVE },
      MY_MASTER_PROJECTS_DIRECTORY,
    ),
    color: 'inspiniaBlue',
  },
  ...getMasterProjectsExpiredItems(
    MY_MASTER_PROJECTS_DIRECTORY,
    {
      color: 'secondaryMid',
    },
    {
      color: 'inspiniaBlue',
    },
  ),
  {
    label: 'Onboarding',
    href: HOME,
    color: 'inspiniaBlueLighter',
  },
  {
    label: 'Bidding',
    href: HOME,
    color: 'inspiniaBlueLighter',
  },
  {
    label: 'Awarded',
    href: HOME,
    color: 'inspiniaBlueLighter',
  },
];

const myTasksConfig = [
  {
    label: 'Due Today',
    href: insertQueryParamsIntoURL(
      { tab: TaskDueDateFilterPreset.DUE_TODAY },
      MY_TASKS,
    ),
    fieldName: 'dueToday',
  },
  {
    label: 'Due Tomorrow',
    href: insertQueryParamsIntoURL(
      { tab: TaskDueDateFilterPreset.DUE_TOMORROW },
      MY_TASKS,
    ),
    fieldName: 'dueTomorrow',
  },
  {
    label: 'Past Due',
    href: insertQueryParamsIntoURL(
      { tab: TaskDueDateFilterPreset.PAST_DUE },
      MY_TASKS,
    ),
    fieldName: 'overdue',
    color: 'secondaryMid',
  },
  {
    label: 'Ready to Close',
    href: insertQueryParamsIntoURL(
      { tab: TaskDueDateFilterPreset.READY_TO_CLOSE },
      MY_TASKS,
    ),
    fieldName: 'readyToClose',
    color: 'secondaryMid',
  },
];

const masterProjectsConfig = getMasterProjectsExpiredItems(
  MASTER_PROJECTS_DIRECTORY,
  { margin: '0 0 30px 0', color: 'secondaryMid' },
);

export const projectsTotalItems = getTotalItems(projectsConfig);

export const myProjectsTotalItems = getTotalItems(myProjectsItemsConfig);

export const masterProjectsTotalItems = getTotalItems(masterProjectsConfig);

export const myMasterProjectsTotalItems = getTotalItems(myMasterProjectsConfig);

export const tasksTotalItems = [
  ...getTotalItems([
    {
      label: 'W.O. Verifications',
      href: HOME,
    },
  ]),
  {
    Component: OpenSupplierInvoicesTotalComponent,
    key: 'w-o-verifications',
  },
];

function getOpenSupplierInvoicesTotalCom(managerId) {
  return function (props) {
    return (
      <OpenSupplierInvoicesTotalComponent {...props} managerId={managerId} />
    );
  };
}

export const getMyTasksTotalItems = (managerId) => [
  ...getTotalItems(myTasksConfig),
  {
    Component: getOpenSupplierInvoicesTotalCom(managerId),
    key: 'open-supplier-invoices-total',
  },
];

export const myRecurringProjects = getTotalItems([
  {
    label: 'Scheduled',
    fieldName: 'scheduled',
    href: insertQueryParamsIntoURL(
      { tab: recurringProjectsDashboardUIStatuses.SCHEDULED },
      MY_RECURRING_PROJECTS_DIRECTORY,
    ),
  },
  {
    label: 'Unscheduled',
    fieldName: 'unscheduled',
    href: insertQueryParamsIntoURL(
      {
        tab: recurringProjectsDashboardUIStatuses.UNSCHEDULED,
      },
      MY_RECURRING_PROJECTS_DIRECTORY,
    ),
  },
  {
    label: 'Completed (not closed)',
    fieldName: 'completed',
    href: insertQueryParamsIntoURL(
      { tab: recurringProjectsDashboardUIStatuses.COMPLETED },
      MY_RECURRING_PROJECTS_DIRECTORY,
    ),
  },
]);
