import React from 'react';
import styled from 'styled-components';
import { arrayOf, bool, shape, func } from 'prop-types';
import { useMapConfigToTablePropsWithSorting } from '@poly/admin-ui';
import { Loader, Table } from '@poly/admin-book';

import { procedureTableConfig } from './table-config.js';
import { ManageProceduresPageTableWrapper } from './components.js';

const TableS = styled(Table)`
  th:nth-child(3),
  td:nth-child(3) {
    width: 50px;
  }
`;

export function ManageProceduresPageTable({ loading, procedures }) {
  const tableProps = useMapConfigToTablePropsWithSorting({
    items: procedures,
    tableConfig: procedureTableConfig,
    initialSorting: { columnKey: 0, dir: 1 },
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <ManageProceduresPageTableWrapper>
      <TableS {...tableProps} />
    </ManageProceduresPageTableWrapper>
  );
}

ManageProceduresPageTable.propTypes = {
  loading: bool,
  procedures: arrayOf(shape({})),
  getAlreadyUsedNames: func,
  tableSortingProps: shape({}),
};
