import { gql, useMutation } from '@apollo/client';

import { useModalContext } from '../../../components/Modal/ModalProvider.js';
import { useNotificationState } from '../../../hooks/useNotificationState.js';

const RESEND_ENROLLMENT_EMAIL_MUTATION = gql`
  mutation resendUserEnrollmentEmail($input: ResendEnrollmentEmailInput!) {
    resendUserEnrollmentEmail(input: $input) {
      sent
    }
  }
`;

export const useResentEnrollmentEmail = () => {
  const [resendEnrollmentEmail] = useMutation(RESEND_ENROLLMENT_EMAIL_MUTATION);
  const { openConfirmModal } = useModalContext();
  const { showSuccessNotification, showErrorNotification } =
    useNotificationState();

  return (userId) =>
    openConfirmModal({
      btnCaption: 'Yes',
      cancelBtnCaption: 'No',
      title: 'Send Enrollment Email',
      id: 'confirm_resent_enrollment_email_modal_id',
      content:
        'User is not signed up yet. Do you want to send Email Invitation?',
      onConfirm: (closeConfirmModal) => async () => {
        try {
          await resendEnrollmentEmail({
            variables: { input: { userId } },
          });
          showSuccessNotification('Enrollment Email Was Sent');
        } catch {
          showErrorNotification('Failed To Send Enrollment Email');
        }
        closeConfirmModal();
      },
    });
};
