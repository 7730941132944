import React from 'react';
import * as R from 'ramda';
import { bool, shape, string } from 'prop-types';
import { formatSelectOptionsWithTitleCased } from '@poly/client-utils';
import { SupplierSources, supplierTypes } from '@poly/constants';
import { Select } from '@poly/admin-book';
import { pathEqLegacy, propEqLegacy } from '@poly/utils';

// supplierTypeOptions :: Object -> [Option]
const supplierTypeOptions = formatSelectOptionsWithTitleCased(supplierTypes);

// supplierTypeOptionsTIAA :: [Option] -> [Option]
const supplierTypeOptionsTIAA = R.reject(
  propEqLegacy('value', supplierTypes.SUBCONTRACTOR),
  supplierTypeOptions,
);

// supplierTypeOptionsTIAA :: Object -> [Option]
const supplierTypeOptionsBySource = R.ifElse(
  pathEqLegacy(['misc', 'source'], SupplierSources.TIAA),
  R.always(supplierTypeOptionsTIAA),
  R.always(supplierTypeOptions),
);

// isSupplierTypeSelectEditable :: Supplier -> Boolean
const isSupplierTypeSelectEditable = propEqLegacy(
  'type',
  supplierTypes.SUBCONTRACTOR,
);

export function SupplierTypeSelect({
  formData,
  supplier,
  isSupplierTypeEditable,
  ...props
}) {
  const disabled =
    formData.disableTypeInput ||
    !isSupplierTypeEditable ||
    isSupplierTypeSelectEditable(supplier);

  const options = supplierTypeOptionsBySource(formData);

  return (
    <Select
      {...props}
      name="SupplierStatusSelect"
      disabled={disabled}
      options={options}
    />
  );
}

SupplierTypeSelect.displayName = 'SupplierTypeSelect';

SupplierTypeSelect.propTypes = {
  formData: shape({ disableTypeInput: bool }),
  supplier: shape({ type: string }),
  isSupplierTypeEditable: bool,
};
