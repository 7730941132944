import React, { useEffect } from 'react';
import { bool, string, shape } from 'prop-types';
import { useOutSidebarContext } from '@poly/client-utils';
import { ProjectType } from '@poly/constants';
import { Loader } from '@poly/admin-book';
import { pathEqLegacy } from '@poly/utils';
import {
  SidebarWrapperForTableForm,
  SidebarWrapper,
  entities,
} from '@poly/admin-ui';

import { projectSidebarTabsId } from './constants.js';
import { ProjectSidebarTasks } from './ProjectSidebarTasks.js';
import { ProjectSidebarHeader } from './ProjectSidebarHeader.js';
import { ProjectSidebarDetails } from './ProjectSidebarDetails.js';
import { NotFoundEntity } from '../../components/NotFoundEntity.js';
import { ProjectSidebarSuppliers } from './ProjectSidebarSuppliers.js';
import { ProjectSidebarFinancial } from './ProjectSidebarFinancial.js';
import { CloneProjectButton } from './components/CloneProjectButton.js';
import { ProjectSidebarContactInfo } from './ProjectSidebarContactInfo.js';
import { ProjectSidebarWeeklyTickets } from './ProjectSidebarWeeklyTickets.js';
import { ConvertRegularProjectButton } from './components/ConvertProjectButton.js';
import { prepareProjectHistoryRecordByEntity } from '../components/commonSidebarUtils.js';
import { useReactiveSidebarDocumentTitle } from '../useReactiveSidebarDocumentTitle.js';
import { useOpenProjectTabsSidebar } from './tabs/useOpenProjectTabsSidebar.js';
import { useSidebarLogicContext } from '../SidebarLogicContext.js';
import { useProjectSidebarData } from './useProjectSidebarData.js';

// we want to separate this logic from other sidebar parts
// to avoid conflict with pristine logic work for forms
function ProjectSidebarRouterLogic(props) {
  const { isSidebarOpened } = useOutSidebarContext();
  const { setHistoryRecord } = useSidebarLogicContext();
  const openSidebarTabs = useOpenProjectTabsSidebar(props);

  const historyRecord = prepareProjectHistoryRecordByEntity(entities.PROJECT)(
    props.project,
  );

  useEffect(() => {
    if (!isSidebarOpened(projectSidebarTabsId)) {
      openSidebarTabs();
      setHistoryRecord(historyRecord);
    }
  }, []);

  useReactiveSidebarDocumentTitle(props.project.projectId, true);

  return null;
}

ProjectSidebarRouterLogic.propTypes = {
  project: shape({
    projectId: string,
  }),
};

// isFeeProject :: ProjectQueryResult -> Boolean
const isFeeProject = pathEqLegacy(['project', 'type'], ProjectType.FEE);

export function ProjectSidebarSections(props) {
  return (
    <>
      <ProjectSidebarHeader {...props} />
      <ProjectSidebarDetails {...props} />
      <ProjectSidebarContactInfo {...props} />
      {!isFeeProject(props) && <ProjectSidebarSuppliers {...props} />}
      <ProjectSidebarTasks {...props} />
      <ProjectSidebarWeeklyTickets {...props} />
      <ProjectSidebarFinancial {...props} />
      <CloneProjectButton {...props} />
      {props.project && !props.project.parent && (
        <ConvertRegularProjectButton {...props} />
      )}
    </>
  );
}

ProjectSidebarSections.propTypes = {
  project: shape({ parent: shape({ _id: string }) }).isRequired,
};

export function ProjectSidebar({ projectLinkId, openFull }) {
  const { loading, ...sidebarProps } = useProjectSidebarData(projectLinkId);

  if (loading) {
    return <Loader />;
  }

  return (
    <SidebarWrapper>
      {!sidebarProps.project ? (
        <NotFoundEntity entityName="project" />
      ) : (
        <SidebarWrapperForTableForm>
          <ProjectSidebarSections {...sidebarProps} />
          <ProjectSidebarRouterLogic {...sidebarProps} openFull={openFull} />
        </SidebarWrapperForTableForm>
      )}
    </SidebarWrapper>
  );
}

ProjectSidebar.displayName = 'ProjectSidebar';
ProjectSidebar.propTypes = {
  openFull: bool,
  projectLinkId: string.isRequired,
};
