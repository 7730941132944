import * as R from 'ramda';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { hasUserAccessWithAi, createAccessItem } from '@poly/security';
import { isNilOrEmpty } from '@poly/utils';

import { useCurrentUserByStoreOrQuery } from './useCurrentUserByStoreOrQuery.js';
import { CURRENT_USER_QUERY } from './useCurrentUserQuery.js';

// prepareAccessItemsForUserByTypes :: AccessItemTypes -> User -> User
export const prepareAccessItemsForUserByTypes = (types) =>
  R.over(
    R.lensProp('accessItems'),
    R.compose(
      R.map(
        R.over(
          R.lensProp('types'),
          R.ifElse(
            () => !!types,
            R.compose(
              R.dissoc('__typename'),
              R.reject(R.isEmpty),
              R.map(R.reject(R.isNil)),
              R.reject(R.isNil),
              R.defaultTo({}),
            ),
            R.always(null),
          ),
        ),
      ),
      R.defaultTo([]),
    ),
  );

// mapUserAccessByPermissionsAndAits :: (AccessItemTypes, User, [String]) -> Boolean
const mapUserAccessByPermissionsAndAits = R.curry((types, user, permissions) =>
  R.map((permission) =>
    hasUserAccessWithAi(createAccessItem(permission, types))(user),
  )(permissions),
);

// hasUserPermissionsWithAits :: (User, [String], AccessItemTypes, PermissionsProps) -> Boolean
// PermissionsProps = { isAll: Boolean }
// eslint-disable-next-line import/no-unused-modules
export const hasUserPermissionsWithAits = (
  user,
  permissions = [],
  types = null,
  { isAll = false } = {},
) =>
  R.compose(
    R.ifElse(
      R.isEmpty,
      R.T,
      R.ifElse(
        R.compose(isNilOrEmpty, R.always(user)),
        R.F,
        R.compose(
          R.ifElse(
            R.always(isAll),
            R.all(R.equals(true)),
            R.any(R.equals(true)),
          ),
          R.converge(mapUserAccessByPermissionsAndAits(types), [
            R.compose(prepareAccessItemsForUserByTypes(types), R.always(user)),
            R.identity,
          ]),
        ),
      ),
    ),
    R.reject(R.isNil),
  )(permissions);

// useHasUserAccessWithPermissionBase :: (String, AccessItemTypes) -> [Boolean, Boolean]
export const useHasUserAccessWithPermissionBase = (permission, types) => {
  const { user, loading } = useCurrentUserByStoreOrQuery(CURRENT_USER_QUERY, {
    skip: !permission,
  });

  const checkResult = hasUserPermissionsWithAits(user, [permission], types);

  return [checkResult, loading];
};

// useHasUserAccessWithPermission :: (String, AccessItemTypes) -> Boolean
export const useHasUserAccessWithPermission = (permission, types) => {
  const [checkResult] = useHasUserAccessWithPermissionBase(permission, types);
  return checkResult;
};

// useHasUserAccessWithAnyOfPermissions :: [Permission] -> Bool
export const useHasUserAccessWithAnyOfPermissions = (permissions = []) => {
  const { user } = useCurrentUserByStoreOrQuery(CURRENT_USER_QUERY, {
    skip: !permissions.length,
  });

  return hasUserPermissionsWithAits(user, permissions);
};

// useRedirectIfHasNoPermissions :: ([String], Boolean) -> _
export const useRedirectIfHasNoPermissions = (permissions, isAll = true) => {
  const { user, loading } = useCurrentUserByStoreOrQuery(CURRENT_USER_QUERY, {
    skip: !permissions.length,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!loading) {
      const checkResult = hasUserPermissionsWithAits(user, permissions, null, {
        isAll,
      });

      if (checkResult === false) {
        navigate('/');
      }
    }
  }, [loading]);
};
