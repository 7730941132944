import { useQuery } from '@apollo/client';
import { ACCOUNT_INDEX } from './queries.js';

export const useAccountIndex = () => {
  const { data, loading } = useQuery(ACCOUNT_INDEX, {
    fetchPolicy: 'network-only',
  });

  return {
    data,
    loading,
  };
};
