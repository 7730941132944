import { gql } from '@apollo/client';

export const CREATE_TASK_MUTATION = gql`
  mutation CREATE_TASK_MUTATION($input: TaskCreateInput!) {
    createTask(input: $input) {
      task {
        _id
      }
    }
  }
`;

export const POST_UPDATE_WITH_ACTION_MUTATION = gql`
  mutation POST_UPDATE_WITH_ACTION($input: CreateUpdateWithActionInput!) {
    createUpdateWithAction(input: $input) {
      update {
        _id
        type
        message
      }
    }
  }
`;

export const UPDATE_TASK_MUTATION = gql`
  mutation UPDATE_TASK_MUTATION($id: ID!, $input: TaskUpdateInput!) {
    updateTask(id: $id, update: $input) {
      task {
        _id
        complete
      }
    }
  }
`;
