import React from 'react';
import { ALL } from '@poly/admin-ui';
import { Select } from '@poly/admin-book';
import {
  ClientInvoiceStatuses,
  NOT_INVOICED_CLIENT_INVOICE_STATUS,
} from '@poly/constants';

const clientInvoiceStatusSelectOptions = [
  { value: ALL, label: 'Any Status' },
  { value: NOT_INVOICED_CLIENT_INVOICE_STATUS, label: 'Not Invoiced' },
  { value: ClientInvoiceStatuses.PENDING, label: 'Invoiced' },
  { value: ClientInvoiceStatuses.PAID, label: 'Paid' },
];

export function ClientInvoiceStatusSelect(props) {
  const selectProps = {
    ...props,
    options: clientInvoiceStatusSelectOptions,
  };

  return <Select {...selectProps} />;
}
