import styled from 'styled-components';

import { getThemeFont } from '../utils.js';

const mapHeadingToFontSize = {
  h1: 28,
  h2: 25,
  h3: 21,
  h4: 16,
  h5: 14,
  h6: 12,
};

export const Heading = styled.div.attrs({ role: 'heading' })`
  margin: 0;
  padding: 0;
  letter-spacing: 1px;
  font-weight: ${getThemeFont(['regular'])};
  font-size: ${({ size }) => mapHeadingToFontSize[size]}px;
`;

Heading.displayName = 'Heading';
