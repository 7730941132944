import * as R from 'ramda';
import styled from 'styled-components';
import React, { useEffect, useRef } from 'react';
import { arrayOf, element, oneOfType } from 'prop-types';
import {
  getTemporalUserToken,
  setTemporalUserToken,
} from '@poly/apollo-client';
import { BODY_PORTAL_ANCHOR_ID } from '@poly/constants';
import { useLocation, useNavigate } from '@poly/client-routing';
import { getThemeColor } from '@poly/admin-book';
import {
  GLOBAL_SEARCH_SIDEBAR_ID,
  useModalContext,
  ModalProvider,
} from '@poly/admin-ui';
import {
  useOutSidebarContext,
  OutSidebarProvider,
  useIsTokenValid,
} from '@poly/client-utils';

import {
  ModalWindow,
  ConfirmModalWindow,
} from '../modules/modals/ModalWindow.js';
import { LayoutContainer } from './styles.js';
import { routesNames } from '../routes/index.js';
import { SidebarIDs } from '../sidebars/constants.js';
import { AppBar } from '../components/appBar/AppBar.js';
import { GeneralSidebarLayout } from '../components/SidebarLayouts.js';
import { SidebarInnerContext } from '../sidebars/SidebarInnerContext.js';
import { useRegisterCurrentUserSharedWorker } from './useRegisterCurrentUserSharedWorker.js';
import { useUserAuthBroadcastChannel } from '../hooks/useBroadcastChannel.js';
import { useOpenSidebarsByURL } from './useOpenSidebarsByURL.js';
import { useOnOutsideClick } from './useOnOutsideClick.js';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${getThemeColor(['white'])};
  height: calc(100% - 60px);

  @media (max-width: 1279px) {
    height: calc(100% - 120px);
  }
`;

const parseBase64 = (base64String) => atob(base64String);
const parseJson = (json) => JSON.parse(json);

// userWithTemporalAccess :: String -> Boolean
const userWithTemporalAccess = R.compose(
  R.ifElse(
    R.isNil,
    R.always(false),
    R.compose(
      R.propSatisfies(R.complement(R.isNil), 'temporalAccessId'),
      parseJson,
      parseBase64,
      R.nth(1),
      R.split('.'),
    ),
  ),
  getTemporalUserToken,
);

function LogoutTemporalAccessUser() {
  const navigate = useNavigate();

  if (userWithTemporalAccess()) {
    setTemporalUserToken(null);
    navigate(routesNames.LOGIN);
  }

  return <div />;
}

// eslint-disable-next-line import/no-unused-modules
export function LayoutRouterLogic() {
  const navigate = useNavigate();
  const previousPath = useRef(null);
  const { pathname, search } = useLocation();
  const { closeAllOutSidebars, isSidebarOpened } = useOutSidebarContext();
  const { onLoggedOutListener } = useUserAuthBroadcastChannel();

  const { isTokenValid } = useIsTokenValid();

  const loginRoute = `${routesNames.LOGIN}?previousUrl=${pathname}${search}`;

  onLoggedOutListener(() => navigate(loginRoute));

  useEffect(() => {
    previousPath.current = pathname;

    if (!isTokenValid) {
      navigate(loginRoute);
    }
  }, [isTokenValid]);

  useEffect(() => {
    if (
      pathname !== previousPath.current &&
      !isSidebarOpened(GLOBAL_SEARCH_SIDEBAR_ID)
    ) {
      closeAllOutSidebars(true);
    }
  }, [pathname]);

  useOpenSidebarsByURL(isTokenValid);

  useRegisterCurrentUserSharedWorker();

  return null;
}

function ContentProvider({ children }) {
  const { activeModals } = useModalContext();

  const onOutsideClick = useOnOutsideClick();

  return (
    <OutSidebarProvider
      disabledBackground
      Layout={GeneralSidebarLayout}
      onOutsideClick={onOutsideClick}
      InnerContext={SidebarInnerContext}
      disabled={!R.isEmpty(activeModals)}
      disableSidebarIDs={[SidebarIDs.contact]}
    >
      <AppBar />
      <ContentWrapper>{children}</ContentWrapper>
      <ModalWindow />
      <ConfirmModalWindow />
      <LogoutTemporalAccessUser />
      <LayoutRouterLogic />
      <div id={BODY_PORTAL_ANCHOR_ID} />
    </OutSidebarProvider>
  );
}

ContentProvider.propTypes = {
  children: oneOfType([element, arrayOf(element)]).isRequired,
};

export function MainLayout({ children }) {
  return (
    <LayoutContainer>
      <ModalProvider>
        <ContentProvider>{children}</ContentProvider>
      </ModalProvider>
    </LayoutContainer>
  );
}

MainLayout.propTypes = {
  children: oneOfType([element, arrayOf(element)]).isRequired,
};
