import * as R from 'ramda';
import React, { useState } from 'react';
import { string, arrayOf, func, oneOfType, array } from 'prop-types';
import { FormField } from '@poly/admin-book';
import { propEqLegacy } from '@poly/utils';

import { ItemsSelectComp } from './ItemsSelectComp.js';

function ItemsSelectEnhanced({
  onBlur: triggerValidation,
  onChange,
  beforeRemoveItem,
  options = [],
  value = [],
  ...restProps
}) {
  const [itemToAdd, setItemToAdd] = useState('');

  const onAddNewItem = () => {
    if (itemToAdd) {
      onChange([itemToAdd, ...value]);
      setItemToAdd('');
      triggerValidation();
    }
  };

  const onRemoveItem = (item) => {
    const handleRemove = () => {
      onChange(value.filter((i) => i !== item));
      triggerValidation();
    };
    if (typeof beforeRemoveItem === 'function') {
      beforeRemoveItem(item, handleRemove);
    } else {
      handleRemove();
    }
  };

  const optionLabelByKey = (item) =>
    R.compose(
      R.defaultTo(''),
      R.last,
      R.defaultTo([]),
      R.find(propEqLegacy(0, item)),
    )(options);

  const availableOptions = R.compose(
    R.map(R.applySpec({ value: R.nth(0), label: R.nth(1) })),
    R.reject(R.__, options),
    R.propSatisfies(R.__, 0),
    R.flip(R.includes),
    R.when(R.complement(R.identity), R.always([])),
  )(value);

  return (
    <ItemsSelectComp
      {...restProps}
      itemToAdd={itemToAdd}
      onItemToAddChange={setItemToAdd}
      onAddNewItem={onAddNewItem}
      onRemoveItem={onRemoveItem}
      optionLabelByKey={optionLabelByKey}
      availableOptions={availableOptions}
      value={value || []}
    />
  );
}

ItemsSelectEnhanced.displayNme = 'ItemsSelectEnhanced';

ItemsSelectEnhanced.propTypes = {
  name: string.isRequired,
  options: arrayOf(arrayOf(string)).isRequired,
  onBlur: func.isRequired,
  onChange: func.isRequired,
  beforeRemoveItem: func,
  value: oneOfType([array, string]),
};

export function ItemsSelect(props) {
  return <FormField {...props} Component={ItemsSelectEnhanced} />;
}
