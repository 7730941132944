import * as R from 'ramda';
import { gql } from '@apollo/client';
import styled from 'styled-components';
import React, { useState, useCallback, useMemo } from 'react';
import { string, shape, func, arrayOf, bool } from 'prop-types';
import { MultiSelectDropDown, MAX_ITEMS, entities } from '@poly/admin-ui';
import { SelectComponents, Popover, Icon } from '@poly/admin-book';
import { debounce, isNilOrEmpty, propEqLegacy } from '@poly/utils';
import {
  DEBOUNCE_USER_INPUT_THRESHOLD,
  useReactiveQuery,
} from '@poly/client-utils';
import {
  ELASTIC_SCORE_FIELD,
  DESC_SORT_ORDER,
  AssetStatuses,
} from '@poly/constants';

import {
  PROJECT_ASSETS_SUB,
  RECURRING_PROJECT_ASSETS_SUB,
} from './commonTabs/useSearchAssetsTabQuery.js';
import { ALL } from '../../modules/core/constants/general.js';
import { ASSETS_BY_SEARCH_SUB } from '../../components/AssetSelect.js';

const subscriptionQueryMap = {
  [entities.RECURRING_PROJECT]: RECURRING_PROJECT_ASSETS_SUB,
  [entities.PROJECT]: PROJECT_ASSETS_SUB,
};

export const AllAssetsOption = { value: ALL, label: 'All' };

const SEARCH_ASSETS_LIST = gql`
  query SEARCH_ASSETS_LIST($input: CollectionSearchParams!) {
    searchAssets(input: $input) {
      hits {
        _id
        type {
          _id
          name
        }
        displayName
        qrCodeId
        manufacturerDoc {
          _id
          name
        }
      }
      total
    }
  }
`;

// getAssetsByQueryWithoutAttached :: [ID] -> SearchAssetsResult -> [Asset]
const getAssetsByQueryWithoutAttached = (attachedAssets) =>
  R.compose(
    R.reject(R.propSatisfies(R.includes(R.__, attachedAssets), '_id')),
    R.pathOr([], ['searchAssets', 'hits']),
  );

// prepareAssetsOptions :: [ID] -> SearchAssetsResult -> [Option]
const prepareAssetsOptions = (attachedAssets) =>
  R.compose(
    R.unless(R.isEmpty, R.prepend(AllAssetsOption)),
    R.map(
      R.applySpec({
        value: R.prop('_id'),
        label: R.prop('displayName'),
      }),
    ),
    getAssetsByQueryWithoutAttached(attachedAssets),
  );

// getAssetQueryByProperties :: FormData -> ElasticQuery
const getAssetQueryByProperties = R.compose(
  R.unless(R.isEmpty, R.compose(R.objOf('terms'), R.objOf('propertyId'))),
  R.reject(isNilOrEmpty),
  R.unnest,
  R.juxt([R.prop('propertyId'), R.propOr([], 'subPropertiesIds')]),
);

// getAssetQueryByType :: FormData -> ElasticQuery
const getAssetQueryByType = R.compose(
  R.ifElse(
    propEqLegacy('length', 1),
    R.always(null),
    R.compose(R.objOf('bool'), R.objOf('should')),
  ),
  R.reject(isNilOrEmpty),
  R.juxt([
    R.compose(
      R.unless(isNilOrEmpty, R.compose(R.objOf('terms'), R.objOf('type._id'))),
      R.prop('types'),
    ),
    R.always({ bool: { must_not: { exists: { field: 'type' } } } }),
  ]),
);

// prepareSearchAssetsQuery :: FormData -> ElasticQuery
const prepareSearchAssetsQuery = R.compose(
  R.objOf('bool'),
  R.objOf('must'),
  R.reject(isNilOrEmpty),
  R.juxt([
    R.always({ match: { status: AssetStatuses.ACTIVE } }),
    getAssetQueryByProperties,
    getAssetQueryByType,
  ]),
  R.defaultTo({}),
);

// checkIfAllOptionSelected :: [Option] -> Boolean
const checkIfAllOptionSelected = R.compose(
  R.includes(ALL),
  R.map(R.prop('value')),
  R.defaultTo([]),
);

// checkIfAllOptionRemoved :: { newValue: [Option], oldValue: [Option] } -> Boolean
const checkIfAllOptionRemoved = R.both(
  R.propSatisfies(R.isEmpty, 'newValue'),
  R.propSatisfies(
    R.compose(R.includes(ALL), R.map(R.prop('value'))),
    'oldValue',
  ),
);

// getAllAssetsIdsByQuery :: SearchAssetsResult -> [ID]
const getAllAssetsIdsByQuery = R.compose(
  R.map(R.prop('_id')),
  getAssetsByQueryWithoutAttached([]),
);

// getExecutedProcedureAssetsOptions :: [ID] -> SearchAssetsResult -> [Option]
const getExecutedProcedureAssetsOptions = (executedProcedureAssets) =>
  R.compose(
    R.map(
      R.applySpec({
        value: R.prop('_id'),
        label: R.prop('displayName'),
      }),
    ),
    R.filter(
      R.propSatisfies(
        R.includes(R.__, R.defaultTo([], executedProcedureAssets)),
        '_id',
      ),
    ),
    R.pathOr([], ['searchAssets', 'hits']),
  );

// checkIfAssetForExecutedProcedure :: MultiValueRemoveProps -> Boolean
const checkIfAssetForExecutedProcedure = R.converge(R.includes, [
  R.path(['data', 'value']),
  R.propOr([], 'executedProcedureAssets'),
]);

const IconS = styled(Icon)`
  margin: 0 4px;
`;

const PopoverContentS = styled.div`
  height: auto;
  padding: 10px;
  color: #fff;
  font-size: 12px;
  max-width: 150px;
  border-radius: 5px;
  background-color: #12347a;
`;

function ExecutedProcedureAssetWarningIcon() {
  return <IconS name="confirm" size={12} color="#61646C" />;
}

function ExecutedProcedureAssetWarning() {
  const warningMessage =
    'You cannot unlink the asset because the procedure has been completed';

  return (
    <Popover
      position="left"
      withPortalAnchor
      bgColor="#12347a"
      Icon={ExecutedProcedureAssetWarningIcon}
      content={<PopoverContentS>{warningMessage}</PopoverContentS>}
    />
  );
}

// getCustomMultiValueRemove :: [ID] -> MultiValueRemoveProps -> ReactComponent
const getCustomMultiValueRemove = (executedProcedureAssets) =>
  function MultiValueRemove(props) {
    const isAssetForExecutedProcedure = checkIfAssetForExecutedProcedure({
      ...props,
      executedProcedureAssets,
    });

    if (isAssetForExecutedProcedure) {
      return <ExecutedProcedureAssetWarning />;
    }

    return <SelectComponents.MultiValueRemove {...props} />;
  };

export function MultiAssetsSelect({
  value,
  entity,
  onChange,
  formData,
  disabled,
  changeFieldValue,
  ...props
}) {
  const [searchTerm, setSearchTerm] = useState('');

  const { _id, name } = entity;
  const { propertyId, attachedAssets, executedProcedureAssets } = formData;

  const query = useMemo(() => prepareSearchAssetsQuery(formData), [formData]);

  const queryOptions = {
    variables: {
      input: {
        query,
        searchTerm,
        size: MAX_ITEMS,
        sort: [{ createdAt: DESC_SORT_ORDER }, ELASTIC_SCORE_FIELD],
      },
    },
    skip: !propertyId || !!disabled,
  };

  const projectSubQuery = subscriptionQueryMap[name] || PROJECT_ASSETS_SUB;

  const projectSubOptions = {
    variables: { input: { query: { match: { _id } } } },
    skip: !_id,
  };

  const { data, loading } = useReactiveQuery(
    SEARCH_ASSETS_LIST,
    [ASSETS_BY_SEARCH_SUB, projectSubQuery],
    {
      queryOptions,
      subscriptionOptions: [queryOptions, projectSubOptions],
    },
  );

  const options = useMemo(
    () => prepareAssetsOptions(attachedAssets)(data),
    [data, attachedAssets],
  );

  const preparedAllAssetsIds = useMemo(
    () => getAllAssetsIdsByQuery(data),
    [data],
  );

  const preparedExecutedProcedureAssetsOptions = useMemo(
    () => getExecutedProcedureAssetsOptions(executedProcedureAssets)(data),
    [executedProcedureAssets, data],
  );

  const onSearchDebounced = useCallback(
    debounce(DEBOUNCE_USER_INPUT_THRESHOLD)(setSearchTerm),
    [],
  );

  const onInputChange = (input) => onSearchDebounced(input.trim());

  const handleChange = (val) => {
    const isAllOptionSelected = checkIfAllOptionSelected(val);
    const isAllOptionRemoved = checkIfAllOptionRemoved({
      newValue: val,
      oldValue: value,
    });

    const preparedChangeValue = isAllOptionRemoved
      ? preparedExecutedProcedureAssetsOptions
      : val;

    onChange(isAllOptionSelected ? [AllAssetsOption] : preparedChangeValue);

    if (isAllOptionSelected && R.is(Function, changeFieldValue)) {
      changeFieldValue('allAssetsIds', preparedAllAssetsIds);
    }
  };

  const selectProps = {
    ...props,
    value,
    loading,
    options,
    handleChange,
    onInputChange,
    required: true,
    maxMenuHeight: 250,
    isDisabled: disabled,
    onSearchChange: setSearchTerm,
    placeholder: 'Start typing assets',
    components: {
      MultiValueRemove: getCustomMultiValueRemove(executedProcedureAssets),
    },
  };

  return <MultiSelectDropDown {...selectProps} />;
}

MultiAssetsSelect.propTypes = {
  disabled: bool,
  changeFieldValue: func,
  onChange: func.isRequired,
  entity: shape({ _id: string, name: string }),
  value: arrayOf(shape({ value: string, label: string })),
  formData: shape({
    propertyId: string,
    types: arrayOf(string),
    allAssetsIds: arrayOf(string),
    executedProcedureAssets: arrayOf(string),
    attachedAssets: arrayOf(string).isRequired,
  }).isRequired,
};
