import * as R from 'ramda';
import React, { memo } from 'react';
import styled from 'styled-components';
import { string, shape, bool, number } from 'prop-types';
import {
  propEqLegacy,
  convertCentsToDollars,
  formatTotal,
  isNilOrEmpty,
} from '@poly/utils';
import { pathOrNothingUI, useOutSidebarContext } from '@poly/client-utils';
import { useUpdateQueryParams } from '@poly/client-routing';
import { LinkButton, Text } from '@poly/admin-book';
import { SidebarRow } from '@poly/admin-ui';
import {
  NOTHING_UI_STRING,
  WorkOrderStatus,
  ProjectType,
} from '@poly/constants';

import {
  SectionLabel,
  BlockWithLabel,
  threeBlocksProps,
  threeBlocksWrapperProps,
  SectionWrapperWithoutBorder,
} from '../components/commonSidebarComponents.js';
import { projectSidebarTabs } from '../../routes/constants.js';
import { AddTimeToProjectButton } from './components/AddTimeToProjectButton.js';
import { ProjectClientInvoicesBlock } from './components/projectSidebarComponents.js';
import { ProjectEstimateSidebarForm } from './ProjectEstimate/ProjectEstimateSidebarForm.js';
import { projectEstimateFormId } from './ProjectEstimate/ProjectEstimateForm/ProjectEstimateForm.js';
import { useOpenProjectTabsSidebar } from './tabs/useOpenProjectTabsSidebar.js';
import {
  getSectionDivision,
  getSectionEditor,
  getSectionText,
} from '../components/commonSidebarSectionFormatters.js';
import {
  projectAccountingStatusToString,
  getAdditionalDescriptionInfo,
  prepareDescriptionForSection,
  formatInvoicesAmount,
} from './projectSidebarUtils.js';

const LinkButtonS = styled(LinkButton)`
  text-align: start;
`;

const NonBillableBlockS = styled(BlockWithLabel)`
  width: auto;
  flex-shrink: 0;
`;

const ButtonsWrapperS = styled.div`
  display: flex;
  flex-direction: row;

  > button {
    margin-left: 20px;
  }
`;

function InvoicesLink({ supplierInvoicesAmount, ...props }) {
  const updateQueryParams = useUpdateQueryParams();
  const openSidebarTabs = useOpenProjectTabsSidebar(props);

  const onLinkClick = () => {
    updateQueryParams({ sidebarTab: projectSidebarTabs.projectInvoices });
    openSidebarTabs();
  };

  return (
    <LinkButtonS onClick={onLinkClick}>{supplierInvoicesAmount}</LinkButtonS>
  );
}

InvoicesLink.propTypes = { supplierInvoicesAmount: string };

// isProjectClientGlCodeEnabled :: Project -> Boolean
export const isProjectClientGlCodeEnabled = R.path(['client', 'enableGlCodes']);

// getProjectClientGLCode :: Project -> ClientGLCode
export const getProjectClientGLCode = R.propOr({}, 'clientGLCode');

// getProjectSidebarFinancialInfo :: Project -> Object
const getProjectSidebarFinancialInfo = R.applySpec({
  _id: R.prop('_id'),
  isFee: propEqLegacy('type', ProjectType.FEE),
  financialCoding: pathOrNothingUI(['property', 'financialCoding']),
  propertyFields: R.pathOr({}, ['client', 'configs', 'propertyFields']),
  invoiceDescription: R.converge(prepareDescriptionForSection, [
    pathOrNothingUI(['invoiceDescription']),
    getAdditionalDescriptionInfo,
  ]),
  isClientGlCodesEnable: isProjectClientGlCodeEnabled,
  clientGlCode: getProjectClientGLCode,
  costCenter: R.prop('costCenter'),
  enableCostCenter: R.path(['client', 'enableCostCenter']),
  nonBillable: R.prop('nonBillable'),
  nonBillableReason: R.prop('nonBillableReason'),
  withSubProperties: R.compose(
    R.complement(isNilOrEmpty),
    R.prop('subProperties'),
  ),
  estimates: R.pathOr([], ['estimates']),
});

export function ClientGLCode({ glCode }) {
  if (isNilOrEmpty(glCode)) {
    return <Text>{NOTHING_UI_STRING}</Text>;
  }

  const { code, name } = glCode;

  return (
    <Text size="12px">
      {code} - {name}
    </Text>
  );
}

ClientGLCode.propTypes = {
  glCode: shape({
    _id: string,
    code: string,
    name: string,
  }),
};

function getClientGLCodeComp(clientGlCode) {
  return memo(() => <ClientGLCode glCode={clientGlCode} />);
}

const AmountContainer = styled.div`
  font-size: 12px;
`;

function InvoiceAmount({ supplierInvoicesAmount, disableTabs, ...props }) {
  if (disableTabs) {
    return <AmountContainer>{supplierInvoicesAmount}</AmountContainer>;
  }
  return (
    <InvoicesLink supplierInvoicesAmount={supplierInvoicesAmount} {...props} />
  );
}

InvoiceAmount.propTypes = {
  supplierInvoicesAmount: string,
  disableTabs: bool,
};

// getProjectEstimateTotal :: Number -> Number
const getProjectEstimateTotal = R.compose(formatTotal, convertCentsToDollars);

const ProjectEstimateAmount = styled(LinkButton)`
  width: fit-content;
`;

// isProjectCompletedOrBlocked :: Project -> Boolean
const isProjectCompletedOrBlocked = R.propSatisfies(
  R.includes(R.__, [WorkOrderStatus.COMPLETED, WorkOrderStatus.BLOCKED]),
  'status',
);
function ProjectEstimateLink({
  total,
  projectId,
  estimateId,
  estimateNumber,
  isEstimateDisabled,
}) {
  const { openOutSidebar } = useOutSidebarContext();

  const estimateTotal = getProjectEstimateTotal(total);

  const onClick = () => {
    openOutSidebar({
      width: 650,
      id: projectEstimateFormId,
      content: (
        <ProjectEstimateSidebarForm
          projectId={projectId}
          estimateId={estimateId}
          estimateNumber={estimateNumber}
        />
      ),
    });
  };

  return (
    <ProjectEstimateAmount disabled={isEstimateDisabled} onClick={onClick}>
      {estimateTotal}
    </ProjectEstimateAmount>
  );
}

ProjectEstimateLink.propTypes = {
  estimateNumber: number,
  total: number.isRequired,
  isEstimateDisabled: bool,
  projectId: string.isRequired,
  estimateId: string.isRequired,
};

function AddClientEstimateButton({ _id, estimateNumber }) {
  const { openOutSidebar } = useOutSidebarContext();

  const onClick = () => {
    openOutSidebar({
      width: 650,
      id: projectEstimateFormId,
      content: (
        <ProjectEstimateSidebarForm
          projectId={_id}
          estimateNumber={estimateNumber}
        />
      ),
    });
  };

  return <LinkButton onClick={onClick}>Add Client Estimate</LinkButton>;
}

AddClientEstimateButton.propTypes = {
  _id: string.isRequired,
  estimateNumber: number,
};

export function ProjectSidebarFinancial({ project, disableTabs, isCard }) {
  const {
    isFee,
    estimates,
    costCenter,
    nonBillable,
    clientGlCode,
    propertyFields,
    financialCoding,
    enableCostCenter,
    withSubProperties,
    nonBillableReason,
    invoiceDescription,
    isClientGlCodesEnable,
  } = getProjectSidebarFinancialInfo(project);

  const isEstimateDisabled = isProjectCompletedOrBlocked(project);

  const estimateNumberOnCreate =
    estimates.length > 0 ? estimates.length + 1 : null;

  return (
    <SectionWrapperWithoutBorder>
      <SidebarRow justify>
        <SectionLabel>Financial</SectionLabel>
        {!isFee && (
          <ButtonsWrapperS>
            <AddTimeToProjectButton _id={project._id} />
            {!isEstimateDisabled && (
              <AddClientEstimateButton
                _id={project._id}
                estimateNumber={estimateNumberOnCreate}
              />
            )}
          </ButtonsWrapperS>
        )}
      </SidebarRow>
      <SidebarRow>
        <BlockWithLabel
          margin="0"
          id={`invoice-description${isCard ? '-card' : ''}`}
          label="Invoice Description"
          Component={getSectionEditor(invoiceDescription)}
        />
      </SidebarRow>
      <SidebarRow {...threeBlocksWrapperProps}>
        <BlockWithLabel
          {...threeBlocksProps}
          id="project-division"
          label="Division"
          Component={getSectionDivision(project)}
        />
        <BlockWithLabel
          {...threeBlocksProps}
          id="invoicing-status"
          label="Invoicing Status"
          Component={getSectionText(projectAccountingStatusToString(project))}
        />
        {!!propertyFields.financialCoding && (
          <BlockWithLabel
            margin="0"
            id="financial-coding"
            label="Coding"
            Component={getSectionText(financialCoding)}
          />
        )}
        <BlockWithLabel
          id={isFee ? 'fee-amount' : 'job-cost-report'}
          label={isFee ? 'Fee Amount' : 'Job Cost Report'}
          {...threeBlocksProps}
          project={project}
          supplierInvoicesAmount={formatInvoicesAmount(project)}
          disableTabs={disableTabs}
          Component={
            isFee
              ? getSectionText(formatTotal(project.feeAmount))
              : InvoiceAmount
          }
        />
        <BlockWithLabel
          id="client-invoices"
          {...threeBlocksProps}
          label="Client Invoices"
          project={project}
          Component={ProjectClientInvoicesBlock}
        />
        {isClientGlCodesEnable && (
          <BlockWithLabel
            {...threeBlocksProps}
            id="client-gl-code"
            label="Client GL Code"
            Component={getClientGLCodeComp(clientGlCode)}
          />
        )}
        {enableCostCenter && !withSubProperties && (
          <BlockWithLabel
            {...threeBlocksProps}
            id="cost-center"
            label="Cost Center"
            Component={getSectionText(costCenter)}
          />
        )}
        {nonBillable && (
          <NonBillableBlockS
            {...threeBlocksProps}
            id="non-billable"
            label="Non Billable"
            Component={getSectionText(nonBillableReason)}
          />
        )}
        {!isFee &&
          estimates.map(({ _id, total }, index) => {
            const itemId = `project-estimate-${_id}`;
            const estimateNumber = estimates.length === 1 ? null : index + 1;
            const itemLabel = `Client Estimate ${
              estimateNumber ? `# ${estimateNumber}` : ''
            }`;

            return (
              <BlockWithLabel
                {...threeBlocksProps}
                id={itemId}
                key={itemId}
                total={total}
                estimateId={_id}
                label={itemLabel}
                projectId={project._id}
                estimateNumber={estimateNumber}
                Component={ProjectEstimateLink}
                isEstimateDisabled={isEstimateDisabled}
              />
            );
          })}
      </SidebarRow>
    </SectionWrapperWithoutBorder>
  );
}

ProjectSidebarFinancial.propTypes = {
  project: shape({ _id: string.isRequired }),
  isCard: bool,
  disableTabs: bool,
};
