import React from 'react';
import styled from 'styled-components';
import { ESFM_INFO } from '@poly/constants';
import { ESFMFullLogo } from '@poly/admin-ui';
import { getThemeColor, Icon } from '@poly/admin-book';

const RequesterSurveyCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${getThemeColor(['light'])};
  font-family: Lato, sans-serif;
  line-height: normal;
  letter-spacing: normal;
`;

const RequesterSurveyCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 650px;
  height: auto;
  padding: 32px 25px;
  background-color: ${getThemeColor(['white'])};
`;

const RequesterSurveyContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 0 16px 0;
`;

const RequesterSurveyIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  margin: 0 5px;
  background-color: ${getThemeColor(['inspiniaBlueLighter'])};
`;

const RequesterSurveyContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 25px;
`;

const RequesterSurveyTitle = styled.div`
  font-size: 22px;
  padding-bottom: 16px;
`;

const RequesterSurveyText = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

const RequesterSurveyFooter = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${getThemeColor(['midLight'])};
  align-items: center;
`;

const Text = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-top: 20px;
`;

const PhoneLink = styled.a`
  color: ${getThemeColor(['primaryLight'])};
  padding-left: 3px;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
`;

export function RequesterSurveySubmittedCard() {
  const { telephone } = ESFM_INFO;

  return (
    <RequesterSurveyCardWrapper>
      <RequesterSurveyCard>
        <ESFMFullLogo />
        <RequesterSurveyContentWrapper>
          <RequesterSurveyIconWrapper icon="checkMark">
            <Icon name="checkMark" color="white" size={28} />
          </RequesterSurveyIconWrapper>
          <RequesterSurveyContent>
            <RequesterSurveyTitle>Hello!</RequesterSurveyTitle>
            <RequesterSurveyText>
              Thank you for your feedback, we appreciate your honesty.
            </RequesterSurveyText>
          </RequesterSurveyContent>
        </RequesterSurveyContentWrapper>
        <RequesterSurveyFooter>
          <Text>
            If you have any questions, you can reach Client Support at
            <PhoneLink href={`tel:${telephone}`}>{telephone}</PhoneLink>
          </Text>
        </RequesterSurveyFooter>
      </RequesterSurveyCard>
    </RequesterSurveyCardWrapper>
  );
}
