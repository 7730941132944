import * as R from 'ramda';

import { assocBy } from './ramda.js';

// applyIfOr :: (a -> Boolean) -> (a -> b) -> b -> a -> b
// a, b, c = Any
export const applyIfOr = R.curry((pred, func, or) =>
  R.ifElse(pred, func, R.always(or)),
);

// applyOr :: (a -> b) -> b -> a -> b
// a, b, c = Any
export const applyOr = applyIfOr(R.complement(R.either(R.isNil, R.isEmpty)));

// applyIfNotNil :: (a -> b) -> a -> b
// a, b = Any
export const applyIfNotNil = (func) => R.when(R.complement(R.isNil), func);

// applyPathOr :: [String] -> (a -> b) -> b -> a -> b
// b = Any
// a = Object
export const applyPathOr = R.curry((path, func, or) =>
  R.compose(applyOr(func)(or), R.path(path)),
);

// applyToPath :: (v -> Res) -> [String] -> { k: v } -> Res
// Res = Any
export const applyPath = R.curry((func, path) => R.pipe(R.path(path), func));

// applyToProp :: (v -> Res) -> String -> { k: v } -> Res
// Res = Any
export const applyProp = (func) => (prop) => applyPath(func)([prop]);

// debounce :: (Args -> String) -> Number -> (Args -> Any) -> (Args -> Undefined)
// Args = Any
export const debounceBy = R.curry((getTimerKeyByArgs, time, fn) => {
  const timeouts = new Map();
  const setNewTimeout = (...args) => {
    const timerKey = getTimerKeyByArgs(...args).toString();
    if (timeouts.has(timerKey)) {
      clearTimeout(timeouts.get(timerKey));
    }

    const newTimeout = setTimeout(() => {
      timeouts.delete(timerKey);
      fn(...args);
    }, time);
    timeouts.set(timerKey, newTimeout);

    setNewTimeout.cancel = () => clearTimeout(timeouts.get(timerKey));
  };

  return setNewTimeout;
});

// debounce :: Number -> (Args -> Any) -> (Args -> Undefined)
// Args = Any
export const debounce = debounceBy(R.always('default_key'));

// tryCallFunction :: Function -> Args -> (Args -> Any)
export const tryCallFunction =
  (func) =>
  (...args) => {
    try {
      func(...args);
    } catch (e) {
      // eslint-disable-next-line
      console.warn(e);
    }
  };

/**
 * applyAndRenameObjectProp :: String -> String -> (Any -> Any) -> Object -> Object
 */
export const applyAndRenameObjectProp = R.curry(
  (oldName, newName, func, targetObj) =>
    R.when(
      R.has(oldName),
      R.compose(
        R.dissoc(oldName),
        assocBy(newName, R.compose(func, R.prop(oldName))),
      ),
    )(targetObj),
);

// unwindByProp :: String -> [Object] -> [Object]
export const unwindByProp = (unwindBy) =>
  R.reduce(
    (acc, nextElem) =>
      R.compose(
        R.concat(acc),
        R.when(R.isEmpty, R.always([nextElem])),
        R.map((elem) => ({ ...nextElem, [unwindBy]: elem })),
        R.prop(unwindBy),
      )(nextElem),
    [],
  );

// isNilOrEmpty :: Any -> Boolean
export const isNilOrEmpty = R.either(R.isEmpty, R.isNil);

// calculateTotal :: (a -> Number) -> [a] -> Number
//   a = Any
export const calculateTotal = R.curry((getSubTotal, documents) =>
  R.reduce(
    R.useWith(R.add, [R.identity, R.compose(R.defaultTo(0), getSubTotal)]),
    0,
    documents,
  ),
);

// setLocalStorageItem :: String -> Any -> Nothing
export const setLocalStorageItem = (key) => (val) =>
  localStorage.setItem(key, val);

// waitForTimeoutP :: Number -> Promise _
export const waitForTimeoutP = (timeout) =>
  new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });

// isNotNillOrEmptyPath :: [String] -> Object -> Boolean
// eslint-disable-next-line import/no-unused-modules
export const isNotNillOrEmptyPath = (path) =>
  R.compose(R.complement(isNilOrEmpty), R.path(path));
