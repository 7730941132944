import * as R from 'ramda';
import { onError } from '@apollo/client/link/error/index.js';
import { setContext } from '@apollo/client/link/context/index.js';
import { debounce } from '@poly/utils';

import { getTemporalUserToken } from './utils.js';

export const authMiddleWare = () =>
  setContext(() => {
    const temporalAccessToken = getTemporalUserToken();

    return {
      headers: {
        // prioritize temporal token from global variable for web-forms
        // to provide temporal access so we can use web-form while keeping
        // user logged in at the same browser
        ...(!temporalAccessToken
          ? {}
          : { authorization: `Bearer ${temporalAccessToken}` }),
        'apollo-require-preflight': true,
        'poly-client-version': process.env.APP_VERSION,
        'browser-location': window.location.host + window.location.pathname,
      },
    };
  });

export const appMaintenanceMiddleware = (handleApplicationMaintenance) => {
  // debounce function to avoid multiple processing on batch queries
  const processErrors = debounce(50)(({ networkError }) => {
    if (
      !!networkError &&
      // do not trigger on not authorized error
      !networkError.statusCode &&
      R.is(Function)(handleApplicationMaintenance)
    ) {
      handleApplicationMaintenance();
    }
  });

  return onError(processErrors);
};
