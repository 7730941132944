import * as R from 'ramda';
import { useQuery } from '@apollo/client';
import { ADMIN_APP_NAME } from '@poly/security';
import { propEqLegacy } from '@poly/utils';

import { USER_GROUP_LIST_QUERY } from '../../../pages/UserGroupManagement/queries/user-group-list-query.js';

// prepareAllowedUserGroupIds :: { listUserGroups: [UserGroup] } -> [ID]
const prepareAllowedUserGroupIds = R.compose(
  R.map(R.prop('_id')),
  R.filter(propEqLegacy('defaultLoginApp', ADMIN_APP_NAME)),
  R.propOr([], 'listUserGroups'),
);

export const useAllowedUserGroupIds = () => {
  const { data, loading } = useQuery(USER_GROUP_LIST_QUERY, {
    variables: {},
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return [];
  }

  return prepareAllowedUserGroupIds(data);
};
