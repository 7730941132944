import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { assocBy, assocByPath, propEqLegacy, renameProp } from '@poly/utils';
import {
  commonAddressFields,
  prepareAddressObjectToForm,
} from '@poly/admin-ui';
import { supplierDocumentRequestTypes } from '@poly/constants';

const REQUESTED_SUPPLIERS_DOCUMENT = gql`
  query REQUESTED_SUPPLIERS_DOCUMENT($id: ID!) {
    requestedSupplierDocument(id: $id) {
      _id
      expiration
      documentType
      mbeMinority
      supplier {
        _id
        service_24_7
        isVeteran
        company {
          name
          dba
          fax
          website
          emails {
            email
            type
          }
          phones {
            phone
            type
            legacyFormat
          }
          services {
            _id
            name
          }
          addressTwo
          address {
            ...commonAddressFields
          }
        }
        rates {
          hourly
          overTime
          trip
          weekend
        }
        tax {
          classification
          name
          id
          socialSecurityNumber
          is1099
        }
        mwbe {
          isWBE
          minority
        }
        remit {
          name
          address {
            ...commonAddressFields
          }
          addressTwo
        }
        masterSupplier {
          _id
        }
      }
      masterSupplier {
        _id
        service_24_7
        isVeteran
        company {
          name
          dba
          fax
          website
          emails {
            email
            type
          }
          phones {
            phone
            type
            legacyFormat
          }
          services {
            _id
            name
          }
          addressTwo
          address {
            ...commonAddressFields
          }
        }
        rates {
          hourly
          overTime
          trip
          weekend
        }
        tax {
          classification
          name
          id
          socialSecurityNumber
          is1099
        }
        mwbe {
          isWBE
          minority
        }
        remit {
          name
          address {
            ...commonAddressFields
          }
          addressTwo
        }
      }
      file {
        url
        fileType
      }
      agreement {
        date
        title
        downloadedAt
        ip
        contactName
        version
      }
      onboarding {
        service_24_7
        isVeteran
        company {
          name
          dba
          fax
          website
          emails {
            email
            type
          }
          phones {
            phone
            type
            legacyFormat
          }
          services {
            _id
            name
          }
        }
        rates {
          hourly
          overTime
          trip
          weekend
        }
        tax {
          classification
          name
          id
          socialSecurityNumber
          is1099
          w9File {
            url
            fileType
          }
        }
        mwbe {
          isWBE
          minority
        }
      }
    }
  }
  ${commonAddressFields}
`;

// prepareRequestedSupplierDocumentData :: QueryData -> RequestedSupplierDocument
export const prepareRequestedSupplierDocumentData = R.compose(
  R.when(
    R.prop('onboarding'),
    R.over(
      R.lensProp('onboarding'),
      R.compose(
        prepareAddressObjectToForm('remit'),
        prepareAddressObjectToForm('company'),
      ),
    ),
  ),
  R.when(
    propEqLegacy('documentType', supplierDocumentRequestTypes.W9_MANUALLY),
    assocBy(
      'onboarding',
      R.converge(R.mergeDeepLeft, [R.prop('supplier'), R.prop('onboarding')]),
    ),
  ),
  R.when(
    propEqLegacy('documentType', supplierDocumentRequestTypes.W9),
    assocByPath(['onboarding', 'remit'], R.pathOr(null, ['supplier', 'remit'])),
  ),
  R.dissoc('masterSupplier'),
  R.when(
    R.path(['masterSupplier', '_id']),
    R.compose(
      R.assocPath(['supplier', 'isMaster'], true),
      renameProp('masterSupplier', 'supplier'),
    ),
  ),
  R.propOr({}, 'requestedSupplierDocument'),
);

export const useRequestedSupplierDocument = (id) => {
  const { data, loading } = useQuery(REQUESTED_SUPPLIERS_DOCUMENT, {
    variables: { id },
    skip: !id,
  });

  const document = prepareRequestedSupplierDocumentData(data);

  return { loading, document };
};
