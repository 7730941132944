import React from 'react';
import { string } from 'prop-types';
import { formatCount, forceTitleCase } from '@poly/utils';
import { defaultTheme, Status } from '@poly/admin-book';
import { ClientStatuses } from '@poly/constants';

import { useClientsBySearch } from '../../core/hooks/clients/index.js';

const {
  colors: {
    statuses: { active, inactive },
  },
} = defaultTheme;

const { ACTIVE, INACTIVE } = ClientStatuses;

const mapTitleColorByStatus = {
  [ACTIVE]: active,
  [INACTIVE]: inactive,
};

export function ClientsTabTitle({ status, ...restProps }) {
  const { result } = useClientsBySearch({
    status,
    propsOfComponent: restProps,
  });

  const count = result.searchClients?.total || 0;

  const titleProps = {
    color: mapTitleColorByStatus[status],
    text: `${forceTitleCase(status)} (${formatCount(count)})`,
  };

  return <Status {...titleProps} />;
}

ClientsTabTitle.propTypes = { status: string };
ClientsTabTitle.displayName = 'ClientsTabTitle';
