import React from 'react';
import {
  createExcelExportCell,
  ExcelExportCellType,
  performExcelExport,
} from '@poly/client-utils';
import { LinkButton } from '@poly/admin-book';
import { EXPORT_XLS_CAPTION } from '@poly/constants';
import { number, string } from 'prop-types';
import * as R from 'ramda';
import { useRouterQuery } from '@poly/client-routing';

import { calculateTotal, forceTitleCase, ofArrayLegacy } from '@poly/utils';
import {
  ALL_MONTHS,
  calculateMonthsTotals,
  formats,
  isTotalRow,
} from './clientSalesReportHelpers.js';
import { salesReportRowsTypeProps } from './prop-types.js';

// getClientNameCell :: ClientSalesReportRow -> ExcelExportDataCell
const getClientNameCell = R.applySpec({
  value: R.prop('clientName'),
  cellType: R.always(ExcelExportCellType.default),
});

// getTitleCellBase :: String -> ClientSalesReportRow -> ExcelExportDataCell
const getTitleCellBase = (title) =>
  R.applySpec({
    value: R.always(title),
    cellType: R.always(ExcelExportCellType.default),
  });

// getAmountCellByEntryAndProp :: String -> Number -> ClientSalesReportRow -> ExcelExportDataCell
const getAmountCellByEntryAndProp = (amountKey) => (monthIndex) =>
  R.applySpec({
    value: R.pathOr(0, [monthIndex, amountKey]),
    cellType: R.always(ExcelExportCellType.defaultCurrency),
  });

// getTotalCell :: String -> ClientSalesReportRow -> ExcelExportDataCell
const getTotalCell = (amountKey) =>
  R.applySpec({
    value: R.compose(
      calculateTotal(R.prop(amountKey)),
      R.values,
      R.omit(['clientName', '_id']),
    ),
    cellType: R.always(ExcelExportCellType.tableCurrencyFooter),
  });

// getClientSalesDataCell :: (String, ClientSalesReportRow -> String, Boolean) -> ClientSalesReportRow -> ExcelExportDataCell
const getClientTotalDataCell = (amountKey, getTitleCell, showClientName) =>
  R.juxt([
    (row) => (showClientName ? getClientNameCell(row) : ''),
    getTitleCell,
    ...R.map(getAmountCellByEntryAndProp(amountKey), R.range(0, 12)),
    getTotalCell(amountKey),
  ]);

// generateXlTotalRow :: ([ClientSalesReportRow], String) -> [ExcelExportDataCell]
const generateXlTotalRow = (rows, format = formats.total) => {
  const monthTotals = calculateMonthsTotals(rows);
  const yearTotal = calculateTotal(R.identity)(monthTotals);
  return [
    format === formats.total
      ? [
          {
            value: 'Total',
            cellType: ExcelExportCellType.tableCurrencyFooter,
          },
          {
            value: '',
            cellType: ExcelExportCellType.tableCurrencyFooter,
          },
          ...R.map(
            R.applySpec({
              value: R.identity,
              cellType: R.always(ExcelExportCellType.tableCurrencyFooter),
            }),
            monthTotals,
          ),
          {
            value: yearTotal,
            cellType: ExcelExportCellType.tableCurrencyFooter,
          },
        ]
      : [],
  ];
};

// getSalesAndWipAmountsRows :: ([ClientSalesReportRow], String) -> [ExcelExportDataCell]
const getSalesAndWipAmountsRows = (rows, format = formats.total) =>
  R.compose(
    R.unnest,
    R.map(
      R.cond([
        [
          isTotalRow,
          R.compose(
            ofArrayLegacy,
            getClientTotalDataCell('amount', getTitleCellBase(''), true),
          ),
        ],
        [
          R.always(format === formats.sales),
          R.compose(
            ofArrayLegacy,
            getClientTotalDataCell(
              'salesAmount',
              getTitleCellBase('Sales'),
              true,
            ),
          ),
        ],
        [
          R.always(format === formats.wip),
          R.compose(
            ofArrayLegacy,
            getClientTotalDataCell('wipAmount', getTitleCellBase('WIP'), true),
          ),
        ],
        [
          R.always(format === formats.total),
          R.juxt([
            getClientTotalDataCell(
              'salesAmount',
              getTitleCellBase('Sales'),
              true,
            ),
            getClientTotalDataCell('wipAmount', getTitleCellBase('WIP')),
          ]),
        ],
      ]),
    ),
  )(rows);

// getClientSalesReportsExcelExportConfig :: ([ClientSalesReportRow], Number, String, String) -> ExcelExportConfig
// eslint-disable-next-line import/no-unused-modules
export const getClientSalesReportsExcelExportConfig = (
  clientSalesReportItems,
  year,
  accountingMethod,
  format,
) => ({
  exportFileName: 'client-sales-report.xlsx',
  columnWidths: [50, 10, R.repeat(15, 15)],
  rows: [
    ...[
      {
        value: 'Client Sales Report',
        cellType: ExcelExportCellType.title,
      },
      {
        value: year,
        cellType: ExcelExportCellType.subTitle,
      },
      {
        value: forceTitleCase(accountingMethod),
        cellType: ExcelExportCellType.subTitle,
      },
    ].map(ofArrayLegacy),

    R.zipWith(
      createExcelExportCell,
      [
        ExcelExportCellType.tableHeader,
        ...R.map(
          R.always(ExcelExportCellType.tableCurrencyHeader),
          R.range(1, 13),
        ),
        ExcelExportCellType.tableCurrencyHeader,
      ],
      ['Client', '', ...ALL_MONTHS, 'Total'],
    ),
    ...getSalesAndWipAmountsRows(clientSalesReportItems, format),
    ...generateXlTotalRow(clientSalesReportItems, format),
  ],
});

export function ClientSalesReportExportXlBtn({
  salesReportRows,
  year,
  accountingMethod,
}) {
  const { format } = useRouterQuery(['format']);

  const onClick = () => {
    const config = getClientSalesReportsExcelExportConfig(
      salesReportRows,
      year,
      accountingMethod,
      format,
    );
    performExcelExport(config);
  };
  return (
    <LinkButton disabled={salesReportRows.length === 0} onClick={onClick}>
      {EXPORT_XLS_CAPTION}
    </LinkButton>
  );
}

ClientSalesReportExportXlBtn.propTypes = {
  year: number,
  accountingMethod: string,
  salesReportRows: salesReportRowsTypeProps,
};
