import React from 'react';
import {
  AddBranchSupplierForm,
  AddBranchSupplierPageHeader,
} from '@poly/admin-ui';
import { DefaultBodyWrapper } from '@poly/admin-book';

import { useRouterQuery } from '@poly/client-routing';
import { routesNames } from '../../routes/index.js';

const addBranchSupplierFormId = 'addBranchSupplierFormId';

export function AddBranchSupplierPage() {
  const { masterSupplierId } = useRouterQuery(['masterSupplierId']);
  return (
    <>
      <AddBranchSupplierPageHeader
        formId={addBranchSupplierFormId}
        masterSupplierId={masterSupplierId}
        redirectUrl={routesNames.ACCOUNT_INDEX}
      />
      <DefaultBodyWrapper>
        <AddBranchSupplierForm
          formId={addBranchSupplierFormId}
          masterSupplierId={masterSupplierId}
          supplierUrl={routesNames.SUPPLIER}
        />
      </DefaultBodyWrapper>
    </>
  );
}
