import * as R from 'ramda';
import { useMutation, gql } from '@apollo/client';
import { useOutSidebarContext } from '@poly/client-utils';
import { useNotificationState } from '@poly/admin-ui';

import { addClientFormId } from '../../constants.js';
import { formDataToClientMutation } from '../edit/formDataToMutation.js';
import { useSidebarLogicContext } from '../../../SidebarLogicContext.js';

const CREATE_CLIENT_MUTATION = gql`
  mutation CREATE_CLIENT_MUTATION($input: CreateClientInput!) {
    createClient(input: $input) {
      client {
        _id
      }
    }
  }
`;

// getClientId :: CreateClientMutationResult -> ID
const getClientId = R.path(['data', 'createClient', 'client', '_id']);

// prepareFormData :: FormData -> FormData
const prepareFormData = R.omit([
  'financialSettingsTitle',
  'clientSettingsTitle',
  'portalSettingsTitle',
  'clientPortalConfigsTitle',
  'requestConfigsTitle',
]);

export const useOnSubmitClientFrom = (parentClientId, cleanupRetainedValue) => {
  const { showSuccessNotification } = useNotificationState();
  const { openClientSidebar } = useSidebarLogicContext();
  const { closeOutSidebar } = useOutSidebarContext();

  const [createClientMutation] = useMutation(CREATE_CLIENT_MUTATION);

  return async (formData) => {
    const [toMutation, mutate] = [
      formDataToClientMutation,
      createClientMutation,
    ];

    const toMutationObject = toMutation(null, {
      ...prepareFormData(formData),
      parentClientId,
    });

    const mutationResult = await mutate({
      variables: { input: toMutationObject },
    });

    const clientId = getClientId(mutationResult);

    showSuccessNotification('Client was successfully created');
    cleanupRetainedValue();
    closeOutSidebar(addClientFormId);
    openClientSidebar(clientId);
  };
};
