import { useCallback } from 'react';
import { debounce, tryCallFunction } from '@poly/utils';
import { isQueryOrSubscriptionsSkip } from './helpers.js';

export const useDebounceRefetchByOptions = ({
  refetch,
  queryOptions,
  refetchDebounce,
  subscriptionOptions,
}) => {
  const tryCallRefetch = () => {
    const isSkipRefetch = isQueryOrSubscriptionsSkip({
      queryOptions,
      subscriptionOptions,
    });

    if (!isSkipRefetch) {
      // we use tryCallFunction to avoid refetch error on unmounted component
      tryCallFunction(refetch)();
    }
  };

  const debouncedRefetch = useCallback(
    debounce(refetchDebounce)(tryCallRefetch),
    [refetch, queryOptions, subscriptionOptions],
  );

  return debouncedRefetch;
};
