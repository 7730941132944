import imageCompression from 'browser-image-compression';

// eslint-disable-next-line import/no-unused-modules
export const compressImage = async (upload) => {
  const compressed = await imageCompression(upload, {
    maxSizeMB: 0.3,
    maxWidthOrHeight: 1280,
    useWebWorker: true,
  });

  return new File([compressed], upload.name, {
    lastModified: new Date().getTime(),
    type: compressed.type,
  });
};
