import { useSelector } from 'react-redux';
import { useState, useRef, useEffect } from 'react';
import { DESC_SORT_ORDER, ELASTIC_SCORE_FIELD } from '@poly/constants';

import { useUnsupportedWorkerMessage } from '../../hooks/useUnsupportedWorkerMessage.js';
import { useRegisterSharedWorker } from '../HomePage/useRegisterSharedWorker.js';

export const useMyProjectsQueryWorker = () => {
  const myProjectsWorkerRef = useRef(null);
  const [workerSkip, setWorkerSkip] = useState(true);
  const [myProjectsData, setMyProjectsData] = useState({});
  const searchTerm = useSelector((state) => state.searchText);
  const showUnsupportedMessage = useUnsupportedWorkerMessage();

  useEffect(() => {
    try {
      myProjectsWorkerRef.current = new SharedWorker(
        new URL('./myProjectsSharedWorker.js', import.meta.url),
        { name: 'admin-my-projects' },
      );
      setWorkerSkip(false);
    } catch (e) {
      showUnsupportedMessage();
    }
  }, []);

  useRegisterSharedWorker({
    skip: workerSkip,
    setData: setMyProjectsData,
    workerInstance: myProjectsWorkerRef.current,
    messageProps: {
      searchTerm,
      sort: [{ createdAt: DESC_SORT_ORDER }, ELASTIC_SCORE_FIELD],
    },
  });

  return myProjectsData;
};
