import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';

import { useRouterQuery } from '@poly/client-routing';
import { Modal } from '@poly/admin-book';
import { func, shape } from 'prop-types';
import { useCloseCurrentModal } from '../redux/modal.js';

export function ModalWindow({ modals, onConfirmClose, ...props }) {
  const { name, payload } = useSelector(R.prop('modal'));

  const closeCurrentModal = useCloseCurrentModal();
  const { pristine } = useRouterQuery(['pristine']);

  const afterCloseModal = useSelector(
    (state) => state?.confirmModal?.afterCloseModal,
  );

  const ActiveModal = modals[name] || modals.empty;
  const { isOverflowAuto } = payload || false;

  const modalProps = {
    isOpen: !!name,
    isOverflowAuto,
    onClose: () => {
      if (pristine === false) {
        onConfirmClose();
      } else {
        closeCurrentModal();
      }

      if (afterCloseModal) {
        afterCloseModal();
      }
    },
    ...props,
  };

  return (
    <Modal {...modalProps}>
      <ActiveModal payload={payload} />
    </Modal>
  );
}

ModalWindow.displayName = 'ModalWindow';

ModalWindow.propTypes = {
  modals: shape({
    empty: func.isRequired,
  }).isRequired,
  onConfirmClose: func.isRequired,
};
