import React, { useState } from 'react';

import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { ConfirmSatisfactionReportHeader } from './ConfirmSatisfactionReportHeader.js';
import { useConfirmSatisfactionReportQuery } from './useConfirmSatisfactionReportQuery.js';
import { ConfirmSatisfactionReportTableContainer } from './ConfirmSatisfactionReportTableContainer.js';

export function ConfirmSatisfactionReportPage() {
  const [filters, setFilters] = useState(null);

  const { projects, loading, tableProps } =
    useConfirmSatisfactionReportQuery(filters);

  return (
    <PageWithSearchHeader headerHeight="155px">
      <ConfirmSatisfactionReportHeader
        setFilters={setFilters}
        loading={loading}
        projects={projects}
      />
      <ConfirmSatisfactionReportTableContainer
        loading={loading}
        projects={projects}
        {...tableProps}
      />
    </PageWithSearchHeader>
  );
}
