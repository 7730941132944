import { shape, number, arrayOf, string } from 'prop-types';

export const invoicesPropTypes = arrayOf(
  shape({
    invoices: arrayOf(
      shape({
        invoice: shape({
          total: number.isRequired,
        }),
        clientInvoice: shape({
          createdAt: string.isRequired,
          paidAt: string,
        }),
      }),
    ),
  }),
);
