import React, { useEffect } from 'react';
import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { MoneyInputAsCents } from '@poly/admin-ui/src/modules/forms/fields/MoneyInput.js';
import { func, number, oneOfType, shape, string } from 'prop-types';
import { getAssetReplacementCost } from '@poly/utils/src/getAssetReplacementCost.js';
import { propEqLegacy } from '@poly/utils';

const ASSET_MANUFACTURE_QUERY = gql`
  query ASSET_MANUFACTURE_QUERY($id: ID!) {
    assetManufacturer(id: $id) {
      replacementCost
      assetModels {
        _id
        replacementCost
      }
    }
  }
`;

const ASSET_TYPE_QUERY = gql`
  query ASSET_TYPE_QUERY($id: ID!) {
    assetType(id: $id) {
      _id
      replacementCost
    }
  }
`;

// getInheritedAssetReplacementCost :: (String, QueryData) -> Number
const getInheritedAssetReplacementCost = (modelId, data) =>
  R.compose(
    getAssetReplacementCost,
    R.applySpec({
      type: R.path(['assetTypeData', 'assetType']),
      manufacturerDoc: R.path(['assetManufacturerData', 'assetManufacturer']),
      modelDoc: R.compose(
        R.find(propEqLegacy('_id', modelId)),
        R.defaultTo([]),
        R.path(['assetManufacturerData', 'assetManufacturer', 'assetModels']),
      ),
    }),
  )(data);

export function AssetReplacementCostInput({
  value,
  formData,
  changeFieldValue,
  onChange,
  meta,
  ...props
}) {
  const { isReplacementCostChanged, manufacturerId, typeId, modelId } =
    formData;

  const { data: assetManufacturerData } = useQuery(ASSET_MANUFACTURE_QUERY, {
    variables: { id: manufacturerId },
    skip: !manufacturerId,
  });

  const { data: assetTypeData } = useQuery(ASSET_TYPE_QUERY, {
    variables: { id: typeId },
    skip: !typeId,
  });

  const replacementCost = getInheritedAssetReplacementCost(modelId, {
    assetManufacturerData,
    assetTypeData,
  });

  useEffect(() => {
    if (!isReplacementCostChanged || !value) {
      onChange(replacementCost);
    }
  }, [replacementCost]);

  useEffect(() => {
    if (meta.touched && !isReplacementCostChanged) {
      changeFieldValue('isReplacementCostChanged', true);
    }
  }, [meta.touched]);

  return <MoneyInputAsCents onChange={onChange} value={value} {...props} />;
}

AssetReplacementCostInput.propTypes = {
  formData: shape({}),
  onChange: func.isRequired,
  value: oneOfType([number, string]),
  changeFieldValue: func.isRequired,
  meta: shape({}),
};
