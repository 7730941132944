import * as R from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';
import { number, shape, arrayOf, string } from 'prop-types';
import { WindowedTable, moneyColumnStyles } from '@poly/admin-book';
import { applyProp, centsToDollarsWithFormat } from '@poly/utils';
import { useMapConfigToTableProps } from '@poly/admin-ui';

import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';

export const housekeepingBatchInvoicingTableStyles = css`
  tbody {
    vertical-align: middle;
  }

  tr {
    width: 100%;
    margin: 0;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 120px;
  }

  ${moneyColumnStyles(3)};
  ${moneyColumnStyles(4)};
  ${moneyColumnStyles(5)};
  ${moneyColumnStyles(6)};

  th:nth-child(7),
  td:nth-child(7) {
    width: 140px;
    text-align: right;
    label {
      margin-left: 110px;
    }
  }
`;

const TableS = styled(WindowedTable)`
  thead {
    height: 45px;
  }

  ${housekeepingBatchInvoicingTableStyles}
`;

const batchInvoicingProjectsTableConfig = [
  [
    'Project No.',
    (project) => (
      <ProjectLink {...project} target="_blank">
        {project.projectId}
      </ProjectLink>
    ),
  ],
  ['Description', R.prop('description')],
  ['Job Cost', applyProp(centsToDollarsWithFormat)('jobCost')],
  [
    'Inv. Base',
    applyProp(centsToDollarsWithFormat)('suggestedClientInvoiceAmount'),
  ],
  [
    'Inv. Extra hrs',
    applyProp(centsToDollarsWithFormat)('extraHoursClientInvoiceAmount'),
  ],
  [
    'Total',
    R.compose(
      centsToDollarsWithFormat,
      R.converge(R.add, [
        R.prop('suggestedClientInvoiceAmount'),
        R.prop('extraHoursClientInvoiceAmount'),
      ]),
    ),
  ],
];

export function HousekeepingBatchInvoicingTable({ projects, ...tableProps }) {
  const { rows, headers, columns, sortQueries } = useMapConfigToTableProps(
    R.identity,
    batchInvoicingProjectsTableConfig,
    projects,
  );

  return (
    <TableS
      headers={headers}
      rows={rows}
      columns={columns}
      sortQueries={sortQueries}
      selectedRowsInEnd
      showScrollBar
      {...tableProps}
    />
  );
}

HousekeepingBatchInvoicingTable.propTypes = {
  projects: arrayOf(
    shape({
      suggestedClientInvoiceAmount: number.isRequired,
      extraHoursClientInvoiceAmount: number.isRequired,
      description: string,
      projectId: string.isRequired,
    }),
  ),
};
