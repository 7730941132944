import React, { useState, useCallback } from 'react';
import { debounce } from '@poly/utils';
import {
  DEBOUNCE_USER_INPUT_THRESHOLD,
  initialPagination,
} from '@poly/client-utils';
import {
  func,
  bool,
  shape,
  string,
  arrayOf,
  object,
  oneOfType,
} from 'prop-types';
import { useUsersBySearchForOptions } from '../../../../hooks/users/index.js';
import { MultiSelectDropDown } from '../../../../components/MultiSelectDropDown.js';

export function MultiSelectUserDropDown({
  query,
  optionsFromQueryResult,
  placeholder,
  componentProps = {},
  ...restProps
}) {
  const [searchTermState, setSearchTermState] = useState('');

  const setSearchTerm = useCallback(
    debounce(DEBOUNCE_USER_INPUT_THRESHOLD)(setSearchTermState),
    [],
  );

  const onInputChange = (text) => {
    setSearchTerm(text.trim());
  };

  const { result } = useUsersBySearchForOptions({
    query,
    pagination: initialPagination,
    searchTerm: searchTermState,
    skipQuery: !searchTermState,
  });

  const options = optionsFromQueryResult(result);

  return (
    <MultiSelectDropDown
      {...restProps}
      {...componentProps}
      onInputChange={onInputChange}
      handleChange={restProps.onChange}
      placeholder={placeholder}
      menuIsOpen={options.length > 0}
      options={options}
    />
  );
}

MultiSelectUserDropDown.propTypes = {
  optionsFromQueryResult: func.isRequired,
  placeholder: string,
  componentProps: shape({
    withPortal: bool,
  }),
  query: shape({
    bool: shape({
      must: arrayOf(oneOfType([string, object])),
    }),
  }),
};
