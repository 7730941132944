import React from 'react';
import {
  PageHeaderContainer,
  HeaderLinksWrapper,
  MainHeader,
} from '@poly/admin-book';

import { AddNewProcedureButton } from './AddNewProcedureButton.js';

export function ManageProceduresPageHeader() {
  return (
    <PageHeaderContainer>
      <MainHeader>Manage Procedures</MainHeader>
      <HeaderLinksWrapper>
        <AddNewProcedureButton />
      </HeaderLinksWrapper>
    </PageHeaderContainer>
  );
}
