import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PopUp, useNotificationState } from '@poly/admin-ui';

import { HistoryContent } from './HistoryContent.js';
import { HistoryWrapper, HistoryIcon } from './historyComponents.js';
import { useSidebarLogicContext } from '../../../sidebars/SidebarLogicContext.js';
import { setPopup } from '../../../redux/popupReducer.js';
import { useAppBarItemClick } from '../useAppBarItemClick.js';

export function HistoryPopUp() {
  const { historyRecords, updateHistoryRecords } = useSidebarLogicContext();
  const popupEntity = useSelector((store) => store.popupEntity);
  const { showWarningNotification } = useNotificationState();
  const dispatch = useDispatch();

  const onHistoryClick = useAppBarItemClick(() => {
    if (!updateHistoryRecords().length) {
      showWarningNotification("You don't have history records");
      return;
    }
    dispatch(setPopup(popupEntity === 'history' ? '' : 'history'));
  });

  useEffect(() => {
    if (!historyRecords.length) {
      dispatch(setPopup(''));
    }
  }, [historyRecords]);

  return (
    <HistoryWrapper>
      <PopUp
        isOpen={popupEntity === 'history'}
        close={() => dispatch(setPopup(''))}
        trigger={
          <HistoryIcon
            size={24}
            name="history"
            color="#fff"
            id="history-icon"
            onClick={onHistoryClick}
            data-testid="history-icon"
          />
        }
      >
        <HistoryContent />
      </PopUp>
    </HistoryWrapper>
  );
}

HistoryPopUp.displayName = 'HistoryPopUp';
