import React from 'react';
import styled from 'styled-components';
import { string, func, bool, oneOfType } from 'prop-types';
import { getThemeColor } from '@poly/admin-book';
import { forceTitleCase } from '@poly/utils';

const AddNewEntityWrapperS = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddNewEntityTitleS = styled.div`
  width: fit-content;
  padding: 5px 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: ${getThemeColor(['primaryLight'])};
`;

const AddNewEntityLabelS = styled(AddNewEntityTitleS)`
  color: ${getThemeColor(['midDark'])};
  cursor: pointer;
`;

export function AddNewAssetEntity({
  entity,
  value,
  onChange,
  isNewManufacturer,
}) {
  const onClick = () => onChange(!value);

  const labelText = value
    ? `New asset ${entity} will be added`
    : `Didn't find the asset ${entity}?`;

  return (
    <AddNewEntityWrapperS>
      <AddNewEntityLabelS>
        {isNewManufacturer ? `New asset ${entity} will be added` : labelText}
      </AddNewEntityLabelS>
      {!isNewManufacturer ? (
        <AddNewEntityTitleS onClick={onClick}>
          {value ? 'Cancel' : `+ Add Asset ${forceTitleCase(entity)}`}
        </AddNewEntityTitleS>
      ) : null}
    </AddNewEntityWrapperS>
  );
}

AddNewAssetEntity.propTypes = {
  entity: string.isRequired,
  onChange: func.isRequired,
  value: oneOfType([string, bool]),
  isNewManufacturer: bool,
};
