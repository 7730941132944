import * as R from 'ramda';

export const generateAvailablePagesConfig = R.cond([
  [
    R.propSatisfies(R.lt(R.__, 8), 'pagesCount'),
    ({ pagesCount }) => R.range(1, pagesCount + 1),
  ],
  [
    R.propSatisfies(R.lt(R.__, 5), 'currentPage'),
    ({ pagesCount }) => [1, 2, 3, 4, 5, 6, '..', pagesCount],
  ],
  [
    ({ pagesCount, currentPage }) => pagesCount - currentPage < 4,
    ({ pagesCount }) => [
      1,
      '..',
      pagesCount - 5,
      pagesCount - 4,
      pagesCount - 3,
      pagesCount - 2,
      pagesCount - 1,
      pagesCount,
    ],
  ],
  [
    R.T,
    ({ pagesCount, currentPage }) => [
      1,
      '..',
      currentPage - 2,
      currentPage - 1,
      currentPage,
      currentPage + 1,
      currentPage + 2,
      '..',
      pagesCount,
    ],
  ],
]);
