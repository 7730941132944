import { useLazyQuery } from '@apollo/client';

import { useMultipleSubscriptions } from './useMultipleSubscriptions.js';
import { useDebounceRefetchByOptions } from './useDebounceRefetchByOptions.js';

export const useLazyQueryWithSubscription = (
  query,
  subscription,
  { queryOptions, subscriptionOptions, refetchDebounce = 2000 },
) => {
  const [queryHandler, { data, loading, refetch, called }] = useLazyQuery(
    query,
    queryOptions,
  );

  const debouncedRefetch = useDebounceRefetchByOptions({
    refetch,
    queryOptions,
    refetchDebounce,
    subscriptionOptions,
  });

  useMultipleSubscriptions(subscription, subscriptionOptions, {
    debouncedRefetch,
    data,
    called,
  });

  return { data, loading, queryHandler };
};
