import React from 'react';
import { number, shape, string } from 'prop-types';
import { entities } from '@poly/admin-ui/src/constants/entities.js';

import { ToolbarButton } from '../../components/commonSidebarComponents.js';
import { ProjectOccurrence } from '../../../modules/core/constants/projects.js';
import { SidebarMastersTab } from '../../components/commonTabs/SidebarMastersTab.js';
import { useAddProjectSidebar } from '../../ProjectSidebar/forms/add/useAddProjectSidebar.js';
import {
  isAllowProjectCreationOnAdminByClient,
  isClientInactive,
} from '../../ClientSidebar/helpers.js';

export function AddPurchaseOrderProjectButton({
  clientId,
  purchaseOrderId,
  type,
  client,
}) {
  const openAddProjectSidebar = useAddProjectSidebar(
    false,
    entities.ADMIN_PURCHASE_ORDER,
    purchaseOrderId,
  );

  if (!isAllowProjectCreationOnAdminByClient(client)) {
    return null;
  }

  const onAddProject = () => {
    openAddProjectSidebar({
      purchaseOrderId,
      clientId,
      type,
    });
  };

  return (
    <ToolbarButton disabled={isClientInactive(client)} onClick={onAddProject}>
      Add Project
    </ToolbarButton>
  );
}

AddPurchaseOrderProjectButton.propTypes = {
  clientId: string.isRequired,
  type: string.isRequired,
  purchaseOrderId: string.isRequired,
  client: shape({}),
};

export function PurchaseOrderSidebarMastersTab({
  _id,
  client,
  doubleTabWidth,
  tabTitlePrefix,
}) {
  return (
    <SidebarMastersTab
      isCard={false}
      tabWidth={doubleTabWidth}
      tabTitlePrefix={tabTitlePrefix}
      entity={{ _id, name: entities.ADMIN_PURCHASE_ORDER }}
      toolBar={
        <AddPurchaseOrderProjectButton
          clientId={client._id}
          type={ProjectOccurrence.RECURRING_OCCURRENCE}
          purchaseOrderId={_id}
          client={client}
        />
      }
    />
  );
}

PurchaseOrderSidebarMastersTab.propTypes = {
  _id: string,
  client: shape({ _id: string }),
  doubleTabWidth: number.isRequired,
  tabTitlePrefix: string,
};
