import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { string, shape, number, bool } from 'prop-types';
import {
  Table,
  BodyRow,
  Row,
  Cell,
  moneyColumnStyles,
  getThemeColor,
} from '@poly/admin-book';
import { useMapConfigToTableProps } from '@poly/admin-ui';
import {
  formatTotal,
  applyProp,
  formatMoneyTransactionPayeeNameColumn,
  formatDate,
} from '@poly/utils';

import {
  reconciliationReportDataPropType,
  reconciliationReportSectionsPropType,
} from './prop-types.js';
import { generateReconciliationReportRows } from './generateTableRows.js';
import { RECONCILIATION_CUSTOM_ROW_TYPE } from './generateTableRowsHelpers.js';

const CustomRowText = styled.div`
  font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
  padding-left: ${({ indentLevel = 0 }) => indentLevel * 150 - 5}px;
`;

const getBorderValueByProps = ({ hasAmountBorder, ...props }) =>
  hasAmountBorder ? `2px solid ${getThemeColor(['black'])(props)}` : 'none';

const getTopBorderValueByProps = ({ hasNoTopAmountBorder, hasAmountBorder }) =>
  getBorderValueByProps({
    hasAmountBorder: !hasNoTopAmountBorder && hasAmountBorder,
  });

const CustomReconciliationTableRowS = styled(Row)`
  td:nth-child(2) {
    border-top: ${getTopBorderValueByProps};
    border-bottom: ${getBorderValueByProps};
  }
`;

const ReconciliationReportTableS = styled(Table)`
  th,
  td {
    height: unset;
  }

  ${moneyColumnStyles(4)};
  ${moneyColumnStyles(5)};

  th:nth-child(1),
  th:nth-child(2),
  th:nth-child(4),
  th:nth-child(5) {
    width: 150px;
  }

  ${Row} {
    td:nth-child(1),
    td:nth-child(2) {
      width: 150px;
    }

    td:nth-child(4),
    td:nth-child(5) {
      width: 150px;
    }
  }

  ${CustomReconciliationTableRowS} {
    th:nth-child(1),
    td:nth-child(1) {
      width: calc(100% - 300px);
    }

    ${moneyColumnStyles(2)};
    ${moneyColumnStyles(3)};
    td:nth-child(2),
    td:nth-child(3) {
      width: 150px;
    }
  }
`;

const reconciliationReportTableConfig = [
  ['Date', applyProp(formatDate)('date')],
  ['Number', R.prop('reference_column')],
  ['Name', formatMoneyTransactionPayeeNameColumn],
  ['Bank Clearing Date', applyProp(formatDate)('bank_cleared_date')],
  ['Amount', applyProp(formatTotal)('amount')],
  ['Balance', R.always('')],
];

// formatTotalIfNumber :: Any -> String
const formatTotalIfNumber = R.ifElse(R.is(Number), formatTotal, R.always(''));

const customRowShapePropType = shape({
  text: string,
  amount: number,
  balance: number,
  isBold: bool,
  hasAmountBorder: bool,
  hasNoTopAmountBorder: bool,
  indentLevel: number,
});

const SubTextContainer = styled.span`
  font-weight: normal;
  margin-left: 50px;
`;

const EmptyColumnS = styled.td`
  height: 30px !important;
`;

function CustomReconciliationTableRow({
  row: {
    text,
    subText,
    amount,
    balance,
    isBold,
    hasAmountBorder,
    hasNoTopAmountBorder,
    indentLevel,
    isEmpty,
  },
  index,
  className,
}) {
  return (
    <CustomReconciliationTableRowS
      index={index}
      hasAmountBorder={hasAmountBorder}
      hasNoTopAmountBorder={hasNoTopAmountBorder}
      className={className}
    >
      {!isEmpty ? (
        <>
          <Cell>
            <CustomRowText isBold={isBold} indentLevel={indentLevel}>
              {text}
              {subText && <SubTextContainer>{subText}</SubTextContainer>}
            </CustomRowText>
          </Cell>
          <Cell>{formatTotalIfNumber(amount)}</Cell>
          <Cell>{formatTotalIfNumber(balance)}</Cell>
        </>
      ) : (
        <EmptyColumnS />
      )}
    </CustomReconciliationTableRowS>
  );
}

CustomReconciliationTableRow.propTypes = {
  row: customRowShapePropType.isRequired,
  index: number.isRequired,
  className: string,
};

function ReconciliationReportTableRow(props) {
  return props.row.type === RECONCILIATION_CUSTOM_ROW_TYPE ? (
    <CustomReconciliationTableRow {...props} />
  ) : (
    <BodyRow {...props} />
  );
}

ReconciliationReportTableRow.propTypes = {
  className: string,
  row: shape({ type: string.isRequired }),
};

export function ReconciliationReportTable({
  data,
  reportSections,
  outstandingReportSections,
  isCreditCard,
  ...restProps
}) {
  const { rows, headers, columns } = useMapConfigToTableProps(
    generateReconciliationReportRows(
      reportSections,
      outstandingReportSections,
      isCreditCard,
    ),
    reconciliationReportTableConfig,
    data,
  );

  return (
    <ReconciliationReportTableS
      headers={headers}
      rows={rows}
      columns={columns}
      RowComponent={ReconciliationReportTableRow}
      moreScrollIconPosition="10px"
      showScrollBar
      overflow="auto"
      {...restProps}
    />
  );
}

ReconciliationReportTable.propTypes = {
  data: reconciliationReportDataPropType,
  reportSections: reconciliationReportSectionsPropType.isRequired,
  outstandingReportSections: reconciliationReportSectionsPropType.isRequired,
  isCreditCard: bool.isRequired,
};
