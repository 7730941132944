import React, { useEffect } from 'react';
import {
  ContentWrapper,
  DefaultBodyWrapper,
  getThemeColor,
  Loader,
  MainHeader,
  PageHeaderContainer,
} from '@poly/admin-book';
import { gql, useQuery } from '@apollo/client';
import styled from 'styled-components';
import { FlexContainer, useNotificationState } from '@poly/admin-ui';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { UPDATE_PROPERTY_HIERARCHY_PERMISSION } from '@poly/security';
import { TitleWithBackButton } from '@poly/admin-ui/src/components/pagesHeadersComps.js';

import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { PropertyHierarchyTreeWithSearch } from './PropertyHierarchyTreeWithSearch/PropertyHierarchyTreeWithSearch.js';
import { ClientHierarchiesSelector } from './ClientHierarchiesSelector.js';
import { AddPropertyHierarchyButton } from './AddPropertyHierarchyButton.js';
import { useHierarchyDetailsQuery } from './useHierarchyDetailsQuery.js';
import { useHierarchyOptions } from './useHierarchyOptions.js';

const HeaderSearchContainer = styled(FlexContainer)`
  justify-content: space-between;
  flex-grow: 1;
`;

const ContentContainerS = styled(DefaultBodyWrapper)`
  background: #fff;
`;

const ContentWrapperS = styled(ContentWrapper)`
  background-color: ${getThemeColor(['lightest'])};
  max-width: 1440px;
  margin: 20px 0;
  border: 1px solid ${getThemeColor(['midLight'])};
  padding: 0;
  flex-direction: column;
`;

const DEFAULT_CLIENT_QUERY = gql`
  query DEFAULT_CLIENT_QUERY($id: ID!) {
    client(id: $id) {
      _id
      name
    }
  }
`;

export function PropertyHierarchyManagementPage() {
  const { searchFilters, handlers, updateFiltersValues } = useSearchFilters([
    { name: 'clientId' },
    { name: 'hierarchyId' },
  ]);

  const { showErrorNotification } = useNotificationState();

  useEffect(() => {
    if (!searchFilters.clientId) {
      showErrorNotification('Client/Hierarchy is not found');
    }
  }, []);

  const hasPermissionToManageHierarchy = useHasUserAccessWithPermission(
    UPDATE_PROPERTY_HIERARCHY_PERMISSION,
  );

  const { data: clientData } = useQuery(DEFAULT_CLIENT_QUERY, {
    variables: { id: searchFilters.clientId },
    skip: !searchFilters.clientId,
  });

  const setNewHierarchySelected = (hierarchyId) => {
    updateFiltersValues({ hierarchyId });
  };

  const { hierarchyObj, loading, refetch } = useHierarchyDetailsQuery(
    searchFilters.clientId,
    searchFilters.hierarchyId,
  );

  const {
    options,
    loading: optionsLoading,
    refetch: refetchOptions,
  } = useHierarchyOptions(searchFilters.clientId);

  const onAddSuccess = (hierarchy) => {
    refetchOptions();
    updateFiltersValues({
      clientId: hierarchy.client._id,
      hierarchyId: hierarchy._id,
    });
  };

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>
          <TitleWithBackButton
            title={`${clientData?.client?.name || 'Client'} Hierarchies`}
          />
        </MainHeader>
        <HeaderSearchContainer>
          <FlexContainer>
            <ClientHierarchiesSelector
              value={searchFilters.hierarchyId}
              options={options}
              loading={optionsLoading}
              clientId={searchFilters.clientId}
              onChange={handlers.hierarchyId}
              changeClientId={handlers.clientId}
            />
          </FlexContainer>
          {hasPermissionToManageHierarchy && (
            <AddPropertyHierarchyButton
              onSuccess={onAddSuccess}
              clientId={searchFilters.clientId}
              setNewHierarchySelected={setNewHierarchySelected}
            />
          )}
        </HeaderSearchContainer>
      </PageHeaderContainer>
      <ContentContainerS>
        {hierarchyObj && hasPermissionToManageHierarchy && (
          <ContentWrapperS>
            {loading ? (
              <Loader />
            ) : (
              <PropertyHierarchyTreeWithSearch
                clientId={searchFilters.clientId}
                hierarchyId={searchFilters.hierarchyId}
                hierarchyObj={hierarchyObj}
                refetch={refetch}
              />
            )}
          </ContentWrapperS>
        )}
      </ContentContainerS>
    </>
  );
}
