export const MISSING_TOKEN_ERROR_MESSAGE = 'Token is missing';
export const EXPIRED_ACCESS_LINK_ERROR_MESSAGE = 'Your access link is expired';
export const INVOICE_ALREADY_UPDATED_ERROR_MESSAGE =
  'The invoice has been received already';
export const SUPPLIER_DOCUMENT_NOT_EXPIRED_ERROR_MESSAGE =
  'The supplier has no missing or expired documents';
export const SERVICE_TICKET_ALREADY_RECEIVED_ERROR_MESSAGE =
  'This service ticket was already been received';
export const SETUP_2FA_REQUIRED_ERROR_MESSAGE = '2FA setup is required';

export const ASSET_PROJECT_ALREADY_EXIST_ERROR = 'Project already exist';

export const CLIENT_MARKUP_RULES_ERROR =
  'You need to add Client Markup Rules before project create';
