import React from 'react';
import { FormCreator } from '@poly/admin-book';
import { usePristineSubscribe } from '@poly/client-routing';

import { func, object, string } from 'prop-types';
import { useNotificationState } from '../../../../hooks/useNotificationState.js';
import { useOnSubmitSetStopSubmitting } from '../../../../hooks/useOnSubmitSetStopSubmitting.js';
import { useCloseCurrentModal } from '../../../../redux/modal.js';
import { serviceTypesSections } from './sections.js';
import { modalFormLayout } from '../../common.js';

export const ServiceTypeForm = React.memo(
  ({ initialValues, formId, successMessage, onCancel, mutate }) => {
    const pristineSubscribeProps = usePristineSubscribe();
    const { showSuccessNotification } = useNotificationState();
    const closeCurrentModal = useCloseCurrentModal();

    const handleSubmit = (input) =>
      mutate(input).then(() => {
        showSuccessNotification(successMessage);
        onCancel();
      });

    const { onSubmit } = useOnSubmitSetStopSubmitting(formId, handleSubmit);

    return (
      <FormCreator
        id={formId}
        sections={serviceTypesSections}
        layout={modalFormLayout}
        initialValues={initialValues}
        onSubmit={onSubmit}
        closeCurrentModal={closeCurrentModal}
        {...pristineSubscribeProps}
      />
    );
  },
);

ServiceTypeForm.displayName = 'ServiceTypeForm';

ServiceTypeForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: object,
  formId: string.isRequired,
  successMessage: string,
  onCancel: func.isRequired,
  mutate: func.isRequired,
};
