import * as R from 'ramda';
import { forceTitleCase } from '@poly/utils';

// formatSelectOptions :: ConstantsDictionary -> [{label: String, value: String}]
export const formatSelectOptions = R.pipe(
  R.defaultTo({}),
  R.map(forceTitleCase),
  (obj) =>
    Object.keys(obj).map((item) => ({
      label: obj[item],
      value: R.toLower(item),
    })),
);
