import React from 'react';
import { AddSupplierForm as CommonAddSupplierForm } from '@poly/admin-ui';
import { SupplierSources } from '@poly/constants';
import {
  getCommonRouterProps,
  useNavigate,
  useLocation,
  useParams,
} from '@poly/client-routing';

import { addSupplierFormId } from './constants.js';
import { routesNames } from '../../../routes/index.js';

export function AddSupplierForm(props) {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const routerProps = {
    push: navigate,
    router: getCommonRouterProps({ params, location }),
  };

  return (
    <CommonAddSupplierForm
      {...props}
      {...routerProps}
      id={addSupplierFormId}
      supplierSource={SupplierSources.AAC}
      rootUrl={routesNames.SUPPLIER}
      masterSupplierRootUrl={routesNames.MASTER_SUPPLIER}
      isSupplierTypeEditable
    />
  );
}
