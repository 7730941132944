import {
  bool,
  func,
  shape,
  string,
  number,
  arrayOf,
  instanceOf,
} from 'prop-types';
import * as R from 'ramda';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import {
  AdminCheckTypes,
  JournalPaymentMode,
  SystemAccountTypes,
} from '@poly/constants';
import { useLocation, useNavigate } from '@poly/client-routing';
import {
  alwaysNewDate,
  insertQueryParamsIntoURL,
  propEqLegacy,
} from '@poly/utils';
import {
  useOnSubmitSetStopSubmitting,
  useNotificationState,
  useProcessState,
  MAX_ITEMS,
} from '@poly/admin-ui';
import {
  NumberInputHTML5,
  getThemeColor,
  InputErrorMsg,
  FormField,
  Select,
} from '@poly/admin-book';

import {
  DatePickerS,
  MoneyInputAsCentsS,
  BottomPanelButtonsContainer,
} from './PaySuppliersComponents.js';
import {
  unpinnedBottomPanelStyles,
  UnpinnedBottomPanelBody,
} from '../../components/BottomPanel.js';
import { routesNames } from '../../routes/index.js';
import { FlexColumn } from '../../components/FlexContainer.js';
import { paymentTypesOptions } from './paySuppliersConstants.js';
import { CCUsersSelect } from '../../components/CCUsersSelect.js';
import { AccountsSelect } from '../../components/AccountsSelect.js';
import { invoicePaymentPropTypes } from './paySupplierInvoicesPropTypes.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { paySupplierInvoicesMutation } from './paySupplierInvoivesHOCs.js';
import { filterAccountsBySystemType } from '../ChartOfAccounts/helper.js';
import { AdminCheckTypeSelect } from '../../components/CheckTypeSelect.js';
import {
  prepareFormDateForSubmit,
  preparePaymentsForSubmit,
  calculateDeductionsTotal,
  isCreditsMemoAvailable,
  calculatePaidTotal,
} from './paySuppliersUtils/payInvoicesUtils.js';
import {
  validatePaySuppliersForm,
  checkIsAllPaymentsValid,
  validateRequiredField,
} from './paySuppliersUtils/validationUtils.js';
import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';

const defaultValues = (currentDate) => ({
  paymentMode: JournalPaymentMode.CHECK,
  checkType: AdminCheckTypes.manual,
  deductions: 0,
  total: 0,
  date: currentDate,
});

const successPaymentsMsg = 'Payments successfully generated';

const PaySupplierInvoicesFormS = styled.form`
  ${unpinnedBottomPanelStyles}
`;

const paySuppliersFormId = 'paySuppliersFormId';

const accountsFiltersMap = {
  [JournalPaymentMode.CREDIT_CARD]: filterAccountsBySystemType(
    SystemAccountTypes.CREDIT_CARD,
  ),
  [JournalPaymentMode.CHECK]: filterAccountsBySystemType(
    SystemAccountTypes.BANK_ACCOUNT,
  ),
  [JournalPaymentMode.ACH]: filterAccountsBySystemType(
    SystemAccountTypes.BANK_ACCOUNT,
  ),
  [JournalPaymentMode.WIRE]: filterAccountsBySystemType(
    SystemAccountTypes.BANK_ACCOUNT,
  ),
};

const InputErrorMsgS = styled(InputErrorMsg)`
  width: 200px;
  padding-top: 0;
  margin-top: -5px;
  font-size: 14px;
`;

const CreditAvailableTextS = styled.div`
  color: ${getThemeColor(['accent1Lighter1'])};
  margin-right: 15px;
`;

const MaxItemsTextS = styled.div`
  color: ${getThemeColor(['notificator', 'warning', 'text'])};
  margin-right: 75px;
`;

function BankSelect({ change, paymentMode, ...props }) {
  useEffect(() => change('bankAccountId', null), [paymentMode]);
  return <AccountsSelect {...props} extractValue={R.prop('_id')} />;
}

BankSelect.propTypes = {
  change: func.isRequired,
  paymentMode: string,
};

// shouldDisplayBeginningChkNo :: { paymentMode: String, checkType: String } -> Boolean
const shouldDisplayBeginningChkNo = R.both(
  propEqLegacy('paymentMode', JournalPaymentMode.CHECK),
  propEqLegacy('checkType', AdminCheckTypes.manual),
);

function PaySupplierInvoicesFormRendered({
  handleSubmit,
  values,
  invoices,
  errors,
  hasValidationErrors,
  deductionsTotal,
  paidTotal,
  isAllPaymentsValid,
  changeFormField,
  setPaidTotal,
  setDeductionsTotal,
  loading,
}) {
  const position = invoices.length < 6 ? 'bottom' : 'top';
  const displayBeginningChkNo = shouldDisplayBeginningChkNo(values);

  useEffect(() => {
    if (invoices && !loading && invoices.length === 0) {
      changeFormField('bankAccountId', '');
      changeFormField('beginningCheckNumber', '');
      changeFormField('date', alwaysNewDate());
      changeFormField('paymentMode', JournalPaymentMode.CHECK);
      changeFormField('checkType', AdminCheckTypes.manual);
      setPaidTotal(0);
      setDeductionsTotal(0);
    }
  }, [invoices]);
  return (
    <PaySupplierInvoicesFormS
      height="160px"
      id={paySuppliersFormId}
      onSubmit={handleSubmit}
    >
      <UnpinnedBottomPanelBody>
        <FlexSpaceBetween>
          <FlexColumn>
            <SearchHeaderColumn title="Payment Mode" titleWidth="110px">
              <FormField
                name="paymentMode"
                Component={Select}
                additionalProps={{
                  width: '100%',
                  options: paymentTypesOptions,
                }}
              />
            </SearchHeaderColumn>
            {values.bankAccountId &&
              values.paymentMode === JournalPaymentMode.CREDIT_CARD && (
                <SearchHeaderColumn title="CC User" titleWidth="110px">
                  <FormField
                    name="creditCardUserId"
                    Component={CCUsersSelect}
                    additionalProps={{
                      queryInput: { accountId: values.bankAccountId },
                      direction: invoices.length > 5 ? 'up' : 'down',
                      width: '100%',
                      required: true,
                      isAutoPopulateSingleUserFound: true,
                      changeFieldValue: changeFormField,
                    }}
                  />
                </SearchHeaderColumn>
              )}
            {values.paymentMode === JournalPaymentMode.CHECK && (
              <SearchHeaderColumn title="Check Type" titleWidth="110px">
                <FormField
                  required
                  name="checkType"
                  Component={AdminCheckTypeSelect}
                  additionalProps={{ width: '100%', required: true }}
                />
              </SearchHeaderColumn>
            )}
          </FlexColumn>
          <FlexColumn>
            <SearchHeaderColumn title="Bank Name" titleWidth="100px">
              <FormField
                name="bankAccountId"
                Component={BankSelect}
                validate={validateRequiredField('Bank is required')}
                additionalProps={{
                  paymentMode: values.paymentMode,
                  change: changeFormField,
                  width: '100%',
                  filterAccounts: accountsFiltersMap[values.paymentMode],
                  required: true,
                }}
              />
            </SearchHeaderColumn>
            <SearchHeaderColumn title="Date" titleWidth="100px">
              <FormField
                name="date"
                Component={DatePickerS}
                validate={validateRequiredField('Date is required')}
                additionalProps={{ position, width: '240px' }}
              />
            </SearchHeaderColumn>
          </FlexColumn>
          <FlexColumn>
            <SearchHeaderColumn title="Deductions" filterWidth="140px">
              <MoneyInputAsCentsS
                value={deductionsTotal}
                width="130px"
                name="deductionsAmount"
                dataTestId="deductions-total-amount-input"
                disabled
                allowNegative
              />
            </SearchHeaderColumn>
            {displayBeginningChkNo && (
              <SearchHeaderColumn title="Beginning Chk No" filterWidth="140px">
                <FormField
                  name="beginningCheckNumber"
                  Component={NumberInputHTML5}
                  additionalProps={{
                    width: '130px',
                    maxLength: 6,
                    dataTestId: 'check-number-input',
                    required: true,
                  }}
                />
              </SearchHeaderColumn>
            )}
          </FlexColumn>
          <FlexColumn>
            <SearchHeaderColumn
              title="Total"
              filterWidth="130px"
              titleWidth="50px"
            >
              <MoneyInputAsCentsS
                value={paidTotal}
                width="130px"
                name="totalAmount"
                dataTestId="total-amount-input"
                disabled
                allowNegative
              />
            </SearchHeaderColumn>
            {errors.invoices && (
              <InputErrorMsgS>{errors.invoices}</InputErrorMsgS>
            )}
          </FlexColumn>
        </FlexSpaceBetween>
        <BottomPanelButtonsContainer>
          {invoices.length >= MAX_ITEMS && (
            <MaxItemsTextS>
              The max number of records (10,000) has been reached. Please Submit
              in multiple batches
            </MaxItemsTextS>
          )}

          {isCreditsMemoAvailable(invoices) && (
            <CreditAvailableTextS data-testid="credit-memo-warning">
              Unapplied Supplier Credit Memo Available
            </CreditAvailableTextS>
          )}
          <SearchHeaderButton
            type="submit"
            size="small"
            form={paySuppliersFormId}
            disabled={loading || hasValidationErrors || !isAllPaymentsValid}
            data-testid="submit-pay-invoices-btn"
            loader={loading}
          >
            Generate Payment
          </SearchHeaderButton>
        </BottomPanelButtonsContainer>
      </UnpinnedBottomPanelBody>
    </PaySupplierInvoicesFormS>
  );
}

PaySupplierInvoicesFormRendered.propTypes = {
  handleSubmit: func.isRequired,
  values: shape({
    bankAccount: string,
    date: instanceOf(Date),
    beginningCheckNumber: number,
    paymentMode: string,
  }),
  invoices: arrayOf(invoicePaymentPropTypes),
  errors: shape({
    invoices: string,
  }),
  hasValidationErrors: bool,
  deductionsTotal: number,
  paidTotal: number,
  isAllPaymentsValid: bool,
  changeFormField: func.isRequired,
  loading: bool,
  setPaidTotal: func.isRequired,
  setDeductionsTotal: func.isRequired,
};

const FormWrapper = styled.td`
  width: 100%;
`;

export function PaySupplierInvoicesBottomForm({ rows, loading }) {
  const invoices = useSelector(R.prop('invoices'));
  const [paidTotal, setPaidTotal] = useState(0);
  const [deductionsTotal, setDeductionsTotal] = useState(0);

  const { showSuccessNotification } = useNotificationState();
  const [paySupplierInvoices] = useMutation(paySupplierInvoicesMutation);
  const { process } = useProcessState(paySuppliersFormId);
  const location = useLocation();
  const navigate = useNavigate();

  const historyPushTimeout = (redirectUrl) => {
    setTimeout(() => {
      navigate(redirectUrl, { state: { previous: location } });
    }, 1000);
  };

  useEffect(() => {
    setPaidTotal(calculatePaidTotal(invoices));
    setDeductionsTotal(calculateDeductionsTotal(invoices));
  }, [invoices]);

  const isAllPaymentsValid = checkIsAllPaymentsValid(rows);

  const submitHandler = async (values) => {
    const { creditCardUserId } = values;
    await paySupplierInvoices({
      variables: {
        input: {
          ...prepareFormDateForSubmit(values),
          payments: preparePaymentsForSubmit(creditCardUserId)(invoices),
        },
      },
    });
    showSuccessNotification(successPaymentsMsg);
    if (values.paymentMode === JournalPaymentMode.CHECK) {
      const redirectUrl = insertQueryParamsIntoURL(
        R.pick(['checkType'], values),
        routesNames.PRINT_CHECKS,
      );

      historyPushTimeout(redirectUrl);
    } else {
      navigate(0);
    }
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    paySuppliersFormId,
    submitHandler,
  );

  return invoices.length === 0 ? null : (
    <tr>
      <FormWrapper>
        <Form
          onSubmit={onSubmit}
          initialValues={defaultValues(alwaysNewDate())}
          validate={validatePaySuppliersForm(invoices)}
          validateOnBlur={false}
          keepDirtyOnReinitialize
        >
          {({
            values,
            handleSubmit,
            errors,
            hasValidationErrors,
            form: { change },
          }) => (
            <PaySupplierInvoicesFormRendered
              values={values}
              handleSubmit={handleSubmit}
              errors={errors}
              hasValidationErrors={hasValidationErrors}
              changeFormField={change}
              deductionsTotal={deductionsTotal}
              paidTotal={paidTotal}
              isAllPaymentsValid={isAllPaymentsValid}
              invoices={invoices}
              setPaidTotal={setPaidTotal}
              setDeductionsTotal={setDeductionsTotal}
              loading={loading || process}
            />
          )}
        </Form>
      </FormWrapper>
    </tr>
  );
}

PaySupplierInvoicesBottomForm.propTypes = {
  loading: bool,
  rows: arrayOf(shape({ _id: string.isRequired })),
};
