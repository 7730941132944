import React from 'react';
import { invoiceToTypes } from '@poly/constants';
import { Select } from '@poly/admin-book';

export function InvoiceSelectField(props) {
  const options = [
    { value: invoiceToTypes.BRANCH_ACCOUNT, label: 'Branch Account' },
    { value: invoiceToTypes.MAIN_ACCOUNT, label: 'Main Account' },
  ];

  return <Select {...props} options={options} />;
}

InvoiceSelectField.displayName = 'PropertyInvoiceSelectField';
