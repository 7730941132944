import * as R from 'ramda';

import { areAccessItemsEqual } from './access-items-comparison.js';
import { replaceSelfWithUserId } from './self-support.js';

/**
 * hasUserAccessWithAi :: AccessItem -> User -> Boolean
 */
export const hasUserAccessWithAi = R.curryN(
  2,
  R.compose(
    R.complement(R.isNil),
    // eslint-disable-next-line react-hooks/rules-of-hooks
    R.useWith(R.find, [
      areAccessItemsEqual,
      R.compose(R.defaultTo([]), R.prop('accessItems'), replaceSelfWithUserId),
    ]),
  ),
);
