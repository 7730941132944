import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import * as R from 'ramda';
import {
  func,
  shape,
  instanceOf,
  string,
  object,
  bool,
  arrayOf,
  oneOfType,
} from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';

import { DatePicker } from '../DatePicker/index.js';
import { getThemeColor } from '../utils.js';
import {
  DatePickerButtons,
  DateRangeToButton,
} from '../DatePicker/constants.js';

const columnStyles = css`
  flex-direction: column;

  & > div:first-child {
    margin: 0 0 14px 0;
  }

  input {
    padding: 6px 6px 6px 0;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div:first-child {
    margin: 0 10px 0 0;
  }

  ${({ styleType }) => styleType === 'column' && columnStyles};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RangeBtn = styled.div`
  position: relative;
`;

const DateRange = styled.div`
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: -4px;
  left: 25px;
  min-width: 70px;
  min-height: 100px;
  background-color: ${getThemeColor(['white'])};
  box-shadow: -2px 2px 6px 0 #dbdde6;
  border-radius: 2px;
  padding: 4px 12px;
  z-index: 100000;

  ${({ rangeStyles }) => rangeStyles};
`;

const DateRangeButton = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: ${getThemeColor(['black'])};
  margin: 8px 0;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    color: ${getThemeColor(['primaryLighter4'])};
  }
`;

const ShowRangeBtn = styled.div`
  box-sizing: border-box;
  position: relative;
  min-width: 15px;
  height: 26px;
  cursor: pointer;
  overflow: hidden;

  &:after {
    content: '...';
    position: absolute;
    top: 1px;
    left: 8px;
    font-size: 22px;
    color: ${getThemeColor(['primaryLighter2'])};
    transform: rotate(90deg);
  }
`;

export function DateRangePicker({
  onChange,
  value: { startDate, endDate },
  className,
  customRangeButtons,
  getCustomRangeToButtons,
  isClosedRange,
  width,
  styleType,
  leftMove,
  rangeStyles,
  ...anotherProps
}) {
  const [isShowRange, setIsShowRange] = useState(false);

  const buttons = R.values(customRangeButtons || DatePickerButtons);

  const selectRange = (name) => {
    if (isClosedRange) {
      setIsShowRange(false);
    }
    const dateRange =
      getCustomRangeToButtons && customRangeButtons
        ? getCustomRangeToButtons({ startDate, endDate })
        : DateRangeToButton;

    const { from, to } = dateRange[name];
    onChange({ startDate: from, endDate: to });
  };

  return (
    <Container className={className} styleType={styleType}>
      <Wrapper>
        <DatePicker
          {...anotherProps}
          width={width}
          disabledDays={[
            {
              after: new Date(endDate),
            },
          ]}
          value={startDate}
          onChange={(date) => onChange({ endDate, startDate: date })}
          isMultiple={false}
        />
      </Wrapper>
      <Wrapper>
        <DatePicker
          {...anotherProps}
          leftMove={leftMove}
          width={styleType === 'column' ? `calc(${width} - 15px)` : width}
          disabledDays={[
            {
              before: new Date(startDate),
            },
          ]}
          value={endDate}
          onChange={(date) => onChange({ startDate, endDate: date })}
          isMultiple={false}
        />
        <RangeBtn>
          <ShowRangeBtn onClick={() => setIsShowRange(!isShowRange)} />
          {isShowRange && (
            <OutsideClickHandler onOutsideClick={() => setIsShowRange(false)}>
              <DateRange rangeStyles={rangeStyles}>
                {buttons.map((name) => (
                  <DateRangeButton key={name} onClick={() => selectRange(name)}>
                    {name}
                  </DateRangeButton>
                ))}
              </DateRange>
            </OutsideClickHandler>
          )}
        </RangeBtn>
      </Wrapper>
    </Container>
  );
}

DateRangePicker.propTypes = {
  onChange: func.isRequired,
  value: shape({
    startDate: oneOfType([instanceOf(Date), string]),
    endDate: oneOfType([instanceOf(Date), string]),
  }).isRequired,
  className: string,
  // eslint-disable-next-line react/forbid-prop-types
  customRangeButtons: object,
  getCustomRangeToButtons: func,
  isClosedRange: bool,
  styleType: string,
  width: string,
  leftMove: string,
  rangeStyles: oneOfType([arrayOf(string), string]),
};
DateRangePicker.defaultProps = {
  className: '',
  rangeStyles: '',
  isClosedRange: true,
};
