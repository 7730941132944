import React from 'react';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { arrayOf, element, object, oneOfType, bool } from 'prop-types';

import { getThemeColor } from '../../utils.js';

const cardStyles = css`
  background-color: ${getThemeColor(['white'])};
  border-radius: 4px;
  box-shadow: 0 1px 4px -1px ${getThemeColor(['primaryLighter7'])};
  margin: 0 0 20px 0;
  padding: 25px;
  height: ${R.propOr('min-content', 'height')};
  min-height: 100%;

  & > div {
    margin: 0;
  }

  & > div:not(:last-child) {
    margin-bottom: 25px;
  }
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ total, width }) =>
    total ? `calc((100% - 30px)/${total})` : width};
  height: ${R.prop('height')};
  padding: ${R.propOr(0, 'padding')};

  ${({ card }) => (card ? cardStyles : '')};
`;

export function GroupLayout({ defaultLayout, layout, children, ...props }) {
  const groupLayout = R.mergeDeepRight(defaultLayout, layout);
  return (
    <Group {...groupLayout} {...props}>
      {children}
    </Group>
  );
}

GroupLayout.displayName = 'GroupLayout';
GroupLayout.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  defaultLayout: object,
  layout: object.isRequired,
  card: bool,
  children: oneOfType([arrayOf(element), element]).isRequired,
};
GroupLayout.defaultProps = {
  defaultLayout: { width: '100%' },
  card: true,
};
