import React, { useState, useEffect } from 'react';
import { oneOfType, func, string, bool, number } from 'prop-types';

import { Input } from './index.js';

export function InputHTML5({
  onChange,
  changeOnBlur,
  value,
  maxLength,
  type,
  onBlur,
  ...props
}) {
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    if (internalValue !== value) {
      setInternalValue(value);
    }
  }, [value]);

  const onBlurInternal = (e) => {
    onBlur(e);
    if (changeOnBlur) {
      onChange(e.target.value);
    }
  };

  const onInternalChange = (e) => {
    const newValue = e.target.value;
    if (!maxLength || (maxLength && newValue.toString().length <= maxLength)) {
      setInternalValue(newValue);
      if (changeOnBlur !== true) {
        onChange(newValue);
      }
    }
  };

  return (
    <Input
      {...props}
      value={internalValue}
      type={type}
      onChange={onInternalChange}
      onBlur={onBlurInternal}
    />
  );
}

InputHTML5.defaultProps = {
  onBlur: () => {},
};

InputHTML5.propTypes = {
  value: oneOfType([string, number]),
  changeOnBlur: bool,
  onChange: func.isRequired,
  onBlur: func,
  maxLength: number,
  type: string,
  autoFocus: bool,
};

// this one always return number!
export function NumberInputHTML5({
  onChange,
  maxValue,
  decimalScale,
  ...props
}) {
  const handleOnChange = (value) => onChange(value ? parseFloat(value) : value);

  return <InputHTML5 {...props} onChange={handleOnChange} type="number" />;
}

NumberInputHTML5.propTypes = {
  onChange: func.isRequired,
  maxValue: number,
  decimalScale: number,
};
