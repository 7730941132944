import React from 'react';
import { SystemAccountTypes } from '@poly/constants';

import { ReconciliationReportPageBase } from './BankReconciliationReport/ReconciliationReportPageBase.js';

const reportSections = [
  {
    // because credit cards are
    // credit normal accounts therefore
    // deposits and payments swap
    propName: 'deposits',
    title: 'Payments',
  },
  {
    propName: 'payments',
    title: 'Charges',
  },
];

const outstandingReportSections = [
  {
    propName: 'outstandingDeposits',
    title: 'Checks & Payments',
  },
  {
    propName: 'outstandingPayments',
    title: 'Deposits & Credits',
  },
];

export function CreditCardReconciliationReportPage() {
  return (
    <ReconciliationReportPageBase
      title="Credit Card Reconciliation Report"
      reportSections={reportSections}
      outstandingReportSections={outstandingReportSections}
      systemAccountType={SystemAccountTypes.CREDIT_CARD}
    />
  );
}
