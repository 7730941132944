import { gql } from '@apollo/client';

export const commonTimeSheetFields = gql`
  fragment commonTimeSheetFields on Timesheet {
    _id
    type
    date
    project {
      _id
      type
      projectId
      client {
        _id
        name
      }
      property {
        _id
        name
      }
    }
    owner {
      _id
      fullName
    }
    totalTimeInMinutes
    notes
    entries {
      timeIn
      timeOut
    }
    createdAt
  }
`;
