import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, func, number, shape, string } from 'prop-types';
import { MoneyInputAsCents } from '@poly/admin-ui';
import {
  Checkbox,
  FormField,
  Icon,
  Input,
  InputHTML5,
  getThemeColor,
} from '@poly/admin-book';

import {
  InvoiceLinesSectionTitle,
  QuarterWidthContainer,
  HalfQuarterWidthContainer,
} from './styled.js';
import { validateRequiredField } from '../PaySuppliersPage/paySuppliersUtils/validationUtils.js';
import { calculateLineTotal } from './helpers.js';

const DeleteTimeEntryIcon = styled(Icon).attrs((props) => ({
  color: props.color ? props.color : getThemeColor(['secondaryMid'])(props),
  cursor: 'pointer',
}))`
  margin-left: 10px;
`;

const NonMarkupCheckbox = styled(Checkbox)`
  margin-top: 10px;
`;

// validatePositiveNumberField :: String -> Object -> String
const validatePositiveNumberField = (errMsg) =>
  R.ifElse(R.either(R.isNil, R.lte(R.__, 0)), R.always(errMsg), R.F);

// validateNumberField :: String -> Object -> String
const validateNumberField = (errMsg) =>
  R.ifElse(R.isNil, R.always(errMsg), R.F);

export function InvoiceLineField({
  name,
  index,
  fields: { value: values, remove },
}) {
  const { isProjectTime } = values[index];
  return (
    <InvoiceLinesSectionTitle>
      <QuarterWidthContainer>
        <FormField
          Component={InputHTML5}
          name={`${name}.name`}
          additionalProps={{
            width: '100%',
            required: true,
            dataTestId: `${name}.name`,
            disabled: isProjectTime,
          }}
          validator={validateRequiredField('Description is required')}
        />
      </QuarterWidthContainer>
      <HalfQuarterWidthContainer>
        <FormField
          Component={NonMarkupCheckbox}
          name={`${name}.isNonMarkup`}
          additionalProps={{
            width: '100%',
            dataTestId: `${name}.isNonMarkup`,
            disabled: isProjectTime,
          }}
        />
      </HalfQuarterWidthContainer>
      <HalfQuarterWidthContainer>
        <FormField
          Component={Input}
          name={`${name}.quantity`}
          additionalProps={{
            width: '100%',
            required: true,
            type: 'numberMask',
            decimalScale: 2,
            floatValue: true,
            dataTestId: `${name}.quantity`,
            disabled: isProjectTime,
          }}
          validate={validatePositiveNumberField('Quantity is required')}
        />
      </HalfQuarterWidthContainer>
      <QuarterWidthContainer>
        <FormField
          Component={MoneyInputAsCents}
          name={`${name}.rate`}
          additionalProps={{
            width: '100%',
            required: true,
            allowNegative: true,
            dataTestId: `${name}.rate`,
            disabled: isProjectTime,
          }}
          validate={validateNumberField('Rate is required')}
        />
      </QuarterWidthContainer>
      <QuarterWidthContainer>
        <MoneyInputAsCents
          allowNegative
          name="lineTotal"
          value={calculateLineTotal(values[index])}
          height="38px"
          width="100%"
          disabled
          dataTestId={`${name}.total`}
        />
        {values.length > 1 && (
          <DeleteTimeEntryIcon
            name="delete"
            size={14}
            color={isProjectTime ? '#c1c2c3' : null}
            onClick={() => (isProjectTime ? null : remove(index))}
          />
        )}
      </QuarterWidthContainer>
    </InvoiceLinesSectionTitle>
  );
}

InvoiceLineField.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  fields: shape({
    value: arrayOf(shape({ quantity: number, rate: number })),
    remove: func.isRequired,
  }),
};
