import * as R from 'ramda';
import { gql } from '@apollo/client';
import styled from 'styled-components';
import React, { useMemo, useEffect } from 'react';
import { string, shape, number } from 'prop-types';
import { AssetStatuses, NOTHING_UI_STRING } from '@poly/constants';
import { useRouterQuery } from '@poly/client-routing';
import { mapIndexed, propEqLegacy } from '@poly/utils';
import {
  useInfiniteScrollQueryWithSubscription,
  keywordSortQuery,
  pathOrNothingUI,
} from '@poly/client-utils';
import {
  useSelectableTableRowsProps,
  useMapConfigToTableProps,
  useTableSorting,
  useTableSearch,
} from '@poly/admin-ui';

import { ApproveNewEquipmentPopup } from '../../components/ApproveNewEquipmentPopup/ApproveNewEquipmentPopup.js';
import { useSidebarLogicContext } from '../../sidebars/SidebarLogicContext.js';

const SEARCH_ASSETS_SUB = gql`
  subscription SEARCH_ASSETS_SUB($input: CollectionSearchParams!) {
    searchAssetChanged(input: $input) {
      id
      type
    }
  }
`;

const SEARCH_ASSETS_QUERY = gql`
  query SEARCH_ASSETS_QUERY($input: CollectionSearchParams!) {
    searchAssets(input: $input) {
      hits {
        _id
        type {
          _id
          name
        }
        displayName
        newModelName
        newManufacturerName
        manufacturerDoc {
          _id
          name
        }
        modelDoc {
          _id
          name
        }
        property {
          _id
          name
        }
      }
      total
    }
  }
`;

const NewEquipmentWrapperS = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  word-break: break-all;
`;

function AssetReviewLink({ displayName, ...props }) {
  const { AssetLink } = useSidebarLogicContext();

  return <AssetLink {...props}>{displayName}</AssetLink>;
}

AssetReviewLink.propTypes = { displayName: string.isRequired };

function AssetReviewPropertyLink({ property = {} }) {
  const { PropertyLink } = useSidebarLogicContext();

  return <PropertyLink {...property} />;
}

AssetReviewPropertyLink.propTypes = {
  property: shape({ _id: string.isRequired, name: string.isRequired }),
};

function AssetReviewManufacturer({
  _id,
  index,
  newManufacturerName,
  ...props
}) {
  return newManufacturerName ? (
    <NewEquipmentWrapperS>
      {newManufacturerName}
      <ApproveNewEquipmentPopup
        assetId={_id}
        entity="manufacturer"
        equipmentName={newManufacturerName}
        position={index <= 5 ? 'bottom' : 'top'}
      />
    </NewEquipmentWrapperS>
  ) : (
    pathOrNothingUI(['manufacturerDoc', 'name'])(props)
  );
}

AssetReviewManufacturer.propTypes = {
  _id: string.isRequired,
  index: number.isRequired,
  newManufacturerName: string,
};

function AssetReviewModel({ _id, index, newModelName, ...props }) {
  const manufacturerId = R.path(['manufacturerDoc', '_id'], props);

  if (!manufacturerId) return NOTHING_UI_STRING;

  if (newModelName)
    return (
      <NewEquipmentWrapperS>
        {newModelName}
        <ApproveNewEquipmentPopup
          left={260}
          assetId={_id}
          entity="model"
          equipmentName={newModelName}
          manufacturerId={manufacturerId}
          position={index <= 5 ? 'bottom' : 'top'}
        />
      </NewEquipmentWrapperS>
    );

  return pathOrNothingUI(['modelDoc', 'name'])(props);
}

AssetReviewModel.propTypes = {
  newModelName: string,
  _id: string.isRequired,
  index: number.isRequired,
};

const assetReviewTableConfig = [
  ['Asset ID', AssetReviewLink, keywordSortQuery(['displayName'])],
  ['Property', AssetReviewPropertyLink, keywordSortQuery(['property', 'name'])],
  [
    'Type',
    pathOrNothingUI(['type', 'name']),
    keywordSortQuery(['type', 'name']),
  ],
  ['Manufacturer', AssetReviewManufacturer],
  ['Model', AssetReviewModel],
];

// getAssetNameById :: ID -> [Asset] -> String
const getAssetNameById = (id) =>
  R.compose(R.prop('displayName'), R.find(propEqLegacy('_id', id)));

// prepareAssetsData :: SearchAssetsQueryResult -> [Asset]
const prepareAssetsData = R.compose(
  mapIndexed((asset, index) => ({ ...asset, index })),
  R.pathOr([], ['searchAssets', 'hits']),
);

// checkSelectedAssetsForError :: [ID] -> [Asset] -> Boolean
const checkSelectedAssetsForError = (selectedAssets) =>
  R.ifElse(
    () => R.isEmpty(selectedAssets),
    R.F,
    R.compose(
      R.complement(R.isEmpty),
      R.filter(
        R.both(
          R.propSatisfies(R.includes(R.__, selectedAssets), '_id'),
          R.either(R.prop('newModelName'), R.prop('newManufacturerName')),
        ),
      ),
    ),
  );

export const useSearchAssetReviewQuery = ({
  hasError,
  setHasError,
  rejectedAsset,
  selectedAssets,
  setRejectedAsset,
  setSelectedAssets,
}) => {
  const { searchText } = useTableSearch();
  const { clientId, propertyId } = useRouterQuery(['clientId', 'propertyId']);

  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: assetReviewTableConfig,
    column: 1,
  });

  const input = useMemo(
    () => ({
      sort,
      searchTerm: searchText,
      query: {
        bool: {
          must: [
            { match: { status: AssetStatuses.PENDING } },
            ...(clientId ? [{ match: { clientId } }] : []),
            ...(propertyId ? [{ match: { propertyId } }] : []),
          ],
        },
      },
    }),
    [clientId, propertyId, searchText, sort],
  );

  const { data, loading, tableProps } = useInfiniteScrollQueryWithSubscription(
    SEARCH_ASSETS_QUERY,
    input,
    { endpointName: 'searchAssets', skip: !clientId },
    SEARCH_ASSETS_SUB,
    { input },
  );

  const preparedData = useMemo(() => prepareAssetsData(data), [data]);

  useEffect(() => {
    const rejectedId = selectedAssets[0];
    const assetName = rejectedId
      ? getAssetNameById(rejectedId)(preparedData)
      : null;

    const selectedAssetsHasError =
      checkSelectedAssetsForError(selectedAssets)(preparedData);

    if (assetName !== rejectedAsset) {
      setRejectedAsset(assetName);
    }

    if (hasError !== selectedAssetsHasError) {
      setHasError(selectedAssetsHasError);
    }
  }, [selectedAssets, preparedData]);

  const { toggleRow, toggleSelectAll } = useSelectableTableRowsProps(
    selectedAssets,
    setSelectedAssets,
    preparedData,
  );

  const dataTableProps = useMapConfigToTableProps(
    R.identity,
    assetReviewTableConfig,
    preparedData,
  );

  return {
    loading,
    clientId,
    tableProps: {
      toggleRow,
      toggleSelectAll,
      isRowSelectable: R.T,
      selectedRows: selectedAssets,
      ...tableProps,
      ...dataTableProps,
      ...tableSortingProps,
    },
  };
};
