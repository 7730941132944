import {
  func,
  bool,
  shape,
  object,
  string,
  arrayOf,
  oneOfType,
  instanceOf,
} from 'prop-types';
import * as R from 'ramda';
import React, { useMemo, memo } from 'react';
import styled, { css } from 'styled-components';
import { gql, useSubscription } from '@apollo/client';
import { collectionNames, SYSTEM_USER_NAME } from '@poly/constants';
import { isNilOrEmpty, formatDateTime } from '@poly/utils';
import { useLocation } from '@poly/client-routing';
import {
  getThemeColor,
  defaultTheme,
  BodyRow,
  Icon,
  Text,
} from '@poly/admin-book';

import { UpdateDetails } from './UpdateDetails.js';
import { temporalUpdateId } from '../../../utils/updates.js';
import { UpdateReplySection } from './UpdateReplySection.js';
import { UpdateCommentsSection } from './UpdateCommentsSection.js';
import { useScrollIntoViewByRef } from '../../../hooks/useScrollIntoViewByRef.js';
import { checkIfPinnedByCollection } from './updatesInfoUtils.js';
import { useToggleUpdatePin } from './useToggleUpdatePin.js';
import { useCopyLinkHook } from './useCopyLinkHook.js';

const {
  colors: { mid, darkest },
} = defaultTheme;

export const UPDATE_COMMENTS_CHANGED = gql`
  subscription UPDATE_COMMENTS_CHANGED($input: UpdateCommentChangedInput!) {
    searchUpdateCommentChanged(input: $input) {
      id
      type
    }
  }
`;

const linkedRowStyles = css`
  background-color: ${getThemeColor(['light'])};
  border: 1px solid ${getThemeColor(['primaryLight'])};
`;

const borderStyles = css`
  :not(:first-child) {
    border-top: none;
  }
`;

const BodyRowS = styled(BodyRow)`
  border-bottom: 1px solid ${getThemeColor(['midLight'])};
  ${({ isUpdateLinked }) => !isUpdateLinked && borderStyles};
  ${({ isUpdateLinked }) => isUpdateLinked && linkedRowStyles};
`;

// checkUpdateLinked :: ID -> String -> Boolean
const checkUpdateLinked = (id) =>
  R.ifElse(
    R.either(R.isNil, R.isEmpty),
    R.F,
    R.compose(R.equals(id), R.last, R.split('_')),
  );

const UpdatesTableRowReplySection = memo(({ showReplySection, ...props }) => {
  if (!showReplySection) return null;

  return <UpdateReplySection {...props} />;
});

UpdatesTableRowReplySection.propTypes = { showReplySection: bool.isRequired };

export function UpdatesTableRow({ row, ...props }) {
  const { _id, isReplyActive, updateComments, debouncedRefetch } = row;

  const showReplySection = useMemo(
    () => !!isReplyActive && isReplyActive === _id,
    [isReplyActive],
  );

  const showCommentsSection = useMemo(
    () => !isNilOrEmpty(updateComments),
    [updateComments],
  );

  const { hash } = useLocation();

  const isUpdateLinked = useMemo(() => checkUpdateLinked(_id)(hash), [hash]);

  const { ref } = useScrollIntoViewByRef(isUpdateLinked);

  useSubscription(UPDATE_COMMENTS_CHANGED, {
    shouldResubscribe: updateComments,
    onData: debouncedRefetch,
    variables: { input: { updateId: _id } },
  });

  return (
    <>
      <BodyRowS {...{ row, isUpdateLinked, ref, ...props }} />
      {showCommentsSection && <UpdateCommentsSection {...row} />}
      <UpdatesTableRowReplySection
        {...row}
        showReplySection={showReplySection}
      />
    </>
  );
}

UpdatesTableRow.propTypes = {
  row: shape({
    _id: string.isRequired,
    isReplyActive: string,
    debouncedRefetch: func,
    updateComments: arrayOf(shape({ _id: string.isRequired })),
  }).isRequired,
};

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 7px;
`;

const DateText = styled(Text)`
  color: ${getThemeColor(['midDark'])};
  font-size: 12px;
  line-height: 18px;
  margin-right: 20px;
`;

const RightSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    :not(:last-child) {
      margin-right: 15px;
    }

    :hover {
      fill: ${getThemeColor(['primaryRegular'])};
    }
  }
`;

// getUserFullName :: User -> String
const getUserFullName = R.pathOr(SYSTEM_USER_NAME, ['fullName']);

// isNotTemporalUpdateId :: ID -> Boolean
const isNotTemporalUpdateId = R.complement(R.equals(temporalUpdateId));

export function UpdatesTableInfoSection({
  _id,
  type,
  pinned,
  createdAt,
  createdBy,
  collection,
  withoutIcons,
  skipCopyLink,
  setActiveReply,
  updateCacheParams,
  ...props
}) {
  const commonProps = { color: mid, size: 12 };

  const copyLink = useCopyLinkHook('update', _id);
  const [toggleUpdatePin] = useToggleUpdatePin({ _id, updateCacheParams });

  const isProjectUpdate =
    collection === collectionNames.projects ||
    collection === collectionNames.recurringProjects;

  const showPinIcon = useMemo(
    () => checkIfPinnedByCollection(collection) && isNotTemporalUpdateId(_id),
    [collection, _id],
  );

  const openReplyForm = () => {
    if (isNotTemporalUpdateId(_id)) {
      setActiveReply(_id);
    }
  };

  return (
    <SectionWrapper>
      <TitleContainer>
        <Text lineHeight="18px" size="12px">
          {getUserFullName(createdBy)}
        </Text>
        <RightSectionWrapper id="updates-icons">
          <DateText>{formatDateTime(createdAt)}</DateText>
          {!withoutIcons && (
            <>
              {showPinIcon && (
                <Icon
                  size={18}
                  name="pin"
                  color={darkest}
                  onClick={toggleUpdatePin}
                  fill={pinned ? darkest : 'none'}
                />
              )}
              {!skipCopyLink && (
                <Icon {...commonProps} name="copy-link" onClick={copyLink} />
              )}
              {isProjectUpdate && (
                <Icon name="reply" {...commonProps} onClick={openReplyForm} />
              )}
            </>
          )}
        </RightSectionWrapper>
      </TitleContainer>
      <UpdateDetails {...props} />
    </SectionWrapper>
  );
}

UpdatesTableInfoSection.propTypes = {
  type: string,
  pinned: bool,
  withoutIcons: bool,
  skipCopyLink: bool,
  collection: string,
  setActiveReply: func,
  _id: string.isRequired,
  createdAt: oneOfType([string, instanceOf(Date)]),
  createdBy: shape({ fullName: string.isRequired }),
  updateCacheParams: shape({
    // eslint-disable-next-line react/prop-types,react/forbid-prop-types
    query: object,
    entity: string,
    // eslint-disable-next-line react/prop-types,react/forbid-prop-types
    variables: object,
  }),
};
