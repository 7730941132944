import * as R from 'ramda';
import { applySpecWithFields } from '@poly/utils';

import { formattingInternationalPhone } from '../../../../components/InternationalAddressAndPhone/InternationalPhone/internationalPhoneUtils.js';

/**
 * preparedInternationalPhones :: FormData -> ModifiedFormData
 * FormData = Object
 * ModifiedFormData = Object
 */
export const preparedInternationalPhones = applySpecWithFields({
  workPhone: R.compose(
    R.ifElse(R.startsWith('+'), formattingInternationalPhone, (phone) => phone),
    R.propOr('', 'workPhone'),
  ),
  mobilePhone: R.compose(
    R.ifElse(R.startsWith('+'), formattingInternationalPhone, (phone) => phone),
    R.propOr('', 'mobilePhone'),
  ),
});
