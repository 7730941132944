import React from 'react';
import { bool, element, func } from 'prop-types';

import OutsideClickHandler from 'react-outside-click-handler';

export function PopUp({
  trigger,
  children,
  isOpen,
  close,
  outsideClickDisabled,
}) {
  return (
    <OutsideClickHandler
      onOutsideClick={() => isOpen && close()}
      disabled={outsideClickDisabled}
    >
      {trigger}
      {isOpen ? children : null}
    </OutsideClickHandler>
  );
}

PopUp.propTypes = {
  trigger: element.isRequired,
  children: element.isRequired,
  isOpen: bool.isRequired,
  close: func.isRequired,
  outsideClickDisabled: bool,
};
