import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { getThemeColor, Text } from '@poly/admin-book';
import { Link } from '@poly/client-routing';
import { propEqLegacy } from '@poly/utils';

import { FlexColumn, FlexContainer } from '../../components/FlexContainer.js';

const getCountBackgroundColor = R.cond([
  [propEqLegacy('children', 0), getThemeColor(['midLight'])],
  [R.prop('color'), ({ color, ...props }) => getThemeColor([color])(props)],
  [R.T, getThemeColor(['midLight'])],
]);

export const PageContainer = styled(FlexContainer)`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  overflow-y: scroll;
`;

export const CompanyWorkflowContainer = styled(FlexColumn)`
  width: 275px;
  background-color: ${getThemeColor(['lightest'])};
  padding: 30px;
`;

export const MyWorkflowContainer = styled(FlexColumn)`
  width: calc(100% - 275px);
  padding: 30px 40px;
`;

export const SectionTitleText = styled(Text)`
  font-family: 'TTNormsMedium', sans-serif;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: ${R.propOr(0, 'margin')};
  color: ${getThemeColor(['primary'])};
`;

export const HeaderText = styled(Text)`
  font-family: 'TTNormsMedium', sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: ${R.propOr('10px', 'margin')};
  padding-top: ${R.propOr('10px', 'padding')};
  color: ${getThemeColor(['primary'])};
`;

export const SubHeaderText = styled(Text)`
  font-family: 'TTNormsMedium', sans-serif;
  font-size: ${R.propOr('12px', 'size')};
  line-height: 18px;
  font-weight: 400;
  color: ${getThemeColor(['midDark'])};
  margin-bottom: ${R.propOr('10px', 'margin')};
`;

export const SectionContainer = styled(FlexColumn)`
  width: ${R.prop('width')};
  margin: ${R.propOr('0 0 14px 0', 'margin')};
`;

export const ItemLabelText = styled(Text)`
  flex: 1;
  font-family: 'TTNormsMedium', sans-serif;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: normal;
  color: ${getThemeColor(['primary'])};
`;

export const TotalItemContainer = styled(Link)`
  display: flex;
  margin: ${R.propOr('0 0 6px 0', 'margin')};
  align-items: center;
  padding: ${R.propOr('0 0 6px 0', 'padding')};
  border-bottom: 1px solid ${getThemeColor(['midLight'])};
  text-decoration: none;
`;

const linkModifiedStyles = css`
  padding: 2px 6px;
  border-radius: 5px;
  color: ${R.ifElse(
    R.either(propEqLegacy('children', 0), R.propSatisfies(R.isNil, 'color')),
    getThemeColor(['primaryRegular']),
    getThemeColor(['white']),
  )};
  background-color: ${getCountBackgroundColor};
`;

export const TextBlock = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: ${({ color }) => getThemeColor([color])};
  text-decoration: none;
  ${({ modify }) => (modify === 'counter' ? linkModifiedStyles : '')};
`;
