import React from 'react';
import { string } from 'prop-types';
import {
  QlFormats,
  ButtonQlSmaller,
  ButtonQlBigger,
  ButtonQlDone,
  Toolbar,
  Button,
} from './styles.js';

export function CustomToolbar({ id }) {
  return (
    <Toolbar id={id} onMouseDown={(e) => e.preventDefault()}>
      <QlFormats className="ql-formats">
        <Button className="ql-bold" />
        <Button className="ql-italic" />
        <Button className="ql-underline" />
        <select className="ql-color">
          <option value="#000000" />
          <option value="#23303c" />
          <option value="#5c5f64" />
          <option value="#3246a0" />
          <option value="#5cb516" />
          <option value="#e9c83a" />
          <option value="#cd1444" />
          <option value="#772ca8" />
        </select>
      </QlFormats>
      <QlFormats className="ql-formats">
        <ButtonQlBigger className="ql-bigger">A</ButtonQlBigger>
        <ButtonQlSmaller className="ql-smaller">A</ButtonQlSmaller>
        <Button className="ql-list" value="bullet" />
        <Button className="ql-list" value="ordered" />
        <Button className="ql-link" />
      </QlFormats>
      <ButtonQlDone className="ql-done ql-close">done</ButtonQlDone>
    </Toolbar>
  );
}

CustomToolbar.propTypes = { id: string.isRequired };
