import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { useMapConfigToTableProps } from '@poly/admin-ui';
import { NOTHING_UI_STRING, ProjectType } from '@poly/constants';
import {
  moneyColumnStyles,
  getThemeColor,
  useMoreScroll,
  Row,
} from '@poly/admin-book';
import {
  calculateTotal,
  formatDate,
  renameProp,
  centsToDollarsWithFormat,
  propEqLegacy,
  ofArrayLegacy,
} from '@poly/utils';
import { getFeeProjectSupplierName } from '@poly/utils/src/projects/common.js';

import {
  CellTitleS,
  TableHeaderC,
} from '../../modules/tables/commonTableComps.js';
import {
  CellS,
  RowTotalS,
  CellTotalS,
  BorderedRow,
  TableFlexRow,
  formatSupplierLink,
  commonSpendReportTableConfig,
} from '../SpendReport/spendReportsTable/SpendReportsTableC.js';
import {
  CollapseFooter,
  CollapseFooterTotalC,
  HierarchyCollapse,
} from '../SpendReport/spendReportsTable/SpendReportsHierarchyTreeTable.js';
import { SpendReportErrorPopover } from './SpendReportErrorPopover.js';
import { getListOfReportErrors } from './prepareBatchSpendReport.js';
import { ProjectTimeOrAfterHoursCallWarning } from '../BatchInvoicingTM/ProjectTimeOrAfterHoursCallWarning.js';

export const BatchSpendReportTableS = styled.table`
  background-color: ${getThemeColor(['lightest'])};
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  border-spacing: 0;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    background: transparent;
    display: none;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 15%;
  }

  ${moneyColumnStyles(7, 130)};
  ${moneyColumnStyles(8, 130)};
  ${moneyColumnStyles(9, 130)};
  ${moneyColumnStyles(10, 130)};
`;

const TotalCell = styled(CellTotalS)`
  width: 130px !important;
  margin-right: ${R.propOr('0px', 'marginRight')};
  font-size: 14px;
`;

// centsToDollarsWithFormatByPropName :: String -> Object -> String
const centsToDollarsWithFormatByPropName = (propName, defaultTo = null) =>
  R.compose(
    R.when(R.is(Number), centsToDollarsWithFormat),
    R.defaultTo(defaultTo),
    R.prop(propName),
  );

// getProjectSpendReportRows :: Project -> [ProjectWithInvoice]
const getProjectSpendReportRows = (project) =>
  R.compose(
    R.map(renameProp('invoiceFileUrl', 'file')),
    R.converge(R.concat, [
      R.compose(
        ofArrayLegacy,
        R.mergeRight(R.dissoc('invoices', project)),
        R.head,
      ),
      R.compose(
        R.map(R.mergeRight(R.pick(['clientGLCode', 'approvedBy'], project))),
        R.tail,
      ),
    ]),
    R.propOr([], 'invoices'),
  )(project);

// calculateTotalWithFormat :: String -> [Object] -> Number
const calculateTotalWithFormat = (propName) =>
  R.compose(centsToDollarsWithFormat, calculateTotal(R.propOr(0, propName)));

// getPropertySpendReportRows :: [PropertyInvoicesReport] -> [[ProjectInvoiceRow]]
// ProjectInvoiceRow = {
//    propertyRows: [[ProjectWithInvoice]]
//    totalInvoices: Number
//    totalClientInvoices: Number
//    taxTotal: Number
//    mgtFeeTotal: Number
// }
const getPropertySpendReportRows = R.compose(
  R.mergeAll,
  R.juxt([
    R.compose(
      R.applySpec({
        mgtFeeTotal: calculateTotalWithFormat('managementFee'),
        taxTotal: calculateTotalWithFormat('taxAmount'),
        totalInvoices: calculateTotalWithFormat('supplierInvoicesAmount'),
        totalClientInvoices: calculateTotalWithFormat(
          'clientInvoiceAmountWithTax',
        ),
      }),
      R.unnest,
    ),
    R.objOf('propertyRows'),
  ]),
  R.map(getProjectSpendReportRows),
  R.defaultTo([]),
);

// checkRowHasError :: InvoiceRow -> Boolean
const checkRowHasError = R.compose(
  R.complement(R.isEmpty),
  getListOfReportErrors,
);

function BatchChildPropertySpendReport({
  propertyInvoicesReport,
  propertyName,
  propertyId,
  columns,
  headers,
}) {
  const {
    propertyRows,
    totalClientInvoices,
    totalInvoices,
    taxTotal,
    mgtFeeTotal,
  } = getPropertySpendReportRows(propertyInvoicesReport);

  return (
    <>
      <Row key={`row_${propertyId}_title`}>
        <CellTitleS>{propertyName}</CellTitleS>
      </Row>
      {propertyRows.map((invoices, rowIndex) =>
        invoices.map((invoice, index) => (
          <BorderedRow
            enableHoverEffect
            key={`row_${invoice._id}`}
            hasError={checkRowHasError(invoice)}
            isLast={index === invoices.length - 1}
          >
            {columns.map((ColumnComp, columnNum) => (
              <CellS
                key={`cell_${propertyId}_${invoice._id}_${headers[columnNum]?.title}`}
              >
                {ColumnComp({ ...invoice, rowIndex })}
              </CellS>
            ))}
          </BorderedRow>
        )),
      )}
      {!R.isEmpty(propertyRows) && (
        <RowTotalS key={`row_${propertyId}_total`}>
          <TotalCell>{totalInvoices}</TotalCell>
          <TotalCell>{mgtFeeTotal}</TotalCell>
          <TotalCell>{taxTotal}</TotalCell>
          <TotalCell marginRight="14px">{totalClientInvoices}</TotalCell>
        </RowTotalS>
      )}
    </>
  );
}

const propertyInvoicesReportPropType = shape({
  _id: string.isRequired,
  projectNumber: string.isRequired,
  clientInvoiceAmountWithTax: number.isRequired,
  supplierInvoicesAmount: number.isRequired,
  taxAmount: number.isRequired,
  managementFee: number.isRequired,
  invoices: arrayOf(
    shape({
      _id: string,
      invoiceDate: string,
      supplier: shape({ _id: string, company: shape({ name: string }) }),
      invoiceNumber: string,
      file: string,
      invoiceAmount: number,
    }),
  ),
});

BatchChildPropertySpendReport.propTypes = {
  propertyInvoicesReport: arrayOf(propertyInvoicesReportPropType),
  propertyName: string,
  propertyId: string,
  columns: arrayOf(func),
  headers: arrayOf(shape({ title: string })),
};

function BatchPropertySpendReport({
  childProperties,
  propertyId,
  columns,
  headers,
  ...props
}) {
  return (
    <TableFlexRow key={`row_${propertyId}`}>
      <BatchChildPropertySpendReport
        propertyId={propertyId}
        columns={columns}
        headers={headers}
        {...props}
      />
      {childProperties?.map((childProperty) => (
        <BatchChildPropertySpendReport
          key={`child-property-${childProperty?.propertyId}`}
          columns={columns}
          headers={headers}
          {...childProperty}
        />
      ))}
    </TableFlexRow>
  );
}

BatchPropertySpendReport.propTypes = {
  columns: arrayOf(func),
  headers: arrayOf(shape({ title: string })),
  propertyId: string,
  childProperties: arrayOf(shape),
};

// defaultNothingUI :: Any -> String | Any
const defaultNothingUI = R.defaultTo(NOTHING_UI_STRING);

const InvoiceTotalWrapper = styled.div`
  margin-right: 5px;
`;

function InvoiceTotalComp(props) {
  const total = centsToDollarsWithFormatByPropName(
    'clientInvoiceAmountWithTax',
  )(props);

  return (
    <InvoiceTotalWrapper>
      {total}
      <ProjectTimeOrAfterHoursCallWarning
        {...props}
        positionRight="5px"
        withProjectTimeWarning
      />
    </InvoiceTotalWrapper>
  );
}

export const batchSpendReportTableConfig = [
  ...commonSpendReportTableConfig,
  ['Date', R.compose(defaultNothingUI, formatDate, R.prop('invoiceDate'))],
  [
    'Supplier',
    R.compose(
      defaultNothingUI,
      R.ifElse(
        propEqLegacy('projectType', ProjectType.FEE),
        getFeeProjectSupplierName,
        R.compose(R.unless(R.isNil, formatSupplierLink), R.prop('supplier')),
      ),
    ),
  ],
  [
    'GLCode',
    R.ifElse(
      R.path(['clientGLCode', 'code']),
      R.path(['clientGLCode', 'code']),
      SpendReportErrorPopover,
    ),
  ],
  [
    'Approved By',
    R.ifElse(
      R.path(['approvedBy', 'fullName']),
      R.path(['approvedBy', 'fullName']),
      () => (
        <SpendReportErrorPopover
          totalLevelErrors={['Please check that Approved By is missing']}
        />
      ),
    ),
  ],
  ['Amount', centsToDollarsWithFormatByPropName('invoiceAmount', 0)],
  ['Mgt.Fee', centsToDollarsWithFormatByPropName('managementFee')],
  ['Tax', centsToDollarsWithFormatByPropName('taxAmount')],
  ['Total', InvoiceTotalComp],
];

export function BatchSpendReportTable({ reports, withoutHierarchy }) {
  const { headers, columns, rows } = useMapConfigToTableProps(
    R.identity,
    batchSpendReportTableConfig,
    reports,
  );

  const { MoreScrollIcon, onScroll } = useMoreScroll('0px');

  return (
    <>
      <BatchSpendReportTableS onScroll={onScroll}>
        {withoutHierarchy && <TableHeaderC headers={headers} />}
        {rows.map((row) => (
          <BatchPropertySpendReport
            key={row.propertyId}
            {...row}
            columns={columns}
            headers={headers}
          />
        ))}
      </BatchSpendReportTableS>
      <MoreScrollIcon />
    </>
  );
}

BatchSpendReportTable.propTypes = {
  withoutHierarchy: bool,
  reports: arrayOf(
    shape({
      propertyInvoicesReport: arrayOf(propertyInvoicesReportPropType),
      propertyName: string,
      propertyId: string,
    }),
  ),
};

function BatchSpendReportCollapseFooter({
  clientInvoicesSubtotal,
  invoicesSubtotal,
  taxSubtotal,
  mgtFeeSubtotal,
}) {
  return (
    <CollapseFooter>
      <CollapseFooterTotalC
        isFooter
        width="112px"
        label="Subtotal Amount:"
        total={centsToDollarsWithFormat(invoicesSubtotal)}
      />
      <CollapseFooterTotalC
        isFooter
        width="112px"
        label="Subtotal Mgt.Fee:"
        total={centsToDollarsWithFormat(mgtFeeSubtotal)}
      />
      <CollapseFooterTotalC
        isFooter
        width="112px"
        label="Subtotal Tax:"
        total={centsToDollarsWithFormat(taxSubtotal)}
      />
      <CollapseFooterTotalC
        width="112px"
        isFooter
        label="Total:"
        total={centsToDollarsWithFormat(clientInvoicesSubtotal)}
      />
    </CollapseFooter>
  );
}

BatchSpendReportCollapseFooter.propTypes = {
  clientInvoicesSubtotal: number.isRequired,
  invoicesSubtotal: number.isRequired,
  taxSubtotal: number.isRequired,
  mgtFeeSubtotal: number.isRequired,
};

export function BatchSpendReportCollapse({ report, ...props }) {
  const { totalLevel, ...restReport } = report;
  return (
    <HierarchyCollapse
      {...props}
      {...restReport}
      clientInvoiceTotal={centsToDollarsWithFormat(totalLevel)}
      FooterComponent={BatchSpendReportCollapseFooter}
    />
  );
}

export const batchSpendReportPropType = shape({
  clientInvoicesSubtotal: number.isRequired,
  invoicesSubtotal: number.isRequired,
  mgtFeeSubtotal: number.isRequired,
  name: string.isRequired,
  properties: arrayOf(
    shape({
      propertyId: string,
      propertyName: string,
      propertyInvoicesReport: arrayOf(propertyInvoicesReportPropType),
    }),
  ),
  taxSubtotal: number.isRequired,
  totalLevel: number.isRequired,
});

BatchSpendReportCollapse.propTypes = {
  report: batchSpendReportPropType,
};
