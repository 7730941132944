import * as R from 'ramda';
import { validateFormData } from '@poly/client-utils';
import { JournalPaymentMode } from '@poly/constants';
import { isNilOrEmpty, propEqLegacy } from '@poly/utils';

import {
  hasCommonMasterSupplier,
  checkMultipleSuppliers,
  filterSelectedInvoices,
  calculatePaidTotal,
  isInvoiceSelected,
} from './payInvoicesUtils.js';

// validateInvoiceIf :: (InvoicePayment -> Boolean) -> (Invoice -> Boolean) -> (Invoice, FormValues) -> Error
// Invoice, FormValues = Object
// Error = String
const validateInvoiceIf = (pred) => (validator) => (invoice, values) =>
  !pred(invoice) || validator(invoice, values);

// validateInvoiceIfSelected :: (InvoicePayment -> Boolean) -> (Invoice, FormValues) -> Error
const validateInvoiceIfSelected = validateInvoiceIf(isInvoiceSelected);

// validateCreditInvoice :: (InvoicePayment -> Boolean) -> (Invoice, FormValues) -> Error
const validateCreditInvoice = validateInvoiceIf(
  R.both(R.propSatisfies(R.lt(R.__, 0), 'currentBalance'), isInvoiceSelected),
);

// validateRegularInvoice :: (InvoicePayment -> Boolean) -> (Invoice, FormValues) -> Error
const validateRegularInvoice = validateInvoiceIf(
  R.both(R.propSatisfies(R.gt(R.__, 0), 'currentBalance'), isInvoiceSelected),
);

// validateFieldIfNotNull :: (String, InvoicePayment -> Boolean) -> (Invoice, FormValues) -> Boolean
const validateFieldIfNotNull = (field, validate) =>
  R.either(R.propSatisfies(isNilOrEmpty, field), validate);

// validateInvoiceSuppliersByPaymentMode :: String -> [Invoice] -> FormValues -> Boolean
const validateInvoiceSuppliersByPaymentMode = R.curry(
  (mode, invoices, formValues) =>
    R.ifElse(
      R.always(propEqLegacy('paymentMode', mode, formValues)),
      R.compose(
        R.ifElse(hasCommonMasterSupplier, R.T, checkMultipleSuppliers),
        filterSelectedInvoices,
      ),
      R.T,
    )(invoices),
);

const payInvoiceValidationConfig = [
  {
    path: 'discountAccountId',
    validators: [
      [
        validateInvoiceIfSelected(
          R.complement(
            R.both(
              R.propSatisfies(R.gt(R.__, 0), 'deductionsAmount'),
              R.propSatisfies(R.isNil, 'discountAccountId'),
            ),
          ),
        ),
        'Gl Disc Code is required',
      ],
    ],
  },
  {
    path: 'paidAmount',
    validators: [
      [
        validateInvoiceIfSelected(
          validateFieldIfNotNull(
            'paidAmount',
            R.propSatisfies(R.complement(R.equals(0)), 'paidAmount'),
          ),
        ),
        'Payment is required',
      ],
      [
        validateRegularInvoice(
          validateFieldIfNotNull(
            'paidAmount',
            R.propSatisfies(R.gt(R.__, 0), 'paidAmount'),
          ),
        ),
        'Invalid payment amount',
      ],
      [
        validateCreditInvoice(
          validateFieldIfNotNull(
            'paidAmount',
            R.propSatisfies(R.lt(R.__, 0), 'paidAmount'),
          ),
        ),
        'Invalid payment amount',
      ],
      [
        validateInvoiceIfSelected(
          validateFieldIfNotNull(
            'paidAmount',
            R.ifElse(
              R.propSatisfies(R.gt(R.__, 0), 'currentBalance'),
              R.converge(R.lte, [
                R.prop('paidAmount'),
                R.prop('currentBalance'),
              ]),
              R.converge(R.gte, [
                R.prop('paidAmount'),
                R.prop('currentBalance'),
              ]),
            ),
          ),
        ),
        'Payment can`t exceed balance',
      ],
    ],
  },
  {
    path: 'deductionsAmount',
    validators: [
      [
        validateInvoiceIfSelected(
          validateFieldIfNotNull(
            'deductionsAmount',
            R.ifElse(
              R.propSatisfies(R.gt(R.__, 0), 'currentBalance'),
              R.converge(R.lte, [
                R.prop('deductionsAmount'),
                R.prop('currentBalance'),
              ]),
              R.converge(R.gte, [
                R.prop('deductionsAmount'),
                R.prop('currentBalance'),
              ]),
            ),
          ),
        ),
        'Deductions can`t exceed balance',
      ],
    ],
  },
  {
    path: 'balanceAmount',
    validators: [
      [
        validateRegularInvoice(
          R.propSatisfies(R.gte(R.__, 0), 'balanceAmount'),
        ),
        'Balance can`t be negative',
      ],
      [
        validateCreditInvoice(R.propSatisfies(R.lte(R.__, 0), 'balanceAmount')),
        'Balance can`t exceed zero',
      ],
    ],
  },
];

// validatePaySupplierInvoice :: (InvoicePayment, FormValues) -> Errors
// InvoicePayment, Errors = Object
// eslint-disable-next-line import/no-unused-modules
export const validatePaySupplierInvoice = (invoice, values) =>
  R.reduce(
    (errors, { path, validators }) =>
      R.compose(
        R.ifElse(
          R.identity,
          R.assocPath([path], R.__, errors),
          R.always(errors),
        ),
        R.prop('1'),
        R.find(([validator]) => !validator(invoice, values)),
      )(validators),
    {},
    payInvoiceValidationConfig,
  );

// validateRequiredField :: String -> FieldValue -> String Boolean
export const validateRequiredField = (errMsg) =>
  R.ifElse(R.identity, R.F, R.always(errMsg));

// formatTransactionName :: InvoicePayment -> String
const getSupplierName = R.prop('supplierName');

// findSupplierInvalidAmountPayments :: (Number -> Boolean) -> [InvoicePayment] -> [InvoicePayment]
const findSupplierInvalidAmountPayments = (checker) =>
  R.compose(
    R.find(R.compose(checker, calculatePaidTotal)),
    R.values,
    R.groupBy(getSupplierName),
    filterSelectedInvoices,
  );

const validatePaySuppliersFormConfig = [
  {
    path: ['invoices'],
    validators: [
      [
        (invoices, formValues) =>
          R.compose(
            R.not,
            R.ifElse(
              R.both(
                R.always(
                  propEqLegacy(
                    'paymentMode',
                    JournalPaymentMode.CHECK,
                    formValues,
                  ),
                ),
                R.any(R.propSatisfies(R.complement(R.isNil), 'paidAmount')),
              ),
              findSupplierInvalidAmountPayments(R.compose(R.equals(R.__, 0))),
              R.F,
            ),
          )(invoices),
        'Not allowed check payments with zero amount',
      ],
      [
        validateInvoiceSuppliersByPaymentMode(JournalPaymentMode.CREDIT_CARD),
        'Credit Card payments are limited to one supplier at a time',
      ],
      [
        validateInvoiceSuppliersByPaymentMode(JournalPaymentMode.WIRE),
        'Wire payments are limited to one supplier at a time',
      ],
    ],
  },
];

// validatePaySuppliersForm :: [InvoicePayment] -> FormValues -> Errors
// eslint-disable-next-line import/no-unused-modules
export const validatePaySuppliersForm = (invoices) =>
  R.compose(
    validateFormData(validatePaySuppliersFormConfig),
    R.mergeRight({ invoices }),
  );

// checkIsAllPaymentsValid :: [InvoicePayment] -> Bool
export const checkIsAllPaymentsValid = R.both(
  R.compose(
    R.isEmpty,
    R.reject(R.anyPass([R.isNil, R.isEmpty])),
    R.map(R.prop('error')),
  ),
  R.compose(R.complement(R.isNil), R.find(propEqLegacy('isSelected', true))),
);
