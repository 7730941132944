import React from 'react';
import styled from 'styled-components';
import { bool, func, shape, string, node } from 'prop-types';
import { Text, Button, ModalActions, Icon } from '@poly/admin-book';
import { FlexContainer } from '../../common.js';

const ModalContainer = styled.div`
  width: 400px;
`;

const ContentWrapper = styled(Text)`
  display: flex;
  width: 100%;
  padding: 15px 0;
  justify-content: ${({ justify }) => (justify ? 'space-between' : 'center')};
`;

const TitleWrapper = styled(Text)`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 5px;
`;

const ButtonS = styled(Button)`
  margin-right: 20px;
`;

const IconS = styled(Icon)`
  margin-right: 10px;
`;

const IconContainerS = styled(FlexContainer)`
  width: 50px;
`;

export function ConfirmModalLayoutC({
  title,
  children,
  textStyleProps,
  btnCaption,
  cancelBtnCaption,
  onConfirm,
  iconName,
  closeCurrentModal,
  loading,
  handleReject,
  withCancel,
}) {
  return (
    <ModalContainer>
      {!!title && <TitleWrapper size="21px">{title}</TitleWrapper>}
      <ContentWrapper {...textStyleProps} size="15px">
        <FlexContainer>
          {iconName && (
            <IconContainerS>
              <IconS name={iconName} />
            </IconContainerS>
          )}
          <Text>{children}</Text>
        </FlexContainer>
      </ContentWrapper>
      <ModalActions>
        <ButtonS
          size="small"
          styleType={withCancel ? 'primaryLight' : 'basicSecondary'}
          type="button"
          disabled={loading}
          onClick={handleReject}
        >
          {cancelBtnCaption}
        </ButtonS>
        <Button
          size="small"
          disabled={loading}
          loader={loading}
          onClick={onConfirm}
        >
          {btnCaption}
        </Button>
        {withCancel && (
          <Button
            size="small"
            disabled={loading}
            loader={loading}
            onClick={closeCurrentModal}
            styleType="basicSecondary"
          >
            Cancel
          </Button>
        )}
      </ModalActions>
    </ModalContainer>
  );
}

ConfirmModalLayoutC.propTypes = {
  cancelBtnCaption: string,
  loading: bool,
  title: string,
  iconName: string,
  content: string,
  btnCaption: string,
  textStyleProps: shape({ justify: bool }),
  onConfirm: func.isRequired,
  closeCurrentModal: func.isRequired,
  children: node.isRequired,
  handleReject: func.isRequired,
  withCancel: bool,
};

ConfirmModalLayoutC.defaultProps = {
  cancelBtnCaption: 'Close',
  iconName: 'confirm',
};
