import * as R from 'ramda';

import { searchSupplierInvoicesFilters } from './constants.js';
import { buildQueryRangeForDateField } from '../../modules/core/utils/elasticQueries.js';
import {
  generateSearchQuery,
  matchQueryGenerator,
} from '../../utils/generateSearchQuery.js';

const queryGenerators = {
  [searchSupplierInvoicesFilters.supplier]: matchQueryGenerator('supplierId'),
  [searchSupplierInvoicesFilters.invoiceStatus]: matchQueryGenerator('status'),
  [searchSupplierInvoicesFilters.invoiceNumber]: matchQueryGenerator('_id'),
  [searchSupplierInvoicesFilters.invoiceDate]:
    buildQueryRangeForDateField('invoiceDate'),
  [searchSupplierInvoicesFilters.creditsOnly]: R.applySpec({
    range: {
      total: R.ifElse(R.identity, R.always({ lt: 0 }), R.always({ gt: 0 })),
    },
  }),
};

export const generateSearchInvoicesQuery = generateSearchQuery(queryGenerators);
