import React from 'react';
import * as R from 'ramda';
import { SubmitBtn, useProcessState } from '@poly/admin-ui';
import { Loader, MainHeader } from '@poly/admin-book';
import {
  useRouterQuery,
  formatBackLink,
  useLocation,
} from '@poly/client-routing';

import { routesNames } from '../../routes/index.js';
import { CancelFormBtn } from '../../components/Buttons.js';
import { DefaultPageLayout } from '../DefaultPageLayout.js';
import { CreateClientInvoiceForm } from './CreateClientInvoiceForm.js';
import { useProjectDetailsQuery } from './useProjectDetailsQuery.js';
import { formatProjectInfo } from './helpers.js';
import {
  ButtonsContainerS,
  FormContainerS,
} from '../../components/FormCardContainer.js';

const createClientInvoiceFormId = 'createClientInvoiceFormId';

export function CreateClientInvoicePage() {
  const location = useLocation();
  const { projectId } = useRouterQuery(['projectId']);
  const { data, loading } = useProjectDetailsQuery(projectId);
  const { process } = useProcessState(createClientInvoiceFormId);

  if (loading) return <Loader />;

  const project = R.propOr({}, 'project', data);

  const projectInfo = formatProjectInfo(project);

  const cancelRedirectUrl = formatBackLink({
    withQuery: true,
    router: location.state,
    fallbackLink: routesNames.CREATE_CLIENT_INVOICE_SEARCH,
  });

  const isMiddleBtnActive =
    !!document.getElementById(createClientInvoiceFormId) &&
    document.getElementById(createClientInvoiceFormId).hasAttribute('print');

  const onSaveAndPrintClick = () => {
    const form = document.getElementById(createClientInvoiceFormId);
    form.setAttribute('print', 'true');
  };

  return (
    <DefaultPageLayout
      headerContent={<MainHeader>Invoice Creation</MainHeader>}
    >
      <FormContainerS
        fallbackLink={routesNames.CREATE_CLIENT_INVOICE_SEARCH}
        formId={createClientInvoiceFormId}
      >
        <CreateClientInvoiceForm
          projectInfo={projectInfo}
          formId={createClientInvoiceFormId}
        />
        <ButtonsContainerS>
          <CancelFormBtn disabled={process} redirectUrl={cancelRedirectUrl} />
          <SubmitBtn
            loader={process && isMiddleBtnActive}
            disabled={process}
            form={createClientInvoiceFormId}
            onClick={onSaveAndPrintClick}
          >
            Save & Print
          </SubmitBtn>
          <SubmitBtn
            loader={process && !isMiddleBtnActive}
            disabled={process}
            form={createClientInvoiceFormId}
          >
            Save
          </SubmitBtn>
        </ButtonsContainerS>
      </FormContainerS>
    </DefaultPageLayout>
  );
}
