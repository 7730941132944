import * as R from 'ramda';
import { commonFileValidators } from '@poly/client-utils';

// eslint-disable-next-line import/no-unused-modules
export const REQUIRED_WEEKLY_TICKET_ERROR = 'Attachment is required';

export const weeklyTicketValidators = [
  R.ifElse(
    R.complement(R.isEmpty),
    R.always({}),
    R.always({ commonError: REQUIRED_WEEKLY_TICKET_ERROR }),
  ),
  ...commonFileValidators,
];
