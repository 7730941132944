import { gql } from '@apollo/client';

export const CREATE_MENTION_GROUP_MUTATION = gql`
  mutation CREATE_MENTION_GROUP_MUTATION($input: CreateMentionGroupInput!) {
    createMentionGroup(input: $input) {
      _id
    }
  }
`;

export const UPDATE_MENTION_GROUP_MUTATION = gql`
  mutation UPDATE_MENTION_GROUP_MUTATION(
    $id: ID!
    $input: UpdateMentionGroupInput!
  ) {
    updateMentionGroup(id: $id, input: $input) {
      _id
    }
  }
`;
