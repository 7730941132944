import React from 'react';
import { func, shape, string } from 'prop-types';
import { CONFIRM_CLOSE_FORM_BTN_CAPTION } from '@poly/admin-ui';
import { extractPreviousUrl, useUpdateQueryParams } from '@poly/client-routing';
import { BasicConfirmButton } from './BasicConfirmButtonComp.js';
import { useBasicActions } from '../useBasicActions.js';

export function ConfirmCloseEditFormBtn(props) {
  const updateQueryParams = useUpdateQueryParams();

  const { dispatch, closeConfirmModal, ...restProps } = useBasicActions();

  const onSubmit = () => {
    updateQueryParams({ isEdit: false });
    dispatch(closeConfirmModal());
  };

  return (
    <BasicConfirmButton
      {...props}
      {...restProps}
      caption={CONFIRM_CLOSE_FORM_BTN_CAPTION}
      onSubmit={onSubmit}
    />
  );
}

export function ConfirmCloseFormBtn(props) {
  const { dispatch, closeConfirmModal, push, router, ...restProps } =
    useBasicActions();

  const onSubmit = () => {
    dispatch(closeConfirmModal());
    push({
      pathname: extractPreviousUrl(router) || props.confirmBtnPayload.url,
      state: { previous: router },
    });
  };

  return (
    <BasicConfirmButton
      {...props}
      {...restProps}
      caption={CONFIRM_CLOSE_FORM_BTN_CAPTION}
      onSubmit={onSubmit}
    />
  );
}

ConfirmCloseFormBtn.propTypes = {
  confirmBtnPayload: shape({
    url: string,
  }),
};

export function ConfirmCloseModalBtn({ afterConfirm, ...props }) {
  const { dispatch, closeCurrentModal, closeConfirmModal, ...restProps } =
    useBasicActions();

  const onSubmit = () => {
    dispatch(closeConfirmModal());
    dispatch(closeCurrentModal());

    if (typeof afterConfirm === 'function') {
      afterConfirm();
    }
  };

  return (
    <BasicConfirmButton
      {...props}
      {...restProps}
      caption={CONFIRM_CLOSE_FORM_BTN_CAPTION}
      onSubmit={onSubmit}
    />
  );
}

ConfirmCloseModalBtn.propTypes = {
  afterConfirm: func,
};
