import { gql } from '@apollo/client';
import { commonSortQuery, useReactiveQuery } from '@poly/client-utils';
import { useRouterParams } from '@poly/client-routing';
import { DESC_SORT_ORDER } from '@poly/constants';
import { useMasterSuppliersSearchInput } from '@poly/admin-ui';

import { projectsQueries } from '../../../modules/tabs/common.js';
import { commonProjectFields } from '../../../modules/core/hooks/projects/fragments.js';
import { PROJECTS_BY_SEARCH_SUB } from '../../../modules/core/hooks/projects/subscriptions.js';

const MASTER_SUPPLIER_PROJECTS = gql`
  query MASTER_SUPPLIER_PROJECTS($id: ID!, $input: CollectionSearchParams!) {
    masterSupplier(id: $id) {
      _id
      searchProjects(input: $input) {
        hits {
          ...commonProjectFields
        }
        total
      }
    }
  }

  ${commonProjectFields}
`;

export const useMasterSupplierProjectsQuery = (status, sort, fetchPolicy) => {
  const { supplierId } = useRouterParams(['supplierId']);
  const input = useMasterSuppliersSearchInput({
    sort: sort || commonSortQuery(['startDate'])(DESC_SORT_ORDER),
    query: projectsQueries[status],
  });

  const subscriptionOptions = {
    variables: { searchInput: input },
    ...(!!fetchPolicy && { fetchPolicy }),
  };
  const queryOptions = {
    ...subscriptionOptions,
    variables: { input, id: supplierId },
  };

  const { data, loading } = useReactiveQuery(
    MASTER_SUPPLIER_PROJECTS,
    PROJECTS_BY_SEARCH_SUB,
    { queryOptions, subscriptionOptions },
  );

  return { data, loading };
};
