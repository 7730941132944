import styled from 'styled-components';
import { getThemeColor, Table } from '@poly/admin-book';

import { ButtonsContainer } from '../../forms/common.js';
import { DnDAreaWithMentions } from '../../../components/DnDAreaWithMentions.js';

export const SectionWrapper = styled.tr`
  display: flex;
  background-color: ${getThemeColor(['secondaryLighter7'])} !important;
  width: 100%;
  margin: 0;
  padding: 24px;
  border-top: 1px solid rgba(197, 198, 201, 0.35);
`;

export const ContentWrapper = styled.td`
  display: flex;
  flex-direction: row;
  padding: 0 !important;
  width: 100% !important;
  align-items: flex-start !important;
  justify-content: space-between;
`;

export const DnDAreaWithMentionsS = styled(DnDAreaWithMentions)`
  > div {
    position: relative;
    background-color: ${getThemeColor(['white'])};
  }

  [class^='draftJsMentionPlugin'] {
    z-index: 4;
  }
`;

export const ToggleRepliesWrapper = styled(ButtonsContainer)`
  font-size: 14px;
  letter-spacing: normal;
  line-height: 1.5;
  width: 100%;
  padding: 10px 20px;
  background-color: ${getThemeColor(['accent2Lighter8'])};
  ${({ isOpen }) =>
    isOpen ? 'border-bottom: 1px solid rgba(197, 198, 201, 0.35)' : ''};
`;

export const ToggleRepliesButton = styled.div`
  display: flex;
  cursor: pointer;

  > div {
    margin-left: 8px;
    font-size: 12px;
    line-height: 18px;
  }

  :hover {
    > svg path {
      fill: ${getThemeColor(['primaryLight'])};
    }

    > div {
      color: ${getThemeColor(['primaryLight'])};
    }
  }
`;

export const AdditionalReplyInfo = styled.div`
  color: ${getThemeColor(['primaryLighter3'])};
  font-size: 12px;
  line-height: 18px;

  > span {
    color: ${getThemeColor(['primary'])};
    font-weight: 700;
  }
`;

export const RepliesTableWrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: ${getThemeColor(['white'])};
  border-bottom: 1px solid ${getThemeColor(['midLight'])};
`;

export const RepliesTable = styled(Table)`
  thead {
    display: none;
  }

  tbody {
    overflow: auto;
    height: 100%;
  }

  tr {
    margin: 0;
    width: 100%;
    border: none !important;
    box-shadow: unset !important;
    background-color: ${getThemeColor(['white'])} !important;

    :hover {
      box-shadow: unset;
    }

    &[active] {
      z-index: 3;
      background-color: ${getThemeColor(['light'])} !important;
      border: 1px solid ${getThemeColor(['primaryLight'])} !important;
    }
  }

  td:first-child {
    width: 200px !important;
    padding-left: 30px;
  }

  td:last-child {
    width: 25px !important;
  }
`;
