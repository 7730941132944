import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { arrayOf, bool, number, string } from 'prop-types';
import { formatTotal, ensureIsDate } from '@poly/utils';
import {
  FlexColumn,
  FlexContainer,
  JustifyFlexEnd,
} from '../../components/FlexContainer.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import {
  calculateTotalBalance,
  extractCogs,
  extractExpense,
  extractIncome,
} from './helpers.js';
import { profitLossPropTypes, datePropType } from './propTypes.js';

const SubTitleS = styled.p`
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  text-align: center;
`;

const HEADER_DATE_FORMAT = 'dd MMMM, yyyy';

export function SubTitle({ startDate, endDate }) {
  return (
    <SubTitleS>
      For the period of
      {` ${format(ensureIsDate(startDate), HEADER_DATE_FORMAT)} to ${format(
        ensureIsDate(endDate),
        HEADER_DATE_FORMAT,
      )}`}
    </SubTitleS>
  );
}

SubTitle.propTypes = {
  startDate: datePropType,
  endDate: datePropType,
};

const BoldTextS = styled.span`
  font-weight: bold;
`;

const AccountingMethodHeaderColumn = styled(FlexContainer)`
  width: 200px;
  justify-content: flex-end;
`;

const CategoryContainer = styled(FlexColumn)`
  padding-right: ${({ isCredit }) => (isCredit ? '0' : '200px')};
  margin-bottom: 20px;
`;

const SectionWithPadding = styled(FlexColumn)`
  padding-left: 20px;
  width: 100%;
`;

const CategorySectionContainerS = styled(FlexColumn)`
  margin-top: 10px;
`;

const AccountReportLineContainer = styled(FlexSpaceBetween)`
  width: 100%;
  padding: 5px;
`;

function CategoryReportSection({ title, isCredit, profitLossReports, total }) {
  return (
    <CategoryContainer isCredit={isCredit}>
      <BoldTextS>{title}</BoldTextS>
      <SectionWithPadding>
        {profitLossReports.map(({ account, subAccounts, balance }) => (
          <CategorySectionContainerS key={account._id}>
            <BoldTextS>{`${account.code} - ${account.name}`}</BoldTextS>
            <SectionWithPadding>
              {subAccounts.map((subAccountReport) => (
                <AccountReportLineContainer key={subAccountReport.account._id}>
                  <span>{`${subAccountReport.account.code} - ${subAccountReport.account.name}`}</span>
                  <span>{formatTotal(subAccountReport.balance)}</span>
                </AccountReportLineContainer>
              ))}
            </SectionWithPadding>
            <AccountReportLineContainer>
              <BoldTextS>{`Total ${account.name}`}</BoldTextS>
              <BoldTextS>{formatTotal(balance)}</BoldTextS>
            </AccountReportLineContainer>
          </CategorySectionContainerS>
        ))}
      </SectionWithPadding>
      <AccountReportLineContainer>
        <BoldTextS>{`Total ${title}`}</BoldTextS>
        <BoldTextS>{formatTotal(total)}</BoldTextS>
      </AccountReportLineContainer>
    </CategoryContainer>
  );
}

CategoryReportSection.propTypes = {
  title: string.isRequired,
  isCredit: bool,
  profitLossReports: profitLossPropTypes,
  total: number.isRequired,
};

const TableContainer = styled(FlexColumn)`
  width: 97%;
  display: flex !important;

  div {
    display: flex !important;
  }
`;

export function ProfitLossReportPrintTable({
  startDate,
  endDate,
  profitLossReport,
  cogsAccountCodes,
}) {
  const incomeReports = extractIncome(profitLossReport);
  const cogsReports = extractCogs(cogsAccountCodes)(profitLossReport);
  const expenseReports = extractExpense(cogsAccountCodes)(profitLossReport);

  const totalIncome = calculateTotalBalance(incomeReports);
  const totalCogs = calculateTotalBalance(cogsReports);
  const totalOverHead = calculateTotalBalance(expenseReports);

  return (
    <TableContainer>
      <SubTitle startDate={startDate} endDate={endDate} />
      <JustifyFlexEnd>
        <AccountingMethodHeaderColumn>Debit</AccountingMethodHeaderColumn>
        <AccountingMethodHeaderColumn>Credit</AccountingMethodHeaderColumn>
      </JustifyFlexEnd>
      <CategoryReportSection
        title="Income"
        isCredit
        total={totalIncome}
        profitLossReports={incomeReports}
      />
      <CategoryReportSection
        title="COGS Facility Services"
        total={totalCogs}
        profitLossReports={cogsReports}
      />
      <CategoryContainer isCredit>
        <AccountReportLineContainer>
          <BoldTextS>Gross Profit</BoldTextS>
          <BoldTextS>{formatTotal(totalIncome - totalCogs)}</BoldTextS>
        </AccountReportLineContainer>
      </CategoryContainer>

      <CategoryReportSection
        title="Overhead"
        total={totalOverHead}
        profitLossReports={expenseReports}
      />
      <CategoryContainer isCredit>
        <AccountReportLineContainer>
          <BoldTextS>Net Profit/(Loss)</BoldTextS>
          <BoldTextS>
            {formatTotal(totalIncome - totalCogs - totalOverHead)}
          </BoldTextS>
        </AccountReportLineContainer>
      </CategoryContainer>
    </TableContainer>
  );
}

ProfitLossReportPrintTable.propTypes = {
  startDate: datePropType,
  endDate: datePropType,
  profitLossReport: profitLossPropTypes,
  cogsAccountCodes: arrayOf(string),
};
