import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { MoneyInputAsCents } from '@poly/admin-ui';
import {
  DatePicker,
  DefaultBodyWrapper,
  getThemeColor,
  moneyColumnStyles,
  Table,
  TableCard,
  WindowedTable,
} from '@poly/admin-book';

import { FlexColumn, FlexContainer } from '../../components/FlexContainer.js';

export const FiltersContainer = styled(FlexColumn)`
  width: 100%;
`;

export const WhiteBodyWrapper = styled(DefaultBodyWrapper)`
  background: ${getThemeColor(['white'])};
  min-height: ${R.prop('minHeight')};
`;

export const BottomPanelButtonsContainer = styled(FlexContainer)`
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid rgba(30, 36, 44, 0.15);
  padding-top: 10px;
`;

export const DatePickerS = styled(DatePicker)`
  width: 100%;
`;

export const PayInvoicesTableCard = styled(TableCard)`
  height: ${R.propOr('auto', 'height')};
  max-width: 1424px;
  width: calc(100% - 80px);
  margin: 0 30px 0 50px;
  flex-grow: 1;
  max-height: none;
  position: relative;
`;

export const PayInvoicesFormContainer = styled(FlexColumn)`
  width: 100%;
  align-items: center;
  height: 100%;
  overflow-y: auto;
`;

export const MoneyInputAsCentsS = styled(MoneyInputAsCents)`
  input {
    color: ${({ value }) => value < 0 && getThemeColor(['secondaryMid'])};
    text-align: right;
    font-family: 'Monda', sans-serif;
    font-size: 12px;
  }
`;

const paySupplierInvoicesTableStyles = css`
  th:nth-child(1),
  td:nth-child(1) {
    width: 70px;
  }
  th:nth-child(2),
  td:nth-child(2) {
    width: 80px;
  }
  th:nth-child(4),
  td:nth-child(4) {
    width: 80px;
  }
  th:nth-child(5),
  td:nth-child(5) {
    width: 80px;
  }
  th:nth-child(6),
  td:nth-child(6) {
    width: 90px;
  }
  th:nth-child(9),
  td:nth-child(9) {
    width: 170px;
  }
  th:nth-child(10),
  td:nth-child(10) {
    width: 1.9%;
    text-align: center;
  }
  th:nth-child(3),
  td:nth-child(3) {
    width: 175px;
  }
  th:nth-child(8),
  td:nth-child(8),
  th:nth-child(11),
  td:nth-child(11) {
    width: 100px;
  }

  th:nth-child(12),
  td:nth-child(12) {
    width: 130px;
  }

  ${moneyColumnStyles(7)};

  th:nth-child(7),
  td:nth-child(7) {
    width: 110px;
  }
`;

export const PaySupplierInvoicesWindowedTableS = styled(WindowedTable)`
  tbody {
    vertical-align: middle;
  }

  td {
    padding: 5px;

    & > div {
      & > input {
        height: 26px;
      }
      & > div {
        padding: 0;
        line-height: 14px;
      }
      & > svg {
        top: 5px;
      }
    }
  }

  tr {
    margin: 0;
    width: 100%;
  }

  tfoot {
    margin-top: 50px;
    position: unset;
  }

  ${paySupplierInvoicesTableStyles}
`;

export const PaySupplierInvoicesTableS = styled(Table)`
  ${paySupplierInvoicesTableStyles}
`;
