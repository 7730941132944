import React from 'react';

import {
  FlexRow,
  ClearButton,
  Title,
  HistoryRow,
  HistoryRecordsWrapper,
  ContentWrapper,
} from './historyComponents.js';
import { HistoryRecord } from './HistoryRecord.js';
import { useSidebarLogicContext } from '../../../sidebars/SidebarLogicContext.js';

export function HistoryContent() {
  const { historyRecords, clearHistoryRecords } = useSidebarLogicContext();

  return (
    <ContentWrapper>
      <HistoryRow>
        <Title>Your Viewing History</Title>
        <FlexRow>
          <ClearButton onClick={clearHistoryRecords}>Clear list</ClearButton>
        </FlexRow>
      </HistoryRow>
      <HistoryRecordsWrapper>
        {historyRecords.map((item) => (
          <HistoryRecord key={item.id} {...item} />
        ))}
      </HistoryRecordsWrapper>
    </ContentWrapper>
  );
}
