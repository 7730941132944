export const HeavyJobStatuses = {
  PENDING: 'pendingWorkerJobStatus',
  IN_PROGRESS: 'inProgressWorkerJobStatus',
  DONE: 'doneWorkerJobStatus',
  FAILED: 'failedWorkerJobStatus',
};

export const HeavyJobTypes = {
  CLIENT_INVOICE_BATCH: 'clientInvoiceBatchHeavyJob',
  SUPPLIER_INVOICE_BATCH: 'supplierInvoiceBatchHeavyJob',
  IMPORT_ASSETS: 'importAssetsHeavyJob',
  CLIENT_INVOICE_BATCH_REPORTS: 'clientInvoiceBatchReportsJob',
  CONSOLIDATED_CLIENT_INVOICE: 'consolidatedClientInvoiceHeavyJob',
  CLIENT_INVOICE_BATCH_XLS_ATTACHMENTS: 'clientInvoiceXLSAttachments',
  PRINT_CLIENT_INVOICES_PDF: 'printClientInvoicesPdfHeavyJob',
};
