import * as R from 'ramda';
import { useEffect } from 'react';
import { arrayOf, string } from 'prop-types';
import { useFormState } from 'react-final-form';
import { usePersistentFormValue } from '@poly/client-utils/src/hooks/persists/usePersistentFormValue.js';

// preparePersistentValues :: [String] -> { values: Object, initialValues: Object }
const preparePersistentValues = (persistFields) =>
  R.compose(
    R.fromPairs,
    R.converge(R.difference, [
      R.compose(R.toPairs, R.pick(persistFields), R.prop('values')),
      R.compose(R.toPairs, R.pick(persistFields), R.prop('initialValues')),
    ]),
  );

export function RetainFormFields({ persistKey, persistFields }) {
  const { values, initialValues, pristine } = useFormState();
  const { onChangePersistentValue } = usePersistentFormValue(persistKey);

  useEffect(() => {
    const persistentValues = preparePersistentValues(persistFields)({
      values,
      initialValues,
    });

    if (!R.isEmpty(persistentValues) && !pristine) {
      onChangePersistentValue(persistentValues);
    }
  }, [values]);

  return null;
}

RetainFormFields.propTypes = {
  persistKey: string.isRequired,
  persistFields: arrayOf(string).isRequired,
};
