import * as R from 'ramda';
import { propEqLegacy } from '@poly/utils';

// getSupplierPhoneByType :: String -> [Object] -> String
export const getSupplierPhoneByType = R.curry((type, phones) =>
  R.pipe(
    R.find(
      R.both(propEqLegacy('type', type), propEqLegacy('legacyFormat', false)),
    ),
    R.defaultTo({}),
    R.propOr(null, 'phone'),
  )(phones),
);
