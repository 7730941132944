import { number, shape, string } from 'prop-types';

export const customerTransactionPropType = shape({
  date: string,
  type: string,
  accountName: string,
  invoiceAmount: number,
  paymentAmount: number,
  project: shape({ projectId: string.isRequired }),
  invoice: shape({ number: number.isRequired }),
});

export const customerFilterPropType = shape({
  clientId: string,
  projectId: string,
  invoiceDate: shape({
    startDate: string.isRequired,
    endDate: string.isRequired,
  }).isRequired,
  invoiceId: string,
  invoiceStatus: string.isRequired,
  accountTypeId: string,
});
