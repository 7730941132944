import { string, shape } from 'prop-types';
import React, { useEffect } from 'react';
import { useOutSidebarContext } from '@poly/client-utils';
import { SidebarWrapper } from '@poly/admin-ui';
import { Loader } from '@poly/admin-book';

import { clientTabsSidebarId } from './constants.js';
import { ClientSidebarHeader } from './ClientSidebarHeader.js';
import { NotFoundEntity } from '../../components/NotFoundEntity.js';
import { ClientSidebarGeneralInfo } from './ClientSidebarGeneralInfo.js';
import { ClientSidebarFinancialTabs } from './financialTabs/ClientSidebarFinancialTabs.js';
import { useReactiveSidebarDocumentTitle } from '../useReactiveSidebarDocumentTitle.js';
import { useOpenClientTabsSidebar } from './tabs/useOpenClientTabsSidebar.js';
import { useClientSidebarData } from './useClientSidebarData.js';
import { EntityRemarks } from '../components/commonSidebarDetailsList.js';
import { ClientSidebarSettingsInfo } from './ClientSidebarSettingsInfo.js';

function ClientSidebarRouterLogic({ client }) {
  const { isSidebarOpened } = useOutSidebarContext();
  const openClientTabsSidebar = useOpenClientTabsSidebar();

  useEffect(() => {
    if (!isSidebarOpened(clientTabsSidebarId)) {
      openClientTabsSidebar(client);
    }
  }, []);

  useReactiveSidebarDocumentTitle(client.name);

  return null;
}

ClientSidebarRouterLogic.propTypes = {
  client: shape({ name: string.isRequired }).isRequired,
};

export function ClientSidebarSections(props) {
  return (
    <>
      <ClientSidebarHeader {...props} />
      <ClientSidebarGeneralInfo {...props} />
      <ClientSidebarSettingsInfo {...props} />
      <EntityRemarks entity={props.client} />
      <ClientSidebarFinancialTabs {...props} />
    </>
  );
}

ClientSidebarSections.propTypes = {
  client: shape({ _id: string.isRequired }).isRequired,
};

export function ClientSidebar({ clientId }) {
  const { loading, ...sidebarProps } = useClientSidebarData(clientId);

  if (loading) {
    return <Loader />;
  }

  return (
    <SidebarWrapper>
      {!sidebarProps.client ? (
        <NotFoundEntity entityName="client" />
      ) : (
        <>
          <ClientSidebarSections {...sidebarProps} />
          <ClientSidebarRouterLogic {...sidebarProps} />
        </>
      )}
    </SidebarWrapper>
  );
}

ClientSidebar.displayName = 'ClientSidebar';
ClientSidebar.propTypes = { clientId: string.isRequired };
