import { gql } from '@apollo/client';
import { staffUserAccountFields } from '@poly/admin-ui';
import { useReactiveQuery } from '@poly/client-utils';

const USER_ACCOUNT_QUERY = gql`
  query USER_ACCOUNT_QUERY($userId: ID!) {
    user(id: $userId) {
      ...staffUserAccountFields
    }
  }
  ${staffUserAccountFields}
`;

const USER_ACCOUNT_CHANGED = gql`
  subscription USER_ACCOUNT_CHANGED($input: SingleDocSubInput!) {
    userChanged(input: $input) {
      id
      type
      document {
        ...staffUserAccountFields
      }
    }
  }

  ${staffUserAccountFields}
`;

export const useUserSidebarData = (userId) => {
  const queryOptions = { variables: { userId }, fetchPolicy: 'network-only' };

  const subscriptionOptions = {
    variables: { input: { id: userId } },
  };

  const { data, loading } = useReactiveQuery(
    USER_ACCOUNT_QUERY,
    USER_ACCOUNT_CHANGED,
    { queryOptions, subscriptionOptions },
  );

  return { loading, ...data };
};
