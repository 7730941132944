import * as R from 'ramda';
import React from 'react';
import {
  getProjectDateCompletedUI,
  getProjectStartEndDatesUI,
} from '@poly/utils/src/projects/common.js';

import { SupplierAttachInvoiceDividerC } from './SupplierAttachInvoiceDivider.js';
import { SupplierAttachInvoiceRowTitle } from './SupplierAttachInvoiceRowTitle.js';
import { SupplierAttachInvoiceReadOnlyField } from './SupplierAttachInvoiceReadOnlyField.js';
import { SupplierAttachInvoiceContactUs } from './SupplierAttachInvoiceContactUs.js';

// getInitialValues :: SupplierInvoiceInfoByTokenResult -> InitializedFormValues
// InitializedFormValues = {
//    supplierName: String
//    projectId: String
//    clientName: String
//    propertyName: String
//    propertyAddress: String
//    description: String
// }
const getFooterValues = R.applySpec({
  supplierName: R.propOr('-', 'supplierName'),
  projectId: R.propOr('-', 'projectId'),
  clientName: R.propOr('-', 'clientName'),
  propertyName: R.propOr('-', 'propertyName'),
  propertyAddress: R.propOr('-', 'propertyAddress'),
  description: R.propOr('-', 'description'),
  multiInvoiceNote: R.propOr('-', 'multiInvoiceNote'),
  startEndDates: getProjectStartEndDatesUI,
  completedDate: getProjectDateCompletedUI,
});

export function SupplierAttachInvoiceFormFooter(formProps) {
  const {
    supplierName,
    projectId,
    clientName,
    propertyName,
    propertyAddress,
    description,
    multiInvoiceNote,
    startEndDates,
    completedDate,
  } = getFooterValues(formProps);

  return (
    <>
      <SupplierAttachInvoiceDividerC margin="20px 0 10px 0" />
      <SupplierAttachInvoiceRowTitle value="TO:" />
      <SupplierAttachInvoiceReadOnlyField value={supplierName} />
      <SupplierAttachInvoiceRowTitle value="PROJECT:" />
      <SupplierAttachInvoiceReadOnlyField value={projectId} />
      <SupplierAttachInvoiceRowTitle value="CLIENT:" />
      <SupplierAttachInvoiceReadOnlyField value={clientName} />
      <SupplierAttachInvoiceRowTitle value="BRANCH:" />
      <SupplierAttachInvoiceReadOnlyField value={propertyName} />
      <SupplierAttachInvoiceRowTitle value="ADDRESS:" />
      <SupplierAttachInvoiceReadOnlyField value={propertyAddress} />
      <SupplierAttachInvoiceRowTitle value="START - END DATE:" />
      <SupplierAttachInvoiceReadOnlyField value={startEndDates} />
      <SupplierAttachInvoiceRowTitle value="COMPLETED:" />
      <SupplierAttachInvoiceReadOnlyField value={completedDate} />
      <SupplierAttachInvoiceDividerC margin="10px 0 10px 0" />
      <SupplierAttachInvoiceRowTitle value="DESCRIPTION:" />
      <SupplierAttachInvoiceReadOnlyField value={description} />
      <SupplierAttachInvoiceRowTitle value="MULTI INVOICE NOTE:" />
      <SupplierAttachInvoiceReadOnlyField value={multiInvoiceNote} />
      <SupplierAttachInvoiceDividerC margin="10px 0 10px 0" />
      <SupplierAttachInvoiceContactUs />
    </>
  );
}
