import * as R from 'ramda';
import { ifNotEmpty } from '@poly/client-utils';
import { propEqLegacy } from '@poly/utils';

import { halfWidth } from '../common.js';
import { SupplierFormCheckbox } from '../components/Checkbox.js';
import { RoutingNumberInput, BankAccountInput } from '../components/index.js';
import { isBankACH } from '../utils.js';
import { MultiEmailsInput } from '../../../../../components/MultiEmailsInput.js';

const lengthIsLessThen = (lt) =>
  ifNotEmpty(R.propSatisfies(R.lt(R.__, lt), 'length'));

export const supplierBankSection = (renderIfBank) => [
  {
    label: 'Bank ACH',
    order: 8,
    layout: { column: 2 },
    ...(!!renderIfBank && { renderIf: renderIfBank }),
    fields: [
      {
        label: 'Pay By ACH',
        order: 1,
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'bank.payByACH',
          Component: SupplierFormCheckbox,
        },
      },
      {
        label: 'Routing Number',
        order: 2,
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'bank.routingNumber',
          Component: RoutingNumberInput,
        },
        validators: [
          [ifNotEmpty(propEqLegacy('length', 9)), 'Should be 9 digits'],
        ],
        renderIf: isBankACH,
      },
      {
        label: 'Account Number',
        order: 3,
        layout: { row: 2, width: halfWidth },
        field: {
          name: 'bank.accountNumber',
          Component: BankAccountInput,
        },
        validators: [[lengthIsLessThen(18), 'Should be less then 18 digits']],
        renderIf: isBankACH,
      },
      {
        label: 'Remittance Emails',
        order: 4,
        layout: { row: 2, width: halfWidth },
        field: {
          name: 'bank.remittanceEmails',
          Component: MultiEmailsInput,
        },
        required: true,
        renderIf: isBankACH,
      },
    ],
  },
];
