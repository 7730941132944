import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { func } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { FormCreator, Input } from '@poly/admin-book';
import { useNotificationState, halfWidth } from '@poly/admin-ui';

import { commonModalLayout } from '../../../modules/forms/common.js';

export const addSupplierInvoiceTaskFormId = 'addSupplierInvoiceTaskFormId';

const createSupplierInvoiceTaskMutation = gql`
  mutation CREATE_SUPPLIER_INVOICE_TASK_MUTATION(
    $input: CreateSupplierInvoiceTaskInput!
  ) {
    createSupplierInvoiceTask(input: $input) {
      _id
    }
  }
`;

const MultiInvoiceDescriptionNote = styled.p`
  font-size: 11px;
`;

// getInitialValues :: Supplier -> { supplierName: String }
const getInitialValues = R.applySpec({
  supplierName: R.path(['company', 'name']),
});

// prepareFormDataToMutation :: FormData -> CreateSupplierInvoiceTaskInput
const prepareFormDataToMutation = R.applySpec({
  supplierId: R.prop('_id'),
  projectId: R.path(['project', '_id']),
  multiInvoiceNote: R.compose(R.trim, R.path(['formData', 'multiInvoiceNote'])),
});

// multiInvoiceNotePrepareToValidation :: String -> String
const multiInvoiceNotePrepareToValidation = R.compose(
  R.length,
  R.trim,
  R.defaultTo(''),
);

const supplierInvoiceFormSections = [
  {
    order: 1,
    layout: { column: 1 },
    fields: [
      {
        label: 'Supplier',
        order: 1,
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'supplierName',
          Component: (props) => <Input {...props} disabled />,
        },
      },
      {
        label: 'Multi Invoice Note',
        order: 2,
        layout: { row: 1, width: halfWidth },
        required: true,
        field: {
          name: 'multiInvoiceNote',
          Component: (props) => (
            <>
              <Input {...props} maxLength={100} />
              <MultiInvoiceDescriptionNote>
                This note will appear on the supplier invoice request
              </MultiInvoiceDescriptionNote>
            </>
          ),
        },
        validators: [
          [
            R.compose(
              R.both(R.gt(R.__, 0), R.lte(R.__, 100)),
              multiInvoiceNotePrepareToValidation,
            ),
            'Multi Invoice Note is required',
          ],
        ],
      },
    ],
  },
];

export function AddSupplierInvoiceForm({ closeModal, ...otherProps }) {
  const { showSuccessNotification } = useNotificationState();
  const [createSupplierInvoiceTask] = useMutation(
    createSupplierInvoiceTaskMutation,
  );

  const onSubmit = async (formData) => {
    const input = prepareFormDataToMutation({ formData, ...otherProps });

    await createSupplierInvoiceTask({ variables: { input } });

    showSuccessNotification('New supplier invoice task created successfully.');
    closeModal(addSupplierInvoiceTaskFormId);
  };

  return (
    <FormCreator
      {...otherProps}
      onSubmit={onSubmit}
      onCancel={closeModal}
      layout={commonModalLayout}
      id={addSupplierInvoiceTaskFormId}
      initialValues={getInitialValues(otherProps)}
      sections={supplierInvoiceFormSections}
    />
  );
}

AddSupplierInvoiceForm.propTypes = {
  closeModal: func,
};
