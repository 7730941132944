import React from 'react';
import { FormCreator } from '@poly/admin-book';
import { func, shape, string } from 'prop-types';
import { usePristineSubscribe } from '@poly/client-routing';
import { openPrintWindowWithData } from '@poly/client-utils';
import {
  CommonPrintLayout,
  useOnSubmitSetStopSubmitting,
} from '@poly/admin-ui';

import { commonModalLayout } from '../../modules/forms/common.js';
import { ProjectPrintToPDFTable } from './ProjectPrintToPDFTable.js';
import { getProjectPrintToPDFSections } from './form-sections.js';
import { useLazyProjectPrintToPDFQuery } from './useLazyProjectPrintToPDFQuery.js';

const projectPrintToPDFSuccessMessage = 'Project printed successfully.';

const DEFAULT_INITIAL_VALUES = {
  config: {
    isSupplierInfo: false,
    isProjectTasks: false,
    isFinancialInfo: true,
    isAttachedAssets: false,
    isGeneralDetails: true,
    isProjectDetails: true,
    isContactInformation: false,
    isIncludeAssetDetailsInfo: false,
    isIncludeClientEstimateFile: false,
    isIncludeClientInvoiceFiles: false,
    isIncludeSupplierInvoiceFiles: false,
    isIncludeCompletedTaskComments: false,
  },
};

export function ProjectPrintToPDFForm({ formId, project, onCancel }) {
  const pristineSubscribeProps = usePristineSubscribe();

  const runPrintProjectQuery = useLazyProjectPrintToPDFQuery(project._id);

  const onSubmitHandler = async ({ config }) => {
    const printProject = await runPrintProjectQuery(project._id);

    openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: ProjectPrintToPDFTable,
      fileName: `print-project-${project.projectId}`,
      metaData: { title: '' },
      config,
      printProject,
      skipFirefoxStyles: true,
    });

    onCancel();
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  return (
    <FormCreator
      id={formId}
      layout={commonModalLayout}
      formId={formId}
      sections={getProjectPrintToPDFSections(formId, onCancel)}
      initialValues={DEFAULT_INITIAL_VALUES}
      onSubmit={onSubmit}
      onCancel={onCancel}
      successMessage={projectPrintToPDFSuccessMessage}
      {...pristineSubscribeProps}
    />
  );
}

ProjectPrintToPDFForm.propTypes = {
  formId: string,
  onCancel: func,
  project: shape({
    _id: string,
  }),
};
