import React from 'react';
import * as R from 'ramda';
import { bool, shape, string } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { useModalContext, useNotificationState } from '@poly/admin-ui';
import { FormCreator, TextButton } from '@poly/admin-book';
import { useOutSidebarContext } from '@poly/client-utils';

import { editPropertyFormId } from '../../../constants.js';
import { PropertySelect } from '../../../../../modules/selects/PropertySelect/PropertySelect.js';
import { useSidebarLogicContext } from '../../../../SidebarLogicContext.js';

const convertPropertyToSubFormId = 'convert_property_to_sub_form_id';

const convertToSubPropertyMutation = gql`
  mutation convertToSubPropertyMutation($id: ID!, $masterPropertyId: ID!) {
    convertToSubProperty(id: $id, masterPropertyId: $masterPropertyId) {
      _id
    }
  }
`;

// getMasterPropertiesSelectQueryByClientId :: ID -> ElasticQuery
const getMasterPropertiesSelectQueryByClientId = R.compose(
  R.objOf('bool'),
  R.objOf('filter'),
  R.juxt([
    R.compose(R.objOf('term'), R.always({ isMaster: true })),
    R.compose(R.objOf('term'), R.objOf('clientId')),
  ]),
);

function MasterPropertySelect({ clientId, ...props }) {
  return (
    <PropertySelect
      withoutSkip
      allowModalOverflow
      includeAllOption={false}
      filterOption={() => true}
      placeholder="Start typing master properties"
      query={getMasterPropertiesSelectQueryByClientId(clientId)}
      {...props}
    />
  );
}

MasterPropertySelect.propTypes = { clientId: string.isRequired };

export function ConvertToSubPropertyButton({ isCard, formData }) {
  const { closeOutSidebar } = useOutSidebarContext();
  const { openModalForm, closeModal } = useModalContext();
  const { openPropertySidebar } = useSidebarLogicContext();
  const { showSuccessNotification } = useNotificationState();
  const [convertToSubProperty] = useMutation(convertToSubPropertyMutation);

  const propertyId = formData._id;
  const clientId = R.path(['client', '_id'], formData);

  const onSubmit = async (variables) => {
    await convertToSubProperty({ variables });
    showSuccessNotification('Property successfully converted');
    closeModal(convertPropertyToSubFormId);
    closeOutSidebar(editPropertyFormId);

    if (!isCard) {
      openPropertySidebar(propertyId);
    }
  };

  const onClick = () =>
    openModalForm({
      id: convertPropertyToSubFormId,
      formId: convertPropertyToSubFormId,
      title: 'Convert Property to SubProperty',
      btnCaption: 'Convert to SubProperty',
      content: (
        <FormCreator
          key={propertyId}
          onSubmit={onSubmit}
          id={convertPropertyToSubFormId}
          initialValues={{ id: propertyId }}
          layout={{ overflow: 'visible', card: false }}
          sections={[
            {
              order: 1,
              layout: { column: 1 },
              fields: [
                {
                  order: 1,
                  label: 'Master Property',
                  layout: { row: 1, width: '60%', padding: 0 },
                  field: {
                    name: 'masterPropertyId',
                    additionalProps: { clientId },
                    Component: MasterPropertySelect,
                  },
                  validators: [[R.identity, 'Master Property is required']],
                  required: true,
                },
              ],
            },
          ]}
        />
      ),
    });

  return <TextButton onClick={onClick}>SubProperty</TextButton>;
}

ConvertToSubPropertyButton.propTypes = {
  isCard: bool,
  formData: shape({ _id: string.isRequired }).isRequired,
};
