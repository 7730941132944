import { string, bool, shape, arrayOf } from 'prop-types';

const treeNodePropTypesBase = {
  id: string.isRequired,
  title: string.isRequired,
  expanded: bool,
};

export const treeNodePropTypes = shape({
  ...treeNodePropTypesBase,
  children: arrayOf(shape(treeNodePropTypesBase)),
});
