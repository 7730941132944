import { gql, useQuery } from '@apollo/client';

const masterSupplierQuery = gql`
  query MASTER_SUPPLIER_QUERY($id: ID!) {
    masterSupplier(id: $id) {
      _id
      type
      company {
        name
        services {
          _id
          name
        }
      }
      supplierSource
      foodBuy {
        isEnabled
        sapVendorNumber
        vendorType
        vaPercentage
      }
    }
  }
`;

export const useMasterSupplierInfoQuery = (masterSupplierId) => {
  const { data } = useQuery(masterSupplierQuery, {
    variables: { id: masterSupplierId },
  });

  return { data };
};
