import React, { useEffect, memo } from 'react';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { func, string, number, bool, oneOfType } from 'prop-types';
import { useMapConfigToTablePropsWithSorting } from '@poly/admin-ui';
import { formatDate, centsToDollarsWithFormat } from '@poly/utils';
import { NOTHING_UI_STRING } from '@poly/constants';
import {
  moneyColumnStyles,
  WindowedTable,
  Table,
  InputOutForm,
  getThemeColor,
} from '@poly/admin-book';

import { useRouterQuery } from '@poly/client-routing';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentInput } from '../../components/PaymentsFromComponents/PaymentInput.js';
import { BalanceInput } from '../../components/PaymentsFromComponents/BalanceInput.js';
import { DiscountSelect } from './DiscountSelect.js';
import { DeductionsInput } from '../../components/PaymentsFromComponents/DeductionsInput.js';
import {
  SelectCheckbox,
  SelectInvoicesCheckBox,
} from '../../components/PaymentsFromComponents/SelectInvoiceCheckBox.js';
import { useInvoicesHandlers } from '../PaySuppliersPage/usePaymentsHandlers.js';
import { GET_ACCOUNTS_QUERY } from '../../components/AccountsSelect.js';
import { filterSelectedInvoices } from '../PaySuppliersPage/paySuppliersUtils/payInvoicesUtils.js';
import {
  adjustDiscount,
  calculateSelectedInvoicesDeductionsTotal,
  distributeInvoicesDiscount,
} from './clientPaymentsHelpers.js';
import { setAllInvoices } from '../../redux/invoices.js';

function DeductionsAccountSelect({
  discountAccountId,
  changeDiscountAccountId,
  deductionsAmount,
  currentBalance,
  dropdownOrientation,
}) {
  return (
    <InputOutForm
      isOutForm
      Component={DiscountSelect}
      size="small"
      width="100%"
      value={discountAccountId}
      onChange={changeDiscountAccountId}
      required={deductionsAmount > 0}
      disabled={currentBalance < 0}
      direction={dropdownOrientation}
      inputHeight="30px"
      placeholder="Select GL Code"
    />
  );
}

DeductionsAccountSelect.propTypes = {
  discountAccountId: string,
  changeDiscountAccountId: func.isRequired,
  deductionsAmount: oneOfType([number, string]),
  currentBalance: number.isRequired,
  dropdownOrientation: string,
};

const paySuppliersTableConfig = (onSelectAll, isAllSelected) => [
  ['Inv Number', R.prop('invoiceNumber'), R.prop('invoiceNumber')],
  [
    'Invoice Date',
    R.compose(formatDate, R.prop('invoiceDate')),
    R.prop('invoiceDate'),
  ],
  ['Due Date', R.compose(formatDate, R.prop('dueDate'))],
  ['Project Number', R.prop('projectId')],
  [
    'Consolidated Invoice #',
    R.propOr(NOTHING_UI_STRING, 'consolidatedClientInvoiceNumber'),
  ],
  ['Invoice Amount', R.compose(centsToDollarsWithFormat, R.prop('total'))],
  ['Deductions', (props) => <DeductionsInput asCents {...props} />],
  ['Chart Of Accounts', DeductionsAccountSelect],
  [
    <SelectCheckbox
      key="select-all-invoices-checkbox"
      name="select-all-invoices-checkbox"
      dataTestId="select-all-invoices-checkbox"
      value={!!isAllSelected}
      onChange={onSelectAll}
    />,
    SelectInvoicesCheckBox,
  ],
  ['Received Amount', (props) => <PaymentInput asCents {...props} />],
  ['Due Amount', (props) => <BalanceInput asCents {...props} />],
];

const payClientInvoicesTableStyles = css`
  th:nth-child(5),
  td:nth-child(5) {
    width: 150px;
  }

  th:nth-child(8),
  td:nth-child(8) {
    width: 170px;
  }
  th:nth-child(9),
  td:nth-child(9) {
    width: 1.9%;
    text-align: center;
  }
  th:nth-child(7),
  td:nth-child(7),
  th:nth-child(10),
  td:nth-child(10) {
    width: 110px;
  }

  th:nth-child(11),
  td:nth-child(11) {
    width: 130px;
  }

  ${moneyColumnStyles(6)};

  th:nth-child(6),
  td:nth-child(6) {
    width: 150px;
  }
`;

export const PayInvoicesWindowedTableS = styled(WindowedTable)`
  th {
    font-size: 10px;
  }

  tbody {
    vertical-align: middle;
  }

  thead {
    white-space: nowrap;
  }

  tr {
    margin: 0;
    width: 100%;
  }

  td {
    padding: 5px;

    & > div {
      & > input {
        height: 26px;
      }
      & > div {
        padding: 0;
        line-height: 14px;
      }
      & > svg {
        top: 5px;
      }
    }
  }

  tfoot {
    position: unset;
  }
  thead {
    background: ${getThemeColor(['lightest'])};
  }
  ${R.prop('tableStyles')}
`;

const PayInvoicesTableS = styled(Table)`
  ${R.prop('tableStyles')};
`;

const useClientDiscountLogic = () => {
  const { discount } = useRouterQuery(['discount']);
  const invoices = useSelector(R.prop('invoices'));
  const dispatch = useDispatch();

  const { data } = useQuery(GET_ACCOUNTS_QUERY, {
    variables: {
      filters: {
        searchText: 'Customer Discounts Given',
        subAccount: true,
        isClientPaymentDeduction: true,
        from: 0,
        size: 1,
      },
    },
  });
  const discountAccountId = R.path(['getAccounts', 'hits', 0, '_id'], data);

  useEffect(() => {
    const selectedInvoices = filterSelectedInvoices(invoices);
    if (selectedInvoices.length > 0 && discount && discount > 0) {
      let invoicesWithDiscount = distributeInvoicesDiscount(
        discount,
        invoices,
        discountAccountId,
      );
      const distributedDiscountTotal =
        calculateSelectedInvoicesDeductionsTotal(invoicesWithDiscount);
      const unassignedDiscountFraction = discount - distributedDiscountTotal;
      if (unassignedDiscountFraction !== 0) {
        invoicesWithDiscount = adjustDiscount(
          unassignedDiscountFraction,
          invoicesWithDiscount,
        );
      }
      dispatch(setAllInvoices(invoicesWithDiscount));
    }
  }, [discount]);
};

export const ReceivePaymentsTable = memo(({ virtualized, ...props }) => {
  const { onSelectAll, isAllSelected, rows } = useInvoicesHandlers();
  useClientDiscountLogic();

  const tableConfig = paySuppliersTableConfig(onSelectAll, isAllSelected);
  const tableProps = useMapConfigToTablePropsWithSorting({
    tableConfig,
    items: rows,
    initialSorting: {
      columnKey: 0,
      dir: 1,
    },
  });

  const TableC = virtualized ? PayInvoicesWindowedTableS : PayInvoicesTableS;

  return (
    <TableC
      {...tableProps}
      {...props}
      tableStyles={payClientInvoicesTableStyles}
      itemSize={60}
      moreScrollPosition="0px"
      showScrollBar
    />
  );
});

ReceivePaymentsTable.propTypes = { virtualized: bool };
