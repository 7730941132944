import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';

const cogsAccountQuery = gql`
  query cogsFacilityParentAccountQuery($filters: AccountFilters) {
    getAccounts(filters: $filters) {
      hits {
        _id
        code
      }
    }
  }
`;

// getCogsAccountCodes :: { getAccounts: GetAccountsResult } -> [String]
const getCogsAccountCodes = R.compose(
  R.map(R.prop('code')),
  R.pathOr([], ['getAccounts', 'hits']),
);

export const useCogsAccount = () => {
  const { data, loading } = useQuery(cogsAccountQuery, {
    variables: { filters: { isCogsAccount: true, subAccount: false } },
    fetchPolicy: 'network-only',
  });

  const cogsAccountCodes = getCogsAccountCodes(data);

  return { loading, cogsAccountCodes };
};
