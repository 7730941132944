import { string } from 'prop-types';
import React, { useState } from 'react';
import { formatSelectOptionsByConstants } from '@poly/client-utils';
import { USAStates } from '@poly/constants';
import { Select } from '@poly/admin-book';

export function StateSelect({ placeholder = 'Start typing state', ...props }) {
  const [searchText, setSearchText] = useState('');
  const options = formatSelectOptionsByConstants(USAStates);

  return (
    <Select
      {...props}
      options={options}
      searchText={searchText}
      placeholder={placeholder}
      onInputChange={setSearchText}
    />
  );
}

StateSelect.propTypes = { placeholder: string };
