import React from 'react';
import styled from 'styled-components';
import { arrayOf, shape, string } from 'prop-types';
import * as R from 'ramda';
import { useMapConfigToTableProps } from '@poly/admin-ui';
import { pathOrNothingUI } from '@poly/client-utils';
import { Table } from '@poly/admin-book';

import {
  AddClientDistrictBtn,
  EditClientDistrictBtn,
} from './ClientDistrictButtons.js';

const TableS = styled(Table)`
  thead > tr > th {
    margin: 0;
    padding: 0;
    height: 0;
  }

  tr {
    border: none;
  }
  tr:hover {
    box-shadow: none;
  }

  td:nth-child(1),
  th:nth-child(1) {
    width: 95%;
    padding: 5px 0 !important;
  }

  td:last-child,
  th:last-child {
    width: 5%;
    padding: 5px;
  }
`;

function ClientDistrictsList({ clientId, clientName, districts }) {
  const tableConfig = [
    ['', pathOrNothingUI(['name'])],
    [
      '',
      ({ _id, name }) => (
        <EditClientDistrictBtn
          clientId={clientId}
          clientName={clientName}
          districtId={_id}
          districtName={name}
        />
      ),
    ],
  ];

  const tableProps = useMapConfigToTableProps(
    R.identity,
    tableConfig,
    districts,
  );

  return districts.length ? <TableS {...tableProps} /> : null;
}

ClientDistrictsList.propTypes = {
  districts: arrayOf(shape({ _id: string, name: string })),
  clientId: string.isRequired,
  clientName: string.isRequired,
};

const SubTabHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export function ClientDistrictsTab({ clientId, clientName, districts }) {
  return (
    <>
      <SubTabHeader>
        <AddClientDistrictBtn clientId={clientId} clientName={clientName} />
      </SubTabHeader>
      <ClientDistrictsList
        clientId={clientId}
        clientName={clientName}
        districts={districts}
      />
    </>
  );
}

ClientDistrictsTab.propTypes = {
  districts: arrayOf(shape({ _id: string, name: string })),
  clientId: string.isRequired,
  clientName: string.isRequired,
};
