import * as R from 'ramda';
import { gql } from '@apollo/client';
import { MAX_ITEMS } from '@poly/admin-ui';
import { useReactiveQuery } from '@poly/client-utils';

const SEARCH_PROCEDURES_QUERY = gql`
  query SEARCH_PROCEDURES_QUERY($input: CollectionSearchParams) {
    searchProcedures(input: $input) {
      hits {
        _id
        name
        assetTypes {
          _id
          name
        }
        steps {
          name
          description
          isRequiredStep
          isImageUploadRequired
        }
      }
    }
  }
`;

const SEARCH_PROCEDURE_CHANGED_SUBSCRIPTION = gql`
  subscription SEARCH_PROCEDURE_CHANGED_SUBSCRIPTION(
    $input: CollectionSearchParams
  ) {
    searchProcedureChanged(input: $input) {
      id
      type
    }
  }
`;

// prepareProcedures :: { searchProcedures: { hits: [Procedure] } } -> [Procedure]
const prepareProcedures = R.pathOr([], ['searchProcedures', 'hits']);

export const useProceduresQuery = () => {
  const queryOptions = { variables: { input: { size: MAX_ITEMS } } };

  const { data, loading } = useReactiveQuery(
    SEARCH_PROCEDURES_QUERY,
    SEARCH_PROCEDURE_CHANGED_SUBSCRIPTION,
    { queryOptions, subscriptionOptions: queryOptions },
  );

  const procedures = prepareProcedures(data);

  return { procedures, loading };
};
