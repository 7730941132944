import React from 'react';
import styled from 'styled-components';
import { Button, getThemeColor } from '@poly/admin-book';
import { bool, func } from 'prop-types';

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const EditBtn = styled(Button).attrs(() => ({
  styleType: 'basicSecondary',
  type: 'button',
  size: 'small',
  children: 'Edit',
}))`
  background-color: transparent;
  height: 18px;
  padding: 0 5px;
  color: ${getThemeColor(['primaryLight'])};
`;

const ShowMoreButtonS = styled.div`
  bottom: 0;
  right: -10px;
  cursor: pointer;
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  padding-left: 30px;
  color: ${getThemeColor(['primaryLight'])};
  background-color: ${getThemeColor(['white'])};
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) -15%,
    rgba(255, 255, 255, 1) 28%,
    rgba(255, 255, 255, 1) 99%
  );
`;

export function ShowMoreButton({ showMore, setShowMore }) {
  return (
    <ShowMoreButtonS onClick={() => setShowMore(!showMore)}>
      {showMore ? 'Show less' : 'Show more'}
    </ShowMoreButtonS>
  );
}

ShowMoreButton.propTypes = {
  showMore: bool.isRequired,
  setShowMore: func.isRequired,
};
