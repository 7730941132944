import React from 'react';
import * as R from 'ramda';
import { arrayOf, element, object, oneOfType, string } from 'prop-types';
import { useUpdateQueryParams } from '@poly/client-routing';
import { useOutSidebarContext } from '@poly/client-utils';
import { SidebarWidth } from '@poly/admin-ui';

import { SidebarIDs } from '../constants.js';
import { ContactSidebar } from './ContactSidebar.js';
import { LinkToSidebar } from '../../components/LinkToSidebar.js';
import { usePreviousSidebarParams } from '../usePreviousSidebarParams.js';
import { useClearPristineState } from '../useClearPristineOnSidebarMount.js';
import { CustomSidebarLayout } from '../components/CustomSidebarLayout.js';

export function ContactLink({
  _id,
  fullName,
  children,
  queryParams = {},
  ...props
}) {
  const linkParams = R.mergeDeepLeft(
    { query: { [SidebarIDs.contact]: _id } },
    queryParams,
  );

  const fullLinkProps = { linkParams, ...props };

  return (
    <LinkToSidebar {...fullLinkProps}>{children || fullName}</LinkToSidebar>
  );
}

ContactLink.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  queryParams: object,
  _id: string.isRequired,
  fullName: oneOfType([string, element, arrayOf(oneOfType([string, element]))]),
  children: oneOfType([string, element, arrayOf(oneOfType([string, element]))]),
};

export const useOpenContactSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();

  const updateQueryParams = useUpdateQueryParams();
  const queryParams = usePreviousSidebarParams();

  const afterClose = () => {
    updateQueryParams({
      sidebarTab: queryParams.sidebarTab,
      [SidebarIDs.contact]: undefined,
    });
  };

  return (contactId, skipSteady) =>
    openOutSidebar(
      {
        afterClose,
        isCustom: true,
        width: SidebarWidth,
        id: SidebarIDs.contact,
        Layout: CustomSidebarLayout,
        content: contactId ? <ContactSidebar contactId={contactId} /> : null,
      },
      skipSteady,
    );
};

export const useNavigateToContactSidebar = () => {
  const updateQueryParams = useUpdateQueryParams();
  const clearPristine = useClearPristineState();

  return (contactId) => {
    clearPristine();
    updateQueryParams({ [SidebarIDs.contact]: contactId });
  };
};
