import { number, shape } from 'prop-types';
import React from 'react';
import * as R from 'ramda';

import { AppBarMenuBubble } from './MenuBubble.js';

// getAccountingBubbleTotal :: MenuPayload -> Number
// MenuPayload = {
//  receivedInvoicesTotal: Number
//  receivedInvoicesTotal: Number
// }
const getAccountingBubbleTotal = R.converge(R.add, [
  R.propOr(0, 'receivedInvoicesTotal'),
  R.propOr(0, 'supplierDocumentsForReviewCount'),
]);

export function AccountingAppBarBubble({ menuPayload }) {
  return <AppBarMenuBubble value={getAccountingBubbleTotal(menuPayload)} />;
}

AccountingAppBarBubble.propTypes = {
  menuPayload: shape({
    receivedInvoicesTotal: number,
    supplierDocumentsForReviewCount: number,
  }),
};
