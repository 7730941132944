import * as R from 'ramda';
import { processesReducerByDefaultState } from '@poly/admin-ui';

export const processes = {
  EXPORTING_TO_PDF: 'EXPORTING_TO_PDF',
  INVOICE_SAVING: 'INVOICE_SAVING',
  CLIENT_UPDATING: 'CLIENT_UPDATING',
  PROPERTY_UPDATING: 'PROPERTY_UPDATING',
  PROPERTY_SAVING: 'PROPERTY_SAVING',
  UNASSIGNED_PROJECTS_LOADING: 'UNASSIGNED_PROJECTS_LOADING',
};

const defaultState = R.pipe(
  R.keys,
  R.reduce((obj, key) => R.assoc(key, false, obj), {}),
)(processes);

export const processesReducer = processesReducerByDefaultState(defaultState);
