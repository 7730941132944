import React from 'react';
import { extractTablePropsFromConfig, useSetItemsCount } from '@poly/admin-ui';
import {
  duplicateField,
  useHighlightMatchesBySearch,
} from '@poly/client-utils';
import * as R from 'ramda';
import { arrayOf, func, number, oneOfType, shape, string } from 'prop-types';
import styled from 'styled-components';

import { setDefaultProjectManager } from '../../tables/projectsTable/projectsTableUtils.js';
import { ProjectsPageProjectsTableComp } from '../../tables/projectsTable/ProjectsTableComp.js';
import {
  descriptionColumnStyles,
  propertyColumnStyles,
} from '../../tables/tablesStyles.js';

// formatProjectsRows :: [Project] -> [Project]
const formatProjectsRows = R.compose(
  setDefaultProjectManager,
  R.map(duplicateField('projectId', 'projectLinkId')),
);

const ProjectsTable = styled(ProjectsPageProjectsTableComp)`
  ${descriptionColumnStyles(3)};
  ${propertyColumnStyles(4)}
`;

export function ProjectsTableBase({ projects, sortProps, tableConfig, total }) {
  useSetItemsCount(R.identity, total);
  const { highlightedRows } = useHighlightMatchesBySearch(
    formatProjectsRows,
    [
      ['projectId'],
      ['description'],
      ['property', 'name'],
      ['client', 'name'],
      ['manager', 'fullName'],
    ],
    projects,
  );
  const { columns, headers, sortQueries } =
    extractTablePropsFromConfig(tableConfig);

  return (
    <ProjectsTable
      sortQueries={sortQueries}
      rows={highlightedRows}
      columns={columns}
      headers={headers}
      {...sortProps}
    />
  );
}

ProjectsTableBase.propTypes = {
  projects: arrayOf(shape({ projectId: string.isRequired })),
  sortProps: shape({
    onHeaderCellClick: func.isRequired,
    sorting: shape({ columnKey: number, dir: number }),
  }),
  tableConfig: arrayOf(arrayOf(oneOfType([string, func]))).isRequired,
  total: number,
};
