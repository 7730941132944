import React from 'react';
import * as R from 'ramda';
import { useReactiveQuery } from '@poly/client-utils';
import { gql } from '@apollo/client';
import { useMapConfigToTableProps } from '@poly/admin-ui';

import { accountTypesTableConfig } from './accountTypesTableConfig.js';
import { AccountTypesTableS } from './components.js';
import { ReportPageTableWrapper } from '../../components/ReportPageTableWrapper.js';

const GET_ACCOUNT_TYPES_QUERY = gql`
  query GET_ACCOUNT_TYPES($input: AccountTypesInput) {
    accountTypes(input: $input) {
      _id
      name
      status
      category
      normal_balance
      system_type
    }
  }
`;

const ACCOUNT_TYPES_CHANGED_SUBSCRIPTION = gql`
  subscription ACCOUNT_TYPES_CHANGED_SUBSCRIPTION(
    $input: CollectionSearchParams
  ) {
    searchAccountTypeChanged(input: $input) {
      id
      type
    }
  }
`;

export function AccountTypesTable() {
  const { data, loading } = useReactiveQuery(
    GET_ACCOUNT_TYPES_QUERY,
    ACCOUNT_TYPES_CHANGED_SUBSCRIPTION,
    { queryOptions: {}, subscriptionOptions: {} },
  );

  const { rows, headers, columns, sortQueries } = useMapConfigToTableProps(
    R.propOr([], 'accountTypes'),
    accountTypesTableConfig,
    data,
  );

  return (
    <ReportPageTableWrapper loading={loading}>
      <AccountTypesTableS
        headers={headers}
        rows={rows}
        columns={columns}
        sortQueries={sortQueries}
        isLoading={loading}
      />
    </ReportPageTableWrapper>
  );
}
