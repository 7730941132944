import React from 'react';
import { DatePicker } from '@poly/admin-book';
import {
  LISTING_PROJECT_SPECIFIC_FIELDS_UI_MAP as LISTING_LABELS,
  ListingTasksActionsTypes,
  taskActionTypes,
  ProjectType,
} from '@poly/constants';

import { halfWidth } from '../../../modules/forms/common.js';
import { MoneyInput } from '../../../modules/forms/fields/MoneyInput.js';

function DatePickerFullWidthLeft(props) {
  return <DatePicker leftMove="-10px" {...props} width="100%" required />;
}

function DatePickerFullWidthRight(props) {
  return <DatePicker leftMove="-70px" {...props} width="100%" required />;
}

const taskActionCustomConfig = {
  [ListingTasksActionsTypes.BROKER_PRICE_OPINION]: [
    {
      order: 1,
      layout: { row: 4, width: halfWidth },
      label: LISTING_LABELS.bpoListPrice,
      field: {
        name: 'bpoListPrice',
        Component: MoneyInput,
      },
      required: true,
    },
    {
      order: 2,
      layout: { row: 4, width: halfWidth },
      label: LISTING_LABELS.bpoDate,
      field: {
        name: 'bpoDate',
        Component: DatePickerFullWidthRight,
      },
      required: true,
    },
    {
      order: 1,
      layout: { row: 5, width: halfWidth },
      label: LISTING_LABELS.bpoLow,
      field: {
        name: 'bpoLow',
        Component: MoneyInput,
      },
      required: true,
    },
    {
      order: 2,
      layout: { row: 5, width: halfWidth },
      label: LISTING_LABELS.bpoHigh,
      field: {
        name: 'bpoHigh',
        Component: MoneyInput,
      },
      required: true,
    },
  ],
  [ListingTasksActionsTypes.COMPLETE_SALES_ADDENDUM]: [
    {
      order: 1,
      layout: { row: 4, width: halfWidth },
      label: LISTING_LABELS.salesAddendumDate,
      field: {
        name: 'salesAddendumDate',
        Component: DatePickerFullWidthLeft,
      },
      required: true,
    },
  ],
  [ListingTasksActionsTypes.LIST_FOR_SALE]: [
    {
      order: 1,
      layout: { row: 4, width: halfWidth },
      label: LISTING_LABELS.listPrice,
      field: {
        name: 'listPrice',
        Component: MoneyInput,
      },
      required: true,
    },
    {
      order: 2,
      layout: { row: 4, width: halfWidth },
      label: LISTING_LABELS.listDate,
      field: {
        name: 'listDate',
        Component: DatePickerFullWidthRight,
      },
      required: true,
    },
  ],
  [ListingTasksActionsTypes.UNDER_CONTRACT]: [
    {
      order: 1,
      layout: { row: 4, width: halfWidth },
      label: LISTING_LABELS.underContractDate,
      field: {
        name: 'underContractDate',
        Component: DatePickerFullWidthLeft,
      },
      required: true,
    },
    {
      order: 2,
      layout: { row: 4, width: halfWidth },
      label: LISTING_LABELS.dueDiligenceExp,
      field: {
        name: 'dueDiligenceExp',
        Component: DatePickerFullWidthRight,
      },
      required: true,
    },
    {
      order: 1,
      layout: { row: 5, width: halfWidth },
      label: LISTING_LABELS.underContractPrice,
      field: {
        name: 'underContractPrice',
        Component: MoneyInput,
      },
      required: true,
    },
    {
      order: 2,
      layout: { row: 5, width: halfWidth },
      label: LISTING_LABELS.expectedCloseDate,
      field: {
        name: 'expectedCloseDate',
        Component: DatePickerFullWidthRight,
      },
      required: true,
    },
  ],
};

// getTaskActionCustomConfig :: (Project, String) -> [FormSection]
export const getTaskActionCustomConfig = (project, action) => {
  if (
    project &&
    project.type === ProjectType.LISTING &&
    action === taskActionTypes.PROJECT_COMPLETE
  ) {
    return [
      {
        order: 8,
        layout: { row: 9, width: halfWidth },
        label: LISTING_LABELS.referralFee,
        field: {
          name: 'referralFee',
          Component: MoneyInput,
        },
        required: true,
      },
    ];
  }
  return taskActionCustomConfig[action];
};
