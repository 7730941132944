export const ClientInvoiceStatuses = {
  PENDING: 'pendingClientInvoiceStatus',
  PARTIALLY_PAID: 'partiallyPaidClientInvoiceStatus',
  PAID: 'paidClientInvoiceStatus',
  VOIDED: 'voidedClientInvoiceStatus',
  WRITTEN_OFF: 'writtenOffClientInvoiceStatus',
};

export const NOT_INVOICED_CLIENT_INVOICE_STATUS =
  'notInvoicedClientInvoiceStatuses';

export const AFTER_HOURS_CALL_LINE_NAME = 'After Hours On-Call Dispatch Fee';
