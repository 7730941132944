import { arrayOf, number, shape, string } from 'prop-types';

export const projectTimeEntryPropType = {
  _id: string.isRequired,
  date: string.isRequired,
  user: shape({
    fullName: string.isRequired,
  }),
  totalTimeInMinutes: number.isRequired,
  rateDescription: string.isRequired,
  type: string.isRequired,
  notes: string,
};

export const projectPropTypes = {
  _id: string.isRequired,
  timeEntries: arrayOf(shape(projectTimeEntryPropType)),
};
