import * as R from 'ramda';
import {
  getHours,
  getMinutes,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns';
import { ensureIsDate, ramdaUseWith } from '@poly/utils';

import { propIsDate } from './common.js';

const applyDateToTime = (baseDate) => (timeDate) => {
  const hours = getHours(timeDate);
  const minutes = getMinutes(timeDate);
  return setSeconds(
    setMinutes(setHours(ensureIsDate(baseDate), hours), minutes),
    0,
  );
};

// applyDateToTimeIfExists :: (String, Date) -> Object -> Object
const applyDateToTimeIfExists = (prop, baseDate) =>
  R.when(propIsDate(prop), R.over(R.lensProp(prop), applyDateToTime(baseDate)));

// normalizeTimesheetEntry :: Date -> TimesheetEntry -> TimesheetEntry
// eslint-disable-next-line import/no-unused-modules
export const normalizeTimesheetEntry = (baseDate) =>
  R.compose(
    R.pick(['timeIn', 'timeOut']),
    applyDateToTimeIfExists('timeIn', baseDate),
    applyDateToTimeIfExists('timeOut', baseDate),
  );

// normalizeTimesheetEntriesByDate :: Date -> [TimesheetEntry] -> [TimesheetEntry]
export const normalizeTimesheetEntriesByDate = ramdaUseWith(R.map, [
  normalizeTimesheetEntry,
  R.identity,
]);

// normalizeTimesheetEntries :: Timesheet -> Timesheet
export const normalizeTimesheetEntries = R.compose(
  R.mergeAll,
  R.juxt([
    R.identity,
    R.compose(
      R.objOf('entries'),
      R.reject(R.isEmpty),
      R.converge(normalizeTimesheetEntriesByDate, [
        R.prop('date'),
        R.propOr([], 'entries'),
      ]),
    ),
  ]),
);
