import { AccountingStatus, WorkOrderStatus } from '@poly/constants';

const recurringProjectsStatusFilter = {
  terms: {
    status: [
      WorkOrderStatus.ACTIVE,
      WorkOrderStatus.ON_HOLD_CLIENT,
      WorkOrderStatus.ON_HOLD_PARTS,
      WorkOrderStatus.ON_HOLD_PROJECT,
    ],
  },
};

// eslint-disable-next-line import/no-unused-modules
export const scheduledRecurringProjectsElasticQuery = [
  recurringProjectsStatusFilter,
  {
    bool: {
      must: {
        exists: { field: 'scheduleDate' },
      },
    },
  },
];

// eslint-disable-next-line import/no-unused-modules
export const unscheduledRecurringProjectsElasticQuery = [
  recurringProjectsStatusFilter,
  {
    bool: {
      must_not: {
        exists: { field: 'scheduleDate' },
      },
      must: {
        exists: { field: 'parentId' },
      },
    },
  },
];

// eslint-disable-next-line import/no-unused-modules
export const completedRecurringProjectsElasticQuery = [
  { term: { status: WorkOrderStatus.COMPLETED } },
  { term: { accountingStatus: AccountingStatus.PENDING } },
  {
    bool: {
      must: {
        exists: { field: 'parentId' },
      },
    },
  },
];
