import React from 'react';
import { object, string, shape } from 'prop-types';
import { TasksTab as TasksTabImp } from '@poly/admin-ui';

import { useTasksPageTableHandlers } from './useTasksPageTableHandlers.js';

export function TasksTab({
  readFromCacheParams,
  entity,
  collection,
  ...props
}) {
  const taskHandlers = useTasksPageTableHandlers({
    entity,
    collection,
    readFromCacheParams,
  });

  const tabProps = {
    ...props,
    entity,
    collection,
    readFromCacheParams,
    ...taskHandlers,
  };

  return <TasksTabImp {...tabProps} />;
}

TasksTab.displayName = 'EntityTasksTab';
/* eslint-disable react/forbid-prop-types */
TasksTab.propTypes = {
  readFromCacheParams: object,
  collection: string.isRequired,
  entity: shape({
    _id: string.isRequired,
    name: string.isRequired,
  }),
};
