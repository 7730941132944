import React from 'react';
import { bool, func } from 'prop-types';
import { Checkbox } from '@poly/admin-book';

export function SelectAllPaymentsCheckBox({
  isAllSelected,
  onSelectAllPayment,
}) {
  const componentName = 'select-all-payments-checkbox';

  return (
    <Checkbox
      name={componentName}
      value={isAllSelected}
      onChange={onSelectAllPayment}
      dataTestId={componentName}
    />
  );
}

SelectAllPaymentsCheckBox.propTypes = {
  isAllSelected: bool,
  onSelectAllPayment: func,
};
