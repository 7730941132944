import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, number, shape, string, oneOfType } from 'prop-types';
import { formatCurrency, formatDate, formatTotal } from '@poly/utils';
import { moneyColumnStyles, Table } from '@poly/admin-book';
import { useMapConfigToTableProps } from '@poly/admin-ui';

const transactionsPropTypes = arrayOf(
  shape({
    date: string,
    invoiceAmount: number,
    paymentAmount: number,
    projectNumber: string,
    transactionName: string,
    transactionNumber: string,
    transactionType: oneOfType([string, number]),
    invoiceNumber: oneOfType([string, number]),
  }),
);

// getBalanceAmount :: Transaction -> Int
export const getBalanceAmount = R.converge(R.subtract, [
  R.propOr(0, 'invoiceAmount'),
  R.propOr(0, 'paymentAmount'),
]);

// calculateTransactionBalance :: Transaction -> String
const calculateTransactionBalance = R.compose(formatCurrency, getBalanceAmount);

// calculateTransactionsTotalByHandler :: Function -> [Transaction] -> String
const calculateTransactionsTotalByHandler = (handler) =>
  R.compose(formatTotal, R.sum, R.map(handler), R.defaultTo([]));

const vendorLedgerReportTableConfig = [
  ['Transaction', R.prop('transactionName')],
  ['Date', R.compose(formatDate, R.prop('date'))],
  ['Num', R.prop('transactionNumber')],
  ['Invoice #', R.prop('invoiceNumber')],
  ['Type', R.prop('transactionType')],
  ['Project #', R.prop('projectNumber')],
  ['Invoice', R.compose(formatCurrency, R.prop('invoiceAmount'))],
  ['Payment', R.compose(formatCurrency, R.prop('paymentAmount'))],
  ['Balance', calculateTransactionBalance],
];

const VendorLedgerReportTableS = styled(Table)`
  ${moneyColumnStyles(7)};
  ${moneyColumnStyles(8)};
  ${moneyColumnStyles(9)};
`;

const VendorLedgerReportTableFooterContainer = styled.tr`
  padding: 20px 0;
  font-weight: bold;
  font-size: 15px;

  ${moneyColumnStyles(2)};
  ${moneyColumnStyles(3)};
  ${moneyColumnStyles(4)};
`;

function TotalFooter({ rows }) {
  return (
    <VendorLedgerReportTableFooterContainer>
      <td>Total</td>
      <td>
        {calculateTransactionsTotalByHandler(R.propOr(0, 'invoiceAmount'))(
          rows,
        )}
      </td>
      <td>
        {calculateTransactionsTotalByHandler(R.propOr(0, 'paymentAmount'))(
          rows,
        )}
      </td>
      <td>{calculateTransactionsTotalByHandler(getBalanceAmount)(rows)}</td>
    </VendorLedgerReportTableFooterContainer>
  );
}

TotalFooter.propTypes = { rows: transactionsPropTypes };

export function VendorLedgerReportTable({ transactions }) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    vendorLedgerReportTableConfig,
    transactions,
  );

  return (
    <VendorLedgerReportTableS
      {...tableProps}
      footerHeight={60}
      TableFooter={TotalFooter}
      showScrollBar
      overflow="auto"
    />
  );
}

VendorLedgerReportTable.propTypes = { transactions: transactionsPropTypes };
