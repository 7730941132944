import * as R from 'ramda';
import { InvoicesStatuses, WorkOrderStatus } from '@poly/constants';

import {
  generateSearchQuery,
  matchQueryGenerator,
} from '../../utils/generateSearchQuery.js';

const queryGenerator = {
  invoiceStatus: matchQueryGenerator('status'),
  clientId: matchQueryGenerator('clientId'),
  serviceTypeId: matchQueryGenerator('project.serviceTypeId'),
  propertyId: matchQueryGenerator('propertyId'),
  projectId: matchQueryGenerator('documentId'),
  projectType: matchQueryGenerator('project.type'),
  supplierId: matchQueryGenerator('supplierId'),
  managerId: matchQueryGenerator('task.managerId'),
};

// getOSISearchInputQuery :: PageFilters -> JSON
// PageFilters = {
//    clientId: ID
//    serviceTypeId: ID
//    managerId: ID
//    propertyId: ID
//    projectId: ID
//    projectType: String
//    invoiceStatus: String
// }
export const getOSISearchInputQuery = R.compose(
  R.over(
    R.lensPath(['bool', 'must']),
    R.concat([{ match: { 'project.status': WorkOrderStatus.COMPLETED } }]),
  ),
  generateSearchQuery(queryGenerator),
);

// getESVirtualInvoiceStatusesMatch :: [String] -> ESMatchByStatus
// ESMatchByStatus = { bool: { should: [{ term: { status: String } }] } }
// eslint-disable-next-line import/no-unused-modules
export const getESVirtualInvoiceStatusesMatch = R.compose(
  R.objOf('bool'),
  R.objOf('should'),
  R.map(R.compose(R.objOf('term'), R.objOf('supplierInvoice.status'))),
);

export const ALL_ALLOWED_INVOICE_STATUSES = [
  InvoicesStatuses.REQUESTED,
  InvoicesStatuses.RECEIVED,
  InvoicesStatuses.MANUAL_FOLLOW_UP,
];
