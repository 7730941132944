import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import {
  assocBy,
  ofArrayLegacy,
  propEqLegacy,
  removeFileExtension,
} from '@poly/utils';
import { useLocation } from '@poly/client-routing';
import {
  filterDocumentsMatchedByPaths,
  useReactiveQuery,
} from '@poly/client-utils';

import { TabsViews } from '../../../constants/tabs.js';
import { MAX_ITEMS } from '../../../constants/general.js';
import { useSearchTabLogic } from '../../../hooks/useSearchTabLogic.js';
import { checkIfPinnedByCollection } from '../../../modules/tables/updatesTable/updatesInfoUtils.js';

const SEARCH_UPDATES_CHANGED = gql`
  subscription UPDATES_SEARCH_CHANGED($searchInput: SearchUpdateChangedInput!) {
    searchUpdateChanged(input: $searchInput) {
      id
      type
    }
  }
`;

export const SEARCH_FILES_CHANGED = gql`
  subscription SEARCH_FILES_CHANGED($searchInput: CollectionSearchParams!) {
    searchFileChanged(input: $searchInput) {
      id
      type
    }
  }
`;

// prepareUpdatesBySearch :: (String, String, RowInfo, Object) -> EntityQueryResult -> [Update]
// EntityQueryResult = Object
// RowInfo = { collection: String, isReplyActive: bool, setActiveReply: Func }
const prepareUpdatesBySearch = (path, searchTerm, rowInfo, LinkToParentClone) =>
  R.compose(
    R.when(
      R.both(R.complement(R.isEmpty), () => !!LinkToParentClone),
      R.converge(R.concat, [
        R.dropLast(1),
        R.compose(ofArrayLegacy, R.mergeLeft({ LinkToParentClone }), R.last),
      ]),
    ),
    R.map(R.mergeDeepLeft(rowInfo)),
    R.map(R.omit(['attachmentsFileNames'])),
    filterDocumentsMatchedByPaths(
      [['title'], ['message'], ['attachmentsFileNames']],
      searchTerm,
    ),
    R.map(
      assocBy(
        'attachmentsFileNames',
        R.compose(
          R.join(' '),
          R.map(R.compose(removeFileExtension, R.prop('fileName'))),
          R.propOr([], 'attachments'),
        ),
      ),
    ),
    R.pathOr([], [path, 'documentUpdates', 'hits']),
  );

// getUpdatesPrintInfo :: String -> EntityQueryResult -> [String]
const getUpdatesPrintInfo = (path) =>
  R.compose(
    R.reject(R.isNil),
    R.juxt([R.path(['client', 'name']), R.path(['property', 'name'])]),
    R.propOr({}, path),
  );

// getIdFromHash :: Location -> ID
const getIdFromHash = R.compose(R.last, R.split('_'), R.prop('hash'));

// findUpdate :: (Updates, ID, String) -> Update
const findUpdate = (data, id, entityName) =>
  R.compose(
    R.find(propEqLegacy('_id', id)),
    R.path([entityName, 'documentUpdates', 'hits']),
  )(data);

export const useUpdatesTabLogic = ({
  entity,
  collection,
  updatesQuery,
  LinkToParentClone,
  tabView = TabsViews.sidebar,
}) => {
  const [showAudit, setShowAudit] = useState(false);
  const [isReplyActive, setIsReplyActive] = useState(null);
  const reduxSearchTerm = useSelector(R.prop('searchText'));
  const { searchTerm: localSearchTerm, ...inputProps } =
    useSearchTabLogic('updates');

  const { _id, name } = entity;
  const searchText =
    tabView === TabsViews.sidebar ? localSearchTerm : reduxSearchTerm;

  const isPinnedByCollection = checkIfPinnedByCollection(collection);

  const queryVariables = {
    id: _id,
    documentUpdatesInput: {
      includeAudit: showAudit,
      sort: {
        ...(isPinnedByCollection && tabView === TabsViews.sidebar
          ? { pinned: -1 }
          : {}),
        createdAt: -1,
      },
      from: 0,
      size: MAX_ITEMS,
    },
  };

  const queryOptions = {
    fetchPolicy: 'network-only',
    variables: queryVariables,
    skip: !_id,
  };

  const { data, loading, debouncedRefetch } = useReactiveQuery(
    updatesQuery,
    [SEARCH_UPDATES_CHANGED, SEARCH_FILES_CHANGED],
    {
      queryOptions,
      subscriptionOptions: [
        { variables: { searchInput: { documentId: _id } }, skip: !_id },
        { variables: { searchInput: { documentId: _id } }, skip: !_id },
      ],
    },
  );

  const location = useLocation();

  useEffect(() => {
    if (
      data &&
      location.hash &&
      !findUpdate(data, getIdFromHash(location), name)
    ) {
      setShowAudit(true);
    }
  }, [data]);

  const rowInfo = {
    searchText,
    collection,
    isReplyActive,
    setActiveReply: setIsReplyActive,
    debouncedRefetch,
    updateCacheParams: {
      query: updatesQuery,
      entity: entity.name,
      variables: queryVariables,
    },
  };

  const updates = prepareUpdatesBySearch(
    name,
    searchText,
    rowInfo,
    LinkToParentClone,
  )(data);

  const printInfo = getUpdatesPrintInfo(name)(data);

  return {
    loading,
    updates,
    printInfo,
    showAudit,
    inputProps,
    toggleAudit: setShowAudit,
    updateCacheParams: {
      query: updatesQuery,
      variables: queryVariables,
    },
  };
};
