import * as R from 'ramda';
import { useReactiveQuery } from '@poly/client-utils';
import { gql } from '@apollo/client';

const GET_CLIENT_GL_CODES_QUERY = gql`
  query GET_CLIENT_GL_CODES_QUERY($clientId: ID!, $input: ClientGLCodesInput) {
    client(id: $clientId) {
      _id
      glCodes(input: $input) {
        _id
        name
        code
        isActive
      }
    }
  }
`;

const CLIENT_GL_CODES_SUBSCRIPTION = gql`
  subscription CLIENT_GL_CODES_SUBSCRIPTION($input: CollectionSearchParams) {
    clientGLCodesChanged(input: $input) {
      id
      type
    }
  }
`;

export const useClientGLCodes = (clientId, isActive) => {
  const { data, loading } = useReactiveQuery(
    GET_CLIENT_GL_CODES_QUERY,
    CLIENT_GL_CODES_SUBSCRIPTION,
    {
      queryOptions: { variables: { clientId, input: { isActive } } },
      subscriptionOptions: {},
    },
  );

  return {
    loading,
    glCodes: R.pathOr([], ['client', 'glCodes'], data),
  };
};
