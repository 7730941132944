import * as R from 'ramda';
import {
  formatTotal,
  convertCentsToDollars,
  calculateTotal,
  getPropertiesDeepByHierarchyNodeR,
} from '@poly/utils';

// preparePropertyHierarchyTotals :: PropertyHierarchySpendReportResult -> Result
// Result = {
//    costTotal: String
//    clientInvoiceTotal: String
// }
export const preparePropertyHierarchyTotals = R.compose(
  R.applySpec({
    costTotal: R.compose(
      formatTotal,
      convertCentsToDollars,
      calculateTotal(R.propOr(0, 'invoicesTotal')),
    ),
    clientInvoiceTotal: R.compose(
      formatTotal,
      convertCentsToDollars,
      calculateTotal(R.propOr(0, 'clientInvoicesTotal')),
    ),
  }),
  getPropertiesDeepByHierarchyNodeR([]),
);
