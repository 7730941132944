import styled from 'styled-components';
import { getThemeFont, InputErrorMsg } from '@poly/admin-book';

export const LinesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-right: 20px;
  position: relative;
`;

export const LineBottom = styled(Line)`
  align-items: center;
  flex-wrap: wrap;
`;

export const TotalAmount = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: ${getThemeFont(['medium'])};
`;

export const TotalFieldErrorMsg = styled(InputErrorMsg)`
  flex-grow: 1;
  text-align: right;
  width: 500px;
`;
