import { arrayOf, shape, number, string } from 'prop-types';

export const accountsReceivableEntriesPropTypes = arrayOf(
  shape({
    client: shape({
      _id: string.isRequired,
      name: string.isRequired,
    }).isRequired,
    amount: number.isRequired,
  }),
);
