import React from 'react';
import { DefaultBodyWrapper } from '@poly/admin-book';

import { FormPageHeaderCreator } from '../components/FormPageHeaderCreator.js';
import { EditSupplierInvoiceForm } from '../modules/accounting/enterSupplierInvoice/enterSupplierInvoiceForm/EditSupplierInvoiceForm.js';
import { editSupplierInvoiceFormId } from '../modules/accounting/constants.js';

export function EditSupplierInvoicePage() {
  return (
    <>
      <FormPageHeaderCreator
        title="Edit Supplier Invoice"
        formId={editSupplierInvoiceFormId}
      />
      <DefaultBodyWrapper>
        <EditSupplierInvoiceForm />
      </DefaultBodyWrapper>
    </>
  );
}
