import { arrayOf, number, objectOf, shape, string } from 'prop-types';

export const taxReportProps = arrayOf(
  shape({
    number: number.isRequired,
    project: shape({
      projectId: string.isRequired,
      property: shape({
        address: shape({
          address_parts: objectOf(string).isRequired,
        }).isRequired,
      }),
    }).isRequired,
    client: shape({
      nickName: string,
    }),
    taxAmount: number.isRequired,
    amount: number,
    markupAmount: number,
    lines: arrayOf(shape({ total: number })),
  }),
).isRequired;
