import { TableCard, TableWithPaginationContainer } from '@poly/admin-book';
import { node } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { TablePagination } from './Pagination.js';

const TablePaginationS = styled(TablePagination)`
  padding: 0;
`;

export function TableCardWithPaginationContainer({ children }) {
  return (
    <TableCard>
      <TableWithPaginationContainer paginationVisible>
        {children}
      </TableWithPaginationContainer>
      <TablePaginationS />
    </TableCard>
  );
}

TableCardWithPaginationContainer.propTypes = {
  children: node.isRequired,
};
