import * as R from 'ramda';
import React from 'react';
import { func, shape, string } from 'prop-types';

import { PropertySelect } from '../../../../../modules/selects/PropertySelect/PropertySelect.js';
import { getQueryForSelect } from '../utils/sections.js';

export function PropertySelector({ formData, value, onChange, ...props }) {
  const query = getQueryForSelect(formData, true);
  const disabled = R.propSatisfies(
    R.anyPass([R.isNil, R.isEmpty]),
    'clientId',
    formData,
  );

  return (
    <PropertySelect
      query={query}
      disabled={disabled}
      onChange={onChange}
      value={value}
      filterOption={() => true}
      {...props}
    />
  );
}

PropertySelector.propTypes = {
  formData: shape({
    clientId: string,
  }),
  onChange: func.isRequired,
  value: string.isRequired,
};
