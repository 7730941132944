import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { string, bool } from 'prop-types';
import {
  getThemeColor,
  Table,
  Text,
  moneyColumnStyles,
} from '@poly/admin-book';
import { useMapConfigToTableProps } from '@poly/admin-ui';
import { Link } from '@poly/client-routing';
import { assocBy } from '@poly/utils';

import {
  generalLedgerFiltersPropTypes,
  generalLedgerReportPropType,
} from './generalLedgerPropTypes.js';
import {
  generateAccountTransactionsUrl,
  formatAccountBalance,
} from './generalLedgerHelpers.js';

function LinkC({ url, children, className }) {
  return (
    <Link href={url} className={className} target="_blank">
      {children}
    </Link>
  );
}

LinkC.propTypes = {
  url: string.isRequired,
  children: string,
  className: string,
};

const GeneralLedgerCellS = styled(Text)`
  ${({ leftPadding, sub_account }) =>
    leftPadding && sub_account && 'padding-left: 20px'};
  ${({ sub_account }) => !sub_account && 'font-weight: bold'};
`;

const GeneralLedgerCellLinkS = GeneralLedgerCellS.withComponent(LinkC);

function GeneralLedgerCell({ sub_account, leftPadding, text, url }) {
  const Comp = url ? GeneralLedgerCellLinkS : GeneralLedgerCellS;
  return (
    <Comp leftPadding={leftPadding} sub_account={sub_account} url={url}>
      {text}
    </Comp>
  );
}

GeneralLedgerCell.propTypes = {
  sub_account: bool,
  leftPadding: bool,
  text: string.isRequired,
  url: string,
};

const generalLedgerTableConfig = [
  [
    'Code',
    ({ account: { code, sub_account }, url }) => (
      <GeneralLedgerCell
        text={code}
        url={url}
        sub_account={sub_account}
        leftPadding
      />
    ),
  ],
  [
    'Account',
    ({ account: { name, sub_account } }) => (
      <GeneralLedgerCell text={name} sub_account={sub_account} leftPadding />
    ),
  ],
  [
    'Forward Balance',
    ({ account: { sub_account }, forward_balance }) => (
      <GeneralLedgerCell
        text={formatAccountBalance(forward_balance)}
        sub_account={sub_account}
      />
    ),
  ],
  [
    'Debit',
    ({ account: { sub_account }, url, debits }) => (
      <GeneralLedgerCell
        text={formatAccountBalance(debits)}
        sub_account={sub_account}
        url={url}
      />
    ),
  ],
  [
    'Credit',
    ({ account: { sub_account }, url, credits }) => (
      <GeneralLedgerCell
        text={formatAccountBalance(credits)}
        sub_account={sub_account}
        url={url}
      />
    ),
  ],
  [
    'Ending Balance',
    ({ account: { sub_account }, ending_balance }) => (
      <GeneralLedgerCell
        text={formatAccountBalance(ending_balance)}
        sub_account={sub_account}
      />
    ),
  ],
];

const GeneralLedgerFooterContainer = styled.tr`
  padding-top: 20px;
  border-top: 1px solid ${getThemeColor(['midLight'])};
  font-weight: bold;
  font-size: 15px;
  display: table;
  table-layout: fixed;
  margin: 0;
  td:first-child {
    padding-left: 10px;
  }
  td {
    padding-left: 5px;
  }
  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
`;

function GeneralLedgerTotalFooter({
  reportData: { debits_total, credits_total },
}) {
  return (
    <GeneralLedgerFooterContainer>
      <td>Total</td>
      <td />
      <td />
      <td>{formatAccountBalance(debits_total)}</td>
      <td>{formatAccountBalance(credits_total)}</td>
      <td />
    </GeneralLedgerFooterContainer>
  );
}

GeneralLedgerTotalFooter.propTypes = {
  reportData: generalLedgerReportPropType,
};

const GeneralLedgerTableS = styled(Table)`
  td:nth-child(1),
  th:nth-child(1) {
    width: 100px;
  }

  ${moneyColumnStyles(3)};
  ${moneyColumnStyles(4)};
  ${moneyColumnStyles(5)};
  ${moneyColumnStyles(6)};

  td:nth-child(3),
  th:nth-child(3),
  td:nth-child(4),
  th:nth-child(4),
  td:nth-child(5),
  th:nth-child(5),
  td:nth-child(6),
  th:nth-child(6) {
    width: 200px;
  }

  tr {
    width: 100%;
    margin: 0;
  }

  td {
    height: auto;
  }
  @media print {
    tr {
      border: none;

      &:first-child {
        border: none;
      }

      &:last-child {
        border: none;
      }
    }
  }
`;

// transformGeneralLedgerReportsToTable :: GeneralLedgerFilters ->
//   [GeneralLedgerReportEntry] -> [GeneralLedgerReportEntry]
const transformAccountsReportsToTable = (filters) =>
  R.map(
    R.compose(
      assocBy('_id', R.path(['account', 'code'])),
      assocBy('url', generateAccountTransactionsUrl(filters)),
    ),
  );

export function GeneralLedgerTable({ reportData, generalLedgerFilters }) {
  const tableProps = useMapConfigToTableProps(
    transformAccountsReportsToTable(generalLedgerFilters),
    generalLedgerTableConfig,
    reportData.entries,
  );

  return (
    <GeneralLedgerTableS
      {...tableProps}
      TableFooter={GeneralLedgerTotalFooter}
      footerProps={{ reportData }}
      footerHeight={45}
      moreScrollIconPosition="40px"
      showScrollBar
      overflow="auto"
    />
  );
}

GeneralLedgerTable.propTypes = {
  reportData: generalLedgerReportPropType,
  generalLedgerFilters: generalLedgerFiltersPropTypes,
};
