import React from 'react';
import {
  DefaultBodyWrapper,
  MainHeader,
  PageHeaderContainer,
} from '@poly/admin-book';
import { useRedirectIfHasNoPermissions } from '@poly/client-utils';
import { READ_CLIENT_BATCH_PERMISSION } from '@poly/security';

import { UserHeavyJobsTable } from './HeavyJobsTable.js';
import { useQueuedHeavyJobs } from './useHeavyJobs.js';

export function BatchGenerationQueuePage() {
  const heavyJobs = useQueuedHeavyJobs();

  useRedirectIfHasNoPermissions([READ_CLIENT_BATCH_PERMISSION]);

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Batch Invoice Generation Queue</MainHeader>
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <UserHeavyJobsTable heavyJobs={heavyJobs} />
      </DefaultBodyWrapper>
    </>
  );
}
