import styled from 'styled-components';
import React, { useEffect } from 'react';
import { shape, string } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  TableCardWithPaginationContainer,
  setSearchText,
} from '@poly/admin-ui';
import { DefaultBodyWrapper } from '@poly/admin-book';

import { ProjectsDirectoryToolbar } from './ProjectsDirectoryToolbar.js';
import { ProjectSearchTable } from '../../modules/tables/projectSearchTable/ProjectSearchTable.js';
import { generateSearchQueryByConfig } from '../../modules/tables/projectSearchTable/projectSearchTableUtils.js';
import { checkRecurringSearch } from './useProjectsToolbarLogic.js';

const DefaultBodyWrapperS = styled(DefaultBodyWrapper)`
  height: calc(100% - 106px);

  > div {
    position: relative;
  }
`;

const ProjectsDirectoryToolbarS = styled(ProjectsDirectoryToolbar)`
  position: absolute;
  right: -20px;
  top: -42px;
  z-index: 1;
`;

export function SearchProjectsTableWrapper({ filter, ...props }) {
  const dispatch = useDispatch();
  const searchTerm = useSelector((state) => state.searchText);
  const isRecurringSearch = checkRecurringSearch(filter);
  const query = generateSearchQueryByConfig(filter);

  useEffect(() => {
    if (filter.keywords) {
      dispatch(setSearchText(filter.keywords));
      document.querySelector('input[name=table-search-input]').value =
        filter.keywords;
    }
  }, [filter]);

  return (
    <DefaultBodyWrapperS>
      <TableCardWithPaginationContainer>
        <ProjectsDirectoryToolbarS isFilter={!!filter} {...props} />
        <ProjectSearchTable {...{ query, searchTerm, isRecurringSearch }} />
      </TableCardWithPaginationContainer>
    </DefaultBodyWrapperS>
  );
}

SearchProjectsTableWrapper.propTypes = {
  filter: shape({ keywords: string }).isRequired,
};
