import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { createActions, handleActions } from 'redux-actions';

export const { openConfirmModal, closeConfirmModal } = createActions(
  'OPEN_CONFIRM_MODAL',
  'CLOSE_CONFIRM_MODAL',
);

const defaultState = { payload: {}, isOpen: false };

export const useConfirmModal = () => {
  const dispatch = useDispatch();
  const openModal = (payload) => dispatch(openConfirmModal(payload));
  const closeModal = (payload) => dispatch(closeConfirmModal(payload));

  return { openModal, closeModal };
};

export const confirmModalReducer = handleActions(
  {
    [openConfirmModal]: (_, { payload }) => ({ ...payload, isOpen: true }),
    [closeConfirmModal]: R.always(defaultState),
  },
  defaultState,
);
