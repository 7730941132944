import {
  startOfToday,
  startOfMonth,
  startOfYear,
  endOfToday,
  endOfMonth,
  subMonths,
} from 'date-fns';

export const DatePickerButtons = {
  TODAY: 'Today',
  THIS_MONTH: 'This Month',
  LAST_MONTH: 'Last Month',
  THIS_YEAR: 'This Year',
};

const lastMonth = subMonths(startOfToday(), 1);

export const DateRangeToButton = {
  [DatePickerButtons.TODAY]: { from: startOfToday(), to: endOfToday() },
  [DatePickerButtons.THIS_MONTH]: {
    from: startOfMonth(startOfToday()),
    to: startOfToday(),
  },
  [DatePickerButtons.LAST_MONTH]: {
    from: startOfMonth(lastMonth),
    to: endOfMonth(lastMonth),
  },
  [DatePickerButtons.THIS_YEAR]: {
    from: startOfYear(startOfToday()),
    to: startOfToday(),
  },
};

export const Months = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
};
