import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { formatDate } from '@poly/utils';
import { arrayOf } from 'prop-types';
import { Table } from '@poly/admin-book';
import { NOTHING_UI_STRING, PRINT_PDF_CAPTION } from '@poly/constants';
import { CommonPrintLayout, useMapConfigToTableProps } from '@poly/admin-ui';

import {
  applyPathOrNothingUI,
  openPrintWindowWithData,
} from '@poly/client-utils';

import { propOrNothingUI } from '../../../modules/core/utils/general.js';
import { preparedOSIProject } from '../propTypes.js';
import { OSIExportPDFButton } from './OSIExportTextButton.js';
import { prepareLastContactDateToDisplay } from '../OSIPageTable.js';

const TableS = styled(Table)`
  font-size: 14px;

  td:nth-child(1),
  th:nth-child(1) {
    width: 110px;
  }

  td:nth-child(2),
  th:nth-child(2) {
    width: 90px;
  }

  td:nth-child(3),
  th:nth-child(3) {
    width: 180px;
  }

  td:nth-child(5),
  th:nth-child(5) {
    width: 150px;
  }

  td:nth-child(6),
  th:nth-child(6) {
    width: 130px;
  }

  td:nth-child(7),
  th:nth-child(7) {
    width: 125px;
  }

  td:nth-child(8),
  th:nth-child(8) {
    width: 130px;
    text-align: center;
  }
`;

const commonTableConfig = [
  ['Project #', propOrNothingUI('projectId')],
  ['Completed', applyPathOrNothingUI(['workCompletionDate'], formatDate)],
  ['Supplier', propOrNothingUI('supplierName')],
  ['Description', propOrNothingUI('description')],
  ['Client', propOrNothingUI('clientName')],
  ['Assigned CSR', propOrNothingUI('managerName')],
  [
    'Last Contact Date',
    R.ifElse(
      R.propSatisfies(R.isNil, 'reminder'),
      R.always(NOTHING_UI_STRING),
      prepareLastContactDateToDisplay,
    ),
  ],
  ['Invoice Status', R.prop('invoiceStatus')],
];

function ExportTable({ data }) {
  const { rows, headers, columns } = useMapConfigToTableProps(
    R.identity,
    commonTableConfig,
    data,
  );

  return <TableS rows={rows} headers={headers} columns={columns} isPrintPDF />;
}

ExportTable.propTypes = {
  data: arrayOf(preparedOSIProject),
};

export function OSIExportToPDFButton({ projects }) {
  const onClick = () =>
    openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: ExportTable,
      data: projects,
      metaData: { title: 'Open Supplier Invoices' },
      fileName: 'open_supplier_invoices',
    });

  return (
    <OSIExportPDFButton onClick={onClick} disabled={R.isEmpty(projects)}>
      {PRINT_PDF_CAPTION}
    </OSIExportPDFButton>
  );
}

OSIExportToPDFButton.propTypes = {
  projects: arrayOf(preparedOSIProject),
};
