import React from 'react';

import { recentProjectsPageTabs } from '../routes/constants.js';
import { RecentProjectsPageHeader } from '../modules/pagesHeaders/RecentProjectsPageHeader/RecentProjectsPageHeader.js';
import { RecentProjectsTab } from '../modules/tabs/RecentProjectsTab.js';
import { PageTabs } from '../modules/tabs/TabsWithRouter.js';

const { recentProjectsActive, recentProjectsCompleted } =
  recentProjectsPageTabs;

export function RecentProjectReportPage() {
  return (
    <>
      <RecentProjectsPageHeader />
      <PageTabs
        generalStyles="height: calc(100% - 120px);"
        tabs={[
          [
            'Completed',
            recentProjectsCompleted,
            <RecentProjectsTab
              key={recentProjectsCompleted}
              tabName={recentProjectsCompleted}
            />,
          ],
          [
            'Active',
            recentProjectsActive,
            <RecentProjectsTab
              key={recentProjectsActive}
              tabName={recentProjectsActive}
            />,
          ],
        ]}
        defaultValue={recentProjectsCompleted}
      />
    </>
  );
}
