import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { arrayOf, shape, number, string } from 'prop-types';
import { AdminSettingsTable } from '@poly/admin-ui';
import { DefaultBodyWrapper, getThemeColor } from '@poly/admin-book';

const DefaultBodyWrapperS = styled(DefaultBodyWrapper)`
  overflow: auto;
  justify-content: space-between;
  align-self: center;
  max-width: 1424px;
  width: calc(100% - 50px);
  margin-left: 50px;
  background-color: ${getThemeColor(['white'])};
`;

const EntitiesSection = styled.div`
  display: flex;
  flex-direction: column;
  width: ${R.propOr('30%', 'width')};
`;

// groupSectionsByColumn :: [{column: Number}] -> [[{column: Number}]]
const groupSectionsByColumn = R.pipe(R.groupBy(R.prop('column')), R.values);

export function AdminSettingsGrid({ items, columnWidth, className }) {
  const groupedSections = groupSectionsByColumn(items);
  return (
    <DefaultBodyWrapperS className={className}>
      {groupedSections.map((groupItems) => (
        <EntitiesSection key={groupItems[0].column} width={columnWidth}>
          <AdminSettingsTable {...{ items: groupItems }} width="100%" />
        </EntitiesSection>
      ))}
    </DefaultBodyWrapperS>
  );
}

AdminSettingsGrid.propTypes = {
  items: arrayOf(
    shape({
      column: number.isRequired,
      title: string.isRequired,
      links: arrayOf(
        shape({
          name: string.isRequired,
          link: string,
          permission: arrayOf(string),
        }),
      ).isRequired,
    }),
  ).isRequired,
  columnWidth: string,
  className: string,
};
