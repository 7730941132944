import React from 'react';
import styled from 'styled-components';
import { func, number, string } from 'prop-types';

import { DeleteLineIcon } from '../../../../components/DeleteLineIcon.js';
import { QuestionFormField } from './components/QuestionFormField.js';
import { QuestionTypeFormField } from './components/QuestionTypeFormField.js';

const ClientRequesterQuestionLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 10px 0;
`;

const DeleteLineIconS = styled(DeleteLineIcon)`
  position: relative;
  margin-top: 16px;
`;

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 13px);
`;

export function ClientRequesterQuestionLine({ name, index, remove }) {
  const displayDeleteIcon = index > 0;

  return (
    <ClientRequesterQuestionLineWrapper>
      <FieldsWrapper>
        <QuestionFormField index={index} name={name} />
        <QuestionTypeFormField name={name} />
      </FieldsWrapper>
      {displayDeleteIcon && (
        <DeleteLineIconS
          name="delete"
          size={13}
          onClick={() => remove(index)}
        />
      )}
    </ClientRequesterQuestionLineWrapper>
  );
}

ClientRequesterQuestionLine.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  remove: func.isRequired,
};
