import * as R from 'ramda';
import { SystemAccountTypes } from '@poly/constants';

/**
 * rejectReceivableAndPayableAccounts :: [Account] -> [Account]
 */
export const rejectReceivableAndPayableAccounts = R.reject(
  R.pathSatisfies(
    R.includes(R.__, [
      SystemAccountTypes.ACCOUNTS_PAYABLE,
      SystemAccountTypes.ACCOUNTS_RECEIVABLE,
    ]),
    ['accountType', 'system_type'],
  ),
);
