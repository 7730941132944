import * as R from 'ramda';

// getAssetReplacementCost :: Asset -> Number
export const getAssetReplacementCost = R.either(
  R.path(['modelDoc', 'replacementCost']),
  R.either(
    R.path(['manufacturerDoc', 'replacementCost']),
    R.path(['type', 'replacementCost']),
  ),
);
