import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, func, number, shape, string } from 'prop-types';
import { NOTHING_UI_STRING } from '@poly/constants';
import { WindowedTable } from '@poly/admin-book';
import {
  extractTablePropsFromConfig,
  useSelectableTableRowsProps,
} from '@poly/admin-ui';
import {
  centsToDollarsWithFormat,
  formatDate,
  applyProp,
  assocBy,
} from '@poly/utils';

import { clientInvoiceStatusOptions } from './constants.js';
import { ClientInvoiceEmailDetails } from '../BatchInvoiceReportsDetails/BatchInvoiceReportsDetailsTable.js';
import { formatDateCurried } from '../../modules/tables/timesheetsTable/utils.js';

const ClientInvoicesTableS = styled(WindowedTable)`
  height: 100%;

  tr {
    width: 100% !important;
    margin: 0;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 150px;
  }

  th:nth-child(8),
  td:nth-child(8) {
    width: 375px;
  }

  th:nth-child(9),
  td:nth-child(9) {
    width: 50px;
  }
`;

const clientInvoicesPageTableConfig = [
  ['Invoice #', R.propOr(NOTHING_UI_STRING, 'number')],
  [
    'Consolidated Invoice #',
    R.pathOr(NOTHING_UI_STRING, ['consolidatedClientInvoice', 'invoiceNumber']),
  ],
  ['Invoice Date', applyProp(formatDate)('invoiceDate')],
  ['Invoice Due Date', applyProp(formatDate)('dueDate')],
  ['Project #', R.pathOr(NOTHING_UI_STRING, ['project', 'projectId'])],
  ['Invoice Amount', applyProp(centsToDollarsWithFormat)('amount')],
  ['Status', applyProp(R.prop(R.__, clientInvoiceStatusOptions))('status')],
  ['Email Details', ClientInvoiceEmailDetails],
  [
    '',
    R.compose(
      R.unless(R.isNil, formatDateCurried('MM/dd/yyyy')),
      R.path(['clientNotified', 'date']),
    ),
  ],
];

// formatRows :: [ClientInvoice] -> [ClientInvoice]
const formatRows = R.map(
  assocBy(
    'clientNotified',
    R.either(
      R.path(['clientNotified']),
      R.path(['consolidatedClientInvoice', 'clientNotified']),
    ),
  ),
);

export function PrintClientInvoicesTable({
  selectedClientInvoices,
  setSelectedClientInvoices,
  clientInvoices,
  refetch,
  tableProps,
}) {
  const { toggleRow, toggleSelectAll } = useSelectableTableRowsProps(
    selectedClientInvoices,
    setSelectedClientInvoices,
    clientInvoices,
  );

  const { headers, columns } = extractTablePropsFromConfig(
    clientInvoicesPageTableConfig,
  );

  const rows = R.map(R.mergeRight({ refetch }), formatRows(clientInvoices));

  return (
    <ClientInvoicesTableS
      {...tableProps}
      rows={rows}
      headers={headers}
      columns={columns}
      selectedRows={selectedClientInvoices}
      isRowSelectable={R.T}
      toggleSelectAll={toggleSelectAll}
      toggleRow={toggleRow}
      selectedRowsInEnd
      moreScrollPosition="0px"
      itemSize={70}
    />
  );
}

PrintClientInvoicesTable.propTypes = {
  selectedClientInvoices: arrayOf(string),
  setSelectedClientInvoices: func.isRequired,
  refetch: func.isRequired,
  clientInvoices: arrayOf(
    shape({
      _id: string.isRequired,
      number: number.isRequired,
    }),
  ),
  tableProps: shape({
    loadMoreItems: func.isRequired,
    itemCount: number.isRequired,
    loadedCount: number.isRequired,
  }).isRequired,
};
