import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, func, number, object, shape, string } from 'prop-types';
import { FieldLayout, TextButton, Text, Input } from '@poly/admin-book';
import { MoneyInput } from '@poly/admin-ui';
import { formatTotal, propEqLegacy } from '@poly/utils';
import { CLIENT_MARKUP_PERCENT_MAX_VALUE } from '@poly/constants';

import {
  Line,
  LinesWrapper,
} from '../../../modules/accounting/enterSupplierInvoice/enterSupplierInvoiceForm/styled.js';
import { DeleteLineIcon } from '../../../components/DeleteLineIcon.js';

const BottomLine = styled(Line)`
  justify-content: flex-end;
`;

const Note = styled(Text)`
  font-size: 12px;
  line-height: 18px;
  margin-left: 34%;
  width: calc(33% - 20px);
`;

// getPreviousAmount :: LineComponentProps -> Number
// LineComponentProps = {
//   index: Number
//   lines: [ClientMarkupRule]
// }
const getPreviousAmount = R.ifElse(
  propEqLegacy('index', 0),
  R.always(0),
  R.compose(
    R.defaultTo(0),
    R.prop('upTo'),
    R.converge(R.prop, [
      R.pipe(R.prop('index'), R.subtract(R.__, 1)),
      R.prop('value'),
    ]),
  ),
);

// getFromAmountValue :: Number -> String
const getFromAmountValue = R.compose(formatTotal, R.when(R.lt(0), R.add(0.01)));

function FromAmountComponent({ name, value }) {
  return (
    <Text {...{ name }} size="12px" lineHeight="30px">
      {value}
    </Text>
  );
}

FromAmountComponent.propTypes = { name: string, value: string };

function MarkupInput(props) {
  return (
    <Input
      {...props}
      suffix="%"
      floatValue
      decimalScale={6}
      type="numberMask"
      skipFixedDecimalScale
      maxValue={CLIENT_MARKUP_PERCENT_MAX_VALUE}
    />
  );
}

export function ClientMarkupLineComponent({
  name,
  index,
  fields: { value, remove, push },
}) {
  const isFirstLine = index === 0;
  const isLastLine = index === value.length - 1;
  const previousAmount = getPreviousAmount({ index, value });
  const fromValue = getFromAmountValue(previousAmount);

  return (
    <LinesWrapper>
      <Line>
        <FieldLayout
          {...(isFirstLine && { label: 'From' })}
          layout={{ width: 'calc(33% - 20px)' }}
          field={{
            name: `${name}.from`,
            Component: FromAmountComponent,
            additionalProps: { value: fromValue },
          }}
          add
        />
        <FieldLayout
          {...(isFirstLine && { label: 'To' })}
          layout={{ width: 'calc(33% - 20px)' }}
          field={{
            name: `${name}.upTo`,
            Component: MoneyInput,
          }}
          {...(!isLastLine && { required: true })}
          validators={[
            ...(isLastLine
              ? [[R.isNil, "Last rule shouldn't have upper bound limit amount"]]
              : [[R.identity, 'Amount is required']]),
            [
              R.either(R.isNil, R.lt(previousAmount)),
              'Amount should be bigger than previous',
            ],
          ]}
        />
        <FieldLayout
          required
          {...(isFirstLine && { label: 'Markup' })}
          layout={{ width: 'calc(33% - 20px)' }}
          field={{
            name: `${name}.percent`,
            Component: MarkupInput,
          }}
          validators={[
            [R.complement(R.isNil), 'Markup is required'],
            [
              R.gte(CLIENT_MARKUP_PERCENT_MAX_VALUE),
              `You can't set more than ${CLIENT_MARKUP_PERCENT_MAX_VALUE}`,
            ],
          ]}
        />
        {!isFirstLine && (
          <DeleteLineIcon
            name="delete"
            size={13}
            onClick={() => remove(index)}
          />
        )}
      </Line>
      {isLastLine && <Note>Leave last field blank for unlimited amount</Note>}
      {isLastLine && (
        <BottomLine>
          <TextButton onClick={() => push({})}>Add</TextButton>
        </BottomLine>
      )}
    </LinesWrapper>
  );
}

ClientMarkupLineComponent.displayName = 'ClientMarkupLineComponent';

ClientMarkupLineComponent.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  fields: shape({
    push: func.isRequired,
    remove: func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    value: arrayOf(object).isRequired,
  }),
};
