import * as R from 'ramda';

// getAlreadySelectedOptions :: [Object] -> [String] -> [String]
const getAlreadySelectedOptions = (path) =>
  R.compose(R.reject(R.isNil), R.map(R.path(path)));

// isGLCodeOptionDisabled :: [Object] -> [String] -> [String] -> Boolean
export const isGLCodeOptionDisabled = (
  allFormGLCodeValues,
  getSelectedGLCodePath,
  getCurrentGLCodePath,
) =>
  R.compose(
    R.apply(R.includes),
    R.juxt([
      R.path(getCurrentGLCodePath),
      () =>
        getAlreadySelectedOptions(getSelectedGLCodePath)(allFormGLCodeValues),
    ]),
  );
