import React from 'react';
import { useApolloClient } from '@apollo/client';
import { AddTaskForm as CommonAddTaskForm } from '@poly/admin-ui';

import { addTaskFormId } from './constants.js';

export function AddTaskForm(props) {
  const apolloClient = useApolloClient();

  return (
    <CommonAddTaskForm
      {...props}
      formId={addTaskFormId}
      apolloClient={apolloClient}
    />
  );
}
