import React, { useState } from 'react';
import * as R from 'ramda';
import StarRatings from 'react-star-ratings';
import { arrayOf, number } from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import { mapIndexed } from '@poly/utils';

import { defaultTheme } from '../ThemeProvider/index.js';
import { PopUp, PopUpCounter, StarsNumber, Wrapper } from './styles.js';

const {
  colors: { gold, secondary },
} = defaultTheme;

// prepareStart :: [Number] -> [{value: Number, index: Number}]
const prepareStart = mapIndexed((value, index) => ({ value, index }));

export function PopUpRating({ stars = [0, 0, 0, 0, 0] }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpenRating = (value) => setIsOpen(value);

  const preparedStars = prepareStart(stars);

  return (
    <OutsideClickHandler onOutsideClick={() => toggleOpenRating(false)}>
      <StarsNumber
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggleOpenRating(!isOpen);
        }}
      >
        ({R.reduce(R.add, 0, stars)})
        {isOpen && (
          <PopUp>
            {preparedStars.map(({ value, index }) => (
              // eslint-disable-next-line
              <Wrapper key={`${number}_${index}`}>
                <StarRatings
                  numberOfStars={5}
                  starSpacing="1px"
                  starDimension="15px"
                  starRatedColor={gold}
                  rating={R.add(1, index)}
                  starEmptyColor={secondary}
                  name={`star_line_${index}`}
                />
                <PopUpCounter>({value})</PopUpCounter>
              </Wrapper>
            ))}
          </PopUp>
        )}
      </StarsNumber>
    </OutsideClickHandler>
  );
}

PopUpRating.propTypes = {
  stars: arrayOf(number),
};
PopUpRating.displayName = 'PopUpRating';
