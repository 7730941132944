import * as R from 'ramda';
import { mergeAllDeepRight, propEqLegacy } from '@poly/utils';
import React from 'react';
import { useField } from 'react-final-form';
import styled from 'styled-components';

import { fieldTypes, layoutType } from '../formTypes.js';
import { validateField } from '../formValidators.js';
import {
  isEquals,
  DEFAULT_MAX,
  sortByOrder,
  getFormData,
} from '../formUtils.js';
import {
  FieldArrayComp,
  ComponentColumn,
  Row,
} from './components/FieladArray.js';
import { FormFieldLabel } from '../FormFieldLabel.js';

const GroupsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${({ position }) => position || 'flex-start'};
  justify-content: ${({ justify }) => justify || 'space-between'};
  width: 100%;
  ${({ isWrapped }) => (isWrapped ? 'flex-wrap: wrap' : '')};
`;

// concatFieldNameAndKey :: a -> Object -> String
const concatFieldNameAndKey = (key) =>
  R.pipe(R.path(['field', 'name']), R.concat(R.toString(key)));

const rowPath = R.pathOr(DEFAULT_MAX, ['layout', 'row']);

// generateFields :: ([Field], (String, Any) -> Void) -> [ReactComponent]
// Field = { label: String, order: Number, required: Boolean, field: shape({ name: String, Component: ReactComponent }) }
const generateFields = (list, changeFieldValue, mutators, globalFieldLayout) =>
  sortByOrder(list).map((props, i) => (
    <FieldLayout
      mutators={mutators}
      key={concatFieldNameAndKey(i)(props)}
      changeFieldValue={changeFieldValue}
      globalFieldLayout={globalFieldLayout}
      {...props}
    />
  ));

// generateRows :: ([Field], String) -> [ReactComponent]
const generateRows =
  (mutators, changeFieldValue, globalFieldLayout) => (list, key) =>
    R.cond([
      [
        () => R.equals(parseInt(key, 10), DEFAULT_MAX),
        () =>
          generateFields(list, changeFieldValue, mutators, globalFieldLayout),
      ],
      [
        propEqLegacy('length', 1),
        () => (
          <FieldLayout
            mutators={mutators}
            changeFieldValue={changeFieldValue}
            key={concatFieldNameAndKey(key)(list[0])}
            globalFieldLayout={globalFieldLayout}
            {...list[0]}
          />
        ),
      ],
      [
        R.T,
        () => (
          <GroupsWrapper
            {...{ key }}
            justify={R.path([0, 'layout', 'justify'])(list)}
            position={R.path([0, 'layout', 'position'])(list)}
            isWrapped={R.path([0, 'layout', 'isWrapped'])(list)}
          >
            {generateFields(
              list,
              changeFieldValue,
              mutators,
              globalFieldLayout,
            )}
          </GroupsWrapper>
        ),
      ],
    ])(list);

// mapFieldsByRows :: [Field] -> [ReactComponent]
export const mapFieldsByRows = (
  mutators,
  changeFieldValue,
  globalFieldLayout,
) =>
  R.pipe(
    R.sortBy(rowPath),
    R.groupBy(rowPath),
    R.mapObjIndexed(
      generateRows(mutators, changeFieldValue, globalFieldLayout),
    ),
    R.values,
  );

// prepareLayout :: Object -> Object -> Object
const prepareLayout = (values) =>
  R.compose(
    R.map(R.when(R.is(Function), (func) => func(values))),
    R.defaultTo({}),
  );

function FieldComp({
  label,
  fieldLayout,
  labelLayout,
  componentLayout,
  field: {
    name,
    subFields,
    Component,
    validate,
    withFormData,
    withChangeFieldValue,
    isEqual,
    additionalProps = {},
  },
  required = false,
  disabled = false,
  values,
  mutators,
  changeFieldValue,
}) {
  const { input, meta } = useField(name, { validate, isEqual });
  const labelId = R.is(String, label) ? label?.replace(/\s+/g, '-') : name;

  return (
    <Row {...prepareLayout(values)(fieldLayout)}>
      {label && (
        <FormFieldLabel {...labelLayout} htmlFor={labelId}>
          {label}
        </FormFieldLabel>
      )}
      <ComponentColumn {...componentLayout}>
        {subFields ? (
          mapFieldsByRows(mutators, changeFieldValue)(subFields)
        ) : (
          <Component
            {...input}
            {...additionalProps}
            mutators={mutators}
            {...{
              name,
              meta,
              required,
              disabled,
              ...getFormData(withFormData, values),
              ...(withChangeFieldValue ? { changeFieldValue } : {}),
            }}
            hasError={!!meta.touched && !!meta.error}
            error={meta.touched && meta.error ? meta.error : ''}
            id={labelId}
          />
        )}
      </ComponentColumn>
    </Row>
  );
}

FieldComp.propTypes = {
  ...fieldTypes,
  fieldLayout: layoutType.isRequired,
  labelLayout: layoutType.isRequired,
  componentLayout: layoutType.isRequired,
};

export const FieldLayout = React.memo((props) => {
  const {
    label,
    field,
    layout,
    required,
    isVisible,
    validators,
    defaultLayout,
    validateFunction,
    globalFieldLayout,
  } = props;

  if (isVisible === false) return null;

  const modifiedProps = {
    ...props,
    fieldLayout: mergeAllDeepRight([defaultLayout, globalFieldLayout, layout]),
    labelLayout: R.mergeAll([
      R.propOr({}, 'label', defaultLayout),
      R.propOr({}, 'label', globalFieldLayout),
      R.propOr({}, 'label', layout),
    ]),
    componentLayout: R.mergeAll([
      R.propOr({}, 'component', defaultLayout),
      R.propOr({}, 'component', globalFieldLayout),
      R.propOr({}, 'component', layout),
    ]),
    field: {
      validate: validateFunction
        ? validateFunction(validators)
        : validateField(validators),
      isEquals,
      // field itself goes last so we can override silly defaults
      ...field,
    },
    label: label && required ? `${label} *` : label,
  };

  if (field.isArrayField) return <FieldArrayComp {...modifiedProps} />;

  return <FieldComp {...modifiedProps} />;
});

FieldLayout.displayName = 'FieldLayout';
FieldLayout.propTypes = fieldTypes;
FieldLayout.defaultProps = {
  defaultLayout: {
    width: '100%',
    component: {
      padding: '0',
    },
    padding: '0 0 8px 0',
    margin: 'initial',
  },
  layout: {},
  globalFieldLayout: {},
};
