import React from 'react';
import * as R from 'ramda';
import { arrayOf, shape, string } from 'prop-types';
import { SupplierSearchTable } from './SupplierSearchTable.js';

export function SupplierSearchTableWrapper(props) {
  return R.propSatisfies(R.isEmpty, 'query', props) ? null : (
    <SupplierSearchTable {...props} />
  );
}

SupplierSearchTableWrapper.displayName = 'SupplierSearchTableWrapper';

SupplierSearchTableWrapper.propTypes = {
  query: shape({
    bool: shape({
      must: arrayOf(
        shape({
          terms: shape({
            status: arrayOf(string),
          }),
        }),
      ),
    }),
  }),
};
