import React from 'react';
import * as R from 'ramda';
import { string, func } from 'prop-types';
import { useOutSidebarContext } from '@poly/client-utils';
import { CommonSidebarFormWidth } from '@poly/admin-ui';

import {
  ProcedureSidebarLayout,
  ProcedureSidebarFormLayout,
} from '../components.js';
import { AddEditProcedureForm } from '../AddEditProcedureForm.js';
import { isEditProcedureForm } from '../helpers.js';

function AddEditProcedureSidebarFrom({ formId, onClose, ...props }) {
  const layoutTitle = isEditProcedureForm(formId)
    ? 'Edit Procedure'
    : 'Add New Procedure';

  return (
    <ProcedureSidebarFormLayout
      formId={formId}
      onClose={onClose}
      title={layoutTitle}
      submitButtonCaption="Save"
    >
      <AddEditProcedureForm {...props} formId={formId} />
    </ProcedureSidebarFormLayout>
  );
}

AddEditProcedureSidebarFrom.propTypes = {
  onClose: func,
  formId: string.isRequired,
};

export const useOpenAddEditProcedureSidebar = (formId, onClose) => {
  const { openOutSidebar, closeOutSidebar } = useOutSidebarContext();

  const onCancel = () => {
    closeOutSidebar(formId);

    if (R.is(Function, onClose)) {
      onClose();
    }
  };

  return (procedure) =>
    openOutSidebar({
      id: formId,
      alwaysFirst: true,
      width: CommonSidebarFormWidth,
      Layout: ProcedureSidebarLayout,
      content: (
        <AddEditProcedureSidebarFrom
          formId={formId}
          onClose={onClose}
          onCancel={onCancel}
          procedure={procedure}
        />
      ),
    });
};
