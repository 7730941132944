import { gql } from '@apollo/client';
import { keywordSortQuery, useReactiveQuery } from '@poly/client-utils';
import { ASC_SORT_ORDER } from '@poly/constants';

import { useMasterSuppliersSearchInput } from './useMasterSuppliersSearchInput.js';

const SEARCH_MASTER_SUPPLIERS = gql`
  query SEARCH_MASTER_SUPPLIERS($input: CollectionSearchParams!) {
    searchMasterSuppliers(input: $input) {
      hits {
        _id
        company {
          name
          address {
            formatted_address
          }
          services {
            _id
            name
          }
        }
      }
      total
    }
  }
`;

const SEARCH_MASTER_SUPPLIER_CHANGED = gql`
  subscription SEARCH_MASTER_SUPPLIER_CHANGED($input: CollectionSearchParams!) {
    searchMasterSupplierChanged(input: $input) {
      id
      type
    }
  }
`;

export const useSearchMasterSuppliersQuery = (
  status,
  type,
  sort,
  fetchPolicy,
) => {
  const input = useMasterSuppliersSearchInput({
    query: {
      bool: {
        filter: [{ term: { status } }, ...(type ? [{ term: { type } }] : [])],
      },
    },
    sort: sort || keywordSortQuery(['company', 'name'])(ASC_SORT_ORDER),
  });

  const queryOptions = {
    variables: { input },
    ...(!!fetchPolicy && { fetchPolicy }),
  };

  const { data, loading } = useReactiveQuery(
    SEARCH_MASTER_SUPPLIERS,
    SEARCH_MASTER_SUPPLIER_CHANGED,
    { queryOptions, subscriptionOptions: queryOptions },
  );

  return { data, loading };
};
