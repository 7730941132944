import * as R from 'ramda';
import React from 'react';
import { arrayOf } from 'prop-types';
import { formatDate } from '@poly/utils';
import {
  ExcelExportCellType,
  createExcelExportCell,
  performExcelExport,
} from '@poly/client-utils';
import { EXPORT_XLS_CAPTION } from '@poly/constants';

import { OSIExportExcelButton } from './OSIExportTextButton.js';
import { preparedOSIProject } from '../propTypes.js';
import { prepareLastContactDateToDisplay } from '../OSIPageTable.js';

// getOSIReportXLSRowConfig :: Object -> [ExcelExportDataCell]
const getOSIReportXLSRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 8),
  ]),
  R.juxt([
    R.propOr('', 'projectId'),
    R.compose(
      R.ifElse(R.isNil, R.always(''), formatDate),
      R.prop('workCompletionDate'),
    ),
    R.propOr('', 'supplierName'),
    R.propOr('', 'description'),
    R.propOr('', 'clientName'),
    R.propOr('', 'managerName'),
    R.ifElse(
      R.pathSatisfies(R.isNil, ['reminder', 'nextRequestDate']),
      R.always(''),
      prepareLastContactDateToDisplay,
    ),
    R.propOr('', 'invoiceStatus'),
  ]),
);

// getOSIReportXLSExportExcelConfig :: [Project] -> Object
export const getOSIReportXLSExportExcelConfig = (projects) => ({
  exportFileName: 'open_supplier_invoices_export.xlsx',
  columnWidths: [17, 10, 15, 50, 15, 15, 15, 15],
  rows: [
    [
      createExcelExportCell(
        ExcelExportCellType.title,
        'Open Supplier Invoices',
      ),
    ],
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 8)],
      [
        'Project #',
        'Completed',
        'Supplier',
        'Description',
        'Client',
        'Assigned CSR',
        'Last Contact Date',
        'Invoice Status',
      ],
    ),
    ...R.map(getOSIReportXLSRowConfig, projects),
  ],
});

export function OSIExportToExcelButton({ projects }) {
  const handleClick = () => {
    const excelConfig = getOSIReportXLSExportExcelConfig(projects);
    performExcelExport(excelConfig);
  };

  return (
    <OSIExportExcelButton onClick={handleClick} disabled={R.isEmpty(projects)}>
      {EXPORT_XLS_CAPTION}
    </OSIExportExcelButton>
  );
}

OSIExportToExcelButton.propTypes = {
  projects: arrayOf(preparedOSIProject),
};
