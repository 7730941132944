import React, { useCallback } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { gql, useMutation, useQuery } from '@apollo/client';
import { getThemeColor, TextButton, FormCreator } from '@poly/admin-book';
import { insertParamsIntoURL, forceTitleCase, debounce } from '@poly/utils';
import {
  useUpdateQueryParams,
  useRouterParams,
  useNavigate,
} from '@poly/client-routing';
import { string } from 'prop-types';
import { MasterSupplierSelect } from '../masterSupplier/MasterSupplierSelect.js';
import { commonModalLayout } from '../forms/common.js';
import { useModalContext } from '../../components/Modal/ModalProvider.js';
import { useNotificationState } from '../../hooks/useNotificationState.js';

const ConvertSupplierWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ConvertSupplierLabel = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: ${getThemeColor(['primaryLighter2'])};
  padding-right: 5px;
`;

const ConvertSupplierText = styled(ConvertSupplierLabel)`
  color: ${getThemeColor(['black'])};
`;

const TextButtonS = styled(TextButton)`
  font-size: 14px;
  margin: 0 5px;
`;

const getSupplierTypeQuery = gql`
  query getSupplierTypeQuery($id: ID!) {
    supplier(id: $id) {
      _id
      type
    }
  }
`;

const convertToMasterSupplierMutation = gql`
  mutation convertToMasterSupplierMutation($id: ID!) {
    convertToMasterSupplier(id: $id) {
      _id
    }
  }
`;

const convertToBranchSupplierMutation = gql`
  mutation convertToBranchSupplierMutation($id: ID!, $masterSupplierId: ID!) {
    convertToBranchSupplier(id: $id, masterSupplierId: $masterSupplierId) {
      _id
    }
  }
`;

function ConvertToMasterBtn({ formId, masterSupplierUrl }) {
  const navigate = useNavigate();
  const { openConfirmSubmitFormModal } = useModalContext();
  const { showSuccessNotification } = useNotificationState();
  const { supplierId } = useRouterParams(['supplierId']);
  const [convertToMasterSupplier] = useMutation(
    convertToMasterSupplierMutation,
  );

  const navigateToMasterSupplierCard = useCallback(
    debounce(10)((url) => {
      navigate(url);
    }),
    [],
  );

  const onClick = () =>
    openConfirmSubmitFormModal({
      content: (
        <div>
          Are you sure you want to convert this supplier to Master? All
          relations will be attached to the branch.
        </div>
      ),
      btnCaption: 'Convert to Master',
      processId: formId,
      onConfirm: (closeConfirmModal) => async () => {
        const result = await convertToMasterSupplier({
          variables: { id: supplierId },
        });
        showSuccessNotification('Supplier successfully converted');
        const masterSupplierId = R.path(
          ['data', 'convertToMasterSupplier', '_id'],
          result,
        );
        const link = insertParamsIntoURL({ supplierId: masterSupplierId })(
          masterSupplierUrl,
        );
        closeConfirmModal();
        navigateToMasterSupplierCard(link);
      },
    });

  return <TextButtonS onClick={onClick}>Master</TextButtonS>;
}

ConvertToMasterBtn.propTypes = {
  formId: string.isRequired,
  masterSupplierUrl: string.isRequired,
};

function ConvertToBranchBtn({ formId }) {
  const updateQueryParams = useUpdateQueryParams();
  const { openModalForm, closeModal } = useModalContext();
  const { showSuccessNotification } = useNotificationState();
  const { supplierId } = useRouterParams(['supplierId']);
  const { data } = useQuery(getSupplierTypeQuery, {
    variables: { id: supplierId },
    fetchPolicy: 'network-only',
  });
  const [convertToBranchSupplier] = useMutation(
    convertToBranchSupplierMutation,
  );

  const supplierType = R.compose(
    forceTitleCase,
    R.path(['supplier', 'type']),
  )(data);

  const masterSuppliersQuery = supplierType
    ? { term: { type: supplierType } }
    : null;

  const onSubmit = async (variables) => {
    await convertToBranchSupplier({ variables });
    showSuccessNotification('Supplier successfully converted');
    updateQueryParams({ isEdit: false });
    closeModal(formId);
  };

  const onClick = () =>
    openModalForm({
      formId,
      id: formId,
      title: 'Convert Supplier to Branch',
      btnCaption: 'Convert to Branch',
      content: (
        <FormCreator
          validateOnBlur
          key={supplierId}
          onSubmit={onSubmit}
          id={formId}
          layout={commonModalLayout}
          initialValues={{ id: supplierId }}
          sections={[
            {
              order: 1,
              layout: { column: 1 },
              fields: [
                {
                  order: 1,
                  label: 'Master Supplier',
                  layout: { row: 1, width: '60%', padding: 0 },
                  field: {
                    name: 'masterSupplierId',
                    Component: MasterSupplierSelect,
                    additionalProps: { query: masterSuppliersQuery },
                  },
                  validators: [[R.identity, 'Master Supplier is required']],
                  required: true,
                },
              ],
            },
          ]}
        />
      ),
    });

  return <TextButtonS onClick={onClick}>Branch</TextButtonS>;
}

ConvertToBranchBtn.propTypes = {
  formId: string.isRequired,
};

export function ConvertSupplierComponent({ formId, masterSupplierUrl }) {
  return (
    <ConvertSupplierWrapper>
      <ConvertSupplierLabel>Convert to:</ConvertSupplierLabel>
      <ConvertSupplierText>
        <ConvertToMasterBtn
          formId={formId}
          masterSupplierUrl={masterSupplierUrl}
        />
        or
        <ConvertToBranchBtn formId={formId} />
      </ConvertSupplierText>
    </ConvertSupplierWrapper>
  );
}

ConvertSupplierComponent.propTypes = {
  formId: string.isRequired,
  masterSupplierUrl: string.isRequired,
};
