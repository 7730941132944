import React from 'react';
import * as R from 'ramda';
import { DatePicker, Select, Input } from '@poly/admin-book';
import { UserEmployeeInfoStatus } from '@poly/constants';
import {
  formatSelectOptionsByConstantsValue,
  formatSelectOptionsByConstants,
  validatePhone,
  ifNotEmpty,
} from '@poly/client-utils';

import { halfWidth } from '../../../forms/common.js';
import { rateTypesUI } from '../../../../constants/users.js';
import { ExtInput } from '../../../../components/ExtInput.js';
import { MoneyInput } from '../../../forms/fields/MoneyInput.js';
import { PhoneNumberInput } from '../../../forms/fields/index.js';

const disableDays = (path, modifierPath) =>
  R.when(
    R.path(path),
    R.compose(
      R.assoc(modifierPath, R.__, {}),
      R.constructN(1, Date),
      R.path(path),
    ),
  );

function TerminationDatePicker(props) {
  const disabledDays = disableDays(
    ['formData', 'employeeInfo', 'terminationDate'],
    'after',
  )(props);

  return <DatePicker {...props} disabledDays={disabledDays} leftMove="0" />;
}

function StartDatePicker(props) {
  const disabledDays = disableDays(
    ['formData', 'employeeInfo', 'startDate'],
    'before',
  )(props);

  return <DatePicker {...props} disabledDays={disabledDays} />;
}

function StatusDropDown(props) {
  const options = formatSelectOptionsByConstantsValue(UserEmployeeInfoStatus);

  return <Select {...props} options={options} />;
}

function RateDropDown(props) {
  const options = formatSelectOptionsByConstants(rateTypesUI);

  return <Select {...props} placeholder="Enter rate" options={options} />;
}

function RateMoneyInput(props) {
  return <MoneyInput placeholder="Enter rate" {...props} />;
}

function LocationInput(props) {
  return <Input placeholder="Enter Location" {...props} />;
}

export const getEmployeeSection = (column, isContractor) => [
  {
    label: `${isContractor ? 'Contractor' : 'Employment'} Information`,
    order: 3,
    layout: { column },
    fields: [
      {
        label: 'Title',
        order: 1,
        field: {
          name: 'employeeInfo.title',
          Component: Input,
        },
        layout: {
          row: 1,
          width: halfWidth,
        },
      },
      {
        label: 'Status',
        order: 2,
        field: {
          name: 'employeeInfo.status',
          Component: StatusDropDown,
        },
        layout: {
          row: 1,
          width: halfWidth,
        },
      },
      {
        label: 'Hire Date',
        order: 3,
        field: {
          name: 'employeeInfo.startDate',
          withFormData: true,
          Component: TerminationDatePicker,
        },
        layout: {
          row: 2,
          width: halfWidth,
        },
      },
      {
        label: 'Termination',
        order: 4,
        field: {
          name: 'employeeInfo.terminationDate',
          withFormData: true,
          Component: StartDatePicker,
        },
        layout: {
          row: 2,
          width: halfWidth,
        },
      },

      {
        label: 'Rate Type',
        order: 5,
        field: {
          name: 'employeeInfo.payRateType',
          Component: RateDropDown,
        },
        layout: {
          row: 3,
          width: halfWidth,
        },
      },
      {
        label: 'Rate',
        order: 6,
        field: {
          name: 'employeeInfo.payRate',
          Component: RateMoneyInput,
        },
        layout: {
          row: 3,
          width: halfWidth,
        },
      },
      {
        label: 'Phone',
        order: 7,
        field: {
          name: 'company.phones.work.number',
          Component: PhoneNumberInput,
        },
        layout: {
          row: 4,
          width: halfWidth,
        },
        validators: [[ifNotEmpty(validatePhone), 'Incorrect phone']],
      },
      {
        label: 'Ext',
        order: 8,
        field: {
          name: 'company.phones.work.ext',
          Component: ExtInput,
        },
        layout: {
          row: 4,
          width: halfWidth,
        },
      },
      {
        label: 'Mobile',
        order: 9,
        field: {
          name: 'company.phones.cell.number',
          Component: PhoneNumberInput,
        },
        layout: {
          row: 5,
          width: halfWidth,
        },
        validators: [[ifNotEmpty(validatePhone), 'Incorrect phone']],
      },
      {
        label: 'Location',
        order: 10,
        field: {
          name: 'company.companyLocation',
          Component: LocationInput,
        },
        layout: {
          row: 5,
          width: halfWidth,
        },
      },
    ],
  },
];
