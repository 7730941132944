import { useSelector, useDispatch } from 'react-redux';
import { createAction, handleAction } from 'redux-actions';

export const setSearchText = createAction('SET_SEARCH_TABLE');

export const searchTextReducer = handleAction(
  setSearchText,
  (_, { payload }) => payload,
  '',
);

export const useTableSearch = () => {
  const dispatch = useDispatch();
  const searchText = useSelector((state) => state.searchText);

  return {
    searchText,
    setSearchText: (payload) => dispatch(setSearchText(payload)),
  };
};
