import {
  shape,
  node,
  arrayOf,
  oneOfType,
  bool,
  string,
  number,
  object,
  func,
} from 'prop-types';
import * as R from 'ramda';

export const tableHeadersPropTypes = arrayOf(
  shape({ title: node.isRequired, hasSortingValue: bool }),
);

export const defaultTableRowPropTypes = {
  rows: [],
  selectedRows: undefined,
  toggleRow: R.T,
  isRowSelectable: R.T,
  isRowDisabled: R.F,
  selectedRowsInEnd: false,
  omitColumn: undefined,
  itemSize: 45, // as pixels
  RowComponent: null,
  enableHoverEffect: true,
};

const componentPropType = oneOfType([func, object]);

export const tableRowPropTypes = {
  columns: arrayOf(componentPropType.isRequired).isRequired,
  rows: arrayOf(
    shape({
      _id: string.isRequired,
    }).isRequired,
  ),
  selectedRows: arrayOf(oneOfType([string, number])),
  toggleRow: func,
  headers: tableHeadersPropTypes.isRequired,
  isRowSelectable: func,
  selectedRowsInEnd: bool,
  isRowDisabled: func,
  omitColumn: number,
  itemSize: number,
  NotSelectableCheckboxComponent: func,
  RowComponent: componentPropType,
  enableHoverEffect: bool,
};
