import * as R from 'ramda';

// getPropertyStatusTitle :: Property -> String
export const getPropertyStatusTitle = R.compose(
  R.join(''),
  R.reject(R.isEmpty),
  R.juxt([
    R.always('Customer / '),
    R.ifElse(R.prop('isMaster'), R.always('Master '), R.always(null)),
    R.ifElse(
      R.path(['masterProperty', '_id']),
      R.always('Child '),
      R.always(null),
    ),
    R.always('Property'),
  ]),
);
