import React from 'react';
import { useRedirectIfHasNoPermissions } from '@poly/client-utils';
import { CREATE_CLIENT_BATCH_PERMISSION } from '@poly/security';

import { BatchInvoicingTMPage } from './BatchInvoicingTMPage.js';
import {
  bfsActiveBatchInvoicingQuery,
  bfsDormantBatchInvoicingQuery,
} from './client-filters-presets.js';

export function BfsDormantBatchInvoicingPage() {
  useRedirectIfHasNoPermissions([CREATE_CLIENT_BATCH_PERMISSION]);

  return (
    <BatchInvoicingTMPage
      title="BFS Dormant Batch Invoice"
      query={bfsDormantBatchInvoicingQuery}
    />
  );
}

export function BfsActiveBatchInvoicingPage() {
  useRedirectIfHasNoPermissions([CREATE_CLIENT_BATCH_PERMISSION]);

  return (
    <BatchInvoicingTMPage
      title="BFS Active Batch Invoice"
      query={bfsActiveBatchInvoicingQuery}
    />
  );
}
