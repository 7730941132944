import styled from 'styled-components';
import { checkBoxColumn, dateColumnStyles, Table } from '@poly/admin-book';

export const TableWrapper = styled.div`
  height: 100%;
  position: relative;
`;

export const TasksTableComp = styled(Table)`
  ${checkBoxColumn(1)};

  th:nth-child(3),
  td:nth-child(3) {
    width: 15%;
  }

  ${dateColumnStyles(4)};

  th:nth-child(5),
  td:nth-child(5) {
    width: 20%;
    padding-right: 20px;

    > div {
      justify-content: flex-end;
    }
  }
`;
