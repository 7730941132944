import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';

export const taskTemplateQuery = gql`
  query taskTemplateQuery($id: ID!) {
    taskTemplate(id: $id) {
      _id
      name
      description
      priority
    }
  }
`;

// getTaskTemplateFormInitialValues :: TaskTemplateQueryResult -> TaskTemplate
export const getTaskTemplateFormInitialValues = R.compose(
  R.pick(['name', 'description', 'priority']),
  R.propOr({}, 'taskTemplate'),
);

export const useTaskTemplateDetails = (id) => {
  const { data, loading } = useQuery(taskTemplateQuery, {
    variables: { id },
    skip: !id,
  });

  return { data, loading };
};
