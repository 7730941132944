import React from 'react';
import { defaultTheme, getThemeColor, Icon } from '@poly/admin-book';
import styled from 'styled-components';
import { bool, func } from 'prop-types';

import { treeNodePropTypes } from '../propTypes.js';

const PlusBtnContainer = styled.div`
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  > div:last-child {
    transform: rotate(90deg);
  }
`;

const PlusBtnLine = styled.div`
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  width: 100%;
  height: 1px;
  background: ${getThemeColor(['midDark'])};
`;

function PlusBtn() {
  return (
    <PlusBtnContainer>
      <PlusBtnLine />
      <PlusBtnLine />
    </PlusBtnContainer>
  );
}

const RemoveBtn = styled(Icon)`
  cursor: pointer;
`;

const TreeNodeButtonS = styled.div.attrs(() => ({
  tabIndex: 0,
  role: 'button',
}))``;

export function TreeNodeButtons({
  node,
  onAddNewNode,
  onRemoveNode,
  addButtonHidden,
  removeButtonHidden,
  removeButtonDisabled,
}) {
  return (
    <div className="rst_buttons">
      {!addButtonHidden && (
        <div className="rst__rowToolbar" key={`${node.title}-add`}>
          <TreeNodeButtonS
            onClick={onAddNewNode}
            key={`${node.title}-add-btn`}
            className="rst__toolbarButton"
          >
            <PlusBtn />
          </TreeNodeButtonS>
        </div>
      )}
      <div
        className="rst__rowToolbar"
        key={`${node.title}-remove`}
        style={{ pointerEvents: removeButtonDisabled && 'none' }}
      >
        <TreeNodeButtonS
          onClick={onRemoveNode}
          className="rst__toolbarButton"
          key={`${node.title}-remove-btn`}
        >
          {!removeButtonHidden && (
            <RemoveBtn
              name="delete"
              color={
                removeButtonDisabled
                  ? defaultTheme.colors.secondary
                  : defaultTheme.colors.accent
              }
              size={12}
            />
          )}
        </TreeNodeButtonS>
      </div>
    </div>
  );
}

TreeNodeButtons.propTypes = {
  node: treeNodePropTypes,
  onAddNewNode: func,
  onRemoveNode: func,
  addButtonHidden: bool,
  removeButtonHidden: bool,
  removeButtonDisabled: bool,
};
