import * as R from 'ramda';
import { propEqLegacy } from '@poly/utils';

// findCountByStatus :: String -> [{status: String, count: Number}] -> Number
export const findCountByStatus = (status, countByStatuses) =>
  R.compose(
    (v) => v.toLocaleString(),
    R.propOr(0, 'count'),
    R.find(propEqLegacy('status', status)),
  )(countByStatuses);
