import React, { useEffect } from 'react';
import * as R from 'ramda';
import { Select } from '@poly/admin-book/src/Select/Select.js';
import { bool, func, shape, string } from 'prop-types';
import { startOfDay } from 'date-fns';
import { gql } from '@apollo/client';
import { useQueryWithSearch } from '@poly/client-utils/src/select.js';
import { aminPurchaseOrderStatuses } from '@poly/constants/src/adminPurchaseOrder.js';
import { isAdminPOWithLowBalance } from '@poly/utils/src/adminPurchaseOrder.js';
import { useReactiveQuery } from '@poly/client-utils/src/index.js';
import { isNilOrEmpty } from '@poly/utils/src/general.js';
import { alwaysNewDate } from '@poly/utils/src/dates.js';
import { ofArrayLegacy } from '@poly/utils';

import { SEARCH_CLIENT_PO_SUBSCRIPTION } from '../../../../../pages/PurchaseOrdersPage/useClientPurchaseOrders.js';
import { purchaseOrderDetailSubs } from '../../../../PurchaseOrderSidebar/usePurchaseOrderDetail.js';
import { useHasUserAccessToReadAdminPO } from '../../../../PurchaseOrderSidebar/common-hooks.js';

const searchAdminPOQuery = gql`
  query searchAdminPOQuery($searchInput: CollectionSearchParams) {
    searchAdminPurchaseOrders(input: $searchInput) {
      hits {
        _id
        poNumber
        displayName
      }
    }
  }
`;

// getPurchaseOrdersOption :: AdminPO -> Option
const getPurchaseOrdersOption = R.applySpec({
  label: R.compose(
    R.join('/'),
    R.juxt([R.prop('poNumber'), R.prop('displayName')]),
  ),
  value: R.prop('_id'),
});

// getPurchaseOrdersOptions :: QueryData -> [Option]
const getPurchaseOrdersOptions = R.compose(
  R.map(getPurchaseOrdersOption),
  R.pathOr([], ['searchAdminPurchaseOrders', 'hits']),
);

// getDefaultAdminPOSelectOptions :: {adminPurchaseOrder: AdminPO} -> [Option]
const getDefaultAdminPOSelectOptions = R.compose(
  R.ifElse(
    isNilOrEmpty,
    R.always([]),
    R.compose(ofArrayLegacy, getPurchaseOrdersOption),
  ),
  R.prop('adminPurchaseOrder'),
);

export const useAdminPOSelectOptions = ({ query, skipQuery }) => {
  const { result, loading, onSearchChange } = useQueryWithSearch({
    gqlSearchQuery: searchAdminPOQuery,
    gqlSearchChangedQuery: SEARCH_CLIENT_PO_SUBSCRIPTION,
    query,
    skipQuery,
    withoutSkip: true,
  });

  const options = getPurchaseOrdersOptions(result);

  return {
    options,
    loading,
    onSearchChange,
  };
};

function AdminPOSelectorBase({
  query,
  skipQuery,
  onChange,
  value,
  hasAccessReadAdminPO,
  formData,
  ...props
}) {
  const { options, loading, onSearchChange } = useAdminPOSelectOptions({
    query,
    skipQuery,
  });

  useEffect(() => {
    if (value && R.isEmpty(options) && !loading) {
      onChange(null);
    }
  }, [options]);

  const defaultOptions = getDefaultAdminPOSelectOptions(formData);

  return (
    <Select
      {...props}
      onChange={onChange}
      value={value}
      options={hasAccessReadAdminPO ? options : defaultOptions}
      onInputChange={onSearchChange}
    />
  );
}

AdminPOSelectorBase.propTypes = {
  query: shape({}),
  skipQuery: bool,
  onChange: func.isRequired,
  value: string,
  formData: shape({}),
  hasAccessReadAdminPO: bool,
};

const purchaseOrderDetailQuery = gql`
  query purchaseOrderDetailQuery($purchaseOrderId: ID!) {
    adminPurchaseOrder(id: $purchaseOrderId) {
      _id
      initialBalance
      currentBalance
      lowBalancePercent
      status
      endDate
    }
  }
`;

const selectedAdminPOFormFieldName = 'selectedAdminPO';

const useValidatePOBalance = (
  purchaseOrderId,
  hasAccessReadAdminPO,
  changeFieldValue,
) => {
  const skipQuery = !hasAccessReadAdminPO || !purchaseOrderId;

  const queryOptions = {
    variables: { purchaseOrderId },
    fetchPolicy: 'network-only',
    skip: skipQuery,
  };

  const subscriptionOptions = {
    variables: { input: { id: purchaseOrderId } },
    skip: skipQuery,
  };
  const { data } = useReactiveQuery(
    purchaseOrderDetailQuery,
    purchaseOrderDetailSubs,
    { queryOptions, subscriptionOptions },
  );

  const purchaseOrder = R.prop('adminPurchaseOrder', data);

  useEffect(() => {
    changeFieldValue(selectedAdminPOFormFieldName, purchaseOrder);
  }, [purchaseOrder]);

  if (purchaseOrder) {
    if (isAdminPOWithLowBalance(purchaseOrder)) {
      return 'This is PO with a low balance. Make sure you select correct and take other PO if needed.';
    }
  }

  return null;
};

// getMustNotExistFieldQuery :: String -> ElasticQuery
const getMustNotExistFieldQuery = (fieldName) => ({
  bool: {
    must_not: {
      exists: {
        field: fieldName,
      },
    },
  },
});

// getPropertyQueries :: ProjectFormData -> ElasticQuery
const getPropertyQueries = R.ifElse(
  R.path(['property', '_id']),
  R.juxt([
    R.always(getMustNotExistFieldQuery('propertiesIds')),
    R.applySpec({ term: { propertiesIds: R.path(['property', '_id']) } }),
  ]),
  R.always([]),
);

// wrapBoolWith :: String -> ElasticQuery -> ElasticQuery
const wrapBoolWith = (propName) =>
  R.compose(...R.map(R.objOf, ['bool', propName]));

// getMinimumShouldMatch ::  ProjectFormData -> Number
const getMinimumShouldMatch = R.ifElse(
  R.path(['property', '_id']),
  R.always(2),
  R.always(1),
);

// getPropertyAndEndDateQuery :: ProjectFormData -> ElasticQuery
const getPropertyAndEndDateQuery = R.compose(
  R.objOf('bool'),
  R.applySpec({
    should: R.compose(
      R.unnest,
      R.juxt([getPropertyQueries, () => getMustNotExistFieldQuery('endDate')]),
    ),
    minimum_should_match: getMinimumShouldMatch,
  }),
);

// getPropertyAndDatesRangeQuery :: ProjectFormData -> ElasticQuery
const getPropertyAndDatesRangeQuery = R.compose(
  R.objOf('bool'),
  R.applySpec({
    should: R.compose(
      R.unnest,
      R.juxt([
        getPropertyQueries,
        R.compose(
          wrapBoolWith('must'),
          R.juxt([
            R.applySpec({
              range: {
                startDate: { lte: R.compose(startOfDay, R.prop('startDate')) },
              },
            }),
            R.applySpec({
              range: {
                endDate: { gte: R.compose(startOfDay, R.prop('endDate')) },
              },
            }),
          ]),
        ),
      ]),
    ),
    minimum_should_match: getMinimumShouldMatch,
  }),
);

// getSearchAdminPOQueryByProjectBase :: ProjectFormData -> ElasticQuery
const getSearchAdminPOQueryByProjectBase = R.compose(
  wrapBoolWith('must'),
  R.juxt([
    R.compose(
      R.assocPath(['bool', 'minimum_should_match'], 1),
      wrapBoolWith('should'),
      R.juxt([
        () => getMustNotExistFieldQuery('endDate'),
        R.applySpec({
          range: { endDate: { gte: R.compose(startOfDay, alwaysNewDate) } },
        }),
      ]),
    ),
    R.applySpec({ term: { clientId: R.path(['client', '_id']) } }),
    R.applySpec({
      term: { status: R.always(aminPurchaseOrderStatuses.ACTIVE) },
    }),
    R.compose(
      wrapBoolWith('should'),
      R.juxt([getPropertyAndEndDateQuery, getPropertyAndDatesRangeQuery]),
    ),
  ]),
);

// getSearchAdminPOQueryByProject :: ProjectFormData -> ElasticQuery
export const getSearchAdminPOQueryByProject = R.ifElse(
  R.path(['adminPurchaseOrder', '_id']),
  R.compose(
    wrapBoolWith('should'),
    R.juxt([
      R.applySpec({ term: { _id: R.path(['adminPurchaseOrder', '_id']) } }),
      getSearchAdminPOQueryByProjectBase,
    ]),
  ),
  getSearchAdminPOQueryByProjectBase,
);

export function AdminPOSelector({
  formData,
  onChange,
  changeFieldValue,
  value,
  ...props
}) {
  const hasAccessReadAdminPO = useHasUserAccessToReadAdminPO();

  const clientId = R.path(['client', '_id'], formData);

  const query = getSearchAdminPOQueryByProject(formData);

  const warning = useValidatePOBalance(
    value,
    hasAccessReadAdminPO,
    changeFieldValue,
  );

  return (
    <AdminPOSelectorBase
      {...props}
      value={value}
      onChange={hasAccessReadAdminPO ? onChange : R.identity}
      query={query}
      warning={warning}
      skipQuery={!clientId || !hasAccessReadAdminPO}
      disabled={!hasAccessReadAdminPO}
      hasAccessReadAdminPO={hasAccessReadAdminPO}
      formData={formData}
    />
  );
}

AdminPOSelector.propTypes = {
  formData: shape({
    client: shape({ _id: string }),
    property: shape({ _id: string }),
  }),
  value: string,
  onChange: func.isRequired,
  changeFieldValue: func.isRequired,
};

export const adminPOInfoHolderField = {
  field: {
    name: selectedAdminPOFormFieldName,
    Component: () => null,
  },
  leaveValues: true,
};
