import React from 'react';
import * as R from 'ramda';
import { object, oneOfType, shape, string } from 'prop-types';
import { pathOrNothingUI } from '@poly/client-utils';
import { NOTHING_UI_STRING } from '@poly/constants';
import { SidebarRow } from '@poly/admin-ui';

import { forceTitleCase } from '@poly/utils';
import {
  SectionLabel,
  BlockWithLabel,
  SectionWrapper,
  threeBlocksProps,
  threeBlocksWrapperProps,
} from '../components/commonSidebarComponents.js';
import { isPropertyFieldActive } from './propertySidebarUtils.js';
import {
  getSectionEditor,
  getSectionText,
} from '../components/commonSidebarSectionFormatters.js';

// isBuildingFieldActive :: String -> Client -> Boolean
const isBuildingFieldActive = (field) =>
  isPropertyFieldActive(['buildingInfo', field]);

// titlePropOrNothingUI :: String -> BuildingInfo -> String
const titlePropOrNothingUI = (prop) =>
  R.compose(
    R.ifElse(R.isNil, R.always(NOTHING_UI_STRING), (str) =>
      forceTitleCase(str),
    ),
    R.prop(prop),
  );

export function PropertySidebarBuildingInfo({ property }) {
  const { buildingInfo, client } = property;
  return (
    <SectionWrapper>
      <SidebarRow>
        <SectionLabel>Building Information</SectionLabel>
      </SidebarRow>
      <SidebarRow {...threeBlocksWrapperProps}>
        {isBuildingFieldActive('buildingSize')(client) && (
          <BlockWithLabel
            id="building-size"
            label="Building Size"
            {...threeBlocksProps}
            Component={getSectionText(
              pathOrNothingUI(['buildingSize'])(buildingInfo),
            )}
          />
        )}
        {isBuildingFieldActive('ownership')(client) && (
          <BlockWithLabel
            id="ownership"
            label="Ownership"
            {...threeBlocksProps}
            Component={getSectionText(
              titlePropOrNothingUI('buildingOwnership')(buildingInfo),
            )}
          />
        )}
        {isBuildingFieldActive('occupancy')(client) && (
          <BlockWithLabel
            id="occupancy"
            label="Occupancy"
            {...threeBlocksProps}
            Component={getSectionText(
              titlePropOrNothingUI('occupancy')(buildingInfo),
            )}
          />
        )}
        {isBuildingFieldActive('lockBoxCode')(client) && (
          <BlockWithLabel
            id="lock-box-code"
            label="Lockbox Code"
            {...threeBlocksProps}
            Component={getSectionText(
              pathOrNothingUI(['lockBoxCode'])(buildingInfo),
            )}
          />
        )}
        {isBuildingFieldActive('powerRequirements')(client) && (
          <BlockWithLabel
            id="power-requirements"
            label="Power Requirements"
            {...threeBlocksProps}
            Component={getSectionText(
              pathOrNothingUI(['powerRequirements'])(buildingInfo),
            )}
          />
        )}
        {isBuildingFieldActive('parcelTaxId')(client) && (
          <BlockWithLabel
            id="parcel-tax-id"
            label="Parcel Tax ID"
            {...threeBlocksProps}
            Component={getSectionText(
              pathOrNothingUI(['parcelTaxId'])(buildingInfo),
            )}
          />
        )}
        {isBuildingFieldActive('buildingType')(client) && (
          <BlockWithLabel
            id="building-type"
            label="Building Type"
            {...threeBlocksProps}
            Component={getSectionText(
              pathOrNothingUI(['buildingType'])(buildingInfo),
            )}
          />
        )}
      </SidebarRow>
      {isBuildingFieldActive('landlordResponsibility')(client) && (
        <SidebarRow>
          <BlockWithLabel
            margin="0"
            width="100%"
            id="landlord-responsibility"
            label="Landlord Responsibility"
            Component={getSectionEditor(buildingInfo?.landlordResponsibility)}
          />
        </SidebarRow>
      )}
    </SectionWrapper>
  );
}

PropertySidebarBuildingInfo.propTypes = {
  property: shape({
    buildingInfo: shape({
      occupancy: string,
      lockBoxCode: string,
      buildingSize: string,
      buildingOwnership: string,
      powerRequirements: string,
      landlordResponsibility: oneOfType([object, string]),
    }),
    client: shape({ _id: string.isRequired }),
  }),
};
