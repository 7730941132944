import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { Checkbox } from '@poly/admin-book';

export function CheckWOComponent({
  onChange,
  formData,
  relatedText,
  relatedCheckbox,
  changeFieldValue,
  ...props
}) {
  const onCheckboxChange = (value) => {
    onChange(value);
    changeFieldValue('supplierNote', value && relatedText ? relatedText : '');

    if (value && formData[relatedCheckbox]) {
      changeFieldValue(relatedCheckbox, false);
    }
  };

  return <Checkbox {...props} onChange={onCheckboxChange} />;
}

CheckWOComponent.propTypes = {
  value: bool,
  // eslint-disable-next-line
  formData: object,
  relatedText: string,
  onChange: func.isRequired,
  changeFieldValue: func.isRequired,
  relatedCheckbox: string,
};
