import React from 'react';
import { string, node } from 'prop-types';
import { MainHeader, PageHeaderContainer } from '@poly/admin-book';

import { useProcessState } from '../hooks/useProcessState.js';
import { ButtonsContainer } from './Button/Button.js';
import { SubmitBtn } from './common.js';
import { CancelFormBtn } from './Button/CancelFormBtn.js';

export function FormPageHeader({ title, formId, redirectUrl }) {
  const { process } = useProcessState(formId);
  return (
    <PageHeaderContainer>
      <MainHeader>{title}</MainHeader>
      <ButtonsContainer>
        <SubmitBtn disabled={process} form={formId} loader={process}>
          Create
        </SubmitBtn>
        <CancelFormBtn disabled={process} redirectUrl={redirectUrl} />
      </ButtonsContainer>
    </PageHeaderContainer>
  );
}

FormPageHeader.propTypes = {
  title: node.isRequired,
  formId: string.isRequired,
  redirectUrl: string,
};
