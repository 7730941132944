import React from 'react';
import { DefaultBodyWrapper, TableCard } from '@poly/admin-book';

import { UserGroupManagementTable } from './UserGroupManagementTable.js';
import { UserGroupManagementPageHeader } from './UserGroupManagementPageHeader.js';
import { useUserGroupListQuery } from './useUserGroupListQuery.js';

export function UserGroupManagementPage() {
  const { userGroupList, loading, refetch, tableSortingProps } =
    useUserGroupListQuery();

  return (
    <>
      <UserGroupManagementPageHeader refetch={refetch} />
      <DefaultBodyWrapper>
        <TableCard>
          <UserGroupManagementTable
            {...{ userGroupList, loading, tableSortingProps }}
          />
        </TableCard>
      </DefaultBodyWrapper>
    </>
  );
}
