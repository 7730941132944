import * as R from 'ramda';
import { getClientInvoiceTotalWithTax } from './client-invoice/tax.js';
import { calculateTotal } from './general.js';
import { assocBy, overPropUnlessNil } from './ramda.js';

// calculateTimeEntryTotal :: [TimeEntryInput] -> Number
export const calculateTimeEntryTotal = R.converge(R.multiply, [
  R.propOr(0, 'rate'),
  R.compose(R.divide(R.__, 60), R.propOr(0, 'totalMinutes')),
]);

// calculateEntriesTotals :: ProjectEstimateInput -> ProjectEstimateInput
const calculateEstimateLinesTotals = R.compose(
  overPropUnlessNil(
    'markupEntries',
    R.map(
      assocBy(
        'total',
        R.converge(R.add, [R.propOr(0, 'amount'), R.propOr(0, 'markup')]),
      ),
    ),
  ),
  overPropUnlessNil(
    'manualEntries',
    R.map(
      assocBy(
        'total',
        R.converge(R.multiply, [R.propOr(0, 'rate'), R.propOr(0, 'quantity')]),
      ),
    ),
  ),
  overPropUnlessNil(
    'timeEntries',
    R.map(assocBy('total', calculateTimeEntryTotal)),
  ),
);
// calculateEntriesTotal :: String -> ProjectEstimateInput -> Number
export const calculateEntriesTotal = (key) =>
  R.compose(calculateTotal(R.prop('total')), R.propOr([], key));

// getAfterHoursCallAmount :: ProjectEstimateInput -> Number
const getAfterHoursCallAmount = R.propOr(0, 'afterHoursCall');

// assocWithEstimateSubtotalAmount :: ProjectEstimateInput -> Number
const assocWithEstimateSubtotalAmount = assocBy(
  'subTotal',
  R.compose(
    calculateTotal(R.identity),
    R.juxt([
      calculateEntriesTotal('markupEntries'),
      calculateEntriesTotal('manualEntries'),
      calculateEntriesTotal('timeEntries'),
      getAfterHoursCallAmount,
    ]),
  ),
);

// assocWithMarkupTotalAmount :: ProjectEstimateInput -> Number
const assocWithMarkupTotalAmount = assocBy(
  'markupTotal',
  R.compose(calculateTotal(R.prop('markup')), R.propOr([], 'markupEntries')),
);

// calculateEstimateTaxAmount :: ProjectEstimate -> ProjectEstimate
const calculateEstimateTaxAmount = R.compose(
  R.applySpec({
    tax: R.prop('taxAmount'),
    total: R.prop('amount'),
  }),
  getClientInvoiceTotalWithTax,
  R.applySpec({
    amount: R.prop('subTotal'),
    markupAmount: R.prop('markupTotal'),
    taxPercent: R.prop('taxPercent'),
  }),
);

// assocWithTaxAndTotalAmounts :: ProjectEstimate -> ProjectEstimate
const assocWithTaxAndTotalAmounts = R.compose(
  assocBy(
    'total',
    R.converge(R.add, [R.prop('total'), R.propOr(0, 'woCharge')]),
  ),
  R.ifElse(
    R.prop('taxPercent'),
    R.converge(R.mergeRight, [
      R.omit(['taxPercent']),
      calculateEstimateTaxAmount,
    ]),
    assocBy('total', R.prop('subTotal')),
  ),
);

// calculateProjectEstimateTotals :: ProjectEstimateInput -> ProjectEstimate
export const calculateProjectEstimateTotals = R.compose(
  assocWithTaxAndTotalAmounts,
  assocWithEstimateSubtotalAmount,
  assocWithMarkupTotalAmount,
  calculateEstimateLinesTotals,
);
