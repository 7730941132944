import * as R from 'ramda';

// getPropertyInvoicesReportWithChildren :: BatchPropertySpendReportResult -> [BatchInvoiceSpendReportResult]
export const getPropertyInvoicesReportWithChildren = R.compose(
  R.flatten,
  R.juxt([
    R.propOr([], 'propertyInvoicesReport'),
    R.compose(
      R.map(R.propOr([], 'propertyInvoicesReport')),
      R.propOr([], 'childProperties'),
    ),
  ]),
);
