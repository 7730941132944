/* eslint-disable node/file-extension-in-import */
/* eslint-disable import/extensions */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RoutesGenerator } from '@poly/client-routing';
import { NotificatorProvider } from '@poly/admin-ui';
import { initSentry } from '@poly/client-utils';

import {
  StoreProvider,
  ThemeProvider,
  ApolloClientProvider,
} from './providers.js';
import { appRoutesConfig } from './routes/appRoutesConfig.js';
import { AdminOldVersionDetector } from './AdminOldVersionDetector.js';

initSentry('poly-admin');

function App() {
  return (
    <ApolloClientProvider>
      <ThemeProvider>
        <StoreProvider>
          <NotificatorProvider>
            <RoutesGenerator config={appRoutesConfig} />
            <AdminOldVersionDetector />
          </NotificatorProvider>
        </StoreProvider>
      </ThemeProvider>
    </ApolloClientProvider>
  );
}

ReactDOM.createRoot(document.getElementById('root')).render(<App />);
