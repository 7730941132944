import React from 'react';
import * as R from 'ramda';
import { bool } from 'prop-types';
import styled from 'styled-components';
import { usePristineSubscribe } from '@poly/client-routing';
import { FormCreator } from '@poly/admin-book';
import {
  commonSidebarFormSectionLayout,
  useOnSubmitSetStopSubmitting,
  commonSidebarFormFieldLayout,
  commonSidebarFormLayout,
} from '@poly/admin-ui';

import { addAssetFormId } from '../../constants.js';
import { assetFormSections } from '../form/assetFormSections.js';
import { useOnSubmitAddAssetForm } from './useOnSubmitAddAssetForm.js';
import { useValidateAssetForm } from '../form/useValidateAssetForm.js';

export const AssetFormCreator = styled(FormCreator)`
  min-height: calc(100vh - 190px);
`;

// prepareInitialValues :: Boolean -> FormProps -> InitialValues
const prepareInitialValues = (onlyChildProperties) =>
  R.compose(
    R.mergeLeft({ photo: [] }),
    R.when(() => onlyChildProperties, R.dissoc('propertyId')),
  );

export function AddAssetForm({ onlyChildProperties, ...props }) {
  const pristineSubscribeProps = usePristineSubscribe({
    id: addAssetFormId,
    ...props,
  });

  const onSubmitHandler = useOnSubmitAddAssetForm();

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    addAssetFormId,
    onSubmitHandler,
  );

  const initialValues = prepareInitialValues(onlyChildProperties)(props);

  const validate = useValidateAssetForm();

  return (
    <AssetFormCreator
      validateOnBlur
      id={addAssetFormId}
      onSubmit={onSubmit}
      validate={validate}
      sections={assetFormSections}
      initialValues={initialValues}
      layout={commonSidebarFormLayout}
      fieldLayout={commonSidebarFormFieldLayout}
      sectionLayout={commonSidebarFormSectionLayout}
      {...pristineSubscribeProps}
    />
  );
}

AddAssetForm.propTypes = { onlyChildProperties: bool };
