import { useState, useCallback } from 'react';
import * as R from 'ramda';
import { assocBy, isNilOrEmpty } from '@poly/utils';

import { ifNotEmpty } from '../formValidators.js';
import {
  generateFormattedAddress,
  isCanadianZip,
  isEmptyAddressFields,
} from '../AddressSection/addressSectionUtils.js';

// isEmptyZip :: Address -> Boolean
const isEmptyZip = ifNotEmpty(
  R.pathSatisfies(isNilOrEmpty, ['address_parts', 'postal_code']),
);

// modifyAddress :: Address :: Address
const modifyAddress = R.when(
  isCanadianZip,
  R.pipe(
    R.dissocPath(['address_parts', 'administrative_area_level_1']),
    R.assocPath(['address_parts', 'country'], 'CA'),
  ),
);

// getFormattedAddress :: AddressObject -> String
const getFormattedAddress = R.compose(
  R.ifElse(isEmptyAddressFields, R.always(''), generateFormattedAddress),
  modifyAddress,
);

// getWarningMessage :: AddressObject -> String
const getWarningMessage = R.ifElse(
  R.both(
    R.compose(R.complement(isEmptyAddressFields), modifyAddress),
    R.compose(isNilOrEmpty, R.path(['address_parts', 'street_number'])),
  ),
  R.always('Note: Street number is missing'),
  R.always(''),
);

// assocDefaultCountryIfNeed :: AddressObject -> AddressObject
const assocDefaultCountryIfNeed = R.unless(
  R.path(['address_parts', 'country']),
  R.assocPath(['address_parts', 'country'], 'US'),
);

// shouldUnsetAddress :: Input -> Boolean
// Input = {
//    isAddressManualValue: Boolean
//    required: Boolean
//    value: Address
// }
const shouldUnsetAddress = R.allPass([
  R.complement(R.prop('isAddressManualValue')),
  R.complement(R.prop('required')),
  R.pathSatisfies(R.isEmpty, ['value', 'formatted_address']),
]);

// prepareAddressBeforeChange :: AddressObject -> AddressObject
const prepareAddressBeforeChange = R.compose(
  assocDefaultCountryIfNeed,
  assocBy('formatted_address', getFormattedAddress),
);

export const useAddressSectionLogic = (props) => {
  const { isAddressManual, value, required, zipNotRequired, onChange } = props;

  const [isAddressManualState, setIsAddressManualState] = useState(false);
  const [warning, setWarning] = useState('');

  const onChangeAddress = (address) => {
    if (!isEmptyAddressFields(address)) {
      setWarning(getWarningMessage(address));
      onChange(prepareAddressBeforeChange(address));
    }
  };

  const isRequiredSearchAddressField = !!(
    !isAddressManual &&
    isNilOrEmpty(value) &&
    required
  );
  const isRequiredZipCode = !required ? required : !zipNotRequired;
  const isValueAddressEmpty = isEmptyAddressFields(value);

  const onInputChange = useCallback(() => {
    if (required && !isNilOrEmpty(value)) return;

    if (isEmptyAddressFields(value)) {
      onChangeAddress({});
    }
  }, [required, value, onChangeAddress]);

  const setAddressManual = useCallback(
    (isAddressManualValue) => {
      if (shouldUnsetAddress({ isAddressManualValue, required, value })) {
        onChange(null);
      } else {
        onChangeAddress(
          R.compose(
            assocDefaultCountryIfNeed,
            R.omit(['geo', 'vicinity', 'utc_offset']),
          )(value),
        );
      }

      setIsAddressManualState(isAddressManualValue);
    },
    [onChangeAddress, value, setIsAddressManualState],
  );

  const setAddressSearch = useCallback(
    (newValue) => {
      const result = R.compose(
        assocDefaultCountryIfNeed,
        R.mergeDeepLeft(newValue),
      )(value);
      onChangeAddress(result);
      setIsAddressManualState(isEmptyZip(newValue));
    },
    [onChangeAddress, setIsAddressManualState, value],
  );

  const setAddressTwo = useCallback(
    (addressTwo) => onChangeAddress({ ...value, addressTwo }),
    [value, onChangeAddress],
  );

  const setStreetNumber = useCallback(
    (streetNumber) =>
      onChangeAddress({
        ...value,
        address_parts: {
          ...value.address_parts,
          street_number: streetNumber,
        },
      }),
    [value, onChangeAddress],
  );

  const setStreetName = useCallback(
    (streetName) =>
      onChangeAddress({
        ...value,
        address_parts: { ...value.address_parts, route: streetName },
      }),
    [value, onChangeAddress],
  );

  const setCity = useCallback(
    (city) =>
      onChangeAddress({
        ...value,
        address_parts: { ...value.address_parts, locality: city },
      }),
    [value, onChangeAddress],
  );

  const setAddressState = useCallback(
    (state) =>
      onChangeAddress({
        ...value,
        address_parts: {
          ...value.address_parts,
          administrative_area_level_1: state,
        },
      }),
    [value, onChangeAddress],
  );

  const setZipCode = useCallback(
    (postalCode) =>
      onChangeAddress({
        ...value,
        address_parts: { ...value.address_parts, postal_code: postalCode },
      }),
    [value, onChangeAddress],
  );

  const setZipCodeSuffix = useCallback(
    (postalCodeSuffix) =>
      onChangeAddress({
        ...value,
        address_parts: {
          ...value.address_parts,
          postal_code_suffix: postalCodeSuffix,
        },
      }),
    [value, onChangeAddress],
  );

  const setCountry = useCallback(
    (country) =>
      onChangeAddress({
        ...value,
        address_parts: { ...value.address_parts, country },
      }),
    [value, onChangeAddress],
  );

  return {
    isRequiredSearchAddressField,
    isRequiredZipCode,
    isEmptyAddress: isValueAddressEmpty,
    isAddressManual: isAddressManualState,
    setAddressManual,
    onInputChange,
    setAddressTwo,
    setAddressSearch,
    setStreetNumber,
    setStreetName,
    setCity,
    setAddressState,
    setZipCode,
    setZipCodeSuffix,
    warning,
    setCountry,
  };
};
