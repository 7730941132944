import React, { useState } from 'react';
import { ClientStatuses, ELASTIC_SCORE_FIELD } from '@poly/constants';
import { TableSearchInput } from '@poly/admin-ui';
import { PageHeaderContainer, MainHeader } from '@poly/admin-book';

import { PageTabs } from '../../modules/tabs/TabsWithRouter.js';
import { PageTabMap } from './constants.js';
import { ClientsTab } from '../../modules/tabs/clientsTab/ClientsTab.js';
import { ClientsTabTitle } from '../../modules/tabs/clientsTab/ClientsTabTitle.js';
import { useClientsBySearch } from '../../modules/core/hooks/clients/index.js';
import { ClientsExportToXLSBtn } from './ClientsExportToXLSBtn.js';

const { ACTIVE, INACTIVE } = ClientStatuses;

function TabsWithPrefetch(props) {
  const { result: activeClientsResult } = useClientsBySearch({
    status: ACTIVE,
    propsOfComponent: props,
  });

  const { result: inActiveClientsResult } = useClientsBySearch({
    status: INACTIVE,
    propsOfComponent: props,
  });

  return <PageTabs {...activeClientsResult} {...inActiveClientsResult} />;
}

export function ClientsPage() {
  const [sort, setSort] = useState([ELASTIC_SCORE_FIELD]);

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Clients</MainHeader>
      </PageHeaderContainer>
      <TabsWithPrefetch
        toolBar={
          <>
            <ClientsExportToXLSBtn sort={sort} />
            <TableSearchInput />
          </>
        }
        tabs={[
          [
            <ClientsTabTitle key={ACTIVE} status={ACTIVE} />,
            PageTabMap.ACTIVE_CLIENTS,
            <ClientsTab
              key={PageTabMap.ACTIVE_CLIENTS}
              status={ACTIVE}
              setSort={setSort}
            />,
          ],
          [
            <ClientsTabTitle key={INACTIVE} status={INACTIVE} />,
            PageTabMap.INACTIVE_CLIENTS,
            <ClientsTab
              key={PageTabMap.INACTIVE_CLIENTS}
              status={INACTIVE}
              setSort={setSort}
            />,
          ],
        ]}
        defaultValue={PageTabMap.ACTIVE_CLIENTS}
      />
    </>
  );
}
