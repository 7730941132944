import React from 'react';
import { usePagination } from '@poly/admin-ui';
import { StaffTabComp } from './StaffTabComp.js';

export function StaffTab(props) {
  const { pagination } = usePagination();

  return <StaffTabComp {...props} pagination={pagination} paginationVisible />;
}

StaffTab.displayName = 'StaffTab';
