import React from 'react';
import { Field } from 'react-final-form';
import { bool, func, object, string, oneOfType } from 'prop-types';

export function FormField({
  labelId,
  Component,
  className,
  additionalProps,
  showErrorMsg,
  name,
  // https://github.com/final-form/react-final-form#fieldprops
  ...fieldProps
}) {
  return (
    <Field name={name} {...fieldProps}>
      {({ input, meta }) => (
        <Component
          className={className}
          {...input}
          {...additionalProps}
          hasError={!!meta.touched && !!meta.error}
          error={showErrorMsg && meta.touched && meta.error ? meta.error : ''}
          meta={meta}
          id={labelId || name}
        />
      )}
    </Field>
  );
}

FormField.propTypes = {
  Component: oneOfType([func, object]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  additionalProps: object,
  showErrorMsg: bool,
  className: string,
  labelId: string,
  name: string,
};
FormField.defaultProps = {
  additionalProps: {},
  showErrorMsg: true,
  className: '',
};

export const createGetFormFieldWidth =
  (totalColumns, columnMarginPx) => (currentColumnCount) => {
    // Pr - %, Px - pixels
    const singleColumnWidthPr = Math.round(100 / totalColumns);
    const currentColumnWidthPr = singleColumnWidthPr * currentColumnCount;

    const currentMarginCompensationPx =
      (totalColumns - currentColumnCount) * columnMarginPx;

    return `calc(${currentColumnWidthPr}% - ${currentMarginCompensationPx}px)`;
  };
