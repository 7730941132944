import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { string, number, oneOfType } from 'prop-types';

import { defaultTheme } from '../ThemeProvider/index.js';
import { fileIcons } from './fileIcons.js';
import { arrowsIcons } from './arrows.js';
import { miscIcons } from './misc.js';
import { userIcons } from './user.js';

const wrapIconsWithStyled = R.map((Component) => {
  function IconBase(props) {
    return <Component {...R.omit(['size'], props)} />;
  }
  return styled(IconBase)`
    display: block;
    width: ${(props) => props.size}px;
  `;
});

export const icons = wrapIconsWithStyled({
  ...fileIcons,
  ...arrowsIcons,
  ...miscIcons,
  ...userIcons,
});

const {
  colors: { black },
} = defaultTheme;

export function Icon({ name, ...props }) {
  const IconC = icons[name];
  return <IconC {...props} />;
}

Icon.displayName = 'Icon';

Icon.propTypes = {
  name: string.isRequired,
  color: string,
  size: oneOfType([number, string]),
};

Icon.defaultProps = {
  color: black,
  size: 50,
};
