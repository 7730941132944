import * as R from 'ramda';
import { AccountStatuses } from '@poly/constants';
import { assocBy, insertQueryParamsIntoURL, propEqLegacy } from '@poly/utils';
import { routesNames } from '../../routes/index.js';

// markAccountsWithParentAndChildFlag :: [AccountPostgre] -> [AccountPostgre]
// eslint-disable-next-line import/no-unused-modules
export const markAccountsWithParentAndChildFlag = R.map((account) =>
  R.compose(
    R.when(
      R.compose(R.complement(R.isEmpty), R.prop('childAccounts')),
      R.assoc('hasChild', true),
    ),
    R.assoc('childAccounts', R.__, account),
    R.map(R.assoc('isChild', true)),
    R.propOr([], 'childAccounts'),
  )(account),
);

// getAccountsForActiveTab :: [AccountPostgre] -> [AccountPostgre]
// eslint-disable-next-line import/no-unused-modules
export const getAccountsForActiveTab = R.compose(
  R.map((account) =>
    R.compose(
      // can not change active parent status
      // if it has active children
      R.ifElse(
        R.compose(R.isEmpty, R.prop('childAccounts')),
        R.identity,
        R.assoc('statusChangeDisabled', true),
      ),
      R.assoc('childAccounts', R.__, { ...account, isDisabled: false }),
      R.map(R.assoc('isDisabled', false)),
      R.reject(propEqLegacy('status', AccountStatuses.INACTIVE)),
      R.propOr([], 'childAccounts'),
    )(account),
  ),
  R.reject(propEqLegacy('status', AccountStatuses.INACTIVE)),
);

// getAccountsForInactiveTab :: [AccountPostgre] -> [AccountPostgre]
// eslint-disable-next-line import/no-unused-modules
export const getAccountsForInactiveTab = R.compose(
  R.map(
    R.ifElse(
      propEqLegacy('status', AccountStatuses.ACTIVE),
      R.assoc('isDisabled', true),
      R.assoc('isDisabled', false),
    ),
  ),
  // omit active parent accounts without child
  R.reject(
    R.allPass([
      propEqLegacy('status', AccountStatuses.ACTIVE),
      R.propSatisfies(R.isEmpty, 'childAccounts'),
    ]),
  ),
  // omit active child accounts
  R.map((account) =>
    R.compose(
      R.assoc('childAccounts', R.__, account),
      R.map(
        R.assoc(
          'statusChangeDisabled',
          account.status === AccountStatuses.INACTIVE,
        ),
      ),
      R.map(R.assoc('isDisabled', false)),
      R.reject(propEqLegacy('status', AccountStatuses.ACTIVE)),
      R.propOr([], 'childAccounts'),
    )(account),
  ),
);

// extractChildAccounts :: [AccountPostgre] -> [AccountPostgre]
// eslint-disable-next-line import/no-unused-modules
export const extractChildAccounts = R.compose(
  R.flatten,
  R.map(
    R.juxt([
      assocBy(
        'accrualBalance',
        R.compose(
          R.sum,
          R.map(R.propOr(0, 'accrualBalance')),
          R.propOr([{ accrualBalance: 0 }], 'childAccounts'),
        ),
      ),
      R.prop('childAccounts'),
    ]),
  ),
);

// getTableRows :: String -> [AccountPostgre] -> [AccountPostgre]
export const getTableRows = R.curry((tab, accounts) =>
  R.compose(
    R.map(
      assocBy(
        'href',
        R.compose(
          insertQueryParamsIntoURL(R.__, routesNames.ACCOUNT_TRANSACTIONS),
          R.objOf('accountId'),
          R.prop('_id'),
        ),
      ),
    ),
    extractChildAccounts,
    R.ifElse(
      () => tab === AccountStatuses.ACTIVE,
      getAccountsForActiveTab,
      getAccountsForInactiveTab,
    ),
    markAccountsWithParentAndChildFlag,
    R.defaultTo([]),
  )(accounts),
);
