import * as R from 'ramda';
import { isFuture, isPast } from 'date-fns';
import { ensureIsDate, propEqLegacy } from '@poly/utils';
import {
  RecurringProjectStatuses,
  RecurringProjectStatusesText,
} from '@poly/constants';

const statusEq = propEqLegacy('status');
const endDateSatisfies = R.propSatisfies(R.__, 'endDate');

/**
 * getUIMasterProjectStatusText :: Project -> String
 */
export const getUIMasterProjectStatusText = R.cond([
  [
    statusEq(RecurringProjectStatuses.CLOSED),
    R.always(RecurringProjectStatusesText.CLOSED),
  ],
  [
    R.both(
      R.anyPass([
        endDateSatisfies(R.isNil),
        endDateSatisfies(R.compose(isFuture, ensureIsDate)),
      ]),
      statusEq(RecurringProjectStatuses.ACTIVE),
    ),
    R.always(RecurringProjectStatusesText.ACTIVE),
  ],
  [
    R.both(
      endDateSatisfies(R.compose(isPast, ensureIsDate)),
      statusEq(RecurringProjectStatuses.ACTIVE),
    ),
    R.always(RecurringProjectStatusesText.EXPIRED),
  ],
  [R.T, R.always('UNKNOWN')],
]);
