import styled from 'styled-components';

import { getThemeFont, getThemeColor } from '../utils.js';

export const UserAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  flex-shrink: 0;
  background-color: ${getThemeColor(['primaryLight'])};
  font-size: 10px;
  line-height: 12px;
  padding-bottom: 2px;
  font-weight: ${getThemeFont(['semibold'])};
  color: ${getThemeColor(['white'])};
  cursor: pointer;
`;

UserAvatar.displayName = 'UserAvatar';
