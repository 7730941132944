import { gql } from '@apollo/client';

export const CREATE_CLIENT_SPEND_REPORT_WITH_HIERARCHY_MUTATION = gql`
  mutation CREATE_CLIENT_SPEND_REPORT_WITH_HIERARCHY_MUTATION(
    $input: ClientSpendReportByHierarchyInput!
  ) {
    createClientSpendReportWithHierarchy(input: $input) {
      fileString
    }
  }
`;
