import { formatDate, propEqLegacy } from '@poly/utils';
import React from 'react';
import styled from 'styled-components';
import {
  useMapConfigToTablePropsWithSorting,
  useSingleSelectableTable,
} from '@poly/admin-ui';
import { Button, Table } from '@poly/admin-book';
import { arrayOf, shape, string, func, bool } from 'prop-types';
import * as R from 'ramda';
import { AccountingPeriodStatuses } from '@poly/constants';
import { FlexColumn, FlexContainer } from '../../components/FlexContainer.js';

const accountingPeriodsUILabels = {
  [AccountingPeriodStatuses.OPENED]: 'Opened',
  [AccountingPeriodStatuses.CLOSED]: 'Closed',
};

const tableConfig = [
  [
    'Start Date',
    R.compose(formatDate, R.prop('startDate')),
    R.prop('startDate'),
  ],
  ['End Date', R.compose(formatDate, R.prop('endDate')), R.prop('endDate')],
  [
    'Status',
    R.compose(R.prop(R.__, accountingPeriodsUILabels), R.prop('status')),
  ],
];

const AccountingPeriodTableS = styled(Table)`
  tr {
    width: 100%;
    margin: 0;
  }
  th:nth-child(4),
  td:nth-child(4) {
    width: 50px;
  }
`;

const AccountingListSectionTitleS = styled(FlexContainer)`
  width: 100%;
  height: 30px;
  border-bottom: 1px solid rgba(197, 198, 201, 0.35);
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-size: 19px;
`;

const AccountingPeriodsSectionContainerS = styled(FlexColumn)`
  justify-content: flex-end;
  margin-top: 10px;
`;

const ButtonContainerS = styled(FlexContainer)`
  justify-content: flex-end;
  margin-top: 10px;
  width: 100%;
`;

export function AccountingPeriodsTableComp({
  accountingPeriods,
  title,
  buttonTitle,
  onSubmit,
  loading,
  selectedRows,
  toggleRow,
  showAllSelector,
  toggleSelectAll,
}) {
  const accountingPeriodsTableProps = useMapConfigToTablePropsWithSorting({
    tableConfig,
    items: accountingPeriods,
    initialSorting: {
      columnKey: 0,
      dir: 1,
    },
  });

  return (
    <AccountingPeriodsSectionContainerS>
      <AccountingListSectionTitleS>{title}</AccountingListSectionTitleS>
      <AccountingPeriodTableS
        {...accountingPeriodsTableProps}
        selectedRows={selectedRows}
        toggleRow={toggleRow}
        toggleSelectAll={toggleSelectAll}
        selectedRowsInEnd
        showAllSelector={showAllSelector}
        minHeightLess
      />

      <ButtonContainerS>
        <Button
          onClick={onSubmit}
          disabled={loading || selectedRows.length <= 0}
          loader={loading}
          size="small"
        >
          {buttonTitle}
        </Button>
      </ButtonContainerS>
    </AccountingPeriodsSectionContainerS>
  );
}

const commonAccountingPeriodTablePropTypes = {
  accountingPeriods: arrayOf(
    shape({
      _id: string.isRequired,
      startDate: string.isRequired,
      endDate: string.isRequired,
      status: string.isRequired,
    }),
  ),
  title: string.isRequired,
  buttonTitle: string.isRequired,
  onSubmit: func.isRequired,
  loading: bool.isRequired,
  showAllSelector: bool,
};

AccountingPeriodsTableComp.propTypes = {
  ...commonAccountingPeriodTablePropTypes,
  toggleRow: func.isRequired,
  selectedRows: arrayOf(string),
};

export function AccountingPeriodsTable({
  accountingPeriods,
  onSubmit,
  ...props
}) {
  const { toggleRow, selectedRows } = useSingleSelectableTable();

  const selectedPeriod = R.find(
    propEqLegacy('_id', selectedRows[0]),
    accountingPeriods,
  );

  const handleSubmit = () => onSubmit(selectedPeriod);

  return (
    <AccountingPeriodsTableComp
      accountingPeriods={accountingPeriods}
      toggleRow={toggleRow}
      selectedRows={selectedRows}
      onSubmit={handleSubmit}
      showAllSelector={false}
      {...props}
    />
  );
}

AccountingPeriodsTable.propTypes = commonAccountingPeriodTablePropTypes;
