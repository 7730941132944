import { arrayOf, shape, number, string, oneOfType } from 'prop-types';

const transactionsPropTypes = arrayOf(
  shape({
    date: string.isRequired,
    invoiceAmount: number.isRequired,
    paymentAmount: number.isRequired,
    projectNumber: string.isRequired,
    transactionName: string.isRequired,
    transactionNumber: string.isRequired,
    invoiceNumber: oneOfType([string, number]).isRequired,
    transactionType: oneOfType([string, number]).isRequired,
  }),
).isRequired;

export const vendorLedgerReportPropTypes = {
  type: string.isRequired,
  title: string.isRequired,
  transactions: transactionsPropTypes,
};
