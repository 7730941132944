import * as R from 'ramda';
import React from 'react';
import { USAStates } from '@poly/constants';
import styled, { css } from 'styled-components';
import {
  moneyColumnStyles,
  WindowedTable,
  LinkButton,
  Table,
  getThemeColor,
  Select,
} from '@poly/admin-book';

const commonTableStyles = css`
  ${moneyColumnStyles(6)};
  ${moneyColumnStyles(7)};
  ${moneyColumnStyles(8)};
  ${moneyColumnStyles(9)};
`;

export const SalesTaxReportTableComp = styled(WindowedTable)`
  tbody {
    vertical-align: middle;
  }

  tr {
    margin: 0;
    width: 100%;
  }

  tfoot {
    margin-top: 2px;
  }

  th:nth-child(1),
  td:nth-child(1),
  th:nth-child(2),
  td:nth-child(2),
  th:nth-child(3),
  td:nth-child(3) {
    width: 130px;
  }

  ${commonTableStyles}
`;

export const SalesTaxReportPrintTableComp = styled(Table)`
  ${commonTableStyles}
`;

export const SalesTaxReportFooterContainer = styled.tr`
  padding: 13px 0;
  font-weight: bold;
  font-size: 15px;
  margin: 1px 0 0 0;
  background-color: ${getThemeColor(['white'])} !important;

  td:nth-child(1) {
    width: auto;
    text-align: end;
    padding-right: 90px;
  }

  ${moneyColumnStyles(2)};
  ${moneyColumnStyles(3)};
  ${moneyColumnStyles(4)};
  ${moneyColumnStyles(5)};
`;

export const SalesReportLink = styled(LinkButton)`
  white-space: nowrap;
`;

// prepareOptions :: Object -> [SelectOption]
const prepareOptions = R.compose(
  R.map(R.applySpec({ value: R.nth(0), label: R.nth(1) })),
  R.toPairs,
);

export function SalesTaxReportStateSelect(props) {
  const selectProps = {
    ...props,
    name: 'SalesTaxReportStateSelect',
    options: prepareOptions(USAStates),
  };

  return <Select {...selectProps} />;
}
