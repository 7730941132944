import * as R from 'ramda';
import React, { useEffect } from 'react';
import { MoneyInputAsCents } from '@poly/admin-ui/src/modules/forms/fields/MoneyInput.js';
import { NEW_YORK_STATE_SHORTNAME } from '@poly/constants';
import { pathEqLegacy } from '@poly/utils';

import { costPropTypes } from './common.js';
import { isEditInvoiceFormDisabled } from '../helpers.js';

// checkIfNYStateAndWithTax :: { formData: { project: Project }, value: Int } -> Boolean
const checkIfNYStateAndWithTax = R.allPass([
  R.either(
    pathEqLegacy(
      [
        'formData',
        'project',
        'property',
        'address',
        'address_parts',
        'administrative_area_level_1',
      ],
      NEW_YORK_STATE_SHORTNAME,
    ),
    pathEqLegacy(
      ['formData', 'propertyLocationState'],
      NEW_YORK_STATE_SHORTNAME,
    ),
  ),
  R.compose(R.both(R.is(Number), R.gt(R.__, 0)), R.prop('value')),
]);

export function InvoiceTaxField({ onChange, formData, value, ...props }) {
  const isNYStateWithTaxValue = checkIfNYStateAndWithTax({ formData, value });

  const onFocus = (e) => {
    if (e.target.value === '$0.00') {
      onChange('');
    }
  };

  const onBlur = (e) => {
    if (!e.target.value) {
      onChange(0);
    }
  };

  useEffect(() => {
    const isCostChanged = formData.labourCost > 0 || formData.materialCost > 0;
    if (!value && isCostChanged) {
      onChange(0);
    }
  }, [formData.labourCost, formData.materialCost]);

  const inputProps = {
    ...props,
    value,
    onBlur,
    onFocus,
    onChange,
    disabled: isEditInvoiceFormDisabled({ formData }),
    ...(isNYStateWithTaxValue
      ? { warning: 'This state should not have Tax' }
      : {}),
  };

  return <MoneyInputAsCents {...inputProps} />;
}

InvoiceTaxField.propTypes = costPropTypes;
