import React from 'react';
import { arrayOf } from 'prop-types';
import styled from 'styled-components';
import { MainHeader, PageHeaderContainer, TextButton } from '@poly/admin-book';
import { EXPORT_XLS_CAPTION, PRINT_PDF_CAPTION } from '@poly/constants';
import { openPrintWindowWithData } from '@poly/client-utils';
import { TableSearchInput } from '@poly/admin-ui';

import {
  SupplierDocumentExceptionsPageTable,
  supplierDocumentExceptionsProjectSupplier,
} from './SupplierDocumentExceptionsPageTable.js';
import { supplierDocumentExceptionsXLS } from './supplierDocumentExceptionsXLS.js';
import { usePrintLayout } from '../../hooks/usePrintLayout.js';

const TextButtonS = styled(TextButton)`
  font-size: 12px;
  display: inline-block;
  margin-right: 40px;
`;

const ExportButtonsWrapper = styled.div`
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 80px;
`;

function ExportToPDFButton({ projectSuppliers }) {
  const Layout = usePrintLayout();

  const onClick = () =>
    openPrintWindowWithData({
      Layout,
      isPrint: true,
      loading: false,
      projectSuppliers,
      allItemsFetched: true,
      fileName: 'supplier_document_exceptions',
      Table: SupplierDocumentExceptionsPageTable,
      metaData: { title: 'Supplier Document Exceptions' },
    });

  return <TextButtonS onClick={onClick}>{PRINT_PDF_CAPTION}</TextButtonS>;
}

ExportToPDFButton.propTypes = {
  projectSuppliers: arrayOf(supplierDocumentExceptionsProjectSupplier),
};

function ExportToExcelButton({ projectSuppliers }) {
  const onClick = () => supplierDocumentExceptionsXLS(projectSuppliers);

  return <TextButtonS onClick={onClick}>{EXPORT_XLS_CAPTION}</TextButtonS>;
}

ExportToExcelButton.propTypes = {
  projectSuppliers: arrayOf(supplierDocumentExceptionsProjectSupplier),
};

export function SupplierDocumentExceptionsPageHeader(props) {
  return (
    <PageHeaderContainer height="100px">
      <MainHeader>Supplier Document Exceptions</MainHeader>
      <ExportButtonsWrapper>
        <ExportToPDFButton {...props} />
        <ExportToExcelButton {...props} />
        <TableSearchInput />
      </ExportButtonsWrapper>
    </PageHeaderContainer>
  );
}
