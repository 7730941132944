import React from 'react';
import styled from 'styled-components';
import { DefaultBodyWrapper, PageHeaderContainer } from '@poly/admin-book';
import { node, string } from 'prop-types';

const DefaultBodyWrapperS = styled(DefaultBodyWrapper)`
  overflow: auto;
  height: ${({ height }) => `calc(100% - ${height})`};
`;

export function DefaultPageLayout({ headerHeight, headerContent, children }) {
  return (
    <>
      <PageHeaderContainer height={headerHeight}>
        {headerContent}
      </PageHeaderContainer>
      <DefaultBodyWrapperS height={headerHeight}>
        {children}
      </DefaultBodyWrapperS>
    </>
  );
}

DefaultPageLayout.propTypes = {
  headerHeight: string,
  headerContent: node,
  children: node,
};

DefaultPageLayout.defaultProps = {
  headerHeight: '90px',
};
