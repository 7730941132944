import React from 'react';
import * as R from 'ramda';
import { Input } from '@poly/admin-book';
import { TAX_SOCIAL_SECURITY_MASK } from '@poly/client-utils';
import { shape, string } from 'prop-types';

const checkIsDisabled = R.compose(R.complement(R.isNil), R.path(['tax', 'id']));

export function SocialSecurityInput({ formData, ...props }) {
  const isDisabled = checkIsDisabled(formData);

  return (
    <Input
      {...props}
      mask="_"
      type="numberMask"
      disabled={isDisabled}
      format={TAX_SOCIAL_SECURITY_MASK}
    />
  );
}

SocialSecurityInput.propTypes = {
  formData: shape({ tax: shape({ id: string }) }),
};
