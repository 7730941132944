import React, { useState } from 'react';
import {
  number,
  string,
  func,
  arrayOf,
  object,
  bool,
  oneOfType,
  shape,
} from 'prop-types';
import { T, F } from 'ramda';

import { Body, LoadingMsg, TableFooterS, TableS } from './styles.js';
import { BodyRow } from './TableBodyRow.js';
import { TableHead } from './TableHead.js';
import { tableHeadersPropTypes } from './propTypes.js';
import { useMoreScroll } from './MoreScrollIcon.js';

const componentPropType = oneOfType([func, object]);

function CollapsibleTableRows({ RowComp, row, isCollapsibleTable, ...props }) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const isRenderChildRows =
    isCollapsibleTable && Array.isArray(row.childrenRows) && isCollapsed;
  return (
    <>
      <RowComp
        row={row}
        {...props}
        toggleCollapse={toggleCollapse}
        isCollapsibleTableRow={!!row.childrenRows}
        isCollapsed={isCollapsed}
        isCollapsibleTable={isCollapsibleTable}
      />
      {isRenderChildRows &&
        row.childrenRows.map((subRow, subIndex) => {
          const subRowKey = `${subRow._id}-${subIndex}`;
          return (
            <RowComp
              {...{
                ...props,
                isCollapsibleTable,
                row: subRow,
                index: subIndex,
              }}
              key={subRowKey}
              childRow
            />
          );
        })}
    </>
  );
}

CollapsibleTableRows.propTypes = {
  RowComp: componentPropType,
  row: shape({}),
  isCollapsibleTable: bool,
};

export function Table({
  id,
  rows,
  headers,
  columns,
  className,
  selectedRows,
  selectedRowsInEnd,
  isRowSelectable,
  showAllSelector,
  isRowDisabled,
  omitColumn,
  isLoading,
  loadingMessage = 'Loading...',
  toggleRow,
  onScroll,
  dataTestId,
  RowComponent,
  tableRowProps,
  footerHeight,
  TableFooter,
  footerProps,
  HeadComponent,
  isPrintPDF,
  enableHoverEffect,
  minHeightLess,
  moreScrollIconPosition,
  showScrollBar,
  overflow,
  isCollapsibleTable,
  ...tableHeadProps
}) {
  const { MoreScrollIcon, onScroll: onMoreScroll } = useMoreScroll(
    moreScrollIconPosition,
  );

  return (
    <>
      <TableS
        id={id}
        className={className}
        dataTestId={dataTestId}
        isPrintPDF={isPrintPDF}
        minHeightLess={minHeightLess}
      >
        <HeadComponent
          headers={headers}
          rows={rows}
          selectedRows={selectedRows}
          showAllSelector={showAllSelector}
          selectedRowsInEnd={selectedRowsInEnd}
          isCollapsibleTable={isCollapsibleTable}
          {...tableHeadProps}
        />
        <Body
          onScroll={(e) => {
            onScroll(e);
            onMoreScroll(e);
          }}
          footerHeight={footerHeight}
          showScrollBar={showScrollBar}
          overflow={overflow}
        >
          {rows.map((row, index) => {
            const RowComp = RowComponent || BodyRow;
            const rowKey = `${row._id}-${index}`;
            return (
              <CollapsibleTableRows
                RowComp={RowComp}
                key={rowKey}
                row={row}
                index={index}
                columns={columns}
                selectedRows={selectedRows}
                toggleRow={toggleRow}
                headers={headers}
                isRowSelectable={isRowSelectable}
                selectedRowsInEnd={selectedRowsInEnd}
                isRowDisabled={isRowDisabled}
                omitColumn={omitColumn}
                enableHoverEffect={enableHoverEffect}
                isCollapsibleTable={isCollapsibleTable}
                {...tableRowProps}
              />
            );
          })}
        </Body>
        <TableFooterS>
          {TableFooter && <TableFooter rows={rows} {...footerProps} />}
        </TableFooterS>
      </TableS>
      {isLoading ? <LoadingMsg>{loadingMessage}</LoadingMsg> : null}
      {moreScrollIconPosition ? <MoreScrollIcon /> : null}
    </>
  );
}

Table.defaultProps = {
  id: 'table',
  rows: [],
  onScroll: T,
  toggleRow: T,
  isRowSelectable: T,
  isRowDisabled: F,
  omitColumn: undefined,
  onHeaderCellClick: T,
  className: '',
  selectedRows: undefined,
  isLoading: false,
  selectedRowsInEnd: false,
  RowComponent: null,
  HeadComponent: TableHead,
  dataTestId: 'table',
  footerHeight: 0,
  showAllSelector: true,
};

Table.propTypes = {
  RowComponent: componentPropType,
  rows: arrayOf(object.isRequired),
  headers: tableHeadersPropTypes.isRequired,
  columns: arrayOf(oneOfType([func, object]).isRequired).isRequired,
  className: string,
  onHeaderCellClick: func,
  selectedRowsInEnd: bool,
  selectedRows: arrayOf(oneOfType([string, number])),
  isLoading: bool,
  loadingMessage: string,
  isRowSelectable: func,
  isRowDisabled: func,
  omitColumn: number,
  toggleRow: func,
  onScroll: func,
  TableFooter: componentPropType,
  HeadComponent: componentPropType,
  footerHeight: number,
  /* eslint-disable react/forbid-prop-types */
  footerProps: object,
  tableRowProps: object,
  /* eslint-enable react/forbid-prop-types */
  dataTestId: string,
  isPrintPDF: bool, // disables styles not intended for printing
  showAllSelector: bool,
  enableHoverEffect: bool,
  minHeightLess: bool,
  moreScrollIconPosition: string,
  id: string,
  showScrollBar: bool,
  overflow: string,
  isCollapsibleTable: bool,
};
