import { instanceOf, oneOfType, shape, string } from 'prop-types';

export const confirmSatisfactionProjectPropTypes = shape({
  _id: string,
  type: string,
  projectId: string,
  description: string,
  workCompletionDate: oneOfType([string, instanceOf(Date)]),
  contact: shape({
    _id: string,
    profile: shape({ fullName: string }),
  }),
});
