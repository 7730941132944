import React from 'react';
import * as R from 'ramda';
import { arrayOf, func, shape, string } from 'prop-types';
import { Select } from '@poly/admin-book';

// removeSelectedHierarchies :: ([HierarchyOption], [ID]) -> [HierarchyOption]
// HierarchyOption = {
//  label: String,
//  value: ID
// }
const removeSelectedHierarchies = (clientHierarchies, selectedIds) =>
  R.reject(
    R.compose(R.includes(R.__, selectedIds), R.prop('value')),
    clientHierarchies,
  );

export function HierarchySelector({
  selectedIds,
  onHierarchyChange,
  clientHierarchies,
  ...props
}) {
  const selectProps = {
    ...props,
    onChange: onHierarchyChange,
    options: removeSelectedHierarchies(clientHierarchies, selectedIds),
  };

  return <Select {...selectProps} />;
}

HierarchySelector.propTypes = {
  clientHierarchies: arrayOf(
    shape({
      label: string,
      value: string,
    }),
  ),
  onHierarchyChange: func.isRequired,
  selectedIds: arrayOf(string),
};
