import * as R from 'ramda';
import styled from 'styled-components';
import React, { Fragment } from 'react';
import { arrayOf, shape, string, oneOfType } from 'prop-types';

const SentEmailsPreviewWrapper = styled.div``;
const SentEmailLinkS = styled.a.attrs(() => ({
  tabIndex: 0,
  role: 'button',
}))``;

function SentEmailLink({ html, to }) {
  const onClick = (event) => {
    event.preventDefault();
    const newWindow = window.open();
    const newDocument = newWindow.document;

    newDocument.write(html);
    newDocument.title = 'Email Preview';

    newWindow.stop();
  };

  return <SentEmailLinkS onClick={onClick}>{to}</SentEmailLinkS>;
}

SentEmailLink.displayName = 'SentEmailLink';
SentEmailLink.propTypes = { html: string.isRequired, to: string.isRequired };

function EmailFileLink({ url, to }) {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {to}
    </a>
  );
}

EmailFileLink.displayName = 'EmailFileLink';
EmailFileLink.propTypes = {
  to: string.isRequired,
  url: string.isRequired,
};

export const getEmailsSeparator = (length, i) =>
  length > 1 && i !== length - 1 ? ', ' : '';

// prepareEmailFiles :: [File] -> [File]
const prepareEmailFiles = R.compose(
  R.flatten,
  R.map(
    R.ifElse(
      R.pathSatisfies(R.is(Array), ['emailParams', 'to']),
      R.converge(R.map, [
        (file) => (to) => ({ to, ...file }),
        R.path(['emailParams', 'to']),
      ]),
      R.converge(R.mergeLeft, [
        R.compose(R.objOf('to'), R.path(['emailParams', 'to'])),
        R.identity,
      ]),
    ),
  ),
  R.defaultTo([]),
);

export function UpdateSentEmailsPreview({ emailFiles, className }) {
  const emailsArray = prepareEmailFiles(emailFiles);
  const hasNoEmails = R.isEmpty(emailsArray);

  if (hasNoEmails) return null;

  return (
    <SentEmailsPreviewWrapper className={className}>
      Preview&nbsp;Emails:&nbsp;
      {emailsArray.map((email, i) => (
        <Fragment key={email.to}>
          {email.html ? (
            <SentEmailLink {...email} />
          ) : (
            <EmailFileLink {...email} />
          )}
          {getEmailsSeparator(emailsArray.length, i)}
        </Fragment>
      ))}
    </SentEmailsPreviewWrapper>
  );
}

UpdateSentEmailsPreview.propTypes = {
  className: string,
  emailFiles: arrayOf(
    shape({
      url: string.isRequired,
      emailParams: shape({
        to: oneOfType([string, arrayOf(string)]).isRequired,
      }).isRequired,
    }),
  ),
};
