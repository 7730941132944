import { shape, string } from 'prop-types';

export const assetPropTypes = shape({
  description: string,
  photo: shape({
    url: string.isRequired,
    fileName: string.isRequired,
  }),
  manufacturerDoc: shape({
    name: string.isRequired,
  }),
  modelDoc: shape({
    name: string.isRequired,
  }),
  serial: string,
  type: shape({
    name: string.isRequired,
  }),
  location: string,
  startUp: string,
  equipmentType: string,
});
