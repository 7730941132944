import {
  subDays,
  addDays,
  endOfWeek,
  endOfYear,
  endOfMonth,
  endOfToday,
  startOfWeek,
  startOfYear,
  startOfMonth,
  startOfToday,
} from 'date-fns';
import { alwaysNewDate } from '@poly/utils';

export const progressiveRangePickerOptions = {
  NO_RANGE: 'all',
  TODAY: 'today',
  LAST_24_HOURS: 'last_24_hours',
  THIS_WEEK: 'this_week',
  NEXT_WEEK: 'next_week',
  LAST_7_DAYS: 'last_7_days',
  THIS_MONTH: 'this_month',
  LAST_30_DAYS: 'last_30_days',
  LAST_90_DAYS: 'last_90_days',
  THIS_YEAR: 'this_year',
  CUSTOM: 'custom_range',
};

export const progressiveOptionsToDates = {
  [progressiveRangePickerOptions.NO_RANGE]: {
    startDate: undefined,
    endDate: undefined,
  },
  [progressiveRangePickerOptions.TODAY]: {
    startDate: startOfToday(),
    endDate: endOfToday(),
  },
  [progressiveRangePickerOptions.LAST_24_HOURS]: {
    startDate: subDays(alwaysNewDate(), 1),
    endDate: alwaysNewDate(),
  },
  [progressiveRangePickerOptions.THIS_WEEK]: {
    startDate: startOfWeek(alwaysNewDate()),
    endDate: endOfWeek(alwaysNewDate()),
  },
  [progressiveRangePickerOptions.NEXT_WEEK]: {
    startDate: startOfWeek(addDays(alwaysNewDate(), 7)),
    endDate: endOfWeek(addDays(alwaysNewDate(), 7)),
  },
  [progressiveRangePickerOptions.LAST_7_DAYS]: {
    startDate: subDays(alwaysNewDate(), 7),
    endDate: alwaysNewDate(),
  },
  [progressiveRangePickerOptions.THIS_MONTH]: {
    startDate: startOfMonth(alwaysNewDate()),
    endDate: endOfMonth(alwaysNewDate()),
  },
  [progressiveRangePickerOptions.LAST_30_DAYS]: {
    startDate: subDays(alwaysNewDate(), 30),
    endDate: alwaysNewDate(),
  },
  [progressiveRangePickerOptions.LAST_90_DAYS]: {
    startDate: subDays(alwaysNewDate(), 90),
    endDate: alwaysNewDate(),
  },
  [progressiveRangePickerOptions.THIS_YEAR]: {
    startDate: startOfYear(alwaysNewDate()),
    endDate: endOfYear(alwaysNewDate()),
  },
};

export const customRangeModalId = 'customRangeModalId';

export const customModalTypes = {
  SINGLE_DAY: 'single_day',
  DATE_RANGE: 'date_range',
};
