import React from 'react';
import * as R from 'ramda';
import {
  formatDate,
  formatSocialSecurity,
  formatTaxId,
  forceTitleCase,
  propEqLegacy,
} from '@poly/utils';
import { NOTHING_UI_STRING, AdminCheckTypes } from '@poly/constants';
import { applyPathOrNothingUI } from '@poly/client-utils';

import { SupplierCheckAmountTableCell } from '../PrintChecksDetailsPage/SupplierCheckAmountTableCell.js';

const getSupplierTaxIDOrSocialNumber = R.compose(
  R.defaultTo(NOTHING_UI_STRING),
  R.head,
  R.reject(R.isEmpty),
  R.juxt([
    // tax ID and socialSecurityNumber order is important
    R.compose(formatTaxId, R.pathOr('', ['tax', 'id'])),
    R.compose(
      formatSocialSecurity,
      R.pathOr('', ['tax', 'socialSecurityNumber']),
    ),
  ]),
);

// isRemoteCheck :: AdminCheck -> Boolean
const isRemoteCheck = propEqLegacy('type', AdminCheckTypes.remote);

// getRemoteCheckSuppliersLength :: AdminCheck -> Int
const getRemoteCheckSuppliersLength = R.compose(
  R.length,
  R.pathOr([], ['payload', 'remoteCheckSuppliers']),
);

// isRemoteWithSingleSupplier :: AdminCheck -> Boolean
const isRemoteWithSingleSupplier = R.both(
  isRemoteCheck,
  R.compose(R.equals(1), getRemoteCheckSuppliersLength),
);

// isRemoteWithManySuppliers :: AdminCheck -> Boolean
const isRemoteWithManySuppliers = R.both(
  isRemoteCheck,
  R.compose(R.gt(R.__, 1), getRemoteCheckSuppliersLength),
);

// getFieldValueByCheckType :: (Supplier -> String) -> AdminCheck -> String
const getFieldValueByCheckType = (getSupplierValue) =>
  R.compose(
    R.cond([
      [
        isRemoteWithSingleSupplier,
        R.compose(
          getSupplierValue,
          R.path(['payload', 'remoteCheckSuppliers', '0']),
        ),
      ],
      [isRemoteWithManySuppliers, R.always(NOTHING_UI_STRING)],
      // condition for manual check
      [R.T, R.compose(getSupplierValue, R.prop('supplier'))],
    ]),
  );

export const printChecksTableConfig = [
  ['Payment Date', applyPathOrNothingUI(['paymentDate'], formatDate)],
  ['Supplier Name', getFieldValueByCheckType(R.path(['company', 'name']))],
  [
    'Supplier Status',
    getFieldValueByCheckType(R.compose(forceTitleCase, R.prop('status'))),
  ],
  [
    'Supplier Tax ID #',
    getFieldValueByCheckType(getSupplierTaxIDOrSocialNumber),
  ],
  ['Bank', R.prop('bankAccountName')],
  ['Check Amount', (check) => <SupplierCheckAmountTableCell {...check} />],
  ['Check Number', R.prop('checkNumber')],
];
