import * as R from 'ramda';
import { string, shape } from 'prop-types';
import React, { useEffect } from 'react';
import { useOutSidebarContext } from '@poly/client-utils';
import { SidebarWrapper } from '@poly/admin-ui';
import { Loader } from '@poly/admin-book';

import { propertyTabsSidebarId } from './constants.js';
import {
  checkPropertyLocationHierarchyEnabled,
  PropertySidebarHeader,
} from './PropertySidebarHeader.js';
import { NotFoundEntity } from '../../components/NotFoundEntity.js';
import { PropertySidebarRemarks } from './PropertySidebarRemarks.js';
import { PropertyFinancialTabs } from './PropertySidebarFinancial.js';
import { PropertySidebarAlarmInfo } from './PropertySidebarAlarmInfo.js';
import { PropertySidebarGeneralInfo } from './PropertySidebarGeneralInfo.js';
import { PropertySidebarBuildingInfo } from './PropertySidebarBuildingInfo.js';
import { PropertySidebarHierarchy } from './PropertySidebarHierarchy.js';
import { useReactiveSidebarDocumentTitle } from '../useReactiveSidebarDocumentTitle.js';
import { useOpenPropertyTabsSidebar } from './tabs/useOpenPropertyTabsSidebar.js';
import { usePropertySidebarData } from './usePropertySidebarData.js';
import { isPropertyFieldActive } from './propertySidebarUtils.js';
import { PropertySidebarSupplierWOInstructions } from './PropertySidebarSupplierWOInstructions.js';

// isPropertyFieldActiveByProps :: String -> PropertyDetailsResult -> Boolean
const isPropertyFieldActiveByProps = (field) =>
  R.compose(isPropertyFieldActive([field]), R.path(['property', 'client']));

function PropertySidebarRouterLogic({ property }) {
  const { isSidebarOpened } = useOutSidebarContext();
  const openPropertyTabsSidebar = useOpenPropertyTabsSidebar();

  useEffect(() => {
    if (!isSidebarOpened(propertyTabsSidebarId)) {
      openPropertyTabsSidebar({
        property,
        isPropertyLocationHierarchyEnabled:
          checkPropertyLocationHierarchyEnabled(property),
      });
    }
  }, []);

  useReactiveSidebarDocumentTitle(property.name);

  return null;
}

PropertySidebarRouterLogic.propTypes = {
  property: shape({ name: string.isRequired }).isRequired,
};

export function PropertySidebarSections(props) {
  return (
    <>
      <PropertySidebarHeader {...props} />
      <PropertySidebarGeneralInfo {...props} />
      <PropertySidebarHierarchy {...props} />
      {isPropertyFieldActiveByProps('buildingInfo')(props) && (
        <PropertySidebarBuildingInfo {...props} />
      )}
      {isPropertyFieldActiveByProps('alarmInfo')(props) && (
        <PropertySidebarAlarmInfo {...props} />
      )}
      <PropertySidebarRemarks {...props} />
      <PropertySidebarSupplierWOInstructions {...props} />
      <PropertyFinancialTabs {...props} />
    </>
  );
}

export function PropertySidebar({ propertyId }) {
  const { loading, ...sidebarProps } = usePropertySidebarData(propertyId);

  if (loading) {
    return <Loader />;
  }

  return (
    <SidebarWrapper>
      {!sidebarProps.property ? (
        <NotFoundEntity entityName="property" />
      ) : (
        <>
          <PropertySidebarSections {...sidebarProps} />
          <PropertySidebarRouterLogic {...sidebarProps} />
        </>
      )}
    </SidebarWrapper>
  );
}

PropertySidebar.displayName = 'PropertySidebar';
PropertySidebar.propTypes = { propertyId: string.isRequired };
