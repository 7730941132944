import React, { useEffect } from 'react';
import { bool, func, number, oneOfType, shape, string } from 'prop-types';
import { getThemeColor } from '@poly/admin-book';
import styled, { css } from 'styled-components';
import { MoneyInput, MoneyInputAsCents } from '@poly/admin-ui';

const disabledMoneyInputStyles = css`
  input {
    font-size: 12px;
    color: ${({ value, ...props }) =>
      value < 0
        ? getThemeColor(['secondaryMid'])(props)
        : getThemeColor(['midDark'])(props)};
    background: ${getThemeColor(['midLight'])};
  }
`;

const DisabledMoneyInput = styled(MoneyInput)`
  ${disabledMoneyInputStyles}
`;

export const DisabledMoneyInputAsCents = styled(MoneyInputAsCents)`
  ${disabledMoneyInputStyles}
`;

export function BalanceInput({
  currentBalance,
  deductionsAmount = 0,
  paidAmount = 0,
  changeBalance,
  balanceAmount,
  error,
  asCents,
}) {
  useEffect(() => {
    const newBalance = currentBalance - deductionsAmount - paidAmount;
    if (newBalance !== balanceAmount) {
      changeBalance(newBalance);
    }
  }, [paidAmount, deductionsAmount]);

  const DisabledMoneyInputComp = asCents
    ? DisabledMoneyInputAsCents
    : DisabledMoneyInput;

  return (
    <DisabledMoneyInputComp
      width="100%"
      value={balanceAmount}
      error={error?.balanceAmount}
      dataTestId="balance-amount-input"
      name="invoice-balance-input"
      disabled
      allowNegative
    />
  );
}

BalanceInput.propTypes = {
  balanceAmount: number.isRequired,
  error: shape({ balanceAmount: string }),
  currentBalance: number.isRequired,
  deductionsAmount: oneOfType([number, string]),
  paidAmount: oneOfType([number, string]),
  changeBalance: func.isRequired,
  asCents: bool,
};
