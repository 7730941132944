import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { bool, shape, string } from 'prop-types';
import { SectionWithCard, WhiteCard } from '@poly/admin-book';

import { BooleanTextWithDot } from '../../../modules/tabs/supplierAccountTab/supplierAccountUtils.js';
import { TwoFASettings } from './TwoFASettings.js';

const EntitiesSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  > div {
    width: 100%;
  }
`;

const WhiteCardS = styled(WhiteCard)`
  align-items: flex-start;
`;

const SectionWithCardS = styled(SectionWithCard)`
  width: 33% !important;
`;

export function UserSettingsList({
  notificationSettings,
  twoFASettings,
  userId,
  isTwoFactorAuthRequired,
  linksSettings,
}) {
  const updateCommentEmail = R.pathOr(
    false,
    ['notifications', 'updateCommentEmail'],
    notificationSettings,
  );

  return (
    <WhiteCardS data-testid="user-settings-list">
      <EntitiesSection>
        <SectionWithCardS
          title="Notifications"
          list={[
            {
              title: 'Get Updates via Email',
              elem: (
                <BooleanTextWithDot
                  isTrue={updateCommentEmail}
                  dataTestId="updates-via-email"
                />
              ),
            },
          ]}
        />
        <SectionWithCardS
          title="Links"
          list={[
            {
              title: 'Open Project in New Tab',
              elem: (
                <BooleanTextWithDot
                  isTrue={linksSettings?.openProjectInNewTab}
                  dataTestId="project-in-new-tab"
                />
              ),
            },
          ]}
        />

        <SectionWithCardS
          title="2 Factor Authentications"
          list={[
            {
              elem: (
                <TwoFASettings
                  twoFASettings={twoFASettings}
                  userId={userId}
                  isTwoFactorAuthRequired={isTwoFactorAuthRequired}
                />
              ),
            },
          ]}
        />
      </EntitiesSection>
    </WhiteCardS>
  );
}

UserSettingsList.propTypes = {
  notificationSettings: shape({
    notifications: shape({ updateCommentEmail: bool }),
  }),
  twoFASettings: shape({
    createdAt: string,
    isVerified: bool.isRequired,
  }),
  userId: string.isRequired,
  isTwoFactorAuthRequired: bool.isRequired,
  linksSettings: shape({ openProjectInNewTab: bool }),
};
