import * as R from 'ramda';
import styled from 'styled-components';
import React, { useContext } from 'react';
import { arrayOf, shape, string, func, bool } from 'prop-types';
import { useNavigate, useLocation } from '@poly/client-routing';
import { insertQueryParamsIntoURL, propEqLegacy } from '@poly/utils';
import {
  extractTablePropsFromConfig,
  useSingleSelectableTable,
} from '@poly/admin-ui';
import {
  DefaultBodyWrapper,
  moneyColumnStyles,
  ContentWrapper,
  WindowedTable,
  BodyRow,
  Button,
  Loader,
  defaultTheme,
  Text,
} from '@poly/admin-book';

import {
  BottomPanelBody,
  BottomPanelContainer,
} from '../../components/BottomPanel.js';
import { routesNames } from '../../routes/index.js';
import { PageWithSearchHeaderContext } from '../../components/PageWithSearchHeader.js';
import { createClientInvoiceTableConfig } from './createClientInvoiceTableConfig.js';
import { SuggestedInvoiceAmountContext } from './useSuggestedInvoiceAmountLogic.js';

function POMissingComponent() {
  return <Text>Missing PO#</Text>;
}

const tableProps = extractTablePropsFromConfig(createClientInvoiceTableConfig);

const SelectButtonS = styled(Button)`
  width: 100px;
  align-self: flex-end;
`;

const ProjectsTableS = styled(WindowedTable)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 135px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 100px;
  }

  th:nth-child(7),
  td:nth-child(7) {
    width: 170px;
  }

  td:nth-child(5),
  td:nth-child(6),
  td:nth-child(7),
  td:nth-child(8) {
    vertical-align: middle;
  }

  ${moneyColumnStyles(5)};
  ${moneyColumnStyles(6)};
  th:nth-child(8),
  td:nth-child(8) {
    width: 90px;
  }

  tr {
    width: 100%;
    margin: 0;
  }
`;

const PageBodyWrapperS = styled(DefaultBodyWrapper)`
  flex-direction: column;
  align-items: center;
`;

const PageContentS = styled(ContentWrapper)`
  width: calc(100% - 80px);
  height: calc(100% - 65px);
  max-width: 1424px;
  justify-content: center;
  padding: 0;
  margin: 0 30px 0 50px;
`;

// getSuggestedAmount :: ID -> [AmountObj] -> Float
const getSuggestedAmount = (id) =>
  R.compose(
    R.propOr(0, 'amount'),
    R.find(propEqLegacy('_id', id)),
    R.defaultTo([]),
  );

// isClientReferenceNumber :: Project -> Boolean
const isClientReferenceNumber = R.ifElse(
  R.path(['client', 'configs', 'clientInvoicing', 'email', 'enabled']),
  R.either(
    R.prop('clientReferenceNumber'),
    R.path(['parent', 'clientReferenceNumber']),
  ),
  R.T,
);

// getRowColorStyle :: Row -> String
const getRowColorStyle = R.compose(
  R.ifElse(
    isClientReferenceNumber,
    R.always('black'),
    R.always(defaultTheme.colors.accent),
  ),
  R.prop('row'),
);

const BodyRowS = styled(BodyRow)`
  color: ${getRowColorStyle};
`;

export function CreateClientInvoiceSearchBody({
  projects,
  tableProps: scrollTableProps,
  loading,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const { contentHeight } = useContext(PageWithSearchHeaderContext);
  const { toggleRow, selectedRows } = useSingleSelectableTable();
  const { amounts } = useContext(SuggestedInvoiceAmountContext);

  const onSelectClick = () => {
    const selectedProjectId = selectedRows[0];
    const suggestedAmount = getSuggestedAmount(selectedProjectId)(amounts);
    if (selectedProjectId) {
      navigate(
        insertQueryParamsIntoURL(
          { projectId: selectedProjectId, suggestedAmount },
          routesNames.CREATE_CLIENT_INVOICE,
        ),
        { state: { previous: location } },
      );
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <PageBodyWrapperS height={contentHeight}>
      <PageContentS>
        <ProjectsTableS
          {...tableProps}
          {...scrollTableProps}
          rows={projects}
          selectedRows={selectedRows}
          toggleRow={toggleRow}
          selectedRowsInEnd
          showAllSelector={false}
          isRowSelectable={isClientReferenceNumber}
          RowComponent={BodyRowS}
          NotSelectableCheckboxComponent={POMissingComponent}
          showScrollBar
        />
      </PageContentS>
      <BottomPanelContainer height="65px">
        <BottomPanelBody>
          <SelectButtonS
            disabled={selectedRows.length === 0}
            onClick={onSelectClick}
          >
            Select
          </SelectButtonS>
        </BottomPanelBody>
      </BottomPanelContainer>
    </PageBodyWrapperS>
  );
}

CreateClientInvoiceSearchBody.propTypes = {
  projects: arrayOf(
    shape({
      _id: string.isRequired,
      projectId: string.isRequired,
      description: string.isRequired,
    }),
  ),
  tableProps: shape({
    onScroll: func.isRequired,
  }),
  loading: bool,
};
