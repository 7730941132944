import * as R from 'ramda';
import styled from 'styled-components';
import React, { useEffect } from 'react';
import { func, string, shape, bool } from 'prop-types';
import { useRouterQuery } from '@poly/client-routing';
import { SupplierSelect, ALL } from '@poly/admin-ui';
import {
  HeaderLinksWrapper,
  getThemeColor,
  DatePicker,
  Checkbox,
} from '@poly/admin-book';

import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import { invoicesPropTypes } from './prop-types.js';
import { FlexCenterAlign } from '../../components/FlexContainer.js';
import { APAgingReportPrintBtn } from './APAgingReportPrintPDFBtn.js';
import { CountrySelect } from '../../modules/selects/CountrySelect.js';
import { APAgingReportExportXLBtn } from './APAgingReportExportXLBtn.js';
import { ClientInvoiceStatusSelect } from './ClientInvoiceStatusSelect.js';
import { FiltersContainer } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { generateSearchAgingReportQueryByKeyProp } from './generateSearchAgingReportQueryByKeyProp.js';

const HeaderFilterRowS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${R.propOr('flex-start', 'position')};
  width: 100%;
`;

const HeaderLinksWrapperS = styled(HeaderLinksWrapper)`
  button {
    margin-left: 5px;
  }
  justify-content: flex-end;
  margin-right: 0;
`;

const CheckboxS = styled(Checkbox)`
  label {
    font-size: 14px;
    font-weight: 700;
    color: ${getThemeColor(['primaryLighter4'])};
  }
`;

const ButtonsSearchHeaderColumn = styled(SearchHeaderColumn)`
  width: 420px;
  div:first-child {
    width: 100%;
  }

  div:first-child {
    width: 100%;
  }
`;

const SearchHeaderColumnS = styled(SearchHeaderColumn)`
  label {
    @media (max-width: 1410px) {
      max-width: 100px;
    }
  }
`;

// apAgingReportFilters :: APAgingReportFilters
export const apAgingReportFilters = {
  country: 'country',
  branchId: 'branchId',
  supplierId: 'supplierId',
  invoiceEndDate: 'invoiceEndDate',
  invoiceStartDate: 'invoiceStartDate',
  clientInvoiceStatus: 'clientInvoiceStatus',
  excludeReadyToInvoice: 'excludeReadyToInvoice',
  invoicePaymentDueDate: 'invoicePaymentDueDate',
};

export const commonAgingReportConfig = [
  { name: apAgingReportFilters.branchId },
  { name: apAgingReportFilters.country },
];

const apAgingReportHeaderConfig = [
  { name: apAgingReportFilters.supplierId },
  { name: apAgingReportFilters.invoiceEndDate },
  { name: apAgingReportFilters.invoiceStartDate },
  { name: apAgingReportFilters.invoicePaymentDueDate },
  { name: apAgingReportFilters.clientInvoiceStatus, defaultValue: ALL },
  { name: apAgingReportFilters.excludeReadyToInvoice, defaultValue: false },
  ...commonAgingReportConfig,
];

export function APAgingReportHeader({ title, setQuery, exportProps, loading }) {
  const { supplierId, country } = useRouterQuery(['supplierId', 'country']);
  const { searchFilters, handlers, onReset } = useSearchFilters(
    apAgingReportHeaderConfig,
  );

  const onSearch = () => {
    if (!loading) {
      setQuery(
        generateSearchAgingReportQueryByKeyProp('supplierId')(searchFilters),
      );
    }
  };

  const onResetSearch = () => {
    onReset();
    setQuery(null);
  };

  useEffect(() => {
    if (!!supplierId || !!country) {
      onSearch();
    }
  }, []);

  return (
    <SearchPageHeaderContainer title={title}>
      <FiltersContainer>
        <HeaderFilterRowS>
          <SearchHeaderColumn
            title="Supplier Account"
            titleWidth="130px"
            filterWidth="250px"
          >
            <SupplierSelect
              width="100%"
              isClearable
              withoutError
              name="supplierId"
              includeAllOption
              value={searchFilters.supplierId}
              onChange={handlers.supplierId}
            />
          </SearchHeaderColumn>
          <SearchHeaderColumnS
            title="Invoice Due Date"
            titleWidth="150px"
            filterWidth="150px"
          >
            <DatePicker
              showResetBtn
              width="150px"
              value={searchFilters.invoicePaymentDueDate}
              onChange={handlers.invoicePaymentDueDate}
            />
          </SearchHeaderColumnS>
          <SearchHeaderColumnS
            title="Invoice Date Start"
            titleWidth="150px"
            filterWidth="150px"
          >
            <DatePicker
              showResetBtn
              width="150px"
              value={searchFilters.invoiceStartDate}
              onChange={handlers.invoiceStartDate}
            />
          </SearchHeaderColumnS>
          <SearchHeaderColumnS
            title="Invoice Date End"
            titleWidth="150px"
            filterWidth="150px"
          >
            <DatePicker
              showResetBtn
              width="150px"
              value={searchFilters.invoiceEndDate}
              onChange={handlers.invoiceEndDate}
            />
          </SearchHeaderColumnS>
        </HeaderFilterRowS>
        <HeaderFilterRowS>
          <SearchHeaderColumn
            position=""
            title="Supplier Branch"
            titleWidth="130px"
            filterWidth="250px"
          >
            <SupplierSelect
              disabled
              isClearable
              width="100%"
              name="branchId"
              includeAllOption
              onChange={handlers.branchId}
              value={searchFilters.branchId}
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn
            title="Client Invoice Status"
            titleWidth="150px"
            filterWidth="150px"
          >
            <ClientInvoiceStatusSelect
              value={searchFilters.clientInvoiceStatus}
              onChange={handlers.clientInvoiceStatus}
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn
            title="Country"
            titleWidth="150px"
            filterWidth="150px"
          >
            <CountrySelect
              value={searchFilters.country}
              onChange={handlers.country}
            />
          </SearchHeaderColumn>
          <ButtonsSearchHeaderColumn position="flex-end">
            <HeaderLinksWrapperS>
              <APAgingReportPrintBtn {...exportProps} title={title} />
              <APAgingReportExportXLBtn {...exportProps} title={title} />
            </HeaderLinksWrapperS>
          </ButtonsSearchHeaderColumn>
        </HeaderFilterRowS>
        <HeaderFilterRowS position="flex-end">
          <SearchHeaderColumn
            titleWidth="0px"
            filterWidth="400px"
            position="flex-end"
          >
            <CheckboxS
              position="right"
              name="excludeReadyToInvoice"
              label="Exclude Ready to Invoice"
              onChange={handlers.excludeReadyToInvoice}
              value={searchFilters.excludeReadyToInvoice}
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn
            titleWidth="0px"
            filterWidth="200px"
            position="flex-end"
          >
            <FlexCenterAlign>
              <SearchHeaderButton
                size="small"
                styleType="basicPrimary"
                onClick={onResetSearch}
              >
                Reset
              </SearchHeaderButton>
              <SearchHeaderButton
                size="small"
                onClick={onSearch}
                loader={loading}
              >
                Search
              </SearchHeaderButton>
            </FlexCenterAlign>
          </SearchHeaderColumn>
        </HeaderFilterRowS>
      </FiltersContainer>
    </SearchPageHeaderContainer>
  );
}

APAgingReportHeader.propTypes = {
  setQuery: func.isRequired,
  title: string.isRequired,
  exportProps: shape({ report: invoicesPropTypes }),
  loading: bool,
};
