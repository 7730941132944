import React from 'react';
import { string, bool, func } from 'prop-types';

import { fileErrorPropTypes, UploadFileLogic } from './UploadFileLogic.js';
import { FileList } from './fileList.js';
import { DragArea } from './dragArea.js';
import { attachmentsPropTypes } from './commonPropTypes.js';

function FilePickerComp({ readOnly, ...props }) {
  return (
    <div>
      {!readOnly && <DragArea {...props} />}
      <FileList {...props} readOnly={readOnly} />
    </div>
  );
}

FilePickerComp.displayName = 'FilePicker';
FilePickerComp.propTypes = {
  isDragOver: bool.isRequired,
  onDragLeave: func.isRequired,
  onDragEnter: func.isRequired,
  onFileUpload: func.isRequired,
  onDrop: func.isRequired,
  onFileRemove: func.isRequired,
  onFileNameChange: func.isRequired,
  attachments: attachmentsPropTypes,
  name: string.isRequired,
  hasError: bool,
  label: string,
  error: fileErrorPropTypes,
  readOnly: bool,
  onDropRejected: func.isRequired,
};
FilePickerComp.defaultProps = {
  attachments: [],
  hasError: false,
  error: [],
  label: '',
};

export function FilePicker({ value, onChange, onBlur, ...props }) {
  const setNewAttachments = (data) => {
    onChange(data);
    if (onBlur) {
      // fixes validation bug when validateOnBlur enabled
      onBlur({});
    }
  };

  return (
    <UploadFileLogic
      setNewAttachments={setNewAttachments}
      attachments={value || []}
      Component={FilePickerComp}
      {...props}
    />
  );
}

FilePicker.propTypes = {
  value: attachmentsPropTypes,
  onChange: func.isRequired,
  onBlur: func,
};
