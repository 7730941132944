import React, { useEffect, useState } from 'react';
import { number, string, func, object, bool, shape } from 'prop-types';
import { T } from 'ramda';
import VirtualizedAutoSizer from 'react-virtualized-auto-sizer';
import ReactWindowInfiniteLoader from 'react-window-infinite-loader';
import styled from 'styled-components';

import { LoadingMsg, hideScrollBarCss } from './styles.js';
import { BodyRow } from './TableBodyRow.js';
import { TableHead } from './TableHead.js';
import { defaultTableRowPropTypes, tableRowPropTypes } from './propTypes.js';
import { VirtualTable } from './VirtualTable.js';
import { Table } from './Table.js';
import { getThemeColor } from '../utils.js';
import { MoreScrollIcon } from './MoreScrollIcon.js';

const WindowedTableWrapper = styled.div`
  height: 100%;
  width: 100%;

  .auto-sizer > div {
    ${({ showScrollBar }) => (showScrollBar ? '' : hideScrollBarCss)};

    thead {
      // fixes table header bug at Firefox
      overflow-y: unset;
    }
  }

  tbody {
    // fixes table header bug at Firefox
    overflow: auto;
  }
`;

const TableHeadS = styled(TableHead)`
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: ${getThemeColor(['white'])};
`;

function TableRow({
  style,
  index,
  columns,
  rows,
  selectedRows,
  toggleRow,
  headers,
  isRowSelectable,
  selectedRowsInEnd,
  isRowDisabled,
  omitColumn,
  itemSize,
  NotSelectableCheckboxComponent,
  RowComponent,
  enableHoverEffect,
  setHid,
  ...tableRowProps
}) {
  const row = rows[index];

  useEffect(() => {
    setHid(index >= rows.length - 1);
  }, []);

  if (!row) {
    // do not render last "loading" row
    return null;
  }

  const RowComp = RowComponent || BodyRow;

  return (
    <RowComp
      row={row}
      // compensate header height
      style={{ ...style, top: style.top + itemSize }}
      index={index}
      // eslint-disable-next-line react/no-array-index-key
      key={`${row._id}-${index}`}
      columns={columns}
      selectedRows={selectedRows}
      toggleRow={toggleRow}
      headers={headers}
      isRowSelectable={isRowSelectable}
      selectedRowsInEnd={selectedRowsInEnd}
      isRowDisabled={isRowDisabled}
      omitColumn={omitColumn}
      NotSelectableCheckboxComponent={NotSelectableCheckboxComponent}
      enableHoverEffect={enableHoverEffect}
      {...tableRowProps}
    />
  );
}

TableRow.defaultProps = {
  style: {},
  ...defaultTableRowPropTypes,
};

TableRow.propTypes = {
  style: shape({
    top: number,
  }),
  index: number.isRequired,
  setHid: func.isRequired,
  ...tableRowPropTypes,
};

function WindowedTableComp({
  rows,
  headers,
  columns,
  className,
  selectedRows,
  selectedRowsInEnd,
  isRowSelectable,
  isRowDisabled,
  omitColumn,
  isLoading,
  loadingMessage,
  toggleRow,
  dataTestId,
  RowComponent,
  tableRowProps,
  itemSize,
  loadMoreItems,
  itemCount = rows.length,
  TableFooter,
  footerHeight = 0,
  footerProps,
  threshold = 15,
  NotSelectableCheckboxComponent,
  enableHoverEffect,
  moreScrollPosition,
  showScrollBar = false,
  ...tableHeadProps
}) {
  useEffect(() => {
    if (TableFooter && !footerHeight) {
      // eslint-disable-next-line no-console
      console.error(
        'WindowedTable: provide `footerHeight` to display footer properly',
      );
    }
  }, []);

  const [hid, setHid] = useState(!rows.length);

  const isItemLoaded = (idx) => idx < rows.length;

  // ReactWindowInfiniteLoader interface is a bit stupid
  // so we need extra item at the end of list if there are more items to load
  const emulatedItemCount =
    itemCount > rows.length ? rows.length + 1 : rows.length;

  const rowProps = {
    rows,
    columns,
    selectedRows,
    toggleRow,
    headers,
    isRowSelectable,
    selectedRowsInEnd,
    isRowDisabled,
    omitColumn,
    itemSize,
    NotSelectableCheckboxComponent,
    RowComponent,
    enableHoverEffect,
    setHid,
    ...tableRowProps,
  };
  return (
    <WindowedTableWrapper className={className} showScrollBar={showScrollBar}>
      <VirtualizedAutoSizer className="auto-sizer">
        {({ width, height }) => (
          <ReactWindowInfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={emulatedItemCount}
            loadMoreItems={loadMoreItems}
            threshold={threshold}
          >
            {({ onItemsRendered, ref }) => (
              <VirtualTable
                height={height}
                width={width}
                itemCount={emulatedItemCount}
                itemSize={itemSize}
                itemKey={(idx) => rows?.[idx]?._id || 'loading'}
                rowProps={rowProps}
                row={TableRow}
                header={
                  <TableHeadS
                    headers={headers}
                    rows={rows}
                    selectedRows={selectedRows}
                    selectedRowsInEnd={selectedRowsInEnd}
                    {...tableHeadProps}
                  />
                }
                footer={
                  TableFooter ? (
                    <TableFooter rows={rows} {...footerProps} />
                  ) : null
                }
                listRef={ref}
                onItemsRendered={onItemsRendered}
                footerHeight={footerHeight}
              />
            )}
          </ReactWindowInfiniteLoader>
        )}
      </VirtualizedAutoSizer>
      {isLoading ? <LoadingMsg>{loadingMessage}</LoadingMsg> : null}
      {moreScrollPosition && !hid && (
        <MoreScrollIcon moreScrollPosition={moreScrollPosition} />
      )}
    </WindowedTableWrapper>
  );
}

WindowedTableComp.defaultProps = {
  onHeaderCellClick: T,
  className: '',
  isLoading: false,
  dataTestId: 'table',
  loadingMessage: 'Loading...',
  loadMoreItems: () => {},
  ...defaultTableRowPropTypes,
};

WindowedTableComp.propTypes = {
  className: string,
  onHeaderCellClick: func,
  isLoading: bool,
  loadingMessage: string,
  dataTestId: string,
  threshold: number,
  loadMoreItems: func,
  itemCount: number,
  footerHeight: number,
  /* eslint-disable react/forbid-prop-types */
  footerProps: object,
  tableRowProps: object,
  /* eslint-enable react/forbid-prop-types */
  TableFooter: func,
  ...tableRowPropTypes,
  NotSelectableCheckboxComponent: func,
  enableHoverEffect: bool,
  moreScrollPosition: string,
  showScrollBar: bool,
};

export function WindowedTable(props) {
  // we always use Table component for testing for correct work
  const TableComp = process.env.NODE_ENV === 'test' ? Table : WindowedTableComp;
  return <TableComp {...props} />;
}
