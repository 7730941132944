import React from 'react';
import { performExcelExport } from '@poly/client-utils';
import { EXPORT_XLS_CAPTION } from '@poly/constants';

import { getAccountXLSExportConfig } from './xlsExport.js';
import { TextButtonS } from '../components.js';
import { defaultChartOfAccountsPropTypes } from '../chartOfAccountsPropTypes.js';

export function ExportToExcelButton({ accountsByTabAndFilter, tab }) {
  const handleClick = () => {
    const excelConfig = getAccountXLSExportConfig(tab, accountsByTabAndFilter);
    performExcelExport(excelConfig);
  };

  return <TextButtonS onClick={handleClick}>{EXPORT_XLS_CAPTION}</TextButtonS>;
}

ExportToExcelButton.propTypes = defaultChartOfAccountsPropTypes;
