import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { oneOf, shape, string } from 'prop-types';
import { BodyRow, getThemeColor, LinkButton, Loader } from '@poly/admin-book';
import { filesCollections } from '@poly/constants';

import { forceTitleCase } from '@poly/utils';
import {
  SidebarTabTitle,
  SidebarTabHeader,
  SidebarTabToolbar,
  SidebarTabWrapper,
  CommonSidebarTabTable,
  SidebarTabTableWrapper,
} from '../sidebarTabComponents.js';
import { AddFilesForm } from '../../../modules/forms/addFilesForm/AddFilesForm.js';
import { CommonSidebarFormWrapper } from '../../CommonSidebarFormWrapper.js';
import { SearchInputComp } from '../../../components/TableSearchInput.js';
import { useFilesTabLogic } from './useFilesTabLogic.js';

const addFilesFormId = 'add_files_form_id';

const FilesTable = styled(CommonSidebarTabTable)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 45px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 155px;
  }

  th:nth-child(5),
  td:nth-child(5) {
    width: 15px;
  }
`;

const FileRow = styled(BodyRow)`
  background: ${({ row, ...props }) =>
    row.pinned && getThemeColor(['secondaryLighter5'])(props)};
`;

export function SidebarFilesTab({ tabTitlePrefix, collection, entity }) {
  const titlePrefix = tabTitlePrefix || forceTitleCase(entity.preview);

  const titleByEntity = `${titlePrefix} Files`;

  const { loading, tableProps, inputProps, setAddFile, showAddFile } =
    useFilesTabLogic({ collection, entity });

  const openAddFileForm = () => setAddFile(true);
  const closeAddFileForm = () => setAddFile(false);

  return (
    <SidebarTabWrapper>
      <SidebarTabHeader skipMargin>
        <SidebarTabTitle>{titleByEntity}</SidebarTabTitle>
        <LinkButton onClick={openAddFileForm}>Add File</LinkButton>
      </SidebarTabHeader>
      <SidebarTabToolbar>
        <SearchInputComp {...inputProps} />
      </SidebarTabToolbar>
      {showAddFile && (
        <CommonSidebarFormWrapper
          title="Add File"
          formId={addFilesFormId}
          onCancel={closeAddFileForm}
        >
          <AddFilesForm
            documentId={entity._id}
            formId={addFilesFormId}
            collection={collection}
            onCancel={closeAddFileForm}
            successMessage="File was successfully added"
          />
        </CommonSidebarFormWrapper>
      )}
      <SidebarTabTableWrapper offset={96}>
        {loading ? (
          <Loader />
        ) : (
          <FilesTable RowComponent={FileRow} {...tableProps} />
        )}
      </SidebarTabTableWrapper>
    </SidebarTabWrapper>
  );
}

SidebarFilesTab.propTypes = {
  entity: shape({
    _id: string.isRequired,
    preview: string.isRequired,
  }).isRequired,
  collection: oneOf(R.values(filesCollections)).isRequired,
  tabTitlePrefix: string,
};
