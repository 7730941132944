import { gql } from '@apollo/client';

export const commonFileFields = gql`
  fragment commonFileFields on File {
    _id
    url
    week
    fileType
    fileSize
    fileName
    createdAt
    createdBy {
      fullName
    }
  }
`;
