import React from 'react';
import { string, func, arrayOf, oneOfType, bool, shape } from 'prop-types';

import { TextareaWithDropzone } from './textareaWithDropzone.js';
import { valuePropTypes } from '../FilePicker/commonPropTypes.js';
import { UploadFileLogic } from '../FilePicker/UploadFileLogic.js';
import { FileList } from './fileList.js';

function DnDTextareaC({ error = [], hasError = false, className, ...props }) {
  return (
    <div data-testid="dnd-textarea" {...{ className }}>
      <TextareaWithDropzone {...props} error={error} hasError={hasError} />
      <FileList {...{ ...props, error, hasError, readOnly: false }} />
    </div>
  );
}

DnDTextareaC.displayName = 'DnDTextareaC';
DnDTextareaC.propTypes = {
  hasError: bool,
  className: string,
  error: oneOfType([
    string,
    arrayOf(string),
    arrayOf(shape({ editorError: string })),
  ]),
};

export function DnDTextarea({ onChange, value, ...props }) {
  const { text, mentions, attachments, ...rest } = value;

  const setNewAttachments = (newAttachments) => {
    onChange({
      text,
      mentions,
      attachments: newAttachments,
      ...rest,
    });
  };

  const onTextChange = (textAndMentions) => {
    onChange({ ...textAndMentions, attachments, ...rest });
  };

  return (
    <UploadFileLogic
      value={value}
      Component={DnDTextareaC}
      onTextChange={onTextChange}
      setNewAttachments={setNewAttachments}
      attachments={attachments || []}
      {...props}
    />
  );
}

DnDTextarea.propTypes = {
  onChange: func.isRequired,
  value: valuePropTypes.isRequired,
};
