import * as R from 'ramda';

import { FULL_ACCESS_PERMISSION } from '../permissions.js';
import {
  FLIPPED_CONTAINS_AIT_OPERATOR,
  EQUALS_AIT_OPERATOR,
  GREATER_OR_EQUAL_AIT_OPERATOR,
  LESS_THAN_AIT_OPERATOR,
  GREATER_THAN_AIT_OPERATOR,
  LESS_OR_EQUAL_AIT_OPERATOR,
  NOT_FLIPPED_CONTAINS_AIT_OPERATOR,
} from '../operators.js';

// operatorImplementationMap :: { [OperatorName]: a -> b -> Boolean}
//   a, b = AitValue
//   AitValue = PrimitiveAitValue | OperatorAitValue
//   OperatorAitValue = { OperatorName: PrimitiveAitValue }
//   OperatorName = String
//   PrimitiveAitValue = SinglePrimitiveAitValue | [SinglePrimitiveAitValue]
//   SinglePrimitiveAitValue = ID | String | Number | Boolean
const operatorImplementationMap = {
  [EQUALS_AIT_OPERATOR]: R.equals,

  [GREATER_OR_EQUAL_AIT_OPERATOR]: R.gte,
  [GREATER_THAN_AIT_OPERATOR]: R.gt,

  [LESS_OR_EQUAL_AIT_OPERATOR]: R.lte,
  [LESS_THAN_AIT_OPERATOR]: R.lt,

  // flippedContains :: [a] -> a -> Boolean
  [FLIPPED_CONTAINS_AIT_OPERATOR]: R.flip(R.includes),

  // notFlippedContains :: [a] -> a -> Boolean
  [NOT_FLIPPED_CONTAINS_AIT_OPERATOR]: R.complement(R.flip(R.includes)),
};

// mapObjValuesByKeys :: Object -> [String] -> [Any]
const mapObjValuesByKeys = R.curry((obj, keys) =>
  R.map(R.prop(R.__, obj), keys),
);

// isOperatorAitValue :: AitValue -> Boolean
// should be enough for current usage
// in future we may check if specific keys present (equals, gt, lt etc.)
const isOperatorAitValue = R.is(Object);

// lensAitValuePairLast :: Lens (Pair AitValue AitValue) AitValue
const lensAitValuePairLast = R.lensIndex(0);

// ensureAitValuesBackwardCompatible :: Pair AitValue AitValue
//   -> Pair AitValue OperatorAitValue
const ensureAitValuesBackwardCompatible = R.over(
  lensAitValuePairLast,
  R.unless(isOperatorAitValue, R.objOf(EQUALS_AIT_OPERATOR)),
);

// getOperatorNameByAitValue :: OperatorAitValue -> OperatorName
const getOperatorNameByAitValue = R.compose(R.head, R.keys);

// getPrimitiveValueByOperatorAitValue :: OperatorAitValue -> PrimitiveAitValue
const getPrimitiveValueByOperatorAitValue = R.compose(R.head, R.values);

// defaultOperatorFn :: a -> a -> Boolean
//    a = Any
const defaultOperatorFn = () => () => false;

// areAitValuesMatchByOperator :: Pair AitValue OperatorAitValue -> Boolean
const areAitValuesMatchByOperator = ([extractedAit, roleAitWithOperator]) =>
  R.compose(
    ([operatorFn, roleValue, extractedValue]) =>
      R.any(operatorFn(roleValue), extractedValue),
    R.juxt([
      R.compose(
        R.propOr(defaultOperatorFn, R.__, operatorImplementationMap),
        getOperatorNameByAitValue,
      ),
      getPrimitiveValueByOperatorAitValue,
      R.compose(
        // make sure extracted value is always array,
        // so we can always use `R.any`
        R.unless(R.is(Array), R.of(Array)),
        getPrimitiveValueByOperatorAitValue,
        R.always(extractedAit),
      ),
    ]),
  )(roleAitWithOperator);

/**
 * compareTypes :: AccessItemType -> AccessItemType -> Boolean
 *
 * All values from second type should match exactly
 * to first type but not vice versa
 */
const compareTypes = (currentTypes) => (roleTypes) =>
  R.compose(
    R.all(areAitValuesMatchByOperator),
    R.map(ensureAitValuesBackwardCompatible),
    R.apply(R.zip),
    R.juxt([mapObjValuesByKeys(currentTypes), mapObjValuesByKeys(roleTypes)]),
    R.keys,
  )(roleTypes);

/**
 * areAccessItemsEqual :: AccessItem -> AccessItem -> Boolean
 *
 * Access items order matters!
 * First argument should be access item generated by context extractor (current access item).
 * Second argument should be access item from user's role.
 *
 * Operators support
 *
 * User role's AccessItemTypes may contain operators for more sophisticated comparison:
 * `{aitName: {operatorName: roleSideArgument}}`
 *
 * Therefore, comparing of
 * `{types: {aitName: extractedSideArgument}}`
 *   and
 * `{aitName: {operatorName: roleSideArgument}}`
 *
 * will result in execution:
 * `operatorName(roleSideArgument, extractedSideArgument)`
 */
export const areAccessItemsEqual = R.curry((currentAi, roleAi) =>
  R.ifElse(
    R.either(
      R.propEq(currentAi.permission, 'permission'),
      R.propEq(FULL_ACCESS_PERMISSION, 'permission'),
    ),
    R.compose(compareTypes(currentAi.types), R.prop('types')),
    R.F,
  )(roleAi),
);
