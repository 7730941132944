import * as R from 'ramda';

// assocNodesForChildProperties :: [String] -> PropertySpendReportResult -> PropertySpendReportResult
const assocNodesForChildProperties = (parentNodes) =>
  R.when(
    R.propIs(Array, 'childProperties'),
    R.over(R.lensProp('childProperties'), R.map(R.assoc('nodes', parentNodes))),
  );

// getPropertiesDeepByHierarchyNodeR :: [String] -> PropertyHierarchySpendReportResult -> [PropertySpendReportResult]
export const getPropertiesDeepByHierarchyNodeR = R.curry((parentNodes, node) =>
  R.compose(
    R.flatten,
    R.cond([
      [
        R.both(
          R.propSatisfies(R.complement(R.isNil), 'properties'),
          R.propSatisfies(R.is(Array), 'properties'),
        ),
        R.compose(
          R.map(assocNodesForChildProperties(parentNodes)),
          R.map(R.assoc('nodes', parentNodes)),
          R.prop('properties'),
        ),
      ],
      [
        R.both(
          R.propSatisfies(R.complement(R.isNil), 'children'),
          R.propSatisfies(R.is(Array), 'children'),
        ),
        R.compose(
          R.map(
            R.converge(getPropertiesDeepByHierarchyNodeR, [
              R.compose(R.append(R.__, parentNodes), R.prop('name')),
              R.identity,
            ]),
          ),
          R.prop('children'),
        ),
      ],
      [R.T, R.always([])],
    ]),
  )(node),
);
