import React from 'react';
import { string, shape, func } from 'prop-types';
import { useOutSidebarContext } from '@poly/client-utils';
import { EditStaffUserForm } from '@poly/admin-ui';

import { editUserFormId } from '../../constants.js';
import { SidebarFormLayout } from '../../../components/SidebarFormLayout.js';
import { useSidebarLogicContext } from '../../../SidebarLogicContext.js';

function EditUserForm({ staffUser }) {
  const { openUserSidebar } = useSidebarLogicContext();

  const { closeOutSidebar } = useOutSidebarContext();

  const formProps = {
    staffUser,
    isCardLayout: false,
    formId: editUserFormId,
    onMutationSuccess: () => {
      closeOutSidebar(editUserFormId);
      openUserSidebar(staffUser._id);
    },
  };

  return <EditStaffUserForm {...formProps} />;
}

EditUserForm.propTypes = {
  staffUser: shape({ _id: string.isRequired }).isRequired,
};

export function EditUserSidebarForm({ staffUser, onClose }) {
  return (
    <SidebarFormLayout
      onClose={onClose}
      title="Edit Staff"
      formId={editUserFormId}
      submitButtonCaption="Save"
    >
      <EditUserForm staffUser={staffUser} />
    </SidebarFormLayout>
  );
}

EditUserSidebarForm.propTypes = {
  onClose: func.isRequired,
  staffUser: shape({ _id: string.isRequired }).isRequired,
};
