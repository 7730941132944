import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { Textarea } from '@poly/admin-book';
import { func, shape, string } from 'prop-types';

const TextAreaS = styled(Textarea)`
  textarea {
    min-height: 75px;
  }
`;

export function SupplierUpdateDetails({ value, onChange }) {
  const handleChange = (e) => onChange({ ...value, textValue: e.target.value });
  const label = `Project ${R.pathOr(
    '',
    ['label', 'projectId'],
    value,
  )} has been re-opened. (custom text inserted here)`;

  return (
    <TextAreaS onChange={handleChange} value={value.textValue} label={label} />
  );
}

SupplierUpdateDetails.propTypes = {
  value: shape({
    textValue: string,
  }),
  onChange: func.isRequired,
};
