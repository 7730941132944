import React from 'react';
import { func } from 'prop-types';
import {
  PageHeaderContainer,
  HeaderLinksWrapper,
  LinkButton,
  MainHeader,
} from '@poly/admin-book';

import { useOpenAddUserGroupSidebar } from './form/useOpenAddUserGroupSidebar.js';

function AddUserGroupButton({ refetch }) {
  const onAddNewUserGroup = useOpenAddUserGroupSidebar();

  const onClick = () => onAddNewUserGroup({ refetch });

  return <LinkButton onClick={onClick}>Add New User Group</LinkButton>;
}

AddUserGroupButton.propTypes = { refetch: func };

export function UserGroupManagementPageHeader(props) {
  return (
    <PageHeaderContainer>
      <MainHeader>User Group Management</MainHeader>
      <HeaderLinksWrapper>
        <AddUserGroupButton {...props} />
      </HeaderLinksWrapper>
    </PageHeaderContainer>
  );
}
