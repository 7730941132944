import React, { memo, useEffect, useRef } from 'react';
import * as R from 'ramda';
import {
  shape,
  number,
  func,
  string,
  arrayOf,
  oneOfType,
  instanceOf,
} from 'prop-types';
import {
  DatePicker,
  Input,
  getThemeColor,
  Icon,
  FormField,
} from '@poly/admin-book';
import styled from 'styled-components';

import { getDay } from 'date-fns';
import { toDate } from '@poly/utils';

import { getHousekeepingCalendarYearsWeeks } from './HousekeepingCalendarList.js';

export const LineWrapper = styled.div`
  display: flex;
  justify-content: start;
`;

const DeleteLineIcon = styled(Icon).attrs((props) => ({
  color: getThemeColor(['secondaryMid'])(props),
}))`
  cursor: pointer;
`;

const FieldWrapper = styled.div`
  margin-right: 20px;
  margin-bottom: 20px;
  width: ${R.prop('width')};
`;

// validateYearWeeks :: [String] -> Number -> String -> String
const validateYearWeeks = (weeks, index) =>
  R.compose(
    R.ifElse(
      R.identity,
      R.always(''),
      R.always('Year may have 52 or 53 weeks'),
    ),
    R.ifElse(
      R.compose(
        R.equals(index),
        R.subtract(R.__, 1),
        R.length,
        R.always(weeks),
      ),
      R.T,
      R.includes(R.__, ['52', '53']),
    ),
  );

// validateStartOfYear :: Date -> String
const validateStartOfYear = R.cond([
  [R.isNil, R.always('Start Of Year is required')],
  [
    R.compose(R.complement(R.equals(0)), getDay, toDate),
    R.always('Year should begin on Sunday'),
  ],
  [R.T, R.always('')],
]);

export const HousekeepingCalendarLine = memo(
  ({ name, index, value, remove, update, values }) => {
    const firstUpdate = useRef(false);

    const weeks = getHousekeepingCalendarYearsWeeks(values);

    const prevIndex = index - 1;

    const nextIndex = index + 1;

    useEffect(() => {
      if (firstUpdate.current) {
        update(index, { ...value, weeks: weeks[index] });
      }
    }, [value.startOfYear, value.description]);

    useEffect(() => {
      if (firstUpdate.current && weeks[prevIndex]) {
        update(prevIndex, {
          ...values[prevIndex],
          weeks: weeks[prevIndex],
        });
      }
      if (firstUpdate.current && weeks[nextIndex]) {
        update(nextIndex, {
          ...values[nextIndex],
          weeks: weeks[nextIndex],
        });
      }
    }, [value.startOfYear]);

    useEffect(() => {
      firstUpdate.current = true;
    }, []);

    return (
      <LineWrapper>
        <FieldWrapper width="40%">
          <FormField
            name={`${name}.startOfYear`}
            Component={DatePicker}
            validate={validateStartOfYear}
            additionalProps={{
              width: '100%',
            }}
          />
        </FieldWrapper>
        <FieldWrapper width="40%">
          <FormField
            name={`${name}.description`}
            Component={Input}
            validate={(val) => (val ? '' : 'Description is required')}
          />
        </FieldWrapper>
        <FieldWrapper width="15%">
          <FormField
            name={`${name}.weeks`}
            Component={Input}
            validate={validateYearWeeks(weeks, index)}
            additionalProps={{
              disabled: true,
            }}
          />
        </FieldWrapper>
        <DeleteLineIcon name="delete" size={14} onClick={() => remove(index)} />
      </LineWrapper>
    );
  },
);

HousekeepingCalendarLine.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  value: shape({
    startOfYear: oneOfType([string, instanceOf(Date)]),
    description: string,
  }),
  remove: func.isRequired,
  update: func.isRequired,
  values: arrayOf(
    shape({
      startOfYear: oneOfType([string, instanceOf(Date)]),
      description: string,
    }),
  ),
};
