import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, func, object, shape } from 'prop-types';

import { mapListToGroups } from '../mapListToGroups.js';
import { sectionTypes } from '../formTypes.js';

const Container = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: ${R.prop('width')};
  padding: ${R.propOr(0, 'padding')};
  flex-wrap: wrap;
  ${({ overflow }) => (overflow ? `overflow: ${overflow};` : '')};
  ${({ card }) => (card ? 'max-width: 1424px;' : '')};
`;

export function FormLayout({
  values,
  layout,
  sections,
  mutators,
  fieldLayout,
  defaultLayout,
  sectionLayout,
  changeFieldValue,
}) {
  const formLayout = R.mergeDeepRight(defaultLayout, layout);
  const groupLayout = R.pick(['group', 'card'], layout);

  return (
    <Container {...formLayout}>
      {mapListToGroups(
        sections,
        changeFieldValue,
        sectionLayout,
        fieldLayout,
        values,
        groupLayout,
        mutators,
      )}
    </Container>
  );
}

FormLayout.displayName = 'FormLayout';
FormLayout.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  values: object,
  layout: object,
  fieldLayout: object,
  defaultLayout: object,
  sectionLayout: object,
  mutators: object.isRequired,
  changeFieldValue: func.isRequired,
  sections: arrayOf(shape(sectionTypes)).isRequired,
};
FormLayout.defaultProps = {
  values: {},
  layout: {},
  fieldLayout: {},
  sectionLayout: {},
  defaultLayout: {
    width: '100%',
    card: true,
  },
};
