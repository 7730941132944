import * as R from 'ramda';
import { useState, useRef, useEffect } from 'react';
import { useCurrentUserByStoreOrQuery } from '@poly/client-utils/src/hooks/useCurrentUserByStoreOrQuery.js';

import { useRegisterSharedWorker } from '../../pages/HomePage/useRegisterSharedWorker.js';
import { useUnsupportedWorkerMessage } from '../../hooks/useUnsupportedWorkerMessage.js';

export const useAppBarPayload = () => {
  const appBarWorkerRef = useRef(null);
  const { user } = useCurrentUserByStoreOrQuery();
  const [appBarPayload, setAppBarPayload] = useState({});
  const showUnsupportedMessage = useUnsupportedWorkerMessage();

  const currentUserId = R.prop('_id', user);

  useEffect(() => {
    try {
      appBarWorkerRef.current = new SharedWorker(
        new URL('./appBarQuerySharedWorker.js', import.meta.url),
        {
          name: 'admin-app-bar',
        },
      );
    } catch (e) {
      showUnsupportedMessage();
    }
  }, []);

  useRegisterSharedWorker({
    setData: setAppBarPayload,
    messageProps: { currentUserId },
    workerInstance: appBarWorkerRef.current,
    skip: !currentUserId || !appBarWorkerRef.current,
  });

  return appBarPayload;
};
