import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { GoogleMap } from '../modules/googleMap/index.js';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`;

export function SuppliersMapPage() {
  const [suppliers, setSuppliers] = useState([]);

  useEffect(() => {
    const suppliersValue = R.ifElse(
      R.anyPass([R.isNil, R.isEmpty]),
      R.always([]),
      (json) => JSON.parse(json),
    )(localStorage.getItem('suppliers'));

    setSuppliers(suppliersValue);

    localStorage.removeItem('suppliers');
  }, []);

  return (
    <PageWrapper>
      <GoogleMap {...{ suppliers }} />
    </PageWrapper>
  );
}

SuppliersMapPage.displayName = 'SuppliersMapPage';
