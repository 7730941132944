import * as R from 'ramda';
import React from 'react';
import { arrayOf } from 'prop-types';
import { LinkButton } from '@poly/admin-book';
import { formatDate } from '@poly/utils';
import { EXPORT_XLS_CAPTION, NOTHING_UI_STRING } from '@poly/constants';
import {
  performExcelExport,
  ExcelExportCellType,
  createExcelExportCell,
} from '@poly/client-utils';

import { confirmSatisfactionProjectPropTypes } from './prop-types.js';

// getSupplierPaymentRow :: SupplierPaymentTransaction -> [ExcelExportDataCell]
const getSupplierPaymentRow = R.compose(
  R.zipWith(createExcelExportCell, R.repeat(ExcelExportCellType.default, 4)),
  R.juxt([
    R.propOr(NOTHING_UI_STRING, 'projectId'),
    R.propOr(NOTHING_UI_STRING, 'description'),
    R.pathOr(NOTHING_UI_STRING, ['contact', 'profile', 'fullName']),
    R.ifElse(
      R.propSatisfies(R.isNil, 'workCompletionDate'),
      R.always(NOTHING_UI_STRING),
      R.compose(formatDate, R.prop('workCompletionDate')),
    ),
  ]),
);

const getConfirmSatisfactionXlsExportConfig = (projects) => ({
  exportFileName: 'confirm-satisfaction-report.xlsx',
  columnWidths: [15, 60, 15, 15],
  rows: [
    [
      {
        value: 'Confirm Satisfaction Report',
        cellType: ExcelExportCellType.title,
      },
    ],

    R.zipWith(
      createExcelExportCell,
      R.repeat(ExcelExportCellType.tableHeader, 4),
      ['Project #', 'Project description', 'Requestor', 'Date completed'],
    ),

    ...projects.map(getSupplierPaymentRow),
  ],
});

export function ConfirmSatisfactionExportXLSBtn({ projects }) {
  const disabled = R.isEmpty(projects);

  const onClick = () => {
    const config = getConfirmSatisfactionXlsExportConfig(projects);

    performExcelExport(config);
  };

  return (
    <LinkButton disabled={disabled} onClick={onClick}>
      {EXPORT_XLS_CAPTION}
    </LinkButton>
  );
}

ConfirmSatisfactionExportXLSBtn.propTypes = {
  projects: arrayOf(confirmSatisfactionProjectPropTypes),
};
