import React, { useState } from 'react';
import { string } from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { SystemAccountTypes } from '@poly/constants';

import styled from 'styled-components';

import { ReportPageTableWrapper } from '../../components/ReportPageTableWrapper.js';
import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { ReconciliationReportHeader } from './ReconciliationReportHeader.js';
import { ReconciliationReportTable } from './ReconciliationReportTable.js';
import { reconciliationReportSectionsPropType } from './prop-types.js';
import { moneyTransactionCommonFragment } from '../BankRegistersPage/bankRegistersQuery.js';

const reconciliationReportQuery = gql`
  query RECONCILIATION_REPORT($input: ReconciliationReportInput!) {
    reconciliationReport(input: $input) {
      beginningBalance
      deposits {
        ...moneyTransactionCommonFragment
      }
      payments {
        ...moneyTransactionCommonFragment
      }
      outstandingDeposits {
        ...moneyTransactionCommonFragment
      }
      outstandingPayments {
        ...moneyTransactionCommonFragment
      }
      clearedBalance
      registerBalance
      endingDate
      endingBalance
      account {
        _id
        name
        code
      }
    }
  }

  ${moneyTransactionCommonFragment}
`;

const NoReconciliationReportDataMessage = styled.div.attrs(() => ({
  children: 'No Filters Applied',
}))`
  text-align: center;
  width: 100%;
`;

export function ReconciliationReportPageBase({
  title,
  reportSections,
  outstandingReportSections,
  systemAccountType,
}) {
  const [filters, setFilters] = useState(null);

  const { reconciliationStatementId } = filters || {};

  const { data, loading, error } = useQuery(reconciliationReportQuery, {
    variables: { input: { reconciliationStatementId } },
    skip: !reconciliationStatementId,
  });

  const isCreditCard = systemAccountType === SystemAccountTypes.CREDIT_CARD;

  return (
    <PageWithSearchHeader headerHeight="120px">
      <ReconciliationReportHeader
        title={title}
        systemAccountType={systemAccountType}
        reportSections={reportSections}
        outstandingReportSections={outstandingReportSections}
        isCreditCard={isCreditCard}
        setFilters={setFilters}
        loading={loading}
        data={data}
      />
      <ReportPageTableWrapper loading={loading}>
        {reconciliationStatementId && !error ? (
          <ReconciliationReportTable
            data={data}
            reportSections={reportSections}
            outstandingReportSections={outstandingReportSections}
            isCreditCard={isCreditCard}
          />
        ) : (
          <NoReconciliationReportDataMessage />
        )}
      </ReportPageTableWrapper>
    </PageWithSearchHeader>
  );
}

ReconciliationReportPageBase.propTypes = {
  title: string.isRequired,
  systemAccountType: string.isRequired,
  reportSections: reconciliationReportSectionsPropType.isRequired,
  outstandingReportSections: reconciliationReportSectionsPropType.isRequired,
};
