import * as R from 'ramda';
import React, { useMemo } from 'react';
import arrayMutators from 'final-form-arrays';
import { useHasUserAccessToProtectedSupplier } from '@poly/client-utils/src/suppliers/useHasUserAccessToUpdateSupplierProtected.js';
import { UPDATE_MASTER_SUPPLIER_PERMISSION } from '@poly/security/src/permissions.js';
import { usePristineSubscribe } from '@poly/client-routing';
import { FormPage, Loader } from '@poly/admin-book';
import { bool, shape, string } from 'prop-types';

import { masterSupplierFormSections } from './formSections/masterSupplierFormSections.js';
import { useUpdateMasterSupplierMutation } from '../hooks/useUpdateMasterSupplierMutation.js';
import { useOnSubmitSetStopSubmitting } from '../../../hooks/useOnSubmitSetStopSubmitting.js';
import { useMasterSupplierDetailsQuery } from '../hooks/useMasterSupplierDetailsQuery.js';
import {
  useSupplierTaxValidation,
  supplierToEditForm,
} from '../../forms/supplierForm/index.js';

// prepareMaterSupplierToForm :: MasterSupplier -> FormData
const prepareMaterSupplierToForm = R.compose(
  R.omit(['cardNumber', 'rating']),
  supplierToEditForm,
);

export function EditMasterSupplierForm({
  formId,
  supplierId,
  supplierRootUrl,
  masterSupplierRootUrl,
  protectedFieldsAccess,
  isSupplierTypeEditable,
}) {
  const { data, loading } = useMasterSupplierDetailsQuery(supplierId);

  const hasAccessToUpdatePrivateSupplier = useHasUserAccessToProtectedSupplier(
    UPDATE_MASTER_SUPPLIER_PERMISSION,
  );

  const masterSupplier = useMemo(
    () => R.pathOr({}, ['masterSupplier'], data),
    [data],
  );

  const onSubmitHandler = useUpdateMasterSupplierMutation(
    supplierId,
    protectedFieldsAccess,
  );

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  const pristineSubscribeProps = usePristineSubscribe();

  const validate = useSupplierTaxValidation(
    masterSupplierRootUrl,
    supplierRootUrl,
    false,
    masterSupplier,
  );

  const formProps = {
    id: formId,
    onSubmit,
    validate,
    mutators: arrayMutators,
    sections: masterSupplierFormSections(
      masterSupplierRootUrl,
      masterSupplier,
      isSupplierTypeEditable,
      protectedFieldsAccess,
      masterSupplier?.supplierSource,
      hasAccessToUpdatePrivateSupplier,
    ),
    initialValues: prepareMaterSupplierToForm(masterSupplier),
    ...pristineSubscribeProps,
  };

  return loading ? <Loader /> : <FormPage {...formProps} />;
}

EditMasterSupplierForm.propTypes = {
  supplierRootUrl: string.isRequired,
  masterSupplierRootUrl: string.isRequired,
  formId: string.isRequired,
  supplierId: string.isRequired,
  isSupplierTypeEditable: bool,
  protectedFieldsAccess: shape({
    hasUserAccessToUpdateTax: bool,
    hasUserAccessToUpdateBank: bool,
    hasUserAccessToUpdateFinance: bool,
    hasUserAccessToUpdateRemit: bool,
    hasUserAccessToUpdateDocuments: bool,
  }).isRequired,
};
