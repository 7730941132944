import React from 'react';
import * as R from 'ramda';
import { forceTitleCase } from '@poly/utils';
import { string } from 'prop-types';
import {
  MainHeader,
  PageHeaderContainer,
} from '@poly/admin-book/src/Header/index.js';
import {
  completedRecurringProjectsElasticQuery,
  scheduledRecurringProjectsElasticQuery,
  unscheduledRecurringProjectsElasticQuery,
} from '@poly/utils/src/projects/elasticQuery.js';
import { useCurrentUserByStoreOrQuery } from '@poly/client-utils/src/hooks/useCurrentUserByStoreOrQuery.js';

import { PageTabs } from '../../modules/tabs/TabsWithRouter.js';
import { ProjectsTabTitle } from '../../modules/tabs/projectsTab/ProjectsTabTitle.js';
import { ProjectsPageProjectsTab } from '../../modules/tabs/projectsTab/ProjectsTab.js';
import { SearchProjectsTableWrapper } from '../ProjectsDirectory/SearchProjectsTableWrapper.js';
import { ProjectsDirectoryToolbar } from '../ProjectsDirectory/ProjectsDirectoryToolbar.js';
import { recurringProjectsDashboardUIStatuses } from '../../modules/core/constants/projects.js';
import { useSearchProjectsFilter } from './useSearchProjectsFilter.js';

const { SCHEDULED, UNSCHEDULED, COMPLETED } =
  recurringProjectsDashboardUIStatuses;

// createBoolFilterSearchQueryWithManager :: (ID, [Object]) -> ElasticQuery
const createBoolFilterSearchQueryWithManager = (managerId, otherQueries) => ({
  bool: {
    filter: [...(managerId ? [{ term: { managerId } }] : []), ...otherQueries],
  },
});

const getMyRecurringProjectsQueries = (status, userId) => {
  const queryConfig = {
    [SCHEDULED]: createBoolFilterSearchQueryWithManager(
      userId,
      scheduledRecurringProjectsElasticQuery,
    ),
    [UNSCHEDULED]: createBoolFilterSearchQueryWithManager(
      userId,
      unscheduledRecurringProjectsElasticQuery,
    ),

    [COMPLETED]: createBoolFilterSearchQueryWithManager(
      userId,
      completedRecurringProjectsElasticQuery,
    ),
  };
  return queryConfig[status];
};

const myRecurringProjectsTabsConfig = [
  [SCHEDULED, 'scheduled'],
  [UNSCHEDULED, 'unscheduled'],
  [COMPLETED, 'completed'],
];

function MyRecurringProjectTabTitle({ status, ...props }) {
  const text = forceTitleCase(status);

  return <ProjectsTabTitle text={text} {...props} />;
}

MyRecurringProjectTabTitle.propTypes = {
  status: string.isRequired,
};

const tabFromName = ([status, name, userId]) => [
  <MyRecurringProjectTabTitle
    key={status}
    {...{ name, status }}
    query={getMyRecurringProjectsQueries(status, userId)}
  />,
  name,
  <ProjectsPageProjectsTab
    key={status}
    query={getMyRecurringProjectsQueries(status, userId)}
  />,
];

// getRecurringProjectsTabs :: ID -> [ReactNode]
const getRecurringProjectsTabs = (userId) =>
  R.compose(
    R.map(tabFromName),
    R.map(R.append(userId)),
  )(myRecurringProjectsTabsConfig);

export function MyRecurringProjects() {
  const { filter, headerProps } = useSearchProjectsFilter();

  const { user } = useCurrentUserByStoreOrQuery();

  const tabs = getRecurringProjectsTabs(user?._id);

  return (
    <>
      <PageHeaderContainer {...headerProps}>
        <MainHeader>
          {filter ? 'Search Projects' : 'Reoccurring Projects'}
        </MainHeader>
      </PageHeaderContainer>
      {filter ? (
        <SearchProjectsTableWrapper filter={filter} />
      ) : (
        <PageTabs
          toolBar={
            <ProjectsDirectoryToolbar
              customFileName="reoccurring_projects"
              customTitle="Reoccurring Projects"
            />
          }
          tabs={tabs}
          withPips
          defaultValue={myRecurringProjectsTabsConfig[0][1]}
        />
      )}
    </>
  );
}
