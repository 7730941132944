export const ESFM_INFO = {
  shortName: 'ESFM',
  fax: '704-625-8846',
  telephone: '800-818-4563',
  website: 'https://esfm-usa.com',
  name: 'A. Anthony LLC d.b.a ESFM',
  addressLine1: '800 Valley Plaza, Suite 8',
  addressLine2: 'Johnson City, NY 13790',
  email: 'ESFM-AP@compass-usa.com',
};
