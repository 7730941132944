import { gql } from '@apollo/client';

export const commonAddressFields = gql`
  fragment commonAddressFields on Address {
    formatted_address
    utc_offset
    vicinity
    address_parts {
      street_number
      route
      locality
      administrative_area_level_2
      administrative_area_level_1
      country
      postal_code
      postal_code_suffix
      neighborhood
    }
    geo {
      type
      coordinates
    }
  }
`;
