import * as R from 'ramda';
import { CHILD_PROJECT_ELASTIC_QUERY } from '@poly/client-utils';
import { isNilOrEmpty, assocBy, pathEqLegacy } from '@poly/utils';
import {
  BillingProfileConsolidateBy,
  BillingProfileProjectType,
  RecurringProjectTypes,
  ProjectType,
} from '@poly/constants';

import { BillingProfileConfigFields } from '../constants.js';
import { ALL } from '../../../modules/core/constants/general.js';
import { getEntityQuery } from '../../../modules/tables/commonTableUtils.js';

// checkAllValuesSelected :: [String] -> [Option] -> Boolean
const checkAllValuesSelected = (selectedValues) =>
  R.compose(
    R.both(R.compose(R.equals(1), R.length), R.compose(R.equals(ALL), R.head)),
    R.without(selectedValues),
    R.map(R.prop('value')),
    R.defaultTo([]),
  );

// prepareMultipleSelectValue :: Any -> [Any]
export const prepareMultipleSelectValue = (value, options) => {
  if (isNilOrEmpty(value)) {
    return [];
  }

  if (checkAllValuesSelected(value)(options)) {
    return [{ value: ALL, label: 'All' }];
  }

  return R.filter(
    R.propSatisfies((val) => R.any(R.equals(val), value), 'value'),
  )(options);
};

// onMultipleSelectValueChange :: () => _ -> [Option] -> _
export const onMultipleSelectValueChange = (handleChange) =>
  R.compose(
    handleChange,
    R.when(R.includes(ALL), R.always([ALL])),
    R.map(R.prop('value')),
  );

// mapBillingProfileToProjectQuery :: BillingProfileProjectType -> MongoQuery
const mapBillingProfileToProjectQuery = R.cond([
  [
    R.equals(BillingProfileProjectType.DEFAULT),
    R.always({ bool: { must_not: [CHILD_PROJECT_ELASTIC_QUERY] } }),
  ],
  [
    R.equals(BillingProfileProjectType.CHILD_RECURRING),
    R.always({
      bool: {
        must: [
          CHILD_PROJECT_ELASTIC_QUERY,
          { match: { 'parent.type': RecurringProjectTypes.recurringProject } },
        ],
      },
    }),
  ],
  [
    R.equals(BillingProfileProjectType.CHILD_PM),
    R.always({
      bool: {
        must: [
          CHILD_PROJECT_ELASTIC_QUERY,
          {
            match: {
              'parent.type': RecurringProjectTypes.preventiveMaintenanceProject,
            },
          },
        ],
      },
    }),
  ],
  [R.T, R.always(null)],
]);

// getTermsQuery :: FieldName -> QueryPathString -> DataObject -> QueryObject
const getTermsQuery = R.curry((name, path, data) =>
  R.compose(
    R.ifElse(
      isNilOrEmpty,
      R.always(null),
      R.compose(R.objOf('terms'), R.objOf(path)),
    ),
    R.prop(name),
  )(data),
);

// isConsolidateByCostCenter :: String -> Boolean
const isConsolidateByCostCenter = R.equals(
  BillingProfileConsolidateBy.COST_CENTER,
);

// getSubPropertiesNestedQuery :: QueryObject -> QueryObject
const getSubPropertiesNestedQuery = (query) => ({
  nested: {
    path: 'subProperties',
    query,
  },
});

// getShouldOneOfTermsQuery :: FieldName -> QueryPathString -> DataObject -> QueryObject
export const getShouldOneOfTermsQuery = R.curry((name, path, data) =>
  R.compose(
    R.ifElse(
      isNilOrEmpty,
      R.always(null),
      R.compose(
        R.objOf('bool'),
        R.objOf('should'),
        R.reject(R.isNil),
        R.juxt([
          R.compose(R.objOf('terms'), R.objOf(path)),
          R.compose(R.objOf('terms'), R.objOf(`parent.${path}`)),
          R.ifElse(
            R.always(isConsolidateByCostCenter(path)),
            R.compose(
              getSubPropertiesNestedQuery,
              R.objOf('terms'),
              R.objOf(`subProperties.${path}`),
            ),
            R.always(null),
          ),
        ]),
      ),
    ),
    R.prop(name),
  )(data),
);

// getConstantQuery :: (String, String) -> QueryObject
export const getConstantQuery = (name, value) =>
  R.always({ term: { [name]: value } });

// getNonPOQuery :: Boolean -> QueryObject
const getNonPOQuery = (isParent) => ({
  bool: {
    should: [
      {
        term: {
          [isParent ? 'parent.clientReferenceNumber' : 'clientReferenceNumber']:
            '',
        },
      },
      {
        bool: {
          must_not: {
            exists: {
              field: isParent
                ? 'parent.clientReferenceNumber'
                : 'clientReferenceNumber',
            },
          },
        },
      },
    ],
  },
});

// getCommonProjectsQueryByConfig :: BillingProfileUIConfig -> MongoQuery
export const getCommonProjectsQueryByConfig = R.converge(Array.of, [
  R.compose(
    R.unless(R.path(['bool', 'should', '0']), R.always(null)),
    R.objOf('bool'),
    R.objOf('should'),
    R.flatten,
    R.map(mapBillingProfileToProjectQuery),
    R.keys,
    R.filter(R.identity),
    R.pick(R.values(BillingProfileProjectType)),
  ),
  getTermsQuery('serviceTypeIds', 'serviceTypeId'),
  getTermsQuery('propertyIds', 'propertyId'),
  getEntityQuery('clientId', 'clientId'),
  // we exclude housekeeping projects for consolidation
  // https://gitlab.com/askpoly/poly-apps/-/issues/4021#note_1327308727
  R.ifElse(
    R.propSatisfies(isNilOrEmpty, 'costTypes'),
    R.always({
      bool: { must_not: { term: { type: ProjectType.HOUSEKEEPING } } },
    }),
    getTermsQuery('costTypes', 'type'),
  ),
  // for nonPO field logic
  R.ifElse(
    R.either(
      R.propSatisfies(R.either(R.isNil, R.equals(false)), 'nonPO'),
      pathEqLegacy(
        ['consolidateBy', 0],
        BillingProfileConsolidateBy.REFERENCE_NUMBER,
      ),
    ),
    R.always(null),
    R.always({
      bool: { must: [getNonPOQuery(true), getNonPOQuery(false)] },
    }),
  ),
]);

// getTargetQueryPart :: (String, Boolean) -> MongoQuery
const getTargetQueryPart = (field, isParent = false) => ({
  bool: {
    must: [
      ...(isParent ? [{ exists: { field: 'parentId' } }] : []),
      { exists: { field: isParent ? `parent.${field}` : field } },
      {
        bool: {
          must_not: { term: { [isParent ? `parent.${field}` : field]: '' } },
        },
      },
    ],
  },
});

// getTargetFieldQuery :: [String] -> [MongoQuery] -> [MongoQuery]
export const getTargetFieldQuery = R.curry((targetFields, queryArr) =>
  R.concat(
    isNilOrEmpty(targetFields)
      ? []
      : [
          {
            bool: {
              should: targetFields.reduce(
                (accumulator, targetField) => [
                  ...accumulator,
                  getTargetQueryPart(targetField),
                  getTargetQueryPart(targetField, true),
                  ...(isConsolidateByCostCenter(targetField)
                    ? [
                        getSubPropertiesNestedQuery({
                          bool: {
                            must: {
                              exists: { field: 'subProperties.costCenter' },
                            },
                          },
                        }),
                      ]
                    : []),
                ],
                [],
              ),
            },
          },
        ],
  )(queryArr),
);

// generateProjectsQueryForConfigByTarget :: String -> BillingProfileUIConfig -> MongoQuery
export const generateProjectsQueryForConfigByTarget = (targetField) =>
  R.compose(
    R.objOf('bool'),
    R.objOf('must'),
    R.when(() => !!targetField, getTargetFieldQuery([targetField])),
    R.reject(R.isNil),
    getCommonProjectsQueryByConfig,
  );

// formatProjectConsolidationOptionsByName :: SearchProjectsQueryResult -> [Option]
export const formatProjectConsolidationOptionsByName = (name) =>
  R.compose(
    R.map(R.applySpec({ value: R.identity, label: R.identity })),
    R.uniq,
    R.reject(isNilOrEmpty),
    R.flatten,
    R.map(
      R.juxt([
        R.prop(name),
        R.path(['parent', name]),
        R.compose(
          R.map(R.prop(name)),
          R.defaultTo([]),
          R.prop('subProperties'),
        ),
      ]),
    ),
    R.pathOr([], ['searchProjects', 'hits']),
  );

// assocWithReportingInput ::  BillingProfileUIConfig -> BillingProfileConfig
const assocWithReportingInput = R.compose(
  R.omit([
    'apReportingEnabled',
    'coverPageAndDetailsReportingEnabled',
    'isPassThroughFeeEnabled',
    'isFinancialCodingEnabled',
  ]),
  assocBy(
    'reporting',
    R.applySpec({
      accountsPayable: R.propOr(false, 'apReportingEnabled'),
      coverPageAndDetails: R.applySpec({
        enabled: R.propOr(false, 'coverPageAndDetailsReportingEnabled'),
        isPassThroughFeeEnabled: R.propOr(false, 'isPassThroughFeeEnabled'),
        isFinancialCodingEnabled: R.propOr(false, 'isFinancialCodingEnabled'),
      }),
    }),
  ),
);

// getBillingProfileQueryConfig :: BillingProfileUIConfig -> BillingProfileConfig
export const getBillingProfileQueryConfig = R.compose(
  assocWithReportingInput,
  R.when(
    R.prop('batch'),
    R.omit(['consolidation', 'consolidateBy', 'attachBackupInvoices']),
  ),
  R.when(R.prop('consolidation'), R.omit(['batch'])),
  R.pick(BillingProfileConfigFields),
  R.reject(isNilOrEmpty),
  assocBy(
    'type',
    R.compose(
      R.keys,
      R.filter(R.identity),
      R.pick(R.values(BillingProfileProjectType)),
    ),
  ),
);
