import * as R from 'ramda';
import styled from 'styled-components';
import { bool, func } from 'prop-types';
import React, { useEffect } from 'react';
import { useRouterQuery } from '@poly/client-routing';
import { SupplierSelect } from '@poly/admin-ui';
import { alwaysNewDate } from '@poly/utils';
import {
  HeaderLinksWrapper,
  DateRangePicker,
  Checkbox,
  Select,
} from '@poly/admin-book';

import {
  invoiceStatusSelectOptions,
  searchSupplierInvoicesFilters,
} from './constants.js';
import {
  FlexColumn,
  FlexContainer,
  JustifyFlexEnd,
} from '../../components/FlexContainer.js';
import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import { ALL } from '../../modules/core/constants/general.js';
import { searchSupplierInvoicesDataPropType } from './propTypes.js';
import { InvoicesSelect } from '../../modules/selects/InvoicesSelect.js';
import { SupplierInvoicesExportPDFBtn } from './SupplierInvoicesExportPDFBtn.js';
import { SupplierInvoicesExportXlsBtn } from './SupplierInvoicesExportXlsBtn.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { generateSearchInvoicesQuery } from './searchSupplierInvoicesUtils.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';

const FiltersContainerS = styled(FlexColumn)`
  width: 100%;
`;

const CheckboxContainerS = styled(FlexContainer)`
  padding-left: 5px;
  width: 100%;
`;

const searchSupplierInvoicesPageHeaderConfig = [
  { name: searchSupplierInvoicesFilters.supplier },
  { name: searchSupplierInvoicesFilters.invoiceNumber },
  {
    name: searchSupplierInvoicesFilters.invoiceStatus,
    defaultValue: ALL,
  },
  {
    name: searchSupplierInvoicesFilters.invoiceDate,
    nestedFields: ['startDate', 'endDate'],
    defaultValue: {
      startDate: alwaysNewDate().toDateString(),
      endDate: alwaysNewDate().toDateString(),
    },
  },
  {
    name: searchSupplierInvoicesFilters.creditsOnly,
    defaultValue: false,
  },
];

// isExportDisabled :: QueryData -> Bool
const isExportDisabled = R.compose(
  R.equals(0),
  R.pathOr(0, ['searchInvoices', 'total']),
);

// checkHasUrlParams :: URLParams -> Boolean
const checkHasUrlParams = R.compose(
  R.complement(R.isEmpty),
  R.reject(R.equals(false)),
);

export function SearchSupplierInvoicesPageHeader({ setQuery, loading, data }) {
  const urlParams = useRouterQuery(R.values(searchSupplierInvoicesFilters));
  const { searchFilters, handlers, onReset } = useSearchFilters(
    searchSupplierInvoicesPageHeaderConfig,
  );

  const onResetFilters = () => {
    onReset();
    setQuery(null);
  };

  const onSearch = () => setQuery(generateSearchInvoicesQuery(searchFilters));

  const hasUrlParams = checkHasUrlParams(urlParams);

  useEffect(() => {
    if (hasUrlParams) {
      onSearch();
    }
  }, []);

  const exportDisabled = isExportDisabled(data);

  const invoiceStatusSelectProps = {
    value: searchFilters.invoiceStatus,
    options: invoiceStatusSelectOptions,
    onChange: handlers.invoiceStatus,
    name: 'invoiceStatusSelect',
    width: '100%',
    size: 'small',
    required: true,
  };

  return (
    <SearchPageHeaderContainer title="Search Supplier Invoices">
      <FiltersContainerS>
        <FlexSpaceBetween>
          <SearchHeaderColumn
            titleWidth="100px"
            filterWidth="312px"
            title="Supplier"
          >
            <SupplierSelect
              value={searchFilters.supplier}
              onChange={handlers.supplier}
              isClearable
              size="small"
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn titleWidth="120px" title="Invoice Number">
            <InvoicesSelect
              value={searchFilters.invoiceNumber}
              onChange={handlers.invoiceNumber}
              width="100%"
              isReportOnly
              size="small"
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn titleWidth="120px" title="Invoice Status">
            <Select {...invoiceStatusSelectProps} />
          </SearchHeaderColumn>
        </FlexSpaceBetween>
        <FlexSpaceBetween>
          <SearchHeaderColumn
            titleWidth="100px"
            filterWidth="312px"
            title="Invoice Date"
          >
            <DateRangePicker
              value={searchFilters.invoiceDate}
              onChange={handlers.invoiceDate}
              width="100%"
              size="small"
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn titleWidth="120px" title="Only Credits">
            <CheckboxContainerS>
              <Checkbox
                name="creditCheckbox"
                onChange={handlers.creditsOnly}
                value={searchFilters.creditsOnly}
              />
            </CheckboxContainerS>
          </SearchHeaderColumn>
          <SearchHeaderColumn filterWidth="360px">
            <JustifyFlexEnd>
              <HeaderLinksWrapper>
                <SupplierInvoicesExportPDFBtn
                  data={data}
                  disabled={exportDisabled}
                />
                <SupplierInvoicesExportXlsBtn
                  data={data}
                  disabled={exportDisabled}
                />
              </HeaderLinksWrapper>

              <SearchHeaderButton
                size="small"
                styleType="basicPrimary"
                onClick={onResetFilters}
              >
                Reset
              </SearchHeaderButton>
              <SearchHeaderButton
                loader={loading}
                size="small"
                onClick={onSearch}
              >
                Search
              </SearchHeaderButton>
            </JustifyFlexEnd>
          </SearchHeaderColumn>
        </FlexSpaceBetween>
      </FiltersContainerS>
    </SearchPageHeaderContainer>
  );
}

SearchSupplierInvoicesPageHeader.propTypes = {
  setQuery: func.isRequired,
  loading: bool,
  data: searchSupplierInvoicesDataPropType,
};
