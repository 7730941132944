import React from 'react';
import { defaultTheme } from '../ThemeProvider/index.js';

/* eslint-disable max-len, react/jsx-closing-bracket-location, react/no-unknown-property, react/prop-types */
export const fileIcons = {
  ai: ({ ...props }) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      fill="enable-background:new 0 0 512 512;"
    >
      <path
        fill="#E2E5E7"
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        fill="#B0B7BD"
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon fill="#CAD1D8" points="480,224 384,128 480,128" />
      <path
        fill="#F7B84E"
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16V416z"
      />
      <g>
        <path
          fill={defaultTheme.colors.white}
          d="M164.224,384c-4.096-2.32-6.656-6.912-4.096-12.288l36.704-71.76c3.456-6.784,12.672-7.04,15.872,0l36.064,71.76c5.248,9.968-10.24,17.904-14.832,7.936l-5.648-11.264h-47.2l-5.504,11.264C173.552,384,168.816,384.912,164.224,384zM219.216,351.504l-14.448-31.6l-15.728,31.6H219.216z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M264.048,303.152c0-10.496,16.896-10.88,16.896,0v73.024c0,10.624-16.896,10.88-16.896,0V303.152z"
        />
      </g>
      <path
        fill="#CAD1D8"
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  bmp: ({ ...props }) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      fill="enable-background:new 0 0 512 512;"
    >
      <path
        fill="#E2E5E7"
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        fill="#B0B7BD"
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon fill="#CAD1D8" points="480,224 384,128 480,128 " />
      <path
        fill="#84BD5A"
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16V416z"
      />
      <g>
        <path
          fill={defaultTheme.colors.white}
          d="M99.968,384c-4.608,0-7.808-3.456-7.808-7.936v-72.656c0-4.608,3.2-7.936,7.808-7.936h35.952c16.768,0,25.84,11.392,25.84,24.432c0,5.744-1.664,11.392-7.024,16.128c10.096,3.968,14.576,11.76,14.576,21.232c-0.016,14.704-10,26.736-29.184,26.736H99.968z M135.904,311.072h-26.992v19.056h26.992c5.504,0,8.96-3.456,8.96-10.24C144.864,315.68,141.408,311.072,135.904,311.072z M108.912,368.384h31.216c14.848,0,14.848-22.64,0-22.64c-9.712,0-21.104,0-31.216,0V368.384z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M201.456,327.84v47.328c0,5.648-4.608,8.832-9.2,8.832c-4.096,0-7.68-3.184-7.68-8.832v-72.016c0-6.656,5.648-8.848,7.68-8.848c3.696,0,5.872,2.192,8.048,4.624l28.16,37.984l29.152-39.408c4.24-5.232,14.592-3.2,14.592,5.648v72.016c0,5.648-3.584,8.832-7.664,8.832c-4.608,0-8.192-3.184-8.192-8.832V327.84l-21.248,26.864c-4.592,5.648-10.352,5.648-14.576,0L201.456,327.84z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M290.176,303.152c0-4.224,3.328-8.848,8.704-8.848h29.552c16.64,0,31.616,11.136,31.616,32.496c0,20.224-14.976,31.472-31.616,31.472h-21.36v16.896c0,5.648-3.584,8.832-8.192,8.832c-4.224,0-8.704-3.184-8.704-8.832L290.176,303.152L290.176,303.152z M307.056,310.432v31.856h21.36c8.576,0,15.36-7.552,15.36-15.488c0-8.96-6.784-16.368-15.36-16.368L307.056,310.432L307.056,310.432z"
        />
      </g>
      <path
        fill="#CAD1D8"
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  css: ({ ...props }) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      fill="enable-background:new 0 0 512 512;"
    >
      <path
        fill="#E2E5E7"
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        fill="#B0B7BD"
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon fill="#CAD1D8" points="480,224 384,128 480,128 " />
      <path
        fill="#576D7E"
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16V416z"
      />
      <g>
        <path
          fill={defaultTheme.colors.white}
          d="M103.936,339.088c0-24.688,15.472-45.92,44.912-45.92c11.12,0,19.952,3.328,29.296,11.392c3.456,3.184,3.824,8.816,0.368,12.4c-3.456,3.056-8.704,2.688-11.76-0.384c-5.248-5.504-10.624-7.024-17.904-7.024c-19.696,0-29.168,13.952-29.168,29.552c0,15.872,9.344,30.448,29.168,30.448c7.28,0,14.064-2.96,19.952-8.192c3.968-3.072,9.472-1.552,11.76,1.536c2.048,2.816,3.072,7.552-1.408,12.016c-8.96,8.336-19.696,10-30.32,10C117.888,384.912,103.936,363.776,103.936,339.088z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M195.712,314.656c2.944-24.816,40.416-29.28,58.08-15.712c8.704,7.024-0.512,18.16-8.192,12.528c-9.472-6.016-30.96-8.832-33.648,4.464c-3.456,20.992,52.192,8.976,51.296,42.992c-0.896,32.496-47.968,33.264-65.632,18.672c-4.24-3.44-4.096-9.056-1.792-12.528c3.328-3.312,7.024-4.464,11.392-0.896c10.48,7.168,37.488,12.544,39.392-5.648C244.976,339.616,191.744,351.008,195.712,314.656z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M276.48,314.656c2.944-24.816,40.416-29.28,58.08-15.712c8.704,7.024-0.512,18.16-8.192,12.528c-9.472-6.016-30.96-8.832-33.648,4.464c-3.456,20.992,52.192,8.976,51.296,42.992c-0.896,32.496-47.968,33.264-65.632,18.672c-4.224-3.44-4.096-9.056-1.792-12.528c3.328-3.312,7.024-4.464,11.392-0.896c10.48,7.168,37.488,12.544,39.408-5.648C325.728,339.616,272.512,351.008,276.48,314.656z"
        />
      </g>
      <path
        fill="#CAD1D8"
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  dat: ({ ...props }) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      fill="enable-background:new 0 0 512 512;"
    >
      <path
        fill="#E2E5E7"
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        fill="#B0B7BD"
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon fill="#CAD1D8" points="480,224 384,128 480,128 " />
      <path
        fill="#84BD5A"
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16V416z"
      />
      <g>
        <path
          fill={defaultTheme.colors.white}
          d="M110.272,384c-4.224,0-8.832-2.304-8.832-7.92v-72.672c0-4.608,4.608-7.936,8.832-7.936h29.296c58.464,0,57.184,88.528,1.152,88.528H110.272z M118.336,311.072v57.312h21.232c34.544,0,36.08-57.312,0-57.312H118.336z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M196.288,384c-4.096-2.304-6.64-6.912-4.096-12.288l36.72-71.76c3.456-6.784,12.672-7.024,15.872,0l36.064,71.76c5.248,9.984-10.24,17.904-14.832,7.936l-5.648-11.264h-47.2l-5.504,11.264C205.632,384,200.896,384.912,196.288,384zM251.296,351.52l-14.448-31.616L221.12,351.52H251.296z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M305.584,311.472H283.2c-11.136,0-11.136-16.368,0-16.368h60.496c11.392,0,11.392,16.368,0,16.368h-21.232v64.608c0,11.12-16.896,11.376-16.896,0v-64.608H305.584z"
        />
      </g>
      <path
        fill="#CAD1D8"
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  doc: ({ ...props }) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      fill="enable-background:new 0 0 512 512;"
    >
      <path
        fill="#E2E5E7"
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        fill="#B0B7BD"
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon fill="#CAD1D8" points="480,224 384,128 480,128 " />
      <path
        fill="#50BEE8"
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16V416z"
      />
      <g>
        <path
          fill={defaultTheme.colors.white}
          d="M92.576,384c-4.224,0-8.832-2.32-8.832-7.936v-72.656c0-4.608,4.608-7.936,8.832-7.936h29.296c58.464,0,57.168,88.528,1.136,88.528H92.576z M100.64,311.072v57.312h21.232c34.544,0,36.064-57.312,0-57.312H100.64z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M228,385.28c-23.664,1.024-48.24-14.72-48.24-46.064c0-31.472,24.56-46.944,48.24-46.944c22.384,1.136,45.792,16.624,45.792,46.944C273.792,369.552,250.384,385.28,228,385.28z M226.592,308.912c-14.336,0-29.936,10.112-29.936,30.32c0,20.096,15.616,30.336,29.936,30.336c14.72,0,30.448-10.24,30.448-30.336C257.04,319.008,241.312,308.912,226.592,308.912z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M288.848,339.088c0-24.688,15.488-45.92,44.912-45.92c11.136,0,19.968,3.328,29.296,11.392c3.456,3.184,3.84,8.816,0.384,12.4c-3.456,3.056-8.704,2.688-11.776-0.384c-5.232-5.504-10.608-7.024-17.904-7.024c-19.696,0-29.152,13.952-29.152,29.552c0,15.872,9.328,30.448,29.152,30.448c7.296,0,14.08-2.96,19.968-8.192c3.952-3.072,9.456-1.552,11.76,1.536c2.048,2.816,3.056,7.552-1.408,12.016c-8.96,8.336-19.696,10-30.336,10C302.8,384.912,288.848,363.776,288.848,339.088z"
        />
      </g>
      <path
        fill="#CAD1D8"
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  gif: ({ ...props }) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      fill="enable-background:new 0 0 512 512;"
    >
      <path
        fill="#E2E5E7"
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        fill="#B0B7BD"
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon fill="#CAD1D8" points="480,224 384,128 480,128 " />
      <path
        fill="#A066AA"
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16V416z"
      />
      <g>
        <path
          fill={defaultTheme.colors.white}
          d="M199.84,374.16c-9.088,7.536-20.224,10.752-31.472,10.752c-26.88,0-45.936-15.36-45.936-45.808c0-25.84,20.096-45.92,47.072-45.92c10.112,0,21.232,3.456,29.168,11.264c7.808,7.664-3.456,19.056-11.12,12.288c-4.736-4.624-11.392-8.064-18.048-8.064c-15.472,0-30.432,12.4-30.432,30.432c0,18.944,12.528,30.448,29.296,30.448c7.792,0,14.448-2.304,19.184-5.76V348.08h-19.184c-11.392,0-10.24-15.632,0-15.632h25.584c4.736,0,9.072,3.6,9.072,7.568v27.248C203.024,369.552,202.016,371.712,199.84,374.16z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M224.944,303.152c0-10.496,16.896-10.88,16.896,0v73.024c0,10.624-16.896,10.88-16.896,0V303.152z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M281.12,312.096v20.336h32.608c4.608,0,9.216,4.608,9.216,9.088c0,4.224-4.608,7.664-9.216,7.664H281.12v26.864c0,4.48-3.2,7.936-7.68,7.936c-5.632,0-9.072-3.456-9.072-7.936v-72.656c0-4.608,3.456-7.936,9.072-7.936h44.912c5.632,0,8.96,3.328,8.96,7.936c0,4.096-3.328,8.688-8.96,8.688H281.12V312.096z"
        />
      </g>
      <path
        fill="#CAD1D8"
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  html: ({ ...props }) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      fill="enable-background:new 0 0 512 512;"
    >
      <path
        fill="#E2E5E7"
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        fill="#B0B7BD"
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon fill="#CAD1D8" points="480,224 384,128 480,128 " />
      <path
        fill="#84BD5A"
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16V416z"
      />
      <g>
        <path
          fill={defaultTheme.colors.white}
          d="M55.68,376.064v-72.656c0-4.608,3.328-7.936,9.072-7.936c4.48,0,7.808,3.328,7.808,7.936v27.888h41.696v-27.888c0-4.608,4.096-7.936,8.704-7.936c4.48,0,7.936,3.328,7.936,7.936v72.656c0,4.48-3.456,7.936-7.936,7.936c-4.608,0-8.704-3.456-8.704-7.936v-28H72.56v28c0,4.48-3.328,7.936-7.808,7.936C59.008,384,55.68,380.544,55.68,376.064z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M172.784,311.472H150.4c-11.136,0-11.136-16.368,0-16.368h60.496c11.392,0,11.392,16.368,0,16.368h-21.232v64.592c0,11.12-16.896,11.392-16.896,0v-64.592H172.784z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M248.688,327.84v47.328c0,5.648-4.608,8.832-9.2,8.832c-4.096,0-7.68-3.184-7.68-8.832v-72.016c0-6.656,5.648-8.848,7.68-8.848c3.696,0,5.872,2.192,8.048,4.624l28.16,37.984l29.152-39.408c4.24-5.232,14.592-3.2,14.592,5.648v72.016c0,5.648-3.6,8.832-7.68,8.832c-4.592,0-8.192-3.184-8.192-8.832V327.84l-21.232,26.864c-4.592,5.648-10.352,5.648-14.576,0L248.688,327.84z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M337.264,303.152c0-4.224,3.584-7.808,8.064-7.808c4.096,0,7.552,3.6,7.552,7.808v64.096h34.8c12.528,0,12.8,16.752,0,16.752h-42.336c-4.48,0-8.064-3.184-8.064-7.808v-73.04H337.264z"
        />
      </g>
      <path
        fill="#CAD1D8"
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  jpg: ({ ...props }) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      fill="enable-background:new 0 0 512 512;"
    >
      <path
        fill="#E2E5E7"
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        fill="#B0B7BD"
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon fill="#CAD1D8" points="480,224 384,128 480,128 " />
      <path
        fill="#50BEE8"
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16V416z"
      />
      <g>
        <path
          fill={defaultTheme.colors.white}
          d="M141.968,303.152c0-10.752,16.896-10.752,16.896,0v50.528c0,20.096-9.6,32.256-31.728,32.256c-10.88,0-19.952-2.96-27.888-13.184c-6.528-7.808,5.76-19.056,12.416-10.88c5.376,6.656,11.136,8.192,16.752,7.936c7.152-0.256,13.44-3.472,13.568-16.128v-50.528H141.968z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M181.344,303.152c0-4.224,3.328-8.832,8.704-8.832H219.6c16.64,0,31.616,11.136,31.616,32.48c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.584,8.816-8.192,8.816c-4.224,0-8.704-3.184-8.704-8.816L181.344,303.152L181.344,303.152z M198.24,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504c0-8.944-6.784-16.368-15.36-16.368H198.24z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M342.576,374.16c-9.088,7.552-20.224,10.752-31.472,10.752c-26.88,0-45.936-15.344-45.936-45.808c0-25.824,20.096-45.904,47.072-45.904c10.112,0,21.232,3.44,29.168,11.248c7.792,7.664-3.456,19.056-11.12,12.288c-4.736-4.608-11.392-8.064-18.048-8.064c-15.472,0-30.432,12.4-30.432,30.432c0,18.944,12.528,30.464,29.296,30.464c7.792,0,14.448-2.32,19.184-5.76V348.08h-19.184c-11.392,0-10.24-15.616,0-15.616h25.584c4.736,0,9.072,3.584,9.072,7.552v27.248C345.76,369.568,344.752,371.712,342.576,374.16z"
        />
      </g>
      <path
        fill="#CAD1D8"
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  pdf: ({ ...props }) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      fill="enable-background:new 0 0 512 512;"
    >
      <path
        fill="#E2E5E7"
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        fill="#B0B7BD"
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon fill="#CAD1D8" points="480,224 384,128 480,128 " />
      <path
        fill="#F15642"
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16V416z"
      />
      <g>
        <path
          fill={defaultTheme.colors.white}
          d="M101.744,303.152c0-4.224,3.328-8.832,8.688-8.832h29.552c16.64,0,31.616,11.136,31.616,32.48c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.584,8.816-8.192,8.816c-4.224,0-8.688-3.184-8.688-8.816V303.152zM118.624,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504c0-8.944-6.784-16.368-15.36-16.368H118.624z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M196.656,384c-4.224,0-8.832-2.304-8.832-7.92v-72.672c0-4.592,4.608-7.936,8.832-7.936h29.296c58.464,0,57.184,88.528,1.152,88.528H196.656z M204.72,311.088V368.4h21.232c34.544,0,36.08-57.312,0-57.312H204.72z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M303.872,312.112v20.336h32.624c4.608,0,9.216,4.608,9.216,9.072c0,4.224-4.608,7.68-9.216,7.68h-32.624v26.864c0,4.48-3.184,7.92-7.664,7.92c-5.632,0-9.072-3.44-9.072-7.92v-72.672c0-4.592,3.456-7.936,9.072-7.936h44.912c5.632,0,8.96,3.344,8.96,7.936c0,4.096-3.328,8.704-8.96,8.704h-37.248V312.112z"
        />
      </g>
      <path
        fill="#CAD1D8"
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  png: ({ ...props }) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      fill="enable-background:new 0 0 512 512;"
    >
      <path
        fill="#E2E5E7"
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        fill="#B0B7BD"
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon fill="#CAD1D8" points="480,224 384,128 480,128 " />
      <path
        fill="#A066AA"
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16V416z"
      />
      <g>
        <path
          fill={defaultTheme.colors.white}
          d="M92.816,303.152c0-4.224,3.312-8.848,8.688-8.848h29.568c16.624,0,31.6,11.136,31.6,32.496c0,20.224-14.976,31.472-31.6,31.472H109.68v16.896c0,5.648-3.552,8.832-8.176,8.832c-4.224,0-8.688-3.184-8.688-8.832C92.816,375.168,92.816,303.152,92.816,303.152z M109.68,310.432v31.856h21.376c8.56,0,15.344-7.552,15.344-15.488c0-8.96-6.784-16.368-15.344-16.368L109.68,310.432L109.68,310.432z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M178.976,304.432c0-4.624,1.024-9.088,7.68-9.088c4.592,0,5.632,1.152,9.072,4.464l42.336,52.976v-49.632c0-4.224,3.696-8.848,8.064-8.848c4.608,0,9.072,4.624,9.072,8.848v72.016c0,5.648-3.456,7.792-6.784,8.832c-4.464,0-6.656-1.024-10.352-4.464l-42.336-53.744v49.392c0,5.648-3.456,8.832-8.064,8.832s-8.704-3.184-8.704-8.832v-70.752H178.976z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M351.44,374.16c-9.088,7.536-20.224,10.752-31.472,10.752c-26.88,0-45.936-15.36-45.936-45.808c0-25.84,20.096-45.92,47.072-45.92c10.112,0,21.232,3.456,29.168,11.264c7.808,7.664-3.456,19.056-11.12,12.288c-4.736-4.624-11.392-8.064-18.048-8.064c-15.472,0-30.432,12.4-30.432,30.432c0,18.944,12.528,30.448,29.296,30.448c7.792,0,14.448-2.304,19.184-5.76V348.08h-19.184c-11.392,0-10.24-15.632,0-15.632h25.584c4.736,0,9.072,3.6,9.072,7.568v27.248C354.624,369.552,353.616,371.712,351.44,374.16z"
        />
      </g>
      <path
        fill="#CAD1D8"
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  ppt: ({ ...props }) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      fill="enable-background:new 0 0 512 512;"
    >
      <path
        fill="#E2E5E7"
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        fill="#B0B7BD"
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon fill="#CAD1D8" points="480,224 384,128 480,128 " />
      <path
        fill="#F15642"
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16V416z"
      />
      <g>
        <path
          fill={defaultTheme.colors.white}
          d="M105.456,303.152c0-4.224,3.328-8.832,8.688-8.832h29.552c16.64,0,31.616,11.136,31.616,32.48c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.568,8.816-8.176,8.816c-4.224,0-8.688-3.184-8.688-8.816v-72.032H105.456z M122.336,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504c0-8.944-6.784-16.368-15.36-16.368H122.336z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M191.616,303.152c0-4.224,3.328-8.832,8.704-8.832h29.552c16.64,0,31.616,11.136,31.616,32.48c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.584,8.816-8.192,8.816c-4.224,0-8.704-3.184-8.704-8.816V303.152z M208.496,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504c0-8.944-6.784-16.368-15.36-16.368H208.496z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M301.68,311.472h-22.368c-11.136,0-11.136-16.368,0-16.368h60.496c11.392,0,11.392,16.368,0,16.368h-21.232v64.608c0,11.12-16.896,11.392-16.896,0V311.472z"
        />
      </g>
      <path
        fill="#CAD1D8"
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  raw: ({ ...props }) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      fill="enable-background:new 0 0 512 512;"
    >
      <path
        fill="#E2E5E7"
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        fill="#B0B7BD"
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon fill="#CAD1D8" points="480,224 384,128 480,128 " />
      <path
        fill="#576D7E"
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16V416z"
      />
      <g>
        <path
          fill={defaultTheme.colors.white}
          d="M94.912,375.68c0,11.12-17.024,11.504-17.024,0.256V303.28c0-4.48,3.472-7.808,7.68-7.808H119.6c32.48,0,39.136,43.504,12.016,54.368l17.008,20.72c6.656,9.856-6.64,19.312-14.336,9.6l-19.312-27.632H94.912V375.68zM94.912,337.808H119.6c16.624,0,17.664-26.864,0-26.864H94.912V337.808z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M162.624,384c-4.096-2.32-6.656-6.912-4.096-12.288l36.704-71.76c3.456-6.784,12.672-7.04,15.872,0l36.064,71.76c5.248,9.968-10.24,17.904-14.832,7.936l-5.648-11.264h-47.2l-5.504,11.264C171.952,384,167.216,384.912,162.624,384zM217.632,351.504l-14.448-31.6l-15.728,31.6H217.632z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M341.248,353.424l19.056-52.704c3.84-10.352,19.312-5.504,15.488,5.632l-25.328,68.704c-2.32,7.296-4.48,9.472-8.832,9.472c-4.608,0-6.016-2.832-8.576-7.424L310.8,326.576l-21.248,49.76c-2.304,5.36-4.464,8.432-9.072,8.432c-4.464,0-6.784-3.072-8.832-8.704l-24.816-69.712c-3.84-11.504,12.4-15.728,15.728-5.632l18.944,52.704l22.64-52.704c3.056-7.808,11.12-8.192,14.448-0.368L341.248,353.424z"
        />
      </g>
      <path
        fill="#CAD1D8"
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  sql: ({ ...props }) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      fill="enable-background:new 0 0 512 512;"
    >
      <path
        fill="#E2E5E7"
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        fill="#B0B7BD"
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon fill="#CAD1D8" points="480,224 384,128 480,128 " />
      <path
        fill="#F15642"
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16V416z"
      />
      <g>
        <path
          fill={defaultTheme.colors.white}
          d="M98.128,314.672c2.944-24.832,40.416-29.296,58.064-15.728c8.704,7.024-0.496,18.16-8.192,12.528c-9.456-6-30.96-8.816-33.648,4.464c-3.456,20.992,52.208,8.976,51.296,43.008c-0.896,32.496-47.968,33.248-65.632,18.672c-4.224-3.456-4.096-9.072-1.776-12.544c3.312-3.312,7.024-4.464,11.376-0.88c10.496,7.152,37.488,12.528,39.408-5.648C147.376,339.632,94.16,351.008,98.128,314.672z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M265.488,369.424l2.048,2.416c8.432,7.68-2.56,20.224-11.136,12.16l-4.336-3.44c-6.656,4.592-14.448,6.784-24.816,6.784c-22.512,0-48.24-15.504-48.24-46.976s25.584-47.456,48.24-47.456c23.776,0,47.072,15.984,47.072,47.456C274.32,352.528,271.232,361.504,265.488,369.424z M257.792,340.368c0-20.336-15.984-30.688-30.56-30.688c-15.728,0-31.216,10.336-31.216,30.688c0,15.504,13.168,30.208,31.216,30.208c4.592,0,9.072-1.152,13.552-2.304l-14.576-13.44c-6.784-8.192,3.968-19.84,12.528-12.288l14.464,14.448C256.384,352.528,257.792,347.024,257.792,340.368z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M293.168,303.152c0-4.224,3.584-7.808,8.064-7.808c4.096,0,7.552,3.6,7.552,7.808v64.096h34.8c12.528,0,12.8,16.752,0,16.752h-42.336c-4.48,0-8.064-3.184-8.064-7.792v-73.056H293.168z"
        />
      </g>
      <path
        fill="#CAD1D8"
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  svg: ({ ...props }) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      fill="enable-background:new 0 0 512 512;"
    >
      <path
        fill="#E2E5E7"
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        fill="#B0B7BD"
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon fill="#CAD1D8" points="480,224 384,128 480,128 " />
      <path
        fill="#F7B84E"
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16V416z"
      />
      <g>
        <path
          fill={defaultTheme.colors.white}
          d="M96.816,314.656c2.944-24.816,40.416-29.28,58.08-15.712c8.704,7.024-0.512,18.16-8.192,12.528c-9.472-6.016-30.96-8.832-33.648,4.464c-3.456,20.992,52.192,8.976,51.312,42.992c-0.896,32.496-47.984,33.264-65.648,18.672c-4.224-3.44-4.096-9.056-1.792-12.528c3.328-3.312,7.04-4.464,11.392-0.896c10.48,7.168,37.488,12.544,39.392-5.648C146.064,339.616,92.848,351.008,96.816,314.656z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M209.12,378.256l-33.776-70.752c-4.992-10.112,10.112-18.416,15.728-7.808l11.392,25.712l14.704,33.776l14.448-33.776l11.392-25.712c5.12-9.712,19.952-3.584,15.616,7.04L226,378.256C223.056,386.32,213.984,388.224,209.12,378.256z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M345.76,374.16c-9.088,7.536-20.224,10.752-31.472,10.752c-26.88,0-45.936-15.36-45.936-45.808c0-25.84,20.096-45.92,47.072-45.92c10.112,0,21.232,3.456,29.168,11.264c7.792,7.664-3.456,19.056-11.12,12.288c-4.736-4.624-11.392-8.064-18.048-8.064c-15.472,0-30.432,12.4-30.432,30.432c0,18.944,12.528,30.448,29.296,30.448c7.792,0,14.448-2.304,19.184-5.76V348.08h-19.184c-11.392,0-10.24-15.632,0-15.632h25.584c4.736,0,9.072,3.6,9.072,7.568v27.248C348.96,369.552,347.936,371.712,345.76,374.16z"
        />
      </g>
      <path
        fill="#CAD1D8"
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  txt: ({ ...props }) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      fill="enable-background:new 0 0 512 512;"
    >
      <path
        fill="#E2E5E7"
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        fill="#B0B7BD"
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon fill="#CAD1D8" points="480,224 384,128 480,128 " />
      <path
        fill="#576D7E"
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16V416z"
      />
      <g>
        <path
          fill={defaultTheme.colors.white}
          d="M132.784,311.472H110.4c-11.136,0-11.136-16.368,0-16.368h60.512c11.392,0,11.392,16.368,0,16.368h-21.248v64.592c0,11.12-16.896,11.392-16.896,0v-64.592H132.784z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M224.416,326.176l22.272-27.888c6.656-8.688,19.568,2.432,12.288,10.752c-7.68,9.088-15.728,18.944-23.424,29.024l26.112,32.496c7.024,9.6-7.04,18.816-13.952,9.344l-23.536-30.192l-23.152,30.832c-6.528,9.328-20.992-1.152-13.68-9.856l25.696-32.624c-8.048-10.096-15.856-19.936-23.664-29.024c-8.064-9.6,6.912-19.44,12.784-10.48L224.416,326.176z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M298.288,311.472H275.92c-11.136,0-11.136-16.368,0-16.368h60.496c11.392,0,11.392,16.368,0,16.368h-21.232v64.592c0,11.12-16.896,11.392-16.896,0V311.472z"
        />
      </g>
      <path
        fill="#CAD1D8"
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  xls: ({ ...props }) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      fill="enable-background:new 0 0 512 512;"
    >
      <path
        fill="#E2E5E7"
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        fill="#B0B7BD"
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon fill="#CAD1D8" points="480,224 384,128 480,128 " />
      <path
        fill="#84BD5A"
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16V416z"
      />
      <g>
        <path
          fill={defaultTheme.colors.white}
          d="M144.336,326.192l22.256-27.888c6.656-8.704,19.584,2.416,12.288,10.736c-7.664,9.088-15.728,18.944-23.408,29.04l26.096,32.496c7.04,9.6-7.024,18.8-13.936,9.328l-23.552-30.192l-23.152,30.848c-6.528,9.328-20.992-1.152-13.696-9.856l25.712-32.624c-8.064-10.112-15.872-19.952-23.664-29.04c-8.048-9.6,6.912-19.44,12.8-10.464L144.336,326.192z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M197.36,303.152c0-4.224,3.584-7.808,8.064-7.808c4.096,0,7.552,3.6,7.552,7.808v64.096h34.8c12.528,0,12.8,16.752,0,16.752H205.44c-4.48,0-8.064-3.184-8.064-7.792v-73.056H197.36z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M272.032,314.672c2.944-24.832,40.416-29.296,58.08-15.728c8.704,7.024-0.512,18.16-8.192,12.528c-9.472-6-30.96-8.816-33.648,4.464c-3.456,20.992,52.192,8.976,51.296,43.008c-0.896,32.496-47.968,33.248-65.632,18.672c-4.24-3.456-4.096-9.072-1.792-12.544c3.328-3.312,7.024-4.464,11.392-0.88c10.48,7.152,37.488,12.528,39.392-5.648C321.28,339.632,268.064,351.008,272.032,314.672z"
        />
      </g>
      <path
        fill="#CAD1D8"
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  xml: ({ ...props }) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      fill="enable-background:new 0 0 512 512;"
    >
      <path
        fill="#E2E5E7"
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        fill="#B0B7BD"
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon fill="#CAD1D8" points="480,224 384,128 480,128 " />
      <path
        fill="#50BEE8"
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16V416z"
      />
      <g>
        <path
          fill={defaultTheme.colors.white}
          d="M131.28,326.176l22.272-27.888c6.64-8.688,19.568,2.432,12.288,10.752c-7.664,9.088-15.728,18.944-23.424,29.024l26.112,32.496c7.024,9.6-7.04,18.816-13.952,9.344l-23.536-30.192l-23.152,30.832c-6.528,9.328-20.992-1.152-13.68-9.856l25.712-32.624c-8.064-10.096-15.872-19.936-23.664-29.024c-8.064-9.6,6.912-19.44,12.784-10.48L131.28,326.176z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M201.264,327.84v47.328c0,5.648-4.608,8.832-9.2,8.832c-4.096,0-7.68-3.184-7.68-8.832v-72.016c0-6.656,5.648-8.848,7.68-8.848c3.696,0,5.872,2.192,8.048,4.624l28.16,37.984l29.152-39.408c4.24-5.232,14.592-3.2,14.592,5.648v72.016c0,5.648-3.584,8.832-7.664,8.832c-4.608,0-8.192-3.184-8.192-8.832V327.84l-21.248,26.864c-4.592,5.648-10.352,5.648-14.576,0L201.264,327.84z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M294.288,303.152c0-4.224,3.584-7.808,8.064-7.808c4.096,0,7.552,3.6,7.552,7.808v64.096h34.8c12.528,0,12.8,16.752,0,16.752h-42.336c-4.48,0-8.064-3.184-8.064-7.808v-73.04H294.288z"
        />
      </g>
      <path
        fill="#CAD1D8"
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  zip: ({ ...props }) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      fill="enable-background:new 0 0 512 512;"
    >
      <path
        fill="#E2E5E7"
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        fill="#B0B7BD"
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon fill="#CAD1D8" points="480,224 384,128 480,128 " />
      <path
        fill="#84BD5A"
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16V416z"
      />
      <g>
        <path
          fill={defaultTheme.colors.white}
          d="M132.64,384c-8.064,0-11.264-7.792-6.656-13.296l45.552-60.512h-37.76c-11.12,0-10.224-15.712,0-15.712h51.568c9.712,0,12.528,9.184,5.632,16.624l-43.632,56.656h41.584c10.24,0,11.52,16.256-1.008,16.256h-55.28V384z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M212.048,303.152c0-10.496,16.896-10.88,16.896,0v73.04c0,10.608-16.896,10.88-16.896,0V303.152z"
        />
        <path
          fill={defaultTheme.colors.white}
          d="M251.616,303.152c0-4.224,3.328-8.832,8.704-8.832h29.552c16.64,0,31.616,11.136,31.616,32.48c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.584,8.816-8.192,8.816c-4.224,0-8.704-3.184-8.704-8.816L251.616,303.152L251.616,303.152z M268.496,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504c0-8.944-6.784-16.368-15.36-16.368H268.496z"
        />
      </g>
      <path
        fill="#CAD1D8"
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
};
