import * as R from 'ramda';
import { string } from 'prop-types';
import React, { useMemo } from 'react';
import arrayMutators from 'final-form-arrays';
import { useHasUserAccessToProtectedSupplier } from '@poly/client-utils/src/suppliers/useHasUserAccessToUpdateSupplierProtected.js';
import { useHasUserAccessToUpdateSupplierProtected } from '@poly/client-utils';
import { UPDATE_SUPPLIER_PERMISSION } from '@poly/security/src/permissions.js';
import { usePristineSubscribe } from '@poly/client-routing';
import { FormPage, Loader } from '@poly/admin-book';

import { branchSupplierFormSections } from './formSections/branchSupplierFormSections.js';
import { useOnSubmitSetStopSubmitting } from '../../../hooks/useOnSubmitSetStopSubmitting.js';
import { useUpdateBranchSupplierMutation } from './hooks/useUpdateBranchSupplierMutation.js';
import { useBranchSupplierDetailsQuery } from './hooks/useBranchSupplierDetailsQuery.js';
import { supplierToEditForm } from '../../forms/supplierForm/index.js';

export function EditBranchSupplierForm({ formId, supplierUrl }) {
  const { data, loading } = useBranchSupplierDetailsQuery();

  const { protectedFieldsAccess, accessLoading } =
    useHasUserAccessToUpdateSupplierProtected();

  const hasAccessToUpdatePrivateSupplier = useHasUserAccessToProtectedSupplier(
    UPDATE_SUPPLIER_PERMISSION,
  );

  const branchSupplier = useMemo(
    () => R.pathOr({}, ['supplier'], data),
    [data],
  );

  const onSubmitHandler = useUpdateBranchSupplierMutation(
    protectedFieldsAccess,
  );

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  const pristineSubscribeProps = usePristineSubscribe();

  const formProps = {
    id: formId,
    onSubmit,
    mutators: arrayMutators,
    sections: branchSupplierFormSections(
      supplierUrl,
      protectedFieldsAccess,
      hasAccessToUpdatePrivateSupplier,
    ),
    initialValues: supplierToEditForm(branchSupplier),

    ...pristineSubscribeProps,
  };

  return loading || accessLoading ? <Loader /> : <FormPage {...formProps} />;
}

EditBranchSupplierForm.propTypes = {
  formId: string.isRequired,
  supplierUrl: string.isRequired,
};
