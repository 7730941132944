import styled from 'styled-components';
import { TextButton } from '@poly/admin-book';

export const OSIExportPDFButton = styled(TextButton)`
  font-size: 14px;
  display: inline-block;
`;

export const OSIExportExcelButton = styled(TextButton)`
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
`;
