import { gql } from '@apollo/client';
import { commonFileFields } from '@poly/admin-ui';

export const globalSearchQuery = gql`
  query globalSearchQuery($input: CollectionSearchParams) {
    searchProjects(input: $input) {
      hits {
        _id
        projectId
        type
        description
        status
        client {
          _id
          name
        }
        property {
          _id
          name
        }
      }
      total
      maxScore
    }

    searchRecurringProjects(input: $input) {
      hits {
        _id
        projectId
        description
        status
        client {
          _id
          name
        }
        property {
          _id
          name
        }
      }
      total
      maxScore
    }

    searchSuppliers(input: $input) {
      hits {
        _id
        cardNumber
        status
        company {
          name
          dba
          address {
            formatted_address
          }
        }
        misc {
          source
        }
        type
        remit {
          name
        }
        tax {
          exempt
          name
          w9File {
            url
          }
        }
        isVeteran
        mwbe {
          isWBE
          minority
        }
        documents {
          exempt
          wcFile {
            url
          }
          wcExp
          glFile {
            url
          }
          glExp
          scaFile {
            url
          }
          mbeCertificationFile {
            url
          }
          wbeCertificationFile {
            url
          }
          veteranCertificationFile {
            url
          }
        }
      }
      total
      maxScore
    }

    searchMasterSuppliers(input: $input) {
      hits {
        _id
        cardNumber
        status
        company {
          name
          dba
          address {
            formatted_address
          }
        }
        type
        remit {
          name
        }
        tax {
          name
          w9File {
            url
          }
        }
        documents {
          wcFile {
            url
          }
          wcExp
          glFile {
            url
          }
          glExp
          scaFile {
            url
          }
        }
      }
      total
      maxScore
    }

    searchProperties(input: $input) {
      hits {
        _id
        cardNumber
        name
        status
        client {
          _id
          name
        }
        address {
          formatted_address
        }
      }
      total
      maxScore
    }

    searchClients(input: $input) {
      hits {
        _id
        cardNumber
        status
        name
        address {
          formatted_address
        }
      }
      total
      maxScore
    }

    searchUsers(input: $input) {
      hits {
        _id
        status
        title
        fullName
        email
        profile {
          cellPhoneNumber
          workPhoneNumber
          workPhoneNumberExt
          faxPhoneNumber
        }
      }
      total
      maxScore
    }

    searchAssets(input: $input) {
      hits {
        _id
        type {
          _id
          name
        }
        name
        manufacturerDoc {
          _id
          name
        }
        qrCodeId
        equipmentType
        description
      }
      total
      maxScore
    }

    searchFiles(input: $input) {
      hits {
        ...commonFileFields
      }
      total
      maxScore
    }
  }

  ${commonFileFields}
`;
