import React from 'react';
import styled, { css } from 'styled-components';
import { func, instanceOf, shape } from 'prop-types';
import { useModalContext } from '@poly/admin-ui';
import {
  PageHeaderContainer,
  HeaderLinksWrapper,
  DateRangePicker,
  MainSubHeader,
  TextButton,
  MainHeader,
} from '@poly/admin-book';
import { useCurrentUserByStoreOrQuery } from '@poly/client-utils/src/hooks/useCurrentUserByStoreOrQuery.js';

import {
  AddTimesheetForm,
  addTimesheetFormId,
} from '../../modules/forms/timesheetForm/add.js';
import {
  getCustomRangeToButtons,
  customRangeButtons,
} from '../../modules/pagesHeaders/TimeSheetReportPageHeader/customDateRangeButtons.js';

const LeftHeaderSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const CenterHeaderSectionWrapper = styled(LeftHeaderSectionWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 45px;
`;

const MainSubHeaderS = styled(MainSubHeader)`
  margin: 0 0 5px 0;
`;

const HeaderLinksWrapperS = styled(HeaderLinksWrapper)`
  margin: 35px 0 0 0;
`;

function CurrentUserNameSubHeader(props) {
  const { user } = useCurrentUserByStoreOrQuery();
  return <MainSubHeader {...props}>{user?.fullName}</MainSubHeader>;
}

const headerStyles = css`
  height: 115px;
  z-index: 3;
`;

function AddNewTimeSheetBtn(props) {
  const { user } = useCurrentUserByStoreOrQuery();
  const { openModalForm, closeModal } = useModalContext();
  const onCancel = () => closeModal(addTimesheetFormId);
  const onClick = () =>
    openModalForm({
      id: addTimesheetFormId,
      title: 'Add Time',
      subTitle: user?.fullName,
      formId: addTimesheetFormId,
      btnCaption: 'Save',
      width: '740px',
      content: <AddTimesheetForm {...props} onCancel={onCancel} />,
    });

  return <TextButton onClick={onClick}>Add New</TextButton>;
}

export function TimesheetPageHeader({
  setTimesheetDateRange,
  timesheetDateRange,
  ...props
}) {
  return (
    <PageHeaderContainer {...{ headerStyles }}>
      <LeftHeaderSectionWrapper>
        <MainHeader>Timesheet</MainHeader>
        <CurrentUserNameSubHeader />
      </LeftHeaderSectionWrapper>
      <CenterHeaderSectionWrapper>
        <MainSubHeaderS>Pay Period</MainSubHeaderS>
        <DateRangePicker
          value={timesheetDateRange}
          onChange={setTimesheetDateRange}
          isClosedRange={false}
          {...{ customRangeButtons, getCustomRangeToButtons }}
        />
      </CenterHeaderSectionWrapper>
      <HeaderLinksWrapperS>
        <AddNewTimeSheetBtn {...props} />
      </HeaderLinksWrapperS>
    </PageHeaderContainer>
  );
}

TimesheetPageHeader.propTypes = {
  setTimesheetDateRange: func.isRequired,
  timesheetDateRange: shape({
    date: instanceOf(Date),
    startDate: instanceOf(Date).isRequired,
    endDate: instanceOf(Date).isRequired,
  }),
};
