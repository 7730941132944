import * as R from 'ramda';
import { Link } from '@poly/client-routing';
import styled, { css } from 'styled-components';
import { propEqLegacy } from '@poly/utils';

import {
  PopUpMenuItemStyles,
  DefaultPopUpContentWrapper,
} from '../PopUp/index.js';
import { getThemeProp, getThemeColor, getThemeFont } from '../utils.js';
import { Icon } from '../Icon/index.js';

const getPosition = R.ifElse(
  propEqLegacy('position', 'left'),
  R.always('left: 0;'),
  R.always(''),
);

const getColor = R.compose(
  R.ifElse(
    R.any(R.identity),
    () => getThemeColor(['white']),
    () => getThemeColor(['navBarLighter1']),
  ),
  R.props(['isActive', 'isOpen']),
);

const getWeight = R.compose(
  R.ifElse(
    R.any(R.identity),
    () => getThemeFont(['medium']),
    () => getThemeFont(['regular']),
  ),
  R.props(['isActive', 'isOpen']),
);

const getContent = R.compose(
  R.when(R.is(Array), R.prop(0)),
  R.prop('children'),
);

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 1600px;
  width: 100%;
  padding: 0 20px;
  flex-wrap: wrap;
`;

export const PopUpWrapper = styled.div`
  display: flex;
  height: 100%;

  > div {
    height: 100%;
    width: 100%;
  }
`;

export const PopUpElementContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
`;

export const PopUpContentWrapper = styled(DefaultPopUpContentWrapper)`
  width: ${R.prop('width')};
  ${getPosition};
`;

export const PopUpMenuItemS = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 7px 10px;
  font-size: 14px;
  line-height: 17px;
  font-weight: ${getThemeFont(['regular'])};
  color: ${getThemeColor(['primaryLighter'])};
  transition: all ${getThemeProp(['defaultTransition'])};
  letter-spacing: 1px;

  &:hover {
    color: ${getThemeColor(['primary'])};
    background-color: ${getThemeColor(['secondaryLighter5'])};
  }
`;

export const PopUpMenuItemCounter = styled.div`
  display: flex;
  height: 20px;
  min-width: 20px;
  position: relative;
  justify-content: end;
  font-weight: ${getThemeFont(['semibold'])};

  ${R.prop('counterStyles')};
`;

export const AppBarMenuItem = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: 10px ${({ isSub }) => (isSub ? 18 : 0)}px 10px 24px;
  font-weight: ${getWeight};
  line-height: 18px;
  color: ${getColor};
  cursor: pointer;
  transition: all ${getThemeProp(['defaultTransition'])};

  &:after {
    display: flex;
    content: '${getContent}';
    font-weight: ${getThemeFont(['medium'])};
    height: 0;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  }

  &:hover {
    color: ${getThemeColor(['white'])};
    font-weight: ${getThemeFont(['medium'])};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &:before {
        width: calc(100% - ${({ isSub }) => (isSub ? 40 : 22)}px);
        height: 4px;
        content: '';
        position: absolute;
        bottom: 0;
        left: 24px;
        background-color: ${getThemeColor(['secondaryMid'])};
      }
    `};
`;

AppBarMenuItem.displayName = 'AppBarMenuItem';

export const MenuSubItem = styled(AppBarMenuItem)`
  ${PopUpMenuItemStyles};

  cursor: initial;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  letter-spacing: 1px;

  &:after {
    display: none;
  }
`;

MenuSubItem.displayName = 'MenuSubItem';

export const MenuItemLink = styled(Link)`
  height: 100%;
  text-decoration: none;
`;

MenuItemLink.displayName = 'MenuItemLink';

export const AppBar = styled.div`
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;
  background-color: ${getThemeColor(['primaryMid'])};
  width: 100%;
  z-index: 10000;
`;

AppBar.displayName = 'AppBar';

export const NotificationDotS = styled.div`
  display: ${(props) => (props.children ? 'block' : 'none')};
  position: absolute;
  top: 1px;
  background-color: rgb(255, 99, 99);
  left: 21px;
  right: unset;
  padding: 0 6px 1.4px 6px;
  color: #ffffff;
  font-size: 11px;
  min-width: 15px;
  text-align: center;
  line-height: 12px;
  font-weight: bold;
  border-radius: 17px;
`;

NotificationDotS.displayName = 'NotificationDot';

export const ItemIconS = styled(Icon)`
  position: absolute;
  right: 0;
  top: calc(50% - 6px);
`;
