import { isClientInvoiceAmountValid } from '@poly/utils';
import * as R from 'ramda';

// isNegativeTotalsOutOfRange :: Totals -> Boolean
// Totals = {
//    suggestedClientInvoiceAmount: Float
//    total: Float
// }
export const isNegativeTotalsOutOfRange = R.both(
  R.compose(R.lt(R.__, 0), R.propOr(0, 'suggestedClientInvoiceAmount')),
  R.converge(R.lt, [
    R.propOr(0, 'jobCost'),
    R.propOr(0, 'suggestedClientInvoiceAmount'),
  ]),
);

// isPositiveTotalsOutOfRange :: Totals -> Boolean
export const isPositiveTotalsOutOfRange = R.both(
  R.compose(R.gte(R.__, 0), R.propOr(0, 'suggestedClientInvoiceAmount')),
  R.converge(R.gt, [
    R.propOr(0, 'jobCost'),
    R.propOr(0, 'suggestedClientInvoiceAmount'),
  ]),
);

// isZeroJobCostAndClientInvoice :: Totals -> Boolean
export const isZeroJobCostAndClientInvoice = R.both(
  R.compose(R.equals(0), R.propOr(0, 'suggestedClientInvoiceAmount')),
  R.compose(R.equals(0), R.propOr(0, 'jobCost')),
);

// isTotalsOutOfRange :: Totals -> Boolean
export const isTotalsOutOfRange = R.anyPass([
  isNegativeTotalsOutOfRange,
  isPositiveTotalsOutOfRange,
  isZeroJobCostAndClientInvoice,
]);

// isAmountInvalid :: Project -> Boolean
export const isClientInvoiceAmountInvalid = R.compose(
  R.not,
  isClientInvoiceAmountValid,
  R.prop('suggestedClientInvoiceAmount'),
);

// isProjectInvoiceAmountInvalid :: Project -> Boolean
export const isProjectInvoiceAmountInvalid = R.either(
  isClientInvoiceAmountInvalid,
  isTotalsOutOfRange,
);

// getProjectInvoiceAmountErrorMessage :: Project -> String
export const getProjectInvoiceAmountErrorMessage = R.cond([
  [isPositiveTotalsOutOfRange, R.always('inv. amount < job cost')],
  [isNegativeTotalsOutOfRange, R.always('inv. amount > job cost')],
  [isZeroJobCostAndClientInvoice, R.always('$0 invoices')],
  [isClientInvoiceAmountInvalid, R.always('Invalid invoice amount')],
  [R.T, R.always('Unknown')],
]);
