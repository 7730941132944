import * as R from 'ramda';
import React from 'react';
import { number, object, shape } from 'prop-types';
import { LinkButtonWithLoader } from '@poly/admin-book';
import {
  ExcelExportCellType,
  createExcelExportCell,
  performExcelExport,
} from '@poly/client-utils';
import { EXPORT_XLS_CAPTION, ProjectTypeToNameMap } from '@poly/constants';
import styled from 'styled-components';
import {
  applyProp,
  formatDate,
  forceTitleCase,
  convertCentsToDollars,
} from '@poly/utils';

import {
  calculateWIPReportTotal,
  prepareWipRows,
  propOrEmptyUi,
  wipRecordsPropTypes,
  wipTableSortQueries,
} from './WorkInProgressTable.js';
import { sortTableRowsByConfig } from '../WorkInProgressQCReport/helpers.js';

export const WorkInProgressExportXLSBtnS = styled(LinkButtonWithLoader)`
  margin-right: 10px;
`;

// getWIPReportRowConfig :: [WipRow] -> [ExcelExportDataCell]
const getWIPReportRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 6),
    ...R.repeat(ExcelExportCellType.defaultCurrency, 3),
    ExcelExportCellType.default,
  ]),
  R.juxt([
    propOrEmptyUi(R.path(['project', 'projectId'])),
    R.compose(R.prop(R.__, ProjectTypeToNameMap), R.path(['project', 'type'])),
    propOrEmptyUi(R.path(['client', 'name'])),
    propOrEmptyUi(applyProp(forceTitleCase)('projectAccountingStatus')),
    propOrEmptyUi(R.compose(formatDate, R.path(['project', 'endDate']))),
    R.ifElse(
      R.compose(R.isNil, R.prop('name')),
      R.path(['supplier', 'company', 'name']),
      R.prop('name'),
    ),
    R.compose(convertCentsToDollars, R.prop('total')),
    R.compose(
      convertCentsToDollars,
      R.when(R.isNil, R.always(0)),
      R.prop('markup'),
    ),
    propOrEmptyUi(
      R.compose(
        R.unless(R.isNil, convertCentsToDollars),
        R.prop('suggestedClientInvoiceAmount'),
      ),
    ),
    propOrEmptyUi(R.path(['manager', 'fullName'])),
  ]),
);

// getTotalRowConfig :: [WipRow] -> [ExcelExportDataCell]
const getTotalRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.tableStringFooter, 5),
    ...R.repeat(ExcelExportCellType.tableCurrencyFooter, 3),
    ExcelExportCellType.tableStringFooter,
  ]),
  R.unnest,
  R.juxt([
    R.always(R.repeat('', 5)),
    R.always('Total'),
    R.prop('supplierInvoiceTotal'),
    R.prop('markup'),
    R.prop('total'),
    R.always(''),
  ]),
  calculateWIPReportTotal,
);

// eslint-disable-next-line import/no-unused-modules
export const getWorkInProgressExportExcelConfig = ({
  title,
  wipRowsSorted,
}) => ({
  exportFileName: 'wip-report.xlsx',
  columnWidths: [15, 15, 20, 25, 15, 35, 15, 15, 15, 20],
  rows: [
    [
      {
        cellType: ExcelExportCellType.title,
        value: title,
      },
    ],
    R.zipWith(
      createExcelExportCell,
      [
        ...R.repeat(ExcelExportCellType.tableHeader, 6),
        ...R.repeat(ExcelExportCellType.tableCurrencyHeader, 3),
        ExcelExportCellType.tableHeader,
      ],
      [
        'Project #',
        'Project Type',
        'Client',
        'Acct Status',
        'End Date',
        'Supplier',
        'Amount',
        'Markup',
        /* eslint-disable-next-line @cspell/spellchecker */
        'Sugg.Total',
        'Assigned CSR',
      ],
    ),
    ...R.map(getWIPReportRowConfig, wipRowsSorted),
    getTotalRowConfig(wipRowsSorted),
  ],
});

const sortWipRecords = sortTableRowsByConfig(wipTableSortQueries);

export function WorkInProgressExportXLSBtn({
  queryFilters,
  sorting,
  wipRecords,
}) {
  const onExportClick = () => {
    const wipRows = prepareWipRows(wipRecords);

    const wipRowsSorted = sortWipRecords(wipRows, sorting);

    const excelConfig = getWorkInProgressExportExcelConfig({
      title: `Work In Progress Report ${formatDate(queryFilters.date)}`,
      wipRecords,
      wipRowsSorted,
    });
    performExcelExport(excelConfig);
  };

  return (
    <WorkInProgressExportXLSBtnS onClick={onExportClick}>
      {EXPORT_XLS_CAPTION}
    </WorkInProgressExportXLSBtnS>
  );
}

WorkInProgressExportXLSBtn.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  queryFilters: object,
  wipRecords: wipRecordsPropTypes,
  sorting: shape({
    columnKey: number.isRequired,
    dir: number.isRequired,
  }),
};
