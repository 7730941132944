import { prop } from 'ramda';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { number, string } from 'prop-types';
import { getThemeColor } from '../utils.js';

const circleAnimation = ({ size }) => keyframes`
  0% {
    transform: rotate(0deg);
    stroke-dashoffset: ${0.66 * size};
  }

  50% {
    transform: rotate(720deg);
    stroke-dashoffset: ${3.14 * size};
  }

  100% {
    transform: rotate(1080deg);
    stroke-dashoffset: ${0.66 * size};
  }
`;

const Base = styled.svg.attrs((props) => ({
  width: prop('size')(props),
  height: prop('size')(props),
  'data-testid': 'loader',
}))`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
`;

const Circle = styled.circle.attrs(({ size }) => ({
  cx: size / 2,
  cy: size / 2,
  // prettier-ignore
  r: (size / 2) - 2,
}))`
  fill: none;
  stroke: ${getThemeColor(['accent2'])};
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke-linecap: round;
  stroke-dasharray: ${({ size }) => 3.14 * size};
  animation: ${(props) => circleAnimation(props)} 2.5s linear infinite;
  transform-origin: ${({ size }) => `${size / 2}px ${size / 2}px 0`};
`;

export function Loader({ size, className }) {
  return (
    <Base size={size} className={className}>
      <Circle size={size} />
    </Base>
  );
}

Loader.displayName = 'Loader';
Loader.propTypes = {
  size: number,
  className: string,
};

Loader.defaultProps = {
  size: 50,
  className: '',
};
