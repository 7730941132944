import { isEqual, startOfDay } from 'date-fns';
import * as R from 'ramda';
import { PROJECT_QUEUE_STATUS_LABEL } from '@poly/constants';
import { alwaysNewDate, ensureIsDate } from '@poly/utils';

// isToday :: Date -> Boolean
const isToday = R.converge(isEqual, [
  R.compose(startOfDay, ensureIsDate),
  R.compose(startOfDay, alwaysNewDate),
]);

// getDateColor :: Date -> [String]
export const getDateColor = (date) =>
  isToday(date) ? ['accent1Lighter1'] : ['primary'];

// setDefaultProjectManager :: [Project] => [Project]
export const setDefaultProjectManager = R.map(
  R.over(
    R.lensPath(['manager', 'fullName']),
    R.defaultTo(PROJECT_QUEUE_STATUS_LABEL),
  ),
);
