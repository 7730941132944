import { createAction, handleAction } from 'redux-actions';

import { getStartOfWeek, getEndOfWeek } from './utils.js';

const timeSheetReportCurrentWeek = {
  startDate: getStartOfWeek(new Date()),
  endDate: getEndOfWeek(new Date()),
};
const initialValues = {
  ownerId: '',
  ...timeSheetReportCurrentWeek,
};

export const initialTimeSheetReport = {
  ...initialValues,
  filteredConfig: initialValues,
};

export const setTimeSheetReport = createAction('SET_TIME_SHEET_REPORT');

export const timeSheetReportReducer = handleAction(
  setTimeSheetReport,
  (_, { payload }) => payload,
  initialTimeSheetReport,
);
