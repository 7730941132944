import { Modal } from '@poly/admin-book';
import { bool, func, node, object, string, oneOfType } from 'prop-types';
import React from 'react';

export function ActiveModal({
  id,
  root,
  simple,
  isOpen,
  Layout,
  content,
  closeCurrentModal,
  openConfirmModal,
  isOverflowAuto,
  maxWidth,
  ...props
}) {
  return (
    <Modal
      root={root}
      onClose={closeCurrentModal}
      isOpen={isOpen}
      modalId={id}
      simple={simple}
      isOverflowAuto={isOverflowAuto}
      maxWidth={maxWidth}
    >
      <Layout
        closeCurrentModal={closeCurrentModal}
        openConfirmModal={openConfirmModal}
        {...props}
      >
        {content}
      </Layout>
    </Modal>
  );
}

ActiveModal.propTypes = {
  id: string.isRequired,
  isOpen: bool.isRequired,
  content: node.isRequired,
  Layout: oneOfType([func, object]).isRequired,
  closeCurrentModal: func.isRequired,
  openConfirmModal: func.isRequired,
  isOverflowAuto: bool,
  // eslint-disable-next-line react/forbid-prop-types
  root: object,
  simple: bool,
  maxWidth: string,
};
