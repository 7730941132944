import { accountSection } from './accountSection.js';
import { generalSection } from './generalSection.js';
import { supplierTaxSection } from './taxSection.js';
import { supplierBankSection } from './bankSection.js';
import { supplierRatesSection } from './ratesSection.js';
import { supplierRemarksSection } from './remarksSection.js';
import { officeHoursSection } from './officeHoursSection.js';
import { supplierDocumentsSection } from './documentsSection.js';
import { supplierFinancialSection } from './financialSection.js';
import { supplierRemitFormSection } from './supplierRemitFormSection.js';

export const supplierFormSections = (
  ConvertSupplierComponent,
  rootUrl,
  supplier,
  isSupplierTypeEditable,
  protectedFieldsAccess,
  isAddressSectionRequired,
  { supplierSource, hasAccessToPrivateSupplier },
) => {
  const {
    hasUserAccessToUpdateTax,
    hasUserAccessToUpdateBank,
    hasUserAccessToUpdateFinance,
    hasUserAccessToUpdateRemit,
    hasUserAccessToUpdateDocuments,
  } = protectedFieldsAccess;

  return [
    ...accountSection(
      supplier,
      isSupplierTypeEditable,
      hasAccessToPrivateSupplier,
    ),
    ...supplierRemarksSection,
    ...generalSection(rootUrl, isAddressSectionRequired),
    ...(hasUserAccessToUpdateFinance
      ? supplierFinancialSection(supplierSource)
      : []),
    ...(hasUserAccessToUpdateRemit ? supplierRemitFormSection : []),
    ...officeHoursSection,
    ...supplierRatesSection(),
    ...(hasUserAccessToUpdateTax
      ? supplierTaxSection(ConvertSupplierComponent)
      : []),
    ...(hasUserAccessToUpdateBank ? supplierBankSection(null) : []),
    ...(hasUserAccessToUpdateDocuments ? supplierDocumentsSection(true) : []),
  ];
};
