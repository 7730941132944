import React from 'react';
import styled from 'styled-components';
import { Checkbox as CheckboxComponent, Icon, Popover } from '@poly/admin-book';

const CheckBoxS = styled(CheckboxComponent)`
  padding-top: 6px;
  input {
    top: 6px;
  }
  .state-re-presenter {
    top: 6px;
  }
`;

const DocumentsCheckboxS = styled(CheckBoxS)`
  position: absolute;
  top: 140px;
  left: 100px;
`;

export function SupplierFormCheckbox(props) {
  return <CheckBoxS label="Yes" {...props} />;
}

export function SupplierDocumentsCheckbox(props) {
  return <DocumentsCheckboxS {...props} label="Exempt" />;
}

const PopoverContentWrapper = styled.div`
  font-size: 12px;
  line-height: 12px;
  background-color: #12347a;
  padding: 12px 20px;
  color: #fff;
  border-radius: 5px;
`;

// eslint-disable-next-line import/no-unused-modules
export const ExemptSupplierSalesTaxContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  height: 25px;
`;

// eslint-disable-next-line import/no-unused-modules
export function ExemptSupplierSalesTaxPopover() {
  return (
    <Popover
      position="top"
      title={<Icon name="confirm" size={14} />}
      bgColor="#12347a"
      disableBoxShadow
      content={
        <PopoverContentWrapper>
          Supplier invoices for NY and WI will be exempt from sales tax
        </PopoverContentWrapper>
      }
    />
  );
}

export function ExemptSupplierSalesTaxCheckbox(props) {
  return (
    <ExemptSupplierSalesTaxContainer>
      <CheckboxComponent label="Yes" {...props} />
      <ExemptSupplierSalesTaxPopover />
    </ExemptSupplierSalesTaxContainer>
  );
}
