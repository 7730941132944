import * as R from 'ramda';
import { DEFAULT_PROPERTY_HIERARCHY_NAME } from '@poly/constants';

import { getPropertiesDeepByHierarchyNodeR } from './properties-by-hierarchy.js';
import { ofArrayLegacy } from '../ramdaLegacyFunctions.js';

// isFilteredHierarchy :: { filterHierarchyId: ID, _id: ID } -> Boolean
const isFilteredHierarchy = R.converge(R.equals, [
  R.prop('filterHierarchyId'),
  R.prop('_id'),
]);

// removeDuplicatedProperties :: [ID] -> PropertyHierarchySpendReportResult -> PropertyHierarchySpendReportResult
// -> [PropertyHierarchySpendReportResult]
const removeDuplicatedProperties = R.curry(
  (filterHierarchyIds, noHierarchy, filterHierarchy) =>
    R.compose(
      R.prepend(filterHierarchy),
      ofArrayLegacy,
      R.over(
        R.lensProp('properties'),
        R.reject(
          R.propSatisfies(R.includes(R.__, filterHierarchyIds), 'propertyId'),
        ),
      ),
    )(noHierarchy),
);

// prepareReportWithFilterHierarchy :: [PropertyHierarchySpendReportResult] -> [PropertyHierarchySpendReportResult]
const prepareReportWithFilterHierarchy = R.compose(
  R.converge(removeDuplicatedProperties, [
    R.compose(
      R.map(R.prop('propertyId')),
      getPropertiesDeepByHierarchyNodeR([]),
      R.defaultTo({}),
      R.find(isFilteredHierarchy),
    ),
    R.compose(
      R.mergeRight({ _id: null, name: DEFAULT_PROPERTY_HIERARCHY_NAME }),
      R.objOf('properties'),
      R.uniqBy(R.prop('propertyId')),
      R.flatten,
      R.map(getPropertiesDeepByHierarchyNodeR([])),
      R.reject(isFilteredHierarchy),
    ),
    R.compose(R.defaultTo({}), R.find(isFilteredHierarchy)),
  ]),
);

// prepareHierarchyNotDuplicatedProperties :: ID -> [PropertyHierarchySpendReportResult] -> [PropertyHierarchySpendReportResult]
export const prepareHierarchyNotDuplicatedProperties = (hierarchyId) =>
  R.compose(
    R.map(R.omit(['filterHierarchyId'])),
    prepareReportWithFilterHierarchy,
    R.map(R.assoc('filterHierarchyId', hierarchyId)),
  );
