import { gql } from '@apollo/client';

export const ASSIGN_SUPPLIERS_TO_PROJECT_MUTATION = gql`
  mutation ASSIGN_SUPPLIERS_TO_PROJECT_MUTATION(
    $input: AssignSuppliersToProjectInput!
  ) {
    assignSuppliersToProject(input: $input) {
      project {
        _id
      }
    }
  }
`;

export const ADD_SUPPLIER_TO_PROJECT_MUTATION = gql`
  mutation ADD_SUPPLIER_TO_PROJECT_MUTATION(
    $input: AddSupplierToProjectInput!
  ) {
    addSupplierToProject(input: $input) {
      _id
    }
  }
`;

export const ASSIGN_MANAGER_TO_PROJECTS_MUTATION = gql`
  mutation ASSIGN_MANAGER_TO_PROJECTS_MUTATION(
    $input: AssignManagerToProjectsInput!
  ) {
    assignManagerToProjects(input: $input) {
      projects {
        _id
      }
    }
  }
`;

export const COMPLETE_ASSIGN_SUPPLIERS_TO_PROJECT_MUTATION = gql`
  mutation COMPLETE_ASSIGN_SUPPLIERS_TO_PROJECT_MUTATION(
    $input: CompleteAssignSupplierTaskInput!
  ) {
    completeAssignSuppliersTask(input: $input) {
      project {
        _id
      }
    }
  }
`;

export const CREATE_PROJECT_MUTATION = gql`
  mutation CREATE_PROJECT_MUTATION($input: CreateProjectInput!) {
    createProject(input: $input) {
      project {
        _id
        type
        projectId
      }
    }
  }
`;

export const UPDATE_PROJECT_MUTATION = gql`
  mutation UPDATE_PROJECT_MUTATION($id: ID!, $update: ProjectUpdate!) {
    updateProject(id: $id, update: $update) {
      _id
      type
      projectId
    }
  }
`;

export const CREATE_RWO_MUTATION = gql`
  mutation CREATE_RWO_MUTATION($input: CreateRecurringProjectInput!) {
    createRecurringProject(input: $input) {
      recurringProject {
        _id
        projectId
      }
    }
  }
`;

export const UPDATE_RWO_MUTATION = gql`
  mutation UPDATE_RWO_MUTATION(
    $id: ID!
    $update: RecurringProjectUpdateInput!
  ) {
    updateRecurringProject(id: $id, update: $update) {
      _id
      projectId
    }
  }
`;
