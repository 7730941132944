import React from 'react';
import * as R from 'ramda';
import { func, string } from 'prop-types';
import { useLocation, useNavigate } from '@poly/client-routing';

import { useModalContext } from '../Modal/ModalProvider.js';
import { CancelBtn, confirmCancelFormPayload } from '../common.js';

export function CancelFormBtn({ additionalClickFunc, redirectUrl, ...props }) {
  const { openConfirmModal: openConformModalContext } = useModalContext();
  const navigate = useNavigate();
  const location = useLocation();

  const closeForm = () =>
    navigate({
      pathname: R.pathOr(redirectUrl, ['previous', 'pathname'], location.state),
      search: R.pathOr('', ['previous', 'search'], location.state),
      state: { previous: location.state },
    });

  const onClick = () => {
    if (location.state && location.state.pristine === false) {
      openConformModalContext({
        ...confirmCancelFormPayload,
        onConfirm: (closeConfirmModal) => () => {
          closeConfirmModal();
          closeForm();
        },
      });
    } else {
      if (additionalClickFunc && R.is(Function, additionalClickFunc)) {
        additionalClickFunc();
      }
      closeForm();
    }
  };

  return <CancelBtn {...props} onClick={onClick} />;
}

CancelFormBtn.propTypes = {
  additionalClickFunc: func,
  redirectUrl: string,
};
