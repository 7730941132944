import React, { useMemo, useReducer, useState } from 'react';
import { string, object, bool, node, func } from 'prop-types';
import { gql } from '@apollo/client';
import { ASC_SORT_ORDER } from '@poly/constants';
import {
  MainHeader,
  PageHeaderContainer,
  DefaultBodyWrapper,
} from '@poly/admin-book';
import {
  commonSortQuery,
  useTableInfiniteScrollQuery,
} from '@poly/client-utils';
import styled from 'styled-components';

import { batchClientInvoicingReducer } from './batch-invoicing-state.js';
import { BatchInvoicingPageBody } from './BatchInvoicingPageBody.js';
import { BatchInvoicingTMBassProTable } from './BatchInvoicingTMBassProTable.js';
import { BatchInvoicingTMBassProTotalsTable } from './BatchInvoicingTMBassProTotalsTable.js';
import { IncludeInvoicesCheckboxS } from '../CreateClientInvoice/ClientInvoiceForm.js';

export const batchInvoicingTMProjectsGqlQuery = gql`
  query ClientBatchInvoicingProjects($input: CollectionSearchParams!) {
    searchProjects(input: $input) {
      hits {
        _id
        type
        projectId
        description
        suggestedClientInvoiceAmount
        clientInvoiceMarkupAmount
        clientReferenceNumber
        isAfterHoursCall
        nte
        jobCost
        property {
          _id
          woCharge
        }
        parent {
          _id
          clientReferenceNumber
        }
        serviceType {
          name
        }
        client {
          woCharge
        }
        timeEntries {
          _id
        }
      }
      total
    }
  }
`;

const bassProTMTotalsProps = [
  'suggestedClientInvoiceAmount',
  'jobCost',
  'clientInvoiceMarkupAmount',
  'woCharge',
];

const CheckboxS = styled(IncludeInvoicesCheckboxS)`
  margin-right: 130px;
`;

function IncludeSupplierInvoicesCheckbox({
  setIncludeSupplierInvoices,
  includeSupplierInvoices,
}) {
  return (
    <CheckboxS
      label="Include Supplier Invoices"
      onChange={setIncludeSupplierInvoices}
      value={includeSupplierInvoices}
      name="includeSupplierInvoices"
    />
  );
}
IncludeSupplierInvoicesCheckbox.propTypes = {
  setIncludeSupplierInvoices: func.isRequired,
  includeSupplierInvoices: bool.isRequired,
};

export function BatchInvoicingTMPage({
  title,
  children,
  query,
  isClientNTE,
  withSupplierInvoices,
  showSupplierInvoicesCheckbox,
}) {
  const [batchEntriesMap, dispatch] = useReducer(
    batchClientInvoicingReducer,
    {},
  );

  const [includeSupplierInvoices, setIncludeSupplierInvoices] =
    useState(withSupplierInvoices);

  const input = useMemo(
    () => ({
      query,
      sort: commonSortQuery(['projectId'])(ASC_SORT_ORDER),
    }),
    [query],
  );

  const { data, loading, refetch, tableProps } = useTableInfiniteScrollQuery(
    batchInvoicingTMProjectsGqlQuery,
    input,
    {
      endpointName: 'searchProjects',
      pageSize: 500,
      skip: query === null,
    },
  );

  const extendedTableProps = {
    ...tableProps,
    isClientNTE,
  };

  const externalRefetch = () => {
    refetch({ input });
  };

  return (
    <>
      <PageHeaderContainer>
        <>
          {children || <MainHeader>{title}</MainHeader>}
          {showSupplierInvoicesCheckbox && (
            <IncludeSupplierInvoicesCheckbox
              includeSupplierInvoices={includeSupplierInvoices}
              setIncludeSupplierInvoices={setIncludeSupplierInvoices}
            />
          )}
        </>
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <BatchInvoicingPageBody
          data={data}
          tableProps={extendedTableProps}
          loading={loading}
          batchEntriesMap={batchEntriesMap}
          dispatch={dispatch}
          refetch={externalRefetch}
          calculateTotalsProps={bassProTMTotalsProps}
          TableComponent={BatchInvoicingTMBassProTable}
          TotalsTable={BatchInvoicingTMBassProTotalsTable}
          includeSupplierInvoices={includeSupplierInvoices}
          setIncludeSupplierInvoices={setIncludeSupplierInvoices}
        />
      </DefaultBodyWrapper>
    </>
  );
}

BatchInvoicingTMPage.propTypes = {
  title: string,
  // eslint-disable-next-line react/forbid-prop-types
  query: object,
  children: node,
  isClientNTE: bool,
  withSupplierInvoices: bool,
  showSupplierInvoicesCheckbox: bool,
};
BatchInvoicingTMPage.defaultProps = {
  isClientNTE: false,
  withSupplierInvoices: true,
  showSupplierInvoicesCheckbox: true,
};
