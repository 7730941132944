import { defaultTheme } from '@poly/admin-book';
import { ServiceTypeStatuses } from '@poly/constants';

const {
  colors: {
    statuses: { active, inactive },
  },
} = defaultTheme;

export const serviceTypesStatusesColors = {
  [ServiceTypeStatuses.active]: active,
  [ServiceTypeStatuses.inactive]: inactive,
};
