import React from 'react';
import { useOutSidebarContext } from '@poly/client-utils';
import { CommonSidebarFormWidth } from '@poly/admin-ui';

import { addUserFormId } from '../../constants.js';
import { AddUserSidebarForm } from './AddUserSidebarForm.js';

export const useAddUserSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();

  return ({ isContractor }) =>
    openOutSidebar({
      alwaysFirst: true,
      id: addUserFormId,
      width: CommonSidebarFormWidth,
      content: <AddUserSidebarForm isContractor={isContractor} />,
    });
};
