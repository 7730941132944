import React from 'react';
import { TimeSheetReportFilter } from './FilterComponent.js';
import { SearchPageHeader } from '../commonSearchPageHeaderComps.js';

export function TimeSheetReportPageHeader(props) {
  return (
    <SearchPageHeader
      {...props}
      height={115}
      title="Timesheet Report"
      FilterComponent={TimeSheetReportFilter}
    />
  );
}
