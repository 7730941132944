import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useReactiveQuery } from '@poly/client-utils';
import { HeavyJobStatuses } from '@poly/constants';
import { propEqLegacy } from '@poly/utils';

const myHeavyJobsQuery = gql`
  query MyHeavyJobs {
    myHeavyJobs {
      _id
      status
      type
      createdAt
      triggeredAt
      finishedAt
      clientInvoiceBatch {
        projectTypes
        createdAt
        invoicesCount
        client {
          name
        }
      }
      assetsCount
      property {
        name
      }
      client {
        name
      }
      printClientInvoicesCount
      consolidatedInvoicesCount
    }
  }
`;

const myHeavyJobsSubscription = gql`
  subscription HeavyJobsChangedSubscription {
    searchHeavyJobChanged(input: {}) {
      id
      type
    }
  }
`;

const createUseHeavyJobs = (filterFn) => () => {
  const { data } = useReactiveQuery(myHeavyJobsQuery, myHeavyJobsSubscription, {
    queryOptions: {},
    subscriptionOptions: {},
  });

  const jobs = filterFn(R.propOr([], 'myHeavyJobs', data));

  return jobs;
};

// isHeavyJobCompleted :: HeavyJob -> Bool
const isHeavyJobCompleted = propEqLegacy('status', HeavyJobStatuses.DONE);

export const useCompletedHeavyJobs = createUseHeavyJobs(
  R.filter(isHeavyJobCompleted),
);

export const useQueuedHeavyJobs = createUseHeavyJobs(
  R.reject(isHeavyJobCompleted),
);
