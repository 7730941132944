import React from 'react';
import * as R from 'ramda';
import { Form } from 'react-final-form';
import { shape, func } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { propEqLegacy } from '@poly/utils';
import {
  useNotificationState,
  useModalContext,
  useOnSubmitSetStopSubmitting,
} from '@poly/admin-ui';

import {
  projectPropTypes,
  projectTimeEntryPropType,
} from './projectTimePropTypes.js';
import { ProjectTimeForm } from './ProjectTimeForm.js';
import {
  prepareProjectTimeEntryBeforeUpdate,
  validateProjectTime,
} from './projectTimeUtils.js';

const UPDATE_PROJECT_TIME_ENTRY = gql`
  mutation UPDATE_PROJECT_TIME_ENTRY(
    $projectId: ID!
    $projectTimeEntryId: ID!
    $update: TimeEntryInput!
  ) {
    updateProjectTimeEntry(
      projectId: $projectId
      projectTimeEntryId: $projectTimeEntryId
      update: $update
    ) {
      totalTimeInMinutes
    }
  }
`;

// getTimeEntryInitialValues :: Project -> ProjectTimeEntry -> InitialValues
const getTimeEntryInitialValues = R.curry((project, projectTimeEntry) =>
  R.applySpec({
    ownerId: R.pathOr('', ['owner', '_id']),
    date: R.prop('date'),
    rate: R.prop('rate'),
    totalTimeInMinutes: R.prop('totalTimeInMinutes'),
    type: R.prop('type'),
    notes: R.prop('notes'),
    clientStaffRate: R.compose(
      R.find(
        R.__,
        R.pathOr([], ['client', 'staffRates', 'customRates'], project),
      ),
      propEqLegacy('description'),
      R.prop('rateDescription'),
    ),
  })(projectTimeEntry),
);

export const editProjectTimeFormId = 'editProjectTimeFormId';

export function EditProjectTimeForm({
  project,
  projectTimeEntry,
  handleCancel,
  setEditTimeProps,
}) {
  const [updateProjectTimeEntry] = useMutation(UPDATE_PROJECT_TIME_ENTRY);

  const { showSuccessNotification } = useNotificationState();
  const { closeModal } = useModalContext();

  const initialValues = {
    isManual: true,
    project,
    ...getTimeEntryInitialValues(project, projectTimeEntry),
  };

  const updateProjectTime = async (values) => {
    await updateProjectTimeEntry({
      variables: {
        projectId: project._id,
        projectTimeEntryId: projectTimeEntry._id,
        update: prepareProjectTimeEntryBeforeUpdate(values),
      },
    });

    showSuccessNotification('Project time successfully saved');
    if (typeof setEditTimeProps === 'function') {
      setEditTimeProps(null);
    } else {
      closeModal(editProjectTimeFormId);
    }
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    editProjectTimeFormId,
    updateProjectTime,
  );

  return (
    <Form
      initialValues={initialValues}
      render={ProjectTimeForm}
      onSubmit={onSubmit}
      formId={editProjectTimeFormId}
      handleCancel={handleCancel}
      validate={validateProjectTime}
    />
  );
}

EditProjectTimeForm.propTypes = {
  projectTimeEntry: shape(projectTimeEntryPropType).isRequired,
  project: shape(projectPropTypes).isRequired,
  handleCancel: func,
  setEditTimeProps: func,
};
