import { propEqLegacy } from '@poly/utils';
import * as R from 'ramda';
import { createAction, handleActions } from 'redux-actions';

export const setEditablePayments = createAction('SET_EDITABLE_PAYMENTS');
export const setAllEditablePayments = createAction('SET_ALL_EDITABLE_PAYMENTS');
export const setUpdatedEditablePayment = createAction(
  'SET_UPDATED_EDITABLE_PAYMENT',
);

// mergeNewEditablePaymentsWithOld :: [JournalPostgre] -> [JournalPostgre] -> [JournalPostgre]
// After loading next page or sorting we must "merge" existing payments
// (where paid amount already set) with new ones,
// so new documents and their order are in tact.
const mergeNewEditablePaymentsWithOld = (statePayments) =>
  R.map(
    R.converge(R.mergeRight, [
      R.compose(
        R.defaultTo({}),
        R.find(R.__, statePayments),
        propEqLegacy('_id'),
        R.prop('_id'),
      ),
      R.identity,
    ]),
  );

export const editablePaymentsReducer = handleActions(
  {
    [setEditablePayments]: (state, { payload }) =>
      mergeNewEditablePaymentsWithOld(state)(payload),
    [setAllEditablePayments]: (_, { payload }) => payload,
    [setUpdatedEditablePayment]: (state, { payload }) =>
      R.over(R.lensIndex(payload.index), R.always(payload.item))(state),
  },
  [],
);
