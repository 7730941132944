import * as R from 'ramda';
import { formatDateOrNothingUI } from '@poly/client-utils';
import { WorkOrderStatus } from '@poly/constants';
import { propEqLegacy } from '@poly/utils';

import { isFeeProjectType } from '../../../sidebars/ProjectSidebar/forms/form/utils/sections.js';
import { getProjectDaysOpen } from '../../../utils/project.js';

// formatStatusInfo :: Project -> StatusInfo
// StatusInfo = { label: String, message: String }
export const formatStatusInfo = R.cond([
  [
    propEqLegacy('status', WorkOrderStatus.BLOCKED),
    R.compose(
      R.assoc('label', 'Blocked'),
      R.objOf('message'),
      formatDateOrNothingUI,
      R.prop('blockedAt'),
    ),
  ],
  [
    propEqLegacy('status', WorkOrderStatus.COMPLETED),
    R.compose(
      R.assoc('label', 'Completed'),
      R.objOf('message'),
      formatDateOrNothingUI,
      R.prop('workCompletionDate'),
    ),
  ],
  [isFeeProjectType, R.always(null)],
  [
    R.T,
    R.compose(
      R.assoc('label', 'Open'),
      R.objOf('message'),
      R.concat(R.__, ' Days'),
      R.toString,
      getProjectDaysOpen,
    ),
  ],
]);
