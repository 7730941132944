import styled from 'styled-components';

import { getThemeColor } from '../utils.js';

export const Divider = styled.div`
  height: 1px;
  background-color: ${getThemeColor(['secondaryLighter1'])};
  margin: 20px 0;
`;

Divider.displayName = 'Divider';

export const VerticalDivider = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${getThemeColor(['primaryLighter6'])};
`;

VerticalDivider.displayName = 'VerticalDivider';
