import React from 'react';
import * as R from 'ramda';
import { useRouterParams, useRouterQuery } from '@poly/client-routing';
import { DefaultBodyWrapper, Loader } from '@poly/admin-book';
import { useReactiveDocumentTitle } from '@poly/client-utils';

import {
  PageCardBody,
  PageCardFirstSection,
  PageCardSecondSection,
} from '../components/PageCardComponents.js';
import { propertySidebarTabs } from '../routes/constants.js';
import { NotFoundEntity } from '../components/NotFoundEntity.js';
import { PropertySidebarSections } from '../sidebars/PropertySidebar/PropertySidebar.js';
import { PropertySidebarTabs } from '../sidebars/PropertySidebar/tabs/PropertySidebarTabs.js';
import { usePropertySidebarData } from '../sidebars/PropertySidebar/usePropertySidebarData.js';
import { useCloseExtendedTab } from '../hooks/useCloseExtendedTab.js';

// isFullWidthTabById :: String -> Boolean
const isFullWidthTabByTabName = R.includes(R.__, [
  propertySidebarTabs.propertyPMs,
  propertySidebarTabs.propertyAssets,
  propertySidebarTabs.propertyMasters,
  propertySidebarTabs.propertyChildProperties,
  propertySidebarTabs.propertyProjects,
  propertySidebarTabs.propertyContacts,
  propertySidebarTabs.propertySuppliers,
  propertySidebarTabs.propertyLocationHierarchy,
]);

// checkIsPropertyLocationHierarchyEnabled :: Property -> Boolean
const checkIsPropertyLocationHierarchyEnabled = R.path([
  'client',
  'enablePropertyLocationHierarchy',
]);

export function PropertyCard() {
  const { propertyId } = useRouterParams(['propertyId']);
  const { cardTab } = useRouterQuery(['cardTab']);
  const { loading, property, refetch } = usePropertySidebarData(propertyId);

  const closeExtendedTab = useCloseExtendedTab();
  useReactiveDocumentTitle(property ? property.name : null);

  if (loading) {
    return <Loader />;
  }

  const fullWidth = isFullWidthTabByTabName(cardTab);

  const tabsProps = {
    property,
    isCard: true,
    isPropertyLocationHierarchyEnabled:
      checkIsPropertyLocationHierarchyEnabled(property),
    customTabQueryKey: 'cardTab',
  };

  return (
    <DefaultBodyWrapper height="100%">
      <PageCardBody>
        {!property ? (
          <NotFoundEntity entityName="property" />
        ) : (
          <>
            <PageCardFirstSection onClick={closeExtendedTab}>
              <PropertySidebarSections
                isCard
                refetch={refetch}
                property={property}
              />
            </PageCardFirstSection>
            <PageCardSecondSection fullWidth={fullWidth}>
              <PropertySidebarTabs {...tabsProps} />
            </PageCardSecondSection>
          </>
        )}
      </PageCardBody>
    </DefaultBodyWrapper>
  );
}
