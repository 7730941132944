import React from 'react';
import * as R from 'ramda';
import { gql, useMutation } from '@apollo/client';
import { usePristineSubscribe } from '@poly/client-routing';
import { alwaysNewDate } from '@poly/utils';
import {
  useOnSubmitSetStopSubmitting,
  useNotificationState,
} from '@poly/admin-ui';

import { bankDepositsFormId } from './constants.js';
import { bankDepositsFromSections } from './bankDepositsFormSections.js';
import { prepareDepositInputForMutation } from './bankDepositsUtils.js';
import { FormPageWithButtons } from '../../components/FormCardContainer.js';
import { routesNames } from '../../routes/index.js';

const createDepositMutation = gql`
  mutation createDepositMutation($input: CreateJournalDepositInput!) {
    createJournalDeposit(input: $input) {
      _id
    }
  }
`;

export function BankDepositsForm() {
  const { showSuccessNotification } = useNotificationState();
  const [createDeposit] = useMutation(createDepositMutation);

  const onSubmitHandler = async (formData) => {
    const input = prepareDepositInputForMutation(formData);
    await createDeposit({ variables: { input } });
    showSuccessNotification('Bank Deposit is successfully recorded!');
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    bankDepositsFormId,
    onSubmitHandler,
  );

  const pristineSubscribeProps = usePristineSubscribe();

  const formProps = {
    onSubmit,
    id: bankDepositsFormId,
    resetFormOnSubmit: true,
    initialValuesEqual: R.T,
    keepDirtyOnReinitialize: false,
    sections: bankDepositsFromSections,
    initialValues: { date: alwaysNewDate() },
    layout: { card: false },
    isFullHeight: false,
    fallbackLink: routesNames.FINANCIAL_DIRECTORY,
    ...pristineSubscribeProps,
  };

  return <FormPageWithButtons {...formProps} />;
}
