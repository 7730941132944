import * as R from 'ramda';
import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { bool, func, shape, string } from 'prop-types';
import { FormCreator, Input } from '@poly/admin-book';
import { usePristineSubscribe } from '@poly/client-routing';
import {
  MoneyInputAsCents,
  useModalContext,
  useNotificationState,
} from '@poly/admin-ui';
import { ASSET_TYPE_LENGTH_LIMIT } from '@poly/constants';

import { commonModalLayout } from '../../modules/forms/common.js';
import { addAssetTypesFormId, editAssetTypesFormId } from './constants.js';
import { LifeExpectancyInput } from '../AssetManufactureModels/AssetManufacturerModelForm.js';

const createSuccessMessage = 'Asset type was created successfully';
const editSuccessMessage = 'Asset type was edited successfully';

const assetTypesFormSections = [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        label: 'Name',
        order: 1,
        layout: { row: 1 },
        required: true,
        validators: [[R.identity, 'Asset type name is required']],
        field: {
          name: 'name',
          Component: Input,
          additionalProps: {
            maxLength: ASSET_TYPE_LENGTH_LIMIT,
            charactersLimit: ASSET_TYPE_LENGTH_LIMIT,
            showCharactersLeft: true,
          },
        },
      },
      {
        label: 'Life Expectancy (years)',
        field: {
          name: 'lifeExpectancy',
          Component: LifeExpectancyInput,
        },
      },
      {
        label: 'Asset Replacement Cost',
        field: {
          name: 'replacementCost',
          Component: MoneyInputAsCents,
        },
      },
    ],
  },
];

const CREATE_ASSET_TYPE_MUTATION = gql`
  mutation CREATE_ASSET_TYPE_MUTATION($input: CreateAssetTypeInput!) {
    createAssetType(input: $input) {
      _id
    }
  }
`;

const UPDATE_ASSET_TYPE_MUTATION = gql`
  mutation UPDATE_ASSET_TYPE_MUTATION($id: ID!, $input: UpdateAssetTypeInput!) {
    updateAssetType(id: $id, input: $input) {
      _id
    }
  }
`;

// getAssetTypeInitialValues :: AssetType- > AssetType
const getAssetTypeInitialValues = R.applySpec({
  name: R.prop('name'),
  lifeExpectancy: R.prop('lifeExpectancy'),
  replacementCost: R.propOr(null, 'replacementCost'),
});

// prepareMutationVariables :: (Boolean, AssetType) -> FormData ->
//  { variables: { input: UpdateAssetTypeInput, id: ID } }
const prepareMutationVariables = (isNewType, assetType) =>
  R.compose(
    R.objOf('variables'),
    R.unless(R.always(isNewType), R.assoc('id', assetType._id)),
    R.objOf('input'),
    getAssetTypeInitialValues,
  );

export function AssetTypesForm({ isNewType, assetType, refetch, ...props }) {
  const { closeModal } = useModalContext();
  const pristineSubscribeProps = usePristineSubscribe();
  const { showSuccessNotification } = useNotificationState();

  const submitMutation = isNewType
    ? CREATE_ASSET_TYPE_MUTATION
    : UPDATE_ASSET_TYPE_MUTATION;

  const [assetTypeMutation] = useMutation(submitMutation);

  const formId = isNewType ? addAssetTypesFormId : editAssetTypesFormId;

  const successMessage = isNewType ? createSuccessMessage : editSuccessMessage;

  const onSubmit = async (input) => {
    await assetTypeMutation(
      prepareMutationVariables(isNewType, assetType)(input),
    );

    if (!isNewType) {
      await refetch();
    }

    showSuccessNotification(successMessage);
    closeModal(formId);
  };

  return (
    <FormCreator
      {...props}
      id={formId}
      layout={commonModalLayout}
      formId={formId}
      sections={assetTypesFormSections}
      initialValues={getAssetTypeInitialValues(assetType)}
      onSubmit={onSubmit}
      onCancel={closeModal}
      successMessage={successMessage}
      {...pristineSubscribeProps}
    />
  );
}

AssetTypesForm.defaultProps = {
  assetType: {},
};
AssetTypesForm.propTypes = {
  isNewType: bool.isRequired,
  refetch: func,
  assetType: shape({
    _id: string,
    name: string,
  }),
};
