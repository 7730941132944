import * as R from 'ramda';
import { ofArrayLegacy, pathEqLegacy } from '@poly/utils';

const QUERY_ERROR_KEYWORD = 'Query error: ';
const MUTATION_ERROR_KEYWORD = 'Expected ';
const FETCH_ERROR_MESSAGE = 'Failed to fetch';
const FIREFOX_FETCH_ERROR_MESSAGE =
  'NetworkError when attempting to fetch resource.';

// queryMessage :: String -> String
const queryMessage = R.cond([
  [
    R.includes(MUTATION_ERROR_KEYWORD),
    R.compose(
      R.replace(QUERY_ERROR_KEYWORD, ''),
      R.concat(MUTATION_ERROR_KEYWORD),
      R.last,
      R.split(MUTATION_ERROR_KEYWORD),
    ),
  ],
  [
    R.includes(QUERY_ERROR_KEYWORD),
    R.compose(R.last, R.split(QUERY_ERROR_KEYWORD)),
  ],
  [R.T, R.identity],
]);

// networkResultErrors :: ApolloError -> [Object]
const networkResultErrors = R.compose(
  R.flatten,
  R.filter(R.identity),
  R.defaultTo([]),
  R.cond([
    [R.is(Array), R.map(R.prop('errors'))],
    [R.prop('errors'), R.prop('errors')],
    [R.T, ofArrayLegacy],
  ]),
  R.path(['networkError', 'result']),
);

// networkErrors :: ApolloError -> [Object]
const networkErrors = R.compose(
  R.objOf('message'),
  R.when(
    R.includes(R.__, [FETCH_ERROR_MESSAGE, FIREFOX_FETCH_ERROR_MESSAGE]),
    R.always(''),
  ),
  R.defaultTo(''),
  R.path(['networkError', 'message']),
);

// errorsFromResponse :: ApolloError -> [Object]
// eslint-disable-next-line import/no-unused-modules
export const errorsFromResponse = R.converge(
  R.compose(R.filter(R.prop('message')), R.flatten, Array.of),
  [
    networkResultErrors,
    networkErrors,
    R.compose(R.defaultTo([]), R.prop('graphQLErrors')),
  ],
);

// parseMessages :: ApolloError -> [String]
// eslint-disable-next-line import/no-unused-modules
export const parseMessages = R.compose(
  R.uniq,
  R.map(queryMessage),
  R.map(R.propOr('', 'message')),
  errorsFromResponse,
);

// isNotAuthenticatedApolloError :: ApolloError -> Bool
export const isNotAuthenticatedApolloError = pathEqLegacy(
  ['networkError', 'statusCode'],
  401,
);

// getErrorMessagesByApolloError :: ApolloError -> [String]
export const getErrorMessagesByApolloError = R.ifElse(
  isNotAuthenticatedApolloError,
  R.always([]),
  parseMessages,
);
