import React from 'react';
import { arrayOf, func, shape } from 'prop-types';
import * as R from 'ramda';
import { gql, useMutation } from '@apollo/client';
import { FormCreator } from '@poly/admin-book/src/Form/FormCreator.js';
import {
  DatePickerWrapper,
  DnDAreaWithMentionsWrapper,
  dateValidators,
} from '@poly/admin-ui/src/modules/forms/taskForm/form/sections.js';
import {
  dNdTextAreaFileValidators,
  validateFilesFunc,
} from '@poly/client-utils/src/formValidators.js';
import { initialCommonValues } from '@poly/admin-ui/src/modules/forms/taskForm/form/constants.js';
import { TaskAssignUsersSelect } from '@poly/admin-ui/src/modules/forms/fields/TaskAssignUsersSelect.js';
import { halfWidth } from '@poly/admin-ui/src/modules/forms/common.js';
import { useRouterQuery } from '@poly/client-routing/src/hooks.js';
import { usePersistDnDAreaWithMentions } from '@poly/client-utils/src/hooks/persists/usePersistDnDAreaWithMentions.js';
import { prepareAttachments } from '@poly/admin-ui/src/modules/forms/taskForm/form/taskFormUtils.js';
import { useOnSubmitSetStopSubmitting } from '@poly/admin-ui/src/hooks/useOnSubmitSetStopSubmitting.js';
import { useNotificationContext } from '@poly/admin-book/src/Notificator/NotificatorProvider.js';
import {
  commonSidebarFormFieldLayout,
  commonSidebarFormLayout,
  commonSidebarFormSectionLayout,
} from '@poly/admin-ui/src/sidebar/commonSidebarFormConstants.js';
import { clientReportTaskActionTypes } from '@poly/constants/src/tasks.js';

export const updateTasksSidebarFormId = 'updateTasksFormId';

export const UPDATE_TASKS_MUTATION = gql`
  mutation UPDATE_TASKS_MUTATION($taskIds: [ID]!, $update: TasksUpdateInput!) {
    updateTasks(taskIds: $taskIds, update: $update)
  }
`;

// getClientReportTasksDescriptions :: {tasks: [Task]} -> String
const getClientReportTasksDescriptions = R.compose(
  R.join(', '),
  R.uniq,
  R.pluck('description'),
  R.filter(
    R.propSatisfies(R.includes(R.__, clientReportTaskActionTypes), 'action'),
  ),
  R.propOr([], 'tasks'),
);

function DnDAreaWithMentionsWithWarning({ formData, ...props }) {
  const tasksUpdateForClientsDescriptions =
    getClientReportTasksDescriptions(formData);
  const warning = tasksUpdateForClientsDescriptions
    ? `${tasksUpdateForClientsDescriptions} update will show to client`
    : '';
  return (
    <DnDAreaWithMentionsWrapper
      {...props}
      formData={formData}
      warning={warning}
    />
  );
}

DnDAreaWithMentionsWithWarning.propTypes = {
  formData: shape({}),
};

const updateTasksSections = (onChangePersistentValue) => [
  {
    id: 'main',
    layout: { column: 1, margin: 0 },
    order: 1,
    fields: [
      {
        label: 'Update (may show to client based on type of task)',
        order: 1,
        layout: { row: 1, width: '100%' },
        field: {
          name: 'details',
          withFormData: true,
          Component: (props) => (
            <DnDAreaWithMentionsWithWarning
              onChangePersistentValue={onChangePersistentValue}
              {...props}
            />
          ),
        },
        validators: dNdTextAreaFileValidators,
        validateFunction: validateFilesFunc,
      },
      {
        order: 4,
        layout: { row: 8, width: halfWidth, isWrapped: true },
        label: 'Due Date',
        field: {
          name: 'dueDate',
          Component: (props) => (
            <DatePickerWrapper position="bottom" {...props} />
          ),
        },

        validators: dateValidators,
      },
      {
        label: 'Assigned to',
        order: 6,
        layout: { row: 8, width: halfWidth },
        field: {
          name: 'managerId',
          Component: TaskAssignUsersSelect,
        },
        validators: [[R.identity, 'Manager is required']],
      },
    ],
  },
];

// prepareTasksMutationInput :: FormData -> TasksUpdateInput
const prepareTasksMutationInput = R.applySpec({
  managerId: R.prop('managerId'),
  dueDate: R.prop('dueDate'),
  update: {
    message: R.path(['details', 'text']),
    mentions: R.path(['details', 'mentions']),
    filesAttachments: prepareAttachments,
  },
});

// getDueDateInitialValue :: [Task] -> Date
const getDueDateInitialValue = R.compose(
  R.prop('dueDate'),
  R.head,

  R.sortBy(R.prop('dueDate')),
);

export const getTasksPersistKey = (managerId) => `tasks.details.${managerId}`;

export function UpdateTasksSidebarForm({ tasks, onClose, unselectTasks }) {
  const { managerId } = useRouterQuery(['managerId']);

  const { showSuccessNotification } = useNotificationContext();

  const [updateTasks] = useMutation(UPDATE_TASKS_MUTATION);

  const {
    onChangePersistentValue,
    cleanupRetainedValue,
    retainedValue: details,
  } = usePersistDnDAreaWithMentions(getTasksPersistKey(managerId));

  const initialValues = {
    managerId,
    details: details || { ...initialCommonValues, placeholder: null },
    dueDate: getDueDateInitialValue(tasks),
    tasks,
  };

  const handleSubmit = async (formData) => {
    await updateTasks({
      variables: {
        update: prepareTasksMutationInput(formData),
        taskIds: R.pluck('_id', tasks),
      },
    });
    cleanupRetainedValue();
    showSuccessNotification('Tasks successfully edited');
    onClose();
    unselectTasks();
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    updateTasksSidebarFormId,
    handleSubmit,
  );
  return (
    <FormCreator
      id={updateTasksSidebarFormId}
      onSubmit={onSubmit}
      sections={updateTasksSections(onChangePersistentValue)}
      initialValues={initialValues}
      layout={commonSidebarFormLayout}
      sectionLayout={commonSidebarFormSectionLayout}
      fieldLayout={commonSidebarFormFieldLayout}
    />
  );
}

UpdateTasksSidebarForm.propTypes = {
  tasks: arrayOf(shape({})),
  onClose: func.isRequired,
  unselectTasks: func.isRequired,
};
