import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';

import { useOnSubmitSetStopSubmitting } from '../../../hooks/useOnSubmitSetStopSubmitting.js';
import { mapAttachmentsBeforeUpdate } from '../../forms/addUpdateForm/addUpdateFormUtils.js';
import { useNotificationState } from '../../../hooks/useNotificationState.js';

// eslint-disable-next-line import/no-unused-modules
export const POST_UPDATE_REPLY = gql`
  mutation POST_UPDATE_REPLY($input: PostUpdateCommentInput!) {
    postUpdateComment(input: $input) {
      updateComment {
        _id
      }
    }
  }
`;

export const useUpdateReplySectionHook = (
  updateId,
  setActiveReply,
  cleanupRetainedValue,
) => {
  const updateReplyFormId = 'updateReplyFormId';

  const [postUpdateReply] = useMutation(POST_UPDATE_REPLY);
  const { showSuccessNotification } = useNotificationState();
  const isLoading = useSelector(
    R.pathOr(false, ['processes', [updateReplyFormId]]),
  );

  const prepareReplyForMutation = R.compose(
    R.objOf('input'),
    R.converge(R.mergeDeepLeft, [
      R.compose(
        R.applySpec({
          message: R.prop('text'),
          mentions: R.prop('mentions'),
          filesAttachments: R.pipe(
            R.prop('attachments'),
            mapAttachmentsBeforeUpdate,
          ),
          updateId: R.always(updateId),
        }),
        R.prop('updateDetails'),
      ),
      R.compose(
        R.objOf('preventExternalUsersEmails'),
        R.not,
        R.prop('sendEmails'),
      ),
    ]),
  );

  const onCancel = () => setActiveReply(null);

  const onSubmitHandler = async (input) => {
    await postUpdateReply({ variables: prepareReplyForMutation(input) });
    showSuccessNotification('Update Successfully Commented');
    cleanupRetainedValue();
    onCancel();
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    updateReplyFormId,
    onSubmitHandler,
  );

  return {
    onSubmit,
    onCancel,
    isLoading,
    formId: updateReplyFormId,
  };
};
