import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { Link } from '@poly/client-routing';
import { NON_CLIENT_SELECT_OPTION, NOTHING_UI_STRING } from '@poly/constants';
import { BodyRow, getThemeColor } from '@poly/admin-book';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import {
  convertCentsToDollars,
  formatTotal,
  insertQueryParamsIntoURL,
} from '@poly/utils';

import { routesNames } from '../../routes/constants.js';
import { incomeStatementTableHeaderTitles } from './constants.js';
import { incomeStatementsFiltersPropTypes } from './prop-types.js';
import {
  IncomeStatementSectionTotal,
  IncomeStatementSectionTotalTitle,
} from './components.js';

// isAccountBalanceRow :: { isSubtotalColumn: Boolean, isParentGLCodeRow: Boolean } -> Boolean
const isAccountBalanceRow = R.both(
  R.prop('isParentGLCodeRow'),
  R.complement(R.prop('isSubtotalColumn')),
);

// isClientBalanceRow :: { isSubtotalColumn: Boolean, isParentGLCodeRow: Boolean } -> Boolean
const isClientBalanceRow = R.both(
  R.prop('isSubtotalColumn'),
  R.complement(R.prop('isParentGLCodeRow')),
);

// prepareQueryParams :: Input -> Result
// Input = {
//    _id: ID
//    accountId: ID
//    filters: PageFilters
//    isNonClientBalanceRow: Boolean
//    isSubtotalColumn: Boolean
//    isParentGLCodeRow: Boolean
// }
// PageFilters = {
//    startDate: DateTime
//    endDate: DateTime
//    accountingMethod: String
// }
// Result = {
//    clientId: ID
//    accountId: ID
//    startDate: DateTime
//    endDate: DateTime
//    accountingMethod: String
// }
const prepareQueryParams = R.compose(
  R.reject(R.isNil),
  R.mergeAll,
  R.juxt([
    R.cond([
      [
        R.prop('isNonClientBalanceRow'),
        R.always({ clientId: NON_CLIENT_SELECT_OPTION }),
      ],
      [isClientBalanceRow, R.applySpec({ clientId: R.prop('_id') })],
      [R.T, R.always({})],
    ]),
    R.applySpec({
      accountId: R.prop('accountId'),
      startDate: R.path(['filters', 'startDate']),
      endDate: R.path(['filters', 'endDate']),
      accountingMethod: R.path(['filters', 'accountingMethod']),
    }),
  ]),
);

// prepareRowTotal :: Input -> String
// Input = {
//    balance: Int
//    clientBalance: Int
//    isSubtotalColumn: Boolean
//    isParentGLCodeRow: Boolean
// }
const prepareRowTotal = R.compose(
  formatTotal,
  convertCentsToDollars,
  R.cond([
    [isAccountBalanceRow, R.propOr(0, 'balance')],
    [isClientBalanceRow, R.propOr(0, 'clientBalance')],
    [R.T, R.always(0)],
  ]),
);

function TableTotalLink({ isSubtotalColumn, ...props }) {
  const url = insertQueryParamsIntoURL(
    prepareQueryParams({ ...props, isSubtotalColumn }),
    routesNames.ACCOUNT_TRANSACTIONS,
  );
  return (
    <Link target="_blank" href={url}>
      {prepareRowTotal({ ...props, isSubtotalColumn })}
    </Link>
  );
}

TableTotalLink.propTypes = {
  isSubtotalColumn: bool,
};

const BodyRowS = styled(BodyRow)`
  background-color: ${({ isParentGLCodeRow }) =>
    isParentGLCodeRow ? getThemeColor(['lighter']) : 'transparent'};
`;

const SectionBodyRowColumns = [
  R.propOr('', 'compass_gl_code'),
  R.pathOr('', ['client', 'sapGLCode']),
  R.propOr('', 'compass_gl_code_description'),
  R.propOr('', 'code'),
  R.compose(
    R.defaultTo(NOTHING_UI_STRING),
    R.either(R.prop('name'), R.path(['client', 'name'])),
  ),
  (props) => <TableTotalLink {...props} isSubtotalColumn />,
  TableTotalLink,
];

const reportDataRowPropTypes = {
  code: string,
  name: string,
  balance: number,
  clientBalance: number,
  compass_gl_code: string,
  compass_gl_code_description: string,
  client: shape({
    name: string,
    sapGLCode: string,
  }),
};

function SectionBodyRow({ row, index, filters, ...props }) {
  const rowKey = `${row._id}-${index}`;
  const { isParentGLCodeRow } = row;

  return (
    <BodyRowS
      row={{ ...row, filters }}
      key={rowKey}
      index={index}
      columns={SectionBodyRowColumns}
      isParentGLCodeRow={isParentGLCodeRow}
      {...props}
    />
  );
}

SectionBodyRow.defaultProps = {
  toggleRow: () => null,
  headers: incomeStatementTableHeaderTitles,
  isRowSelectable: () => false,
  isRowDisabled: () => false,
};

SectionBodyRow.propTypes = {
  row: shape(reportDataRowPropTypes),
  index: number.isRequired,
  filters: shape(incomeStatementsFiltersPropTypes),
  toggleRow: func,
  headers: arrayOf(shape({ title: string })),
  isRowSelectable: func,
  isRowDisabled: func,
};

export function IncomeStatementTableBodySection({
  total,
  filters,
  reportData,
  totalTitle,
  sectionTitle,
}) {
  return (
    <>
      <IncomeStatementSectionTotalTitle title={sectionTitle} />
      {reportData.map((row, index) => {
        const rowKey = `${row._id}-${index}`;
        return (
          <SectionBodyRow
            row={row}
            index={index}
            key={rowKey}
            filters={filters}
          />
        );
      })}
      <IncomeStatementSectionTotal title={totalTitle} total={total} />
    </>
  );
}

IncomeStatementTableBodySection.propTypes = {
  total: number.isRequired,
  filters: shape(incomeStatementsFiltersPropTypes),
  totalTitle: string.isRequired,
  sectionTitle: string.isRequired,
  reportData: arrayOf(shape(reportDataRowPropTypes)),
};
