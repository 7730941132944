import { gql } from '@apollo/client';

export const UPDATES_SEARCH_CHANGED = gql`
  subscription UPDATES_SEARCH_CHANGED($searchInput: SearchUpdateChangedInput!) {
    searchUpdateChanged(input: $searchInput) {
      id
      type
    }
  }
`;
