import {
  bool,
  func,
  shape,
  string,
  object,
  arrayOf,
  oneOfType,
} from 'prop-types';
import React, { useState } from 'react';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import {
  ModalActions,
  Text,
  Icon,
  getThemeColor,
  defaultTheme,
} from '@poly/admin-book';

import { TaskForm } from './TaskForm.js';
import { UpdatesList } from './components/UpdatesList.js';
import { TaskFormError } from './components/TaskFormError.js';
import { EntityInfoView } from './components/EntityInfoView.js';
import { CancelBtn, SubmitBtn } from '../../common.js';

const updatePropTypes = {
  createdAt: string.isRequired,
  message: string.isRequired,
  title: string.isRequired,
  createdBy: shape({
    fullName: string.isRequired,
  }),
  attachments: arrayOf(shape({ url: string.isRequired })),
  // eslint-disable-next-line react/forbid-prop-types
  mentions: object,
};

const FormWrapperS = styled.div`
  position: relative;
  max-width: 650px;

  ${({ title }) => !title && 'margin-top: -15px'};
  ${({ withFormButtons }) => withFormButtons && 'padding-bottom: 35px'};
`;

const Title = styled(Text)`
  padding-right: 40px;
  margin-bottom: ${R.prop('margin')};
`;

const ModalActionsS = styled(ModalActions)`
  ${({ isComplete }) => !!isComplete && 'justify-content: flex-end'};

  margin-top: 10px;
`;

const completeStyles = css`
  display: flex;
  align-items: center;
  background-color: ${getThemeColor(['primaryRegular'])};

  > svg:first-child {
    margin-right: 10px;
    ${({ loader }) => !!loader && 'display: none'};
  }
`;

const SubmitButton = styled(SubmitBtn)`
  ${completeStyles};
`;

export function TaskFormWrapper({
  title,
  subtitle,
  updates,
  error,
  onCancel,
  entityInfo,
  submitCaption,
  projectId,
  withFormButtons,
  actionInProgress,
  formId,
  suppliers,
  isComplete,
  openConfirmModal,
  UpdatesListComponent,
  ...restProps
}) {
  const [isSendAutomaticEmail, setIsSendAutomaticEmail] = useState(false);

  return (
    <FormWrapperS {...{ withFormButtons, title }}>
      {title && (
        <Title lineHeight="24px" size="18px" margin={entityInfo ? 0 : '10px'}>
          {title}
        </Title>
      )}
      {entityInfo && <EntityInfoView value={{ projectId, entityInfo }} />}
      <TaskForm
        {...{
          formId,
          onCancel,
          isSendAutomaticEmail,
          setIsSendAutomaticEmail,
          ...restProps,
        }}
      />
      {error && <TaskFormError {...{ error }} />}
      {!withFormButtons && (
        <ModalActionsS {...{ error, suppliers, isComplete }}>
          {!isComplete && (
            <SubmitBtn
              form={formId}
              loader={actionInProgress}
              disabled={!!error || actionInProgress}
            >
              {isSendAutomaticEmail ? 'Send Email' : submitCaption}
            </SubmitBtn>
          )}
          <CancelBtn disabled={actionInProgress} onClick={onCancel} />
          {!!isComplete && (
            <SubmitButton
              form={formId}
              loader={actionInProgress}
              disabled={!!error || actionInProgress}
            >
              <Icon name="check" size={15} color={defaultTheme.colors.white} />
              {isSendAutomaticEmail ? 'Send Email' : submitCaption}
            </SubmitButton>
          )}
        </ModalActionsS>
      )}
      {R.pathSatisfies(R.lt(0), ['hits', 'length'], updates) && (
        <UpdatesListComponent
          {...{ updates: R.pathOr([], ['hits'], updates) }}
        />
      )}
    </FormWrapperS>
  );
}

TaskFormWrapper.propTypes = {
  title: string,
  submitCaption: string.isRequired,
  onCancel: func.isRequired,
  entityInfo: string,
  projectId: string,
  error: oneOfType([
    string,
    shape({
      type: string,
      message: string,
    }),
  ]),
  updates: shape({ hits: arrayOf(shape(updatePropTypes)) }),
  subtitle: string,
  withFormButtons: bool,
  formId: string.isRequired,
  actionInProgress: bool,
  openConfirmModal: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  suppliers: arrayOf(object),
  isComplete: bool,
  UpdatesListComponent: func,
};

TaskFormWrapper.defaultProps = {
  title: null,
  entityInfo: null,
  updates: { hits: [] },
  error: '',
  subtitle: '',
  withFormButtons: false,
  suppliers: [],
  isComplete: false,
  UpdatesListComponent: UpdatesList,
};
