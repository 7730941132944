import React from 'react';
import { string, number, func } from 'prop-types';
import { CommonSidebarFormWrapper } from '@poly/admin-ui';

import { editWeeklyPorterHoursFormId } from './EditPorterHoursForm/constants.js';
import { EditPorterHoursForm } from './EditPorterHoursForm/EditPorterHoursForm.js';

export function CreditPorterHoursForm({
  onCancel,
  projectId,
  creditedPorterHours,
  ticketsTotalHours,
}) {
  return (
    <CommonSidebarFormWrapper
      onCancel={onCancel}
      title="Credit Porter Hours"
      formId={editWeeklyPorterHoursFormId}
    >
      <EditPorterHoursForm
        {...{ onCancel, projectId, creditedPorterHours, ticketsTotalHours }}
      />
    </CommonSidebarFormWrapper>
  );
}

CreditPorterHoursForm.propTypes = {
  onCancel: func.isRequired,
  projectId: string.isRequired,
  creditedPorterHours: number.isRequired,
  ticketsTotalHours: number.isRequired,
};
