import React from 'react';
import { JournalPaymentMode } from '@poly/constants';
import {
  PageHeaderContainer,
  DefaultBodyWrapper,
  MainHeader,
} from '@poly/admin-book';

import { PayByForm } from './PayByForm.js';
import { payByCreditCardFormId } from './constants.js';

export function PayByCreditCardPage() {
  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Pay By Credit Card</MainHeader>
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <PayByForm
          formId={payByCreditCardFormId}
          initialPaymentMode={JournalPaymentMode.CREDIT_CARD}
          showInvoiceNumberInput
        />
      </DefaultBodyWrapper>
    </>
  );
}
