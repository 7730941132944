import { gql } from '@apollo/client';
import { useReactiveQuery } from '@poly/client-utils';
import {
  MASTER_SUPPLIER_DETAILS_SUB,
  masterSupplierDetailsFields,
  MAX_ITEMS,
} from '@poly/admin-ui';
import { InvoicesStatuses } from '@poly/constants';

const MASTER_SUPPLIER_DETAILS = gql`
  query MASTER_SUPPLIER_DETAILS(
    $supplierId: ID!
    $searchInput: CollectionSearchParams!
  ) {
    masterSupplier(id: $supplierId) {
      searchInvoices(input: $searchInput) {
        hits {
          _id
          balance
          status
        }
      }
      ...masterSupplierDetailsFields
    }
  }

  ${masterSupplierDetailsFields}
`;

const supplierInvoicesSearchInput = {
  query: {
    bool: {
      must: [
        {
          terms: {
            status: [InvoicesStatuses.PARTIALLY_PAID, InvoicesStatuses.PENDING],
          },
        },
      ],
    },
  },
  size: MAX_ITEMS,
};

export const useMasterSupplierDetailsWithInvoicesQuery = (
  supplierId,
  fetchPolicy,
) => {
  const queryOptions = {
    variables: { supplierId, searchInput: supplierInvoicesSearchInput },
    ...(!!fetchPolicy && { fetchPolicy }),
  };
  const subscriptionOptions = {
    variables: { input: { id: supplierId } },
    ...(!!fetchPolicy && { fetchPolicy }),
  };

  const { data, loading, refetch } = useReactiveQuery(
    MASTER_SUPPLIER_DETAILS,
    MASTER_SUPPLIER_DETAILS_SUB,
    { queryOptions, subscriptionOptions },
  );

  return { data, loading, refetch };
};
