import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import {
  showError,
  showSuccess,
  showWarning,
  hideNotification as hideNotificationAction,
} from '../redux/notifications.js';

export const useNotificationState = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(R.propOr([], 'notifications'));

  const showSuccessNotification = (notification, time) =>
    dispatch(showSuccess(notification, time));

  const showWarningNotification = (notification, time) =>
    dispatch(showWarning(notification, time));

  const showErrorNotification = (notification, time) =>
    dispatch(showError(notification, time));

  const hideNotification = (id) => dispatch(hideNotificationAction(id));

  return {
    showSuccessNotification,
    showWarningNotification,
    showErrorNotification,
    hideNotification,
    notifications,
  };
};
