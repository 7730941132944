import { bool, number, string, shape, instanceOf } from 'prop-types';

export const invoiceClientPropTypes = shape({
  _id: string.isRequired,
  invoiceNumber: number.isRequired,
  projectId: string,
  invoiceDate: string.isRequired,
  dueDate: instanceOf(Date).isRequired,
  total: number.isRequired,
  balanceAmount: number,
  currentBalance: number,
  isSelected: bool,
});
