import React from 'react';
import { useModalContext } from '@poly/admin-ui';
import {
  MainHeader,
  PageHeaderContainer,
  HeaderLinksWrapper,
} from '@poly/admin-book';

import { addAccountFormId } from './constants.js';
import { AccountForm } from './forms/AccountForm.js';
import { ExportToExcelButton } from './export/ExportToExcelButton.js';
import { ExportToPDFButton } from './export/ExportToPDFButton.js';
import { TextButtonS } from './components.js';
import { defaultChartOfAccountsPropTypes } from './chartOfAccountsPropTypes.js';

function AddAccountButton(props) {
  const { openModalForm } = useModalContext();
  const onClick = () =>
    openModalForm({
      id: addAccountFormId,
      title: 'Create New Account',
      formId: addAccountFormId,
      btnCaption: 'Create',
      content: <AccountForm isNewAccount {...props} />,
    });

  return <TextButtonS onClick={onClick}>Add New</TextButtonS>;
}

AddAccountButton.propTypes = defaultChartOfAccountsPropTypes;

export function ChartOfAccountsPageHeader(props) {
  return (
    <PageHeaderContainer>
      <MainHeader>Chart Of Accounts</MainHeader>
      <HeaderLinksWrapper>
        <AddAccountButton {...props} />
        <ExportToPDFButton {...props} />
        <ExportToExcelButton {...props} />
      </HeaderLinksWrapper>
    </PageHeaderContainer>
  );
}
