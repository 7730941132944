import styled from 'styled-components';
import { EntityStatistics, Divider } from '@poly/admin-book';

export const EntityStatisticsS = styled(EntityStatistics)`
  width: 325px;
`;

export const DividerS = styled(Divider)`
  margin: 0 0 30px 0;
`;
