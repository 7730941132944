import React from 'react';
import * as R from 'ramda';
import { getYear } from 'date-fns';
import styled from 'styled-components';
import { func, shape, bool, number, oneOfType, string } from 'prop-types';
import { parseFieldToNumberIfExists } from '@poly/client-utils';
import { TableSearchInput } from '@poly/admin-ui';
import {
  PageHeaderContainer,
  HeaderLinksWrapper,
  MainHeader,
} from '@poly/admin-book';

import {
  YearSelect,
  ThresholdSelect,
  Supplier1099Select,
} from './supplier1099ReportFilterComponents.js';
import { SpaceBetween } from '../../components/FlexContainer.js';
import { SearchHeaderButton } from '../../components/SearchHeaderColumn.js';
import { PrintSupplier1099ReportBtn } from './Supplier1099ReportPrintBtn.js';
import {
  ExportXLSSupplier1099ReportBtn,
  filteredRowsPropTypes,
} from './Supplier1099ReportExportBtn.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';

const Supplier1099ReportLinksWrapper = styled(HeaderLinksWrapper)`
  margin-bottom: 10px;
`;

const Supplier1099ReportSearchWrapper = styled(SpaceBetween)`
  flex-direction: column;
  margin-top: -20px;
  align-items: flex-end;
`;

const Supplier1099ReportTitle = styled(MainHeader)`
  margin-right: 100px;
`;

// supplier1099ReportFilters :: Supplier1099ReportFilters
const supplier1099ReportFilters = {
  threshold: 'threshold',
  is1099: 'is1099',
  year: 'year',
};

const supplier1099ReportHeaderConfig = [
  {
    name: supplier1099ReportFilters.threshold,
    defaultValue: 0,
  },
  {
    name: supplier1099ReportFilters.is1099,
    defaultValue: 'all',
  },
  {
    name: supplier1099ReportFilters.year,
    defaultValue: getYear(new Date()),
  },
];

// generateSearchSupplier1099ReportQuery :: Supplier1099ReportFilters -> Supplier1099ReportQuery
const generateSearchSupplier1099ReportQuery = R.compose(
  parseFieldToNumberIfExists('year'),
  parseFieldToNumberIfExists('threshold'),
  R.pick(['threshold', 'is1099', 'year']),
  R.when(R.propSatisfies(R.equals('all'), 'is1099'), R.dissoc('is1099')),
  R.defaultTo({}),
);

export function Supplier1099ReportHeader({ query, setQuery, filteredRows }) {
  const { searchFilters, handlers } = useSearchFilters(
    supplier1099ReportHeaderConfig,
  );

  const onFilter = () =>
    setQuery(generateSearchSupplier1099ReportQuery(searchFilters));

  const disableExport = !query;
  const year = query?.year;

  return (
    <PageHeaderContainer contentStyles="margin-top: 10px;">
      <Supplier1099ReportTitle>1099 Report</Supplier1099ReportTitle>
      <ThresholdSelect
        name={supplier1099ReportFilters.threshold}
        value={searchFilters.threshold}
        onChange={handlers.threshold}
      />
      <Supplier1099Select
        name={supplier1099ReportFilters.is1099}
        value={searchFilters.is1099}
        onChange={handlers.is1099}
      />
      <YearSelect
        name={supplier1099ReportFilters.year}
        value={searchFilters.year}
        onChange={handlers.year}
      />
      <SearchHeaderButton size="small" onClick={onFilter}>
        Filter
      </SearchHeaderButton>
      <Supplier1099ReportSearchWrapper>
        <Supplier1099ReportLinksWrapper withoutMargin>
          <PrintSupplier1099ReportBtn
            disabled={disableExport}
            year={year}
            filteredRows={filteredRows}
          />
          <ExportXLSSupplier1099ReportBtn
            disabled={disableExport}
            year={year}
            filteredRows={filteredRows}
          />
        </Supplier1099ReportLinksWrapper>
        <TableSearchInput />
      </Supplier1099ReportSearchWrapper>
    </PageHeaderContainer>
  );
}

Supplier1099ReportHeader.propTypes = {
  query: shape({
    is1099: bool,
    year: oneOfType([string, number]),
    threshold: oneOfType([string, number]),
  }),
  setQuery: func.isRequired,
  filteredRows: filteredRowsPropTypes,
};
