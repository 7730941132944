import { gql } from '@apollo/client';

export const CREATE_SUPPLIER_MUTATION = gql`
  mutation CREATE_SUPPLIER_MUTATION($input: CreateSupplierInput!) {
    createSupplier(input: $input) {
      supplier {
        _id
      }
    }
  }
`;
