import React from 'react';
import * as R from 'ramda';
import { func, string } from 'prop-types';
import { useQuery, gql } from '@apollo/client';
import { entityToOptionByLabelPath } from '@poly/client-utils';
import { Select } from '@poly/admin-book';

// formatSuppliersOptions :: [Supplier] -> [Option]
const formatSuppliersOptions = R.map(
  entityToOptionByLabelPath(['company', 'name']),
);

const payrollProvidersQuery = gql`
  query payrollProvidersQuery($searchInput: CollectionSearchParams!) {
    searchSuppliers(input: $searchInput) {
      hits {
        _id
        company {
          name
        }
      }
    }
  }
`;

export function PayRollProviderSelect({ onChange, value, ...props }) {
  const { data, loading } = useQuery(payrollProvidersQuery, {
    variables: {
      searchInput: {
        query: {
          bool: {
            must: [{ term: { 'misc.isPayrollProvider': true } }],
          },
        },
      },
      from: 0,
      size: 25,
    },
    fetchPolicy: 'network-only',
  });

  const suppliers = R.pathOr([], ['searchSuppliers', 'hits'], data);

  const selectProps = {
    value,
    loading,
    onChange,
    options: formatSuppliersOptions(suppliers),
    ...props,
  };

  return <Select {...selectProps} />;
}

PayRollProviderSelect.propTypes = {
  onChange: func.isRequired,
  value: string,
};
