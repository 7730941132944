import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { bool, number, shape, string } from 'prop-types';
import { WeeklyServiceTicketStatuses } from '@poly/constants';
import { usePristineSubscribe } from '@poly/client-routing';
import { getFileNameWithoutExt } from '@poly/utils';
import { FormCreator } from '@poly/admin-book';
import {
  useOnSubmitSetStopSubmitting,
  useSidebarTableFormContext,
  CommonSidebarFormWrapper,
  useNotificationState,
} from '@poly/admin-ui';

import { commonModalLayout } from '../../../modules/forms/common.js';
import { selectSingleFileNameWithUpload } from '../../../utils/files.js';
import {
  getWeeklyServiceTicketFormSections,
  notCompletedIsNotChecked,
} from './form/getWeeklyServiceTicketFormSections.js';

const addWeeklyServiceTicketFormId = 'addWeeklyServiceTicketFormId';
const editWeeklyServiceTicketFormId = 'editWeeklyServiceTicketFormId';

const addWeeklyServiceTicketMutation = gql`
  mutation createWeeklyServiceTicket($input: WeeklyServiceTicketInput!) {
    createWeeklyServiceTicket(input: $input) {
      _id
    }
  }
`;

export const editWeeklyServiceTicketMutation = gql`
  mutation updateWeeklyServiceTicket($input: WeeklyServiceTicketInput!) {
    updateWeeklyServiceTicket(input: $input) {
      _id
    }
  }
`;

export const ticketPropTypes = shape({
  week: number.isRequired,
  hours: number.isRequired,
  attachment: shape({
    url: string.isRequired,
    fileName: string.isRequired,
  }),
});

export function WeeklyServiceTicketForm({
  week,
  isEdit,
  ticket,
  projectId,
  weeklyPorterHours,
}) {
  const formId = isEdit
    ? editWeeklyServiceTicketFormId
    : addWeeklyServiceTicketFormId;
  const mutation = isEdit
    ? editWeeklyServiceTicketMutation
    : addWeeklyServiceTicketMutation;

  const [mutate] = useMutation(mutation);
  const formSubscriptionProps = usePristineSubscribe();
  const { showSuccessNotification } = useNotificationState();
  const { formSetter } = useSidebarTableFormContext();

  const onCancel = () => formSetter(null);

  const onSubmitHandler = async (values) => {
    const isTicketCompleted = notCompletedIsNotChecked(values);

    await mutate({
      variables: {
        input: {
          week,
          projectId,
          ticket: {
            hours: isTicketCompleted ? values.hours : 0,
            extraHours: isTicketCompleted ? values.extraHours : 0,
            status: isTicketCompleted
              ? WeeklyServiceTicketStatuses.approved
              : WeeklyServiceTicketStatuses.notCompleted,
            ...(isTicketCompleted
              ? {
                  attachment: selectSingleFileNameWithUpload(values.attachment),
                }
              : {}),
          },
        },
      },
    });
    showSuccessNotification(`Ticket Was ${isEdit ? 'Updated' : 'Added'}.`);
    onCancel();
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  const title = `${isEdit ? 'Edit' : 'Add'} Weekly Service Ticket`;

  const initialValues = isEdit
    ? {
        attachment: ticket.attachment
          ? [
              {
                fileName: getFileNameWithoutExt(ticket.attachment.fileName),
                url: ticket.attachment.url,
              },
            ]
          : [],
        hours: ticket.hours,
        extraHours: ticket.extraHours,
        ...(ticket.status === WeeklyServiceTicketStatuses.notCompleted
          ? { isNotCompleted: true }
          : {}),
      }
    : {
        attachment: [],
        ...(weeklyPorterHours === 0 ? { hours: 0 } : {}),
      };

  return (
    <CommonSidebarFormWrapper
      isTableRow
      title={title}
      formId={formId}
      onCancel={onCancel}
      submitCaption={isEdit ? 'Save' : 'Add Ticket'}
    >
      <FormCreator
        key={week}
        id={formId}
        onSubmit={onSubmit}
        layout={commonModalLayout}
        initialValues={initialValues}
        sections={getWeeklyServiceTicketFormSections(weeklyPorterHours)}
        {...formSubscriptionProps}
      />
    </CommonSidebarFormWrapper>
  );
}

WeeklyServiceTicketForm.propTypes = {
  isEdit: bool,
  week: number.isRequired,
  ticket: ticketPropTypes,
  projectId: string.isRequired,
  weeklyPorterHours: number.isRequired,
};

WeeklyServiceTicketForm.defaultProps = { isEdit: false };
