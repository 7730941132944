import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { bool, shape, arrayOf } from 'prop-types';
import { useMapConfigToTableProps } from '@poly/admin-ui/src/hooks/useMapConfigToTableProps.js';
import { pathOrNothingUI } from '@poly/client-utils/src/objects.js';
import { Table } from '@poly/admin-book/src/Table/Table.js';
import { assocBy } from '@poly/utils/src/ramda.js';
import { formatDate } from '@poly/utils/src/dates.js';
import { isNilOrEmpty } from '@poly/utils/src/general.js';
import { NOTHING_UI_STRING } from '@poly/constants/src/misc.js';
import { centsToDollarsWithFormat } from '@poly/utils/src/converters.js';
import { moneyColumnStyles } from '@poly/admin-book/src/Table/columnStyles.js';

import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import { SupplierLink } from '../../components/Links.js';
import { PropertyLink } from '../../sidebars/PropertySidebar/useOpenPropertySidebar.js';
import { UserLink } from '../../sidebars/UserSidebar/useOpenUserSidebar.js';

const TabWrapper = styled.div`
  width: 100%;
  height: 100%;
  @media (max-width: 1860px) {
    overflow-x: scroll;
  }
`;

const SubCategoryReportTableS = styled(Table)`
  @media (max-width: 1860px) {
    width: 1860px;
  }
  th:nth-child(1),
  td:nth-child(1) {
    width: 85px;
  }
  th:nth-child(2),
  td:nth-child(2) {
    width: 90px;
  }
  th:nth-child(3),
  td:nth-child(3) {
    width: 250px;
  }
  ${moneyColumnStyles(5, 110)}
  ${moneyColumnStyles(6, 100)}
  ${moneyColumnStyles(7, 100)}
  ${moneyColumnStyles(8)}
`;

const SubCategoryReportPrintTableS = styled(Table)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 90px;
  }
  th:nth-child(2),
  td:nth-child(2) {
    width: 90px;
  }
`;

// getClientGLCode :: BatchSubCategoryReport -> String
const getClientGLCode = R.compose(
  R.ifElse(
    isNilOrEmpty,
    R.always(NOTHING_UI_STRING),
    R.compose(R.join('-'), R.juxt([R.prop('code'), R.prop('name')])),
  ),
  R.prop('clientGLCode'),
);

// getApprovedBy :: {approvedBy: User} -> String
const getApprovedBy = (isPrint) =>
  R.compose(
    R.ifElse(
      isNilOrEmpty,
      R.always(NOTHING_UI_STRING),
      R.compose(
        R.ifElse(R.always(isPrint), R.prop('name'), UserLink),
        assocBy('name', R.path(['profile', 'fullName'])),
      ),
    ),
    R.prop('approvedBy'),
  );

const getSubCategoryReportTableConfig = (isPrintPDF) => [
  [
    'Invoice Date',
    R.compose(
      R.ifElse(isNilOrEmpty, R.always(NOTHING_UI_STRING), formatDate),
      R.prop('invoiceDate'),
    ),
  ],
  [
    'Project #',
    ({ project }) => <ProjectLink {...project} isPrintPDF={isPrintPDF} />,
  ],
  ['Description', pathOrNothingUI(['project', 'description'])],
  [
    'Supplier',
    R.compose(
      R.ifElse(
        isNilOrEmpty,
        R.always(NOTHING_UI_STRING),
        R.compose(SupplierLink, assocBy('name', R.path(['company', 'name']))),
      ),

      R.prop('supplier'),
    ),
  ],
  ['Amount', R.compose(centsToDollarsWithFormat, R.propOr(0, 'invoiceAmount'))],
  [
    'Mgt Fee',
    R.compose(centsToDollarsWithFormat, R.propOr(0, 'managementFee')),
  ],
  ['Tax', R.compose(centsToDollarsWithFormat, R.propOr(0, 'taxAmount'))],
  ['Total', R.compose(centsToDollarsWithFormat, R.propOr(0, 'total'))],
  ['Cost Center', pathOrNothingUI(['costCenter'])],
  ['GL Code', getClientGLCode],
  ['Sub Category', pathOrNothingUI(['subCategoryName'])],
  ['Approved By', getApprovedBy(isPrintPDF)],
  ['Area', pathOrNothingUI(['areaName'])],
  ['Region', pathOrNothingUI(['regionName'])],
  [
    'Property',
    R.compose(
      R.ifElse(isNilOrEmpty, R.always(NOTHING_UI_STRING), PropertyLink),
      R.prop('property'),
    ),
  ],
];

export function SubCategoryReportTable({ reportItems, isPrint, pagination }) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    getSubCategoryReportTableConfig(isPrint),
    reportItems,
  );

  const TableComponent = isPrint
    ? SubCategoryReportPrintTableS
    : SubCategoryReportTableS;

  return (
    <TabWrapper>
      <TableComponent pagination={pagination} {...tableProps} />
    </TabWrapper>
  );
}

SubCategoryReportTable.propTypes = {
  reportItems: arrayOf(shape({})),
  isPrint: bool,
  pagination: shape({}),
};
