import * as R from 'ramda';
import { forceTitleCase } from '@poly/utils/src/strings.js';

import { purchaseOrderStatusUI, purchaseOrderTypesUI } from './constants.js';

// getPurchaseOrdersTypeUI :: PurchaseOrder -> String
export const getPurchaseOrdersTypeUI = R.compose(
  R.prop(R.__, purchaseOrderTypesUI),
  R.prop('type'),
);

// getPurchaseOrdersTypeUI :: String -> String
export const getPurchaseOrdersStatusUI = R.compose(
  forceTitleCase,
  R.prop(R.__, purchaseOrderStatusUI),
);

// preparePurchaseOrderBeforeMutation :: FormData -> CreateAdminPurchaseOrderInput
export const preparePurchaseOrderBeforeMutation = R.compose(
  R.evolve({
    notificationContactsEmails: R.map(R.prop('value')),
  }),
  R.unless(R.prop('propertyIds'), R.dissoc('propertyIds')),
);
