import React from 'react';
import * as R from 'ramda';
import { bool, string, func } from 'prop-types';

import {
  DnDSpan,
  DropzoneS,
  DnDWrapper,
  AreaWrapper,
  DragLineWrapper,
  UploadFilesBtnS,
} from './styles.js';
import { UploadIcon } from './UploadIcon.js';
import { DragOverComp, getDragAreaText } from './onDragComp.js';
import { fileErrorPropTypes } from './UploadFileLogic.js';

export function DragAreaComponent({ name, multiple, ...props }) {
  const text = `Upload file${multiple ? 's' : ''}`;

  return (
    <DnDWrapper>
      <UploadIcon />
      <DnDSpan>{getDragAreaText(multiple)}</DnDSpan>
      <UploadFilesBtnS
        text={text}
        multiple={multiple}
        id={`text-area-file-upload-${name}`}
        {...props}
      />
    </DnDWrapper>
  );
}

DragAreaComponent.propTypes = {
  name: string,
  multiple: bool,
};

// dragdropHandler :: (File|[File] -> _, Boolean) -> [File] -> _
const dragdropHandler = (onDrop, multiple) => (acceptedFiles) =>
  acceptedFiles.length > 0 && !multiple
    ? onDrop([acceptedFiles[0]])
    : onDrop(acceptedFiles);

export function DragArea({
  isDragOver,
  onDrop,
  onDragEnter,
  onDragLeave,
  onFileUpload,
  name,
  hasError,
  error,
  multiple,
  onDropRejected,
  dragAreaHeight,
}) {
  const onDropHandler = dragdropHandler(onDrop, multiple);

  return (
    <AreaWrapper {...{ error, hasError, isDragOver, name }}>
      <DropzoneS
        disableClick
        onDrop={onDropHandler}
        multiple
        {...{ onDragEnter, onDragLeave }}
        onDropRejected={onDropRejected}
      >
        {({ getInputProps, getRootProps }) => (
          <DragLineWrapper
            {...{ isDragOver, ...getRootProps(), dragAreaHeight }}
          >
            {isDragOver ? (
              <DragOverComp multiple={multiple} />
            ) : (
              <DragAreaComponent
                onChange={onFileUpload}
                // we don't use ref from react-dropzone to skip upload behaviour
                {...{ ...R.omit(['ref'], getInputProps()), multiple, name }}
              />
            )}
          </DragLineWrapper>
        )}
      </DropzoneS>
    </AreaWrapper>
  );
}

DragArea.propTypes = {
  isDragOver: bool.isRequired,
  onDrop: func.isRequired,
  onDragEnter: func.isRequired,
  onDragLeave: func.isRequired,
  onFileUpload: func.isRequired,
  onDropRejected: func.isRequired,
  name: string.isRequired,
  hasError: bool.isRequired,
  error: fileErrorPropTypes,
  multiple: bool,
  dragAreaHeight: string,
};

DragArea.defaultProps = { multiple: true };
