import { createAction, handleAction } from 'redux-actions';

const initialRecentProjects = {
  isDisabled: true,
  clientId: '',
  startDate: '',
  endDate: '',
};

export const setRecentProjects = createAction('SET_RECENT_PROJECTS');

export const recentProjectsReducer = handleAction(
  setRecentProjects,
  (_, { payload }) => payload,
  initialRecentProjects,
);
