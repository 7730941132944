import * as R from 'ramda';
import { validateEmail } from '@poly/utils';

import { MailToErrors } from './constants.js';
import { getEntityValidator } from './mailToUtils.js';

const validateEmailsExists = R.pipe(
  R.path(['emails']),
  R.complement(R.isEmpty),
);

const validateEmailsNotEmpty = R.pipe(
  R.path(['emails']),
  R.pipe(R.reject(R.isNil), R.complement(R.isEmpty)),
);

export const mailToValidator = [
  getEntityValidator('requestor', [
    [validateEmailsExists, MailToErrors.RequestorError],
    [validateEmailsNotEmpty, MailToErrors.SupplierNoEmailError],
  ]),
  getEntityValidator('siteContact', [
    [validateEmailsExists, MailToErrors.SiteContactError],
    [validateEmailsNotEmpty, MailToErrors.SupplierNoEmailError],
  ]),
  getEntityValidator('supplier', [
    [validateEmailsExists, MailToErrors.SupplierMissedError],
    [validateEmailsNotEmpty, MailToErrors.SupplierNoEmailError],
  ]),

  getEntityValidator('clientManager', [
    [validateEmailsExists, MailToErrors.ClientManagersMissedError],
    [validateEmailsNotEmpty, MailToErrors.ClientManagersNoEmailError],
  ]),
  [
    'other',
    [
      [
        R.when(
          R.path(['shouldSend']),
          R.pipe(
            R.path(['emails']),
            R.map(validateEmail),
            R.none(R.equals(false)),
          ),
        ),
        MailToErrors.IncorrectEmailError,
      ],
    ],
  ],
];
