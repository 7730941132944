import * as R from 'ramda';
import { gql } from '@apollo/client';
import React, { useMemo, useEffect } from 'react';
import { ASC_SORT_ORDER } from '@poly/constants';
import {
  useInfiniteScrollQueryWithSubscription,
  highlightMatchesInObjectCurried,
  keywordSortQuery,
} from '@poly/client-utils';
import {
  useMapConfigToTableProps,
  USER_SEARCH_CHANGED,
  UnlinkContactBtn,
  EmailLink,
  PhoneLink,
} from '@poly/admin-ui';

import { useSidebarLogicContext } from '../../SidebarLogicContext.js';

function ContactNameLink(props) {
  const { ContactLink } = useSidebarLogicContext();

  const contactLinkProps = R.pick(['_id', 'fullName'], props);

  return <ContactLink {...contactLinkProps} />;
}

const CONTACTS_BY_SEARCH = gql`
  query CONTACTS_BY_SEARCH($searchInput: CollectionSearchParams!) {
    searchUsers(input: $searchInput) {
      hits {
        _id
        fullName
        links {
          propertyId
          clientId
          supplierId
        }
        title
        email
        profile {
          cellPhoneNumber
          workPhoneNumber
          workPhoneNumberExt
          faxPhoneNumber
        }
        isClientManager
      }
      total
    }
  }
`;

// getContactWorkPhone :: User -> String
const getContactWorkPhone = R.applySpec({
  number: R.path(['profile', 'workPhoneNumber']),
  ext: R.path(['profile', 'workPhoneNumberExt']),
});

// getContactCellPhone :: User -> String
const getContactCellPhone = R.applySpec({
  number: R.path(['profile', 'cellPhoneNumber']),
});

const contactsTableConfig = [
  ['Name', ContactNameLink],
  ['Title', R.prop('title')],
  ['Phone', (contact) => <PhoneLink {...getContactWorkPhone(contact)} />],
  ['Mobile', (contact) => <PhoneLink {...getContactCellPhone(contact)} />],
  ['Email', EmailLink],
  ['', UnlinkContactBtn],
];

const searchTextPaths = [['fullName'], ['title']];

// prepareContactsForTable :: (String, Entity) -> SearchUsersResult -> [User]
const prepareContactsForTable = (searchTerm, entity) =>
  R.compose(
    R.map(R.mergeDeepLeft({ entity })),
    R.map(highlightMatchesInObjectCurried(searchTextPaths, searchTerm)),
    R.pathOr([], ['searchUsers', 'hits']),
  );

export const useSearchSubTabsContactsQuery = ({
  status,
  entity,
  searchTerm = '',
}) => {
  const searchInput = useMemo(
    () => ({
      searchTerm,
      sort: keywordSortQuery(['profile', 'fullName'])(ASC_SORT_ORDER),
      query: {
        bool: {
          must: [
            { match: { status } },
            ...(entity?._id
              ? [
                  {
                    nested: {
                      path: 'links',
                      query: {
                        term: { [`links.${entity.name}Id`]: entity._id },
                      },
                    },
                  },
                ]
              : []),
          ],
        },
      },
    }),
    [status, entity, searchTerm],
  );

  const { data, loading, tableProps, debouncedRefetch } =
    useInfiniteScrollQueryWithSubscription(
      CONTACTS_BY_SEARCH,
      searchInput,
      { endpointName: 'searchUsers', inputName: 'searchInput' },
      USER_SEARCH_CHANGED,
      { searchInput },
      !entity?._id,
    );

  useEffect(() => {
    if (data) {
      debouncedRefetch();
    }
  }, []);

  const dataTableProps = useMapConfigToTableProps(
    prepareContactsForTable(searchTerm, entity),
    contactsTableConfig,
    data,
  );

  const count = R.pathOr(0, ['searchUsers', 'total'], data);

  return {
    count,
    loading,
    tableProps: {
      ...tableProps,
      ...dataTableProps,
    },
  };
};
