import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import { MasterSupplierStatus, SupplierTypes } from '@poly/constants';
import {
  masterSupplierStatusColorUI,
  materSupplierStatusLabelUI,
  TableSearchInput,
  TablePagination,
  useSearchMasterSuppliersQuery,
  MasterSuppliersTable,
} from '@poly/admin-ui';
import {
  PageHeaderContainer,
  MainHeader,
  TableCard,
  Status,
} from '@poly/admin-book';
import { formatCount, forceTitleCase } from '@poly/utils';
import { TableWithPaginationContainer } from '@poly/admin-book/src/Card.js';

import { PageTabs } from '../../modules/tabs/TabsWithRouter.js';
import { usePrefetchMasterSuppliersByType } from './hooks/usePrefetchMasterSuppliersByType.js';
import { routesNames } from '../../routes/index.js';

const { ACTIVE, BLOCKED } = MasterSupplierStatus;

// getMasterSuppliersCount :: SearchMasterSuppliers -> String
const getMasterSuppliersCount = R.compose(
  formatCount,
  R.pathOr(0, ['searchMasterSuppliers', 'total']),
);

function MasterSuppliersTabTitle({ status, type }) {
  const { data } = useSearchMasterSuppliersQuery(status, type);

  const text = `${
    materSupplierStatusLabelUI[status]
  } (${getMasterSuppliersCount(data)})`;
  const color = masterSupplierStatusColorUI[status];

  return <Status color={color} text={text} />;
}

MasterSuppliersTabTitle.propTypes = {
  status: string.isRequired,
  type: string.isRequired,
};

function MasterSuppliersTab(props) {
  return (
    <TableCard>
      <TableWithPaginationContainer {...{ paginationVisible: true }}>
        <MasterSuppliersTable
          masterSupplierRootUrl={routesNames.MASTER_SUPPLIER}
          {...props}
        />
      </TableWithPaginationContainer>
      <TablePagination />
    </TableCard>
  );
}

function MasterSuppliersPage({ type }) {
  return (
    <>
      <PageHeaderContainer>
        <MainHeader>{`Master Suppliers ${forceTitleCase(type)}`}</MainHeader>
      </PageHeaderContainer>
      <PageTabs
        toolBar={<TableSearchInput />}
        tabs={[
          [
            <MasterSuppliersTabTitle
              key={`master-tab-${ACTIVE}`}
              status={ACTIVE}
              type={type}
            />,
            ACTIVE,
            <MasterSuppliersTab key={ACTIVE} status={ACTIVE} type={type} />,
          ],
          [
            <MasterSuppliersTabTitle
              key={`master-tab-${BLOCKED}`}
              status={BLOCKED}
              type={type}
            />,
            BLOCKED,
            <MasterSuppliersTab key={BLOCKED} status={BLOCKED} type={type} />,
          ],
        ]}
        defaultValue={ACTIVE}
      />
    </>
  );
}

MasterSuppliersPage.propTypes = { type: string.isRequired };

export function MasterSuppliersAdministrativePage() {
  usePrefetchMasterSuppliersByType(SupplierTypes.ADMINISTRATIVE);

  return <MasterSuppliersPage type={SupplierTypes.ADMINISTRATIVE} />;
}

export function MasterSuppliersSubcontractorsPage() {
  usePrefetchMasterSuppliersByType(SupplierTypes.SUBCONTRACTOR);

  return <MasterSuppliersPage type={SupplierTypes.SUBCONTRACTOR} />;
}
