export const aminPurchaseOrderStatuses = {
  PENDING: 'pendingPoStatus',
  ACTIVE: 'activePoStatus',
  BLOCKED: 'blockedPoStatus',
  COMPLETED: 'completePoStatus',
};

export const adminPurchaseOrderTypes = {
  STANDARD: 'standardPoType',
  PLANNED: 'plannedPoType',
  BLANKED: 'blanketPoType',
  CONTRACT: 'contractPoType',
};
