import { gql } from '@apollo/client';

export const UserAlertCommonFields = gql`
  fragment UserAlertCommonFields on UserAlert {
    _id
    message
    isArchived
    mentions {
      users {
        _id
        fullName
      }
      groups {
        _id
        name
      }
    }
    createdAt
    createdBy {
      _id
      fullName
    }
    collection
    documentId
    documentUpdateId
    documentUpdateCommentId
    url
  }
`;
