import React, { useState } from 'react';

import * as R from 'ramda';
import {
  base64ToBlob,
  copyToClipBoard,
  openPrintWindowWithData,
} from '@poly/client-utils';
import { func, node, shape, string, bool } from 'prop-types';
import {
  useNotificationState,
  useModalContext,
  CommonPrintLayout,
} from '@poly/admin-ui';
import {
  Button,
  Icon,
  LinkButtonWithLoader,
  Text,
  defaultTheme,
} from '@poly/admin-book';
import styled from 'styled-components';
import { useMutation, gql } from '@apollo/client';
import QRCode from 'qrcode';
import downloadFile from 'downloadjs';
import { isPropertyInactive } from '@poly/utils/src/property.js';

import { isClientInactive } from '../ClientSidebar/helpers.js';

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ButtonsWrapper = styled(ButtonWrapper)`
  gap: 15px;
`;

function LinkButtonWithIcon({ iconName, title, disabled, ...props }) {
  return (
    <ButtonWrapper>
      <Icon
        name={iconName}
        color={
          disabled
            ? defaultTheme.colors.secondary
            : defaultTheme.colors.primaryLight
        }
        size="16"
      />
      <LinkButtonWithLoader disabled={disabled} {...props}>
        {title}
      </LinkButtonWithLoader>
    </ButtonWrapper>
  );
}

LinkButtonWithIcon.propTypes = {
  iconName: string.isRequired,
  title: string.isRequired,
  disabled: bool,
};

const QRCodeModalLayoutS = styled.div`
  width: 332px;
  height: 384px;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
`;

const SubTitleText = styled(Text)`
  color: #999999;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
`;

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 18px 0;
`;

const ButtonS = styled(Button)`
  width: 100%;
  margin-top: ${R.propOr('0', 'marginTop')};
`;

const ModalHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const IconS = styled(Icon)`
  cursor: pointer;
`;

function QRCodeModalLayout({ children }) {
  return <QRCodeModalLayoutS>{children}</QRCodeModalLayoutS>;
}

QRCodeModalLayout.propTypes = {
  children: node.isRequired,
};

function QRCodeImage({ qrCode }) {
  return (
    <ImageWrapper>
      <img src={qrCode} alt="QR Code" />
    </ImageWrapper>
  );
}

QRCodeImage.propTypes = {
  qrCode: string.isRequired,
};

// getQrCodeBase64String :: String -> String
const getQrCodeBase64String = R.compose(R.last, R.split(','));

function QRCodeModal({ qrCode, closeModal, name }) {
  const handleDownload = () => {
    const base64Str = getQrCodeBase64String(qrCode);
    const qrCodeBlob = base64ToBlob(base64Str, 'image/png');
    downloadFile(qrCodeBlob, `${name}.png`);
  };

  const handlePrint = async () => {
    await openPrintWindowWithData({
      Layout: CommonPrintLayout,
      fileName: name,
      Table: QRCodeImage,
      qrCode,
    });
  };

  return (
    <>
      <ModalHeaderWrapper>
        <Text size="20px">Print QR code</Text>
        <IconS onClick={closeModal} name="cross" size="12" />
      </ModalHeaderWrapper>
      <SubTitleText>You can download the code or scan now</SubTitleText>
      <QRCodeImage qrCode={qrCode} />
      <ButtonS size="middle" onClick={handleDownload}>
        Download QR code
      </ButtonS>
      <ButtonS
        styleType="secondaryLighter"
        size="middle"
        marginTop="10px"
        onClick={handlePrint}
      >
        Print QR code
      </ButtonS>
    </>
  );
}

QRCodeModal.propTypes = {
  qrCode: string.isRequired,
  closeModal: func.isRequired,
  name: string.isRequired,
};

const qrCodeModalId = 'qr_code_modal_id';

function QRCodeModalButton({ url, name, ...props }) {
  const { openModal, closeModal } = useModalContext();

  const handleCloseModal = () => closeModal(qrCodeModalId);

  const handleOpenQRCodeModal = async () => {
    const qrCode = await QRCode.toDataURL(url);

    openModal({
      id: qrCodeModalId,
      content: (
        <QRCodeModal
          qrCode={qrCode}
          closeModal={handleCloseModal}
          name={name}
        />
      ),
      Layout: QRCodeModalLayout,
      simple: true,
    });
  };

  return (
    <LinkButtonWithIcon
      title="Print QR code"
      iconName="qr-code"
      onClick={handleOpenQRCodeModal}
      {...props}
    />
  );
}

QRCodeModalButton.propTypes = {
  url: string.isRequired,
  name: string.isRequired,
  isDisabled: bool,
};

function CopyUrlButton({ url, ...props }) {
  const { showSuccessNotification } = useNotificationState();

  const onCopyUrlClick = () => {
    copyToClipBoard(url);
    showSuccessNotification(`Copied ${url}`);
  };

  return (
    <LinkButtonWithIcon
      title="Copy link"
      iconName="copy"
      onClick={onCopyUrlClick}
      {...props}
    />
  );
}

CopyUrlButton.propTypes = {
  url: string.isRequired,
};

const CREATE_REQUEST_APP_ACCESS_MUTATION = gql`
  mutation createRequestAppAccess($input: CreateRequestAppAccessInput!) {
    createRequestAppAccess(input: $input)
  }
`;

const GenerateButton = styled(Button)`
  height: 32px;
  font-size: 12px;
`;

function RequestAppAccessButton({ propertyId, clientId, ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const { showSuccessNotification } = useNotificationState();

  const [createAccess] = useMutation(CREATE_REQUEST_APP_ACCESS_MUTATION, {
    variables: {
      input: { propertyId, clientId },
    },
  });

  const handleClick = async () => {
    setIsLoading(true);
    await createAccess();
    setIsLoading(false);
    showSuccessNotification('QR code and link are successfully generated');
  };

  return (
    <GenerateButton loader={isLoading} onClick={handleClick} {...props}>
      Generate QR code and link
    </GenerateButton>
  );
}

RequestAppAccessButton.propTypes = {
  propertyId: string,
  clientId: string,
};

export function SubmitProjectWebFormUrl({ property, client }) {
  const { _id, submitProjectWebFormUrl, name } = property || client;

  const isDisabled = property
    ? isPropertyInactive(property)
    : isClientInactive(client);

  if (submitProjectWebFormUrl) {
    return (
      <ButtonsWrapper>
        <CopyUrlButton url={submitProjectWebFormUrl} disabled={isDisabled} />
        <QRCodeModalButton
          url={submitProjectWebFormUrl}
          name={name}
          disabled={isDisabled}
        />
      </ButtonsWrapper>
    );
  }

  const requestBtnProps = property ? { propertyId: _id } : { clientId: _id };

  return <RequestAppAccessButton {...requestBtnProps} disabled={isDisabled} />;
}

SubmitProjectWebFormUrl.propTypes = {
  property: shape({
    _id: string.isRequired,
    submitProjectWebFormUrl: string,
  }),
  client: shape({
    _id: string.isRequired,
    submitProjectWebFormUrl: string,
  }),
};
