import * as R from 'ramda';
import {
  applySpecWithFields,
  removePropDeep,
  isNilOrEmpty,
  ofArrayLegacy,
} from '@poly/utils';
import { internationalPhoneUtils } from '@poly/client-utils/src/internationalPhoneUtils/internationalPhoneUtils.js';

// getPhoneByPathOrNull :: [String] -> Object -> String | Null
const getPhoneByPathOrNull = (path) =>
  R.compose(R.when(isNilOrEmpty, R.always(null)), R.path(path));

// prepareStaffOtherProfileFields :: FormData -> Profile
const prepareStaffOtherProfileFields = R.compose(
  R.omit(['firstName', 'lastName']),
  R.dissocPath(['address', 'addressTwo']),
  applySpecWithFields({
    addressTwo: R.pathOr('', ['address', 'addressTwo']),
    personalMobile: R.propOr(null, 'personalMobile'),
    personalPhone: R.propOr(null, 'personalPhone'),
    personalEmail: R.propOr(null, 'personalEmail'),
    socialSecurityNumber: R.propOr(null, 'socialSecurityNumber'),
    middleName: R.propOr(null, 'middleName'),
    gender: R.propOr(null, 'gender'),
    maritalStatus: R.propOr(null, 'maritalStatus'),
  }),
  R.apply(R.mergeRight),
  R.props(['general', 'contacts']),
);

/*
 *  prepareStaffUserFormDataForMutation :: Object -> Object
 */
export const prepareStaffUserFormDataForMutation = R.compose(
  removePropDeep('__typename'),
  R.applySpec({
    profile: R.compose(
      R.mergeAll,
      R.juxt([
        R.applySpec({
          cellPhoneNumber: getPhoneByPathOrNull([
            'company',
            'phones',
            'cell',
            'number',
          ]),
          workPhoneNumber: getPhoneByPathOrNull([
            'company',
            'phones',
            'work',
            'number',
          ]),
          workPhoneNumberExt: getPhoneByPathOrNull([
            'company',
            'phones',
            'work',
            'ext',
          ]),
        }),
        prepareStaffOtherProfileFields,
      ]),
    ),
    employeeInfo: R.compose(R.omit(['title']), R.prop('employeeInfo')),
    emails: R.compose(ofArrayLegacy, R.path(['systemAccess', 'email'])),
    firstName: R.path(['general', 'firstName']),
    lastName: R.path(['general', 'lastName']),
    status: R.path(['systemAccess', 'status']),
    userGroups: [
      {
        userGroupId: R.path(['systemAccess', 'userGroupId']),
        variables: R.always([]),
      },
    ],
    title: R.pathOr('', ['employeeInfo', 'title']),
    companyLocation: R.pathOr('', ['company', 'companyLocation']),
    remarks: R.prop('remarks'),
    isContractor: R.prop('isContractor'),
    loginCellPhoneNumber: R.compose(
      R.unless(R.compose(R.gt(R.__, 4), R.length), R.always(null)),
      R.when(R.identity, internationalPhoneUtils.formattingInternationalPhone),
      R.prop('loginCellPhoneNumber'),
    ),
  }),
  R.unless(R.prop('isContractor'), R.dissoc('isContractor')),
);
