import * as R from 'ramda';
import React, { useState } from 'react';
import { isBefore, startOfDay, endOfDay } from 'date-fns';
import {
  arrayOf,
  bool,
  func,
  instanceOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { ProgressiveDayPicker, Button } from '@poly/admin-book';
import { ensureIsDate } from '@poly/utils';

import {
  customModalTypes,
  customRangeModalId,
  progressiveOptionsToDates,
  progressiveRangePickerOptions,
} from './constants.js';
import { useModalContext } from '../Modal/ModalProvider.js';
import {
  prepareProgressiveRangePickerOptions,
  constructPlaceholderByValue,
  prepareLabel,
} from './progressiveRangePickerUtils.js';
import {
  ProgressiveRangePickerDropdown,
  CustomRangeModalRowWrapper,
  CustomRangeModalTypeBtn,
  CustomRangeModalLayout,
} from './styles.js';

function CustomRangeModal({ onChange, isDisabledDays }) {
  const { closeModal } = useModalContext();
  const [type, setType] = useState(customModalTypes.SINGLE_DAY);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const isDateRange = type === customModalTypes.DATE_RANGE;

  const onStartDateChange = (day) => {
    setStartDate(day);
    if (isBefore(ensureIsDate(endDate), ensureIsDate(day))) setEndDate(day);
  };

  const onCancel = () => closeModal(customRangeModalId);

  const onSave = () => {
    onChange({
      option: progressiveRangePickerOptions.CUSTOM,
      startDate: startOfDay(ensureIsDate(startDate)),
      endDate: endOfDay(
        isDateRange ? ensureIsDate(endDate) : ensureIsDate(startDate),
      ),
    });
    closeModal(customRangeModalId);
  };

  return (
    <>
      <CustomRangeModalRowWrapper>
        {R.values(customModalTypes).map((current) => (
          <CustomRangeModalTypeBtn
            key={current}
            isActive={current === type}
            onClick={() => setType(current)}
            last={current === customModalTypes.DATE_RANGE}
          >
            {prepareLabel(current)}
          </CustomRangeModalTypeBtn>
        ))}
      </CustomRangeModalRowWrapper>
      <CustomRangeModalRowWrapper>
        <ProgressiveDayPicker
          single
          value={startDate}
          onChange={onStartDateChange}
          isDisabledDays={isDisabledDays}
        />
        {isDateRange && (
          <ProgressiveDayPicker
            isDisabledDays={isDisabledDays}
            value={endDate}
            onChange={setEndDate}
            disabledBefore={startDate}
          />
        )}
      </CustomRangeModalRowWrapper>
      <CustomRangeModalRowWrapper>
        <Button size="small" type="button" onClick={onSave}>
          Apply
        </Button>
        <Button
          size="small"
          type="button"
          onClick={onCancel}
          styleType="basicSecondary"
        >
          Cancel
        </Button>
      </CustomRangeModalRowWrapper>
    </>
  );
}

CustomRangeModal.propTypes = {
  onChange: func.isRequired,
  isDisabledDays: bool,
};

// adjustAllOption :: (Bool, [String]) -> [Pair String String]
const getDropdownOptions = (enableAllOption, customOptions) =>
  R.compose(
    prepareProgressiveRangePickerOptions,
    R.unless(R.always(enableAllOption), R.omit(['NO_RANGE'])),
    R.when(R.always(customOptions), R.filter(R.includes(R.__, customOptions))),
  )(progressiveRangePickerOptions);

export function ProgressiveRangePicker({
  name,
  value,
  enableAllOption,
  label,
  width,
  size,
  onChange,
  customOptions,
  isDisabledDays,
  ...props
}) {
  const { openModal } = useModalContext();

  const onOptionSelect = (option) => {
    if (option === progressiveRangePickerOptions.CUSTOM) {
      openModal({
        id: customRangeModalId,
        Layout: CustomRangeModalLayout,
        content: (
          <CustomRangeModal
            onChange={onChange}
            isDisabledDays={isDisabledDays}
          />
        ),
      });
    } else {
      onChange({ ...progressiveOptionsToDates[option], option });
    }
  };

  return (
    <ProgressiveRangePickerDropdown
      {...props}
      size={size}
      label={label}
      width={width}
      onChange={onOptionSelect}
      value={value.option || ''}
      hideSelectedOptions={false}
      name={name || 'ProgressiveRangePicker'}
      controlShouldRenderValue={!!value.option}
      options={getDropdownOptions(enableAllOption, customOptions)}
      placeholder={constructPlaceholderByValue(value)}
    />
  );
}

ProgressiveRangePicker.displayName = 'ProgressiveRangePicker';
ProgressiveRangePicker.propTypes = {
  name: string,
  label: string,
  width: string,
  size: string,
  enableAllOption: bool,
  value: shape({
    option: string,
    startDate: oneOfType([string, instanceOf(Date)]),
    endDate: oneOfType([string, instanceOf(Date)]),
  }).isRequired,
  onChange: func.isRequired,
  customOptions: arrayOf(string),
  isDisabledDays: bool,
};

ProgressiveRangePicker.defaultProps = {
  isDisabledDays: true,
};
