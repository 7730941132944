import React from 'react';
import * as R from 'ramda';
import { string, shape } from 'prop-types';
import { InvoicesStatuses } from '@poly/constants';
import { pathEqLegacy } from '@poly/utils';

import { AddSupplierInvoiceForm } from './AddSupplierInvoiceForm.js';
import { EditProjectInvoiceForm } from './EditProjectInvoiceForm.js';
import { ApproveSupplierInvoiceForm } from './ApproveSupplierInvoiceForm.js';

// getFormBySupplierInvoice :: { payload: { supplierInvoice: { _id: ID, status: String } } } -> ReactComponent
const getFormBySupplierInvoice = R.cond([
  [
    R.pathSatisfies(R.isNil, ['payload', 'supplierInvoice', 'status']),
    R.always(AddSupplierInvoiceForm),
  ],
  [
    pathEqLegacy(
      ['payload', 'supplierInvoice', 'status'],
      InvoicesStatuses.RECEIVED,
    ),
    R.always(ApproveSupplierInvoiceForm),
  ],
  [
    R.anyPass([
      pathEqLegacy(
        ['payload', 'supplierInvoice', 'status'],
        InvoicesStatuses.REQUESTED,
      ),
      pathEqLegacy(
        ['payload', 'supplierInvoice', 'status'],
        InvoicesStatuses.REJECTED,
      ),
      pathEqLegacy(
        ['payload', 'supplierInvoice', 'status'],
        InvoicesStatuses.MANUAL_FOLLOW_UP,
      ),
    ]),
    R.always(EditProjectInvoiceForm),
  ],
  [R.T, R.always(AddSupplierInvoiceForm)],
]);

export function SupplierInvoiceForm(props) {
  const FormComponent = getFormBySupplierInvoice(props);

  return (
    <FormComponent
      {...props}
      submitCaption="Complete"
      title={R.path(['payload', 'description'], props)}
      invoiceId={R.path(['payload', 'supplierInvoice', '_id'], props)}
    />
  );
}

SupplierInvoiceForm.propTypes = {
  payload: shape({
    _id: string.isRequired,
    supplierInvoice: shape({ status: string }),
  }),
};
