import React from 'react';
import * as R from 'ramda';
import { moneyColumnStyles, Table } from '@poly/admin-book';
import { ASC_SORT_ORDER } from '@poly/constants';
import {
  calculateTotal,
  centsToDollarsWithFormat,
  formatDate,
  mapIndexed,
  renameProp,
} from '@poly/utils';
import styled from 'styled-components';
import {
  commonSortQuery,
  keywordSortQuery,
  keywordSortQueryBase,
} from '@poly/client-utils';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import { useMapConfigToTableProps } from '@poly/admin-ui';

import { FlexColumn, FlexContainer } from '../../components/FlexContainer.js';
import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import { InvoiceDueDateWithWarning } from '../PaySuppliersPage/InvoiceDueDateWithWarning.js';

const TotalFooterRow = styled.tr`
  font-weight: bold;
  font-size: 16px;
  padding-top: 10px;
  td:nth-child(5) {
    font-size: 15px !important;
  }
`;

function TotalFooter({ rows }) {
  const total = calculateTotal(R.propOr(0, 'balance'), rows);
  return (
    <TotalFooterRow>
      <td />
      <td />
      <td />
      <td />
      <td>Total</td>
      <td>{centsToDollarsWithFormat(total)}</td>
    </TotalFooterRow>
  );
}

export const invoicesPropTypes = {
  _id: string.isRequired,
  invoiceNumber: string.isRequired,
  total: number.isRequired,
  balance: number.isRequired,
};

TotalFooter.propTypes = {
  rows: arrayOf(shape(invoicesPropTypes)),
};

export const getApReportTableConfig = (isPrintPDF) => [
  ['Inv #', R.prop('invoiceNumber'), keywordSortQuery(['invoiceNumber'])],
  [
    'Project #',
    ({ project }) => <ProjectLink {...project} isPrintPDF={isPrintPDF} />,
  ],
  [
    'Inv Date',
    R.compose(formatDate, R.prop('invoiceDate')),
    (order) => [
      ...commonSortQuery(['invoiceDateOnly'])(order),
      keywordSortQueryBase(['invoiceNumber'])(ASC_SORT_ORDER),
    ],
  ],
  [
    'Due Date',
    R.compose(
      InvoiceDueDateWithWarning,
      renameProp('paymentDueDate', 'dueDate'),
    ),
  ],
  ['Inv Amount', R.compose(centsToDollarsWithFormat, R.prop('total'))],
  ['Balance', R.compose(centsToDollarsWithFormat, R.prop('balance'))],
];

const ApReportTableS = styled(Table)`
  ${moneyColumnStyles(5)}
  ${moneyColumnStyles(6)}
  @media print {
    td:nth-child(5),
    th:nth-child(5),
    td:nth-child(6),
    th:nth-child(6) {
      width: 17%;
    }
    td {
      height: 30px;
    }
    tr {
      border: none;
      box-shadow: none;

      &:first-child {
        border: none;
      }

      &:last-child {
        border: none;
      }
    }
  }
  max-height: calc(100% - 70px);
  height: auto;
`;

const MetaInfoContainerS = styled(FlexColumn)`
  align-items: flex-start;
  margin: 5px 0 5px 40px;
  height: 50px;
`;

const MetaRowContainer = styled(FlexContainer)`
  font-weight: normal;
  font-size: 15px;
  margin-top: 10px;
`;

const MetaRowTitleS = styled.div`
  font-weight: bold;
  font-size: 16px;
  width: 150px;
`;

const TableContainer = styled(FlexColumn)`
  height: 100%;
`;

export function ApReportTable({
  invoices,
  terms,
  supplierName,
  cardNumber,
  className,
  isPrintPDF,
  ...tableProps
}) {
  const tableCommonProps = useMapConfigToTableProps(
    mapIndexed((invoice, index) => ({
      ...invoice,
      popoverPosition: index < 6 ? 'left' : 'top',
    })),
    getApReportTableConfig(isPrintPDF),
    invoices,
  );

  return (
    <TableContainer className={className}>
      <MetaInfoContainerS>
        <MetaRowContainer>
          <MetaRowTitleS>Supplier Code</MetaRowTitleS>
          {cardNumber}
        </MetaRowContainer>
        <MetaRowContainer>
          <MetaRowTitleS>Supplier Name</MetaRowTitleS>
          {supplierName}
        </MetaRowContainer>
      </MetaInfoContainerS>
      <ApReportTableS
        {...tableCommonProps}
        {...tableProps}
        TableFooter={TotalFooter}
      />
    </TableContainer>
  );
}

ApReportTable.propTypes = {
  invoices: arrayOf(shape(invoicesPropTypes)),
  terms: string,
  supplierName: string,
  cardNumber: string,
  className: string,
  isPrintPDF: bool,
};
