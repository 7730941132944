import { useDispatch } from 'react-redux';
import { setGlobalTrigger } from '../../redux/globalTriger.js';

export const useWindowedTablePropsForCustomLoadButton = (tableProps) => {
  const dispatch = useDispatch();

  const handelGlobalTrigger = () => {
    dispatch(setGlobalTrigger(true));

    return Promise.resolve(true);
  };

  return {
    windowedTableProps: {
      ...tableProps,
      loadMoreItems: handelGlobalTrigger,
      threshold: 1,
      isLoading: false,
    },
    buttonProps: tableProps,
  };
};
