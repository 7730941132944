import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { removePropDeep } from '@poly/utils';
import { gql, useMutation } from '@apollo/client';
import { bool, shape, string } from 'prop-types';
import { UPDATE_CLIENT_PERMISSION } from '@poly/security';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import {
  thirdWidth,
  useModalContext,
  useNotificationState,
} from '@poly/admin-ui';
import {
  Status,
  LinkButton,
  FormCreator,
  getThemeFont,
  defaultTheme,
  getThemeColor,
} from '@poly/admin-book';

import { CheckboxWithLabel } from '../../ProjectSidebar/forms/form/components/CheckboxWithLabel.js';

const updateClientNotificationsFormId = 'updateClientNotificationsFormId';

const {
  colors: {
    statuses: { error, active },
  },
} = defaultTheme;

const getColor = (config) => (config ? active : error);

const ClientNotificationWrapper = styled.div`
  width: 100%;
  align-items: start;
  padding: 25px 20px 20px 20px;
`;

const Header = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: ${getThemeColor(['primaryLighter3'])};
  font-weight: ${getThemeFont(['medium'])};
  padding-bottom: 5px;
`;

const StatusS = styled(Status)`
  font-size: 12px;
  margin: 10px 0;
`;

const LinkButtonS = styled(LinkButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

const HeaderWrapper = styled.div`
  position: relative;
`;

const EditClientNotificationForm = styled(FormCreator)`
  div > div > div {
    font-size: 15px;
  }
`;

// getClientNotificationConfigsByProp :: { clientConfigs: ClientConfigs } -> NotificationConfigs
// NotificationConfigs = {
//    toSiteContact: Boolean
//    toRequester: Boolean
//    toClientManager: Boolean
// }
export const getClientNotificationConfigsByProp = (prop) =>
  R.compose(
    R.applySpec({
      toSiteContact: R.propOr(false, 'toSiteContact'),
      toRequester: R.propOr(false, 'toRequester'),
      toClientManager: R.propOr(false, 'toClientManager'),
    }),
    R.pathOr({}, ['configs', 'notifications', prop]),
  );

const updateClientNotificationsMutation = gql`
  mutation updateClientNotificationsMutation(
    $input: UpdateClientNotificationsInput!
  ) {
    updateClientNotifications(input: $input) {
      _id
    }
  }
`;

// prepareInputForMutation :: { formData: FormData, clientId: ID } -> UpdateClientNotificationsInput
export const prepareInputForMutation = R.applySpec({
  clientId: R.prop('clientId'),
  update: R.compose(
    R.pick([
      'serviceRequestConfirmationEmail',
      'projectClientStatusUpdateDefaultMailTo',
    ]),
    R.prop('formData'),
  ),
});

const formCreatorSections = [
  {
    label: 'Client Service Request Confirmation Email',
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        order: 1,
        layout: { row: 1, width: thirdWidth },
        field: {
          name: 'serviceRequestConfirmationEmail.toSiteContact',
          Component: (props) => (
            <CheckboxWithLabel {...props} label="Site Contact" />
          ),
        },
      },
      {
        order: 2,
        layout: { row: 1, width: thirdWidth },
        field: {
          name: 'serviceRequestConfirmationEmail.toRequester',
          Component: (props) => (
            <CheckboxWithLabel {...props} label="Requester" />
          ),
        },
      },
      {
        order: 3,
        layout: { row: 1, width: thirdWidth },
        field: {
          name: 'serviceRequestConfirmationEmail.toClientManager',
          Component: (props) => (
            <CheckboxWithLabel {...props} label="Client Manager" />
          ),
        },
      },
    ],
  },
  {
    label: 'Project Client Status Update Default Mail To',
    id: 'main',
    layout: { column: 1 },
    order: 2,
    fields: [
      {
        order: 1,
        layout: { row: 1, width: thirdWidth },
        field: {
          name: 'projectClientStatusUpdateDefaultMailTo.toSiteContact',
          Component: (props) => (
            <CheckboxWithLabel {...props} label="Site Contact" />
          ),
        },
      },
      {
        order: 2,
        layout: { row: 1, width: thirdWidth },
        field: {
          name: 'projectClientStatusUpdateDefaultMailTo.toRequester',
          Component: (props) => (
            <CheckboxWithLabel {...props} label="Requester" />
          ),
        },
      },
      {
        order: 3,
        layout: { row: 1, width: thirdWidth },
        field: {
          name: 'projectClientStatusUpdateDefaultMailTo.toClientManager',
          Component: (props) => (
            <CheckboxWithLabel {...props} label="Client Manager" />
          ),
        },
      },
    ],
  },
];

function EditClientNotificationButton({
  clientId,
  serviceRequestConfirmationEmail,
  projectClientStatusUpdateDefaultMailTo,
}) {
  const { showSuccessNotification } = useNotificationState();
  const { openModalForm, closeModal } = useModalContext();
  const [updateClientNotifications] = useMutation(
    updateClientNotificationsMutation,
  );

  const onSubmit = async (formData) => {
    const input = prepareInputForMutation({ clientId, formData });
    await updateClientNotifications({ variables: { input } });
    showSuccessNotification('Client Notifications successfully updated');
    closeModal(updateClientNotificationsFormId);
  };

  const onClick = () =>
    openModalForm({
      id: updateClientNotificationsFormId,
      formId: updateClientNotificationsFormId,
      title: 'Edit Client Notifications',
      btnCaption: 'Save',
      content: (
        <EditClientNotificationForm
          id={updateClientNotificationsFormId}
          formId={updateClientNotificationsFormId}
          sections={formCreatorSections}
          initialValues={{
            serviceRequestConfirmationEmail,
            projectClientStatusUpdateDefaultMailTo,
          }}
          onSubmit={onSubmit}
        />
      ),
    });

  return <LinkButtonS onClick={onClick}>edit</LinkButtonS>;
}

EditClientNotificationButton.propTypes = {
  clientId: string,
  serviceRequestConfirmationEmail: shape({
    toSiteContact: bool.isRequired,
    toRequester: bool.isRequired,
    toClientManager: bool.isRequired,
  }),
  projectClientStatusUpdateDefaultMailTo: shape({
    toSiteContact: bool.isRequired,
    toRequester: bool.isRequired,
    toClientManager: bool.isRequired,
  }),
};

// getIdFromClient :: Client -> ID
const getIdFromClient = R.prop('_id');

const clientPropTypes = {
  _id: string.isRequired,
  configs: shape({
    notifications: shape({
      serviceRequestConfirmationEmail: shape({
        toSiteContact: bool,
        toRequester: bool,
        toClientManager: bool,
      }),
    }),
  }),
};

// setDefaultNotificationByPath :: [String] -> Object -> Object
const setDefaultNotificationByPath = (path) =>
  R.over(R.lensPath(path), R.defaultTo(false));

// getClientNotificationConfigs :: Client -> ClientNotificationConfigs
const getClientNotificationConfigs = R.compose(
  setDefaultNotificationByPath([
    'projectClientStatusUpdateDefaultMailTo',
    'toClientManager',
  ]),
  setDefaultNotificationByPath([
    'projectClientStatusUpdateDefaultMailTo',
    'toRequester',
  ]),
  setDefaultNotificationByPath([
    'projectClientStatusUpdateDefaultMailTo',
    'toSiteContact',
  ]),
  setDefaultNotificationByPath([
    'serviceRequestConfirmationEmail',
    'toClientManager',
  ]),
  setDefaultNotificationByPath([
    'serviceRequestConfirmationEmail',
    'toRequester',
  ]),
  setDefaultNotificationByPath([
    'serviceRequestConfirmationEmail',
    'toSiteContact',
  ]),
  removePropDeep('__typename'),
  R.pathOr({}, ['configs', 'notifications']),
);

function NotificationStatuses({ toSiteContact, toRequester, toClientManager }) {
  return (
    <>
      <StatusS text="Site Contact" color={getColor(toSiteContact)} />
      <StatusS text="Requester" color={getColor(toRequester)} />
      <StatusS text="Client Manager" color={getColor(toClientManager)} />
    </>
  );
}

NotificationStatuses.propTypes = {
  toRequester: bool.isRequired,
  toSiteContact: bool.isRequired,
  toClientManager: bool.isRequired,
};

export function ClientNotificationSubTab({ client }) {
  const hasEditPermission = useHasUserAccessWithPermission(
    UPDATE_CLIENT_PERMISSION,
  );

  const {
    serviceRequestConfirmationEmail,
    projectClientStatusUpdateDefaultMailTo,
  } = getClientNotificationConfigs(client);

  return (
    <ClientNotificationWrapper>
      <HeaderWrapper>
        <Header>Client Service Request Confirmation Email</Header>
        {hasEditPermission && (
          <EditClientNotificationButton
            clientId={getIdFromClient(client)}
            serviceRequestConfirmationEmail={serviceRequestConfirmationEmail}
            projectClientStatusUpdateDefaultMailTo={
              projectClientStatusUpdateDefaultMailTo
            }
          />
        )}
      </HeaderWrapper>
      <NotificationStatuses {...serviceRequestConfirmationEmail} />

      <Header>Project Client Status Update Default Mail To</Header>
      <NotificationStatuses {...projectClientStatusUpdateDefaultMailTo} />
    </ClientNotificationWrapper>
  );
}

ClientNotificationSubTab.propTypes = {
  client: shape(clientPropTypes),
};
