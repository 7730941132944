import { css } from 'styled-components';

export const moneyColumnStyles = (columnNum, width = 135) => css`
  th:nth-child(${columnNum}),
  td:nth-child(${columnNum}) {
    width: ${width}px;
    text-align: right;
    padding-right: 20px;
  }

  td:nth-child(${columnNum}),
  td:nth-child(${columnNum}) > * {
    font-family: 'Monda', sans-serif;
    font-size: 13px;
  }
`;

export const dateColumnStyles = (columnNum) => css`
  th:nth-child(${columnNum}),
  td:nth-child(${columnNum}) {
    width: 100px;
  }
`;

export const checkBoxColumn = (columnNum) => css`
  th:nth-child(${columnNum}),
  td:nth-child(${columnNum}) {
    width: 65px;
  }
`;

export const pipColumnStyles = (columnNum) => css`
  th:nth-child(${columnNum}),
  td:nth-child(${columnNum}) {
    width: 25px;
  }
`;

export const singleButtonColumnStyles = (columnNum) => css`
  th:nth-child(${columnNum}),
  td:nth-child(${columnNum}) {
    width: 90px;
    text-align: right;
    padding-right: 30px;
  }
`;
