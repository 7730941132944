import React, { useState } from 'react';
import styled from 'styled-components';
import { TableCardWithPaginationContainer } from '@poly/admin-ui/src/components/Table.js';
import { DefaultBodyWrapper } from '@poly/admin-book/src/Tabs/components.js';
import { TableWithToolBarWrapper } from '@poly/admin-book/src/Table/styles.js';

import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { PurchaseOrdersHeader } from './PurchaseOrdersHeader.js';
import { useClientPurchaseOrders } from './useClientPurchaseOrders.js';
import { PurchaseOrdersTable } from './PurchaseOrdersTable.js';

const EmptyActivityList = styled.div.attrs(() => ({
  children: 'List is empty',
}))`
  text-align: center;
  margin-top: 30px;
  width: 100%;
`;

const DefaultBodyWrapperS = styled(DefaultBodyWrapper)`
  height: calc(100% - 115px);
`;

export function PurchaseOrdersPage() {
  const [filter, setFilter] = useState(null);

  const { purchaseOrders, loading } = useClientPurchaseOrders(filter);

  return (
    <PageWithSearchHeader headerHeight="115px">
      <PurchaseOrdersHeader
        setClientFilter={setFilter}
        purchaseOrders={purchaseOrders}
        loading={loading}
      />
      <DefaultBodyWrapperS>
        <TableCardWithPaginationContainer>
          <TableWithToolBarWrapper>
            {purchaseOrders.length > 0 ? (
              <PurchaseOrdersTable purchaseOrders={purchaseOrders} />
            ) : (
              <EmptyActivityList />
            )}
          </TableWithToolBarWrapper>
        </TableCardWithPaginationContainer>
      </DefaultBodyWrapperS>
    </PageWithSearchHeader>
  );
}
