import { MoneyInput } from '../../../fields/MoneyInput.js';
import { isSubcontractor } from '../utils.js';
import { halfWidth } from '../common.js';

export const supplierRatesSection = (renderIfRates = isSubcontractor) => [
  {
    label: 'Rates',
    order: 7,
    layout: { column: 1 },
    renderIf: renderIfRates,
    fields: [
      {
        label: 'Hourly Rate',
        order: 1,
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'rates.hourly',
          Component: MoneyInput,
        },
      },
      {
        label: 'After Hourly Rate',
        order: 2,
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'rates.overTime',
          Component: MoneyInput,
        },
      },
      {
        label: 'Weekend Rate',
        order: 3,
        layout: { row: 2, width: halfWidth },
        field: {
          name: 'rates.weekend',
          Component: MoneyInput,
        },
      },
      {
        label: 'Trip Charge',
        order: 4,
        layout: { row: 2, width: halfWidth },
        field: {
          name: 'rates.trip',
          Component: MoneyInput,
        },
      },
    ],
  },
];
