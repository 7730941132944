import * as R from 'ramda';
import { Link } from '@poly/client-routing';
import { entities } from '@poly/admin-ui';

import { useSidebarLogicContext } from '../../../sidebars/SidebarLogicContext.js';

export const useGenerateHistoryRecordLink = (entity) => {
  const { ProjectLink } = useSidebarLogicContext();

  return R.cond([
    [
      R.anyPass([
        R.equals(entities.PROJECT),
        R.equals(entities.RECURRING_PROJECT),
      ]),
      R.always(ProjectLink),
    ],
    [R.T, R.always(Link)],
  ])(entity);
};
