import React from 'react';
import * as R from 'ramda';
import { shape } from 'prop-types';
import {
  commonSidebarFormFieldLayout,
  commonSidebarFormLayout,
  commonSidebarFormSectionLayout,
} from '@poly/admin-ui/src/sidebar/commonSidebarFormConstants.js';
import { usePristineSubscribe } from '@poly/client-routing/src/hooks.js';
import { useMutation, gql } from '@apollo/client';
import { useOutSidebarContext } from '@poly/client-utils/src/slideOutSidebar.js';
import { useNotificationState } from '@poly/admin-ui/src/hooks/useNotificationState.js';
import styled from 'styled-components';
import { FormCreator } from '@poly/admin-book/src/index.js';
import { ensureIsDate } from '@poly/utils/src/dates.js';
import { assocBy } from '@poly/utils/src/ramda.js';

import { editPurchaseOrderFormId } from '../constants.js';
import { getPurchaseOrderFormSections } from './purchaseOrderFormSections.js';
import { preparePurchaseOrderBeforeMutation } from '../utils.js';
import { useSidebarLogicContext } from '../../SidebarLogicContext.js';

// prepareEditPurchaseOrderInitialValues :: PurchaseOrder -> EditAdminPurchaseOrderInput
const prepareEditPurchaseOrderInitialValues = R.compose(
  R.mergeAll,
  R.juxt([
    R.pick([
      'poNumber',
      'displayName',
      'initialBalance',
      'status',
      'remarks',
      'lowBalancePercent',
      'type',
      'notificationContactsEmails',
    ]),
    R.applySpec({
      clientId: R.path(['client', '_id']),
      propertiesIds: R.compose(R.pluck('_id'), R.propOr([], 'properties')),
      authorizedBy: R.path(['authorizedBy', '_id']),
      notificationContactsEmails: R.compose(
        R.map(R.applySpec({ value: R.identity, label: R.identity })),
        R.propOr([], 'notificationContactsEmails'),
      ),
      startDate: R.compose(ensureIsDate, R.prop('startDate')),
      endDate: R.compose(ensureIsDate, R.prop('endDate')),
    }),
  ]),
);

export const UPDATE_ADMIN_PO_MUTATION = gql`
  mutation UPDATE_ADMIN_PO_MUTATION(
    $id: ID!
    $input: UpdateAdminPurchaseOrderInput!
  ) {
    updateAdminPurchaseOrder(id: $id, input: $input) {
      _id
    }
  }
`;

// preparePODataBeforeMutation :: FormData -> UpdateAdminPurchaseOrderInput
const preparePODataBeforeMutation = R.compose(
  assocBy('lowBalancePercent', R.propOr(null, 'lowBalancePercent')),
  R.dissoc('clientId'),
  preparePurchaseOrderBeforeMutation,
);

const FormCreatorS = styled(FormCreator)`
  min-height: calc(100vh - 190px);
`;

export function EditPurchaseOrderForm({ purchaseOrder }) {
  const pristineSubscribeProps = usePristineSubscribe({
    id: editPurchaseOrderFormId,
  });

  const { closeOutSidebar } = useOutSidebarContext();
  const { showSuccessNotification } = useNotificationState();

  const { openPOSidebar } = useSidebarLogicContext();

  const [createPurchaseOrderMutation] = useMutation(UPDATE_ADMIN_PO_MUTATION);

  const initialValues = prepareEditPurchaseOrderInitialValues(purchaseOrder);
  const onSubmit = async (formData) => {
    await createPurchaseOrderMutation({
      variables: {
        id: purchaseOrder._id,
        input: preparePODataBeforeMutation(formData),
      },
    });

    showSuccessNotification('Purchase Order was successfully edited');
    closeOutSidebar(editPurchaseOrderFormId);
    openPOSidebar(purchaseOrder?._id);
  };

  return (
    <FormCreatorS
      id={editPurchaseOrderFormId}
      initialValues={initialValues}
      layout={commonSidebarFormLayout}
      fieldLayout={commonSidebarFormFieldLayout}
      sectionLayout={commonSidebarFormSectionLayout}
      sections={getPurchaseOrderFormSections(true)}
      onSubmit={onSubmit}
      {...pristineSubscribeProps}
    />
  );
}

EditPurchaseOrderForm.propTypes = {
  purchaseOrder: shape({}),
};
