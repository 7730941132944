import * as R from 'ramda';
import { gql, useMutation } from '@apollo/client';
import { useRouterParams, useUpdateQueryParams } from '@poly/client-routing';
import { undefinedAsNullDeep } from '@poly/client-utils';

import { prepareCommonSupplierDataForMutation } from '../../../forms/supplierForm/form/prepareFormDataForMutation.js';
import { useNotificationState } from '../../../../hooks/useNotificationState.js';
import {
  omitSupplierSubcontractorAgreement,
  emptyStringAsNullForSupplierInput,
  omitRemitIfNeed,
  omitSupplierProtectedFieldsIfNoPermission,
  prepareSupplierFinancial,
} from '../../../forms/supplierForm/edit/formDataToMutation.js';
import { prepareRemittanceEmailsIfExists } from '../branchSupplierFormUtils.js';

const UPDATE_BRANCH_SUPPLIER_MUTATION = gql`
  mutation UPDATE_BRANCH_SUPPLIER_MUTATION(
    $id: ID!
    $input: UpdateBranchSupplierInput!
  ) {
    updateBranchSupplier(id: $id, input: $input) {
      supplier {
        _id
      }
    }
  }
`;

// mergeOrOmitW9File :: FormData -> FormData
const mergeOrOmitW9File = R.ifElse(
  R.compose(R.isNil, R.path(['tax', 'w9File'])),
  R.omit(['tax']),
  R.converge(R.mergeRight, [
    R.identity,
    R.applySpec({
      tax: {
        w9File: R.path(['tax', 'w9File']),
      },
    }),
  ]),
);

// prepareBranchSupplierToUpdate :: FormData -> FieldsAccess -> UpdateBranchSupplierInput
const prepareBranchSupplierToUpdate = R.curry(
  (formData, protectedFieldsAccess) =>
    R.compose(
      prepareRemittanceEmailsIfExists,
      omitSupplierSubcontractorAgreement,
      omitSupplierProtectedFieldsIfNoPermission(protectedFieldsAccess),
      prepareSupplierFinancial,
      omitRemitIfNeed,
      mergeOrOmitW9File,
      R.omit(['type']),
      R.when(R.prop('payByMaster'), R.omit(['bank', 'finance'])),
      R.when(R.prop('applyMasterRates'), R.omit(['rates'])),
      prepareCommonSupplierDataForMutation,
      undefinedAsNullDeep,
      emptyStringAsNullForSupplierInput,
    )(formData),
);

export const useUpdateBranchSupplierMutation = (protectedFieldsAccess) => {
  const { showSuccessNotification } = useNotificationState();
  const updateQueryParams = useUpdateQueryParams();
  const { supplierId } = useRouterParams(['supplierId']);
  const [updateBranchSupplier] = useMutation(UPDATE_BRANCH_SUPPLIER_MUTATION);

  return async (formData) => {
    await updateBranchSupplier({
      variables: {
        id: supplierId,
        input: prepareBranchSupplierToUpdate(formData, protectedFieldsAccess),
      },
    });
    showSuccessNotification('Branch Supplier Successfully Edited');
    return updateQueryParams({ isEdit: false });
  };
};
