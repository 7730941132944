import * as R from 'ramda';
import { endOfDay, startOfDay } from 'date-fns';
import { calculateTotal } from '@poly/utils';

// constructAndAdjustDate :: (Date -> Date, String) -> Object -> Object
const constructAndAdjustDate = (adjustDateFn, prop) =>
  R.over(
    R.lensProp(prop),
    R.when(
      R.complement(R.isNil),
      R.compose(adjustDateFn, R.constructN(1, Date)),
    ),
  );

// generateSalesTaxReportQueryByState :: Object -> SalesTaxReportInput
export const generateSalesTaxReportQueryByState = R.compose(
  R.reject(R.isNil),
  constructAndAdjustDate(endOfDay, 'endDate'),
  constructAndAdjustDate(startOfDay, 'startDate'),
  R.dissoc('year'),
  R.when(R.propSatisfies(R.isNil, 'state'), R.omit(['state'])),
  R.defaultTo({}),
);

// calculateSubTotal :: ClientInvoice -> Number
export const calculateSubTotal = R.converge(R.add, [
  R.compose(calculateTotal(R.prop('total')), R.prop('lines')),
  R.prop('markupAmount'),
]);
