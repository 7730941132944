import React from 'react';
import * as R from 'ramda';
import { useMutation, gql } from '@apollo/client';
import styled from 'styled-components';
import {
  useModalContext,
  useNotificationState,
  useOnSubmitSetStopSubmitting,
} from '@poly/admin-ui';
import { FormCreator, LinkButton } from '@poly/admin-book';
import { func, shape, string } from 'prop-types';
import { propertyHierarchyFormConfig } from './AddPropertyHierarchyForm.js';

const editPropertyHierarchyFormId = 'editPropertyHierarchyFormId';

const hierarchyPrototypes = shape({
  client: shape({ _id: string.isRequired }),
  name: string.isRequired,
  description: string,
});

const updatePropertyHierarchyMutation = gql`
  mutation updatePropertyHierarchyMutation(
    $id: ID!
    $input: UpdatePropertyHierarchyInput!
  ) {
    updatePropertyHierarchy(id: $id, input: $input) {
      _id
    }
  }
`;

const formatUpdateInput = R.pick(['name', 'description']);

function EditPropertyHierarchyForm({ hierarchyObj, onSuccess }) {
  const [updatePropertyHierarchy] = useMutation(
    updatePropertyHierarchyMutation,
  );
  const onSubmitHandler = (values) =>
    updatePropertyHierarchy({
      variables: { id: hierarchyObj._id, input: formatUpdateInput(values) },
    }).then(() => {
      onSuccess();
    });
  const { onSubmit } = useOnSubmitSetStopSubmitting(
    editPropertyHierarchyFormId,
    onSubmitHandler,
  );
  return (
    <FormCreator
      id={editPropertyHierarchyFormId}
      formId={editPropertyHierarchyFormId}
      sections={propertyHierarchyFormConfig}
      onSubmit={onSubmit}
      initialValues={{ ...hierarchyObj, isEdit: true }}
      layout={{ card: false }}
    />
  );
}

EditPropertyHierarchyForm.propTypes = {
  hierarchyObj: hierarchyPrototypes,
  onSuccess: func.isRequired,
};

const LinkButtonAligned = styled(LinkButton)`
  position: absolute;
  right: 20px;
`;

export function EditPropertyHierarchyButton({ hierarchyObj, onSuccess }) {
  const { openModalForm, closeModal } = useModalContext();
  const { showSuccessNotification } = useNotificationState();

  const onSuccessWithShowNotification = () => {
    onSuccess();
    showSuccessNotification('Hierarchy was updated successfully');
    closeModal(editPropertyHierarchyFormId);
  };
  const onClick = () => {
    openModalForm({
      title: 'Edit Hierarchy',
      btnCaption: 'Save',
      id: editPropertyHierarchyFormId,
      formId: editPropertyHierarchyFormId,
      content: (
        <EditPropertyHierarchyForm
          hierarchyObj={hierarchyObj}
          onSuccess={onSuccessWithShowNotification}
        />
      ),
    });
  };
  return (
    <LinkButtonAligned onClick={onClick}>Edit Hierarchy</LinkButtonAligned>
  );
}

EditPropertyHierarchyButton.propTypes = {
  hierarchyObj: hierarchyPrototypes,
  onSuccess: func.isRequired,
};
