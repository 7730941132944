import {
  object,
  arrayOf,
  instanceOf,
  number,
  shape,
  string,
  oneOfType,
  bool,
} from 'prop-types';

const datePropTypes = oneOfType([instanceOf(Date), string]).isRequired;

export const projectInfoPropTypes = shape({
  _id: string.isRequired,
  projectId: string.isRequired,
  clientId: string.isRequired,
  description: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  invoiceDescription: object.isRequired,
  invoiceTo: string,
  clientAddress: string.isRequired,
  clientReferenceNumber: string,
  terms: string,
  serviceAddress: string.isRequired,
  isTaxRateDisabled: bool.isRequired,
  state: string,
  jobCosts: arrayOf(
    shape({
      _id: string.isRequired,
      amount: number.isRequired,
      invoiceDate: datePropTypes,
      invoiceNumber: string.isRequired,
      supplierName: string,
      glCode: string,
    }),
  ),
  timeTracking: arrayOf(
    shape({
      _id: string.isRequired,
      date: datePropTypes,
      user: string.isRequired,
      hours: number.isRequired,
      rate: number.isRequired,
      description: string,
    }),
  ),
});

export const clientInvoicePropTypes = shape({
  invoiceDate: datePropTypes,
  description: oneOfType([string, object]),
  lines: arrayOf(
    shape({
      name: string.isRequired,
      rate: number,
      quantity: number,
    }),
  ),
});
