import React from 'react';
import { number, string, func } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { useNotificationState } from '@poly/admin-ui';
import { parseFieldToNumberIfExists } from '@poly/client-utils';
import { FormCreator } from '@poly/admin-book';

import { editPorterHoursFormSections } from './sections.js';
import { editWeeklyPorterHoursFormId } from './constants.js';

const CREDIT_PORTER_HOURS_MUTATION = gql`
  mutation CREDIT_PORTER_HOURS_MUTATION($input: CreditWeeklyPorterHoursInput!) {
    creditWeeklyPorterHours(input: $input) {
      _id
    }
  }
`;

export function EditPorterHoursForm({
  onCancel,
  projectId,
  ticketsTotalHours,
  creditedPorterHours,
}) {
  const { showSuccessNotification } = useNotificationState();
  const [creditWeeklyPorterHours] = useMutation(CREDIT_PORTER_HOURS_MUTATION);

  const onSubmit = async (formData) => {
    await creditWeeklyPorterHours({
      variables: {
        input: { projectId, ...parseFieldToNumberIfExists('hours')(formData) },
      },
    });
    showSuccessNotification('Hours were successfully credited');
    onCancel();
  };

  return (
    <FormCreator
      onSubmit={onSubmit}
      onCancel={onCancel}
      id={editWeeklyPorterHoursFormId}
      formId={editWeeklyPorterHoursFormId}
      layout={{ overflow: 'visible', card: false }}
      initialValues={{ hours: creditedPorterHours }}
      sections={editPorterHoursFormSections(ticketsTotalHours)}
    />
  );
}

EditPorterHoursForm.propTypes = {
  onCancel: func.isRequired,
  projectId: string.isRequired,
  creditedPorterHours: number.isRequired,
  ticketsTotalHours: number.isRequired,
};
