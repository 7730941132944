import * as R from 'ramda';
import { css } from 'styled-components';

import { getThemeColor } from '../utils.js';

const requiredInputStyle = css`
  box-shadow: inset 0px -2px 0 ${getThemeColor(['accent1Lighter1'])};
`;

// notProp :: String -> Object -> Bool
const notProp = R.complement(R.prop);

// inputHasError :: InputFieldProps -> Bool
//   InputFieldProps = {
//     hasError: Bool
//     error: String
//     value: Any
//     defaultValue: Any
//     required: Bool
//   }
export const inputHasError = R.anyPass([R.prop('hasError'), R.prop('error')]);

// requiredFieldIsEmpty :: InputFieldProps -> Bool
const requiredFieldIsEmpty = R.allPass([
  R.prop('required'),
  notProp('value'),
  notProp('defaultValue'),
  // prevent false positive required highlighting for 0
  R.complement(R.propSatisfies(R.is(Number), 'value')),
]);

// markAsRequired :: InputFieldProps -> CSS
export const markAsRequired = R.ifElse(
  R.both(requiredFieldIsEmpty, R.complement(inputHasError)),
  () => requiredInputStyle,
  () => '',
);
