import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { func, number, oneOfType, shape, string } from 'prop-types';
import {
  createGetFormFieldWidth,
  Input,
  getThemeColor,
} from '@poly/admin-book';
import { validateFilesFunc } from '@poly/client-utils';
import { HoursInput } from '@poly/admin-ui';

import { CheckboxWithLabel } from './components/CheckboxWithLabel.js';
import { AttachDocumentField } from '../../../../components/AttachDocumentField.js';
import { weeklyTicketValidators } from './weeklyServiceTicketFormValidators.js';
import { sumWeeklyServiceTicketHours } from '../../projectSidebarUtils.js';

// 3 columns, 5px between columns
const columnWidth = createGetFormFieldWidth(3, 5)(1);

export function WeeklyTicketPicker(props) {
  return (
    <AttachDocumentField {...props} label="Weekly Ticket" showLabel={false} />
  );
}

export function TotalHoursDisplayInput({ onChange, formData }) {
  return (
    <Input
      disabled
      name="TotalHoursDisplayInput"
      onChange={onChange}
      value={sumWeeklyServiceTicketHours(formData)}
    />
  );
}

TotalHoursDisplayInput.propTypes = {
  onChange: func.isRequired,
  formData: shape({
    hours: oneOfType([number, string]),
    extraHours: oneOfType([number, string]),
  }).isRequired,
};

export const CheckboxWithLabelS = styled(CheckboxWithLabel)`
  font-size: 12px;
  color: ${getThemeColor(['darkest'])};
`;

// notCompletedIsNotChecked :: FormData -> Boolean
export const notCompletedIsNotChecked = R.propSatisfies(
  R.complement(R.equals(true)),
  'isNotCompleted',
);

export const getWeeklyServiceTicketFormSections = (weeklyPorterHours) => [
  {
    id: 'main',
    layout: { column: 1, margin: '25px 0 25px 0' },
    order: 1,
    fields: [
      {
        order: 1,
        layout: { row: 1, padding: '0 0 20px 0' },
        field: {
          name: 'isNotCompleted',
          Component: (props) => (
            <CheckboxWithLabelS {...props} label="Not completed" />
          ),
        },
      },
      {
        order: 2,
        layout: { row: 2, width: columnWidth },
        label: 'Weekly Porter Hours',
        field: {
          name: 'hours',
          Component: HoursInput,
        },
        required: weeklyPorterHours !== 0,
        disabled: weeklyPorterHours === 0,
        validators: [
          ...(weeklyPorterHours === 0
            ? []
            : [
                [R.either(R.is(Number), R.identity), 'Hours is required field'],
              ]),
          [R.lte(R.__, weeklyPorterHours), 'Can`t exceeds Weekly Porter Hours'],
        ],
        renderIf: notCompletedIsNotChecked,
      },
      {
        order: 3,
        layout: { row: 2, width: columnWidth },
        label: 'Extra Porter Hours',
        field: {
          name: 'extraHours',
          Component: HoursInput,
        },
        required: true,
        validators: [
          [R.either(R.is(Number), R.identity), 'Hours is required field'],
        ],
        renderIf: notCompletedIsNotChecked,
      },
      {
        order: 4,
        layout: { row: 2, width: columnWidth },
        label: 'Total Porter Hours',
        field: {
          name: 'totalHours',
          withFormData: true,
          Component: TotalHoursDisplayInput,
        },
        renderIf: notCompletedIsNotChecked,
      },
      {
        order: 5,
        layout: { row: 3 },
        label: 'Attach Weekly Ticket',
        field: {
          name: 'attachment',
          Component: WeeklyTicketPicker,
        },
        required: true,
        validators: weeklyTicketValidators,
        validateFunction: validateFilesFunc,
        renderIf: notCompletedIsNotChecked,
      },
    ],
  },
];
