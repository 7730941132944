import * as R from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { string, number, shape, arrayOf } from 'prop-types';
import { FieldLayout, TimePicker, getThemeColor } from '@poly/admin-book';
import { propEqLegacy } from '@poly/utils';

import { SupplierFormCheckbox } from '../components/Checkbox.js';

const LinesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const DayLabel = styled.div`
  display: flex;
  align-self: center;
  margin-top: -16px;
  width: calc(30% - 20px);
  font-size: 12px;
  color: ${getThemeColor(['darkest'])};
`;

function OfficeHourCheckbox(props) {
  return <SupplierFormCheckbox {...props} label="" />;
}

function OfficeHoursFormLine({ name, index, fields: { value } }) {
  const { day, isActive, startTime } = useMemo(
    () => value[index],
    [value, index],
  );

  return (
    <LinesWrapper>
      <Line>
        <DayLabel>{day}</DayLabel>
        <FieldLayout
          layout={{ width: 'calc(10% - 20px)' }}
          field={{
            name: `${name}.isActive`,
            Component: OfficeHourCheckbox,
          }}
        />
        <FieldLayout
          required
          disabled={!isActive}
          layout={{ width: 'calc(30% - 20px)' }}
          field={{
            Component: TimePicker,
            name: `${name}.startTime`,
          }}
        />
        <FieldLayout
          required
          disabled={!isActive}
          layout={{ width: 'calc(30% - 20px)' }}
          field={{
            withFormData: true,
            Component: TimePicker,
            name: `${name}.endTime`,
          }}
          values={{ timeIn: startTime }}
        />
      </Line>
    </LinesWrapper>
  );
}

OfficeHoursFormLine.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  fields: shape({
    value: arrayOf(shape({ day: string.isRequired })).isRequired,
  }),
};

export const officeHoursSection = [
  {
    label: 'Office Hours',
    order: 5,
    layout: { column: 1 },
    fields: [
      {
        order: 1,
        leaveValues: true,
        label: '24/7 Service',
        layout: { row: 1, width: '100%' },
        field: {
          name: 'service_24_7',
          Component: SupplierFormCheckbox,
        },
      },
      {
        order: 2,
        layout: { row: 2, width: '100%', padding: '10px 0 0 0' },
        field: {
          isArrayField: true,
          name: 'officeHours',
          Component: OfficeHoursFormLine,
        },
        renderIf: R.complement(propEqLegacy('service_24_7', true)),
      },
    ],
  },
];
