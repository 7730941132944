import {
  number,
  shape,
  string,
  instanceOf,
  arrayOf,
  oneOfType,
} from 'prop-types';

const accountPropTypes = shape({
  _id: string.isRequired,
  name: string.isRequired,
});

export const accountTransactionPropType = shape({
  journal: shape({
    date: string.isRequired,
    type: string.isRequired,
    description: string,
    supplier: shape({
      company: shape({
        name: string.isRequired,
      }).isRequired,
    }),
  }).isRequired,
  split_accounts: arrayOf(accountPropTypes),
  balance: number.isRequired,
  amount: number.isRequired,
  invoiceNumber: string,
});

export const accountTransactionsResultPropType = shape({
  forward_balance: number.isRequired,
  credits: number.isRequired,
  debits: number.isRequired,
  hits: arrayOf(accountTransactionPropType).isRequired,
});

export const accountTransactionsFiltersPropTypes = shape({
  date: shape({
    startDate: oneOfType([string, instanceOf(Date)]),
    endDate: oneOfType([string, instanceOf(Date)]),
  }).isRequired,
  accountCode: string,
  accountingMethod: string,
});
