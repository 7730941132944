import * as R from 'ramda';
import React, { useMemo, useCallback } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { string, arrayOf, number, func, shape } from 'prop-types';
import { useGetJSONDataFromClipboard } from '@poly/admin-ui';
import { EQUALS_AIT_OPERATOR } from '@poly/security';
import { debounce, propEqLegacy } from '@poly/utils';
import {
  useNotificationContext,
  FieldLayout,
  LinkButton,
  Select,
} from '@poly/admin-book';

import {
  ButtonsWrapper,
  DeleteLinkButtonS,
  UserGroupFlexLineS,
  UserGroupPermissionSectionS,
} from './userGroupFormComponents.js';
import { UserGroupScopeLine } from './UserGroupScopeLine.js';
import { UserGroupPermissionEntitySelect } from './UserGroupPermissionEntitySelect.js';

// getActionOptionBySchema :: String -> UserGroupPermissionSchemaQueryResult -> [Option]
const getActionOptionBySchema = (entity) =>
  R.compose(
    R.map(R.applySpec({ value: R.prop('permission'), label: R.prop('name') })),
    R.defaultTo([]),
    R.prop('actions'),
    R.find(propEqLegacy('_id', entity)),
    R.defaultTo([]),
    R.prop('getUserGroupPermissionSchema'),
  );

// getAllowedScopesBySchema :: (String, [ScopeFormLine]) ->
// UserGroupPermissionSchemaQueryResult -> [Option]
const getAllowedScopesBySchema = (entity, selectedScopes) =>
  R.compose(
    R.without(
      R.compose(R.map(R.prop('scope')), R.defaultTo([]))(selectedScopes),
    ),
    R.defaultTo([]),
    R.prop('types'),
    R.find(propEqLegacy('_id', entity)),
    R.defaultTo([]),
    R.prop('getUserGroupPermissionSchema'),
  );

export function UserGroupPermissionSection({
  name,
  value,
  index,
  schema,
  changeFieldValue,
  onDeletePermission,
  onCopySinglePermission,
}) {
  const getDataFromClipboard = useGetJSONDataFromClipboard();
  const { showWarningNotification } = useNotificationContext();

  const selectedEntity = useMemo(() => value[index].entity, [value]);

  const actionOptions = useMemo(
    () =>
      selectedEntity ? getActionOptionBySchema(selectedEntity)(schema) : [],
    [selectedEntity, schema],
  );

  const currentSectionScopes = useMemo(
    () => value[index].scopes || [],
    [value],
  );

  const allowedScopes = useMemo(
    () =>
      selectedEntity
        ? getAllowedScopesBySchema(selectedEntity, currentSectionScopes)(schema)
        : [],
    [selectedEntity, currentSectionScopes, schema],
  );

  const isScopesAllowed = useMemo(
    () => !!selectedEntity && R.complement(R.isEmpty)(allowedScopes),
    [selectedEntity, allowedScopes],
  );

  const addNewScopeDebounced = useCallback(
    debounce(100)((fields) => {
      fields.push({ operator: EQUALS_AIT_OPERATOR });
    }),
    [],
  );

  return (
    <UserGroupPermissionSectionS>
      <FieldArray name={`${name}.scopes`}>
        {({ fields }) => {
          const onAddScope = (e) => {
            if (isScopesAllowed) {
              e.preventDefault();
              addNewScopeDebounced(fields);
            }
          };

          const onPasteScopes = async (e) => {
            e.preventDefault();
            const { scopes } = await getDataFromClipboard();

            if (scopes) {
              const preparedScopes = R.difference(scopes, currentSectionScopes);

              if (R.isEmpty(preparedScopes)) {
                showWarningNotification(
                  'This scope already exists for this permission',
                );
              } else {
                preparedScopes.forEach((scope) => {
                  fields.push(scope);
                });
              }
            }
          };

          const afterEntitySelect = (entityToSelect) => {
            changeFieldValue(`${name}.action`, null);
            changeFieldValue(`${name}.scopes`, []);

            const scopesForSelected = entityToSelect
              ? getAllowedScopesBySchema(entityToSelect, [])(schema)
              : [];

            if (R.complement(R.isEmpty)(scopesForSelected)) {
              addNewScopeDebounced(fields);
            }
          };

          return (
            <>
              <UserGroupFlexLineS>
                <FieldLayout
                  layout={{ width: 'calc(37% - 5px)' }}
                  field={{
                    name: `${name}.entity`,
                    Component: UserGroupPermissionEntitySelect,
                    additionalProps: {
                      schema,
                      afterEntitySelect,
                      placeholder: 'Entity',
                    },
                  }}
                />
                <FieldLayout
                  required={!!selectedEntity}
                  layout={{ width: 'calc(37% - 5px)' }}
                  field={{
                    Component: Select,
                    name: `${name}.action`,
                    additionalProps: {
                      placeholder: 'Actions',
                      options: actionOptions,
                    },
                  }}
                />
                <ButtonsWrapper>
                  <LinkButton disabled={!isScopesAllowed} onClick={onAddScope}>
                    Add Scope
                  </LinkButton>
                  <LinkButton onClick={onCopySinglePermission}>Copy</LinkButton>
                  <LinkButton onClick={onPasteScopes}>Paste</LinkButton>
                  <DeleteLinkButtonS onClick={onDeletePermission}>
                    Delete
                  </DeleteLinkButtonS>
                </ButtonsWrapper>
              </UserGroupFlexLineS>
              {fields.map((fieldName, fieldIndex) => (
                <UserGroupScopeLine
                  id={fieldName}
                  key={fieldName}
                  fields={fields}
                  name={fieldName}
                  index={fieldIndex}
                  allowedScopes={allowedScopes}
                />
              ))}
            </>
          );
        }}
      </FieldArray>
    </UserGroupPermissionSectionS>
  );
}

UserGroupPermissionSection.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  changeFieldValue: func.isRequired,
  onDeletePermission: func.isRequired,
  onCopySinglePermission: func.isRequired,
  value: arrayOf(shape({ entity: string })).isRequired,
  schema: shape({
    getUserGroupPermissionSchema: arrayOf(shape({ _id: string.isRequired })),
  }),
};
