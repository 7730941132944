import { Editor } from '@poly/admin-book';

export const getRemarksSection = (column) => [
  {
    label: 'Remarks',
    order: 2,
    layout: { column },
    fields: [
      {
        order: 1,
        layout: { width: '100%' },
        field: {
          name: 'remarks',
          Component: Editor,
        },
      },
    ],
  },
];
