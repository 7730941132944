import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import React, { useContext } from 'react';
import { bool, func, shape } from 'prop-types';
import { checkIfScrolledToBottom } from '@poly/client-utils';
import { Loader } from '@poly/admin-book';

import {
  WhiteBodyWrapper,
  PayInvoicesTableCard,
  PayInvoicesFormContainer,
} from './PaySuppliersComponents.js';
import { PaySupplierInvoicesTable } from './PaySupplierInvoicesTable.js';
import { PageWithSearchHeaderContext } from '../../components/PageWithSearchHeader.js';
import { PaySupplierInvoicesBottomForm } from './PaySupplierInvoicesBottomForm.js';
import { setGlobalTrigger } from '../../redux/globalTriger.js';

export function PaySupplierInvoicesForm({
  loading,
  virtualized,
  tableSortProps,
}) {
  const dispatch = useDispatch();
  const { contentHeight } = useContext(PageWithSearchHeaderContext);

  const onScroll = R.compose(
    (isScrolled) => dispatch(setGlobalTrigger(isScrolled)),
    checkIfScrolledToBottom,
  );

  return (
    <WhiteBodyWrapper minHeight={contentHeight}>
      <PayInvoicesFormContainer>
        <PayInvoicesTableCard onScroll={onScroll}>
          {loading ? (
            <Loader />
          ) : (
            <PaySupplierInvoicesTable
              {...tableSortProps}
              virtualized={virtualized}
              footerHeight={185}
              TableFooter={PaySupplierInvoicesBottomForm}
              footerProps={{ loading }}
            />
          )}
        </PayInvoicesTableCard>
      </PayInvoicesFormContainer>
    </WhiteBodyWrapper>
  );
}

PaySupplierInvoicesForm.propTypes = {
  loading: bool,
  virtualized: bool,
  tableSortProps: shape({
    onHeaderCellClick: func.isRequired,
    setSorting: func.isRequired,
  }),
};
