import React from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';
import { thirdWidth } from '@poly/admin-ui/src/modules/forms/common.js';

const RowTitle = styled.div`
  font-family: TTNormsMedium, FFMarkWebProNarrowBook, sans-serif;
  font-size: 15px;
  color: #697788;
  display: inline-block;
  width: ${thirdWidth};
  vertical-align: top;

  @media (max-width: 450px) {
    font-size: 3vw;
  }
`;

export function SupplierAttachInvoiceRowTitle({ value }) {
  return <RowTitle>{value}</RowTitle>;
}

SupplierAttachInvoiceRowTitle.propTypes = {
  value: string,
};
