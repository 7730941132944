import React from 'react';
import * as R from 'ramda';
import { arrayOf, shape, string } from 'prop-types';
import styled, { css } from 'styled-components';
import { getThemeColor, getThemeProp, MenuPopUp } from '@poly/admin-book';

const activeStyles = css`
  :after {
    display: flex;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: ${getThemeColor(['accent'])};
    opacity: 0.4;
  }
`;

const AddButtonWrapper = styled.div`
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
  background-color: ${getThemeColor(['accent'])};
  margin: 0 24px 0 12px;
  border-radius: 50%;
  transition: all ${getThemeProp(['defaultTransition'])};
  cursor: pointer;
  z-index: 1;

  ${({ isOpen }) => isOpen && activeStyles};

  :hover {
    ${activeStyles};
  }
`;

const AddButtonIcon = styled.div`
  :before,
  :after {
    display: flex;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${getThemeColor(['white'])};
    z-index: 1;
  }

  :before {
    width: 8px;
    height: 1px;
  }

  :after {
    width: 1px;
    height: 8px;
  }
`;

function AddButton() {
  return (
    <AddButtonWrapper data-testid="global-add-btn">
      <AddButtonIcon />
    </AddButtonWrapper>
  );
}

// getButtonPermissions :: [MenuItem] -> [String]
const getButtonPermissions = R.compose(R.flatten, R.map(R.prop('permissions')));

export function GlobalAddBtn(props) {
  const { subItems } = props;
  const btnProps = {
    isSub: true,
    Trigger: AddButton,
    permissions: getButtonPermissions(subItems),
    ...props,
  };
  return <MenuPopUp {...btnProps} />;
}

GlobalAddBtn.propTypes = {
  subItems: arrayOf(
    shape({
      title: string,
      route: string,
      permissions: arrayOf(string),
    }),
  ),
};
GlobalAddBtn.defaultProps = { subItems: [] };
GlobalAddBtn.displayName = 'GlobalAddButton';
