import React from 'react';
import styled from 'styled-components';
import {
  PageGeneralTabsWrapper,
  PageHeaderContainer,
  HeaderLinksWrapper,
  TabsWrapper,
  MainHeader,
} from '@poly/admin-book';

import { projectsQueries } from '../modules/tabs/common.js';
import { projectUIStatuses } from '../modules/core/constants/projects.js';
import { ProjectsPageProjectsTab } from '../modules/tabs/projectsTab/ProjectsTab.js';
import { ToolBarBtnDivider } from '../modules/core/tableToolbar/tableToolBarStyles.js';
import { RecalledProjectsPrintPDFBtn } from '../modules/tables/projectsTable/ProjectsTableToolBar.js';
import { RecalledProjectTableExportToXLSBtn } from '../modules/core/tableToolbar/TableExportToXLSBtn.js';

const TabsWrapperC = styled(TabsWrapper)`
  margin-top: -10px;
`.withComponent('div');

export function RecalledProjectsPage() {
  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Recalled Projects</MainHeader>
        <HeaderLinksWrapper>
          <RecalledProjectsPrintPDFBtn
            query={projectsQueries[projectUIStatuses.RECALLED]}
          />
          <ToolBarBtnDivider />
          <RecalledProjectTableExportToXLSBtn />
        </HeaderLinksWrapper>
      </PageHeaderContainer>
      <PageGeneralTabsWrapper>
        <TabsWrapperC>
          <ProjectsPageProjectsTab
            query={projectsQueries[projectUIStatuses.RECALLED]}
            additionalProps={{ fetchPolicy: 'network-only' }}
          />
        </TabsWrapperC>
      </PageGeneralTabsWrapper>
    </>
  );
}
