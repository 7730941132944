import * as R from 'ramda';
import {
  useHasUserAccessWithPermission,
  useSubscriptionByChanges,
} from '@poly/client-utils';
import { READ_UPDATE_PERMISSION } from '@poly/security';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATES_SEARCH_CHANGED } from './subscriptions.js';
import { FILES_SEARCH_CHANGED } from '../files/subscriptions.js';
import { ADD_UPDATE_MUTATION } from './mutations.js';

// extractParams :: Object -> Object
const extractParams = R.compose(
  R.assocPath(['variables', 'searchInput', 'documentId'], R.__, {}),
  R.path(['entity', '_id']),
);

export const useEntityUpdatesByQuery = ({
  query,
  withAudit,
  entity,
  maxItemsCount = 10000,
  skipQuery = false,
}) => {
  const skip = skipQuery || !entity?._id;

  const { data, refetch, loading } = useQuery(query, {
    variables: {
      id: entity?._id,
      documentUpdatesInput: {
        includeAudit: withAudit,
        sort: { createdAt: -1 },
        from: 0,
        size: maxItemsCount,
      },
    },
    skip,
    fetchPolicy: 'network-only',
  });

  useSubscriptionByChanges({
    gqlQueryChanged: UPDATES_SEARCH_CHANGED,
    refetch,
    result: data,
    extractQueryParamsFromProps: R.always({ variables: { searchInput: {} } }),
    debounceTime: 0,
    skipQuery: skip,
  });

  useSubscriptionByChanges({
    gqlQueryChanged: FILES_SEARCH_CHANGED,
    refetch,
    result: { ...data, entity },
    extractQueryParamsFromProps: extractParams,
    debounceTime: 0,
    skipQuery: skip,
  });

  return {
    data,
    loading,
  };
};

export const useEntityUpdates = ({ query, entity, maxItemsCount = 10000 }) => {
  const ifHasPermission = useHasUserAccessWithPermission(
    READ_UPDATE_PERMISSION,
  );

  useEntityUpdatesByQuery({
    query,
    withAudit: true,
    entity,
    maxItemsCount,
    skipQuery: !ifHasPermission,
  });

  useEntityUpdatesByQuery({
    query,
    withAudit: false,
    entity,
    maxItemsCount,
    skipQuery: !ifHasPermission,
  });
};

export const useAddNewUpdate = () => {
  const [mutate] = useMutation(ADD_UPDATE_MUTATION, {
    alias: 'useAddNewUpdate',
  });

  return {
    addNewUpdate: mutate,
  };
};
