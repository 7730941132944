import * as R from 'ramda';
import { nullToUndefinedDeep, propEqLegacy } from '@poly/utils';
import { BooleanTextWithDot as BooleanTextWithDotImp } from '@poly/admin-book';
import { supplierTaxClassesUI, getActiveOfficeHours } from '@poly/admin-ui';
import { pathOrNothingUI } from '@poly/client-utils';
import {
  supplierEmailsTypes,
  supplierPhoneTypes,
  supplierFinTermsUI,
  NOTHING_UI_STRING,
  collectionNames,
} from '@poly/constants';

import { getPhoneByType } from '../../core/utils/suppliers.js';

export const BooleanTextWithDot = BooleanTextWithDotImp;

const runIfExistOrDash = R.curry((func, path) =>
  R.pipe(R.path(path), R.ifElse(R.isNil, R.always(NOTHING_UI_STRING), func)),
);

const getEmailByType = (emails, type) =>
  R.pipe(
    R.find(propEqLegacy('type', type)),
    pathOrNothingUI(['email']),
  )(emails);

// getMultiEmailsByType :: String -> SupplierEmail -> [String]
const getMultiEmailsByType = (type) =>
  R.compose(R.pluck('email'), R.filter(propEqLegacy('type', type)));

// getOpeningInvoicesTotal :: { searchInvoices: { hits: [Invoice] } } -> Float
const getOpeningInvoicesTotal = R.compose(
  R.sum,
  R.map(R.prop('balance')),
  R.pathOr([], ['searchInvoices', 'hits']),
);

// getSupplierCompanyData :: {Supplier} => {InputData}
const getSupplierCompanyData = R.compose(
  R.pick(['emails', 'phones', 'address', 'addressTwo']),
  R.propOr({}, 'company'),
);

const { GENERAL, SERVICE, ACCOUNT, SECONDARY_SERVICE } = supplierEmailsTypes;

export const prepareSupplierForDisplaying = R.pipe(
  R.evolve({ supplier: nullToUndefinedDeep }),
  ({ supplier }) => {
    const {
      emails = [],
      phones = [],
      address,
      addressTwo,
    } = getSupplierCompanyData(supplier);

    return {
      _id: supplier._id,
      type: supplier.type,
      generalInfo: {
        name: pathOrNothingUI(['company', 'name'], supplier),
        dba: R.path(['company', 'dba'], supplier),
        masterSupplier: R.prop('masterSupplier', supplier),
        fax: R.path(['company', 'fax'], supplier),
        phone: getPhoneByType(supplierPhoneTypes.GENERAL, phones),
        mobile: getPhoneByType(supplierPhoneTypes.MOBILE, phones),
        afterHoursPhone: getPhoneByType(supplierPhoneTypes.AFTER_HOURS, phones),
        address,
        addressTwo,
        emails: {
          [GENERAL]: getEmailByType(emails, GENERAL),
          [SERVICE]: getEmailByType(emails, SERVICE),
          [ACCOUNT]: getMultiEmailsByType(ACCOUNT)(emails),
          [SECONDARY_SERVICE]: getMultiEmailsByType(SECONDARY_SERVICE)(emails),
        },
        website: pathOrNothingUI(['company', 'website'], supplier),
        service_24_7: supplier.service_24_7,
        officeHours: getActiveOfficeHours(supplier),
        services: R.path(['company', 'services'], supplier),
        foodBuy: R.propOr({}, 'foodBuy', supplier),
        isVeteran: supplier?.isVeteran,
      },

      remarks: supplier.remarks || '',

      financialInfo: {
        terms: runIfExistOrDash(R.prop(R.__, supplierFinTermsUI), [
          'finance',
          'terms',
        ])(supplier),
        acceptsCreditCard: R.pathOr(false, ['finance', 'acceptsCreditCard'])(
          supplier,
        ),
        exemptSupplierSalesTax: R.pathOr(false, [
          'tax',
          'exemptSupplierSalesTax',
        ])(supplier),
        is1099: R.pathOr(false, ['tax', 'is1099'])(supplier),
        exempt: R.path(['tax', 'exempt'])(supplier),
        w9FileName: pathOrNothingUI(['tax', 'w9File', 'fileName'], supplier),
        w9FileUrl: R.path(['tax', 'w9File', 'url'], supplier),
        taxId: pathOrNothingUI(['tax', 'id'], supplier),
        socialSecurityNumber: pathOrNothingUI(
          ['tax', 'socialSecurityNumber'],
          supplier,
        ),
        taxAddress1: R.pathOr({}, ['tax', 'address'], supplier),
        taxAddress2: pathOrNothingUI(['tax', 'addressTwo'], supplier),
        taxClass: runIfExistOrDash(R.prop(R.__, supplierTaxClassesUI), [
          'tax',
          'classification',
        ])(supplier),
        taxName: pathOrNothingUI(['tax', 'name'], supplier),
        cardNumber: R.prop('cardNumber', supplier),
        openingInvoicesTotal: getOpeningInvoicesTotal(supplier),
      },

      remitInfo: {
        name: pathOrNothingUI(['remit', 'name'], supplier),
        address: R.pathOr({}, ['remit', 'address'], supplier),
        addressTwo: R.path(['remit', 'addressTwo'], supplier),
      },

      bankACH: {
        routingNumber: pathOrNothingUI(['bank', 'routingNumber'], supplier),
        accountingNumber: pathOrNothingUI(['bank', 'accountNumber'], supplier),
        remittanceEmails: R.pathOr([], ['bank', 'remittanceEmails'], supplier),
        payByACH: R.pathOr(false, ['bank', 'payByACH'])(supplier),
      },

      documentsInfo: {
        ...(supplier.documents || {}),
        collection: collectionNames.suppliers,
        supplierId: supplier?._id,
        isBranchSupplier: !!supplier?.masterSupplier?._id,
      },
      ratesInfo: {
        ...(supplier.rates || {}),
      },
      mwbe: R.compose(R.defaultTo({}), R.path(['mwbe']))(supplier),
    };
  },
);
