import styled from 'styled-components';
import { getThemeColor } from '@poly/admin-book';

export const CustomSidebarLayout = styled.div`
  height: calc(100vh - 60px);
  padding: 10px 0 0 0;
  position: fixed;
  top: 60px;
  background-color: ${getThemeColor(['white'])};
  box-shadow: -3px 0 10px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.3s;
  transform: translateX(${({ open }) => (!open ? '100%' : 0)});
  z-index: 1000;
  overflow-y: auto;
  width: ${({ width }) => (width ? `${width}px` : 'fit-content')};
  right: 0;
`;
