export const ProjectType = {
  WORK_ORDER: 'tm',
  REPORT_ONLY: 'reportOnly',
  HOUSEKEEPING: 'housekeepingProjectType',
  PREVENTIVE_MAINTENANCE: 'preventiveMaintenance',
  PASS_THROUGH: 'passThrough',
  FEE: 'feeProjectType',
  BID: 'bidProjectType',
  LISTING: 'listing',
};

export const ProjectTypeToNameMap = {
  [ProjectType.WORK_ORDER]: 'T&M',
  [ProjectType.REPORT_ONLY]: 'Report Only',
  [ProjectType.PASS_THROUGH]: 'Pass Through',
  [ProjectType.FEE]: 'Fee',
  [ProjectType.BID]: 'Bid',
  [ProjectType.HOUSEKEEPING]: 'Housekeeping Contract',
  [ProjectType.LISTING]: 'Listing',
};

export const WorkOrderStatus = {
  ACTIVE: 'active',
  PENDING: 'pending',

  // used in site
  IN_PROCESS: 'inProcess',
  ON_HOLD_PARTS: 'onHoldParts',
  ON_HOLD_PROJECT: 'onHoldProject',
  ON_HOLD_CLIENT: 'onHoldClient',
  REJECTED: 'rejected',

  COMPLETED: 'completed',
  BLOCKED: 'blocked',
};

export const AccountingStatus = {
  PENDING: 'pending',
  READY_TO_INVOICE: 'ready_to_invoice',
  INVOICED: 'invoiced',
  PAID: 'paid',
  REPORT_ONLY: 'report_only',
  REPORT_ONLY_CLOSED: 'report_only_closed',
  NON_BILLABLE: 'non_billable',
  NON_BILLABLE_CLOSED: 'non_billable_closed',
  WRITTEN_OFF: 'written_off',
};

export const PROJECT_QUEUE_STATUS_LABEL = 'Queue';

export const onHoldWorkOrderStatuses = [
  WorkOrderStatus.ON_HOLD_PARTS,
  WorkOrderStatus.ON_HOLD_PROJECT,
  WorkOrderStatus.ON_HOLD_CLIENT,
];

export const FeeTypes = {
  CUSTOM_FEE: 'customFeeType',
  MANAGEMENT_FEE: 'managementFeeType',
  TRANSITION_FEE: 'transitionFeeType',
  LISTING_FEE: 'listingFeeType',
};

export const FeeTypesUI = {
  [FeeTypes.CUSTOM_FEE]: 'Custom Fee',
  [FeeTypes.MANAGEMENT_FEE]: 'Management Fee',
  [FeeTypes.TRANSITION_FEE]: 'Transition Fee',
  [FeeTypes.LISTING_FEE]: 'Listing Fee',
};

export const NonBillableReasons = [
  'Completed Without Supplier',
  'Completed Self Repair',
  'Completed Fulfilled Client Request',
  'Completed Requires Supplier Payment',
];

export const ProjectSpendTypes = {
  REACTIVE_REPAIR: 'reactiveRepair',
  PREVENTIVE_REPAIR: 'preventativeRepair',
  RECURRING_SERVICE: 'recurringService',
  CAP_EX: 'capEx',
};

export const ProjectSpendTypesUI = {
  [ProjectSpendTypes.REACTIVE_REPAIR]: 'Reactive repair',
  [ProjectSpendTypes.PREVENTIVE_REPAIR]: 'Preventative repair',
  [ProjectSpendTypes.RECURRING_SERVICE]: 'Recurring service',
  [ProjectSpendTypes.CAP_EX]: 'CapEx.',
};
