import * as R from 'ramda';
import React, { useEffect } from 'react';
import { startOfDay, endOfDay } from 'date-fns';
import { arrayOf, bool, func, string } from 'prop-types';
import { ALL, UserGroupMultiSelect } from '@poly/admin-ui';
import { Select, DatePicker } from '@poly/admin-book';
import { ensureIsDate, propEqLegacy } from '@poly/utils';

import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { FiltersContainer } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { FlexCenterAlign } from '../../components/FlexContainer.js';
import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import {
  StaffReportDatePresets,
  getStaffReportEndDateByPreset,
  getStaffReportStartDateByPreset,
} from './dateRangeUtils.js';

const datePresetOptions = [
  { value: StaffReportDatePresets.TODAY, label: 'Today' },
  { value: StaffReportDatePresets.THIS_WEEK, label: 'This Week' },
  { value: StaffReportDatePresets.THIS_MONTH, label: 'This Month' },
  { value: StaffReportDatePresets.YEAR_TO_DATE, label: 'Year To Date' },
];

// staffReportFilters :: StaffReportFilters
const staffReportFilters = {
  userGroupIds: 'userGroupIds',
  datePreset: 'datePreset',
  startDate: 'startDate',
  endDate: 'endDate',
};

const getStaffReportHeaderConfig = (defaultUserGroupIds) => [
  { name: staffReportFilters.userGroupIds, defaultValue: defaultUserGroupIds },
  {
    name: staffReportFilters.datePreset,
    defaultValue: StaffReportDatePresets.TODAY,
    subscribers: [
      [staffReportFilters.startDate, getStaffReportStartDateByPreset],
      [staffReportFilters.endDate, getStaffReportEndDateByPreset],
    ],
  },
  {
    name: staffReportFilters.startDate,
    defaultValue: getStaffReportStartDateByPreset(StaffReportDatePresets.TODAY),
  },
  {
    name: staffReportFilters.endDate,
    defaultValue: getStaffReportEndDateByPreset(StaffReportDatePresets.TODAY),
  },
];

// constructAndAdjustDate :: (Date -> Date, String) -> Object -> Object
const constructAndAdjustDate = (adjustDateFn, prop) =>
  R.over(
    R.lensProp(prop),
    R.when(R.is(String), R.compose(adjustDateFn, R.constructN(1, Date))),
  );

// generateStaffReportQuery :: StaffReportFilters -> StaffReportFilters
const generateStaffReportQuery = R.compose(
  R.omit([staffReportFilters.datePreset]),
  constructAndAdjustDate(
    R.compose(startOfDay, ensureIsDate),
    staffReportFilters.startDate,
  ),
  constructAndAdjustDate(
    R.compose(endOfDay, ensureIsDate),
    staffReportFilters.endDate,
  ),
  R.when(propEqLegacy('userGroupIds', ALL), R.dissoc('userGroupIds')),
  R.defaultTo({}),
);

export function StaffReportHeader({
  loading,
  setQuery,
  userGroupsLoading,
  defaultUserGroupIds,
}) {
  const { searchFilters, handlers, onReset } = useSearchFilters(
    getStaffReportHeaderConfig(defaultUserGroupIds),
  );

  const onSearch = () => {
    setQuery(generateStaffReportQuery(searchFilters));
  };

  const onResetSearch = () => {
    onReset();
    setQuery(null);
  };

  useEffect(() => {
    if (!userGroupsLoading) {
      onSearch();
    }
  }, [userGroupsLoading]);

  const presetSelectProps = {
    name: 'datePreset',
    value: searchFilters.datePreset,
    onChange: handlers.datePreset,
    options: datePresetOptions,
    width: '100%',
    required: true,
  };

  const userGroupsMultiSelectProps = {
    name: 'userGroupIds',
    value: searchFilters.userGroupIds,
    onChange: handlers.userGroupIds,
    width: '100%',
    maxHeight: '55px',
  };

  return (
    <SearchPageHeaderContainer title="Staff Activity Report">
      <FiltersContainer>
        <FlexSpaceBetween>
          <SearchHeaderColumn
            title="Preset"
            titleWidth="100px"
            filterWidth="260px"
          >
            <Select {...presetSelectProps} />
          </SearchHeaderColumn>
          <SearchHeaderColumn
            title="Date From"
            titleWidth="90px"
            filterWidth="150px"
          >
            <DatePicker
              value={searchFilters.startDate}
              onChange={handlers.startDate}
              width="150px"
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn
            title="Date To"
            titleWidth="90px"
            filterWidth="150px"
          >
            <DatePicker
              value={searchFilters.endDate}
              onChange={handlers.endDate}
              width="150px"
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn titleWidth="0px" filterWidth="200px">
            <FlexCenterAlign>
              <SearchHeaderButton
                size="small"
                styleType="basicPrimary"
                onClick={onResetSearch}
              >
                Reset
              </SearchHeaderButton>
              <SearchHeaderButton
                size="small"
                onClick={onSearch}
                loader={loading}
              >
                Search
              </SearchHeaderButton>
            </FlexCenterAlign>
          </SearchHeaderColumn>
        </FlexSpaceBetween>
      </FiltersContainer>
      <FiltersContainer>
        <SearchHeaderColumn
          title="User Groups"
          titleWidth="100px"
          filterWidth="260px"
        >
          <UserGroupMultiSelect {...userGroupsMultiSelectProps} />
        </SearchHeaderColumn>
      </FiltersContainer>
    </SearchPageHeaderContainer>
  );
}

StaffReportHeader.propTypes = {
  setQuery: func.isRequired,
  loading: bool,
  userGroupsLoading: bool,
  defaultUserGroupIds: arrayOf(string),
};
