import React from 'react';
import styled from 'styled-components';
import { func, node, shape, string } from 'prop-types';
import { FlexColumn } from '@poly/admin-ui';

import { Button } from '@poly/admin-book';
import { SidebarIDs } from '../../../sidebars/constants.js';
import { FlexContainer } from '../../../components/FlexContainer.js';
import { OpenFullInfoSidebarButton } from '../../../sidebars/components/OpenFullInfoSidebarButton.js';
import { useSidebarLogicContext } from '../../../sidebars/SidebarLogicContext.js';

const SidebarFormContainer = styled(FlexColumn)`
  padding: 24px;
  position: relative;
`;

const OpenProjectBtnContainer = styled(FlexContainer)`
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 1000;
`;

function CloseSidebarBtn({ onClick }) {
  return (
    <Button size="tiny" styleType="accentDark" onClick={onClick}>
      Close Related Project
    </Button>
  );
}

CloseSidebarBtn.propTypes = {
  onClick: func.isRequired,
};

export function TaskSidebarContainer({ children, project, id }) {
  const { openProjectSidebar } = useSidebarLogicContext();

  const onClick = () => {
    openProjectSidebar(project);
  };

  return (
    <SidebarFormContainer>
      {project && (
        <OpenProjectBtnContainer>
          <OpenFullInfoSidebarButton
            sidebarId={id}
            openSidebarHandler={onClick}
            title="Open Related Project"
            CloseSidebarBtn={CloseSidebarBtn}
            fullSidebarId={SidebarIDs.project}
          />
        </OpenProjectBtnContainer>
      )}
      {children}
    </SidebarFormContainer>
  );
}

TaskSidebarContainer.propTypes = {
  id: string.isRequired,
  project: shape({
    projectId: string.isRequired,
    type: string.isRequired,
  }),
  children: node.isRequired,
};
