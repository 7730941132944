import * as R from 'ramda';
import { useUpdateSidebarLinkByParams } from '@poly/client-routing';
import { taskCollections } from '@poly/constants';
import { propEqLegacy } from '@poly/utils';

import {
  clientSidebarTabs,
  projectSidebarTabs,
  propertySidebarTabs,
  masterProjectSidebarTabs,
} from '../../routes/constants.js';
import { SidebarIDs } from '../../sidebars/constants.js';
import { useTasksPageTableHandlers } from '../../modules/tabs/tasksTab/useTasksPageTableHandlers.js';

const { CLIENTS, PROJECTS, PROPERTIES, RECURRING_PROJECTS } = taskCollections;

// getTaskPropsByEntity :: String -> Task -> Object
const getTaskPropsByEntity = (entityName) => R.path(['document', entityName]);

// isSidebarTaskCollection :: Task -> Boolean
const isSidebarTaskCollection = ({ collection }) =>
  R.any(R.equals(collection), [
    CLIENTS,
    PROJECTS,
    PROPERTIES,
    RECURRING_PROJECTS,
  ]);

// mergeLinkQueriesWithTab :: (String, [Function]) -> Task -> QueryObject
const mergeLinkQueriesWithTab = (sidebarTab, queryPicks) =>
  R.compose(R.mergeAll, R.juxt([...queryPicks, R.always({ sidebarTab })]));

// getSidebarLinkQueryByCollection :: Task -> QueryObject
const getSidebarLinkQueryByCollection = R.cond([
  [
    propEqLegacy('collection', PROJECTS),
    mergeLinkQueriesWithTab(projectSidebarTabs.projectTasks, [
      R.compose(R.pick(['type']), getTaskPropsByEntity('project')),
      R.compose(
        R.objOf(SidebarIDs.project),
        R.prop('projectId'),
        getTaskPropsByEntity('project'),
      ),
    ]),
  ],
  [
    propEqLegacy('collection', RECURRING_PROJECTS),
    mergeLinkQueriesWithTab(masterProjectSidebarTabs.masterProjectTasks, [
      R.compose(
        R.objOf(SidebarIDs.masterProject),
        R.prop('projectId'),
        getTaskPropsByEntity('recurringProject'),
      ),
    ]),
  ],
  [
    propEqLegacy('collection', CLIENTS),
    mergeLinkQueriesWithTab(clientSidebarTabs.clientTasks, [
      R.compose(
        R.objOf(SidebarIDs.client),
        R.prop('_id'),
        getTaskPropsByEntity('client'),
      ),
    ]),
  ],
  [
    propEqLegacy('collection', PROPERTIES),
    mergeLinkQueriesWithTab(propertySidebarTabs.propertyTasks, [
      R.compose(
        R.objOf(SidebarIDs.property),
        R.prop('_id'),
        getTaskPropsByEntity('property'),
      ),
    ]),
  ],
]);

export const useMyTasksRowHandler = (row) => {
  const updateSidebarLinkParams = useUpdateSidebarLinkByParams();
  const { onCompleteTask } = useTasksPageTableHandlers({
    collection: row.collection,
  });

  const taskIdHash = `#taskId_${row._id}`;

  return (e) => {
    if (e.target.href) {
      return e.stopPropagation();
    }

    if (isSidebarTaskCollection(row)) {
      return updateSidebarLinkParams({
        hash: taskIdHash,
        query: getSidebarLinkQueryByCollection(row),
      });
    }

    return onCompleteTask(row);
  };
};
