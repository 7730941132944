import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState } from 'react';
import { Icon, Text, getThemeColor } from '@poly/admin-book';

import {
  number,
  string,
  element,
  bool,
  func,
  arrayOf,
  shape,
} from 'prop-types';
import { getPropertiesDeepByHierarchyNodeR, isNilOrEmpty } from '@poly/utils';
import { preparePropertyHierarchyTotals } from '@poly/client-utils';
import { spendReportHierarchyBranchPropType } from '../SpendDetailsPageHeader/spendReportsPropTypes.js';
import { SpendReportErrorPopover } from '../../BatchInvoicingMondelez/SpendReportErrorPopover.js';

const BORDER_STYLE = '1px solid rgba(188, 188, 188, 0.4)';

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${({ level, withFooterTotals }) =>
    (level === 0 && withFooterTotals) || level > 0
      ? getThemeColor(['white'])
      : 'transparent'};
  padding: ${({ level }) =>
    level === 0 ? '10px 0' : `10px 0 10px ${level * 20}px`};
  border-top: ${({ level, withFooterTotals }) =>
    withFooterTotals || level > 0 ? BORDER_STYLE : 'none'};
  border-bottom: ${({ withFooterTotals, level }) =>
    !withFooterTotals && level !== 0 ? BORDER_STYLE : 'none'};
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const IconS = styled(Icon)`
  margin-left: 15px;
  margin-right: 10px;
`;

const CollapseWrapper = styled.div`
  border-bottom: ${({ level }) => (level === 0 ? BORDER_STYLE : 'none')};
  padding: 10px 0;
`;

export const CollapseFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: ${getThemeColor(['white'])};
  border-bottom: ${BORDER_STYLE};
  padding: 10px 0;
`;

const FooterTotalWrapper = styled.div`
  width: ${R.propOr('135px', 'width')};
  margin-right: ${R.propOr('17px', 'marginRight')};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export function CollapseFooterTotalC({ label, total, ...props }) {
  return (
    <FooterTotalWrapper {...props}>
      <Text size="12px" color={['midDark']}>
        {label}
      </Text>
      <Text size="16px">{total}</Text>
    </FooterTotalWrapper>
  );
}

CollapseFooterTotalC.propTypes = {
  label: string.isRequired,
  total: string.isRequired,
};

const TotalCS = styled(CollapseFooterTotalC)`
  flex-direction: row;
  width: auto;

  div {
    margin-right: 10px;
  }
`;

function SpendReportCollapseFooter({ clientInvoiceTotal, costTotal }) {
  return (
    <CollapseFooter>
      <CollapseFooterTotalC isFooter label="Subtotal Cost:" total={costTotal} />
      <CollapseFooterTotalC
        isFooter
        label="Subtotal Client Invoice:"
        total={clientInvoiceTotal}
      />
    </CollapseFooter>
  );
}

SpendReportCollapseFooter.propTypes = {
  clientInvoiceTotal: string.isRequired,
  costTotal: string.isRequired,
};

const FlexEndWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: ${R.propOr(0, 'gap')};
  margin: ${R.propOr(0, 'margin')};
`;

const ErrorText = styled.p`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  color: #5c5c5c;
  margin: 0;
  padding: 0;
`;

function ErrorsTotalComponent({ totalLevelErrors }) {
  if (isNilOrEmpty(totalLevelErrors)) {
    return null;
  }

  const text = totalLevelErrors.length === 1 ? 'error' : 'errors';

  return (
    <FlexEndWrapper gap="10px" margin="4px 0 0 0">
      <SpendReportErrorPopover totalLevelErrors={totalLevelErrors} />
      <ErrorText>{`${totalLevelErrors.length} ${text}`}</ErrorText>
    </FlexEndWrapper>
  );
}

ErrorsTotalComponent.propTypes = {
  totalLevelErrors: arrayOf(string),
};

export function HierarchyCollapse({
  level,
  title,
  content,
  isNoHierarchy,
  FooterComponent,
  withFooterTotals,
  totalLevelErrors,
  clientInvoiceTotal,
  showNoHierarchyTotal,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(true);

  const size = level === 0 ? '20px' : '14px';

  const titleProps = {
    level,
    withFooterTotals,
    onClick: () => setIsOpen(!isOpen),
  };
  const isTotalLevel = showNoHierarchyTotal
    ? !withFooterTotals || isNoHierarchy
    : !withFooterTotals;

  const isTotalFooter = showNoHierarchyTotal
    ? withFooterTotals && isOpen
    : withFooterTotals;

  return (
    <CollapseWrapper level={level}>
      <TitleWrapper {...titleProps}>
        <Title>
          <IconS size={10} name={isOpen ? 'arrow-up' : 'arrow-down'} />
          <Text size={size}>{title}</Text>
        </Title>
        {isTotalLevel && (
          <FlexEndWrapper gap="25px">
            <ErrorsTotalComponent totalLevelErrors={totalLevelErrors} />
            <TotalCS
              label={`Total level ${level + 1}:`}
              total={clientInvoiceTotal}
            />
          </FlexEndWrapper>
        )}
      </TitleWrapper>
      {isOpen && content}
      {isTotalFooter && (
        <FooterComponent {...props} clientInvoiceTotal={clientInvoiceTotal} />
      )}
    </CollapseWrapper>
  );
}

HierarchyCollapse.propTypes = {
  content: element,
  isNoHierarchy: bool,
  withFooterTotals: bool,
  title: string.isRequired,
  level: number.isRequired,
  showNoHierarchyTotal: bool,
  FooterComponent: func.isRequired,
  totalLevelErrors: arrayOf(string),
  clientInvoiceTotal: string.isRequired,
};

export function SpendReportCollapse({ report, ...props }) {
  const { costTotal, clientInvoiceTotal } =
    preparePropertyHierarchyTotals(report);
  return (
    <HierarchyCollapse
      {...props}
      costTotal={costTotal}
      clientInvoiceTotal={clientInvoiceTotal}
      FooterComponent={SpendReportCollapseFooter}
    />
  );
}

SpendReportCollapse.defaultProps = {
  report: {},
};

SpendReportCollapse.propTypes = {
  report: spendReportHierarchyBranchPropType,
};

// isNodeWithoutProperties :: ... -> Boolean
const isNodeWithoutProperties = R.compose(
  R.isEmpty,
  getPropertiesDeepByHierarchyNodeR([]),
);

export function SpendReportsHierarchyTreeTable({
  report,
  level,
  TableComponent,
  Collapse,
  showNoHierarchyTotal,
}) {
  const collapseProps = {
    level,
    title: report.name,
    key: report.name,
  };
  const isNoHierarchy = report._id === null && level === 0;

  if (isNodeWithoutProperties(report)) {
    return null;
  }

  if (!report.properties && !report.children) {
    return <Collapse {...collapseProps} content={null} />;
  }

  if (report.properties) {
    return (
      <Collapse
        {...collapseProps}
        withFooterTotals
        isNoHierarchy={isNoHierarchy}
        showNoHierarchyTotal={showNoHierarchyTotal}
        report={report}
        content={<TableComponent reports={report.properties} />}
      />
    );
  }

  return (
    <Collapse
      {...collapseProps}
      report={report}
      content={
        <>
          {report.children.map((children) => (
            <SpendReportsHierarchyTreeTable
              key={children.name}
              report={children}
              level={level + 1}
              TableComponent={TableComponent}
              Collapse={Collapse}
            />
          ))}
        </>
      }
    />
  );
}

SpendReportsHierarchyTreeTable.propTypes = {
  report: shape({
    name: string,
    children: arrayOf(shape({})),
    properties: arrayOf(shape({})),
  }),
  level: number,
  TableComponent: func.isRequired,
  Collapse: func,
  showNoHierarchyTotal: bool,
};
