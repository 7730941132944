import { gql } from '@apollo/client';

export const MENTION_GROUPS_SEARCH_QUERY = gql`
  query MENTION_GROUPS_SEARCH_QUERY($searchInput: CollectionSearchParams!) {
    searchMentionGroups(input: $searchInput) {
      hits {
        _id
        name
        description
        users {
          _id
          fullName
        }
        status
        client {
          _id
          name
        }
        notifyOnUnassignedProject
        notifyOnExternalRequest
        notifyOnClientCommentAdded
        notifyOnInternalProjectUpdateAdded
      }
      total
    }
  }
`;
