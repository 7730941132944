import { getPersonalInfoSection } from './personalInfoSection.js';
import { getSystemAccessSection } from './systemAccessSection.js';
import { getEmployeeSection } from './employeeInfoSection.js';
import { getRemarksSection } from './remarksSection.js';

export const getUserFormSections = ({
  isEdit = false,
  isContractor,
  isCardLayout,
  hasPermissionToLoginViaSMS,
}) => {
  const columnByProps = isCardLayout ? 2 : 1;

  return [
    ...getPersonalInfoSection(1),
    ...getRemarksSection(columnByProps),
    ...getEmployeeSection(columnByProps, isContractor),
    ...getSystemAccessSection(
      isEdit ? 1 : columnByProps,
      hasPermissionToLoginViaSMS,
    ),
  ];
};
