import { collectionNames } from './collectionNames.js';

export const filesCollections = {
  BATCHES: 'batches',
  CLIENTS: 'clients',
  INVOICES: 'invoices',
  OCR_INVOICES: 'ocrInvoices',
  MAINTENANCE_PLANS: 'maintenancePlans',
  PROJECTS: 'projects',
  PROPERTIES: 'properties',
  PURCHASE_ORDERS: 'purchaseOrders',
  SUPPLIERS: 'suppliers',
  USERS: 'users',
  RECURRING_PROJECTS: collectionNames.recurringProjects,
  MASTER_SUPPLIERS: collectionNames.masterSuppliers,
  ASSETS: collectionNames.assets,
  ADMIN_PURCHASE_ORDERS: collectionNames.adminPurchaseOrders,
};

export const MIME_TYPES = {
  PDF: 'application/pdf',
  // Sometimes PDF may have Postscript MIME type
  // therefore those can be used interchangeably
  // (see #3513 for example)
  POSTSCRIPT: 'application/postscript',
  IMAGE_PNG: 'image/png',
  IMAGE_JPEG: 'image/jpeg',
  IMAGE_JPG: 'image/jpg',
  TEXT_HTML: 'text/html',
};

export const ALLOWED_FILE_EXTENSIONS = [
  'bmp',
  'png',
  'jpg',
  'jpeg',
  'tif',
  'tiff',
  'csv',
  'doc',
  'docm',
  'docx',
  'html',
  'mdb',
  'pdf',
  'pot',
  'potm',
  'potx',
  'ppam',
  'pps',
  'ppsm',
  'ppsx',
  'ppt',
  'pptx',
  'rtf',
  'sldx',
  'txt',
  'xla',
  'xlam',
  'xlm',
  'xls',
  'xlsm',
  'xlsx',
  'odp',
];

export const MAX_FILE_SIZE = 21e6; // in bytes

const bytesToMegabytes = (bytes) => bytes / 1e6;
export const MAX_FILE_SIZE_ERROR_MESSAGE = `Max size of the file should not exceed ${bytesToMegabytes(
  MAX_FILE_SIZE,
).toFixed()} MB`;

export const MAX_FILES_LIMIT = 5;

export const generateMaxFilesLimitError = (limit) =>
  `File Upload limited to ${limit} files per upload`;

export const MAX_FILES_LIMIT_ERROR_MESSAGE =
  generateMaxFilesLimitError(MAX_FILES_LIMIT);

export const MAX_FILE_NAME_CHARS_LIMIT = 100;

export const MAX_FILE_EXT_LENGTH = 20;

export const POLY_STATIC_FILES_HOST = 'https://poly-static.s3.amazonaws.com';

export const SIGN_OFF_SHEET_DOCUMENT_URL = `${POLY_STATIC_FILES_HOST}/Sign_off_Form.pdf`;
