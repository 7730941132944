import styled from 'styled-components';
import { Tabs, getThemeColor } from '@poly/admin-book';

import {
  GLOBAL_SEARCH_RESULT_ACTIVE_TAB_ID,
  GLOBAL_SEARCH_RESULT_CONTAINER_ID,
} from '../globalSearchConstants.js';

export const GlobalSearchResultTabsS = styled(Tabs)`
  height: 100%;

  & > div:first-of-type {
    // tab header
    padding: 0 20px;
    max-width: 100vw;
    position: relative;

    &:after {
      display: flex;
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: ${getThemeColor(['primaryLighter8'])};
    }

    & > div {
      margin-right: 18px;
    }
  }
  & > div:last-of-type {
    // tab content with is defined by tab header
    position: absolute;
    width: 100%;
  }
`;

export const GlobalSearchResultWrapperS = styled.div.attrs(() => ({
  id: GLOBAL_SEARCH_RESULT_CONTAINER_ID,
}))`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: ${getThemeColor(['white'])};
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
  border: 2px none #e1e6f4;
  border-left-style: solid;
`;

export const GlobalSearchResultActiveTabS = styled.div.attrs(() => ({
  id: GLOBAL_SEARCH_RESULT_ACTIVE_TAB_ID,
}))`
  overflow: auto;
  height: calc(100% - 45px);
`;
