import React, { Fragment } from 'react';
import { bool, func, number, shape } from 'prop-types';
import { MAX_FILE_NAME_CHARS_LIMIT } from '@poly/constants';

import { attachmentsPropTypes } from '../FilePicker/commonPropTypes.js';
import { InputErrorMsg } from '../Input/index.js';
import {
  FileListWrapper,
  FileListItem,
  FileNameInput,
  IconClose,
} from '../FilePicker/styles.js';
import {
  getFileItemErrors,
  FileItemErrorMsg,
  getCommonFilesError,
  getFileNameLength,
} from '../FilePicker/fileList.js';
import { fileErrorPropTypes } from '../FilePicker/UploadFileLogic.js';
import { CharacterCount } from '../CharacterCount/CharacterCount.js';

export function FileList({
  value: { attachments },
  onFileNameChange,
  onFileRemove,
  error,
  fileNameLimit,
  isFileNameLimited,
}) {
  const commonError = getCommonFilesError(error);
  return (
    <>
      <FileListWrapper data-testid="files-list">
        {attachments.map(({ fileName, _id, upload }) => {
          const errors = getFileItemErrors(_id, error);
          return (
            <Fragment key={_id}>
              {isFileNameLimited && (
                <CharacterCount
                  length={getFileNameLength(fileName)}
                  limit={fileNameLimit}
                />
              )}
              <FileListItem hasError={!!errors.length}>
                <FileNameInput
                  autoFocus
                  value={fileName}
                  onChange={onFileNameChange(_id)}
                  placeholder="Enter file description"
                />
                <p>
                  <span>{upload.name}</span>
                  <IconClose onClick={onFileRemove(_id)} name="close" />
                </p>
              </FileListItem>
              {errors.map((err) => (
                <FileItemErrorMsg key={err}>{err}</FileItemErrorMsg>
              ))}
            </Fragment>
          );
        })}
      </FileListWrapper>
      {commonError && <InputErrorMsg>{commonError}</InputErrorMsg>}
    </>
  );
}

FileList.propTypes = {
  value: shape({ attachments: attachmentsPropTypes }).isRequired,
  onFileNameChange: func.isRequired,
  onFileRemove: func.isRequired,
  error: fileErrorPropTypes,
  isFileNameLimited: bool,
  fileNameLimit: number,
};

FileList.defaultProps = {
  fileNameLimit: MAX_FILE_NAME_CHARS_LIMIT,
  isFileNameLimited: true,
};
