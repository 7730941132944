import * as R from 'ramda';
import { pathEqLegacy } from '@poly/utils';

// checkFilterField :: String -> String -> Boolean
const checkFilterField = (fieldName) => (name) =>
  R.test(new RegExp(`^${name}.*|^${name}$`), fieldName);

// rejectFormFieldsByNamesList :: [FieldName] -> [FormSectionConfig] -> [FormSectionConfig]
// FieldName = String
// eslint-disable-next-line import/no-unused-modules
export const rejectFormFieldsByNamesList = R.curry((namesList, formConfig) =>
  R.map(
    R.over(
      R.lensProp('fields'),
      R.reject(
        R.pathSatisfies(
          (fieldName) => R.find(checkFilterField(fieldName), namesList),
          ['field', 'name'],
        ),
      ),
    ),
    formConfig,
  ),
);

// mergeObjectsListByGroupFn :: (Object -> String, [Object], [Object]) -> [Object]
// eslint-disable-next-line import/no-unused-modules
export const mergeObjectsListByGroupFn = (
  groupByFn,
  listObjectsOne,
  listObjectsTwo,
) =>
  R.compose(
    R.values,
    R.mapObjIndexed(R.reduce(R.mergeDeepRight, {})),
    R.groupBy(groupByFn),
    R.concat(listObjectsOne),
  )(listObjectsTwo);

// mergeSections :: (FormSectionConfig, FormSectionConfig) -> FormSectionConfig
// FormSectionConfig = Object
const mergeSections = (section, customSection) =>
  R.mergeWithKey(
    (key, sectionField, customSectionField) =>
      key === 'fields'
        ? mergeObjectsListByGroupFn(
            R.path(['field', 'name']),
            sectionField,
            customSectionField,
          )
        : customSectionField,
    section,
    customSection,
  );

// mergeFormConfigs :: ([FormSectionConfig], [FormSectionConfig]) -> [FormSectionConfig]
// eslint-disable-next-line import/no-unused-modules
export const mergeFormConfigs = R.curry((sections, customSections) =>
  R.compose(
    R.values,
    R.mapObjIndexed(R.reduce(mergeSections, {})),
    R.groupBy(R.prop('id')),
    R.concat(R.__, customSections),
  )(sections),
);

export const isNullRemark = R.both(
  pathEqLegacy(['ops', 'length'], 1),
  R.pathSatisfies(R.isNil, ['ops', 0, 'insert']),
);

// isNotEmptyObject :: Any -> Boolean
const isNotEmptyObject = R.allPass([
  R.is(Object),
  R.complement(R.is(Date)),
  R.complement(R.is(Array)),
  R.complement(R.isEmpty),
]);

// removedFieldsAsNull :: [String] -> Object -> Object -> Object
const removedFieldsAsNull = R.curryN(
  3,
  (skipFields, initialValues, values = {}) =>
    R.compose(
      R.reduce((acc, key) => {
        if (isNotEmptyObject(initialValues[key])) {
          return {
            ...acc,
            [key]: {
              ...acc[key],
              ...removedFieldsAsNull(
                skipFields,
                initialValues[key],
                values[key] || {},
              ),
            },
          };
        }
        if (!R.isNil(values[key])) {
          return acc;
        }
        return { ...acc, [key]: null };
      }, values),
      R.reject(R.includes(R.__, skipFields)),
      R.keys,
    )(initialValues),
);

// formDataToMutationBase :: (FormValues -> MutationInput, [String]) -> InitialValues -> MutationInput
export const formDataToMutationBase =
  (getMutationValues, mandatoryTextFields) => (initialValues) =>
    R.converge(R.mergeDeepLeft, [
      getMutationValues,
      R.compose(
        removedFieldsAsNull([], R.pick(mandatoryTextFields, initialValues)),
        R.pick(mandatoryTextFields),
      ),
    ]);
