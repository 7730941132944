import React from 'react';
import {
  MainHeader,
  PageHeaderContainer,
} from '@poly/admin-book/src/Header/index.js';

import {
  projectsQueries,
  projectsTabsConfig,
} from '../../modules/tabs/common.js';
import { PageTabs } from '../../modules/tabs/TabsWithRouter.js';
import { ProjectsDirectoryToolbar } from './ProjectsDirectoryToolbar.js';
import { SearchProjectsTableWrapper } from './SearchProjectsTableWrapper.js';
import { ProjectsTabTitle } from '../../modules/tabs/projectsTab/ProjectsTabTitle.js';
import { ProjectsPageProjectsTab } from '../../modules/tabs/projectsTab/ProjectsTab.js';
import { useSearchProjectsFilter } from '../MyRecurringProjects/useSearchProjectsFilter.js';

const tabFromName = ([status, name]) => [
  <ProjectsTabTitle
    key={status}
    {...{ name, status }}
    query={projectsQueries[status]}
  />,
  name,
  <ProjectsPageProjectsTab key={status} query={projectsQueries[status]} />,
];

const tabs = projectsTabsConfig.map(tabFromName);

export function ProjectsPage() {
  const { filter, headerProps } = useSearchProjectsFilter();

  return (
    <>
      <PageHeaderContainer {...headerProps}>
        <MainHeader>
          {filter ? 'Search Projects' : 'Projects Directory'}
        </MainHeader>
      </PageHeaderContainer>
      {filter ? (
        <SearchProjectsTableWrapper filter={filter} />
      ) : (
        <PageTabs
          toolBar={<ProjectsDirectoryToolbar />}
          tabs={tabs}
          withPips
          defaultValue={projectsTabsConfig[0][1]}
        />
      )}
    </>
  );
}
