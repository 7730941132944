import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';

const FormTitle = styled.div`
  font-family: TTNormsMedium, FFMarkWebProNarrowBook, sans-serif;
  font-size: ${R.prop('size')};
  font-weight: 600;
  line-height: 25px;
`;

export function RequesterSurveyFormTitle({ value }) {
  return <FormTitle size="18px">{value}</FormTitle>;
}

RequesterSurveyFormTitle.propTypes = {
  value: string.isRequired,
};

export function RequesterSurveyFormSubTitle({ value }) {
  return <FormTitle size="14px">{value}</FormTitle>;
}

RequesterSurveyFormSubTitle.propTypes = {
  value: string.isRequired,
};
