import React, { useEffect } from 'react';
import * as R from 'ramda';
import { FormSpy } from 'react-final-form';
import { arrayOf, shape, string, func } from 'prop-types';
import { Checkbox, FormField, Loader } from '@poly/admin-book';
import { useSupplierServiceTypes } from '../../../../core/hooks/suppliers/index.js';

function ServiceCheckBox({ _id, name, ...restProps }) {
  return (
    <FormField
      {...restProps}
      Component={Checkbox}
      name={`services.${_id}`}
      additionalProps={{ label: name }}
    />
  );
}

ServiceCheckBox.propTypes = {
  _id: string,
  name: string,
};

function ServiceCheckBoxGroup({ services }) {
  return (
    <>
      {R.map(
        ({ _id, ...rest }) => (
          <ServiceCheckBox key={_id} {...{ _id, ...rest }} />
        ),
        services,
      )}
    </>
  );
}

ServiceCheckBoxGroup.propTypes = {
  services: arrayOf(
    shape({
      _id: string.isRequired,
    }),
  ).isRequired,
};

function ServiceCheckBoxContainer({ form, supplierId }) {
  const { supplierServiceTypes, loading } = useSupplierServiceTypes(supplierId);

  const services = supplierServiceTypes.filter((service) => service);

  useEffect(() => {
    form.change('services', {});
  }, [supplierId]);

  if (loading) return <Loader />;
  if (!services.length) return null;

  return <ServiceCheckBoxGroup services={services} />;
}

ServiceCheckBoxContainer.propTypes = {
  form: shape({
    change: func,
  }),
  supplierId: string,
};

export function SupplierServicesCheckboxGroup() {
  return (
    <FormSpy subscription={{ values: true }}>
      {({ values, form }) => (
        <ServiceCheckBoxContainer form={form} supplierId={values.supplierId} />
      )}
    </FormSpy>
  );
}

SupplierServicesCheckboxGroup.displayName = 'SupplierServicesCheckboxGroup';
