import * as R from 'ramda';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setSearchEntityParams } from '../redux/searchEntityParams.js';

const pickSearchEntityParams = R.pick([
  'sort',
  'searchText',
  'query',
  'pagination',
]);

export const useSetSearchEntityParams = (props) => {
  const { sort, searchText, query, pagination } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSearchEntityParams(pickSearchEntityParams(props)));

    return () => dispatch(setSearchEntityParams(null));
  }, [sort, searchText, query, pagination]);
};
