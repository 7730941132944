import * as R from 'ramda';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import React, { createContext, useState, useEffect } from 'react';
import {
  func,
  bool,
  element,
  object,
  arrayOf,
  string,
  node,
  oneOfType,
} from 'prop-types';

import { IconButton } from '../IconButton/index.js';
import { getThemeColor } from '../utils.js';

export const ModalContext = createContext({});

function Context({ contextValue, children }) {
  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
}

Context.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  contextValue: object,
  children: node,
};

const ESC_KEY_CODE = 27;

const Overlay = styled.div.attrs(({ modalId }) => ({
  'data-testid': `modal-${modalId}`,
}))`
  position: fixed;
  top: 0;
  z-index: 10001;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);

  ${R.prop('layoutStyles')};
`;

const Aligner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  background-color: ${getThemeColor(['white'])};
  border-radius: 4px;
  box-shadow: 0 6px 12px -1px rgba(0, 0, 0, 0.12);
  max-width: ${({ maxWidth }) => maxWidth || '95%'};
`;

const Header = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  padding-right: 20px;
  align-items: flex-end;
  justify-content: flex-end;
`;

const Content = styled.div`
  padding: 0 25px 30px 25px;
  max-height: calc(100vh - 30px);
  overflow: ${(props) => (props.isOverflowAuto ? 'auto' : props.overflow)};
`;

function ModalDialog({ modalId, children, onClose, isOverflowAuto, maxWidth }) {
  const [overflow, setOverflow] = useState('auto');

  useEffect(() => {
    const onEscape = (e) => {
      if (e.keyCode === ESC_KEY_CODE) {
        onClose(e);
      }
    };

    document.addEventListener('keydown', onEscape, false);
    return () => document.removeEventListener('keydown', onEscape, false);
  }, []);

  return (
    <Context contextValue={{ overflow, setModalOverflow: setOverflow }}>
      <Container maxWidth={maxWidth}>
        <Header>
          <IconButton
            data-testid={`modal-close-icon-${modalId}`}
            name="close"
            size={15}
            onClick={onClose}
            color="#8C8F94"
          />
        </Header>
        <Content {...{ overflow, isOverflowAuto }}>{children}</Content>
      </Container>
    </Context>
  );
}

ModalDialog.propTypes = {
  children: element.isRequired,
  onClose: func.isRequired,
  modalId: string,
  isOverflowAuto: bool,
  maxWidth: string,
};

export function ModalOverlay({
  children,
  isOpen,
  root,
  layoutStyles,
  modalId,
}) {
  return createPortal(
    isOpen ? (
      <Overlay {...{ layoutStyles, modalId }}>
        <Aligner>{children}</Aligner>
      </Overlay>
    ) : null,
    root,
  );
}

ModalOverlay.displayName = 'Modal';
ModalOverlay.propTypes = {
  children: element.isRequired,
  isOpen: bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  root: object,
  layoutStyles: oneOfType([string, arrayOf(string)]),
};

ModalOverlay.defaultProps = {
  layoutStyles: '',
  root: window.document.getElementById('root'),
};

export const ModalActions = styled.div`
  display: flex;
  justify-content: flex-start;

  & > :not(:last-child) {
    margin-right: 10px;
  }
`;

export function Modal({
  onClose,
  children,
  simple,
  modalId,
  isOverflowAuto,
  maxWidth,
  ...props
}) {
  return (
    <ModalOverlay modalId={modalId} {...props}>
      {simple ? (
        children
      ) : (
        <ModalDialog
          modalId={modalId}
          onClose={onClose}
          isOverflowAuto={isOverflowAuto}
          maxWidth={maxWidth}
        >
          {children}
        </ModalDialog>
      )}
    </ModalOverlay>
  );
}

Modal.propTypes = {
  onClose: func.isRequired,
  children: element.isRequired,
  simple: bool,
  isOverflowAuto: bool,
  modalId: string,
  maxWidth: string,
};
Modal.defaultProps = {
  simple: false,
};
