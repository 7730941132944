import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { LinkButton } from '@poly/admin-book';
import { useModalContext } from '@poly/admin-ui';
import { arrayOf, func, shape, string } from 'prop-types';

import { MergeAssetComponentsModalForm } from './MergeAssetComponentsModalForm.js';
import { getMergeAssetComponentsConfig } from './merge-config.js';

const MergeAssetComponentsLayout = styled.div`
  width: 400px;
`;

// isMergeDisabled :: [ID] -> Boolean
const isMergeDisabled = R.compose(R.lte(R.__, 1), R.length, R.defaultTo([]));

export function MergeAssetComponentsButton({
  documents,
  mergeEntity,
  selectedRows,
  setSelectedRows,
}) {
  const { openModal } = useModalContext();

  const mergeAssetConfig = getMergeAssetComponentsConfig(mergeEntity);

  const onClick = () =>
    openModal({
      id: mergeAssetConfig.modalId,
      Layout: MergeAssetComponentsLayout,
      content: (
        <MergeAssetComponentsModalForm
          documents={documents}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          mergeAssetConfig={mergeAssetConfig}
        />
      ),
    });

  const linkButtonProps = {
    onClick,
    disabled: isMergeDisabled(selectedRows),
  };

  return <LinkButton {...linkButtonProps}>Merge</LinkButton>;
}

MergeAssetComponentsButton.propTypes = {
  mergeEntity: string.isRequired,
  selectedRows: arrayOf(string),
  setSelectedRows: func,
  documents: arrayOf(
    shape({
      _id: string,
      name: string,
    }),
  ),
};
