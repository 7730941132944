import * as R from 'ramda';
import { isNilOrEmpty, propEqLegacy } from '@poly/utils';
import phoneCountriesConfig from './addressPhonesConfig.json';

// replaceFormat :: String -> String
const replaceFormat = R.replace(/\./g, '#');

// replacePhoneNumber :: String -> String
const replacePhoneNumber = R.replace(/\D*/g, '');

// prepareFormatPhone :: [CountryOption] -> String
const prepareFormatPhone = R.compose(
  R.concat('+'),
  R.join(' '),
  R.juxt([
    R.compose(R.replace(/\d/g, '#'), R.propOr('', 'dialCode')),
    R.compose(
      replaceFormat,
      R.when(isNilOrEmpty, R.always('### ### ####')),
      R.prop('phoneFormat'),
    ),
  ]),
);

// preparePlaceholder :: [CountryOption] -> String
const preparePlaceholder = R.compose(
  R.replace(/#/g, '_'),
  R.concat('+'),
  R.join(' '),
  R.juxt([
    R.propOr('', 'dialCode'),
    R.compose(
      replaceFormat,
      R.when(isNilOrEmpty, R.always('___ ___ ____')),
      R.prop('phoneFormat'),
    ),
  ]),
);

// countryIfOr :: ([[CountryOption]], [[CountryOption]]) -> [[CountryOption]]
const countryIfOr = (countriesByArea, countriesByCode) =>
  countriesByArea.length ? countriesByArea : countriesByCode;

// getCountriesOptions :: [[CountryOption]] -> [Option]
// eslint-disable-next-line import/no-unused-modules
export const getCountriesOptionsBase = R.compose(
  R.map(
    R.applySpec({
      value: R.propOr('', 'iso'),
      label: R.compose(
        R.join(' +'),
        R.juxt([R.propOr('', 'label'), R.propOr('', 'dialCode')]),
      ),
    }),
  ),
  R.sortBy(R.prop('priority')),
  R.propOr([], 'options'),
);

// getCountryByIsoBase :: (String ,[[CountryOption]]) -> Country
// Country = { iso: String, code: String, format: String, placeholder: String, isPattern: String}
// eslint-disable-next-line import/no-unused-modules
export const getCountryByIsoBase = R.curry((iso, options) =>
  R.compose(
    R.ifElse(
      R.isNil,
      R.always({}),
      R.applySpec({
        iso: R.prop('iso'),
        code: R.prop('dialCode'),
        format: prepareFormatPhone,
        placeholder: preparePlaceholder,
        isPattern: R.propOr(null, 'phoneFormat'),
      }),
    ),
    R.find(propEqLegacy('iso', iso)),
    R.propOr([], 'options'),
  )(options),
);

// getPhonePattern :: Country -> String
// Country = Object
const getPhonePattern = R.compose(
  R.ifElse(
    R.compose(R.isNil, R.prop('isPattern')),
    R.always(null),
    R.compose(
      R.replace(/#/g, '[0-9]'),
      R.replace(/[\\+()-]/g, '\\W'),
      R.prop('format'),
    ),
  ),
);

// findIsoCountryByNumberBase -> (String -> [[CountryOption]]) -> String
// eslint-disable-next-line import/no-unused-modules
export const findIsoCountryByNumberBase = R.curry((number, phoneConfig) =>
  R.compose(
    R.propOr(null, 'iso'),
    R.head,
    R.when(
      R.propSatisfies(R.gt(R.__, 1), 'length'),
      R.converge(countryIfOr, [
        R.filter(
          R.compose(
            R.find(R.startsWith(R.__, R.drop(1, replacePhoneNumber(number)))),
            R.propOr([], 'phoneAreaCodes'),
          ),
        ),
        R.sortBy(R.prop('priority')),
      ]),
    ),
    R.filter(
      R.compose(
        R.startsWith(R.__, replacePhoneNumber(number)),
        R.prop('dialCode'),
      ),
    ),
    R.propOr([], 'options'),
  )(phoneConfig),
);

// isPhoneNumberHasCountryCode :: String -> Boolean
const isPhoneNumberNotHasCountryCode = R.compose(
  R.both(
    R.compose(R.not, R.startsWith('+')),
    R.compose(R.propSatisfies(R.gt(R.__, 7), 'length'), replacePhoneNumber),
  ),
  R.defaultTo(''),
);

// formattingInternationalPhone :: String -> String
const formattingInternationalPhone = R.compose(
  R.concat('+'),
  replacePhoneNumber,
);

// isPhoneNumberEntered :: String -> Number -> Boolean
const isPhoneNumberEntered = R.curry((phoneNumber, code) =>
  R.ifElse(
    isNilOrEmpty,
    R.F,
    R.compose(R.not, R.equals(0), R.subtract(R.__, code), replacePhoneNumber),
  )(phoneNumber),
);

/**
 * getCountryFieldsByIso :: String -> CountriesConfig -> Country
 * Country = {
 *    localityName: Object,
 *    postalCode: Object,
 *    administrativeArea: Object
 * }
 */
const getCountryFieldsByIso = R.curry((iso, countriesConfig) =>
  R.compose(
    R.fromPairs,
    R.unnest,
    R.map(R.toPairs),
    R.defaultTo([]),
    R.find(R.complement(R.isNil)),
    R.pluck('locality'),
    R.propOr([], 'fields'),
    R.find(propEqLegacy('iso', iso)),
    R.propOr([], 'options'),
  )(countriesConfig),
);

// findIsoCountryByNumber :: String -> String
const findIsoCountryByNumber = (phone) =>
  findIsoCountryByNumberBase(phone, phoneCountriesConfig);

// getCountryByIso :: String -> String
const getCountryByIso = (countryIso) =>
  getCountryByIsoBase(countryIso, phoneCountriesConfig);

// getCountriesOptions :: _ -> [String, String]
const getCountriesOptions = () => getCountriesOptionsBase(phoneCountriesConfig);

const internationalPhoneUtils = {
  getCountryByIso,
  findIsoCountryByNumber,
  isPhoneNumberEntered,
  formattingInternationalPhone,
  isPhoneNumberNotHasCountryCode,
  getPhonePattern,
  getCountriesOptions,
  getCountryFieldsByIso,
};

export { internationalPhoneUtils };
