import styled from 'styled-components';
import { bool, func, shape, string } from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';
import { internationalPhoneUtils } from '@poly/client-utils';
import { Input, Select } from '@poly/admin-book';

import flagImg from './flag.png';
import flagConfig from './flagConfig.json';
import {
  getBackgroundPositionByIso,
  getCountryIsoByAddress,
} from './internationalPhoneUtils.js';

const {
  getCountriesOptions,
  getPhonePattern,
  findIsoCountryByNumber,
  isPhoneNumberNotHasCountryCode,
  isPhoneNumberEntered,
  getCountryByIso,
} = internationalPhoneUtils;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const SelectCountry = styled(Select)`
  .select__control,
  .select__control:hover,
  .select__control:focus,
  .select__control--is-focused {
    min-height: 30px;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 60px;
  }

  .select__value-container {
    padding: 2px 2px 2px 8px;
    margin-bottom: 2px;
  }

  .select__dropdown-indicator {
    padding: 0 5px 0 3px;
  }

  .select__single-value {
    width: 25px;
    height: 20px;
    background-image: url(${flagImg});
    background-repeat: no-repeat;
    background-position: ${({ bgPosition }) => bgPosition};
    color: transparent;
  }

  .select__menu-portal,
  .select__menu {
    width: 300px;
  }
`;

const SelectWrapper = styled.div`
  width: 60px;
`;

const InputPhone = styled(Input)`
  width: calc(100% - 60px);
  input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export function InternationalPhoneInput({
  value,
  name,
  onChange,
  formData,
  error,
  onBlur,
  onFocus,
  hasError,
  required,
}) {
  const [internalValue, setInternalValue] = useState(value);

  const isoByAddress = getCountryIsoByAddress(formData);

  const isoByNumber = !isPhoneNumberNotHasCountryCode(internalValue)
    ? findIsoCountryByNumber(internalValue)
    : undefined;

  const [countryIso, setCountryIso] = useState(isoByNumber || isoByAddress);

  const bgPosition = getBackgroundPositionByIso(countryIso, flagConfig);

  const options = useMemo(() => getCountriesOptions(), []);

  const country = useMemo(() => getCountryByIso(countryIso), [countryIso]);

  const phonePattern =
    isPhoneNumberEntered(internalValue, country.code) || required
      ? getPhonePattern(country)
      : null;

  const onValueChange = (values) => {
    const { formattedValue } = values;
    setInternalValue(formattedValue);
    if (isPhoneNumberEntered(formattedValue, country.code)) {
      onChange(formattedValue);
    }
  };

  useEffect(() => {
    if (countryIso !== isoByAddress && countryIso !== isoByNumber) {
      onChange(country.code);
      setInternalValue(country.code);
    }
  }, [countryIso]);

  useEffect(() => {
    if (isoByNumber) {
      setCountryIso(isoByNumber);
    } else {
      setCountryIso(isoByAddress);
    }
  }, [isoByAddress, isoByNumber]);

  useEffect(() => {
    if (isPhoneNumberNotHasCountryCode(internalValue)) {
      const formattedValue = `+${country.code}${value}`;
      setInternalValue(formattedValue);
      onChange(formattedValue);
    }
  }, []);

  const onFocusInternal = (e) => {
    if (!e.target.value) {
      setInternalValue(e.target.placeholder);
    }
    onFocus(e);
  };

  const onChangeCountry = (iso) => {
    if (iso === isoByAddress) {
      const { code } = getCountryByIso(iso);
      onChange(code);
      setInternalValue(code);
      setCountryIso(iso);
    } else {
      setCountryIso(iso);
    }
  };

  return (
    <Wrapper>
      <SelectWrapper>
        <SelectCountry
          required
          options={options}
          value={countryIso}
          name="phoneCountry"
          isSearchable={false}
          bgPosition={bgPosition}
          onChange={onChangeCountry}
          data-testid="select-country"
        />
      </SelectWrapper>
      <InputPhone
        skipTrim
        mask="_"
        type="tel"
        name={name}
        value={internalValue}
        pattern={phonePattern}
        format={country.format}
        onFocus={onFocusInternal}
        onValueChange={onValueChange}
        placeholder={country.placeholder}
        {...{ error, onBlur, hasError, required }}
      />
    </Wrapper>
  );
}

InternationalPhoneInput.propTypes = {
  error: string,
  required: bool,
  name: string.isRequired,
  onBlur: func.isRequired,
  onFocus: func.isRequired,
  onChange: func.isRequired,
  hasError: bool.isRequired,
  value: string,
  formData: shape({
    address: shape({
      address_parts: shape({
        country: string,
      }),
    }),
  }),
};
