import * as R from 'ramda';

// getMarkupPercentBase :: Number -> [ProjectMarkupRule] -> Number
// ProjectMarkupRule = {upTo: Number, percent: Number}
export const getMarkupPercentBase = R.curry((total, markupRules) =>
  R.ifElse(
    R.either(R.isNil, R.isEmpty),
    R.always(null),
    R.compose(
      R.prop('percent'),
      R.find(R.compose(R.lte(total), R.propOr(Infinity, 'upTo'))),
    ),
  )(markupRules),
);
