import { ESFM_INFO } from '@poly/constants';

export const sendWOCFormId = 'sendWOCFormId';

export const reviseWOCNote =
  'Revised work order - All previously sent work ' +
  'orders related to this project are null and void. Do not combine previously ' +
  'sent NTE with this work order. The pricing in this new work order is all ' +
  'inclusive of all labor, material, shipping, handling, tax and/or ' +
  'miscellaneous amounts.';

export const requestFreeQuoteNote =
  'Please note: this work order is for a ' +
  'FREE QUOTE. Do not discuss or leave pricing with the site. Please provide ' +
  `your quote to ${ESFM_INFO.name} within one (1) business day from the ` +
  'assessment, and include a labor and material breakdown in your pricing.';
