import * as R from 'ramda';
import { removeFileExtension } from '@poly/utils';

// getFileLinkProps :: File -> Obj
// Input = {
//    url: String,
//    fileName: String,
// }
export const getFileLinkProps = R.compose(
  R.mergeAll,
  R.juxt([
    R.applySpec({
      fileName: R.compose(removeFileExtension, R.prop('fileName')),
    }),
    R.pick(['url']),
  ]),
);
