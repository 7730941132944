import React from 'react';
import { CommonPrintLayout } from '@poly/admin-ui';

import { StoreProvider, ThemeProvider } from '../providers.js';
import { SidebarInnerContext } from '../sidebars/SidebarInnerContext.js';

export const usePrintLayout = () =>
  function (props) {
    return (
      <ThemeProvider>
        <StoreProvider>
          <CommonPrintLayout {...props} Context={SidebarInnerContext} />
        </StoreProvider>
      </ThemeProvider>
    );
  };
