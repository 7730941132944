import React, {
  useMemo,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { object, bool, func } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { formatTotal, tryCallFunction, debounce } from '@poly/utils';
import { useTableInfiniteScrollQuery } from '@poly/client-utils';
import { DESC_SORT_ORDER } from '@poly/constants';
import { Button, Loader } from '@poly/admin-book';
import {
  useNotificationState,
  useProcessState,
  useModalContext,
} from '@poly/admin-ui';

import {
  BottomPanelBody,
  BottomPanelContainer,
} from '../../components/BottomPanel.js';
import {
  PageWithSearchHeader,
  PageWithSearchHeaderContext,
} from '../../components/PageWithSearchHeader.js';
import {
  WhiteBodyWrapper,
  PayInvoicesTableCard,
} from '../PaySuppliersPage/PaySuppliersComponents.js';
import { VoidSupplierPaymentsHeader } from './VoidSupplierPaymentsHeader.js';
import { VoidSupplierPaymentsTable } from './VoidSupplierPaymentsTable.js';
import { TableAndPanelWrapper } from './styles.js';
import {
  getVoidSupplierPaymentsSearchQueryByFilters,
  convertPaymentsIdsToBatchNumbers,
  calculateSelectedPaymentsTotal,
  getBatchPaymentsByData,
} from './helpers.js';

const VoidButtonS = styled(Button)`
  align-self: flex-end;
`;

const supplierPaymentsMoneyTransactionsQuery = gql`
  query supplierPaymentsMoneyTransactionsQuery(
    $input: CollectionSearchParams!
  ) {
    searchMoneyTransactions(input: $input) {
      hits {
        _id
        transaction_number
        check_number
        is_split
        supplierCheck {
          isPrinted
        }
        date
        masterSupplier {
          _id
          company {
            name
          }
        }
        supplier {
          _id
          payByMaster
          company {
            name
          }
          tax {
            id
          }
        }
        lines {
          amount
          account {
            _id
            name
            code
          }
          payment_status
        }
      }
      total
    }
  }
`;

const voidSupplierPaymentMutationQuery = gql`
  mutation VoidSupplierPayment($input: VoidSupplierPaymentsInput!) {
    voidSupplierPayments(input: $input) {
      transactionNumbers
    }
  }
`;

const VOID_PAYMENT_PROCESS_ID = 'void_payment_process_id';

function VoidSupplierPaymentsBody({ data, loading, refetch, tableProps }) {
  const { openConfirmSubmitFormModal } = useModalContext();
  const { showSuccessNotification } = useNotificationState();
  const { isVoidInProcess } = useProcessState(VOID_PAYMENT_PROCESS_ID);
  const [isRefetching, setIsRefetching] = useState(false);

  const [voidSupplierPaymentsMutation] = useMutation(
    voidSupplierPaymentMutationQuery,
  );

  const [selectedPayments, setSelectedPayments] = useState([]);

  useEffect(() => {
    setSelectedPayments([]);
  }, [data]);

  const batchPayments = useMemo(() => getBatchPaymentsByData(data), [data]);

  const debouncedRefetch = useCallback(
    debounce(2000)(() => tryCallFunction(refetch)()),
    [refetch],
  );

  const onVoidSupplierPayments = async () => {
    const total = calculateSelectedPaymentsTotal(
      batchPayments,
      selectedPayments,
    );

    return openConfirmSubmitFormModal({
      content: (
        <>
          <div>
            {/* eslint-disable-next-line @cspell/spellchecker */}
            <span>You&apos;re about to void&nbsp;</span>
            <strong>{selectedPayments.length}</strong>
            <span>&nbsp;payments worth&nbsp;</span>
            <strong>{formatTotal(total)}</strong>
          </div>
          <div>Are you sure?</div>
        </>
      ),
      btnCaption: 'Yes, Void',
      processId: VOID_PAYMENT_PROCESS_ID,
      onConfirm: (closeConfirmModal) => async () => {
        await voidSupplierPaymentsMutation({
          variables: {
            input: {
              transactionNumbers:
                convertPaymentsIdsToBatchNumbers(selectedPayments),
            },
          },
        });
        setIsRefetching(true);
        setSelectedPayments([]);
        showSuccessNotification('Payments are successfully voided!');
        closeConfirmModal();
        // we need to timeout this to get actual data after mutation
        debouncedRefetch();
      },
    });
  };

  const { contentHeight } = useContext(PageWithSearchHeaderContext);

  useEffect(() => {
    setIsRefetching(false);
  }, [batchPayments]);

  return loading ? (
    <Loader />
  ) : (
    <WhiteBodyWrapper height={contentHeight}>
      <TableAndPanelWrapper>
        <PayInvoicesTableCard height="calc(100% - 70px)">
          <VoidSupplierPaymentsTable
            batchPayments={batchPayments}
            selectedPayments={selectedPayments}
            setSelectedPayments={setSelectedPayments}
            isRefetching={isRefetching}
            {...tableProps}
          />
        </PayInvoicesTableCard>
        <BottomPanelContainer height="70px">
          <BottomPanelBody>
            <VoidButtonS
              size="small"
              onClick={onVoidSupplierPayments}
              disabled={!selectedPayments.length || isVoidInProcess}
              loader={isVoidInProcess}
            >
              Void
            </VoidButtonS>
          </BottomPanelBody>
        </BottomPanelContainer>
      </TableAndPanelWrapper>
    </WhiteBodyWrapper>
  );
}

VoidSupplierPaymentsBody.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: object,
  loading: bool,
  refetch: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tableProps: object.isRequired,
};

const sortSupplierPaymentsOptions = [
  { date: { order: DESC_SORT_ORDER } },
  { check_number: { order: DESC_SORT_ORDER } },
];

export function VoidSupplierPaymentsPage() {
  const [query, setQuery] = useState({});

  const input = useMemo(
    () => ({
      query: getVoidSupplierPaymentsSearchQueryByFilters(query),
      sort: sortSupplierPaymentsOptions,
    }),
    [query],
  );

  const { data, loading, refetch, tableProps } = useTableInfiniteScrollQuery(
    supplierPaymentsMoneyTransactionsQuery,
    input,
    {
      pageSize: 500,
      endpointName: 'searchMoneyTransactions',
      skip: R.isEmpty(query),
    },
  );

  return (
    <PageWithSearchHeader headerHeight="165px">
      <VoidSupplierPaymentsHeader loading={loading} setQuery={setQuery} />
      <VoidSupplierPaymentsBody
        data={data}
        loading={loading}
        refetch={refetch}
        tableProps={tableProps}
      />
    </PageWithSearchHeader>
  );
}
