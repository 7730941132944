import * as R from 'ramda';
import React from 'react';
import { useRouterParams } from '@poly/client-routing';
import { ITEMS_LIMIT } from '@poly/admin-ui';

import { PRINT_TYPES } from '@poly/constants';
import { useQuery } from '@apollo/client';
import { SUPPLIER_ACCOUNT } from '../../modules/core/hooks/suppliers/queries.js';
import { TablePrintBtn } from '../../modules/core/tableToolbar/TablePrintPDFBtn.js';
import { useSearchSupplierInvoices } from './useSearchSupplierInvoices.js';

export function SupplierInvoicesPrintBtn() {
  const { supplierId } = useRouterParams(['supplierId']);

  const { data: supplierData } = useQuery(SUPPLIER_ACCOUNT, {
    variables: { supplierId },
  });

  const { data } = useSearchSupplierInvoices(supplierId);

  const printBtnProps = {
    data: R.path(['searchInvoices', 'hits'], data),
    style: { marginLeft: 30 },
    showWarningModal: R.pathSatisfies(
      R.gt(R.__, ITEMS_LIMIT),
      ['searchInvoices', 'hits', 'length'],
      data,
    ),
    printType: PRINT_TYPES.SUPPLIER_INVOICES,
    metaData: {
      title: R.path(['supplier', 'company', 'name'], supplierData),
    },
  };

  return <TablePrintBtn {...printBtnProps} />;
}
