import * as R from 'ramda';
import React from 'react';
import { gql } from '@apollo/client';
import { useReactiveQuery } from '@poly/client-utils';
import { TableCardWithoutTabs } from '@poly/admin-book';

import { DivisionsPageHeader } from './DivisionsPageHeader.js';
import { DivisionsTable } from './DivisionsTable.js';

const GET_DIVISIONS_QUERY = gql`
  query GET_DIVISIONS($input: DivisionsInput) {
    divisions(input: $input) {
      _id
      name
      status
    }
  }
`;

const DIVISIONS_CHANGED_SUBSCRIPTION = gql`
  subscription DIVISIONS_CHANGED_SUBSCRIPTION($input: CollectionSearchParams) {
    searchDivisionChanged(input: $input) {
      id
      type
    }
  }
`;

// getDivisions :: { divisions: [Division] } -> [Division]
const getDivisions = R.propOr([], 'divisions');

export function DivisionsPage() {
  const { data, loading } = useReactiveQuery(
    GET_DIVISIONS_QUERY,
    DIVISIONS_CHANGED_SUBSCRIPTION,
    { queryOptions: {}, subscriptionOptions: {} },
  );

  const divisions = getDivisions(data);

  return (
    <>
      <DivisionsPageHeader divisions={divisions} loading={loading} />
      <TableCardWithoutTabs>
        <DivisionsTable divisions={divisions} loading={loading} />
      </TableCardWithoutTabs>
    </>
  );
}
