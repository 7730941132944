import * as R from 'ramda';
import { gql } from '@apollo/client';
import { Select } from '@poly/admin-book';
import React, { useState } from 'react';
import { func, number, shape, string } from 'prop-types';
import {
  filterDocumentsMatchedByPaths,
  entityToOptionByLabelPath,
  useReactiveQuery,
} from '@poly/client-utils';

const CLIENT_INVOICES_QUERY = gql`
  query CLIENT_INVOICES_QUERY($input: ClientInvoicesInput) {
    clientInvoices(input: $input) {
      hits {
        _id
        number
      }
    }
  }
`;

const CLIENT_INVOICES_SUBSCRIPTION = gql`
  subscription CLIENT_INVOICES_SUBSCRIPTION($input: CollectionSearchParams!) {
    searchClientInvoiceChanged(input: $input) {
      id
      type
    }
  }
`;

// getDropdownOptions :: String -> ClientInvoicesResult -> [Pair String String]
const getDropdownOptions = (searchText) =>
  R.compose(
    R.map(entityToOptionByLabelPath(['number'])),
    R.take(10),
    filterDocumentsMatchedByPaths([['number']], searchText),
    R.pathOr([], ['clientInvoices', 'hits']),
  );

export function ClientInvoiceSelect({ input, onChange, ...otherProps }) {
  const [searchText, setSearchText] = useState('');

  const reactiveQueryParams = {
    queryOptions: { variables: { input }, fetchPolicy: 'network-only' },
    subscriptionOptions: { variables: { input } },
  };

  const { data, loading } = useReactiveQuery(
    CLIENT_INVOICES_QUERY,
    CLIENT_INVOICES_SUBSCRIPTION,
    reactiveQueryParams,
  );

  const selectProps = {
    ...otherProps,
    options: getDropdownOptions(searchText)(data),
    loading,
    searchText,
    onChange,
    onInputChange: setSearchText,
    isClearable: true,
    placeholder: 'Start typing client invoices',
  };

  return <Select {...selectProps} />;
}

ClientInvoiceSelect.propTypes = {
  onChange: func,
  input: shape({
    status: string,
    clientId: string,
    projectId: string,
    invoiceNumberFrom: number,
    invoiceNumberTo: number,
    invoiceNumber: number,
    from: number,
    size: number,
  }),
};
