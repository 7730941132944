import * as R from 'ramda';
import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import {
  useEntitySubscriptionQuery,
  useSubscriptionByChanges,
  useEntitiesByReactiveSearch,
} from '@poly/client-utils';
import { useRouterParams } from '@poly/client-routing';
import { tryCallFunction, debounce } from '@poly/utils';
import { useTableSearch } from '../../redux/search-text.js';
import { contactsDefaultSort } from '../../hocs/users/index.js';
import { usePagination } from '../../redux/pagination.js';
import { USER_SEARCH_CHANGED } from '../../hocs/users/subscriptions.js';
import { ALL } from '../../constants/general.js';
import { CONTACT_DETAILS, USERS_BY_SEARCH_FOR_OPTIONS } from './queries.js';
import { CONTACT_USER_CHANGED } from './subscriptions.js';
import { DEFAULT_USER_QUERY, USERS_EMAILS } from '../../hocs/users/queries.js';

export const useContactUsersSearchByQuery = ({
  gqlSearchQuery,
  gqlSearchChangedQuery = USER_SEARCH_CHANGED,
  sort = contactsDefaultSort,
  status = '',
  entity = {},
  skipQuery = false,
}) => {
  const { pagination } = usePagination();
  const { searchText } = useTableSearch();

  const { loading, result } = useEntitiesByReactiveSearch({
    gqlSearchQuery,
    gqlSearchChangedQuery,
    sort,
    pagination,
    searchText,
    query: {
      bool: {
        must: [
          { match: { status } },
          ...(entity?._id
            ? [
                {
                  nested: {
                    path: 'links',
                    query: {
                      term: { [`links.${entity?.name}Id`]: entity?._id },
                    },
                  },
                },
              ]
            : []),
        ],
      },
    },
    searchTextForQuery: searchText,
    additionalVars: { entityId: entity?._id },
    fetchPolicy: 'network-only',
    skipQuery: !entity?._id || skipQuery,
    alias: 'useContactUsersSearchByQuery',
  });

  return { loading, result };
};

export const useContactDetails = (contactId) => {
  const { data, loading, refetch } = useQuery(CONTACT_DETAILS, {
    variables: { id: contactId },
    skip: !contactId || contactId === ALL,
  });

  useSubscriptionByChanges({
    gqlQueryChanged: CONTACT_USER_CHANGED,
    refetch,
    result: data,
  });

  return {
    data,
    loading,
    refetch,
  };
};

// getIdFromProps :: Object -> Object
const getIdFromProps = R.compose(R.objOf('id'), R.prop('contactId'));

export const useContactDetailsSubscription = () => {
  const { contactId } = useRouterParams(['contactId']);

  const { restProps } = useEntitySubscriptionQuery({
    gqlQuery: CONTACT_DETAILS,
    gqlSubscription: CONTACT_USER_CHANGED,
    queryEndpointName: 'contactUser',
    subscriptionEndpointName: 'userChanged',
    queryParamsFromProps: getIdFromProps,
    subscriptionParamsFromProps: getIdFromProps,
    propsOfComponent: { contactId },
    skipQuery: !contactId,
    alias: 'useContactDetailsSubscription',
  });

  const debouncedRefetchQuery = debounce(1000)(() =>
    tryCallFunction(restProps.refetch)(),
  );

  useEffect(() => {
    debouncedRefetchQuery();
  }, [restProps]);

  return {
    result: restProps,
  };
};

export const useDefaultUser = (value) => {
  const { data, loading } = useQuery(DEFAULT_USER_QUERY, {
    variables: { id: value },
    skip: !value || value === ALL,
  });

  return {
    data,
    loading,
  };
};

export const useUsersBySearchForOptions = ({
  query,
  pagination,
  searchTerm,
  skipQuery,
}) => {
  const { result, loading } = useEntitiesByReactiveSearch({
    gqlSearchQuery: USERS_BY_SEARCH_FOR_OPTIONS,
    gqlSearchChangedQuery: USER_SEARCH_CHANGED,
    query,
    pagination,
    searchText: searchTerm,
    skipQuery,
  });

  return {
    result,
    loading,
  };
};

export const useUsersEmailsMailTo = ({
  query,
  pagination,
  skipQuery,
  searchText = '',
}) => {
  const { result, loading } = useEntitiesByReactiveSearch({
    gqlSearchQuery: USERS_EMAILS,
    gqlSearchChangedQuery: USER_SEARCH_CHANGED,
    query,
    pagination,
    skipQuery,
    alias: 'useUsersEmailsMailTo',
    searchText,
  });

  return {
    result,
    loading,
  };
};
