import { UserEmployeeInfoStatus, UserStatuses } from '@poly/constants';
import { defaultTheme } from '@poly/admin-book';

const {
  colors: {
    statuses: { active, inactive, blocked, pending },
  },
} = defaultTheme;

export const systemAccessStatusesColors = {
  [UserStatuses.ACTIVE]: active,
  [UserStatuses.INACTIVE]: inactive,
};

export const employeeStatusColors = {
  [UserEmployeeInfoStatus.ACTIVE]: active,
  [UserEmployeeInfoStatus.INACTIVE]: blocked,
  [UserEmployeeInfoStatus.LEAVE]: pending,
};

export const rateTypes = {
  HOURLY: 'hourly',
  WEEKLY: 'weekly',
};

export const rateTypesUI = {
  [rateTypes.HOURLY]: 'Hourly',
  [rateTypes.WEEKLY]: 'Salary',
};

export const maritalStatuses = {
  MARRIED: 'married',
  SINGLE: 'single',
};

export const genders = {
  MALE: 'male',
  FEMALE: 'female',
};
