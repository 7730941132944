import React, { useCallback } from 'react';
import { BaseSidebarLayout, entities } from '@poly/admin-ui';
import { useOutSidebarContext } from '@poly/client-utils';
import { debounce } from '@poly/utils';

import { editClientUserFormId } from '../forms/constants.js';
import { EditClientUserForm } from '../forms/EditClientUserForm.js';
import { useOnCloseFormSidebar } from '../../useOnCloseFormSidebar.js';

export const commonClientUserSidebarProps = {
  width: 700,
  alwaysFirst: true,
  Layout: BaseSidebarLayout,
};

export const useOpenUpdateClientUser = (
  clientId,
  isCard,
  isAssetScannerEnabled,
  isClientPortalEnabled,
) => {
  const { openOutSidebar, closeOutSidebar } = useOutSidebarContext();
  const onCloseFormSidebar = useOnCloseFormSidebar(isCard);

  const onCloseFormSidebarDebounced = useCallback(
    debounce(1000)(onCloseFormSidebar),
    [onCloseFormSidebar, isCard],
  );

  const onCancel = () => {
    closeOutSidebar(editClientUserFormId);
    onCloseFormSidebarDebounced(entities.CLIENT, clientId);
  };

  return (userId) => {
    openOutSidebar({
      ...commonClientUserSidebarProps,
      id: editClientUserFormId,
      content: (
        <EditClientUserForm
          userId={userId}
          clientId={clientId}
          onCancel={onCancel}
          isAssetScannerEnabled={isAssetScannerEnabled}
          isClientPortalEnabled={isClientPortalEnabled}
        />
      ),
    });
  };
};
