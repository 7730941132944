import React from 'react';
import { bool, shape } from 'prop-types';
import { MoneyInputAsCents } from '@poly/admin-ui';

import { isEditInvoiceFormDisabled } from '../helpers.js';

export function HourlyRateInput({ formData, ...props }) {
  return (
    <MoneyInputAsCents
      {...props}
      disabled={isEditInvoiceFormDisabled({ formData })}
    />
  );
}

HourlyRateInput.propTypes = {
  formData: shape({ isFormDisabled: bool }),
};
