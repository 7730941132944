import * as R from 'ramda';
import { string } from 'prop-types';
import { gql } from '@apollo/client';
import React, { useMemo, useState } from 'react';
import { useTableInfiniteScrollQuery } from '@poly/client-utils';
import {
  WISCONSIN_STATE_SHORTNAME,
  NEW_YORK_STATE_SHORTNAME,
} from '@poly/constants';

import { SalesTaxReportHeader } from './SalesTaxReportHeader.js';
import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { SalesTaxReportTableContainer } from './SalesTaxReportTable.js';
import { generateSalesTaxReportQueryByState } from './salesTaxReportUtils.js';

// eslint-disable-next-line import/no-unused-modules
export const salesTaxReportQuery = gql`
  query salesTaxReportQuery($input: SalesTaxReportInput!) {
    salesTaxReport(input: $input) {
      hits {
        _id
        state
        number
        invoiceDate
        amount
        markupAmount
        taxAmount
        lines {
          total
        }
        project {
          _id
          projectId
          type
          property {
            address {
              address_parts {
                postal_code
                postal_code_suffix
              }
            }
          }
        }
        client {
          _id
          nickName
        }
      }
      total
    }
  }
`;

// prepareReport :: { salesTaxReport: SalesTaxReportResult } -> [ClientInvoice]
const prepareReport = R.compose(
  R.uniqBy(R.prop('_id')),
  R.pathOr([], ['salesTaxReport', 'hits']),
  R.defaultTo({}),
);

function SalesTaxReportPage({ state }) {
  const [filters, setFilters] = useState(null);

  const query = useMemo(
    () => generateSalesTaxReportQueryByState(filters),
    [filters],
  );

  const { data, loading, tableProps } = useTableInfiniteScrollQuery(
    salesTaxReportQuery,
    query,
    {
      endpointName: 'salesTaxReport',
      pageSize: 50,
      skip: !query?.state,
    },
  );

  const report = useMemo(() => prepareReport(data), [data]);

  return (
    <PageWithSearchHeader headerHeight="110px">
      <SalesTaxReportHeader
        state={state}
        filters={filters}
        setFilters={setFilters}
        exportProps={{ report, query }}
        loading={loading}
      />
      <SalesTaxReportTableContainer
        {...{ state, report, loading, ...tableProps }}
      />
    </PageWithSearchHeader>
  );
}

SalesTaxReportPage.propTypes = { state: string };

export function WisconsinSalesTaxReportPage() {
  return <SalesTaxReportPage state={WISCONSIN_STATE_SHORTNAME} />;
}

export function NewYorkSalesTaxReportPage() {
  return <SalesTaxReportPage state={NEW_YORK_STATE_SHORTNAME} />;
}

export function SalesTaxReportByStatePage() {
  return <SalesTaxReportPage />;
}
