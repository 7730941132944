import React from 'react';
import styled from 'styled-components';
import { ESFM_INFO } from '@poly/constants';

const ContactUsC = styled.div`
  font-family: TTNormsMedium, FFMarkWebProNarrowBook, sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #697788;
`;

export function SupplierAttachInvoiceContactUs() {
  const value = `If you have any questions you can reach AP support at ${ESFM_INFO.telephone}`;
  return <ContactUsC>{value}</ContactUsC>;
}
