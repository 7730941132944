import React from 'react';
import { bool } from 'prop-types';

import { AttachDocumentField } from '../../../../components/AttachDocumentField.js';
import { isEditInvoiceFormDisabled } from '../helpers.js';

export function AttachInvoiceFile(props) {
  const { showLabel } = props;

  const isReadOnly = isEditInvoiceFormDisabled(props);

  return (
    <AttachDocumentField
      {...props}
      showLabel={isReadOnly ? false : showLabel}
      readOnly={isReadOnly}
    />
  );
}

AttachInvoiceFile.propTypes = {
  showLabel: bool,
};
