import React from 'react';
import { number, string, bool } from 'prop-types';

import { TextBlock, ItemLabelText, TotalItemContainer } from '../styles.js';

export function TotalItemComp({
  href,
  label,
  total,
  color,
  margin,
  padding,
  isStyled,
}) {
  return (
    <TotalItemContainer {...{ margin, padding, href }}>
      <ItemLabelText>{label}</ItemLabelText>
      <TextBlock {...{ href, color }} modify={isStyled ? 'counter' : 'link'}>
        {total || 0}
      </TextBlock>
    </TotalItemContainer>
  );
}

TotalItemComp.propTypes = {
  isStyled: bool,
  total: number,
  color: string,
  margin: string,
  padding: string,
  label: string.isRequired,
  href: string.isRequired,
};

TotalItemComp.defaultProps = { total: 0 };
