import React from 'react';
import { string, objectOf, number } from 'prop-types';
import { TableSearchInput } from '@poly/admin-ui';
import {
  PageHeaderContainer,
  HeaderLinksWrapper,
  MainHeader,
} from '@poly/admin-book';

import {
  projectsQueries,
  projectsTabsConfig,
} from '../../modules/tabs/common.js';
import { PageTabs } from '../../modules/tabs/TabsWithRouter.js';
import { MyProjectsTab } from '../../modules/tabs/projectsTab/ProjectsTab.js';
import { MyProjectsTabTitle } from '../../modules/tabs/projectsTab/ProjectsTabTitle.js';
import { ToolBarBtnDivider } from '../../modules/core/tableToolbar/tableToolBarStyles.js';
import { MyProjectsPrintPDFBtn } from '../../modules/tables/myProjectsTable/MyProjectsTableToolBar.js';
import { MyProjectsTableExportToXLSBtn } from '../../modules/core/tableToolbar/TableExportToXLSBtn.js';
import { useMyProjectsQueryWorker } from './useMyProjectsQueryWorker.js';

function MyProjectsTabTitleComp({ status, myProjectsCountsData, ...props }) {
  const count = myProjectsCountsData[status] || 0;

  return <MyProjectsTabTitle {...props} status={status} count={count} />;
}

MyProjectsTabTitleComp.propTypes = {
  status: string.isRequired,
  myProjectsCountsData: objectOf(number).isRequired,
};

// getMyProjectsTabs :: Object -> [[Node, String, Node]]
const getMyProjectsTabs = (myProjectsCountsData) =>
  projectsTabsConfig.map(([status, name]) => [
    <MyProjectsTabTitleComp
      key={status}
      {...{ name, status, myProjectsCountsData }}
    />,
    name,
    <MyProjectsTab key={status} query={projectsQueries[status]} />,
  ]);

export function MyProjectsPage() {
  const myProjectsCountsData = useMyProjectsQueryWorker();

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>My Projects</MainHeader>
        <HeaderLinksWrapper>
          <MyProjectsPrintPDFBtn />
          <ToolBarBtnDivider />
          <MyProjectsTableExportToXLSBtn />
        </HeaderLinksWrapper>
      </PageHeaderContainer>
      <PageTabs
        withPips
        toolBar={<TableSearchInput />}
        defaultValue={projectsTabsConfig[0][1]}
        tabs={getMyProjectsTabs(myProjectsCountsData)}
      />
    </>
  );
}
