import { gql } from '@apollo/client';

export const basicTaskFields = gql`
  fragment basicTaskFields on Task {
    _id
    dueDate
    description
    complete
    priority
  }
`;

export const commonTaskDocumentFields = gql`
  fragment commonTaskDocumentFields on TaskDocument {
    project {
      _id
      type
      notRM
      endDate
      startDate
      description
      clientGLCode {
        _id
      }
      subCategory {
        _id
      }
      projectId
      client {
        _id
        name
        nickName
        enableGlCodes
        clientManagers {
          _id
          email
        }
        configs {
          notifications {
            projectClientStatusUpdateDefaultMailTo {
              toSiteContact
              toRequester
              toClientManager
            }
          }
        }
      }
      clientManager {
        _id
        email
      }
      contact {
        _id
        email
      }
      siteContact {
        _id
        email
      }
      property {
        _id
        name
      }
      weeklyServiceTickets {
        status
      }
      parent {
        _id
      }
    }
    recurringProject {
      _id
      description
      projectId
      client {
        _id
        name
      }
      property {
        _id
        name
      }
    }
    client {
      _id
      name
    }
    invoice {
      _id
    }
    property {
      _id
      name
      client {
        _id
        name
      }
    }
    supplier {
      _id
      company {
        name
      }
    }
    masterSupplier {
      _id
      company {
        name
      }
    }
    task {
      _id
    }
  }
`;
