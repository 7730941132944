import React from 'react';
import { Link } from '@poly/client-routing';
import * as R from 'ramda';
import { SupplierStatuses } from '@poly/constants';
import { string, oneOfType, object } from 'prop-types';
import { EntityStatisticsS } from '../entitiesStatisticsStyles.js';
import { findCountByStatus } from '../entitiesStatisticsUtils.js';
import { routesNames } from '../../../routes/index.js';

const { SUPPLIERS_ADMINISTRATIVE_AAC } = routesNames;
const { ACTIVE, BLOCKED } = SupplierStatuses;

function AdministrativeStatisticsLink({ tab, ...restProps }) {
  const href = `${SUPPLIERS_ADMINISTRATIVE_AAC}?tab=${tab}`;

  return <Link {...restProps} href={href} />;
}

AdministrativeStatisticsLink.propTypes = {
  tab: string,
};

// getAdministrativeSuppliersCountByStatuses :: AccountIndex -> [SuppliersStatusCount]
const getAdministrativeSuppliersCountByStatuses = R.pathOr(
  [],
  ['aacSuppliersAdministrative', 'countByStatuses'],
);

export function AdministrativeStatistics({ adminAccountIndex, ...restProps }) {
  const countByStatuses =
    getAdministrativeSuppliersCountByStatuses(adminAccountIndex);

  const items = [
    [
      'Active',
      <AdministrativeStatisticsLink
        tab={SupplierStatuses.ACTIVE}
        key={`administrative-${SupplierStatuses.ACTIVE}`}
      >
        {findCountByStatus(ACTIVE, countByStatuses)}
      </AdministrativeStatisticsLink>,
    ],
    [
      'Blocked',
      <AdministrativeStatisticsLink
        tab={SupplierStatuses.BLOCKED}
        key={`administrative-${SupplierStatuses.BLOCKED}`}
      >
        {findCountByStatus(BLOCKED, countByStatuses)}
      </AdministrativeStatisticsLink>,
    ],
  ];

  return (
    <EntityStatisticsS {...restProps} header="Administrative" items={items} />
  );
}

AdministrativeStatistics.displayName = 'AdministrativeStatistics';

AdministrativeStatistics.propTypes = {
  adminAccountIndex: oneOfType([object]),
};
