import React from 'react';
import styled from 'styled-components';
import { arrayOf } from 'prop-types';

import {
  batchSpendReportTableConfig,
  BatchSpendReportTableS,
  BatchSpendReportCollapse,
  BatchSpendReportTable,
  batchSpendReportPropType,
} from './BatchSpendReportTable.js';
import { TableHeaderC } from '../../modules/tables/commonTableComps.js';
import { SpendReportHierarchyWrapper } from '../SpendReport/spendReportsTable/SpendReportHierarchyTree.js';
import { SpendReportsHierarchyTreeTable } from '../SpendReport/spendReportsTable/SpendReportsHierarchyTreeTable.js';

const BatchSpendReportEmptyTableS = styled(BatchSpendReportTableS)`
  height: 45px;
`;

export function BatchSpendReportHierarchyTable({ reports }) {
  const tableHeaders = batchSpendReportTableConfig.map(([title]) => ({
    title,
  }));

  return (
    <>
      <BatchSpendReportEmptyTableS>
        <TableHeaderC headers={tableHeaders} />
      </BatchSpendReportEmptyTableS>
      <SpendReportHierarchyWrapper>
        {reports.map((report) => (
          <SpendReportsHierarchyTreeTable
            key={report.name}
            report={report}
            level={0}
            TableComponent={BatchSpendReportTable}
            Collapse={BatchSpendReportCollapse}
            showNoHierarchyTotal
          />
        ))}
      </SpendReportHierarchyWrapper>
    </>
  );
}

BatchSpendReportHierarchyTable.propTypes = {
  reports: arrayOf(batchSpendReportPropType),
};
