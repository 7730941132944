import * as R from 'ramda';
import { string, bool } from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import { CommonPrintLayout } from '@poly/admin-ui';
import { PRINT_PDF_CAPTION } from '@poly/constants';

import {
  formatFromToDateTitle,
  generateFileNameByTitle,
  openPrintWindowWithData,
} from '@poly/client-utils';
import { Button } from '@poly/admin-book';

import { BankRegistersTable } from './BankRegistersTable.js';
import {
  bankRegistersQueryPropType,
  bankRegistersQueryDataPropType,
} from './commonPropTypes.js';

const AdditionalInfoWrapper = styled.div`
  text-align: center;
`;

const BankRegistersTablePDF = styled(BankRegistersTable)`
  th:last-child,
  td:last-child {
    // this fixes 30px vertical cut at last table column.
    // bug is reproduced at Google Chrome 78, but is not
    // reproduced at Chromium 81.0, so when Google Chrome gets
    // updated this fix can be potentially removed
    padding-right: 30px;
  }
`;

const ExportButton = styled(Button)`
  padding: 3px 14px;
  margin-right: 15px;
  opacity: inherit;
`;

export function BankRegistersExportPDFButton({
  query,
  exportTitle,
  hideCheckNumber,
  data,
  disabled,
}) {
  const exportPdf = async () => {
    const actualAccountName = R.path(['getSingleAccount', 'name'], data);

    const dateRangeStr = formatFromToDateTitle(query.fromDate, query.toDate);

    await openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: BankRegistersTablePDF,
      fileName: generateFileNameByTitle(exportTitle),
      metaData: {
        title: exportTitle,
        additional: (
          <>
            <AdditionalInfoWrapper>{actualAccountName}</AdditionalInfoWrapper>
            <AdditionalInfoWrapper>{dateRangeStr}</AdditionalInfoWrapper>
          </>
        ),
      },
      data,
      hideCheckNumber,
      searchTerm: query.searchTerm,
      accountId: query.accountId,
    });
  };

  return (
    <ExportButton
      disabled={disabled}
      size="tiny"
      styleType="primaryLight"
      onClick={exportPdf}
    >
      {PRINT_PDF_CAPTION}
    </ExportButton>
  );
}

BankRegistersExportPDFButton.propTypes = {
  query: bankRegistersQueryPropType,
  hideCheckNumber: bool,
  exportTitle: string.isRequired,
  disabled: bool,
  data: bankRegistersQueryDataPropType,
};
