import * as R from 'ramda';
import styled from 'styled-components';
import React from 'react';
import { string, shape, bool, object, arrayOf, func } from 'prop-types';
import { Popover, Text, getThemeColor } from '@poly/admin-book';
import {
  getUserCellPhoneObject,
  getUserFaxPhoneObject,
  getUserWorkPhoneObject,
  getUserCellPhoneNumber,
  isNilOrEmpty,
} from '@poly/utils';
import { NOTHING_UI_STRING } from '@poly/constants';

import { EmailLink, PhoneLink } from './Links.js';

const PopoverS = styled(Popover)`
  button:first-child {
    padding-left: 0;
    font-size: 12px;
  }

  > div > div > div {
    width: 100%;
    padding: 5px 15px 0 15px;

    &:last-child {
      padding-bottom: 5px;
    }
  }
`;

const SubTitle = styled(Text).attrs(() => ({ size: '12px' }))`
  padding-bottom: 5px !important;
  border-bottom: 1px solid ${getThemeColor(['primaryLighter7'])};
`;

const LinkWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  padding: 2px 0;

  a {
    font-size: 12px;
    line-height: 18px;
    padding-right: 5px;
  }
`;

const HoveredLinkWrapper = styled(LinkWrapper)`
  &:hover {
    background-color: ${getThemeColor(['secondaryLighter2'])};
    transition: background-color 0.1s linear;
  }
`;

const Underline = styled.div`
  border-bottom: 1px solid ${getThemeColor(['primaryLighter7'])};
  margin: 3px 0;
  width: 135px;
`;

function EmailLinkS({ Wrapper, ...props }) {
  return (
    <Wrapper>
      <EmailLink {...props} />
    </Wrapper>
  );
}

EmailLinkS.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  Wrapper: object.isRequired,
};

const phoneLinkPropTypes = {
  number: string,
  type: string,
  ext: string,
};

function PhoneLinkS({ number, ext, type }) {
  return (
    <LinkWrapper key={number + type}>
      <PhoneLink {...{ number, ext }} />
      <Text size="12px">{R.concat(' - ', type)}</Text>
    </LinkWrapper>
  );
}

PhoneLinkS.propTypes = phoneLinkPropTypes;

function HoveredPhoneLinkS({ number, ext, type }) {
  return (
    <HoveredLinkWrapper key={number + type}>
      <PhoneLink {...{ number, ext }} />
      <Text size="12px">{R.concat(' - ', type)}</Text>
    </HoveredLinkWrapper>
  );
}

HoveredPhoneLinkS.propTypes = phoneLinkPropTypes;

// getUserLoginCellPhoneObject :: User -> { number: String }
const getUserLoginCellPhoneObject = R.when(
  R.compose(R.isNil, getUserCellPhoneNumber),
  R.applySpec({
    number: R.path(['profile', 'loginCellPhoneNumber']),
  }),
);

// prepareUserPhones :: User -> [{ number: String, ext: String }]
const prepareUserPhones = R.compose(
  R.reject(R.propSatisfies(isNilOrEmpty, 'number')),
  R.juxt([
    R.compose(R.mergeRight({ type: 'Mobile' }), getUserCellPhoneObject),
    R.compose(R.mergeRight({ type: 'Mobile' }), getUserLoginCellPhoneObject),
    R.compose(R.mergeRight({ type: 'Office' }), getUserWorkPhoneObject),
    R.compose(R.mergeRight({ type: 'Fax' }), getUserFaxPhoneObject),
  ]),
);

function UserInfo({ email, fullName, userPhones }) {
  return (
    <>
      <Text size="12px" lineHeight="18px">
        {fullName}
      </Text>
      <EmailLinkS {...{ email }} Wrapper={LinkWrapper} />
      {!!userPhones && userPhones.map(PhoneLinkS)}
    </>
  );
}

UserInfo.propTypes = {
  userPhones: arrayOf(shape(phoneLinkPropTypes)),
  fullName: string,
  email: string,
};

const userPropTypes = {
  profile: shape({
    cellPhoneNumber: string,
    workPhoneNumber: string,
    workPhoneNumberExt: string,
    faxPhoneNumber: string,
  }),
  fullName: string,
  email: string,
};

export function InfoPopover({
  title,
  email,
  profile,
  fullName,
  position,
  PopoverParent,
  isPopover = true,
}) {
  const userPhones = prepareUserPhones({ profile });

  return isPopover ? (
    <PopoverS
      {...{ position }}
      title={fullName}
      PopoverParent={PopoverParent}
      content={
        <>
          <Text size="12px" weight="semibold">
            {fullName}
          </Text>
          <SubTitle>{title}</SubTitle>
          <EmailLinkS {...{ email }} Wrapper={HoveredLinkWrapper} />
          {!!userPhones && userPhones.map(HoveredPhoneLinkS)}
        </>
      }
    />
  ) : (
    <UserInfo {...{ email, fullName, userPhones }} />
  );
}

InfoPopover.propTypes = {
  ...userPropTypes,
  position: string,
  title: string,
  isPopover: bool,
  PopoverParent: func,
};

export function SiteContactInfo({ propertyPhone, user }) {
  const userPhones = prepareUserPhones({ profile: R.prop('profile')(user) });

  if (!propertyPhone && !user) {
    return (
      <Text size="12px" lineHeight="18px">
        {NOTHING_UI_STRING}
      </Text>
    );
  }

  return (
    <>
      {!!propertyPhone && <PhoneLinkS number={propertyPhone} type="Branch" />}
      {!!user && (
        <>
          {!!propertyPhone && <Underline />}
          <UserInfo {...{ userPhones, ...user }} />
        </>
      )}
    </>
  );
}

SiteContactInfo.propTypes = {
  propertyPhone: string,
  user: shape(userPropTypes),
};
