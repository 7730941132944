import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { LinkButton } from '@poly/admin-book';
import { func, number, shape, string } from 'prop-types';

import { DEFAULT_STEP } from './constants.js';
import { ProcedureStepLine } from './ProcedureStepLine.js';
import {
  ProcedureStepConfigurationTitle,
  ProcedureStepConfigurationLineWrapper,
} from './components.js';

export function ProcedureStepConfigurationLine({
  name,
  index,
  fields: { push, remove, update, value },
}) {
  const isFirstLine = index === 0;
  const skipDeleteBtn = index === 1 && value.length === 2;

  const onAddStep = (e) => {
    e.preventDefault();
    push(DEFAULT_STEP);
  };

  const onRemoveStep = (e) => {
    e.preventDefault();
    remove(index);
  };

  const onIsRequiredStepChange = ({ currentValue, value: stepValue }) => {
    update(index, {
      ...stepValue,
      isRequiredStep: currentValue,
      ...(currentValue ? {} : { isImageUploadRequired: false }),
    });
  };

  return (
    <ProcedureStepConfigurationLineWrapper>
      {isFirstLine && (
        <ProcedureStepConfigurationTitle>
          Step Configuration
          <LinkButton onClick={onAddStep}>Add Step</LinkButton>
        </ProcedureStepConfigurationTitle>
      )}
      {!isFirstLine && (
        <FieldArray name={name}>
          {(props) => (
            <ProcedureStepLine
              {...props}
              onRemoveStep={onRemoveStep}
              skipDeleteBtn={skipDeleteBtn}
              onIsRequiredStepChange={onIsRequiredStepChange}
            />
          )}
        </FieldArray>
      )}
    </ProcedureStepConfigurationLineWrapper>
  );
}

ProcedureStepConfigurationLine.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  fields: shape({
    push: func.isRequired,
  }),
};
