import * as R from 'ramda';
import React, { useEffect } from 'react';
import { bool, object, oneOfType, string, func } from 'prop-types';
import {
  highlightedTextPropType,
  SidebarWidth,
  useNotificationState,
} from '@poly/admin-ui';
import { READ_SENSITIVE_FIELD_USER_PERMISSION } from '@poly/security';
import { useUpdateQueryParams } from '@poly/client-routing';
import {
  useHasUserAccessWithPermissionBase,
  useOutSidebarContext,
} from '@poly/client-utils';
import { propEqLegacy } from '@poly/utils';

import { SidebarIDs } from '../constants.js';
import { UserSidebar } from './UserSidebar.js';
import { LinkToSidebar } from '../../components/LinkToSidebar.js';
import { useClearPristineState } from '../useClearPristineOnSidebarMount.js';
import { useUserAccessRoleQuery } from './useUserAccessRoleQuery.js';

const useViewUserByPermissionLogic = () => {
  const { showErrorNotification } = useNotificationState();
  const [hasViewPermission, loading] = useHasUserAccessWithPermissionBase(
    READ_SENSITIVE_FIELD_USER_PERMISSION,
  );

  const onReject = () =>
    showErrorNotification("You don't have permission to view user");

  return { loading, hasViewPermission, onReject };
};

export function UserLink({
  _id,
  name,
  onClick,
  inNewTab,
  children,
  className,
  queryParams = {},
}) {
  const { hasViewPermission, onReject } = useViewUserByPermissionLogic();

  const linkParams = R.mergeDeepLeft(
    { query: { [SidebarIDs.user]: _id } },
    queryParams,
  );

  const fullLinkProps = {
    onClick,
    inNewTab,
    className,
    linkParams,
    ...(hasViewPermission ? {} : { onReject }),
  };

  return <LinkToSidebar {...fullLinkProps}>{children || name}</LinkToSidebar>;
}

UserLink.propTypes = {
  onClick: func,
  inNewTab: bool,
  // eslint-disable-next-line react/forbid-prop-types
  queryParams: object,
  _id: string.isRequired,
  name: highlightedTextPropType.isRequired,
  children: oneOfType([string, object]),
  className: oneOfType([string, object]),
};

export const useNavigateToUserSidebar = () => {
  const updateQueryParams = useUpdateQueryParams();
  const clearPristine = useClearPristineState();
  return (userId) => {
    clearPristine();
    updateQueryParams({
      [SidebarIDs.user]: userId,
    });
  };
};

// checkIsUserReady :: Object -> Boolean
const checkIsUserReady = R.allPass([
  R.prop('userId'),
  propEqLegacy('loading', false),
  propEqLegacy('hasPermission', true),
]);

export const useOpenUserSidebarByPermission = (userId, hasPermission) => {
  const { isSidebarOpened, closeAllOutSidebars, openOutSidebar } =
    useOutSidebarContext();
  const { onReject, loading, hasViewPermission } =
    useViewUserByPermissionLogic();
  const checkIsContactUser = useUserAccessRoleQuery();
  const updateQueryParams = useUpdateQueryParams();

  const isUserOpened = isSidebarOpened(SidebarIDs.user);
  const isUserReady = checkIsUserReady({ userId, hasPermission, loading });

  useEffect(() => {
    if (isUserReady && !isUserOpened) {
      if (hasViewPermission) {
        const onSuccess = async () => {
          const isContactUser = await checkIsContactUser(userId);

          if (isContactUser) {
            updateQueryParams({
              [SidebarIDs.contact]: userId,
              [SidebarIDs.user]: undefined,
            });
          } else {
            openOutSidebar({
              alwaysFirst: true,
              width: SidebarWidth,
              id: SidebarIDs.user,
              content: <UserSidebar {...{ userId }} />,
            });
          }
        };

        onSuccess();
      } else {
        onReject();
      }
    }

    if (!userId && isUserOpened) {
      closeAllOutSidebars();
    }
  }, [userId, loading, hasViewPermission, hasPermission]);
};
