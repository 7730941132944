import styled from 'styled-components';
import { string, func } from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';

import { Loader } from '../Loader/index.js';
import { Button } from '../Button/index.js';

function DownloadLink({ value }) {
  return (
    <a href={value} target="_blank" rel="noopener noreferrer" download>
      Download
    </a>
  );
}

DownloadLink.propTypes = {
  value: string.isRequired,
};

const IFrameS = styled.iframe``;

const ErrorMessage = styled.div`
  padding: 10px;
  justify-content: space-between;
  & > div {
    margin-top: 10px;
    button {
      margin-left: 10px;
    }
  }
`;

function FilePreviewError({ url, onResetAttempts }) {
  return (
    <ErrorMessage>
      <span>Something went wrong while loading file</span>
      <div>
        <DownloadLink value={url} />
        <Button onClick={onResetAttempts}>Try Again</Button>
      </div>
    </ErrorMessage>
  );
}

FilePreviewError.propTypes = {
  url: string.isRequired,
  onResetAttempts: func.isRequired,
};

const ViewerContainer = styled.div`
  position: relative;
  overflow: auto;
`;

function PdfViewer({ url, fileType, className }) {
  const iframeRef = useRef(null);
  const reloadTimeout = useRef(null);
  const [attempts, setAttempts] = useState(5);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isIFrameActive, setIsIFrameActive] = useState(false);

  const isError = !isLoaded && attempts < 1;
  const isLoading = !isLoaded && attempts >= 1;

  const tryToLoad = (attempt) => {
    if (isLoaded || attempt < 1) return;
    if (iframeRef.current) {
      iframeRef.current.src = url;
    }
    setAttempts(attempt - 1);
    reloadTimeout.current = setTimeout(() => tryToLoad(attempt - 1), 5000);
  };

  const onResetAttempts = () => {
    setAttempts(5);
    tryToLoad(5);
  };

  const onLoad = () => {
    setIsLoaded(true);

    if (reloadTimeout.current) clearTimeout(reloadTimeout.current);
  };

  useEffect(() => {
    tryToLoad(attempts);

    return () => {
      clearTimeout(reloadTimeout);
    };
  }, []);

  useEffect(() => {
    if (isLoaded && iframeRef.current.src !== url) {
      iframeRef.current.src = url;
    }
  }, [url, isLoaded, iframeRef]);

  const onMouseMove = (event) => {
    // "buttons" prop responsible for mouse left button
    if (!isIFrameActive && event.buttons === 0) {
      setIsIFrameActive(true);
    }
  };

  const onMouseLeave = () => setIsIFrameActive(false);

  return (
    <ViewerContainer
      tabIndex={0}
      className={className}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
    >
      {isLoading ? <Loader style={{ height: '100%' }} /> : null}
      {isError && (
        <FilePreviewError url={url} onResetAttempts={onResetAttempts} />
      )}
      <IFrameS
        width="100%"
        ref={iframeRef}
        type={fileType}
        onLoad={onLoad}
        title="File Viewer"
        height={isLoading || isError ? '0%' : '99%'}
        style={{
          flexGrow: 1,
          border: 'none',
          opacity: isLoading || isError ? 0 : 1,
          pointerEvents: isIFrameActive ? 'auto' : 'none',
        }}
      />
    </ViewerContainer>
  );
}

PdfViewer.propTypes = {
  fileType: string,
  className: string,
  url: string.isRequired,
};

function ImageViewer({ url, className, width }) {
  return (
    <ViewerContainer className={className}>
      <img alt="icon" src={url} width={width} />
    </ViewerContainer>
  );
}

ImageViewer.propTypes = {
  className: string,
  url: string,
  width: string,
};

ImageViewer.defaultProps = {
  className: '',
  url: '',
  width: '100%',
};

export function FileViewer({ fileType, ...props }) {
  return fileType.includes('image') ? (
    <ImageViewer {...props} fileType={fileType} />
  ) : (
    <PdfViewer {...props} fileType={fileType} />
  );
}

FileViewer.propTypes = {
  fileType: string.isRequired,
};
