import {
  func,
  bool,
  array,
  shape,
  number,
  object,
  string,
  arrayOf,
  oneOfType,
  instanceOf,
} from 'prop-types';
import React from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { UPDATE_PERSIST_KEY_PREFIX, taskActionTypes } from '@poly/constants';
import { FormCreator } from '@poly/admin-book';
import {
  mapCreateUpdateWithActionInputToUpdate,
  useOnSubmitSetStopSubmitting,
  mapCreateUpdateInputToUpdate,
  prependNewEntityUpdate,
  updateCacheHandler,
  showSuccess,
} from '@poly/admin-ui';
import { usePersistDnDAreaWithMentions } from '@poly/client-utils';
import { useCurrentUserByStoreOrQuery } from '@poly/client-utils/src/hooks/useCurrentUserByStoreOrQuery.js';

import { commonModalLayout } from '../common.js';
import { projectUpdateSections } from './sections.js';
import { useUpdateTask } from '../../core/hooks/tasks/useUpdateTask.js';
import { isDefaultTask } from '../../core/utils/projects.js';
import {
  prepareRecallProjectDataToMutation,
  prepareProjectTaskDataToMutation,
  getProjectUpdateInitValues,
  prepareUpdateData,
} from './addProjectUpdateUtils.js';
import {
  useCreateUpdateWithAction,
  useAddNewUpdate,
} from '../../core/hooks/updates/index.js';

const appendNewProjectUpdate = prependNewEntityUpdate('project');

export function AddProjectUpdateForm({
  updateCacheParams,
  project = {},
  formId,
  onCancel,
  projectId,
  projectTask,
  updateType: updateTypeFromProps,
}) {
  const {
    onChangePersistentValue,
    cleanupRetainedValue,
    retainedValue: details,
  } = usePersistDnDAreaWithMentions(
    `${UPDATE_PERSIST_KEY_PREFIX}${project._id}`,
  );

  const dispatch = useDispatch();

  const { user } = useCurrentUserByStoreOrQuery();

  const { createUpdateWithAction } = useCreateUpdateWithAction();
  const { addNewUpdate } = useAddNewUpdate();
  const { updateTask } = useUpdateTask();

  const mutate = ({ updateType, ...values }) => {
    const projectUpdateCacheHandler = (newUpdate) =>
      updateCacheHandler(
        updateCacheParams.query,
        updateCacheParams.variables,
        appendNewProjectUpdate(newUpdate),
      );
    if (updateType === taskActionTypes.RECALL_PROJECT) {
      const mutationInput = prepareRecallProjectDataToMutation(values);
      return createUpdateWithAction({
        variables: {
          input: mutationInput,
        },
        update: projectUpdateCacheHandler(
          mapCreateUpdateWithActionInputToUpdate(project.type)({
            ...mutationInput,
            updateType,
            user,
          }),
        ),
      });
    }

    if (isDefaultTask(updateType)) {
      if (!values.taskId) return null;

      const mutationInput = prepareProjectTaskDataToMutation(values);
      return updateTask({
        variables: { ...mutationInput, id: values.taskId },
        update: projectUpdateCacheHandler(
          mapCreateUpdateWithActionInputToUpdate(project.type)({
            ...mutationInput.input,
            updateType,
            user,
          }),
        ),
      });
    }
    const mutationInput = prepareUpdateData(updateType)(values);
    return addNewUpdate({
      variables: {
        input: mutationInput,
      },
      update: projectUpdateCacheHandler(
        mapCreateUpdateInputToUpdate({
          ...mutationInput,
          user,
        }),
      ),
    });
  };

  const sections = projectUpdateSections(projectId, onChangePersistentValue);

  const initialValues = getProjectUpdateInitValues({
    project,
    projectTask,
    updateType: updateTypeFromProps,
    details,
  });

  const onSubmitHandler = async (values) => {
    await mutate({ projectId, ...R.omit(['isHQProject'], values) });
    dispatch(showSuccess('Update was created'));
    cleanupRetainedValue();
    onCancel();
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  return (
    <FormCreator
      id={formId}
      successMessage="Update was created"
      sections={sections}
      layout={commonModalLayout}
      initialValues={initialValues}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
}

AddProjectUpdateForm.displayName = 'AddProjectUpdateForm';

AddProjectUpdateForm.propTypes = {
  updateCacheParams: shape({
    query: shape({
      kind: string,
      definitions: arrayOf(
        shape({
          kind: string,
          name: shape({
            kind: string,
            value: string,
          }),
          operation: string,
        }),
      ),
    }).isRequired,
    variables: shape({
      id: string,
      documentUpdatesInput: shape({
        from: number,
        size: number,
        includeAudit: bool,
      }),
    }),
  }),
  project: oneOfType([array, string, number, object, instanceOf(Date)])
    .isRequired,
  formId: string.isRequired,
  onCancel: func.isRequired,
  projectId: string.isRequired,
  projectTask: shape({
    _id: string,
    action: string,
    complete: bool,
    documentId: string,
  }),
  updateType: string.isRequired,
};
