import React from 'react';
import * as R from 'ramda';
import { shape, string } from 'prop-types';
import {
  formatFromToDateTitle,
  openPrintWindowWithData,
} from '@poly/client-utils';
import { CommonPrintLayout } from '@poly/admin-ui';
import { PRINT_PDF_CAPTION, USAStates } from '@poly/constants';

import { SalesTaxReportTableContainer } from './SalesTaxReportTable.js';
import { SalesReportLink } from './salesTaxReportComponents.js';
import { taxReportProps } from './prop-types.js';

// getFileName :: { state: ValidSalesTaxAccountState } -> String
const getFileName = R.ifElse(
  R.propSatisfies(R.isNil, 'state'),
  R.always('all'),
  R.compose(
    R.toLower,
    R.join('_'),
    R.split(' '),
    R.prop(R.__, USAStates),
    R.prop('state'),
  ),
);

export function SalesTaxReportPrintBtn({ report, title, query }) {
  const onClick = async () => {
    await openPrintWindowWithData({
      fileName: `${getFileName(query)}_sales_tax_report`,
      Layout: CommonPrintLayout,
      Table: SalesTaxReportTableContainer,
      metaData: {
        title,
        additional: formatFromToDateTitle(query.startDate, query.endDate),
      },
      report,
      isPrint: true,
    });
  };
  return (
    <SalesReportLink onClick={onClick} disabled={R.isEmpty(report)}>
      {PRINT_PDF_CAPTION}
    </SalesReportLink>
  );
}

SalesTaxReportPrintBtn.propTypes = {
  title: string.isRequired,
  report: taxReportProps,
  query: shape({ state: string }),
};
