import React from 'react';
import { EditRecurringJournalEntryForm } from './EditRecurringJournalForm.js';
import { FormPageHeaderCreator } from '../../components/FormPageHeaderCreator.js';
import { editRecurringJournalEntryFormId } from './recurringJournalEntryHelpers.js';

export function EditRecurringJournalPage() {
  return (
    <>
      <FormPageHeaderCreator
        title="Edit Recurring Journal Entry"
        formId={editRecurringJournalEntryFormId}
      />
      <EditRecurringJournalEntryForm />
    </>
  );
}
