import React from 'react';
import { ChangePasswordPageBaseAdmin } from '@poly/admin-ui';

import { routesNames } from '../routes/constants.js';
import { APP_TITLE } from './LoginPage.js';

export function ResetPasswordPage() {
  return (
    <ChangePasswordPageBaseAdmin
      title={APP_TITLE}
      homeUrl={routesNames.HOME}
      forgotPasswordUrl={routesNames.FORGOT_PASSWORD}
    />
  );
}
