import { createAction, handleAction } from 'redux-actions';

export const setPreventativeRepairChanged = createAction(
  'SET_PREVENTATIVE_REPAIR_CHANGED_SUBSCRIPTION',
);

export const preventativeRepairChangedReducer = handleAction(
  setPreventativeRepairChanged,
  (_, { payload }) => payload,
  false,
);
