import * as R from 'ramda';
import { gql, useMutation } from '@apollo/client';
import { insertParamsIntoURL } from '@poly/utils';
import { useNavigate } from '@poly/client-routing';

import { prepareBranchSupplierToMutation } from '../branchSupplierFormUtils.js';
import { useNotificationState } from '../../../../hooks/useNotificationState.js';

const CREATE_BRANCH_SUPPLIER_MUTATION = gql`
  mutation CREATE_BRANCH_SUPPLIER_MUTATION($input: CreateBranchSupplierInput!) {
    createBranchSupplier(input: $input) {
      supplier {
        _id
      }
    }
  }
`;

export const useCreateBranchSupplierMutation = (
  supplierUrl,
  source,
  protectedFieldsAccess,
) => {
  const navigate = useNavigate();
  const { showSuccessNotification } = useNotificationState();
  const [createBranchSupplier] = useMutation(CREATE_BRANCH_SUPPLIER_MUTATION);

  return async (formData) => {
    const result = await createBranchSupplier({
      variables: {
        input: {
          ...prepareBranchSupplierToMutation(formData, protectedFieldsAccess),
          misc: { source },
        },
      },
    });
    showSuccessNotification('Branch Supplier Successfully Created');
    const supplierId = R.path(
      ['data', 'createBranchSupplier', 'supplier', '_id'],
      result,
    );
    const link = insertParamsIntoURL({ supplierId })(supplierUrl);
    return navigate(link);
  };
};
