import React from 'react';
import { WorkOrderPriority, WorkOrderStatus } from '@poly/constants';
import {
  DefaultBodyWrapper,
  MainHeader,
  PageHeaderContainer,
  TableCard,
  TableWithPaginationContainer,
} from '@poly/admin-book';
import { TablePagination } from '@poly/admin-ui/src/components/Pagination.js';

import { ProjectsPageTable } from '../modules/tables/projectsTable/ProjectsTable.js';

const query = {
  bool: {
    must: [
      { match: { status: WorkOrderStatus.ACTIVE } },
      { match: { priority: WorkOrderPriority.EMERGENCY } },
    ],
  },
};

export function EmergencyProjectsPage() {
  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Emergency Projects</MainHeader>
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <TableCard>
          <TableWithPaginationContainer paginationVisible>
            <ProjectsPageTable query={query} />
          </TableWithPaginationContainer>
          <TablePagination />
        </TableCard>
      </DefaultBodyWrapper>
    </>
  );
}
