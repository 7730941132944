import * as R from 'ramda';
import { useMutation, gql } from '@apollo/client';

const CREATE_PROCEDURE_MUTATION = gql`
  mutation CREATE_PROCEDURE_MUTATION($input: CreateEditProcedureInput!) {
    createProcedure(input: $input) {
      _id
    }
  }
`;

const EDIT_PROCEDURE_MUTATION = gql`
  mutation EDIT_PROCEDURE_MUTATION(
    $id: ID!
    $input: CreateEditProcedureInput!
  ) {
    editProcedure(id: $id, input: $input) {
      _id
    }
  }
`;

// prepareInput :: FromData -> AddProcedureInput
const prepareInput = R.applySpec({
  name: R.prop('name'),
  steps: R.compose(R.tail, R.propOr([], 'steps')),
  assetTypeIds: R.compose(R.map(R.prop('value')), R.propOr([], 'assetTypeIds')),
});

export const useAddEditProcedureMutation = () => {
  const [runCreateProcedure] = useMutation(CREATE_PROCEDURE_MUTATION);
  const [runEditProcedure] = useMutation(EDIT_PROCEDURE_MUTATION);

  const createProcedure = async ({ formData }) => {
    const input = prepareInput(formData);

    await runCreateProcedure({ variables: { input } });
  };

  const editProcedure = async ({ id, formData }) => {
    const input = prepareInput(formData);

    await runEditProcedure({ variables: { id, input } });
  };

  return { createProcedure, editProcedure };
};
