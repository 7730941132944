import React from 'react';
import styled from 'styled-components';
import { number } from 'prop-types';

import { IncomeStatementSectionTotal } from './components.js';

const FooterTableBody = styled.tbody`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export function IncomeStatementReportTableFooter({
  cogsReportsTotal,
  incomeReportsTotal,
  expenseReportsTotal,
}) {
  return (
    <FooterTableBody>
      <IncomeStatementSectionTotal
        isBold
        title="Net Profit/(Loss)"
        total={incomeReportsTotal + cogsReportsTotal + expenseReportsTotal}
      />
    </FooterTableBody>
  );
}

IncomeStatementReportTableFooter.propTypes = {
  cogsReportsTotal: number,
  incomeReportsTotal: number,
  expenseReportsTotal: number,
};
