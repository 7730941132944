import React from 'react';
import { useOutSidebarContext } from '@poly/client-utils';
import {
  sidebarWidthByTransactionType,
  transactionsDetailsSidebarId,
} from './helpers.js';
import { TransactionsDetailsSidebar } from './TransactionDetailsSidebar.js';

export const useOpenTransactionDetailsSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();

  return (transactionNumber, transactionType) =>
    openOutSidebar({
      id: transactionsDetailsSidebarId,
      content: (
        <TransactionsDetailsSidebar transactionNumber={transactionNumber} />
      ),
      width: sidebarWidthByTransactionType(transactionType),
      alwaysFirst: true,
    });
};
