import * as R from 'ramda';
import React from 'react';
import { Link } from '@poly/client-routing';
import { SupplierStatuses } from '@poly/constants';
import { oneOfType, object, string } from 'prop-types';
import { EntityStatisticsS } from '../entitiesStatisticsStyles.js';
import { findCountByStatus } from '../entitiesStatisticsUtils.js';
import { routesNames } from '../../../routes/index.js';

const { ACTIVE, BLOCKED } = SupplierStatuses;

function SubcontractorsStatisticsLink({ tab, ...restProps }) {
  const href = `${routesNames.SUPPLIERS_SUBCONTRACTORS}?tab=${tab}`;

  return <Link {...restProps} href={href} />;
}

SubcontractorsStatisticsLink.propTypes = {
  tab: string,
};

// getSubcontractorSuppliersCountByStatuses :: AccountIndex -> [SuppliersStatusCount]
const getSubcontractorSuppliersCountByStatuses = R.pathOr(
  [],
  ['aacSuppliersSubcontracts', 'countByStatuses'],
);

export function SubcontractorsStatistics({ adminAccountIndex, ...restProps }) {
  const countByStatuses =
    getSubcontractorSuppliersCountByStatuses(adminAccountIndex);

  const items = [
    [
      'Active',
      <SubcontractorsStatisticsLink
        tab={SupplierStatuses.ACTIVE}
        key={`subcontractors-${SupplierStatuses.ACTIVE}`}
      >
        {findCountByStatus(ACTIVE, countByStatuses)}
      </SubcontractorsStatisticsLink>,
    ],
    [
      'Blocked',
      <SubcontractorsStatisticsLink
        tab={SupplierStatuses.BLOCKED}
        key={`subcontractors-${SupplierStatuses.BLOCKED}`}
      >
        {findCountByStatus(BLOCKED, countByStatuses)}
      </SubcontractorsStatisticsLink>,
    ],
  ];

  return (
    <EntityStatisticsS {...restProps} header="Subcontractors" items={items} />
  );
}

SubcontractorsStatistics.displayName = 'SubcontractorsStatistics';

SubcontractorsStatistics.propTypes = {
  adminAccountIndex: oneOfType([object]),
};
