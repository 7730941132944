import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { mentionGroupStatuses, UserStatuses } from '@poly/constants';
import {
  READ_MENTION_GROUP_PERMISSION,
  RECEIVE_ALERT_PERMISSION,
  FULL_ACCESS_PERMISSION,
} from '@poly/security';

import { useHasUserAccessWithPermission } from './hooks/useHasUserAccessWithPermission.js';

const MentionTypes = {
  USER: 'user',
  GROUP: 'group',
};

// renameId :: Object -> {id: ID}
const renameId = R.compose(R.objOf('id'), R.prop('_id'));

// usersToSuggestions :: [User] -> [Suggestion]
//   Suggestion = {id: ID, name: String, type: MentionType}
const usersToSuggestions = R.map(
  R.compose(
    R.mergeRight({ type: MentionTypes.USER }),
    R.converge(R.mergeRight, [
      renameId,
      R.compose(R.objOf('name'), R.prop('fullName')),
    ]),
  ),
);

// groupsToSuggestions :: [MentionGroup] -> [Suggestion]
const groupsToSuggestions = R.map(
  R.compose(
    R.mergeRight({ type: MentionTypes.GROUP }),
    R.converge(R.mergeRight, [renameId, R.pick(['name'])]),
  ),
);

// queryDataToUsers :: String -> Object -> [User]
const queryDataToHits = (endpointName) =>
  R.pathOr([], ['data', endpointName, 'hits']);

// queryDataToSuggestions :: Object -> [Suggestion]
const queryDataToSuggestions = R.converge(R.concat, [
  R.compose(groupsToSuggestions, queryDataToHits('searchMentionGroups')),
  R.compose(usersToSuggestions, queryDataToHits('searchUsers')),
]);

const searchMentionedUsersQuery = gql`
  query searchMentionedUsers($input: CollectionSearchParams) {
    searchUsers(input: $input) {
      hits {
        _id
        fullName
      }
    }
  }
`;

const searchMentionedGroupsQuery = gql`
  query searchMentionedGroups($input: CollectionSearchParams) {
    searchMentionGroups(input: $input) {
      hits {
        _id
        name
      }
    }
  }
`;

const mentionedUsersFilterQuery = {
  query: {
    bool: {
      filter: [
        {
          nested: {
            path: 'userGroups',
            query: { exists: { field: 'userGroups.userGroupId' } },
          },
        },
        {
          nested: {
            path: 'accessItems',
            query: {
              bool: {
                should: [
                  {
                    term: {
                      'accessItems.permission': RECEIVE_ALERT_PERMISSION,
                    },
                  },
                  {
                    term: { 'accessItems.permission': FULL_ACCESS_PERMISSION },
                  },
                ],
              },
            },
          },
        },
      ],
      must: [{ match: { status: UserStatuses.ACTIVE } }],
    },
  },
};

const mentionedGroupsFilterQuery = {
  query: {
    bool: { must: [{ match: { status: mentionGroupStatuses.ACTIVE } }] },
  },
};

// getMentionsPlaceholder :: MentionProps -> String
// MentionProps = { placeholder: String, disableMentions: Boolean }
const getMentionsPlaceholder = R.cond([
  [
    R.propSatisfies(R.complement(R.isNil), 'placeholder'),
    R.prop('placeholder'),
  ],
  [R.prop('disableMentions'), R.always('Add update description')],
  [R.T, R.always('Add update and use @ to mention someone')],
]);

export const useLoadMentionSuggestions = (props) => {
  const { refetch: loadUsers } = useQuery(searchMentionedUsersQuery, {
    skip: true,
  });
  const { refetch: loadGroups } = useQuery(searchMentionedGroupsQuery, {
    skip: true,
  });

  const hasReadMentionGroupsPermission = useHasUserAccessWithPermission(
    READ_MENTION_GROUP_PERMISSION,
  );

  const loadMentionSuggestions = async (searchTerm) => {
    const usersData = await loadUsers({
      input: { searchTerm, ...mentionedUsersFilterQuery },
    });

    let mentionGroupsData = {};

    if (hasReadMentionGroupsPermission) {
      mentionGroupsData = await loadGroups({
        input: { searchTerm, ...mentionedGroupsFilterQuery },
      });
    }

    const result = R.mergeDeepRight(mentionGroupsData, usersData);

    return queryDataToSuggestions(result);
  };

  return {
    loadMentionSuggestions,
    placeholder: getMentionsPlaceholder(props),
  };
};
