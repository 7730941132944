import * as R from 'ramda';

// passValidationEntryWithMessage :: String -> Bool -> PassValidationEntry
const passValidationEntryWithMessage = R.curryN(2, R.unapply(R.reverse));

export const PASSWORD_MAX_LEN = 40;
export const PASSWORD_MIN_LEN = 8;

// eslint-disable-next-line import/no-unused-modules
export const SPECIAL_CHARACTER_PASSWORD_MSG =
  'special character (*+?^$|@% etc.)';

// validatePassword :: String -> [PassValidationEntry]
//   PassValidationEntry = Pair Bool String
// eslint-disable-next-line import/no-unused-modules
export const validatePassword = R.compose(
  R.juxt([
    R.compose(
      passValidationEntryWithMessage('lower case letter'),
      R.test(/[a-z]/),
    ),
    R.compose(
      passValidationEntryWithMessage('upper case letter'),
      R.test(/[A-Z]/),
    ),
    R.compose(passValidationEntryWithMessage('number'), R.test(/\d/)),
    R.compose(
      passValidationEntryWithMessage(SPECIAL_CHARACTER_PASSWORD_MSG),
      R.test(/[^a-z0-9\s]/i),
    ),
    R.compose(
      passValidationEntryWithMessage(
        `from ${PASSWORD_MIN_LEN} to ${PASSWORD_MAX_LEN} characters`,
      ),
      R.both(R.gte(PASSWORD_MAX_LEN), R.lte(PASSWORD_MIN_LEN)),
      R.length,
    ),
  ]),
  R.unless(R.is(String), R.always('')), // treat invalid input as empty string
);

// getPasswordValidationError :: String -> String
export const getPasswordValidationError = R.ifElse(
  R.isEmpty,
  R.always('Password is required'),
  R.compose(
    R.ifElse(
      R.isEmpty,
      R.always(undefined),
      R.compose(R.concat('Must contain: '), R.join(', '), R.map(R.last)),
    ),
    R.reject(R.compose(R.equals(true), R.head)),
    validatePassword,
  ),
);
