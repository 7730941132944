import * as R from 'ramda';
import { useMutation, gql } from '@apollo/client';

import { useNotificationState } from '../../../../hooks/useNotificationState.js';

const SEND_CONFIRM_SATISFACTION_EMAIL_MUTATION = gql`
  mutation SEND_CONFIRM_SATISFACTION_EMAIL_MUTATION(
    $input: SendConfirmSatisfactionEmailInput!
  ) {
    sendConfirmSatisfactionEmail(input: $input) {
      _id
    }
  }
`;

// prepareMutationInput :: { task: Task, mailTo: Object } -> SendConfirmSatisfactionEmailInput
const prepareMutationInput = R.applySpec({
  taskId: R.path(['task', '_id']),
  emailTo: R.compose(
    R.flatten,
    R.map(R.propOr([], 'emails')),
    R.filter(R.prop('shouldSend')),
    R.values,
    R.propOr({}, 'mailTo'),
  ),
});

export const useSendConfirmSatisfactionEmailMutation = () => {
  const { showSuccessNotification } = useNotificationState();
  const [sendConfirmSatisfactionEmail] = useMutation(
    SEND_CONFIRM_SATISFACTION_EMAIL_MUTATION,
  );

  const runMutation = async (input) => {
    const mutationInput = prepareMutationInput(input);

    await sendConfirmSatisfactionEmail({ variables: { input: mutationInput } });

    showSuccessNotification('Confirm satisfaction email successfully sent.');
  };

  return runMutation;
};
