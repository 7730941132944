import React from 'react';
import * as R from 'ramda';
import {
  arrayOf,
  object,
  oneOfType,
  string,
  bool,
  element,
  node,
} from 'prop-types';
import { useRouterQuery, useUpdateQueryParams } from '@poly/client-routing';
import { useOutSidebarContext } from '@poly/client-utils';
import { RecurringProjectTypes } from '@poly/constants';
import { insertParamsIntoURL } from '@poly/utils';
import { ProjectSidebarWidth } from '@poly/admin-ui';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { SidebarIDs } from '../constants.js';
import { routesNames } from '../../routes/index.js';
import { ProjectSidebar } from './ProjectSidebar.js';
import { LinkToSidebar } from '../../components/LinkToSidebar.js';
import { MasterRecurringProjectSidebar } from '../MasterRecurringProjectSidebar/MasterRecurringProjectSidebar.js';
import { useClearPristineState } from '../useClearPristineOnSidebarMount.js';

// prepareUrlParams :: Object -> URLParams -> URLParams
const prepareUrlParams = (urlParams) =>
  R.compose(
    R.converge(R.mergeDeepLeft, [
      R.compose(
        R.objOf('query'),
        R.mergeLeft(urlParams),
        R.defaultTo({}),
        R.prop('query'),
      ),
      R.identity,
    ]),
    R.defaultTo({}),
  );

// isRecurringProject :: Project -> Boolean
const isRecurringProject = R.propSatisfies(
  R.anyPass([
    R.isNil,
    R.equals(RecurringProjectTypes.recurringProject),
    R.equals(RecurringProjectTypes.preventiveMaintenanceProject),
  ]),
  'type',
);

// getProjectSidebarProps :: Object -> Object
const getProjectSidebarProps = ({ type, projectLinkId }) => {
  const SidebarComp = isRecurringProject({ type })
    ? MasterRecurringProjectSidebar
    : ProjectSidebar;

  const id = isRecurringProject({ type })
    ? SidebarIDs.masterProject
    : SidebarIDs.project;

  return {
    id,
    alwaysFirst: true,
    width: ProjectSidebarWidth,
    isSteady: !isRecurringProject({ type }),
    content: projectLinkId ? (
      <SidebarComp projectLinkId={projectLinkId} />
    ) : null,
  };
};

// queryPropsByType :: (String, String) -> Object
const queryPropsByType = (type, projectId) => {
  const queryId = isRecurringProject({ type })
    ? SidebarIDs.masterProject
    : SidebarIDs.project;

  return {
    [queryId]: projectId,
    ...(isRecurringProject({ type }) ? {} : { type }),
  };
};

// getCardRouteByType :: String -> Route
const getCardRouteByType = (type) =>
  isRecurringProject({ type })
    ? routesNames.MASTER_PROJECT_CARD
    : routesNames.PROJECT_CARD;

function ProjectLinkBase({
  _id,
  type,
  inNewTab,
  urlParam,
  projectId,
  children,
  className,
  queryParams,
  projectLinkId,
  skipOpen,
  linkId,
  ...props
}) {
  const { projectSidebarId } = useRouterQuery([SidebarIDs.project]);

  const isOpenProjectInNewTab = useSelector(
    (state) => state?.currentUser?.user?.settings?.links?.openProjectInNewTab,
  );

  const inNewTabChecked =
    (!!projectSidebarId && !isRecurringProject({ type })) || inNewTab;

  const cardRoute = getCardRouteByType(type);

  const linkParams = prepareUrlParams(queryPropsByType(type, linkId))(
    queryParams,
  );

  const isOpenInNewTab = isOpenProjectInNewTab || inNewTabChecked;

  const linkProps = {
    linkParams,
    inNewTab: skipOpen ? false : isOpenInNewTab,
    entityCardLink: insertParamsIntoURL({ projectId: linkId }, cardRoute),
    ...props,
  };

  return (
    <LinkToSidebar {...linkProps} className={className}>
      {children || projectId}
    </LinkToSidebar>
  );
}

ProjectLinkBase.displayName = 'ProjectLink';
ProjectLinkBase.propTypes = {
  _id: string,
  type: string,
  inNewTab: bool,
  urlParam: string,
  // eslint-disable-next-line react/forbid-prop-types
  queryParams: object,
  projectLinkId: string,
  children: oneOfType([string, object, arrayOf(oneOfType([string, node]))]),
  className: oneOfType([string, object]),
  projectId: oneOfType([
    string,
    element,
    arrayOf(oneOfType([string, element])),
  ]),
  skipOpen: bool,
  linkId: string,
};

const PrintPDFLinkS = styled.div`
  color: #436cbe;
`;

export function ProjectLink({
  isPrintPDF,
  urlParam,
  projectLinkId,
  projectId,
  _id,
  children,
  ...props
}) {
  const linkId = urlParam || projectLinkId || projectId || _id;

  if (isPrintPDF) {
    return <PrintPDFLinkS>{linkId}</PrintPDFLinkS>;
  }

  const projectLinkProps = {
    urlParam,
    projectLinkId,
    projectId,
    _id,
    children,
    linkId,
    ...props,
  };

  return <ProjectLinkBase {...projectLinkProps} />;
}

ProjectLink.propTypes = {
  _id: string,
  isPrintPDF: bool,
  urlParam: string,
  // eslint-disable-next-line react/forbid-prop-types
  projectLinkId: string,
  children: oneOfType([string, object, arrayOf(oneOfType([string, node]))]),
  projectId: oneOfType([
    string,
    element,
    arrayOf(oneOfType([string, element])),
  ]),
};

export const useOpenProjectSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();

  return ({ projectId, type }, skipSteady) =>
    openOutSidebar(
      getProjectSidebarProps({
        type,
        projectLinkId: projectId,
      }),
      skipSteady,
    );
};

export const useNavigateToProjectSidebar = () => {
  const updateQueryParams = useUpdateQueryParams();
  const clearPristine = useClearPristineState();

  return ({ projectId, type, inNewTab }) => {
    const cardRoute = getCardRouteByType(type);
    const newParams = queryPropsByType(type, projectId);

    if (inNewTab) {
      const link = insertParamsIntoURL({ projectId }, cardRoute);
      return window.open(link, '_blank');
    }
    clearPristine();
    return updateQueryParams({
      ...newParams,
    });
  };
};
