import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { arrayOf, func, shape, string } from 'prop-types';
import { highlightMatchesInObjectCurried } from '@poly/client-utils';
import { Link, useNavigate } from '@poly/client-routing';

// getHighlightMatches :: Object -> Object
const getHighlightMatches = R.converge(highlightMatchesInObjectCurried, [
  R.prop('highlightPath'),
  R.prop('globalSearchText'),
  R.identity,
]);

function GlobalSearchResultItem({
  Component,
  LinkComponent,
  urlGenerator,
  document,
  ...props
}) {
  const globalSearchText = useSelector((store) => store.globalSearchText);

  const navigate = useNavigate();

  const highlightMatches = getHighlightMatches({
    globalSearchText,
    goTo: navigate,
    ...document,
    ...props,
  });
  const LinkC = LinkComponent || Link;
  const linkProps = LinkComponent
    ? document
    : {
        href: urlGenerator(document),
        target: '_blank',
      };

  return (
    <LinkC {...linkProps}>
      <Component key={document._id} {...{ ...highlightMatches }} />
    </LinkC>
  );
}

GlobalSearchResultItem.propTypes = {
  urlGenerator: func,
  LinkComponent: func,
  Component: func.isRequired,
  document: shape({ _id: string.isRequired }),
};

const GlobalSearchResultTabC = ({ hits, ...config }) =>
  hits.map((document) => (
    <GlobalSearchResultItem key={document._id} {...{ ...config, document }} />
  ));

export function GlobalSearchResultTab({ hits, ...props }) {
  const filteredHits = R.filter(R.complement(R.isNil), hits);
  return <GlobalSearchResultTabC hits={filteredHits} {...props} />;
}

GlobalSearchResultTab.propTypes = {
  hits: arrayOf(shape({ _id: string.isRequired })),
};
