import styled from 'styled-components';
import * as R from 'ramda';
import { quarterWidth } from '@poly/admin-ui/src/modules/forms/supplierForm/form/common.js';
import { getThemeColor, getThemeFont, getThemeProp } from '@poly/admin-book';

import { FlexSpaceBetweenFullWidth } from '../../modules/forms/assignSupplierForm/styles.js';
import { FlexColumn, FlexContainer } from '../../components/FlexContainer.js';

export const InvoiceLinesSectionTitle = styled(FlexSpaceBetweenFullWidth)`
  padding: 5px 0;
  &:hover {
    box-shadow: ${getThemeProp(['defaultBoxShadow'])};
    tr,
    td {
      padding-bottom: 5px;
      padding-top: 5px;
    }
  }
`;

export const QuarterWidthContainer = styled(FlexContainer)`
  width: ${quarterWidth};
`;

export const HalfQuarterWidthContainer = styled(FlexContainer)`
  width: calc(12% - 20px);
`;

export const FieldTitle = styled.label`
  font-size: 14px;
  line-height: 18px;
  color: ${getThemeColor(['primaryLighter3'])};
  font-weight: ${getThemeFont(['medium'])};
  padding-bottom: 5px;
`;

export const FieldTitleWithBorder = styled(FieldTitle)`
  border-bottom: 1px solid ${getThemeColor(['midLight'])};
`;

export const FieldValueContainer = styled.div`
  font-size: 15px;
  line-height: 21px;
  color: ${getThemeColor(['primary'])};
  white-space: pre-wrap;
  word-break: break-all;
`;

export const HalfSection = styled(FlexColumn)`
  width: calc(50% - 10px);
`;

export const FormFieldContainer = styled(FlexColumn)`
  margin-bottom: 20px;
  font-weight: ${getThemeFont(['regular'])};
  width: ${R.prop('width')};
`;

export const FormSectionContainer = styled(FlexColumn)`
  margin-bottom: 25px;
`;
