import { gql } from '@apollo/client';

const commonEmailDataFields = gql`
  fragment commonEmailDataFields on UpdateCommentEmailData {
    from {
      name
      address
    }
    to {
      name
      address
    }
    cc {
      name
      address
    }
    subject
    receivedDate
  }
`;

const commonUpdateCommentsFields = gql`
  fragment commonUpdateCommentsFields on UpdateComment {
    _id
    message
    attachments {
      _id
      fileName
      fileType
      url
    }
    mentions {
      users {
        _id
        fullName
      }
      groups {
        _id
        name
      }
    }
    createdAt
    createdBy {
      _id
      fullName
    }
    emailData {
      ...commonEmailDataFields
    }
  }

  ${commonEmailDataFields}
`;

export const commonUpdateFields = gql`
  fragment commonUpdateFields on Update {
    _id
    title
    type
    pinned
    message
    mentions {
      users {
        _id
        fullName
      }
      groups {
        _id
        name
      }
    }
    createdAt
    attachments {
      _id
      fileName
      fileType
      fileSize
      url
      emailParams {
        to
      }
    }
    createdBy {
      _id
      fullName
    }
    emailData {
      ...commonEmailDataFields
    }
    updateComments {
      ...commonUpdateCommentsFields
    }
  }

  ${commonUpdateCommentsFields}
  ${commonEmailDataFields}
`;
