import * as R from 'ramda';
import styled from 'styled-components';

export const commonModalLayout = {
  overflow: 'visible',
  card: false,
};

export const halfWidth = 'calc(50% - 10px)';
export const thirdWidth = 'calc(33.33% - 7px)';
export const quarterWidth = 'calc(25% - 20px)';

export const FormWrapper = styled.div`
  width: ${R.propOr('585px', 'width')};
`;

export const FieldsWrapper = styled.div`
  margin: 25px 0;
`;
