import * as R from 'ramda';
import { titleCase } from 'title-case';
import { noCase } from 'change-case';

// firstLettersOfEachWord :: String -> String
export const firstLettersOfEachWord = R.pipe(
  R.defaultTo(''),
  R.split(' '),
  R.map(R.head),
  R.join(''),
);

// escapeSpecialChars :: String -> String
//
// Allows escape RegExp special characters.
// Useful while constructing RegExp out of plain string.
// Source: https://stackoverflow.com/a/9310752/4046380
export const escapeSpecialChars = R.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');

export const tailToLower = (str) => `${str[0]}${R.toLower(str.substring(1))}`;

export function upperCaseFirst(input) {
  const safeInput = input === null ? '' : input;
  return safeInput.charAt(0).toUpperCase() + safeInput.substring(1);
}

// forceTitleCase :: String -> String
export const forceTitleCase = R.compose(titleCase, noCase, R.defaultTo(''));

// trimString :: String -> String
export const trimString = R.when(R.is(String), R.trim);
