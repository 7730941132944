import * as R from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';
import { ProjectType } from '@poly/constants';
import { number, shape, arrayOf, string, bool } from 'prop-types';
import {
  WindowedTable,
  moneyColumnStyles,
  BodyRow,
  defaultTheme,
} from '@poly/admin-book';
import {
  applyProp,
  centsToDollarsWithFormat,
  formatTotal,
  propEqLegacy,
} from '@poly/utils';
import { useMapConfigToTableProps } from '@poly/admin-ui';

import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import { getWoChargeAmount } from '../CreateClientInvoice/helpers.js';
import {
  isNegativeTotalsOutOfRange,
  isPositiveTotalsOutOfRange,
  isTotalsOutOfRange,
  isZeroJobCostAndClientInvoice,
} from './batchInvoicingUtils.js';
import { ProjectTimeOrAfterHoursCallWarning } from './ProjectTimeOrAfterHoursCallWarning.js';

export const tmBatchInvoicingTableStyles = ({ isClientNTE = false }) => css`
  tr {
    width: 100%;
    margin: 0;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 100px;
  }

  ${moneyColumnStyles(3)};
  ${moneyColumnStyles(4)};
  ${moneyColumnStyles(5)};
  ${moneyColumnStyles(6)};
  ${isClientNTE && moneyColumnStyles(7)};

  ${moneyColumnStyles(isClientNTE ? 7 : 6)};

  th:nth-child(${isClientNTE ? 8 : 7}),
  td:nth-child(${isClientNTE ? 8 : 7}) {
    width: 165px;
    text-align: right;
    label {
      margin-left: 110px;
    }
  }
`;

const TableS = styled(WindowedTable)`
  thead {
    height: 45px;
  }

  ${tmBatchInvoicingTableStyles}
`;

// getInvoiceAmountWithWarning :: Row -> { warning: String, amount: String, positionRight: String }
const getInvoiceAmountWithWarning = R.applySpec({
  amount: R.compose(
    centsToDollarsWithFormat,
    R.prop('suggestedClientInvoiceAmount'),
  ),
  warning: R.cond([
    [isPositiveTotalsOutOfRange, R.always('inv. amount < job cost')],
    [isNegativeTotalsOutOfRange, R.always('inv. amount > job cost')],
    [isZeroJobCostAndClientInvoice, R.always('$0 invoices')],
    [R.T, R.always('')],
  ]),
  positionRight: R.ifElse(
    isZeroJobCostAndClientInvoice,
    R.always('-100px'),
    R.always('-160px'),
  ),
});

const WarningMsgContainer = styled.div`
  position: absolute;
  top: 8px;
  right: ${R.prop('positionRight')};
`;

function InvAmountColumn(row) {
  const { amount, warning, positionRight } = getInvoiceAmountWithWarning(row);

  return (
    <>
      {amount}
      {isTotalsOutOfRange(row) && (
        <WarningMsgContainer positionRight={positionRight}>
          {warning}
        </WarningMsgContainer>
      )}
      <ProjectTimeOrAfterHoursCallWarning {...row} />
    </>
  );
}

const batchInvoicingProjectsTableConfig = (
  isClientNTE,
  isWoCharge,
  withProjectTimeWarning,
) => [
  [
    'Project No.',
    (project) => (
      <ProjectLink {...project} target="_blank">
        {project.projectId}
      </ProjectLink>
    ),
  ],
  ['Description', R.prop('description')],
  ...(isClientNTE ? [['Client NTE', applyProp(formatTotal)('nte')]] : []),
  ['Job Cost', applyProp(centsToDollarsWithFormat)('jobCost')],
  ['Markup', applyProp(centsToDollarsWithFormat)('clientInvoiceMarkupAmount')],
  ...(isWoCharge
    ? [
        [
          'Work Order Charge',
          R.compose(
            centsToDollarsWithFormat,
            R.ifElse(
              propEqLegacy('type', ProjectType.WORK_ORDER),
              R.compose(R.defaultTo(0), getWoChargeAmount),
              R.always(0),
            ),
          ),
        ],
      ]
    : []),
  [
    'Inv. Amt.',
    R.compose(InvAmountColumn, R.mergeRight({ withProjectTimeWarning })),
  ],
];

const warningColor = R.path(
  ['colors', 'notificator', 'warning', 'text'],
  defaultTheme,
);

// getRowColorStyle :: Row -> String
const getRowColorStyle = R.compose(
  R.ifElse(isTotalsOutOfRange, R.always(warningColor), R.always('black')),
  R.prop('row'),
);

const getBodyRow = styled(BodyRow)`
  color: ${getRowColorStyle};
`;
export function BatchInvoicingTMBassProTable({
  projects,
  isClientNTE = false,
  isWoCharge,
  withProjectTimeWarning,
  ...tableProps
}) {
  const { rows, headers, columns, sortQueries } = useMapConfigToTableProps(
    R.identity,
    batchInvoicingProjectsTableConfig(
      isClientNTE,
      isWoCharge,
      withProjectTimeWarning,
    ),
    projects,
  );

  return (
    <TableS
      headers={headers}
      rows={rows}
      columns={columns}
      isClientNTE={isClientNTE}
      sortQueries={sortQueries}
      selectedRowsInEnd
      RowComponent={getBodyRow}
      showScrollBar
      {...tableProps}
    />
  );
}

BatchInvoicingTMBassProTable.propTypes = {
  projects: arrayOf(
    shape({
      suggestedClientInvoiceAmount: number,
      jobCost: number.isRequired,
      description: string,
      projectId: string.isRequired,
    }),
  ),
  isClientNTE: bool,
  isWoCharge: bool,
  withProjectTimeWarning: bool,
};
