import React from 'react';
import * as R from 'ramda';
import { arrayOf, string, oneOfType, func } from 'prop-types';
import { SupplierStatuses } from '@poly/constants';
import { assocBy } from '@poly/utils';
import {
  TableWithPaginationContainer,
  TextButton,
  WhiteCard,
  Loader,
  Table,
} from '@poly/admin-book';
import { stringifyQuery, useLocation, useNavigate } from '@poly/client-routing';
import styled from 'styled-components';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { CREATE_SUPPLIER_PERMISSION } from '@poly/security';

import { getServiceTypes } from '../tables/index.js';
import { useTableSorting } from '../../hooks/useTableSorting.js';
import { useMasterSupplierBranchesQuery } from './hooks/useMasterSupplierBranchesQuery.js';
import { useSetItemsCount } from '../../hooks/useSetItemsCount.js';
import { useMapConfigToTableProps } from '../../hooks/useMapConfigToTableProps.js';
import { PageSubTabs } from '../../components/Tabs.js';
import { TablePagination } from '../../components/Pagination.js';
import { MasterSupplierBranchesSubTabTitle } from './MasterSupplierBranchesSubtabTitle.js';

// getBranchesTotal :: MasterSupplier -> Number
const getBranchesTotal = R.pathOr(0, [
  'masterSupplier',
  'searchSupplierBranches',
  'total',
]);

// prepareMasterSupplierBranchesForTable :: MasterSupplier -> [Supplier]
const prepareMasterSupplierBranchesForTable = R.compose(
  R.map(assocBy('serviceTypesStr', getServiceTypes)),
  R.pathOr([], ['masterSupplier', 'searchSupplierBranches', 'hits']),
);

const { ACTIVE, BLOCKED } = SupplierStatuses;

const SuppliersTableComp = styled(Table)`
  padding: 0 20px;

  td:last-child {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

function MasterSupplierBranchesTable({ status, tableConfig, supplierId }) {
  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig,
    column: 1,
  });

  const { data, loading } = useMasterSupplierBranchesQuery(
    supplierId,
    status,
    sort,
  );

  useSetItemsCount(getBranchesTotal, data);

  const tableProps = useMapConfigToTableProps(
    prepareMasterSupplierBranchesForTable,
    tableConfig,
    data,
  );

  return loading ? (
    <Loader />
  ) : (
    <SuppliersTableComp {...tableSortingProps} {...tableProps} />
  );
}

MasterSupplierBranchesTable.propTypes = {
  status: string.isRequired,
  tableConfig: arrayOf(arrayOf(oneOfType([string, func]))).isRequired,
  supplierId: string.isRequired,
};

function MasterSupplierBranchesSubTab(props) {
  return (
    <>
      <TableWithPaginationContainer>
        <MasterSupplierBranchesTable {...props} />
      </TableWithPaginationContainer>
      <TablePagination />
    </>
  );
}

function AddBranchSupplierBtn({ supplierId, addBranchRoute }) {
  const navigate = useNavigate();
  const masterSupplierRouteQuery = stringifyQuery({
    masterSupplierId: supplierId,
  });

  const location = useLocation();

  const onClick = () =>
    navigate({
      pathname: addBranchRoute,
      search: masterSupplierRouteQuery,
      state: { previous: location },
    });

  return <TextButton onClick={onClick}>Add New</TextButton>;
}

AddBranchSupplierBtn.propTypes = {
  addBranchRoute: string.isRequired,
  supplierId: string.isRequired,
};

const TABS = [ACTIVE, BLOCKED];

export function MasterSupplierBranchesTab({
  supplierId,
  addBranchRoute,
  tableConfig,
}) {
  const hasUserPermissionToCreateSupplier = useHasUserAccessWithPermission(
    CREATE_SUPPLIER_PERMISSION,
  );

  const tabs = TABS.map((status) => [
    <MasterSupplierBranchesSubTabTitle
      status={status}
      key={status}
      supplierId={supplierId}
    />,
    status,
    <MasterSupplierBranchesSubTab
      status={status}
      key={status}
      supplierId={supplierId}
      tableConfig={tableConfig}
    />,
  ]);

  return (
    <WhiteCard>
      <PageSubTabs
        toolBar={
          hasUserPermissionToCreateSupplier ? (
            <AddBranchSupplierBtn
              supplierId={supplierId}
              addBranchRoute={addBranchRoute}
            />
          ) : null
        }
        tabs={tabs}
        defaultValue={ACTIVE}
      />
    </WhiteCard>
  );
}

MasterSupplierBranchesTab.propTypes = {
  supplierId: string.isRequired,
  addBranchRoute: string.isRequired,
  tableConfig: arrayOf(arrayOf(oneOfType([string, func]))),
};
