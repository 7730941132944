import { propEqLegacy } from '@poly/utils';

import { buildTreeBase, flattenTreeBase } from '../../components/Tree/utils.js';
import { HIERARCHY_NODE_TYPES } from './constants.js';

// isRegionNode :: TreeNode -> Boolean
// TreeNode = { id: String, type: String, children: [TreeNode] }
export const isRegionNode = propEqLegacy('type', HIERARCHY_NODE_TYPES.region);

// isPropertyNode :: TreeNode -> Boolean
export const isPropertyNode = propEqLegacy(
  'type',
  HIERARCHY_NODE_TYPES.property,
);

// hasChildrenNodeBase :: (String, Number) -> TreeNode -> Boolean
export const checkChildrenNodeTypeBase =
  (type, maxDeepLevel = Infinity) =>
  (node) => {
    if (node.children && maxDeepLevel > 0) {
      const result = node.children.find((childNode) => {
        if (childNode && childNode.type === type) {
          return true;
        }
        if (childNode.children) {
          return checkChildrenNodeTypeBase(type, maxDeepLevel - 1)(childNode);
        }
        return false;
      });
      return !!result;
    }
    return false;
  };

// hasChildrenProperty :: TreeNode -> Boolean
export const hasChildrenProperty = checkChildrenNodeTypeBase(
  HIERARCHY_NODE_TYPES.property,
);

// haveChildrenRegion :: TreeNode -> Boolean
export const hasFirstChildrenProperty = checkChildrenNodeTypeBase(
  HIERARCHY_NODE_TYPES.property,
  1,
);

// haveChildrenRegion :: TreeNode -> Boolean
export const hasFirstChildrenRegion = checkChildrenNodeTypeBase(
  HIERARCHY_NODE_TYPES.region,
  1,
);

// propertyHierarchyNodeBuilder :: (TreeNode, String) -> PropertyHierarchyNode
const propertyHierarchyNodeBuilder = (treeNode, parentId) => {
  if (isPropertyNode(treeNode)) {
    return null;
  }

  const nodeFlattened = {
    name: treeNode.title,
    id: treeNode.id,
    ...(parentId ? { parentId } : {}),
    ...(treeNode.poNumber ? { poNumber: treeNode.poNumber } : {}),
  };

  if (treeNode?.children?.every(isPropertyNode)) {
    return {
      ...nodeFlattened,
      propertiesIds: treeNode.children.map((child) => child.id),
    };
  }
  return nodeFlattened;
};

// flattenTree :: [TreeNode] -> [PropertyHierarchyNode]
export const flattenTree = flattenTreeBase(propertyHierarchyNodeBuilder);

// buildTreeNode :: PropertyHierarchyNode -> TreeNode
const buildTreeNode = (node) => {
  const treeNode = {
    id: node.id,
    expanded: true,
    title: node.name,
    poNumber: node.poNumber,
    type: HIERARCHY_NODE_TYPES.region,
  };

  if (node.properties && node.properties.length > 0) {
    return {
      ...treeNode,
      children: node.properties.map((property) => ({
        id: property._id,
        title: property.name,
        type: HIERARCHY_NODE_TYPES.property,
      })),
    };
  }

  return treeNode;
};

// buildTree :: [PropertyHierarchyNode] -> [TreeNode]
export const buildTree = buildTreeBase(buildTreeNode);

// getTreePropertiesIds :: [TreeNode] -> [ID]
export const getTreePropertiesIds = (treeNodes = []) => {
  const treeFlattened = flattenTree(treeNodes).filter((node) => !!node);
  return treeFlattened.reduce(
    (acc, node) => (node.propertiesIds ? [...acc, ...node.propertiesIds] : acc),
    [],
  );
};
