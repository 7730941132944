import React from 'react';
import styled, { css } from 'styled-components';
import { string, bool, func, oneOfType, node } from 'prop-types';

import { getThemeColor, getThemeFont } from '../utils.js';

const RadioButtonLabel = styled.span`
  padding-left: 25px;
`;

const RadioButtonPropTypes = {
  onChange: func.isRequired,
  value: oneOfType([bool, string]).isRequired,
  name: string.isRequired,
  className: string,
  label: oneOfType([string, node]),
  disabled: bool,
};

const RadioButtonDefaultProps = {
  className: '',
  label: null,
  disabled: false,
};

function RadioButtonContainer({
  className,
  onChange,
  label,
  value,
  name,
  disabled,
}) {
  return (
    <label htmlFor={name} {...{ className }}>
      <div className="state-re-presenter" />
      <input
        id={name}
        type="radio"
        checked={value}
        onChange={() => onChange(name)}
        disabled={disabled}
      />
      {label ? <RadioButtonLabel>{label}</RadioButtonLabel> : null}
    </label>
  );
}

RadioButtonContainer.propTypes = RadioButtonPropTypes;

RadioButtonContainer.defaultProps = RadioButtonDefaultProps;

const PointMark = css`
  content: '';
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: ${getThemeColor(['white'])};
`;

export const RadioButton = styled(RadioButtonContainer)`
  font-weight: ${getThemeFont(['regular'])};
  height: 12px;
  font-size: 12px;
  line-height: 18px;
  width: ${({ label }) => (label ? 'auto' : '14px')};
  display: ${({ label }) => (label ? 'inline-block' : 'block')};
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  input {
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .state-re-presenter {
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border-radius: 15px;
    border: solid 1px ${getThemeColor(['primaryLighter6'])};
    background-color: ${({ value }) =>
      value ? getThemeColor(['primaryLight']) : 'transparent'};

    &:after {
      ${({ value }) => !!value && PointMark};
    }
  }
`;

RadioButton.displayName = 'RadioButton';
RadioButton.propTypes = RadioButtonPropTypes;
RadioButton.defaultProps = RadioButtonDefaultProps;
