import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useOutSidebarContext } from '@poly/client-utils';
import { Button, getThemeColor } from '@poly/admin-book';
import { SidebarWrapper } from '@poly/admin-ui';

import { ProjectsDirectoryFilterId } from './constants.js';
import { FlexContainer } from '../../components/FlexContainer.js';
import { SidebarCloseIcon } from '../../components/SidebarLayouts.js';
import { RowTitle, filterProjectsConfig } from './FilterProjectsConfig.js';
import { useClearPristineOnSidebarMount } from '../../sidebars/useClearPristineOnSidebarMount.js';
import { useLocalSearchFilters } from '../../hooks/useSearchFilters.js';
import { setProjectsFilter } from '../../redux/searchFilters/projectsFilter.js';

// formatFilterConfig :: [FilterRowConfig] -> [Object]
// FilterRowConfig = Object
const formatFilterConfig = R.compose(
  R.map(R.pick(['nestedFields', 'name', 'defaultValue', 'subscribers'])),
  R.flatten,
  R.map(R.propOr([], 'columns')),
);

// isInitialFilter :: SearchFilters -> [FilterRowConfig] -> Object
const isInitialFilter = (searchFilters) =>
  R.compose(
    R.equals(searchFilters),
    R.fromPairs,
    R.map(R.juxt([R.prop('name'), R.prop('defaultValue')])),
    R.flatten,
    R.map(R.propOr([], 'columns')),
  );

const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 24px 5px;
`;

const PageTitle = styled.h3`
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: ${getThemeColor(['primary'])};
  margin: 0;
`;

const FilterRow = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${getThemeColor(['lighter'])};
  padding: 14px 24px;

  &:last-child {
    padding: 24px;
  }
`;

const RowInner = styled.div`
  display: flex;
  align-items: center;

  ${({ rowLayout }) => rowLayout};
`;

const FilterButtonsContainer = styled(FlexContainer)`
  padding: 24px;
`;

const ProjectSidebarWrapperS = styled(SidebarWrapper)`
  overflow-x: hidden;
  height: 100%;
`;

const FilterButton = styled(Button)`
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

export function ProjectsFilter() {
  const dispatch = useDispatch();
  const projectsFilters = useSelector(
    (state) => state.searchFilters.projectsFilter,
  );

  useClearPristineOnSidebarMount();
  const { searchFilters, handlers, onReset } = useLocalSearchFilters(
    formatFilterConfig(filterProjectsConfig),
    projectsFilters,
  );

  const { closeOutSidebar } = useOutSidebarContext();

  const onSearch = () => {
    const result = isInitialFilter(searchFilters)(filterProjectsConfig)
      ? null
      : searchFilters;
    dispatch(setProjectsFilter(result));
  };

  const callIfExist = (func) =>
    R.is(Function, func) ? func(searchFilters) : true;

  const onClose = () => closeOutSidebar(ProjectsDirectoryFilterId);

  return (
    <ProjectSidebarWrapperS>
      <SidebarHeader>
        <PageTitle>Project Search</PageTitle>
        <SidebarCloseIcon onClick={onClose} />
      </SidebarHeader>
      {filterProjectsConfig.map(
        ({ key, rowTitle, rowLayout, columns, titleWidth }) => (
          <FilterRow key={key}>
            {!!rowTitle && (
              <RowTitle titleWidth={titleWidth}>{rowTitle}</RowTitle>
            )}
            <RowInner rowLayout={rowLayout}>
              {columns.map(
                ({ name, Component, filterProps, renderIf }) =>
                  callIfExist(renderIf) && (
                    <Component
                      name={name}
                      key={name}
                      size="small"
                      value={searchFilters[name]}
                      onChange={handlers[name]}
                      {...filterProps}
                    />
                  ),
              )}
            </RowInner>
          </FilterRow>
        ),
      )}
      <FilterButtonsContainer>
        <FilterButton
          size="tiny"
          styleType="default"
          data-testid="search-button"
          onClick={onSearch}
        >
          Search
        </FilterButton>
        <FilterButton
          size="tiny"
          styleType="primaryLighter"
          onClick={onReset}
          data-testid="reset-button"
        >
          Reset
        </FilterButton>
      </FilterButtonsContainer>
    </ProjectSidebarWrapperS>
  );
}
