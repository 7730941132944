import React from 'react';
import * as R from 'ramda';
import { arrayOf } from 'prop-types';
import styled from 'styled-components';
import { FileLink, SidebarRow, useMapConfigToTableProps } from '@poly/admin-ui';
import { useOutSidebarContext } from '@poly/client-utils';
import { formatTotal, formatDate } from '@poly/utils';
import { moneyColumnStyles, Table } from '@poly/admin-book';
import { DatePicker } from '@poly/admin-book/src/DatePicker/index.js';

import {
  linePropTypes,
  journalPropTypes,
} from './transactionDetailsPropTypes.js';
import {
  TransactionHeader,
  TransactionLinesContainer,
  TransactionDetailsContainer,
} from './ClientPaymentTransactionDetailsSidebar.js';
import { HeaderBlock } from './HeaderBlock.js';
import {
  formatJournalType,
  getCreatorNameUIByJournal,
  transactionsDetailsSidebarId,
} from './helpers.js';
import { formatAccountOptionText } from '../../components/AccountsSelect.js';
import { useEditJournalPostingDate } from './useEditJournalPostingDate.js';
import { TransactionDetailsButtons } from './TransactionDetailsButtons.js';
import { BlockWithLabel } from '../components/commonSidebarComponents.js';

const transactionLinesTableConfig = [
  ['Account', R.compose(formatAccountOptionText, R.prop('account'))],
  [
    'Net Amount',
    R.compose(
      formatTotal,
      R.either(R.prop('cash_amount'), R.prop('accrual_amount')),
    ),
  ],
  [
    '',
    ({ receiptFile }) =>
      receiptFile ? <a href={receiptFile.url}>Receipt</a> : null,
  ],
];

const TransactionLinesTableS = styled(Table)`
  ${moneyColumnStyles(2, '30%')};
  th:nth-child(1),
  td:nth-child(1) {
    width: 45%;
  }
`;

function TransactionLinesTable({ lines }) {
  const linesWithIds = lines.map((l, i) => R.assoc('_id', i, l));
  const tableProps = useMapConfigToTableProps(
    R.always(linesWithIds),
    transactionLinesTableConfig,
  );
  return <TransactionLinesTableS {...tableProps} />;
}

TransactionLinesTable.propTypes = {
  lines: arrayOf(linePropTypes),
};

function PostingDateDatePicker(props) {
  return <DatePicker {...props} width="125px" />;
}

export function GeneralTransactionDetailsSidebar({ journals }) {
  const { closeOutSidebar } = useOutSidebarContext();
  const journal = journals[0];
  const invoiceFile = R.path(['invoice', 'invoiceFile'], journal);
  const {
    handleEditJournalPostingDate,
    isEditMode,
    toggleEditMode,
    postingDate,
    setPostingDate,
  } = useEditJournalPostingDate(journal);

  return (
    <TransactionDetailsContainer>
      <SidebarRow justify>
        <TransactionHeader>
          Transaction #{journal.transaction_number}
        </TransactionHeader>
        <TransactionDetailsButtons
          toggleEditMode={toggleEditMode}
          isEditMode={isEditMode}
          handleEditJournalPostingDate={handleEditJournalPostingDate}
          closeOutSidebar={() => closeOutSidebar(transactionsDetailsSidebarId)}
        />
      </SidebarRow>
      <SidebarRow>
        <HeaderBlock title="Reference ID" content={journal.journal_number} />
        {isEditMode ? (
          <BlockWithLabel
            id="postingDate"
            label="Posting Date"
            width="25%"
            onChange={setPostingDate}
            value={postingDate}
            Component={PostingDateDatePicker}
          />
        ) : (
          <HeaderBlock
            title="Posting Date"
            content={formatDate(journal.date)}
          />
        )}
        <HeaderBlock title="Type" content={formatJournalType(journal)} />
        {invoiceFile && (
          <HeaderBlock
            title="Invoice File"
            content={
              <FileLink url={invoiceFile.url} fileName={invoiceFile.fileName} />
            }
          />
        )}
      </SidebarRow>
      <SidebarRow>
        <HeaderBlock
          title="Created By"
          content={getCreatorNameUIByJournal(journal)}
        />
      </SidebarRow>
      <SidebarRow>
        <HeaderBlock
          width="100%"
          title="Description"
          content={journal.description || ''}
          withLongText
        />
      </SidebarRow>
      <TransactionLinesContainer>
        <SidebarRow>
          <TransactionLinesTable lines={journal.lines} />
        </SidebarRow>
      </TransactionLinesContainer>
    </TransactionDetailsContainer>
  );
}

GeneralTransactionDetailsSidebar.propTypes = {
  journals: arrayOf(journalPropTypes),
};
