import { gql } from '@apollo/client';
import { contactDetailsFields } from '../../hocs/users/fragments.js';

export const CONTACT_DETAILS = gql`
  query CONTACT_DETAILS($id: ID!) {
    contactUser(id: $id) {
      isStaff
      ...contactDetailsFields
    }
  }

  ${contactDetailsFields}
`;

export const USERS_BY_SEARCH_FOR_OPTIONS = gql`
  query USERS_BY_SEARCH_FOR_OPTIONS($searchInput: CollectionSearchParams!) {
    searchUsers(input: $searchInput) {
      hits {
        _id
        fullName
      }
    }
  }
`;
