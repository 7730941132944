import React, { useState } from 'react';
import { TableCardWithoutTabs } from '@poly/admin-book';
import { gql, useQuery } from '@apollo/client';
import * as R from 'ramda';
import { propEqLegacy } from '@poly/utils';

import { useRouterQuery } from '@poly/client-routing';
import { SupplierSearchTableWrapper } from '../modules/tables/supplierSearchTable/index.js';
import { SearchHeaderCreator } from '../components/SearchHeaderCreator.js';
import {
  isZipValid,
  supplierSearchHeaderConfig,
} from '../modules/pagesHeaders/SupplierSearchPageHeader/SupplierSearchHeader.js';
import {
  PageWithSearchHeader,
  SearchPageContentWrapper,
  SearchPageHeaderContainer,
} from '../components/PageWithSearchHeader.js';
import {
  generateSupplerSearchQueryByConfig,
  prepareGeocodeAddress,
} from '../modules/tables/supplierSearchTable/supplierSearchTableUtils.js';

const GEOCODE_ADDRESS = gql`
  query GEOCODE_ADDRESS($address: NonEmptyString!) {
    geocodeAddress(address: $address) {
      lat
      lon
    }
  }
`;

// getGeocodeAddress :: QueryData -> GeocodeAddress
const getGeocodeAddress = R.compose(
  R.when(
    R.both(propEqLegacy('lat', null), propEqLegacy('lon', null)),
    R.always(null),
  ),
  R.dissoc('__typename'),
  R.prop('geocodeAddress'),
);

export function SupplierSearchPage() {
  const [query, setQuery] = useState({});
  const { radiusStartPoint } = useRouterQuery(['radiusStartPoint']);
  const { data, loading } = useQuery(GEOCODE_ADDRESS, {
    variables: { address: radiusStartPoint },
    skip: !radiusStartPoint || !isZipValid(radiusStartPoint),
  });

  const geocodeAddress = getGeocodeAddress(data);

  const onSuppliersSearch = (filters) => {
    setQuery(
      generateSupplerSearchQueryByConfig({
        geocodeAddress: prepareGeocodeAddress(geocodeAddress),
        ...filters,
      }),
    );
  };
  return (
    <PageWithSearchHeader headerHeight="230px">
      <SearchPageHeaderContainer title="Search Suppliers">
        <SearchHeaderCreator
          onSearch={onSuppliersSearch}
          config={supplierSearchHeaderConfig}
        />
      </SearchPageHeaderContainer>
      <SearchPageContentWrapper>
        <TableCardWithoutTabs>
          {!loading && (
            <SupplierSearchTableWrapper
              query={query}
              geocodeAddress={geocodeAddress}
            />
          )}
        </TableCardWithoutTabs>
      </SearchPageContentWrapper>
    </PageWithSearchHeader>
  );
}
