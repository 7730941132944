import styled from 'styled-components';
import React from 'react';
import {
  ListBodyWrapper,
  PageHeaderContainer,
  MainHeader,
  Loader,
} from '@poly/admin-book';
import { MasterSuppliersStatistics } from '../modules/entitiesStatistics/MasterSuppliersStatistics.js';
import { AACSuppliersStatistics } from '../modules/entitiesStatistics/AACSuppliersStatistics/AACSuppliersStatistics.js';
import { ClientsStatistics } from '../modules/entitiesStatistics/ClientsStatisctics.js';
import { useAccountIndex } from '../modules/core/hooks/accountIndex/index.js';

const EntitiesWrapper = styled(ListBodyWrapper)`
  overflow: auto;
  justify-content: space-evenly;
`;

const EntitiesSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

export function AccountIndexPage() {
  const { data, loading } = useAccountIndex();

  if (loading) return <Loader />;

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Account Index</MainHeader>
      </PageHeaderContainer>
      <EntitiesWrapper>
        <EntitiesSection>
          <ClientsStatistics {...data} />
        </EntitiesSection>
        <EntitiesSection>
          <AACSuppliersStatistics {...data} />
        </EntitiesSection>
        <EntitiesSection>
          <MasterSuppliersStatistics {...data} />
        </EntitiesSection>
      </EntitiesWrapper>
    </>
  );
}
