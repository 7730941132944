import React from 'react';
import { bool } from 'prop-types';
import { NOTHING_UI_STRING } from '@poly/constants';
import { SectionWithCard, EntityAddress } from '@poly/admin-book';
import { supplierAccountPropsTypes, W9FileLink } from '@poly/admin-ui';
import { formatSocialSecurity, formatTaxId } from '@poly/utils';
import {
  ExemptSupplierSalesTaxContainer,
  ExemptSupplierSalesTaxPopover,
} from '@poly/admin-ui/src/modules/forms/supplierForm/form/components/Checkbox.js';

import { BooleanTextWithDot } from '../supplierAccountUtils.js';
import { ApReportLink } from '../ApReportLink.js';

const isFieldNotExist = (field) => !field || field === NOTHING_UI_STRING;

function ExemptSupplierSalesTax({ exemptSupplierSalesTax }) {
  return (
    <ExemptSupplierSalesTaxContainer>
      <BooleanTextWithDot isTrue={exemptSupplierSalesTax} />
      <ExemptSupplierSalesTaxPopover />
    </ExemptSupplierSalesTaxContainer>
  );
}

ExemptSupplierSalesTax.propTypes = {
  exemptSupplierSalesTax: bool,
};

export function FinancialSection({
  terms,
  acceptsCreditCard,
  is1099,
  taxClass,
  taxId,
  exempt,
  w9FileUrl,
  w9FileName,
  taxAddress1,
  taxAddress2,
  taxName,
  isMaster,
  socialSecurityNumber,
  openingInvoicesTotal,
  exemptSupplierSalesTax,
  hasUserTaxPrivateFieldsAccess,
}) {
  return (
    <SectionWithCard
      title="Financial"
      list={[
        {
          title: 'Ap Report',
          elem: openingInvoicesTotal ? (
            <ApReportLink
              openingInvoicesTotal={openingInvoicesTotal}
              isMaster={isMaster}
            />
          ) : (
            NOTHING_UI_STRING
          ),
        },
        {
          title: 'W9',
          elem: exempt ? (
            'Exempt'
          ) : (
            <W9FileLink
              w9FileUrl={w9FileUrl}
              w9FileName={w9FileName}
              hasUserTaxPrivateFieldsAccess={hasUserTaxPrivateFieldsAccess}
            />
          ),
        },
        {
          title: 'Terms',
          elem: terms,
        },
        {
          title: 'Is 1099',
          elem: <BooleanTextWithDot isTrue={is1099} />,
        },
        {
          title: 'Accepts Credit Card',
          elem: <BooleanTextWithDot isTrue={acceptsCreditCard} />,
        },
        {
          title: 'Company Type',
          elem: taxClass,
        },
        {
          title: 'Exempt a Supplier from Sales Tax',
          elem: (
            <ExemptSupplierSalesTax
              exemptSupplierSalesTax={exemptSupplierSalesTax}
            />
          ),
        },
        ...(isFieldNotExist(socialSecurityNumber)
          ? [
              {
                title: 'Tax ID No',
                elem: formatTaxId(taxId),
              },
            ]
          : []),
        {
          title: 'Tax Name',
          elem: taxName,
        },
        {
          title: 'Tax Address',
          elem: (
            <EntityAddress address={taxAddress1} addressTwo={taxAddress2} />
          ),
        },
        ...(isFieldNotExist(taxId)
          ? [
              {
                title: 'Social Security #',
                elem: formatSocialSecurity(socialSecurityNumber),
              },
            ]
          : []),
      ]}
    />
  );
}

FinancialSection.propTypes = supplierAccountPropsTypes.financialPropTypes;
