import React from 'react';
import * as R from 'ramda';
import { array, oneOfType, shape, string } from 'prop-types';
import { formatTotal, propEqLegacy } from '@poly/utils';
import {
  SupplierTypes,
  SupplierPhoneTypes,
  NOTHING_UI_STRING,
} from '@poly/constants';
import { commonSortQuery, keywordSortQuery } from '@poly/client-utils';
import { Rating } from '@poly/admin-book';
import { PhoneLink } from '@poly/admin-ui';

import { SupplierLink } from '../../../components/Links.js';

export function SupplierLinkComp({ company, ...restProps }) {
  return <SupplierLink {...restProps} name={company.name} />;
}

SupplierLinkComp.propTypes = {
  company: shape({
    name: oneOfType([string, array]),
  }),
};

const getSupplierPhone = R.compose(
  R.prop('phone'),
  R.find(propEqLegacy('type', SupplierPhoneTypes.GENERAL)),
  R.pathOr([], ['company', 'phones']),
);

export const supplierNameColumn = [
  'Name',
  SupplierLinkComp,
  keywordSortQuery(['company', 'name']),
];

export const supplierPhoneColumn = [
  'Phone',
  (supplier) => <PhoneLink number={getSupplierPhone(supplier)} />,
  keywordSortQuery(['company', 'phoneNumbersStr']),
];

function SupplierRating(props) {
  return <Rating {...props} readOnly />;
}

// supplierRatingSortQuery :: Supplier -> [ElasticSortQUery]
const supplierRatingSortQuery = R.compose(
  R.unnest,
  R.juxt([
    commonSortQuery(['rating', 'average']),
    commonSortQuery(['rating', 'total']),
  ]),
);

export const supplierRatingColumn = [
  'Rating',
  R.pipe(
    R.ifElse(
      propEqLegacy('type', R.toUpper(SupplierTypes.SUBCONTRACTOR)),
      R.pipe(R.prop('rating'), R.objOf('value'), SupplierRating),
      R.always(NOTHING_UI_STRING),
    ),
  ),
  supplierRatingSortQuery,
];

export const supplierHourlyRateColumn = [
  'Hourly Rate',
  R.pipe(R.pathOr(0, ['rates', 'hourly']), formatTotal),
  commonSortQuery(['rates', 'hourly']),
];
