import React from 'react';
import * as R from 'ramda';
import { thirdWidth } from '@poly/admin-ui';
import { Checkbox } from '@poly/admin-book';

import { UserGroupNameInput } from './components/UserGroupNameInput.js';
import { DefaultLoginAppSelect } from './components/DefaultLoginAppSelect.js';
import { UserGroupPermissionLine } from './components/UserGroupPermissionLine.js';

function Is2FARequiredCheckbox(props) {
  return <Checkbox {...props} label="Is 2FA Required" />;
}

export const userGroupFormSections = [
  {
    order: 1,
    layout: { padding: 0 },
    fields: [
      {
        order: 1,
        required: true,
        layout: { row: 1, width: thirdWidth, padding: '12px 0 12px 24px' },
        field: {
          name: 'name',
          Component: UserGroupNameInput,
        },
        validators: [
          [R.identity, 'User Group name is required'],
          [
            R.compose(
              R.both(R.gte(R.__, 5), R.lte(R.__, 100)),
              R.defaultTo(0),
              R.prop('length'),
            ),
            'User Group name length should be between 5 and 100',
          ],
        ],
      },
      {
        order: 2,
        required: true,
        layout: { row: 1, width: thirdWidth, padding: '12px 0' },
        field: {
          name: 'defaultLoginApp',
          Component: DefaultLoginAppSelect,
        },
        validators: [[R.identity, 'Default Login App is required']],
      },
      {
        order: 3,
        layout: { row: 1, width: thirdWidth, padding: '17px 24px 12px 0' },
        field: {
          name: 'isTwoFactorAuthRequired',
          Component: Is2FARequiredCheckbox,
        },
      },
      {
        order: 4,
        layout: { row: 2, width: '100%' },
        field: {
          name: 'accessItems',
          isArrayField: true,
          withChangeFieldValue: true,
          Component: UserGroupPermissionLine,
        },
      },
    ],
  },
];
