import React, { useMemo } from 'react';
import { number, shape, func, arrayOf, string } from 'prop-types';
import { useCopyDataAsJSONToClipboard, ThreeDotsPopover } from '@poly/admin-ui';
import { FieldLayout, PopoverLink } from '@poly/admin-book';
import { PO_AMOUNT_AIT } from '@poly/security';
import { propEqLegacy } from '@poly/utils';

import { UserGroupScopeSelect } from './UserGroupScopeSelect.js';
import { UserGroupFlexLineS } from './userGroupFormComponents.js';
import { UserGroupScopeRadioButtons } from './UserGroupScopeRadioButtons.js';
import { UserGroupScopeEntitySelect } from './UserGroupScopeEntitySelect.js';

// checkIsNumberTypeScope :: { scope: String } -> Boolean
export const checkIsNumberTypeScope = propEqLegacy('scope', PO_AMOUNT_AIT);

export function UserGroupScopeLine({
  name,
  index,
  allowedScopes,
  fields: { remove, value, update },
}) {
  const copyToClipboard = useCopyDataAsJSONToClipboard();

  const formLineData = useMemo(() => value[index], [value]);

  const isNumberTypeScope = useMemo(
    () => checkIsNumberTypeScope(formLineData),
    [formLineData],
  );

  const onCopyScope = async (e) => {
    e.preventDefault();
    await copyToClipboard('scopes', [formLineData]);
  };

  const onLineChange = (line) => update(index, line);

  const onDeleteScope = (e) => {
    e.preventDefault();
    remove(index);
  };

  return (
    <UserGroupFlexLineS>
      <FieldLayout
        layout={{ width: 'calc(18% - 5px)' }}
        field={{
          name: `${name}.scope`,
          Component: UserGroupScopeSelect,
          additionalProps: {
            formLineData,
            onLineChange,
            allowedScopes,
          },
        }}
      />
      <FieldLayout
        layout={{
          padding: 0,
          width: `calc(${isNumberTypeScope ? '46' : '30'}% - 5px)`,
        }}
        field={{
          name: `${name}.operator`,
          Component: UserGroupScopeRadioButtons,
          additionalProps: { formLineData, onLineChange },
        }}
      />
      <FieldLayout
        required={!!formLineData.scope}
        layout={{ width: `calc(${isNumberTypeScope ? '33' : '49'}% - 5px)` }}
        field={{
          name: `${name}.entities`,
          Component: UserGroupScopeEntitySelect,
          additionalProps: { formLineData, isNumberTypeScope, onLineChange },
        }}
      />
      <ThreeDotsPopover
        content={
          <>
            <PopoverLink onClick={onCopyScope}>Copy</PopoverLink>
            <PopoverLink onClick={onDeleteScope}>Delete</PopoverLink>
          </>
        }
      />
    </UserGroupFlexLineS>
  );
}

UserGroupScopeLine.displayName = 'UserGroupScopeLine';

UserGroupScopeLine.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  allowedScopes: arrayOf(string).isRequired,
  fields: shape({
    remove: func.isRequired,
    update: func.isRequired,
    value: arrayOf(shape({ scope: string })).isRequired,
  }),
};
