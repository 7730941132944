import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import {
  NOTHING_UI_STRING,
  supplierEmailsTypes,
  SupplierTypes,
  supplierTypes,
} from '@poly/constants';
import {
  WebsiteLink,
  EmailLink,
  SupplierMBEElement,
  SupplierServicesList,
  supplierAccountPropsTypes,
  PhoneLink,
  FoodBuyVendorType,
} from '@poly/admin-ui';
import { SectionWithCard, EntityAddress } from '@poly/admin-book';
import { MultiEmailsLinks } from '@poly/admin-ui/src/components/MultiEmailsLinks.js';

import { BooleanTextWithDot } from '../supplierAccountUtils.js';
import { MasterSupplierLink } from '../../../../components/Links.js';
import { propOrNothingUI } from '../../../core/utils/general.js';

const getEmailFields = (type, emails) =>
  type === supplierTypes.ADMINISTRATIVE
    ? [
        {
          title: 'Email',
          elem: <EmailLink email={emails[supplierEmailsTypes.GENERAL]} />,
        },
      ]
    : [
        {
          title: 'Accounting Emails',
          elem: (
            <MultiEmailsLinks emails={emails[supplierEmailsTypes.ACCOUNT]} />
          ),
        },
        {
          title: 'Primary Service Email',
          elem: <EmailLink email={emails[supplierEmailsTypes.SERVICE]} />,
        },
        {
          title: 'Secondary Service Emails',
          elem: (
            <MultiEmailsLinks
              emails={emails[supplierEmailsTypes.SECONDARY_SERVICE]}
            />
          ),
        },
      ];

const getWebsiteField = (type, checkType, website) =>
  type === checkType
    ? [
        {
          title: 'Website',
          elem: <WebsiteLink website={website} />,
        },
      ]
    : [];

const getMasterSupplierField = (masterSupplier) =>
  masterSupplier
    ? [
        {
          title: 'Master Supplier',
          elem: (
            <MasterSupplierLink
              _id={masterSupplier._id}
              name={masterSupplier.company.name}
            />
          ),
        },
      ]
    : [];

export function GeneralInfoSection({
  dba,
  fax,
  name,
  type,
  mwbe,
  phone,
  mobile,
  emails,
  address,
  website,
  foodBuy,
  isVeteran,
  addressTwo,
  service_24_7,
  services = [],
  masterSupplier,
  afterHoursPhone,
}) {
  return (
    <SectionWithCard
      title="General Information"
      list={[
        {
          title: 'Supplier Name',
          elem: name,
        },
        ...(dba
          ? [
              {
                title: 'DBA',
                elem: dba,
              },
            ]
          : []),
        ...getMasterSupplierField(masterSupplier),
        {
          title: 'Address',
          elem: <EntityAddress {...{ address, addressTwo }} />,
        },
        {
          title: '24/7 Service',
          elem: <BooleanTextWithDot isTrue={service_24_7} />,
        },
        { title: 'Phone', elem: <PhoneLink number={phone} /> },
        {
          title: 'Service SMS Number',
          elem: <PhoneLink number={mobile} international />,
          isHidden: type === SupplierTypes.ADMINISTRATIVE,
        },
        { title: 'Fax', elem: <PhoneLink number={fax} /> },
        ...(service_24_7
          ? [
              {
                title: 'After Hours Phone',
                elem: <PhoneLink number={afterHoursPhone} />,
              },
            ]
          : []),
        ...getEmailFields(type, emails),
        ...getWebsiteField(type, supplierTypes.ADMINISTRATIVE, website),
        {
          title: 'Service Types',
          elem: <SupplierServicesList {...{ services }} />,
          fullWidth: R.equals(type, supplierTypes.ADMINISTRATIVE),
        },
        ...getWebsiteField(type, supplierTypes.SUBCONTRACTOR, website),
        {
          title: 'MBE',
          elem: <SupplierMBEElement minority={mwbe.minority} />,
        },
        {
          title: 'WBE',
          elem: <BooleanTextWithDot isTrue={mwbe.isWBE} />,
        },
        {
          title: 'Veteran',
          elem: <BooleanTextWithDot isTrue={isVeteran} />,
        },
        {
          title: 'Foodbuy',
          elem: <BooleanTextWithDot isTrue={foodBuy.isEnabled} />,
        },
        {
          title: 'SAP Vendor Number',
          elem: propOrNothingUI('sapVendorNumber', foodBuy),
        },
        {
          title: 'Foodbuy Term Set',
          elem: <span>{foodBuy.termSet || NOTHING_UI_STRING}</span>,
          isHidden: !foodBuy.isEnabled,
        },
        {
          title: 'Foodbuy VA %',
          elem: <span>{foodBuy.vaPercentage || 0} %</span>,
          isHidden: !foodBuy.isEnabled,
        },
        {
          title: 'Foodbuy Vendor Type',
          elem: <FoodBuyVendorType vendorType={foodBuy.vendorType} />,
          isHidden: !foodBuy.isEnabled,
        },
        {
          title: 'Supplier VA Email ',
          elem: <span>{foodBuy.vaEmail || NOTHING_UI_STRING}</span>,
          isHidden: !foodBuy.isEnabled,
        },
      ]}
    />
  );
}

GeneralInfoSection.propTypes = {
  ...supplierAccountPropsTypes.generalInfoPropTypes,
  type: string.isRequired,
};
