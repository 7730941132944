import React from 'react';
import styled from 'styled-components';
import { TableSearchInput } from '@poly/admin-ui/src/components/TableSearchInput.js';
import { useOutSidebarContext } from '@poly/client-utils/src/slideOutSidebar.js';
import { getThemeColor, getThemeProp } from '@poly/admin-book/src/utils.js';
import { HeaderLinksWrapper } from '@poly/admin-book/src/Header/index.js';
import { LinkButton } from '@poly/admin-book/src/LinkButton/index.js';

import { MasterProjectsFilterId } from './constants.js';
import { MasterProjectsFilter } from './MasterProjectsFilter.js';
import { MasterProjectsPrintPDFBtn } from './MasterProjectsPrintPDFBtn.js';
import { RecurringProjectTableExportToXLSBtn } from '../../modules/core/tableToolbar/TableExportToXLSBtn.js';

const FilterButtonS = styled(LinkButton)`
  font-size: 12px;
  cursor: pointer;
  line-height: 18px;
  margin-left: 35px;
  width: max-content;
  text-decoration: none;
  color: ${getThemeColor(['midDark'])};
  transition: color ${getThemeProp(['defaultTransition'])};

  :hover,
  :focus {
    color: ${getThemeColor(['accent5'])};
  }
`;

function FilterButton() {
  const { openOutSidebar } = useOutSidebarContext();

  const onFilterClick = () =>
    openOutSidebar({
      width: 600,
      alwaysFirst: true,
      id: MasterProjectsFilterId,
      content: <MasterProjectsFilter />,
    });

  return <FilterButtonS onClick={onFilterClick}>Filter</FilterButtonS>;
}

export function MasterProjectsDirectoryToolbar() {
  return (
    <HeaderLinksWrapper>
      <MasterProjectsPrintPDFBtn />
      <RecurringProjectTableExportToXLSBtn />
      <FilterButton />
      <TableSearchInput />
    </HeaderLinksWrapper>
  );
}
