import { createAction, handleAction } from 'redux-actions';

export const setConversionRecurringProject = createAction(
  'SET_RECURRING_PROJECT_SUBSCRIPTION',
);

export const conversionRecurringProjectReducer = handleAction(
  setConversionRecurringProject,
  (_, { payload }) => payload,
  false,
);
