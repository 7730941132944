import arrayMutators from 'final-form-arrays';

export const supplierInvoiceFormCommonConfig = {
  // this prevents obsolete queries while validating invoice number
  validateOnBlur: true,
  resetFormOnSubmit: true,
  keepDirtyOnReinitialize: false,
  mutators: { ...arrayMutators },
};

export const editSupplierInvoiceDefaultValues = () => ({
  lines: [{}],
  invoiceFile: [],
  invoiceDate: new Date().toISOString(),
});
