import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { arrayOf, shape, string } from 'prop-types';
import { TimeSheetReportTableComp } from './TimeSheetReportTableComp.js';
import { tableConfig, useMapTimeSheetReportForTable } from './helpers.js';

const preparedTableConfig = R.dropLast(2, tableConfig);

const PrintTableComponent = styled(TimeSheetReportTableComp)`
  height: 100%;
`;

export function PrintTimeSheetReportTable({ data, ...restProps }) {
  const timeSheetsTableProps = useMapTimeSheetReportForTable({
    timesheetsReport: { timesheetsReport: data },
  });

  const headers = R.map(R.nth(0), preparedTableConfig);
  const columns = R.map(R.nth(1), preparedTableConfig);

  return (
    <PrintTableComponent
      {...restProps}
      {...timeSheetsTableProps}
      data={data}
      headers={headers}
      columns={columns}
    />
  );
}

PrintTimeSheetReportTable.displayName = 'PrintTimeSheetReportTable';

PrintTimeSheetReportTable.propTypes = {
  data: arrayOf(
    shape({
      owner: shape({
        fullName: string,
        _id: string,
      }),
    }),
  ),
};
