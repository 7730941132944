import * as R from 'ramda';
import { CURRENT_USER_QUERY } from '@poly/client-utils';
import {
  USER_GROUP_ID_AIT,
  EQUALS_AIT_OPERATOR,
  UPDATE_USER_PERMISSION,
  UPDATE_CONTACT_USER_PERMISSION,
} from '@poly/security';

import { hasUserPermissionsWithAits } from '@poly/client-utils/src/hooks/useHasUserAccessWithPermission.js';
import { useCurrentUserByStoreOrQuery } from '@poly/client-utils/src/hooks/useCurrentUserByStoreOrQuery.js';

// hasUpdateContactUserPermission :: User -> Boolean
const hasUpdateContactUserPermission = (user) =>
  hasUserPermissionsWithAits(user, UPDATE_CONTACT_USER_PERMISSION, {});

// hasUpdateStaffUserPermission :: (User, [ID]) -> Boolean
const hasUpdateStaffUserPermission = (user, userGroupIds) =>
  R.compose(
    R.all(R.equals(true)),
    R.map((ait) =>
      hasUserPermissionsWithAits(user, [UPDATE_USER_PERMISSION], ait, {
        isAll: true,
      }),
    ),
    R.map(R.assocPath([USER_GROUP_ID_AIT, EQUALS_AIT_OPERATOR], R.__, {})),
  )(userGroupIds);

export const useHasEditUserPermission = (userGroupIds) => {
  const { user, loading } = useCurrentUserByStoreOrQuery(
    CURRENT_USER_QUERY,
    {},
  );

  if (loading) {
    return false;
  }

  const permissionCheckFunc = R.isEmpty(userGroupIds)
    ? hasUpdateContactUserPermission
    : hasUpdateStaffUserPermission;

  return permissionCheckFunc(user, userGroupIds);
};
