import { arrayOf, shape, string, number } from 'prop-types';

export const clientInvoicesPropType = arrayOf(
  shape({
    _id: string.isRequired,
    amount: number.isRequired,
    number: number.isRequired,
    status: string.isRequired,
    paidAt: string,
    createdAt: string.isRequired,
    balance: number.isRequired,
  }),
);
