import * as R from 'ramda';
import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { useModalContext, useNotificationState } from '@poly/admin-ui';
import { FormCreator } from '@poly/admin-book';
import { bool, shape, string } from 'prop-types';
import { usePristineSubscribe } from '@poly/client-routing';

import {
  AccountTypeCategories,
  NormalBalanceTypes,
} from '@poly/constants/src/accountTypes.js';
import { renameProp } from '@poly/utils';
import { getAccountTypeFormSections } from './AccountTypeFormSections.js';
import { addAccountTypeFormId, editAccountTypeFormId } from '../constants.js';

const createSuccessMessage = 'Account type was created successfully';
const editSuccessMessage = 'Account type was edited successfully';

// eslint-disable-next-line import/no-unused-modules
export const CREATE_ACCOUNT_TYPE_MUTATION = gql`
  mutation CREATE_ACCOUNT_TYPE($input: AccountTypeCreateInput!) {
    createAccountType(input: $input) {
      _id
    }
  }
`;

// eslint-disable-next-line import/no-unused-modules
export const UPDATE_ACCOUNT_TYPE_MUTATION = gql`
  mutation UPDATE_ACCOUNT_TYPE($id: ID!, $input: AccountTypeUpdateInput!) {
    updateAccountType(id: $id, input: $input) {
      _id
    }
  }
`;

// getMutationVariables :: String -> Boolean -> FormFields -> Promise _ _
// FormFields = {
//    name: String,
//    status: String,
//    normalBalance: String,
// }
const getMutationVariables = R.curry((accountTypeId, isNewAccountType, input) =>
  R.compose(
    R.assoc('id', accountTypeId),
    R.objOf('input'),
    R.reject(R.isEmpty),
    R.ifElse(
      () => !isNewAccountType,
      R.pick(['name', 'category', 'status']),
      R.compose(
        renameProp('normalBalance', 'normal_balance'),
        R.pick(['name', 'normalBalance', 'category']),
      ),
    ),
  )(input),
);

export function AccountTypeForm({ isNewAccountType, document, ...props }) {
  const formId = isNewAccountType
    ? addAccountTypeFormId
    : editAccountTypeFormId;
  const accountTypeId = R.propOr('', '_id', document);
  const successMessage = isNewAccountType
    ? createSuccessMessage
    : editSuccessMessage;
  const submitMutation = isNewAccountType
    ? CREATE_ACCOUNT_TYPE_MUTATION
    : UPDATE_ACCOUNT_TYPE_MUTATION;
  const initialValues = R.applySpec({
    name: R.prop('name'),
    status: R.prop('status'),
    normalBalance: R.propOr(NormalBalanceTypes.DEBIT, 'normalBalance'),
    category: R.propOr(AccountTypeCategories.income, 'category'),
  })(document);

  const { showSuccessNotification } = useNotificationState();

  const { closeModal } = useModalContext();
  const [accountTypeMutation] = useMutation(submitMutation);

  const onSubmit = async (input) => {
    const variables = getMutationVariables(
      accountTypeId,
      isNewAccountType,
      input,
    );

    await accountTypeMutation({ variables });

    showSuccessNotification(successMessage);
    closeModal(formId);
  };

  const pristineSubscribeProps = usePristineSubscribe();

  return (
    <FormCreator
      id={formId}
      formId={formId}
      sections={getAccountTypeFormSections(isNewAccountType)}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onCancel={closeModal}
      successMessage={successMessage}
      {...props}
      {...pristineSubscribeProps}
    />
  );
}

AccountTypeForm.propTypes = {
  isNewAccountType: bool.isRequired,
  document: shape({
    _id: string.isRequired,
    name: string.isRequired,
    status: string.isRequired,
  }),
};
