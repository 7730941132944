import * as R from 'ramda';
import { assocBy } from '../ramda.js';
import { propEqLegacy } from '../ramdaLegacyFunctions.js';
import { getPropertiesDeepByHierarchyNodeR } from './properties-by-hierarchy.js';

// removePropertiesDuplicateR :: [ID] -> BatchHierarchySpendReportResult -> BatchHierarchySpendReportResult
const removePropertiesDuplicateR = R.curry((ids, node) =>
  R.compose(
    assocBy(
      'children',
      R.compose(
        R.when(Array.isArray, R.map(removePropertiesDuplicateR(ids))),
        R.prop('children'),
      ),
    ),
    assocBy(
      'properties',
      R.compose(
        R.when(
          Array.isArray,
          R.reject(
            R.both(
              R.propSatisfies(R.includes(R.__, ids), 'propertyId'),
              propEqLegacy('isMaster', false),
            ),
          ),
        ),
        R.prop('properties'),
      ),
    ),
  )(node),
);

// removeBatchSpendReportDuplicate :: [BatchHierarchySpendReportResult] -> BatchHierarchySpendReportResult
// -> [BatchHierarchySpendReportResult]
const removeBatchSpendReportDuplicate = R.curry((nodes, currentNode) =>
  R.compose(
    R.append(R.__, nodes),
    R.converge(removePropertiesDuplicateR, [
      R.compose(
        R.map(R.prop('propertyId')),
        R.unnest,
        R.map(getPropertiesDeepByHierarchyNodeR([])),
      ),
      R.always(currentNode),
    ]),
  )(nodes),
);

// removeBatchSpendReportPropertiesDuplicate :: [BatchHierarchySpendReportResult] -> [BatchHierarchySpendReportResult]
export const removeBatchSpendReportPropertiesDuplicate = R.reduce(
  removeBatchSpendReportDuplicate,
  [],
);
