import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { useRouterQuery } from '@poly/client-routing';

const CLIENT_CONFIG_QUERY = gql`
  query CLIENT_CONFIG_QUERY($clientId: ID!) {
    client(id: $clientId) {
      _id
      configs {
        enablePurchaseOrder
      }
    }
  }
`;

export const useClientPOEnabled = () => {
  const { clientId } = useRouterQuery(['clientId']);

  const { data } = useQuery(CLIENT_CONFIG_QUERY, {
    variables: { clientId },
    skip: !clientId,
  });

  return R.path(['client', 'configs', 'enablePurchaseOrder'], data);
};
