import * as R from 'ramda';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import React, { useEffect, useMemo } from 'react';
import { MoneyInputAsCents } from '@poly/admin-ui';
import { Popover, Icon } from '@poly/admin-book';

import { costPropTypes, placeholder } from './common.js';
import { isEditInvoiceFormDisabled } from '../helpers.js';

// eslint-disable-next-line import/no-unused-modules
export const SUPPLIER_FOODBUY_INFO = gql`
  query SUPPLIER_FOODBUY_INFO($supplierId: ID!) {
    supplier(id: $supplierId) {
      _id
      foodBuy {
        isEnabled
      }
    }
  }
`;

const PopoverS = styled(Popover)`
  position: absolute;
  top: 1px;
  left: 45px;
`;

const PopoverContentWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: start;
  background-color: #12347a;
  color: #fff;
  border-radius: 5px;
  max-width: 190px;
  font-size: 12px;
  line-height: 18px;
`;

export function LabourCostInput({ formData, onChange, value, ...props }) {
  const { materialCost, supplierId, isFoodbuy } = formData;

  const { data } = useQuery(SUPPLIER_FOODBUY_INFO, {
    variables: { supplierId },
    skip: !supplierId,
  });

  useEffect(() => {
    if (!value && materialCost > 0) {
      onChange(0);
    }
  }, [materialCost]);

  const onFocus = (e) => {
    if (e.target.value === '$0.00') {
      onChange('');
    }
  };

  const isFoodbuySupplier = useMemo(
    () => R.pathOr(false, ['supplier', 'foodBuy', 'isEnabled'])(data),
    [data],
  );

  return (
    <>
      <MoneyInputAsCents
        {...{ placeholder, onChange, value, ...props, onFocus }}
        disabled={isEditInvoiceFormDisabled({ formData })}
      />
      {(isFoodbuy || isFoodbuySupplier) && (
        <PopoverS
          position="bottom"
          bgColor="#12347a"
          isClickable={false}
          title={<Icon name="yellowWarning" size={16} />}
          content={
            <PopoverContentWrapperS>
              Foodbuy vendor – invoice must include Labor rate/hour breakdown
            </PopoverContentWrapperS>
          }
        />
      )}
    </>
  );
}

LabourCostInput.propTypes = costPropTypes;
