import React from 'react';
import * as R from 'ramda';
import { gql, useMutation } from '@apollo/client';
import { collectionNames } from '@poly/constants';
import { Loader, PopoverLink } from '@poly/admin-book';
import { propEqLegacy } from '@poly/utils';

import styled from 'styled-components';
import { useModalContext } from '../../../components/Modal/ModalProvider.js';
import { useNotificationState } from '../../../hooks/useNotificationState.js';
import { ThreeDotsPopover } from '../../sidebarComponents.js';
import { UpdateFilesForm } from './UpdateFilesForm.js';
import { useToggleFilePin } from './hooks.js';

const UPDATING_FILE_FORM_ID = 'updateFilesFormId';

const DELETE_FILE_MUTATION = gql`
  mutation DELETE_FILE_MUTATION($input: DeleteFileInput!) {
    deleteFile(input: $input) {
      id
    }
  }
`;

// prepareMutationInput :: Input -> DeleteFileInput
// Input = {
//    _id: ID
//    documentId: ID
//    collection: String
// }
const prepareMutationInput = R.compose(
  R.mergeAll,
  R.juxt([
    R.applySpec({ fileId: R.prop('_id') }),
    R.pick(['collection', 'documentId']),
  ]),
);

// isFileMenuDisplayed :: { week: Int, collection: String } -> Boolean
const isFileMenuDisplayed = R.allPass([
  R.propSatisfies(R.isNil, 'week'),
  R.complement(propEqLegacy('collection', collectionNames.ocrInvoices)),
]);

const PopoverLinkS = styled(PopoverLink)`
  position: relative;
`;

export function FileMenu(file) {
  const [deleteFileMutation] = useMutation(DELETE_FILE_MUTATION);
  const [toggleFilePin, loading] = useToggleFilePin(file);
  const { openModalForm, openConfirmSubmitFormModal, closeModal } =
    useModalContext();
  const { showSuccessNotification, showErrorNotification } =
    useNotificationState();

  const deleteFile = async (selectedFile) => {
    await deleteFileMutation({
      variables: { input: prepareMutationInput(selectedFile) },
    });
  };

  const onUpdateClick = () =>
    openModalForm({
      id: UPDATING_FILE_FORM_ID,
      formId: UPDATING_FILE_FORM_ID,
      title: 'Update File',
      btnCaption: 'Save Changes',
      maxWidth: '650px',
      content: (
        <UpdateFilesForm
          formId={UPDATING_FILE_FORM_ID}
          file={file}
          deleteFileMutation={deleteFile}
          closeModal={closeModal}
          showSuccess={showSuccessNotification}
          showError={showErrorNotification}
        />
      ),
    });

  const onDeleteClick = async () =>
    openConfirmSubmitFormModal({
      cancelBtnCaption: 'No',
      btnCaption: 'Yes, Delete',
      id: 'confirm_delete_file_modal_id',
      content: 'Are you sure you want to delete file from the project?',
      processId: 'deleteFileProcessId',
      onConfirm: (onCloseConfirmModal) => async () => {
        await deleteFile(file);
        showSuccessNotification('File was successfully deleted');
        onCloseConfirmModal();
      },
    });

  const onPinClick = async () => {
    await toggleFilePin(file);
    showSuccessNotification('File was successfully pinned');
  };

  return (
    isFileMenuDisplayed(file) && (
      <ThreeDotsPopover
        content={
          <>
            <PopoverLink onClick={onUpdateClick}>Update File</PopoverLink>
            <PopoverLink onClick={onDeleteClick}>Delete File</PopoverLink>
            {!file.pinned && (
              <PopoverLinkS onClick={onPinClick}>
                {loading && <Loader size={10} />}
                {!loading && 'Pin File'}
              </PopoverLinkS>
            )}
          </>
        }
      />
    )
  );
}
