import * as R from 'ramda';
import { format } from 'date-fns';
import { AccountStatuses } from '@poly/constants';
import {
  capitalizeFirstLetter,
  ExcelExportCellType,
  createExcelExportCell,
} from '@poly/client-utils';
import {
  propEqLegacy,
  XLS_BASE_FOOTER_STYLE,
  XLS_CURRENCY_FORMAT,
} from '@poly/utils';

const parentCellStyle = {
  ...XLS_BASE_FOOTER_STYLE,
  font: {
    ...XLS_BASE_FOOTER_STYLE.font,
    color: { argb: 'FFB7B7B7' },
  },
};

const defaultCellStyle = {
  alignment: { indent: 1 },
};

// formatHeader :: String -> String
const formatHeader = (tab) => {
  const date = format(new Date(), 'MM.dd.yyyy');
  return `ESFM Chart of Account / ${capitalizeFirstLetter(tab)}\n${date}`;
};

// isParent :: Account -> Boolean
const isParent = propEqLegacy('sub_account', false);

// isInactive :: String -> Account -> Boolean
const isInactive = (tab) =>
  R.allPass([
    () => R.equals(AccountStatuses.INACTIVE, tab),
    propEqLegacy('status', AccountStatuses.ACTIVE),
  ]);

// createCustomCell :: (String | Number, Object) -> ExcelExportDataCell
const createCustomCell = (value, style) => ({
  value,
  style,
});

// getAccountXLSRowValues :: Account -> [String]
const getAccountXLSRowValues = R.juxt([
  R.propOr('', 'code'),
  R.propOr('', 'name'),
  R.pathOr('', ['accountType', 'name']),
  R.pathOr('', ['division', 'name']),
  R.propOr('', 'accrualBalance'),
]);

// getAccountXLSRowConfig :: String -> Account -> [ExcelExportDataCell]
const getAccountXLSRowConfig = (tab) => (account) => {
  if (isParent(account)) {
    if (isInactive(tab)(account)) {
      return R.zipWith(createCustomCell, getAccountXLSRowValues(account), [
        ...R.repeat(parentCellStyle, 4),
        {
          ...parentCellStyle,
          numFmt: XLS_CURRENCY_FORMAT,
        },
      ]);
    }

    return R.zipWith(
      createExcelExportCell,
      [
        ...R.repeat(ExcelExportCellType.tableStringFooter, 4),
        ExcelExportCellType.tableCurrencyFooter,
      ],
      getAccountXLSRowValues(account),
    );
  }

  return R.zipWith(createCustomCell, getAccountXLSRowValues(account), [
    ...R.repeat(defaultCellStyle, 4),
    {
      ...defaultCellStyle,
      numFmt: XLS_CURRENCY_FORMAT,
    },
  ]);
};

// performAccountXLSExport :: (String, [Account]) -> Object
export const getAccountXLSExportConfig = (tab, accounts) => ({
  exportFileName: 'chart_of_account_export.xlsx',
  columnWidths: [15, 40, 20, 20, 20],
  rows: [
    [createExcelExportCell(ExcelExportCellType.title, formatHeader(tab))],
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 5)],
      ['GL Code', 'Name', 'Type', 'Division', 'Balance'],
    ),
    ...R.map(getAccountXLSRowConfig(tab), accounts),
  ],
});
