import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState } from 'react';
import { string, arrayOf, shape, func } from 'prop-types';
import { TextButton } from '@poly/admin-book';
import {
  generateFileNameByTitle,
  openPrintWindowWithData,
} from '@poly/client-utils';
import { PRINT_PDF_CAPTION } from '@poly/constants';

import { UpdatesTable } from '../../../modules/tables/updatesTable/UpdatesTable.js';
import { preparePrintTitleByEntity } from '../../../modules/tables/updatesTable/updatesInfoUtils.js';

// getFileNameByEntity :: Entity -> String
const getFileNameByEntity = R.compose(
  R.concat(R.__, '_updates'),
  generateFileNameByTitle,
  preparePrintTitleByEntity,
);

const AdditionalInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

export function PrintUpdatesTabButton({
  entity,
  updates,
  PrintLayout,
  printInfo,
  ...props
}) {
  const [loading, setLoading] = useState(false);

  const disabled = R.isEmpty(updates);

  const onClick = async () => {
    if (loading) return;
    setLoading(true);
    await openPrintWindowWithData({
      // we don't want to show warning modal for updates print
      openWarningModal: () => null,
      showWarningModal: false,
      updates,
      isPrint: true,
      Layout: PrintLayout,
      Table: UpdatesTable,
      fileName: getFileNameByEntity(entity),
      metaData: {
        title: 'Updates',
        additional: (
          <>
            {preparePrintTitleByEntity(entity)}
            {!R.isEmpty(printInfo) && (
              <AdditionalInfoWrapper>
                {printInfo.map((item) => (
                  <div key={item}>{item}</div>
                ))}
              </AdditionalInfoWrapper>
            )}
          </>
        ),
      },
      ...props,
    });
    setLoading(false);
  };

  return (
    <TextButton onClick={onClick} {...{ loading, disabled }}>
      {PRINT_PDF_CAPTION}
    </TextButton>
  );
}

PrintUpdatesTabButton.propTypes = {
  PrintLayout: func.isRequired,
  entity: shape({
    name: string.isRequired,
    humanReadableId: string.isRequired,
  }).isRequired,
  printInfo: arrayOf(string).isRequired,
  updates: arrayOf(shape({ _id: string.isRequired })).isRequired,
};
