import React from 'react';
import { useModalContext } from '@poly/admin-ui';
import { LinkButton } from '@poly/admin-book';

import { addDivisionFormId } from '../constants.js';
import { DivisionForm } from '../forms/DivisionForm.js';

export function AddDivisionButton() {
  const { openModalForm } = useModalContext();
  const onClick = () =>
    openModalForm({
      id: addDivisionFormId,
      title: 'Create New Division',
      formId: addDivisionFormId,
      btnCaption: 'Create',
      content: <DivisionForm isNewDivision />,
    });

  return <LinkButton onClick={onClick}>Add New</LinkButton>;
}
