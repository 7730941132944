import { string, shape, oneOfType, instanceOf, bool } from 'prop-types';

const invoiceDatePropTypes = shape({
  startDate: oneOfType([instanceOf(Date), string]),
  endDate: oneOfType([instanceOf(Date), string]),
});

export const paySupplierInvoiceFiltersPropTypes = shape({
  supplierId: string,
  masterSupplierId: string,
  clientId: string,
  serviceTypeId: string,
  projectId: string,
  invoiceDate: invoiceDatePropTypes,
  terms: string,
  acceptsCreditCard: bool,
  invoiceId: string,
});
