import { openPrintWindowWithData } from '@poly/client-utils';
import { CommonPrintLayout } from '@poly/admin-ui';
import { PRINT_PDF_CAPTION } from '@poly/constants';
import { alwaysNewDate } from '@poly/utils';
import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { LinkButton } from '@poly/admin-book';
import { arrayOf, shape, string } from 'prop-types';

import { ApReportTable, invoicesPropTypes } from './ApReportTable.js';
import { FlexContainer } from '../../components/FlexContainer.js';

const SubTitleS = styled.p`
  font-size: 16px;
  margin-top: 10px;
`;

function ApReportHeader() {
  return (
    <>
      <p>AP Outstanding Details</p>
      <SubTitleS>{`As of ${format(
        alwaysNewDate(),
        'MMMM dd, yyyy',
      )}`}</SubTitleS>
    </>
  );
}

const ApReportTableWithPaddingContainer = styled(FlexContainer)`
  padding-right: 40%;
`;

function ApReportPrintTable(props) {
  return (
    <ApReportTableWithPaddingContainer>
      <ApReportTable {...{ ...props, isPrintPDF: true }} isPrintPDF />
    </ApReportTableWithPaddingContainer>
  );
}

export function PrintSupplierApReportBtn({
  invoices,
  supplierName,
  cardNumber,
  terms,
}) {
  const onClick = () =>
    openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: ApReportPrintTable,
      fileName: 'ap_report',
      metaData: {
        title: (
          <ApReportHeader supplierName={supplierName} cardNumber={cardNumber} />
        ),
      },
      invoices,
      supplierName,
      cardNumber,
      terms,
    });
  return <LinkButton onClick={onClick}>{PRINT_PDF_CAPTION}</LinkButton>;
}

PrintSupplierApReportBtn.propTypes = {
  invoices: arrayOf(shape(invoicesPropTypes)),
  supplierName: string,
  cardNumber: string,
  terms: string,
};
