import React from 'react';
import * as R from 'ramda';
import { shape, string } from 'prop-types';
import { formatSelectOptions } from '@poly/admin-ui';
import { propertyStatuses } from '@poly/constants';
import { Select } from '@poly/admin-book';
import { propEqLegacy } from '@poly/utils';

// getPropertyStatusSelectWarning :: FormData -> String
const getPropertyStatusSelectWarning = R.ifElse(
  R.both(
    R.prop('submitProjectWebFormUrl'),
    propEqLegacy('status', propertyStatuses.INACTIVE),
  ),
  R.always('The property Web Forms will be disabled'),
  R.always(''),
);

export function StatusSelect({ formData, ...props }) {
  const options = formatSelectOptions(propertyStatuses);
  const warningMsg = getPropertyStatusSelectWarning(formData);

  return <Select {...props} warning={warningMsg} options={options} />;
}

StatusSelect.displayName = 'PropertyStatusSelect';

StatusSelect.propTypes = {
  formData: shape({
    status: string,
    submitProjectWebFormUrl: string,
  }),
};
